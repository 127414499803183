<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Org Settings"
    :scrollable="false"
    @close="close"
    @confirm="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button
        v-else
        before-icon="settings"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        >View Org Settings</primary-button
      >
    </template>
    <template slot="body">
      <div v-for="setting in Object.keys(settings)" :key="setting">
        <strong>{{ novaCore.EntitySettings.Org[setting].label }}</strong
        >: {{ settings[setting] }}
      </div>
    </template>
    <template slot="dialog-actions">
      <action-group @confirm="close" hide-cancel confirm-label="close"> </action-group>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    settings: {
      type: Object,
      required: true
    }
  }
};
</script>
