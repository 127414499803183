<template>
  <div class="printable-window">
    <div class="header-spacing"></div>
    <article>
      <header class="printable-header">
        <img src="/images/logo_for_print.png" alt="Opendock Logo" />
        <span>Printed on {{ currentTimestamp }}</span>
      </header>
      <section class="section-1">
        <div class="center-row">
          <v-icon class="jumbo-icon">mdi-calendar</v-icon>
          <div style="margin-left: 15px" class="column">
            <div class="label-text" style="margin-bottom: 15px">
              {{ isRequested ? 'Requested for:' : 'Scheduled to:' }}
            </div>
            <div class="appointment-time">{{ formattedAppointmentDateTime }}</div>
          </div>
        </div>
        <div class="status-details">
          <template v-if="noShow">
            <div>
              <div class="label-text">Reported as No Show:</div>
              <div class="status-date-time">Carrier did not show for appointment.</div>
            </div>
          </template>
          <template v-else-if="isRequested">
            <div>
              <div class="status-date-time">Appointment waiting for warehouse confirmation</div>
            </div>
          </template>

          <template v-else>
            <div>
              <div class="label-text">Arrived at:</div>
              <div class="status-date-time">
                {{ formattedArrivalTime }}
              </div>
              <div class="label-text black" v-if="arrivalTimeDiff">{{ arrivalTimeDiff }}</div>
            </div>
            <img class="arrow" src="/images/arrow-right.png" alt="Right Arrow" />
            <div>
              <div class="label-text">Completed at:</div>
              <div class="status-date-time">
                {{ formattedCompletedTime }}
              </div>
              <div class="label-text black" v-if="completedTimeDiff">{{ completedTimeDiff }}</div>
            </div>
          </template>
        </div>
      </section>
      <section class="section-2">
        <div>
          <div class="label-text">Carrier Contact:</div>
          <div class="data-group">
            <span class="bold">{{ companyName }}</span>
            <span>
              <span class="bold">Name:</span>
              {{ user.firstName }} {{ user.lastName }}
            </span>
            <span>
              <span class="bold">Email:</span>
              {{ user.email }}
            </span>
            <span>
              <span class="bold">Phone:</span>
              {{ novaCore.formatPhoneNumber(user.phone) }}
            </span>
          </div>

          <div class="label-text">Warehouse:</div>
          <div class="data-group">
            <span class="bold">{{ warehouse.name }}</span>
            <span>
              <span class="bold">Address:</span>
              {{ warehouseAddress }}
            </span>
            <span>
              <span class="bold">Email:</span>
              {{ warehouse.email }}
            </span>
            <span>
              <span class="bold">Phone:</span>
              {{ warehouse.phone }}
            </span>
          </div>

          <div class="label-text">Appointment Confirmation #:</div>
          <div class="data-group">
            <span>{{ appointment.confirmationNumber }}</span>
          </div>

          <div class="label-text">Appointment ID:</div>
          <div class="data-group">
            <span>{{ appointment.id }}</span>
          </div>
        </div>
        <div>
          <div class="label-text">Dock name:</div>
          <div class="data-group">
            {{ dock.name }}
          </div>
          <div class="label-text">Load type:</div>
          <div class="data-group">
            {{ loadType.name }}
          </div>

          <template v-if="settings.referenceNumberIsVisible !== false">
            <div class="label-text">{{ refNumLabel }}:</div>
            <div class="data-group">
              {{ appointment.refNumber }}
            </div>
          </template>

          <div class="label-text">Appointment Notes:</div>
          <div class="data-group" v-html="appointmentNotes"></div>

          <div class="label-text">Tags:</div>
          <div class="data-group">
            {{ appointmentTags }}
          </div>

          <template v-for="field in Object.values(customFields)">
            <div :key="field.name" v-if="showCustomField(field)">
              <div class="label-text">{{ field.label }}:</div>
              <div
                v-html="renderCustomField(field)"
                style="white-space: pre-line"
                class="data-group"></div>
            </div>
          </template>

          <template v-for="field in customFormData">
            <div :key="field.name" v-if="showCustomField(field)">
              <div class="label-text">{{ field.label }}:</div>
              <div
                v-html="renderCustomField(field)"
                style="white-space: pre-line"
                class="data-group"></div>
            </div>
          </template>
        </div>
      </section>

      <div style="padding: 2rem 0; text-align: center">
        <span class="bold" style="margin-right: 10px">Opendock</span>
        The ultimate dock scheduling software for warehouses.
      </div>
    </article>
    <div class="page-break"></div>
  </div>
</template>

<script>
import appointmentMixinBase from '@satellite/components/mixins/appointmentMixinBase';

export default {
  mixins: [appointmentMixinBase],
  props: {
    appointment: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    /*
     * Array of customFormData
     * label, type, value
     * similar to custom fields
     */
    customFormData: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.setCustomFieldsRows();
  },
  methods: {
    renderCustomField(field) {
      return this.novaCore.getCustomFieldFormattedValue(field, {
        [this.novaCore.CustomFieldType.Timestamp]: {
          timezone: this.appointment.dock.warehouse.timezone,
          formatAsMilitary: this.$isMilitaryTimeEnabled(
            this.appointment.dock.warehouse || this.$org
          )
        }
      });
    }
  },
  watch: {
    appointment() {
      this.setCustomFieldsRows();
    }
  }
};
</script>
