<template>
  <div>
    <v-card v-if="!showLoadTypeForm" :class="{ disabled: readOnly }">
      <v-card-title>
        <v-row align="center" class="search-field">
          <v-col md="6">
            <text-field
              class="mb-5"
              v-model="filters.searchStr"
              append-icon="mdi-magnify"
              label="Search load types"
              hint="Search load types"
              single-line></text-field>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-tooltip top :disabled="!isCreationButtonDisabled">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <primary-button
                @click="showLoadTypeForm = true"
                :disabled="Boolean(getDisabledCreationMessage)"
                before-icon="plus"
                large
                class="mr-2">
                Create Load Type
              </primary-button>
            </div>
          </template>
          <span>{{ getDisabledCreationMessage }}</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table
        :class="{ disabled: readOnly }"
        :headers="rowHeaders"
        :items="$loadTypes"
        :loading="loading"
        :server-items-length="$loadTypePagination.total"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="footerProps"
        :options.sync="options">
        <template v-slot:[`item.name`]="{ item }">
          <copy-content :content="item.name">{{ item.name }}</copy-content>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <v-tooltip v-if="item.description" top>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ novaCore.truncateString(item.description, 25) }}</span>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:header.warehouses>
          <span class="d-inline-block mr-1">Warehouses</span>
          <help-icon-tooltip>
            List of all warehouses where this load type is assigned to at least one dock
          </help-icon-tooltip>
        </template>
        <template v-slot:item.duration_min="{ item }">
          {{ novaCore.formatMinutesToHuman(item.duration_min) }}
        </template>

        <template v-slot:item.allowCarrierScheduling="{ item }">
          {{ !item.allowCarrierScheduling ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.warehouses="{ item }">
          <v-btn
            text
            class="link px-0"
            x-small
            @click="warehouseDialog = { shouldShow: true, entity: item }">
            {{ getWarehouseList(item.warehouses) }}
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="row-actions">
            <audit-log-entity-icon-button
              :item="item"
              :timezone="warehouse?.timezone ?? 'UTC'"
              :entity-type="novaCore.DatabaseEntities.LoadType"></audit-log-entity-icon-button>
            <icon-tooltip-button
              :disabled="readOnly"
              icon-class="ml-2"
              size="large"
              :tooltip="readOnly ? novaCore.getRoleActionError() : 'Edit Load Type'"
              @click="showEditDialog(item)"
              edit></icon-tooltip-button>
            <icon-tooltip-button
              :disabled="readOnly"
              icon-class="ml-2"
              size="large"
              @click="showDeleteLoadTypeConfirmation(item)"
              :tooltip="readOnly ? novaCore.getRoleActionError() : 'Delete Load Type'"
              delete></icon-tooltip-button>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-card v-else>
      <v-card-title>
        <div>
          <span class="text-uppercase text-caption grey--text text--darken-2">
            {{ editingLoadType ? 'edit load type' : 'create load type' }}
          </span>
          <br />
          <span>Load Type Details</span>
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="closeEditOrCreateDialog">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <load-type-form
          ref="createLoadTypeForm"
          @close="closeEditOrCreateDialog"
          @saved="data => getData()"
          :warehouse="warehouse"
          :loadType="editingLoadType"></load-type-form>
      </v-card-text>
    </v-card>

    <list-warehouse-dialog
      @close="closeWarehouseDialog"
      v-if="warehouseDialog.entity"
      :warehouse-dialog="warehouseDialog"
      :warehouses="warehouseDialog.entity.warehouses"
      :label="`${warehouseDialog.entity.name} is being used at the following locations:`"
      :header="`Warehouses using ${warehouseDialog.entity.name}`"></list-warehouse-dialog>

    <confirm
      :should-show="showDeleteConfirm"
      is-manual-mode
      persistent
      v-if="deletingLoadType"
      @result="handleDeleteResult"
      icon="mdi-delete-forever"
      delete-confirmation
      :show-delete-warning="!deleteMessageHtml"
      title="Delete this Load Type?"
      :width="650"
      :loading="deleting"
      :confirmation-input-text="deleteConfirmationInputText"
      :entity-name="deletingLoadType.name"
      button-true-text="YES, DELETE"
      button-true-color="error"
      color="error">
      <template v-slot:message>
        <v-skeleton-loader v-if="deleting" type="list-item-three-line"></v-skeleton-loader>
        <div v-else-if="deleteMessageHtml">
          <h2 class="text-center my-3">Appointments will also be deleted!</h2>
          <v-alert text prominent border="left" color="red" class="my-8">
            <v-icon color="red" class="mr-2">mdi-alert</v-icon>
            <span class="text--primary" v-html="deleteMessageHtml"></span>
          </v-alert>
        </div>
      </template>
    </confirm>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import listWithWarehousesMixin from '@satellite/components/mixins/listWithWarehousesMixin';
import createLoadTypeMixin from '@/modules/load_type/mixins/createLoadTypeMixin';

export default {
  mixins: [dataListMixin, listWithWarehousesMixin, createLoadTypeMixin],
  props: {
    shouldDisplayWarehouses: {
      type: Boolean,
      required: false,
      default: false
    },
    warehouse: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    areActionsDisplayed() {
      const isOrgLoadtypeList = !(this.warehouse?.id && this.warehouse?.orgId);
      const canWarehouseUserAccess = this.novaCore.canUserAccessWarehouse(
        this.$me,
        this.warehouse?.id,
        this.warehouse?.orgId
      );
      const hasLoadTypePermissions =
        this.$rolePermissions.canUpdateLoadType || this.$rolePermissions.canDeleteLoadType;

      // When the loadtype list is showing org load types,
      // we just check if the user is warehouse restricted org or not
      // If the user is warehouse restricted it can't change org load types.
      // Otherwise, we can check against the warehouse permission system
      return isOrgLoadtypeList ? !this.readOnly : canWarehouseUserAccess && hasLoadTypePermissions;
    },
    headers() {
      const headers = [
        {
          text: 'Name',
          value: 'name',
          searchable: true,
          sortable: true
        },
        {
          text: 'Description',
          value: 'description',
          searchable: true,
          sortable: true
        },
        {
          text: 'Duration',
          value: 'duration_min',
          searchable: false
        },
        {
          text: 'Direction',
          value: 'direction',
          searchable: false
        },
        {
          text: 'Operation',
          value: 'operation',
          searchable: false
        },
        {
          text: 'Equipment Type',
          value: 'equipmentType',
          searchable: false
        },
        {
          text: 'Transportation Mode',
          value: 'transportationMode',
          searchable: false
        },
        {
          text: 'Hidden from Carriers?',
          value: 'allowCarrierScheduling',
          sortable: true
        }
      ];

      if (this.areActionsDisplayed) {
        headers.push({
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end'
        });
      }
      return headers;
    }
  },
  data() {
    return {
      loading: false,
      sortBy: ['name'],
      searchFields: ['name'],
      forceCreateDialogShow: false,
      showDeleteConfirm: false,
      deleteMessageHtml: null,
      deletingLoadType: null,
      deleting: false,
      showLoadTypeForm: false,
      editingLoadType: null,
      deleteConfirmationInputText: 'YES, I AM AWARE'
    };
  },
  methods: {
    async closeEditOrCreateDialog() {
      if (
        this.showLoadTypeForm &&
        this.$route.meta.isDirty &&
        !(await this.checkDirtyConfirmation())
      ) {
        return;
      }

      this.editingLoadType = null;
      this.showLoadTypeForm = false;
    },
    showEditDialog(loadType) {
      this.editingLoadType = loadType;
      this.showLoadTypeForm = true;
    },
    async getData() {
      try {
        this.loading = true;
        const storeAction = this.shouldDisplayWarehouses
          ? 'getLoadTypesWithWarehouses'
          : 'getLoadTypes';
        await this.$store.dispatch(`LoadTypes/${storeAction}`, this.getQuery());
      } finally {
        this.loading = false;
      }
    },
    async showDeleteLoadTypeConfirmation(loadType) {
      this.deletingLoadType = loadType;
      this.showDeleteConfirm = true;
      this.deleteMessageHtml = await this.makeDeleteConfirmationText(loadType);
    },
    async makeDeleteConfirmationText(loadType) {
      this.deleting = true;

      const appointmentCountRes = await axios
        .get(`/loadtype/${loadType.id}/appointment-count`)
        .catch(() => {
          this.notify('Failed to get appointment count', 'error');
        });

      // { warehouseId, warehouseName, count }[]
      const appointmentCountData = appointmentCountRes?.data?.data;
      let msg = '';

      if (appointmentCountData.length > 0) {
        msg = appointmentCountData.reduce((a, { warehouseName, count }) => {
          if (count > 0) {
            return (
              a +
              `<p class="mb-1 ml-8"><strong>
                 ${warehouseName}: ${count} appointment${count > 1 ? 's' : ''}
               </strong></p>`
            );
          }
        }, '<span>You are about to permanently delete appointments from:</span><br /><br />');

        const totalCount = appointmentCountData.reduce((a, { count }) => a + parseInt(count), 0);

        msg += `<p class="mb-0 mt-3 ml-8"><strong>
                Total of ${totalCount} appointment${totalCount > 1 ? 's' : ''}
                </strong></p>`;

        this.deleteConfirmationInputText = this.util.deleteConfirmationInputText(
          totalCount,
          'appointment'
        );
      } else {
        this.deleteConfirmationInputText = this.deletingLoadType.name;
      }

      this.deleting = false;
      return msg;
    },
    getQuery() {
      const query = this.getQueryBase();

      if (this.warehouse?.id) {
        query.warehouseId = this.warehouse.id;
        query.includeOrgLoadTypes = false;
      } else {
        query.s.warehouseId = null;
      }

      return query;
    },
    async handleDeleteResult(confirmed) {
      if (confirmed) {
        this.deleting = true;
        await this.$store.dispatch('LoadTypes/deleteLoadType', this.deletingLoadType);
        this.deleting = false;
      }
      this.deletingLoadType = null;
      this.deleteMessageHtml = null;
      this.showDeleteConfirm = false;

      return this.getData();
    },
    async handleSocketEvent() {
      return this.getData();
    }
  },
  beforeMount() {
    this.$store.commit('LoadTypes/setLoadTypes', []);
  },
  mounted() {
    // This is required so when subspace notify changes we know whether to fetch the warehouses or not
    this.$store.commit(`LoadTypes/setShouldDisplayWarehouses`, this.shouldDisplayWarehouses);

    if (this.shouldDisplayWarehouses) {
      this.headers.splice(-1, 0, {
        text: 'Warehouses',
        value: 'warehouses',
        searchable: false,
        sortable: false
      });
    }
    if (this.$route.query.showLoadTypeForm) {
      this.forceCreateDialogShow = true;
      this.$router.replace({ query: {} });
    }
    this.$eventHub.$on(
      ['update-LoadType', 'create-LoadType', 'delete-LoadType'],
      this.handleSocketEvent
    );
    if (this.shouldDisplayWarehouses) {
      // We want to listen for changes on load types, docks, and warehouses
      // since we display warehouses in the load type list
      this.$eventHub.$on('update-Dock', this.handleSocketEvent);
      this.$eventHub.$on('update-Warehouse', this.handleSocketEvent);
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(
      ['update-LoadType', 'create-LoadType', 'delete-LoadType'],
      this.handleSocketEvent
    );
    if (this.shouldDisplayWarehouses) {
      this.$eventHub.$off('update-Dock', this.handleSocketEvent);
      this.$eventHub.$off('update-Warehouse', this.handleSocketEvent);
    }
  },
  destroyed() {
    this.$store.commit(`LoadTypes/setShouldDisplayWarehouses`, false);
  }
};
</script>
