<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Reserves conflict"
    :scrollable="false"
    loading-message="Processing reserves"
    @close="close">
    <template v-slot:activator></template>
    <template v-slot:body>
      <transfer-reserves-form
        @close="close"
        @confirm="confirm"
        :deleted-user="deletedUser"
        ref="transferUserForm"></transfer-reserves-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    deletedUser: {
      type: Object,
      required: true
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.close();
    }
  }
};
</script>
