<template>
  <div class="appointment-sections">
    <header class="z-100 white" id="mobile-appointment-details-header">
      <section class="d-flex align-center justify-space-between pt-3 px-3">
        <div class="d-flex flex-row flex-nowrap align-center">
          <v-btn
            icon
            class="nav-arrow-btn mr-4 mobile-icon-btn"
            @click="$emit('close')"
            data-testid="back-button">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <div class="d-flex flex-column">
          <p class="mt-1">
            Keep records of damages, security measures and other evidence to support claim disputes:
          </p>
        </div>
      </section>
    </header>

    <v-divider></v-divider>

    <div class="mb-12 pb-12">
      <v-expansion-panels accordion class="details-expansion-panel">
        <v-expansion-panel v-for="trigger of claimSupportTriggers" :key="trigger.id">
          <v-expansion-panel-header>
            <h3 class="mr-1 pa-2">{{ featureData(trigger.feature).title }}</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <custom-forms-data
              :key="trigger.id"
              :trigger="trigger"
              flat
              :object-id="appointment.id"
              :timezone="warehouse.timezone"
              :military-time-enabled="$isMilitaryTimeEnabled(warehouse)"></custom-forms-data>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div id="mobile-claims-form-actions">
      <v-divider></v-divider>

      <div>
        <v-dialog
          content-class="claims-actions-mobile"
          :value="showClaimsActions"
          transition="dialog-bottom-transition"
          external-activator
          @input="val => (showClaimsActions = val)">
          <template #activator="slotProps">
            <v-btn
              color="secondary"
              class="full-width m-b-4 p-x-2 claims-actions-trigger"
              v-on="slotProps.on"
              v-bind="slotProps.attrs">
              Update form
            </v-btn>
          </template>
          <div class="pa-4">
            <div class="font-weight-bold font-size-small mb-4 ml-4">
              Which Claims Support form would you like to update?
            </div>
            <v-btn
              v-for="trigger of claimSupportTriggers"
              :key="trigger.feature"
              text
              plain
              @click="
                showClaimsActions = false;
                activeTrigger = trigger;
              "
              class="full-width mb-2 left-aligned-btn"
              :disabled="!trigger.isActive">
              <v-icon small class="mr-2">mdi-text-box-edit-outline</v-icon>
              {{ featureData(trigger.feature).title }}
            </v-btn>
          </div>
        </v-dialog>
      </div>
    </div>

    <custom-forms-flow-dialog
      :external-activator="true"
      :warehouse="warehouse"
      :show-dialog="hasActiveTrigger"
      :object-id="appointment.id"
      :trigger="activeTrigger"
      fullscreen
      stack-actions
      @close="cleanUpCustomFormsData"
      @update="updateCustomFormsData"
      @create="value => createBulkCustomFormsData(value, warehouse.id)">
      <template #custom-actions></template>
    </custom-forms-flow-dialog>
  </div>
</template>

<script>
import appointmentMixin from '@/components/mixins/appointmentMixin';
import customFormsMixin from '@satellite/components/mixins/customFormsMixin';
export default {
  mixins: [appointmentMixin, customFormsMixin],
  props: {
    appointment: {
      type: Object,
      required: true
    },
    warehouseTriggers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showClaimsActions: false
    };
  },
  methods: {
    featureData(feature) {
      return this.novaCore.CustomFormsFeaturesData[feature] || {};
    }
  },
  computed: {
    claimSupportTriggers() {
      return (
        this.warehouseTriggers?.filter(t =>
          this.novaCore.CustomFormsClaimFeatures.includes(t.feature)
        ) || []
      );
    },
    canUserAccessWarehouse() {
      return this.novaCore.canUserAccessWarehouse(
        this.$me,
        this.appointmentWarehouse?.id,
        this.appointmentWarehouse?.orgId
      );
    }
  }
};
</script>

<style lang="scss">
.claims-actions-mobile {
  max-height: 300px !important;
  margin: 0 !important;
  top: auto;
  bottom: 0;
  background-color: #fff;
  position: fixed !important;
}
.claims-actions-trigger {
  margin: 12px 0px;
  width: calc(100% - 32px) !important;
  left: 16px;
}
</style>
