var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "zoom-buttons d-none", attrs: { id: "zoom-buttons" } },
    [
      _c(
        "v-btn",
        { attrs: { small: "", tile: "" }, on: { click: _vm.zoomIn } },
        [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-magnify-plus")])],
        1
      ),
      _c(
        "v-btn",
        { attrs: { small: "", tile: "" }, on: { click: _vm.zoomOut } },
        [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-magnify-minus")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }