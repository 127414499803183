import customMessageMixin from '@satellite/components/mixins/customMessageMixin';

export default {
  computed: {
    isCreationButtonDisabled() {
      return Boolean(this.getDisabledCreationMessage);
    },
    getDisabledCreationMessage() {
      const maxLoadTypes = this.novaCore.MAX_LOAD_TYPES_PER_WAREHOUSE;
      const isWarehouseLoadType = Boolean(this.warehouseId);
      // permissions takes precedence
      if (!this.$rolePermissions.canCreateLoadType || this.readOnly) {
        return this.novaCore.getRoleActionError();
      } else if (this.$loadTypePagination.total >= maxLoadTypes) {
        return customMessageMixin.computed.maxLoadTypeLimitWarning(
          maxLoadTypes,
          isWarehouseLoadType
        );
      }
      return '';
    }
  }
};
