import { ref } from 'vue';
import isEmpty from 'lodash/isEmpty';
import carrierContactsService from '@satellite/services/carrier-contacts-service';

export default function useCarrierContactDetail() {
  const carrierContactRef = ref();
  const isLoadingRef = ref(false);

  async function clear() {
    carrierContactRef.value = null;
  }

  async function fetch(params = {}) {
    isLoadingRef.value = true;
    params.limit = 1;

    try {
      const contacts = await carrierContactsService.list(params);
      const contact = contacts?.[0];
      if (!contact || isEmpty(contact)) {
        throw new Error('No contact found');
      }
      carrierContactRef.value = contact;
    } catch (err) {
      console.error('Failed to fetch carrier contact detail', err);
      carrierContactRef.value = null;
    } finally {
      isLoadingRef.value = false;
    }
  }

  return { carrierContactRef, isLoadingRef, fetch, clear };
}
