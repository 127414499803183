<template>
  <v-autocomplete
    data-testid="yard-view-spot-select"
    outlined
    :items="spotsByArea"
    item-value="id"
    item-text="code"
    placeholder="Select a spot"
    :return-object="false"
    @update:search-input="$e => (searchTerm = $e)"
    @input="$emit('input', $event)"
    dense
    :clearable="false"
    hide-details>
    <template #no-data>
      <v-list-item>
        <v-list-item-title>
          <span class="typography-body-sm">No spots available</span>
        </v-list-item-title>
      </v-list-item>
    </template>
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <v-icon x-small class="mr-2">{{ item.icon }}</v-icon>
          <span>{{ item.code }}</span>
          <span v-if="searchTerm" class="font-weight-regular text-uppercase ml-2">
            ({{ item.group }})
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewSpotSelect',
  emits: ['input'],
  props: {
    showClosedSpots: {
      type: Boolean,
      default: false
    },
    showAssignedSpots: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const yardStore = useYard();
    const searchTerm = ref('');

    const spotsByArea = computed(() => {
      const items = [];
      const allSpots = yardStore.spotsRef.value || [];
      const areas = yardStore.spotAreasRef.value || [];

      for (const area of areas) {
        let areaSpots = allSpots.filter(spot => spot.spotAreaId === area.id);

        if (!props.showClosedSpots) {
          areaSpots = areaSpots.filter(spot => !yardStore.isSpotClosed(spot));
        }
        if (!props.showAssignedSpots) {
          areaSpots = areaSpots.filter(spot => !yardStore.isSpotAssigned(spot));
        }

        items.push({ header: area.name.toUpperCase() });
        items.push(
          ...areaSpots.map(spot => ({
            code: spot.code,
            id: spot.id,
            icon: yardStore.getSpotIcon(spot.type),
            group: area.name
          }))
        );
      }

      return items;
    });

    return {
      selectedAssetVisit: yardStore.selectedAssetVisitRef,
      selectedSpot: yardStore.selectedSpotRef,
      spotAreas: yardStore.spotAreasRef,
      spots: yardStore.spotsRef,
      spotsByArea,
      searchTerm
    };
  }
});
</script>
