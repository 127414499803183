<template>
  <v-card v-if="ready" class="elevation-0">
    <v-card-text class="pa-1">
      <bar-chart
        title="Average Appointment Duration by Load Type in Minutes"
        :colors="colors"
        :data="chartData"></bar-chart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else class="mx-auto" type="card"> </v-skeleton-loader>
</template>

<script>
import insightsMixin from '@/components/mixins/insightsMixin';

export default {
  mixins: [insightsMixin],
  data() {
    return {
      apiEndpoint: 'average-duration-by-load-type'
    };
  }
};
</script>
