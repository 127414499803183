var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("document-upload-button", {
        attrs: { required: _vm.field.required, rules: _vm.validationRules },
        on: {
          uploading: function ($event) {
            return _vm.$emit("toggle-confirm-button", true)
          },
          uploaded: function ($event) {
            return _vm.$emit("toggle-confirm-button", false)
          },
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v(" Maximum "),
      _c("strong", [_vm._v("50MB")]),
      _vm._v(" in size. Only "),
      _c("strong", [_vm._v("ONE image")]),
      _vm._v(" , "),
      _c("strong", [_vm._v("text")]),
      _vm._v(" , "),
      _c("strong", [_vm._v("zip")]),
      _vm._v(" or "),
      _c("strong", [_vm._v("spreadsheet")]),
      _vm._v(" allowed. "),
      _c("br"),
      _vm._v(" To upload multiple docs, zip first then upload. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }