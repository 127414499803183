var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        ref: "companySelect",
        attrs: {
          required: _vm.required,
          "prepend-icon": !_vm.hideIcon ? "mdi-domain" : "",
          dense: "",
          height: "20px",
          "search-input": _vm.filters.searchStr,
          value: _vm.value,
          items: _vm.companies,
          "test-id": "select-company-search-field",
          "item-value": "id",
          "no-filter": "",
          "hide-no-data": !_vm.filters.searchStr,
          "persistent-hint": "",
          "item-text": "name",
          label: _vm.placeholder,
          loading: _vm.isLoading,
          disabled: _vm.disabled,
        },
        on: {
          input: _vm.updateValue,
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            $event.preventDefault()
            return (() => {}).apply(null, arguments)
          },
          "update:searchInput": function ($event) {
            return _vm.$set(_vm.filters, "searchStr", $event)
          },
          "update:search-input": function ($event) {
            return _vm.$set(_vm.filters, "searchStr", $event)
          },
          change: _vm.emitCompanyChanged,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "selection",
              fn: function ({ item }) {
                return [
                  _vm._v("[" + _vm._s(item.scac) + "] " + _vm._s(item.name)),
                ]
              },
            },
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  [
                    _c(
                      "div",
                      { staticClass: "full-width" },
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-content",
                              { staticClass: "d-block" },
                              [
                                _c("v-list-item-title", {
                                  domProps: {
                                    innerHTML: _vm._s(`${item.name}`),
                                  },
                                }),
                                _c("v-list-item-subtitle", [
                                  _vm._v("SCAC: " + _vm._s(item.scac)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.companies.indexOf(item) < _vm.companies.length - 1
                          ? _c("v-divider", {
                              key: _vm.companies.indexOf(item),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                ]
              },
            },
            {
              key: "append-item",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c("create-carrier-company-dialog", {
                            attrs: { "entry-point": _vm.entryPoint },
                            on: { save: _vm.handleNewCompany },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (slotProps) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "primary",
                                              block: "",
                                            },
                                          },
                                          "v-btn",
                                          slotProps.attrs,
                                          false
                                        ),
                                        slotProps.on
                                      ),
                                      [_vm._v(" Create a new Carrier Company ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            _vm.required
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.placeholder) + " "),
                      _c("span", { staticClass: "red--text" }, [
                        _c("strong", [_vm._v("*")]),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      },
      "v-autocomplete",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }