<template>
  <v-card
    :width="cardWidth"
    :height="cardHeight"
    elevation="1"
    @dragenter="handleDragEnter"
    @dragleave="handleDragLeave"
    @dragover="handleDragEnter"
    @click="handleSpotClick"
    :data-testid="`yard-spot-${spot.code}`"
    :id="spot.id"
    :class="{
      'drag-active': isDragActive,
      'drag-disabled': isDragDisabled,
      'bg--color-success-20': isSpotOpen(spot),
      'disabled-background': isSpotClosed(spot),
      'drop-zone': isSpotOpen(spot),
      'spot-selected': isSpotSelected
    }"
    class="spot-title">
    <v-card-title
      class="pa-1"
      :class="{
        'bg--color-neutral-20': !selectedSpot || !isSpotSelected,
        'bg--color-primary-60': isSpotSelected
      }">
      <div
        class="font-size-small text-center full-width text-uppercase spot-card-code"
        :data-testid="`yard-spot-title-${spot.code}`">
        <strong>{{ spot.code }}</strong>
      </div>
    </v-card-title>

    <v-card-text class="card-content">
      <v-card
        elevation="0"
        :draggable="isSpotAssigned(spot)"
        :id="spotAssignment?.id"
        @dragstart="onDragStart"
        @dragend="onDragEnd"
        :height="cardContentHeight"
        class="d-flex flex-column align-center justify-center text-center bg--color-background-transparent">
        <yard-view-spot-unassigned :spot="spot" v-if="!isSpotAssigned(spot)" />
        <yard-view-spot-assigned :spot="spot" :appointment="appointment" v-else />
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

import YardViewSpotUnassigned from '@/modules/yard/components/Spot/Card/YardViewSpotUnassigned.vue';
import YardViewSpotAssigned from '@/modules/yard/components/Spot/Card/YardViewSpotAssigned.vue';
import { useEventHub, useSockets } from '@/composables';
import { isUUID } from 'class-validator';

export default defineComponent({
  name: 'YardViewSpot',
  components: { YardViewSpotAssigned, YardViewSpotUnassigned },
  props: {
    spot: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();
    const eventHub = useEventHub();
    const sockets = useSockets();

    const isDragActive = ref(false);
    const isDragDisabled = ref(false);

    const isDragging = ref(false);
    const originalElement = ref(null);

    const handleSpotClick = () => {
      if (yardStore.selectedSpotRef.value?.id === props.spot.id) {
        return;
      }
      yardStore.selectedSpotRef.value = props.spot;
    };

    const spotAssignment = computed(() => props.spot.spotAssignments[0]);
    const appointment = computed(() => spotAssignment.value?.assetVisit?.appointment);

    const isSpotSelected = computed(() => yardStore.selectedSpotRef.value?.id === props.spot.id);

    const handleDragEnter = () => {
      if (props.spot.isActive && !yardStore.isSpotAssigned(props.spot)) {
        isDragActive.value = true;
      } else {
        isDragDisabled.value = true;
      }
    };

    const handleDragLeave = () => {
      isDragActive.value = false;
      isDragDisabled.value = false;
    };

    const updateAppointmentFromSubspace = event => {
      if (event.id === appointment?.value?.id && props.spot.spotAssignments.length) {
        props.spot.spotAssignments[0].assetVisit.appointment = event;
      }
    };

    const onDragStart = event => {
      isDragging.value = true;
      originalElement.value = event.target;

      // Set the data for transfer
      event.dataTransfer.setData('text/plain', event.target.id);
    };

    const onDragEnd = event => {
      isDragging.value = false;

      // Check if the element was dropped inside a drop-zone
      const newSpotElement = document
        .elementFromPoint(event.clientX, event.clientY)
        .closest('.drop-zone');

      if (isUUID(newSpotElement?.id)) {
        const spotAssignmentElement = event.srcElement;
        const oldSpotElement = spotAssignmentElement.parentElement.parentElement;

        const oldSpot = yardStore.spotsRef.value.find(spot => spot.id === oldSpotElement.id);
        const newSpot = yardStore.spotsRef.value.find(spot => spot.id === newSpotElement.id);

        if (
          spotAssignmentElement &&
          oldSpot &&
          newSpot &&
          yardStore.getSpotStatus(newSpot) === yardStore.spotStatus.OPEN
        ) {
          yardStore
            .reassignSpotAssignment(spotAssignmentElement.id, {
              spotId: newSpot.id,
              reason: 'Drag and Drop'
            })
            .then(spotAssignment => {
              if (spotAssignment?.spot?.id) {
                eventHub.$emit('spot-assignment-created', spotAssignment);
                yardStore.selectedSpotRef.value = newSpot;
              }
            });
        }
      }
    };

    onBeforeMount(() =>
      Object.keys(sockets.actions).forEach(operation =>
        eventHub.$on(`${operation}-Appointment`, updateAppointmentFromSubspace)
      )
    );

    onBeforeUnmount(() =>
      Object.keys(sockets.actions).forEach(operation =>
        eventHub.$off(`${operation}-Appointment`, updateAppointmentFromSubspace)
      )
    );

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      selectedSpot: yardStore.selectedSpotRef,
      handleSpotClick,
      handleDragEnter,
      handleDragLeave,
      isSpotAssigned: yardStore.isSpotAssigned,
      isSpotClosed: yardStore.isSpotClosed,
      isSpotOpen: yardStore.isSpotOpen,
      spotAssignment,
      appointment,
      isDragActive,
      isDragDisabled,
      isSpotSelected,
      onDragStart,
      onDragEnd,
      spotStatus: yardStore.spotStatus,
      getSpotStatus: yardStore.getSpotStatus,
      getSpotIcon: yardStore.getSpotIcon,
      spotType: yardStore.spotType,
      cardWidth: yardStore.spotCardWidth,
      cardHeight: yardStore.spotCardHeight,
      cardContentHeight: yardStore.spotCardHeight - 45
    };
  }
});
</script>
<style lang="scss" scoped>
.spot-title {
  border: 1px solid $color-neutral-60 !important;
  &:hover {
    border-color: $color-primary-60 !important;
  }
}
.spot-selected {
  border: 1px solid $color-primary-60 !important;
}
.drag-active {
  border: 2px dotted $color-primary-60 !important;
  transform: scale(1.1);
}
.drag-disabled {
  opacity: 0.5 !important;
  background-color: lightgrey !important;
}
.card-content {
  border-top: 4px solid $white !important;
}
.disabled-background {
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    30deg,
    $white,
    $white 4px,
    $color-neutral-20 4px,
    $color-neutral-20 8px
  );
}
</style>
