var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("phone-number-field", {
    attrs: {
      "single-line": "",
      validator: _vm.$validator,
      label: _vm.field.lable,
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }