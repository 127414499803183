<template>
  <dialog-base
    header=""
    hide-close-icon
    :key="renderKey"
    :loading="false"
    v-bind="[$props, $attrs]"
    loading-message="Updating Appointment Details ..."
    @close="close"
    @confirm="$refs.editAppointmentForm.updateAppointment()"
    max-width="100%"
    has-dialog-actions
    :value="showDialog"
    width="900px">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs">
        Edit Appointment
      </primary-button>
    </template>
    <template v-slot:body>
      <header class="px-4 pt-8">
        <v-btn icon @click="close" class="mobile-icon-btn"><v-icon>mdi-arrow-left</v-icon></v-btn>
      </header>
      <edit-appointment-form-mobile
        :appointment="appointment"
        ref="editAppointmentForm"
        @close="close"></edit-appointment-form-mobile>
    </template>
  </dialog-base>
</template>

<script>
import EditAppointmentDialogBase from '@/modules/appointments/components/edit/EditAppointmentDialogBase.vue';
export default {
  extends: EditAppointmentDialogBase,
  watch: {
    showDialog(newVal) {
      if (newVal) {
        this.$store.commit('App/setMobilePageTitle', 'Edit Details');
      } else {
        this.$store.commit('App/setMobilePageTitle', 'Appointment Details');
      }
    }
  }
};
</script>
