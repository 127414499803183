<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :header="`Features Setup for ${org.name}`"
    loading-message="Loading features..."
    width="800px"
    :loading="loading"
    max-width="100%"
    @close="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <template v-slot:body>
      <custom-forms-features-form
        :disabled="!loading"
        :org-id="org.id"
        :org-name="org.name"
        @loading="value => (loading = value)"
        @close="close"></custom-forms-features-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>
