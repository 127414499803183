var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row align-center mb-4 full-width" },
    [
      _vm.label
        ? _c("h3", { staticClass: "text--disabled mr-4" }, [
            _vm._v(_vm._s(_vm.label) + ":"),
          ])
        : _vm._e(),
      _c("v-autocomplete", {
        attrs: {
          "prepend-icon": "mdi-domain",
          items: _vm.orgs,
          "item-text": "name",
          dense: "",
          "item-value": "id",
          placeholder: "Select an Org",
        },
        on: { input: _vm.updateValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }