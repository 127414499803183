var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        width: "600",
        persistent: "",
        "data-testid": "yard-spot-form-generator-dialog",
      },
      on: { "click:outside": _vm.closeDialog },
    },
    [
      _vm.value
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(" Add Spots "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { grow: "" },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          attrs: {
                            "tab-value": _vm.tabs.SINGLE,
                            "data-testid": "yard-spot-form-single-tab",
                          },
                        },
                        [_vm._v("Single")]
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: {
                            "tab-value": _vm.tabs.MULTIPLE,
                            "data-testid": "yard-spot-form-multiple-tab",
                          },
                        },
                        [_vm._v(" Multiple ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: _vm.tabs.SINGLE } },
                        [
                          _c("yard-view-spot-generator-single-form", {
                            ref: "singleForm",
                            model: {
                              value: _vm.singleSpot,
                              callback: function ($$v) {
                                _vm.singleSpot = $$v
                              },
                              expression: "singleSpot",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: _vm.tabs.MULTIPLE } },
                        [
                          _c("yard-view-spot-generator-multiple-form", {
                            ref: "multipleForm",
                            model: {
                              value: _vm.multipleSpot,
                              callback: function ($$v) {
                                _vm.multipleSpot = $$v
                              },
                              expression: "multipleSpot",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "ma-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        "data-testid": "yard-spot-form-cancel-button",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" Nevermind ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-testid": "yard-spot-form-submit-button",
                        disabled: !_vm.isFormValid,
                        color: "primary",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Add ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }