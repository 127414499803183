/**
 * Mixin used in dialogs
 * @mixin
 * @displayName Dialog Mixin
 */
import renderMixin from '@satellite/components/mixins/renderMixin';

export default {
  mixins: [renderMixin],
  props: {
    /**
     * Display the dialog
     */
    showDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Flag to hide activator if external activator is being used to trigger dialog
     */
    externalActivator: {
      type: Boolean,
      required: false,
      default: false
    },
    cardClasses: {
      type: Array,
      default() {
        return [];
      }
    },
    disableActivator: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showConfirmDialog: false
    };
  },
  methods: {
    /**
     * Emits close event and rerenders component to reset data
     * @public
     */
    close() {
      /**
       * Emits close event
       * @event close
       */
      this.$emit('close');
      this.renderKey++;
    },
    open() {
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.$emit('confirm');
      this.close();
    },
    nevermind() {
      this.resolve(false);
      this.close();
    }
  }
};
