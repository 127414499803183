var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-column carrier-list-wrapper" },
    [
      _c(
        "v-card",
        { staticClass: "carrier-list-header", attrs: { outlined: "" } },
        [
          !_vm.novaCore.isInternalUser(_vm.$me)
            ? _c("carrier-contact-search")
            : _vm._e(),
          _c(
            "div",
            { staticClass: "carrier-list-create-wrapper" },
            [
              _c(
                "label",
                {
                  staticClass: "carrier-list-create-label",
                  attrs: { for: "carrier-list-create" },
                },
                [_vm._v(" Didn't find your carrier contact? ")]
              ),
              _c("create-carrier-dialog", {
                attrs: { "entry-point": "Carrier Contacts Page" },
                on: { save: _vm.handleSave },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "carrier-list-create",
                                attrs: {
                                  color: "primary",
                                  id: "carrier-list-create",
                                  "data-testid": "carrier-list-create",
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { left: "", size: "16" } }, [
                              _vm._v("mdi-plus"),
                            ]),
                            _vm._v(" CREATE CONTACT "),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("carrier-contact-data-table"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }