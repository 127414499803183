<template>
  <!--
    Triggered on input event
    @event input
    @property {string} value - Custom v-model
  -->
  <v-textarea
    ref="textarea"
    dense
    :value="value"
    @input="updateTextareaHeight"
    :class="{ required: required }"
    :required="required"
    validate-on-blur
    rows="1"
    auto-grow
    :style="{ height: textareaHeight }"
    v-on="$listeners"
    v-bind="[$props, $attrs]"></v-textarea>
</template>

<script>
/**
 * Auto-Expandable Text Area with additional error highlighting and styles
 * @displayName Auto Expandable Textarea
 */
export default {
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      textareaHeight: 'auto'
    };
  },
  methods: {
    updateTextareaHeight(content) {
      const minimumHeight = 48;
      // Reset the height to the minimum height so that the textarea will shrink if the user deletes content
      this.textareaHeight = `${minimumHeight}px`;
      // Set the height of the textarea based on its content
      this.$nextTick(() => {
        const textarea = this.$refs.textarea.$el.querySelector('textarea');
        const height =
          textarea.scrollHeight < minimumHeight ? `${minimumHeight}px` : textarea.scrollHeight;

        textarea.style.height = `${height}px`;
        this.textareaHeight = `${height}px`;
      });
      this.$emit('input', content);
    }
  }
};
</script>
