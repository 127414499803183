import main from '@/main.js';
import config from '@/config/config';
import notificationsHelper from '@satellite/helpers/notifications';
import { databaseActions } from '@satellite/../nova/core';
import { isObject, isUUID } from 'class-validator';
import { LAST_CRUD_ACTION_KEY } from '@satellite/plugins/util';

// TODO: Refactor so it lives in Vue instance rather than window?
class AxiosPlugin {
  static init(redirect401, uiApplicationName) {
    window.axios = require('axios');
    const axios = window.axios;
    axios.defaults.baseURL = config.neutron_url;
    axios.defaults.headers['X-UI-AppName'] = uiApplicationName;
    axios.defaults.headers['X-UI-Version'] = process.env.VUE_APP_NOVA_VERSION;

    axios.defaults.paramsSerializer = params =>
      Object.keys(params)
        .filter(key => params[key] !== undefined)
        .map(key => {
          if (isObject(params[key])) {
            params[key] = JSON.stringify(params[key]);
          }
          if (Array.isArray(params[key])) {
            return params[key].map(v => `${key}[]=${encodeURIComponent(v)}`).join('&');
          }
          return `${key}=${encodeURIComponent(params[key])}`;
        })
        .join('&');

    axios.interceptors.request.use(config => {
      main.$validator.clear();
      main.$root.$data.$globalLoading = true;
      return config;
    });

    // Response Interception
    axios.interceptors.response.use(
      response => {
        if (isObject(response?.data)) {
          const { entity, action, data } = response.data;

          const formattedEntityName = notificationsHelper.getEntityTerm(response.data);
          const crudAction = databaseActions[action]?.pastTense;

          // Global notification for succeeded WRITE actions
          if (formattedEntityName && crudAction && !response.config?.suppressNotification) {
            let message = `${formattedEntityName} has been ${crudAction}`;
            main.$root.notify(message, 'success');
          }

          // Store the last operation at session storage
          // So we can ignore the subspace message to avoid double requests
          if (isUUID(data?.id) && ['delete', 'create', 'update'].includes(action)) {
            sessionStorage.setItem(LAST_CRUD_ACTION_KEY, `${action}-${entity}:${data?.id}`);
          }
        }
        main.$root.$data.$globalLoading = false;
        return response;
      },
      error => {
        main.$root.$data.$globalLoading = false;

        // The request has returned no response, we have no context
        const hasErrorMessage = Boolean(error.response?.data?.message);
        let errorMessage = 'A network error occurred. If the issue persists please contact us.';

        if (hasErrorMessage) {
          errorMessage = Array.isArray(error.response.data.message)
            ? error.response.data.message.join(', ')
            : error.response.data.message;
        }

        errorMessage = main.novaCore.upperFirst(errorMessage);
        main.$validator.clear();
        if (error.response?.status === 401) {
          if (redirect401) {
            main.$store.dispatch('Auth/logout');
            if (main.$route.path !== redirect401) {
              window.location.replace(
                `${redirect401}?${window.location.pathname}&errorMessage=${errorMessage}`
              );
              return;
            }
          }
        }

        if (!error.config?.suppressNotification) {
          main.notify(errorMessage, 'error', { duration: 15000 });
        }

        return Promise.reject(error);
      }
    );
  }

  static setAccessToken(token) {
    window.axios.defaults.headers.Authorization = token ? `Bearer ${token}` : undefined;
  }
}

export default AxiosPlugin;
