<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Save Report Search"
    :scrollable="false"
    has-dialog-actions
    loading-message="Saving report search..."
    @close="closeDialog()"
    @confirm="submit">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" color="primary">
        <v-icon left>mdi-floppy</v-icon>
        {{ actionButtonText }}
      </v-btn>
    </template>
    <template #body>
      <form-base hide-required-legend>
        <template #form>
          <v-form ref="form" v-on:submit.prevent @keyup.native.enter="submit">
            <v-container class="px-6">
              <v-row>
                <v-col>
                  <text-field
                    :required="true"
                    type="text"
                    v-model="title"
                    :rules="$validator.rules.required('Report Search Title')"
                    label="Report Search Title"></text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template #form-actions>
          <action-group
            @cancel="closeDialog"
            confirm-icon="plus"
            :disable-confirm="!title"
            confirm-label="Save Report Search"
            @confirm="submit"></action-group>
        </template>
      </form-base>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    actionButtonText: {
      type: String,
      required: false,
      default: 'Save Search'
    }
  },
  data() {
    return {
      title: null
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$emit('save-report-search', { title: this.title });
      this.closeDialog();
    },
    closeDialog() {
      this.title = null;
      this.close();
    }
  }
};
</script>
