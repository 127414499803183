var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "d-flex align-center input" },
    [
      _c("v-switch", {
        attrs: {
          dense: "",
          height: "20px",
          color: "primary",
          label: _vm.label,
          "hide-details": "auto",
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm.field.required
        ? _c("span", { staticClass: "red--text required-bool-label" }, [
            _c("strong", [_vm._v("*")]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }