var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-x-hidden" }, [
    _c("div", {
      staticStyle: {
        "line-height": "initial !important",
        color: "black !important",
      },
      domProps: { innerHTML: _vm._s(_vm.notificationHtml) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }