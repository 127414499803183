<template>
  <v-select
    @input="updateValue"
    :value="value"
    :items="loadTypes"
    hide-details="auto"
    :item-text="item => getLoadTypeName(item)"
    item-value="id"
    v-bind="[$props, $attrs]"
    return-object
    clearable
    @click:clear="updateValue([])"
    :ref="selectRefKey">
    <template v-slot:prepend-item v-if="loadTypes.length && multiple && !disableSelectAll">
      <v-list-item ripple @click="toggleSelectAllItems">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider slot="prepend-item" class="mt-2" />
    </template>

    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0">{{ item.name }}</span>
      <span v-if="index === 1" class="grey--text text-caption ml-2">
        (+{{ value.length - 1 }} others)
      </span>
    </template>

    <template v-slot:append-item>
      <template>
        <select-limit-list-item
          v-if="isAtSelectionLimit"
          :limit="maximumSelections"></select-limit-list-item>
      </template>
    </template>
  </v-select>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';

export default {
  mixins: [selectMixin, selectAllSelectMixin],
  name: 'basicLoadTypeSelect',
  props: {
    /**
     * @model
     */
    value: {
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    warehouseIds: {
      type: Array,
      required: false,
      default: null
    },
    // Include to only show load types associated with these docks
    docks: {
      type: Array,
      required: false,
      default: null
    },
    includeOrgLoadTypes: {
      type: Boolean,
      required: false,
      default: true
    },
    showOnlyAssignedLoadTypes: {
      type: Boolean,
      required: false,
      default: false
    },
    fetchLoadTypesOnMount: {
      type: Boolean,
      required: false,
      default: true
    },
    showDirectionInName: {
      type: Boolean,
      required: false,
      default: false
    },
    selectAllOnFetch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    selectableItems() {
      return this.loadTypes;
    }
  },
  data() {
    return {
      selectRefKey: 'loadTypeSelect',
      loadTypes: []
    };
  },
  methods: {
    updateValue(loadTypes) {
      this.$emit(
        'input',
        loadTypes.filter(lt => lt.id ?? typeof lt === 'string')
      );
    },
    async setLoadTypes() {
      if (!this.warehouseIds?.length && !this.includeOrgLoadTypes) {
        return (this.loadTypes = []);
      }
      let loadTypes = [];

      const params = { s: { $or: [] } };

      if (this.includeOrgLoadTypes) {
        params.s.$or.push({ warehouseId: null });
      }

      if (this.warehouseIds?.length) {
        params.s.$or.push({ warehouseId: { $in: this.warehouseIds } });
        params.join = 'warehouse||name';
      }

      try {
        const response = await axios.get('/loadtype', { params });
        loadTypes = this.novaCore.sortBy(response.data.data, 'name');
      } catch (e) {
        loadTypes = [];
      }
      const loadTypeGroups = {};

      if (this.docks && this.showOnlyAssignedLoadTypes) {
        loadTypes = this.filterLoadTypesByDock(loadTypes);
      }

      // Sort org loadtypes to the bottom
      loadTypes.sort(
        (a, b) => (a.warehouseId === null) - (b.warehouseId === null) || +(a > b) || -(a < b)
      );

      // Group load types
      loadTypes.forEach(lt => {
        if (lt.warehouse) {
          if (loadTypeGroups[lt.warehouse.name.toLocaleUpperCase()]) {
            loadTypeGroups[lt.warehouse.name.toLocaleUpperCase()].push(lt);
          } else {
            loadTypeGroups[lt.warehouse.name.toLocaleUpperCase()] = [lt];
          }
        } else {
          if (loadTypeGroups['ORG LOAD TYPES']) {
            loadTypeGroups['ORG LOAD TYPES'].push(lt);
          } else {
            loadTypeGroups['ORG LOAD TYPES'] = [lt];
          }
        }
      });

      // Apply groups to select options
      this.loadTypes = [];
      Object.keys(loadTypeGroups).forEach(grpName => {
        this.loadTypes.push({ header: grpName });
        this.loadTypes.push(...loadTypeGroups[grpName]);
      });

      if (this.selectAllOnUpdate) {
        this.selectAllItems();
      }
    },

    getLoadTypeName(lt) {
      return this.showDirectionInName ? `${lt.name} - ${lt.direction}` : lt.name;
    },
    filterLoadTypesByDock(loadTypes) {
      let selectedDocksLoadTypeIds = [];
      this.docks.forEach(dock => {
        selectedDocksLoadTypeIds = [...selectedDocksLoadTypeIds, dock.loadTypeIds.flat()].flat();
      });

      return loadTypes.filter(lt => selectedDocksLoadTypeIds.includes(lt.id));
    }
  },
  async mounted() {
    if (this.fetchLoadTypesOnMount) {
      await this.setLoadTypes();
    }
  },
  watch: {
    async warehouseIds() {
      await this.setLoadTypes();
    },
    docks() {
      this.setLoadTypes();
    }
  }
};
</script>
