<template>
  <v-card v-if="ready" class="elevation-0">
    <v-card-text class="pa-1">
      <bar-chart
        title="Appointments by Load Type by Day of Week"
        :categories="categories"
        :stacked="true"
        :colors="colors"
        :show-data-labels="true"
        :data="chartData"></bar-chart>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
  <v-skeleton-loader v-else class="mx-auto" type="card"> </v-skeleton-loader>
</template>

<script>
import insightsMixin from '@/components/mixins/insightsMixin';

/**
 * Appointments By Load Type Bar Chart
 * @displayName Appointments By Load Type Bar Chart
 */
export default {
  mixins: [insightsMixin],
  data() {
    return {
      apiEndpoint: 'load-type',
      categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      useCurrentWeek: true
    };
  },
  methods: {
    formatData() {
      this.chartData = [];

      for (let loadType in this.rawData) {
        this.chartData.push({
          name: loadType,
          data: Object.values(this.rawData[loadType])
        });
      }

      this.ready = true;
    }
  }
};
</script>
