<template>
  <div>
    <v-row class="justify-end mr-1">
      <template v-for="(actionItem, actionName) in appointmentActions">
        <appointment-action-group-item-old
          v-if="!actionItem.isShowMoreOption"
          :key="`${renderKey}-${actionName}`"
          :disabled="actionItem.disabled"
          :disabled-message="actionItem.disabledMessage"
          :action-item="actionItem"
          :action-name="actionName"
          @click="handleClick"></appointment-action-group-item-old>
      </template>
      <v-menu
        offset-y
        :internal-activator="true"
        :close-on-click="true"
        v-if="isShowMoreMenuVisible">
        <template v-slot:activator="{ on: onMenu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="{ ...onTooltip, ...onMenu }"
                data-testid="appointmentShowMoreMenu">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <span>More Options</span>
          </v-tooltip>
        </template>
        <v-list>
          <template v-for="(actionItem, actionName) in appointmentActions">
            <v-list-item
              v-if="actionItem.isShowMoreOption"
              class="account-option"
              :key="`action-${actionName}`">
              <appointment-action-group-item-old
                :disabled="actionItem.disabled"
                :disabled-message="actionItem.disabledMessage"
                :action-item="actionItem"
                :action-name="actionName"
                @click="handleClick"></appointment-action-group-item-old>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </v-row>
    <share-appointment-dialog
      :external-activator="true"
      :show-dialog="showShareDialog"
      :appointment="appointment"
      @close="showShareDialog = false"></share-appointment-dialog>
    <create-recurring-series-dialog-old
      :external-activator="true"
      :show-dialog="showCreateRecurringSeriesDialog"
      :appointment="appointment"
      @close="showCreateRecurringSeriesDialog = false"></create-recurring-series-dialog-old>
    <delete-recurring-series-dialog
      :parent-appointment="recurringParentAppointment"
      :external-activator="true"
      :show-dialog="showDeleteRecurringSeriesDialog"
      :appointment="appointment"
      @close="showDeleteRecurringSeriesDialog = false"></delete-recurring-series-dialog>
    <reschedule-appointment-dialog-old
      :appointment-warehouse="appointmentWarehouse"
      :external-activator="true"
      :show-dialog="showRescheduleDialog"
      :appointment="appointment"
      @close="showRescheduleDialog = false"
      @appointment-updated="$emit('appointment-updated')"></reschedule-appointment-dialog-old>
    <edit-appointment-dialog
      :external-activator="true"
      :show-dialog="showEditDialog"
      :appointment="appointment"
      @appointment-updated="$emit('appointment-updated')"
      @close="showEditDialog = false"></edit-appointment-dialog>
    <cancel-appointment-dialog-old
      ref="cancelAppointment"
      @confirm="$emit('confirm')"
      :external-activator="true"
      :show-dialog="showConfirmDialog"
      :appointment="appointment"
      @close="showConfirmDialog = false"
      @appointment-updated="$emit('appointment-updated')"></cancel-appointment-dialog-old>
    <create-appointment-dialog
      :external-activator="true"
      :show-dialog="showCreateAppointmentDialog"
      @close="showCreateAppointmentDialog = false"
      :context="appointment"></create-appointment-dialog>
    <edit-reserve-dialog
      external-activator
      :reserve="appointment"
      @close="showEditReserveDialog = false"
      :show-dialog="showEditReserveDialog"></edit-reserve-dialog>
    <printable-appointment-details
      id="printable-visible-appointment-details"
      :settings="settings"
      :appointment="appointment"
      :custom-form-data="customFormData"
      v-if="!this.novaCore.isReserve(appointment)"></printable-appointment-details>
  </div>
</template>

<script>
import appointmentMixin from '@/components/mixins/appointmentMixin';
import recurringAppointmentMixin from '@/components/mixins/recurringAppointmentMixin';
import { isObject } from 'class-validator';

/**
 * @displayName Appointment Action Group
 */
export default {
  mixins: [appointmentMixin, recurringAppointmentMixin],
  props: {
    appointment: {
      type: Object,
      required: true
    },
    /**
     * Appointment actions to show
     * {icon: 'printer', action: 'open-dialog', show: true}
     */
    appointmentActions: {
      type: Object,
      required: true
    },
    appointmentWarehouse: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  computed: {
    isShowMoreMenuVisible() {
      return (
        isObject(this.appointmentActions) &&
        Object.values(this.appointmentActions)?.some(a => a?.isShowMoreOption)
      );
    },
    settings() {
      return this.warehouse.settings ?? {};
    }
  },
  data() {
    return {
      printing: false,
      showShareDialog: false,
      showRescheduleDialog: false,
      showEditDialog: false,
      showConfirmDialog: false,
      showCreateAppointmentDialog: false,
      showCreateRecurringSeriesDialog: false,
      showDeleteRecurringSeriesDialog: false,
      showEditReserveDialog: false,
      renderKey: 0,
      customFormData: []
    };
  },
  methods: {
    openShareDialog() {
      this.showShareDialog = true;
    },
    openCreateRecurringSeriesDialog() {
      this.showCreateRecurringSeriesDialog = true;
    },
    openDeleteRecurringSeriesDialog() {
      this.showDeleteRecurringSeriesDialog = true;
    },
    openCreateAppointmentDialogForClone() {
      this.mixpanel.track(this.mixpanel.events.ACTION.OPENED_CLONE_APPOINTMENT, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Appointment ID': this.appointment.id
      });
      this.showCreateAppointmentDialog = true;
    },
    openRescheduleDialog() {
      this.showRescheduleDialog = true;
    },
    openEditDialog() {
      this.showEditDialog = true;
    },
    openEditReserveDialog() {
      this.showEditReserveDialog = true;
    },
    async setCustomFormData() {
      if (!this.appointment) {
        return;
      }

      const response = await axios.get('custom-forms/form-data', {
        params: {
          s: {
            objectId: this.appointment.id,
            entityName: 'appointment'
          },
          limit: 1000
        }
      });
      this.customFormData = response?.data?.data || [];
    },
    async openPrintTab() {
      this.$nextTick(async () => {
        await this.setCustomFormData();

        let domClone = document
          .getElementById('printable-visible-appointment-details')
          .cloneNode(true);

        let $printContent = document.getElementById('print-content');
        if (!$printContent) {
          $printContent = document.createElement('section');
          $printContent.id = 'print-content';
          document.body.appendChild($printContent);
        }

        $printContent.innerHTML = '';
        $printContent.appendChild(domClone);
        window.print();
      });
    },
    handleClick(action) {
      this[action]();
    },
    async muteNotifications() {
      const confirmAction = this.appointment.muteNotifications ? 'ENABLE' : 'DISABLE';

      const confirmMessage = `Are you sure you want to ${confirmAction} future notifications for this appointment?`;

      const isConfirmed = await this.$confirm(confirmMessage, { color: 'warning' });
      if (isConfirmed) {
        const response = await axios.patch(`appointment/${this.appointment.id}`, {
          muteNotifications: !this.appointment.muteNotifications
        });

        if (response?.data) {
          this.appointment.muteNotifications = !this.appointment.muteNotifications;
        }
      }
    }
  },
  watch: {
    appointmentActions: {
      handler() {
        this.renderKey++;
      },
      deep: true
    }
  }
};
</script>
