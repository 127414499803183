var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    attrs: {
      "data-testid": "yard-view-spot-select",
      outlined: "",
      items: _vm.spotsByArea,
      "item-value": "id",
      "item-text": "code",
      placeholder: "Select a spot",
      "return-object": false,
      dense: "",
      clearable: false,
      "hide-details": "",
    },
    on: {
      "update:search-input": ($e) => (_vm.searchTerm = $e),
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "no-data",
        fn: function () {
          return [
            _c(
              "v-list-item",
              [
                _c("v-list-item-title", [
                  _c("span", { staticClass: "typography-body-sm" }, [
                    _vm._v("No spots available"),
                  ]),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "v-list-item-content",
              [
                _c(
                  "v-list-item-title",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { "x-small": "" } },
                      [_vm._v(_vm._s(item.icon))]
                    ),
                    _c("span", [_vm._v(_vm._s(item.code))]),
                    _vm.searchTerm
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-regular text-uppercase ml-2",
                          },
                          [_vm._v(" (" + _vm._s(item.group) + ") ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }