<template>
  <div>
    <v-form ref="form" id="dock-dialog-form" class="pb-5">
      <v-row>
        <v-col cols="6" xs="12">
          <text-field
            disabled
            :readonly="!$rolePermissions.canCreateDock"
            :required="true"
            class="mb-0"
            type="text"
            hide-details="auto"
            v-model="internalDock.name"
            prepend-icon="mdi-warehouse"
            :rules="$validator.rules.required('Dock Name')"
            label="Dock Name"></text-field>
        </v-col>
      </v-row>
      <v-row class="mt-7">
        <v-col>
          <load-type-select
            select-all
            readonly
            :selected-warehouse="warehouse"
            v-model="internalDock.loadTypeIds"
            :return-object="false"
            :visible-selection-count="3"
            :clearable="$rolePermissions.canCreateDock"
            :required="true"
            :rules="$validator.rules.selectAtLeast(1, 'Load Type')"
            :multi-select="true"></load-type-select>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col>
        <h2 class="mt-2 py-0 black--text">Dock Schedule</h2>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <caution-message class="pl-3 mb-4">
        Make sure you understand the
        <strong>implications</strong>
        of having
        <strong>different schedules</strong>
        for Capacity Docks before proceeding.
      </caution-message>
    </v-row>
    <v-row>
      <v-btn-toggle
        mandatory
        v-model="view"
        dense
        class="secondary-button-group ml-3 mt-4 interval-buttons">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div class="d-inline" v-on="on">
              <v-btn
                class="rounded-tr-0 rounded-br-0"
                elevation="0"
                small
                :value="viewTypes.schedule">
                <v-icon small class="mr-2">mdi-calendar</v-icon>
                Weekly Schedule
              </v-btn>
            </div>
          </template>
          <span>View the weekly schedule</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div class="d-inline" v-on="on">
              <v-btn elevation="0" small :value="viewTypes.closedDates">
                <v-icon small class="mr-2">mdi-calendar-remove</v-icon>
                Closed Dates
              </v-btn>
            </div>
          </template>
          <span>View the closed dates</span>
        </v-tooltip>
      </v-btn-toggle>
    </v-row>
    <template v-if="warehouse.id">
      <v-row :class="{ 'visually-remove': view === viewTypes.closedDates }">
        <v-col class="mt-2">
          <template v-if="$rolePermissions.canCreateDock">
            <time-select-grid
              :settings-entity="warehouse"
              :enabled-intervals="warehouse.schedule"
              ref="timeSelect"
              :toggle-to-edit="false"
              :local-storage-key="internalDock.id"
              compact
              :value="internalDock.schedule"
              :docks="warehouse.docks"
              @update-interval="val => (this.interval = val)"
              @set-schedule-default="setScheduleToDefault"
              @set-schedule-closed="clearDockSchedule"
              @copy-warehouse-schedule="copyWarehouseScheduleToDock"
              @copy-dock-schedule="copyDockScheduleToDock"></time-select-grid>
          </template>

          <template v-else>
            <div class="mt-3 mb-4 text-h6 black--text mt-3">Hours of Operation</div>
            <hours-of-operation-list
              v-if="internalDock.schedule && warehouse.timezone"
              :schedule="internalDock.schedule"
              :timezone="warehouse.timezone"></hours-of-operation-list>
          </template>
        </v-col>
      </v-row>

      <v-row :class="{ 'visually-remove': view === viewTypes.schedule }">
        <v-col class="mt-3">
          <days-off
            :schedule="internalDock.schedule"
            :warehouse="warehouse"
            @update-closed-intervals="updateClosedIntervals"></days-off>
        </v-col>
      </v-row>
    </template>

    <action-group
      v-if="$rolePermissions.canCreateDock"
      @cancel="$emit('close')"
      confirm-icon="upload"
      confirm-label="Save Dock"
      cancel-label="Nevermind"
      @confirm="submit"></action-group>
  </div>
</template>

<script>
import dockScheduleMixin from '@satellite/components/mixins/dockScheduleMixin';

/**
 * Create/Edit Dock Form
 * @displayName Dock Form
 */
export default {
  name: 'CapacityDockForm',
  mixins: [dockScheduleMixin],
  props: {
    /**
     * Dock object
     */
    dock: {
      type: Object,
      required: false
    },
    /**
     * Position of the dock being created - not used when editing a dock
     */
    sortOrder: {
      type: Number,
      required: false
    },
    /**
     * Warehouse ID
     */
    warehouseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      minLeadTimeInitialized: false,
      maxLeadTimeInitialized: false,
      maxLeadTimeInDays: null,
      internalDock: {},
      warehouse: {},
      enableMinLeadTime: false,
      enableMaxLeadTime: false,
      formInitialized: false,
      showOverbookWarningDialog: false,
      interval: 30,
      closedIntervals: []
    };
  },
  computed: {
    ccEmailsStr() {
      return this.internalDock.ccEmails ? this.internalDock.ccEmails.join() : '';
    }
  },
  methods: {
    /**
     * Submit form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      let response = null;

      if (!this.$refs.form.validate() || !this.$refs.timeSelect) {
        return;
      }

      this.$refs.timeSelect.saveChanges();
      const data = {
        ...this.internalDock,
        ...{
          schedule: {
            ...this.$refs.timeSelect.internalSchedule,
            closedIntervals: this.closedIntervals
          }
        }
      };
      Object.keys(data).forEach(
        key => this.novaCore.ALLOWABLE_CHILD_DOCK_FIELDS.includes(key) || delete data[key]
      );

      response = await this.$store.dispatch('Docks/updateDock', data);

      if (response?.data) {
        this.setDirtyStatus(false);
        this.mixpanel.track(
          this.internalDock.id
            ? this.mixpanel.events.MODULE.DOCK.UPDATED
            : this.mixpanel.events.MODULE.DOCK.CREATED,
          {
            'Dock ID': response.data.id,
            'Dock Name': response.data.name,
            'Warehouse ID': this.warehouse.id,
            'Warehouse Name': this.warehouse.name,
            'Org ID': this.$org.id,
            'Org Name': this.$org.name
          }
        );
        this.$nextTick(() => {
          this.$emit('saved');
        });
      }
    },
    /**
     * Removes any loadTypeIds that are present on the dock, but not in the database.
     * @public
     */
    async removeMissingLoadTypesFromDock() {
      const loadTypes = this.novaCore.pluck(
        await this.$store.dispatch('LoadTypes/getLoadTypes'),
        'id'
      );
      this.internalDock.loadTypeIds = this.internalDock.loadTypeIds.filter(loadTypeId =>
        loadTypes.includes(loadTypeId)
      );
    },
    addEventListeners() {
      document.querySelectorAll('.disable-label-click').forEach(element => {
        element.querySelector('label').addEventListener('click', this.clickHandler);
      });
    },
    // Using named function here allows the browser to discard exact duplicate event listeners automatically
    clickHandler(e) {
      e.stopPropagation();
    },
    updateClosedIntervals(closedIntervals) {
      this.closedIntervals = closedIntervals;
    }
  },
  async mounted() {
    const dock = this.dock || {
      schedule: {},
      allowCarrierScheduling: true,
      sortOrder: this.sortOrder || null
    };
    this.internalDock = this.novaCore.deepClone(dock);
    this.warehouse = await this.$store.dispatch('Warehouses/getWarehouseById', this.warehouseId);
    this.enableMinLeadTime = Boolean(this.internalDock.minCarrierLeadTime_hr);
    this.enableMaxLeadTime = Boolean(this.internalDock.maxCarrierLeadTime_hr);
    if (this.enableMaxLeadTime) {
      this.maxLeadTimeInDays = this.internalDock.maxCarrierLeadTime_hr / 24;
    }
    this.addEventListeners();
    setTimeout(() => {
      this.formInitialized = true;
    }, 1000);

    const lsInterval = localStorage.getItem(`scheduleInterval-${this.internalDock.id}`);
    if (lsInterval) {
      this.interval = parseInt(lsInterval);
    }
  },
  watch: {
    enableMinLeadTime(value) {
      if (value && this.mounted) {
        this.$nextTick(() => {
          this.$refs.minleadtimehours.$el.querySelector('input').focus();
        });
      }
      this.minLeadTimeInitialized = true;
    },
    internalDock: {
      handler() {
        this.setDirtyStatus(this.formInitialized);
      },
      deep: true
    },
    'internalDock.allowCarrierScheduling'(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.addEventListeners();
        });
      }
    },
    closedIntervals: {
      handler() {
        this.setDirtyStatus(this.formInitialized);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
#overbooking-container {
  .v-input__prepend-outer {
    display: flex;
    align-self: center;
  }
}
</style>
