var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", [_vm._v("Timeline of updates made to the appointment:")]),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "3" } }),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("audit-log-appointment", {
                attrs: {
                  "object-id": _vm.appointment.id,
                  org: _vm.$org,
                  "entity-obj": _vm.appointment,
                  entity: "appointment",
                },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "3" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }