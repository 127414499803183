<template>
  <v-dialog max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <icon-tooltip-button
        :icon-class="iconClass"
        :tooltip="tooltipText"
        size="large"
        v-bind="attrs"
        v-on="on"
        icon="mdi-chart-pie">
      </icon-tooltip-button>
    </template>
    <template v-slot:default="dialog">
      <v-card data-testid="pie-chart-dialog">
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn class="pa-3" icon light @click="dialog.value = false"
            ><v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <pie-chart v-bind="[$props, $attrs]"></pie-chart>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Close </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogTitle: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: false
    },
    colors: {
      type: Array,
      required: false
    },
    totalLabel: {
      type: String,
      required: false
    },
    totalValue: {
      type: String,
      required: false
    },
    iconColor: {
      type: String,
      required: false,
      default: 'secondary'
    },
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    tooltipText: {
      type: String,
      required: false,
      default: 'View pie chart'
    }
  }
};
</script>
