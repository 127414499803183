<template>
  <div class="flex-grow-1">
    <template v-if="readOnly">
      <div class="mt-3 font-size-x-small">Tags</div>
      <div class="d-flex flex-wrap mt-3">
        <v-chip
          :color="'dark-gray'"
          v-for="(tag, index) in value"
          :key="`tag-${tag}`"
          class="mb-2"
          :class="{ 'mr-2': index + 1 < value.length }">
          <span class="pr-2">{{ tag }}</span>
        </v-chip>
      </div>
    </template>
    <v-combobox
      v-else
      :key="renderKey"
      :multiple="multiple"
      :value="value"
      :delimiters="[',']"
      @input="emitInput"
      :label="label"
      v-bind="[$attrs, $props]"
      append-icon
      :items="customTags"
      hide-selected
      item-text="name"
      item-value="name"
      :return-object="false"
      :chips="hasChips"
      persistent-placeholder
      :hide-details="hideInstructions"
      :placeholder="placeholder"
      class="tag-input stack-selections label-text">
      <template v-slot:selection="{ attrs, item }">
        <template v-if="!hideSelectedTags">
          <v-chip
            v-bind="$attrs"
            :color="tagColor"
            label
            :outlined="outlinedChips"
            v-if="!util.getCustomTagData($org.customTags, item)">
            <span class="pr-2">{{ item }}</span>
            <v-icon small v-if="allowTagDelete" @click="removeTag(item)">mdi-close</v-icon>
          </v-chip>
          <v-chip
            v-bind="$attrs"
            :color="util.getCustomTagData($org.customTags, item).color"
            :outlined="false"
            :text-color="util.getCustomTagData($org.customTags, item).textColor"
            label
            v-else>
            <span class="pr-2">{{ util.getCustomTagData($org.customTags, item).name }}</span>
            <v-icon small v-if="allowTagDelete" @click="removeTag(item)">mdi-close</v-icon>
          </v-chip>
        </template>
      </template>

      <template v-slot:no-data>
        <v-list-item class="input-instructions">
          <v-list-item-content>
            <v-list-item-title>
              <span v-html="instructions"></span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:item="{ item }">
        <v-chip :color="`${item.color}`" :text-color="`${item.textColor}`" label>
          {{ item.name }}
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import TagManagerBase from '@satellite/components/elements/TagManagerBase.vue';
/**
 * @displayName Tag Manager
 */
export default {
  extends: TagManagerBase
};
</script>
