var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-space-between px-2 py-4 align-center flex-wrap",
          attrs: { id: "appointment-results-list-header-mobile" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "nav-arrow-btn mr-4 mobile-icon-btn",
              attrs: { icon: "", "data-testid": "back-button-search" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
            1
          ),
          _c(
            "div",
            { staticClass: "flex-grow-1 is-relative" },
            [
              _c("v-text-field", {
                staticClass: "appended-inner-icon",
                attrs: {
                  autofocus: "",
                  label: "Search Appointments",
                  "hide-details": "auto",
                  outlined: "",
                  dense: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchStr,
                  callback: function ($$v) {
                    _vm.searchStr = $$v
                  },
                  expression: "searchStr",
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "appended-inner-icon mobile-icon-btn",
                  attrs: {
                    icon: "",
                    ripple: false,
                    "data-testid": "search-button-mobile",
                  },
                  on: { click: _vm.search },
                },
                [_c("v-icon", [_vm._v("mdi-magnify")])],
                1
              ),
            ],
            1
          ),
          !_vm.hasSearched
            ? _c("span", { staticClass: "flex-basis-100 px-2 pt-2" }, [
                _vm._v(
                  " Search by Carrier, Dock, Tags, Confirmation #, or Reference # "
                ),
              ])
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex align-center justify-space-between flex-basis-100 px-2 pt-2",
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.totalResults) + " results")]),
                  _c("date-picker", {
                    attrs: {
                      "no-title": false,
                      "date-picker-wrapper-class": "centered",
                      color: "#015A86",
                      "external-trigger": "",
                      format: "MMM D, YYYY",
                      height: "35",
                      "no-input-mode": "",
                      "is-range": "",
                      "single-line": "",
                      "hide-details": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "external-trigger",
                        fn: function ({ openDatePicker }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { elevation: "0" },
                                on: { click: openDatePicker },
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.dateRangeLabel) + " "),
                                _c("v-icon", { staticClass: "ml-2" }, [
                                  _vm._v("mdi-menu-down"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.dateRangeInput,
                      callback: function ($$v) {
                        _vm.dateRangeInput = $$v
                      },
                      expression: "dateRangeInput",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-4" },
        [
          _vm.isLoading && _vm.isFirstPage
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-center justify-center pt-8",
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                    _c("span", [_vm._v("Searching Appointments...")]),
                  ],
                  1
                ),
              ]
            : [
                _vm.searchStr
                  ? [
                      _vm.hasSearched && _vm.events.length === 0
                        ? [
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-8 flex-column align-center",
                                attrs: {
                                  "no-gutters": "",
                                  "data-testid": "no-results-message-container",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2 primary--text mb-3",
                                    attrs: { "x-large": "" },
                                  },
                                  [_vm._v("mdi-arrow-up")]
                                ),
                                _c(
                                  "strong",
                                  { staticClass: "no-results-message" },
                                  [
                                    _vm._v(
                                      "We couldn't find results for your search"
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "px-4 text-center pt-3" },
                                  [
                                    _vm._v(
                                      " You can try choosing another date range at the top toolbar "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _vm._l(
                              _vm.eventsByDate,
                              function (events, date, index) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: `event-list-${_vm.novaCore.toKebabCase(
                                        date
                                      )}-${index}`,
                                    },
                                    [
                                      _c("h3", { staticClass: "pt-4 mb-3" }, [
                                        _vm._v(_vm._s(date)),
                                      ]),
                                      _vm._l(events, function (event) {
                                        return _c(
                                          "v-row",
                                          {
                                            key: `event-${event.id}`,
                                            staticClass: "mb-4",
                                            attrs: { "no-gutters": "" },
                                          },
                                          [
                                            _c("v-col", [
                                              _c(
                                                "article",
                                                {
                                                  staticClass:
                                                    "mobile-event-tile",
                                                  class: [
                                                    `is-${event.status.toLowerCase()}`,
                                                  ],
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openDetailsDialog(
                                                        event.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center justify-space-between",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tile-header",
                                                        },
                                                        [
                                                          _c(
                                                            "strong",
                                                            {
                                                              staticClass:
                                                                "mr-4",
                                                              attrs: {
                                                                "data-timestamp":
                                                                  event.start,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getEventStart(
                                                                      event
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "company-name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  event.companyName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-size-x-small rounded pa-1 white--text font-weight-bold current-status",
                                                          class: [
                                                            event.status.toLowerCase(),
                                                          ],
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                event.status
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center mt-2 font-size-x-small",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "third-width",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-block",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Confirmation"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-block appointment-confirmation-number",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    event.confirmationNumber
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "third-width",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-block",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Reference"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("span", {
                                                            staticClass:
                                                              "d-block",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.getRowVal(
                                                                  event.refNumber
                                                                )
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "third-width",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-block",
                                                            },
                                                            [_vm._v("Dock")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-block",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  event.dock
                                                                    .name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              }
                            ),
                            _vm.shouldShowLoadMoreButton
                              ? _c(
                                  "outline-button",
                                  {
                                    attrs: {
                                      "data-testid": "load-more-button",
                                      block: "",
                                      loading: _vm.isLoading,
                                    },
                                    on: { click: _vm.loadMoreAppointments },
                                  },
                                  [_vm._v(" Load More Results ")]
                                )
                              : [
                                  _vm.events.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "d-flex align-start" },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [_vm._v("mdi-list-status")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "These are all results for your search."
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  "You can try choosing another date range at the top toolbar."
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                          ],
                    ]
                  : _vm._e(),
              ],
          _c("appointment-details-dialog-mobile", {
            attrs: {
              "content-class": "mobile-dialog details-dialog-mobile",
              fullscreen: "",
              transition: "dialog-bottom-transition",
              "external-activator": "",
              "show-dialog": _vm.showDetailsDialog,
              appointment: _vm.$selectedEvent,
            },
            on: {
              close: function ($event) {
                _vm.showDetailsDialog = false
              },
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }