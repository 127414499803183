var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: { header: "Edit Warehouse coordinates" },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _vm._e(),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.warehouse.name))]),
                      _c("address-display", {
                        attrs: {
                          label: null,
                          zip: _vm.warehouse.zip,
                          city: _vm.warehouse.city,
                          state: _vm.warehouse.state,
                          street: _vm.warehouse.street,
                          country: _vm.warehouse.country,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("form-base", {
                    scopedSlots: _vm._u(
                      [
                        _vm.geolocation
                          ? {
                              key: "form",
                              fn: function () {
                                return [
                                  _c(
                                    "v-form",
                                    { ref: "form" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          label: "Latitude",
                                          rules: [
                                            (v) =>
                                              (v >= -90 && v <= 90) ||
                                              "Enter a valid latitude from -90 to 90.",
                                          ],
                                          placeholder:
                                            "Enter a valid latitude from -90 to 90.",
                                        },
                                        model: {
                                          value: _vm.geolocation.latitude,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.geolocation,
                                              "latitude",
                                              $$v
                                            )
                                          },
                                          expression: "geolocation.latitude",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          label: "Longitude",
                                          rules: [
                                            (v) =>
                                              (v >= -180 && v <= 180) ||
                                              "Enter a valid longitude from -180 to 180.",
                                          ],
                                          placeholder:
                                            "Enter a valid longitude from -180 to 180.",
                                        },
                                        model: {
                                          value: _vm.geolocation.longitude,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.geolocation,
                                              "longitude",
                                              $$v
                                            )
                                          },
                                          expression: "geolocation.longitude",
                                        },
                                      }),
                                      _c("div", { staticClass: "my-6" }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "form-actions",
                          fn: function () {
                            return [
                              _c("action-group", {
                                attrs: {
                                  "confirm-icon": "update",
                                  "confirm-label": "save",
                                  loading: _vm.loading,
                                },
                                on: {
                                  cancel: function ($event) {
                                    return _vm.close()
                                  },
                                  confirm: _vm.submit,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "additional-actions",
                                    fn: function () {
                                      return [
                                        _vm.warehouse.geolocation
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  color: "red",
                                                },
                                                on: { click: _vm.disable },
                                              },
                                              [_vm._v(" Disable Check-in ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }