<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    :key="renderKey"
    header="Create new field"
    loading-message="Saving..."
    :scrollable="false"
    @close="close">
    <template v-slot:body>
      <custom-forms-field-form
        :field="field"
        :label-suggestion="labelSuggestion"
        @save="handleSaveEvent"
        @close="close"></custom-forms-field-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
export default {
  mixins: [dialogMixin],
  props: {
    field: {
      type: Object,
      required: false
    },
    labelSuggestion: {
      type: String,
      required: false
    }
  },
  methods: {
    async handleSaveEvent(data) {
      let response = {};

      const existingFieldId = this.field?.id;

      try {
        if (existingFieldId) {
          response = await axios.patch(`custom-forms/field/${existingFieldId}`, data);
        } else {
          response = await axios.post(`custom-forms/field`, data);
        }

        if (response?.data?.data) {
          this.mixpanel?.track(
            this.mixpanel.events.MODULE.CUSTOM_FORMS[
              existingFieldId ? 'EDIT_FIELD' : 'CREATE_FIELD'
            ],
            {
              'Field Label': data.label,
              'Field Type': data.type,
              'Field Description': data.description,
              'Field Placeholder': data.placeholder,
              'Org ID': this.$org.id,
              'Org Name': this.$org.name
            }
          );

          this.notify(`Field has been ${this.field ? 'updated' : 'created'}`, 'success');
          this.$eventHub.$emit(
            existingFieldId ? 'update-Field' : 'create-Field',
            response.data.data
          );
          this.$emit('save', response.data.data);
        }
      } finally {
        this.close();
      }
    }
  }
};
</script>
