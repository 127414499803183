var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-base",
    {
      attrs: { "hide-required-legend": true },
      scopedSlots: _vm._u(
        [
          _vm.shouldDisplayActions
            ? {
                key: "form-actions",
                fn: function () {
                  return [
                    _c("action-group", {
                      attrs: {
                        "confirm-label": "Save",
                        "hide-cancel": "",
                        loading: _vm.isLoading,
                        "is-spacer-visible": false,
                      },
                      on: { confirm: _vm.updateAppointment },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "additional-actions",
                            fn: function () {
                              return [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      text: "",
                                      disabled: _vm.isLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("close")
                                      },
                                    },
                                  },
                                  [_vm._v("Nevermind")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1242176551
                      ),
                    }),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _c(
        "template",
        { slot: "form" },
        [
          _c("v-form", { ref: "form", staticClass: "pt-6" }, [
            _c("div", { staticClass: "time-input" }, [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("date-picker", {
                    staticClass: "timestamp-date-picker",
                    attrs: {
                      placeholder: "Select date",
                      "compact-display": "",
                      outlined: "",
                      "no-margins": "",
                      "display-field-label": false,
                      "display-field-icon": false,
                      required: "",
                    },
                    model: {
                      value: _vm.customDate,
                      callback: function ($$v) {
                        _vm.customDate = $$v
                      },
                      expression: "customDate",
                    },
                  }),
                  _c("time-entry", {
                    staticClass: "ml-2",
                    attrs: {
                      outlined: "",
                      "hide-timezone": "",
                      warehouse: _vm.appointment.dock.warehouse,
                    },
                    model: {
                      value: _vm.customTime,
                      callback: function ($$v) {
                        _vm.customTime = $$v
                      },
                      expression: "customTime",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex mt-4" }, [
                _c(
                  "div",
                  { staticClass: "menu-container full-width" },
                  [
                    _c(
                      "v-btn-toggle",
                      {
                        staticClass: "full-width",
                        attrs: { color: "primary" },
                        on: { change: _vm.adjustTime },
                      },
                      _vm._l(
                        _vm.timeAdjustmentOptions,
                        function (option, index) {
                          return _c(
                            "v-btn",
                            {
                              key: `${index}-timeline-option`,
                              staticClass: "flex-grow-1",
                              attrs: { dense: "", small: "", outlined: "" },
                            },
                            [_vm._v(" " + _vm._s(option.label) + " ")]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }