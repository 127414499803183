<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Invite a User to Your Org"
    :scrollable="false"
    loading-message="Inviting User..."
    @close="close"
    @confirm="$refs.inviteUserForm.submit()">
    <template #activator="{ on, attrs }" v-if="!externalActivator">
      <primary-button :before-icon="beforeIcon" v-on="on" v-bind="attrs" large class="mr-2">
        {{ buttonLabel }}
      </primary-button>
    </template>
    <template #body>
      <invite-user-form
        :post-submission-route-name="postSubmissionRouteName"
        @saved="data => $emit('saved', data)"
        @close="close"
        ref="inviteUserForm"></invite-user-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for invite user form
 * @displayName Invite User Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    beforeIcon: {
      type: String,
      required: false,
      default: 'plus'
    },
    buttonLabel: {
      type: String,
      required: false,
      default: 'Invite User'
    },
    postSubmissionRouteName: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>
