var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.customFieldsExist
    ? _c(
        "div",
        { class: _vm.containerClasses },
        _vm._l(_vm.customFields, function (field) {
          return _c(
            "div",
            {
              key: field.name,
              class: [{ row: _vm.isInline }, ..._vm.fieldClasses],
            },
            [
              field.label
                ? _c(
                    "h3",
                    [
                      _vm._v(" " + _vm._s(field.label) + ": "),
                      field.description
                        ? _c("help-icon-tooltip", [
                            _vm._v(_vm._s(field.description)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(`${_vm.customFieldComponents[field.type]}-old`, {
                tag: "component",
                attrs: {
                  "single-line": "",
                  field: field,
                  warehouse: _vm.warehouse,
                },
                model: {
                  value: field.value,
                  callback: function ($$v) {
                    _vm.$set(field, "value", $$v)
                  },
                  expression: "field.value",
                },
              }),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }