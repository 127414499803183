var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("h1", { staticClass: "mb-8" }, [_vm._v("Live Yard View")]),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "h3",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          icon: "",
                          small: "",
                          rounded: "",
                          "data-testid": "yard-area-form-back",
                        },
                        on: { click: _vm.back },
                      },
                      [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                      1
                    ),
                    _vm._v(" " + _vm._s(_vm.selectedWarehouse?.name) + " "),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card",
                { staticClass: "mt-4", attrs: { elevation: "1" } },
                [
                  _c(
                    "div",
                    { staticClass: "pa-6" },
                    [
                      _vm.isEditing || !_vm.defaultSpotAreaId
                        ? _c("yard-view-spot-area-form", {
                            attrs: { "spot-area": _vm.spotArea },
                          })
                        : [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { cols: "8" } }, [
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "py-1 pt-2",
                                      attrs: {
                                        "data-testid":
                                          "yard-spot-area-area-name",
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.spotArea.name)),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex justify-end",
                                    attrs: { cols: "4" },
                                  },
                                  [
                                    _vm.spots.length > 0
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              title: "Select all spots",
                                              small: "",
                                              text: "",
                                              "data-testid":
                                                "yard-spot-area-select-all-button",
                                            },
                                            on: { click: _vm.selectAllSpots },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { small: "" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.areAllSpotsSelected
                                                        ? "mdi-selection-off"
                                                        : "mdi-select-all"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.areAllSpotsSelected
                                                    ? "Unselect"
                                                    : "Select"
                                                ) +
                                                " All Spots "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.selectedSpot?.id &&
                                    !_vm.selectedSpots?.length
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              title: "Update area name",
                                              small: "",
                                              text: "",
                                              "data-testid":
                                                "yard-spot-area-edit-button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.isEditing = true
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("mdi-pencil")]
                                            ),
                                            _vm._v(" Rename Area "),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.selectedSpot?.id &&
                                    !_vm.selectedSpots?.length
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              title: "Delete area and spots",
                                              small: "",
                                              text: "",
                                              color: "red",
                                              "data-testid":
                                                "yard-spot-area-delete-button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.isDeleteConfirmationVisible = true
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("mdi-delete")]
                                            ),
                                            _vm._v(" Delete Area "),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                  _c("v-divider"),
                  _vm.defaultSpotAreaId
                    ? _c("yard-view-spot-area", {
                        staticClass: "pa-5",
                        attrs: { editable: true, "spot-area": _vm.spotArea },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-navigation-drawer",
            { attrs: { app: "", right: "", permanent: "" } },
            [
              _c(
                "transition",
                { attrs: { name: "slide-fade", mode: "in-out" } },
                [
                  _vm.selectedSpot?.id
                    ? _c("yard-view-spot-form", {
                        staticClass: "pl-4",
                        attrs: { spot: _vm.selectedSpot },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.isLoading &&
              !_vm.selectedSpot?.id &&
              !_vm.selectedSpots?.length
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-center full-height pa-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column text-center align-center justify-center",
                        },
                        [
                          _c("svg-select-spots", { staticClass: "mb-6" }),
                          _c("p", [
                            _vm._v(" Hold "),
                            _c("strong", [_vm._v("Shift")]),
                            _vm._v(
                              " in your keyboard to select multiple spots. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isLoading && _vm.selectedSpots?.length > 0
                ? _c("yard-view-spot-area-multi-selected", {
                    staticClass: "pl-4",
                    on: {
                      clear: function ($event) {
                        _vm.selectedSpots = []
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isLoading ? _c("yard-view-loading") : _vm._e(),
      _vm.isDeleteConfirmationVisible
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.isDeleteConfirmationVisible,
              "delete-confirmation": "",
              "show-delete-warning": "",
              persistent: "",
              title: "Delete this Area and its Spots?",
              "entity-name": _vm.spotArea.name,
              "button-true-text": "YES, DELETE",
              "button-true-color": "error",
              width: 650,
            },
            on: { result: _vm.handleDeleteConfirmationResult },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }