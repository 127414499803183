var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("date-picker", {
    staticClass: "ml-0",
    attrs: {
      dense: "",
      height: "20px",
      required: _vm.field.required,
      "no-title": "",
      "compact-display": "",
      "display-field-label": true,
      "display-field-icon": false,
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }