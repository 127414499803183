/**
 * @typedef {Object} User
 * @property {string[]|null} [warehouseAccessList] - the users warehouse access list
 */

/**
 * @typedef CarrierContact
 * @property {null|string[]} favoriteWarehouseIds
 */

/**
 * Derive a nullable value or it's default.
 * If value is null, then return null.
 * If value is non-null & non-falsy return value.
 * Else return default value.
 * @param {any|null} value
 * @param {any} defaultValue
 * @returns {any|null}
 */
function getNullableOrDefault(value, defaultValue) {
  return value === null ? null : value ?? defaultValue;
}

/**
 * Verify if an user has restricted warehouse access.
 * @param {User} [user]
 * @returns {boolean} - Whether the user has restricted warehouse access
 */
export function isUserWarehouseRestricted(user) {
  return user?.warehouseAccessList !== null;
}

/**
 * Verify if an user has permission to manage a carrier contact.
 * @param {User} [user]
 * @param {CarrierContact} [carrierContact]
 * @returns {boolean} - Whether the user can manage the contact
 */
export function canManageCarrierContact(user, carrierContact) {
  const userWarehouses = getNullableOrDefault(user?.warehouseAccessList, []);
  const contactWarehouses = getNullableOrDefault(carrierContact?.favoriteWarehouseIds, []);

  if (!isUserWarehouseRestricted(user)) {
    return true;
  }

  if (contactWarehouses === null) {
    return false;
  }

  return contactWarehouses?.every(warehouseId => userWarehouses.includes(warehouseId));
}
