var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dialog-base",
        _vm._b(
          {
            key: _vm.renderKey,
            staticClass: "mx-auto",
            attrs: {
              header: "Carrier email subscribers",
              loading: _vm.fetching,
              scrollable: false,
              "has-dialog-actions": "",
              width: "600px",
            },
            on: {
              close: _vm.close,
              confirm: _vm.submit,
              open: _vm.retrieveData,
            },
            scopedSlots: _vm._u(
              [
                !_vm.externalActivator
                  ? {
                      key: "activator",
                      fn: function (slotProps) {
                        return [
                          _vm.$scopedSlots.activator
                            ? _vm._t("activator", null, null, slotProps)
                            : _vm._e(),
                        ]
                      },
                    }
                  : null,
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "v-container",
                        [
                          _c("form-base", {
                            attrs: { "hide-required-legend": "" },
                            scopedSlots: _vm._u([
                              {
                                key: "form",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-form",
                                      { ref: "form" },
                                      [
                                        _c(
                                          "v-alert",
                                          {
                                            staticClass: "py-2 body-2",
                                            attrs: { color: "disabled" },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v("Primary contact:"),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${_vm.carrier.firstName} ${_vm.carrier.lastName}`
                                                ) +
                                                " - " +
                                                _vm._s(_vm.carrier.email) +
                                                " - " +
                                                _vm._s(
                                                  _vm.carrier.company.name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "body-2 grey--text text--darken-3",
                                          },
                                          [
                                            _vm._v(
                                              " Carrier email subscribers are a list of email addresses that will receive all appointment notifications when appointments are scheduled with this Carrier Contact at your Org. "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "py-4" },
                                          [
                                            _c("email-list-field", {
                                              ref: "emailFieldList",
                                              attrs: {
                                                icon: "mdi-account-multiple",
                                                disabled: _vm.fetching,
                                                label:
                                                  "Carrier email subscribers",
                                              },
                                              model: {
                                                value: _vm.emailCCs,
                                                callback: function ($$v) {
                                                  _vm.emailCCs = $$v
                                                },
                                                expression: "emailCCs",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("v-spacer"),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "form-actions",
                                fn: function () {
                                  return [
                                    _c("action-group", {
                                      attrs: {
                                        loading: _vm.submitting,
                                        "disable-confirm": _vm.fetching,
                                        "confirm-label": "Save",
                                        "cancel-icon": null,
                                      },
                                      on: {
                                        cancel: _vm.close,
                                        confirm: _vm.submit,
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "dialog-base",
          _vm.$props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }