<template>
  <div v-if="loading">
    <generic-loader class="mt-12"></generic-loader>
  </div>
  <audit-log-base v-else v-bind="[$props, $attrs]" :log-lines="logLines"></audit-log-base>
</template>

<script>
import auditLogMixin from '@/components/mixins/auditLogMixin';
import { DateTime } from 'luxon';

const STATUS_FIELDS = ['status', 'statusTimeline'];
const CHANGED_FIELDS_TO_REMOVE = [
  'lastChangedDateTime',
  'lastChangedBy',
  'userId',
  'reschedules',
  'recurringParent',
  'isCheckedInByCarrier'
];
export default {
  mixins: [auditLogMixin],
  computed: {
    logLines() {
      let lines = [];
      if (this.entityObj) {
        if (this.auditLog?.length > 0) {
          let initialStatus = '';
          const statusUpdates = [];
          const customFieldUpdates = [];
          const tagUpdates = [];
          const startUpdates = [];
          this.auditLog.map((logEntry, index) => {
            if (index === 0) {
              initialStatus = logEntry.changedFields.status;
            }
            let lineItem = this.getDefaultLineItem(logEntry);
            this.getFormattedLogLines(
              logEntry,
              lineItem,
              lines,
              {
                customFieldUpdates,
                statusUpdates,
                tagUpdates,
                startUpdates,
                prevEntry: this.auditLog.length > 1 ? this.auditLog[index - 1] : null
              },
              initialStatus
            );
          });
        }
      }
      return lines;
    }
  },
  data() {
    return {
      appointmentLabel: 'Appointment'
    };
  },
  methods: {
    /**
     * Get the most recent status change by happy path order
     * @public
     * @param {object} statusTimeline
     * @returns {string}
     */
    getMostRecentStatusChangeByHappyPath(statusTimeline) {
      return this.novaCore.getMostRecentStatusChangeByHappyPath(statusTimeline);
    },
    /**
     * @public
     * @param item
     * @param lineItem
     * @param lines
     * @param statusUpdates
     */
    getFormattedLogLines(
      item,
      lineItem,
      lines,
      {
        statusUpdates = [],
        customFieldUpdates = [],
        tagUpdates = [],
        startUpdates = [],
        prevEntry = null
      },
      initialStatus
    ) {
      if (item.action === 'INSERT') {
        startUpdates.push(item.changedFields.start);
        customFieldUpdates.push(JSON.parse(item.changedFields.customFields));
        lineItem.change = this.getReadableAppointmentTime(item);
        lineItem.lineColor = `${item.changedFields.status.toLowerCase()}`;
        lineItem.lineTitle = `Appointment ${this.novaCore.upperFirst(item.changedFields.status)}`;
        lines.push(lineItem);
      } else if (item.action === 'UPDATE') {
        let changedFields = this.getChangedFields(item) || {};
        const hasStatusUpdate = Object.keys(changedFields).some(item => {
          return STATUS_FIELDS.includes(item);
        });

        if (hasStatusUpdate) {
          lineItem = this.getStatusUpdateLine(
            lineItem,
            statusUpdates,
            changedFields,
            startUpdates,
            initialStatus,
            prevEntry
          );
          lines.push(lineItem);
        }

        Object.entries(changedFields).forEach(async ([field, fieldVal]) => {
          const currentStatusTimeline = statusUpdates[statusUpdates.length - 1];
          let currentStatus = this.getMostRecentStatusChangeByHappyPath(currentStatusTimeline);
          if (!currentStatus) {
            currentStatus = initialStatus;
          }

          switch (field) {
            case 'refNumber': {
              const refNumDisplayName = this.$refNumSettings(
                this.entityObj.dock.warehouse
              ).displayName;
              lineItem = {
                ...lineItem,
                lineTitle: 'Details Updated',
                change: `<strong>${refNumDisplayName}:</strong> ${fieldVal}`
              };
              lines.push(lineItem);
              break;
            }

            case 'tags': {
              fieldVal = this.getTagArray(fieldVal);
              tagUpdates.push(fieldVal);
              const prevVal = tagUpdates[tagUpdates.length - 2] ?? [];
              const removedTags = [...prevVal].filter(tag => tag && !fieldVal.includes(tag));
              const addedTags = [...fieldVal].filter(tag => tag && !prevVal.includes(tag));
              this.pushTags(removedTags, addedTags, lineItem, lines);
              break;
            }

            // TODO: This will need to change when we add edits
            case 'recurringParentId': {
              let pattern = null;
              let change = '';
              if (changedFields['recurringPattern']) {
                const recurringPattern = JSON.parse(changedFields['recurringPattern']);
                pattern = this.novaCore.getReadableRecurringPatternParts(
                  recurringPattern,
                  momentjs(startUpdates[startUpdates.length - 1]).valueOf(),
                  this.entityObj.dock.warehouse.timezone
                );
              }
              let lineTitle = 'Recurrence Created';
              if (!fieldVal) {
                lineTitle = 'Recurrence Deleted';
              }
              if (pattern) {
                change = `Every ${pattern.weekDays} @ ${pattern.endTime} until ${pattern.endDate}`;
              }
              lineItem = {
                ...lineItem,
                change,
                lineTitle
              };
              lines.push(lineItem);
              break;
            }

            case 'recurringPattern': {
              break;
            }

            case 'start': {
              lineItem = {
                ...lineItem,
                lineTitle: `${this.appointmentLabel} Rescheduled`,
                change: this.getReadableAppointmentTime(item),
                lineColor: currentStatus.toLowerCase()
              };
              lines.push(lineItem);
              break;
            }
            case 'end': {
              if (changedFields?.['start']) {
                return;
              }
              lineItem = {
                ...lineItem,
                lineTitle: `${this.appointmentLabel} Rescheduled`,
                change: this.getReadableAppointmentTime(item),
                lineColor: currentStatus.toLowerCase()
              };
              lines.push(lineItem);
              break;
            }

            case 'customFields': {
              const currentFields = JSON.parse(fieldVal);
              customFieldUpdates.push(currentFields);
              lineItem = {
                ...lineItem,
                lineTitle: 'Details Updated',
                lineColor: 'non-status-item'
              };
              const prevFields = customFieldUpdates[customFieldUpdates.length - 2] ?? [];

              currentFields.forEach(field => {
                const prevField = prevFields.find(searchField => searchField.name === field.name);

                const firstChange = !prevField && field.value;
                const hasChanged = !_.isEqual(prevField?.value, field.value);

                if (firstChange || hasChanged) {
                  const value = this.getCustomFieldValueForAuditLog(field);
                  const change = `<strong>${field.label}:</strong> ${value}`;

                  lineItem = { ...lineItem, change };
                  lines.push(lineItem);
                }
              });
              break;
            }

            case 'carrier': {
              if (this.novaCore.isReserve(this.entityObj)) {
                lineItem = {
                  ...lineItem,
                  lineTitle: 'Reserve Transferred',
                  lineColor: 'non-status-item',
                  change: `${item.changedFields.carrier.firstName} ${item.changedFields.carrier.lastName} - ${item.changedFields.carrier.email}`
                };
              } else {
                lineItem = {
                  ...lineItem,
                  lineTitle: 'Carrier Contact Updated',
                  lineColor: 'non-status-item',
                  change: this.getUpdatedCarrier(item)
                };
              }
              lines.push(lineItem);
              break;
            }

            case 'muteNotifications':
              lineItem = {
                ...lineItem,
                lineTitle: 'Email notifications',
                lineColor: 'non-status-item',
                change: fieldVal === 't' ? 'Disabled' : 'Enabled'
              };
              lines.push(lineItem);
              break;

            case 'eta':
              lineItem = {
                ...lineItem,
                lineTitle: 'ETA Updated',
                lineColor: 'non-status-item',
                change: `<strong>ETA</strong>: ${this.novaCore.formatDateTimeWithMilitarySupport(
                  fieldVal.replace(' ', 'T'),
                  this.entityObj.dock.warehouse.timezone,
                  this.novaCore.LuxonDateTimeFormats.LongDateTimeShortMonth,
                  this.$isMilitaryTimeEnabled(this.$org),
                  this.novaCore.LuxonDateTimeFormats.LongDateTime24ShortMonth
                )}`
              };
              lines.push(lineItem);
              break;

            default: {
              lineItem = {
                ...lineItem,
                lineTitle: 'Details Updated',
                lineColor: 'non-status-item',
                change: `<strong>${this.novaCore.ucWords(field)}</strong>: ${fieldVal}`
              };
              lines.push(lineItem);
              break;
            }
          }
        });
      }
    },
    setAppointmentLabel() {
      const createEntry = this.auditLog.find(entry => entry.action === 'INSERT');
      this.appointmentLabel = this.novaCore.isReserve(createEntry.changedFields)
        ? 'Reserve'
        : 'Appointment';
    },
    /**
     * Get changed fields without CHANGED_FIELDS_TO_REMOVE items
     * @public
     * @param item
     * @returns {{}}
     */
    getChangedFields(item) {
      const changedFields = {};
      Object.entries(item.changedFields).forEach(([fieldKey, fieldVal]) => {
        if (!CHANGED_FIELDS_TO_REMOVE.includes(fieldKey)) {
          changedFields[fieldKey] = fieldVal;
        }
      });
      return changedFields;
    },
    /**
     * Returns status change line item based on multiple sets of conditions
     * @public
     * @param lineItem
     * @param statusUpdates
     * @param changedFields
     * @returns {*&{field: string, prevVal: string, lineTitle: string, fieldVal: string, lineColor: string}}
     */
    getStatusUpdateLine(
      lineItem,
      statusUpdates,
      changedFields,
      startUpdates,
      initialStatus,
      prevEntry
    ) {
      if (changedFields.statusTimeline) {
        statusUpdates.push(JSON.parse(changedFields.statusTimeline));
      } else {
        // This is an invalid state, the audit log cannot identify
        // the change, because the statusTimeline was not updated
        // together with the status field.
        statusUpdates.push(this.entityObj.statusTimeline);
      }

      const cancelledStatus = this.novaCore.AppointmentStatus.Cancelled;
      const currentStatusTimeline = statusUpdates[statusUpdates.length - 1];
      const previousStatusTimeline = statusUpdates[statusUpdates.length - 2];
      const prevStatus =
        prevEntry.changedFields?.status ??
        this.novaCore.getMostRecentStatusChange(previousStatusTimeline);
      const currentStatus =
        changedFields.status ?? this.novaCore.getMostRecentStatusChange(currentStatusTimeline);

      let lineTitle = 'Status Updated';
      let newLineColor = null;
      let baseClasses = `audit-log-status-change-line font-weight-bold`;
      if (currentStatus !== this.novaCore.AppointmentStatus.Requested) {
        baseClasses += ` ${currentStatus.toLowerCase()}--text`;
      }
      if ((prevStatus && currentStatus && prevStatus === currentStatus) || !changedFields.status) {
        const arrivedStatus = this.novaCore.AppointmentStatus.Arrived;
        const completedStatus = this.novaCore.AppointmentStatus.Completed;
        const arrivalTimeChange =
          previousStatusTimeline[arrivedStatus] !== currentStatusTimeline[arrivedStatus];
        const dwellTimeChange =
          previousStatusTimeline[completedStatus] !== currentStatusTimeline[completedStatus];
        if (arrivalTimeChange) {
          lineTitle = `${arrivedStatus} Time Updated`;
          newLineColor = arrivedStatus.toLowerCase();
          lineItem.change = this.util.getReadableDuration(
            startUpdates[startUpdates.length - 1],
            currentStatusTimeline[arrivedStatus],
            this.entityObj.dock.warehouse.timezone,
            ' late'
          );
        } else if (dwellTimeChange) {
          lineTitle = `Dwell Time Updated`;
          newLineColor = completedStatus.toLowerCase();
          lineItem.change = this.util.getReadableDuration(
            currentStatusTimeline[arrivedStatus],
            currentStatusTimeline[completedStatus],
            this.entityObj.dock.warehouse.timezone
          );
        }
      } else if (prevStatus && currentStatus) {
        if (currentStatus === cancelledStatus) {
          lineTitle = `${this.appointmentLabel} ${cancelledStatus}`;
          lineItem.change = '<div class="d-none"></div>';
          newLineColor = 'error';
        } else {
          lineItem.change = `${this.novaCore.breakWordsAtCaps(
            prevStatus
          )} &#8594; <span class="${baseClasses}">${this.novaCore.breakWordsAtCaps(
            currentStatus
          )}</span>`;
        }
      } else if (currentStatus && !prevStatus) {
        lineItem.change = `${this.novaCore.breakWordsAtCaps(
          initialStatus
        )} &#8594; <span class="${baseClasses}">${this.novaCore.breakWordsAtCaps(
          currentStatus
        )}</span>`;
      }
      Object.keys(changedFields).forEach(key => {
        if (STATUS_FIELDS.includes(key)) {
          delete changedFields[key];
        }
      });
      return {
        ...lineItem,
        field: 'status',
        prevVal: prevStatus,
        fieldVal: currentStatus,
        lineColor: newLineColor ?? currentStatus.toLowerCase(),
        lineTitle
      };
    },
    /**
     * Returns the initial line item
     * @param item
     * @returns {{lineTitle: string, action, lineColor: string, id, user: (string|string), timestamp: Date | string}}
     */
    getDefaultLineItem(item) {
      const user = this.getItemUser(item);
      let lineColor = this.novaCore.AppointmentStatus.Scheduled.toLowerCase();
      let lineTitle = `${this.appointmentLabel} Scheduled`;
      return {
        action: item.action,
        user,
        id: item.id,
        timestamp: DateTime.fromISO(item.actionTimestamp)
          .setZone(this.entityObj.dock.warehouse.timezone)
          .toISO(),
        lineColor,
        lineTitle
      };
    },
    /**
     * Returns a readable appointment time specific to audit log
     * @param item
     * @returns {string}
     */
    getReadableAppointmentTime(item) {
      const timezone = this.entityObj.dock.warehouse.timezone;
      const start = (item.changedFields.start ?? this.entityObj.start).replace(' ', 'T');
      const end = (item.changedFields.end ?? this.entityObj.end).replace(' ', 'T');
      return `${this.novaCore.formatDateTimeWithMilitarySupport(
        start,
        timezone,
        this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
      )} - ${this.novaCore.formatDateTimeWithMilitarySupport(
        end,
        timezone,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      )}`;
    },
    /**
     * Strip curly brackets and double quotes to create comma-separated string
     * (Since tags are not proper JSON, we can't use JSON.parse without error)
     * @param str
     * @returns {*}
     */
    formatTagString(str) {
      return str.replace(/[{}"]/g, '');
    },
    /**
     * Splits formatted tag string by comma
     * @param str
     * @returns {*|*[]}
     */
    getTagArray(str) {
      return str ? this.formatTagString(str).split(',') : [];
    },
    /**
     * Pushes tag line items to lines array
     * @param removedTags
     * @param addedTags
     * @param lineItem
     * @param lines
     */
    pushTags(removedTags, addedTags, lineItem, lines) {
      lineItem = {
        ...lineItem,
        lineTitle: 'Tags Updated',
        lineColor: 'non-status-item'
      };
      const customTags = this.org.customTags;
      const inlineStyles = 'display:inline-block;padding:3px;border-radius:5px;';
      if (removedTags.length) {
        lineItem.change = '<strong>Removed</strong>';
        removedTags.forEach(tag => {
          let color = '';
          let textColor = '';
          const tagItem = this.util.getCustomTagData(customTags, tag);
          if (tagItem) {
            color = tagItem.color;
            textColor = tagItem.textColor;
          }
          lineItem.change += ` <span class="strike font-weight-light" style="background:${color};color:${textColor};${inlineStyles}">"${tag}"</span>`;
        });
        lines.push(lineItem);
      }
      if (addedTags.length) {
        lineItem.change = '<strong>Added</strong>';
        addedTags.forEach(tag => {
          let color = '';
          let textColor = '';
          const tagItem = this.util.getCustomTagData(customTags, tag);
          if (tagItem) {
            color = tagItem.color;
            textColor = tagItem.textColor;
          }
          lineItem.change += ` <span style="background:${color};color:${textColor};${inlineStyles}">"${tag}"</span>`;
        });
        lines.push(lineItem);
      }
    },
    getUpdatedCarrier(item) {
      return `${item.changedFields.carrier.firstName} ${item.changedFields.carrier.lastName} - ${item.changedFields.carrier.company?.name} - ${item.changedFields.carrier.email}`;
    }
  },
  watch: {
    auditLog() {
      if (this.auditLog?.length > 0) {
        this.setAppointmentLabel();
      }
    }
  }
};
</script>
