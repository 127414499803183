<template>
  <dialog-base
    :persistent="false"
    v-if="appointment?.id"
    :key="renderKey"
    :loading="loading"
    v-bind="$props"
    header="Appointment Details"
    loading-message=""
    @close="closeDialog"
    :content-class="contentClasses"
    transition="dialog-bottom-transition"
    max-width="100%"
    :value="showDialog"
    width="1200px"
    @click:outside="closeDialog">
    <template #customHeader>
      <header class="details-header">
        <div class="time-details">
          <div class="d-flex">
            <span
              class="text-lg-h6 black--text"
              v-html="getAppointmentDateTimeHeader(appointment)"></span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-2" v-if="appointment.isInDSTChange">
                  mdi-sun-clock
                </v-icon>
              </template>
              <span>Includes a Daylight Savings time shift</span>
            </v-tooltip>
            <template v-if="reschedulesCount > 0">
              <span
                class="reschedules-count"
                data-testid="appointment-details-dialog-reschedules-count">
                {{ reschedulesString }}
              </span>
            </template>
          </div>
          <div class="d-flex align-center timezone">
            <v-icon class="mr-2" small>mdi-earth</v-icon>
            <span>
              {{ appointment.dock.warehouse.timezone.replace('_', ' ') }}
            </span>
          </div>
          <div v-if="recurringPattern.weekDays" class="recurring-pattern">
            Recurs every
            <strong>{{ recurringPattern.weekDays }} @ {{ recurringPattern.endTime }}</strong>
            until
            <strong>{{ recurringPattern.endDate }}</strong>
          </div>
        </div>

        <div class="d-flex">
          <appointment-action-group
            v-if="appointmentWarehouse"
            :appointment-warehouse="appointmentWarehouse"
            :parent-appointment="parentAppointment"
            :appointment="appointment"
            :appointment-actions="appointmentActions"
            @update:create-appointment-dialog="handleDialogVisibility"></appointment-action-group>

          <v-btn class="pa-3" icon light @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </header>
    </template>

    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button v-on="slotProps.on" v-bind="slotProps.attrs">View Appointment</primary-button>
    </template>
    <template v-slot:body>
      <appointment-details
        class="full-height"
        :read-only="!canUserAccessWarehouse"
        v-if="appointmentWarehouse"
        :appointment-warehouse="appointmentWarehouse"
        :parent-appointment="recurringParentAppointment"
        @close="close"
        :appointment="appointment"
        :should-edit-status="true"
        :should-display-cancel-button="false"></appointment-details>
    </template>
  </dialog-base>
</template>

<script>
import AppointmentDetailsDialogBase from '@/modules/appointments/components/details/AppointmentDetailsDialogBase.vue';

/**
 * Dialog for create appointment form
 * @displayName Create Appointment Dialog
 */
export default {
  extends: AppointmentDetailsDialogBase,
  data() {
    return {
      showRescheduleDialog: false,
      hideDialog: false
    };
  },
  computed: {
    canReschedule() {
      return (
        this.$rolePermissions.canUpdateAppointment &&
        this.novaCore.isRescheduleAllowed(this.appointment.status) &&
        !this.readOnly
      );
    },
    reschedulesCount() {
      return this.novaCore.getReschedulesCount(this.appointment);
    },
    reschedulesString() {
      return this.novaCore.getReschedulesString(this.reschedulesCount);
    },
    contentClasses() {
      const defaultClasses = [
        'details-dialog',
        'details-dialog-wrapper',
        'appointment-details-dialog'
      ];
      if (this.hideDialog) {
        defaultClasses.push('hide-dialog');
      } else {
        const index = defaultClasses.indexOf('hide-dialog');
        if (index >= 0) {
          defaultClasses.splice(index, 1);
        }
      }
      return defaultClasses.join(' ');
    }
  },
  methods: {
    resetSelectedEvent() {
      this.$store.commit('Calendar/setSelectedEvent', null);
    },
    closeDialog() {
      this.close();
      this.removeAppointmentIdFromRouteQuery();
      this.resetSelectedEvent();
    },
    handleDialogVisibility(val) {
      if (!val) {
        this.hideDialog = false;
      } else {
        this.hideDialog = true;
      }
    }
  },
  watch: {
    appointment(newAppointment) {
      this.$nextTick(() => {
        if (newAppointment) {
          const scrollableDiv = document.querySelector('.details-dialog .v-card__text');
          if (scrollableDiv) {
            scrollableDiv.scrollTop = 0;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.details-dialog-wrapper.v-dialog {
  .v-card {
    &__text {
      padding: 0 !important;

      > div {
        height: 100%;
      }
    }
  }
}
.details-dialog.v-dialog {
  padding: 0 !important;
  align-self: flex-end;
  margin-bottom: 0;
  margin-top: 0;
  max-height: calc(100% - 48px) !important;
  height: calc(100% - 48px);
  border-radius: 8px 8px 1px 1px;

  &.hide-dialog {
    height: 10px;
  }

  .dialog-box {
    max-height: 100%;
    border-radius: 8px 8px 0 0;
  }

  .timezone {
    font-size: 14px;
    color: $color-text-tertiary;
  }

  .details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $color-line-border;
    padding: 20px 24px;
  }

  .time-details {
    line-height: 18px;

    .v-btn {
      height: auto;
    }
  }

  .recurring-pattern {
    font-size: 12px;
    color: $color-text-secondary;
  }
}
</style>
