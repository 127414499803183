var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar-label" },
    [
      [_vm.calendarOptions.DAY, _vm.calendarOptions.WEEK].includes(_vm.viewType)
        ? _c("span", { staticClass: "text-subtitle-1 font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ])
        : _vm._e(),
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "text-none font-weight-regular",
                          attrs: { depressed: "", text: "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", [_vm._v("mdi-calendar")]),
                      _vm._v(" " + _vm._s(_vm.label) + " "),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.datePicker,
            callback: function ($$v) {
              _vm.datePicker = $$v
            },
            expression: "datePicker",
          },
        },
        [
          _c("v-date-picker", {
            attrs: {
              color: "accent",
              value: _vm.value,
              type: _vm.calendarType,
            },
            on: { input: _vm.onChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }