var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Edit Subscribers",
          "loading-message": "Sharing Appointment...",
          width: "800px",
          "max-width": "90%",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.shareAppointmentForm.submit()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "before-icon": "account-multiple",
                                large: "",
                              },
                            },
                            "primary-button",
                            slotProps.attrs,
                            false
                          ),
                          slotProps.on
                        ),
                        [_vm._v(" Edit Subscribers ")]
                      ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.alertMessage
            ? _c(
                "v-alert",
                {
                  staticClass: "mb-0",
                  attrs: { type: _vm.alertType, shaped: "" },
                },
                [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
              )
            : _vm._e(),
          _c("share-appointment-form", {
            ref: "shareAppointmentForm",
            attrs: { appointment: _vm.appointment },
            on: { close: _vm.close },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }