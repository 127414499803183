<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    :key="renderKey"
    header="Clone Schedule"
    :scrollable="true"
    max-width="450px"
    width="450px"
    has-dialog-actions
    loading-message="Cloning Schedule..."
    @close="close"
    @keydown.native.enter="emitCopySchedule"
    @submit="emitCopySchedule">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <button-base
        v-else
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        text
        plain
        block
        x-small
        before-icon="content-copy"
        class="white">
        Another Dock...
      </button-base>
    </template>
    <template v-slot:body>
      <form-base hide-required-legend class="px-2 mb-2">
        <template #form>
          <v-form ref="form">
            <h4 class="black--text">Dock schedule to clone from:</h4>
            <dock-select
              :multi-select="false"
              :prepend-icon="null"
              v-model="selectedDock"
              :docks="docks"
              placeholder="Select Dock"></dock-select>
          </v-form>
        </template>
        <template #form-actions>
          <action-group
            @cancel="close"
            confirm-icon="content-copy"
            confirm-label="Clone Schedule"
            @confirm="emitCopySchedule"></action-group>
        </template>
      </form-base>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit create/edit dock form
 * @displayName Edit Dock Dialog
 */
export default {
  name: 'CloneDockScheduleDialog',
  mixins: [dialogMixin],
  props: {
    docks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      selectedDock: {}
    };
  },
  methods: {
    emitCopySchedule() {
      this.$emit('copy-schedule', this.selectedDock.schedule);
      this.selectedDock = {};
      this.close();
    }
  }
};
</script>
