<template>
  <!--
    Triggered on input event
    @event input
    @property {string} value - Custom v-model
  -->
  <v-text-field
    dense
    height="20px"
    :value="value"
    @input="value => $emit('input', value)"
    class="required"
    required
    validate-on-blur
    v-on="$listeners"
    v-bind="[$props, $attrs]"></v-text-field>
</template>

<script>
/**
 * Text input with additional error highlighting and styles
 * @displayName Required Text Field
 */
export default {
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>
