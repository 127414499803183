<template>
  <v-card flat height="75%">
    <v-card-text class="px-0">
      <v-data-table
        hide-default-header
        hide-default-footer
        disable-pagination
        disable-sort
        show-expand
        item-key="id"
        height="90%"
        group-by="sortDate"
        :group-desc="true"
        :expanded.sync="expanded"
        :headers="headers"
        :items="logLines"
        :search="searchStr">
        <template v-slot:group.header="{ items, isOpen, toggle }">
          <th :colspan="6">
            <strong>
              <v-icon small class="mr-2">mdi-calendar</v-icon>
              {{ items[0].date }}
            </strong>
          </th>
        </template>
        <template v-slot:item.time="{ item }">
          {{ item.time }}
        </template>
        <template v-slot:item.value="{ item }"></template>
        <template v-slot:item.date="{ item }"></template>
        <template v-slot:item.field="{ item }">
          <strong>{{ item.field }}</strong>
          {{ item.action }}
        </template>
        <template v-slot:item.user="{ item }">
          <strong class="text--disabled">by {{ item.user }}</strong>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-4">
            <pre class="expanded" v-html="item.value"></pre>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    logLines: {
      type: Array,
      required: true
    },
    timezone: {
      type: String,
      required: false
    },
    searchStr: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          value: 'time',
          align: 'start',
          sortable: false
        },
        {
          value: 'field',
          sortable: false
        },
        {
          value: 'user',
          align: 'end',
          sortable: false
        },
        {
          value: 'value',
          sortable: false
        },
        {
          value: 'date',
          sortable: false
        }
      ]
    };
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.expanded {
  overflow-y: hidden;
  max-width: 100%;
  white-space: pre-wrap;
  font-size: 12px;
}
</style>
