import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export default function initSentry(vue, routes, config) {
  if (config.sentry_dsn) {
    Sentry.init({
      vue,
      environment: config.server_env,
      dsn: config.sentry_dsn,
      release: config.nova_version,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Navigation cancelled from',
        'AxiosError',
        'NotAllowedError'
      ],
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(routes),
          tracingOrigins: [config.neutron_url, /^\//]
        })
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config.sentry_traces_sample_rate ?? 1,
      // Redact sensitive information
      beforeSend(event) {
        function redactSensitiveData(str) {
          if (!str) {
            return str;
          }

          return str.replace(
            /[a-zA-Z0-9_=]{36}\.[a-zA-Z0-9_=]{51}\.[a-zA-Z0-9_\-+/=]{43}/u,
            'JWT_REDACTED'
          );
        }

        // Ignore Axios Errors for non internal server errors
        if (event.request?.statusCode >= 400 && event.request?.statusCode < 500) {
          return null;
        }

        if (event.request) {
          event.request.url = redactSensitiveData(event.request.url);
        }

        if (event.exception) {
          event.exception.values = event.exception.values?.map(exception => ({
            ...exception,
            value: redactSensitiveData(exception.value)
          }));
        }

        return event;
      }
    });
  }
}
