<template>
  <div>
    <div id="printable-appointments">
      <template v-for="appointment in listableAppointments">
        <printable-appointment-details
          :settings="settings"
          :key="appointment.id"
          :appointment="appointment"></printable-appointment-details>
      </template>
    </div>
    <v-card class="appointment-list">
      <v-card-title>
        <v-row align="center" v-if="customHeader">
          <slot
            name="table-header"
            :filters="filters"
            :rows="exportableRows"
            :columns="{
              available: selectColumns,
              selected: selectedColumns,
              autoSelect: selectedColumnsAutoselect,
              update: event => (selectedColumns = event)
            }"
            :exporter="{
              exportExcel: exportToExcel,
              excelFileName: excelFileName,
              buildingExcelData: buildingExcelData,
              printTable: printTable
            }"></slot>
        </v-row>
        <v-row align="center" v-else class="mb-2 pl-3">
          <v-col cols="6">
            <text-field
              class="mb-5"
              v-model="filters.searchStr"
              append-icon="mdi-filter"
              label="Filter"
              hint="Search for appointments"
              persistent-hint></text-field>
          </v-col>
          <v-col cols="6">
            <generic-multi-select
              :items="selectColumns"
              class="mb-5"
              item-text="text"
              item-value="value"
              v-model="selectedColumns"
              append-icon="mdi-list"
              refKey="columnSelect"
              :auto-select="selectedColumnsAutoselect"
              :minimum-selections="1"
              :visible-selection-count="3"
              label="Table Columns"
              return-object
              as-chips
              hint="Select columns to show in the table"
              multiple
              persistent-hint></generic-multi-select>
          </v-col>
          <v-spacer></v-spacer>
          <primary-button
            v-if="showExportButton"
            data-testid="export-to-excel"
            class="mr-3"
            :loading="buildingExcelData"
            before-icon="microsoft-excel"
            @click="exportToExcel(exportableRows, excelFileName)">
            Export to Excel
          </primary-button>
          <primary-button before-icon="printer" @click="printTable" v-if="showPrintButton">
            Print Appointments
          </primary-button>
          <v-spacer></v-spacer>
          <span class="font-size-large text--black">
            {{ displayTotal ? `Total Appointments: ${displayTotal}` : '' }}
          </span>
        </v-row>
      </v-card-title>
      <appointments-loader :is-loading="isLoading"></appointments-loader>
      <div class="print-table">
        <v-data-table
          data-testid="appointments-list-data-table"
          :headers="columnsToShow"
          :items="listableAppointments"
          :loading="$data.$globalLoading"
          :sort-by.sync="sortBy"
          ref="appointmentListTable"
          :sort-desc.sync="sortDesc"
          :custom-sort="customSort"
          :search="regexSafeSearch"
          :custom-filter="searchRows"
          @current-items="setExportRows"
          id="appointments-table"
          item-key="id"
          :server-items-length="totalItems"
          :expanded.sync="expanded"
          :footer-props="footerProps"
          :disable-sort="printMode"
          :disable-pagination="printMode"
          class="clickable-rows"
          @pagination="handlePagination"
          :options.sync="options">
          <template v-for="header in columnsToShow" v-slot:[`item.${header.value}`]="{ value }">
            <template v-if="header.value === 'user.email'">
              <copy-content
                :key="header.value"
                :content="value"
                label="Click to copy carrier email">
                <span :inner-html.prop="value | highlight(regexSafeSearch)"></span>
              </copy-content>
            </template>
            <template v-else>
              <span
                :key="header.value"
                :inner-html.prop="value | highlight(regexSafeSearch)"></span>
            </template>
          </template>
          <template v-slot:item.status="{ item }">
            <div :class="`${item.status.toLowerCase()} tile font-size-x-small text-center`">
              <v-icon class="mr-1" v-if="novaCore.isRequested(item.status)">mdi-clock-alert</v-icon>
              <strong
                :class="{ 'black--text': novaCore.isRequested(item.status) }"
                :inner-html.prop="
                  novaCore.breakWordsAtCaps(item.status) | highlight(regexSafeSearch)
                "></strong>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="row-actions">
              <icon-tooltip-button
                icon-class="mr-2"
                size="large"
                @click="handleEventClick(item)"
                tooltip="View Appointment"
                icon="eye"></icon-tooltip-button>
              <icon-tooltip-button
                size="large"
                @click="toggleRowExpansion(item)"
                tooltip="Show More"
                :icon="
                  expanded.includes(item) ? 'chevron-up' : 'chevron-down'
                "></icon-tooltip-button>
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }" v-if="headers && headers.length">
            <td :colspan="headers.length" class="py-6">
              <h3>Tags</h3>

              <template v-for="tag in item.tags">
                <v-chip
                  :key="tag"
                  class="px-2 mr-1"
                  label
                  :text-color="util.makeTagObject($org.customTags, tag).textColor"
                  :color="util.makeTagObject($org.customTags, tag).color">
                  <span
                    :inner-html.prop="
                      util.makeTagObject($org.customTags, tag).name
                        | highlight(regexSafeSearch, item.id, 'tag')
                    "></span>
                </v-chip>
              </template>
              <span v-if="!item.tags || !item.tags.length">
                This appointment does not have tags
              </span>

              <v-divider class="my-5"></v-divider>

              <h3>Custom Fields</h3>
              <div v-for="customField in filteredCustomFields(item)" :key="customField.name">
                <strong>{{ customField.label }}</strong>
                :
                <span
                  :inner-html.prop="
                    getCustomFieldValue(customField)
                      | highlight(regexSafeSearch, item.id, 'customField')
                  "></span>
              </div>

              <v-divider class="my-5"></v-divider>
              <div v-for="trigger of $selectedWarehouseTriggers" :key="trigger.id" class="mb-2">
                <h3 class="font-weight-bold">
                  {{
                    trigger.flow?.name || novaCore.CustomFormsFeaturesData[trigger.feature].title
                  }}
                </h3>
                <template>
                  <custom-forms-data
                    :key="trigger.id"
                    :trigger="trigger"
                    :object-id="item.id"
                    :timezone="warehouse.timezone"
                    flat
                    :military-time-enabled="$isMilitaryTimeEnabled(warehouse)"></custom-forms-data>
                </template>
              </div>
            </td>
          </template>

          <template v-slot:item.startDate="{ item }">
            <span>
              {{
                getTimeInWarehouseTimezone(
                  item,
                  novaCore.LuxonDateTimeFormats.LongDateShortMonth,
                  novaCore.LuxonDateTimeFormats.LongDateShortMonth,
                  $isMilitaryTimeEnabled(warehouse)
                )
              }}
            </span>
          </template>

          <template v-slot:item.startTime="{ item }">
            <span>
              {{
                getTimeInWarehouseTimezone(
                  item,
                  `${novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM} - ${novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                  `${novaCore.LuxonDateTimeFormats.Extended24HrTime} - ${novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                  $isMilitaryTimeEnabled(warehouse)
                )
              }}
            </span>
          </template>

          <template v-slot:item.start="{ item }">
            <span>
              {{
                getTimeInWarehouseTimezone(
                  item,
                  novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
                  novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24,
                  $isMilitaryTimeEnabled(warehouse)
                )
              }}
            </span>
          </template>

          <template v-slot:item.tags="{ item }">
            <div @click="toggleRowExpansion(item)">
              <template v-for="(tag, idx) in item.tags">
                <v-chip
                  :key="tag"
                  class="px-2 mr-1"
                  label
                  small
                  v-if="idx === 0"
                  :color="util.makeTagObject($org.customTags, tag).color"
                  :text-color="util.makeTagObject($org.customTags, tag).textColor">
                  <span
                    :inner-html.prop="
                      util.makeTagObject($org.customTags, tag).name
                        | highlight(regexSafeSearch, item.id, 'tag')
                    "></span>
                </v-chip>
                <span :key="tag" v-else-if="item.tags.length > 1 && idx + 1 === item.tags.length">
                  + {{ item.tags.length - 1 }} more
                </span>
              </template>
            </div>
          </template>

          <template v-slot:no-data v-if="shouldShowNoSelectionOverlayMargin">
            <div style="height: 350px"></div>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <feature-flag name="enable-new-appointment-details">
      <template #enabled>
        <appointment-details-dialog
          v-if="selectedEvent"
          :external-activator="true"
          :show-dialog="showDetailsDialog"
          @close="handleDetailsDialogClose"
          :appointment="selectedEvent"></appointment-details-dialog>
      </template>
      <template #disabled>
        <appointment-details-dialog-old
          v-if="selectedEvent"
          :external-activator="true"
          :show-dialog="showDetailsDialog"
          @close="handleDetailsDialogClose"
          :appointment="selectedEvent"></appointment-details-dialog-old>
      </template>
    </feature-flag>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import exportAppointmentsMixin from '@satellite/components/mixins/exportAppointmentsMixin';
import appointmentMixinBase from '@satellite/components/mixins/appointmentMixinBase';
import { getNestedPropertyByDotNotation } from '@satellite/../nova/core';
import { getTimeInWarehouseTimezone } from '@satellite/plugins/util';

export default {
  mixins: [dataListMixin, appointmentMixinBase, exportAppointmentsMixin],
  props: {
    overrides: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    appointments: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    customHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    isRange: {
      type: Boolean,
      required: false,
      default: true
    },
    filterLocally: {
      type: Boolean,
      required: false,
      default: false
    },
    // Manage appointment data within this component instead of from the appointments prop
    manageDataInternally: {
      type: Boolean,
      required: false,
      default: false
    },
    showExportButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showPrintButton: {
      type: Boolean,
      required: false,
      default: true
    },
    buildHeadersFn: {
      type: Function,
      required: false,
      default: null
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 15
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    // Additional query params when fetching appointments
    queryObject: {
      type: Object,
      required: false,
      default: null
    },
    formatAppointmentsFn: {
      type: Function,
      required: false,
      default: null
    }
  },
  data() {
    return {
      sortBy: ['start'],
      filters: {
        searchStr: null,
        dateRange: this.isRange ? [] : ''
      },
      showDetailsDialog: false,
      selectedEvent: null,
      expanded: [],
      options: {
        itemsPerPage: this.itemsPerPage
      },
      selectedColumns: [],
      columnsToShow: [],
      persistentColumns: [
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end',
          hidePrint: true
        },
        {
          text: 'Custom Fields',
          value: 'customFields',
          searchable: true,
          align: ' d-none',
          hidePrint: true
        }
      ],
      printMode: false,
      internalAppointments: [],
      internalTotal: null,
      displayTotal: null,
      columnsStorageKey: 'appointmentList_selectedColumns'
    };
  },
  filters: {
    highlight(value, query, itemId, type) {
      if (typeof value !== 'string') {
        return value;
      }

      let r = value?.replace(
        new RegExp(query, 'ig'),
        v => `<span class='orange lighten-3'>${v}</span>`
      );

      if (type === 'tag') {
        r = `${r}`;
      }

      return r;
    }
  },
  computed: {
    settings() {
      return this.warehouse?.settings ?? {};
    },
    totalItems() {
      return this.manageDataInternally ? this.internalTotal : this.$appointments.total;
    },
    excelFileName() {
      return `${this.$selectedWarehouse?.name || 'opendock'}_appointments.xlsx`;
    },
    listableAppointments() {
      let appointments = this.manageDataInternally ? this.internalAppointments : this.appointments;

      if (this.formatAppointmentsFn) {
        appointments = this.formatAppointmentsFn(appointments);
      }

      return appointments.filter(appointment => !this.novaCore.isReserve(appointment));
    },
    shouldShowNoSelectionOverlayMargin() {
      return !this.$selectedWarehouse?.id || !this.$selectedDocks?.length;
    },
    selectColumns() {
      const unselectableCols = ['customFields', 'actions'];
      return this.headers.filter(h => !unselectableCols.includes(h.value));
    },
    headers() {
      let headers;

      if (this.buildHeadersFn) {
        headers = this.buildHeadersFn();
      } else {
        headers = [
          {
            text: 'Conf #',
            value: 'confirmationNumber',
            searchable: true,
            idx: 0,
            width: 90
          },
          {
            text: 'Start Date',
            value: 'startDate',
            searchable: true,
            idx: 0
          },
          {
            text: 'Start Time',
            value: 'startTime',
            searchable: true,
            idx: 1
          },
          {
            text: 'Carrier Contact',
            value: 'user.email',
            searchable: true,
            idx: 2
          },
          {
            text: 'Carrier Company',
            value: 'user.company.name',
            searchable: true,
            sortable: true,
            idx: 3
          },
          {
            text: 'Location',
            value: 'dock.warehouse.name',
            searchable: true,
            align: ' d-none',
            idx: 4
          },
          {
            text: 'Dock',
            value: 'dock.name',
            searchable: true,
            idx: 5
          },
          {
            text: 'Load Type',
            value: 'loadType.name',
            searchable: true,
            idx: 6
          },
          {
            text: 'Direction',
            value: 'loadType.direction',
            searchable: true,
            idx: 7,
            align: 'd-none'
          },
          {
            text: 'Tags',
            value: 'tags',
            searchable: true,
            idx: 8
          },
          {
            text: this.$getSettingValue('referenceNumberDisplayName', this.warehouse?.settings),
            value: 'refNumber',
            searchable: true,
            idx: 9
          },
          {
            text: 'Status',
            value: 'status',
            searchable: true,
            idx: 10
          },
          {
            text: 'Appointment ID',
            value: 'id',
            searchable: true,
            idx: 11
          }
        ];
      }

      headers = [...headers, ...this.persistentColumns];

      return headers;
    },
    regexSafeSearch() {
      if (this.filters.searchStr) {
        return _.escapeRegExp(this.filters.searchStr);
      }

      return this.filters.searchStr;
    },
    warehouse() {
      // This is used by appointmentMixin
      return this.$selectedWarehouse;
    },
    storedSelectedColumns: {
      get() {
        const stored = localStorage.getItem(this.columnsStorageKey);
        if (!stored) {
          return [];
        }
        try {
          return JSON.parse(stored) || [];
        } catch {
          return [];
        }
      },
      set(value) {
        const count = value?.length ?? 0;
        if (count < 1) {
          return;
        }
        localStorage.setItem(this.columnsStorageKey, JSON.stringify(value));
      }
    },
    selectedColumnsAutoselect() {
      return this.storedSelectedColumns.length < 1;
    }
  },
  methods: {
    getTimeInWarehouseTimezone,
    handlePagination(pagination) {
      this.displayTotal = pagination.itemsLength;
    },
    searchRows(value, search, item) {
      if (!value) {
        return false;
      }
      if (!search) {
        return true;
      }
      let hasMatch = false;
      let hasExpandedMatch = false;
      const isArr = Array.isArray(value);

      const s = search.toLowerCase();

      if (typeof value === 'string') {
        hasMatch = value.toLowerCase().search(s) !== -1;
      }

      if (isArr && !hasMatch) {
        const filtered = value.filter(i => {
          i = i && typeof i === 'object' ? Object.values(i).join(', ') : i;
          return typeof i === 'string' ? i.toLowerCase().search(s) !== -1 : false;
        });
        hasMatch = filtered.length > 0;
        hasExpandedMatch = hasMatch;
      }

      if (typeof value === 'object' && !isArr && !hasMatch) {
        hasMatch =
          Object.values(value).filter(i => {
            return i.toLowerCase().search(s) !== -1;
          }).length > 0;
        hasExpandedMatch = hasMatch;
      }

      if (hasExpandedMatch) {
        this.expanded.push(item);
      }

      return hasMatch;
    },
    toggleRowExpansion(item) {
      if (this.expanded.includes(item)) {
        const index = this.expanded.findIndex(i => i === item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    async getData() {
      if (this.filterLocally) {
        return this.appointments;
      }
      if (this.shouldGetData(this.filters)) {
        let query = this.getQueryBase();
        if (this.filters.dateRange.length === 2) {
          query.s = {
            start: {
              $between: [
                momentjs(this.filters.dateRange[0]).toDate(),
                momentjs(this.filters.dateRange[1]).endOf('day').toDate()
              ]
            }
          };
        }

        if (this.queryObject) {
          query = this.util.mergeDeep(query, this.queryObject);
        }

        if (this.manageDataInternally) {
          const response = await axios.get(`appointment?${this.$appointmentJoins()}`, {
            params: query
          });
          if (response?.data?.data) {
            this.internalAppointments = response.data.data;
            this.internalTotal = response.data.total;
          }
        } else {
          await this.$store.dispatch('Appointments/getAppointments', query);
        }
      }
    },
    setExportRows() {
      if (this.$refs.appointmentListTable) {
        this.exportableRows = this.novaCore.deepClone(
          this.$refs.appointmentListTable?.$children[0].filteredItems
        );
      }
    },
    handleEventClick(event) {
      this.$store.commit('Calendar/setSelectedEvent', event);
      if (this.novaCore.isReserve(event)) {
        this.showReserveDialog = this.$rolePermissions.canUpdateAppointment;
      } else {
        this.showDetailsDialog = true;
      }
      this.selectedEvent = event;
      this.showDetailsDialog = true;
    },
    handleDetailsDialogClose() {
      this.$store.commit('Calendar/setSelectedEvent', null);
      this.showDetailsDialog = false;
    },
    shouldGetData(filters) {
      return !(this.isRange && filters.dateRange.length === 1);
    },
    filteredCustomFields(event) {
      if (event) {
        return this.novaCore.updateCustomFieldsFromWarehouse(
          event.customFields,
          event.dock.warehouse,
          false
        );
      }
      return [];
    },
    handleColumnSelect() {
      if (this.selectColumns?.length > 0) {
        this.storedSelectedColumns = this.selectedColumns;
      }
    },
    customSort(items, sortBy, sortDesc) {
      if (!sortBy[0]) {
        return items;
      }

      const objKey =
        sortBy[0] === 'startDate'
          ? 'start'
          : sortBy[0] === 'startTime'
          ? 'startClockSpan'
          : sortBy[0];

      const collator = new Intl.Collator('en', {
        numeric: true,
        sensitivity: 'base'
      });

      const sorted = items.sort((a, b) => {
        return collator.compare(
          getNestedPropertyByDotNotation(a, objKey),
          getNestedPropertyByDotNotation(b, objKey)
        );
      });

      if (sortDesc[0]) {
        sorted.reverse();
      }

      return sorted;
    },
    printTable() {
      this.updateSelectedColumns();

      this.$nextTick(() => {
        let domClone = document.getElementById('printable-appointments').cloneNode(true);

        let $printContent = document.getElementById('print-content');
        if (!$printContent) {
          $printContent = document.createElement('section');
          $printContent.id = 'print-content';
          $printContent.className = 'table-print';
          document.body.appendChild($printContent);
        }

        $printContent.innerHTML = '';
        $printContent.appendChild(domClone);
        window.print();
        this.updateSelectedColumns();

        this.mixpanel.track(this.mixpanel.events.ACTION.PRINT_APPOINTMENTS, {
          'Org Name': this.$org.name,
          'Org ID': this.$org.id,
          'Warehouse Name': this.$selectedWarehouse?.name,
          'Warehouse ID': this.$selectedWarehouse?.id
        });
      });
    },
    updateSelectedColumns() {
      const cts = this.novaCore.deepClone(this.novaCore.sortBy(this.selectedColumns, 'idx'));
      this.columnsToShow = [...cts, ...this.persistentColumns].filter(
        h => !this.printMode || (this.printMode && !h.hidePrint)
      );
    }
  },
  async mounted() {
    await this.handleApptIdURLQuery();
    const storedSelectedColumns = this.storedSelectedColumns;
    this.selectedColumns =
      storedSelectedColumns.length > 0 ? storedSelectedColumns : this.selectColumns;
  },
  watch: {
    $selectedEvent() {
      this.selectedEvent = this.$selectedEvent;
    },
    'filters.searchStr'() {
      if (!this.filters.searchStr) {
        this.expanded = [];
      }
    },
    'filters.dateRange'() {
      this.$nextTick(() => {
        if (this.isRange && this.filters.dateRange.length === 1) {
          return;
        }
        this.options.page = 1;
      });
    },
    selectedColumns() {
      this.updateSelectedColumns();
      this.handleColumnSelect();
    },
    async $route(newVal, oldVal) {
      const shouldFetchEvent =
        newVal.query.appointmentId &&
        newVal.query.appointmentId !== oldVal.query.appointmentId &&
        this.$selectedEvent?.id !== newVal.query.appointmentId;
      if (shouldFetchEvent) {
        await this.fetchAndOpenAppt(newVal.query.appointmentId);
      }
    }
  }
};
</script>

<style lang="scss">
@media print {
  #print-content {
    .printable-window {
      page-break-inside: avoid;
      page-break-before: always;
    }
  }
}
</style>
