import { ref, computed } from 'vue';
import { debounce } from 'lodash';

const DEFAULT_DEBOUNCE_DELAY = 350;

/**
 * @param {object} opts
 * @param {Function} opts.onChange
 * @param {number} [opts.delay]
 * @param {any} [opts.initialValue]
 */
export default function useDebouncedInput({ onChange, ...opts }) {
  const innerValue = ref(opts.initialValue);
  const debounced = ref(debounce(onChange, opts.delay || DEFAULT_DEBOUNCE_DELAY));
  const value = computed({
    get() {
      return innerValue.value;
    },
    set(value_) {
      innerValue.value = value_;
      debounced.value(value_);
    }
  });
  return { value };
}
