var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-base",
    { attrs: { "hide-required-legend": true } },
    [
      _c(
        "template",
        { slot: "form" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("text-field", {
                attrs: {
                  required: true,
                  type: "text",
                  rules: _vm.$validator.rules.required("Company Name"),
                  label: "Company Name",
                },
                model: {
                  value: _vm.fields.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.fields, "name", $$v)
                  },
                  expression: "fields.name",
                },
              }),
              _c("text-field", {
                attrs: {
                  type: "text",
                  label: "SCAC code",
                  rules: _vm.$validator.rules.optionalLengthBetween(2, 4),
                },
                model: {
                  value: _vm.fields.scac,
                  callback: function ($$v) {
                    _vm.$set(_vm.fields, "scac", $$v)
                  },
                  expression: "fields.scac",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "form-actions" },
        [
          _c("v-divider", { staticClass: "mt-8" }),
          _c("action-group", {
            staticClass: "pa-5",
            attrs: {
              "confirm-icon": "upload",
              "confirm-label": "Save Company",
            },
            on: {
              cancel: function ($event) {
                return _vm.$emit("close")
              },
              confirm: _vm.submit,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }