<template>
  <apexchart
    type="treemap"
    height="350"
    :options="chartOptions"
    class="treemap-chart"
    :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

/**
 * @displayName Tree Map Chart
 */
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    /**
     * Title of chart
     */
    title: {
      type: String,
      required: false
    },
    /**
     * Data to display
     */
    data: {
      type: Array,
      required: true
    },
    /**
     * Chart colors
     */
    colors: {
      type: Array,
      required: false,
      default: () => [
        '#3B93A5',
        '#F7B844',
        '#ADD8C7',
        '#EC3C65',
        '#CDD7B6',
        '#C1F666',
        '#D43F97',
        '#1E5D8C',
        '#421243',
        '#7F94B0',
        '#EF6537',
        '#C0ADDB'
      ]
    },
    noDataText: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      series: [
        {
          data: this.data
        }
      ],
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'treemap',
          offsetX: 14,
          toolbar: {
            show: true,
            offsetX: -5,
            offsetY: 10
          }
        },
        grid: {
          padding: {
            right: 0
          }
        },
        title: {
          text: this.title || '',
          offsetY: 5,
          offsetX: -5
        },
        colors: this.colors,
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        },
        noData: {
          text: this.noDataText || 'Not enough data yet...'
        }
      }
    };
  },
  watch: {
    data() {
      this.series = [
        {
          data: this.data
        }
      ];
    }
  }
};
</script>
