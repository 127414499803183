var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: { header: "Change Org Type" },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _vm._e(),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _vm.org
                    ? _c("edit-org-type-form", {
                        attrs: { org: _vm.org },
                        on: { close: _vm.close, save: _vm.updateOrgType },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }