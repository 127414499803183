<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  props: {
    appointment: {
      type: Object,
      required: true
    }
  },
  computed: {
    refNumberPlaceholder() {
      return this.refNumberSettings.helperText || this.refNumberSettings.displayName;
    },
    refNumberSettings() {
      return this.$refNumSettings(this.warehouse) || {};
    }
  },
  data() {
    return {
      previousCarrier: null,
      disableConfirm: false,
      customFields: [],
      refNumber: '',
      notes: '',
      selectedCarrier: null,
      loading: false,
      warehouse: {},
      dock: {}
    };
  },
  async beforeMount() {
    this.refNumber = this.appointment.refNumber;
    this.notes = this.appointment.notes;
    this.selectedCarrier = this.appointment.user;
    this.dock = this.appointment.dock || {};
    this.warehouse = { ...(this.appointment.dock?.warehouse || { docks: [] }), docks: [this.dock] };
    /*
      If this.appointment.customFields is null we need to get the custom fields
      Template from the warehouse
     */
    await this.getFullWarehouse();
  },
  methods: {
    async getFullWarehouse() {
      if (!this.warehouse.customApptFieldsTemplate && !this.appointment.customFields) {
        const warehouse = await this.$store.dispatch(
          'Warehouses/getFullWarehouseById',
          this.warehouse.id
        );

        this.warehouse = {
          ...this.warehouse,
          customApptFieldsTemplate: warehouse.customApptFieldsTemplate
        };

        this.appointment.dock.warehouse = {
          ...this.appointment.dock.warehouse,
          customApptFieldsTemplate: warehouse.customApptFieldsTemplate
        };
      }
    },
    clearCarrierField() {
      this.previousCarrier = this.selectedCarrier;
      this.selectedCarrier = null;
    },
    refillCarrierField() {
      if (!this.selectedCarrier) {
        this.selectedCarrier = this.previousCarrier;
      }
    },
    toggleConfirmButton(disableButton) {
      this.disableConfirm = disableButton;
    },
    async updateAppointment() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await axios
          .patch(`appointment/${this.appointment.id}`, {
            refNumber: this.refNumber,
            notes: this.notes,
            customFields: this.customFields,
            userId: this.selectedCarrier.id,
            loadTypeId: this.appointment.loadTypeId
          })
          .then(response => {
            if (response?.data?.data) {
              this.$store.dispatch('Appointments/triggerSocketEvent', {
                response,
                appointment: this.appointment
              });
              this.$eventHub.$emit('appointment-updated');
              this.$store.dispatch('Appointments/trackMixpanelEvent', {
                appointment: this.appointment,
                change: 'Details'
              });
              this.$emit('close');
            }
          })
          .catch(err => {
            err;
            // TODO: if we don't catch this, then a "[Vue warn]" console.error output will occur,
            // which will cause Laser tests to fail.
            // The actual error handling is being done in the global axios mixin, I think?
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$nextTick(() => {
          const container = document.getElementById('edit-appointment-form');
          this.util.scrollFirstErrorIntoView(container);
        });
      }
    }
  }
};
</script>
