var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-textarea",
    _vm._b(
      {
        class: { required: _vm.field.required },
        attrs: {
          "single-line": "",
          variant: "outlined",
          "hide-details": "auto",
          required: _vm.field.required,
          "prepend-icon": _vm.fieldIcon,
          label: _vm.field.label,
          placeholder: _vm.field.placeholder ?? _vm.field.label,
          "persistent-placeholder": Boolean(_vm.field.placeholder),
          rules: _vm.validationRules,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      },
      "v-textarea",
      [_vm.$props, _vm.$attrs, _vm.$on],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }