var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-base",
    {
      attrs: { "hide-required-legend": true },
      scopedSlots: _vm._u([
        {
          key: "form-actions",
          fn: function () {
            return [
              _c("action-group", {
                attrs: { "confirm-label": "Save" },
                on: {
                  cancel: function ($event) {
                    return _vm.$emit("close")
                  },
                  confirm: _vm.updateAppointment,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "form" },
        [
          _c("v-form", { ref: "form", staticClass: "pt-6" }, [
            _c(
              "div",
              { staticClass: "time-input" },
              [
                _c(
                  "div",
                  { staticClass: "mx-4 my-3" },
                  [
                    _c("date-picker", {
                      attrs: {
                        "compact-display": "",
                        "display-field-label": false,
                        "display-field-icon": false,
                        required: "",
                      },
                      model: {
                        value: _vm.customDate,
                        callback: function ($$v) {
                          _vm.customDate = $$v
                        },
                        expression: "customDate",
                      },
                    }),
                  ],
                  1
                ),
                _c("time-entry", {
                  attrs: { warehouse: _vm.appointment.dock.warehouse },
                  model: {
                    value: _vm.customTime,
                    callback: function ($$v) {
                      _vm.customTime = $$v
                    },
                    expression: "customTime",
                  },
                }),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "menu-container full-width" },
                    [
                      _c(
                        "v-btn-toggle",
                        {
                          staticClass: "full-width",
                          attrs: { color: "primary" },
                          on: { change: _vm.adjustTime },
                        },
                        _vm._l(
                          _vm.timeAdjustmentOptions,
                          function (option, index) {
                            return _c(
                              "v-btn",
                              {
                                key: `${index}-timeline-option`,
                                staticClass: "flex-grow-1",
                                attrs: { dense: "", small: "", outlined: "" },
                              },
                              [_vm._v(" " + _vm._s(option.label) + " ")]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }