var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        top: "",
        "nudge-bottom": "105",
        "nudge-left": "16",
        "close-on-click": false,
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g(
                  {
                    staticClass: "color-picker-activator",
                    style: _vm.activatorStyle,
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c(
        "v-card",
        [
          _vm.headerText
            ? _c(
                "v-card-subtitle",
                {
                  style: `color:${_vm.textColor};background-color:${
                    _vm.headerBackground || _vm.value
                  }`,
                },
                [
                  _vm._v(" " + _vm._s(_vm.headerText) + " "),
                  _vm.shouldShowCloseButton
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            top: "",
                            right: "",
                            "x-small": "",
                            icon: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.close(false)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: _vm.textColor } }, [
                            _vm._v("mdi-close"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c("v-color-picker", {
                attrs: {
                  value: _vm.value,
                  flat: "",
                  "show-swatches": "",
                  "hide-inputs": "",
                  "hide-mode-switch": "",
                  swatches: _vm.swatches,
                },
                on: { input: (value) => _vm.$emit("input", value) },
              }),
            ],
            1
          ),
          _vm.shouldShowFooter
            ? _c(
                "v-card-actions",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          return _vm.close(false)
                        },
                      },
                    },
                    [_vm._v(" Nevermind ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          return _vm.close(true)
                        },
                      },
                    },
                    [_vm._v(" Select ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }