export default {
  data() {
    return {
      editingAddress: {},
      geocodingData: null,
      manualAddressMode: false
    };
  },
  computed: {
    defaultPhoneCountry() {
      if (this.editingAddress.country) {
        const country = this.novaCore.ISO3166Countries[this.editingAddress.country];
        return country.iso2;
      }

      return 'US';
    }
  },
  methods: {
    enterManualAddressMode() {
      this.manualAddressMode = true;
      this.$nextTick(() => {
        this.$refs.manualStreetField.$el.querySelector('input').focus();
      });
    },
    enterSearchAddressMode() {
      this.manualAddressMode = false;
      this.$nextTick(() => {
        this.$refs.searchAddressField.$el.querySelector('input').focus();
      });
    },
    fillFullAddress({ streetAddress, state, postalCode, city, timezone }) {
      if (!(streetAddress && postalCode)) {
        this.enterManualAddressMode();
        this.notify(
          'The selected address is incomplete, please fill-in the address manually.',
          'warning'
        );
      } else {
        this.editingAddress = {
          ...this.editingAddress,
          state,
          city,
          timezone,
          zip: postalCode,
          street: streetAddress
        };
      }
    }
  },
  watch: {
    'editingAddress.country': {
      handler(newVal, oldVal) {
        if (oldVal && newVal !== oldVal) {
          this.manualAddressMode = false;
          this.editingAddress = {
            ...this.editingAddress,
            state: '',
            city: '',
            zip: '',
            street: '',
            timezone: ''
          };
        }
      }
    }
  }
};
