var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tv-calendar",
      style: { zoom: `${_vm.currentZoom}% !important` },
    },
    [
      _c("div", { staticClass: "calendar-header" }, [
        _c("div", { staticClass: "left-header-div" }, [
          _c("img", {
            staticClass: "logo",
            attrs: { alt: "logo", src: "/images/opendock_graphic_dark.svg" },
          }),
          _c(
            "div",
            { staticClass: "today-date" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", color: "grey darken-2" },
                          on: {
                            click: function ($event) {
                              return _vm.setToday()
                            },
                          },
                        },
                        [_vm._v(" Today")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "", rounded: "" },
                          on: { click: _vm.prev },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-chevron-left"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "", rouded: "" },
                          on: { click: _vm.next },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-chevron-right"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDateToHeader(_vm.selectedDate)) +
                          " "
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-1 darken-1",
                          attrs: { small: "", title: "Lock on current date" },
                        },
                        [_vm._v(" " + _vm._s(_vm.lockDateIcon) + " ")]
                      ),
                      _vm.autoScroll
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "ml-1 darken-1",
                              attrs: {
                                small: "",
                                title: "Follow current time",
                              },
                            },
                            [_vm._v(" mdi-update ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "warehouse-name" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "right-header-div" }, [
          _c("div", { staticClass: "today-date" }, [
            _vm._v(" " + _vm._s(_vm.currentTime) + " "),
            _c(
              "span",
              { staticClass: "mx-3" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "decrease-zoom-button",
                    attrs: { icon: "", "x-small": "", rounded: "" },
                    on: {
                      click: function ($event) {
                        return _vm.decreaseZoom()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-minus")])],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2 increase-zoom-button",
                    attrs: { icon: "", "x-small": "", rounded: "" },
                    on: {
                      click: function ($event) {
                        return _vm.increaseZoom()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-plus")])],
                  1
                ),
                _c(
                  "v-menu",
                  {
                    attrs: {
                      absolute: "",
                      "offset-y": "",
                      "close-on-content-click": false,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      icon: "",
                                      "x-small": "",
                                      rounded: "",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-cog")])],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    "x-small": "",
                                    label: "Follow current time",
                                  },
                                  model: {
                                    value: _vm.autoScroll,
                                    callback: function ($$v) {
                                      _vm.autoScroll = $$v
                                    },
                                    expression: "autoScroll",
                                  },
                                }),
                                _c("v-checkbox", {
                                  attrs: {
                                    "x-small": "",
                                    label: "Lock on current date",
                                  },
                                  model: {
                                    value: _vm.lockCurrentDate,
                                    callback: function ($$v) {
                                      _vm.lockCurrentDate = $$v
                                    },
                                    expression: "lockCurrentDate",
                                  },
                                }),
                                _c("hr"),
                                _c("v-checkbox", {
                                  attrs: {
                                    "x-small": "",
                                    label: "Show appointment tags",
                                  },
                                  model: {
                                    value: _vm.showAppointmentTags,
                                    callback: function ($$v) {
                                      _vm.showAppointmentTags = $$v
                                    },
                                    expression: "showAppointmentTags",
                                  },
                                }),
                                _c("v-checkbox", {
                                  attrs: {
                                    "x-small": "",
                                    label: "Show appointment fields",
                                  },
                                  model: {
                                    value: _vm.showAppointmentFields,
                                    callback: function ($$v) {
                                      _vm.showAppointmentFields = $$v
                                    },
                                    expression: "showAppointmentFields",
                                  },
                                }),
                                _c("v-checkbox", {
                                  attrs: {
                                    "x-small": "",
                                    label: "Show parallel capacity",
                                  },
                                  model: {
                                    value: _vm.showParallelCapacity,
                                    callback: function ($$v) {
                                      _vm.showParallelCapacity = $$v
                                    },
                                    expression: "showParallelCapacity",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "warehouse-name" }, [
            _vm._v(" " + _vm._s(_vm.formatTimezoneString()) + " "),
          ]),
        ]),
      ]),
      _c("v-calendar", {
        ref: "calendar",
        attrs: {
          type: "category",
          color: "primary",
          "interval-minutes": "30",
          "interval-width": "90",
          "interval-height": "100",
          "category-for-invalid": "No Dock",
          "event-overlap-mode": "column",
          "interval-count": (24 * 60) / 30,
          "interval-format": _vm.formatInterval,
          events: _vm.appointments,
          title: _vm.title,
          categories: _vm.categories,
          "event-category": _vm.getEventCategory,
          "event-color": _vm.getEventClasses,
          "short-intervals": true,
          "weekday-format": () => {},
          "category-show-all": "",
        },
        scopedSlots: _vm._u([
          {
            key: "day-label-header",
            fn: function ({ date }) {
              return [_c("div")]
            },
          },
          {
            key: "category",
            fn: function ({ category }) {
              return [_vm._v(" " + _vm._s(_vm.getDockNameById(category)) + " ")]
            },
          },
          {
            key: "day-body",
            fn: function ({ category }) {
              return [
                _c("div", {
                  staticClass: "v-current-time",
                  class: { first: category === _vm.firstCategory },
                  style: { top: _vm.nowY },
                }),
              ]
            },
          },
          {
            key: "event",
            fn: function ({ event }) {
              return [
                _c("event-tv-mode", {
                  attrs: {
                    event: event,
                    showAppointmentFields: _vm.showAppointmentFields,
                    timezone: _vm.warehouse?.timezone,
                    showAppointmentTags: _vm.showAppointmentTags,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedDate,
          callback: function ($$v) {
            _vm.selectedDate = $$v
          },
          expression: "selectedDate",
        },
      }),
      _c("v-footer", { attrs: { app: "", bottom: "", fixed: "" } }, [
        _c("span", { staticClass: "text" }, [
          _c("strong", [
            _vm._v(
              _vm._s(_vm.totalAppointments) +
                " scheduled - " +
                _vm._s(_vm.cancelledAppointments) +
                " cancelled"
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }