import { ref } from 'vue';
import { useNovaCore, useStore } from '@/composables/index';
import { DateTime } from 'luxon';
import messageThreadService from '@satellite/services/message-thread-service';

export default function useMessageThread(assetVisit) {
  const messageThreadRef = ref({});
  const novaCore = useNovaCore();
  const store = useStore();
  const fetchMessageThread = async () => {
    const response = await messageThreadService.getMessageThreadByAssetVisitId(assetVisit.id);

    if (response) {
      messageThreadRef.value = response;
    }
  };

  const sendMessage = async (messageThread, formData) => {
    return messageThreadService.sendMessage(messageThread.id, formData);
  };

  const postLastReadMessage = async (messageThread, messageId) => {
    if (!messageThread) {
      await fetchMessageThread();
      messageThread = messageThreadRef.value;
    }
    if (messageThread.lastReadMessageId === messageId) {
      return;
    }
    await messageThreadService.updateLastReadMessage(messageThread.id, messageId);
  };

  const isIncomingMessage = message => {
    return message.sourceType === novaCore.MessageSourceType.DRIVER;
  };

  const isOutgoingMessage = message => {
    return !isIncomingMessage(message);
  };

  const isSystemMessage = message => {
    return message.sourceType === novaCore.MessageSourceType.SYSTEM;
  };

  const getIncomingMessages = messages => {
    return messages.filter(isIncomingMessage);
  };

  const getLastUnreadIncomingMessage = (messages, lastReadId) => {
    const incMessages = getIncomingMessages(messages);
    const lastReadIndex = incMessages.findIndex(message => message.id === lastReadId);
    return incMessages[lastReadIndex];
  };

  // Is the message the last unread message in the thread?
  const isLastReadMessage = (message, messages, lastReadId) => {
    if (!message || !messages?.length || !lastReadId) {
      return false;
    }
    const lastMessage = getLastUnreadIncomingMessage(messages, lastReadId);
    return lastMessage?.id === message.id;
  };

  // Is the last message of the message thread read?
  const isLastMessageRead = messageThread => {
    const lastMessage = messageThread.messages[messageThread.messages.length - 1];
    if (isSystemMessage(lastMessage)) {
      return true;
    }
    return lastMessage.id === messageThread.lastReadMessageId;
  };

  const makeChatTimestamp = (date, warehouse, includeDate = true, includeTime = true) => {
    const dateTime = DateTime.fromISO(date).setZone(warehouse.timezone);
    const now = DateTime.now().setZone(warehouse.timezone);

    if (!includeDate && includeTime) {
      return novaCore.formatDateTimeWithMilitarySupport(
        dateTime,
        warehouse.timezone,
        novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        store.getters['Settings/isMilitaryTimeEnabled'](warehouse),
        novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    }

    const timeFormat = includeTime
      ? ' • ' +
        novaCore.formatDateTimeWithMilitarySupport(
          dateTime,
          warehouse.timezone,
          novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
          store.getters['Settings/isMilitaryTimeEnabled'](warehouse),
          novaCore.LuxonDateTimeFormats.Extended24HrTime
        )
      : '';

    if (includeDate) {
      if (dateTime.hasSame(now, 'day')) {
        return `Today${timeFormat}`;
      } else if (dateTime.hasSame(now.minus({ days: 1 }), 'day')) {
        return `Yesterday${timeFormat}`;
      } else {
        return dateTime.toFormat(`MMMM d yyyy${timeFormat}`);
      }
    }

    return '';
  };

  const toggleThreadOpenStatus = async messageThread => {
    if (messageThread.isOpen) {
      return messageThreadService.closeMessageThread(messageThread.id);
    } else {
      return messageThreadService.openMessageThread(messageThread.id);
    }
  };

  return {
    messageThreadRef,
    fetchMessageThread,
    isIncomingMessage,
    isSystemMessage,
    postLastReadMessage,
    isLastReadMessage,
    getLastUnreadIncomingMessage,
    getIncomingMessages,
    isLastMessageRead,
    sendMessage,
    makeChatTimestamp,
    toggleThreadOpenStatus,
    isOutgoingMessage
  };
}
