var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    {
      key: _vm.renderKey,
      attrs: {
        header: "Register with Opendock",
        "loading-message": "Registering User...",
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (slotProps) {
            return [
              _c(
                "outline-button",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-2",
                      attrs: { "before-icon": "account-plus", large: "" },
                    },
                    "outline-button",
                    slotProps.attrs,
                    false
                  ),
                  slotProps.on
                ),
                [_vm._v("Register")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "body" },
        [_c("registration-form", { on: { close: _vm.close } })],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }