<template>
  <div>
    <v-tabs
      grow
      ref="tabs"
      background-color="secondary"
      v-model="tab"
      dark
      slider-color="primary"
      centered
      id="insights-tabs">
      <v-tab key="all" :to="{ name: 'insights' }" v-if="!novaCore.isInternalUser($me)">
        All Insights
      </v-tab>
      <v-tab key="carrier" :to="{ name: 'insights.carrier' }" v-if="!novaCore.isInternalUser($me)">
        Carrier Insights
      </v-tab>
      <v-tab
        key="warehouse"
        :to="{ name: 'insights.warehouse' }"
        v-if="!novaCore.isInternalUser($me)">
        Warehouse Insights
      </v-tab>
      <v-tab
        key="reporting"
        :to="{ name: 'insights.reporting' }"
        v-if="!novaCore.isInternalUser($me)">
        Reporting
      </v-tab>
      <v-tab
        key="internal"
        v-if="novaCore.isInternalRole($me.role)"
        :to="{ name: 'insights.internal' }">
        Internal Insights
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="all" :value="route('insights')" v-if="!novaCore.isInternalUser($me)">
        <v-card flat v-if="tab === '/insights'">
          <feature-flag name="enable-power-bi-all-insights">
            <template #enabled>
              <power-bi-insights :title="insightsTitle" :url="insightsUrl"></power-bi-insights>
            </template>
            <template #disabled>
              <chart-grid></chart-grid>
            </template>
          </feature-flag>
        </v-card>
      </v-tab-item>
      <v-tab-item
        key="carrier"
        :value="route('insights.carrier')"
        v-if="!novaCore.isInternalUser($me)">
        <power-bi-insights :title="carrierTitle" :url="carrierUrl"></power-bi-insights>
      </v-tab-item>
      <v-tab-item
        key="warehouse"
        :value="route('insights.warehouse')"
        v-if="!novaCore.isInternalUser($me)">
        <power-bi-insights
          :title="warehouseTitle"
          :url="warehouseUrl"
          v-if="tab === '/insights/warehouse'"></power-bi-insights>
      </v-tab-item>
      <v-tab-item
        key="reporting"
        :value="route('insights.reporting')"
        v-if="!novaCore.isInternalUser($me)">
        <appointment-reporting-table
          v-if="tab === '/insights/reporting'"></appointment-reporting-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  data() {
    return {
      insightsTitle: 'All Insights',
      insightsUrl: '/reporting/all-insights',
      carrierTitle: 'Carrier Performance',
      carrierUrl: '/reporting/carrier-insights',
      warehouseTitle: 'Warehouse Performance',
      warehouseUrl: '/reporting/warehouse-insights',
      tab: null,
      tabsName: {
        '/insights/reporting': 'Reporting',
        '/insights': 'All Insights',
        '/insights/carrier': 'Carrier Insights',
        '/insights/warehouse': 'Warehouse Insights'
      }
    };
  },
  mounted() {},
  watch: {
    tab(value, oldValue) {
      // this will avoid trigerring "all insights" event every time it loads the page
      if (oldValue !== null) {
        this.mixpanel.track(this.mixpanel.events.MODULE.REPORTING.VIEW_PAGE, {
          'Org Name': this.$org.name,
          'Org ID': this.$org.id,
          'Insights Tab': this.tabsName[value]
        });
      }
    }
  }
};
</script>

<style lang="scss">
#insights-tabs {
  .v-tabs-slider-wrapper,
  .v-tabs-slider-wrapper {
    height: 4px !important;
  }
}
</style>
