<template>
  <v-select
    :value="value"
    @input="value => $emit('input', value)"
    :items="customTags"
    ref="customTagSelect"
    v-bind="[$props, $attrs]"
    item-text="name"
    item-value="name"
    return-object
    :label="label"
    multiple
    chips
    persistent-hint>
    <template v-slot:prepend-item v-if="customTags.length">
      <v-list-item ripple @click="toggleSelectAllItems">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider slot="prepend-item" class="mt-2" />
    </template>

    <template v-slot:item="{ item, attrs, on }">
      <v-list-item
        v-on="on"
        v-bind="attrs"
        selectable
        #default="{ active }"
        :color="item.color"
        active-class="not-real"
        :style="`background-color:${item.color}!important;`">
        <v-list-item-action>
          <v-checkbox
            :ripple="false"
            :color="item.textColor"
            :background-color="item.color"
            :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title :style="`color:${item.textColor}!important;`">
            <v-row no-gutters align="center">
              {{ item.name }}
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0"
        ><v-chip small :color="item.color" :text-color="item.textColor" class="mt-1">{{
          item.name
        }}</v-chip></span
      >
      <span v-if="index === 1" class="grey--text text-caption ml-2 mt-1">
        (+{{ value.length - 1 }} others)
      </span>
    </template>
  </v-select>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';

export default {
  mixins: [selectMixin, selectAllSelectMixin],
  props: {
    /**
     * @model
     */
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: 'Custom Tags'
    }
  },
  data: () => ({
    customTags: [],
    selectRefKey: 'customTagSelect'
  }),
  computed: {
    selectableItems() {
      return this.customTags;
    }
  },
  mounted() {
    this.customTags = this.$org.customTags || [];
  }
};
</script>
