<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    :key="renderKey"
    header=""
    @close="close"
    has-dialog-actions>
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button
        before-icon="pencil"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2">
        Edit Time
      </primary-button>
    </template>
    <template v-slot:body>
      <v-alert :type="alertType" class="mb-0" shaped v-if="alertMessage">
        {{ alertMessage }}
      </v-alert>

      <status-timeline-edit-form-mobile
        :header="dialogHeader"
        :mixpanel-entry-point="mixpanelEntryPoint"
        :appointment="appointment"
        @close="close"
        @mounted="setDialogPosition"
        :status-to-edit="statusToEdit"></status-timeline-edit-form-mobile>
    </template>
  </dialog-base>
</template>

<script>
import StatusTimelineEditDialogBase from '@/modules/appointments/components/StatusTimelineEditDialogBase.vue';

export default {
  name: 'StatusTimelineEditDialogMobile',
  extends: StatusTimelineEditDialogBase,
  methods: {
    setDialogPosition() {
      const formHeight = document
        .getElementById('status-timeline-edit-mobile')
        .getBoundingClientRect().height;
      const dialog = document.querySelector('.status-timeline-edit-dialog-mobile');
      const dialogHeight = dialog.getBoundingClientRect().height;
      dialog.style.cssText = `height: ${formHeight}px; top: ${dialogHeight - formHeight}px;`;
    }
  }
};
</script>
