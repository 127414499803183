<template>
  <v-card elevation="0">
    <v-card-title class="d-flex flex-row card-title">
      <h4 data-testid="yard-spot-multi-selected-title">Selected</h4>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('clear')" data-testid="yard-spot-multi-selected-close-button">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text data-testid="yard-spot-multi-selected-content" class="full-height">
      <div v-for="spot of selectedSpots" :key="spot.id" class="d-flex flex-column mb-4">
        <h3 class="text-uppercase">
          {{ spot.code }}
        </h3>
        <span class="text-capitalize">
          <v-icon x-small color="red" v-if="getSpotStatus(spot) === SpotStatus.ASSIGNED">
            mdi-clock-outline
          </v-icon>
          {{ getSpotStatus(spot) }}
        </span>
      </div>
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-btn
        outlined
        block
        color="error"
        @click="isDeleteConfirmationVisible = true"
        data-testid="yard-spot-multi-selected-delete-button">
        <v-icon small class="mr-2">mdi-delete-outline</v-icon>
        Delete Spots
      </v-btn>
    </v-card-actions>
    <confirm
      :should-show="isDeleteConfirmationVisible"
      delete-confirmation
      show-delete-warning
      persistent
      v-if="isDeleteConfirmationVisible"
      @result="handleDeleteConfirmationResult"
      title="Delete these Spots?"
      :message="`Are you sure to delete ${selectedSpots.length} spot${
        selectedSpots.length > 1 ? 's' : ''
      }?`"
      button-true-text="YES, DELETE"
      button-true-color="error"
      :width="650"></confirm>
  </v-card>
</template>
<script>
import { defineComponent, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { SpotStatus } from '@nova/core';

export default defineComponent({
  name: 'YardViewSpotAreaMultiSelected',
  emits: ['clear'],
  setup() {
    const yardStore = useYard();
    const selectedSpots = yardStore.selectedSpotsRef;
    const isDeleteConfirmationVisible = ref(false);

    const handleDeleteConfirmationResult = result => {
      if (result) {
        deleteManySpot();
      }
      isDeleteConfirmationVisible.value = false;
    };

    const deleteManySpot = () => {
      yardStore.deleteManySpot(selectedSpots.value.map(s => s.id));
      selectedSpots.value = [];
    };

    return {
      selectedSpots,
      SpotStatus,
      isDeleteConfirmationVisible,
      handleDeleteConfirmationResult,
      getSpotStatus: yardStore.getSpotStatus,
      deleteManySpot
    };
  }
});
</script>
<style lang="scss" scoped>
.v-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.full-height {
  height: 100%;
  overflow-y: auto;
}
</style>
