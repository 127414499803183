var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          loading: _vm.isLoading,
          header: "Audit Log",
          width: "900px",
          "max-width": "100%",
        },
        on: {
          open: function ($event) {
            _vm.isVisible = true
            _vm.getAuditLog()
          },
          close: function ($event) {
            _vm.isVisible = false
            _vm.close()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "activator",
              fn: function (slotProps) {
                return [
                  _vm.$scopedSlots.activator
                    ? _vm._t("activator", null, null, slotProps)
                    : _c(
                        "primary-button",
                        _vm._g(
                          _vm._b({}, "primary-button", slotProps.attrs, false),
                          slotProps.on
                        ),
                        [_vm._v("Audit Log")]
                      ),
                ]
              },
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("p", [
                    _vm._v(
                      " Timeline for all updates made to this " +
                        _vm._s(_vm.entity) +
                        ": "
                    ),
                    _c("strong", [_vm._v(_vm._s(_vm.entityName))]),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c("v-col", { attrs: { md: "1" } }, [
                        _c("h4", [_vm._v("Filter:")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c("date-picker", {
                            attrs: {
                              maxDate: _vm.maxFilterDate,
                              outlined: "",
                              "hide-details": "",
                              "inner-icon": "",
                              placeholder: "Events from date",
                            },
                            on: { input: _vm.setLogLines },
                            model: {
                              value: _vm.searchDate,
                              callback: function ($$v) {
                                _vm.searchDate = $$v
                              },
                              expression: "searchDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-4",
                          attrs: { md: "6", "offset-md": "2" },
                        },
                        [
                          _c("text-field", {
                            staticClass: "mb-5",
                            attrs: {
                              outlined: "",
                              "append-icon": "mdi-magnify",
                              label: "Search event...",
                              "data-testid": `filter-audit-log-${_vm.objectId}`,
                              "single-line": "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.searchStr,
                              callback: function ($$v) {
                                _vm.searchStr = $$v
                              },
                              expression: "searchStr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isLoading
                    ? _c("audit-log-entity-form", {
                        attrs: {
                          "log-lines": _vm.logLines,
                          "search-str": _vm.searchStr,
                          timezone: _vm.timezone,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.showDialog,
          callback: function ($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog",
        },
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }