<template>
  <v-card v-if="ready" class="elevation-0">
    <v-card-text class="pa-1">
      <bar-chart title="Appointments by Carrier" :data="chartData" :colors="colors"></bar-chart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
</template>

<script>
import insightsMixin from '@/components/mixins/insightsMixin';

/**
 * Appointments By Carrier Bar Chart
 * @displayName Appointments By Carrier Bar Chart
 */
export default {
  mixins: [insightsMixin],
  data() {
    return {
      apiEndpoint: 'carrier'
    };
  },
  methods: {
    /**
     * Format chart data
     * @public
     */
    formatData() {
      this.makeChartData();
      this.chartData = this.bucketOverflowResults(this.chartData, 4, 'y', 'Other');
      this.ready = true;
    },
    bucketOverflowResults(data, maxCount, sumProperty, overflowName = 'Other') {
      data.forEach(d => {
        d.y = parseInt(d.y);
      });
      if (data.length <= maxCount + 1) {
        // If there are one or less "others" then we should not modify the data
        return data;
      }
      const result = data;
      this.util.sortObjectArray(result, sumProperty, true);
      const other = result.splice(maxCount, data.length - maxCount);
      const otherTotal = other.reduce((accum, d) => accum + parseInt(d[sumProperty]), 0);

      result.push({
        x: overflowName,
        y: otherTotal
      });

      return result;
    }
  }
};
</script>
