var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        staticStyle: { "z-index": "20001" },
        attrs: {
          header: "We value your privacy and security",
          loading: _vm.loading,
          "max-width": "100%",
          value: _vm.showDialog,
          hideCloseIcon: true,
        },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function (slotProps) {
              return undefined
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    " We made a few changes in order to make Opendock even safer to use. Please review "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://loadsmart.com/subscription-and-professional-services-agreement/",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Loadsmart's Terms & Conditions")]
                  ),
                  _vm._v(" and "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://loadsmart.com/privacy-policy/",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Loadsmart's Privacy Policy")]
                  ),
                  _vm._v(
                    ", and mark your consent below to continue using Opendock. "
                  ),
                ]),
                _c("v-checkbox", {
                  attrs: {
                    "x-small": "",
                    label:
                      "I have read and agree to be bound by Loadsmart's Terms & Conditions and subject to Loadsmart's Privacy Policy in connection with my use of the Opendock platform.",
                  },
                  model: {
                    value: _vm.confirmed,
                    callback: function ($$v) {
                      _vm.confirmed = $$v
                    },
                    expression: "confirmed",
                  },
                }),
                _c("p", [
                  _vm._v(
                    " If you need any help, reach out to our support team "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://community.loadsmart.com/hc/en-us/community/topics/14812876253715-OpenDock",
                        target: "_blank",
                      },
                    },
                    [_vm._v("through this link")]
                  ),
                  _vm._v(". "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "dialog-actions",
            fn: function () {
              return [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "font-weight-bold",
                    attrs: {
                      elevation: "0",
                      color: "success",
                      disabled: _vm.confirmed === false,
                    },
                    on: { click: _vm.handleConfirm },
                  },
                  [_vm._v(" Confirm ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }