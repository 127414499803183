var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    staticClass: "px-8 pt-6 hours-of-operation-form",
    class: {
      disabled: _vm.readOnly,
      "half-width": !_vm.readOnly,
      "pb-4": _vm.readOnly,
    },
    attrs: { fluid: "", "hide-required-legend": true },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              [
                _c("span", { staticClass: "text-h6" }, [
                  _vm._v(
                    "Standard Hours (" + _vm._s(_vm.warehouse.timezone) + ")"
                  ),
                ]),
                _vm._l(_vm.weekdays, function (weekday) {
                  return _c("hours-of-operation-item", {
                    key: weekday,
                    attrs: {
                      "read-only": _vm.readOnly,
                      "date-range-select-items": _vm.dateRangeSelectItems,
                      weekday: weekday,
                    },
                    on: { "copy-schedule": _vm.copySchedule },
                    model: {
                      value: _vm.schedule[weekday],
                      callback: function ($$v) {
                        _vm.$set(_vm.schedule, weekday, $$v)
                      },
                      expression: "schedule[weekday]",
                    },
                  })
                }),
                _c("div", { staticClass: "text-h6 mt-12" }, [
                  _vm._v("Closed dates"),
                ]),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "Set closed dates for holidays, events or other exceptional dates"
                  ),
                ]),
                _vm.warehouse.id
                  ? _c("days-off", {
                      attrs: {
                        schedule: _vm.warehouse.schedule,
                        warehouse: _vm.warehouse,
                      },
                      on: {
                        "update-closed-intervals": _vm.updateClosedIntervals,
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "disable-confirm": !_vm.areClosedIntervalsValid || _vm.readOnly,
                loading: _vm.loading,
                "hide-cancel": true,
                "confirm-label": "Save Operating Hours",
              },
              on: { confirm: _vm.updateSchedule },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }