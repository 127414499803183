var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-4 full-width" },
    [
      _vm.label
        ? _c(
            "h3",
            {
              staticClass: "mr-4",
              class: _vm.disabled ? "text--disabled" : "",
            },
            [_vm._v(_vm._s(_vm.label) + ":")]
          )
        : _vm._e(),
      _c(
        "v-autocomplete",
        _vm._b(
          {
            ref: "carrierSelect",
            staticClass: "pt-0",
            attrs: {
              required: _vm.required,
              rules: _vm.rules,
              "hide-details": "auto",
              "search-input": _vm.filters.searchStr,
              value: _vm.value,
              items: _vm.carriers,
              "item-value": "id",
              "no-filter": "",
              loading: _vm.isLoading,
              "return-object": "",
              disabled: _vm.disabled,
            },
            on: {
              input: _vm.updateValue,
              "update:searchInput": function ($event) {
                return _vm.$set(_vm.filters, "searchStr", $event)
              },
              "update:search-input": function ($event) {
                return _vm.$set(_vm.filters, "searchStr", $event)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      [
                        _c(
                          "div",
                          { staticClass: "full-width" },
                          [
                            _c(
                              "v-list-item",
                              {
                                attrs: {
                                  color: "primary",
                                  "input-value": _vm.isCarrierFavorite(item),
                                },
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getCarrierName(item)) +
                                          " - " +
                                          _vm._s(item.company.name) +
                                          " - " +
                                          _vm._s(item.email) +
                                          " "
                                      ),
                                    ]),
                                    _c("v-list-item-subtitle", [
                                      _vm._v(
                                        " SCAC: " +
                                          _vm._s(item.company.scac) +
                                          " "
                                      ),
                                      _vm.getCarrierEmailCCs(item).length > 0
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    "open-on-click": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "ml-3",
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-account-multiple"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "+" +
                                                                      _vm._s(
                                                                        _vm.getCarrierEmailCCs(
                                                                          item
                                                                        ).length
                                                                      ) +
                                                                      " email subscribers"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "my-2" },
                                                    _vm._l(
                                                      _vm.getCarrierEmailCCs(
                                                        item
                                                      ),
                                                      function (email) {
                                                        return _c(
                                                          "p",
                                                          {
                                                            key: email,
                                                            staticClass: "mb-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(email) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.isCarrierFavorite(item)
                                  ? _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-star")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.carriers.indexOf(item) < _vm.carriers.length - 1
                              ? _c("v-divider", {
                                  key: _vm.carriers.indexOf(item),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                    ]
                  },
                },
                {
                  key: "selection",
                  fn: function ({ item }) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.getSelectedCarrierText(item, 15))),
                      ]),
                    ]
                  },
                },
                {
                  key: "no-data",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.filters.searchStr && !_vm.carriers.length
                                    ? "No matching carrier contacts found..."
                                    : _vm.placeholder
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                _vm.required
                  ? {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(" " + _vm._s(_vm.placeholder) + " "),
                          _c("span", { staticClass: "red--text" }, [
                            _c("strong", [_vm._v("*")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "v-autocomplete",
          [_vm.$attrs, _vm.$props],
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }