var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _vm.loading
              ? [
                  _c(
                    "v-card-text",
                    [
                      _vm._v(" Loading Appointment Data... "),
                      _c("v-progress-linear", {
                        attrs: {
                          color: "secondary",
                          indeterminate: "",
                          rounded: "",
                          height: "8",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _c(
                  "v-form",
                  { ref: "form" },
                  [
                    _vm._l(_vm.formFields, function (localField) {
                      return _c("div", { key: localField.formFieldId }, [
                        _c(
                          "p",
                          { staticClass: "mb-2 mt-2 font-size-small" },
                          [
                            _c("strong", { staticClass: "mr-1" }, [
                              _vm._v(_vm._s(localField.customField.label)),
                            ]),
                            localField.customField.description
                              ? _c(
                                  "help-icon-tooltip",
                                  {
                                    attrs: { "icon-style-class": "secondary" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          localField.customField.description
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "mb-7" },
                          [
                            _c(
                              _vm.customFieldComponents[
                                localField.customField.type
                              ],
                              {
                                tag: "component",
                                attrs: {
                                  field: localField.customField,
                                  warehouse: _vm.warehouse,
                                  "single-line": "",
                                },
                                model: {
                                  value: localField.value,
                                  callback: function ($$v) {
                                    _vm.$set(localField, "value", $$v)
                                  },
                                  expression: "localField.value",
                                },
                              }
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    _vm.formFields.length === 0
                      ? _c("div", [
                          _c("h3", [
                            _vm._v(
                              "No fields have been set for this form, please skip it."
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "confirm-label": _vm.shouldShowNextForm ? "Next" : "Confirm",
                "cancel-label": "Nevermind",
                "cancel-icon": "",
                "stack-actions": _vm.stackActions,
              },
              on: {
                cancel: function ($event) {
                  return _vm.$emit("close")
                },
                confirm: function ($event) {
                  _vm.shouldShowNextForm ? _vm.nextForm() : _vm.submit()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "additional-actions",
                  fn: function () {
                    return [
                      _vm.wasAutoTriggered
                        ? _c(
                            "v-btn",
                            {
                              class: { "full-width": _vm.stackActions },
                              attrs: { outlined: "", color: "secondary" },
                              on: { click: _vm.skip },
                            },
                            [
                              _vm._v(
                                " Skip " + _vm._s(_vm.trigger.flow.name) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }