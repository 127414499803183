<template>
  <div class="calendar-label">
    <span
      v-if="[calendarOptions.DAY, calendarOptions.WEEK].includes(viewType)"
      class="text-subtitle-1 font-weight-bold">
      {{ title }}
    </span>
    <v-menu
      ref="menu"
      v-model="datePicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" depressed text class="text-none font-weight-regular">
          <v-icon>mdi-calendar</v-icon>
          {{ label }}
        </v-btn>
      </template>
      <v-date-picker
        color="accent"
        :value="value"
        @input="onChange"
        :type="calendarType"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { CalendarViewEnum } from '@/enums';

export default {
  name: 'CalendarDateSelect',
  props: {
    /**
     * String representing the date
     */
    value: {
      type: String,
      required: true
    },
    /**
     * DAY, WEEK, or MONTH
     */
    viewType: {
      type: String,
      required: true
    },
    /**
     * False if using list mode
     */
    gridMode: {
      type: Boolean,
      required: true
    },
    /**
     * Start and end of the week, as date strings.
     * Used only if in weekview (grid).
     */
    weekDetails: {
      type: Object,
      required: false,
      default() {
        return { start: {}, end: {} };
      }
    }
  },
  computed: {
    calendarType() {
      return 'date';
    },
    weekViewDetails() {
      const start = this.gridMode
        ? this.weekDetails.start
        : { date: momentjs(this.value).startOf('week').format() };
      const end = this.gridMode
        ? this.weekDetails.end
        : { date: momentjs(this.value).endOf('week').format() };
      return { start, end };
    },
    title() {
      if (this.viewType === this.calendarOptions.DAY) {
        return momentjs(this.value).format(this.novaCore.DateTimeFormats.FullWeekday);
      } else if (this.viewType === this.calendarOptions.WEEK) {
        return `${momentjs(this.weekViewDetails.start.date).format(
          this.novaCore.DateTimeFormats.FullWeekday
        )} - ${momentjs(this.weekViewDetails.end.date).format(
          this.novaCore.DateTimeFormats.FullWeekday
        )}`;
      } else {
        return '';
      }
    },
    label() {
      if (this.viewType === this.calendarOptions.DAY) {
        return momentjs(this.value).format(this.novaCore.DateTimeFormats.LongDateFull);
      } else if (this.viewType === this.calendarOptions.WEEK) {
        let startMonth = momentjs(this.weekViewDetails.start.date).format(
          this.novaCore.DateTimeFormats.ShortMonth
        );
        let endMonth = momentjs(this.weekViewDetails.end.date).format(
          this.novaCore.DateTimeFormats.ShortMonth
        );
        let isSameMonth = startMonth === endMonth;
        let startYear = momentjs(this.weekViewDetails.start.date).format(
          this.novaCore.DateTimeFormats.FullYear
        );
        let endYear = momentjs(this.weekViewDetails.end.date).format(
          this.novaCore.DateTimeFormats.FullYear
        );
        let isSameYear = startYear === endYear;
        let startDay = momentjs(this.weekViewDetails.start.date).format(
          this.novaCore.DateTimeFormats.FullDayOfMonth
        );
        let endDay = momentjs(this.weekViewDetails.end.date).format(
          this.novaCore.DateTimeFormats.FullDayOfMonth
        );

        return `${startMonth} ${startDay} ${!isSameYear ? `, ${startYear}` : ''} - ${
          !isSameMonth ? endMonth : ''
        } ${endDay}, ${endYear}`;
      } else {
        return momentjs(this.value).format(this.novaCore.DateTimeFormats.FullMonthYear);
      }
    },
    calendarOptions() {
      return {
        DAY: CalendarViewEnum.DAY.toUpperCase(),
        WEEK: CalendarViewEnum.WEEK.toUpperCase(),
        MONTH: CalendarViewEnum.MONTH.toUpperCase()
      };
    }
  },
  data() {
    return {
      datePicker: false
    };
  },
  methods: {
    onChange(value) {
      this.datePicker = false;
      this.$emit('input', momentjs(value).format(this.novaCore.DateTimeFormats.DateDashed));
    }
  }
};
</script>
