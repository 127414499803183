<template>
  <div class="d-flex flex-row">
    <billing-details-form></billing-details-form>
    <billing-contact-form></billing-contact-form>
  </div>
</template>

<script>
/**
 * @displayName Billing Info
 */
export default {
  //
};
</script>
