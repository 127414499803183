var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    _vm._b(
      {
        attrs: { width: _vm.width },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)
            )
              return null
            return _vm.closeModal.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _vm._e(),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.modal,
          callback: function ($$v) {
            _vm.modal = $$v
          },
          expression: "modal",
        },
      },
      "v-dialog",
      [_vm.$props, _vm.$attrs],
      false
    ),
    [
      _c(
        "v-card",
        { staticClass: "dialog-box" },
        [
          _c(
            "v-card-title",
            [
              _c("span", { staticClass: "headline d-flex" }, [
                _vm._v("Manage Subscribers"),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "pa-3",
                  attrs: { icon: "", light: "" },
                  on: { click: _vm.closeModal },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "dialog-body" },
            [
              _c("carrier-contact-card", {
                attrs: {
                  carrier: _vm.carrierContact.user,
                  company: _vm.carrierContact.company,
                  "is-favorite": _vm.carrierContact.isFavorite,
                },
              }),
              _c(
                "div",
                { staticClass: "subscribers-wrapper" },
                [
                  _c(
                    "label",
                    { attrs: { for: "carrier-contact-subscribers" } },
                    [
                      _vm._v(
                        " Send email notifications for all appointments scheduled with this contact to: "
                      ),
                    ]
                  ),
                  _c("email-list-field", {
                    ref: "emailField",
                    staticClass: "carrier-contact-subscribers",
                    attrs: {
                      loading: _vm.isLoadingRef,
                      value: _vm.subscribers,
                      id: "carrier-contact-subscribers",
                      name: "carrier-contact-subscribers",
                      "data-testid": "carrier-contact-subscribers",
                      dense: "",
                      flat: "",
                      "hide-details": "",
                    },
                    on: { input: (emails) => (_vm.subscribers = emails) },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "dialog-actions" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    "data-testid": "carrier-contact-subscribers-nevermind",
                  },
                  on: { click: _vm.closeModal },
                },
                [_vm._v(" Nevermind ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.canSave,
                    "data-testid": "carrier-contact-subscribers-save",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v(" Save Subscribers ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }