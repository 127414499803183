var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center time-range-group-item" },
    [
      _c("v-autocomplete", {
        staticClass: "time-range-select mt-0",
        attrs: {
          readonly: _vm.readonly,
          required: "",
          "hide-details": _vm.hideDetails,
          rules: _vm.fromRules,
          dense: "",
          items: _vm.fromItems,
        },
        on: { change: _vm.updateValue },
        model: {
          value: _vm.start,
          callback: function ($$v) {
            _vm.start = $$v
          },
          expression: "start",
        },
      }),
      _c(
        "span",
        {
          staticClass: "mx-6 font-size-x-small font-weight-bold",
          class: { "text--disabled": !_vm.start },
        },
        [_vm._v("to")]
      ),
      _c("v-autocomplete", {
        staticClass: "time-range-select mt-0",
        attrs: {
          readonly: _vm.readonly,
          required: "",
          "hide-details": _vm.hideDetails,
          rules: _vm.toRules,
          dense: "",
          items: _vm.toItems,
        },
        on: { change: _vm.updateValue },
        model: {
          value: _vm.end,
          callback: function ($$v) {
            _vm.end = $$v
          },
          expression: "end",
        },
      }),
      _vm.canDelete && !_vm.readonly
        ? _c(
            "v-btn",
            {
              staticClass: "delete-button",
              attrs: { icon: "", small: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("delete-range", _vm.index)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-delete-outline")])],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }