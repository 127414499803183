<template>
  <v-sheet class="custom-calendar-container custom-day-calendar-container fill-height is-relative">
    <v-calendar
      class="calendar"
      :class="calendarClasses"
      ref="calendar"
      :interval-count="intervalsToShow"
      :interval-minutes="$intervalMinutes"
      :interval-height="$zoomLevel"
      :first-interval="intervals.first"
      v-model="selectedDate"
      type="category"
      color="primary"
      category-for-invalid="No Dock"
      category-show-all
      :event-category="getEventCategory"
      event-overlap-mode="column"
      :categories="categories"
      :events="events"
      :interval-format="formatInterval"
      :short-intervals="true"
      :event-color="getEventClasses"
      @contextmenu:event="setEventContextMenuId"
      @mouseup.native="cancelDrag"
      @mousedown:event="startDrag"
      @mousedown:time-category="startTime"
      @mousemove:time-category="mouseMove"
      @mouseup:time-category="endDrag"
      @mouseleave.native="cancelDrag"
      @click:time-category="handleCalendarClick">
      <template v-slot:category="{ category }">
        <div
          class="d-flex full-width align-center full-height category-header"
          :class="getGroupClasses(category)">
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" :class="getDockNameClasses(category)">
                  {{ getDockNameById(category) }}
                </span>
              </template>
              <span>
                {{ totalAppointmentsByDockString($docksKeyedById[category]) }}
              </span>
            </v-tooltip>
            <v-btn
              v-if="novaCore.isCapacityParent($docksKeyedById[category])"
              plain
              class="font-size-x-small plain-text px-4 pa-1 d-flex align-center black--text capacity-view-toggle"
              @click="toggleCapacityDocks(category)">
              <span v-if="!shouldShowCapacityDockOpts[category]">
                {{ getDockCapacityCount(category) }}
              </span>
              <v-icon x-small class="ml-1" v-if="!shouldShowCapacityDockOpts[category]">
                mdi-arrow-expand-right
              </v-icon>
              <v-icon x-small class="ml-1" v-else>mdi-arrow-expand-left</v-icon>
            </v-btn>
          </template>
        </div>
      </template>

      <template v-slot:day-body="{ date, week, category, present }">
        <div :class="getGroupClasses(category)">
          <div v-if="present" class="v-current-time" :style="{ top: nowY }"></div>
        </div>
      </template>

      <template v-slot:interval="{ date, time, category, hour, minute, weekday }">
        <div
          class="slot-wrapper"
          v-if="mounted"
          :data-weekday="weekday"
          :data-date="date"
          :data-time="time.replace(/^0/, '')"
          :data-dock-id="category">
          <div
            v-if="isIntervalDisabled(date, time, category, 'slot render')"
            class="disabled-time-interval overlay"></div>
          <quick-reserve-button
            v-else-if="
              !$isFetchingEvents &&
              category &&
              isTimeAvailable(date, hour, minute, category) &&
              $rolePermissions.canCreateAppointment
            "
            @create-reserve="createReserve"
            class="quick-reserve-button-wrapper"
            :start="toWarehouseTime({ date, hour, minute }).toISOString()"
            :dockId="category"
            :loading="creatingReserve"
            :interval-minutes="$intervalMinutes"></quick-reserve-button>
        </div>
      </template>

      <template v-slot:event="{ event }">
        <div
          class="event-content"
          :key="`${renderKey}-${event.id}`"
          :data-weekday="event.weekday"
          :data-time="event.startTime24.replace(/^0/, '')"
          :data-date="event.startDate"
          :data-dock-id="event.dockId">
          <event-with-tooltip
            :is-light-theme="$useLightGridTheme"
            :event="event"
            :enableMilitaryTime="$isMilitaryTimeEnabled($selectedWarehouse)"
            :view-type="CalendarViewEnum.DAY"
            :use-new-grid-tiles="useNewGridTilesSetting"
            @click="handleEventClick"
            @drag-event="dragEvent"
            @cancel-reserve="cancelReserve"
            :disable-tooltip="isTooltipDisabled(event)"></event-with-tooltip>
        </div>

        <div
          v-if="isStartingStatus(event.status) && $rolePermissions.canUpdateAppointment"
          class="v-event-drag-bottom"
          @mousedown.stop="extendBottom(event)"></div>
        <event-context-menu
          @close="eventContextMenuId = null"
          v-if="
            eventContextMenuId === event.id &&
            event.status !== novaCore.AppointmentStatus.Cancelled &&
            event.type !== novaCore.AppointmentType.Reserve
          "
          :nativeEvent="eventContextMenuEvent"
          :appointment="event"></event-context-menu>
      </template>
    </v-calendar>

    <load-type-re-select-dialog
      @close="nevermindLoadTypeDialog"
      v-if="eventToUpdate && originalEvent"
      :original-event="originalEvent"
      :event="eventToUpdate"
      :event-warehouse="eventWarehouse"
      :event-dock="eventDock"
      :original-event-dock="$docksKeyedById[originalEvent.category]"
      :show-dialog="shouldShowLoadTypeReselectDialog"></load-type-re-select-dialog>

    <calendar-dialogs
      :appointment-context="appointmentContext"
      :show-create-dialog="showCreateDialog"
      :show-details-dialog="showDetailsDialog"
      :show-reserve-dialog="showReserveDialog"
      @close="handleDialogClose"></calendar-dialogs>
    <zoom-buttons></zoom-buttons>
  </v-sheet>
</template>

<script>
import calendarMixin from '@/modules/calendar/mixins/calendarMixin';
import { CalendarViewEnum } from '@/enums';

/**
 * Calendar Day View
 * @displayName Calendar Day View
 */
export default {
  name: 'CalendarDayView',
  mixins: [calendarMixin],
  computed: {
    CalendarViewEnum() {
      return CalendarViewEnum;
    },
    /**
     * Categories === docks - calendar component expects categories as a prop, so keeping name the same
     * @returns {*}
     */
    categories() {
      let selectedDocks = this.novaCore.naturalSort(this.$selectedDocks, false, 'name');
      selectedDocks = this.novaCore.naturalSort(selectedDocks, false, 'sortOrder');
      return selectedDocks.map(dock => dock.id);
    },
    /**
     * Formatted date label
     * TODO: Confirm this is still being used
     * @returns {string|string}
     */
    dateLabel() {
      return this.cal && this.cal.parsedValue
        ? momentjs(this.cal.parsedValue.date).format(this.novaCore.DateTimeFormats.LongDateFull)
        : '';
    },
    calendarClasses() {
      let classes = !(this.$selectedWarehouse.id && this.$selectedDocks.length)
        ? 'disabled'
        : 'clickable';
      if (this.isDrag) {
        classes += ' is-dragging';
      }
      const firstIntervalMinutes = this.firstIntervalClock.split(':')[1];
      classes += ` start-minute__${firstIntervalMinutes} calendar-interval__${this.$intervalMinutes}`;
      return classes;
    },
    startTimeMinutesSetting() {
      return this.$selectedWarehouse?.settings?.appointmentStartTimeMinutes ?? 30;
    }
  },
  data() {
    return {
      creatingReserve: false
    };
  },
  methods: {
    getDockNameById(dockId) {
      const dock = this.$docksKeyedById[dockId];

      if (dock) {
        return this.novaCore.isCapacityChild(dock) ? dock.readableDockName : dock.name;
      }
      return '';
    },
    getDockNameClasses(dockId) {
      const dock = this.$docksKeyedById[dockId];

      if (dock) {
        return {
          'flex-grow-1': true,
          'ml-12': this.novaCore.isCapacityParent(dock),
          'append-animate': this.novaCore.isCapacityChild(dock),
          'text-center': !this.novaCore.isCapacityParticipant(dock)
        };
      }

      return {};
    },
    /**
     * Handle calendar category click
     * @param dayTimeObject
     */
    handleCalendarClick(dayTimeObject) {
      if (this.isDrag) {
        return false;
      }
      const time = momentjs(
        this.roundTime(
          momentjs(`${dayTimeObject.date} ${dayTimeObject.time}`).valueOf(),
          true,
          this.$intervalMinutes
        )
      ).format(this.novaCore.DateTimeFormats.Extended24HrTimeLeadingZeroHour);
      if (
        this.isIntervalDisabled(dayTimeObject.date, time, dayTimeObject.category) ||
        !this.$rolePermissions.canCreateAppointment
      ) {
        return false;
      }
      if (!this.dragEvent && !this.isExtending) {
        if (!this.$selectedWarehouse?.id) {
          this.$eventHub.$emit('shake-element', 'warehouse-select');
          return;
        }

        let dock = this.$docksKeyedById[dayTimeObject.category];

        if (dock) {
          if (this.novaCore.isCapacityChild(dock)) {
            dock = this.$docksKeyedById[dock.capacityParentId];
          }
          this.showCreateAppointmentDialog(dayTimeObject, dock);
        } else {
          this.$eventHub.$emit('shake-element', 'dock-select');
        }
      }
    },
    isTimeAvailable(date, hour, minute, category) {
      const start = this.toWarehouseTime({ date, hour, minute });
      const end = start.clone().add(this.$intervalMinutes, 'minutes');
      const currentEvents = this.events
        .filter(
          event =>
            event.category === category &&
            event.status !== this.novaCore.AppointmentStatus.Cancelled
        )
        .map(event => ({
          start: momentjs(event.start).tz(this.$selectedWarehouse.timezone, true).toDate(),
          end: momentjs(event.end).tz(this.$selectedWarehouse.timezone, true).toDate()
        }));
      return !this.novaCore.anyOverlaps(
        { start: start.toDate(), end: end.toDate() },
        currentEvents
      );
    },
    isIntervalDisabled(date, time, dockId) {
      if (!(dockId || this.selectedWarehouseId)) {
        return false;
      }
      let isDisabled = false;
      const dockHoops = this.$selectedWarehouseHoops?.[dockId];
      if (dockHoops) {
        const start = momentjs.tz(
          this.novaCore.floorOfInterval(
            momentjs
              .tz(
                `${date} ${time}`,
                `${this.novaCore.DateTimeFormats.DateDashed} ${this.novaCore.DateTimeFormats.Extended24HrTimeLeadingZeroHour}`,
                this.$selectedWarehouse.timezone
              )
              .toDate(),
            this.$intervalMinutes
          ),
          this.$selectedWarehouse.timezone
        );
        const end = start.clone().add(this.$intervalMinutes, 'minutes');
        const slot = { start: start.toDate(), end: end.toDate() };
        if (!this.novaCore.isAnySubInterval(slot, dockHoops)) {
          isDisabled = true;
        }
      }
      return isDisabled;
    },
    async createReserve(data) {
      this.$emit('set-loader', true);
      data.duration_min = this.$intervalMinutes;
      let dockIdsToReserve = [data.dockId];
      const dock = this.docks.find(dock => dock.id === data.dockId);
      const isParentDock = this.novaCore.isCapacityParent(dock);
      // If it's a parent dock and capacity is NOT expanded, we should reserve all capacity
      if (isParentDock && !this.shouldShowCapacityDockOpts[dock.id]) {
        dockIdsToReserve = dock.capacityChildren.map(child => child.id);
      }
      this.creatingReserve = true;

      const promises = [];
      dockIdsToReserve.forEach(dockId => {
        data.dockId = dockId;
        promises.push(this.$store.dispatch('Appointments/createReserve', data));
      });
      try {
        await Promise.all(promises);
      } finally {
        this.$emit('set-loader', false);
        this.creatingReserve = false;
      }
    },
    getEventCategory(event) {
      const eventDock = this.$docksKeyedById[event.category];
      let eventCategory = eventDock.id;
      if (
        this.novaCore.isCapacityChild(eventDock) &&
        !this.shouldShowCapacityDockOpts[eventDock.capacityParentId]
      ) {
        eventCategory = eventDock.capacityParentId;
      }
      return eventCategory;
    },
    getDockCapacityCount(dockId) {
      const count = this.$docksKeyedById?.[dockId].capacityChildren?.length;
      return count ?? 0;
    },
    toggleCapacityDocks(dockId) {
      this.shouldShowCapacityDockOpts[dockId] = !this.shouldShowCapacityDockOpts[dockId];
    },
    getGroupClasses(dockId) {
      let classes = '';
      const dock = this.$docksKeyedById[dockId];
      if (dock) {
        if (this.novaCore.isCapacityChild(dock)) {
          classes = 'category-group child append-animate';
        }
        if (this.novaCore.isCapacityParent(dock) && this.shouldShowCapacityDockOpts[dockId]) {
          classes += 'category-group parent append-animate';
        }
      }

      return classes;
    },
    setshouldShowCapacityDockOpts() {
      const shouldShowCapacityDockOpts = {};
      this.$selectedDocks.forEach(dock => {
        shouldShowCapacityDockOpts[dock.id] = false;
      });
      this.shouldShowCapacityDockOpts = {
        ...shouldShowCapacityDockOpts,
        ...this.shouldShowCapacityDockOpts
      };
    },
    insertDocksIntoGrid(selectedDockIndex, selectedDock) {
      const isSelectedParent =
        selectedDockIndex !== -1 && this.novaCore.isCapacityParent(selectedDock);
      // add the children in the position next to its parent
      selectedDockIndex++;
      if (isSelectedParent) {
        this.novaCore.sortCapacityChildren(selectedDock).forEach(child => {
          const childIndex = this.docks.findIndex(dock => dock.id === child.id);
          // This condition ignores the parent dock which is included in the child dock array by design
          if (this.novaCore.isCapacityChild(child) && childIndex === -1) {
            this.docks.splice(selectedDockIndex, 0, child);
            selectedDockIndex++;
          }
        });
      }
    },
    removeDockFromGrid(selectedDock) {
      const hasChildDocks = selectedDock?.capacityChildren?.length > 0;
      if (hasChildDocks) {
        selectedDock?.capacityChildren.forEach(child => {
          if (this.novaCore.isCapacityChild(child)) {
            const childIndex = this.docks.findIndex(dock => dock.id === child.id);
            if (childIndex !== -1) {
              this.docks.splice(childIndex, 1);
            }
          }
        });
      }
    },
    totalAppointmentsByDock(dock) {
      if (dock?.id) {
        return (
          this.events?.filter(event => {
            const notReserve = !this.novaCore.isReserve(event);
            const notCanceled = event.status !== this.novaCore.AppointmentStatus.Cancelled;
            const todayEvent =
              event.start.startsWith(this.selectedDate) ||
              (event.end.startsWith(this.selectedDate) && !event.end.endsWith('00:00'));
            const validAppointment = notReserve && notCanceled && todayEvent;

            const dockAppointment = event.dockId === dock.id;
            const parentAppointment = Boolean(event.parentDockId) && event.parentDockId === dock.id;
            const isParentDock = this.novaCore.isCapacityParent(dock);
            const isCapacityVisible = !this.shouldShowCapacityDockOpts[dock.id];

            if (isParentDock && isCapacityVisible) {
              return (parentAppointment || dockAppointment) && validAppointment;
            }

            return validAppointment && dockAppointment;
          }).length || 0
        );
      }

      return 0;
    },
    totalAppointmentsByDockString(dock) {
      const total = this.totalAppointmentsByDock(dock);
      return `${total} appointment`.concat(total === 1 ? '' : 's');
    }
  },
  async mounted() {
    this.attachZoomControls();

    if (!this.$selectedWarehouse || !this.$selectedDocks) {
      this.util.addCursorTextListener(
        this.$refs.calendar.$el,
        'No Warehouse/Docks selected',
        'calendarCursorText'
      );
    }

    await this.updateTime();
    await this.scrollToTime();

    this.$store.commit('Calendar/setDisabledDateTimesByWarehouse', {});
    this.setshouldShowCapacityDockOpts();
    this.mounted = true;
    this.$eventHub.$on('update-Dock', this.handleSocketDockUpdate);
    this.$eventHub.$on('update-Warehouse', this.handleSocketWarehouseUpdate);

    this.$eventHub.$on('update-Trigger', this.handleSocketTriggerUpdate);
    this.$eventHub.$on('create-Trigger', this.handleSocketTriggerUpdate);
  },
  beforeDestroy() {
    this.util.removeCursorTextListener(this.$refs.calendar.$el, 'calendarCursorText');
    this.$eventHub.$off('update-Dock', this.handleSocketDockUpdate);
    this.$eventHub.$off('update-Warehouse', this.handleSocketWarehouseUpdate);

    this.$eventHub.$off('update-Trigger');
    this.$eventHub.$off('create-Trigger');
  },
  watch: {
    $selectedDocks() {
      this.setshouldShowCapacityDockOpts();
    },
    $selectedDate() {
      if (this.mounted) {
        this.getSelectedWarehouseHoops();
      }
    },
    $selectedWarehouse: {
      async handler(newWarehouse, oldWarehouse) {
        this.shouldShowCapacityDockOpts = {};
        if (newWarehouse?.id !== oldWarehouse?.id) {
          await this.getSelectedWarehouseHoops();
        }
      },
      immediate: true
    },
    shouldShowCapacityDockOpts: {
      handler(newDocks, oldDocks) {
        this.$emit('is-dock-capacity-toggle', true);
        Object.entries(newDocks).forEach(([dockId, shouldShow]) => {
          const selectedDock = this.$docksKeyedById[dockId];
          if (selectedDock) {
            let selectedDockIndex = this.docks.findIndex(dock => dock.id === selectedDock.id);
            if (shouldShow) {
              this.insertDocksIntoGrid(selectedDockIndex, selectedDock);
            } else {
              this.removeDockFromGrid(selectedDock);
            }
          }
        });
        if (
          newDocks &&
          oldDocks &&
          Object.keys(newDocks)?.length !== Object.keys(oldDocks)?.length
        ) {
          this.$emit('is-dock-capacity-toggle', true);
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
