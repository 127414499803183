<script>
import { DateTimeFormats } from '@satellite/../nova/core';

export default {
  props: {
    appointment: {
      type: Object,
      required: true
    },
    statusToEdit: {
      type: String,
      required: false,
      default: null
    },
    mixpanelEntryPoint: {
      type: String,
      required: false
    },
    shouldDisplayActions: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      showTimeInputDialog: false,
      timeAdjustmentOptionsDefault: [
        {
          label: 'On Time',
          quantity: '0',
          unit: 'minutes',
          operator: 'add'
        },
        {
          label: '15 Min Late',
          quantity: '15',
          unit: 'minutes',
          operator: 'add'
        },
        {
          label: '30 Min Late',
          quantity: '30',
          unit: 'minutes',
          operator: 'add'
        }
      ],
      timeAdjustmentOptionsInProgress: [
        {
          label: 'Upon arrival',
          quantity: '0',
          unit: 'minutes',
          operator: 'add'
        },
        {
          label: '15 min later',
          quantity: '15',
          unit: 'minutes',
          operator: 'add'
        },
        {
          label: '30 min later',
          quantity: '30',
          unit: 'minutes',
          operator: 'add'
        }
      ],
      timeAdjustmentOptionsCompleted: [
        {
          label: '30 min dwell',
          quantity: '30',
          unit: 'minutes',
          operator: 'add'
        },
        {
          label: '1 hour dwell',
          quantity: '1',
          unit: 'hour',
          operator: 'add'
        },
        {
          label: '2 hours dwell',
          quantity: '2',
          unit: 'hours',
          operator: 'add'
        }
      ],
      customTime: {
        amPm: '',
        time: ''
      },
      customDate: '',
      isLoading: false
    };
  },
  computed: {
    timezone() {
      return this.appointment?.dock?.warehouse?.timezone;
    },
    adjustFromDateTime() {
      const arrivedTime = this.appointment.statusTimeline[this.novaCore.AppointmentStatus.Arrived];
      switch (this.statusToEdit) {
        // If Completed, then we are calculating Dwell time as time since "Arrived"
        case this.novaCore.AppointmentStatus.Completed:
          return momentjs.tz(arrivedTime, this.timezone);
        // If InProgress, we are calculating time since "Arrived".
        // Better to keep separate than "Completed" above so they are independent of each other
        case this.novaCore.AppointmentStatus.InProgress:
          return momentjs.tz(arrivedTime, this.timezone);
        default:
          return momentjs(this.appointment.start);
      }
    },
    statusDateTime() {
      return momentjs.tz(this.appointment.statusTimeline[this.statusToEdit], this.timezone);
    },
    statusTime() {
      return {
        amPm: this.statusDateTime.format(this.novaCore.DateTimeFormats.AmPm),
        time: this.statusDateTime.format(DateTimeFormats.Extended12HrTimeLeadingZeroHour)
      };
    },
    statusTimeTitle() {
      switch (this.statusToEdit) {
        case this.novaCore.AppointmentStatus.Arrived:
          return 'Arrived Time';
        case this.novaCore.AppointmentStatus.Completed:
          return 'Dwell Time';
        case this.novaCore.AppointmentStatus.InProgress:
          return 'InProgress Time';
        default:
          return 'N/A';
      }
    },
    timeAdjustmentOptions() {
      switch (this.statusToEdit) {
        case this.novaCore.AppointmentStatus.Completed:
          return this.timeAdjustmentOptionsCompleted;
        case this.novaCore.AppointmentStatus.InProgress:
          return this.timeAdjustmentOptionsInProgress;
        default:
          return this.timeAdjustmentOptionsDefault;
      }
    }
  },
  methods: {
    getAdjustedTime(index) {
      if (index >= 0) {
        const timeAdjustment = this.timeAdjustmentOptions[index];
        const apptDateTime = this.adjustFromDateTime.clone();
        return apptDateTime[timeAdjustment.operator](timeAdjustment.quantity, timeAdjustment.unit);
      }
    },
    adjustTime(timeAdjustmentOptionsIndex) {
      const newDateTime =
        timeAdjustmentOptionsIndex >= 0
          ? this.getAdjustedTime(timeAdjustmentOptionsIndex)
          : this.statusDateTime;

      this.customTime.amPm = newDateTime.format(this.novaCore.DateTimeFormats.AmPm);
      this.customTime.time = newDateTime.format(DateTimeFormats.Extended12HrTimeLeadingZeroHour);
      this.customDate = newDateTime.format(DateTimeFormats.DateDashed);
    },
    updateAppointment() {
      this.$nextTick(async () => {
        if (!this.$refs.form.validate()) {
          return;
        }

        this.isLoading = true;

        try {
          const newDateTimeString = [
            this.customDate,
            this.customTime.time,
            this.customTime.amPm
          ].join(' ');
          const newDateTime = momentjs.tz(
            newDateTimeString,
            `${DateTimeFormats.DateDashed} ${DateTimeFormats.Extended12HrTimeLeadingZeroHour} a`,
            this.timezone
          );

          const newTimeline = {
            ...this.appointment.statusTimeline,
            [this.statusToEdit]: newDateTime.utc().format()
          };

          // update the timeline status to have 0 seconds for each timestamp
          // technically this is not needed for any NEW appointments, but it is here still for
          // backwards compatibility, i.e. timeline has arrived from before this commit and
          // user tries to set "InProgress" or "Completed" to "Upon Arrival"
          for (const [status, value] of Object.entries(newTimeline)) {
            if (value) {
              newTimeline[status] = momentjs(value).startOf('minute').toISOString();
            }
          }

          await axios
            .patch(`appointment/${this.appointment.id}`, { statusTimeline: newTimeline })
            .then(response => {
              if (response?.data?.data) {
                this.$store.dispatch('Appointments/trackMixpanelEvent', {
                  entryPoint: this.mixpanelEntryPoint,
                  appointment: { ...this.appointment, ...response.data.data },
                  change: `Status: ${this.statusTimeTitle}`
                });
                this.$store.dispatch('Appointments/triggerSocketEvent', {
                  response,
                  appointment: this.appointment
                });
                this.$emit('close');
              }
            });
        } finally {
          this.isLoading = false;
        }
      });
    }
  },
  beforeMount() {
    this.customTime = this.statusTime;
    this.customDate = this.statusDateTime.format(DateTimeFormats.DateDashed);
  }
};
</script>
