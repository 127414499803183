<template>
  <form-base>
    <template #form>
      <template v-if="loading">
        <v-card-text>
          Loading Appointment Data...
          <v-progress-linear color="secondary" indeterminate rounded height="8"></v-progress-linear>
        </v-card-text>
      </template>
      <v-form ref="form" v-else>
        <div v-for="localField of formFields" :key="localField.formFieldId">
          <p class="mb-2 mt-2 font-size-small">
            <strong class="mr-1">{{ localField.customField.label }}</strong>
            <help-icon-tooltip
              v-if="localField.customField.description"
              icon-style-class="secondary">
              {{ localField.customField.description }}
            </help-icon-tooltip>
          </p>

          <p class="mb-7">
            <component
              v-bind:is="customFieldComponents[localField.customField.type]"
              :field="localField.customField"
              :warehouse="warehouse"
              single-line
              v-model="localField.value"></component>
          </p>
        </div>
        <div v-if="formFields.length === 0">
          <h3>No fields have been set for this form, please skip it.</h3>
        </div>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="$emit('close')"
        :confirm-label="shouldShowNextForm ? 'Next' : 'Confirm'"
        cancel-label="Nevermind"
        cancel-icon=""
        :stack-actions="stackActions"
        @confirm="shouldShowNextForm ? nextForm() : submit()">
        <template v-slot:additional-actions>
          <v-btn
            v-if="wasAutoTriggered"
            :class="{ 'full-width': stackActions }"
            outlined
            color="secondary"
            @click="skip">
            Skip {{ trigger.flow.name }}
          </v-btn>
        </template>
      </action-group>
    </template>
  </form-base>
</template>

<script>
import { isEqual } from 'lodash';
import { isMobile } from '@satellite/plugins/util';

const FORM_FROM = 'formFrom';
const FORM_TO = 'formTo';

export default {
  props: {
    trigger: {
      type: Object,
      default: null
    },
    objectId: {
      type: String,
      required: true
    },
    warehouse: {
      type: Object,
      required: true,
      default: () => ({})
    },
    statusChange: {
      type: String,
      required: false
    },
    stackActions: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      activeForm: FORM_FROM,
      localFields: [],
      submittedData: [],
      loading: false
    };
  },
  computed: {
    customFieldComponents() {
      const componentsMap = {};
      Object.entries(this.novaCore.CustomFieldType).forEach(
        type => (componentsMap[type[1]] = `custom-field-${type[0].toLowerCase()}`)
      );
      return componentsMap;
    },
    wasAutoTriggered() {
      if (this.trigger?.category === this.novaCore.CustomFormFlowCategoryEnum.manualTrigger) {
        return false;
      }
      return !this.submittedData?.length && this.statusChange;
    },
    flow() {
      return this.trigger?.flow || {};
    },
    formFields() {
      return this.localFields
        .filter(f => f.form === this.activeForm)
        .sort((a, b) => a.customField.order - b.customField.order);
    },
    hasFormTo() {
      return Boolean(this.flow.formTo?.id);
    },
    hasFieldConditionToShowNextForm() {
      const { conditionFormFieldId, conditionOperator, conditionValue } = this.flow;

      if (conditionFormFieldId && conditionOperator) {
        const formField = this.localFields.find(f => f.formFieldId === conditionFormFieldId);
        return this.novaCore.applyFieldOperation(
          formField?.value,
          conditionValue,
          conditionOperator,
          isEqual
        );
      }

      return false;
    },
    shouldShowNextForm() {
      const canShow = this.hasFormTo && this.activeForm === FORM_FROM;
      return canShow && this.hasFieldConditionToShowNextForm;
    }
  },
  methods: {
    cleanUpFormToValues() {
      if (!this.hasFieldConditionToShowNextForm) {
        this.flow.formTo?.formFields.forEach(formToField => {
          const localFormToField = this.localFields.find(f => f.formFieldId === formToField.id);
          if (localFormToField) {
            localFormToField.value = null;
          }
        });
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        const actionFields = new Set();

        this.localFields.forEach(f => {
          // Add selected features to be launched after form submission
          if (f.customField.type === this.novaCore.CustomFieldType.Action) {
            actionFields.add(f.value);
          }
        });

        // Do not send action fields
        const fields = [...this.localFields].filter(
          f => f.customField.type !== this.novaCore.CustomFieldType.Action
        );

        // Remove local properties
        fields.forEach(f => {
          delete f.customField;
          delete f.form;
        });

        this.cleanUpFormToValues();

        const eventName = this.submittedData.length > 0 ? 'update' : 'create';
        this.$emit(eventName, fields, this.statusChange);

        this.trackMixpanelFormSubmit();

        actionFields.forEach(feature => this.$eventHub.$emit('action-field-submitted', feature));
        this.$emit('close');

        return true;
      }
      return false;
    },
    trackMixpanelFormSubmit(isSkip = false) {
      return this.mixpanel?.track(this.mixpanel.events.MODULE.CUSTOM_FORMS.SUBMIT_FORM, {
        'Custom Form Application': this.trigger.app,
        'Custom Form Type': this.trigger.feature,
        'Custom Form Trigger': this.trigger.category,
        'Appointment ID': this.objectId,
        'Warehouse ID': this.warehouse.id,
        'Warehouse Name': this.warehouse.name,
        'Org ID': this.$org.id,
        'Org Name': this.$org.name,
        'Entry Point': isMobile() ? 'Mobile Details Page' : 'Details Modal',
        'Was Skipped': isSkip ? 'Yes' : 'No',
        'Was Auto Triggered': this.wasAutoTriggered ? 'Yes' : 'No'
      });
    },
    skip() {
      if (!this.statusChange) {
        this.$emit('close');
        return;
      }

      const skip = true;

      this.$emit('create', { skip }, this.statusChange);
      this.trackMixpanelFormSubmit(skip);
    },
    nextForm() {
      if (this.$refs.form.validate()) {
        this.activeForm = FORM_TO;
      }
    },
    getCustomField(formField, existingValue) {
      const field = formField.field;

      return {
        label: formField.overrideLabel || field.label,
        type: field.type,
        name: formField.id,
        description: field.description,
        order: formField.order,
        required: formField.required,
        dropDownValues: field.extraFields?.dropDownValues,
        placeholder: field.placeholder,
        minLengthOrValue: field.extraFields?.minLengthOrValue,
        maxLengthOrValue: field.extraFields?.maxLengthOrValue,
        value: existingValue
      };
    },
    async setSubmittedData() {
      if (this.statusChange) {
        return;
      }
      this.loading = true;
      try {
        const response = await axios.get('custom-forms/form-data', {
          params: {
            s: { objectId: this.objectId, triggerId: this.trigger.id },
            limit: 1000
          }
        });
        this.submittedData = response?.data?.data || [];
      } catch (_) {
        this.submittedData = [];
      } finally {
        this.loading = false;
      }
    },
    setLocalFields() {
      for (const form of [FORM_FROM, FORM_TO]) {
        for (const formField of this.flow[form]?.formFields || []) {
          const defaultValue = formField.field.type === 'bool' ? false : null;
          const existingField = this.submittedData.find(v => v.formField?.id === formField.id);
          const existingValue = existingField?.value;
          const existingId = existingField?.id;

          const value = existingValue || defaultValue;

          this.localFields.push({
            form,
            id: existingId,
            customField: this.getCustomField(formField, value),
            objectId: this.objectId,
            entityName: this.novaCore.CustomFormDataEntitiesEnum.appointment,
            formFieldId: formField.id,
            triggerId: this.trigger.id,
            value
          });
        }
      }
    }
  },
  async mounted() {
    await this.setSubmittedData();
    this.setLocalFields();
  },
  destroyed() {
    this.localFields = [];
    this.submittedData = [];
    this.activeForm = FORM_FROM;
    this.loading = false;
  }
};
</script>
