export default {
  props: {
    /**
     * ID of the entity to get the audit log for
     */
    objectId: {
      type: String,
      required: true
    },
    /**
     * The entity type
     */
    entity: {
      type: String,
      required: true
    },
    org: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    /**
     * The full object must be passed for the audit log
     */
    entityObj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      auditLog: [],
      fetchOnMounted: true,
      loading: false
    };
  },
  methods: {
    getItemUser(item) {
      const role = item.changedByUser?.role;
      if (role && this.novaCore.isInternalRole(role)) {
        return 'Opendock Team';
      }
      return item.changedByUser
        ? `${item.changedByUser.firstName} ${item.changedByUser.lastName}`
        : 'Unknown User';
    },
    /**
     * Get the audit log
     */
    async getAuditLog() {
      try {
        this.loading = true;
        const auditLogResponse = await axios.get(`audit-log/${this.objectId}`);
        this.auditLog = auditLogResponse?.data.reverse() || [];
      } finally {
        this.loading = false;
      }
    },
    /**
     * Is the data from a subspace update event
     * @public
     * @param {object} data
     * @returns {boolean}
     */
    isSubspaceUpdateEvent(data) {
      // Recurring appointments send multiple update events via subspace,
      // so we have to make sure we check the debounced IDs
      // in order to update all appointments in the debounced ID array
      const subspaceDebounceData = data.debounce;
      const hasMultipleEvents = subspaceDebounceData?.count > 1;
      return hasMultipleEvents
        ? subspaceDebounceData?.ids?.includes(this.objectId)
        : data.id === this.objectId;
    },
    getCustomFieldValueForAuditLog(field) {
      // When the file is deleted we don't have the filename anymore, it defaults to Download
      const translateGenericFileName = value =>
        value?.toUpperCase() === 'DOWNLOAD' ? 'Deleted' : value;
      const formattedValue = this.novaCore.getCustomFieldFormattedValue(field, {
        [this.novaCore.CustomFieldType.Timestamp]: {
          timezone: this.entityObj.dock.warehouse.timezone,
          formatAsMilitary: this.$isMilitaryTimeEnabled(this.$org)
        }
      });

      if (this.novaCore.CustomFieldType.Document === field?.type) {
        return translateGenericFileName(formattedValue);
      }

      const clearedValues = [undefined, 'undefined', [], ''];

      if (clearedValues.includes(formattedValue)) {
        return 'has been cleared';
      }

      return formattedValue;
    },
    async handleSubspaceEvent(data) {
      if (this.isSubspaceUpdateEvent(data)) {
        await this.getAuditLog();
      }
    }
  },
  async mounted() {
    if (this.fetchOnMounted) {
      await this.getAuditLog();
    }
    this.$eventHub.$on(`update-${this.entity}`, this.handleSubspaceEvent);
  },
  beforeDestroy() {
    this.$eventHub.$off(`update-${this.entity}`, this.handleSubspaceEvent);
  }
};
