<template>
  <form-base :header="header" :hide-required-legend="true" :key="renderKey">
    <template #form>
      <v-form ref="form">
        <div>
          <span>
            Please type in the new ETA
            {{ partyToAlert === 'Warehouse' ? ` and notify the ${partyToAlert}` : '' }}.
          </span>

          <div class="d-flex my-4">
            <div class="pa-3 disabled border-radius font-size-x-small">
              <strong>Appointment time</strong>
              :
              {{ formattedAppointmentDatetime }}
              ({{ appointment.dock.warehouse.timezone }})
            </div>
            <img
              class="align-self-center thin-arrow"
              src="/images/arrow-right-thin.png"
              alt="Right Arrow" />
            <div class="pa-3 disabled border-radius font-size-x-small">New ETA</div>
          </div>
          <div class="my-3 mid-narrow margin-x-auto">
            <date-picker
              :format="novaCore.DateTimeFormats.ShortMonthYear"
              :clearable="false"
              v-model="customDate"
              :min-date="minEtaDate"
              :max-date="maxEtaDate"
              :display-field-label="false"
              required></date-picker>
          </div>
          <div class="margin-x-auto mid-wide">
            <time-entry
              :date="appointmentDateTime"
              :warehouse="appointment.dock.warehouse"
              v-model="customTime"></time-entry>
          </div>
        </div>
        <auto-expandable-textarea
          v-model="etaReason"
          label="Reason"
          :rules="isCarrierView ? $validator.rules.required('Reason') : []"
          placeholder="Enter a short description to justify this ETA alert"
          :required="isCarrierView"
          validate-on-blur></auto-expandable-textarea>
        <p v-if="isCarrierView">
          <small>
            ETA must be within {{ novaCore.ETA_WINDOW_HOURS }} hours from the appointment start!
          </small>
        </p>
        <v-spacer class="my-6"></v-spacer>
        <v-alert color="yellow lighten-4">
          <div class="d-flex align-center">
            <v-icon color="brown darken-0" class="mr-4">mdi-alert</v-icon>
            <p>
              Providing an ETA is an update for facilities but does not reschedule appointments or
              bypass facility late charges or fines.
            </p>
          </div>
        </v-alert>
      </v-form>
    </template>
    <template #form-actions>
      <action-group @cancel="cancel" confirm-label="Save" @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
import renderMixin from '@satellite/components/mixins/renderMixin';
import appointmentMixinBase from '@satellite/components/mixins/appointmentMixinBase';
import { DateTime } from 'luxon';

export default {
  mixins: [renderMixin, appointmentMixinBase],
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    },
    /**
     * @model
     */
    appointment: {
      type: Object,
      required: true
    },
    partyToAlert: {
      type: String,
      required: false,
      default: 'Warehouse',
      validator(value) {
        // The value must match one of these strings
        return ['Warehouse', 'Carrier'].includes(value);
      }
    }
  },
  computed: {
    customDateTime() {
      return momentjs.tz(
        `${this.customDate} ${this.customTime.time}${this.customTime.amPm}`,
        'YYYY-MM-DD hh:mma',
        this.timezone
      );
    },
    isCarrierView() {
      return this.novaCore.isCarrierUser(this.$me);
    },
    appointmentDateTime() {
      return momentjs.tz(this.appointment.start, this.timezone);
    },
    appointmentTime() {
      return {
        amPm: this.appointmentDateTime.format(this.novaCore.DateTimeFormats.AmPm),
        time: this.appointmentDateTime.format(
          this.novaCore.DateTimeFormats.Extended12HrTimeLeadingZeroHour
        )
      };
    },
    appointmentEtaDateTime() {
      return momentjs.tz(this.appointment.eta, this.timezone);
    },
    appointmentEtaTime() {
      return {
        amPm: this.appointmentEtaDateTime.clone().format(this.novaCore.DateTimeFormats.AmPm),
        time: this.appointmentEtaDateTime
          .clone()
          .format(this.novaCore.DateTimeFormats.Extended12HrTimeLeadingZeroHour)
      };
    },
    minEtaDate() {
      if (this.isCarrierView) {
        return DateTime.now().minus({ hour: this.novaCore.ETA_WINDOW_HOURS }).toISODate();
      }
      return null;
    },
    maxEtaDate() {
      if (this.isCarrierView) {
        return DateTime.now().plus({ hour: this.novaCore.ETA_WINDOW_HOURS }).toISODate();
      }
      return null;
    },
    formattedAppointmentDatetime() {
      const tz = null;
      return this.novaCore.formatDateTimeWithMilitarySupport(
        this.appointmentDateTime.format(),
        tz,
        this.novaCore.LuxonDateTimeFormats.ShortMonthDayTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.ShortMonthDayTime24
      );
    }
  },
  data() {
    return {
      ccEmails: this.appointment.ccEmails,
      customTime: {
        amPm: '',
        time: ''
      },
      customDate: '',
      etaReason: ''
    };
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    trackMixpanelChange(appointment) {
      const payload = {
        'ETA Tag': appointment.tags.filter(t => t?.startsWith(this.novaCore.ETA_PREFIX_STRING)),
        'Appointment ETA': appointment.eta,
        'Appointment ID': appointment.id,
        'Appointment Start': appointment.start,
        'Org ID': appointment.orgId,
        'Changed by carrier': this.$me?.orgName ? 'No' : 'Yes',
        'Warehouse ID': this.appointment.dock.warehouse.id,
        'Warehouse Name': this.appointment.dock.warehouse.name
      };

      if (this.$me?.orgName) {
        payload['Org Name'] = this.$me?.orgName;
      }
      this.mixpanel.track(this.mixpanel.events.MODULE.APPOINTMENT.ETA_CHANGED, payload);
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const newDateTimeString = [this.customDate, this.customTime.time, this.customTime.amPm].join(
        ' '
      );
      const newDateTime = momentjs.tz(
        newDateTimeString,
        `${this.novaCore.DateTimeFormats.DateDashed} ${this.novaCore.DateTimeFormats.Extended12HrTimeLeadingZeroHour} a`,
        this.timezone
      );

      // temporary hardcoded eta!  Our Most Amazing Sam will make it do the right thing for us!
      const response = await axios.patch(`appointment/${this.appointment.id}/set-eta`, {
        eta: newDateTime,
        reason: this.etaReason
      });

      if (response.data?.data) {
        this.trackMixpanelChange(response.data.data);
        this.notify('ETA was set', 'success');
        this.$store.dispatch('Appointments/triggerSocketEvent', {
          response,
          appointment: this.appointment
        });
        this.cancel();
      }
    }
  },
  beforeMount() {
    if (this.appointment.eta) {
      this.customTime = this.appointmentEtaTime;
      this.customDate = this.appointmentEtaDateTime.format(
        this.novaCore.DateTimeFormats.DateDashed
      );
    } else {
      this.customTime = this.appointmentTime;
      this.customDate = this.appointmentDateTime.format(this.novaCore.DateTimeFormats.DateDashed);
    }
  },
  watch: {
    appointment(appointment) {
      this.ccEmails = appointment.ccEmails;
      this.renderKey++;
    },
    customDateTime(newVal) {
      let newEtaCondition = this.novaCore.EtaCondition.OnTime;
      if (newVal) {
        newEtaCondition = this.novaCore.getEtaCondition(
          this.appointment.start,
          newVal,
          this.appointment.dock.warehouse.timezone
        );
      }
      this.$emit('condition-updated', newEtaCondition);
    }
  }
};
</script>
