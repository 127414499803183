var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    attrs: { header: _vm.header, "is-loading": _vm.loading },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form", staticClass: "pt-0" },
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      { staticClass: "pt-0" },
                      [
                        _c(
                          "v-col",
                          [
                            _vm.editMode
                              ? _c("text-field", {
                                  attrs: {
                                    required: true,
                                    rules:
                                      _vm.$validator.rules.required(
                                        "First Name"
                                      ),
                                    label: "First Name",
                                  },
                                  model: {
                                    value: _vm.fields.firstName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fields, "firstName", $$v)
                                    },
                                    expression: "fields.firstName",
                                  },
                                })
                              : _c(
                                  "div",
                                  { staticClass: "v-text-field pt-0" },
                                  [
                                    _c("v-label", [
                                      _c("strong", [_vm._v("First Name")]),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "mb-5" },
                                      [
                                        _c("v-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$me.firstName) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _vm.editMode
                              ? _c("text-field", {
                                  attrs: {
                                    required: true,
                                    rules:
                                      _vm.$validator.rules.required(
                                        "Last Name"
                                      ),
                                    label: "Last Name",
                                  },
                                  model: {
                                    value: _vm.fields.lastName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fields, "lastName", $$v)
                                    },
                                    expression: "fields.lastName",
                                  },
                                })
                              : _c(
                                  "div",
                                  { staticClass: "v-text-field pt-0" },
                                  [
                                    _c("v-label", [
                                      _c("strong", [_vm._v("Last Name")]),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "mb-5" },
                                      [
                                        _c("v-label", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$me.lastName) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "pt-0" },
                      [
                        _c(
                          "v-col",
                          [
                            _vm.editMode
                              ? _c("phone-number-field", {
                                  attrs: {
                                    "margin-top": "-7px",
                                    validator: _vm.$validator,
                                    label: "Phone",
                                  },
                                  model: {
                                    value: _vm.fields.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fields, "phone", $$v)
                                    },
                                    expression: "fields.phone",
                                  },
                                })
                              : _c(
                                  "div",
                                  { staticClass: "v-text-field pt-0" },
                                  [
                                    _c("v-label", [
                                      _c("strong", [_vm._v("Phone")]),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "mb-5" },
                                      [
                                        _c("v-label", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$me.phone
                                                  ? _vm.novaCore.formatPhoneNumber(
                                                      _vm.$me.phone
                                                    )
                                                  : "-"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.editMode
                      ? _c(
                          "v-row",
                          { staticClass: "pt-0" },
                          [
                            _c("v-col", [
                              _c(
                                "div",
                                { staticClass: "v-text-field pt-0" },
                                [
                                  _c("v-label", [
                                    _c("strong", [_vm._v("Email")]),
                                  ]),
                                  _c(
                                    "p",
                                    { staticClass: "mb-5" },
                                    [
                                      _c("v-label", [
                                        _vm._v(
                                          " " + _vm._s(_vm.$me.email) + " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("v-col", [
                              _c(
                                "div",
                                { staticClass: "v-text-field pt-0" },
                                [
                                  _c("v-label", [
                                    _c("strong", [_vm._v("My Role")]),
                                  ]),
                                  _c(
                                    "p",
                                    { staticClass: "mb-5" },
                                    [
                                      _c("v-label", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.novaCore.userRoleToText(
                                                _vm.$me.role
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isWarehouseListVisible && !_vm.editMode
                      ? _c(
                          "v-row",
                          { staticClass: "mt-2" },
                          [
                            _c("v-col", [
                              _c(
                                "div",
                                { staticClass: "v-text-field pt-0" },
                                [
                                  _c("v-label", [
                                    _c("strong", [_vm._v("My Warehouse list")]),
                                  ]),
                                  _c(
                                    "p",
                                    { staticClass: "mt-3" },
                                    _vm._l(
                                      _vm.warehouses,
                                      function (warehouse) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: warehouse.id,
                                            staticClass: "mr-2 mb-2",
                                            attrs: { small: "" },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(warehouse.name) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-spacer", { staticClass: "my-2" }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c(
              "action-group",
              {
                attrs: {
                  "hide-cancel": _vm.editMode,
                  "hide-confirm": !_vm.editMode,
                  "confirm-icon": "check",
                  "confirm-label": "Save",
                },
                on: { cancel: _vm.cancel, confirm: _vm.submit },
              },
              [
                _c(
                  "template",
                  { slot: "additional-actions" },
                  [
                    _vm.editMode
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: _vm.editMode ? "default" : "secondary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.editMode = false
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-2", attrs: { small: "" } },
                              [_vm._v("mdi-pencil-off")]
                            ),
                            _vm._v(" Cancel edit "),
                          ],
                          1
                        )
                      : [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary" },
                              on: {
                                click: function ($event) {
                                  _vm.editMode = true
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("mdi-pencil")]
                              ),
                              _vm._v(" Edit "),
                            ],
                            1
                          ),
                          _c("change-password-dialog", {
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (slotProps) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { staticClass: "ml-3" },
                                          "v-btn",
                                          slotProps.attrs,
                                          false
                                        ),
                                        slotProps.on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("mdi-key")]
                                        ),
                                        _vm._v(" Change password "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                  ],
                  2
                ),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }