var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("div", { staticClass: "pr-8 sidebar border-r" }, [
        _c(
          "div",
          {
            staticClass: "sidebar-content",
            class: { "has-asset-visit": _vm.assetVisit?.id },
          },
          [
            _c("h3", { staticClass: "black--text" }, [_vm._v("Summary")]),
            _vm.shouldShowSummaryDescription
              ? _c("p", { staticClass: "font-size-x-small py-2 text-dense" }, [
                  _vm._v(" You can follow the appointment summary here. "),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "py-3" },
              [
                _vm.shouldShowStep1Summary
                  ? [
                      _c("summary-item", {
                        staticClass: "mb-4",
                        attrs: {
                          title: "Carrier Contact",
                          data: _vm.selectedCarrierSummary,
                        },
                      }),
                      _c("summary-item", {
                        staticClass: "mb-4",
                        attrs: {
                          title: "Warehouse",
                          data: _vm.selectedWarehouse.name,
                        },
                      }),
                      _c("summary-item", {
                        staticClass: "mb-4",
                        attrs: {
                          title: "Load Type",
                          data: _vm.selectedLoadType.name,
                        },
                      }),
                      _c("summary-item", {
                        staticClass: "mb-4",
                        attrs: {
                          title: _vm.dockSummaryTitle,
                          data: _vm.selectedDocksSummary,
                        },
                      }),
                    ]
                  : _vm._e(),
                _vm.shouldShowStep2Summary
                  ? _c("summary-item", {
                      staticClass: "mb-4",
                      attrs: {
                        title: "Date & Time",
                        data: _vm.dateTimeSummary,
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
            _vm.assetVisit?.id
              ? [
                  _c("v-divider", { staticClass: "my-4" }),
                  _c("div", [
                    _c("h3", { staticClass: "black--text" }, [
                      _vm._v("Asset Details"),
                    ]),
                    _c(
                      "p",
                      { staticClass: "font-size-x-small pt-2 pb-0 text-dense" },
                      [
                        _vm._v(
                          " Details of the asset you'd like to create an appointment from "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-column",
                        attrs: {
                          "data-testid":
                            "create-appointment-dialog-asset-details-container",
                        },
                      },
                      [
                        _c("asset-visit-form-data", {
                          attrs: {
                            "asset-visit": _vm.assetVisit,
                            "is-list": true,
                            "is-compact": "",
                            "bold-labels": "",
                            "is-inline": false,
                            "phone-title": "phone",
                            "company-title": "Company",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      !_vm.submitted
        ? _c("form-base", {
            staticClass: "flex-grow-1 pl-8",
            attrs: { header: _vm.header },
            scopedSlots: _vm._u(
              [
                {
                  key: "form",
                  fn: function () {
                    return [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass:
                            "d-flex create-appointment-form inline-fields",
                        },
                        [
                          _c(
                            "v-stepper",
                            {
                              staticClass: "full-width",
                              model: {
                                value: _vm.step,
                                callback: function ($$v) {
                                  _vm.step = $$v
                                },
                                expression: "step",
                              },
                            },
                            [
                              _c(
                                "v-stepper-header",
                                [
                                  _c(
                                    "v-stepper-step",
                                    {
                                      attrs: {
                                        complete: _vm.step > 1,
                                        step: "1",
                                      },
                                    },
                                    [_vm._v("Basic Information")]
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-stepper-step",
                                    {
                                      attrs: {
                                        complete: _vm.step > 2,
                                        step: "2",
                                      },
                                    },
                                    [_vm._v("Date & Time")]
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-stepper-step",
                                    { attrs: { step: "3" } },
                                    [_vm._v("Appointment Details")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-items",
                                [
                                  _c(
                                    "v-stepper-content",
                                    {
                                      staticClass: "px-0",
                                      attrs: { step: "1" },
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: false,
                                                  expression: "false",
                                                },
                                              ],
                                            },
                                            [
                                              _c("org-select", {
                                                attrs: {
                                                  required: true,
                                                  label: "Org",
                                                  "hide-icon": true,
                                                },
                                                model: {
                                                  value: _vm.selectedOrg,
                                                  callback: function ($$v) {
                                                    _vm.selectedOrg = $$v
                                                  },
                                                  expression: "selectedOrg",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c("warehouse-select", {
                                                staticClass: "mb-4",
                                                attrs: {
                                                  disabled:
                                                    !_vm.selectedCarrier &&
                                                    !_vm.context.hasOwnProperty(
                                                      "selectedWarehouse"
                                                    ),
                                                  "restricted-warehouse-ids":
                                                    _vm.$me.warehouseAccessList,
                                                  required: true,
                                                  label: "Warehouse",
                                                  tooltipText:
                                                    "Select warehouse location for the appointment",
                                                  "hide-icon": true,
                                                },
                                                on: {
                                                  "clear-warehouse":
                                                    _vm.clearWarehouseContext,
                                                  close: function ($event) {
                                                    return _vm.$emit("close")
                                                  },
                                                },
                                                model: {
                                                  value: _vm.selectedWarehouse,
                                                  callback: function ($$v) {
                                                    _vm.selectedWarehouse = $$v
                                                  },
                                                  expression:
                                                    "selectedWarehouse",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _vm.isContextSet
                                                ? _c("carrier-select", {
                                                    attrs: {
                                                      required: "",
                                                      warehouse:
                                                        _vm.selectedWarehouse,
                                                      showWarning: "",
                                                      "entry-point":
                                                        "Create Appointment Modal",
                                                      label: "Carrier Contact",
                                                      tooltipText:
                                                        "Select carrier contact for the appointment",
                                                      "hide-icon": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedCarrier,
                                                      callback: function ($$v) {
                                                        _vm.selectedCarrier =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedCarrier",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c("load-type-select", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.selectedWarehouse.id,
                                                  "selected-docks":
                                                    _vm.selectedDocks,
                                                  required: true,
                                                  label: "Load Type",
                                                  tooltipText:
                                                    "Select load type for the appointment",
                                                  "selected-warehouse":
                                                    _vm.selectedWarehouse,
                                                  showOnlyAssignedLoadTypes: true,
                                                  "hide-icon": true,
                                                },
                                                on: {
                                                  close: function ($event) {
                                                    return _vm.$emit("close")
                                                  },
                                                },
                                                model: {
                                                  value: _vm.selectedLoadType,
                                                  callback: function ($$v) {
                                                    _vm.selectedLoadType = $$v
                                                  },
                                                  expression:
                                                    "selectedLoadType",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c("dock-select", {
                                                attrs: {
                                                  disabled:
                                                    !_vm.selectedWarehouse.id &&
                                                    !_vm.context.hasOwnProperty(
                                                      "selectedDocks"
                                                    ),
                                                  label: "Docks",
                                                  tooltipText:
                                                    "Select dock for the appointment (or allow system to select automatically)",
                                                  required: true,
                                                  "multi-select": true,
                                                  docks: _vm.warehouseDocks,
                                                  "hide-icon": true,
                                                },
                                                model: {
                                                  value: _vm.selectedDocks,
                                                  callback: function ($$v) {
                                                    _vm.selectedDocks = $$v
                                                  },
                                                  expression: "selectedDocks",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    {
                                      staticClass: "px-0",
                                      attrs: { step: "2" },
                                    },
                                    [
                                      _vm.step === 2
                                        ? _c("date-time-picker-old", {
                                            attrs: {
                                              "selected-docks":
                                                _vm.selectedDocks,
                                              "selected-load-type":
                                                _vm.selectedLoadType,
                                              "start-date": _vm.selectedDate,
                                              "selected-warehouse":
                                                _vm.selectedWarehouse,
                                              timezone:
                                                _vm.selectedWarehouse.timezone,
                                            },
                                            on: {
                                              "availability-error":
                                                _vm.handleAvailabilityError,
                                            },
                                            model: {
                                              value: _vm.selectedTime,
                                              callback: function ($$v) {
                                                _vm.selectedTime = $$v
                                              },
                                              expression: "selectedTime",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    {
                                      staticClass: "px-0",
                                      attrs: { step: "3" },
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        [
                                          _vm.$refNumSettings(
                                            _vm.selectedWarehouse
                                          ).isVisible
                                            ? _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "h3",
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$refNumSettings(
                                                              _vm.selectedWarehouse
                                                            ).displayName
                                                          ) +
                                                          ": "
                                                      ),
                                                      _vm.$refNumSettings(
                                                        _vm.selectedWarehouse
                                                      ).helperText
                                                        ? _c(
                                                            "help-icon-tooltip",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$refNumSettings(
                                                                      _vm.selectedWarehouse
                                                                    ).helperText
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "help-icon-tooltip",
                                                            [
                                                              _vm._v(
                                                                " Reference or PO Number associated with appointment or load delivery "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.$refNumSettings(
                                                    _vm.selectedWarehouse
                                                  ).isRequired
                                                    ? _c(
                                                        "auto-expandable-textarea",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.refNumberPlaceholder,
                                                            placeholder:
                                                              _vm.refNumberPlaceholder,
                                                            "validate-on-blur":
                                                              "",
                                                            rules:
                                                              _vm.$validator.rules.required(
                                                                _vm.$refNumSettings(
                                                                  _vm.selectedWarehouse
                                                                ).displayName
                                                              ),
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.refNumber,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.refNumber =
                                                                $$v
                                                            },
                                                            expression:
                                                              "refNumber",
                                                          },
                                                        }
                                                      )
                                                    : _c(
                                                        "auto-expandable-textarea",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.refNumberPlaceholder,
                                                            placeholder:
                                                              _vm.refNumberPlaceholder,
                                                            "validate-on-blur":
                                                              "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.refNumber,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.refNumber =
                                                                $$v
                                                            },
                                                            expression:
                                                              "refNumber",
                                                          },
                                                        }
                                                      ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.selectedWarehouse.id
                                            ? _c("custom-fields-old", {
                                                attrs: {
                                                  "initial-custom-fields":
                                                    _vm.customFields,
                                                  warehouse:
                                                    _vm.selectedWarehouse,
                                                },
                                                on: {
                                                  "toggle-confirm-button":
                                                    _vm.toggleConfirmButton,
                                                },
                                                model: {
                                                  value: _vm.customFields,
                                                  callback: function ($$v) {
                                                    _vm.customFields = $$v
                                                  },
                                                  expression: "customFields",
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-4" },
                                            [
                                              _c(
                                                "h3",
                                                [
                                                  _vm._v(
                                                    " Appointment Notes: "
                                                  ),
                                                  _c("help-icon-tooltip", [
                                                    _vm._v(
                                                      " Include any additional notes for this appointment "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("rich-text-input", {
                                                staticClass: "flex-fill",
                                                attrs: {
                                                  "editor-config": {
                                                    plugins: [
                                                      "Essentials",
                                                      "Paragraph",
                                                    ],
                                                    toolbar: { items: [] },
                                                  },
                                                },
                                                model: {
                                                  value: _vm.notes,
                                                  callback: function ($$v) {
                                                    _vm.notes = $$v
                                                  },
                                                  expression: "notes",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-4" },
                                            [
                                              _c(
                                                "h3",
                                                [
                                                  _vm._v(" Appointment Tags: "),
                                                  _c("help-icon-tooltip", [
                                                    _vm._v(
                                                      " Select from list of tags or create your own custom tag "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("tag-manager", {
                                                    attrs: {
                                                      outlined: "",
                                                      "outlined-chips": true,
                                                      "should-enable-custom-tags": true,
                                                      "small-chips": "",
                                                      placeholder: "Add tags",
                                                    },
                                                    model: {
                                                      value: _vm.tags,
                                                      callback: function ($$v) {
                                                        _vm.tags = $$v
                                                      },
                                                      expression: "tags",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-4" },
                                            [
                                              _c(
                                                "h3",
                                                [
                                                  _vm._v(
                                                    " Email Subscribers: "
                                                  ),
                                                  _c("help-icon-tooltip", [
                                                    _vm._v(
                                                      " List of emails that will receive all notifications about the appointment "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("email-list-field", {
                                                ref: "emailFieldList",
                                                attrs: {
                                                  "small-chips": "",
                                                  small: "",
                                                  disabled:
                                                    _vm.fetchingOrgCarrierSettings,
                                                },
                                                model: {
                                                  value: _vm.ccEmails,
                                                  callback: function ($$v) {
                                                    _vm.ccEmails = $$v
                                                  },
                                                  expression: "ccEmails",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-8" },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      dense: "",
                                                      disabled:
                                                        !_vm.notificationsEnabledOrgLevel,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mr-1",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.notificationsEnabled
                                                                              ? "mdi-bell"
                                                                              : "mdi-bell-off-outline"
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " Email notifications "
                                                                  ),
                                                                  _c("strong", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.notificationsEnabled
                                                                          ? "on"
                                                                          : "off"
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  !_vm.notificationsEnabledOrgLevel
                                                                    ? _c(
                                                                        "small",
                                                                        [
                                                                          _vm._v(
                                                                            " (disabled on the Org level) "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3644677923
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.notificationsEnabled,
                                                      callback: function ($$v) {
                                                        _vm.notificationsEnabled =
                                                          $$v
                                                      },
                                                      expression:
                                                        "notificationsEnabled",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "form-actions",
                  fn: function () {
                    return [
                      _c("action-group", {
                        attrs: {
                          loading: _vm.loading,
                          "hide-cancel": true,
                          "confirm-label": _vm.stepperLabelNext,
                          "disable-confirm":
                            _vm.disableConfirm || !_vm.showNextButton,
                        },
                        on: { confirm: _vm.proceed },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "additional-actions",
                              fn: function () {
                                return [
                                  _vm.step > 1
                                    ? _c(
                                        "outline-button",
                                        {
                                          attrs: {
                                            "before-icon": "arrow-left",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.step--
                                            },
                                          },
                                        },
                                        [_vm._v(" Previous ")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3396838173
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2458537759
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }