var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      staticClass:
        "custom-calendar-container custom-week-calendar-container fill-height",
    },
    [
      _c("v-calendar", {
        ref: "calendar",
        staticClass: "calendar",
        class: !_vm.$selectedWarehouse.id ? "disabled" : "clickable",
        attrs: {
          type: "month",
          weekdays: _vm.weekdaysToShow,
          events: _vm.events,
          color: "primary",
          "event-overlap-mode": "stack",
          "event-color": _vm.getEventClasses,
        },
        on: {
          "click:more": _vm.viewDay,
          "click:day": _vm.viewDay,
          "click:date": _vm.viewDay,
        },
        scopedSlots: _vm._u([
          {
            key: "day-label-header",
            fn: function (dateTime) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column",
                    class: dateTime.present ? "primary--text" : "",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "text-uppercase weekday-label" },
                      [_vm._v(_vm._s(_vm.weekdays[dateTime.weekday]))]
                    ),
                    _c("span", [_vm._v(_vm._s(dateTime.day))]),
                  ]
                ),
              ]
            },
          },
          {
            key: "day-body",
            fn: function ({ date, week }) {
              return [
                _c("div", {
                  staticClass: "v-current-time",
                  class: { first: date === week[0].date },
                  style: { top: _vm.nowY },
                }),
              ]
            },
          },
          {
            key: "event",
            fn: function ({ event }) {
              return [
                _c(
                  "div",
                  { key: `${_vm.renderKey}-${event.id}` },
                  [
                    _c("event-with-tooltip", {
                      attrs: {
                        event: event,
                        "is-light-theme": _vm.$useLightGridTheme,
                        enableMilitaryTime: _vm.$isMilitaryTimeEnabled(
                          _vm.$selectedWarehouse
                        ),
                        "use-new-grid-tiles": _vm.useNewGridTilesSetting,
                        "disable-tooltip": _vm.isTooltipDisabled(event),
                      },
                      on: {
                        click: _vm.handleEventClick,
                        "cancel-reserve": _vm.cancelReserve,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedDate,
          callback: function ($$v) {
            _vm.selectedDate = $$v
          },
          expression: "selectedDate",
        },
      }),
      _c("calendar-dialogs", {
        attrs: {
          "appointment-context": _vm.appointmentContext,
          "show-create-dialog": _vm.showCreateDialog,
          "show-details-dialog": _vm.showDetailsDialog,
          "show-reserve-dialog": _vm.showReserveDialog,
        },
        on: { close: _vm.handleDialogClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }