<template>
  <hours-of-operation-form
    class="px-8 pt-6"
    :warehouse="warehouse"
    :read-only="$isWarehouseReadOnly(warehouse.id, warehouse.orgId)"
    @schedule-updated="handlePostUpdate"></hours-of-operation-form>
</template>

<script>
export default {
  props: {
    /**
     * Warehouse entity
     */
    warehouse: {
      type: Object,
      required: true
    },
    postSubmissionRouteName: {
      type: String,
      required: false
    }
  },
  methods: {
    async trackMixPanel() {
      const entryPoint = this.mixpanel.getEntryPoint(this, [
        { entryPoint: 'Operating Hours Page', component: 'warehouse-details-page' }
      ]);
      await this.mixpanel?.track(this.mixpanel.events.MODULE.WAREHOUSE.OPERATING_HOURS, {
        'Warehouse ID': this.warehouse.id,
        'Warehouse Name': this.warehouse.name,
        'Entry Point': entryPoint,
        'Org ID': this.$org.id,
        'Org Name': this.$org.name
      });
    },
    async handlePostUpdate() {
      await this.trackMixPanel();
      if (this.postSubmissionRouteName) {
        await this.$router.push({
          name: this.postSubmissionRouteName,
          params: { warehouseId: this.warehouse.id }
        });
      }

      this.$emit('close');
    }
  }
};
</script>
