var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                !_vm.localFormField.field
                  ? _c("v-autocomplete", {
                      attrs: {
                        label: "Select a base field",
                        "prepend-icon": "mdi-text",
                        required: "",
                        items: _vm.fields,
                        "item-text": (f) => `${f.label} - ${f.description}`,
                        "item-value": "id",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function ({ item }) {
                              return [_vm._v(" " + _vm._s(item.label) + " ")]
                            },
                          },
                        ],
                        null,
                        false,
                        3440074194
                      ),
                      model: {
                        value: _vm.localFormField.fieldId,
                        callback: function ($$v) {
                          _vm.$set(_vm.localFormField, "fieldId", $$v)
                        },
                        expression: "localFormField.fieldId",
                      },
                    })
                  : _vm._e(),
                _vm.localFormField.field
                  ? _c(
                      "v-card",
                      { attrs: { elevation: "1" } },
                      [
                        _c("v-card-text", { staticClass: "pa-0" }, [
                          _c(
                            "h3",
                            { staticClass: "pa-3" },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(
                                  " mdi-" +
                                    _vm._s(
                                      _vm.novaCore.customFieldIcons[
                                        _vm.localFormField.field.type
                                      ]
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.localFormField.field.label) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "px-3 py-0" }, [
                            _vm._v(
                              _vm._s(_vm.localFormField.field.description)
                            ),
                          ]),
                          _vm.localFormField.field.extraFields?.dropDownValues
                            ?.length > 0
                            ? _c("section", { staticClass: "px-3 py-2" }, [
                                _c("p", { staticClass: "mb-1" }, [
                                  _c("strong", [_vm._v("Choices:")]),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "- " +
                                      _vm._s(
                                        _vm.localFormField.field.extraFields.dropDownValues
                                          .slice(0, 10)
                                          .join(", ")
                                      )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-card",
                  { staticClass: "my-4", attrs: { flat: "" } },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "mt-4 pa-1" },
                      [
                        _c(
                          "h4",
                          [
                            _c("strong", { staticClass: "mr-2" }, [
                              _vm._v("Replace the label on this form:"),
                            ]),
                            _c(
                              "help-icon-tooltip",
                              { attrs: { "icon-style-class": "grey" } },
                              [
                                _vm._v(
                                  " This will not replace the label on forms that also use this field "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("v-text-field", {
                          attrs: { placeholder: "Enter a label" },
                          model: {
                            value: _vm.localFormField.overrideLabel,
                            callback: function ($$v) {
                              _vm.$set(_vm.localFormField, "overrideLabel", $$v)
                            },
                            expression: "localFormField.overrideLabel",
                          },
                        }),
                        _c("v-switch", {
                          attrs: {
                            inset: "",
                            label: "Required for this form?",
                          },
                          model: {
                            value: _vm.localFormField.required,
                            callback: function ($$v) {
                              _vm.$set(_vm.localFormField, "required", $$v)
                            },
                            expression: "localFormField.required",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "confirm-icon": "upload",
                "confirm-label": "Save",
                "cancel-label": "Nevermind",
                "cancel-icon": null,
              },
              on: {
                cancel: function ($event) {
                  return _vm.$emit("close")
                },
                confirm: _vm.submit,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }