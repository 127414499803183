<template>
  <!-- TODO: We'll end up removing these most likely - temp fix for clients -->
  <div id="zoom-buttons" class="zoom-buttons d-none">
    <v-btn small tile @click="zoomIn"><v-icon small>mdi-magnify-plus</v-icon></v-btn>
    <v-btn small tile @click="zoomOut"><v-icon small>mdi-magnify-minus</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    zoomIn() {
      if (this.$zoomLevel < 100) {
        this.$store.dispatch(
          'Calendar/setZoomLevel',
          this.novaCore.roundToNearestNumber(this.$zoomLevel, 5, true) + 5
        );
      }
    },
    zoomOut() {
      if (this.$zoomLevel > 10) {
        this.$store.dispatch(
          'Calendar/setZoomLevel',
          this.novaCore.roundToNearestNumber(this.$zoomLevel, 5, false) - 5
        );
      }
    }
  }
};
</script>
