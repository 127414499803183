<template>
  <v-container class="pa-4">
    <template v-if="$isFetchingEvents && isFirstPage">
      <div class="d-flex flex-column align-center justify-center pt-8">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <span>Loading Appointments...</span>
      </div>
    </template>
    <template v-else>
      <template v-if="eventsByDate.length === 0">
        <v-row
          no-gutters
          class="mt-8 flex-column align-center"
          data-testid="no-appointments-message-container">
          <v-icon class="mr-2 primary--text mb-3" x-large>mdi-arrow-up</v-icon>
          <strong class="no-results-message">No appointments for this date</strong>
          <span class="px-4 text-center pt-3">
            You can select a different date or search for an appointment at the top toolbar
          </span>
        </v-row>
      </template>
      <template v-else>
        <v-row
          no-gutters
          v-for="event in eventsByDate"
          :key="`event-list-${event.id}`"
          class="mb-4">
          <v-col>
            <article
              class="mobile-event-tile"
              :class="[`is-${event.status.toLowerCase()}`]"
              @click="openDetailsDialog(event)">
              <div class="d-flex align-center justify-space-between">
                <div class="tile-header">
                  <strong class="mr-4" :data-timestamp="event.start">
                    {{ getEventStart(event) }}
                  </strong>
                  <span class="company-name">{{ event.companyName }}</span>
                </div>
                <span
                  :class="[event.status.toLowerCase()]"
                  class="font-size-x-small rounded pa-1 white--text font-weight-bold current-status">
                  {{ event.status }}
                </span>
              </div>
              <div class="d-flex align-center mt-2 font-size-x-small">
                <div class="third-width">
                  <span class="d-block">Confirmation</span>
                  <span class="d-block appointment-confirmation-number">
                    {{ event.confirmationNumber }}
                  </span>
                </div>
                <div class="third-width">
                  <span class="d-block">Reference</span>
                  <span class="d-block" v-html="getRowVal(event.refNumber)"></span>
                </div>
                <div class="third-width">
                  <span class="d-block">Dock</span>
                  <span class="d-block">{{ event.dock.name }}</span>
                </div>
              </div>
            </article>
          </v-col>
        </v-row>
      </template>

      <outline-button
        v-if="shouldShowLoadMoreButton"
        data-testid="load-more-button"
        block
        :loading="$isFetchingEvents"
        @click="loadMoreAppointments">
        Load More Results
      </outline-button>
      <template v-else>
        <div v-if="eventsByDate.length > 0" class="d-flex align-start">
          <v-icon class="mr-2">mdi-list-status</v-icon>
          <div class="d-flex flex-column">
            <span>No more appointments for this date.</span>
            <span>You can try choosing another date at the top toolbar.</span>
          </div>
        </div>
      </template>
    </template>

    <v-btn
      icon
      class="back-to-top-btn mobile-icon-btn"
      elevation="5"
      ref="scrolltotop"
      @click="scrollToTop">
      <v-icon>mdi-chevron-double-up</v-icon>
    </v-btn>

    <appointment-details-dialog-mobile
      content-class="mobile-dialog details-dialog-mobile"
      fullscreen
      transition="dialog-bottom-transition"
      external-activator
      @close="showDetailsDialog = false"
      :show-dialog="showDetailsDialog"
      :appointment="$selectedEvent"></appointment-details-dialog-mobile>
  </v-container>
</template>

<script>
import { CalendarViewEnum } from '@/enums';
import appointmentMixin from '@/components/mixins/appointmentMixin';
import { formatDateTimeWithMilitarySupport, LuxonDateTimeFormats } from '@satellite/../nova/core';

const RESULT_LIMIT = 10;
export default {
  mixins: [appointmentMixin],
  data() {
    return {
      showDetailsDialog: false,
      initialPagination: {
        page: 1,
        limit: RESULT_LIMIT
      },
      loadingEvents: false,
      container: null
    };
  },
  computed: {
    calendarDataKey() {
      return `nova_calendar_${this.$me.id}`;
    },
    calendarData() {
      return this.$loadObjectFromLocalStorage(this.calendarDataKey);
    },
    eventsByDate() {
      return this.$events;
    },
    shouldShowLoadMoreButton() {
      return this.$eventPagination?.page < this.$eventPagination?.pageCount;
    },
    isFirstPage() {
      return !this.$eventPagination;
    }
  },
  methods: {
    getEventStart(event) {
      return formatDateTimeWithMilitarySupport(
        event.utcStart,
        this.$selectedWarehouse?.timezone,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$selectedWarehouse),
        LuxonDateTimeFormats.Extended24HrTime
      );
    },
    async setupLocalStorageData() {
      // Force all docks for selected warehouse and load types/statuses and DAY view
      this.$store.commit('Calendar/setViewType', CalendarViewEnum.DAY.toUpperCase());
      if (this.calendarData?.selectedWarehouse?.id) {
        const warehouse = this.calendarData?.selectedWarehouse;
        this.$store.commit('Calendar/setSelectedWarehouse', warehouse);
        this.$store.commit(
          'Calendar/setSelectedDocks',
          warehouse?.docks?.filter(dock => dock.isActive)
        );
        this.$store.commit(
          'Calendar/setSelectedLoadtypes',
          this.util.getLoadTypesFromActiveDocks(warehouse?.docks)
        );
      } else {
        let response = await axios.get(`warehouse?join=docks&limit=1`);
        if (response?.data?.data?.length > 0) {
          const warehouse = response.data.data[0];
          this.$store.commit('Calendar/setSelectedWarehouse', warehouse);
          this.$store.commit(
            'Calendar/setSelectedDocks',
            this.util.getActiveDocksFromWarehouse(warehouse)
          );
          this.$store.commit(
            'Calendar/setSelectedLoadtypes',
            this.util.getLoadTypesFromActiveDocks(warehouse?.docks)
          );
        }
      }
    },
    openDetailsDialog(event) {
      this.$store.commit('Calendar/setSelectedEvent', event);
      this.showDetailsDialog = true;
    },
    async loadMoreAppointments() {
      await this.$store.dispatch('Calendar/getEvents', {
        fetchForWarehouse: true,
        orderByStart: true,
        page: this.$eventPagination?.page + 1,
        limit: RESULT_LIMIT,
        shouldFilterReserves: true,
        dismissCheckInNotifications: true
      });
    },
    handleScroll() {
      if (
        this.container?.scrollTop > 0 &&
        !this.$refs.scrolltotop.$el.classList.contains('visible')
      ) {
        this.$refs.scrolltotop.$el.classList.add('visible');
      }
      if (
        this.container?.scrollTop === 0 &&
        this.$refs.scrolltotop?.$el?.classList?.contains('visible')
      ) {
        this.$refs.scrolltotop?.$el?.classList?.remove('visible');
      }
    },
    scrollToTop() {
      this.container?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.$refs.scrolltotop?.$el?.classList?.remove('visible');
    }
  },
  async mounted() {
    await this.setupLocalStorageData();
    this.container = document.getElementById('appointments-page-container-mobile');
  },
  created() {
    window.addEventListener('touchmove', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('touchmove', this.handleScroll);
  },
  watch: {
    async $selectedDate() {
      await this.$store.dispatch('Calendar/getEvents', {
        fetchForWarehouse: true,
        ...this.initialPagination,
        orderByStart: true,
        shouldClearPagination: true,
        shouldFilterReserves: true,
        dismissCheckInNotifications: true
      });
    },
    async $selectedWarehouse() {
      await this.$store.dispatch('Calendar/getEvents', {
        fetchForWarehouse: true,
        orderByStart: true,
        ...this.initialPagination,
        shouldFilterReserves: true,
        dismissCheckInNotifications: true
      });
      await this.$store.dispatch('Calendar/getEvents', {
        fetchForWarehouse: true,
        ...this.initialPagination,
        orderByStart: true,
        shouldClearPagination: true,
        shouldFilterReserves: true,
        dismissCheckInNotifications: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.back-to-top-btn {
  position: fixed;
  z-index: 1;
  bottom: 4rem;
  opacity: 0;
  transition: opacity 0.5s ease;
  right: 1rem;

  &.visible {
    opacity: 1;
  }
}
</style>
