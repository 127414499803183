<template>
  <div class="text-center">
    <v-progress-linear indeterminate color="primary" class="mb-1 mt-4"></v-progress-linear>
    <span>Loading</span>
  </div>
</template>

<script>
export default {};
</script>
