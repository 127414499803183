import storageHelpers from '@satellite/helpers/storage';
import yardSpotService from '@satellite/services/yardSpot-service';
import yardSpotAreaService from '@satellite/services/yardSpotArea-service';
import assetVisitService from '@satellite/services/asset-visit-service';
import messageThreadService from '@satellite/services/message-thread-service';

export const viewModes = Object.freeze({
  grid: 'grid',
  list: 'list'
});

function makeInitialYardData(state) {
  return {
    viewMode: state.viewMode
  };
}

function setYardData(userId, key, data, state) {
  const novaYardKey = storageHelpers.makeUserBoundedKey('nova_yard', userId);

  if (!localStorage.getItem(novaYardKey)) {
    localStorage.setItem(
      novaYardKey,
      JSON.stringify({
        ...makeInitialYardData(state)
      })
    );
  }

  let currentStorage = JSON.parse(localStorage.getItem(novaYardKey));
  currentStorage[key] = data;

  localStorage.setItem(novaYardKey, JSON.stringify({ ...currentStorage }));
}

const state = {
  selectedWarehouse: null,
  selectedSpot: null,
  selectedAssetVisit: null,
  isAssetVisitChatVisible: false,
  selectedSpots: [],
  selectedAreas: [],
  spots: [],
  spotAreas: [],
  assetVisits: [],
  isLoadingSpots: false,
  isLoadingAssetVisits: false,
  viewMode: viewModes.grid
};

const getters = {};

const actions = {
  checkSelectedWarehouse({ state }) {
    if (!state.selectedWarehouse?.id) {
      this.$app.notify('Please select a warehouse first', 'error');
      return false;
    }

    return true;
  },
  async fetchAssetVisits({ commit, dispatch }, params) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }
    commit('setIsLoadingAssetVisits', true);
    try {
      const assetVisits = await assetVisitService.getLatestAssetVisitsByWarehouseId(
        state.selectedWarehouse.id,
        params
      );
      commit('setAssetVisits', assetVisits || []);
    } catch (error) {
      this.$app.notify('Failed to get asset visits', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingAssetVisits', false);
    }
  },
  async fetchSpots({ commit, state, dispatch }, params) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }
    commit('setIsLoadingSpots', true);
    try {
      const spots = await yardSpotService.getManyByWarehouseId(state.selectedWarehouse.id, params);
      commit('setSpots', spots || []);
    } catch (error) {
      this.$app.notify('Failed to get spots', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async fetchSpot({ commit }, id) {
    commit('setIsLoadingSpots', true);
    try {
      const spot = await yardSpotService.getOneById(id, {});
      commit('setSelectedSpot', spot);
      return spot;
    } catch (error) {
      this.$app.notify('Failed to get spot', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async fetchSpotAreas({ commit, state, dispatch }, params) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }
    try {
      const spotAreas = await yardSpotAreaService.getManyByWarehouseId(
        state.selectedWarehouse.id,
        params
      );
      commit('setSpotAreas', spotAreas || []);
      commit('setSelectedAreas', spotAreas.map(area => area.id) || []);
    } catch (error) {
      this.$app.notify('Failed to get spot areas', 'error');
      console.warn(error);
    }
  },
  async createSpotAssignment({ dispatch }, data) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }

    try {
      const spotAssignment = await yardSpotService.createSpotAssignment(data);
      this.$app.notify('Spot assignment created successfully', 'success');
      dispatch('fetchSpots');
      dispatch('fetchAssetVisits');
      return spotAssignment;
    } catch (error) {
      this.$app.notify('Failed to create spot assignment', 'error');
      console.warn(error);
    }
  },
  async deleteSpotAssignment({ dispatch, commit }, id) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }
    commit('setIsLoadingSpots', true);
    try {
      await yardSpotService.deleteSpotAssignment(id);
      this.$app.notify('Spot assignment deleted successfully', 'success');
      dispatch('fetchSpots');
      dispatch('fetchAssetVisits');
      commit('setSelectedSpot', null);
    } catch (error) {
      this.$app.notify('Failed to delete spot assignment', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async departSpotAssignment({ dispatch, commit }, id) {
    commit('setIsLoadingAssetVisits', true);
    try {
      await yardSpotService.departSpotAssignment(id);
      this.$app.notify('Asset visit departed successfully', 'success');
      dispatch('fetchSpots');
      dispatch('fetchAssetVisits');
      commit('setSelectedSpot', null);
    } catch (error) {
      this.$app.notify('Failed to depart asset visit', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingAssetVisits', false);
    }
  },
  async reassignSpotAssignment({ dispatch, commit }, { id, data }) {
    commit('setIsLoadingSpots', true);
    try {
      const spotAssignment = await yardSpotService.reassignSpotAssignment(id, data);
      this.$app.notify('Spot assignment reassigned successfully', 'success');
      dispatch('fetchSpots');
      commit('setSelectedSpot', null);
      return spotAssignment;
    } catch (error) {
      this.$app.notify('Failed to reassign spot assignment', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async createSpotArea({ dispatch, state }, data) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }

    try {
      const spotArea = await yardSpotAreaService.create({
        ...data,
        warehouseId: state.selectedWarehouse.id
      });
      dispatch('fetchSpotAreas');
      this.$app.notify('Spot area created successfully', 'success');
      return spotArea;
    } catch (error) {
      this.$app.notify('Failed to create spot area', 'error');
      console.warn(error);
    }
  },
  async updateSpotArea({ dispatch }, { id, data }) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }
    try {
      const spotArea = await yardSpotAreaService.update(id, data);
      dispatch('fetchSpotAreas');
      this.$app.notify('Spot area updated successfully', 'success');
      return spotArea;
    } catch (error) {
      this.$app.notify('Failed to update spot area', 'error');
      console.warn(error);
    }
  },
  async deleteSpotArea({ dispatch }, id) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }
    try {
      await yardSpotAreaService.delete(id);
      this.$app.notify('Spot area deleted successfully', 'success');
    } catch (error) {
      this.$app.notify('Failed to delete spot area', 'error');
      console.warn(error);
    }
  },
  async updateSpot({ dispatch }, { id, data }) {
    try {
      const spot = await yardSpotService.update(id, data);
      dispatch('fetchSpots');
      this.$app.notify('Spot updated successfully', 'success');
      return spot;
    } catch (error) {
      this.$app.notify('Failed to update spot', 'error');
      console.warn(error);
    }
  },
  async createSpot({ dispatch, commit }, data) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }
    commit('setIsLoadingSpots', true);
    try {
      const spot = await yardSpotService.create({
        ...data
      });
      dispatch('fetchSpots');
      this.$app.notify('Spot created successfully', 'success');
      return spot;
    } catch (error) {
      this.$app.notify('Failed to create spot', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async createManySpot({ dispatch, commit }, data) {
    if (!dispatch('checkSelectedWarehouse')) {
      return;
    }
    commit('setIsLoadingSpots', true);
    try {
      await yardSpotService.createMany(data);
      dispatch('fetchSpots');
      this.$app.notify('Spots created successfully', 'success');
    } catch (error) {
      this.$app.notify('Failed to create spots', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async closeSpot({ dispatch, commit }, { id, data }) {
    commit('setIsLoadingSpots', true);
    try {
      const spot = await yardSpotService.close(id, data);
      dispatch('fetchSpots', { s: { spotAreaId: spot.spotAreaId } });
      commit('setSelectedSpot', null);
      this.$app.notify('Spot closed successfully', 'success');
    } catch (error) {
      this.$app.notify('Failed to close spot', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async openSpot({ dispatch, commit }, { id, data }) {
    commit('setIsLoadingSpots', true);
    try {
      const spot = await yardSpotService.open(id, data);
      dispatch('fetchSpots', { s: { spotAreaId: spot.spotAreaId } });
      commit('setSelectedSpot', null);
      this.$app.notify('Spot opened successfully', 'success');
    } catch (error) {
      this.$app.notify('Failed to open spot', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async deleteSpot({ dispatch, commit }, id) {
    commit('setIsLoadingSpots', true);
    try {
      await yardSpotService.delete(id);
      dispatch('fetchSpots');
      commit('setSelectedSpot', null);
      this.$app.notify('Spot deleted successfully', 'success');
    } catch (error) {
      this.$app.notify('Failed to delete spot', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async deleteManySpot({ dispatch, commit }, ids) {
    commit('setIsLoadingSpots', true);
    try {
      await yardSpotService.deleteMany(ids);
      dispatch('fetchSpots');
      commit('setSelectedSpot', null);
      this.$app.notify('Spots deleted successfully', 'success');
    } catch (error) {
      this.$app.notify('Failed to delete spots', 'error');
      console.warn(error);
    } finally {
      commit('setIsLoadingSpots', false);
    }
  },
  async messageDriver({}, { assetVisitId, content }) {
    try {
      const messageThread = await messageThreadService.getMessageThreadByAssetVisitId(assetVisitId);
      if (messageThread?.id) {
        await messageThreadService.sendMessage(messageThread.id, { content });
        this.$app.notify('Message sent successfully', 'success');
      } else {
        this.$app.notify('Failed to send message', 'error');
      }
    } catch (error) {
      this.$app.notify('Failed to send message', 'error');
      console.warn(error);
    }
  },
  setViewMode({ commit, state, rootState }, { value }) {
    commit('setViewMode', value);

    setYardData(rootState.Auth.me.id, 'viewMode', value, state);
  }
};

const mutations = {
  setSelectedWarehouse(state, warehouse) {
    state.selectedWarehouse = warehouse;
    if (!warehouse?.id) {
      state.selectedAreas = [];
      state.spots = [];
      state.spotAreas = [];
      state.assetVisits = [];
    }
    state.selectedSpot = null;
  },
  setSelectedSpot(state, spot) {
    state.selectedAssetVisit = null;
    state.isAssetVisitChatVisible = false;
    state.selectedSpot = spot;
  },
  setSelectedAssetVisit(state, assetVisit) {
    state.selectedSpot = null;
    state.selectedAssetVisit = assetVisit;
    state.isAssetVisitChatVisible = false;
  },
  setSelectedSpots(state, spots) {
    state.selectedSpots = spots;
  },
  setIsAssetVisitChatVisible(state, value) {
    state.isAssetVisitChatVisible = value;
  },
  setSelectedAreas(state, areas) {
    state.selectedAreas = areas;
    state.selectedSpot = null;
  },
  setSpots(state, spots) {
    state.spots = spots;
  },
  setSpotAreas(state, spotAreas) {
    state.spotAreas = spotAreas;
  },
  setAssetVisits(state, assetVisits) {
    state.assetVisits = assetVisits;
  },
  setIsLoadingSpots(state, value) {
    state.isLoadingSpots = value;
  },
  setIsLoadingAssetVisits(state, value) {
    state.isLoadingAssetVisits = value;
  },
  setViewMode(state, value) {
    state.viewMode = value;
  }
};

export default {
  namespaced: true,
  name: 'Yard',
  state,
  getters,
  actions,
  mutations
};
