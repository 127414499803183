import { ref } from 'vue';
import { useNovaCore } from '@/composables/index';

export default function useEta() {
  const etaCondition = ref('');
  const novaCore = useNovaCore();

  const getEtaConditionMeta = etaCondition => {
    const meta = {
      color: '',
      classes: ''
    };

    switch (etaCondition) {
      case novaCore.EtaCondition.Early:
        meta.color = 'blue';
        meta.classes = 'lighten-5 black--text';
        break;
      case novaCore.EtaCondition.Late:
        meta.color = 'error';
        meta.classes = 'white--text';
        break;
      default:
        break;
    }

    return meta;
  };

  return {
    etaCondition,
    getEtaConditionMeta
  };
}
