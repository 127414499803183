import WarehousesPage from '@/modules/warehouses/WarehousesPage';

export default [
  {
    path: '/docks',
    name: 'docks',
    component: WarehousesPage,
    props: true,
    meta: {
      title: 'Docks',
      requiresAuth: true
    }
  }
];
