import { removeArrayDuplicates } from '@satellite/../nova/core';

/**
 * Generate a query string from options, specifically for fields and joins.
 * @param {Object} options - Options object.
 * @param {Array} options.fields - Array of fields to include in the query string.
 * @param {Array} options.joins - Array of joins to include in the query string.
 * @returns {string} - The generated query string.
 */
export function getQueryStringFromOptions(options = {}) {
  const { fields, joins } = cleanRequestOptions(options);
  let queryString = '';
  if (fields?.length) {
    queryString += `fields=${fields.join(',')}`;
  }
  if (joins?.length) {
    queryString += `${queryString ? '&' : ''}join=${joins.join('&join=')}`;
  }
  return queryString;
}

/**
 * Get the appropriate response data based on options.
 * @param {Object} response - The response object.
 * @param {Object} options - Options for handling the response.
 * @returns {Object} - The response data or the entire response based on options.
 */
export function getResponse(response = null, options = {}) {
  if (options?.includeMetaData) {
    // If includeMetaData is true, return the entire response
    return response;
  } else {
    // Otherwise, return only the response data
    return response?.data?.data?.data ?? response?.data?.data ?? response?.data ?? response;
  }
}

export function cleanRequestOptions(options) {
  return {
    fields: removeArrayDuplicates(options.fields),
    joins: removeArrayDuplicates(options.joins)
  };
}
