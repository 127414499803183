import LoginPage from './LoginPage';
import RegistrationPage from './RegistrationPage';
import NotAuthorized from './components/NotAuthorized';

export default [
  {
    path: '/login/:verificationToken?',
    name: 'login',
    component: LoginPage,
    props: true,
    meta: {
      title: 'Login',
      bypassOrgCheck: true,
      requiresAuth: false,
      hideSidebar: true,
      customHeader: true,
      fullScreen: true,
      allowMobile: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegistrationPage,
    props: true,
    meta: {
      title: 'Register',
      bypassOrgCheck: true,
      requiresAuth: false,
      hideSidebar: true,
      customHeader: true,
      fullScreen: true,
      allowMobile: true
    }
  },
  {
    path: '/auth/401',
    name: 'notAuthorized',
    props: false,
    component: NotAuthorized,
    meta: {
      title: '401 Not Authorized',
      bypassOrgCheck: true,
      requiresAuth: false,
      hideSidebar: true
    }
  },
  {
    path: '/auth/reset-password/:resetEmail/:resetToken',
    name: 'resetPassword',
    props: true,
    component: LoginPage,
    meta: {
      title: 'Reset Password',
      bypassOrgCheck: true,
      requiresAuth: false,
      hideSidebar: true,
      customHeader: true,
      fullScreen: true,
      allowMobile: true
    }
  },
  {
    path: '/auth/verify/:verificationToken',
    name: 'verifyEmail',
    redirect: '/login/:verificationToken',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/auth/verify-and-reset-password/:resetEmail/:resetToken',
    name: 'verifyAndResetPassword',
    props: true,
    component: LoginPage,
    meta: {
      title: 'Activate account and set password',
      bypassOrgCheck: true,
      requiresAuth: false,
      hideSidebar: true,
      customHeader: true,
      fullScreen: true,
      verifyAccount: true,
      allowMobile: true
    }
  }
];
