var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.trigger?.flow
    ? _c(
        "dialog-base",
        _vm._b(
          {
            key: _vm.renderKey,
            attrs: {
              fullscreen: _vm.fullscreen,
              "no-padding": _vm.fullscreen,
              header: _vm.flow.name || "Flow",
            },
            on: { close: _vm.close },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { class: { "ma-4": _vm.fullscreen } },
                        [
                          _c("p", { staticClass: "font-size-medium mb-7" }, [
                            _vm._v(_vm._s(_vm.flow.description)),
                          ]),
                          _c("custom-forms-flow-form", {
                            staticClass: "m-4",
                            attrs: {
                              "display-label": false,
                              trigger: _vm.trigger,
                              "object-id": _vm.objectId,
                              warehouse: _vm.warehouse,
                              "status-change": _vm.statusChange,
                              "stack-actions": _vm.stackActions,
                            },
                            on: {
                              close: _vm.close,
                              update: (data) => _vm.$emit("update", data),
                              create: (data) => _vm.$emit("create", data),
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3936446710
            ),
          },
          "dialog-base",
          [_vm.$props, _vm.$attrs],
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }