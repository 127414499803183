<template>
  <div v-if="shouldShowPasswordRestrictions(password)">
    <span
      v-for="error in passwordValidationErrors"
      class="d-block"
      :class="{ text__error: !error.passed }"
      :key="error.error">
      {{ error.message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    password: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      passwordValidationErrors: []
    };
  },
  watch: {
    password(newValue) {
      this.computePasswordRestrictions(newValue);
    }
  },
  methods: {
    computePasswordRestrictions(password) {
      this.passwordValidationErrors = this.novaCore.getPasswordValidationErrors(password);
    },
    shouldShowPasswordRestrictions(password) {
      return password.length > 0 && this.$validator.rules.password.some(x => x(password) !== true);
    }
  },
  mounted() {
    this.computePasswordRestrictions(this.password);
  }
};
</script>
