var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _c(
        "v-menu",
        {
          attrs: { value: _vm.modal },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          icon: "",
                          "data-testid":
                            "carrier-contacts-data-table-menu-activator",
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b({}, "v-icon", attrs, false),
                                        on
                                      ),
                                      [_vm._v("mdi-dots-horizontal")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: {
                                "data-testid":
                                  "carrier-contacts-data-table-menu-tooltip",
                              },
                            },
                            [_vm._v("More actions")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { staticClass: "actions-list" },
            [
              _c(
                "v-list-item",
                {
                  class: {
                    clickable: _vm.canManageContact,
                  },
                  attrs: {
                    disabled: !_vm.canManageContact,
                    "data-testid":
                      "carrier-contacts-data-table-menu-subscriber-activator",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.manageSubscribersDialog(true)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-email-fast-outline")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Manage subscribers")]),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  staticClass: "clickable",
                  on: {
                    click: function ($event) {
                      return _vm.manageAppointmentDialog(true)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-calendar")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Schedule appointment")]),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-item",
                {
                  class: {
                    "list-item-danger": _vm.canManageContact,
                    clickable: _vm.canManageContact,
                  },
                  attrs: {
                    disabled: !_vm.canManageContact,
                    "data-testid":
                      "carrier-contacts-data-table-menu-remove-activator",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.manageRemoveDialog(true)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-playlist-remove")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Remove from list")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("carrier-contact-subscriber-dialog", {
        ref: "subscribersDialog",
        attrs: {
          "external-activator": "",
          "carrier-contact": _vm.carrierContact,
        },
      }),
      _c("create-appointment-dialog", {
        attrs: {
          "external-activator": "",
          "show-dialog": _vm.appointmentDialog,
          context: { selectedCarrier: _vm.carrierContact.user },
        },
        on: {
          close: function ($event) {
            return _vm.manageAppointmentDialog(false)
          },
        },
      }),
      _c("carrier-contact-remove-dialog", {
        ref: "removeDialog",
        attrs: {
          "mixpanel-data": _vm.mixpanelData,
          "external-activator": "",
          "carrier-contact": _vm.carrierContact,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }