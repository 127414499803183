<template>
  <div class="flex-grow-1">
    <div class="d-flex flex-wrap mt-3 align-center">
      <h5 class="mb-2 mr-2">Tagged As:</h5>
      <template v-for="(tag, index) in value">
        <v-chip
          outlined
          small
          :key="`tag-${tag}`"
          close
          color="#737373"
          close-icon="mdi-close"
          class="mb-2"
          v-if="!util.getCustomTagData($org.customTags, tag)"
          @click:close="$emit('remove-tag', tag)"
          :class="{ 'mr-2': index + 1 < value.length }">
          <span class="pr-2">{{ tag }}</span>
        </v-chip>

        <v-chip
          v-else
          small
          :key="`tag-${tag}`"
          v-bind="$attrs"
          :color="util.getCustomTagData($org.customTags, tag).color"
          :text-color="util.getCustomTagData($org.customTags, tag).textColor"
          :class="{ 'mr-2': index + 1 < value.length }"
          class="mb-2"
          label
          @click:close="$emit('remove-tag', tag)">
          <span class="pr-2">{{ util.getCustomTagData($org.customTags, tag).name }}</span>
          <v-icon small v-if="allowTagDelete" @click="removeTag(tag)">mdi-close</v-icon>
        </v-chip>
      </template>
    </div>
  </div>
</template>

<script>
import TagManagerBase from '@satellite/components/elements/TagManagerBase.vue';

export default {
  extends: TagManagerBase
};
</script>

<style lang="scss" scoped>
.v-chip {
  border-radius: 5px !important;
}
</style>
