import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async getDockById(id, params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`dock/${id}?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },
  async getDocks(params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`dock?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },
  async getListDocks(params, options = {}) {
    const response = await this.getDocks(params, {
      fields: ['name'],
      includeMetaData: options?.includeMetaData
    });
    return response;
  },
  async createCapacityDock(id, options) {
    const response = await axios.post(`dock/${id}/capacity`);
    return getResponse(response, options);
  },
  async unlinkCapacityDock(id, parentId, options) {
    const response = await axios.delete(`dock/${parentId}/capacity/${id}`);
    return getResponse(response, options);
  },
  async createDock(dock, options) {
    const response = await axios.post('dock', dock);
    return getResponse(response, options);
  },
  async saveDockOrder(docks, options) {
    const response = await axios.post(`dock/sort`, docks);
    return getResponse(response, options);
  },
  async updateDock(dock, options) {
    const response = await axios.patch(`dock/${dock.id}`, dock);
    return getResponse(response, options);
  },
  async deleteDock(id, options) {
    let response = await axios.delete(`dock/${id}`, { data: { hardDelete: true } });
    return getResponse(response, options);
  },
  async deactivateDock(id, options) {
    let response = await axios.patch(`dock/${id}`, { isActive: false });
    return getResponse(response, options);
  },
  async activateDock(id, options) {
    let response = await axios.patch(`dock/${id}`, { isActive: true });
    return getResponse(response, options);
  }
};
