<template>
  <div>
    <form-base :header="header" hide-required-legend>
      <template #form>
        <v-form ref="form" :class="{ disabled: readOnly }">
          <v-container class="px-8 pt-8" fluid>
            <h2 class="mb-8">Address & Details</h2>
            <h4 class="mb-6">Contact Info</h4>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <text-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  :required="true"
                  type="text"
                  v-model="editingWarehouse.name"
                  :rules="$validator.rules.required('Warehouse Name')"
                  tooltipText="Name of Warehouse location visible to Warehouse and Carriers"
                  label="Warehouse Name"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <phone-number-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  margin-top="-7px"
                  v-model="editingWarehouse.phone"
                  :validator="$validator"
                  :default-country="defaultPhoneCountry"
                  tooltipText="Warehouse contact phone number for Carriers"
                  label="Phone"></phone-number-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <text-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  type="email"
                  v-model="editingWarehouse.email"
                  tooltipText="Warehouse contact email for Carriers. It will be used as 'Reply To' address of the appointment emails."
                  :rules="$validator.rules.email"
                  label="Warehouse Contact Email"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <text-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  prepend-icon="mdi-pound"
                  v-model="editingWarehouse.facilityNumber"
                  tooltipText="Warehouse store number"
                  label="Store Number"></text-field>
              </v-col>
            </v-row>

            <v-row class="mb-4">
              <v-col class="align-field" cols="12" sm="6" md="4">
                <country-select
                  :disabled="readOnly"
                  v-model="editingAddress.country"
                  limit-options
                  tooltipText="Country of the Warehouse location"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"></country-select>
              </v-col>
              <v-col :class="{ 'align-field': manualAddressMode }">
                <address-autocomplete-field
                  required
                  ref="searchAddressField"
                  v-if="!manualAddressMode"
                  clear-on-country-change
                  v-model="geocodingData"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  :disabled="!editingAddress.country || readOnly"
                  :country="editingAddress.country"
                  tooltipText="Address of the Warehouse location visible to carriers"
                  @filled="fillFullAddress"
                  @manual-override="enterManualAddressMode"></address-autocomplete-field>
                <street-field
                  v-else
                  ref="manualStreetField"
                  @search="enterSearchAddressMode"
                  required
                  v-model="editingAddress.street"
                  :rules="$validator.rules.required('Street Address')" />
              </v-col>
            </v-row>

            <address-display
              v-if="!manualAddressMode && editingAddress.city"
              :zip="editingAddress.zip"
              :city="editingAddress.city"
              :state="editingAddress.state"
              :street="editingAddress.street"
              :timezone="editingAddress.timezone" />

            <v-row v-else-if="manualAddressMode">
              <v-col cols="12" sm="6" md="3">
                <text-field
                  required
                  type="text"
                  v-model="editingAddress.city"
                  :rules="$validator.rules.required('City')"
                  label="City"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <state-select
                  v-if="editingAddress.country === 'USA' || editingAddress.country === 'CAN'"
                  required
                  :rules="$validator.rules.required('State')"
                  label="State"
                  :country="editingAddress.country"
                  v-model="editingAddress.state"></state-select>
                <text-field
                  v-else
                  required
                  type="text"
                  v-model="editingAddress.state"
                  :rules="$validator.rules.required('State')"
                  label="State"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <text-field
                  required
                  type="text"
                  v-model="editingAddress.zip"
                  :rules="$validator.rules.required('Zip')"
                  placeholder="85331"
                  label="Zip / Postal Code"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <timezone-select
                  v-model="editingAddress.timezone"
                  :rules="$validator.rules.required('Timezone')"
                  label="Timezone"
                  required />
              </v-col>
            </v-row>

            <h4 class="mb-2 pt-6">
              Warehouse Logo
              <help-icon-tooltip class="z-100" icon-style-class="grey darken-2">
                {{ warehouseLogoSetting.label }}
              </help-icon-tooltip>
            </h4>
            <div class="d-flex align-center mt-2">
              <document-upload-button
                :read-only="readOnly"
                :crop-shape="warehouseLogoSetting.documentOptions?.cropShape"
                :aspectRatio="warehouseLogoSetting.documentOptions?.aspectRatio"
                :show-crop-result="warehouseLogoSetting.documentOptions?.showCropResult"
                :modal-header-text="warehouseLogoSetting.documentOptions?.modalHeaderText"
                allow-warehouseLogoSetting
                :max-size="warehouseLogoSetting.maxFileSize ?? 1024"
                :conversion-unit="warehouseLogoSetting.fileSizeUnit ?? novaCore.FileUnits.MB"
                :allowed-mime-types="warehouseLogoSetting.allowedMimeTypes"
                class="flex-grow-0"
                v-model="warehouseSettings.customLogo"></document-upload-button>
            </div>
            <div class="v-text-field__details">
              <div class="v-messages theme--light">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">{{ warehouseLogoSetting.description }}</div>
                </div>
              </div>
            </div>

            <h4 class="mb-2 pt-6">
              Warehouse Email Subscribers
              <help-icon-tooltip class="z-100" icon-style-class="grey darken-2">
                Default email subscribers for appointments at this facility.
              </help-icon-tooltip>
            </h4>
            <v-row>
              <v-col>
                <email-list-field
                  class="full-width mb-4"
                  outlined
                  label="Email Subscribers"
                  :read-only="!$rolePermissions.canUpdateWarehouse || readOnly"
                  :disabled="readOnly"
                  v-model="editingWarehouse.ccEmails"
                  small-chips
                  small></email-list-field>
              </v-col>
            </v-row>

            <h4 class="mb-1 pt-8">
              Warehouse Internal Notes
              <help-icon-tooltip class="z-100" icon-style-class="grey darken-2">
                Notes that are internal to the Warehouse
              </help-icon-tooltip>
            </h4>
            <v-row>
              <v-col>
                <v-textarea
                  v-if="$rolePermissions.canUpdateWarehouse && !readOnly"
                  label="Internal Notes"
                  rows="2"
                  outlined
                  v-model="editingWarehouse.notes"></v-textarea>
                <div v-else class="v-input v-text-field">
                  <div class="v-text-field__slot full-width">
                    <label class="v-label font-size-x-small">Notes</label>
                    <div class="text--disabled border-width-1 bordered rounded pa-4 full-width">
                      {{ editingWarehouse.notes }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <h2 class="mb-2 mt-6">Instructions to Carriers</h2>

            <h4 class="mb-2 pt-6">
              Yard Map
              <help-icon-tooltip class="z-100" icon-style-class="grey darken-2">
                {{ warehouseYardMapSetting.label }}
              </help-icon-tooltip>
            </h4>
            <div class="d-flex align-center mt-2">
              <document-upload-button
                :read-only="readOnly"
                :crop-shape="warehouseYardMapSetting.documentOptions?.cropShape"
                :aspectRatio="warehouseYardMapSetting.documentOptions?.aspectRatio"
                :show-crop-result="warehouseYardMapSetting.documentOptions?.showCropResult"
                :modal-header-text="warehouseYardMapSetting.documentOptions?.modalHeaderText"
                allow-warehouseYardMapSetting
                :max-size="warehouseYardMapSetting.maxFileSize ?? 1024"
                :conversion-unit="warehouseYardMapSetting.fileSizeUnit ?? novaCore.FileUnits.MB"
                :allowed-mime-types="warehouseYardMapSetting.allowedMimeTypes"
                class="flex-grow-0"
                v-model="warehouseSettings.yardMap"></document-upload-button>
            </div>
            <div class="v-text-field__details">
              <div class="v-messages theme--light">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">{{ warehouseYardMapSetting.description }}</div>
                </div>
              </div>
            </div>

            <h4 class="mb-5 pt-8">
              Warehouse General Instructions
              <help-icon-tooltip class="z-100" icon-style-class="grey darken-2">
                To be displayed in the email notifications and the Carrier View Portal
              </help-icon-tooltip>
            </h4>
            <v-row>
              <v-col>
                <rich-text-input
                  v-if="$rolePermissions.canUpdateWarehouse && !readOnly"
                  label="Instructions"
                  @ready="handleRichTextReady"
                  v-model="editingWarehouse.instructions"></rich-text-input>
                <div v-else class="v-input v-text-field">
                  <div class="v-text-field__slot full-width">
                    <div
                      class="text--disabled border-width-1 bordered rounded pa-4"
                      v-html="editingWarehouse.instructions"></div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <warehouse-amenities-ppe-requirements
                  :fields="editingWarehouse"></warehouse-amenities-ppe-requirements>
              </v-col>
            </v-row>

            <action-group
              class="pr-0 pt-8"
              confirm-label="Save Warehouse Info"
              :hide-cancel="true"
              cancel-color="error"
              :confirm-button-tooltip="readOnly ? novaCore.getRoleActionError() : ''"
              :disable-confirm="!$rolePermissions.canUpdateWarehouse || readOnly"
              @confirm="submit"></action-group>
          </v-container>
        </v-form>
      </template>
    </form-base>
  </div>
</template>

<script>
import autocompleteAddressMixin from '@/components/mixins/autocompleteAddressMixin';
import editWarehouseMixin from '@/modules/warehouses/editWarehouseMixin';

/**
 * Edit Warehouse Form
 * @displayName Edit Warehouse Form
 */
export default {
  mixins: [autocompleteAddressMixin, editWarehouseMixin],
  computed: {
    warehouseBaseSettings() {
      return this.novaCore.makeGroupedSettings(this.novaCore.DatabaseEntities.Warehouse);
    },
    warehouseLogoSetting() {
      return this.warehouseBaseSettings['Scheduling Portal'].customLogo;
    },
    warehouseYardMapSetting() {
      return this.warehouseBaseSettings['Scheduling Portal'].yardMap;
    }
  },
  beforeMount() {
    this.warehouseSettings = { ...(this.warehouse?.settings || {}) };
  },
  mounted() {
    this.setEditingWarehouse();
  }
};
</script>

<style scoped>
.align-field {
  padding-top: 7px !important;
}

p {
  line-height: 0;
}
</style>
