var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hours-of-operation-form" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mt-4", attrs: { cols: "7" } },
            [
              _vm.closedIntervals.length
                ? _c(
                    "v-list",
                    _vm._l(_vm.closedIntervals, function (interval, index) {
                      return _c(
                        "v-list-item",
                        {
                          key: `closed-interval-${index}`,
                          staticClass:
                            "hours-of-operation-item exception-date closed",
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "div",
                                { staticClass: "date-picker-container" },
                                [
                                  _c("date-picker", {
                                    ref: `datepicker-${index}`,
                                    refInFor: true,
                                    attrs: {
                                      required: "",
                                      value: _vm.closedIntervals[index].start,
                                      clearable: false,
                                      "inner-icon": "",
                                      placeholder: "Closed Date",
                                      "compact-display": true,
                                    },
                                    on: {
                                      input: (value) =>
                                        _vm.updateClosedInterval(value, index),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "side-button",
                                  attrs: { icon: "", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteClosedInterval(index)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "mt-3 add-hours-button",
                  attrs: {
                    disabled: !_vm.$rolePermissions.canCreateDock,
                    text: "",
                    small: "",
                  },
                  on: { click: _vm.addClosedDate },
                },
                [
                  _c("v-icon", { staticClass: "mr-1 font-size-x-small" }, [
                    _vm._v("mdi-plus"),
                  ]),
                  _vm._v(" Add closed date "),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "5" } }, [
            _c(
              "div",
              { staticClass: "my-10" },
              [
                _c("holidays-selector", {
                  attrs: {
                    country: _vm.warehouse.country,
                    "added-dates":
                      _vm.closedIntervals?.map((i) => i.start) || [],
                  },
                  on: { input: _vm.addClosedDateFromHoliday },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }