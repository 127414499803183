import { ref } from 'vue';
import { useRenderKey } from '@/composables';

export const dialogBaseProps = {
  showDialog: {
    type: Boolean,
    required: false,
    default: false
  },
  externalActivator: {
    type: Boolean,
    required: false,
    default: false
  },
  cardClasses: {
    type: Array,
    default() {
      return [];
    }
  },
  disableActivator: {
    type: Boolean,
    required: false,
    default: false
  }
};

export default function useDialogBase({ onClose, onDismiss, onConfirm } = {}) {
  const showConfirmDialog = ref(false);
  const { renderKey, rerender } = useRenderKey();
  const resolve = ref(null);
  const reject = ref(null);

  const close = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    rerender();
  };

  const openConfirmation = () => {
    return new Promise((res, rej) => {
      resolve.value = res;
      reject.value = rej;
    });
  };

  const confirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
    resolve.value(true);
    close();
  };

  const nevermind = () => {
    if (typeof onDismiss === 'function') {
      onDismiss();
    }
    resolve.value(false);
    close();
  };

  return {
    showConfirmDialog,
    openConfirmation,
    renderKey,
    close,
    confirm,
    nevermind
  };
}
