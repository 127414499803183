<template>
  <phone-number-field
    single-line
    v-model="value"
    :validator="$validator"
    :label="field.lable"></phone-number-field>
</template>

<script>
import customFieldTypeMixinOld from '@/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  name: 'custom-field-phone-old',
  mixins: [customFieldTypeMixinOld]
};
</script>
