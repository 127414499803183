<template>
  <section class="is-relative full-height overflow-auto" id="appointments-page-container-mobile">
    <div
      id="appointments-list-header-mobile"
      class="d-flex justify-space-between px-2 py-4 align-center">
      <v-btn
        icon
        class="mobile-icon-btn"
        :disabled="isToday"
        @click="setToday"
        data-testid="today-button">
        <v-icon>mdi-calendar</v-icon>
      </v-btn>
      <div class="d-flex flex-1 date-nav align-center justify-center">
        <v-btn icon @click="dateNavPrev" data-testid="prev-day-button" class="mobile-icon-btn">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div class="d-flex align-center">
          <date-picker
            v-model="selectedDate"
            external-trigger
            :no-title="false"
            date-picker-wrapper-class="centered"
            color="#015A86">
            <template #external-trigger="{ openDatePicker }">
              <v-btn
                plain
                class="caption"
                @click="openDatePicker"
                :current-date="$selectedDate"
                data-testid="date-picker-trigger"
                >{{ formattedHeaderDate }}</v-btn
              >
            </template>
          </date-picker>
        </div>
        <v-btn icon @click="dateNavNext" data-testid="next-day-button" class="mobile-icon-btn">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <v-btn
        icon
        data-testid="appointment-search-mobile"
        @click="showSearchDialog = true"
        class="mobile-icon-btn">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </div>
    <appointments-list-mobile></appointments-list-mobile>

    <appointment-search-dialog-mobile
      fullscreen
      :show-dialog="showSearchDialog"
      content-class="mobile-dialog"
      @close="showSearchDialog = false"
      external-activator></appointment-search-dialog-mobile>
  </section>
</template>

<script>
import appointmentsPageMixin from '@/components/mixins/appointmentsPageMixin';
import AppointmentSearchDialogMobile from '@/modules/appointments/mobile/components/AppointmentSearchDialogMobile.vue';
export default {
  components: { AppointmentSearchDialogMobile },
  mixins: [appointmentsPageMixin],
  data() {
    return {
      selectedDate: momentjs().format(this.novaCore.DateTimeFormats.DateDashed),
      showSearchDialog: false
    };
  },
  computed: {
    formattedHeaderDate() {
      return momentjs(this.$selectedDate).format(this.novaCore.DateTimeFormats.ShortDate);
    },
    isToday() {
      const today = momentjs.tz(this.$selectedWarehouse?.timezone);
      const selectedDate = momentjs.tz(this.$selectedDate, this.$selectedWarehouse?.timezone);
      return today.isSame(selectedDate, 'day');
    }
  },
  methods: {
    dateNav(action) {
      return (
        momentjs(this.$selectedDate)
          // eslint-disable-next-line no-unexpected-multiline
          [action](1, 'day')
          .format(this.novaCore.DateTimeFormats.DateDashed)
      );
    },
    dateNavPrev() {
      this.$store.commit('Calendar/setSelectedDate', this.dateNav('subtract'));
    },
    dateNavNext() {
      this.$store.commit('Calendar/setSelectedDate', this.dateNav('add'));
    },
    setToday() {
      this.$store.dispatch('Calendar/setSelectedDate', {
        value: momentjs.tz(this.$selectedWarehouse?.timezone).format('YYYY-MM-DD'),
        getEvents: true
      });
    }
  },
  watch: {
    selectedDate(newDate) {
      this.$store.commit('Calendar/setSelectedDate', newDate);
    }
  }
};
</script>

<style lang="scss" scoped></style>
