var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          loading: false,
          header: "Cancel Appointment?",
          "loading-message": "Cancelling appointment...",
          "max-width": "100%",
          "has-dialog-actions": "",
          value: _vm.showDialog,
          width: "500px",
        },
        on: { close: _vm.close, confirm: _vm.confirm },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                {},
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v(" Cancel Appointment ")]
                          ),
                    ]
                  },
                }
              : null,
            {
              key: "customHeader",
              fn: function () {
                return [
                  _c(
                    "header",
                    { staticClass: "full-width" },
                    [
                      _c("dialog-header", {
                        attrs: { header: "Cancel Appointment?" },
                        on: { close: _vm.close },
                        scopedSlots: _vm._u([
                          {
                            key: "close-icon",
                            fn: function () {
                              return [_c("v-icon", [_vm._v("mdi-close")])]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "px-6 pt-4 d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-1",
                          attrs: { small: "", color: "error" },
                        },
                        [_vm._v("mdi-alert-circle-outline")]
                      ),
                      _c("span", [_vm._v("You cannot undo this action.")]),
                    ],
                    1
                  ),
                  _c("cancel-appointment-form", {
                    ref: "form",
                    attrs: {
                      "mixpanel-entry-point": _vm.mixpanelEntryPoint,
                      appointment: _vm.appointment,
                    },
                    on: { nevermind: _vm.nevermind, confirm: _vm.confirm },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }