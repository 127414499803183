<template>
  <v-text-field
    dense
    :height="height"
    :class="{ required: required }"
    :value="value"
    @input="value => $emit('input', value)"
    v-bind="[$props, $attrs]"
    v-on="$listeners"
    :placeholder="placeholder"
    @wheel="handleWheelEvent"
    validate-on-blur>
    <template v-slot:label v-if="tooltipText">
      <div class="with-tooltip">
        {{ label }}
        <help-icon-tooltip iconStyleClass="grey darken-2">{{ tooltipText }}</help-icon-tooltip>
      </div>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    /**
     * @model
     */
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: String,
      required: false,
      default: '20px'
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    handleWheelEvent(e) {
      if (this.$attrs.type === 'number') {
        e.currentTarget.blur();
      }
    }
  }
};
</script>
