<template>
  <form-base>
    <template #form>
      <v-form ref="form" v-on:submit.prevent @keyup.native.enter="submit">
        <v-container>
          <text-field type="text" v-model="localOrgName" label="Name"></text-field>
        </v-container>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="cancel"
        confirm-icon="update"
        confirm-label="Update Org"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * Edit Org Form
 * @displayName Edit Org Form
 */
export default {
  data() {
    return {
      localOrgName: ''
    };
  },
  methods: {
    /**
     * Submit form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      const org = await this.$store.dispatch('Orgs/updateOrg', { name: this.localOrgName });

      if (org) {
        this.$emit('saved', org);
        this.cancel();
      }
    },
    cancel() {
      this.setLocalOrgName();

      /**
       * Emits close event
       * @event close
       */
      this.$emit('close');
    },
    setLocalOrgName() {
      this.localOrgName = this.$org.name;
    }
  },
  async mounted() {
    await this.$store.dispatch('Orgs/getOrg', this.$me.orgId);
    this.setLocalOrgName();
  }
};
</script>
