var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "h3",
        { staticClass: "mb-3" },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
            _vm._v("mdi-truck-outline"),
          ]),
          _vm._v(
            " " +
              _vm._s(
                _vm.assetVisit.company?.name ||
                  _vm.assetVisit.companyHint ||
                  "Unknown Company"
              ) +
              " "
          ),
        ],
        1
      ),
      _c("p", { staticClass: "d-flex flex-column" }, [
        _c("strong", { staticClass: "font-size-x-small" }, [
          _vm._v("Arrived at"),
        ]),
        _c("span", [_vm._v(" " + _vm._s(_vm.arrivedTimeFormatted) + " ")]),
      ]),
      _vm.showAssignSpotSelect
        ? _c(
            "div",
            { staticClass: "d-flex flex-column font-size-x-small mb-4" },
            [
              _c("strong", { staticClass: "mb-2" }, [_vm._v("Assign To")]),
              _c("yard-view-spot-select", {
                on: {
                  input: function ($event) {
                    return _vm.$emit("assign", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("v-divider", { staticClass: "my-4" }),
      _c("asset-visit-form-data", {
        attrs: {
          "asset-visit": _vm.assetVisit,
          "is-list": "",
          "is-inline": false,
          "show-company-name": false,
        },
      }),
      _c("v-divider", { staticClass: "mb-4 my-2" }),
      _vm.appointment
        ? _c("div", { staticClass: "font-size-x-small" }, [
            _vm.appointment?.refNumber
              ? _c("p", { staticClass: "d-flex flex-column" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$refNumSettings(_vm.selectedWarehouse).displayName
                      )
                    ),
                  ]),
                  _vm._v(" " + _vm._s(_vm.appointment.refNumber) + " "),
                ])
              : _vm._e(),
            _c("p", { staticClass: "d-flex flex-column" }, [
              _c("strong", [_vm._v("Scheduled for")]),
              _c("span", [_vm._v(_vm._s(_vm.scheduledFor.date))]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.scheduledFor.start) +
                    " - " +
                    _vm._s(_vm.scheduledFor.end)
                ),
              ]),
            ]),
            _c(
              "p",
              [
                _c("strong", [_vm._v("Appointment Status")]),
                _c("br"),
                _c(
                  "v-chip",
                  {
                    attrs: {
                      color: _vm.appointment.status.toLowerCase(),
                      dark: "",
                      small: "",
                      pill: "",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.appointment.status.toUpperCase()) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("p", { staticClass: "d-flex flex-column" }, [
              _c("strong", [_vm._v("Load Type")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.appointment.loadType.name) +
                    " (" +
                    _vm._s(_vm.appointment.loadType.direction) +
                    ")"
                ),
              ]),
            ]),
          ])
        : _c("div", [_vm._v("No appointment found")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }