var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        staticClass: "mx-auto",
        attrs: {
          header: "New Carrier Company",
          scrollable: false,
          "has-dialog-actions": "",
          "max-width": "560px",
          width: "50%",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.createCompanyForm.submit()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c("v-tooltip", {
                        attrs: {
                          top: "",
                          disabled: _vm.$rolePermissions.canCreateCarrier,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g(_vm._b({}, "div", attrs, false), on),
                                    [
                                      _vm.$scopedSlots.activator
                                        ? _vm._t(
                                            "activator",
                                            null,
                                            null,
                                            slotProps
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("create-company-form", {
                    ref: "createCompanyForm",
                    attrs: { "entry-point": _vm.entryPoint },
                    on: { close: _vm.close, save: _vm.handleCompanySave },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }