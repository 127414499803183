<template>
  <form-base :hide-required-legend="true">
    <template #form>
      <v-form class="pt-6 pb-12 mx-3 edit-appointment-form" ref="form">
        <template v-if="loading">
          <v-progress-linear
            indeterminate
            :loading="loading"
            height="6"
            class="mt-6"></v-progress-linear>
          <h4 class="text-center mt-4 mb-12">Updating appointment ...</h4>
        </template>
        <template v-else>
          <template>
            <load-type-select
              class="mb-6"
              :is-inline="false"
              v-model="appointment.loadTypeId"
              :return-object="false"
              label="Load Type"
              hideIcon
              tooltipText="Changing the Load Type of this appointment will not change this appointment’s duration."
              :visible-selection-count="5"
              :required="true"
              :rules="$validator.rules.required('Load Type')"
              :multi-select="false"
              :showOnlyAssignedLoadTypes="true"
              :selected-docks="warehouse.docks"
              :selected-warehouse="warehouse"></load-type-select>
          </template>
          <div v-if="refNumberSettings.isVisible" class="mb-6">
            <h3 class="mr-4">
              {{ refNumberSettings.displayName }}
              <help-icon-tooltip v-if="refNumberSettings.helperText">
                {{ refNumberSettings.helperText }}
              </help-icon-tooltip>
              :
            </h3>
            <auto-expandable-textarea
              dense
              hide-details="auto"
              v-model="refNumber"
              single-line
              :label="refNumberPlaceholder"
              :placeholder="refNumberPlaceholder"
              validate-on-blur
              :rules="$validator.rules.required(refNumberSettings.displayName)"
              required
              v-if="refNumberSettings.isRequired"></auto-expandable-textarea>
            <auto-expandable-textarea
              dense
              hide-details="auto"
              v-else
              single-line
              v-model="refNumber"
              :label="refNumberPlaceholder"
              :placeholder="refNumberPlaceholder"
              validate-on-blur></auto-expandable-textarea>
          </div>
          <div class="px-4">
            <custom-fields
              single-line
              :container-classes="['my-0']"
              :field-classes="['mb-6']"
              :is-inline="false"
              v-model="customFields"
              :appointment="appointment"
              :warehouse="appointment.dock.warehouse"
              @toggle-confirm-button="toggleConfirmButton"></custom-fields>
          </div>
          <div class="mb-4">
            <h3 class="mr-4 mb-2">Appointment Notes:</h3>
            <rich-text-input
              class="flex-fill"
              :editor-config="{
                plugins: ['Essentials', 'Paragraph'],
                toolbar: { items: [] }
              }"
              v-model="notes"></rich-text-input>
          </div>
          <template>
            <carrier-select-mobile
              single-line
              @click.native="clearCarrierField"
              v-on-clickaway="refillCarrierField"
              required
              :warehouse="warehouse"
              v-model="selectedCarrier"
              label="Carrier Contact"
              :rules="$validator.rules.required('Carrier Contact')"></carrier-select-mobile>
          </template>
        </template>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @confirm="updateAppointment"
        :cancel-icon="null"
        stack-actions
        confirm-label="Save changes"
        @cancel="$emit('close')"></action-group>
    </template>
  </form-base>
</template>

<script>
import EditAppointmentFormBase from '@/modules/appointments/components/edit/EditAppointmentFormBase.vue';

export default {
  extends: EditAppointmentFormBase
};
</script>
