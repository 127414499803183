<template>
  <v-sheet class="custom-calendar-container custom-week-calendar-container fill-height">
    <v-calendar
      class="calendar"
      type="month"
      ref="calendar"
      :weekdays="weekdaysToShow"
      :class="!$selectedWarehouse.id ? 'disabled' : 'clickable'"
      v-model="selectedDate"
      :events="events"
      color="primary"
      event-overlap-mode="stack"
      :event-color="getEventClasses"
      @click:more="viewDay"
      @click:day="viewDay"
      @click:date="viewDay">
      <template v-slot:day-label-header="dateTime">
        <div :class="dateTime.present ? 'primary--text' : ''" class="d-flex flex-column">
          <span class="text-uppercase weekday-label">{{ weekdays[dateTime.weekday] }}</span>
          <span>{{ dateTime.day }}</span>
        </div>
      </template>

      <template v-slot:day-body="{ date, week }">
        <div
          class="v-current-time"
          :class="{ first: date === week[0].date }"
          :style="{ top: nowY }"></div>
      </template>

      <template v-slot:event="{ event }">
        <div :key="`${renderKey}-${event.id}`">
          <event-with-tooltip
            :event="event"
            :is-light-theme="$useLightGridTheme"
            :enableMilitaryTime="$isMilitaryTimeEnabled($selectedWarehouse)"
            :use-new-grid-tiles="useNewGridTilesSetting"
            @click="handleEventClick"
            @cancel-reserve="cancelReserve"
            :disable-tooltip="isTooltipDisabled(event)"></event-with-tooltip>
        </div>
      </template>
    </v-calendar>

    <calendar-dialogs
      :appointment-context="appointmentContext"
      :show-create-dialog="showCreateDialog"
      :show-details-dialog="showDetailsDialog"
      :show-reserve-dialog="showReserveDialog"
      @close="handleDialogClose"></calendar-dialogs>
  </v-sheet>
</template>

<script>
import calendarMixin from '@/modules/calendar/mixins/calendarMixin';

/**
 * Calendar Month View
 * @displayName Calendar Month View
 */
export default {
  mixins: [calendarMixin],
  mounted() {
    this.$eventHub.$on('update-Trigger', this.handleSocketDockUpdate);
    this.$eventHub.$on('create-Trigger', this.handleSocketWarehouseUpdate);

    this.util.addCursorTextListener(
      this.$refs.calendar.$el,
      'No Warehouse/Docks selected',
      'calendarCursorText'
    );
  },
  beforeDestroy() {
    this.$eventHub.$off('update-Trigger');
    this.$eventHub.$off('create-Trigger');

    this.util.removeCursorTextListener(this.$refs.calendar.$el, 'calendarCursorText');
  }
};
</script>
