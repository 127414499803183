var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-8" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _vm.embedConfig
        ? _c("PowerBIReportEmbed", {
            attrs: {
              embedConfig: _vm.embedConfig,
              phasedEmbedding: false,
              cssClassName: "report-container",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }