<template>
  <div>
    <warehouse-list></warehouse-list>
  </div>
</template>

<script>
/**
 * Create Warehouse Page
 * @displayName Create Warehouse Page
 */
export default {};
</script>
