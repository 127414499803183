var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          loading: _vm.loading,
          header: "Reserve Details",
          "loading-message": "",
          "max-width": "100%",
          value: _vm.showDialog,
          width: "900px",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            {
              key: "header-actions",
              fn: function () {
                return [
                  _c("appointment-action-group", {
                    attrs: {
                      "parent-appointment": _vm.parentAppointment,
                      appointment: _vm.appointment,
                      "appointment-actions": _vm.appointmentActions,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b({}, "primary-button", slotProps.attrs, false),
                          slotProps.on
                        ),
                        [_vm._v("View Reserve")]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("v-divider", { staticClass: "mb-8" }),
                  _c("reserve-details", {
                    attrs: {
                      "parent-appointment": _vm.parentAppointment,
                      appointment: _vm.appointment,
                      "should-edit-status": true,
                      "should-display-cancel-button": false,
                    },
                    on: { close: _vm.close },
                  }),
                ]
              },
              proxy: true,
            },
            (_vm.canCancel || _vm.isCancelled) &&
            !_vm.appointment.isRecurringAppointment
              ? {
                  key: "dialog-actions",
                  fn: function () {
                    return [
                      !_vm.isCancelled &&
                      _vm.$rolePermissions.canUpdateAppointment
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "text__error font-weight-bold",
                              attrs: {
                                elevation: "0",
                                color: "white",
                                disabled:
                                  _vm.appointment.status ===
                                  _vm.novaCore.AppointmentStatus.Cancelled,
                              },
                              on: { click: _vm.deleteReserve },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.cancelButtonIcon)),
                              ]),
                              _vm._v(" Delete Reserve "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }