var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-4" },
    [
      _vm.$isFetchingEvents && _vm.isFirstPage
        ? [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column align-center justify-center pt-8",
              },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "primary" },
                }),
                _c("span", [_vm._v("Loading Appointments...")]),
              ],
              1
            ),
          ]
        : [
            _vm.eventsByDate.length === 0
              ? [
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-8 flex-column align-center",
                      attrs: {
                        "no-gutters": "",
                        "data-testid": "no-appointments-message-container",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2 primary--text mb-3",
                          attrs: { "x-large": "" },
                        },
                        [_vm._v("mdi-arrow-up")]
                      ),
                      _c("strong", { staticClass: "no-results-message" }, [
                        _vm._v("No appointments for this date"),
                      ]),
                      _c("span", { staticClass: "px-4 text-center pt-3" }, [
                        _vm._v(
                          " You can select a different date or search for an appointment at the top toolbar "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              : _vm._l(_vm.eventsByDate, function (event) {
                  return _c(
                    "v-row",
                    {
                      key: `event-list-${event.id}`,
                      staticClass: "mb-4",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c("v-col", [
                        _c(
                          "article",
                          {
                            staticClass: "mobile-event-tile",
                            class: [`is-${event.status.toLowerCase()}`],
                            on: {
                              click: function ($event) {
                                return _vm.openDetailsDialog(event)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center justify-space-between",
                              },
                              [
                                _c("div", { staticClass: "tile-header" }, [
                                  _c(
                                    "strong",
                                    {
                                      staticClass: "mr-4",
                                      attrs: { "data-timestamp": event.start },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getEventStart(event)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("span", { staticClass: "company-name" }, [
                                    _vm._v(_vm._s(event.companyName)),
                                  ]),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-size-x-small rounded pa-1 white--text font-weight-bold current-status",
                                    class: [event.status.toLowerCase()],
                                  },
                                  [_vm._v(" " + _vm._s(event.status) + " ")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center mt-2 font-size-x-small",
                              },
                              [
                                _c("div", { staticClass: "third-width" }, [
                                  _c("span", { staticClass: "d-block" }, [
                                    _vm._v("Confirmation"),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block appointment-confirmation-number",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(event.confirmationNumber) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "third-width" }, [
                                  _c("span", { staticClass: "d-block" }, [
                                    _vm._v("Reference"),
                                  ]),
                                  _c("span", {
                                    staticClass: "d-block",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getRowVal(event.refNumber)
                                      ),
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "third-width" }, [
                                  _c("span", { staticClass: "d-block" }, [
                                    _vm._v("Dock"),
                                  ]),
                                  _c("span", { staticClass: "d-block" }, [
                                    _vm._v(_vm._s(event.dock.name)),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                }),
            _vm.shouldShowLoadMoreButton
              ? _c(
                  "outline-button",
                  {
                    attrs: {
                      "data-testid": "load-more-button",
                      block: "",
                      loading: _vm.$isFetchingEvents,
                    },
                    on: { click: _vm.loadMoreAppointments },
                  },
                  [_vm._v(" Load More Results ")]
                )
              : [
                  _vm.eventsByDate.length > 0
                    ? _c(
                        "div",
                        { staticClass: "d-flex align-start" },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("mdi-list-status"),
                          ]),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", [
                              _vm._v("No more appointments for this date."),
                            ]),
                            _c("span", [
                              _vm._v(
                                "You can try choosing another date at the top toolbar."
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
          ],
      _c(
        "v-btn",
        {
          ref: "scrolltotop",
          staticClass: "back-to-top-btn mobile-icon-btn",
          attrs: { icon: "", elevation: "5" },
          on: { click: _vm.scrollToTop },
        },
        [_c("v-icon", [_vm._v("mdi-chevron-double-up")])],
        1
      ),
      _c("appointment-details-dialog-mobile", {
        attrs: {
          "content-class": "mobile-dialog details-dialog-mobile",
          fullscreen: "",
          transition: "dialog-bottom-transition",
          "external-activator": "",
          "show-dialog": _vm.showDetailsDialog,
          appointment: _vm.$selectedEvent,
        },
        on: {
          close: function ($event) {
            _vm.showDetailsDialog = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }