<template>
  <v-menu
    v-model="menu"
    top
    nudge-bottom="105"
    nudge-left="16"
    :close-on-click="false"
    :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <div :style="activatorStyle" v-on="on" class="color-picker-activator" />
    </template>
    <v-card>
      <v-card-subtitle
        v-if="headerText"
        :style="`color:${textColor};background-color:${headerBackground || value}`">
        {{ headerText }}
        <v-btn absolute top right x-small icon @click="close(false)" v-if="shouldShowCloseButton">
          <v-icon :color="textColor">mdi-close</v-icon>
        </v-btn>
      </v-card-subtitle>
      <v-card-text class="pa-0">
        <v-color-picker
          :value="value"
          @input="value => $emit('input', value)"
          flat
          show-swatches
          hide-inputs
          hide-mode-switch
          :swatches="swatches" />
      </v-card-text>
      <v-card-actions class="pt-0" v-if="shouldShowFooter">
        <v-btn text x-small @click="close(false)"> Nevermind </v-btn>
        <v-spacer></v-spacer>
        <v-btn text x-small @click="close(true)"> Select </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'colorPicker',
  props: {
    value: {
      type: String,
      required: false,
      default: '#000000'
    },
    swatches: {
      type: Array,
      required: false,
      default: () => []
    },
    iconStyles: {
      type: Object,
      required: false,
      default: () => {}
    },
    headerText: {
      type: String,
      required: false
    },
    headerBackground: {
      type: String,
      required: false
    },
    shouldShowCloseButton: {
      type: Boolean,
      required: false,
      default: true
    },
    shouldShowFooter: {
      type: Boolean,
      required: false,
      default: true
    },
    textColor: {
      type: String,
      required: false,
      default: '#000000'
    }
  },
  computed: {
    activatorStyle() {
      return {
        ...{
          backgroundColor: this.value,
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: this.menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out'
        },
        ...this.iconStyles
      };
    }
  },
  data() {
    return {
      menu: false,
      initialValue: null
    };
  },
  methods: {
    close(save) {
      if (!save) {
        this.$emit('input', this.initialValue);
      }
      this.menu = false;
    }
  },
  watch: {
    menu() {
      if (this.menu) {
        this.initialValue = this.value;
      }
    }
  }
};
</script>
