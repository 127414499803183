<template>
  <form-base hide-required-legend>
    <template #form>
      <template v-if="loading">
        <v-progress-linear
          indeterminate
          :loading="loading"
          height="6"
          class="mt-6"></v-progress-linear>
        <h4 class="text-center mt-4 mb-12">Updating reserve ...</h4>
      </template>
      <template v-else>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="notes"
                  :rules="$validator.rules.required('Title')"
                  validate-on-blur
                  single-line
                  placeholder="Reserve title..."></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <date-picker
                  v-model="date"
                  required
                  height="26px"
                  :display-field-icon="false"
                  :display-field-label="false"
                  :no-margins="true"
                  :clearable="false"></date-picker>
              </v-col>
              <v-col>
                <time-range-selector
                  v-model="timeRange"
                  :index="0"
                  :date-range-select-items="dateRangeSelectItems"
                  :from-items="fromItems"
                  :to-items="toItems"
                  :from-rules="$validator.rules.required('From')"
                  :to-rules="$validator.rules.required('To')"
                  :hide-details="false"
                  :interval-length="intervalLength"></time-range-selector>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
    </template>
    <template #form-actions v-if="!loading">
      <action-group
        @cancel="$emit('close')"
        confirm-label="Save Changes"
        cancel-label="Nevermind"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * Form that changes an existing reserve
 * @displayName Edit Reserve Form
 */
export default {
  props: {
    reserve: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      notes: '',
      date: '',
      intervalLength: 15,
      timeRange: {
        start: '',
        end: ''
      }
    };
  },
  methods: {
    fillLocal() {
      this.timeRange = {}; // Needed to reset the range selector!
      this.notes = this.reserve.notes;
      this.date = momentjs(this.reserve.start).format(this.novaCore.DateTimeFormats.DateDashed);
      this.timeRange.start = this.novaCore
        .clockToMoment(this.reserve.start.slice(-5))
        .format(this.novaCore.DateTimeFormats.Extended24HrTime);
      this.timeRange.end = this.novaCore
        .clockToMoment(this.reserve.end.slice(-5))
        .format(this.novaCore.DateTimeFormats.Extended24HrTime);
    },
    toWarehouseTime(date, time) {
      const timezone = this.$selectedWarehouse.timezone;
      return momentjs.tz(`${date}T${time}`, this.novaCore.DateTimeFormats.DateDashedTime, timezone);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await axios
          .patch(`appointment/${this.reserve.id}`, {
            notes: this.notes,
            start: this.toWarehouseTime(this.date, this.timeRange.start).utc().format(),
            end: this.toWarehouseTime(this.date, this.timeRange.end).utc().format()
          })
          .then(response => {
            if (response?.data?.data) {
              this.$store.commit('Calendar/removeEvent', this.reserve.id);
              this.reserve.notes = this.notes;
              this.$eventHub.$emit('appointment-updated');
              this.$store.dispatch('Appointments/triggerSocketEvent', {
                response,
                appointment: this.reserve
              });
              this.$emit('close');
            }
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  computed: {
    dateRangeSelectItems() {
      return this.novaCore.makeFullDayClockOptions(this.intervalLength);
    },
    fromItems() {
      if (!this.timeRange.end) {
        return this.dateRangeSelectItems;
      }

      return this.dateRangeSelectItems.filter(
        item => this.novaCore.clockAsInt(item.value) < this.novaCore.clockAsInt(this.timeRange.end)
      );
    },
    toItems() {
      if (!this.timeRange.start) {
        return this.dateRangeSelectItems;
      }

      return this.dateRangeSelectItems.filter(
        item =>
          this.novaCore.clockAsInt(item.value) > this.novaCore.clockAsInt(this.timeRange.start)
      );
    }
  },
  beforeMount() {
    this.fillLocal();
  },
  watch: {
    reserve(oldValue, newValue) {
      if (newValue?.id) {
        this.fillLocal();
      }
    }
  }
};
</script>
