var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    {
      attrs: {
        absolute: "",
        value: _vm.isVisible,
        color: _vm.color,
        opacity: _vm.opacity,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "overlay-contents",
          attrs: { "data-testid": "appts-missing-selection-overlay" },
        },
        [
          _c("svg-warehouse"),
          _c("h3", { staticClass: "align-center" }, [
            _vm._v("No " + _vm._s(_vm.missingSelectionTitle) + " selected"),
          ]),
          _c("p", [
            _vm._v(
              " Please select a warehouse along with one or more " +
                _vm._s(_vm.missingSelectionTitle) +
                " to continue "
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }