var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    staticClass: "reschedule-form",
    attrs: {
      header: _vm.header,
      "hide-required-legend": true,
      "use-global-loader": false,
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "full-width" },
                  [
                    _c("v-progress-linear", {
                      staticClass: "progress-bar",
                      attrs: {
                        indeterminate: "",
                        loading: _vm.loading,
                        height: "6",
                      },
                    }),
                    _c("h4", { staticClass: "text-center mt-4" }, [
                      _vm._v("Rescheduling Appointment..."),
                    ]),
                  ],
                  1
                )
              : _c(
                  "v-form",
                  { ref: "form" },
                  [
                    _vm.appointmentWarehouse?.id
                      ? _c("date-time-picker", {
                          attrs: {
                            appointment: _vm.appointment,
                            "selected-docks": Array.isArray(_vm.selectedDock)
                              ? _vm.selectedDock
                              : [_vm.selectedDock],
                            "selected-load-type": _vm.selectedLoadType,
                            "start-date": _vm.startDate,
                            "existing-start": _vm.appointment.start,
                            "existing-end": _vm.appointment.end,
                            "selected-warehouse": _vm.appointmentWarehouse,
                            timezone: _vm.appointment.dock.warehouse.timezone,
                            "is-reschedule": "",
                            "allow-dock-select": _vm.allowDockSelect,
                          },
                          on: {
                            "update-selected-dock": _vm.updateSelectedDock,
                            "update-selected-loadtype":
                              _vm.updateSelectedLoadType,
                            "availability-error": _vm.handleAvailabilityError,
                          },
                          model: {
                            value: _vm.selectedTime,
                            callback: function ($$v) {
                              _vm.selectedTime = $$v
                            },
                            expression: "selectedTime",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "hide-cancel": _vm.loading,
                "hide-confirm": _vm.loading,
                "disable-confirm": !_vm.selectedTime,
                "confirm-label": "Save",
              },
              on: { cancel: _vm.cancel, confirm: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }