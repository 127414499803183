<template>
  <date-picker
    v-model="value"
    dense
    class="ml-0"
    height="20px"
    :required="field.required"
    no-title
    compact-display
    :display-field-label="true"
    :display-field-icon="false"></date-picker>
</template>

<script>
import customFieldTypeMixinOld from '@/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  name: 'CustomFieldDateOld',
  mixins: [customFieldTypeMixinOld]
};
</script>
