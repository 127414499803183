<template>
  <div class="calendar-loader__wrapper overlay-base" v-if="isLoading">
    <div class="calendar-loader__overlay overlay-base"></div>
    <div class="calendar-loader__content">
      <v-progress-linear indeterminate></v-progress-linear>
      <div class="text-center mt-3">{{ customMessage ?? 'Loading Appointments ...' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppointmentsLoader',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    customMessage: {
      type: String,
      default: null
    }
  }
};
</script>
