<template>
  <load-type-list
    :read-only="$isLoadTypeReadOnly(warehouse.id, warehouse.orgId)"
    :warehouse="warehouse"
    :should-display-warehouses="false"></load-type-list>
</template>

<script>
export default {
  props: {
    warehouse: {
      type: Object,
      required: true
    }
  }
};
</script>
