var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Create Warehouse Schedule",
          scrollable: false,
          width: "1000",
          "max-width": "90%",
          "loading-message": "Updating User...",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.warehouseOpHoursTab.handlePostUpdate()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                { staticClass: "mr-2", attrs: { large: "" } },
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v("Create Schedule")]
                          ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("warehouse-operating-hours-tab", {
            ref: "warehouseOpHoursTab",
            attrs: {
              warehouse: _vm.warehouse,
              "should-start-in-edit-mode": true,
              "post-submission-route-name": _vm.postSubmissionRouteName,
            },
            on: { close: _vm.close },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }