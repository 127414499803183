<template>
  <v-dialog
    :value="value"
    width="600"
    persistent
    @click:outside="closeDialog"
    data-testid="yard-spot-form-generator-dialog">
    <v-card v-if="value">
      <v-card-title>
        Add Spots
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="activeTab" grow>
          <v-tab :tab-value="tabs.SINGLE" data-testid="yard-spot-form-single-tab">Single</v-tab>
          <v-tab :tab-value="tabs.MULTIPLE" data-testid="yard-spot-form-multiple-tab">
            Multiple
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item :value="tabs.SINGLE">
            <yard-view-spot-generator-single-form ref="singleForm" v-model="singleSpot" />
          </v-tab-item>
          <v-tab-item :value="tabs.MULTIPLE">
            <yard-view-spot-generator-multiple-form ref="multipleForm" v-model="multipleSpot" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions class="ma-3">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeDialog" data-testid="yard-spot-form-cancel-button">
          Nevermind
        </v-btn>
        <v-btn
          data-testid="yard-spot-form-submit-button"
          :disabled="!isFormValid"
          color="primary"
          @click="submit">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useEventHub } from '@/composables';
import useYard from '@/modules/yard/composables/useYard';
import YardViewSpotGeneratorSingleForm from '@/modules/yard/components/Spot/Generator/YardViewSpotGeneratorSingleForm.vue';
import YardViewSpotGeneratorMultipleForm from '@/modules/yard/components/Spot/Generator/YardViewSpotGeneratorMultipleForm.vue';

export default defineComponent({
  name: 'YardViewSpotGeneratorDialog',
  components: { YardViewSpotGeneratorMultipleForm, YardViewSpotGeneratorSingleForm },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const tabs = Object.freeze({
      SINGLE: 'single',
      MULTIPLE: 'multiple'
    });
    const activeTab = ref(tabs.SINGLE);
    const isFormValid = ref(false);

    const yardStore = useYard();
    const eventHub = useEventHub();

    const baseSpot = {
      code: '',
      type: ''
    };
    const baseMultipleSpot = {
      numberOfSpots: 1,
      startFromNumber: 1,
      leadingChar: '',
      type: ''
    };

    const singleSpot = ref({ ...baseSpot });
    const multipleSpot = ref({ ...baseMultipleSpot });

    const singleForm = ref(null);
    const multipleForm = ref(null);

    const closeDialog = () => {
      singleSpot.value = {
        ...baseSpot
      };
      multipleSpot.value = {
        ...baseMultipleSpot
      };
      eventHub.$emit('close-spot-generator-dialog');
    };

    const validateForm = () => {
      const form = activeTab.value === tabs.SINGLE ? singleForm : multipleForm;
      isFormValid.value = form?.value?.validate();
    };

    const submit = () => {
      if (!isFormValid.value) {
        return;
      }
      if (activeTab.value === tabs.SINGLE) {
        yardStore
          .createSpot({
            code: singleSpot.value.code,
            type: singleSpot.value.type,
            spotAreaId: yardStore.defaultSpotAreaIdRef.value
          })
          .then(() => closeDialog());
      } else {
        yardStore
          .createManySpot({
            numberOfSpots: Number(multipleSpot.value.numberOfSpots),
            startFromNumber: Number(multipleSpot.value.startFromNumber),
            leadingChar: multipleSpot.value.leadingChar,
            type: multipleSpot.value.type,
            spotAreaId: yardStore.defaultSpotAreaIdRef.value
          })
          .then(() => closeDialog());
      }
    };

    watch([singleSpot, multipleSpot, activeTab], validateForm, { deep: true });

    return {
      activeTab,
      closeDialog,
      singleSpot,
      multipleSpot,
      submit,
      tabs,
      spotType: yardStore.spotType,
      isFormValid,
      singleForm,
      multipleForm
    };
  }
});
</script>
