<template>
  <form-base :hide-required-legend="true">
    <template slot="form">
      <v-form class="pt-6" ref="form">
        <div class="time-input">
          <div class="mx-4 my-3">
            <date-picker
              v-model="customDate"
              compact-display
              :display-field-label="false"
              :display-field-icon="false"
              required></date-picker>
          </div>
          <time-entry :warehouse="appointment.dock.warehouse" v-model="customTime"></time-entry>
          <div class="d-flex">
            <div class="menu-container full-width">
              <v-btn-toggle @change="adjustTime" class="full-width" color="primary">
                <v-btn
                  dense
                  small
                  outlined
                  v-for="(option, index) in timeAdjustmentOptions"
                  class="flex-grow-1"
                  :key="`${index}-timeline-option`">
                  {{ option.label }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </div>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        confirm-label="Save"
        @cancel="$emit('close')"
        @confirm="updateAppointment"></action-group>
    </template>
  </form-base>
</template>

<script>
import StatusTimelineEditFormBase from '@/modules/appointments/components/StatusTimelineEditFormBase.vue';

export default {
  extends: StatusTimelineEditFormBase
};
</script>
