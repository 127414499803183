<template>
  <div v-if="selectedSpot?.id">
    <v-card elevation="0" flat>
      <v-card-title class="d-flex flex-row card-header">
        <h3 class="text-uppercase" data-testid="yard-spot-detail-title">
          {{ selectedSpot.code }}
        </h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDetail" data-testid="yard-spot-detail-close-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text data-testid="yard-spot-detail-content" class="card-content">
        <h2 class="text-capitalize" v-if="!isSpotAssigned(selectedSpot)">
          {{ getSpotStatus(selectedSpot) }}
        </h2>

        <div>
          <yard-view-spot-detail-open v-if="isSpotOpen(selectedSpot)" />
          <yard-view-spot-detail-closed v-if="isSpotClosed(selectedSpot)" />
          <yard-view-spot-detail-assigned
            :spot-assignment="spotAssignment"
            v-if="isSpotAssigned(selectedSpot)" />
        </div>
      </v-card-text>

      <v-card-actions class="card-actions" data-testid="yard-spot-detail-actions">
        <div class="d-flex flex-column full-width">
          <v-btn block color="primary" @click="openSpot" v-if="isSpotClosed(selectedSpot)">
            <v-icon small class="mr-2">mdi-check</v-icon>
            Open This Spot
          </v-btn>
          <v-btn block outlined @click="closeSpot" v-if="isSpotOpen(selectedSpot)">
            <v-icon small class="mr-2">mdi-close</v-icon>
            Close this Spot
          </v-btn>
          <template v-if="isSpotAssigned(selectedSpot)">
            <v-btn block @click="updateSpotAssignmentType = 'depart'" class="mb-2 primary">
              Truck Departed
            </v-btn>
            <v-btn block outlined @click="updateSpotAssignmentType = 'unassign'">Unassign</v-btn>
            <v-btn outlined block class="mt-4" @click="isAssetVisitChatVisible = true">
              Details & Chat
            </v-btn>
          </template>
        </div>
      </v-card-actions>
    </v-card>
    <yard-view-spot-status-dialog :status-type="dialogStatusOpen" :spot="selectedSpot" />
    <yard-view-spot-assignment-update-dialog
      v-if="isSpotAssigned(selectedSpot)"
      :update-type="updateSpotAssignmentType"
      :spot-assignment="spotAssignment" />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { useEventHub } from '@/composables';

import useYard from '@/modules/yard/composables/useYard';
import YardViewSpotStatusDialog from '@/modules/yard/components/Spot/Dialogs/YardViewSpotStatusDialog.vue';
import YardViewSpotDetailOpen from '@/modules/yard/components/Spot/Detail/YardViewSpotDetailOpen.vue';
import YardViewSpotDetailClosed from '@/modules/yard/components/Spot/Detail/YardViewSpotDetailClosed.vue';
import YardViewSpotDetailAssigned from '@/modules/yard/components/Spot/Detail/YardViewSpotDetailAssigned.vue';

export default defineComponent({
  name: 'YardViewSpotDetail',
  components: {
    YardViewSpotDetailAssigned,
    YardViewSpotDetailClosed,
    YardViewSpotDetailOpen,
    YardViewSpotStatusDialog
  },
  setup() {
    const yardStore = useYard();
    const eventHub = useEventHub();
    const spotAssignment = computed(() => yardStore.selectedSpotRef.value.spotAssignments[0]);
    const dialogStatusOpen = ref('');
    const updateSpotAssignmentType = ref('');

    const closeSpot = () => {
      dialogStatusOpen.value = 'close';
    };

    const openSpot = () => {
      dialogStatusOpen.value = 'open';
    };

    const closeDetail = () => {
      yardStore.selectedSpotRef.value = null;
    };

    const getSpotStatus = spot => {
      return yardStore.getSpotStatus(spot);
    };

    onMounted(() => {
      eventHub.$on('close-spot-status-dialog', () => {
        dialogStatusOpen.value = '';
      });

      eventHub.$on('close-spot-assignment-update-dialog', () => {
        updateSpotAssignmentType.value = '';
      });
    });

    onBeforeUnmount(() => {
      eventHub.$off('close-spot-status-dialog');
      eventHub.$off('close-spot-assignment-update-dialog');
    });

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      openSpot,
      closeSpot,
      closeDetail,
      getSpotStatus,
      updateSpotAssignmentType,
      isSpotAssigned: yardStore.isSpotAssigned,
      isSpotClosed: yardStore.isSpotClosed,
      isSpotOpen: yardStore.isSpotOpen,
      spotAssignment,
      dialogStatusOpen,
      isAssetVisitChatVisible: yardStore.isAssetVisitChatVisibleRef,
      latestClosedEvent: yardStore.latestClosedEventRef,
      selectedSpot: yardStore.selectedSpotRef,
      spotStatus: yardStore.spotStatus
    };
  }
});
</script>
