var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.updateType, width: "600", persistent: "" },
      on: {
        close: function ($event) {
          _vm.updateType = null
        },
      },
    },
    [
      _vm.updateType
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "h4",
                    {
                      staticClass: "text-capitalize",
                      attrs: {
                        "data-testid": "yard-spot-assignment-dialog-title",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("mdi-alert-circle-outline")]
                      ),
                      _vm.updateType === "unassign"
                        ? _c("span", [_vm._v("Confirm unassignment?")])
                        : _vm.updateType === "depart"
                        ? _c("span", [_vm._v("Confirm asset departure?")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _vm.updateType === "depart"
                  ? _c("p", [
                      _vm._v(
                        " This asset will be cleared from the Live Yard View, and the spot will be marked as open for assignment again. "
                      ),
                    ])
                  : _vm.updateType === "unassign"
                  ? _c("p", [
                      _vm._v(
                        " This asset will be removed from this spot and moved back to the Waiting list. "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "pa-4" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        "data-testid": "yard-spot-assignment-dialog-cancel",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" Nevermind ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        "data-testid": "yard-spot-assignment-dialog-submit",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }