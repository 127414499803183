var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row align-center full-width" },
    [
      _c(
        "v-autocomplete",
        _vm._b(
          {
            class: { required: _vm.required },
            attrs: {
              "auto-select-first": "",
              dense: "",
              height: "25px",
              required: "",
              value: _vm.value,
              rules: _vm.rules,
              label: _vm.label,
              "item-text": "label",
              "item-value": "value",
              items: _vm.options,
            },
            on: { input: _vm.updateValue },
          },
          "v-autocomplete",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }