<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h1 class="mb-8">Live Yard View</h1>
        <div class="d-flex justify-space-between">
          <h3>
            <v-btn icon small rounded @click="back" data-testid="yard-area-form-back">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ selectedWarehouse?.name }}
          </h3>
        </div>

        <v-card elevation="1" class="mt-4">
          <div class="pa-6">
            <yard-view-spot-area-form
              v-if="isEditing || !defaultSpotAreaId"
              :spot-area="spotArea" />
            <template v-else>
              <v-row>
                <v-col cols="8">
                  <h4 class="py-1 pt-2" data-testid="yard-spot-area-area-name">
                    <strong>{{ spotArea.name }}</strong>
                  </h4>
                </v-col>
                <v-col cols="4" class="d-flex justify-end">
                  <v-btn
                    title="Select all spots"
                    small
                    text
                    v-if="spots.length > 0"
                    data-testid="yard-spot-area-select-all-button"
                    @click="selectAllSpots">
                    <v-icon small class="mr-2">
                      {{ areAllSpotsSelected ? 'mdi-selection-off' : 'mdi-select-all' }}
                    </v-icon>
                    {{ areAllSpotsSelected ? 'Unselect' : 'Select' }} All Spots
                  </v-btn>
                  <v-btn
                    v-if="!selectedSpot?.id && !selectedSpots?.length"
                    title="Update area name"
                    small
                    text
                    data-testid="yard-spot-area-edit-button"
                    @click="isEditing = true">
                    <v-icon small class="mr-2">mdi-pencil</v-icon>
                    Rename Area
                  </v-btn>

                  <v-btn
                    v-if="!selectedSpot?.id && !selectedSpots?.length"
                    title="Delete area and spots"
                    small
                    text
                    color="red"
                    class="ml-2"
                    data-testid="yard-spot-area-delete-button"
                    @click="isDeleteConfirmationVisible = true">
                    <v-icon small class="mr-2">mdi-delete</v-icon>
                    Delete Area
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </div>

          <v-divider></v-divider>

          <yard-view-spot-area
            v-if="defaultSpotAreaId"
            class="pa-5"
            :editable="true"
            :spot-area="spotArea"></yard-view-spot-area>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-navigation-drawer app right permanent>
        <transition name="slide-fade" mode="in-out">
          <yard-view-spot-form v-if="selectedSpot?.id" class="pl-4" :spot="selectedSpot" />
        </transition>
        <div
          v-if="!isLoading && !selectedSpot?.id && !selectedSpots?.length"
          class="d-flex align-center justify-center full-height pa-2">
          <div class="d-flex flex-column text-center align-center justify-center">
            <svg-select-spots class="mb-6" />
            <p>
              Hold
              <strong>Shift</strong>
              in your keyboard to select multiple spots.
            </p>
          </div>
        </div>
        <yard-view-spot-area-multi-selected
          class="pl-4"
          @clear="selectedSpots = []"
          v-if="!isLoading && selectedSpots?.length > 0" />
      </v-navigation-drawer>
    </v-row>
    <yard-view-loading v-if="isLoading" />

    <confirm
      :should-show="isDeleteConfirmationVisible"
      delete-confirmation
      show-delete-warning
      persistent
      v-if="isDeleteConfirmationVisible"
      @result="handleDeleteConfirmationResult"
      title="Delete this Area and its Spots?"
      :entity-name="spotArea.name"
      button-true-text="YES, DELETE"
      button-true-color="error"
      :width="650"></confirm>
  </v-container>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { useRouter } from 'vue-router/composables';
import warehouseService from '@satellite/services/warehouse-service';
import yardSpotAreaService from '@satellite/services/yardSpotArea-service';
import YardViewSpotArea from '@/modules/yard/components/SpotArea/YardViewSpotArea.vue';
import { useEventHub, useSockets } from '@/composables';
import YardViewLoading from '@/modules/yard/components/Overlays/YardViewLoading.vue';
import YardViewSpotForm from '@/modules/yard/components/Spot/YardViewSpotForm.vue';
import SvgSelectSpots from '@/components/svgs/SvgSelectSpots.vue';
import YardViewSpotAreaMultiSelected from '@/modules/yard/components/SpotArea/YardViewSpotAreaMultiSelected.vue';

export default defineComponent({
  name: 'YardViewSpotAreaPage',
  components: {
    YardViewSpotAreaMultiSelected,
    SvgSelectSpots,
    YardViewSpotForm,
    YardViewLoading,
    YardViewSpotArea
  },
  setup() {
    const router = useRouter();
    const yardStore = useYard();
    const sockets = useSockets();
    const eventHub = useEventHub();

    const selectedWarehouse = yardStore.selectedWarehouseRef.value;
    const defaultWarehouseId = yardStore.defaultWarehouseIdRef.value;
    const defaultSpotAreaId = yardStore.defaultSpotAreaIdRef.value;

    const isEditingRef = ref(false);
    const isDeleteConfirmationVisible = ref(false);

    const spotAreaRef = ref({ name: '' });
    const spots = computed(() =>
      yardStore.spotsRef.value.filter(spot => spot.spotAreaId === spotAreaRef.value.id)
    );

    //TODO: try to apply the existing helper from helios: useSelectAll.js
    const areAllSpotsSelected = computed(
      () =>
        spots.value.length ===
        yardStore.selectedSpotsRef.value.filter(s => s.spotAreaId === spotAreaRef.value.id).length
    );

    const selectAllSpots = () =>
      (yardStore.selectedSpotsRef.value = areAllSpotsSelected.value ? [] : spots.value);

    const fetchDataUponReload = () => {
      // Fetch spot area when reloading url
      if (defaultWarehouseId && defaultSpotAreaId) {
        yardSpotAreaService.getOneById(defaultSpotAreaId, {}).then(spotArea => {
          spotAreaRef.value = spotArea;
        });
      }

      // Fetch warehouse when reloading url
      if (!selectedWarehouse && defaultWarehouseId) {
        warehouseService.getWarehouseById(defaultWarehouseId, {}).then(warehouse => {
          yardStore.selectedWarehouseRef.value = warehouse;
        });
      }

      yardStore.selectedSpotRef.value = null;
    };

    const handleDeleteConfirmationResult = result => {
      if (result) {
        yardStore.deleteSpotArea(spotAreaRef.value.id);
        back();
      }
      isDeleteConfirmationVisible.value = false;
    };

    const back = () =>
      router.push({
        name: 'yard-warehouse',
        params: { warehouseId: defaultWarehouseId }
      });

    onBeforeMount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$on(`${operation}-Spot`, yardStore.handleSpotSubspaceEvent);
      });
      fetchDataUponReload();
      eventHub.$on('yard-spot-area-form-close', () => {
        isEditingRef.value = false;
      });
    });

    onBeforeUnmount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$off(`${operation}-Spot`, yardStore.handleSpotSubspaceEvent);
      });
      eventHub.$off('yard-spot-form-close');
      yardStore.selectedSpotRef.value = null;
      yardStore.selectedSpotsRef.value = [];
    });

    return {
      enableYardView: yardStore.enableYardViewRef,
      selectedWarehouse: yardStore.selectedWarehouseRef,
      defaultWarehouseId: yardStore.defaultWarehouseIdRef,
      defaultSpotAreaId: yardStore.defaultSpotAreaIdRef,
      selectedSpot: yardStore.selectedSpotRef,
      selectedSpots: yardStore.selectedSpotsRef,
      isLoading: yardStore.isLoadingSpotsRef,
      isReadOnly: yardStore.isReadOnlyRef,
      spotArea: spotAreaRef,
      isEditing: isEditingRef,
      isDeleteConfirmationVisible,
      areAllSpotsSelected,
      handleDeleteConfirmationResult,
      spots,
      selectAllSpots,
      back,
      onBeforeMount,
      onBeforeUnmount
    };
  },

  beforeMount() {
    // TODO: Remove this when the ff enable-yard-view-helios is removed
    // Redirect to appointments page if yard view is disabled
    if (!this.enableYardView) {
      this.$router.replace({ name: 'appointments' });
    }
  }
});
</script>
<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
