var render = function render(_c, _vm) {
  return _c(
    "v-overlay",
    {
      attrs: {
        value: _vm.props.isVisible,
        absolute: true,
        color: _vm.props.color,
        opacity: _vm.props.opacity,
      },
    },
    [
      _c(
        "div",
        { staticClass: "overlay-contents" },
        [
          _c("svg-warehouse"),
          _c("h3", { staticClass: "align-center" }, [
            _vm._v("No warehouse or dock selected"),
          ]),
          _c("p", [
            _vm._v(
              "Please select a warehouse along with one or more docks to continue"
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }