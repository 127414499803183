<template>
  <v-card>
    <v-card-title class="font-size-large font-weight-bold">
      Edit Appointment Notification
      <v-spacer></v-spacer>
      <icon-tooltip-button
        tooltip="Close editing window"
        x-large
        @click="$eventHub.$emit('set-edit-notification-key', null)"
        icon="mdi-close"></icon-tooltip-button>
    </v-card-title>
    <v-card-subtitle class="font-size-medium black--text pt-4">
      <strong>Subject:</strong>
      {{
        novaCore.makeInjectedNotificationSubject(
          novaCore.Notifications[notificationType].emailSubject,
          'Example Org',
          'Example Warehouse',
          1234,
          'ExampleTag'
        )
      }}
    </v-card-subtitle>
    <v-card-text class="mt-8">
      <div class="d-flex justify-start">
        <div>
          <v-form class="pt-0" data-testid="notification-config-form">
            <div class="pa-5 elevation-3 rounded">
              <label class="v-label font-weight-bold black--text">
                Display ID Type:
                <help-icon-tooltip iconStyleClass="grey darken-2" zIndexClass="z-0">
                  The type of identification that will be displayed on the top of the email.
                </help-icon-tooltip>
              </label>
              <v-radio-group
                v-model="notificationConfig.settings.appointmentIdType"
                row
                class="mt-2"
                hide-details>
                <div class="d-flex flex-row">
                  <v-radio
                    :class="{ 'mr-1': isReferenceNumberHidden }"
                    :value="novaCore.AppointmentIdType.REFERENCE_NUMBER"
                    :disabled="isReferenceNumberHidden">
                    <template v-slot:label>
                      <div>
                        {{ $getSettingValue('referenceNumberDisplayName', settings) }}
                      </div>
                    </template>
                  </v-radio>
                  <help-icon-tooltip
                    iconStyleClass="grey darken-2"
                    zIndexClass="z-0"
                    v-if="isReferenceNumberHidden">
                    {{ hiddenRefNumberMessage }}
                  </help-icon-tooltip>
                </div>
                <v-radio
                  :value="novaCore.AppointmentIdType.CONFIRMATION_NUMBER"
                  :class="{ 'ml-2': isReferenceNumberHidden }">
                  <template v-slot:label>
                    <div>Confirmation Number</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div class="mt-5 pa-3 elevation-3 rounded">
              <div
                v-for="contentBlock in novaCore.NotificationContentBlocks"
                class="content-block-container pa-2"
                :key="contentBlock.blockKey">
                <label class="v-label font-weight-bold black--text">
                  {{ contentBlock.meta.title }}:
                  <help-icon-tooltip iconStyleClass="grey darken-2">
                    {{ contentBlock.meta.description }}
                  </help-icon-tooltip>
                </label>
                <v-radio-group
                  v-model="
                    notificationConfig.contentBlocks[getContentBlockIdxByKey(contentBlock.blockKey)]
                      .styleType
                  "
                  row
                  class="mt-2"
                  hide-details
                  dense>
                  <v-radio value="hidden" label="Hide this block"></v-radio>
                  <v-radio value="standard" label="Neutral"></v-radio>
                  <v-radio value="info" label="Info"></v-radio>
                  <v-radio value="alert" label="Alert/Critical"></v-radio>
                </v-radio-group>
                <rich-text-input
                  v-model="
                    notificationConfig.contentBlocks[getContentBlockIdxByKey(contentBlock.blockKey)]
                      .content
                  "
                  @keyup.native.stop
                  class="mt-7 notification-config-wysiwyg"></rich-text-input>
              </div>
            </div>

            <div class="mt-5 pa-3 elevation-3 rounded">
              <label class="v-label font-weight-bold black--text">Appointment Details:</label>
              <outline-button class="ml-3" outlined @click="showAppointmentFieldEditDialog = true">
                Select and Reorder
              </outline-button>
            </div>

            <primary-button @click="saveNotificationConfig" class="mt-5">
              Apply & Save Changes
            </primary-button>
            <text-button before-icon="eye" @click="previewNotification" class="mt-5 ml-4">
              Preview
            </text-button>
          </v-form>
        </div>
        <div id="email-preview-container" class="ml-10">
          <h3 class="mb-2">Preview</h3>
          <email-notification-preview
            :key="previewRender"
            :notification-key="notificationType"
            :preview-data="previewData"></email-notification-preview>
        </div>
      </div>
    </v-card-text>
    <v-dialog v-model="showAppointmentFieldEditDialog" width="600">
      <v-card>
        <dialog-header
          header="Appointment Details"
          @close="showAppointmentFieldEditDialog = false"></dialog-header>

        <v-card-text>
          <h3>Select and order the fields to be displayed on this notification</h3>
          <div id="appointment-fields-list">
            <v-checkbox
              v-model="notificationConfig.settings.displayableAppointmentFields"
              :label="novaCore.snakeToTitleCase(field)"
              v-for="field in notificationConfig.settings.displayableAppointmentFields"
              :key="field"
              :value="field"
              hide-details>
              <template v-slot:prepend>
                <v-icon class="handle">mdi-arrow-split-horizontal</v-icon>
              </template>
            </v-checkbox>
          </div>
          <div>
            <v-checkbox
              v-model="notificationConfig.settings.displayableAppointmentFields"
              v-for="field in Object.values(novaCore.AppointmentNotificationField)"
              :key="field"
              :value="field"
              v-show="!notificationConfig.settings.displayableAppointmentFields.includes(field)"
              :readonly="
                field === novaCore.AppointmentNotificationField.REFERENCE_NUMBER &&
                isReferenceNumberHidden
              "
              hide-details>
              <template v-slot:prepend>
                <v-icon
                  :disabled="
                    !notificationConfig.settings.displayableAppointmentFields.includes(field)
                  ">
                  mdi-arrow-split-horizontal
                </v-icon>
              </template>
              <template v-slot:label>
                <span class="mr-1">{{ novaCore.snakeToTitleCase(field) }}</span>
                <help-icon-tooltip
                  iconStyleClass="grey darken-2"
                  v-if="
                    field === novaCore.AppointmentNotificationField.REFERENCE_NUMBER &&
                    isReferenceNumberHidden
                  ">
                  {{ hiddenRefNumberMessage }}
                </help-icon-tooltip>
              </template>
            </v-checkbox>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <outline-button @click="resetAppointmentFields">Nevermind</outline-button>
          <primary-button @click="showAppointmentFieldEditDialog = false">Confirm</primary-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Sortable from 'sortablejs';

export default {
  props: {
    notificationType: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    warehouseId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      notificationConfig: this.buildInitialConfig(),
      selected: [],
      previousDisplayableFields: [],
      showAppointmentFieldEditDialog: false,
      previewData: null,
      previewRender: 0
    };
  },
  watch: {
    showAppointmentFieldEditDialog() {
      if (this.showAppointmentFieldEditDialog) {
        // If the user clicks "Nevermind", we will revert back to the original state.
        this.previousDisplayableFields = this.novaCore.deepClone(
          this.notificationConfig.settings.displayableAppointmentFields
        );
        setTimeout(() => this.initSortable());
      }
    }
  },
  methods: {
    buildInitialConfig() {
      return {
        notificationType: this.notificationType,
        settings: {
          displayableAppointmentFields: Object.values(this.novaCore.AppointmentNotificationField),
          appointmentIdType: this.novaCore.AppointmentIdType.CONFIRMATION_NUMBER
        },
        contentBlocks: this.novaCore.deepClone(this.novaCore.NotificationContentBlocks)
      };
    },
    async getNotificationConfig() {
      const params = this.warehouseId
        ? {
            warehouseId: this.warehouseId
          }
        : {};
      const response = await axios.get(`notification-config/type/${this.notificationType}`, {
        params
      });
      const nc = response.data;
      if (nc.id) {
        this.notificationConfig = {
          id: nc.id,
          contentBlocks: nc.contentBlocks,
          notificationType: nc.notificationType,
          settings: {
            ...nc.settings,
            appointmentIdType: this.isReferenceNumberHidden
              ? this.novaCore.AppointmentIdType.CONFIRMATION_NUMBER
              : nc.settings.appointmentIdType,
            displayableAppointmentFields: this.isReferenceNumberHidden
              ? nc.settings.displayableAppointmentFields.filter(
                  f => f !== this.novaCore.AppointmentNotificationField.REFERENCE_NUMBER
                )
              : nc.settings.displayableAppointmentFields
          }
        };
      }
      if (this.notificationConfig.contentBlocks?.length) {
        this.notificationConfig.contentBlocks = this.notificationConfig.contentBlocks.map(
          cBlock => {
            cBlock.styleType = cBlock.styleType || this.novaCore.ContentBlockStyleType.STANDARD;
            return cBlock;
          }
        );
      }

      await this.makeNotificationPreview();
      this.previewRender++;

      return response;
    },
    getContentBlockIdxByKey(key) {
      return this.notificationConfig.contentBlocks.indexOf(
        this.notificationConfig.contentBlocks.find(b => b.blockKey === key)
      );
    },
    async saveNotificationConfig() {
      let response;
      if (this.notificationConfig.id) {
        response = await axios.patch(
          `notification-config/${this.notificationConfig.id}`,
          this.makeCleanConfigData()
        );
      } else {
        response = await axios.post(`notification-config`, this.makeCleanConfigData());
        this.notificationConfig = response.data.data;
      }

      if (response?.status < 400) {
        this.notify('Notification configuration saved!');
        this.mixpanel.track(this.mixpanel.events.MODULE.NOTIFICATION.UPDATED, {
          'Org Name': this.$org.name,
          'Org ID': this.$org.id,
          'Notification Type': this.notificationType,
          'Content Block Style': this.contentBlockStyle,
          'Display ID Type': this.displayIdType
        });
        this.makeNotificationPreview();
      }
    },
    resetAppointmentFields() {
      this.showAppointmentFieldEditDialog = false;
      this.notificationConfig.settings.displayableAppointmentFields = this.novaCore.deepClone(
        this.previousDisplayableFields
      );
    },
    makeCleanConfigData() {
      const data = this.novaCore.deepClone(this.notificationConfig);
      data.contentBlocks.forEach(block => {
        delete block.meta;
      });

      if (this.warehouseId) {
        data.warehouseId = this.warehouseId;
      }

      return data;
    },
    async previewNotification() {
      this.mixpanel.track(this.mixpanel.events.MODULE.NOTIFICATION.PREVIEW, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Notification Type': this.notificationType,
        'Content Block Style': this.contentBlockStyle,
        'Display ID Type': this.displayIdType
      });
      this.makeNotificationPreview();
    },
    async makeNotificationPreview() {
      const params = this.warehouseId
        ? {
            warehouseId: this.warehouseId
          }
        : {};
      await axios
        .post(`/notification-config/preview`, this.makeCleanConfigData(), {
          params
        })
        .then(r => {
          this.previewData = {
            html: r.data.bodyHtml,
            subject: r.data.subject
          };
        });
    },
    initSortable() {
      const _this = this;
      const container = document.querySelector('#appointment-fields-list');
      if (!container) {
        return;
      }

      Sortable.create(container, {
        handle: '.handle',
        animation: 150,
        filter: '.filtered',
        ghostClass: 'ghost-item',
        onEnd({ oldIndex, newIndex }) {
          // Get the moved field and remove it from the main list
          const movedField = _this.notificationConfig.settings.displayableAppointmentFields.splice(
            oldIndex,
            1
          )[0];
          // Move it to the new index
          _this.notificationConfig.settings.displayableAppointmentFields.splice(
            newIndex,
            0,
            movedField
          );
        }
      });
    }
  },
  computed: {
    isReferenceNumberHidden() {
      return this.$getSettingValue('referenceNumberIsVisible', this.settings) === false;
    },
    hiddenRefNumberMessage() {
      return 'The reference number cannot be used as it has been hidden in the organization settings.';
    },
    contentBlockStyle() {
      const mappedValues = {
        hidden: 'Hidden',
        standard: 'Standard',
        info: 'Info',
        alert: 'Alert'
      };
      return mappedValues[
        this.notificationConfig.contentBlocks[
          this.getContentBlockIdxByKey('appointment_paragraph_one')
        ].styleType
      ];
    },
    displayIdType() {
      const mappedValues = {
        confirmation_number: 'Confirmation Number',
        reference_number: 'Reference Number'
      };
      return mappedValues[this.notificationConfig.settings?.appointmentIdType];
    }
  },
  mounted() {
    this.getNotificationConfig();
    this.mixpanel.track(this.mixpanel.events.MODULE.NOTIFICATION.ACTION_EDIT, {
      'Org Name': this.$org.name,
      'Org ID': this.$org.id,
      'Notification Type': this.notificationType
    });
  }
};
</script>

<style lang="scss">
#email-preview-container > div > div > div {
  margin-left: 0 !important;
  margin-top: 0 !important;
  width: 600px !important;
}
.notification-config-wysiwyg .ck-content {
  min-height: 150px;
  width: 550px;
  max-width: 100%;
}

.ghost-item {
  opacity: 0;
}
</style>
