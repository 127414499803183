<template>
  <dialog-base
    :key="renderKey"
    :loading="false"
    v-bind="[$props, $attrs]"
    header="Edit details"
    loading-message="Updating Appointment Details ..."
    @close="close"
    @confirm="$refs.editAppointmentForm.updateAppointment()"
    max-width="100%"
    has-dialog-actions
    :value="showDialog"
    width="900px">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs">
        Edit Appointment
      </primary-button>
    </template>
    <template v-slot:body>
      <edit-appointment-form
        :appointment="appointment"
        id="edit-appointment-form"
        ref="editAppointmentForm"
        @close="close"></edit-appointment-form>
    </template>
  </dialog-base>
</template>

<script>
import EditAppointmentDialogBase from '@/modules/appointments/components/edit/EditAppointmentDialogBase.vue';
export default {
  extends: EditAppointmentDialogBase
};
</script>
