<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    content-class="dock-dialog-content"
    :key="renderKey"
    header="Create Dock"
    :scrollable="false"
    max-width="60vw"
    width="60vw"
    loading-message="Saving Dock..."
    @close="close"
    @confirm="$refs.dockForm.submit()">
    <template v-slot:activator="slotProps">
      <v-tooltip top :disabled="$rolePermissions.canCreateAppointment">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
            <primary-button
              v-else
              :before-icon="beforeIcon"
              @click="onOpenModal()"
              v-on="slotProps.on"
              v-bind="slotProps.attrs"
              :disabled="!$rolePermissions.canCreateDock"
              large
              class="mr-2">
              Create Dock
            </primary-button>
          </div>
        </template>
        <span>{{ novaCore.getRoleActionError() }}</span>
      </v-tooltip>

      <!--
        @slot Custom activator for the dialog
        @binding {object} slotProps Object containing {on, attrs}
      -->
    </template>
    <template slot="body">
      <dock-form
        :dock="dock"
        :warehouse-id="warehouseId"
        @saved="$eventHub.emit('create-Dock')"
        @close="close"
        ref="dockForm"></dock-form>
    </template>
  </dialog-base>
</template>

<script lang="js">
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for create dock form
 * @displayName CreateDockDialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Dock object
     */
    dock: {
      type: Object,
      required: false
    },
    /**
     * Warehouse ID
     */
    warehouseId: {
      type: String,
      required: true
    },
    beforeIcon: {
      type: String,
      required: false,
      default: 'plus'
    }
  },
  methods: {
    onOpenModal() {
      this.navigate('warehouses.details.docks', { warehouseId: this.warehouseId })
    }
  }
};
</script>
