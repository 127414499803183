var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "py-4" },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item",
                { staticClass: "pl-0" },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "mr-2" },
                    [
                      _vm.warehouse.geolocation
                        ? _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-check-circle"),
                          ])
                        : _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v("mdi-alert-circle"),
                          ]),
                    ],
                    1
                  ),
                  _c("v-list-item-content", [
                    _vm._v(
                      " Feature " +
                        _vm._s(
                          _vm.warehouse.geolocation ? "enabled" : "disabled"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "mt-4", attrs: { cols: "12" } },
        [
          _vm.warehouse.geolocation
            ? _c(
                "v-card",
                { staticClass: "qr-code-card w-95" },
                [
                  _c("v-card-text", [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c("div", { staticClass: "mr-8" }, [
                        _c("canvas", { attrs: { id: "checkin-qr" } }),
                      ]),
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-subtitle-1 font-weight-bold text--primary mb-2",
                          },
                          [_vm._v(" QR Code & Check-in template ")]
                        ),
                        _c(
                          "p",
                          { staticClass: "text-body-2 text--primary mb-8" },
                          [
                            _vm._v(
                              " Print the Check-in template and display it to drivers upon their arrival. "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-row" },
                          [
                            _c(
                              "div",
                              { staticClass: "mr-2" },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "internal-activator": "",
                                      "close-on-click": "",
                                      bottom: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "button-base",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        outlined: "",
                                                        "after-icon":
                                                          "chevron-down",
                                                        append: "",
                                                      },
                                                    },
                                                    "button-base",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" DOWNLOAD ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      249797233
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      _vm._l(
                                        _vm.downloadChoices,
                                        function (item, index) {
                                          return _c(
                                            "v-list-item",
                                            { key: index },
                                            [
                                              _c(
                                                "button-base",
                                                {
                                                  staticClass: "text-left",
                                                  attrs: {
                                                    small: "",
                                                    text: "",
                                                    block: "",
                                                  },
                                                  on: { click: item.action },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "flex-grow-1 justify-start",
                                                    },
                                                    [_vm._v(_vm._s(item.title))]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpToCheckIn()
                                  },
                                },
                              },
                              [_vm._v("Check-in URL")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _c(
                "v-col",
                { staticClass: "mt-2", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: { click: _vm.requestCheckInFeature },
                    },
                    [_vm._v("request feature")]
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c("gate-management-template", {
        attrs: {
          "warehouse-name": _vm.warehouse.name,
          id: "printable-warehouse-template",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }