/**
 * Global ixin used for localStorage
 * @mixin
 * @displayName Local Storage Mixin
 */
export default {
  methods: {
    $loadObjectFromLocalStorage(key) {
      let data = {};
      if (localStorage.getItem(key)) {
        data = JSON.parse(localStorage.getItem(key));
      }

      return data;
    },
    $updateObjectInLocalStorage(key, val) {
      const keyParts = key.split('.');
      const currentData = { ...this.$loadObjectFromLocalStorage(keyParts[0]) };
      const data = setNestedObjectData(currentData, keyParts, val);

      localStorage.setItem(keyParts[0], JSON.stringify(data));

      function setNestedObjectData(data, keyParts, val) {
        let context = data;
        keyParts = keyParts.length > 1 ? keyParts.slice(1) : keyParts;
        keyParts.forEach((key, index) => {
          if (index + 1 === keyParts.length) {
            context[key] = val;
          } else {
            if (typeof context[key] === 'undefined') {
              context[key] = {};
            }
            context = context[key];
          }
        });
        return context;
      }
    }
  }
};
