var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dock-list", {
    attrs: {
      "read-only": _vm.$isDockReadOnly(_vm.warehouse.id, _vm.warehouse.orgId),
      "warehouse-id": _vm.warehouse.id,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }