<template>
  <div>
    <div class="pa-4 d-flex flex-row align-content-center new-sign-in-warning">
      <v-icon class="mr-4" size="24px">mdi-bell</v-icon>
      <div class="d-flex flex-column">
        <span class="font-weight-bold">A new sign in page will be released soon</span>
        <span>We are updating the Opendock sign in page with a brand new look and feel</span>
      </div>
    </div>
    <div class="login-wrapper">
      <auth-hero></auth-hero>
      <div class="flex-grow-1 d-flex flex-column align-center justify-center">
        <div class="full-width">
          <div class="d-flex align-center justify-center flex-wrap">
            <span class="text-h4 text-center py-2 pt-4 font-weight-bold secondary--text">
              Sign in
            </span>
          </div>
          <login-form
            :verification-token="verificationToken"
            :defaultEmail="defaultEmail"></login-form>
        </div>
      </div>

      <password-reset-dialog
        :show-dialog="shouldShowResetDialog"
        :reset-token="resetToken"
        :email="resetEmail"
        :verify-account="shouldVerifyAccount"></password-reset-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Verification Token
     */
    verificationToken: {
      type: String,
      required: false
    },
    /**
     * Reset Email
     */
    resetEmail: {
      type: String,
      required: false
    },
    /**
     * Reset Token
     */
    resetToken: {
      type: String,
      required: false
    },
    defaultEmail: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    shouldShowResetDialog() {
      return Boolean(this.resetEmail) && Boolean(this.resetToken);
    },
    shouldVerifyAccount() {
      return this.$route.meta.verifyAccount;
    }
  },
  mounted() {
    if (this.$route.query.errorMessage) {
      this.notify(this.$route.query.errorMessage, 'error');
    }
    document.body.classList.add('login-page');
  },
  destroyed() {
    document.body.classList.remove('login-page');
  }
};
</script>

<style scoped>
.new-sign-in-warning {
  background: #fcfcff;
}
</style>
