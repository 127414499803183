<template>
  <div>
    <multidocument-upload-button
      :required="field.required"
      v-model="value"
      :rules="validationRules"
      @uploading="$emit('toggle-confirm-button', true)"
      @uploaded="$emit('toggle-confirm-button', false)"></multidocument-upload-button>
    <span>
      Maximum
      <strong>50MB</strong>
      in size per file. Maximum 10 files. Only
      <strong>image</strong>
      ,
      <strong>text</strong>
      ,
      <strong>zip</strong>
      or
      <strong>spreadsheet</strong>
      allowed.
    </span>
  </div>
</template>

<script>
import customFieldTypeMixin from '@/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-multidocument',
  mixins: [customFieldTypeMixin]
};
</script>
