<template>
  <v-row no-gutters class="d-flex yard-view-page-header-container gap-4 gap-lg-0">
    <v-col sm="12" md="12" lg="6">
      <h1 class="typography-heading-xlg">Live Yard View</h1>
    </v-col>

    <v-col
      sm="12"
      md="12"
      lg="6"
      class="d-flex flex-row flex-wrap flex-md-nowrap justify-lg-end gap-3"
      v-if="isGateManagementEnabled">
      <big-number-card
        :data-testid="`yard-view-page-header-card-${card.title}`"
        v-for="card of cards"
        :key="card.title">
        <v-card-title>
          {{ card.title }}
        </v-card-title>
        <v-card-text>
          {{ card.count }}
        </v-card-text>
      </big-number-card>
    </v-col>
  </v-row>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import BigNumberCard from '@/components/elements/BigNumberCard.vue';

export default defineComponent({
  name: 'YardViewPageHeader',
  components: { BigNumberCard },
  props: {},
  setup() {
    const yardStore = useYard();

    const spots = computed(() =>
      yardStore.spotsRef.value.filter(spot =>
        yardStore.selectedAreasRef.value.includes(spot.spotAreaId)
      )
    );

    const parkedCount = computed(
      () =>
        spots.value.filter(
          spot => spot.spotAssignments.length > 0 && spot.type === yardStore.spotType.PARKING
        ).length
    );

    const dockedCount = computed(
      () =>
        spots.value.filter(
          spot => spot.spotAssignments.length > 0 && spot.type === yardStore.spotType.DOCKING
        ).length
    );

    const cards = computed(() => [
      {
        title: 'PARKED',
        count: parkedCount.value
      },
      {
        title: 'DOCKED',
        count: dockedCount.value
      }
    ]);

    return {
      cards,
      selectedWarehouse: yardStore.selectedWarehouseRef,
      isGateManagementEnabled: yardStore.isGateManagementEnabledForSelectedWarehouse
    };
  }
});
</script>
