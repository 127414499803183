<template>
  <dialog-base
    :key="renderKey"
    :loading="loading"
    no-padding
    v-bind="[$props, $attrs]"
    header=""
    hide-close-icon
    loading-message=""
    @close="close"
    max-width="100%"
    :value="showDialog"
    color="black"
    fullscreen>
    <template v-slot:body>
      <div class="pa-4">
        <v-btn icon large color="black" class="disabled-bg" background="white" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="my-4 d-flex align-center white--text px-4 font-size-large">
        <v-icon class="mr-1 white--text">mdi-map-marker</v-icon>
        <strong>Choose Warehouse</strong>
      </div>
      <v-btn
        plain
        dark
        :disabled="!warehouse?.docks.length"
        :class="{ active: isWarehouseSelected(warehouse) }"
        class="btn-plain text-left px-4 py-6 btn-text-left"
        block
        v-for="warehouse in warehouses"
        :key="warehouse.id"
        @click="selectWarehouse(warehouse)">
        {{ warehouse.name }}
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
export default {
  mixins: [dialogMixin],
  data() {
    return {
      loading: false,
      warehousesLoaded: false,
      warehouses: [],
      mounted: false
    };
  },
  methods: {
    async getData() {
      if (this.loading) {
        // Return current data while loading if attempt is made to get more data
        return this.warehouses;
      }
      this.loading = true;
      const warehouses = await this.services.warehouse.getWarehouses(
        {},
        {
          joins: ['docks||name,id,capacityChildren,isActive,loadTypeIds', 'org||name,settings'],
          fields: []
        }
      );
      let data = [];
      if (warehouses?.length > 0) {
        data = this.novaCore.naturalSort(warehouses, false, 'name');
        data.forEach(d => (d.org = this.$org));
      }
      this.loading = false;

      return data;
    },
    isWarehouseSelected(warehouse) {
      return warehouse.id === this.$selectedWarehouse?.id;
    },
    selectWarehouse(warehouse) {
      this.$store.dispatch('Calendar/setSelectedWarehouse', warehouse);
      this.$store.commit(
        'Calendar/setSelectedDocks',
        this.util.getActiveDocksFromWarehouse(warehouse)
      );
      this.$store.commit(
        'Calendar/setSelectedLoadtypes',
        this.util.getLoadTypesFromActiveDocks(warehouse?.docks)
      );
      this.close();
    }
  },
  async mounted() {
    this.warehouses = await this.getData();
    this.mounted = true;
  }
};
</script>
