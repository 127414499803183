var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.asButton
        ? _c(
            "v-btn",
            {
              attrs: { text: "", "data-testid": "yard-area-add-spots-button" },
              on: { click: _vm.addSpot },
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-plus")]),
              _vm._v(" Add Spots "),
            ],
            1
          )
        : _c(
            "v-card",
            {
              staticClass:
                "ma-1 cursor-pointer d-flex flex-column align-center justify-center text-center add-card grey--text",
              attrs: {
                width: _vm.cardWidth,
                height: _vm.cardHeight,
                elevation: "0",
                "data-testid": "yard-area-add-spots-card",
              },
              on: { click: _vm.addSpot },
            },
            [
              _c(
                "v-card-text",
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-plus")])],
                1
              ),
            ],
            1
          ),
      _c("yard-view-spot-generator-dialog", {
        model: {
          value: _vm.generatorDialogOpen,
          callback: function ($$v) {
            _vm.generatorDialogOpen = $$v
          },
          expression: "generatorDialogOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }