<template>
  <div>
    <p>Timeline of updates made to the appointment:</p>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="6">
        <audit-log-appointment
          :object-id="appointment.id"
          :org="$org"
          :entity-obj="appointment"
          entity="appointment"></audit-log-appointment>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';

export default {
  extends: AppointmentDetailsBase
};
</script>
