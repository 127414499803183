<template>
  <div class="d-flex flex-row align-center full-width">
    <v-autocomplete
      auto-select-first
      dense
      height="25px"
      class="required"
      required
      @input="value => $emit('input', value)"
      :value="value"
      :rules="$validator.rules.required('Country')"
      label="Country"
      item-text="label"
      item-value="value"
      v-bind="$attrs"
      :items="options">
      <template #label>
        Country
        <help-icon-tooltip v-if="tooltipText" iconStyleClass="grey darken-2">
          {{ tooltipText }}
        </help-icon-tooltip>
        <span v-if="required" class="red--text"><strong>*</strong></span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';

export default {
  mixins: [selectMixin],
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Country'
    },
    limitOptions: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    options() {
      return Object.entries(
        this.limitOptions ? this.novaCore.ISO3166FilteredCountries : this.novaCore.ISO3166Countries
      )
        .map(entry => {
          return {
            label: entry[1].name,
            value: entry[0]
          };
        })
        .sort((a, b) => {
          // Prioritize USA and Canada
          if (a.value === 'USA') {
            return -1;
          }
          if (b.value === 'USA') {
            return 1;
          }
          if (a.value === 'CAN') {
            return -1;
          }
          if (b.value === 'CAN') {
            return 1;
          }

          return a.label.localeCompare(b.label);
        });
    }
  }
};
</script>
