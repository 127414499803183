<template>
  <dialog-base
    :key="renderKey"
    v-bind="$props"
    header="Carrier sign in"
    @close="close"
    max-width="100%"
    :value="showDialog"
    width="650px">
    <template slot="body">
      <v-container class="px-0">
        <div>{{ email }}</div>
        <div class="mt-4 black--text">
          It seems like this email is registered as a carrier. To sign in, please proceed to the
          Opendock Scheduling Portal.
        </div>
      </v-container>
    </template>
    <template slot="dialog-actions">
      <action-group @cancel="close" :hide-confirm="true">
        <template v-slot:additional-actions-after>
          <a
            :href="`${lunaUrl}/login`"
            target="_blank"
            rel="noopener"
            class="primary-button v-btn v-size--default px-4 font-weight-bold black--text">
            Go To Scheduling Portal
            <v-icon class="black--text ml-2" small>mdi-arrow-right</v-icon>
          </a>
        </template>
      </action-group>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import config from '@/config/config';

/**
 * Incorrect Portal Dialog
 * @displayName Incorrect Portal Dialog
 */
export default {
  mixins: [dialogMixin],
  computed: {
    lunaUrl() {
      return config.luna_url;
    }
  },
  props: {
    /**
     * Alert message to display in dialog
     */
    alertMessage: {
      type: String,
      required: false
    },
    /**
     * Alert type
     * @values success | error
     */
    alertType: {
      type: String,
      required: false,
      default: 'success'
    },
    email: {
      type: String,
      required: true
    }
  }
};
</script>
