<template>
  <svg
    width="80"
    height="24"
    viewBox="0 0 80 24"
    :class="`truck-svg-${theme}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="{ transform: `rotate(${rotation}deg)`, scale }">
    <g clip-path="url(#clip0_2457_25664)">
      <path
        d="M1.04198 0.400393L62.9588 0.400391C63.3393 0.400391 63.6004 0.682156 63.6004 0.973361V23.0274C63.6004 23.3186 63.3393 23.6004 62.9588 23.6004H1.04198C0.661529 23.6004 0.400391 23.3186 0.400391 23.0274L0.400391 0.973369C0.400391 0.68216 0.661528 0.400393 1.04198 0.400393Z"
        :fill="colors.truckBackgroundColor"
        :stroke="colors.borderColor" />
      <path
        d="M6.24023 3.6002C6.24023 3.37928 6.41932 3.2002 6.64023 3.2002C6.86115 3.2002 7.04023 3.37928 7.04023 3.6002V20.4002C7.04023 20.6211 6.86115 20.8002 6.64023 20.8002C6.41932 20.8002 6.24023 20.6211 6.24023 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M10.4004 3.6002C10.4004 3.37928 10.5795 3.2002 10.8004 3.2002C11.0213 3.2002 11.2004 3.37928 11.2004 3.6002V20.4002C11.2004 20.6211 11.0213 20.8002 10.8004 20.8002C10.5795 20.8002 10.4004 20.6211 10.4004 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M14.5605 3.6002C14.5605 3.37928 14.7396 3.2002 14.9605 3.2002C15.1814 3.2002 15.3605 3.37928 15.3605 3.6002V20.4002C15.3605 20.6211 15.1814 20.8002 14.9605 20.8002C14.7396 20.8002 14.5605 20.6211 14.5605 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M18.7207 3.6002C18.7207 3.37928 18.8998 3.2002 19.1207 3.2002C19.3417 3.2002 19.5207 3.37928 19.5207 3.6002V20.4002C19.5207 20.6211 19.3417 20.8002 19.1207 20.8002C18.8998 20.8002 18.7207 20.6211 18.7207 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M22.8809 3.6002C22.8809 3.37928 23.06 3.2002 23.2809 3.2002C23.5017 3.2002 23.6809 3.37928 23.6809 3.6002V20.4002C23.6809 20.6211 23.5017 20.8002 23.2809 20.8002C23.06 20.8002 22.8809 20.6211 22.8809 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M27.041 3.6002C27.041 3.37928 27.2201 3.2002 27.441 3.2002C27.662 3.2002 27.841 3.37928 27.841 3.6002V20.4002C27.841 20.6211 27.662 20.8002 27.441 20.8002C27.2201 20.8002 27.041 20.6211 27.041 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M31.1992 3.6002C31.1992 3.37928 31.3783 3.2002 31.5992 3.2002C31.8201 3.2002 31.9992 3.37928 31.9992 3.6002V20.4002C31.9992 20.6211 31.8201 20.8002 31.5992 20.8002C31.3783 20.8002 31.1992 20.6211 31.1992 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M35.3594 3.6002C35.3594 3.37928 35.5384 3.2002 35.7594 3.2002C35.9803 3.2002 36.1594 3.37928 36.1594 3.6002V20.4002C36.1594 20.6211 35.9803 20.8002 35.7594 20.8002C35.5384 20.8002 35.3594 20.6211 35.3594 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M39.5195 3.6002C39.5195 3.37928 39.6987 3.2002 39.9195 3.2002C40.1405 3.2002 40.3195 3.37928 40.3195 3.6002V20.4002C40.3195 20.6211 40.1405 20.8002 39.9195 20.8002C39.6987 20.8002 39.5195 20.6211 39.5195 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M43.6797 3.6002C43.6797 3.37928 43.8588 3.2002 44.0797 3.2002C44.3006 3.2002 44.4797 3.37928 44.4797 3.6002V20.4002C44.4797 20.6211 44.3006 20.8002 44.0797 20.8002C43.8588 20.8002 43.6797 20.6211 43.6797 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M47.8398 3.6002C47.8398 3.37928 48.019 3.2002 48.2398 3.2002C48.4608 3.2002 48.6398 3.37928 48.6398 3.6002V20.4002C48.6398 20.6211 48.4608 20.8002 48.2398 20.8002C48.019 20.8002 47.8398 20.6211 47.8398 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M52 3.6002C52 3.37928 52.1791 3.2002 52.4 3.2002C52.6209 3.2002 52.8 3.37928 52.8 3.6002V20.4002C52.8 20.6211 52.6209 20.8002 52.4 20.8002C52.1791 20.8002 52 20.6211 52 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path
        d="M56.1602 3.6002C56.1602 3.37928 56.3392 3.2002 56.5602 3.2002C56.781 3.2002 56.9602 3.37928 56.9602 3.6002V20.4002C56.9602 20.6211 56.781 20.8002 56.5602 20.8002C56.3392 20.8002 56.1602 20.6211 56.1602 20.4002V3.6002Z"
        :fill="colors.truckColor"
        fill-opacity="0.2" />
      <path d="M63.1992 4H64.7992V20H63.1992V4Z" :fill="colors.borderColor" />
      <path
        d="M71.2008 21.2002H70.8008V21.6002V22.0002C70.8008 22.442 71.1589 22.8002 71.6008 22.8002H73.2008C73.6426 22.8002 74.0008 22.442 74.0008 22.0002V21.6002V21.2002H73.6008H71.2008Z"
        :fill="colors.truckBackgroundColor"
        :stroke="colors.borderColor" />
      <path
        d="M65.1992 2.7998H77.7826C78.7943 2.7998 79.5992 3.59961 79.5992 4.56755V19.432C79.5992 20.4 78.7943 21.1998 77.7826 21.1998H65.1992V2.7998Z"
        :fill="colors.truckBackgroundColor"
        :stroke="colors.borderColor" />
      <path
        d="M73.6008 2.8002H74.0008V2.4002V2.0002C74.0008 1.55837 73.6426 1.2002 73.2008 1.2002H71.6008C71.1589 1.2002 70.8008 1.55837 70.8008 2.0002V2.4002V2.8002H71.2008H73.6008Z"
        :fill="colors.truckBackgroundColor"
        :stroke="colors.borderColor" />
    </g>
    <defs>
      <clipPath id="clip0_2457_25664">
        <rect width="80" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    /**
     * Theme for the SVG truck.
     * Valid themes: blue, red, green, grey, yellow
     */
    theme: {
      type: String,
      default: 'blue',
      validator: value => {
        return ['blue', 'red', 'green', 'grey', 'yellow', 'white'].includes(value);
      }
    },
    rotation: {
      type: Number,
      default: 0 // Rotation degree (0, 90, 180, 270)
    },
    scale: {
      type: Number,
      default: 1
    }
  },
  computed: {
    colors() {
      const themes = {
        blue: {
          truckColor: '#ADD8E6',
          borderColor: '#4682B4',
          truckBackgroundColor: '#E4F1F7'
        },
        red: {
          truckColor: '#FF7F7F',
          borderColor: '#B22222',
          truckBackgroundColor: '#FAD4D4'
        },
        green: {
          truckColor: '#90EE90',
          borderColor: '#228B22',
          truckBackgroundColor: '#D4FAD4'
        },
        grey: {
          truckColor: '#B0B0B0',
          borderColor: '#808080',
          truckBackgroundColor: '#D3D3D3'
        },
        yellow: {
          truckColor: '#c2b978',
          borderColor: '#6e601c',
          truckBackgroundColor: '#d4cc89'
        },
        white: {
          truckColor: '#EEEEEE',
          borderColor: '#808080',
          truckBackgroundColor: '#FFFFFF'
        }
      };
      return themes[this.theme] || themes.blue;
    }
  }
};
</script>

<style scoped>
svg {
  display: block;
  margin: auto;
}
</style>
