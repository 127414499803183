<template>
  <div class="full-height">
    <v-tabs
      background-color="var(--v-mobileblue-base)"
      dark
      class="mobile-appointment-tabs non-active-tab-underline"
      slider-color="#ffe67f"
      grow
      v-model="tab">
      <v-tab key="status">Status & Details</v-tab>
      <v-tab key="claims" :disabled="claimSupportTriggers.length === 0">Claims Support</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="status">
        <appointment-details-header-mobile
          :appointment="appointment"
          @close="$emit('close')"></appointment-details-header-mobile>
        <status-timeline-mobile
          :appointment="appointment"
          :warehouse-triggers="warehouseTriggers"></status-timeline-mobile>
        <v-divider class="my-4"></v-divider>
        <appointment-details-mobile
          class="px-6"
          :appointment="appointment"
          :appointment-warehouse="appointmentWarehouse"></appointment-details-mobile>
      </v-tab-item>
      <v-tab-item key="claims">
        <appointment-details-claims-mobile
          :appointment="appointment"
          :warehouse-triggers="warehouseTriggers"
          @close="$emit('close')"></appointment-details-claims-mobile>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'AppointmentDetailsPageMobile',
  props: {
    appointment: {
      type: Object,
      required: true
    },
    appointmentWarehouse: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      warehouseTriggers: [],
      tab: 0
    };
  },
  computed: {
    claimSupportTriggers() {
      return (
        this.warehouseTriggers?.filter(t =>
          this.novaCore.CustomFormsClaimFeatures.includes(t.feature)
        ) || []
      );
    }
  },
  async mounted() {
    await this.setWarehouseTriggers();

    this.$eventHub.$on(`update-Trigger`, this.setWarehouseTriggers);
    this.$eventHub.$on(`create-FormField`, this.setWarehouseTriggers);
  },
  destroyed() {
    Object.keys(this.sockets.actions).map(action => {
      this.$eventHub.$off(`${action}-Trigger`, this.setWarehouseTriggers);
      this.$eventHub.$off(`${action}-FormField`, this.setWarehouseTriggers);
    });
  },
  methods: {
    async setWarehouseTriggers() {
      if (this.$selectedWarehouseTriggers) {
        return (this.warehouseTriggers = this.$selectedWarehouseTriggers);
      }

      const response = await axios.get('custom-forms/trigger', {
        params: {
          s: { objectId: this.appointmentWarehouse.id, entityName: 'warehouse' },
          limit: 100
        }
      });
      this.warehouseTriggers = response?.data?.data;
    }
  }
};
</script>
