var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-row align-center full-width",
      attrs: { "data-testid": "grid-warehouse-select" },
    },
    [
      _vm.label
        ? _c(
            "h3",
            {
              staticClass: "mr-4",
              class: _vm.disabled ? "text--disabled" : "",
            },
            [
              _vm._v(" " + _vm._s(_vm.label) + ": "),
              _vm.tooltipText
                ? _c("help-icon-tooltip", [
                    _vm._v(" " + _vm._s(_vm.tooltipText) + " "),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("v-autocomplete", {
        staticClass: "pt-0 warehouse-select",
        attrs: {
          outlined: _vm.outlined,
          "data-testid": _vm.$attrs.dataTestid ?? "warehouse-select",
          dense: "",
          "prepend-icon": !_vm.hideIcon ? "mdi-warehouse" : "",
          value: _vm.value,
          items: _vm.warehouses,
          "item-disabled": _vm.isItemDisabled,
          "search-input": _vm.filters.searchStr,
          "no-filter": "",
          loading: _vm.loading || _vm.externalLoading,
          del: "search",
          "item-value": "id",
          multiple: _vm.multiSelect,
          label: _vm.customPersistentLabel ?? _vm.placeholder,
          "return-object": "",
          clearable: _vm.clearable,
          disabled: _vm.disabled,
          "hide-details": "auto",
          "single-line": !_vm.customPersistentLabel,
        },
        on: {
          input: _vm.updateValue,
          "update:searchInput": function ($event) {
            return _vm.$set(_vm.filters, "searchStr", $event)
          },
          "update:search-input": [
            function ($event) {
              return _vm.$set(_vm.filters, "searchStr", $event)
            },
            function ($event) {
              _vm.options.page = 1
            },
          ],
          "click:clear": _vm.clearSelection,
        },
        scopedSlots: _vm._u(
          [
            _vm.required
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.placeholder) + " "),
                      _c("span", { staticClass: "red--text" }, [
                        _c("strong", [_vm._v("*")]),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "item",
              fn: function ({ item }) {
                return [_vm._v(" " + _vm._s(_vm.getWarehouseName(item)) + " ")]
              },
            },
            {
              key: "selection",
              fn: function ({ item, index }) {
                return [
                  _vm.multiSelect
                    ? [
                        index === 0
                          ? _c("span", [_vm._v(_vm._s(item.name))])
                          : _vm._e(),
                        index === 1
                          ? _c(
                              "span",
                              { staticClass: "grey--text text-caption ml-2" },
                              [
                                _vm._v(
                                  " (+" +
                                    _vm._s(_vm.value.length - 1) +
                                    " others) "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : [
                        !!_vm.value
                          ? _c("section", { staticClass: "text-truncate" }, [
                              _vm._v(" " + _vm._s(item.name) + " "),
                            ])
                          : _vm._e(),
                      ],
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  _vm.filters.searchStr && !_vm.filters.searchStr.length
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c(
                                "span",
                                { staticClass: "d-block typography-body-sm" },
                                [
                                  _vm._v(
                                    " Looks like your org doesn't have a warehouse set up yet... "
                                  ),
                                ]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "full-width v-btn primary v-size--default mt-2",
                                  attrs: {
                                    to: {
                                      name: "warehouses",
                                      props: true,
                                      params: {
                                        shouldShowCreateWarehouseDialog: true,
                                      },
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleCreateWarehouseClick.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Let's create a warehouse ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-list-item",
                        [
                          _c("v-list-item-title", [
                            _c(
                              "span",
                              { staticClass: "d-block typography-body-sm" },
                              [
                                _vm._v(
                                  " No warehouses found matching this criteria... "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                ]
              },
              proxy: true,
            },
            {
              key: "append-item",
              fn: function () {
                return [
                  _vm.warehouseDoesNotHaveDocks &&
                  _vm.showCreateDockButton &&
                  !_vm.orgId
                    ? [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-block typography-body-sm" },
                                  [
                                    _vm._v(
                                      " Looks like your warehouse doesn't have a dock set up yet ... "
                                    ),
                                  ]
                                ),
                                _vm.warehouses.length
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "full-width v-btn primary v-size--default mt-2",
                                        attrs: {
                                          to: {
                                            name: "warehouses.details.docks",
                                            props: true,
                                            params: {
                                              warehouseId: _vm.warehouses[0].id,
                                              shouldShowCreateDockForm: true,
                                            },
                                          },
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleCreateDockClick.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Let's Create a Dock ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c("div", {
                    directives: [
                      {
                        name: "intersect",
                        rawName: "v-intersect",
                        value: _vm.onIntersect,
                        expression: "onIntersect",
                      },
                    ],
                    attrs: { options: { threshold: [0, 0.5, 1.0] } },
                  }),
                  _vm.loading && _vm.warehouses.length < _vm.warehouses.total
                    ? [
                        _c("v-divider"),
                        _c("div", { staticClass: "v-list-item py-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column align-center flex-grow-1 flex-shrink-0",
                            },
                            [
                              _c("p", { staticClass: "typography-body-sm" }, [
                                _vm._v("Loading more warehouses..."),
                              ]),
                              _c("v-progress-linear", {
                                attrs: { indeterminate: "" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }