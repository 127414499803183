import _ from 'lodash';
import { useStore } from '@/composables';
import { computed, onMounted } from 'vue';

export default function useCarrierContacts({ prefetch = false } = {}) {
  const store = useStore();
  const carrierContacts = computed(() => _.get(store, 'state.CarrierContacts.carrierContacts'));
  const isLoading = computed(() => _.get(store, 'state.CarrierContacts.isLoading'));
  const pagination = computed(() => _.get(store, 'state.CarrierContacts.pagination'));
  const params = computed({
    get() {
      return _.get(store, 'state.CarrierContacts.params');
    },
    set(value) {
      store.commit('CarrierContacts/setParams', value || {});
    }
  });

  const fetch = () => store.dispatch('CarrierContacts/fetch');
  const create = (payload, meta) => store.dispatch('CarrierContacts/create', { payload, meta });
  const remove = (payload, meta) => store.dispatch('CarrierContacts/remove', { payload, meta });
  const update = (payload, meta) => store.dispatch('CarrierContacts/update', { payload, meta });

  if (prefetch) {
    onMounted(() => {
      if (!isLoading.value) {
        fetch();
      }
    });
  }

  return {
    isLoadingRef: isLoading,
    carrierContactsRef: carrierContacts,
    paginationRef: pagination,
    paramsRef: params,
    create,
    remove,
    fetch,
    update
  };
}
