<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Create Warehouse Schedule"
    :scrollable="false"
    width="1000"
    max-width="90%"
    loading-message="Updating User..."
    @close="close"
    @confirm="$refs.warehouseOpHoursTab.handlePostUpdate()">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <!--
        @slot Custom activator for the dialog
        @binding {object} slotProps Object containing {on, attrs}
      -->
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs" large class="mr-2"
        >Create Schedule</primary-button
      >
    </template>
    <template slot="body">
      <warehouse-operating-hours-tab
        @close="close"
        :warehouse="warehouse"
        :should-start-in-edit-mode="true"
        :post-submission-route-name="postSubmissionRouteName"
        ref="warehouseOpHoursTab">
      </warehouse-operating-hours-tab>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit user form
 * @displayName Edit User Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    warehouse: {
      type: Object,
      required: true
    },
    postSubmissionRouteName: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>
