/**
 * Mixin used for custom messages
 * @mixin

 */
export default {
  computed: {
    maxLoadTypeLimitWarning(maxLoadTypes, isWarehouseLoadType) {
      return `Only ${maxLoadTypes} ${isWarehouseLoadType ? 'Warehouse' : 'Org'}
      Load Types can be created per ${isWarehouseLoadType ? 'Warehouse' : 'Organization'}.`;
    }
  }
};
