<template>
  <div class="ma-1">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-column gap-8">
        <yard-view-page-header />

        <v-row no-gutters>
          <v-col col="12" class="d-flex flex-column gap-4">
            <yard-view-page-sub-header />
            <template v-if="selectedWarehouse?.id">
              <yard-view-yard v-if="isGateManagementEnabled" />
              <yard-view-gm-disabled v-else />
            </template>
            <yard-view-no-selected-warehouse v-else />
          </v-col>
        </v-row>
      </v-col>

      <v-spacer></v-spacer>

      <v-navigation-drawer
        app
        right
        permanent
        :temporary="isAssetVisitChatVisible"
        class="yard-navigation-drawer"
        :width="isAssetVisitChatVisible ? 756 : 256"
        v-if="isGateManagementEnabled">
        <transition name="slide-fade" mode="out-in">
          <yard-view-asset-visit-detail-chat
            :asset-visit="assetVisitForChat"
            v-if="isAssetVisitChatVisible" />
          <template v-else>
            <yard-view-spot-detail v-if="selectedSpot?.id" />
            <yard-view-asset-visit-detail v-else-if="selectedAssetVisit?.id" />
            <yard-view-not-assigned-list v-else />
          </template>
        </transition>
      </v-navigation-drawer>
    </v-row>
    <yard-view-loading v-if="isLoading" />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onBeforeUnmount, computed } from 'vue';

import YardViewPageHeader from '@/modules/yard/components/YardViewPageHeader.vue';
import YardViewNotAssignedList from '@/modules/yard/components/YardViewNotAssignedList.vue';
import YardViewYard from '@/modules/yard/components/YardViewYard.vue';

import useYard from '@/modules/yard/composables/useYard';
import YardViewLoading from '@/modules/yard/components/Overlays/YardViewLoading.vue';
import YardViewPageSubHeader from '@/modules/yard/components/YardViewPageSubHeader.vue';
import YardViewSpotDetail from '@/modules/yard/components/Spot//Detail/YardViewSpotDetail.vue';
import { useEventHub, useSockets } from '@/composables';
import YardViewAssetVisitDetail from '@/modules/yard/components/AssetVisit/Detail/YardViewAssetVisitDetail.vue';

export default defineComponent({
  name: 'YardViewPage',
  components: {
    YardViewAssetVisitDetail,
    YardViewSpotDetail,
    YardViewPageSubHeader,
    YardViewLoading,
    YardViewYard,
    YardViewNotAssignedList,
    YardViewPageHeader
  },
  props: {},
  setup() {
    const yardStore = useYard();

    const sockets = useSockets();
    const eventHub = useEventHub();

    onBeforeMount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$on(`${operation}-AssetVisit`, yardStore.handleAssetVisitSubspaceEvent);
        eventHub.$on(`${operation}-AssetVisitEvent`, yardStore.handlerAssetVisitEventSubspaceEvent);
      });
      if (yardStore.selectedWarehouseRef.value?.id) {
        yardStore.fetchAssetVisits();
        yardStore.fetchSpotAreas();
        yardStore.fetchSpots();
      }
    });

    onBeforeUnmount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$off(`${operation}-AssetVisit`, yardStore.handleAssetVisitSubspaceEvent);
        eventHub.$off(
          `${operation}-AssetVisitEvent`,
          yardStore.handlerAssetVisitEventSubspaceEvent
        );
      });
    });

    const assetVisitForChat = computed(() => {
      if (yardStore.selectedAssetVisitRef.value) {
        return yardStore.selectedAssetVisitRef.value;
      }

      if (yardStore.selectedSpotRef.value) {
        return yardStore.selectedSpotRef.value?.spotAssignments.find(s => s.assetVisit?.id)
          ?.assetVisit;
      }

      return {};
    });

    return {
      enableYardView: yardStore.enableYardViewRef,
      selectedWarehouse: yardStore.selectedWarehouseRef,
      isLoading: yardStore.isLoadingSpotsRef,
      selectedSpot: yardStore.selectedSpotRef,
      selectedAssetVisit: yardStore.selectedAssetVisitRef,
      isGateManagementEnabled: yardStore.isGateManagementEnabledForSelectedWarehouse,
      isAssetVisitChatVisible: yardStore.isAssetVisitChatVisibleRef,
      assetVisitForChat
    };
  },
  beforeMount() {
    // TODO: Remove this when the ff enable-yard-view-helios is removed
    // Redirect to appointments page if yard view is disabled
    if (!this.enableYardView) {
      this.$router.replace({ name: 'appointments' });
    }
  }
});
</script>
<style lang="scss">
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.yard-navigation-drawer {
  .card-actions {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-left: 1px;
    background-color: $white;
    z-index: 1000;
  }
  .card-header {
    position: fixed;
    top: 0;
    width: 100%;
    margin-left: 1px;
    background-color: $white;
    z-index: 1000;
  }
  .card-content {
    position: fixed;
    top: 80px;
    bottom: 160px;
    width: 100%;
    margin-left: 1px;
    overflow-y: auto;
  }
}
</style>
