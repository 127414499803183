var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", height: "75%" } },
    [
      _c(
        "v-card-text",
        { staticClass: "px-0" },
        [
          _c("v-data-table", {
            attrs: {
              "hide-default-header": "",
              "hide-default-footer": "",
              "disable-pagination": "",
              "disable-sort": "",
              "show-expand": "",
              "item-key": "id",
              height: "90%",
              "group-by": "sortDate",
              "group-desc": true,
              expanded: _vm.expanded,
              headers: _vm.headers,
              items: _vm.logLines,
              search: _vm.searchStr,
            },
            on: {
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "group.header",
                fn: function ({ items, isOpen, toggle }) {
                  return [
                    _c("th", { attrs: { colspan: 6 } }, [
                      _c(
                        "strong",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [_vm._v("mdi-calendar")]
                          ),
                          _vm._v(" " + _vm._s(items[0].date) + " "),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.time",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.time) + " ")]
                },
              },
              {
                key: "item.value",
                fn: function ({ item }) {
                  return undefined
                },
              },
              {
                key: "item.date",
                fn: function ({ item }) {
                  return undefined
                },
              },
              {
                key: "item.field",
                fn: function ({ item }) {
                  return [
                    _c("strong", [_vm._v(_vm._s(item.field))]),
                    _vm._v(" " + _vm._s(item.action) + " "),
                  ]
                },
              },
              {
                key: "item.user",
                fn: function ({ item }) {
                  return [
                    _c("strong", { staticClass: "text--disabled" }, [
                      _vm._v("by " + _vm._s(item.user)),
                    ]),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "py-4",
                        attrs: { colspan: headers.length },
                      },
                      [
                        _c("pre", {
                          staticClass: "expanded",
                          domProps: { innerHTML: _vm._s(item.value) },
                        }),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }