<template functional>
  <v-tooltip bottom :max-width="props.maxWidth">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :color="props.iconStyleClass"
        dark
        v-bind="attrs"
        v-on="on"
        small
        class="field-label-tooltip"
        :class="{ 'd-block': dense, [zIndexClass]: true }">
        {{ props.iconName }}
      </v-icon>
    </template>
    <span><slot></slot></span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    iconStyleClass: {
      type: String,
      required: false,
      default: 'primary'
    },
    iconName: {
      type: String,
      required: false,
      default: 'mdi-help-circle'
    },
    dense: {
      type: Boolean,
      required: false
    },
    maxWidth: {
      type: Number,
      default: 300
    },
    zIndexClass: {
      type: String,
      required: false,
      default: 'z-2'
    }
  }
};
</script>
