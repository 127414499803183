var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.assetVisit?.id
    ? _c(
        "section",
        [
          _c(
            "v-card",
            { attrs: { elevation: "0", flat: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex flex-row border-bottom" },
                [
                  _c(
                    "h4",
                    { attrs: { "data-testid": "yard-asset-visit-chat-title" } },
                    [_vm._v("Arrival Details")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        "data-testid": "yard-asset-visit-chat-close-button",
                      },
                      on: { click: _vm.closeDetail },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { attrs: { "data-testid": "yard-asset-visit-chat-content" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("yard-view-asset-visit-detail-data", {
                            staticClass: "mt-4",
                            attrs: {
                              "asset-visit": _vm.assetVisit,
                              "show-assign-spot-select": false,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex d-flex-row full-chat-box pt-4",
                          attrs: { cols: "8" },
                        },
                        [
                          _c("drivers-chat-window", {
                            attrs: {
                              "allow-phone-edit": false,
                              "compact-mode": "",
                              warehouse: _vm.selectedWarehouse,
                              "asset-visit": _vm.assetVisit,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", [_c("p", [_vm._v("Invalid asset visit")])])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }