<template>
  <v-expansion-panels multiple :value="expansionValues">
    <slot name="prepended-panel"></slot>
    <v-expansion-panel
      :class="{ disabled: readOnly }"
      v-for="(group, settingGroup) in settings"
      :key="settingGroup">
      <v-expansion-panel-header ripple>
        {{ settingGroup }}
      </v-expansion-panel-header>
      <v-divider></v-divider>
      <v-expansion-panel-content>
        <gate-management-checklist
          :warehouse="entityParentObj"
          v-if="
            settingGroup === novaCore.SettingGroups.GATE_MANAGEMENT
          "></gate-management-checklist>
        <div
          class="setting-panel-content"
          v-for="(setting, key, idx) in group"
          :key="setting.label">
          <div
            class="font-size-small mt-4 mb-6"
            v-if="!idx && getSettingGroupDescriptionMeta(setting)?.description">
            <span class="d-inline-block mr-1">
              {{ insertEntity(getSettingGroupDescriptionMeta(setting)?.description) }}
            </span>
            <a
              target="_blank"
              rel="noopener"
              class="font-size-x-small"
              v-if="getSettingGroupDescriptionMeta(setting)?.link"
              :href="getSettingGroupDescriptionMeta(setting)?.link">
              {{ getSettingGroupDescriptionMeta(setting)?.linkText ?? 'Learn more' }}
            </a>
          </div>
          <v-tooltip
            top
            v-if="novaCore.areRequiredFieldsSet(entityParentObj, setting)"
            :disabled="novaCore.areSettingRequirementsMet(key, setting, localSettings) && !readOnly"
            :color="!readOnly ? 'error' : 'default'">
            <template
              v-slot:activator="{ on, attrs }"
              v-if="!settingKeysToNotDynamicallyRender.includes(key)">
              <div v-bind="attrs" v-on="on" :class="{ 'mt-6': idx }">
                <template v-if="setting.isActive">
                  <div
                    class="setting-input is-relative"
                    :class="{
                      'my-4': !idx && !getSettingGroupDescriptionMeta(setting)?.description
                    }">
                    <label
                      :for="getInputId(setting)"
                      class="font-size-small d-flex v-label"
                      v-if="!novaCore.isSettingHidden(key, setting, localSettings)">
                      <strong class="d-block mr-2">{{ insertEntity(setting.label) }}</strong>
                      <help-icon-tooltip v-if="setting.description" icon-style-class="grey">
                        {{ insertEntity(setting.description) }}
                      </help-icon-tooltip>
                      <help-icon-tooltip
                        v-if="isInherited(key) && isInheritable(key)"
                        icon-name="mdi-alert-circle">
                        {{ getInheritedSettingMessage(key) }}
                      </help-icon-tooltip>
                    </label>
                    <v-text-field
                      :id="getInputId(setting)"
                      class="mt-2 w-95"
                      v-if="
                        setting.inputType === novaCore.SettingInputType.String &&
                        !novaCore.isSettingHidden(key, setting, localSettings)
                      "
                      v-model="localSettings[key]"
                      :disabled="
                        !novaCore.areSettingRequirementsMet(key, setting, localSettings) || readOnly
                      "
                      :placeholder="setting.placeholder ?? setting.defaultValue"
                      :hint="setting.note"
                      outlined
                      clearable
                      :hide-details="!setting.note"
                      persistent-hint
                      persistent-placeholder>
                      <template v-slot:message="{ message, key }">
                        <span v-html="insertEntity(message)"></span>
                      </template>
                    </v-text-field>

                    <v-textarea
                      :id="getInputId(setting)"
                      class="mt-2 w-95"
                      v-if="
                        setting.inputType === novaCore.SettingInputType.LongString &&
                        !novaCore.isSettingHidden(key, setting, localSettings)
                      "
                      v-model="localSettings[key]"
                      :disabled="
                        !novaCore.areSettingRequirementsMet(key, setting, localSettings) || readOnly
                      "
                      :placeholder="setting.placeholder ?? setting.defaultValue"
                      :hint="setting.note"
                      outlined
                      clearable
                      :hide-details="!setting.note"
                      persistent-hint
                      persistent-placeholder>
                      <template v-slot:message="{ message, key }">
                        <span v-html="insertEntity(message)"></span>
                      </template>
                    </v-textarea>

                    <v-select
                      :id="getInputId(setting)"
                      class="mt-2 w-95"
                      v-if="
                        setting.inputType === novaCore.SettingInputType.Bool &&
                        !novaCore.isSettingHidden(key, setting, localSettings)
                      "
                      :disabled="
                        !novaCore.areSettingRequirementsMet(key, setting, localSettings) || readOnly
                      "
                      outlined
                      :hide-details="!setting.note"
                      persistent-hint
                      v-model="localSettings[key]"
                      :items="getBoolOptions(key, setting)"
                      item-text="label"
                      item-value="value"
                      :hint="setting.note">
                      <template v-slot:message="{ message, key }">
                        <span v-html="message"></span>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span v-html="item.label"></span>
                      </template>
                      <template v-slot:item="{ item, on }">
                        <v-list-item v-on="on">
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="item.label"
                              :class="{
                                'font-weight-medium primary--text':
                                  item.value === localSettings[key]
                              }"></v-list-item-title>
                            <v-list-item-subtitle
                              v-if="item.example"
                              class="text--black font-size-x-small">
                              {{ item.example }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>

                    <v-select
                      :id="getInputId(setting)"
                      class="mt-2 w-95"
                      v-if="
                        setting.inputType === novaCore.SettingInputType.DropDown &&
                        !novaCore.isSettingHidden(key, setting, localSettings)
                      "
                      :disabled="
                        !novaCore.areSettingRequirementsMet(key, setting, localSettings) || readOnly
                      "
                      outlined
                      :hide-details="!setting.note"
                      persistent-hint
                      v-model="localSettings[key]"
                      :items="getDropDownOptions(key, setting.dropDownOptions)"
                      item-text="label"
                      item-value="value"
                      :hint="setting.note">
                      <template v-slot:message="{ message, key }">
                        <span v-html="message"></span>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span v-html="item.label"></span>
                      </template>
                      <template v-slot:item="{ item, on }">
                        <v-list-item v-on="on">
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="item.label"
                              :class="{
                                'font-weight-medium primary--text':
                                  item.value === localSettings[key]
                              }"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>

                    <clock-selector
                      class="w-95"
                      :id="getInputId(setting)"
                      :disabled="
                        !novaCore.areSettingRequirementsMet(key, setting, localSettings) || readOnly
                      "
                      outlined
                      :hide-details="!setting.note"
                      persistent-hint
                      :description="setting.note"
                      v-model="localSettings[key]"
                      v-if="
                        setting.inputType === novaCore.SettingInputType.ClockArray &&
                        !novaCore.isSettingHidden(key, setting, localSettings)
                      "></clock-selector>
                    <template v-if="setting.inputType === novaCore.SettingInputType.Document">
                      <div class="d-flex align-center mt-2">
                        <document-upload-button
                          :read-only="readOnly"
                          :crop-shape="setting.documentOptions?.cropShape"
                          :aspectRatio="setting.documentOptions?.aspectRatio"
                          :show-crop-result="setting.documentOptions?.showCropResult"
                          :modal-header-text="setting.documentOptions?.modalHeaderText"
                          allow-cropping
                          :max-size="setting.maxFileSize ?? 1024"
                          :conversion-unit="setting.fileSizeUnit ?? novaCore.FileUnits.MB"
                          :allowed-mime-types="setting.allowedMimeTypes"
                          class="flex-grow-0"
                          v-model="localSettings[key]"></document-upload-button>
                      </div>
                      <div class="v-text-field__details">
                        <div class="v-messages theme--light">
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">{{ setting.description }}</div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <template
                      v-if="
                        setting.inputType === novaCore.SettingInputType.Number &&
                        !novaCore.isSettingHidden(key, setting, localSettings)
                      ">
                      <v-text-field
                        type="number"
                        :id="getInputId(setting)"
                        class="mt-2 w-95"
                        :value="localSettings[key]"
                        :rules="[
                          value =>
                            isInteger(setting.minValue)
                              ? +value > +setting.minValue ||
                                `Value must be greater than ${setting.minValue}`
                              : true
                        ]"
                        @input="localSettings[key] = Number($event)"
                        :disabled="
                          !novaCore.areSettingRequirementsMet(key, setting, localSettings) ||
                          readOnly
                        "
                        :placeholder="setting.placeholder || String(setting.defaultValue)"
                        :hint="setting.note"
                        outlined
                        clearable
                        persistent-hint
                        persistent-placeholder></v-text-field>
                    </template>

                    <!-- ERROR HERE? -->
                    <template
                      v-if="
                        !novaCore.isSettingHidden(key, setting, localSettings) &&
                        novaCore.shouldDisplayError(key, setting, localSettings)
                      ">
                      <v-tooltip right max-width="250">
                        <template v-slot:activator="{ on }">
                          <v-icon color="error" v-on="on" class="setting-error font-size-base">
                            mdi-alert
                          </v-icon>
                        </template>
                        <span>{{ setting.errorMessage }}</span>
                      </v-tooltip>
                    </template>
                  </div>
                </template>
              </div>
            </template>
            <span v-html="makeFieldTooltipText(key, setting)"></span>
          </v-tooltip>
        </div>
        <email-notifications-list
          class="mt-8"
          :entity-settings="localSettings"
          @settingsUpdated="setSettings"
          v-if="
            isVisibleMutedAppointmentNotifications(localSettings) &&
            settingGroup === novaCore.SettingGroups.APPOINTMENT_NOTIFICATIONS
          "></email-notifications-list>
        <grid-tile-config-editor
          :input-enabled="localSettings.useNewGridTiles"
          :input-config="localSettings.gridTileConfig"
          :warehouse="entityParentObj"
          @configUpdated="config => (localSettings.gridTileConfig = config)"
          @enabledUpdated="val => (localSettings.useNewGridTiles = val)"
          v-if="
            settingGroup === novaCore.SettingGroups.CUSTOM_APPPOINTMENTS_GRID
          "></grid-tile-config-editor>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <slot name="append-panel"></slot>
  </v-expansion-panels>
</template>

<script>
import { EntitySettings } from '@satellite/../nova/core';
import { isInteger } from 'lodash';

export default {
  name: 'SettingsExpansionPanels',
  props: {
    entityKey: {
      type: String,
      required: true
    },
    entitySettings: {
      type: Object,
      required: true
    },
    shouldBeExpanded: {
      type: Boolean,
      required: false,
      default: false
    },
    entityParent: {
      type: String,
      required: false,
      default: 'Default'
    },
    entityParentObj: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    settings() {
      return this.novaCore.makeGroupedSettings(this.entityKey);
    },
    isOrgSettings() {
      return this.entityKey === this.novaCore.DatabaseEntities.Org;
    }
  },
  data() {
    return {
      expansionValues: [],
      localSettings: {},
      settingKeysToNotDynamicallyRender: [
        'mutedAppointmentNotifications',
        'useNewGridTiles',
        'gridTileConfig'
      ]
    };
  },
  methods: {
    isInteger,
    makeFieldTooltipText(settingKey, setting) {
      if (this.readOnly) {
        return this.novaCore.getRoleActionError();
      }
      const reqsMet = this.novaCore.areSettingRequirementsMet(
        settingKey,
        setting,
        this.localSettings
      );

      if (reqsMet) {
        return setting.description;
      }

      let lines = ['Field Requirements:'];

      Object.keys(setting.requirements).forEach(reqKey => {
        const v = setting.requirements[reqKey];
        let reqV = v === true ? 'enabled' : v === false ? 'disabled' : `"${v}"`;
        lines.push(`- The "${this.novaCore.Settings[reqKey].label}" setting must be ${reqV}`);
      });

      return lines.join('<br />');
    },
    isInherited(settingKey) {
      return this.isOrgSettings
        ? false
        : !this.novaCore.objPropExists(this.localSettings, settingKey);
    },
    isInheritable(settingKey) {
      return this.isOrgSettings
        ? false
        : this.novaCore.objPropExists(EntitySettings[this.entityParent], settingKey);
    },
    getInheritedSettingMessage(settingKey) {
      let message = '';
      if (this.isInheritable(settingKey)) {
        message = `If no option is selected, this setting will be inherited from the ${this.entityParent}`;
      }
      return message;
    },
    getDefaultValue(settingKey) {
      // GROSS!  Special case "muteAppointmentNotifications" here because the UI reverses the meaning - disabled => enable
      const defaultVal = EntitySettings[this.entityKey][settingKey].defaultValue;
      const valsToFlip = ['muteAppointmentNotifications'];
      return valsToFlip.includes(settingKey) ? !defaultVal : defaultVal;
    },
    setDefaultFlagOnOptions(options, settingKey) {
      options = options.map(option => {
        if (this.isInheritable(settingKey)) {
          return option;
        } else {
          if (option.value === this.getDefaultValue(settingKey)) {
            option.label +=
              ' <span class="d-inline-block ml-2 font-size-x-small grey--text text--darken-1">(default)</span>';
          }
          return option;
        }
      });
      return options;
    },
    getBoolOptions(settingKey, setting) {
      let options = [
        {
          label: setting.booleanDropDownOptions?.whenTrue || 'Yes',
          example: setting.booleanDropDownOptions?.exampleTrue ?? '',
          value: true
        },
        {
          label: setting.booleanDropDownOptions?.whenFalse || 'No',
          example: setting.booleanDropDownOptions?.exampleFalse ?? '',
          value: false
        }
      ];

      options = this.setDefaultFlagOnOptions(this.novaCore.deepClone(options), settingKey);

      if (!this.isInheritable(settingKey)) {
        return options;
      } else {
        return this.novaCore.objPropExists(this.localSettings, settingKey)
          ? this.appendOption(options, this.getUnsetOption(settingKey))
          : options;
      }
    },
    getDropDownOptions(settingKey, options) {
      if (!this.isOrgSettings) {
        options = this.novaCore.objPropExists(this.localSettings, settingKey)
          ? this.appendOption(options, this.getUnsetOption(settingKey))
          : this.removeOption(options, this.getUnsetOption(settingKey));
      }

      options = this.setDefaultFlagOnOptions(this.novaCore.deepClone(options), settingKey);

      return options;
    },
    appendOption(options, newOption) {
      if (!this.novaCore.objExistsInArr(options, newOption)) {
        options.push(newOption);
      }

      return options;
    },
    removeOption(options, newOption) {
      if (this.novaCore.objExistsInArr(options, newOption)) {
        const index = options.findIndex(item => JSON.stringify(item) === JSON.stringify(newOption));
        options.splice(index, 1);
      }

      return options;
    },
    isVisibleMutedAppointmentNotifications(settings) {
      // Note that this is inverted
      return settings?.muteAppointmentNotifications === true;
    },
    setSettings(settings) {
      Object.keys(settings).forEach(key => {
        if (settings[key] === null) {
          delete settings[key];
        }

        if (!this.isVisibleMutedAppointmentNotifications(settings)) {
          delete settings.mutedAppointmentNotifications;
        }
      });
      this.$emit('update-settings', settings);
    },
    getSettingGroupDescriptionMeta(setting) {
      return this.novaCore.settingGroupDescriptions[setting.group];
    },
    insertEntity(str) {
      const entity = this.isOrgSettings ? 'Organization' : this.entityKey;
      return str.replace(/{{entity}}/g, entity);
    },
    getInputId(setting) {
      return this.novaCore.toKebabCase(this.insertEntity(setting.label)).toLowerCase();
    },
    isDefaultValue(setting, value) {
      return setting.defaultValue === value;
    },
    getUnsetOption(settingKey) {
      if (this.isInheritable(settingKey)) {
        return {
          label: `Unset to ${this.entityParent}'s Settings`,
          value: null
        };
      }
    }
  },
  mounted() {
    this.localSettings = this.novaCore.deepClone(this.entitySettings);
    Object.keys(EntitySettings[this.entityKey]).map(key => {
      if (!this.isInheritable(key) && this.localSettings[key] == undefined) {
        this.localSettings[key] = EntitySettings[this.entityKey][key].defaultValue;
      }
    });
    if (this.shouldBeExpanded) {
      this.expansionValues = Array.from({ length: Object.keys(this.settings).length }, (v, i) => i);
    }
  },
  watch: {
    localSettings: {
      handler(settings) {
        this.setSettings(settings);
      },
      deep: true
    }
  }
};
</script>
