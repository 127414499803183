<template>
  <div>
    <span :class="{ 'grey--text': disabled }">{{ item.title }}</span
    ><span
      v-if="item.note"
      class="ml-3 font-size-x-small grey--text"
      :class="{ 'text--darken-3': !disabled }"
      >({{ item.note }})</span
    >
  </div>
</template>

<script>
export default {
  name: 'GridTileDataBlockCheckboxLabel',
  props: {
    item: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
