import { getResponse } from '@satellite/services/serviceUtilities';

export default {
  /**
   *
   * @param params
   * @param options { fields: string[], joins: string[], includeMetaData: boolean }
   * @param requestConfig
   * @returns {Promise<Object|*>}
   */
  async getCustomFormData(params, options = {}, requestConfig = {}) {
    const response = await axios.get(
      'custom-forms/form-data',
      {
        params
      },
      requestConfig
    );
    return getResponse(response, options);
  }
};
