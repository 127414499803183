<template>
  <div>
    <div class="d-flex flex-wrap" v-if="spots.length > 0">
      <template v-if="editable">
        <yard-view-spot-editable
          v-for="spot in spots"
          :key="spot.id"
          :spot="spot"
          class="mr-1 mb-6" />
        <yard-view-spot-add :spot-area="spotArea" v-if="spotArea?.id && !isReadOnly" />
      </template>
      <template v-else>
        <yard-view-spot v-for="spot in spots" :key="spot.id" :spot="spot" class="mr-1 mb-6" />
      </template>
    </div>
    <p v-if="!editable && !spots.length" class="small text--disabled mt-4">
      There are no spots available
    </p>
    <div
      v-if="editable && !spots.length"
      class="d-flex flex-row justify-center align-content-center full-width my-12">
      <yard-view-spot-add
        as-button
        :spot-area="spotArea"
        v-if="editable && spotArea?.id && !isReadOnly" />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import YardViewSpot from '@/modules/yard/components/Spot/Card/YardViewSpot.vue';
import { useRouter } from 'vue-router/composables';
import YardViewSpotAdd from '@/modules/yard/components/Spot/YardViewSpotAdd.vue';
import YardViewSpotEditable from '@/modules/yard/components/Spot/YardViewSpotEditable.vue';
import { useEventHub } from '@/composables';

export default defineComponent({
  name: 'YardViewSpotArea',
  components: { YardViewSpotEditable, YardViewSpotAdd, YardViewSpot },
  props: {
    spotArea: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter();
    const yardStore = useYard();
    const eventHub = useEventHub();

    const showOpenSpots = ref(true);
    const showClosedSpots = ref(true);
    const showOccupiedSpots = ref(true);

    const spots = computed(() => {
      const spotAreaSpots = yardStore.spotsRef.value.filter(s => {
        const sameArea = s.spotAreaId === props.spotArea.id;
        const status = yardStore.getSpotStatus(s);

        if (!showOpenSpots.value && status === yardStore.spotStatus.OPEN) {
          return false;
        }
        if (!showClosedSpots.value && status === yardStore.spotStatus.CLOSED) {
          return false;
        }
        if (!showOccupiedSpots.value && status === yardStore.spotStatus.ASSIGNED) {
          return false;
        }
        return sameArea;
      });

      yardStore.sortSpots(spotAreaSpots);

      return spotAreaSpots;
    });

    const editSpotArea = () =>
      router.push({
        name: 'yard-spot-area-detail',
        params: { warehouseId: selectedWarehouse.value.id, spotAreaId: props.spotArea.id }
      });

    const toggleOpenSpots = areaId => {
      if (areaId === props.spotArea.id) {
        showOpenSpots.value = !showOpenSpots.value;
      }
    };

    const toggleClosedSpots = areaId => {
      if (areaId === props.spotArea.id) {
        showClosedSpots.value = !showClosedSpots.value;
      }
    };

    const toggleOccupiedSpots = areaId => {
      if (areaId === props.spotArea.id) {
        showOccupiedSpots.value = !showOccupiedSpots.value;
      }
    };

    onBeforeMount(() => {
      eventHub.$on('toggleOpenSpots', toggleOpenSpots);
      eventHub.$on('toggleClosedSpots', toggleClosedSpots);
      eventHub.$on('toggleOccupiedSpots', toggleOccupiedSpots);
    });

    onBeforeUnmount(() => {
      eventHub.$off('toggleOpenSpots', toggleOpenSpots);
      eventHub.$off('toggleClosedSpots', toggleClosedSpots);
      eventHub.$off('toggleOccupiedSpots', toggleOccupiedSpots);
    });

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      editSpotArea,
      isReadOnly: yardStore.isReadOnlyRef,
      spots
    };
  }
});
</script>
