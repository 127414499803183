var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.org &&
    _vm.$rolePermissions.canUpdateFavoriteCarriers &&
    !_vm.novaCore.isInternalUser(_vm.$me)
    ? _c("icon-tooltip-button", {
        attrs: {
          tooltip: _vm.isCarrierFavorite(_vm.carrier)
            ? "Unfavorite this carrier"
            : "Favorite this carrier",
          size: "large",
          color: _vm.isCarrierFavorite(_vm.carrier) ? "success" : "grey",
          icon: _vm.isCarrierFavorite(_vm.carrier)
            ? "star-remove"
            : "star-plus-outline",
          "icon-class": "mr-2",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("toggle-favorite", _vm.carrier)
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }