var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-row flex-wrap align-center gap-2" },
    [
      _c("div", { staticClass: "d-flex flex-column gap-2" }, [
        _c("h2", { staticClass: "typography-heading-sm-bold" }, [
          _vm._v(_vm._s(_vm.spotArea.name)),
        ]),
        _c(
          "div",
          { staticClass: "d-flex flex-row align-center gap-1" },
          [
            _c(
              "v-chip",
              {
                attrs: {
                  small: "",
                  title: "Click to toggle open spots for this area",
                  outlined: _vm.openSpotsButtonOutlined,
                  color: "secondary",
                  "data-testid": `yard-main-area-header-open-${_vm.spotArea.id}`,
                },
                on: {
                  click: [
                    function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                    },
                    function ($event) {
                      return _vm.eventHub.$emit(
                        "toggleOpenSpots",
                        _vm.spotArea.id
                      )
                    },
                  ],
                },
              },
              [_vm._v(" " + _vm._s(_vm.spotsOpen) + " open ")]
            ),
            _c(
              "v-chip",
              {
                attrs: {
                  small: "",
                  title: "Click to toggle closed spots for this area",
                  outlined: _vm.closedSpotsButtonOutlined,
                  color: "secondary",
                  "data-testid": `yard-main-area-header-closed-${_vm.spotArea.id}`,
                },
                on: {
                  click: [
                    function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                    },
                    function ($event) {
                      return _vm.eventHub.$emit(
                        "toggleClosedSpots",
                        _vm.spotArea.id
                      )
                    },
                  ],
                },
              },
              [_vm._v(" " + _vm._s(_vm.spotsClosed) + " closed ")]
            ),
            _c(
              "v-chip",
              {
                attrs: {
                  small: "",
                  title: "Click to toggle occupied spots for this area",
                  outlined: _vm.occupiedSpotsButtonOutlined,
                  color: "secondary",
                  "data-testid": `yard-main-area-header-occupied-${_vm.spotArea.id}`,
                },
                on: {
                  click: [
                    function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                    },
                    function ($event) {
                      return _vm.eventHub.$emit(
                        "toggleOccupiedSpots",
                        _vm.spotArea.id
                      )
                    },
                  ],
                },
              },
              [_vm._v(" " + _vm._s(_vm.spotsOccupied) + " occupied ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-btn",
        {
          staticClass: "ml-0 ml-sm-auto",
          attrs: { small: "", text: "" },
          on: { click: _vm.editSpotArea },
        },
        [_vm._v("Edit Area")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }