<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Forgot Password"
    loading-message="Sending reset email..."
    @close="close"
    @confirm="submit">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <secondary-button v-on="slotProps.on" v-bind="slotProps.attrs"
        >Forgot Password?</secondary-button
      >
    </template>
    <template slot="body">
      <v-form ref="form" @keyup.native.enter="submit">
        <v-container class="px-7 pb-7">
          <text-field
            :required="true"
            type="email"
            prepend-icon="mdi-email"
            v-model="email"
            :rules="[...$validator.rules.required('Email'), ...$validator.rules.email]"
            label="Email">
          </text-field>
        </v-container>
        <action-group
          @cancel="close"
          confirm-icon="email"
          confirm-label="Send Reset"
          @confirm="submit">
        </action-group>
      </v-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for password reset form
 * @displayName Password Reset Email Dialog
 */
export default {
  mixins: [dialogMixin],
  data() {
    return {
      email: null
    };
  },
  methods: {
    /**
     * Submit the form
     * @public
     */
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // TODO (API DEPENDENT) discus if user should be notified if email doesn't exist, this would happen on the API side though
      // But if it should, then it also needs to happen here
      this.$store.dispatch('Auth/sendPasswordReset', this.email).finally(() => {
        this.notify('Password reset email sent!');
        this.close();
      });
    }
  }
};
</script>
