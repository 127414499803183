var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass: "z-100 white",
      attrs: { id: "mobile-appointment-details-header" },
    },
    [
      _c(
        "section",
        { staticClass: "d-flex align-center justify-space-between pa-4" },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-row flex-nowrap align-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "nav-arrow-btn mr-4 mobile-icon-btn",
                  attrs: { icon: "", "data-testid": "back-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                1
              ),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("strong", [
                  _vm._v(" " + _vm._s(_vm.formattedAppointmentDate) + " "),
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm.getAppointmentInterval(_vm.appointment))),
                ]),
              ]),
            ],
            1
          ),
          _vm.canCancel &&
          _vm.$rolePermissions.canUpdateAppointment &&
          _vm.canUserAccessWarehouse
            ? _c(
                "v-btn",
                {
                  staticClass: "cancel-btn",
                  attrs: {
                    elevation: "0",
                    disabled:
                      _vm.appointment.status ===
                      _vm.novaCore.AppointmentStatus.Cancelled,
                  },
                  on: { click: _vm.confirmCancel },
                },
                [_c("strong", [_vm._v("Cancel")])]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", {
        staticClass: "status-divider",
        class: [_vm.appointment.status.toLowerCase()],
      }),
      _c("cancel-appointment-dialog-old", {
        ref: "cancelAppointment",
        attrs: {
          "external-activator": true,
          "show-dialog": _vm.showConfirmDialog,
          appointment: _vm.appointment,
        },
        on: {
          close: function ($event) {
            _vm.showConfirmDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }