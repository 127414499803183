var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              {
                ref: "form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "v-container",
                  [
                    _c("text-field", {
                      attrs: { type: "text", label: "Name" },
                      model: {
                        value: _vm.localOrgName,
                        callback: function ($$v) {
                          _vm.localOrgName = $$v
                        },
                        expression: "localOrgName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "confirm-icon": "update",
                "confirm-label": "Update Org",
              },
              on: { cancel: _vm.cancel, confirm: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }