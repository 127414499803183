var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "span",
      {
        staticClass:
          "sub-title d-flex align-center justify-center font-weight-bold",
      },
      [_vm._v(" " + _vm._s(_vm.header) + " ")]
    ),
    _c(
      "div",
      {
        staticClass: "d-flex flex-column align-center justify-center flex-wrap",
      },
      [
        _c(
          "span",
          {
            staticClass:
              "text-h4 text-center py-2 pt-4 font-weight-bold secondary--text",
          },
          [_vm._v(" Check your email account ")]
        ),
        _c("p", { staticClass: "pt-4 text-center" }, [
          !_vm.login
            ? _c("span", { staticClass: "font-weight-bold d-block" }, [
                _vm._v(" You should receive an email from us soon. "),
              ])
            : _vm._e(),
          _vm._v(
            " Please click the link we sent you to complete the creation of your Opendock account. "
          ),
        ]),
        _vm.allowVerificationResend
          ? _c(
              "primary-button",
              {
                staticClass: "mt-6 mb-10",
                attrs: { block: "", "x-large": "", loading: _vm.loading },
                on: { click: _vm.sendVerificationEmail },
              },
              [_vm._v(" Resend Email ")]
            )
          : _vm._e(),
        _vm._m(0),
        _c(
          "v-btn",
          {
            staticClass: "link text-center mt-6 registration-btn",
            attrs: { text: "" },
            on: { click: _vm.backToLogin },
          },
          [_vm._v(" Back to login ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "font-size-small text-center" }, [
      _c("span", { staticClass: "font-weight-bold d-block" }, [
        _vm._v("Didn't get an email?"),
      ]),
      _vm._v(" Check your spam folder or contact us: "),
      _c("a", { attrs: { href: "mailto:support@opendock.com" } }, [
        _vm._v("support@opendock.com"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }