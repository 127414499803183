var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-select", {
    staticClass: "yard-view-area-select",
    attrs: {
      outlined: "",
      disabled: !_vm.selectedWarehouse,
      items: _vm.spotAreas,
      "item-text": "name",
      "item-value": "id",
      placeholder: "Select areas",
      "return-object": false,
      multiple: "",
      dense: "",
      clearable: false,
      "hide-details": "",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "no-data",
          fn: function () {
            return [
              _c(
                "v-list-item",
                [
                  _c("v-list-item-title", [
                    _c("span", { staticClass: "typography-body-sm" }, [
                      _vm._v("No areas available"),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "selection",
          fn: function ({ index }) {
            return [
              index === 0
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.selectedAreas.length) +
                        " of " +
                        _vm._s(_vm.spotAreas.length) +
                        " selected"
                    ),
                  ])
                : _vm._e(),
            ]
          },
        },
        _vm.spotAreas.length > 0
          ? {
              key: "prepend-item",
              fn: function () {
                return [
                  _c(
                    "v-list-item",
                    {
                      attrs: { ripple: "" },
                      on: { click: _vm.toggleSelectAll },
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.selectAllIcon) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "typography-body-md" },
                            [_vm._v("All areas")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mt-2" }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "item",
          fn: function ({ item, attrs, on }) {
            return [
              _c(
                "v-list-item",
                _vm._g(
                  _vm._b(
                    { attrs: { ripple: "" } },
                    "v-list-item",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.getItemIcon(item)) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticClass:
                            "d-flex flex-row align-center typography-body-md",
                        },
                        [
                          _vm._v(" " + _vm._s(item.name) + " "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none typography-button-sm ml-auto",
                              attrs: { text: "", small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectOnly($event, item)
                                },
                              },
                            },
                            [_vm._v(" Only ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.selectedAreas,
      callback: function ($$v) {
        _vm.selectedAreas = $$v
      },
      expression: "selectedAreas",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }