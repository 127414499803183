<script>
import etaMixin from '@satellite/components/mixins/etaMixin';
import statusManagerMixin from '@satellite/components/mixins/statusManagerMixin';

const BTN_HAPPY = 'BTN_HAPPY';
const BTN_NON_HAPPY = 'BTN_NON_HAPPY';
const BTN_UNDO = 'BTN_UNDO';

/**
 * @displayName Status Manager
 */
export default {
  name: 'StatusManager',
  mixins: [etaMixin, statusManagerMixin],
  props: {
    mixpanelEntryPoint: {
      type: String,
      required: false
    },
    warehouseTriggers: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      lastBtnClicked: null
    };
  },
  computed: {
    btnHappy_loading: function () {
      return this.loading && this.lastBtnClicked === BTN_HAPPY;
    },
    btnNonHappy_loading: function () {
      return this.loading && this.lastBtnClicked === BTN_NON_HAPPY;
    },
    btnUndo_loading: function () {
      return this.loading && this.lastBtnClicked === BTN_UNDO;
    }
  },
  methods: {
    btnHappy_onClick(status) {
      if (this.loading) {
        return;
      }
      this.updateStatus(this.getNextHappyPathStatus(status));
      this.lastBtnClicked = BTN_HAPPY;
    },
    btnNonHappy_onClick(status) {
      if (this.loading) {
        return;
      }
      this.updateStatus(this.getNextNonHappyPathStatus(status));
      this.lastBtnClicked = BTN_NON_HAPPY;
    },
    btnUndo_onClick() {
      if (this.loading) {
        return;
      }
      this.undoLatestStatus();
      this.lastBtnClicked = BTN_UNDO;
    }
  }
};
</script>
