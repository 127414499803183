var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "printable-window" }, [
    _c("div", { staticClass: "header-spacing" }),
    _c("article", [
      _c("header", { staticClass: "printable-header" }, [
        _c("img", {
          attrs: { src: "/images/logo_for_print.png", alt: "Opendock Logo" },
        }),
        _c("span", [_vm._v("Printed on " + _vm._s(_vm.currentTimestamp))]),
      ]),
      _c("section", { staticClass: "section-1" }, [
        _c(
          "div",
          { staticClass: "center-row" },
          [
            _c("v-icon", { staticClass: "jumbo-icon" }, [
              _vm._v("mdi-calendar"),
            ]),
            _c(
              "div",
              { staticClass: "column", staticStyle: { "margin-left": "15px" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "label-text",
                    staticStyle: { "margin-bottom": "15px" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isRequested ? "Requested for:" : "Scheduled to:"
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "appointment-time" }, [
                  _vm._v(_vm._s(_vm.formattedAppointmentDateTime)),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "status-details" },
          [
            _vm.noShow
              ? [_vm._m(0)]
              : _vm.isRequested
              ? [_vm._m(1)]
              : [
                  _c("div", [
                    _c("div", { staticClass: "label-text" }, [
                      _vm._v("Arrived at:"),
                    ]),
                    _c("div", { staticClass: "status-date-time" }, [
                      _vm._v(" " + _vm._s(_vm.formattedArrivalTime) + " "),
                    ]),
                    _vm.arrivalTimeDiff
                      ? _c("div", { staticClass: "label-text black" }, [
                          _vm._v(_vm._s(_vm.arrivalTimeDiff)),
                        ])
                      : _vm._e(),
                  ]),
                  _c("img", {
                    staticClass: "arrow",
                    attrs: {
                      src: "/images/arrow-right.png",
                      alt: "Right Arrow",
                    },
                  }),
                  _c("div", [
                    _c("div", { staticClass: "label-text" }, [
                      _vm._v("Completed at:"),
                    ]),
                    _c("div", { staticClass: "status-date-time" }, [
                      _vm._v(" " + _vm._s(_vm.formattedCompletedTime) + " "),
                    ]),
                    _vm.completedTimeDiff
                      ? _c("div", { staticClass: "label-text black" }, [
                          _vm._v(_vm._s(_vm.completedTimeDiff)),
                        ])
                      : _vm._e(),
                  ]),
                ],
          ],
          2
        ),
      ]),
      _c("section", { staticClass: "section-2" }, [
        _c("div", [
          _c("div", { staticClass: "label-text" }, [
            _vm._v("Carrier Contact:"),
          ]),
          _c("div", { staticClass: "data-group" }, [
            _c("span", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.companyName)),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Name:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.user.firstName) +
                  " " +
                  _vm._s(_vm.user.lastName) +
                  " "
              ),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Email:")]),
              _vm._v(" " + _vm._s(_vm.user.email) + " "),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Phone:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.novaCore.formatPhoneNumber(_vm.user.phone)) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "label-text" }, [_vm._v("Warehouse:")]),
          _c("div", { staticClass: "data-group" }, [
            _c("span", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.warehouse.name)),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Address:")]),
              _vm._v(" " + _vm._s(_vm.warehouseAddress) + " "),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Email:")]),
              _vm._v(" " + _vm._s(_vm.warehouse.email) + " "),
            ]),
            _c("span", [
              _c("span", { staticClass: "bold" }, [_vm._v("Phone:")]),
              _vm._v(" " + _vm._s(_vm.warehouse.phone) + " "),
            ]),
          ]),
          _c("div", { staticClass: "label-text" }, [
            _vm._v("Appointment Confirmation #:"),
          ]),
          _c("div", { staticClass: "data-group" }, [
            _c("span", [_vm._v(_vm._s(_vm.appointment.confirmationNumber))]),
          ]),
          _c("div", { staticClass: "label-text" }, [_vm._v("Appointment ID:")]),
          _c("div", { staticClass: "data-group" }, [
            _c("span", [_vm._v(_vm._s(_vm.appointment.id))]),
          ]),
        ]),
        _c(
          "div",
          [
            _c("div", { staticClass: "label-text" }, [_vm._v("Dock name:")]),
            _c("div", { staticClass: "data-group" }, [
              _vm._v(" " + _vm._s(_vm.dock.name) + " "),
            ]),
            _c("div", { staticClass: "label-text" }, [_vm._v("Load type:")]),
            _c("div", { staticClass: "data-group" }, [
              _vm._v(" " + _vm._s(_vm.loadType.name) + " "),
            ]),
            _vm.settings.referenceNumberIsVisible !== false
              ? [
                  _c("div", { staticClass: "label-text" }, [
                    _vm._v(_vm._s(_vm.refNumLabel) + ":"),
                  ]),
                  _c("div", { staticClass: "data-group" }, [
                    _vm._v(" " + _vm._s(_vm.appointment.refNumber) + " "),
                  ]),
                ]
              : _vm._e(),
            _c("div", { staticClass: "label-text" }, [
              _vm._v("Appointment Notes:"),
            ]),
            _c("div", {
              staticClass: "data-group",
              domProps: { innerHTML: _vm._s(_vm.appointmentNotes) },
            }),
            _c("div", { staticClass: "label-text" }, [_vm._v("Tags:")]),
            _c("div", { staticClass: "data-group" }, [
              _vm._v(" " + _vm._s(_vm.appointmentTags) + " "),
            ]),
            _vm._l(Object.values(_vm.customFields), function (field) {
              return [
                _vm.showCustomField(field)
                  ? _c("div", { key: field.name }, [
                      _c("div", { staticClass: "label-text" }, [
                        _vm._v(_vm._s(field.label) + ":"),
                      ]),
                      _c("div", {
                        staticClass: "data-group",
                        staticStyle: { "white-space": "pre-line" },
                        domProps: {
                          innerHTML: _vm._s(_vm.renderCustomField(field)),
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            }),
            _vm._l(_vm.customFormData, function (field) {
              return [
                _vm.showCustomField(field)
                  ? _c("div", { key: field.name }, [
                      _c("div", { staticClass: "label-text" }, [
                        _vm._v(_vm._s(field.label) + ":"),
                      ]),
                      _c("div", {
                        staticClass: "data-group",
                        staticStyle: { "white-space": "pre-line" },
                        domProps: {
                          innerHTML: _vm._s(_vm.renderCustomField(field)),
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._m(2),
    ]),
    _c("div", { staticClass: "page-break" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "label-text" }, [
        _vm._v("Reported as No Show:"),
      ]),
      _c("div", { staticClass: "status-date-time" }, [
        _vm._v("Carrier did not show for appointment."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "status-date-time" }, [
        _vm._v("Appointment waiting for warehouse confirmation"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { padding: "2rem 0", "text-align": "center" } },
      [
        _c(
          "span",
          { staticClass: "bold", staticStyle: { "margin-right": "10px" } },
          [_vm._v("Opendock")]
        ),
        _vm._v(" The ultimate dock scheduling software for warehouses. "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }