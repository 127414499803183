<template>
  <v-tooltip top :disabled="shouldDisableTooltip">
    <template v-slot:activator="{ on }">
      <span
        v-on="on"
        class="cursor-pointer copy-btn-wrapper"
        @click="handleClick($event)"
        :class="{ 'd-inline-block': inline, 'grey--text text--darken-2': disabled }">
        <slot v-if="$slots.default"></slot>
        <v-btn :data-testid="testId" v-else icon small class="ml-2">
          <v-icon x-small>mdi-content-copy</v-icon>
        </v-btn>
      </span>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>
/**
 * An edit button with a pencil icon
 * @displayName Copy content
 */
export default {
  props: {
    /**
     * Content
     */
    content: {
      type: String,
      default: ''
    },
    /**
     * Message displayed on the notifications
     */
    message: {
      type: String,
      default: 'Copied to Clipboard!'
    },
    /**
     * Label
     */
    label: {
      type: String,
      default: 'Click to Copy'
    },
    /**
     * Display inline
     */
    inline: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    shouldDisableTooltip: {
      type: Boolean,
      required: false,
      default: false
    },
    testId: {
      type: String,
      required: false
    }
  },
  methods: {
    handleClick($event) {
      /**
       * Emits click event with target's event
       * @event click
       * @property {object} $event - Target's Event object
       */
      this.$emit('copy', $event);
      navigator.clipboard.writeText(this.content);
      if (this.notify) {
        this.notify(this.message);
      }
    }
  }
};
</script>
