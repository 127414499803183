<template>
  <div>
    <h3 class="mb-3">
      <v-icon small class="mr-2">mdi-truck-outline</v-icon>
      {{ assetVisit.company?.name || assetVisit.companyHint || 'Unknown Company' }}
    </h3>

    <p class="d-flex flex-column">
      <strong class="font-size-x-small">Arrived at</strong>
      <span>
        {{ arrivedTimeFormatted }}
      </span>
    </p>

    <div class="d-flex flex-column font-size-x-small mb-4" v-if="showAssignSpotSelect">
      <strong class="mb-2">Assign To</strong>
      <yard-view-spot-select @input="$emit('assign', $event)" />
    </div>

    <v-divider class="my-4" />

    <asset-visit-form-data
      :asset-visit="assetVisit"
      is-list
      :is-inline="false"
      :show-company-name="false" />

    <v-divider class="mb-4 my-2" />

    <div v-if="appointment" class="font-size-x-small">
      <p class="d-flex flex-column" v-if="appointment?.refNumber">
        <strong>{{ $refNumSettings(selectedWarehouse).displayName }}</strong>
        {{ appointment.refNumber }}
      </p>

      <p class="d-flex flex-column">
        <strong>Scheduled for</strong>

        <span>{{ scheduledFor.date }}</span>
        <span>{{ scheduledFor.start }} - {{ scheduledFor.end }}</span>
      </p>

      <p>
        <strong>Appointment Status</strong>
        <br />
        <v-chip :color="appointment.status.toLowerCase()" dark small pill>
          {{ appointment.status.toUpperCase() }}
        </v-chip>
      </p>

      <p class="d-flex flex-column">
        <strong>Load Type</strong>
        <span>{{ appointment.loadType.name }} ({{ appointment.loadType.direction }})</span>
      </p>
    </div>
    <div v-else>No appointment found</div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { LuxonDateTimeFormats } from '@nova/core';
import YardViewSpotSelect from '@/modules/yard/components/Selects/YardViewSpotSelect.vue';

export default defineComponent({
  name: 'YardViewAssetVisitDetailData',
  components: { YardViewSpotSelect },
  emits: ['assign'],
  props: {
    assetVisit: {
      type: Object,
      required: true
    },
    showAssignSpotSelect: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const yardStore = useYard();
    const appointment = computed(() => {
      return props.assetVisit?.appointment;
    });

    const formatHour = value =>
      yardStore.formatDateTime(
        value,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        LuxonDateTimeFormats.Extended24HrTime
      );

    const scheduledFor = computed(() => {
      return {
        date: yardStore.formatDateTime(
          appointment.value?.start,
          LuxonDateTimeFormats.DateWithAbbreviatedMonth
        ),
        start: formatHour(appointment.value?.start),
        end: formatHour(appointment.value?.end)
      };
    });

    const arrivedTimeFormatted = computed(() => {
      return yardStore.formatDateTime(
        props.assetVisit?.createDateTime,
        LuxonDateTimeFormats.ShortDayDateSlashed12Hour,
        LuxonDateTimeFormats.ShortDayDateSlashed24Hour
      );
    });

    return {
      appointment,
      arrivedTimeFormatted,
      scheduledFor,
      selectedWarehouse: yardStore.selectedWarehouseRef
    };
  }
});
</script>
