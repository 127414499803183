var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showVerification
        ? _c(
            "div",
            { staticClass: "entry-form-container" },
            [
              _c("verify-email", {
                attrs: {
                  header: "Your email is unverified",
                  login: true,
                  email: _vm.creds.email,
                },
                on: {
                  backToLogin: function ($event) {
                    _vm.showVerification = false
                  },
                },
              }),
            ],
            1
          )
        : [
            _c("incorrect-portal-dialog", {
              attrs: {
                email: _vm.portalEmail,
                "show-dialog": _vm.showIncorrectPortalDialog,
                "external-activator": "",
              },
              on: {
                close: function ($event) {
                  _vm.showIncorrectPortalDialog = false
                },
              },
            }),
            _c(
              "div",
              { staticClass: "entry-form-container" },
              [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-tabs",
                      {
                        staticClass: "py-3 non-active-tab-underline",
                        attrs: { grow: "" },
                        model: {
                          value: _vm.tab,
                          callback: function ($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab",
                        },
                      },
                      [
                        _c(
                          "v-tab",
                          { staticClass: "flex-grow-1 font-weight-bold" },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-warehouse"),
                            ]),
                            _vm._v(" WAREHOUSE "),
                          ],
                          1
                        ),
                        _c(
                          "v-tab",
                          { staticClass: "flex-grow-1 font-weight-bold" },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-truck"),
                            ]),
                            _vm._v(" CARRIER "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function ($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab",
                },
              },
              [
                _c(
                  "v-tab-item",
                  [
                    !_vm.loggedIn
                      ? _c(
                          "v-form",
                          {
                            ref: "form",
                            staticClass: "entry-form-container px-2 mt-2",
                            attrs: { "data-testid": "login-form" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.submit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("text-field", {
                              attrs: {
                                required: true,
                                type: "email",
                                dense: "",
                                rules: [
                                  ..._vm.$validator.rules.required("Email"),
                                  ..._vm.$validator.rules.email,
                                ],
                                label: "Email",
                                outlined: "",
                                "persistent-placeholder": true,
                                placeholder: "Type your email ...",
                              },
                              model: {
                                value: _vm.creds.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.creds, "email", $$v)
                                },
                                expression: "creds.email",
                              },
                            }),
                            _c("text-field", {
                              attrs: {
                                required: true,
                                type: "password",
                                dense: "",
                                rules:
                                  _vm.$validator.rules.required("Password"),
                                label: "Password",
                                outlined: "",
                                "persistent-placeholder": true,
                                placeholder: "Type your password ...",
                              },
                              model: {
                                value: _vm.creds.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.creds, "password", $$v)
                                },
                                expression: "creds.password",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-end" },
                              [
                                _c("forgot-password-dialog", {
                                  attrs: {
                                    "external-activator": true,
                                    "show-dialog": _vm.showPasswordDialog,
                                  },
                                  on: {
                                    "show-incorrect-portal":
                                      _vm.displayIncorrectPortalDialog,
                                    close: function ($event) {
                                      _vm.showPasswordDialog = false
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-end mb-8" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "link forgot-password-btn",
                                    attrs: {
                                      ripple: false,
                                      text: "",
                                      "x-small": "",
                                      dense: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showPasswordDialog = true
                                      },
                                    },
                                  },
                                  [_vm._v(" Forgot your password? ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.showVerification
                      ? _c(
                          "div",
                          { staticClass: "entry-form-container pb-8 px-2" },
                          [
                            _c(
                              "primary-button",
                              {
                                staticClass: "login-button",
                                attrs: {
                                  block: "",
                                  "x-large": "",
                                  loading: _vm.loading,
                                },
                                on: { click: _vm.submit },
                              },
                              [_vm._v(" Log in ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("v-tab-item", [
                  _c(
                    "div",
                    { staticClass: "entry-form-container" },
                    [
                      _c("v-container", { attrs: { fluid: "" } }, [
                        _c(
                          "div",
                          { staticClass: "pt-12 px-14 pb-16 mb-6 text-center" },
                          [
                            _vm._v(
                              " To log in as a carrier, please proceed to our Opendock Scheduling Portal "
                            ),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "primary-button full-width d-flex pa-3 text-center v-btn v-size--x-large font-weight-bold",
                            attrs: {
                              href: `${_vm.lunaUrl}/login`,
                              target: "_blank",
                              rel: "noopener",
                            },
                          },
                          [
                            _vm._v(" Go to Scheduling Portal "),
                            _c("v-icon", { staticClass: "black--text ml-2" }, [
                              _vm._v("mdi-arrow-right"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }