var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form", staticClass: "pa-4" },
              [
                _c(
                  "v-row",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "v-col",
                      [
                        _c("text-field", {
                          attrs: {
                            required: true,
                            type: "text",
                            rules:
                              _vm.$validator.rules.required("Load Type Name"),
                            label: "Name",
                          },
                          model: {
                            value: _vm.loadTypeLocal.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.loadTypeLocal, "name", $$v)
                            },
                            expression: "loadTypeLocal.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-select", {
                          attrs: {
                            dense: "",
                            height: "25px",
                            items: _vm.loadTypeDirectionOptions,
                            required: "",
                            rules: _vm.$validator.rules.required(
                              "Load Type Direction"
                            ),
                            label: "Direction",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(" Direction "),
                                  _c("span", { staticClass: "red--text" }, [
                                    _c("strong", [_vm._v("*")]),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.loadTypeLocal.direction,
                            callback: function ($$v) {
                              _vm.$set(_vm.loadTypeLocal, "direction", $$v)
                            },
                            expression: "loadTypeLocal.direction",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-select", {
                          attrs: {
                            dense: "",
                            height: "25px",
                            required: "",
                            rules: _vm.$validator.rules.required("Operation"),
                            items: _vm.loadTypeOperationOptions,
                            label: "Operation",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(" Operation "),
                                  _c("strong", [_vm._v("*")]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.loadTypeLocal.operation,
                            callback: function ($$v) {
                              _vm.$set(_vm.loadTypeLocal, "operation", $$v)
                            },
                            expression: "loadTypeLocal.operation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-select", {
                          attrs: {
                            dense: "",
                            height: "25px",
                            required: "",
                            rules:
                              _vm.$validator.rules.required("Equipment Type"),
                            items: _vm.loadTypeEquipmentTypeOptions,
                            label: "Equipment Type",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(" Equipment Type "),
                                  _c("strong", [_vm._v("*")]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.loadTypeLocal.equipmentType,
                            callback: function ($$v) {
                              _vm.$set(_vm.loadTypeLocal, "equipmentType", $$v)
                            },
                            expression: "loadTypeLocal.equipmentType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-select", {
                          attrs: {
                            dense: "",
                            height: "25px",
                            required: "",
                            rules: _vm.$validator.rules.required(
                              "Transportation Mode"
                            ),
                            items: _vm.loadTypeTransportationModeOptions,
                            label: "Transportation Mode",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(" Transportation Mode "),
                                  _c("strong", [_vm._v("*")]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.loadTypeLocal.transportationMode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.loadTypeLocal,
                                "transportationMode",
                                $$v
                              )
                            },
                            expression: "loadTypeLocal.transportationMode",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("auto-expandable-textarea", {
                          attrs: { label: "Description" },
                          model: {
                            value: _vm.loadTypeLocal.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.loadTypeLocal, "description", $$v)
                            },
                            expression: "loadTypeLocal.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("load-type-duration", {
                  model: {
                    value: _vm.loadTypeLocal.duration_min,
                    callback: function ($$v) {
                      _vm.$set(_vm.loadTypeLocal, "duration_min", $$v)
                    },
                    expression: "loadTypeLocal.duration_min",
                  },
                }),
                _c(
                  "v-row",
                  { staticClass: "mt-6" },
                  [
                    _c("v-col", [
                      _c("h3", { staticClass: "mt-6 black--text" }, [
                        _vm._v("Scheduling Portal Availability"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("carrier-scheduling-toggle", {
                          attrs: {
                            entity: "Load Type",
                            disabled: !_vm.$rolePermissions.canCreateLoadType,
                          },
                          model: {
                            value: _vm.loadTypeLocal.allowCarrierScheduling,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.loadTypeLocal,
                                "allowCarrierScheduling",
                                $$v
                              )
                            },
                            expression: "loadTypeLocal.allowCarrierScheduling",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.omitAdvancedSettings
                  ? [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("h3", { staticClass: "mt-6 black--text" }, [
                              _vm._v("Advanced Settings"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-6" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("settings-expansion-panels", {
                                attrs: {
                                  "entity-key":
                                    _vm.novaCore.DatabaseEntities.LoadType,
                                  "entity-parent": _vm.warehouse
                                    ? _vm.novaCore.DatabaseEntities.Warehouse
                                    : _vm.novaCore.DatabaseEntities.Org,
                                  "should-be-expanded": false,
                                  "entity-settings": _vm.loadTypeSettings,
                                },
                                on: { "update-settings": _vm.updateSettings },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append-panel",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-expansion-panel",
                                            [
                                              _c(
                                                "v-expansion-panel-header",
                                                { attrs: { ripple: "" } },
                                                [_vm._v("Load Type Schedule")]
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-expansion-panel-content",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        { staticClass: "mt-6" },
                                                        [
                                                          _c(
                                                            "time-select-grid",
                                                            {
                                                              ref: "timeSelect",
                                                              attrs: {
                                                                "settings-entity":
                                                                  _vm.warehouse ??
                                                                  _vm.$org,
                                                                "toggle-to-edit": false,
                                                                compact: "",
                                                                "with-copy-from-dock": false,
                                                                "with-copy-from-warehouse": false,
                                                              },
                                                              on: {
                                                                "set-schedule-default":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setToDefault(
                                                                      _vm.loadTypeLocal
                                                                    )
                                                                  },
                                                                "set-schedule-closed":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.clearSchedule(
                                                                      _vm.loadTypeLocal
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .loadTypeLocal
                                                                    .schedule,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.loadTypeLocal,
                                                                      "schedule",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "loadTypeLocal.schedule",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1152661177
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _c(
                      "v-row",
                      { staticClass: "my-4" },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-1 mb-1",
                                attrs: { size: "18", color: "gray darken-3" },
                              },
                              [_vm._v("mdi-information")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "grey--text text--darken-3 font-size-medium",
                              },
                              [
                                _vm._v(
                                  " Other advanced settings can be found on the Load Types page. "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              staticClass: "mb-2",
              attrs: {
                "confirm-icon": null,
                "cancel-icon": null,
                "confirm-label": "Save Load Type",
              },
              on: {
                cancel: function ($event) {
                  return _vm.$emit("close")
                },
                confirm: _vm.promptForSubmission,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }