<template>
  <section class="full-height">
    <v-card v-if="isLoading" class="text-center my-4" width="270">
      <v-card-text>
        <v-progress-circular color="primary" indeterminate size="22"></v-progress-circular>
        <p class="small mt-4 font-weight-bold">Loading Asset Visits data...</p>
      </v-card-text>
    </v-card>
    <v-card elevation="0" v-else>
      <v-card-title>
        <p>
          <v-icon small class="mr-1">mdi-clock-outline</v-icon>
          Waiting assignment
        </p>
        <v-card-subtitle class="pa-1">
          <span class="font-size-small font-weight-normal">
            Drag and drop carriers to assign them a spot.
          </span>

          <div>
            <v-chip small class="mt-2" data-testid="yard-unassigned-list-carrier-count">
              {{ waitingAssetVisits.length }} carrier{{
                waitingAssetVisits.length !== 1 ? 's' : ''
              }}
            </v-chip>
          </div>
        </v-card-subtitle>
      </v-card-title>
      <v-card-text class="pt-4" v-if="waitingAssetVisits.length > 0">
        <div
          v-for="[date, dateAssetVisits] in Object.entries(groupedAssetVisitsByDate)"
          :key="date">
          <p class="font-weight-bold">{{ date }}</p>
          <yard-view-asset-visit
            v-for="assetVisit in dateAssetVisits"
            :asset-visit="assetVisit"
            :key="assetVisit.id" />
        </div>
      </v-card-text>
    </v-card>
    <div v-if="waitingAssetVisits.length === 0" class="no-waiting-asset-visits">
      <div>
        <svg-truck theme="white" class="mb-4" />
        <span class="text-center font-size-x-small">No carriers waiting</span>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

import YardViewAssetVisit from '@/modules/yard/components/AssetVisit/Card/YardViewAssetVisit.vue';
import { LuxonDateTimeFormats } from '@nova/core';
import SvgTruck from '@/components/svgs/SvgTruck.vue';

export default defineComponent({
  name: 'YardViewNotAssignedList',
  components: { SvgTruck, YardViewAssetVisit },
  props: {},
  setup() {
    const yardStore = useYard();

    const groupedAssetVisitsByDate = computed(() => {
      const items = {};

      yardStore.waitingAssetVisitsRef.value?.forEach(assetVisit => {
        const date = yardStore.formatDateTime(
          assetVisit.createDateTime,
          LuxonDateTimeFormats.DateWithAbbreviatedMonth,
          LuxonDateTimeFormats.DateWithAbbreviatedMonth
        );

        items[date] = items[date] || [];
        items[date].push(assetVisit);
      });

      return items;
    });

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      waitingAssetVisits: yardStore.waitingAssetVisitsRef,
      isLoading: yardStore.isLoadingAssetVisitsRef,
      groupedAssetVisitsByDate
    };
  }
});
</script>
<style scoped lang="scss">
.no-waiting-asset-visits {
  display: flex;
  flex-direction: column;
  color: $color-text-secondary;
  align-items: center;
  justify-content: center;
  height: calc(100% - 200px);
}
</style>
