import * as novaCore from '@satellite/../nova/core';

const baseUrl = 'carrier-contacts';
const defaultParams = {
  page: 1,
  limit: 10,
  sort: ['user.email,ASC'],
  join: ['favoriteWarehouses', 'user', 'org', 'user.company'],
  select: [
    'id',
    'emailCCs',
    'favoriteWarehouseIds',
    'favoriteWarehouses',
    'org.id',
    'org.name',
    'user.id',
    'user.email',
    'user.firstName',
    'user.lastName',
    'user.phone',
    'user.companyId',
    'user.company',
    'user.company.id',
    'user.company.name',
    'user.company.scac'
  ]
};
const searchableFields = [
  'user.firstName',
  'user.lastName',
  'user.email',
  'user.phone',
  'user.company.name',
  'user.company.scac'
];
const searchFieldsToSplit = ['user.firstName', 'user.lastName', 'user.company.name', 'emailCCs'];

/**
 * @typedef {Object} CarrierContactListParams
 * @property {Object} [s] - the query object
 * @property {string[]} [join] - fields to join
 * @property {string[]} [select] - fields to select
 * @property {number} limit
 * @private
 */

/**
 * @typedef {Object} CarrierContactOptions
 * @property {boolean} includeMetaData - whether to include metadata in the response
 */

/**
 * @typedef {Object} CarrierContactCompany
 * @property {string} id
 * @property {string} name
 * @property {string} [scac]
 */

/**
 * @typedef {Object} CarrierContactWarehouse
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} CarrierContactCarrier
 * @property {string} id
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} [fullName]
 * @property {string} [phone]
 * @property {string} [companyId]
 * @property {CarrierContactCompany} [company]
 */

/**
 * @typedef {Object} CarrierContactOrg
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef CarrierContact
 * @property {string} id
 * @property {null|string[]} emailCCs
 * @property {null|string[]} favoriteWarehouseIds
 * @property {null|CarrierContactWarehouse[]} favoriteWarehouses
 * @property {CarrierContactOrg} org
 * @property {CarrierContactCarrier} user
 */

/**
 * @typedef _CarrierContactListResponse
 * @property {CarrierContact[]} [data]
 * @property {number} [total]
 * @property {number} [count]
 * @property {number} [page]
 * @property {number} [pageCount]
 */

/**
 * @param {string|string[]} value
 * @param {string[]} defaultValue
 * @param {'list'|'string'} [as='list']
 */
function parseArrayParam(value, defaultValue, as = 'list') {
  const valAsList = Array.isArray(value)
    ? value
    : typeof value === 'string' || value instanceof String
    ? value.split(',')
    : null;
  const valOrDefault = (valAsList?.length || 0) > 1 ? valAsList : defaultValue;
  const valUniq = [...new Set(valOrDefault)];
  return as === 'list' ? valUniq : as === 'string' ? valUniq.join(',') : null;
}

/**
 * @param {object} [params]
 * @returns {CarrierContactListParams}
 */
function prepareParams(params = {}) {
  const prepared = {};
  prepared.page = params.page || defaultParams.page;
  prepared.limit = params.limit || defaultParams.limit;

  // NOTE(2024-07-15): @martini97: `join` and `sort` must be sent as arrays, but `select` must
  // be sent as a comma separated string ¯\_(ツ)_/¯
  // see: https://gid-oss.github.io/dataui-nestjs-crud/requests/#select
  prepared.join = parseArrayParam(params.join, defaultParams.join);
  prepared.sort = parseArrayParam(params.sort, defaultParams.sort);
  prepared.select = parseArrayParam(params.select, defaultParams.select, 'string');
  prepared.s = { ...params.s, $and: [...(params.s?.$and || [])] };

  if (params.searchStr) {
    const $or = novaCore.searchStringToQuery(params.searchStr.trim(), {
      searchableFields,
      searchFieldsToSplit
    });
    prepared.s.$and.push({ $or });
  }

  if (params.favoritesOnly) {
    const $or = [
      { favoriteWarehouseIds: { $isnull: true } },
      { favoriteWarehouseIds: { $ne: [] } }
    ];
    prepared.s.$and.push({ $or });
  }

  return prepared;
}

/**
 * @param {CarrierContact} [carrierContact]
 * @returns {CarrierContact}
 */
function prepareCarrierContact(carrierContact) {
  const { company, ...user } = carrierContact.user || {};
  const fullName = user.fullName || novaCore.namesToFullName(user.firstName, user.lastName);
  const isOrgFavorite = carrierContact.favoriteWarehouseIds === null;
  const isFavorite = isOrgFavorite || (carrierContact.favoriteWarehouseIds?.length || 0) > 0;
  return {
    ...carrierContact,
    company,
    isFavorite,
    isOrgFavorite,
    user: { ...carrierContact.user, fullName }
  };
}

/**
 * @param {import('axios').AxiosResponse<_CarrierContactListResponse>} response
 * @param {CarrierContactOptions} [options]
 */
function prepareListResponse(response, options = {}) {
  const data = response?.data?.data?.map(prepareCarrierContact) || [];
  if (!options.includeMetaData) {
    return data;
  }
  return {
    data,
    total: response?.data?.total || 0,
    count: response?.data?.count || 0,
    page: response?.data?.page || 0,
    pageCount: response?.data?.pageCount || 0
  };
}

export default {
  /**
   * @param {CarrierContactListParams} [params={}]
   * @param {CarrierContactOptions} [options={}]
   */
  async list(params = {}, options = {}) {
    try {
      const response = await axios.get(baseUrl, { params: prepareParams(params) });
      return prepareListResponse(response, options);
    } catch (error) {
      console.error('CarrierContactsService: list failed', error);
      throw error;
    }
  },

  /**
   * @param {object} carrierContact
   * @param {string} carrierContact.userId
   */
  async create(carrierContact) {
    try {
      await axios.post(baseUrl, carrierContact);
    } catch (error) {
      console.error('CarrierContactsService: create failed', error);
      throw error;
    }
  },

  /**
   * @param {CarrierContact} carrierContact
   * @param {boolean} [hardDelete=false]
   */
  async delete(carrierContact, hardDelete = false) {
    const data = hardDelete === true ? { hardDelete: true } : undefined;
    try {
      await axios.delete(`${baseUrl}/${carrierContact.id}`, { data });
    } catch (error) {
      console.error('CarrierContactsService: delete failed', error);
      throw error;
    }
  },

  /**
   * @param {Partial<CarrierContact>} carrierContact
   */
  async update(carrierContact) {
    const { id, ...data } = carrierContact;
    try {
      await axios.patch(`${baseUrl}/${id}`, data);
    } catch (error) {
      console.error('CarrierContactsService: update failed', error);
      throw error;
    }
  },

  /**
   * @param {CarrierContact} carrierContact
   */
  softDelete(carrierContact) {
    return this.delete(carrierContact, false);
  },

  /**
   * @param {CarrierContact} carrierContact
   */
  hardDelete(carrierContact) {
    return this.delete(carrierContact, true);
  }
};
