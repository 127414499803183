var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row align-center full-width" },
    [
      _c(
        "v-autocomplete",
        _vm._b(
          {
            staticClass: "required",
            attrs: {
              "auto-select-first": "",
              dense: "",
              height: "25px",
              required: "",
              value: _vm.value,
              rules: _vm.$validator.rules.required("Country"),
              label: "Country",
              "item-text": "label",
              "item-value": "value",
              items: _vm.options,
            },
            on: { input: (value) => _vm.$emit("input", value) },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _vm._v(" Country "),
                    _vm.tooltipText
                      ? _c(
                          "help-icon-tooltip",
                          { attrs: { iconStyleClass: "grey darken-2" } },
                          [_vm._v(" " + _vm._s(_vm.tooltipText) + " ")]
                        )
                      : _vm._e(),
                    _vm.required
                      ? _c("span", { staticClass: "red--text" }, [
                          _c("strong", [_vm._v("*")]),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "v-autocomplete",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }