<template>
  <v-row>
    <v-col sm="6" lg="6" class="d-flex align-content-center flex-row justify-center">
      <label class="mr-2 pt-2"><strong>Area name:</strong></label>

      <v-text-field
        data-testid="yard-area-form-name-input"
        hide-details
        dense
        required
        outlined
        v-model="spotAreaName"></v-text-field>
    </v-col>

    <v-col cols="6" class="d-flex justify-end">
      <v-btn outlined class="mr-4" @click="back">Nevermind</v-btn>
      <primary-button @click="submit" :disabled="!spotAreaName" data-testid="yard-area-form-save">
        Save Area
      </primary-button>
    </v-col>
  </v-row>
</template>

<script>
import { ref, defineComponent, onBeforeMount } from 'vue';

import useYard from '@/modules/yard/composables/useYard';
import { useRouter } from 'vue-router/composables';
import { useEventHub } from '@/composables';

export default defineComponent({
  name: 'YardViewSpotAreaForm',
  props: {
    spotArea: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();
    const router = useRouter();
    const spotAreaName = ref('');
    const eventHub = useEventHub();

    const defaultWarehouseId = yardStore.defaultWarehouseIdRef.value;
    const defaultSpotAreaId = yardStore.defaultSpotAreaIdRef.value;

    const back = () => {
      if (props.spotArea?.id) {
        eventHub.$emit('yard-spot-area-form-close');
      } else {
        router.push({ name: 'yard-warehouse', params: { warehouseId: defaultWarehouseId } });
      }
    };

    const submit = async () => {
      if (defaultSpotAreaId) {
        yardStore
          .updateSpotArea(defaultSpotAreaId, {
            name: spotAreaName.value
          })
          .then(spotArea => {
            props.spotArea.name = spotArea.name;
            props.spotArea.id = spotArea.id;
            back();
          });
      } else {
        yardStore.createSpotArea({ name: spotAreaName.value }).then(spotArea => {
          props.spotArea.name = spotArea.name;
          props.spotArea.id = spotArea.id;

          router.replace({
            name: 'yard-spot-area-detail',
            params: { warehouseId: defaultWarehouseId, spotAreaId: spotArea.id }
          });
          back();
        });
      }
    };

    onBeforeMount(() => {
      if (props.spotArea?.id) {
        spotAreaName.value = props.spotArea.name;
      }
    });

    return {
      submit,
      back,
      spotAreaName
    };
  }
});
</script>
