<template>
  <form-base :header="header" :hide-required-legend="true" :key="renderKey">
    <template #form>
      <v-form ref="formRef">
        <custom-field-timestamp
          :warehouse="appointment.dock.warehouse"
          v-model="customDateTimeModel"
          hide-details
          hide-timezone
          return-object
          :persistent-placeholder="false"
          outlined
          :display-label="false"
          :field="{ label: 'New ETA', value: customDateTime }"
          :required="true"
          :compact="true"></custom-field-timestamp>
        <auto-expandable-textarea
          v-model="etaReason"
          hide-details="auto"
          outlined
          placeholder="Enter a reason for the ETA update"
          validate-on-blur></auto-expandable-textarea>

        <div class="d-flex mt-4">
          <v-spacer></v-spacer>
          <outline-button
            :disabled="isLoading"
            small
            class="mr-2"
            @click="$emit('cancel', EditableDetailsFields.eta)">
            Nevermind
          </outline-button>
          <primary-button :disabled="isLoading" small text @click="submit">Save</primary-button>
        </div>
      </v-form>
    </template>
  </form-base>
</template>

<script>
import { EditableDetailsFields } from '@/enums';
import { computed, onBeforeMount, ref, watch } from 'vue';
import {
  useAuth,
  useMixpanel,
  useNotify,
  useNovaCore,
  useOrg,
  useRenderKey,
  useSettings,
  useStore,
  useValidator
} from '@/composables';
import { DateTime } from 'luxon';

export default {
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    },
    /**
     * @model
     */
    appointment: {
      type: Object,
      required: true
    }
  },
  emits: ['condition-updated', 'cancel'],
  setup(props, context) {
    const novaCore = useNovaCore();
    const notify = useNotify();
    const store = useStore();
    const { meRef } = useAuth();
    const { renderKey } = useRenderKey();
    const mixpanel = useMixpanel();
    const validator = useValidator();
    const org = useOrg();
    const { settingsGettersRef } = useSettings();
    const formRef = ref(null);
    const isLoading = ref(false);
    const timezone = props.appointment.dock.warehouse.timezone;
    const customDateTimeModel = ref({
      am: '',
      pm: '',
      date: ''
    });
    const etaReason = ref('');

    const customDateTime = computed(() => {
      return DateTime.fromFormat(
        `${customDateTimeModel.value.date} ${customDateTimeModel.value.time}${customDateTimeModel.value.amPm}`,
        'yyyy-MM-dd hh:mma'
      ).setZone(timezone, { keepLocalTime: true });
    });

    const appointmentDateTime = computed(() => {
      return DateTime.fromISO(props.appointment.start).setZone(timezone);
    });

    const appointmentTime = computed(() => {
      return {
        amPm: appointmentDateTime.value.toFormat(novaCore.LuxonDateTimeFormats.AMPM),
        time: appointmentDateTime.value.toFormat(
          novaCore.LuxonDateTimeFormats.Extended12HrTimePadding
        )
      };
    });

    const appointmentEtaDateTime = computed(() => {
      return DateTime.fromISO(props.appointment.eta).setZone(timezone);
    });

    const appointmentEtaTime = computed(() => {
      return {
        amPm: appointmentEtaDateTime.value.toFormat(novaCore.LuxonDateTimeFormats.AMPM),
        time: appointmentEtaDateTime.value.toFormat(
          novaCore.LuxonDateTimeFormats.Extended12HrTimePadding
        )
      };
    });

    const formattedAppointmentDatetime = computed(() => {
      const tz = null;
      return novaCore.formatDateTimeWithMilitarySupport(
        appointmentDateTime.value.toISO(),
        tz,
        novaCore.LuxonDateTimeFormats.ShortMonthDayTimeAMPM,
        settingsGettersRef.value.isMilitaryTimeEnabled(org),
        novaCore.LuxonDateTimeFormats.ShortMonthDayTime24
      );
    });

    function trackMixpanelChange(appointment) {
      const payload = {
        'ETA Tag': appointment.tags.filter(t => t?.startsWith(novaCore.ETA_PREFIX_STRING)),
        'Appointment ETA': appointment.eta,
        'Appointment ID': appointment.id,
        'Appointment Start': appointment.start,
        'Org ID': appointment.orgId,
        'Changed by carrier': meRef.value?.orgName ? 'No' : 'Yes',
        'Warehouse ID': props.appointment.dock.warehouse.id,
        'Warehouse Name': props.appointment.dock.warehouse.name
      };

      if (meRef.value?.orgName) {
        payload['Org Name'] = meRef.value?.orgName;
      }
      mixpanel.track(mixpanel.events.MODULE.APPOINTMENT.ETA_CHANGED, payload);
    }

    async function submit() {
      if (
        !formRef.value.validate() ||
        !customDateTimeModel.value.date ||
        !customDateTimeModel.value.time
      ) {
        return;
      }

      isLoading.value = true;

      try {
        const newDateTimeString = [
          customDateTimeModel.value.date,
          customDateTimeModel.value.time,
          customDateTimeModel.value.amPm
        ].join(' ');
        const newDateTime = DateTime.fromFormat(
          newDateTimeString,
          `${novaCore.LuxonDateTimeFormats.DateDashed} ${novaCore.LuxonDateTimeFormats.Extended12HrTimePadding} ${novaCore.LuxonDateTimeFormats.AMPM}`
        ).setZone(timezone, { keepLocalTime: true });

        const response = await axios.patch(`appointment/${props.appointment.id}/set-eta`, {
          eta: newDateTime,
          reason: etaReason.value
        });

        if (response.data?.data?.id) {
          trackMixpanelChange(response.data.data);
          notify('ETA was set', 'success');
          context.emit('cancel', EditableDetailsFields.eta);
          await store.dispatch('Appointments/triggerSocketEvent', {
            appointment: response.data.data,
            response
          });
        }
      } finally {
        isLoading.value = false;
      }
    }

    onBeforeMount(() => {
      if (props.appointment.eta) {
        customDateTimeModel.value.amPm = appointmentEtaTime.value.amPm;
        customDateTimeModel.value.time = appointmentEtaTime.value.time;
        customDateTimeModel.value.date = appointmentEtaDateTime.value.toFormat(
          novaCore.LuxonDateTimeFormats.DateDashed
        );
      } else {
        const appointmentStartDateTime = DateTime.fromISO(props.appointment.start).setZone(
          props.appointment.dock.warehouse.timezone
        );
        customDateTimeModel.value.amPm = appointmentStartDateTime.toFormat(
          novaCore.LuxonDateTimeFormats.AMPM
        );
        customDateTimeModel.value.time = novaCore.formatDateTimeWithMilitarySupport(
          props.appointment.start,
          timezone,
          novaCore.LuxonDateTimeFormats.Extended12HrTimePadding,
          settingsGettersRef.value.isMilitaryTimeEnabled(org),
          novaCore.LuxonDateTimeFormats.Extended24HrTime
        );
        customDateTimeModel.value.date = appointmentStartDateTime.toFormat(
          novaCore.LuxonDateTimeFormats.DateDashed
        );
      }
    });

    watch(customDateTime, newVal => {
      let newEtaCondition = novaCore.EtaCondition.OnTime;
      if (newVal) {
        newEtaCondition = novaCore.getEtaCondition(
          props.appointment.start,
          newVal,
          props.appointment.dock.warehouse.timezone
        );
      }
      context.emit('condition-updated', newEtaCondition);
    });

    return {
      EditableDetailsFields,
      customDateTime,
      appointmentDateTime,
      appointmentTime,
      appointmentEtaDateTime,
      appointmentEtaTime,
      formattedAppointmentDatetime,
      customDateTimeModel,
      etaReason,
      trackMixpanelChange,
      submit,
      formRef,
      renderKey,
      validator,
      isLoading
    };
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .form-wrapper {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .hoc-loader-content {
    padding-bottom: 0 !important;
  }
}
</style>
