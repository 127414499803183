var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.trigger?.id
    ? _c(
        "dialog-base",
        _vm._b(
          {
            key: _vm.renderKey,
            attrs: {
              "content-class": "details-dialog edit-custom-form-dialog",
              transition: "dialog-bottom-transition",
              "loading-message": "updating appointment",
              "max-width": "100%",
              value: _vm.showDialog,
              header: _vm.flow.name || "Flow",
              width: "1200px",
            },
            on: { close: _vm.close },
            scopedSlots: _vm._u(
              [
                {
                  key: "customHeader",
                  fn: function () {
                    return [
                      _c(
                        "header",
                        { staticClass: "details-header" },
                        [
                          _c("dialog-header", {
                            attrs: {
                              header: _vm.flow.name || "Flow",
                              "hide-close-icon": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-button",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { icon: "", text: "" },
                                          on: { click: _vm.close },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-arrow-left"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1447643821
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "details-content-box",
                        [
                          _c("custom-forms-flow-form", {
                            ref: "customFormFlowForm",
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": false,
                              outlined: "",
                              "display-label": false,
                              trigger: _vm.trigger,
                              "object-id": _vm.objectId,
                              warehouse: _vm.warehouse,
                              "status-change": _vm.statusChange,
                              "stack-actions": _vm.stackActions,
                            },
                            on: {
                              close: _vm.close,
                              update: (data) => _vm.$emit("update", data),
                              create: (data) => _vm.$emit("create", data),
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              708501511
            ),
          },
          "dialog-base",
          _vm.$props,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }