<script>
import { isEmail, isInt, isNumber, isURL, isUUID } from 'class-validator';
import { isPhoneValid, getPasswordValidationErrors } from '@satellite/../nova/core';
import { DateTime } from 'luxon';

export default {
  data() {
    return {
      errors: {},
      rules: {
        required: function (fieldName) {
          return [v => !!v || isNumber(v) || `${fieldName ?? 'This field is'} required`];
        },
        password: [
          v =>
            getPasswordValidationErrors(v).filter(x => x.passed === false).length === 0 ||
            'Password does not meet password requirements'
        ],
        email: [v => !v || isEmail(v) || 'Email must be valid'],
        phone: [v => !v || isPhoneValid(v) || 'Please enter a valid phone number'],
        url: [v => !v || isURL(v) || 'Please enter a valid URL'],
        customField: function (
          fieldName,
          required,
          type,
          minLengthOrValue = null,
          maxLengthOrValue = null
        ) {
          let rules = this[type] || [];
          if (required) {
            rules = [...rules, ...this.required(fieldName)];
          }

          // TODO: Ensure validation actually makes sense for the type
          // so when we change the type of a field, clear the validations instead of only adding new ones
          if (isInt(minLengthOrValue) || isInt(maxLengthOrValue)) {
            // Unfortunately, this validator doesn't have access to novaCore in order to use the customField constant
            if (type === 'str' || type === 'bigstr') {
              rules = [...rules, ...this.optionalLengthBetween(minLengthOrValue, maxLengthOrValue)];
            } else if (type === 'int') {
              rules = [...rules, ...this.optionalNumberBetween(minLengthOrValue, maxLengthOrValue)];
            }
          }

          return rules;
        },
        genericRequired: [v => !!v || 'This field is required'],
        selectAtLeast: function (count = 0, itemName = 'item') {
          return [
            v =>
              (Array.isArray(v) && v.length >= count) ||
              `Please select at least ${count} ${itemName}${count === 1 ? '' : 's'}`
          ];
        },
        selectLimit: function (limit = 0, itemName = 'item') {
          return [
            v =>
              (Array.isArray(v) && v.length <= limit) ||
              `Cannot select more than ${limit} ${itemName}.`
          ];
        },
        eq: function (comp = '', message = null) {
          return [v => v === comp || `${message || `Field must equal ${comp}`}`];
        },
        time: [
          v => {
            let parts = v.split(':');
            let hour = parts[0].substring(0, 2);
            let minute = parts?.[1];
            if (hour < 1 || hour > 12) {
              return 'Invalid Hour';
            }
            if (minute < 0 || minute > 59) {
              return 'Invalid Minute';
            }
            return true;
          }
        ],
        time24: [
          v => {
            let parts = v.split(':');
            let hour = parts[0].substring(0, 2);
            let minute = parts?.[1];
            if (hour < 0 || hour > 23) {
              return 'Invalid Hour';
            }
            if (minute < 0 || minute > 59) {
              return 'Invalid Minute';
            }
            return true;
          }
        ],
        length: function (len) {
          return [v => v.length === len || `Must be ${len} characters`];
        },
        lengthBetween: function (min, max) {
          return [
            v =>
              (v.length >= min && v.length <= max) ||
              `Must be between ${min} and ${max} characters long`
          ];
        },
        optionalLengthBetween: function (min, max) {
          const hasMin = min !== null && min !== undefined && min !== '';
          const hasMax = max !== null && max !== undefined && max !== '';
          if (hasMin && hasMax) {
            return [
              v =>
                !v ||
                (v.length >= min && v.length <= max) ||
                `Must be between ${min} and ${max} characters long`
            ];
          } else if (hasMin && !hasMax) {
            return [v => !v || v.length >= min || `Must be at least ${min} characters long`];
          } else if (!hasMin && hasMax) {
            return [v => !v || v.length <= max || `Must be at most ${max} characters long`];
          }
        },
        minLength: function (min) {
          return [v => v.length >= min || `Must be at least ${min} characters`];
        },
        numberBetween: function (min, max) {
          return [v => (v >= min && v <= max) || `Must be a number between ${min} and ${max}`];
        },
        optionalNumberBetween: function (min, max) {
          const hasMin = min !== null && min !== undefined && min !== '';
          const hasMax = max !== null && max !== undefined && max !== '';
          if (hasMin && hasMax) {
            return [
              v =>
                v === null ||
                v === undefined ||
                v === '' ||
                (v >= min && v <= max) ||
                `Must be a number between ${min} and ${max}`
            ];
          } else if (hasMin && !hasMax) {
            return [
              v =>
                v === null ||
                v === undefined ||
                v === '' ||
                v >= min ||
                `must be a number greater than or equal to ${min}`
            ];
          } else if (!hasMin && hasMax) {
            return [
              v =>
                v === null ||
                v === undefined ||
                v === '' ||
                v <= max ||
                `must be a number less than or equal to ${max}`
            ];
          }
        },
        number: [v => !isNaN(v) || 'Must be a number'],
        uuid: [value => !value || (Boolean(value) && isUUID(value)) || 'Invalid uuid'],
        isCurrentOrFutureDate: [
          v => {
            return !this.novaCore.isPastDate(v) || 'Date must not be in the past';
          }
        ],
        isTimeWithin24Hours: function (
          startTime,
          message = 'must be within 24 hours of start time'
        ) {
          return [
            v => {
              const dateTime = DateTime.fromISO(startTime);
              const val = DateTime.fromISO(v);
              const isWithin24Hours =
                dateTime.minus({ hours: 24 }) <= val && dateTime.plus({ hours: 24 }) >= val;
              return isWithin24Hours || message;
            }
          ];
        }
      }
    };
  },
  methods: {
    get(fieldKey) {
      return this.errors[fieldKey];
    },
    add(error) {
      this.$set(this.errors, error.field, error.msg);
    },
    clear(field = null) {
      if (field) {
        delete this.errors[field];
      } else {
        this.errors = {};
      }
    }
  }
};
</script>
