<template>
  <form-base :header="header">
    <template slot="form">
      <v-form ref="form" v-on:submit.prevent @keyup.native.enter="submit">
        <v-container>
          <text-field
            :required="true"
            type="text"
            v-model="orgName"
            :rules="$validator.rules.required('Org Name')"
            label="Name"></text-field>
        </v-container>
      </v-form>
    </template>
    <template slot="form-actions">
      <action-group
        @cancel="$emit('close')"
        confirm-icon="plus-circle"
        confirm-label="Create"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * Create Org Form
 * @displayName Create Org Form
 */
export default {
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    },
    redirectPath: {
      type: String,
      required: false,
      default: '/warehouses'
    }
  },
  data() {
    return {
      orgName: '',
      saving: false
    };
  },
  methods: {
    /**
     * Submit Form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (this.saving) {
        return;
      }

      if (this.$refs.form.validate()) {
        this.saving = true;
        let org = await this.$store
          .dispatch('Orgs/createOrg', {
            name: this.orgName
          })
          .finally(() => {
            this.saving = false;
          });

        if (org) {
          const entryPoint = this.mixpanel.getEntryPoint(this, [
            { entryPoint: 'Welcome Page', component: 'new-org-welcome' }
          ]);
          this.mixpanel.track(this.mixpanel.events.MODULE.ORG.CREATED, {
            'Org ID': org.id,
            'Org Name': org.name,
            'Entry Point': entryPoint
          });

          //TODO remove this after websocket implementation
          setTimeout(() => {
            this.$store.dispatch('Auth/getMe');
          }, 600);

          this.$emit('close');

          await this.$router.replace(this.redirectPath);
        }
      }
    }
  }
};
</script>
