var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          "no-padding": "",
          tile: "",
          loading: _vm.loading,
          header: "",
          "hide-close-icon": "",
          "loading-message": "",
          "max-width": "100%",
          value: _vm.showDialog,
          width: "980px",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            {
              key: "header-actions",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b({}, "primary-button", slotProps.attrs, false),
                          slotProps.on
                        ),
                        [_vm._v("View Appointment")]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _vm.appointmentWarehouse
                    ? _c("appointment-details-page-mobile", {
                        attrs: {
                          "read-only": !_vm.canUserAccessWarehouse,
                          "appointment-warehouse": _vm.appointmentWarehouse,
                          "parent-appointment": _vm.parentAppointment,
                          appointment: _vm.appointment,
                          "should-edit-status": true,
                          "should-display-cancel-button": false,
                        },
                        on: { close: _vm.close },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }