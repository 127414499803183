<template>
  <div>
    <div v-if="showDeveloperSettings">
      <div class="text-h6">Developer Tools</div>
      <v-container fluid>
        <v-switch v-model="logSubspace" label="Log Subspace Events in Console"> </v-switch>
      </v-container>
    </div>
    <div v-else></div>
    <org-settings-form
      v-if="!notificationConfigKey"
      :read-only="$isOrgReadOnly"></org-settings-form>
    <notification-config-form
      v-else
      :settings="$org.settings"
      :notification-type="notificationConfigKey"></notification-config-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    logSubspace: {
      get() {
        return this.$store.state.Settings.localSettings.logSubspace;
      },
      set(data) {
        return this.$store.commit('Settings/setLogSubspace', data);
      }
    },
    ...mapState('Settings', {
      showDeveloperSettings: state => state.localSettings.showDeveloperSettings
    })
  },
  data() {
    return {
      notificationConfigKey: null
    };
  },
  mounted() {
    if (!this.$store.state.Settings.localSettings.showDeveloperSettings) {
      let context = this;
      document.onkeypress = function (e) {
        const inputs = ['INPUT', 'SELECT', 'BUTTON', 'TEXTAREA'];

        if (
          inputs.includes(document.activeElement?.tagName) ||
          document.activeElement?.className === 'editr--content'
        ) {
          return;
        }

        e = e || window.event;
        if (e.key === 'i') {
          context.$store.commit('Settings/incrementDeveloperSettingsCounter');
        }
      };
    }
    this.$eventHub.$on('set-edit-notification-key', notificationKey => {
      this.notificationConfigKey = notificationKey;
    });
  },
  destroyed() {
    document.onkeypress = null;
  },
  watch: {
    '$store.state.Settings.developerSettingsCounter'(val) {
      if (val >= 10 && val < 15) {
        this.notify(`${15 - val} more clicks for dev settings...`, 'info');
      }
      if (val === 15) {
        this.notify('Dev tools unlocked!');
        this.$store.commit('Settings/setShowDeveloperSettings', true);
      }
    }
  }
};
</script>
