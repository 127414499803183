<template>
  <v-card elevation="5">
    <v-card-title>
      <span class="headline">{{ header }}</span>
    </v-card-title>
    <v-card-text>
      <!-- @slot Card body content -->
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
/**
 * Base card skeleton used for quick builds of new cards
 * @displayName Card Base
 */
export default {
  props: {
    /**
     * Card header
     */
    header: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>
