<template>
  <v-dialog v-model="modal" :width="width" @keydown.escape="closeModal" v-bind="[$props, $attrs]">
    <template #activator="slotProps" v-if="!externalActivator">
      <slot name="activator" v-bind="slotProps" v-if="$scopedSlots.activator" />
    </template>
    <v-card class="dialog-box">
      <v-card-title>
        <span class="headline d-flex">Manage Subscribers</span>
        <v-spacer></v-spacer>
        <v-btn class="pa-3" icon light @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="dialog-body">
        <carrier-contact-card
          :carrier="carrierContact.user"
          :company="carrierContact.company"
          :is-favorite="carrierContact.isFavorite" />
        <div class="subscribers-wrapper">
          <label for="carrier-contact-subscribers">
            Send email notifications for all appointments scheduled with this contact to:
          </label>
          <email-list-field
            ref="emailField"
            :loading="isLoadingRef"
            :value="subscribers"
            @input="emails => (subscribers = emails)"
            id="carrier-contact-subscribers"
            name="carrier-contact-subscribers"
            class="carrier-contact-subscribers"
            data-testid="carrier-contact-subscribers"
            dense
            flat
            hide-details />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal" data-testid="carrier-contact-subscribers-nevermind">
          Nevermind
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
          :disabled="!canSave"
          data-testid="carrier-contact-subscribers-save">
          Save Subscribers
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, ref, computed, nextTick } from 'vue';
import useStore from '@/composables/useStore';
import useModal from '@/composables/useModal';
import useCarrierContacts from '@/composables/useCarrierContacts';

export default defineComponent({
  name: 'CarrierContactSubscriberDialog',
  props: {
    width: { type: Number, default: 550 },
    externalActivator: { type: Boolean, default: false },
    carrierContact: { type: Object, required: true }
  },
  setup(props, context) {
    const store = useStore();
    const emailField = ref();
    const subscribers = ref(props.carrierContact?.emailCCs || []);
    const canSave = computed(() => emailField.value?.errorMessage === null);
    const { modal, closeModal, openModal } = useModal();
    const { update, isLoadingRef } = useCarrierContacts();

    const save = async () => {
      emailField.value?.validate?.();
      await nextTick();

      if (!canSave.value) {
        return;
      }

      try {
        await update(
          { id: props.carrierContact.id, emailCCs: subscribers.value },
          { skipNotify: true }
        );
        store.$app.notify('Subscribers updated successfully', 'success');
        closeModal();
      } catch (error) {
        console.error('Failed to update subscribers', error);
      }
    };

    context.expose({ openModal, closeModal });
    return { modal, closeModal, subscribers, canSave, save, emailField, isLoadingRef };
  }
});
</script>

<style scoped lang="scss">
.dialog-body {
  margin: 40px 0;
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.dialog-actions {
  padding: 16px !important;
}

.subscribers-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 32px 0 48px 0;
}

.carrier-contact-subscribers > :deep(.v-input) {
  border: 1px solid $color-line-border;
  border-radius: 8px;
  margin: 0;

  &:focus,
  &:focus-within {
    border: 2px solid $color-primary-60;
  }

  .v-input__control {
    padding: 5px 12px;

    .v-input__slot {
      .v-select__slot > .v-input__append-inner {
        align-self: center;
      }

      &::before,
      &::after {
        border: none;
      }
    }
  }
}
</style>
