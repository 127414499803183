<template>
  <div>
    <dialog-base
      v-bind="$props"
      :key="renderKey"
      header="Carrier email subscribers"
      :loading="fetching"
      :scrollable="false"
      @close="close"
      @confirm="submit"
      class="mx-auto"
      has-dialog-actions
      @open="retrieveData"
      width="600px">
      <template v-slot:activator="slotProps" v-if="!externalActivator">
        <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      </template>
      <template v-slot:body>
        <v-container>
          <form-base hide-required-legend>
            <template #form>
              <v-form ref="form">
                <v-alert color="disabled" class="py-2 body-2">
                  <strong>Primary contact:</strong>
                  {{ `${carrier.firstName} ${carrier.lastName}` }} - {{ carrier.email }} -
                  {{ carrier.company.name }}
                </v-alert>

                <p class="body-2 grey--text text--darken-3">
                  Carrier email subscribers are a list of email addresses that will receive all
                  appointment notifications when appointments are scheduled with this Carrier
                  Contact at your Org.
                </p>

                <div class="py-4">
                  <email-list-field
                    ref="emailFieldList"
                    icon="mdi-account-multiple"
                    v-model="emailCCs"
                    :disabled="fetching"
                    label="Carrier email subscribers"></email-list-field>
                </div>
                <v-spacer></v-spacer>
              </v-form>
            </template>
            <template #form-actions>
              <action-group
                :loading="submitting"
                :disable-confirm="fetching"
                @cancel="close"
                confirm-label="Save"
                :cancel-icon="null"
                @confirm="submit"></action-group>
            </template>
          </form-base>
        </v-container>
      </template>
    </dialog-base>
  </div>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    carrier: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      emailCCs: [],
      submitting: false,
      fetching: false
    };
  },
  computed: {
    url() {
      return `/carrier/${this.carrier.id}/org-carrier-settings`;
    }
  },
  methods: {
    async submit() {
      if (this.$refs.emailFieldList.validate()) {
        this.submitting = true;
        try {
          await axios.patch(this.url, { emailCCs: this.emailCCs });
          this.$emit('save');
          this.close();
        } finally {
          this.submitting = false;
        }
      }
    },
    async retrieveData() {
      this.emailCCs = [];
      this.fetching = true;
      try {
        const response = await axios.get(this.url);
        this.emailCCs = response?.data?.data?.emailCCs;
      } finally {
        this.fetching = false;
      }
    }
  }
};
</script>
