var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Forgot Password",
          "loading-message": "Sending reset email...",
        },
        on: { close: _vm.close, confirm: _vm.submit },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "secondary-button",
                        _vm._g(
                          _vm._b(
                            {},
                            "secondary-button",
                            slotProps.attrs,
                            false
                          ),
                          slotProps.on
                        ),
                        [_vm._v("Forgot Password?")]
                      ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-container",
                { staticClass: "px-7 pb-7" },
                [
                  _c("text-field", {
                    attrs: {
                      required: true,
                      type: "email",
                      "prepend-icon": "mdi-email",
                      rules: [
                        ..._vm.$validator.rules.required("Email"),
                        ..._vm.$validator.rules.email,
                      ],
                      label: "Email",
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                ],
                1
              ),
              _c("action-group", {
                attrs: {
                  "confirm-icon": "email",
                  "confirm-label": "Send Reset",
                },
                on: { cancel: _vm.close, confirm: _vm.submit },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }