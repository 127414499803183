var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "pa-4 d-flex flex-row align-content-center new-sign-in-warning",
      },
      [
        _c("v-icon", { staticClass: "mr-4", attrs: { size: "24px" } }, [
          _vm._v("mdi-bell"),
        ]),
        _vm._m(0),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "login-wrapper" },
      [
        _c("auth-hero"),
        _c(
          "div",
          {
            staticClass:
              "flex-grow-1 d-flex flex-column align-center justify-center",
          },
          [
            _c(
              "div",
              { staticClass: "full-width" },
              [
                _vm._m(1),
                _c("login-form", {
                  attrs: {
                    "verification-token": _vm.verificationToken,
                    defaultEmail: _vm.defaultEmail,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c("password-reset-dialog", {
          attrs: {
            "show-dialog": _vm.shouldShowResetDialog,
            "reset-token": _vm.resetToken,
            email: _vm.resetEmail,
            "verify-account": _vm.shouldVerifyAccount,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column" }, [
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v("A new sign in page will be released soon"),
      ]),
      _c("span", [
        _vm._v(
          "We are updating the Opendock sign in page with a brand new look and feel"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex align-center justify-center flex-wrap" },
      [
        _c(
          "span",
          {
            staticClass:
              "text-h4 text-center py-2 pt-4 font-weight-bold secondary--text",
          },
          [_vm._v(" Sign in ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }