var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("yard-view-asset-visit-detail-data", {
    attrs: { "asset-visit": _vm.spotAssignment.assetVisit },
    on: { assign: _vm.handleReassignSpotSelection },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }