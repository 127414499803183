import _ from 'lodash';
import { computed } from 'vue';

import { useStore } from '@/composables';
import { isUserWarehouseRestricted } from '@/helpers/permissions';

export default function useCarriers() {
  const store = useStore();
  const meRef = computed(() => _.get(store, 'state.Auth.me'));
  const isWarehouseRestricted = computed(() => isUserWarehouseRestricted(meRef.value));
  return { meRef, isWarehouseRestricted };
}
