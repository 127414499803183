import MixpanelEvents from '@/enums/MixpanelEvents';
import config from '@/config/config';
import mixpanel from 'mixpanel-browser';

/**
 * Mixpanel Mixin
 * @mixin
 * @displayName Mixpanel Mixin
 */
export default {
  data() {
    return {
      mixpanel: {
        track: this.trackMixpanelEvent,
        init: this.initMixpanel,
        events: MixpanelEvents,
        api: mixpanel,
        identify: this.setMixpanelIdentity,
        reset: this.resetMixpanel,
        isInitialized: false,
        getEntryPoint: this.getEntryPoint,
        isDev: config.server_env === 'development'
      }
    };
  },
  methods: {
    /**
     * Track mixpanel event
     * @public
     * @param event
     * @param {object} data
     */
    trackMixpanelEvent(event, data = {}) {
      if (this.mixpanel.isDev) {
        console.log('Tracking Mixpanel Event Debug:\n', { event, data });
      }

      // Silently ignore events if no token is set
      if (!config.mixpanel_token) {
        return;
      }

      try {
        mixpanel.track(event, data);
      } catch (e) {
        console.log('MP Event Error', e);
      }
    },
    /**
     * Initialize Mixpanel
     * @public
     */
    initMixpanel() {
      if (!config.mixpanel_token) {
        return;
      }

      this.mixpanel.isInitialized = true;
      mixpanel.init(config.mixpanel_token, { debug: false });
    },
    /**
     * Set Mixpanel Identity
     * @public
     * @param {User} me (current user)
     */
    setMixpanelIdentity(me) {
      if (!config.mixpanel_token || !me) {
        return;
      }

      mixpanel.identify(me.email);
      mixpanel.people.set({
        $name: `${me.firstName} ${me.lastName}`,
        $email: me.email,
        'Nova User ID': me.id,
        'User Role': me.role,
        'Org ID': me.orgId
      });

      mixpanel.get_group('Org ID', me.orgId).set({
        Name: me.orgName,
        Type: me.orgType,
        'Created At': me.orgCreateDateTime,
        'TC Confirmed At': me.tcConfirmedAt
      });
    },
    /**
     * Reset Mixpanel
     * @public
     */
    resetMixpanel() {
      if (this.mixpanel.isInitialized) {
        mixpanel.reset();
      }
    },
    getEntryPoint(vueInstance, mappedEntryPoints) {
      for (let mappedEntryPoint of mappedEntryPoints) {
        let parent = vueInstance.$parent;
        while (parent) {
          if (parent.$options.name === mappedEntryPoint.component) {
            return mappedEntryPoint.entryPoint;
          }
          parent = parent.$parent;
        }
      }

      return 'Untraceable';
    }
  }
};
