import moment from 'moment';

export default {
  data() {
    return {
      useCurrentWeek: true,
      dateRange: [],
      initialLookbackDays: 6,
      dateRangesReady: false
    };
  },
  methods: {
    initDateRanges() {
      if (this.useCurrentWeek) {
        this.dateRange = [
          moment().startOf('week').format(this.novaCore.DateTimeFormats.DateDashed),
          moment().endOf('week').format(this.novaCore.DateTimeFormats.DateDashed)
        ];
      } else {
        this.dateRange = [
          moment()
            .subtract(this.initialLookbackDays, 'days')
            .format(this.novaCore.DateTimeFormats.DateDashed),
          moment().format(this.novaCore.DateTimeFormats.DateDashed)
        ];
      }
      this.dateRangesReady = true;
    }
  }
};
