import CarriersPage from '@/modules/carriers/CarriersPage';

export default [
  {
    path: '/carriers',
    name: 'carriers',
    component: CarriersPage,
    props: true,
    meta: {
      title: 'My Carrier Contacts',
      requiresAuth: true
    }
  }
];
