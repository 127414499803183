<template>
  <div id="status-timeline-edit-mobile">
    <h3 class="font-weight-bold px-4 pt-6 pb-2 text-center">{{ header }}</h3>
    <form-base :hide-required-legend="true">
      <template #form>
        <v-form ref="form">
          <div class="time-input">
            <div class="mx-4 my-3">
              <date-picker no-title external-trigger v-model="customDate" required>
                <template #external-trigger="{ openDatePicker }">
                  <v-btn plain class="btn-plain white-bg color-inherit" @click="openDatePicker">
                    <v-icon class="mr-2">mdi-calendar</v-icon>
                    {{ datePickerTriggerLabel }}
                  </v-btn>
                </template>
              </date-picker>
            </div>
            <time-entry
              :date="statusDateTime"
              :warehouse="appointment.dock.warehouse"
              compact
              v-model="customTime"></time-entry>
            <div class="d-flex pb-2">
              <div class="menu-container full-width">
                <v-btn-toggle @change="adjustTime" color="primary" class="full-width">
                  <v-btn
                    dense
                    small
                    outlined
                    v-for="(option, index) in timeAdjustmentOptions"
                    class="flex-grow-1 auto-width"
                    :key="`${index}-timeline-option`">
                    {{ option.label }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </div>
        </v-form>
      </template>
      <template #form-actions>
        <primary-button block class="mb-2" @click="updateAppointment">Save</primary-button>
        <v-btn plain block class="black--text" @click="$emit('close')">Nevermind</v-btn>
      </template>
    </form-base>
  </div>
</template>

<script>
import StatusTimelineEditFormBase from '@/modules/appointments/components/StatusTimelineEditFormBase.vue';
import { DateTime } from 'luxon';

export default {
  name: 'StatusTimelineEditFormMobile',
  extends: StatusTimelineEditFormBase,
  props: {
    header: {
      type: String,
      required: false,
      default: 'Edit'
    }
  },
  computed: {
    datePickerTriggerLabel() {
      const date = new Date(this.customDate);
      const dateOptions = { zone: 'utc', locale: this.novaCore.LuxonLocales.enUS };
      return DateTime.fromJSDate(date, dateOptions).toFormat(
        this.novaCore.LuxonDateTimeFormats.DateWithAbbreviatedMonth
      );
    }
  },
  mounted() {
    document.getElementById('mobile-dialog-overlay').classList.add('is-visible');
    this.$nextTick(() => {
      this.$emit('mounted');
    });
  },
  beforeDestroy() {
    document.getElementById('mobile-dialog-overlay').classList.remove('is-visible');
  }
};
</script>
