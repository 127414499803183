import Vue from 'vue';
import Vuetify from 'vuetify';

export const satelliteGlobalThemeColors = {
  primary: '#FFA333',
  secondary: '#0E4059',
  tertiary: '#42535b',
  accent: '#005A87',
  error: '#f24848',
  requested: {
    base: '#CFE3FF',
    darken3: '#005AD8'
  },
  scheduled: {
    base: '#005AD8',
    lighten5: '#E3EFFF',
    lighten4: '#CFE3FF',
    lighten3: '#AACBFA',
    lighten2: '#84B5FA',
    lighten1: '#6298E4',
    darken1: '#0A60D8',
    darken2: '#004099',
    darken3: '#002559',
    darken4: '#001C42'
  },
  arrived: {
    base: '#4D0087',
    lighten5: '#F6EBFF',
    lighten4: '#EBCFFF',
    lighten3: '#D8ACFA',
    lighten2: '#BA8CDE',
    lighten1: '#A173C7',
    darken1: '#762DAD',
    darken2: '#58118F',
    darken3: '#380261',
    darken4: '#23003D'
  },
  inprogress: {
    base: '#FFD34D',
    lighten5: '#FFF8E3',
    lighten4: '#FFF1C7',
    lighten3: '#FFE699',
    lighten2: '#FFD863',
    lighten1: '#FCBE03',
    darken1: '#BB8D00',
    darken2: '#9C7500',
    darken3: '#7A5C00',
    darken4: '#503C00'
  },
  completed: {
    base: '#007531',
    lighten5: '#E4FFEF',
    lighten4: '#C9FFE0',
    lighten3: '#ABFACC',
    lighten2: '#82E0A9',
    lighten1: '#65C28C',
    darken1: '#338254',
    darken2: '#00662B',
    darken3: '#004D20',
    darken4: '#003B18'
  },
  cancelled: {
    base: '#B8B8B8',
    lighten5: '#F5F5F5',
    lighten4: '#E0E0E0',
    lighten3: '#D1D1D1',
    lighten2: '#BDBDBD',
    lighten1: '#A8A8A8',
    darken1: '#737373',
    darken2: '#5E5E5E',
    darken3: '#404040',
    darken4: '#333333'
  },
  noshow: {
    base: '#A50028',
    lighten5: '#FFEDF1',
    lighten4: '#FFD4DE',
    lighten3: '#FDB6C7',
    lighten2: '#FF94AD',
    lighten1: '#F26687',
    darken1: '#AB2041',
    darken2: '#8A0726',
    darken3: '#540014',
    darken4: '#3B000E'
  },
  disabled: '#F5F5F5',
  disabledgrey: '#f5f5f5',
  chalkblue: '#F7FBFC',
  chalkorange: '#fcefdc',
  mobileblue: '#002638',
  mobilebluesecondary: '#015A86'
};

export const satelliteOpts = {
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: satelliteGlobalThemeColors,
      dark: satelliteGlobalThemeColors
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
};

Vue.use(Vuetify);

export default new Vuetify({});
