<template>
  <div class="printable-window">
    <div class="header-spacing"></div>
    <article>
      <h1 class="title">Driver Self Check-in</h1>
      <h4 class="warehouse-name">
        <v-icon x-large class="mr-2">mdi-map-marker</v-icon>
        {{ warehouseName }}
      </h4>

      <div class="instructions">
        <div class="yellow-circle cellphone">
          <v-icon color="black" x-large>mdi-cellphone-screenshot</v-icon>
        </div>
        <div class="qrcode-box">
          <div id="qrcode-template-placeholder"></div>
        </div>
      </div>

      <div class="instructions">
        <div>
          <p class="subtitle">How to check in:</p>
          <div class="black-text">
            <p>
              <v-icon large class="mr-3 yellow-circle">mdi-numeric-1</v-icon>
              Scan the QR code
            </p>
            <br />
            <p>
              <v-icon large class="mr-3 yellow-circle">mdi-numeric-2</v-icon>
              Fill in required information
            </p>
            <br />
            <p>
              <v-icon large class="mr-3 yellow-circle">mdi-numeric-3</v-icon>
              You're checked in!
            </p>
          </div>
        </div>
      </div>

      <div class="footer">
        <img alt="logo" src="/images/logo_hi_res_blue.png" height="48" />
        <strong>Dock scheduling for warehouses</strong>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'GateManagementTemplate',
  props: {
    warehouseName: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped lang="scss">
.printable-window {
  height: 93vh;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  .title {
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    color: #0e4059;
  }

  .subtitle {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #0e4059;
  }

  .warehouse-name {
    margin-top: 10px;
    font-weight: normal !important;
    font-size: 26px;
    text-align: center;
    margin-bottom: 60px;
  }

  .black-text {
    font-size: 26px;
    color: #000000 !important;
  }

  .yellow-circle {
    border-radius: 100px 100px;
    background-color: #ffa333;
    border: 2px solid #000000;
    color: #000000;
  }

  .cellphone {
    position: absolute;
    padding: 8px 8px 8px 8px;
    margin-top: -20px;
    color: #000000 !important;
  }

  .instructions {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  div.qrcode-box {
    border: 12px solid #0e4059;
    border-radius: 16px 16px;
    width: 320px;
    height: 320px;
  }

  .footer {
    width: 100%;
    text-align: center;
    height: 130px;
    color: #0e4059;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  #qrcode-template-placeholder {
    padding-top: 22px;
    padding-left: 12px;
  }
}
</style>
