/**
 * Mixin used for buttons
 * @mixin
 * @displayName Button Mixin
 */
export default {
  methods: {
    /**
     * Handle button click
     * @param {PointerEvent}
     */
    handleClick($event) {
      /**
       * Emits click event with target's PointerEvent
       * @namespace root.events.click
       * @event click
       * @type {PointerEvent}
       * @property {PointerEvent} $event
       */
      if (!this.disabled) {
        this.$emit('click', $event);
      }
    }
  }
};
