<template functional>
  <v-tooltip
    :key="`tooltip-${props.event.id}`"
    :disabled="props.disableTooltip"
    content-class="down-arrow event-tooltip"
    top
    open-delay="500"
    transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" @click.stop="listeners['click'](props.event)">
        <v-event
          :is-light-theme="props.isLightTheme"
          :enable-military-time="props.enableMilitaryTime"
          :is-reserve="props.event.isReserve"
          :event="props.event"
          :view-type="props.viewType"
          :use-new-grid-tiles="props.useNewGridTiles"
          @cancel-reserve="
            (event, e) => $options.methods.cancelReserve(listeners, event, e)
          "></v-event>
      </div>
    </template>
    <event-tooltip v-if="!props.disableTooltip" :event="props.event"></event-tooltip>
  </v-tooltip>
</template>

<script>
import appointmentMixin from '@/components/mixins/appointmentMixin';
/**
 * Calendar Event with tooltip
 * @displayName Event With Tooltip
 */
export default {
  name: 'EventWithTooltip',
  mixins: [appointmentMixin],
  props: {
    /**
     * Calendar Event
     */
    event: {
      type: Object,
      required: true
    },
    disableTooltip: {
      type: Boolean,
      required: false,
      default: false
    },
    useNewGridTiles: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Calendar view type, day, week, month
     */
    viewType: {
      type: String,
      required: false
    },
    enableMilitaryTime: {
      type: Boolean,
      default: false
    },
    // Used to apply different styles to event when light theme is enabled
    isLightTheme: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    cancelReserve(listeners, event, e) {
      listeners['cancel-reserve'](event, e);
    }
  }
};
</script>
