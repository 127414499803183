var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.shouldShowNewMessageDivider(_vm.message, _vm.idx)
      ? _c("div", { staticClass: "text-divider new-message-divider" }, [
          _vm._v(" New "),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        class: [
          "mt-3",
          "message-group",
          _vm.isIncomingMessage(_vm.message) ? "incoming" : "outgoing",
        ],
      },
      [
        _vm._l(_vm.message.files, function (file) {
          return _c(
            "div",
            {
              key: file.url,
              staticClass: "file-message",
              class: _vm.makeMessageClasses(_vm.message, true),
            },
            [
              _vm.isFileImage(file.mimeType)
                ? _c(
                    "div",
                    { staticClass: "pb-1" },
                    [
                      _c("v-img", {
                        attrs: {
                          src: file.url,
                          width: "240",
                          height: "240",
                          "max-height": "240",
                          "max-width": "240",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "non-image-file-container font-size-x-small",
                    },
                    [
                      _c("generic-text-icon", {
                        staticClass: "mr-2",
                        attrs: { text: file.name.split(".").pop() },
                      }),
                      _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "d-block pt-1",
                            attrs: { href: file.url, target: "_blank" },
                          },
                          [_vm._v(_vm._s(file.name))]
                        ),
                        _vm._v(
                          " " + _vm._s(Math.round(file.size / 1000)) + "kb "
                        ),
                      ]),
                    ],
                    1
                  ),
              !_vm.message.content
                ? _c("drivers-chat-message-footer", {
                    attrs: {
                      "asset-visit": _vm.assetVisit,
                      message: _vm.message,
                      status: _vm.messageStatus,
                      warehouse: _vm.warehouse,
                    },
                  })
                : _vm._e(),
              _c(
                "v-icon",
                {
                  staticClass: "download-icon",
                  on: {
                    click: function ($event) {
                      return _vm.openImage(file.url)
                    },
                  },
                },
                [_vm._v("mdi-download")]
              ),
            ],
            1
          )
        }),
        _vm.message.content
          ? _c(
              "div",
              { class: _vm.makeMessageClasses(_vm.message) },
              [
                _c("div", { staticClass: "text-pre-wrap" }, [
                  _vm._v(_vm._s(_vm.message.content)),
                ]),
                _c("drivers-chat-message-footer", {
                  attrs: {
                    "asset-visit": _vm.assetVisit,
                    message: _vm.message,
                    status: _vm.messageStatus,
                    warehouse: _vm.warehouse,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.isOutgoingMessage(_vm.message) &&
        _vm.messageStatus === _vm.novaCore.IMessageStatus.FAILED
          ? _c(
              "div",
              {
                staticClass:
                  "message-status font-size-x-small text--color-neutral-80 status-icon",
              },
              [
                _vm.messageStatus === _vm.novaCore.IMessageStatus.FAILED
                  ? _c("v-icon", { attrs: { "x-small": "", color: "red" } }, [
                      _vm._v(" mdi-alert-circle-outline "),
                    ])
                  : _vm._e(),
                _vm._v(" Failed "),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }