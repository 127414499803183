var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    _vm._b({ attrs: { id: _vm.elementId } }, "v-list", _vm.$attrs, false),
    _vm._l(
      _vm.customFormData,
      function ({ label, value, id, lastChangedDateTime }) {
        return _c("p", { key: id, staticClass: "mb-1" }, [
          _c("strong", { staticClass: "mr-2" }, [_vm._v(_vm._s(label) + ":")]),
          _c("span", { domProps: { innerHTML: value } }),
          _vm.shouldShowDateOnItem
            ? _c("span", { staticClass: "text--disabled ml-2" }, [
                _vm._v(" (" + _vm._s(lastChangedDateTime) + ") "),
              ])
            : _vm._e(),
        ])
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }