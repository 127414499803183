<template>
  <v-dialog :value="spotAssignment" width="600" @close="spotAssignment = null" persistent>
    <v-card v-if="spotAssignment">
      <v-card-title>
        <h4 class="text-capitalize" data-testid="yard-message-driver-dialog-title">
          Message the driver?
        </h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <p>The driver will receive a SMS message notifying the new assigned spot.</p>

        <v-chip class="my-4">
          <v-icon small class="mr-2">mdi-comment-text-outline</v-icon>
          {{ message }}
        </v-chip>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeDialog" data-testid="yard-message-driver-dialog-cancel">
          Skip message
        </v-btn>
        <v-btn color="primary" @click="submit" data-testid="yard-message-driver-dialog-submit">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { useEventHub } from '@/composables';

export default defineComponent({
  name: 'YardViewMessageDriverDialog',

  props: {
    spotAssignment: {
      type: Object
    }
  },
  setup(props) {
    const yardStore = useYard();
    const eventHub = useEventHub();

    const message = computed(
      () =>
        `You've been assigned to ${props.spotAssignment?.spot?.spotArea?.name} - ${props.spotAssignment?.spot?.code}`
    );

    const submit = () => {
      yardStore.messageDriver(props.spotAssignment.assetVisitId, message.value);
      closeDialog();
    };

    const closeDialog = () => {
      eventHub.$emit('close-message-driver-dialog');
    };

    return {
      submit,
      closeDialog,
      message
    };
  }
});
</script>
