var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "status-timeline-edit-mobile" } },
    [
      _c("h3", { staticClass: "font-weight-bold px-4 pt-6 pb-2 text-center" }, [
        _vm._v(_vm._s(_vm.header)),
      ]),
      _c("form-base", {
        attrs: { "hide-required-legend": true },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _c("v-form", { ref: "form" }, [
                  _c(
                    "div",
                    { staticClass: "time-input" },
                    [
                      _c(
                        "div",
                        { staticClass: "mx-4 my-3" },
                        [
                          _c("date-picker", {
                            attrs: {
                              "no-title": "",
                              "external-trigger": "",
                              required: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "external-trigger",
                                fn: function ({ openDatePicker }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "btn-plain white-bg color-inherit",
                                        attrs: { plain: "" },
                                        on: { click: openDatePicker },
                                      },
                                      [
                                        _c("v-icon", { staticClass: "mr-2" }, [
                                          _vm._v("mdi-calendar"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.datePickerTriggerLabel) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.customDate,
                              callback: function ($$v) {
                                _vm.customDate = $$v
                              },
                              expression: "customDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("time-entry", {
                        attrs: {
                          date: _vm.statusDateTime,
                          warehouse: _vm.appointment.dock.warehouse,
                          compact: "",
                        },
                        model: {
                          value: _vm.customTime,
                          callback: function ($$v) {
                            _vm.customTime = $$v
                          },
                          expression: "customTime",
                        },
                      }),
                      _c("div", { staticClass: "d-flex pb-2" }, [
                        _c(
                          "div",
                          { staticClass: "menu-container full-width" },
                          [
                            _c(
                              "v-btn-toggle",
                              {
                                staticClass: "full-width",
                                attrs: { color: "primary" },
                                on: { change: _vm.adjustTime },
                              },
                              _vm._l(
                                _vm.timeAdjustmentOptions,
                                function (option, index) {
                                  return _c(
                                    "v-btn",
                                    {
                                      key: `${index}-timeline-option`,
                                      staticClass: "flex-grow-1 auto-width",
                                      attrs: {
                                        dense: "",
                                        small: "",
                                        outlined: "",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(option.label) + " ")]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "form-actions",
            fn: function () {
              return [
                _c(
                  "primary-button",
                  {
                    staticClass: "mb-2",
                    attrs: { block: "" },
                    on: { click: _vm.updateAppointment },
                  },
                  [_vm._v("Save")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "black--text",
                    attrs: { plain: "", block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v("Nevermind")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }