import { getResponse } from '@satellite/services/serviceUtilities';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async linkUnplannedCheckinToAppointment(params, options = {}, requestConfig = {}) {
    const response = await axios.post(
      '/checkin/link-unplanned-checkin-to-appointment',
      params,
      requestConfig
    );
    return getResponse(response, options);
  }
};
