<template>
  <div
    class="w-100 d-flex flex-column gap-4 justify-center align-center my-10"
    data-testid="yard-no-areas-message">
    <span class="icon-wrapper">
      <v-icon class="icon" size="50" color="#9E9E9E">mdi-warehouse</v-icon>
    </span>
    <div style="max-inline-size: 270px; text-align: center">
      <h3 class="typography-body-md-bold align-center">Don’t have a warehouse yet?</h3>
      <p class="typography-body-sm m-0">
        To create a yard view, you first need to add a warehouse to your Organization.
      </p>
    </div>
    <p class="typography-body-sm m-0">
      Go to the
      <router-link class="typography-body-sm-bold" to="warehouses">Warehouses</router-link>
      page.
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'YardViewNoWarehouse'
});
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  inline-size: 96px;
  block-size: 96px;

  border: 1px solid $color-line-border;
  border-radius: 50%;
  background-color: $color-neutral-20;
}
</style>
