var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("hours-of-operation-form", {
    staticClass: "px-8 pt-6",
    attrs: {
      warehouse: _vm.warehouse,
      "read-only": _vm.$isWarehouseReadOnly(
        _vm.warehouse.id,
        _vm.warehouse.orgId
      ),
    },
    on: { "schedule-updated": _vm.handlePostUpdate },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }