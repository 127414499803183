<template>
  <div
    :data-testid="`yard-spot-unassigned-${spot.code}`"
    :class="{
      'closed-spot': isSpotClosed(spot),
      'open-spot': isSpotOpen(spot)
    }">
    <p class="mb-0">
      <v-icon
        small
        :color="`${isSpotOpen(spot) ? 'success' : 'neutral'} darken-3`"
        :class="`spot-type-${spot.type}`">
        {{ getSpotIcon(spot.type) }}
      </v-icon>
    </p>
    <span class="font-weight-medium text-uppercase spot-status font-size-x-small">
      {{ getSpotStatus(spot) }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewSpotUnassigned',
  props: {
    spot: {
      type: Object,
      required: true
    }
  },
  setup() {
    const yardStore = useYard();
    return {
      getSpotIcon: yardStore.getSpotIcon,
      getSpotStatus: yardStore.getSpotStatus,
      isSpotClosed: yardStore.isSpotClosed,
      isSpotOpen: yardStore.isSpotOpen
    };
  }
});
</script>
<style scoped lang="scss">
.open-spot {
  color: $color-success-100;
}
.closed-spot {
  color: $color-neutral-80;
}
</style>
