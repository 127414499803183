import _ from 'lodash';
import { computed, onMounted } from 'vue';

import * as novaCore from '@satellite/../nova/core';
import { useStore } from '@/composables';

const initialFilters = { searchStr: '', page: 1, limit: 15, sort: 'name,ASC' };
const searchableFields = ['name', 'facilityNumber'];
const searchFieldsToSplit = [];

export default function useWarehouses({ prefetch = false } = {}) {
  const store = useStore();
  const isLoading = computed(() => _.get(store, 'state.Warehouses.isLoading'));
  const warehouses = computed(() => _.get(store, 'state.Warehouses.warehouses'));
  const filters = computed({
    get() {
      return { ...initialFilters, ..._.get(store, 'state.Warehouses.filters') };
    },
    set(value) {
      const searchStr = value?.searchStr || '';
      const $or = novaCore.searchStringToQuery(searchStr || '', {
        searchableFields,
        searchFieldsToSplit
      });
      const filters = { ...initialFilters, searchStr, s: { $or } };
      store.commit('Warehouses/setFilters', filters);
    }
  });

  function fetch() {
    store.dispatch('Warehouses/getWarehouses');
  }

  const updateSearchInput = _.debounce(searchStr => {
    filters.value = { ...filters.value, searchStr };
    fetch();
  }, 350);

  onMounted(() => {
    if (prefetch && !isLoading.value) {
      fetch();
    }
  });

  return {
    isLoadingRef: isLoading,
    warehousesRef: warehouses,
    filtersRef: filters,
    fetch,
    updateSearchInput
  };
}
