<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Edit Expiration Date"
    loading-message="Loading org data..."
    @close="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <template v-slot:body>
      <edit-org-expires-form
        @close="close"
        :org-id="orgId"
        @save="updateOrgExpireDate"></edit-org-expires-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateOrgExpireDate(expiresAt) {
      this.org.expiresAt = expiresAt;
    }
  },
  computed: {
    orgId() {
      return this.org?.id;
    }
  }
};
</script>
