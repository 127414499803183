<template>
  <div class="d-flex align-center time-range-group-item">
    <v-autocomplete
      :readonly="readonly"
      required
      :hide-details="hideDetails"
      :rules="fromRules"
      @change="updateValue"
      dense
      class="time-range-select mt-0"
      :items="fromItems"
      v-model="start"></v-autocomplete>
    <span class="mx-6 font-size-x-small font-weight-bold" :class="{ 'text--disabled': !start }"
      >to</span
    >
    <v-autocomplete
      :readonly="readonly"
      required
      :hide-details="hideDetails"
      :rules="toRules"
      @change="updateValue"
      dense
      class="time-range-select mt-0"
      :items="toItems"
      v-model="end"></v-autocomplete>
    <v-btn
      icon
      small
      class="delete-button"
      v-if="canDelete && !readonly"
      @click="$emit('delete-range', index)">
      <v-icon>mdi-delete-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
/**
 * Component for selecting a range of time
 * @displayName Time Range Selector
 */
export default {
  props: {
    /**
     * index of range in weekday schedule
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * range value
     */
    value: {
      type: Object,
      required: false
    },
    /**
     * length of time each interval should be in minutes
     */
    intervalLength: {
      type: Number,
      required: false,
      default: 30
    },
    /**
     * Array of objects containing items split into specified interval length for use in date range select items
     * [{text: "12:00 am", value: "0:00"}, {text: "12:30 am, value: "0:30"}...]
     */
    dateRangeSelectItems: {
      type: Array,
      required: true
    },
    /**
     * Filtered list of items to use in "from" time select box
     */
    fromItems: {
      type: Array,
      required: true
    },
    /**
     * Filtered list of items to use in "to" time select box
     */
    toItems: {
      type: Array,
      required: true
    },
    /**
     * Is form readonly
     */
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Validation rules for the "From" date
     */
    fromRules: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * Validation rules for the "To" date
     */
    toRules: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * When set true will omit error messages, for example
     */
    hideDetails: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    /**
     * prevent deletion of first range
     * @returns {boolean}
     */
    canDelete() {
      return Boolean(this.index);
    }
  },
  data() {
    return {
      start: '8:00',
      end: '17:00'
    };
  },
  methods: {
    /**
     * v-model
     * @public
     */
    updateValue() {
      /**
       * Emits input event with a time interval
       * @event input
       * @property {object} - { start: '0:00', end: '23:59' }
       */
      this.$emit('input', { start: this.start, end: this.end });
    }
  },
  mounted() {
    this.start = this.value.start;
    this.end = this.value.end;
  },
  watch: {
    value(newVal, oldVal) {
      this.start = this.value.start;
      this.end = this.value.end;
      if (newVal.start !== oldVal.start) {
        let startMoment = newVal?.start
          ? this.novaCore.clockToMoment(newVal.start)
          : this.novaCore.clockToMoment('0:00');
        let endMoment = this.novaCore.clockToMoment('0:00').add('1', 'days');
        if (startMoment.add(this.intervalLength, 'minutes').isSameOrAfter(endMoment)) {
          this.end = '23:59';
        }
      }
    }
  }
};
</script>
