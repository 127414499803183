var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          loading: false,
          header: "Edit details",
          "loading-message": "Updating Appointment Details ...",
          "max-width": "100%",
          "has-dialog-actions": "",
          value: _vm.showDialog,
          width: "900px",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.editAppointmentForm.updateAppointment()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                {},
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v(" Edit Appointment ")]
                          ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("edit-appointment-form", {
                    ref: "editAppointmentForm",
                    attrs: {
                      appointment: _vm.appointment,
                      id: "edit-appointment-form",
                    },
                    on: { close: _vm.close },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }