<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :scrollable="false"
    has-dialog-actions
    loading-message="Creating Warehouse Group"
    @close="close"
    @confirm="$refs.createLoadTypeForm.submit()"
    max-width="900px"
    width="900px">
    <template v-slot:activator="slotProps">
      <v-tooltip top :disabled="false">
        <template v-slot:activator="{ on, attrs }">
          <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
          <div v-else v-on="on" v-bind="attrs">
            <primary-button
              :disabled="false"
              :before-icon="beforeIcon"
              v-on="slotProps.on"
              v-bind="slotProps.attrs"
              large
              class="mr-2">
              Create Warehouse Group
            </primary-button>
          </div>
        </template>
        <span>{{ getDisabledCreationMessage }}</span>
      </v-tooltip>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    showForm: {
      type: Boolean,
      required: true
    }
  },
  computed: {},
  methods: {
    async close() {
      this.showForm = false;
    }
  }
};
</script>
