var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("feature-flag", {
    attrs: { name: "enable-warehouse-groups-page" },
    scopedSlots: _vm._u([
      {
        key: "enabled",
        fn: function () {
          return [
            _c("div", { staticClass: "py-3" }, [_c("warehouse-group-list")], 1),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }