<template>
  <ul class="legend-items mt-4">
    <li class="legend-item">
      <v-icon small>{{ getSpotIcon(SpotType.DOCKING) }}</v-icon>
      <span class="caption">Docking spot</span>
    </li>
    <li class="legend-item">
      <v-icon small>{{ getSpotIcon(SpotType.PARKING) }}</v-icon>
      <span class="caption">Parking spot</span>
    </li>
    <li class="legend-item">
      <v-icon small :class="getLoadTypeDirectionColor(LoadTypeDirection.Inbound)">
        mdi-arrow-up-thick
      </v-icon>
      <span class="caption">Inbound</span>
    </li>
    <li class="legend-item">
      <v-icon small :class="getLoadTypeDirectionColor(LoadTypeDirection.Outbound)">
        mdi-arrow-down-thick
      </v-icon>
      <span class="caption">Outbound</span>
    </li>
    <li class="legend-item">
      <span class="appointment-icon"></span>
      <span class="caption">Appointment</span>
    </li>
    <li class="legend-item">
      <span class="no-appointment-icon"></span>
      <span class="caption">No appointment</span>
    </li>
  </ul>
</template>
<script>
import { defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { LoadTypeDirection, SpotType } from '@nova/core';

export default defineComponent({
  name: 'YardViewLegend',
  props: {},
  setup() {
    const yardStore = useYard();
    return {
      getSpotIcon: yardStore.getSpotIcon,
      getLoadTypeDirectionColor: yardStore.getLoadTypeDirectionColor,
      SpotType,
      LoadTypeDirection
    };
  }
});
</script>

<style scoped lang="scss">
.legend-items {
  display: flex;
  flex-flow: row wrap;
  gap: $spacing-3;

  justify-content: flex-end;

  margin: 0;
  padding: 0;
}

.legend-item {
  display: flex;
  flex-direction: row;
  gap: $spacing-1;

  align-items: center;
}
.v-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  inline-size: 16px;
  block-size: 16px;

  font-size: 10px;
}

.appointment-icon,
.no-appointment-icon {
  display: inline-block;
  inline-size: 10px;
  block-size: 10px;
  margin: 3px;
  border-radius: 2px;

  border-width: 1px;
  border-style: solid;
}

.appointment-icon {
  background-color: $color-accent-20;
  border-color: $color-accent-60;
}

.no-appointment-icon {
  background-color: $color-neutral-20;
  border-color: $color-neutral-60;
}
</style>
