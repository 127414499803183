<template>
  <v-btn class="pa-3" v-bind="$attrs" v-on="$listeners" role="button">
    <v-icon v-if="beforeIcon" left class="ml-0">mdi-{{ beforeIcon }}</v-icon>
    <!-- @slot Button Content -->
    <slot></slot>
    <v-icon v-if="afterIcon" right class="mr-0">mdi-{{ afterIcon }}</v-icon>
  </v-btn>
</template>

<script>
/**
 * Base button skeleton used for quick builds of new buttons.
 * Props and attributes are passed through to base button if used to build a new button
 * @displayName Button Base
 */
export default {
  props: {
    /**
     * MDI icon to display before button content slot - omit the 'mdi-' from icon tag
     */
    beforeIcon: {
      type: String,
      required: false
    },
    /**
     * MDI icon to display after button content slot - omit the 'mdi-' from icon tag
     */
    afterIcon: {
      type: String,
      required: false
    }
  }
};
</script>
