<template>
  <v-dialog v-model="warehouseDialog.shouldShow" width="800px">
    <v-card class="dialog-box">
      <dialog-header v-if="header" :header="header" @close="$emit('close')"></dialog-header>

      <v-card-text class="px-7 pb-6 pt-2">
        <p v-if="label">{{ label }}</p>
        <div v-for="warehouse in warehouses" :key="warehouse.id" class="mb-2">
          <router-link :to="`warehouses/${warehouse.id}/docks`">
            {{ warehouse.name }}
          </router-link>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: false
    },
    warehouses: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      required: false
    },
    warehouseDialog: {
      type: Object,
      required: true
    }
  }
};
</script>
