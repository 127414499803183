var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "My Account",
          "loading-message": "Updating Account Information",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.myAccountForm.submit()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    "before-icon": "account",
                                    large: "",
                                  },
                                },
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v(" My Account ")]
                          ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("my-account-form", {
                    ref: "myAccountForm",
                    on: {
                      close: _vm.close,
                      saved: (data) => _vm.$emit("saved", data),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }