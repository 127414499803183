<template>
  <svg height="150" width="180">
    <g>
      <circle cx="90" cy="80" r="60" fill="#E5F1F7" />
      <foreignObject class="node" x="60" y="50" width="60" height="60">
        <v-icon class="icon" color="secondary lighten-4">mdi-warehouse</v-icon>
      </foreignObject>
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SvgSpotArea'
});
</script>
