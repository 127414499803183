const VIEW_TYPES = {
  schedule: 'schedule',
  closedDates: 'closedDates'
};

import scheduleMixin from './scheduleMixin';

export default {
  mixins: [scheduleMixin],
  data() {
    return {
      view: VIEW_TYPES.schedule,
      viewTypes: VIEW_TYPES
    };
  },
  methods: {
    /**
     * Copies the warehouse schedule to the dock schedule editor
     * @public
     */
    // copy the schedule from the warehouse, but keep the closedIntervals from the dock
    copyWarehouseScheduleToDock() {
      this.copyWarehouseSchedule(this.internalDock, this.warehouse, () => {
        this.mixpanel.track(this.mixpanel.events.ACTION.DOCK_SCHEDULE_COPIED, {
          dockId: this.internalDock.id,
          schedule: this.internalDock.schedule
        });
      });
    },
    /**
     * Copies the provided dock schedule to the dock schedule editor
     * @public
     */
    // copy the schedule from the warehouse, but keep the closedIntervals from the dock
    copyDockScheduleToDock(dockScheduleToClone) {
      this.copyDockSchedule(this.internalDock, dockScheduleToClone);
    },
    // set the schedule, but keep the closedIntervals from the dock
    setScheduleToDefault() {
      this.setToDefault(this.internalDock);
    },
    // clear the schedule, but keep the closedIntervals from the dock
    clearDockSchedule() {
      this.clearSchedule(this.internalDock);
    }
  }
};
