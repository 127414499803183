<!--
  -- this component is using the new Vue Composition API, this is a step towards our goal
  -- of migrating helios into Vue3. To learn more about it, check:
  -- https://vuejs.org/api/composition-api-setup
  -- https://vuejs.org/guide/extras/composition-api-faq.html
-->
<template>
  <div class="wrapper">
    <label
      for="carrier-search-autocomplete"
      class="label"
      data-testid="carrier-search-autocomplete-label">
      <slot name="label">
        <v-icon small>mdi-plus</v-icon>
        Add carrier contacts to your Org’s list
      </slot>
    </label>
    <v-autocomplete
      flat
      solo
      dense
      no-filter
      hide-details
      return-object
      id="carrier-search-autocomplete"
      class="autocomplete"
      data-testid="carrier-search-autocomplete"
      placeholder="Search by carrier name, email or company"
      item-value="id"
      item-text="name"
      @update:search-input="carriers.updateSearchInput"
      :value="null"
      :loading="carriers.isLoadingRef.value"
      :items="carriers.carriersRef.value">
      <template #item="{ item, attrs, on }">
        <carrier-contact-search-item v-bind="attrs" v-on="on" :carrier="item" />
      </template>
      <template #append-item>
        <create-carrier-dialog @save="handleSave" entry-point="Carrier Contacts Select List">
          <template #activator="{ on, attrs }">
            <v-list-item v-on="on" v-bind="attrs" class="list-item clickable">
              <span class="mr-1">Didn’t find your carrier contact?</span>
              <span class="list-item-action">Create new</span>
            </v-list-item>
          </template>
        </create-carrier-dialog>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import useCarriers from '@/composables/useCarriers';
import useCarrierContacts from '@/composables/useCarrierContacts';

export default defineComponent({
  name: 'CarrierContactSearch',
  props: {},
  setup() {
    const carriers = useCarriers({ prefetch: true });
    const carrierContacts = useCarrierContacts();

    const handleSave = async () => {
      await Promise.all([carriers.fetch(), carrierContacts.fetch()]);
    };

    return { carriers, handleSave };
  }
});
</script>

<style scoped lang="scss">
// TODO(2024-06-19): the styling breaks if we remove these imports,
// we need to investigate why they are not being imported by default.
@import '~vuetify/src/styles/styles.sass';
@import '@satellite/styles/main';
@import '@/styles/partials/variables';

.wrapper {
  flex: 1;
}

.label {
  color: $color-neutral-90;
  font-weight: 600;
  font-size: 16px;

  .v-icon {
    color: $color-neutral-90 !important;
  }
}

.autocomplete {
  background: $color-background-secondary;
  border-radius: 4px;
  border: 1px solid $color-line-border;
  font-size: 16px;
  height: 50px;
  line-height: 22px;
  max-height: 50px;
  padding: 14px 12px !important;
  margin-top: 8px !important;

  :deep(.v-input__control) {
    min-height: 22px !important;
    height: 22px !important;
  }

  :deep(.v-input__slot) {
    padding: 0 !important;
  }

  :deep(.v-select__slot > input) {
    padding: 0 !important;
  }

  &:focus,
  &:focus-within {
    border-color: $color-primary-60;
  }
}

.list-item {
  padding: 12px 16px;

  &:hover {
    background: #f6f6f6;
  }

  &-action {
    color: $accent;
    font-weight: 600;
  }
}
</style>
