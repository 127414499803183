var render = function render(_c, _vm) {
  return _c(
    "v-tooltip",
    {
      key: `tooltip-${_vm.props.event.id}`,
      attrs: {
        disabled: _vm.props.disableTooltip,
        "content-class": "down-arrow event-tooltip",
        top: "",
        "open-delay": "500",
        transition: "scale-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b(
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.listeners["click"](_vm.props.event)
                        },
                      },
                    },
                    "div",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-event", {
                    attrs: {
                      "is-light-theme": _vm.props.isLightTheme,
                      "enable-military-time": _vm.props.enableMilitaryTime,
                      "is-reserve": _vm.props.event.isReserve,
                      event: _vm.props.event,
                      "view-type": _vm.props.viewType,
                      "use-new-grid-tiles": _vm.props.useNewGridTiles,
                    },
                    on: {
                      "cancel-reserve": (event, e) =>
                        _vm.$options.methods.cancelReserve(
                          _vm.listeners,
                          event,
                          e
                        ),
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      !_vm.props.disableTooltip
        ? _c("event-tooltip", { attrs: { event: _vm.props.event } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }