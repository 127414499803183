var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-autocomplete",
        _vm._b(
          {
            class: { required: _vm.field.required },
            attrs: {
              height: "20px",
              value: _vm.field.value,
              rules: _vm.validationRules,
              items: _vm.field.dropDownValues,
              dense: "",
              clearable: "",
              "open-on-clear": "",
            },
            on: { input: (value) => _vm.$emit("input", value || "") },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [_vm._v("Choose an option")]
                },
                proxy: true,
              },
            ]),
          },
          "v-autocomplete",
          [_vm.$props, _vm.$attrs],
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }