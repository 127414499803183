var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "search-field", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("text-field", {
                        staticClass: "mb-5",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Search",
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.filters.searchStr,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "searchStr", $$v)
                          },
                          expression: "filters.searchStr",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c("create-warehouse-dialog", {
                staticClass: "action-button",
                attrs: {
                  "is-first-warehouse": _vm.$warehouses.length === 0,
                  "waiting-on-external-resource": _vm.loadingBillingData,
                },
              }),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "warehouse-list",
            attrs: {
              headers: _vm.rowHeaders,
              items: _vm.$warehouses,
              loading: _vm.loading,
              "server-items-length": _vm.$warehousePagination.total,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "footer-props": _vm.footerProps,
              options: _vm.options,
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:options": function ($event) {
                _vm.options = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.org.name",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "copy-content",
                        { attrs: { content: item.org.name } },
                        [_vm._v(_vm._s(item.org.name))]
                      ),
                    ]
                  },
                },
                {
                  key: "item.name",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "warehouses.details.general",
                              params: { warehouseId: item.id },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                      _c("copy-content", { attrs: { content: item.name } }),
                    ]
                  },
                },
                {
                  key: "item.street",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "copy-content",
                        {
                          attrs: {
                            content: _vm.novaCore.formatFullAddress(item),
                            message: "Copied full address to clipboard",
                            label: "Click to copy warehouse address",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.street) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.city",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "copy-content",
                        {
                          attrs: {
                            content: _vm.novaCore.formatFullAddress(item),
                            message: "Copied full address to clipboard",
                            label: "Click to copy warehouse address",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.city) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.state",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "copy-content",
                        {
                          attrs: {
                            content: _vm.novaCore.formatFullAddress(item),
                            message: "Copied full address to clipboard",
                            label: "Click to copy warehouse address",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.state) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.country",
                  fn: function ({ item }) {
                    return [
                      _c("country-flag", {
                        attrs: { "country-code": item.country },
                      }),
                    ]
                  },
                },
                {
                  key: "item.zip",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "copy-content",
                        {
                          attrs: {
                            content: _vm.novaCore.formatFullAddress(item),
                            message: "Copied full address to clipboard",
                            label: "Click to copy warehouse address",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.zip) + " ")]
                      ),
                    ]
                  },
                },
                _vm.areActionsDisplayed
                  ? {
                      key: "item.actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "row-actions" },
                            [
                              _vm.novaCore.canUserAccessWarehouse(
                                _vm.$me,
                                item.id,
                                item.orgId
                              )
                                ? _c("icon-tooltip-button", {
                                    attrs: {
                                      id: item.id,
                                      tooltip: "Edit warehouse",
                                      size: "large",
                                      "icon-class": "mr-2",
                                      color: "secondary",
                                      icon: "pencil",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "warehouses.details.general",
                                          params: { warehouseId: item.id },
                                        })
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.$rolePermissions.canCreateAppointment &&
                              _vm.novaCore.canUserAccessWarehouse(
                                _vm.$me,
                                item.id,
                                item.orgId
                              )
                                ? _c("create-appointment-dialog", {
                                    attrs: {
                                      context: { selectedWarehouse: item },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (slotProps) {
                                            return [
                                              _c(
                                                "icon-tooltip-button",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.novaCore.canUserAccessWarehouse(
                                                            _vm.$me,
                                                            item.id,
                                                            item.orgId
                                                          ),
                                                        tooltip:
                                                          !_vm.novaCore.canUserAccessWarehouse(
                                                            _vm.$me,
                                                            item.id,
                                                            item.orgId
                                                          )
                                                            ? _vm.novaCore.getRoleActionError()
                                                            : "Schedule an appointment at this warehouse",
                                                        size: "large",
                                                        "icon-class": "mr-2",
                                                        color: "primary",
                                                        icon: "calendar",
                                                      },
                                                    },
                                                    "icon-tooltip-button",
                                                    slotProps.attrs,
                                                    false
                                                  ),
                                                  slotProps.on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm._e(),
                              _vm.novaCore.canUserAccessWarehouse(
                                _vm.$me,
                                item.id,
                                item.orgId
                              )
                                ? _c("audit-log-entity-icon-button", {
                                    attrs: {
                                      item: item,
                                      timezone: item.timezone,
                                      "entity-type":
                                        _vm.novaCore.DatabaseEntities.Warehouse,
                                    },
                                  })
                                : _vm._e(),
                              _vm.$rolePermissions.canDeleteWarehouse
                                ? _c("icon-tooltip-button", {
                                    attrs: {
                                      size: "large",
                                      tooltip: "Delete Warehouse",
                                      delete: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showDeleteWarehouseConfirmation(
                                          item
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm.showDeleteConfirm
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.showDeleteConfirm,
              "is-manual-mode": "",
              persistent: "",
              icon: "mdi-delete-forever",
              "delete-confirmation": "",
              "show-delete-warning": !_vm.deleteMessageHtml,
              title: "Delete this Warehouse?",
              width: 650,
              loading: _vm.loading,
              "confirmation-input-text": _vm.deleteConfirmationInputText,
              "entity-name": _vm.deletingWarehouse.name,
              "button-true-text": "YES, DELETE",
              "button-true-color": "error",
              color: "error",
            },
            on: { result: _vm.handleDeleteResult },
            scopedSlots: _vm._u(
              [
                {
                  key: "message",
                  fn: function () {
                    return [
                      _vm.loading
                        ? _c("v-skeleton-loader", {
                            attrs: { type: "list-item-three-line" },
                          })
                        : _vm.deleteMessageHtml
                        ? _c(
                            "div",
                            [
                              _c("h2", { staticClass: "text-center my-3" }, [
                                _vm._v(
                                  "Appointments and other data will also be deleted!"
                                ),
                              ]),
                              _c(
                                "v-alert",
                                {
                                  staticClass: "my-7",
                                  attrs: {
                                    text: "",
                                    prominent: "",
                                    border: "left",
                                    color: "red",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "red" },
                                    },
                                    [_vm._v("mdi-alert")]
                                  ),
                                  _c("span", {
                                    staticClass: "text--primary",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.deleteMessageHtml),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              455133713
            ),
          })
        : _vm._e(),
      _c("create-warehouse-dialog", {
        attrs: {
          "is-first-warehouse": _vm.$warehouses.length === 0,
          "external-activator": true,
          "show-dialog": _vm.showCreateWarehouseDialog,
        },
        on: {
          close: function ($event) {
            _vm.showCreateWarehouseDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }