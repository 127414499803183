import ICustomField from '@satellite/../nova/core';

export default {
  props: {
    field: {
      type: ICustomField,
      required: true
    },
    fieldIcon: {
      type: String,
      required: false,
      default: ''
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    persistentPlaceholder: {
      type: Boolean,
      required: false,
      default: true
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    validationRules() {
      return this.$validator.rules.customField(
        this.field.label,
        this.field.required,
        this.field.type,
        this.field.minLengthOrValue,
        this.field.maxLengthOrValue
      );
    }
  },
  data() {
    return {
      value: this.field.value,
      dropDownValues: this.field.dropDownValues,
      disallowedNumberCharacters: ['e', '', '+', '_', '=', '.']
    };
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    }
  }
};
