<template>
  <div
    class="w-100 d-flex flex-column gap-4 justify-center align-center my-10"
    data-testid="yard-no-gm-enabled-message">
    <span class="icon-wrapper">
      <v-icon class="icon" size="50" color="#9E9E9E">mdi-domain-off</v-icon>
    </span>
    <div style="max-inline-size: 270px; text-align: center">
      <h3 class="typography-body-md-bold align-center mb-8">
        Gate Management is not enabled for this warehouse!
      </h3>
      <p class="typography-body-sm m-0">
        Upgrade to
        <strong>Gate Management</strong>
        to gain access to Live Yard View.
      </p>
    </div>
    <p class="typography-body-sm m-0">
      Interested?
      <a
        href="https://community.loadsmart.com/hc/en-us/articles/19326940613011-Carrier-Self-Check-In"
        target="_blank">
        Learn how it works
      </a>
      or contact us to
      <a href="https://lp.opendock.com/gate-management" target="_blank">request a trial.</a>
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'YardViewGmDisabled'
});
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  inline-size: 96px;
  block-size: 96px;

  border: 1px solid $color-line-border;
  border-radius: 50%;
  background-color: $color-neutral-20;
}
</style>
