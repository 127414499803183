<template>
  <div>
    <div class="dock-schedule-header d-flex align-center mb-5">
      <strong class="mr-2">Interval:</strong>
      <v-btn-toggle mandatory v-model="interval" class="secondary-button-group interval-buttons">
        <v-tooltip top :disabled="!disable30MinOption">
          <template v-slot:activator="{ on }">
            <div class="d-inline" v-on="on">
              <v-btn
                class="rounded-tr-0 rounded-br-0"
                elevation="0"
                small
                :value="30"
                :disabled="disable30MinOption"
                >30 MIN
              </v-btn>
            </div>
          </template>
          <span>Schedule has at least one 15 or 20 min interval. 30 minute view is disabled</span>
        </v-tooltip>
        <v-tooltip top :disabled="!disable20MinOption">
          <template v-slot:activator="{ on }">
            <div class="d-inline" v-on="on">
              <v-btn tile elevation="0" small :value="20" :disabled="disable20MinOption"
                >20 MIN
              </v-btn>
            </div>
          </template>
          <span>Schedule has at least one 15 or 30 min interval. 20 minute view is disabled</span>
        </v-tooltip>

        <v-tooltip top :disabled="!disable15MinOption">
          <template v-slot:activator="{ on }">
            <div class="d-inline" v-on="on">
              <v-btn tile elevation="0" small :value="15" :disabled="disable15MinOption"
                >15 MIN
              </v-btn>
            </div>
          </template>
          <span>Schedule has at least one 20 min interval. 15 minute view is disabled</span>
        </v-tooltip>
      </v-btn-toggle>

      <v-spacer></v-spacer>

      <v-menu
        content-class="left-aligned-options"
        offset-y
        :internal-activator="true"
        :close-on-click="true">
        <template v-slot:activator="{ on, attrs }">
          <secondary-button
            ref="scheduleoptionsbtn"
            after-icon="chevron-down"
            v-bind="attrs"
            v-on="on"
            append>
            <span class="text-truncate"> Set Schedule As </span>
          </secondary-button>
        </template>
        <v-list>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button-base
                x-small
                text
                plain
                block
                before-icon="hours-24"
                class="white"
                v-bind="attrs"
                v-on="on"
                @click="$emit('set-schedule-default')">
                Open 24/7
              </button-base>
            </template>
            <span>Set schedule back to default (open 24/7)</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button-base
                text
                plain
                block
                x-small
                before-icon="cancel"
                class="white"
                v-bind="attrs"
                v-on="on"
                @click="$emit('set-schedule-closed')">
                Close 24/7
              </button-base>
            </template>
            <span>Close Schedule</span>
          </v-tooltip>

          <div class="option-label mb-1" v-if="withCopyFromDock || withCopyFromWarehouse">
            Clone from
          </div>

          <v-tooltip top v-if="withCopyFromWarehouse">
            <template v-slot:activator="{ on, attrs }">
              <button-base
                text
                plain
                block
                x-small
                before-icon="content-copy"
                class="white"
                v-bind="attrs"
                v-on="on"
                @click="$emit('copy-warehouse-schedule')">
                Warehouse
              </button-base>
            </template>
            <span>Clone the Warehouse Schedule</span>
          </v-tooltip>

          <clone-dock-schedule-dialog
            v-if="withCopyFromDock"
            @close="$refs.scheduleoptionsbtn.$el.click()"
            :docks="docks"
            @copy-schedule="emitCopyDockSchedule"></clone-dock-schedule-dialog>
        </v-list>
      </v-menu>
    </div>

    <div class="is-relative">
      <div class="table-loader__wrapper overlay-base" v-if="isInvalidTimeGrid">
        <div class="table-loader__overlay overlay-base"></div>
        <div class="table-loader__content">
          <div class="text-center mt-3">
            This schedule has a conflicting combination of intervals - 20 and 15/30. Please clear
            schedule to edit.
          </div>
        </div>
      </div>
      <v-simple-table id="schedule-table" :class="compact ? 'compact' : ''">
        <template v-slot:default>
          <thead>
            <tr id="schedule-header">
              <th class="text-center" colspan="2">Time</th>
              <th
                class="day-header"
                v-for="(weekday, index) in weekdayLabels"
                :key="weekday"
                :data-column="index + 2">
                {{ weekday }}
              </th>
            </tr>
          </thead>
          <template v-for="(hourGroup, key) in times">
            <tbody class="hour-group" :key="`tbody-${key}`">
              <tr
                v-for="(time, index) in hourGroup"
                :key="time.start"
                class="time-row"
                :class="[
                  { 'start-of-interval-group': !index || index % intervalsInHour === 0 },
                  'interval-row'
                ]">
                <th
                  class="hour-label"
                  v-if="!index || index % intervalsInHour === 0"
                  :rowspan="intervalsInHour">
                  <span class="d-inline-block full-width">{{
                    convert24ToFormat(
                      time.start,
                      novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
                      novaCore.LuxonDateTimeFormats.Extended24HrTime
                    )
                  }}</span>
                </th>
                <th class="row-label">
                  {{ convert24to12(time.start) }}
                </th>
                <td
                  :data-column="colIndex + 2"
                  v-for="(weekday, colIndex) in weekdays"
                  class="time-cell is-relative day-cell"
                  :class="{
                    active: isOpen(weekday, time) && !isTimeDisabled(weekday, time),
                    'time-is-disabled': isTimeDisabled(weekday, time)
                  }"
                  :data-start="time.start"
                  :data-end="time.end"
                  :key="time.start + weekday"
                  @mousedown="
                    e => {
                      handleCellClick(e, weekday, time, isTimeDisabled(weekday, time));
                    }
                  ">
                  <v-icon class="add-interval" x-small>mdi-plus</v-icon>
                  <v-icon class="subtract-interval" x-small>mdi-minus</v-icon>
                  <div class="disabled-time-interval overlay align-center justify-center">
                    <v-icon x-small class="mr-1">mdi-cancel</v-icon>
                    Warehouse
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </template>
      </v-simple-table>
    </div>
    <div class="d-flex mt-2 align-center">
      <div class="mr-4 d-flex align-center">
        <div class="circle bordered primary pa-1 mr-1"></div>
        <strong class="font-size-x-small">OPEN</strong>
      </div>
      <div class="d-flex align-center">
        <div class="circle pa-1 mr-1 bordered"></div>
        <strong class="font-size-x-small">CLOSED</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

/**
 * @displayName Time Select Grid
 */
export default {
  props: {
    /**
     * @model
     */
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },
    /**
     * Display in compact mode
     */
    compact: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Allow edit toggle
     */
    toggleToEdit: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * The component should default to edit mode
     */
    shouldStartInEditMode: {
      type: Boolean,
      required: false,
      default: false
    },
    enabledIntervals: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    localStorageKey: {
      type: String,
      required: false
    },
    docks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    withCopyFromWarehouse: {
      type: Boolean,
      required: false,
      default: true
    },
    withCopyFromDock: {
      type: Boolean,
      required: false,
      default: true
    },
    settingsEntity: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      editMode: false,
      times: [],
      modified: false,
      weekdays: [],
      originalSchedule: {},
      internalSchedule: {},
      interval: 30,
      disable30MinOption: false,
      disable20MinOption: false,
      disable15MinOption: false,
      isInvalidTimeGrid: false,
      shouldShowDocksDialog: false
    };
  },
  computed: {
    intervalsInHour() {
      return 60 / this.interval;
    },
    weekdayLabels() {
      return momentjs.weekdaysShort();
    }
  },
  methods: {
    emitCopyDockSchedule(schedule) {
      this.$emit('copy-dock-schedule', schedule);
    },
    /**
     * Convert time from 24 hour to 12 hour format
     * @public
     * @param {string} time
     * @returns {string}
     */
    convert24to12(time) {
      return this.convert24ToFormat(
        time,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    },
    convert24ToFormat(time, returnFormat12, returnFormat24) {
      if (typeof time === 'string') {
        const tz = null;
        if (time.length === 4) {
          time = `0${time}`; // e.g. 9:00 -> 09:00
        }
        return this.novaCore.formatDateTimeWithMilitarySupport(
          time,
          tz,
          returnFormat12,
          this.$isMilitaryTimeEnabled(this.settingsEntity),
          returnFormat24
        );
      }
    },
    /**
     * Check if any spans overlap
     * @public
     * @param weekday
     * @param span
     * @returns {boolean|*}
     */
    isOpen(weekday, span) {
      let dayKey = weekday.toLowerCase();

      if (this.internalSchedule[dayKey]) {
        return this.internalSchedule[dayKey].some(clockSpan => {
          return this.novaCore.clocksOverlap(clockSpan, span);
        });
      }

      return false;
    },
    isTimeDisabled(weekday, span) {
      const hasEnabledIntervals = this.enabledIntervals
        ? Object.keys(this.enabledIntervals).length > 0
        : false;
      let isDisabled = hasEnabledIntervals;
      if (hasEnabledIntervals) {
        const intervals = this.enabledIntervals[weekday.toLowerCase()];
        if (intervals) {
          intervals.forEach(clockSpan => {
            if (this.novaCore.clocksOverlap(clockSpan, span)) {
              isDisabled = false;
            }
          });
        }
      }

      return isDisabled;
    },
    /**
     * Handle a table cell click
     * @public
     * @param event
     * @param day
     * @param slot
     */
    handleCellClick(event, day, slot, isDisabled) {
      event.preventDefault();
      // Do nothing if the event is not correct
      if (event.buttons < 1 && event.type !== 'click') {
        return;
      }

      let noOverlap = true;
      let daySchedule = this.internalSchedule[day.toLowerCase()];

      // If an exact match exists already, remove
      let existingIdx = daySchedule.findIndex(s => this.spansAreEqual(s, slot));
      if (existingIdx > -1) {
        daySchedule.splice(existingIdx, 1);
        this.hasLesserIntervals(this);
        return;
      }
      daySchedule.forEach(span => {
        if (this.novaCore.clocksOverlap(slot, span)) {
          noOverlap = false;
          this.removeSubSpan(slot, span, daySchedule);
        }
      });

      if ((!daySchedule.length || noOverlap) && !isDisabled) {
        daySchedule.push(slot);
      }

      this.internalSchedule[day.toLowerCase()] = this.novaCore.mergeAdjacentSpans(daySchedule);

      this.modified = true;
      this.hasLesserIntervals(this);
    },
    /**
     * Check if spans are equal duration
     * @public
     * @param {object} span1
     * @param {object} span2
     * @returns {boolean}
     */
    spansAreEqual(span1, span2) {
      return span1.start === span2.start && span1.end === span2.end;
    },
    /**
     * Remove a span from span list
     * @public
     * @param {object} subSpan
     * @param {object} parentSpan
     * @param {object[]} spanList
     * @returns {object[]}
     */
    removeSubSpan(subSpan, parentSpan, spanList) {
      if (parentSpan.start === subSpan.start) {
        parentSpan.start = momentjs(
          parentSpan.start,
          this.novaCore.DateTimeFormats.Extended24HrTime
        )
          .add(this.interval, 'minutes')
          .format(this.novaCore.DateTimeFormats.Extended24HrTime);
      } else if (parentSpan.end === subSpan.end) {
        let end = parentSpan.end === '23:59' ? '24:00' : parentSpan.end;
        parentSpan.end = momentjs(end, this.novaCore.DateTimeFormats.Extended24HrTime)
          .subtract(this.interval, 'minutes')
          .format(this.novaCore.DateTimeFormats.Extended24HrTime);
      } else {
        let idx = spanList.findIndex(s => s.start === parentSpan.start);

        if (idx > -1) {
          spanList.splice(idx, 1);
          spanList.push(
            {
              start: parentSpan.start,
              end: subSpan.start
            },
            {
              start: subSpan.end,
              end: parentSpan.end
            }
          );
        }
      }

      return spanList;
    },
    /**
     * Build clock spans array based on epoch
     * @public
     * @param {number} interval
     * @returns {object[]}
     */
    buildClockSpans(interval = 30) {
      const mt = momentjs('1970-01-01'); // Epoch
      let times = {};
      while (mt.dayOfYear() === 1) {
        const hour = mt.clone().startOf('hour').format('H');
        let spanObj = { start: mt.format(this.novaCore.DateTimeFormats.Extended24HrTime) };
        let end = mt
          .add(interval, 'minutes')
          .format(this.novaCore.DateTimeFormats.Extended24HrTime); // increment
        spanObj.end = end === '0:00' ? '23:59' : end; // TODO This is weird, need to discuss
        if (!this.novaCore.objPropExists(times, `hour-${hour}`)) {
          times[`hour-${hour}`] = [];
        }
        times[`hour-${hour}`].push(spanObj);
      }
      return times;
    },
    /**
     * Set original schedule and internal schedule using v-model value
     * @public
     */
    mapLocalSchedule() {
      this.originalSchedule = JSON.parse(JSON.stringify(this.value));
      this.internalSchedule = {
        ...JSON.parse(JSON.stringify(this.novaCore.getClosedScheduleTemplate())),
        ...(this.value && Object.keys(this.value).length > 1
          ? this.value
          : this.novaCore.getFullyOpenSchedule())
      };
    },
    /**
     * Save edit changes
     * @public
     */
    saveChanges() {
      this.$emit('update-interval', this.interval);
      this.$emit('input', this.internalSchedule);
      this.originalSchedule = { ...this.internalSchedule };
      this.editMode = false;
    },
    /**
     * Toggle to edit mode
     * @public
     */
    enterEditMode() {
      this.editMode = true;
      this.modified = false;
    },
    /**
     * Cancel edit mode
     * @public
     */
    cancelEdit() {
      this.$emit('close');
      this.internalSchedule = {
        ...JSON.parse(JSON.stringify(this.novaCore.getClosedScheduleTemplate())),
        ...JSON.parse(JSON.stringify(this.originalSchedule))
      };
      this.editMode = false;
    },
    hasLesserIntervals: debounce(vueContext => {
      let hasFifteenMinIntervals = false;
      let hasTwentyMinIntervals = false;
      let hasThirtyMinIntervals = false;
      const fifteenMinIdentifiers = [':15', ':45'];
      const twentyMinIdentifiers = [':20', ':40'];
      const thirtyMinIdentifiers = [':30'];
      Object.values(vueContext.internalSchedule).forEach(daySchedule => {
        if (Array.isArray(daySchedule)) {
          daySchedule.forEach(interval => {
            if (
              fifteenMinIdentifiers.some(minute => interval.start.includes(minute)) ||
              fifteenMinIdentifiers.some(minute => interval.end.includes(minute))
            ) {
              hasFifteenMinIntervals = true;
            } else if (
              twentyMinIdentifiers.some(minute => interval.start.includes(minute)) ||
              twentyMinIdentifiers.some(minute => interval.end.includes(minute))
            ) {
              hasTwentyMinIntervals = true;
            } else if (
              thirtyMinIdentifiers.some(minute => interval.start.includes(minute)) ||
              thirtyMinIdentifiers.some(minute => interval.end.includes(minute))
            ) {
              hasThirtyMinIntervals = true;
            }
          });
        }
      });
      if (
        (hasFifteenMinIntervals && hasTwentyMinIntervals) ||
        (hasThirtyMinIntervals && hasTwentyMinIntervals)
      ) {
        vueContext.isInvalidTimeGrid = true;
        return;
      } else {
        vueContext.isInvalidTimeGrid = false;
      }
      if (hasTwentyMinIntervals) {
        vueContext.updateInterval({
          interval: 20,
          disable30MinOption: true,
          disable20MinOption: false,
          disable15MinOption: true
        });
      } else if (hasThirtyMinIntervals) {
        if (hasFifteenMinIntervals) {
          vueContext.updateInterval({
            interval: 15,
            disable30MinOption: true,
            disable20MinOption: true,
            disable15MinOption: false
          });
        } else {
          vueContext.updateInterval({
            interval: vueContext.interval,
            disable30MinOption: false,
            disable20MinOption: true,
            disable15MinOption: false
          });
        }
      } else if (hasFifteenMinIntervals) {
        vueContext.updateInterval({
          interval: 15,
          disable30MinOption: true,
          disable20MinOption: true,
          disable15MinOption: false
        });
      } else {
        vueContext.updateInterval({
          interval: vueContext.interval,
          disable30MinOption: false,
          disable20MinOption: false
        });
      }
    }),
    rowLabelMouseEnter(e) {
      const target = e.target;
      target.classList.add('time-row-group-item');
      target.parentElement.querySelectorAll('td').forEach(node => {
        node.classList.add('time-row-group-item');
      });
    },
    rowLabelMouseLeave(e) {
      const target = e.target;
      target.classList.remove('time-row-group-item');
      target.parentElement.querySelectorAll('td').forEach(node => {
        node.classList.remove('time-row-group-item');
      });
    },
    rowLabelClick(e) {
      this.handleGroupClick(e.target.parentElement);
    },
    hourLabelMouseEnter(e) {
      e.target.closest('.hour-group').classList.add('active');
    },
    hourLabelMouseLeave(e) {
      e.target.closest('.hour-group').classList.remove('active');
    },
    hourLabelClick(e) {
      this.handleGroupClick(e.target.closest('.hour-group'));
    },
    handleGroupClick(parentEl, additionalSelector = '') {
      const selector = additionalSelector ? `td${additionalSelector}` : 'td';
      const activeCellCount = parentEl.querySelectorAll(`${selector}.active`).length;
      const disabledIntervalCount = Array.from(
        parentEl.querySelectorAll(`${selector} .disabled-time-interval`)
      ).filter(node => {
        return window.getComputedStyle(node, null).display !== 'none';
      }).length;
      const totalPotentiallyActiveCells =
        parentEl.querySelectorAll(selector).length - disabledIntervalCount;
      const shouldSelect = activeCellCount > 0 && activeCellCount < totalPotentiallyActiveCells;
      if (shouldSelect) {
        parentEl.querySelectorAll(`${selector}:not(.active)`).forEach(node => {
          node.dispatchEvent(new Event('mousedown'));
        });
      } else {
        parentEl.querySelectorAll(selector).forEach(node => {
          node.dispatchEvent(new Event('mousedown'));
        });
      }
    },
    dayHeaderMouseEnter(e) {
      const target = e.target;
      const column = target.getAttribute('data-column');
      target.classList.add('highlight');
      document
        .getElementById('schedule-table')
        .querySelectorAll(`td[data-column="${column}"]`)
        .forEach(node => node.classList.add('highlight'));
    },
    dayHeaderMouseLeave(e) {
      const target = e.target;
      const column = target.getAttribute('data-column');
      target.classList.remove('highlight');
      document
        .getElementById('schedule-table')
        .querySelectorAll(`td[data-column="${column}"]`)
        .forEach(node => node.classList.remove('highlight'));
    },
    dayHeaderClick(e) {
      const target = e.target;
      const column = target.getAttribute('data-column');
      this.handleGroupClick(document.getElementById('schedule-table'), `[data-column="${column}"]`);
    },
    addEventListeners() {
      document.querySelectorAll('.row-label').forEach(node => {
        node.addEventListener('mouseenter', this.rowLabelMouseEnter);
        node.addEventListener('mouseleave', this.rowLabelMouseLeave);
        node.addEventListener('click', this.rowLabelClick);
      });
      document.querySelectorAll('.hour-label').forEach(node => {
        node.addEventListener('mouseenter', this.hourLabelMouseEnter);
        node.addEventListener('mouseleave', this.hourLabelMouseLeave);
        node.addEventListener('click', this.hourLabelClick);
      });
      document.querySelectorAll('.day-header').forEach(node => {
        node.addEventListener('mouseenter', this.dayHeaderMouseEnter);
        node.addEventListener('mouseleave', this.dayHeaderMouseLeave);
        node.addEventListener('click', this.dayHeaderClick);
      });
    },
    removeEventListeners() {
      document.querySelectorAll('.row-label').forEach(node => {
        node.removeEventListener('mouseenter', this.rowLabelMouseEnter);
        node.removeEventListener('mouseleave', this.rowLabelMouseLeave);
        node.removeEventListener('click', this.rowLabelClick);
      });
      document.querySelectorAll('.hour-label').forEach(node => {
        node.removeEventListener('mouseenter', this.hourLabelMouseEnter);
        node.removeEventListener('mouseleave', this.hourLabelMouseLeave);
        node.removeEventListener('click', this.hourLabelClick);
      });
      document.querySelectorAll('.day-header').forEach(node => {
        node.removeEventListener('mouseenter', this.dayHeaderMouseEnter);
        node.removeEventListener('mouseleave', this.dayHeaderMouseLeave);
        node.removeEventListener('click', this.dayHeaderClick);
      });
    },
    updateInterval({ interval, disable30MinOption, disable20MinOption, disable15MinOption }) {
      this.interval = interval;
      this.disable30MinOption = disable30MinOption;
      this.disable20MinOption = disable20MinOption;
      this.disable15MinOption = disable15MinOption;
      if (this.localStorageKey) {
        localStorage.setItem(`scheduleInterval-${this.localStorageKey}`, this.interval);
      }
    }
  },
  watch: {
    value() {
      this.$nextTick(() => {
        this.mapLocalSchedule();
        this.hasLesserIntervals(this);
      });
    },
    interval() {
      this.times = this.buildClockSpans(this.interval);

      if (this.localStorageKey) {
        localStorage.setItem(`scheduleInterval-${this.localStorageKey}`, this.interval);
      }
      this.$nextTick(() => {
        this.removeEventListeners();
        this.addEventListeners();
      });
    },
    localStorageKey() {
      const lsInterval = localStorage.getItem(`scheduleInterval-${this.localStorageKey}`);
      if (lsInterval) {
        this.interval = parseInt(lsInterval);
      }
    }
  },
  mounted() {
    this.mapLocalSchedule();
    this.weekdays = momentjs.weekdays();
    this.times = this.buildClockSpans(this.interval);
    if (this.shouldStartInEditMode) {
      this.enterEditMode();
    }
    this.$nextTick(() => this.addEventListeners());
    this.hasLesserIntervals(this);
  },
  beforeDestroy() {
    this.removeEventListeners();
  }
};
</script>
