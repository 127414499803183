var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              {
                ref: "form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("p", [
                  _c("strong", [_vm._v("Organization:")]),
                  _vm._v(" " + _vm._s(_vm.$org.name) + " "),
                ]),
                _c(
                  "p",
                  [
                    _c("v-chip", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.formatDate(_vm.$org.expiresAt))),
                      ]),
                    ]),
                    _c("v-icon", { staticClass: "mx-3" }, [
                      _vm._v("mdi-arrow-right-thin"),
                    ]),
                    _c("v-chip", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.formatDate(_vm.localOrgExpiresAt))),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm.novaCore.isExpiresAtWarningVisible(_vm.localOrgExpiresAt)
                  ? _c(
                      "p",
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-1",
                            attrs: { color: "red", small: "" },
                          },
                          [_vm._v("mdi-alert")]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.novaCore.getExpirationWarningText(
                                _vm.localOrgExpiresAt
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-container",
                  [
                    _c("date-picker", {
                      attrs: {
                        minDate: _vm.minDate,
                        type: "text",
                        placeholder: "New expiration date",
                        rules: _vm.$validator.rules.date,
                      },
                      model: {
                        value: _vm.localOrgExpiresAt,
                        callback: function ($$v) {
                          _vm.localOrgExpiresAt = $$v
                        },
                        expression: "localOrgExpiresAt",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: { "confirm-icon": "update", "confirm-label": "save" },
              on: { cancel: _vm.cancel, confirm: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }