export default {
  computed: {
    appointmentStartTimeMinutes() {
      // TODO NO ACCESS TO WAREHOUSE HERE, MAYBE THIS MIXZIN NEEDS TO DIE
      // TODO THIS IS ALSO USED IN LUNA
      const orgAppointmentStartTimeMinutes = this.$org?.settings?.appointmentStartTimeMinutes;
      let appointmentStartTimeMinutes =
        orgAppointmentStartTimeMinutes ?? this.$getSettingValue('appointmentStartTimeMinutes');
      return appointmentStartTimeMinutes === 0 ? 60 : appointmentStartTimeMinutes;
    }
  },
  methods: {}
};
