<template>
  <v-form ref="form">
    <v-container>
      <text-field
        :required="true"
        type="text"
        v-model="localField.label"
        :rules="$validator.rules.required('Field Label')"
        @keydown.enter.native.stop
        label="Field Label"></text-field>

      <v-text-field
        type="text"
        v-model="localField.description"
        label="Description"
        @keydown.enter.native.stop></v-text-field>

      <v-text-field
        v-if="!typesWithoutPlaceholder.includes(localField.type)"
        type="text"
        v-model="localField.placeholder"
        label="Example Value"
        @keydown.enter.native.stop></v-text-field>

      <v-select
        v-model="localField.type"
        class="document-type-select"
        :items="fieldTypeOptions"
        :rules="$validator.rules.required('Field Type')"
        label="Field Type">
        <template v-slot:item="{ item }"><span :inner-html.prop="item.text"></span></template>
        <template v-slot:selection="{ item }">
          <span :inner-html.prop="item.text"></span>
        </template>
      </v-select>
      <span v-if="isDocField" class="fieldNotes">
        Maximum
        <strong>50MB</strong>
        in size. Only
        <strong>ONE image</strong>
        ,
        <strong>text</strong>
        ,
        <strong>zip</strong>
        or
        <strong>spreadsheet</strong>
        allowed. To upload multiple docs, zip first then upload.
      </span>
      <span v-else-if="isMultiDocField" class="fieldNotes">
        Maximum
        <strong>50MB</strong>
        in size per file. Maximum 10 files. Only
        <strong>image</strong>
        ,
        <strong>text</strong>
        ,
        <strong>zip</strong>
        or
        <strong>spreadsheet</strong>
        allowed.
      </span>
      <custom-field-drop-down-choices-form
        v-if="hasDropDownValues"
        v-model="localField.dropDownValues"></custom-field-drop-down-choices-form>

      <div class="d-flex mt-6 flex-row justify-space-between" v-if="isNumberField || isTextField">
        <v-text-field
          class="mr-2"
          type="number"
          v-model.number="localField.minLengthOrValue"
          :rules="
            isTextField
              ? $validator.rules.optionalNumberBetween(0, maxSizeTextField(localField.type))
              : []
          "
          :label="`Minimum ${isNumberField ? 'value' : 'length'}`"
          @keydown.enter.native.stop></v-text-field>

        <v-text-field
          class="ml-2"
          type="number"
          v-model.number="localField.maxLengthOrValue"
          :rules="
            isTextField
              ? $validator.rules.optionalNumberBetween(0, maxSizeTextField(localField.type))
              : []
          "
          :label="`Maximum ${isNumberField ? 'value' : 'length'}`"
          @keydown.enter.native.stop></v-text-field>
      </div>

      <v-container class="my-6">
        <v-row>
          <v-switch v-model="localField.requiredForWarehouse" color="primary" class="mr-3 mt-0">
            <template v-slot:label>
              <span class="pr-1">Required for Warehouse</span>
              <help-icon-tooltip icon-style-class="grey darken-2" dense>
                This field is mandatory for Warehouse users to fill up when scheduling an
                appointment
              </help-icon-tooltip>
            </template>
          </v-switch>
        </v-row>

        <v-row>
          <v-switch v-model="localField.hiddenFromCarrier" color="primary" class="mr-2 mt-0">
            <template v-slot:label>
              <span class="pr-1">Hide this field on carrier scheduling</span>
              <help-icon-tooltip icon-style-class="grey darken-2" dense>
                This field will not be visible to carriers when scheduling an appointment
              </help-icon-tooltip>
            </template>
          </v-switch>
        </v-row>

        <v-row v-if="!localField.hiddenFromCarrier">
          <v-switch v-model="localField.requiredForCarrier" color="primary" class="mr-2 mt-0">
            <template v-slot:label>
              <span class="pr-1">Required for Carriers</span>
              <help-icon-tooltip icon-style-class="grey darken-2" dense>
                This field is mandatory for Carrier users to fill up when scheduling an appointment
              </help-icon-tooltip>
            </template>
          </v-switch>
        </v-row>
      </v-container>

      <action-group
        @cancel="$emit('close')"
        class="pr-0"
        confirm-icon="upload"
        confirm-label="Save"
        cancel-label="Nevermind"
        cancel-color="error"
        @confirm="submit"></action-group>
    </v-container>
  </v-form>
</template>

<script>
import { FIELDS_WITHOUT_PLACEHOLDER } from '@satellite/../nova/core';

/**
 * Custom field form
 * @displayName Custom Field Form
 */
export default {
  props: {
    /**
     * Custom Field
     */
    field: {
      type: Object,
      requiredForCarrier: false,
      requiredForWarehouse: false,
      hiddenFromCarrier: false,
      label: String,
      description: String,
      dropDownValues: [],
      minLengthOrValue: null,
      maxLengthOrValue: null
    },
    fieldTypeOptions: {
      value: String,
      text: String
    },
    warehouse: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localField: {},
      typesWithoutPlaceholder: Object.freeze(FIELDS_WITHOUT_PLACEHOLDER)
    };
  },
  computed: {
    isDocField() {
      return this.localField.type === this.novaCore.CustomFieldType.Document;
    },
    isMultiDocField() {
      return this.localField.type === this.novaCore.CustomFieldType.MultiDocument;
    },
    isNumberField() {
      return this.localField.type === this.novaCore.CustomFieldType.Number;
    },
    isTextField() {
      return (
        this.localField.type === this.novaCore.CustomFieldType.String ||
        this.localField.type === this.novaCore.CustomFieldType.BigString
      );
    },
    hasDropDownValues() {
      return (
        this.localField.type &&
        (this.localField.type === this.novaCore.CustomFieldType.DropDown ||
          this.localField.type === this.novaCore.CustomFieldType.DropDownMultiSelect ||
          this.localField.type === this.novaCore.CustomFieldType.ComboBox)
      );
    }
  },
  methods: {
    /**
     * Submit the form
     * @public
     */
    submit() {
      if (this.hasDropDownValues && this.localField.dropDownValues.length < 1) {
        this.notify('At least one option is required for dropdown fields', 'warning');
        return;
      }
      if (this.$refs.form.validate()) {
        if (!this.hasDropDownValues) {
          // Reset template list when it is not dropdown
          this.localField.dropDownValues = [];
        }
        this.$emit('save', this.localField);
      }
    },
    maxSizeTextField(fieldType) {
      if (fieldType === this.novaCore.CustomFieldType.String) {
        return 255;
      }

      if (fieldType === this.novaCore.CustomFieldType.BigString) {
        return 1000;
      }
    }
  },
  watch: {
    'localField.hiddenFromCarrier'(value) {
      if (value) {
        this.localField.requiredForCarrier = false;
      }
    }
  },
  mounted() {
    this.localField = { ...this.field };

    if (!this.localField.dropDownValues) {
      // Make sure we have it initialized
      this.localField.dropDownValues = [];
    }
  }
};
</script>
