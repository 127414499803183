var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.disabled
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            _vm._b({ staticClass: "fit" }, "div", attrs, false),
                            on
                          ),
                          [
                            _c("outline-button", { attrs: { disabled: "" } }, [
                              _vm._v("Preview Scheduling Portal"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1285539835
              ),
            },
            [
              _c("span", [
                _vm._v("Save the settings to enable the preview mode"),
              ]),
            ]
          )
        : _c(
            "outline-button",
            {
              attrs: { small: "", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.jumpToLunaPreview()
                },
              },
            },
            [_vm._v(" Preview Scheduling Portal ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }