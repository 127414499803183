<template>
  <v-form ref="form">
    <v-card data-testid="yard-spot-multiple-form">
      <v-card-title>
        <span class="font-size-medium">Generate multiple spots to your yard area</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column">
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="value.numberOfSpots"
                label="Number of Spots"
                outlined
                type="number"
                dense
                :rules="[validateNumberOfSpots]"
                data-testid="yard-spot-form-number-spots-input"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="value.startFromNumber"
                label="Start from number"
                type="number"
                outlined
                :rules="[validateStartFromNumber]"
                dense
                data-testid="yard-spot-form-start-number-input"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="value.leadingChar"
                label="Fixed leading char"
                outlined
                :rules="[validateLeadingChar]"
                dense
                data-testid="yard-spot-form-leading-code-input"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="value.type"
                :items="Object.keys(spotType).map(k => k.toLowerCase())"
                label="Spot type"
                :rules="[validateType]"
                data-testid="yard-spot-form-type-multiple-select"
                outlined
                dense></v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { defineComponent, ref } from 'vue';

import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewSpotGeneratorMultipleForm',
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  setup() {
    const yardStore = useYard();
    const form = ref(null);

    const validateNumberOfSpots = value => {
      if (value < 0 || value > 300) {
        return 'Number of Spots must be between 0 and 300';
      }
      return true;
    };

    const validateStartFromNumber = value => {
      if (value < 0 || value > 299) {
        return 'Start from number must be between 0 and 299';
      }
      return true;
    };

    const validateLeadingChar = value => {
      if (!value) {
        return true;
      }
      if (!/^[a-zA-Z0-9]$/.test(value) || value.length !== 1) {
        return 'Leading char must be a single alphanumeric character (a-z, 0-9)';
      }
      return true;
    };

    const validate = () => form.value?.validate();

    return {
      spotType: yardStore.spotType,
      form,
      validate,
      validateType: yardStore.validateSpotTypeInput,
      validateNumberOfSpots,
      validateStartFromNumber,
      validateLeadingChar
    };
  }
});
</script>
