var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appointment-details" },
    [
      _c(
        "div",
        { staticClass: "mx-2 mb-8" },
        [
          _c(
            "v-row",
            [
              _c("v-icon", { staticClass: "text-lg-h5 mr-2" }, [
                _vm._v("mdi-calendar"),
              ]),
              _c("span", {
                staticClass: "text-lg-h6 black--text",
                attrs: { "data-testid": "reserve-date-time" },
                domProps: { innerHTML: _vm._s(_vm.appointmentDateTimeHeader) },
              }),
            ],
            1
          ),
          _vm.recurringPattern.weekDays
            ? _c("v-row", [
                _c("div", { staticClass: "font-size-x-small" }, [
                  _vm._v(" Recurs every "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.recurringPattern.weekDays) +
                        " @ " +
                        _vm._s(_vm.recurringPattern.endTime)
                    ),
                  ]),
                  _vm._v(" until "),
                  _c("strong", [_vm._v(_vm._s(_vm.recurringPattern.endDate))]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      !_vm.loading
        ? _c("v-simple-table", {
            staticClass: "appointment-details-table",
            attrs: { dense: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("thead"),
                      _c(
                        "tbody",
                        [
                          _c("tr", [
                            _c("td", { staticClass: "font-weight-bold pb-4" }, [
                              _c("div", [_vm._v("Reserve Title")]),
                            ]),
                            _c("td", { staticClass: "pb-4" }, [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.appointment.notes || "----"
                                  ),
                                },
                              }),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-weight-bold pb-4" }, [
                              _c(
                                "div",
                                [
                                  _vm._v(" Reserve ID "),
                                  _c("help-icon-tooltip", [
                                    _vm._v("Unique Reserve Reference"),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                            _c("td", { staticClass: "pb-4" }, [
                              _c(
                                "div",
                                [
                                  _c("span", { staticClass: "mr-4" }, [
                                    _vm._v(_vm._s(_vm.appointment.id)),
                                  ]),
                                  _c(
                                    "secondary-button",
                                    {
                                      staticClass: "ml-4",
                                      attrs: { "x-small": "" },
                                      on: { click: _vm.copyApptId },
                                    },
                                    [_vm._v("Copy")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._l(
                            _vm.appointmentTableRows,
                            function (row, index) {
                              return [
                                !row.isHidden
                                  ? [
                                      _c(
                                        "tr",
                                        { key: index + "-detail" },
                                        [
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "font-weight-bold",
                                                class: row.tdClass,
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(row.label) + " "
                                                ),
                                              ]
                                            ),
                                            _c("td", { class: row.tdClass }, [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(row.value),
                                                },
                                              }),
                                            ]),
                                          ],
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            }
                          ),
                          _c("tr", { staticClass: "divider" }, [
                            _c("td"),
                            _c("td"),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "pt-5 font-weight-bold" }, [
                              _vm._v("Warehouse"),
                            ]),
                            _c(
                              "td",
                              { staticClass: "pt-5" },
                              [
                                _c(
                                  "v-expansion-panels",
                                  { attrs: { accordion: "" } },
                                  [
                                    _c(
                                      "v-expansion-panel",
                                      [
                                        _c("v-expansion-panel-header", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.appointment.dock
                                                      .warehouse.name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("v-expansion-panel-content", [
                                          _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _vm._v(" Address: "),
                                              _c(
                                                "copy-content",
                                                {
                                                  attrs: {
                                                    content:
                                                      _vm.getWarehouseAddress(
                                                        _vm.appointment.dock
                                                          .warehouse
                                                      ),
                                                    label:
                                                      "Click to copy warehouse address",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getWarehouseAddress(
                                                          _vm.appointment.dock
                                                            .warehouse
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _vm._v(
                                                "Email: " +
                                                  _vm._s(
                                                    _vm.appointment.dock
                                                      .warehouse.email
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _vm._v(
                                                "Phone: " +
                                                  _vm._s(
                                                    _vm.appointment.dock
                                                      .warehouse.phone
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("tr", { staticClass: "divider" }, [
                            _c("td", { attrs: { colspan: "2" } }),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-weight-bold pt-5" }, [
                              _vm._v("Audit Log"),
                            ]),
                            _c(
                              "td",
                              { staticClass: "pt-5" },
                              [
                                _c(
                                  "v-expansion-panels",
                                  { attrs: { accordion: "" } },
                                  [
                                    _c(
                                      "v-expansion-panel",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAuditLogExpand()
                                          },
                                        },
                                      },
                                      [
                                        _c("v-expansion-panel-header", [
                                          _vm._v(
                                            "Timeline for Reserve Updates"
                                          ),
                                        ]),
                                        _c(
                                          "v-expansion-panel-content",
                                          [
                                            _vm.isAuditLogVisible
                                              ? _c("audit-log-appointment", {
                                                  attrs: {
                                                    id: "audit_log_element",
                                                    org: _vm.$org,
                                                    "object-id":
                                                      _vm.appointment.id,
                                                    "entity-obj":
                                                      _vm.appointment,
                                                    entity: "appointment",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3626088630
            ),
          })
        : _vm._e(),
      (_vm.canCancel || _vm.isCancelled) && _vm.shouldDisplayCancelButton
        ? [
            _c(
              "div",
              { staticClass: "d-flex justify-center my-8" },
              [
                !_vm.isCancelled
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "text__error font-weight-bold",
                        attrs: {
                          elevation: "0",
                          color: "white",
                          disabled:
                            _vm.appointment.status ===
                            _vm.novaCore.AppointmentStatus.Cancelled,
                        },
                        on: { click: _vm.confirmCancel },
                      },
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v(_vm._s(_vm.cancelButtonIcon)),
                        ]),
                        _vm._v(" " + _vm._s(_vm.cancelAppointmentLabel) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.appointment && !_vm.loading
        ? _c(
            "v-alert",
            { staticClass: "white--text", attrs: { color: "error" } },
            [_vm._v(" Could not load appointment data ")]
          )
        : _vm._e(),
      _vm.loading
        ? [
            _c(
              "v-card-text",
              [
                _vm._v(" Loading Appointment Data... "),
                _c("v-progress-linear", {
                  attrs: {
                    color: "secondary",
                    indeterminate: "",
                    rounded: "",
                    height: "8",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("reschedule-appointment-dialog-old", {
        attrs: {
          "external-activator": true,
          "show-dialog": _vm.showRescheduleDialog,
          appointment: _vm.appointment,
        },
        on: {
          close: function ($event) {
            _vm.showRescheduleDialog = false
          },
        },
      }),
      _c("cancel-appointment-dialog-old", {
        ref: "cancelAppointment",
        attrs: {
          "external-activator": true,
          "show-dialog": _vm.showConfirmDialog,
          appointment: _vm.appointment,
        },
        on: {
          confirm: function ($event) {
            return _vm.$emit("confirm")
          },
          close: function ($event) {
            _vm.showConfirmDialog = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }