import * as novaCore from '@satellite/../nova/core';

// initial state
const state = {
  carriers: [],
  pagination: {},
  filters: {}
};

// actions
const actions = {
  async getCarriers({ commit }, filters = {}) {
    // Search by email subscribers per org
    // The join is made on the backend
    if (filters?.s?.$or && filters.searchStr) {
      filters.s.$or.push({ '"orgCarrierSettings"."emailCCs"': { $contL: filters.searchStr } });
    }

    let response = await axios.get('carrier', {
      params: filters || state.filters
    });

    if (response?.data) {
      commit('setApiResponseData', response.data);
      commit('setFilters', filters || state.filters);
    }

    return state.carriers || [];
  },
  async getOrgCarrierSettings({}, carrierId) {
    const response = await axios.get(`carrier/${carrierId}/org-carrier-settings`);
    return response?.data?.data || {};
  },
  async getBookedCarriers({}, filters = {}) {
    const response = await axios.get('carrier/booked', {
      params: filters || state.filters
    });

    return response?.data?.data || [];
  },
  async createCarrier({}, data) {
    if (!data.phone) {
      delete data.phone;
    }

    return await axios.post(`carrier`, data);
  },
  async doesCarrierExist({}, email) {
    const response = await axios.get('carrier/public/exists', {
      params: {
        s: {
          email: {
            $eq: email?.toLowerCase()?.trim()
          }
        }
      },
      suppressNotification: true
    });

    return response?.data?.carrierExists ?? false;
  }
};

// mutations
const mutations = {
  setApiResponseData(state, response) {
    state.carriers = response.data?.map(carrier => ({
      ...carrier,
      fullName: carrier?.fullName || novaCore.namesToFullName(carrier.firstName, carrier.lastName)
    }));
    state.pagination = {
      page: response.page,
      pageCount: response.pageCount,
      total: response.total
    };
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setPagination(state, pagination) {
    state.pagination = {
      page: pagination.page,
      pageCount: pagination.pageCount,
      total: pagination.total
    };
  }
};

export default {
  namespaced: true,
  name: 'Carriers',
  state,
  actions,
  mutations
};
