var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ma-1" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex flex-column gap-8", attrs: { cols: "12" } },
            [
              _c("yard-view-page-header"),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column gap-4",
                      attrs: { col: "12" },
                    },
                    [
                      _c("yard-view-page-sub-header"),
                      _vm.selectedWarehouse?.id
                        ? [
                            _vm.isGateManagementEnabled
                              ? _c("yard-view-yard")
                              : _c("yard-view-gm-disabled"),
                          ]
                        : _c("yard-view-no-selected-warehouse"),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _vm.isGateManagementEnabled
            ? _c(
                "v-navigation-drawer",
                {
                  staticClass: "yard-navigation-drawer",
                  attrs: {
                    app: "",
                    right: "",
                    permanent: "",
                    temporary: _vm.isAssetVisitChatVisible,
                    width: _vm.isAssetVisitChatVisible ? 756 : 256,
                  },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "slide-fade", mode: "out-in" } },
                    [
                      _vm.isAssetVisitChatVisible
                        ? _c("yard-view-asset-visit-detail-chat", {
                            attrs: { "asset-visit": _vm.assetVisitForChat },
                          })
                        : [
                            _vm.selectedSpot?.id
                              ? _c("yard-view-spot-detail")
                              : _vm.selectedAssetVisit?.id
                              ? _c("yard-view-asset-visit-detail")
                              : _c("yard-view-not-assigned-list"),
                          ],
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isLoading ? _c("yard-view-loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }