<template>
  <v-card rounded elevation="8" v-if="ready">
    <v-card-title>
      <span class="mr-2">Next Available Appointment Report</span>
      <help-icon-tooltip>
        This report shorts the next available appointment from today for each dock and load type.
        <br /><br />
        You can toggle dock or load type by clicking the <b>group</b> header. <br /><br />This is a
        new experimental feature and we will be iterating on it for the next release, so please
        share feedback with the Opendock customer support team.
      </help-icon-tooltip></v-card-title
    >
    <v-card-text class="pa-1">
      <v-data-table
        :items="loadTypeMetrics"
        :headers="headers"
        show-group-by
        group-by="dockName"
        sort-by="start"
        hide-default-header
        hide-default-footer
        disable-pagination
        dense
        class="elevation-4">
        <template v-slot:header="{ props, on }">
          <thead>
            <tr>
              <th :colspan="props.headers.length">
                <h3 class="my-3 text-right text--black">
                  Grouped by {{ getGroupByLabel(props.options.groupBy) }} <br />
                  Soonest availability: {{ getEarlyDate(loadTypeMetrics) }}
                </h3>
              </th>
            </tr>
            <tr>
              <template v-for="header in props.headers">
                <th :key="header.value">
                  <span>{{ header.text }}</span>
                  <v-btn @click.stop="on.sort(header.value)" icon>
                    <v-icon small :title="`Sort by ${header.text}`">mdi-arrow-down</v-icon>
                  </v-btn>
                  <v-btn @click.stop="on.group(header.value)" icon>
                    <v-icon small :title="`Group by ${header.text}`">
                      mdi-format-list-group-plus
                    </v-icon>
                  </v-btn>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template #group.header="{ group, groupBy, headers, toggle, isOpen, items }">
          <th :colspan="headers.length - 1">
            <v-icon small @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
            <strong> {{ getGroupByLabel(groupBy) }} </strong>
            : {{ group }}
          </th>
          <th>
            <span v-if="getGroupByLabel(groupBy) !== 'Start'">
              Soonest: {{ getEarlyDate(items) }}
            </span>
          </th>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>

  <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
</template>

<script>
export default {
  data() {
    return {
      loadTypeMetrics: [],
      loadTypeNames: new Set(),
      ready: false,
      chartData: [],
      errorMsg: null,
      dataTest: '',
      headers: [
        { text: 'Dock', value: 'dockName' },
        { text: 'LoadType', value: 'loadTypeName' },
        { text: 'Start', value: 'start' }
      ]
    };
  },
  computed: {
    loadTypeIds() {
      return this.warehouseDocks.length > 0
        ? _.uniq(this.warehouseDocks.map(d => d.loadTypeIds).flat())
        : [];
    },
    warehouseDocks() {
      return this.selectedWarehouse?.docks ?? [];
    }
  },
  props: {
    selectedWarehouse: {
      type: Object,
      required: true,
      default: null
    }
  },
  watch: {
    selectedWarehouse() {
      this.getData();
    }
  },
  methods: {
    getEarlyDate(groupItems) {
      const minDate = Math.min(
        ...groupItems.map(
          i => +this.formatDate(i.startOriginal, this.novaCore.DateTimeFormats.Date)
        )
      );

      return this.formatDate(String(minDate));
    },
    getGroupByLabel(groupByArray) {
      return this.headers.find(h => h.value === groupByArray[0]).text;
    },
    formatDate(date, format = this.novaCore.DateTimeFormats.MonthDayYearSlashed) {
      // THe date is already on the warehouse timezone from the API
      return momentjs(date).format(format);
    },
    formatData(res) {
      this.loadTypeMetrics = [];
      Object.values(res).forEach(loadTypeAvail => {
        this.loadTypeNames.add(loadTypeAvail.loadTypeName);
        Object.values(loadTypeAvail.firstAvailAppts).forEach(dockData => {
          this.loadTypeMetrics.push({
            dockName: dockData.dockName,
            loadTypeName: loadTypeAvail.loadTypeName,
            start: this.formatDate(dockData.startLocal),
            startOriginal: dockData.startLocal
          });
        });
      });

      this.loadTypeMetrics = this.loadTypeMetrics.sort(
        (a, b) =>
          this.formatDate(b.startOriginal, this.novaCore.DateTimeFormats.Date) -
          this.formatDate(a.startOriginal, this.novaCore.DateTimeFormats.Date)
      );
    },
    async getData() {
      if (this.selectedWarehouse?.id && this.warehouseDocks.length > 0) {
        const response = await axios.post(`/metrics/loadtype/first-avail-appt`, {
          warehouseId: this.selectedWarehouse.id,
          loadtypeIds: this.loadTypeIds
        });

        if (response?.data) {
          this.formatData(response.data?.data ?? {});
          this.ready = true;
        }
      }
    }
  },
  mounted() {
    setInterval(() => {
      this.getData();
    }, 600000);

    this.getData();
  }
};
</script>
