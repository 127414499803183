var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "is-relative full-height overflow-auto",
      attrs: { id: "appointments-page-container-mobile" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-space-between px-2 py-4 align-center",
          attrs: { id: "appointments-list-header-mobile" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "mobile-icon-btn",
              attrs: {
                icon: "",
                disabled: _vm.isToday,
                "data-testid": "today-button",
              },
              on: { click: _vm.setToday },
            },
            [_c("v-icon", [_vm._v("mdi-calendar")])],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d-flex flex-1 date-nav align-center justify-center",
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mobile-icon-btn",
                  attrs: { icon: "", "data-testid": "prev-day-button" },
                  on: { click: _vm.dateNavPrev },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("date-picker", {
                    attrs: {
                      "external-trigger": "",
                      "no-title": false,
                      "date-picker-wrapper-class": "centered",
                      color: "#015A86",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "external-trigger",
                        fn: function ({ openDatePicker }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "caption",
                                attrs: {
                                  plain: "",
                                  "current-date": _vm.$selectedDate,
                                  "data-testid": "date-picker-trigger",
                                },
                                on: { click: openDatePicker },
                              },
                              [_vm._v(_vm._s(_vm.formattedHeaderDate))]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedDate,
                      callback: function ($$v) {
                        _vm.selectedDate = $$v
                      },
                      expression: "selectedDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mobile-icon-btn",
                  attrs: { icon: "", "data-testid": "next-day-button" },
                  on: { click: _vm.dateNavNext },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "mobile-icon-btn",
              attrs: { icon: "", "data-testid": "appointment-search-mobile" },
              on: {
                click: function ($event) {
                  _vm.showSearchDialog = true
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-magnify")])],
            1
          ),
        ],
        1
      ),
      _c("appointments-list-mobile"),
      _c("appointment-search-dialog-mobile", {
        attrs: {
          fullscreen: "",
          "show-dialog": _vm.showSearchDialog,
          "content-class": "mobile-dialog",
          "external-activator": "",
        },
        on: {
          close: function ($event) {
            _vm.showSearchDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }