var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          "no-padding": "",
          tile: "",
          loading: false,
          header: "",
          "hide-close-icon": "",
          "loading-message": "",
          "max-width": "100%",
          value: _vm.showDialog,
          width: "980px",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            {
              key: "header-actions",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b({}, "primary-button", slotProps.attrs, false),
                          slotProps.on
                        ),
                        [_vm._v("View Appointment")]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "pa-4 d-flex align-center justify-space-between",
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "disabled-bg",
                          attrs: {
                            icon: "",
                            large: "",
                            color: "black",
                            background: "white",
                          },
                          on: { click: _vm.close },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c("mobile-opendock-logo"),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "my-4 d-flex flex-column align-center justify-center white--text px-4",
                    },
                    [
                      _c("strong", { staticClass: "is-uppercase" }, [
                        _vm._v(_vm._s(_vm.userName)),
                      ]),
                      _c("span", { staticClass: "font-size-x-small" }, [
                        _vm._v(_vm._s(_vm.userRole)),
                      ]),
                    ]
                  ),
                  _c("navigation-drawer-mobile", {
                    attrs: { "org-required": true },
                    on: { close: _vm.close },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }