import { createFeatureFlagPlugin, useClient, useContext } from '@loadsmart/vue-feature-flags';
import { pick, isEqual } from 'lodash';

import * as Core from '@nova/core';
import config from '@/config/config';
import { $eventHub } from '@/eventHub';

/** @typedef {import('unleash-proxy-client').UnleashClient} UnleashClient */

/**
 * Safely parse JWT token.
 * @param {string} token
 * @returns {Record<string, any> | null}
 */
function parseJwt(token) {
  const str = token?.split?.('.')?.at?.(1);
  return str ? Core.json.safeParse(atob(str)) : null;
}

/**
 * Get feature flag context from the JWT token.
 * @return Record<string, any>
 */
function getCtxFromJwt() {
  const token = sessionStorage?.getItem?.('access_token') || '';
  const data = parseJwt(token);
  return {
    userId: data?.id || null,
    orgId: data?.orgId || null,
    companyId: data?.companyId || null,
    role: data?.role || null
  };
}

/**
 * Check if user is currently logged in with valid token.
 * @return {boolean}
 */
function isAuthenticated() {
  const exp = parseJwt(sessionStorage?.getItem?.('access_token') || '')?.exp ?? 0;
  return exp > 0 && new Date(exp * 1000) > new Date();
}

/**
 * Get the Authorization header for the feature flag client.
 * @returns {string}
 */
export function getAuthHeader() {
  const key = 'access_token';
  const token = sessionStorage?.getItem(key) || localStorage?.getItem(key);
  return `Bearer ${token || ''}`;
}

/**
 * Custom fetch for the feature flag client.
 * It will inject the current authorization header for the user instead
 * of using an static value.
 * @param {string | URL} url
 * @param {RequestInit} init
 * @returns {Response}
 */
function customFetch(url, init) {
  init = init || {};
  init.headers = init.headers || {};
  init.headers.Authorization = getAuthHeader();
  init.headers['X-UI-AppName'] = config.ui_app_name;
  return fetch(url, init);
}

/**
 * @param {UnleashClient} client
 * @param {boolean} forceReload
 * @returns {boolean}
 */
function shouldReload(client, forceReload) {
  const curCtx = getCtxFromJwt();
  const oldCtx = pick(client.getContext(), ...Object.keys(curCtx));
  return (
    forceReload ||
    !isEqual(curCtx, oldCtx) ||
    client.clientKey !== getAuthHeader() ||
    client.url.toString() !== config.unleash_url.toString() ||
    client.fetch !== customFetch
  );
}

/**
 * When there's either a login or a logout event we need
 * to restart the feature flag plugin client to guarantee
 * that it's using the freshest user data.
 *
 * @param {boolean} [forceReload]
 */
async function restartClient(forceReload = true) {
  const { client } = useClient();
  const { updateContext } = useContext();

  if (!client) {
    console.error('[featureFlagPlugin] could not get client');
    return;
  }

  if (!shouldReload(client, forceReload)) {
    return;
  }

  await updateContext(getCtxFromJwt());

  try {
    client.clientKey = getAuthHeader();
    client.url = config.unleash_url;
    client.fetch = customFetch;

    client.stop();
    await client.start();
  } catch (error) {
    console.error('[featureFlagPlugin] failed to restart client', error);
  }
}

/*
 * Returns whether or not we should enable auto start.
 * If the user is logged in and not on the login page then we should auto start.
 * @return {boolean}
 */
function shouldAutoStart() {
  const { pathname } = window.location;
  return isAuthenticated() && pathname !== '/login';
}

/** @type {[name: string, forceReload: boolean][]} */
const events = [
  ['auth/getMe', true],
  ['auth/logout', true],
  ['app/routeChanged', false]
];
events.forEach(([event, forceReload]) => {
  $eventHub.$on(event, () => restartClient(forceReload));
});

export default createFeatureFlagPlugin({
  config: {
    url: config.unleash_url,
    appName: config.unleash_app_name,
    clientKey: getAuthHeader(),
    environment: config.server_env === 'production' ? 'production' : 'development',
    fetch: customFetch,
    disableMetrics: true,
    usePOSTrequests: true
  },
  /**
   * With auto start enabled we might start fetching the feature flags
   * before the user logs in, in which case we will restart the client
   * to refetch the flags as the user, but this can cause a "flicker"
   * in the screen where we show the disabled version and then the enabled
   * one.
   * To prevent this auto start has been disable when the user is not logged
   * in or when the user is at the login page.
   */
  disableAutoStart: !shouldAutoStart()
});
