<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :header="headerText"
    max-width="85vw"
    width="85vw"
    :loading="false"
    :scrollable="true"
    @close="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else before-icon="users" v-on="slotProps.on" v-bind="slotProps.attrs" large>
        View Users
      </primary-button>
    </template>
    <template v-slot:body>
      <user-list :org-id="orgId" flat :max-items-per-page="5"></user-list>
    </template>
    <template v-slot:dialog-actions>
      <action-group @confirm="close" hide-cancel confirm-label="close"></action-group>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    orgId: {
      type: String,
      required: true
    },
    headerText: {
      type: String,
      required: false,
      default: 'Users'
    }
  }
};
</script>
