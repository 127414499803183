import { debounce } from 'debounce';
import { isEqual } from 'lodash';

/**
 * Mixin used for any list where filtering/searching is required
 * @mixin
 * @displayName Data List Mixin
 */
export default {
  props: {
    maxItemsPerPage: {
      type: Number,
      required: false
    }
  },
  computed: {
    areActionsDisplayed() {
      return true;
    },
    rowHeaders() {
      const headersCopy = this.novaCore.deepClone(this.headers);
      if (!this.areActionsDisplayed) {
        headersCopy.pop();
        headersCopy[headersCopy.length - 1].align = 'end';
      }

      return headersCopy;
    },
    regexSafeSearch() {
      if (this.filters.searchStr) {
        return _.escapeRegExp(this.filters.searchStr);
      }

      return this.filters.searchStr;
    }
  },

  data() {
    return {
      options: {
        itemsPerPage: this.maxItemsPerPage ?? 15
      },
      filters: {
        searchStr: ''
      },
      sortDesc: [false],
      // These need to be set in your component
      joins: [],
      sortBy: [],
      expanded: [],
      searchFields: [],
      footerProps: {
        itemsPerPageOptions: this.maxItemsPerPage ? [this.maxItemsPerPage] : [5, 10, 15, 30],
        showFirstLastPage: true
      },
      searchFieldsToSplit: ['firstName', 'lastName'],
      shouldUseDefaultFilterWatcher: true
    };
  },
  watch: {
    options(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.debounceInput();
      }
    },
    filters: {
      handler(filters) {
        if (this.shouldUseDefaultFilterWatcher) {
          if (this.shouldGetData(filters)) {
            this.debounceInput();
          }
        }
      },
      deep: true
    },
    'filters.searchStr'() {
      this.options.page = 1;
    }
  },
  filters: {
    highlight(value, query, itemId, type, searchHighlightClass = 'orange lighten-3') {
      if (typeof value !== 'string') {
        return value;
      }

      let r = value?.replace(
        new RegExp(query, 'ig'),
        v => `<span class='${searchHighlightClass}'>${v}</span>`
      );

      if (type === 'tag') {
        r = `${r}, `;
      }

      return r;
    }
  },
  methods: {
    /**
     * Builds query with sort, pagination, filter, and join data
     * This method is called by getQuery and can be access by the child, but should not be overriden by the child
     * @returns Object
     */
    getQueryBase() {
      let query = {
        ...{
          s: {},
          page: this.options.page,
          limit: this.options.itemsPerPage
        },
        ...this.novaCore.deepClone(this.filters)
      };

      if (this.joins) {
        query.join = this.joins;
      }

      if (this.filters.searchStr && this.filters.searchStr !== '') {
        query.s['$or'] = this.getSearchableFields().map(field => {
          return {
            [field]: { $contL: this.filters.searchStr }
          };
        });

        for (const splitField of this.searchFieldsToSplit) {
          if (this.getSearchableFields().includes(splitField)) {
            query.s['$or'].push({
              [splitField]: { $inL: this.filters.searchStr.toLowerCase().split(' ') }
            });
          }
        }
      }

      if (this.sortBy.length) {
        query.sort = `${this.sortBy[0]},${this.sortDesc[0] ? 'DESC' : 'ASC'}`;
      }

      if (Number(query?.limit) === -1) {
        query.limit = '100000';
      }

      return query;
    },
    /**
     * Builds query with sort, pagination, filter, and join data
     * This method calls base, and can be overridden in a child component to replace or add filters
     * @public
     * @returns Object
     */
    getQuery() {
      return this.getQueryBase();
    },
    /**
     * Generates an array of objects using the headers array that can be used to create a searchable fields select
     * @returns Array
     */
    getSearchableFields() {
      if (this.headers && !this.searchFields.length) {
        return this.headers.filter(header => header.searchable).map(header => header.value);
      } else {
        return this.searchFields;
      }
    },
    /**
     * Runs the parent component's getData method with a debounce
     * @returns VoidFunction
     */
    debounceInput: debounce(function () {
      this.getData();
    }, 350),
    // eslint-disable-next-line no-unused-vars
    shouldGetData(filters) {
      return true;
    },
    getData() {
      // Default so error is not thrown if not using "getData" in component
    },
    toggleRowExpansion(item) {
      if (this.expanded.includes(item)) {
        const index = this.expanded.findIndex(i => i === item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    searchRows(value, search, item) {
      if (!value) {
        return false;
      }
      if (!search) {
        return true;
      }
      let hasMatch = false;
      let hasExpandedMatch = false;
      const isArr = Array.isArray(value);

      const s = search.toLowerCase();

      if (typeof value === 'string') {
        hasMatch = value.toLowerCase().search(s) !== -1;
      }

      if (isArr && !hasMatch) {
        const filtered = value.filter(i => {
          i = i && typeof i === 'object' ? Object.values(i).join(', ') : i;
          return typeof i === 'string' ? i.toLowerCase().search(s) !== -1 : false;
        });
        hasMatch = filtered.length > 0;
        hasExpandedMatch = hasMatch;
      }

      if (typeof value === 'object' && !isArr && !hasMatch) {
        hasMatch =
          Object.values(value).filter(i => {
            return i.toLowerCase().search(s) !== -1;
          }).length > 0;
        hasExpandedMatch = hasMatch;
      }

      if (hasExpandedMatch) {
        this.expanded.push(item);
      }

      return hasMatch;
    }
  }
};
