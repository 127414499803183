<template>
  <dialog-base
    :key="renderKey"
    :loading="false"
    v-bind="$props"
    header="Cancel Appointment?"
    loading-message="Cancelling appointment..."
    @close="close"
    @confirm="confirm"
    max-width="100%"
    has-dialog-actions
    :value="showDialog"
    width="500px">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs">
        Cancel Appointment
      </primary-button>
    </template>
    <template #customHeader>
      <header class="full-width">
        <dialog-header header="Cancel Appointment?" @close="close">
          <template #close-icon>
            <v-icon>mdi-close</v-icon>
          </template>
        </dialog-header>
      </header>
    </template>
    <template v-slot:body>
      <div class="px-6 pt-4 d-flex align-center">
        <v-icon small color="error" class="mr-1">mdi-alert-circle-outline</v-icon>
        <span>You cannot undo this action.</span>
      </div>
      <cancel-appointment-form
        ref="form"
        @nevermind="nevermind"
        :mixpanel-entry-point="mixpanelEntryPoint"
        @confirm="confirm"
        :appointment="appointment"></cancel-appointment-form>
    </template>
  </dialog-base>
</template>

<script>
import { dialogBaseProps, useDialogBase, useRenderKey } from '@/composables';
import { ref } from 'vue';

export default {
  props: {
    ...dialogBaseProps,
    appointment: {
      type: Object,
      required: true
    },
    mixpanelEntryPoint: {
      type: String,
      required: false
    }
  },
  emits: ['close'],
  setup(props, context) {
    const { renderKey } = useRenderKey();

    const onClose = () => {
      context.emit('close');
    };

    const onDismiss = () => context.emit('close');
    const onConfirm = () => context.emit('close');

    const { close, confirm, nevermind, openConfirmation } = useDialogBase({
      onClose,
      onDismiss,
      onConfirm
    });

    const customFields = ref([]);
    const tags = ref([]);
    const refNumber = ref('');

    return {
      renderKey,
      customFields,
      tags,
      refNumber,
      close,
      confirm,
      nevermind,
      openConfirmation
    };
  }
};
</script>
