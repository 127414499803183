<template>
  <dialog-base
    :key="renderKey"
    :loading="false"
    v-bind="$props"
    :header="dialogTitle"
    loading-message="Creating Appointments ..."
    content-class="create-recurring-series-dialog"
    @close="close"
    max-width="100%"
    has-dialog-actions
    :value="showDialog"
    width="600px">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs">
        Create Recurrence
      </primary-button>
    </template>
    <template v-slot:body>
      <create-recurring-series-form
        v-if="showDialog"
        @update-title="updateDialogTitle"
        :appointment="appointment"
        @close="close"></create-recurring-series-form>
    </template>
  </dialog-base>
</template>

<script>
import { dialogBaseProps, useDialogBase, useRenderKey } from '@/composables';
import { ref } from 'vue';

export default {
  name: 'CreateRecurringSeriesDialog',
  props: {
    ...dialogBaseProps,
    appointment: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  setup(props, context) {
    const { renderKey } = useRenderKey();

    const onClose = () => {
      context.emit('close');
    };

    const { close } = useDialogBase({
      onClose
    });
    const dialogTitle = ref('Create Recurrence');

    const updateDialogTitle = title => {
      dialogTitle.value = title;
    };

    return {
      dialogTitle,
      updateDialogTitle,
      close,
      renderKey
    };
  }
};
</script>

<style lang="scss">
.create-recurring-series-dialog {
  min-height: 550px;
}
</style>
