import { IsUUID } from 'class-validator';

// initial state
const state = {
  org: {},
  orgs: [],
  pagination: {},
  filters: {},
  billing: {}
};

// getters
const getters = {};

// actions
const actions = {
  async handleSocketEvent({ dispatch, state }, payload) {
    if (state.org?.id && state.org.id === payload.data.id) {
      dispatch('getOrg', state.org.id);
    }
  },
  // TODO document payload keys
  async createOrg({ commit, state, dispatch }, payload) {
    let response = await axios.post('org', payload);

    if (response?.data?.data) {
      commit('setOrg', response.data.data);
      dispatch('Auth/refreshToken', {}, { root: true });
    }

    return state.org;
  },
  async getOrg({ commit, state }, orgId) {
    let response = await axios.get(`org/${orgId}`);

    if (response?.data?.data) {
      commit('setOrg', response.data.data);
    }

    return state.org;
  },
  async updateOrg({ commit }, data) {
    let response = await axios.patch(`org/${state.org.id}`, data);

    commit('setOrgFromResponse', response);

    return state.org;
  },
  async getOrgs({ commit }, filters = {}) {
    let response = await axios.get(`org`, {
      params: filters || state.filters
    });

    if (response?.data) {
      commit('setFilters', filters || state.filters);
      commit('setPagination', response.data);
      commit('setOrgs', response.data.data);
    }

    return response?.data?.data;
  },
  async deactivateOrg({}, data) {
    if (!IsUUID(data?.id)) {
      throw new Error('Invalid org uuid');
    }
    return axios.patch(`org/${data.id}/deactivate`, data);
  },
  async activateOrg({}, data) {
    if (!IsUUID(data?.id)) {
      throw new Error('Invalid org uuid');
    }
    return axios.patch(`org/${data.id}/activate`, data);
  },
  async getBilling({ commit }) {
    let response = await axios.get('billing');

    if (response.status === 200 && response.data) {
      commit('setBilling', response.data);
    }

    return state.billing;
  },
  async updateBilling({ commit, state }, data) {
    const { payload, entryPoint } = data;
    let response = await axios.patch('billing', payload);

    if (response.status === 200 && response.data) {
      commit('setBilling', response.data);
      this.$app.$eventHub.$emit('update-Subscription', state.billing);

      // Track billing update on mixpanel
      const org = this.$app.$org;
      if (org && org.name && org.id) {
        const mixpanel = this.$app.mixpanel;
        mixpanel?.track(mixpanel.events.MODULE.SUBSCRIPTION.UPDATED, {
          'Org Name': org.name,
          'Org ID': org.id,
          'Entry Point': entryPoint
        });
      }
      this.$app.notify('Billing details successfully updated');
    }

    return state.billing;
  },
  async sendBillingInquiry({}, payload) {
    return await axios.post('billing/support', payload);
  }
};

// mutations
const mutations = {
  setOrg(state, org) {
    state.org = org;
  },
  setOrgs(state, orgs) {
    state.orgs = orgs || [];
  },
  setOrgFromResponse(state, response) {
    if (response?.data?.data) {
      state.org = response.data.data;
    }
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setPagination(state, response) {
    state.pagination = {
      page: response.page,
      pageCount: response.pageCount,
      total: response.total
    };
  },
  setBilling(state, billing) {
    state.billing = billing;
  }
};

export default {
  namespaced: true,
  name: 'Orgs',
  state,
  getters,
  actions,
  mutations
};
