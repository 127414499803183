var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-6" },
    [
      _c("h5", { staticClass: "mb-2" }, [
        _vm._v("+ Add/Remove fields to the form"),
      ]),
      _c(
        "v-autocomplete",
        _vm._b(
          {
            ref: "dropdown",
            attrs: {
              loading: _vm.loading,
              items: _vm.fields,
              "search-input": _vm.searchStr,
              outlined: "",
              "hide-details": "",
              "item-text": "label",
              "menu-props": {
                auto: true,
                closeOnClick: false,
                closeOnContentClick: false,
              },
              placeholder: "Search by field name...",
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.searchStr = $event
              },
              "update:search-input": function ($event) {
                _vm.searchStr = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { "align-self": "start", cols: "6" } },
                          [_vm._v(_vm._s(item.label))]
                        ),
                        _c("v-col", [
                          _c(
                            "strong",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [
                                  _vm._v(
                                    "mdi-" +
                                      _vm._s(
                                        _vm.novaCore.customFieldIcons[item.type]
                                      )
                                  ),
                                ]
                              ),
                              _c("small", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.novaCore.customFieldLabels[item.type]
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "v-col",
                          { attrs: { "align-self": "end", cols: "3" } },
                          [
                            _c(
                              "div",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      color:
                                        _vm.fieldActions[
                                          _vm.getFieldAction(item)
                                        ].color,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        _vm.$emit(
                                          _vm.getFieldAction(item),
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.fieldActions[
                                                _vm.getFieldAction(item)
                                              ].icon
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.fieldActions[
                                              _vm.getFieldAction(item)
                                            ].text
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "selection",
                fn: function () {
                  return undefined
                },
                proxy: true,
              },
              {
                key: "append-item",
                fn: function () {
                  return [
                    _c("v-list-item", [
                      _vm._v(" Missing a field? "),
                      _c(
                        "span",
                        {
                          staticClass: "secondary--text ml-2 cursor-pointer",
                          on: { click: _vm.handleCreateField },
                        },
                        [_c("strong", [_vm._v("Create new")])]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "v-autocomplete",
          _vm.$props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }