var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hero" }, [
    _c("div", { staticClass: "login-page-hero" }),
    _c("div", { staticClass: "image-group" }, [
      _c("img", {
        staticClass: "opendock-logo",
        attrs: { src: _vm.$logoPath },
      }),
    ]),
    _c("div", { staticClass: "auth-page-tag" }, [
      _vm._v("Dock scheduling software for Warehouses"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }