<template>
  <dialog-base
    no-padding
    tile
    :key="renderKey"
    :loading="loading"
    v-bind="[$props, $attrs]"
    header=""
    hide-close-icon
    loading-message=""
    @close="close"
    max-width="100%"
    :value="showDialog"
    width="980px">
    <template v-slot:header-actions></template>
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button v-on="slotProps.on" v-bind="slotProps.attrs">View Appointment</primary-button>
    </template>
    <template v-slot:body>
      <appointment-details-page-mobile
        :read-only="!canUserAccessWarehouse"
        v-if="appointmentWarehouse"
        :appointment-warehouse="appointmentWarehouse"
        :parent-appointment="parentAppointment"
        @close="close"
        :appointment="appointment"
        :should-edit-status="true"
        :should-display-cancel-button="false"></appointment-details-page-mobile>
    </template>
  </dialog-base>
</template>

<script>
import AppointmentDetailsDialogBase from '@/modules/appointments/components/details/AppointmentDetailsDialogBase.vue';

/**
 * Dialog for appointment details
 * @displayName Create Appointment Dialog Mobile
 */
export default {
  extends: AppointmentDetailsDialogBase,
  watch: {
    showDialog(newVal) {
      if (newVal) {
        this.$store.commit('App/setMobilePageTitle', 'Appointment Details');
      } else {
        this.$store.commit('App/setMobilePageTitle', this.$route.meta.title);
      }
    }
  }
};
</script>
