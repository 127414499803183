import { render, staticRenderFns } from "./DockForm.vue?vue&type=template&id=533def10"
import script from "./DockForm.vue?vue&type=script&lang=js"
export * from "./DockForm.vue?vue&type=script&lang=js"
import style0 from "./DockForm.vue?vue&type=style&index=0&id=533def10&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/apps/helios/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('533def10')) {
      api.createRecord('533def10', component.options)
    } else {
      api.reload('533def10', component.options)
    }
    module.hot.accept("./DockForm.vue?vue&type=template&id=533def10", function () {
      api.rerender('533def10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/docks/components/DockForm.vue"
export default component.exports