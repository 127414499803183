var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "carrier-contact-data-table-wrapper" },
    [
      _c("v-data-table", {
        class: { "data-table-no-results": _vm.isEmptyState },
        attrs: {
          "data-testid": "carrier-contacts-data-table",
          "hide-default-footer": _vm.isEmptyState,
          headers: _vm.headers,
          loading: _vm.carrierContacts.isLoadingRef.value,
          items: _vm.carrierContacts.carrierContactsRef.value,
          "items-per-page": _vm.carrierContacts.paramsRef.itemsPerPage,
          "sort-by": _vm.carrierContacts.paramsRef.sortBy,
          "sort-desc": _vm.carrierContacts.paramsRef.sortDesc,
          "server-items-length": _vm.carrierContacts.paginationRef.value.total,
          "footer-props": { "items-per-page-options": [10, 20, 30, 40, 50] },
        },
        on: {
          "update:sortBy": function ($event) {
            return _vm.$set(_vm.carrierContacts.paramsRef, "sortBy", $event)
          },
          "update:sort-by": function ($event) {
            return _vm.$set(_vm.carrierContacts.paramsRef, "sortBy", $event)
          },
          "update:sortDesc": function ($event) {
            return _vm.$set(_vm.carrierContacts.paramsRef, "sortDesc", $event)
          },
          "update:sort-desc": function ($event) {
            return _vm.$set(_vm.carrierContacts.paramsRef, "sortDesc", $event)
          },
          "update:options": _vm.handleOptionsUpdate,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between align-center data-table-top",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-field-wrapper d-flex align-center",
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "text-field",
                            attrs: {
                              flat: "",
                              solo: "",
                              dense: "",
                              clearable: "",
                              "single-line": "",
                              "hide-details": "",
                              label: "Find in this table...",
                              "prepend-icon": "mdi-magnify",
                              loading: _vm.carrierContacts.isLoadingRef.value,
                            },
                            model: {
                              value: _vm.searchStr,
                              callback: function ($$v) {
                                _vm.searchStr = $$v
                              },
                              expression: "searchStr",
                            },
                          }),
                          _c("p", { staticClass: "mb-0" }, [
                            _c("span", { staticClass: "font-weight-medium" }, [
                              _vm._v(
                                " " + _vm._s(_vm.totalCarrierContacts) + " "
                              ),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.totalCarrierContacts === 1
                                    ? "result"
                                    : "results"
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      !_vm.novaCore.isInternalUser(_vm.$me)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "switch-wrapper d-flex align-center justify-end",
                            },
                            [
                              _c("v-switch", {
                                staticClass: "mt-0",
                                attrs: {
                                  dense: "",
                                  ripple: "",
                                  "hide-details": "",
                                  label: "Favorites only",
                                  "data-testid":
                                    "carrier-contacts-favorite-only-button",
                                },
                                model: {
                                  value: _vm.favoritesOnly,
                                  callback: function ($$v) {
                                    _vm.favoritesOnly = $$v
                                  },
                                  expression: "favoritesOnly",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            _vm._l(_vm.headers, function (header) {
              return {
                key: `item.${header.value}`,
                fn: function ({ item }) {
                  return [
                    _vm.getItemValue(item, header.value)
                      ? _c("span", { key: `${header.value}-if` }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getItemValue(item, header.value)) +
                              " "
                          ),
                        ])
                      : _c(
                          "span",
                          {
                            key: `${header.value}-else`,
                            staticClass: "item-no-value",
                          },
                          [_vm._v("None")]
                        ),
                  ]
                },
              }
            }),
            {
              key: "item.emailCCs",
              fn: function ({ item }) {
                return [
                  _c("carrier-email-c-cs-column", {
                    attrs: { "carrier-contact": item },
                  }),
                ]
              },
            },
            !_vm.novaCore.isInternalUser(_vm.$me)
              ? {
                  key: "item.favoriteWarehouses",
                  fn: function ({ item }) {
                    return [
                      _c("carrier-contact-warehouse-dialog", {
                        attrs: {
                          favoriteWarehouses: item.favoriteWarehouses,
                          isOrgFavorite: item.isOrgFavorite,
                          isFavorite: item.isFavorite,
                          carrier: item.user,
                          company: item.company,
                        },
                      }),
                    ]
                  },
                }
              : null,
            {
              key: "item.actions",
              fn: function ({ item }) {
                return [
                  !_vm.novaCore.isInternalUser(_vm.$me)
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-row justify-end" },
                        [
                          _c("carrier-contact-manage-dialog", {
                            attrs: { "carrier-contact": item },
                          }),
                          _c("carrier-contact-actions-menu", {
                            attrs: { "carrier-contact": item },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.novaCore.isInternalUser(_vm.$me)
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-row justify-end" },
                        [
                          _c("edit-carrier-dialog", {
                            attrs: {
                              carrier: { ...item, ...item.user },
                              "entry-point": "Carrier Contacts Page",
                            },
                            on: {
                              save: function ($event) {
                                return _vm.carrierContacts.fetch()
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                size: "large",
                                                color: "secondary",
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-pencil ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  _vm.isEmptyState
                    ? _c(
                        "div",
                        {
                          staticClass: "empty-state-overlay py-16",
                          attrs: {
                            "data-testid":
                              "carrier-contacts-data-table-empty-overlay",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "40px" } }, [
                            _vm._v("mdi-truck-outline"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text" },
                            [
                              !_vm.favoritesOnly
                                ? [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "font-weight-medium header",
                                      },
                                      [
                                        _vm._v(
                                          " You don't have any contacts on your Org's list "
                                        ),
                                      ]
                                    ),
                                    _c("p", { staticClass: "subheader" }, [
                                      _vm._v(
                                        "Start searching to add carrier contacts"
                                      ),
                                    ]),
                                  ]
                                : [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "font-weight-medium header",
                                      },
                                      [
                                        _vm._v(
                                          "No contacts have been favorited yet"
                                        ),
                                      ]
                                    ),
                                    _c("p", { staticClass: "subheader" }, [
                                      _vm._v(
                                        " Go back to the contact's list and click on the star icon to start favoriting contacts "
                                      ),
                                    ]),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }