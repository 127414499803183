<template>
  <div class="d-flex flex-row align-center mb-4 full-width">
    <v-select
      @input="updateValue"
      :value="value"
      :items="customFields"
      :rules="
        minimumSelections ? $validator.rules.selectAtLeast(minimumSelections, 'Custom Field') : []
      "
      clearable
      class="pt-0 mt-0"
      v-bind="[$props, $attrs]"
      ref="customFieldSelect"
      @click:clear="updateValue({})">
      <template #label v-if="required">
        {{ placeholder }} <span class="red--text"><strong>*</strong></span>
      </template>

      <template v-slot:prepend-item>
        <v-list-item ripple @click="toggleSelectAllItems">
          <v-list-item-action>
            <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider slot="prepend-item" class="mt-2" />
      </template>
      <template v-slot:selection="{ item, index }" v-if="multiple">
        <v-chip v-if="index < visibleSelectionCount" small color="primary" label>
          <span>{{ item.label }}</span>
        </v-chip>
        <span v-if="index === visibleSelectionCount" class="grey--text text-caption">
          (+{{ value.length - visibleSelectionCount }} other{{
            value.length === visibleSelectionCount + 1 ? '' : 's'
          }})
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';

export default {
  name: 'custom-field-select',
  mixins: [selectMixin, selectAllSelectMixin],
  props: {
    /**
     * @model
     */
    value: {
      required: true
    },
    customFields: {
      type: Array,
      required: true,
      default: () => []
    },
    autoSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    minimumSelections: {
      type: Number,
      required: false,
      default: null
    },
    visibleSelectionCount: {
      type: Number,
      required: false,
      default: 3
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectRefKey: 'customFieldSelect'
    };
  },
  computed: {
    selectableItems() {
      return this.customFields;
    }
  },
  methods: {
    updateValue(customFields) {
      this.$emit('input', customFields);
    },
    setcustomFields() {
      if (this.autoSelect || this.value.length === 0) {
        this.updateValue(this.customFields);
      }
    }
  },
  mounted() {
    this.setcustomFields();
  }
};
</script>
