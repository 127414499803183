<template>
  <table class="pt-5 audit-log-table" v-if="logLines.length">
    <thead></thead>
    <tbody>
      <template v-for="(item, index) in orderedLogLines">
        <tr :key="`audit-item-${item.id}-${index}`">
          <td class="align-vertical-top">
            <span class="d-block text-right font-weight-bold">{{ getItemDate(item) }}</span>
            <span class="d-block text-right">{{ getItemTime(item) }}</span>
          </td>
          <td class="align-vertical-top">
            <div class="circle" :class="item.lineColor">
              <div class="halo" :class="item.lineColor"></div>
            </div>
            <div class="vertical-divider mid-grey"></div>
          </td>
          <td class="align-vertical-top">
            <span class="d-block">
              <span class="entry-header">{{ item.lineTitle }}</span>
            </span>
            <span class="d-block pb-1" v-html="item.change"></span>
            <span class="d-block user-name">by {{ item.user }}</span>
          </td>
        </tr>
      </template>
      <tr v-if="hiddenLogLineCount > 0 && minimizeLines">
        <td class="align-vertical-top"></td>
        <td class="align-vertical-top expandable">
          <div class="text-center">
            <v-icon small>mdi-chevron-down</v-icon>
          </div>
          <div class="vertical-divider mid-grey"></div>
        </td>
        <td class="align-vertical-top">
          <span class="d-block">
            <v-btn text class="link" height="20" @click="minimizeLines = false"
              >{{ hiddenLogLineCount }} more updates</v-btn
            >
          </span>
        </td>
      </tr>
      <tr v-if="firstItem">
        <td class="align-vertical-top">
          <span class="d-block text-right font-weight-bold">{{ getItemDate(firstItem) }}</span>
          <span class="d-block text-right">{{ getItemTime(firstItem) }}</span>
        </td>
        <td class="align-vertical-top">
          <div class="circle" :class="firstItem.lineColor">
            <div class="halo" :class="firstItem.lineColor"></div>
          </div>
          <div class="vertical-divider mid-grey"></div>
        </td>
        <td class="align-vertical-top">
          <span class="d-block">
            <span class="entry-header">{{ firstItem.lineTitle }}</span>
          </span>
          <span class="d-block pb-1" v-html="firstItem.change"></span>
          <span class="d-block user-name">by {{ firstItem.user }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    /**
     * Limit of items to show beyond the initial insert item
     */
    lineItemLimit: {
      type: Number,
      required: false,
      default: 2
    },
    /**
     * The lines of the log to show
     */
    logLines: {
      type: Array,
      required: true
    }
  },
  computed: {
    /**
     * Returns line items in reverse order sans initial creation log item
     * @returns {T[]|*[]}
     */
    orderedLogLines() {
      const orderedLines = this.novaCore.deepClone(this.logLines).slice(1).reverse();
      return this.minimizeLines ? orderedLines.slice(0, this.lineItemLimit) : orderedLines;
    },
    /**
     * Total log line count
     * @returns {number}
     */
    totalLogLineCount() {
      return this.logLines.length;
    },
    hiddenLogLineCount() {
      return this.totalLogLineCount - this.lineItemLimit - 1;
    },
    firstItem() {
      return this.totalLogLineCount > 0 ? this.logLines[0] : null;
    }
  },
  methods: {
    getItemDate(item) {
      return this.novaCore.getFormattedTime(
        item.timestamp,
        this.novaCore.DateTimeFormats.ShortDate
      );
    },
    getItemTime(item) {
      const tz = null;
      return this.novaCore.formatDateTimeWithMilitarySupport(
        item.timestamp,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    }
  },
  data() {
    return {
      minimizeLines: true
    };
  }
};
</script>
