<template>
  <div v-if="orgId">
    <v-tabs
      v-model="activeTab"
      background-color="secondary"
      slider-color="#ffe67f"
      grow
      dark
      centered>
      <v-tab
        :class="{ [`org-tab-${index}`]: true, 'disable-click': tab.disabled }"
        v-for="(tab, index) in tabs"
        :ref="tab.title"
        :key="tab.route"
        active-class="active-tab"
        :to="{ name: tab.route, params: { orgId } }">
        {{ tab.title }}
        <template>
          <v-tooltip top :activator="`.org-tab-${index}`" :disabled="!tab.disabled">
            <span>{{ tab.disabledTooltip }}</span>
          </v-tooltip>
        </template>
      </v-tab>
    </v-tabs>

    <div :key="renderKey">
      <v-tabs-items v-model="activeTab">
        <div v-for="tab in tabs" :key="tab.route">
          <div fluid v-if="activeTab?.endsWith(tab.name)">
            <component :is="tab.component"></component>
          </div>
        </div>
      </v-tabs-items>
    </div>
  </div>
  <div v-else>
    <v-alert color="yellow lighten-4" elevation="2" class="mx-2">
      <h4>
        <v-icon color="brown darken-0" class="mr-2">mdi-alert</v-icon>
        Invalid Organization Data
      </h4>
      <div class="mt-2">
        <small>The request is invalid!</small>
      </div>
    </v-alert>
  </div>
</template>

<script>
import renderMixin from '@satellite/components/mixins/renderMixin';

import LoadTypesPage from '@/modules/load_type/LoadTypesPage';
import BillingInfo from '@/modules/orgs/components/BillingInfo';
import SettingsPage from '@/modules/settings/SettingsPage';
import OrgSsoConfigForm from '@/modules/orgs/components/OrgSsoConfigForm';
import WarehouseGroupsPage from '@/modules/warehouse_groups/WarehouseGroupsPage.vue';
import { useFeatureFlag } from '@loadsmart/vue-feature-flags';

/**
 * Warehouse Details Page
 * @displayName Warehouse Details Page
 */
export default {
  mixins: [renderMixin],
  props: {
    orgId: {
      type: String,
      required: true
    }
  },
  computed: {
    tabs() {
      const allTabs = [
        {
          route: 'org.settings',
          title: 'Org Settings',
          component: SettingsPage,
          name: 'settings'
        },
        {
          route: 'org.loadtypes',
          title: 'Org Load Types',
          component: LoadTypesPage,
          name: 'loadtypes'
        },
        {
          route: 'org.billing',
          title: 'Billing Info',
          component: BillingInfo,
          name: 'billing',
          disabled: !this.novaCore.isUserRoleGreaterOrEqual(
            this.$me.role,
            this.novaCore.UserRole.OWNER
          ),
          disabledTooltip: this.novaCore.getRoleActionError()
        },
        {
          route: 'org.ssoconfig',
          title: 'SSO Setup',
          component: OrgSsoConfigForm,
          name: 'ssoconfig'
        }
      ];

      const enableWarehouseGroupsPage = useFeatureFlag('enable-warehouse-groups-page');
      if (enableWarehouseGroupsPage.value) {
        allTabs.push({
          route: 'org.warehouse-groups',
          title: 'Warehouse Groups',
          component: WarehouseGroupsPage,
          name: 'warehouse-groups'
        });
      }

      return allTabs;
    }
  },
  data() {
    return {
      activeTab: null
    };
  },
  watch: {
    tab(oldTab, newTab) {
      if (oldTab !== newTab) {
        this.renderKey++;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.active-tab {
  background-color: #00576c;
}
</style>
