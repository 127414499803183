<template>
  <v-form ref="form">
    <v-card data-testid="yard-spot-single-form">
      <v-card-title>
        <span class="font-size-medium">Add one single spot to your yard area</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="value.code"
                label="Spot code"
                :rules="[validateCode]"
                outlined
                dense
                data-testid="yard-spot-form-code-input"></v-text-field>

              <v-select
                v-model="value.type"
                :items="Object.keys(spotType).map(k => k.toLowerCase())"
                label="Spot type"
                :rules="[validateType]"
                data-testid="yard-spot-form-type-select"
                outlined
                dense></v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { defineComponent, ref } from 'vue';

import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewSpotGeneratorSingleForm',
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  setup() {
    const yardStore = useYard();
    const form = ref(null);

    const validate = () => form.value?.validate();

    return {
      spotType: yardStore.spotType,
      validateType: yardStore.validateSpotTypeInput,
      validateCode: yardStore.validateSpotCodeInput,
      form,
      validate
    };
  }
});
</script>
