var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        ref: _vm.selectRefKey,
        attrs: {
          value: _vm.value,
          items: _vm.loadTypes,
          "hide-details": "auto",
          "item-text": (item) => _vm.getLoadTypeName(item),
          "item-value": "id",
          "return-object": "",
          clearable: "",
        },
        on: {
          input: _vm.updateValue,
          "click:clear": function ($event) {
            return _vm.updateValue([])
          },
        },
        scopedSlots: _vm._u(
          [
            _vm.loadTypes.length && _vm.multiple && !_vm.disableSelectAll
              ? {
                  key: "prepend-item",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item",
                        {
                          attrs: { ripple: "" },
                          on: { click: _vm.toggleSelectAllItems },
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color:
                                      _vm.value.length > 0
                                        ? "error darken-4"
                                        : "",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.selectAllIcon))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.selectAllLabel)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "mt-2",
                        attrs: { slot: "prepend-item" },
                        slot: "prepend-item",
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "selection",
              fn: function ({ item, index }) {
                return [
                  index === 0
                    ? _c("span", [_vm._v(_vm._s(item.name))])
                    : _vm._e(),
                  index === 1
                    ? _c(
                        "span",
                        { staticClass: "grey--text text-caption ml-2" },
                        [
                          _vm._v(
                            " (+" + _vm._s(_vm.value.length - 1) + " others) "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "append-item",
              fn: function () {
                return [
                  [
                    _vm.isAtSelectionLimit
                      ? _c("select-limit-list-item", {
                          attrs: { limit: _vm.maximumSelections },
                        })
                      : _vm._e(),
                  ],
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "v-select",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }