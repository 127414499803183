var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        ref: "warehouseSelect",
        staticClass: "pt-0",
        attrs: {
          "menu-props": { auto: _vm.shouldDisplayAllItemsImmediately },
          "hide-details": "auto",
          "search-input": _vm.filters.searchStr,
          value: _vm.value,
          items: _vm.warehouses,
          loading: _vm.isLoading,
          "item-text": "name",
          "item-value": "id",
          required: _vm.required,
          "persistent-placeholder": "",
          placeholder: "Select Warehouses",
          "return-object": "",
        },
        on: {
          input: _vm.updateValue,
          "update:searchInput": function ($event) {
            return _vm.$set(_vm.filters, "searchStr", $event)
          },
          "update:search-input": function ($event) {
            return _vm.$set(_vm.filters, "searchStr", $event)
          },
        },
        scopedSlots: _vm._u(
          [
            _vm.warehouses.length && _vm.multiple
              ? {
                  key: "prepend-item",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item",
                        {
                          attrs: { ripple: "" },
                          on: { click: _vm.toggleSelectAllItems },
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color:
                                      _vm.value.length > 0
                                        ? "error darken-4"
                                        : "",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.selectAllIcon))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.selectAllLabel)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "mt-2",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-item",
                              fn: function () {
                                return undefined
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1930050441
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "item",
              fn: function ({ item, attrs, on }) {
                return [
                  _c(
                    "v-list-item",
                    _vm._g(
                      _vm._b(
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ active }) {
                                  return [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            ripple: false,
                                            "input-value": active,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                attrs: {
                                                  "no-gutters": "",
                                                  align: "center",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        "v-list-item",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
            {
              key: "selection",
              fn: function ({ item, index }) {
                return [
                  index === 0
                    ? _c("span", [_vm._v(_vm._s(item.name))])
                    : _vm._e(),
                  index === 1
                    ? _c(
                        "span",
                        { staticClass: "grey--text text-caption ml-2" },
                        [
                          _vm._v(
                            " (+" + _vm._s(_vm.value.length - 1) + " others) "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      },
      "v-select",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }