// initial state
const state = {
  loadTypes: [],
  loadTypeMap: {},
  pagination: {},
  shouldDisplayWarehouses: false
};

// getters
const getters = {
  loadTypes(state) {
    return state.loadTypes;
  },
  loadTypesByIdArray: state => loadTypeIds => {
    return state.loadTypes.filter(loadType => {
      return loadTypeIds.includes(loadType.id);
    });
  },
  loadTypeName:
    () =>
    (loadType, includeDuration = true, includeDirection = true) => {
      if (!loadType) {
        return 'Invalid load type';
      }

      let name = loadType.name ?? 'No name';

      if (includeDuration && loadType.duration_min) {
        name += ` (${loadType.duration_min} min)`;
      }
      if (includeDirection && loadType.direction) {
        name += ` - ${loadType.direction.toUpperCase()}`;
      }
      return name;
    },
  shouldDisplayWarehouses(state) {
    return state.shouldDisplayWarehouses;
  }
};

// actions
const actions = {
  async getLoadTypes({ commit, state }, filters = null) {
    const response = await axios.get(`loadtype`, {
      params: filters || state.filters
    });

    if (response?.data) {
      commit('setApiResponseData', response.data);
      commit('setFilters', filters || state.filters);
    }

    return state.loadTypes;
  },
  async getLoadTypesCountPerWarehouse({}, { warehouseId }) {
    const response = await axios.get(`loadtype`, {
      params: {
        s: { warehouseId },
        select: 'id',
        limit: 1
      }
    });

    return response?.data?.total ?? 0;
  },
  async createLoadType({}, loadType) {
    return await axios.post('loadtype', loadType);
  },
  async deleteLoadType({}, loadType) {
    return await axios.delete(`loadtype/${loadType.id}`);
  },
  async updateLoadType({}, loadType) {
    return await axios.patch(`loadtype/${loadType.id}`, loadType);
  },
  async getAvailability({}, { id, params }) {
    return await axios.post(`loadtype/${id}/get-availability`, params);
  }
};

// mutations
const mutations = {
  setLoadTypes(state, loadTypes) {
    state.loadTypes = loadTypes;

    loadTypes.forEach(lt => {
      state.loadTypeMap[lt.id] = lt;
    });
  },
  setApiResponseData(state, response) {
    state.loadTypes = response.data;
    state.pagination = {
      page: response.page,
      pageCount: response.pageCount,
      total: response.total
    };
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setShouldDisplayWarehouses(state, shouldDisplayWarehouses) {
    state.shouldDisplayWarehouses = shouldDisplayWarehouses;
  }
};

export default {
  namespaced: true,
  name: 'LoadTypes',
  state,
  getters,
  actions,
  mutations
};
