<template>
  <div class="appointment-details">
    <div class="mx-2 mb-8">
      <v-row>
        <v-icon class="text-lg-h5 mr-2">mdi-calendar</v-icon>
        <span
          class="text-lg-h6 black--text"
          data-testid="reserve-date-time"
          v-html="appointmentDateTimeHeader"></span>
      </v-row>
      <v-row v-if="recurringPattern.weekDays">
        <div class="font-size-x-small">
          Recurs every
          <strong>{{ recurringPattern.weekDays }} @ {{ recurringPattern.endTime }}</strong>
          until
          <strong>{{ recurringPattern.endDate }}</strong>
        </div>
      </v-row>
    </div>

    <v-simple-table dense class="appointment-details-table" v-if="!loading">
      <template v-slot:default>
        <thead></thead>
        <tbody>
          <tr>
            <td class="font-weight-bold pb-4">
              <div>Reserve Title</div>
            </td>
            <td class="pb-4">
              <div v-html="appointment.notes || '----'"></div>
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold pb-4">
              <div>
                Reserve ID
                <help-icon-tooltip>Unique Reserve Reference</help-icon-tooltip>
              </div>
            </td>
            <td class="pb-4">
              <div>
                <span class="mr-4">{{ appointment.id }}</span>
                <secondary-button x-small @click="copyApptId" class="ml-4">Copy</secondary-button>
              </div>
            </td>
          </tr>

          <template v-for="(row, index) in appointmentTableRows">
            <template v-if="!row.isHidden">
              <tr :key="index + '-detail'">
                <template>
                  <td class="font-weight-bold" :class="row.tdClass">
                    {{ row.label }}
                  </td>
                  <td :class="row.tdClass">
                    <div v-html="row.value"></div>
                  </td>
                </template>
              </tr>
            </template>
          </template>

          <tr class="divider">
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td class="pt-5 font-weight-bold">Warehouse</td>
            <td class="pt-5">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="d-block font-weight-bold">
                      {{ appointment.dock.warehouse.name }}
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <span class="d-block">
                      Address:
                      <copy-content
                        :content="getWarehouseAddress(appointment.dock.warehouse)"
                        label="Click to copy warehouse address">
                        {{ getWarehouseAddress(appointment.dock.warehouse) }}
                      </copy-content>
                    </span>
                    <span class="d-block">Email: {{ appointment.dock.warehouse.email }}</span>
                    <span class="d-block">Phone: {{ appointment.dock.warehouse.phone }}</span>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </td>
          </tr>

          <tr class="divider">
            <td colspan="2"></td>
          </tr>

          <tr>
            <td class="font-weight-bold pt-5">Audit Log</td>
            <td class="pt-5">
              <v-expansion-panels accordion>
                <v-expansion-panel @click="handleAuditLogExpand()">
                  <v-expansion-panel-header>Timeline for Reserve Updates</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <audit-log-appointment
                      v-if="isAuditLogVisible"
                      id="audit_log_element"
                      :org="$org"
                      :object-id="appointment.id"
                      :entity-obj="appointment"
                      entity="appointment"></audit-log-appointment>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <template v-if="(canCancel || isCancelled) && shouldDisplayCancelButton">
      <div class="d-flex justify-center my-8">
        <v-btn
          v-if="!isCancelled"
          elevation="0"
          class="text__error font-weight-bold"
          color="white"
          @click="confirmCancel"
          :disabled="appointment.status === novaCore.AppointmentStatus.Cancelled">
          <v-icon class="mr-2">{{ cancelButtonIcon }}</v-icon>
          {{ cancelAppointmentLabel }}
        </v-btn>
      </div>
    </template>

    <v-alert color="error" class="white--text" v-if="!appointment && !loading">
      Could not load appointment data
    </v-alert>
    <template v-if="loading">
      <v-card-text>
        Loading Appointment Data...
        <v-progress-linear color="secondary" indeterminate rounded height="8"></v-progress-linear>
      </v-card-text>
    </template>

    <reschedule-appointment-dialog-old
      @close="showRescheduleDialog = false"
      :external-activator="true"
      :show-dialog="showRescheduleDialog"
      :appointment="appointment"></reschedule-appointment-dialog-old>

    <cancel-appointment-dialog-old
      ref="cancelAppointment"
      @confirm="$emit('confirm')"
      :external-activator="true"
      :show-dialog="showConfirmDialog"
      :appointment="appointment"
      @close="showConfirmDialog = false"></cancel-appointment-dialog-old>
  </div>
</template>

<script>
import appointmentMixin from '@/components/mixins/appointmentMixin';
import recurringAppointmentMixin from '@/components/mixins/recurringAppointmentMixin';

/**
 * @displayName Appointment Details
 */
export default {
  mixins: [appointmentMixin, recurringAppointmentMixin],
  props: {
    /**
     * Appointment to display details for
     */
    appointment: {
      type: Object,
      required: true
    },
    /**
     * Display status editing options
     */
    shouldEditStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldDisplayCancelButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    canReschedule() {
      return (
        this.$rolePermissions.canUpdateAppointment &&
        this.novaCore.isRescheduleAllowed(this.appointment.status)
      );
    },
    reschedulesCount() {
      return this.novaCore.getReschedulesCount(this.appointment);
    },
    reschedulesString() {
      return this.novaCore.getReschedulesString(this.reschedulesCount);
    }
  },
  data() {
    return {
      showRescheduleDialog: false,
      loading: true,
      tags: this.appointment.tags,
      lockTagEditing: false,
      isAuditLogVisible: false,
      appointmentTableRows: {
        dockName: {
          label: 'Dock Name',
          value: this.appointment.readableDockName,
          tdClass: 'pb-4',
          isHidden: false
        }
      }
    };
  },
  methods: {
    async updateAppointment() {
      this.lockTagEditing = true;
      await axios
        .patch(`appointment/${this.appointment.id}`, {
          tags: this.tags
        })
        .finally(() => (this.lockTagEditing = false));
    },
    handleAuditLogExpand() {
      this.isAuditLogVisible = !this.isAuditLogVisible;
      this.trackAuditLogMixpanelEvent();
      if (this.isAuditLogVisible) {
        setTimeout(() => {
          const element = document.getElementById('audit_log_element');
          if (element) {
            element.scrollIntoView();
          }
        }, 500);
      }
    },
    trackAuditLogMixpanelEvent() {
      this.mixpanel.track(this.mixpanel.events.VIEW.AUDIT_LOG, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Audit Log Entity': 'Reserve'
      });
    }
  },
  async mounted() {
    this.loading = false;
  },
  beforeDestroy() {
    this.$router.replace({ query: {} });
  },
  watch: {
    tags() {
      if (JSON.stringify(this.tags) !== JSON.stringify(this.appointment.tags)) {
        this.updateAppointment();
      }
    },
    appointment(appointment) {
      this.tags = appointment.tags;
    }
  }
};
</script>
