var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-field-input-base-old", {
    attrs: { "single-line": "", field: _vm.field, type: "number" },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }