<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :header="header"
    loading-message="Sending verification email..."
    @close="close"
    @confirm="sendVerificationEmail">
    <template slot="body">
      <v-card-text>
        <v-container>
          <p>
            After registering your account we sent a confirmation email to
            <strong>{{ email }}</strong
            >. Please find it and click the link inside to verify your email and complete your
            account creation.
          </p>
          <p>
            Didn't get an email?
            <br />
            - Please check your spam/junk folder
            <br v-if="allowVerificationResend" />
            <span v-if="allowVerificationResend">
              - <a @click="sendVerificationEmail">Click here</a> to resend the verification email
            </span>
            <br />
            - Contact us: <a href="@mailto:support@opendock.com">support@opendock.com</a>
          </p>
        </v-container>
      </v-card-text>
      <action-group
        :hide-confirm="true"
        cancel-icon=""
        cancel-label="Close"
        @cancel="close"></action-group>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    /**
     * The header text of the dialog
     */
    header: {
      type: String,
      required: false,
      default: 'Email Unverified'
    },
    /**
     * Whether or not to show the "resend verification" action
     */
    allowVerificationResend: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * The address where the verification email was sent
     */
    email: {
      type: String,
      required: true
    }
  },
  methods: {
    async sendVerificationEmail() {
      const response = await this.$store.dispatch('Auth/sendVerificationEmail', this.email);

      if (response?.status === 200) {
        this.notify(`Verification email sent to ${this.email}!`);
        this.$emit('close');
      }
    }
  }
};
</script>
