var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "mt-6",
            attrs: { indeterminate: "", loading: "", height: "6" },
          })
        : _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("h3", [_vm._v("Single Sign-On")]),
                        _c("p", [
                          _vm._v(
                            " SSO is an authentication and access control technology which allows users to log-in to multiple applications using a single set of credentials. Using SSO with Opendock provides a better user experience for your employees and helps ensure greater security and regulatory compliance. "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://community.loadsmart.com/hc/en-us/articles/14944624317075-Single-Sign-On-SSO-SAML-2-0",
                                target: "_blank",
                                rel: "noopener",
                              },
                            },
                            [_vm._v("Click here")]
                          ),
                          _vm._v(" to learn more. "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("h3", { staticClass: "mt-5" }, [
                          _vm._v("SSO Setup"),
                        ]),
                        _c("p", [
                          _c("small", [
                            _vm._v(
                              " Copy your IdP Metadata URL from your IdP (App Federation Metadata URL), paste it below and then click the Save button. "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("text-field", {
                            attrs: {
                              type: "text",
                              required: _vm.config.enabled,
                              label: "IdP Metadata URL",
                            },
                            model: {
                              value: _vm.config.idpMetadataUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "idpMetadataUrl", $$v)
                              },
                              expression: "config.idpMetadataUrl",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-switch", {
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(" SSO Enabled: "),
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.config.enabled ? "yes" : "no"
                                            )
                                        ),
                                      ]),
                                      !_vm.config.enabled
                                        ? _c(
                                            "small",
                                            { staticClass: "switch-note" },
                                            [_vm._v("(testing only)")]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.config.enabled,
                              callback: function ($$v) {
                                _vm.$set(_vm.config, "enabled", $$v)
                              },
                              expression: "config.enabled",
                            },
                          }),
                          _c("v-switch", {
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(" Forbid password logins: "),
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.config.forbidPasswordLogins
                                                ? "yes"
                                                : "no"
                                            )
                                        ),
                                      ]),
                                      _vm.config.forbidPasswordLogins
                                        ? _c(
                                            "small",
                                            { staticClass: "switch-note" },
                                            [
                                              _vm._v(
                                                "(except for users with Owner role)"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.config.forbidPasswordLogins,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.config,
                                  "forbidPasswordLogins",
                                  $$v
                                )
                              },
                              expression: "config.forbidPasswordLogins",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "primary-button",
                        {
                          staticClass: "mt-2",
                          attrs: { loading: _vm.submitting },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v("Save SSO Setup")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      !_vm.loading
        ? _c(
            "v-container",
            { staticClass: "mt-10", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("h3", [_vm._v("Opendock Service Provider Metadata")]),
                    _c("p", { staticClass: "mb-0" }, [
                      _c("small", [
                        _vm._v(
                          "Use the information in this section to configure your IdP:"
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("Metadata URL:"),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "9" } }, [
                    _c(
                      "a",
                      { attrs: { target: "_blank", href: _vm.spMetadataUrl } },
                      [_vm._v(_vm._s(_vm.spMetadataUrl))]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("Entity ID:"),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.spEntityId))]),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-3",
                          attrs: { size: "large", color: "secondary" },
                          on: { click: _vm.copyEntityID },
                        },
                        [_vm._v("mdi-content-copy")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("Assertion Consumer (AC) Location:"),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.spAcLocation))]),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-3",
                          attrs: { size: "large", color: "secondary" },
                          on: { click: _vm.copyAcLocation },
                        },
                        [_vm._v("mdi-content-copy")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-container",
            { staticClass: "mt-8", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("h3", [_vm._v("Warehouse IDs")]),
                    _c("p", { staticClass: "mb-0" }, [
                      _c("small", [
                        _vm._v(
                          "If you are using provisioning, and you want to restrict user's warehouseAccessList, you can easily copy the desired Warehouse IDs from this list: "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Warehouse"),
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("ID"),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.warehouses, function (wh) {
                                      return _c("tr", { key: wh.id }, [
                                        _c("td", [_vm._v(_vm._s(wh.name))]),
                                        _c("td", [
                                          _c(
                                            "span",
                                            [
                                              _vm._v(" " + _vm._s(wh.id) + " "),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    size: "large",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copyWhId(wh.id)
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-content-copy")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1475255691
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }