<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col>
        <h3 class="mt-12 mb-2 black--text">Amenities available at the warehouse</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="d-flex justify-left flex-wrap">
        <v-checkbox
          :key="item"
          v-for="item in amenitiesEnum"
          :label="item"
          :value="fields.amenities?.includes(item)"
          @click="() => toggleItem(item, 'amenities')"
          class="mr-4"></v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3 class="mt-10 mb-2 black--text">PPE and other requirements</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="d-flex justify-left flex-wrap">
        <v-checkbox
          :key="item"
          v-for="item in ppeRequirementsEnum"
          :label="item"
          :value="fields.ppeRequirements?.includes(item)"
          @click="() => toggleItem(item, 'ppeRequirements')"
          class="mr-4"></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    fields: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    amenitiesEnum() {
      return Object.values(this.novaCore.AmenitiesEnum);
    },
    ppeRequirementsEnum() {
      return Object.values(this.novaCore.PPERequirementsEnum);
    }
  },
  mounted() {
    if (!this.fields.amenities) {
      this.fields.amenities = [];
    }
    if (!this.fields.ppeRequirements) {
      this.fields.ppeRequirements = [];
    }
  },
  methods: {
    toggleItem(item, category) {
      const existingItemIndex = this.fields[category].findIndex(
        existingItem => existingItem === item
      );

      const categoryField = [...this.fields[category]];

      if (existingItemIndex < 0) {
        categoryField.push(item);
      } else {
        categoryField.splice(existingItemIndex, 1);
      }

      this.fields[category] = categoryField;
    }
  }
};
</script>
