<template>
  <div
    class="w-100 d-flex flex-column gap-4 justify-center align-center my-10"
    data-testid="yard-no-areas-message">
    <span class="icon-wrapper">
      <v-icon class="icon" size="50" color="#9E9E9E">mdi-view-column-outline</v-icon>
    </span>
    <div style="max-inline-size: 270px; text-align: center">
      <h3 class="typography-body-md-bold align-center">No yard areas</h3>
      <p class="typography-body-sm">
        Add areas to your warehouse yard view or select one from the menu above.
      </p>
    </div>
    <primary-button @click="() => $router.push({ name: 'yard-add-spot-area' })">
      Add Area
    </primary-button>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'YardViewNoAreas'
});
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  inline-size: 96px;
  block-size: 96px;

  border: 1px solid $color-line-border;
  border-radius: 50%;
  background-color: $color-neutral-20;
}
</style>
