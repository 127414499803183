<template>
  <form-base :is-loading="loading">
    <template #form>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col>
              <text-field
                :required="true"
                type="text"
                v-model="localUser.firstName"
                :rules="$validator.rules.required('First Name')"
                label="First Name"></text-field>
            </v-col>
            <v-col>
              <text-field
                :required="true"
                type="text"
                v-model="localUser.lastName"
                :rules="$validator.rules.required('Last Name')"
                label="Last Name"></text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <role-select
                v-if="showComboBoxRoles"
                v-model="localUser.role"
                :parent-role="$me.role"
                :excluded-roles="[
                  novaCore.UserRole.BASE,
                  novaCore.UserRole.CARRIER_ADMIN,
                  novaCore.UserRole.CARRIER
                ]"
                :rules="$validator.rules.required('Role')"></role-select>
              <div class="v-text-field" v-else>
                <v-label>Role</v-label>
                <p class="mb-5">
                  <v-label>
                    <strong>{{ novaCore.userRoleToText(localUser.role) }}</strong>
                  </v-label>
                </p>
              </div>
            </v-col>
          </v-row>
          <template>
            <user-restriction-switch
              v-model="shouldTargetUserBeRestrictedByLocation"
              :role="localUser.role"
              :readOnly="!isUserAllowedToUnRestrictUser"
              class="mt-1"></user-restriction-switch>
            <v-row v-if="shouldTargetUserBeRestrictedByLocation">
              <v-col>
                <basic-warehouse-select
                  :restricted-warehouse-ids="currentUserWarehouseAccessList"
                  :filter-locally="false"
                  :orgId="localUser.orgId"
                  :disabled="!isUserAllowedToUnRestrictUser"
                  multiple
                  v-model="selectedWarehouses"></basic-warehouse-select>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="$emit('close')"
        confirm-icon="upload"
        confirm-label="Update User"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
import userRestrictionMixin from '@satellite/components/mixins/userRestrictionMixin';

/**
 * Edit User Form
 * @displayName Edit User Form
 */
export default {
  mixins: [userRestrictionMixin],
  props: {
    /**
     * User object
     */
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localUser: {},
      loading: false
    };
  },
  methods: {
    /**
     * Submit form
     * @public
     */
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;

      const payload = {
        id: this.localUser.id,
        firstName: this.localUser.firstName,
        lastName: this.localUser.lastName,
        role: this.localUser.role,
        warehouseAccessList:
          this.selectedWarehouseAccessList.length > 0 ? this.selectedWarehouseAccessList : null
      };

      try {
        const res = await this.$store.dispatch('Users/updateUser', payload);
        if (res?.data?.data) {
          this.$emit('saved', res.data.data);
        }
      } finally {
        this.$emit('close');
        this.loading = false;
      }
    }
  },
  computed: {
    showComboBoxRoles() {
      return (
        this.novaCore.isUserRoleLessOrEqual(this.localUser.role, this.$me.role) &&
        !this.novaCore.isCarrierUser(this.localUser)
      );
    },
    isUserAllowedToUnRestrictUser() {
      return (
        !this.currentUserWarehouseAccessList ||
        this.currentUserWarehouseAccessList.length === 0 ||
        !this.user?.id
      );
    }
  },
  mounted() {
    if (this.localUser.warehouseAccessList) {
      this.shouldTargetUserBeRestrictedByLocation = true;
      this.selectedWarehouses = this.localUser.warehouseAccessList;
    }
  },
  beforeMount() {
    this.localUser = { ...this.user };
  }
};
</script>
