var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.logLines.length
    ? _c("table", { staticClass: "pt-5 audit-log-table" }, [
        _c("thead"),
        _c(
          "tbody",
          [
            _vm._l(_vm.orderedLogLines, function (item, index) {
              return [
                _c("tr", { key: `audit-item-${item.id}-${index}` }, [
                  _c("td", { staticClass: "align-vertical-top" }, [
                    _c(
                      "span",
                      { staticClass: "d-block text-right font-weight-bold" },
                      [_vm._v(_vm._s(_vm.getItemDate(item)))]
                    ),
                    _c("span", { staticClass: "d-block text-right" }, [
                      _vm._v(_vm._s(_vm.getItemTime(item))),
                    ]),
                  ]),
                  _c("td", { staticClass: "align-vertical-top" }, [
                    _c(
                      "div",
                      { staticClass: "circle", class: item.lineColor },
                      [
                        _c("div", {
                          staticClass: "halo",
                          class: item.lineColor,
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "vertical-divider mid-grey" }),
                  ]),
                  _c("td", { staticClass: "align-vertical-top" }, [
                    _c("span", { staticClass: "d-block" }, [
                      _c("span", { staticClass: "entry-header" }, [
                        _vm._v(_vm._s(item.lineTitle)),
                      ]),
                    ]),
                    _c("span", {
                      staticClass: "d-block pb-1",
                      domProps: { innerHTML: _vm._s(item.change) },
                    }),
                    _c("span", { staticClass: "d-block user-name" }, [
                      _vm._v("by " + _vm._s(item.user)),
                    ]),
                  ]),
                ]),
              ]
            }),
            _vm.hiddenLogLineCount > 0 && _vm.minimizeLines
              ? _c("tr", [
                  _c("td", { staticClass: "align-vertical-top" }),
                  _c("td", { staticClass: "align-vertical-top expandable" }, [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-chevron-down"),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "vertical-divider mid-grey" }),
                  ]),
                  _c("td", { staticClass: "align-vertical-top" }, [
                    _c(
                      "span",
                      { staticClass: "d-block" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "link",
                            attrs: { text: "", height: "20" },
                            on: {
                              click: function ($event) {
                                _vm.minimizeLines = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.hiddenLogLineCount) + " more updates"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.firstItem
              ? _c("tr", [
                  _c("td", { staticClass: "align-vertical-top" }, [
                    _c(
                      "span",
                      { staticClass: "d-block text-right font-weight-bold" },
                      [_vm._v(_vm._s(_vm.getItemDate(_vm.firstItem)))]
                    ),
                    _c("span", { staticClass: "d-block text-right" }, [
                      _vm._v(_vm._s(_vm.getItemTime(_vm.firstItem))),
                    ]),
                  ]),
                  _c("td", { staticClass: "align-vertical-top" }, [
                    _c(
                      "div",
                      { staticClass: "circle", class: _vm.firstItem.lineColor },
                      [
                        _c("div", {
                          staticClass: "halo",
                          class: _vm.firstItem.lineColor,
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "vertical-divider mid-grey" }),
                  ]),
                  _c("td", { staticClass: "align-vertical-top" }, [
                    _c("span", { staticClass: "d-block" }, [
                      _c("span", { staticClass: "entry-header" }, [
                        _vm._v(_vm._s(_vm.firstItem.lineTitle)),
                      ]),
                    ]),
                    _c("span", {
                      staticClass: "d-block pb-1",
                      domProps: { innerHTML: _vm._s(_vm.firstItem.change) },
                    }),
                    _c("span", { staticClass: "d-block user-name" }, [
                      _vm._v("by " + _vm._s(_vm.firstItem.user)),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }