<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Search Appointment Fields"
    :scrollable="false"
    width="55vw"
    max-width="55vw"
    loading-message="Getting fields..."
    @close="close"
    @confirm="updateValue()">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <div v-else style="position: relative">
        <primary-button
          before-icon="format-list-bulleted-type"
          v-on="slotProps.on"
          v-bind="slotProps.attrs"
          :disabled="disabled"
          >{{ buttonText }}
        </primary-button>
      </div>
    </template>
    <template slot="body">
      <v-container>
        <v-row v-if="$refNumSettings(selectedWarehouse).isVisible">
          <text-field
            :label="`${$refNumSettings(selectedWarehouse).displayName} Contains`"
            v-model="searchFields.refNum"></text-field>
        </v-row>
        <v-row class="mt-4">
          <text-field label="Notes Field Contains" v-model="searchFields.notes"></text-field>
        </v-row>
        <v-row class="mt-4">
          <text-field label="Tags Contain" v-model="searchFields.tags"></text-field>
        </v-row>
        <v-row class="mt-4">
          <text-field
            label="Email Subscribers Contains"
            v-model="searchFields.emailSubscribers"></text-field>
        </v-row>
        <v-row v-for="cf in customFields" :key="cf.label">
          <text-field :label="`${cf.label} Contains`" v-model="searchFields[cf.label]"></text-field>
        </v-row>
      </v-container>
    </template>
    <template slot="dialog-actions">
      <v-spacer></v-spacer>
      <outline-button before-icon="close-circle" @click="close"> Nevermind </outline-button>
      <v-btn color="success" @click="updateValue()">Save</v-btn>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  name: 'AppointmentFieldFiltersDialog',
  mixins: [dialogMixin],
  props: {
    buttonText: {
      type: String,
      required: false,
      default: 'Appointment Fields'
    },
    value: {
      required: true
    },
    selectedWarehouse: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      searchFields: {
        refNum: '',
        notes: '',
        tags: '',
        emailSubscribers: ''
      },
      customFields: []
    };
  },
  methods: {
    updateValue() {
      this.$emit('input', this.searchFields);
      this.close();
    },
    makeCustomFields() {
      this.customFields = this.selectedWarehouse.customApptFieldsTemplate
        ?.map(field => ({
          ...field,
          name: field.name ?? this.novaCore.getDefaultCustomFieldName(field)
        }))
        .filter(f => ['str', 'int'].includes(f.type));
    }
  },
  watch: {
    selectedWarehouse() {
      if (this.selectedWarehouse) {
        this.makeCustomFields();
      }
    }
  },
  mounted() {
    this.searchfields = this.value;
  }
};
</script>
