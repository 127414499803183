<template>
  <audit-log-entity-dialog
    :object-id="item.id"
    :entity-obj="item"
    :entity-name="entityName ?? item.name"
    :timezone="timezone"
    :entity="entityType">
    <template v-slot:activator="{ on, attrs }">
      <icon-tooltip-button
        tooltip="Audit log"
        v-bind="attrs"
        v-on="on"
        size="large"
        icon-class="mr-2"
        icon="timeline-text-outline">
      </icon-tooltip-button>
    </template>
  </audit-log-entity-dialog>
</template>

<script>
export default {
  props: {
    timezone: {
      type: String,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    entityName: {
      type: String,
      required: false
    },
    item: {
      type: Object,
      required: true
    }
  }
};
</script>
