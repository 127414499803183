var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          "external-activator": true,
          header: _vm.actionText,
          loading: _vm.loading,
          "loading-message": _vm.loadingMessage,
        },
        on: { close: _vm.close, confirm: _vm.submit },
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-container",
                { staticClass: "px-7 pb-7" },
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-icon": "mdi-email",
                      disabled: "",
                      value: _vm.email,
                    },
                  }),
                  _c("text-field", {
                    attrs: {
                      required: true,
                      type: _vm.showPasswords ? "text" : "password",
                      "prepend-icon": "mdi-key",
                      "append-icon": _vm.showPasswords
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      rules: _vm.$validator.rules.password,
                      label: "Password",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPasswords = !_vm.showPasswords
                      },
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c("text-field", {
                    attrs: {
                      required: true,
                      type: _vm.showPasswords ? "text" : "password",
                      "prepend-icon": "mdi-key",
                      "append-icon": _vm.showPasswords
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      rules: _vm.$validator.rules.eq(
                        _vm.password,
                        "Passwords do not match"
                      ),
                      label: "Confirm password",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPasswords = !_vm.showPasswords
                      },
                    },
                    model: {
                      value: _vm.passwordConfirm,
                      callback: function ($$v) {
                        _vm.passwordConfirm = $$v
                      },
                      expression: "passwordConfirm",
                    },
                  }),
                  _c("password-restrictions", {
                    attrs: { password: _vm.password },
                  }),
                ],
                1
              ),
              _c("action-group", {
                attrs: {
                  "confirm-icon": "check",
                  "confirm-label": _vm.actionText,
                },
                on: { cancel: _vm.close, confirm: _vm.submit },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }