var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-stepper",
        {
          staticClass: "status-stepper",
          class: { "mb-8": !_vm.dense, compact: _vm.compact },
          attrs: { "alt-labels": true, flat: true },
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          !_vm.compact
            ? _c(
                "v-stepper-header",
                [
                  _vm._l(_vm.statuses, function (status, index) {
                    return [
                      _c("v-divider", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: index && status.show,
                            expression: "index && status.show",
                          },
                        ],
                        key: `divider-${index}`,
                        staticClass: "divider",
                      }),
                      _c(
                        "v-stepper-step",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: status.show,
                              expression: "status.show",
                            },
                          ],
                          key: `status-${index}`,
                          class: {
                            dense: _vm.dense,
                            "is-requested":
                              _vm.novaCore.isRequested(_vm.appointment) &&
                              status.name === _vm.requestedStatus,
                            "is-inprogress":
                              _vm.novaCore.isInProgress(_vm.appointment) &&
                              status.name === _vm.inProgressStatus,
                          },
                          attrs: {
                            step: status.stepNumber,
                            "complete-icon": _vm.getCompletedIcon(status),
                            complete:
                              status.stepNumber <= _vm.currentStep ||
                              _vm.currentStep === 0,
                            color: status.name.toLowerCase(),
                            "alt-labels": true,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "font-weight-medium status-name",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.novaCore.breakWordsAtCaps(
                                          status.name
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.displayTime(status)
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-wrap-nowrap align-center is-relative",
                                      },
                                      [
                                        _vm.appointment.statusTimeline[
                                          status.name
                                        ]
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-size-x-small full-width",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDateTime(
                                                        _vm.appointment
                                                          .statusTimeline[
                                                          status.name
                                                        ],
                                                        _vm.novaCore
                                                          .LuxonDateTimeFormats
                                                          .MonthDayYearSlashedTimeAMPM,
                                                        _vm.novaCore
                                                          .LuxonDateTimeFormats
                                                          .MonthDayYearSlashedTime24
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.canEdit(status)
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "time-edit-button",
                                                attrs: { small: "", icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openEditor(
                                                      status.name
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-pencil"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass: "caption text-uppercase",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.timeDiffInfo(status.name)
                                        ),
                                      },
                                    }),
                                    _vm.hasCheckinStatus
                                      ? [
                                          status.name === _vm.arrivedStatus &&
                                          _vm.appointment.isCheckedInByCarrier
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mt-1 pa-1 pill text-uppercase black--text bg--color-neutral-20 text--color-neutral-90 font-size-xx-small font-weight-medium",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "pt-1" },
                                                    [
                                                      _vm._v(
                                                        "Checked in by carrier"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          status.name === _vm.arrivedStatus &&
                                          _vm.isUnplannedAppointment
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pa-1 pill text-uppercase bg--color-neutral-20 text--color-neutral-90 font-size-xx-small font-weight-medium",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Unplanned"),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          status.name === _vm.completedStatus &&
                                          _vm.isAssetDeparted
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pa-1 px-2 pill text-uppercase bg--color-neutral-20 text--color-neutral-90 font-size-xx-small font-weight-medium",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Checked Out"),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.appointment.status === _vm.novaCore.AppointmentStatus.Scheduled &&
          !_vm.compact
            ? _c("eta-button-group", {
                staticClass: "mb-4",
                attrs: {
                  label: "Carrier ETA",
                  "party-to-alert": "Carrier",
                  "read-only": _vm.readOnly,
                  appointment: _vm.appointment,
                },
                on: { "condition-updated": _vm.updateCondition },
                model: {
                  value: _vm.etaCondition,
                  callback: function ($$v) {
                    _vm.etaCondition = $$v
                  },
                  expression: "etaCondition",
                },
              })
            : _vm._e(),
          _c(
            "v-stepper-items",
            [
              _vm._l(_vm.statuses, function (status, index) {
                return [
                  _c(
                    "v-stepper-content",
                    {
                      key: `status-${index}`,
                      staticClass: "pa-0",
                      attrs: {
                        step: status.stepNumber,
                        transition: "fab-transition",
                      },
                    },
                    [
                      !_vm.compact
                        ? _c(
                            "v-card",
                            {
                              staticClass: "card-content py-5",
                              attrs: { elevation: "6", outlined: "" },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "justify-center",
                                          class: {
                                            "mb-5":
                                              _vm.$rolePermissions
                                                .canUpdateAppointmentStatus,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.statusMap[status.name]
                                                  .description
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm.$rolePermissions
                                        .canUpdateAppointmentStatus &&
                                      !_vm.readOnly
                                        ? _c(
                                            "v-row",
                                            { staticClass: "justify-center" },
                                            [
                                              _vm.shouldShowNextHPButton(status)
                                                ? _c(
                                                    "secondary-button",
                                                    {
                                                      staticClass:
                                                        "status-proceed-button",
                                                      attrs: {
                                                        loading:
                                                          _vm.btnHappy_loading,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.btnHappy_onClick(
                                                            status
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getButtonLabel(
                                                              _vm.statusMap[
                                                                _vm.getNextHappyPathStatus(
                                                                  status
                                                                )
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.checkForTriggerOnStatus(
                                                status.name
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        outlined: "",
                                                        "data-testid":
                                                          "appointment-details-dialog-trigger-button",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.activeTrigger =
                                                            _vm.checkForTriggerOnStatus(
                                                              status.name
                                                            )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-text-box-edit-outline"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.checkForTriggerOnStatus(
                                                              status.name
                                                            ).flow.name
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              status.canUndo
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "status-undo-button",
                                                      class:
                                                        _vm.getButtonLabel(
                                                          _vm.statusMap[
                                                            _vm.getNextHappyPathStatus(
                                                              status
                                                            )
                                                          ]
                                                        ) && !status.isEndState
                                                          ? "ml-4"
                                                          : "",
                                                      attrs: {
                                                        loading:
                                                          _vm.btnUndo_loading,
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.btnUndo_onClick()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("mdi-undo")]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            `Undo ${_vm.novaCore.breakWordsAtCaps(
                                                              status.name
                                                            )} Status`
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.getNextNonHappyPathStatus(
                                                status
                                              ) && !status.isEndState
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "ml-4 status-undo-button",
                                                      attrs: {
                                                        loading:
                                                          _vm.btnNonHappy_loading,
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.btnNonHappy_onClick(
                                                            status
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.getStatusIcon(
                                                        _vm.statusMap[
                                                          _vm.getNextNonHappyPathStatus(
                                                            status
                                                          )
                                                        ]
                                                      )
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getStatusIcon(
                                                                      _vm
                                                                        .statusMap[
                                                                        _vm.getNextNonHappyPathStatus(
                                                                          status
                                                                        )
                                                                      ]
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getButtonLabel(
                                                              _vm.statusMap[
                                                                _vm.getNextNonHappyPathStatus(
                                                                  status
                                                                )
                                                              ]
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-container",
                                [
                                  _vm.$rolePermissions
                                    .canUpdateAppointmentStatus
                                    ? _c(
                                        "v-row",
                                        [
                                          _vm.shouldShowNextHPButton(status)
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "justify-start align-center no-text-transform",
                                                  attrs: {
                                                    text: "",
                                                    block: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.updateStatus(
                                                        _vm.getNextHappyPathStatus(
                                                          status
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        small: "",
                                                        color: _vm
                                                          .getNextHappyPathStatus(
                                                            status
                                                          )
                                                          .toLowerCase(),
                                                      },
                                                    },
                                                    [_vm._v(" mdi-circle ")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.novaCore.capitalize(
                                                          _vm.getButtonLabel(
                                                            _vm.statusMap[
                                                              _vm.getNextHappyPathStatus(
                                                                status
                                                              )
                                                            ]
                                                          )
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          status.canUndo
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "justify-start align-center no-text-transform",
                                                  attrs: {
                                                    text: "",
                                                    block: "",
                                                  },
                                                  on: {
                                                    click: _vm.undoLatestStatus,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-undo")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        `Undo ${_vm.novaCore.breakWordsAtCaps(
                                                          status.name
                                                        )} Status`
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.getNextNonHappyPathStatus(
                                            status
                                          ) && !status.isEndState
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "justify-start align-center no-text-transform",
                                                  attrs: {
                                                    text: "",
                                                    block: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.updateStatus(
                                                        _vm.getNextNonHappyPathStatus(
                                                          status
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.getStatusIcon(
                                                    _vm.statusMap[
                                                      _vm.getNextNonHappyPathStatus(
                                                        status
                                                      )
                                                    ]
                                                  )
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getStatusIcon(
                                                                  _vm.statusMap[
                                                                    _vm.getNextNonHappyPathStatus(
                                                                      status
                                                                    )
                                                                  ]
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            small: "",
                                                            color: _vm
                                                              .getNextNonHappyPathStatus(
                                                                status
                                                              )
                                                              .toLowerCase(),
                                                          },
                                                        },
                                                        [_vm._v(" mdi-circle ")]
                                                      ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getButtonLabel(
                                                          _vm.statusMap[
                                                            _vm.getNextNonHappyPathStatus(
                                                              status
                                                            )
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("status-timeline-edit-dialog", {
        attrs: {
          "external-activator": true,
          "status-to-edit": _vm.statusToEdit,
          "mixpanel-entry-point": _vm.mixpanelEntryPoint,
          "show-dialog": _vm.showEditDialog,
          appointment: _vm.appointment,
        },
        on: { close: _vm.closeEditor },
      }),
      _c("custom-forms-flow-dialog-old", {
        attrs: {
          "external-activator": true,
          warehouse: _vm.warehouse,
          "show-dialog": _vm.hasActiveTrigger,
          "object-id": _vm.appointment.id,
          trigger: _vm.activeTrigger,
          "status-change": _vm.pendingStatusChange,
        },
        on: {
          close: _vm.cleanUpCustomFormsData,
          update: _vm.updateCustomFormsData,
          create: _vm.submitStatusWithCustomFormsData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }