var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "fill-height justify-center" }, [
    _c(
      "div",
      [
        _c(
          "v-row",
          {
            staticClass: "flex-column align-center",
            attrs: {
              "no-gutters": "",
              "data-testid": "no-results-message-container",
            },
          },
          [
            _c(
              "v-icon",
              {
                staticClass: "mr-2 primary--text mb-3",
                attrs: { "x-large": "" },
              },
              [_vm._v("mdi-laptop")]
            ),
            _c("strong", { staticClass: "no-results-message" }, [
              _vm._v(" Looks like this page is not ready for mobile "),
            ]),
            _c("span", { staticClass: "px-4 text-center pt-3" }, [
              _vm._v(
                " Please access this page through a computer or tablet for a better experience "
              ),
            ]),
            _c(
              "outline-button",
              { staticClass: "mt-4", attrs: { to: "/appointments" } },
              [_vm._v("Go To Appointments Page")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }