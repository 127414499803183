<template>
  <svg width="118" height="92" viewBox="0 0 118 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <g clip-path="url(#clip0_4963_13588)">
        <mask id="path-1-inside-1_4963_13588" fill="white">
          <path d="M0 4C0 1.79086 1.79086 0 4 0H30C32.2091 0 34 1.79086 34 4V92H0V4Z" />
        </mask>
        <rect width="34" height="20" fill="#DBDBDB" />
        <path
          opacity="0.6"
          d="M17.3231 57.538H15.8462V55.6919H17.3231C17.5679 55.6919 17.8027 55.7891 17.9758 55.9622C18.1489 56.1353 18.2462 56.3701 18.2462 56.6149C18.2462 56.8597 18.1489 57.0945 17.9758 57.2676C17.8027 57.4408 17.5679 57.538 17.3231 57.538ZM17.2308 53.8457H14V62.1534H15.8462V59.3842H17.2308C17.9652 59.3842 18.6696 59.0924 19.1889 58.5731C19.7082 58.0537 20 57.3494 20 56.6149C20 55.0826 18.7585 53.8457 17.2308 53.8457Z"
          fill="#656565" />
      </g>
      <path
        d="M-1 4C-1 1.23858 1.23858 -1 4 -1H30C32.7614 -1 35 1.23858 35 4H33C33 2.34315 31.6569 1 30 1H4C2.34315 1 1 2.34315 1 4H-1ZM34 92H0H34ZM-1 92V4C-1 1.23858 1.23858 -1 4 -1V1C2.34315 1 1 2.34315 1 4V92H-1ZM30 -1C32.7614 -1 35 1.23858 35 4V92H33V4C33 2.34315 31.6569 1 30 1V-1Z"
        fill="#9E9E9E"
        mask="url(#path-1-inside-1_4963_13588)" />
      <g clip-path="url(#clip1_4963_13588)">
        <mask id="path-4-inside-2_4963_13588" fill="white">
          <path
            d="M42 4C42 1.79086 43.7909 0 46 0H72C74.2091 0 76 1.79086 76 4V88C76 90.2091 74.2091 92 72 92H46C43.7909 92 42 90.2091 42 88V4Z" />
        </mask>
        <rect width="34" height="20" transform="translate(42)" fill="#9E9E9E" />
        <path
          opacity="0.6"
          d="M62.1165 61.3757C62.5476 61.3757 62.8955 61.0277 62.8955 60.5966C62.8955 60.1655 62.5476 59.8175 62.1165 59.8175C61.6854 59.8175 61.3374 60.1655 61.3374 60.5966C61.3374 61.0277 61.6854 61.3757 62.1165 61.3757ZM62.8955 56.7013H61.5971V57.9997H63.9135L62.8955 56.7013ZM55.884 61.3757C56.3151 61.3757 56.663 61.0277 56.663 60.5966C56.663 60.1655 56.3151 59.8175 55.884 59.8175C55.4529 59.8175 55.1049 60.1655 55.1049 60.5966C55.1049 61.0277 55.4529 61.3757 55.884 61.3757ZM63.1552 55.9222L64.7134 57.9997V60.5966H63.6746C63.6746 61.4588 62.9786 62.1547 62.1165 62.1547C61.2543 62.1547 60.5584 61.4588 60.5584 60.5966H57.4421C57.4421 61.4588 56.7461 62.1547 55.884 62.1547C55.0218 62.1547 54.3259 61.4588 54.3259 60.5966H53.2871V54.8835C53.2871 54.307 53.7494 53.8447 54.3259 53.8447H61.5971V55.9222H63.1552ZM54.3259 54.8835V59.5579H54.7206C55.0062 59.241 55.4217 59.0385 55.884 59.0385C56.3462 59.0385 56.7617 59.241 57.0474 59.5579H60.5584V54.8835H54.3259Z"
          fill="#3C3C3C" />
      </g>
      <path
        d="M46 1H72V-1H46V1ZM75 4V88H77V4H75ZM72 91H46V93H72V91ZM43 88V4H41V88H43ZM46 91C44.3431 91 43 89.6569 43 88H41C41 90.7614 43.2386 93 46 93V91ZM75 88C75 89.6569 73.6569 91 72 91V93C74.7614 93 77 90.7614 77 88H75ZM72 1C73.6569 1 75 2.34315 75 4H77C77 1.23858 74.7614 -1 72 -1V1ZM46 -1C43.2386 -1 41 1.23858 41 4H43C43 2.34315 44.3431 1 46 1V-1Z"
        fill="#9E9E9E"
        mask="url(#path-4-inside-2_4963_13588)" />
      <g clip-path="url(#clip2_4963_13588)">
        <mask id="path-7-inside-3_4963_13588" fill="white">
          <path d="M84 4C84 1.79086 85.7909 0 88 0H114C116.209 0 118 1.79086 118 4V92H84V4Z" />
        </mask>
        <rect width="34" height="20" transform="translate(84)" fill="#DBDBDB" />
        <path
          opacity="0.6"
          d="M101.323 57.538H99.8462V55.6919H101.323C101.568 55.6919 101.803 55.7891 101.976 55.9622C102.149 56.1353 102.246 56.3701 102.246 56.6149C102.246 56.8597 102.149 57.0945 101.976 57.2676C101.803 57.4408 101.568 57.538 101.323 57.538ZM101.231 53.8457H98V62.1534H99.8462V59.3842H101.231C101.965 59.3842 102.67 59.0924 103.189 58.5731C103.708 58.0537 104 57.3494 104 56.6149C104 55.0826 102.758 53.8457 101.231 53.8457Z"
          fill="#656565" />
      </g>
      <path
        d="M83 4C83 1.23858 85.2386 -1 88 -1H114C116.761 -1 119 1.23858 119 4H117C117 2.34315 115.657 1 114 1H88C86.3431 1 85 2.34315 85 4H83ZM118 92H84H118ZM83 92V4C83 1.23858 85.2386 -1 88 -1V1C86.3431 1 85 2.34315 85 4V92H83ZM114 -1C116.761 -1 119 1.23858 119 4V92H117V4C117 2.34315 115.657 1 114 1V-1Z"
        fill="#9E9E9E"
        mask="url(#path-7-inside-3_4963_13588)" />
    </g>
    <defs>
      <clipPath id="clip0_4963_13588">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H30C32.2091 0 34 1.79086 34 4V92H0V4Z" fill="white" />
      </clipPath>
      <clipPath id="clip1_4963_13588">
        <path
          d="M42 4C42 1.79086 43.7909 0 46 0H72C74.2091 0 76 1.79086 76 4V88C76 90.2091 74.2091 92 72 92H46C43.7909 92 42 90.2091 42 88V4Z"
          fill="white" />
      </clipPath>
      <clipPath id="clip2_4963_13588">
        <path
          d="M84 4C84 1.79086 85.7909 0 88 0H114C116.209 0 118 1.79086 118 4V92H84V4Z"
          fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SvgSelectSpots'
});
</script>
