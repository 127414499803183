<template>
  <dialog-base
    :key="renderKey"
    :loading="false"
    v-bind="$props"
    header="Cancel Appointment?"
    loading-message="Cancelling appointment..."
    @close="close"
    @confirm="confirm"
    max-width="100%"
    has-dialog-actions
    :value="showDialog"
    width="500px">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs">
        Cancel Appointment
      </primary-button>
    </template>
    <template v-slot:body>
      <span class="px-4">This will make the time slot available again.</span>
      <cancel-appointment-form-old
        ref="form"
        @nevermind="nevermind"
        :mixpanel-entry-point="mixpanelEntryPoint"
        @confirm="confirm"
        :appointment="appointment"></cancel-appointment-form-old>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    appointment: {
      type: Object,
      required: true
    },
    mixpanelEntryPoint: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      customFields: [],
      tags: [],
      refNumber: ''
    };
  }
};
</script>
