<template>
  <div class="pt-5">
    <apexchart
      v-if="data && data.length"
      type="donut"
      :options="options"
      :height="chartHeight"
      :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

/**
 * @displayName Bar Chart
 */
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    /**
     * Data to display
     */
    data: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: false
    },
    colors: {
      type: Array,
      required: false
    },
    totalValue: {
      type: String,
      required: false
    },
    totalLabel: {
      type: String,
      required: false,
      default: 'Total'
    },
    chartHeight: {
      type: String,
      required: false,
      default: '400px'
    }
  },
  data() {
    return {
      options: {
        labels: this.labels || null,
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center'
        },
        chart: {
          toolbar: {
            show: true,
            offsetX: -5,
            offsetY: 0
          }
        },
        colors: this.colors || null,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: this.totalLabel,
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                  formatter: w => {
                    if (this.totalValue) {
                      return this.totalValue;
                    } else {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    }
                  }
                }
              }
            }
          }
        }
      },
      series: []
    };
  },
  watch: {
    data() {
      setTimeout(() => {
        this.series = this.data;
      }, 500);
    }
  },
  mounted() {
    this.series = this.data;
  }
};
</script>
