<template>
  <form-base>
    <template #form>
      <v-form ref="form" v-on:submit.prevent @keyup.native.enter="submit">
        <p>
          <strong>Organization:</strong>
          {{ $org.name }}
        </p>

        <p>
          <v-chip>
            <strong>{{ formatDate($org.expiresAt) }}</strong>
          </v-chip>
          <v-icon class="mx-3">mdi-arrow-right-thin</v-icon>
          <v-chip>
            <strong>{{ formatDate(localOrgExpiresAt) }}</strong>
          </v-chip>
        </p>

        <p v-if="novaCore.isExpiresAtWarningVisible(localOrgExpiresAt)">
          <v-icon color="red" small class="mr-1">mdi-alert</v-icon>
          {{ novaCore.getExpirationWarningText(localOrgExpiresAt) }}
        </p>
        <v-container>
          <date-picker
            :minDate="minDate"
            type="text"
            v-model="localOrgExpiresAt"
            placeholder="New expiration date"
            :rules="$validator.rules.date"></date-picker>
        </v-container>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="cancel"
        confirm-icon="update"
        confirm-label="save"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * Edit Org Form
 * @displayName Edit Org Form
 */
import { isDate } from 'class-validator';

export default {
  props: {
    orgId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localOrgExpiresAt: null
    };
  },
  computed: {
    isValid() {
      return isDate(this.localOrgExpiresAt);
    },
    minDate() {
      return momentjs().format(this.novaCore.DateTimeFormats.DateDashed);
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.localOrgExpiresAt) {
        this.localOrgExpiresAt = null;
      }

      const org = await this.$store.dispatch('Orgs/updateOrg', {
        expiresAt: this.localOrgExpiresAt
      });

      if (org) {
        this.$emit('save', this.localOrgExpiresAt);
        this.$emit('close');
      }
    },
    formatDate(date) {
      return date ? this.novaCore.formatExpiresAt(date) : 'No expiration date';
    },
    cancel() {
      this.setLocalOrgExpiresAt();
      this.$emit('close');
    },
    setLocalOrgExpiresAt() {
      this.localOrgExpiresAt = this.$org.expiresAt;
    }
  },
  async mounted() {
    await this.$store.dispatch('Orgs/getOrg', this.orgId);
    this.setLocalOrgExpiresAt();
  }
};
</script>
