var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          "content-class": "dock-dialog-content",
          header: "Create Dock",
          scrollable: false,
          "max-width": "60vw",
          width: "60vw",
          "loading-message": "Saving Dock...",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.dockForm.submit()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function (slotProps) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      top: "",
                      disabled: _vm.$rolePermissions.canCreateAppointment,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "div",
                                _vm._g(_vm._b({}, "div", attrs, false), on),
                                [
                                  _vm.$scopedSlots.activator
                                    ? _vm._t("activator", null, null, slotProps)
                                    : _c(
                                        "primary-button",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                "before-icon": _vm.beforeIcon,
                                                disabled:
                                                  !_vm.$rolePermissions
                                                    .canCreateDock,
                                                large: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onOpenModal()
                                                },
                                              },
                                            },
                                            "primary-button",
                                            slotProps.attrs,
                                            false
                                          ),
                                          slotProps.on
                                        ),
                                        [_vm._v(" Create Dock ")]
                                      ),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.novaCore.getRoleActionError())),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("dock-form", {
            ref: "dockForm",
            attrs: { dock: _vm.dock, "warehouse-id": _vm.warehouseId },
            on: {
              saved: function ($event) {
                return _vm.$eventHub.emit("create-Dock")
              },
              close: _vm.close,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }