var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "elevation-5 rounded" },
    [
      _c("apexchart", {
        key: _vm.increment,
        ref: "chart",
        attrs: {
          type: "bar",
          height: "350",
          options: _vm.chartOptions,
          series: _vm.series,
        },
        on: { click: _vm.handleClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }