var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      staticClass: "pa-0 mb-4 calendar-header-wrapper",
      attrs: { height: "64" },
    },
    [
      _c(
        "v-toolbar",
        { staticClass: "calendar-header", attrs: { flat: "" } },
        [
          _c(
            "div",
            { staticClass: "menu-container" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    "internal-activator": true,
                    "close-on-click": true,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on: tooltipOn }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g({}, tooltipOn),
                                          [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "mr-3 calendar-gutter-btn",
                                                    attrs: {
                                                      disabled: _vm.listMode,
                                                      icon: "",
                                                      ripple: false,
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      "data-testid":
                                                        "interval-select-button-icon",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-timeline-clock-outline "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.listMode
                                        ? "Grid time interval granularity selection disabled in list view"
                                        : "Select grid time interval granularity"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.intervalOptions, function (item, index) {
                      return _c(
                        "v-list-item",
                        { key: index, staticClass: "account-option" },
                        [
                          _c(
                            "button-base",
                            {
                              staticClass: "text-left",
                              attrs: { small: "", text: "", block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.setIntervalMinutes(item)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "flex-grow-1 justify-start" },
                                [_vm._v(_vm._s(item) + " minutes")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "menu-container secondary-button-group" },
            [
              _c(
                "v-btn-toggle",
                {
                  attrs: { mandatory: "" },
                  model: {
                    value: _vm.viewType,
                    callback: function ($$v) {
                      _vm.viewType = $$v
                    },
                    expression: "viewType",
                  },
                },
                _vm._l(_vm.availableViewTypes, function (type) {
                  return _c(
                    "v-btn",
                    {
                      key: `calendar-${type}`,
                      staticClass: "view-type",
                      attrs: { outlined: "", color: "darken-2", value: type },
                    },
                    [_vm._v(" " + _vm._s(type) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("calendar-date-select", {
            attrs: {
              value:
                _vm.gridMode && _vm.calendar
                  ? _vm.calendar.value
                  : _vm.$selectedDate,
              "grid-mode": _vm.gridMode,
              "view-type": _vm.viewType,
              "data-testid": "grid-calendar-select",
              "week-details":
                _vm.gridMode && _vm.calendar && _vm.viewType === "WEEK"
                  ? {
                      start: _vm.calendar.getStartOfWeek(
                        _vm.calendar.parsedValue
                      ),
                      end: _vm.calendar.getEndOfWeek(_vm.calendar.parsedValue),
                    }
                  : undefined,
            },
            on: { input: _vm.calendarChange },
          }),
          _c("div", { staticClass: "flex-break" }),
          _c(
            "div",
            { staticClass: "menu-container mr-4" },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    "internal-activator": true,
                    "close-on-click": true,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    outlined: "",
                                    color: "darken-2",
                                    ripple: false,
                                    "data-testid":
                                      "grid-view-mode-menu-activator",
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    "data-testid": "view-mode-activator",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.getViewModeMeta().icon))]
                              ),
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getViewModeMeta().label) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-chevron-down")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "plain-items",
                      attrs: { "data-testid": "view-mode-options" },
                    },
                    [
                      _vm._l(_vm.viewModes, function (item, key) {
                        return _c(
                          "v-list-item",
                          {
                            key: key,
                            staticClass: "account-option is-relative z-100",
                          },
                          [
                            _c(
                              "button-base",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                                attrs: {
                                  "data-testid": `${item}-view-button`,
                                  text: "",
                                  plain: "",
                                  ripple: false,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.mode = item
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.getViewModeMeta(item).icon)
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "flex-grow-1 justify-start font-size-x-small",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getViewModeMeta(item).label
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-list-item",
                        { staticClass: "account-option is-relative z-100" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "button-base",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                          attrs: {
                                            "data-testid": "tv-mode-button",
                                            text: "",
                                            plain: "",
                                            ripple: false,
                                          },
                                          on: { click: _vm.viewTvMode },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-television-play")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-grow-1 justify-start font-size-x-small",
                                            },
                                            [_vm._v("Open TV Mode")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "TV Mode will open a new browser window"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "account-option is-relative z-100",
                          class: {
                            "active-highlight": _vm.$shouldShowWeekends,
                            disabled: _vm.isWeekdayOptionDisabled,
                          },
                        },
                        [
                          _c(
                            "button-base",
                            {
                              staticClass: "d-flex align-center justify-center",
                              attrs: {
                                "data-testid": "weekend-toggle-button",
                                text: "",
                                plain: "",
                                ripple: false,
                              },
                              on: { click: _vm.toggleWeekendVisibility },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$shouldShowWeekends
                                        ? "mdi-checkbox-marked"
                                        : "mdi-checkbox-blank-outline"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex-grow-1 justify-start font-size-x-small",
                                },
                                [_vm._v("Show weekends")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "calendar-nav d-flex align-center justify-end" },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex align-center",
                  attrs: { "data-testid": "calendar-date-step" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { outlined: "", color: "grey darken-2" },
                      on: { click: _vm.setToday },
                    },
                    [_vm._v("Today")]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            text: "",
                            small: "",
                            color: "grey darken-2",
                            "data-testid": "grid-prev-period",
                          },
                          on: { click: _vm.prev },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-chevron-left"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            text: "",
                            small: "",
                            color: "grey darken-2",
                            "data-testid": "grid-next-period",
                          },
                          on: { click: _vm.next },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-chevron-right"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }