var render = function render(_c, _vm) {
  return _c(
    "span",
    { staticClass: "quick-reserve-wrapper" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "mdi-lightning-bolt",
                        attrs: {
                          "x-small": "",
                          icon: "",
                          disabled: _vm.props.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.listeners["create-reserve"]({
                              dockId: _vm.props.dockId,
                              start: _vm.props.start,
                            })
                          },
                        },
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("mdi-lightning-bolt"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", [
            _vm._v(
              "Creates a Reserve with a duration of " +
                _vm._s(_vm.props.intervalMinutes) +
                " minutes"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }