<template>
  <div>
    <div class="entry-form-container" v-if="showVerification">
      <verify-email
        header="Your email is unverified"
        :login="true"
        @backToLogin="showVerification = false"
        :email="creds.email"></verify-email>
    </div>

    <template v-else>
      <incorrect-portal-dialog
        :email="portalEmail"
        :show-dialog="showIncorrectPortalDialog"
        @close="showIncorrectPortalDialog = false"
        external-activator></incorrect-portal-dialog>
      <div class="entry-form-container">
        <v-container fluid>
          <v-tabs grow v-model="tab" class="py-3 non-active-tab-underline">
            <v-tab class="flex-grow-1 font-weight-bold">
              <v-icon class="mr-2">mdi-warehouse</v-icon>
              WAREHOUSE
            </v-tab>
            <v-tab class="flex-grow-1 font-weight-bold">
              <v-icon class="mr-2">mdi-truck</v-icon>
              CARRIER
            </v-tab>
          </v-tabs>
        </v-container>
      </div>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form
            ref="form"
            @keyup.native.enter="submit"
            v-if="!loggedIn"
            class="entry-form-container px-2 mt-2"
            data-testid="login-form">
            <text-field
              :required="true"
              type="email"
              dense
              v-model="creds.email"
              :rules="[...$validator.rules.required('Email'), ...$validator.rules.email]"
              label="Email"
              outlined
              :persistent-placeholder="true"
              placeholder="Type your email ..."></text-field>
            <text-field
              :required="true"
              type="password"
              dense
              v-model="creds.password"
              :rules="$validator.rules.required('Password')"
              label="Password"
              outlined
              :persistent-placeholder="true"
              placeholder="Type your password ..."></text-field>
            <div class="d-flex justify-end">
              <forgot-password-dialog
                :external-activator="true"
                :show-dialog="showPasswordDialog"
                @show-incorrect-portal="displayIncorrectPortalDialog"
                @close="showPasswordDialog = false"></forgot-password-dialog>
            </div>
            <div class="d-flex justify-end mb-8">
              <v-btn
                :ripple="false"
                text
                x-small
                dense
                class="link forgot-password-btn"
                @click="showPasswordDialog = true">
                Forgot your password?
              </v-btn>
            </div>
          </v-form>

          <div class="entry-form-container pb-8 px-2" v-if="!showVerification">
            <primary-button block x-large class="login-button" @click="submit" :loading="loading">
              Log in
            </primary-button>
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="entry-form-container">
            <v-container fluid>
              <div class="pt-12 px-14 pb-16 mb-6 text-center">
                To log in as a carrier, please proceed to our Opendock Scheduling Portal
              </div>

              <a
                :href="`${lunaUrl}/login`"
                class="primary-button full-width d-flex pa-3 text-center v-btn v-size--x-large font-weight-bold"
                target="_blank"
                rel="noopener">
                Go to Scheduling Portal
                <v-icon class="black--text ml-2">mdi-arrow-right</v-icon>
              </a>
            </v-container>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import config from '@/config/config';

/**
 * Login form
 * @displayName Login Form
 */
export default {
  computed: {
    lunaUrl() {
      return config.luna_url;
    }
  },
  props: {
    /**
     * Form header
     */
    header: {
      type: String,
      required: false
    },
    verificationToken: {
      type: String,
      required: false,
      default: ''
    },
    defaultEmail: {
      type: String,
      required: false,
      default: ''
    },
    loginPath: {
      type: String,
      required: false,
      default: 'appointments'
    }
  },
  data() {
    return {
      creds: {
        email: this.defaultEmail,
        password: ''
      },
      portalEmail: '',
      verificationMode: false,
      loggedIn: false,
      loading: false,
      showPasswordDialog: false,
      showVerification: false,
      alertMessage: null,
      alertType: 'success',
      tab: null,
      showIncorrectPortalDialog: false
    };
  },
  methods: {
    displayIncorrectPortalDialog(email) {
      this.portalEmail = email;
      this.showIncorrectPortalDialog = true;
    },
    /**
     * Submit the form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const isUserCarrier = await this.$store
          .dispatch('Carriers/doesCarrierExist', this.creds.email)
          .finally(() => {
            this.loading = false;
          });

        if (isUserCarrier) {
          this.displayIncorrectPortalDialog(this.creds.email);
          this.loading = false;
          return;
        }

        this.$store
          .dispatch('Auth/login', this.creds)
          .then(response => {
            if (response?.status !== 200) {
              this.showVerification =
                response?.data?.errorCode === this.novaCore.NovaErrorCode.UnverifiedEmail;
            } else if (localStorage.getItem('access_token')) {
              setTimeout(() => {
                this.loggedIn = true;
              }, 500);
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    cancel() {
      /**
       * Emits the close event
       * @event close
       */
      this.$emit('close');
    }
  },
  mounted() {
    if (this.verificationToken) {
      let alertMessage = '';
      let alertType = '';
      this.verificationMode = true;
      this.$store
        .dispatch('Auth/verifyEmail', this.verificationToken)
        .then(response => {
          this.creds.email = response.data.details.email;

          this.mixpanel.identify({ email: this.creds.email });
          this.mixpanel.track(this.mixpanel.events.ACCOUNT.ACTIVATED);

          alertMessage = 'Your email has been verified, you can login now.';
          alertType = 'success';
          this.notify(alertMessage, alertType, { duration: 8000 });
        })
        .catch(e => {
          alertMessage = e.response?.data?.message ?? 'An unknown error occurred.';
          alertType = 'error';
          this.notify(alertMessage, alertType, { duration: 8000 });
        });
    }
  },
  watch: {
    $me(val) {
      if (val) {
        this.mixpanel.identify(this.$me);
        this.mixpanel.track(this.mixpanel.events.MODULE.USER.LOGIN);
        if (
          this.$route.query.redirectPath &&
          this.$route.query.redirectPath !== '/login' &&
          this.$route.query.redirectPath !== '/'
        ) {
          this.$router.push({ path: this.$route.query.redirectPath });
        } else {
          if (this.novaCore.isInternalUser(this.$me)) {
            // Navigate internal users to the users page
            this.$router.push({ name: 'internalHome' });
          } else {
            // Navigate all others to the default home page
            this.$router.push({ name: this.loginPath });
          }
        }
      }
    }
  }
};
</script>
