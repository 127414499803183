<template>
  <date-picker
    no-margins
    v-model="value"
    dense
    class="ml-0"
    height="20px"
    :required="field.required"
    no-title
    compact-display
    outlined
    :display-field-label="false"
    :display-field-icon="false"></date-picker>
</template>

<script>
import customFieldTypeMixin from '@/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'CustomFieldDate',
  mixins: [customFieldTypeMixin]
};
</script>
