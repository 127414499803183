<template>
  <div class="elevation-5 rounded">
    <apexchart
      :key="increment"
      type="bar"
      ref="chart"
      height="350"
      @click="handleClick"
      :options="chartOptions"
      :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

/**
 * @displayName Bar Chart
 */
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    /**
     * Title of chart
     */
    title: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false
    },
    /**
     * Data to display
     */
    data: {
      type: Array,
      required: true
    },
    /**
     * Show data labels
     */
    showDataLabels: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Horizontal bar chart
     */
    horizontal: {
      type: Boolean,
      required: false,
      default: false
    },
    xAxisTitle: {
      type: String,
      required: false
    },
    noDataText: {
      type: String,
      required: false
    },
    dataLabelFormatter: {
      type: Function,
      required: false,
      default: function (val) {
        return val;
      }
    },
    stacked: {
      type: Boolean,
      required: false,
      default: false
    },
    categories: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Chart colors
     */
    colors: {
      type: Array,
      required: false,
      default: null
    },
    forceLabelMiddle: {
      type: Boolean,
      required: false,
      default: false
    },
    customOffset: {
      type: Object,
      required: false,
      default: null
    },
    showForSingleSeries: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      increment: 0,
      series: this.buildSeries(),
      chartOptions: {
        theme: {
          palette: 'palette1'
        },
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: true,
            offsetX: -5,
            offsetY: 10
          },
          stacked: this.stacked
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: this.stacked || this.forceLabelMiddle ? 'center' : 'top' // top, center, bottom
            },
            horizontal: this.horizontal
          }
        },
        dataLabels: {
          formatter: this.dataLabelFormatter,
          enabled: this.showDataLabels,
          offsetY: this.makeLabelOffsets().y,
          offsetX: this.makeLabelOffsets().x,
          style: {
            fontSize: '12px',
            colors: ['#333']
          }
        },

        xaxis: {
          position: 'bottom',
          axisBorder: {
            show: true
          },
          tooltip: {
            enabled: false
          },
          title: {
            text: this.xAxisTitle || ''
          },
          labels: {
            show: false
          },
          categories: this.makeCategories()
        },
        title: {
          text: this.title || '',
          floating: false,
          offsetY: 20,
          offsetX: 10,
          margin: 30,
          align: 'left',
          style: {
            color: '#444',
            fontSize: '1.3rem',
            fontWeight: 'bold'
          }
        },
        subtitle: {
          text: this.subTitle || '',
          align: 'left',
          floating: false,
          offsetY: 55,
          offsetX: 10,
          style: {
            fontSize: '1.1rem',
            fontWeight: 'normal',
            color: '#9699a2'
          }
        },
        noData: {
          text: this.noDataText || 'Not enough data yet...',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0
        },
        legend: {
          showForSingleSeries: this.showForSingleSeries
        }
      }
    };
  },
  watch: {
    data() {
      this.series = this.buildSeries();
      this.chartOptions.xaxis.labels.show = Boolean(this.data?.length);
      this.increment++;
    },
    categories() {
      this.chartOptions.xaxis.categories = this.makeCategories();
    },
    horizontal() {
      this.chartOptions.plotOptions.bar.horizontal = this.horizontal;
      this.chartOptions.dataLabels.offsetY = this.makeLabelOffsets().y;
      this.chartOptions.dataLabels.offsetX = this.makeLabelOffsets().x;
      this.increment++;
    },
    colors() {
      this.setColors();
    }
  },
  methods: {
    handleClick(event, chartContext, config) {
      this.$emit('click', { event, chartContext, config });
    },
    buildSeries() {
      if (this.categories?.length) {
        return this.data;
      } else {
        return [
          {
            data: this.data
          }
        ];
      }
    },
    makeLabelOffsets() {
      if (this.customOffset) {
        return this.customOffset;
      }

      let offset = { x: 0, y: -20 };

      if (this.horizontal) {
        offset = { x: 20, y: 0 };
      }

      if (this.stacked) {
        offset = { x: 0, y: 2 };
      }

      return offset;
    },
    makeCategories() {
      return this.categories;
    },
    setColors() {
      if (this.colors?.length) {
        this.chartOptions.colors = this.colors;
        this.increment++;
      }
    }
  },
  mounted() {
    this.chartOptions.xaxis.labels.show = Boolean(this.data?.length);
    this.increment++;
    this.setColors();
  }
};
</script>
