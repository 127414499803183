var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    attrs: { "is-loading": _vm.loading },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c("text-field", {
                              attrs: {
                                required: true,
                                type: "text",
                                rules:
                                  _vm.$validator.rules.required("First Name"),
                                label: "First Name",
                              },
                              model: {
                                value: _vm.localUser.firstName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localUser, "firstName", $$v)
                                },
                                expression: "localUser.firstName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c("text-field", {
                              attrs: {
                                required: true,
                                type: "text",
                                rules:
                                  _vm.$validator.rules.required("Last Name"),
                                label: "Last Name",
                              },
                              model: {
                                value: _vm.localUser.lastName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localUser, "lastName", $$v)
                                },
                                expression: "localUser.lastName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _vm.showComboBoxRoles
                              ? _c("role-select", {
                                  attrs: {
                                    "parent-role": _vm.$me.role,
                                    "excluded-roles": [
                                      _vm.novaCore.UserRole.BASE,
                                      _vm.novaCore.UserRole.CARRIER_ADMIN,
                                      _vm.novaCore.UserRole.CARRIER,
                                    ],
                                    rules:
                                      _vm.$validator.rules.required("Role"),
                                  },
                                  model: {
                                    value: _vm.localUser.role,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.localUser, "role", $$v)
                                    },
                                    expression: "localUser.role",
                                  },
                                })
                              : _c(
                                  "div",
                                  { staticClass: "v-text-field" },
                                  [
                                    _c("v-label", [_vm._v("Role")]),
                                    _c(
                                      "p",
                                      { staticClass: "mb-5" },
                                      [
                                        _c("v-label", [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.novaCore.userRoleToText(
                                                  _vm.localUser.role
                                                )
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    [
                      _c("user-restriction-switch", {
                        staticClass: "mt-1",
                        attrs: {
                          role: _vm.localUser.role,
                          readOnly: !_vm.isUserAllowedToUnRestrictUser,
                        },
                        model: {
                          value: _vm.shouldTargetUserBeRestrictedByLocation,
                          callback: function ($$v) {
                            _vm.shouldTargetUserBeRestrictedByLocation = $$v
                          },
                          expression: "shouldTargetUserBeRestrictedByLocation",
                        },
                      }),
                      _vm.shouldTargetUserBeRestrictedByLocation
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("basic-warehouse-select", {
                                    attrs: {
                                      "restricted-warehouse-ids":
                                        _vm.currentUserWarehouseAccessList,
                                      "filter-locally": false,
                                      orgId: _vm.localUser.orgId,
                                      disabled:
                                        !_vm.isUserAllowedToUnRestrictUser,
                                      multiple: "",
                                    },
                                    model: {
                                      value: _vm.selectedWarehouses,
                                      callback: function ($$v) {
                                        _vm.selectedWarehouses = $$v
                                      },
                                      expression: "selectedWarehouses",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "confirm-icon": "upload",
                "confirm-label": "Update User",
              },
              on: {
                cancel: function ($event) {
                  return _vm.$emit("close")
                },
                confirm: _vm.submit,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }