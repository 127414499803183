<template>
  <v-card-actions class="full-width" :class="{ 'd-block': stackActions }">
    <!-- @slot Additional Actions to show in action group -->
    <slot name="additional-actions"></slot>
    <template v-for="button in buttons">
      <template v-if="button === 'extra'">
        <div
          :key="`${button}-button`"
          :class="{
            inline: inlineActionsMobile,
            'full-width': stackActions,
            'mt-2': stackActions,
            'ml-0': stackActions
          }"></div>
        <v-spacer :key="`${button}-spacer`" v-if="isSpacerVisible"></v-spacer>
      </template>
      <template v-else-if="button === 'cancel'">
        <outline-button
          :key="`${button}-button`"
          v-if="!hideCancel"
          :color="cancelColor"
          @click="$emit('cancel', $event)"
          :class="{
            inline: inlineActionsMobile,
            'full-width': stackActions,
            'mt-2': stackActions,
            'ml-0': stackActions
          }"
          :before-icon="cancelIcon">
          {{ cancelLabel }}
        </outline-button>
      </template>
      <template v-else-if="button === 'confirm'">
        <v-tooltip top v-if="confirmButtonTooltip && !hideConfirm" :key="`${button}-tooltip`">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <primary-button
                v-bind="confirmButtonProps"
                @click="$emit('confirm', $event)"
                :class="{ inline: inlineActionsMobile, 'full-width': stackActions }">
                {{ confirmLabel }}
              </primary-button>
            </div>
          </template>
          <span>{{ confirmButtonTooltip }}</span>
        </v-tooltip>
        <primary-button
          :key="`${button}-button`"
          :class="{ inline: inlineActionsMobile, 'full-width': stackActions }"
          v-else-if="!hideConfirm"
          v-bind="confirmButtonProps"
          @click="$emit('confirm', $event)">
          {{ confirmLabel }}
        </primary-button>
      </template>
    </template>
    <div
      :class="{
        inline: inlineActionsMobile,
        'full-width': stackActions,
        'mt-2': stackActions,
        'ml-0': stackActions
      }">
      <slot name="additional-actions-after"></slot>
    </div>
  </v-card-actions>
</template>

<script>
import PrimaryButton from './buttons/PrimaryButton';
import OutlineButton from './buttons/OutlineButton';

/**
 * Group of action buttons usually placed at the bottom of dialog or form
 * @displayName Action Group
 */
export default {
  components: {
    OutlineButton,
    PrimaryButton
  },
  computed: {
    buttons() {
      return this.stackActions ? ['confirm', 'cancel', 'extra'] : ['extra', 'cancel', 'confirm'];
    },
    confirmButtonProps() {
      return {
        block: this.isConfirmBlock,
        loading: this.loading,
        disabled: this.disableConfirm,
        beforeIcon: this.confirmIcon
      };
    }
  },
  props: {
    isConfirmBlock: {
      type: Boolean,
      required: false,
      default: false
    },
    isSpacerVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Label used for confirm button
     */
    confirmLabel: {
      type: String,
      required: false,
      default: 'Confirm'
    },
    /**
     * Icon name prepended to confirm button
     * Sans "mdi-"
     */
    confirmIcon: {
      type: String,
      required: false
    },
    /**
     * Hide confirm button
     */
    hideConfirm: {
      type: Boolean,
      required: false
    },
    /**
     * Label used for cancel button
     */
    cancelLabel: {
      type: String,
      required: false,
      default: 'Nevermind'
    },
    /**
     * Icon name prepended to cancel button
     * Sans "mdi-"
     */
    cancelIcon: {
      type: String,
      required: false,
      default: 'close-circle'
    },
    /**
     * Cancel button color
     */
    cancelColor: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Hide cancel button
     */
    hideCancel: {
      type: Boolean,
      required: false
    },
    /**
     * Disable confirm button
     */
    disableConfirm: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    confirmButtonTooltip: {
      type: String,
      required: false,
      default: ''
    },
    inlineActionsMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    stackActions: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
