var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          "no-padding": "",
          "use-global-loader": false,
          "content-class": "details-dialog reschedule-appointment-dialog",
          transition: "dialog-bottom-transition",
          "loading-message": "updating appointment",
          "max-width": "100%",
          value: _vm.showDialog,
          header: "Reschedule appointment",
          width: "1200px",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u([
          {
            key: "customHeader",
            fn: function () {
              return [
                _c(
                  "header",
                  { staticClass: "full-width" },
                  [
                    _c("dialog-header", {
                      attrs: { header: "Reschedule appointment" },
                      on: { close: _vm.close },
                      scopedSlots: _vm._u([
                        {
                          key: "prepend-button",
                          fn: function () {
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: { icon: "", text: "" },
                                  on: { click: _vm.close },
                                },
                                [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "close-icon",
                          fn: function () {
                            return [_c("v-icon", [_vm._v("mdi-close")])]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.alertMessage
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mb-0",
                        attrs: { type: _vm.alertType, shaped: "" },
                      },
                      [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
                    )
                  : _vm._e(),
                _c("reschedule-appointment-form", {
                  ref: "rescheduleAppointmentForm",
                  attrs: { appointment: _vm.appointment },
                  on: { close: _vm.close },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }