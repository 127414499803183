<template>
  <div>
    <p>Appointment updates timeline:</p>
    <audit-log-appointment
      :object-id="appointment.id"
      :org="$org"
      :entity-obj="appointment"
      entity="appointment"></audit-log-appointment>
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';

export default {
  extends: AppointmentDetailsBase
};
</script>

<style lang="scss" scoped>
::v-deep {
  table {
    margin-left: -24px;
    width: calc(100% + 24px);
  }
}
</style>
