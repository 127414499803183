var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("appointments-loader", {
        attrs: {
          "is-loading": _vm.isLoading,
          "custom-message":
            !_vm.searchOptions.scheduledStart || !_vm.searchOptions.scheduledEnd
              ? "For better performance, try adding a date range to your search..."
              : null,
        },
      }),
      _c(
        "v-card",
        { staticClass: "appointment-search" },
        [
          _c(
            "div",
            { staticClass: "search-bar" },
            [
              _c(
                "v-card-title",
                { staticClass: "pb-4" },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "start" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "is-relative",
                          attrs: { xs: "6", md: "7" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "is-relative" },
                            [
                              _c("text-field", {
                                staticClass: "multi-icon",
                                attrs: {
                                  outlined: "",
                                  autofocus: "",
                                  clearable: "",
                                  "clear-icon": "mdi-close",
                                  height: "30",
                                  "data-testid": "outer-search-input-wrapper",
                                  label:
                                    "Search by Reference Number, Confirmation Number, Tags...",
                                  placeholder: "Has the Words...",
                                  "hide-details": "auto",
                                  "prepend-inner-icon": "mdi-magnify",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.search.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.searchOptions.searchStr,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchOptions,
                                      "searchStr",
                                      $$v
                                    )
                                  },
                                  expression: "searchOptions.searchStr",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "field-button",
                                  attrs: { "x-small": "", plain: "", text: "" },
                                  on: { click: _vm.toggleSearchOptions },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      class: {
                                        "secondary--text":
                                          _vm.canSearch && _vm.hasOptionsDiff,
                                      },
                                      attrs: { small: "" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.canSearch && _vm.hasOptionsDiff
                                              ? "mdi-filter-multiple"
                                              : "mdi-filter-multiple-outline"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" Filter "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.shouldShowSearchOptions
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "on-clickaway",
                                      rawName: "v-on-clickaway",
                                      value: _vm.hideSearchOptions,
                                      expression: "hideSearchOptions",
                                    },
                                  ],
                                  staticClass: "search-options-container",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "search-fields py-6 px-9" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold font-size-small",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.optionLabels.searchStr
                                                  ) +
                                                  ": "
                                              ),
                                            ]
                                          ),
                                          _c("text-field", {
                                            staticClass: "mb-6",
                                            attrs: {
                                              height: "30",
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.search.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.searchOptions.searchStr,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchOptions,
                                                  "searchStr",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchOptions.searchStr",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold font-size-small",
                                            },
                                            [_vm._v("Booked At:")]
                                          ),
                                          _c("warehouse-multi-select", {
                                            staticClass: "mb-6",
                                            attrs: {
                                              joins: [
                                                "docks||capacityChildren,name,isActive,loadTypeIds",
                                              ],
                                              placeholder:
                                                "Booked at Warehouses",
                                              "show-create-warehouse-dialog": false,
                                              "show-create-dock-dialog": false,
                                              "hide-icon": "",
                                            },
                                            model: {
                                              value: _vm.selectedWarehouses,
                                              callback: function ($$v) {
                                                _vm.selectedWarehouses = $$v
                                              },
                                              expression: "selectedWarehouses",
                                            },
                                          }),
                                          _c("dock-select", {
                                            staticClass: "mb-6",
                                            attrs: {
                                              "hide-icon": "",
                                              disabled:
                                                !_vm.selectedWarehouses.length,
                                              multiple: true,
                                              "hide-inactive-docks": false,
                                              placeholder: "Booked at Docks",
                                              docks: _vm.availableDocks,
                                            },
                                            model: {
                                              value: _vm.selectedDocks,
                                              callback: function ($$v) {
                                                _vm.selectedDocks = $$v
                                              },
                                              expression: "selectedDocks",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold font-size-small",
                                            },
                                            [_vm._v("Carrier contacts:")]
                                          ),
                                          _c("carrier-select", {
                                            attrs: {
                                              multiple: "",
                                              "show-carrier-create-button": false,
                                              "hide-icon": "",
                                              "hide-details": "",
                                            },
                                            model: {
                                              value: _vm.searchOptions.users,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchOptions,
                                                  "users",
                                                  $$v
                                                )
                                              },
                                              expression: "searchOptions.users",
                                            },
                                          }),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold font-size-small",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.optionLabels.refNumber
                                                  ) +
                                                  ": "
                                              ),
                                            ]
                                          ),
                                          _c("text-field", {
                                            staticClass: "mb-6",
                                            attrs: {
                                              height: "30",
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.search.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.searchOptions.refNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchOptions,
                                                  "refNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchOptions.refNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold font-size-small",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.optionLabels
                                                      .customFields
                                                  ) +
                                                  ": "
                                              ),
                                            ]
                                          ),
                                          _c("text-field", {
                                            staticClass: "mb-6",
                                            attrs: {
                                              height: "30",
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.search.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.searchOptions.customFields,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchOptions,
                                                  "customFields",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchOptions.customFields",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold font-size-small",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.optionLabels.tags) +
                                                  ":"
                                              ),
                                            ]
                                          ),
                                          _c("text-field", {
                                            staticClass: "mb-6",
                                            attrs: {
                                              height: "30",
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.search.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.searchOptions.tags,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchOptions,
                                                  "tags",
                                                  $$v
                                                )
                                              },
                                              expression: "searchOptions.tags",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold font-size-small",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.optionLabels.notes) +
                                                  ":"
                                              ),
                                            ]
                                          ),
                                          _c("text-field", {
                                            staticClass: "mb-6",
                                            attrs: {
                                              height: "30",
                                              "hide-details": "auto",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.search.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.searchOptions.notes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchOptions,
                                                  "notes",
                                                  $$v
                                                )
                                              },
                                              expression: "searchOptions.notes",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-end" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-inline-block mr-2 font-weight-bold font-size-small",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.optionLabels.startDate
                                                  ) +
                                                  ": "
                                              ),
                                            ]
                                          ),
                                          _c("date-picker", {
                                            ref: `datepicker-start`,
                                            staticClass: "flex-1",
                                            attrs: {
                                              "inner-icon": "",
                                              "display-field-icon": "",
                                              "no-margins": "",
                                              clearable: true,
                                              placeholder: " ",
                                            },
                                            model: {
                                              value:
                                                _vm.searchOptions.startDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchOptions,
                                                  "startDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchOptions.startDate",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-inline-block ml-4 mr-2 font-weight-bold font-size-small",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.optionLabels.endDate
                                                  ) +
                                                  ": "
                                              ),
                                            ]
                                          ),
                                          _c("date-picker", {
                                            ref: `datepicker-end`,
                                            staticClass: "flex-1",
                                            attrs: {
                                              "inner-icon": "",
                                              "display-field-icon": "",
                                              "no-margins": "",
                                              clearable: true,
                                              placeholder: " ",
                                            },
                                            model: {
                                              value: _vm.searchOptions.endDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchOptions,
                                                  "endDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchOptions.endDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-6" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold font-size-small",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.optionLabels.statuses
                                                  ) +
                                                  ": "
                                              ),
                                            ]
                                          ),
                                          _c("status-select", {
                                            attrs: {
                                              "should-disable-auto-select": true,
                                              "single-line": false,
                                              placeholder: " ",
                                              "select-all": false,
                                              "hide-icon": "",
                                            },
                                            model: {
                                              value: _vm.searchOptions.statuses,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchOptions,
                                                  "statuses",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchOptions.statuses",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-end search-actions py-5 px-9",
                                    },
                                    [
                                      _c(
                                        "outline-button",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { disabled: !_vm.canSearch },
                                          on: { click: _vm.clearOptions },
                                        },
                                        [_vm._v(" Clear All ")]
                                      ),
                                      _c(
                                        "primary-button",
                                        {
                                          attrs: { disabled: !_vm.canSearch },
                                          on: {
                                            click: function ($event) {
                                              _vm.search()
                                              _vm.toggleSearchOptions()
                                            },
                                          },
                                        },
                                        [_vm._v(" Search ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "v-col",
                        { attrs: { xs: "2" } },
                        [
                          _c(
                            "primary-button",
                            {
                              attrs: {
                                "data-testid": "outer-search",
                                disabled: !_vm.canSearch,
                              },
                              on: { click: _vm.search },
                            },
                            [_vm._v(" Search ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right",
                          attrs: { xs: "4", md: "3" },
                        },
                        [
                          _c("date-range", {
                            ref: "dateRange",
                            attrs: {
                              "data-testid": "search-date-range",
                              clearable: "",
                              label: "Start date between",
                              outlined: "",
                              "initial-range": [
                                _vm.searchOptions.scheduledStart,
                                _vm.searchOptions.scheduledEnd,
                              ],
                            },
                            on: { input: _vm.startDateRangeChanged },
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "text-caption",
                              attrs: { href: "#" },
                              on: { click: _vm.clearDateRange },
                            },
                            [_vm._v("Clear date range")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.totalItems > 9999
                ? _c(
                    "v-alert",
                    { attrs: { color: "warning lighten-2", dense: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v("mdi-alert"),
                          ]),
                          _vm._v(
                            " Your search returned 10,000 results. Try adding more filters to narrow it down more. "
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              Object.keys(_vm.currentSearch).length > 0 &&
              !_vm.shouldShowSearchOptions
                ? _c(
                    "div",
                    { staticClass: "selected-options-container" },
                    [
                      _vm._l(_vm.currentSearch, function (option, key) {
                        return [
                          Array.isArray(_vm.currentSearch[key]) &&
                          _vm.currentSearch[key].length > 0
                            ? [
                                _c(
                                  "v-row",
                                  { key: `search-option-${key}` },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "font-size-x-small py-0" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(_vm.optionLabels[key]) + ":"
                                          ),
                                        ]),
                                        _vm._l(
                                          _vm.currentSearch[key],
                                          function (arrayVal, index) {
                                            return [
                                              _c(
                                                "v-chip",
                                                {
                                                  key: `search-option-${key}-${index}`,
                                                  staticClass: "ma-1",
                                                  attrs: {
                                                    small: "",
                                                    close: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    "click:close": function (
                                                      $event
                                                    ) {
                                                      return _vm.removeArrEl(
                                                        key,
                                                        index,
                                                        arrayVal
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getArrValue(
                                                          key,
                                                          arrayVal
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : !Array.isArray(_vm.currentSearch[key]) &&
                              _vm.currentSearch[key] &&
                              _vm.optionLabels[key]
                            ? [
                                _c(
                                  "v-row",
                                  { key: `search-option-${key}` },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "font-size-x-small py-0" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(_vm.optionLabels[key]) + ":"
                                          ),
                                        ]),
                                        _c(
                                          "v-chip",
                                          {
                                            staticClass: "ma-1",
                                            attrs: {
                                              small: "",
                                              close: "",
                                              color: "secondary",
                                            },
                                            on: {
                                              "click:close": function ($event) {
                                                _vm.searchOptions[key] = ""
                                                _vm.search()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.currentSearch[key]) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-data-table", {
            ref: "appointmentSearchTable",
            staticClass: "clickable-rows",
            attrs: {
              headers: _vm.headers,
              items: _vm.appointments,
              "server-items-length": _vm.totalItems,
              "sort-desc": _vm.sort.sortDesc,
              "custom-filter": _vm.searchRows,
              "item-key": "id",
              page: _vm.page,
              expanded: _vm.expanded,
              "footer-props": _vm.footerProps,
              options: _vm.options,
            },
            on: {
              "update:items": function ($event) {
                _vm.appointments = $event
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sort, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sort, "sortDesc", $event)
              },
              "update:page": function ($event) {
                _vm.page = $event
              },
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
              "update:options": function ($event) {
                _vm.options = $event
              },
              "update:sort-by": (sortByVal) => (_vm.sort.sortBy = sortByVal),
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.headers, function (header) {
                  return {
                    key: `item.${header.value}`,
                    fn: function ({ item, value }) {
                      return [
                        header.value === "user.email"
                          ? [
                              _c(
                                "copy-content",
                                {
                                  key: header.value,
                                  attrs: {
                                    content: value,
                                    label: "Click to copy carrier email",
                                  },
                                },
                                [_c("span", { domProps: { innerHTML: value } })]
                              ),
                            ]
                          : header.value === "user"
                          ? [
                              _c("span", { key: header.value }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.user.firstName) +
                                    " " +
                                    _vm._s(item.user.lastName) +
                                    " @ "
                                ),
                                item.user?.company?.name
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.user.company.name)),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          : header.value === "dock.name"
                          ? [
                              _c("span", { key: header.value }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.novaCore.getDockNameWithActiveStatus(
                                        item.dock
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          : [
                              _c("span", {
                                key: header.value,
                                domProps: { innerHTML: value },
                              }),
                            ],
                      ]
                    },
                  }
                }),
                {
                  key: "item.status",
                  fn: function ({ item }) {
                    return [
                      _c("strong", {
                        class: `${item.status.toLowerCase()} tile font-size-x-small`,
                        domProps: {
                          innerHTML: _vm.novaCore.breakWordsAtCaps(item.status),
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "item.actions",
                  fn: function ({ item, index }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "row-actions" },
                        [
                          index !== _vm.detailsIconLoadingIndex
                            ? _c("icon-tooltip-button", {
                                attrs: {
                                  disabled:
                                    _vm.detailsIconLoadingIndex !== null &&
                                    index !== _vm.detailsIconLoadingIndex,
                                  "icon-class": "mr-2",
                                  size: "large",
                                  tooltip: "View Appointment",
                                  icon: "eye",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEventClick(item, index)
                                  },
                                },
                              })
                            : _c("v-progress-circular", {
                                staticClass: "mr-2",
                                attrs: {
                                  indeterminate: "",
                                  color: "primary",
                                  size: "18",
                                },
                              }),
                          _c("icon-tooltip-button", {
                            attrs: {
                              size: "large",
                              tooltip: "Show More",
                              icon: _vm.expanded.includes(item)
                                ? "chevron-up"
                                : "chevron-down",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleRowExpansion(item)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                _vm.headers && _vm.headers.length
                  ? {
                      key: "expanded-item",
                      fn: function ({ headers, item }) {
                        return [
                          _c(
                            "td",
                            {
                              staticClass: "py-6",
                              attrs: { colspan: headers.length },
                            },
                            [
                              _c("h3", [_vm._v("Notes:")]),
                              _c("div", {
                                domProps: { innerHTML: _vm._s(item.notes) },
                              }),
                              _c("v-divider", { staticClass: "my-5" }),
                              _c("h3", [_vm._v("Tags")]),
                              _vm._l(item.tags, function (tag) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      key: tag,
                                      staticClass: "px-2 mr-1",
                                      attrs: {
                                        label: "",
                                        "text-color": _vm.util.makeTagObject(
                                          _vm.$org.customTags,
                                          tag
                                        ).textColor,
                                        color: _vm.util.makeTagObject(
                                          _vm.$org.customTags,
                                          tag
                                        ).color,
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._f("highlight")(
                                            _vm.util.makeTagObject(
                                              _vm.$org.customTags,
                                              tag
                                            ).name,
                                            _vm.regexSafeSearch,
                                            item.id,
                                            "tag"
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              }),
                              !item.tags || !item.tags.length
                                ? _c("span", [
                                    _vm._v(
                                      "This appointment does not have tags"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.appointmentTriggers(item)?.length
                                ? _c("v-divider", { staticClass: "my-5" })
                                : _vm._e(),
                              _vm._l(
                                _vm.appointmentTriggers(item),
                                function (trigger) {
                                  return _c(
                                    "div",
                                    { key: trigger.id, staticClass: "mb-2" },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                trigger.flow?.name ||
                                                  _vm.novaCore
                                                    .CustomFormsFeaturesData[
                                                    trigger.feature
                                                  ].title
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("custom-forms-data", {
                                        key: trigger.id,
                                        attrs: {
                                          trigger: trigger,
                                          "object-id": item.id,
                                          timezone:
                                            item.dock.warehouse.timezone,
                                          flat: "",
                                          "military-time-enabled":
                                            _vm.$isMilitaryTimeEnabled(
                                              item.dock.warehouse
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                    }
                  : null,
                {
                  key: "item.start",
                  fn: function ({ item }) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getTimeInWarehouseTimezone(
                                item,
                                `${_vm.novaCore.LuxonDateTimeFormats.LongDateShortMonth} @ ${_vm.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM} - ${_vm.novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                                `${_vm.novaCore.LuxonDateTimeFormats.LongDateShortMonth} @ ${_vm.novaCore.LuxonDateTimeFormats.Extended24HrTime} - ${_vm.novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                                _vm.$isMilitaryTimeEnabled(_vm.$org)
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "no-data",
                  fn: function () {
                    return [
                      !_vm.canSearch
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mt-3",
                              attrs: { color: "disabled" },
                            },
                            [
                              _vm._v(" No search filters "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.toggleSearchOptions },
                                },
                                [_c("v-icon", [_vm._v("mdi-filter-outline")])],
                                1
                              ),
                              _vm._v(" have been added yet "),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "mt-3" }, [
                            !_vm.searchOptions.scheduledStart ||
                            !_vm.searchOptions.scheduledEnd
                              ? _c(
                                  "p",
                                  { staticClass: "text-subtitle-2 py-4" },
                                  [
                                    _vm._v(
                                      " No data available. Please try adding a "
                                    ),
                                    _c("strong", [_vm._v("date range")]),
                                    _vm._v(" to your search. "),
                                  ]
                                )
                              : _c(
                                  "p",
                                  { staticClass: "text-subtitle-2 py-4" },
                                  [_vm._v("No data available.")]
                                ),
                          ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c("feature-flag", {
        attrs: { name: "enable-new-appointment-details" },
        scopedSlots: _vm._u([
          {
            key: "enabled",
            fn: function () {
              return [
                _vm.selectedAppointment
                  ? _c("appointment-details-dialog", {
                      attrs: {
                        "external-activator": true,
                        "show-dialog": _vm.showDetailsDialog,
                        context: _vm.selectedAppointment,
                        appointment: _vm.selectedAppointment,
                      },
                      on: { close: _vm.handleDetailsDialogClose },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "disabled",
            fn: function () {
              return [
                _vm.selectedAppointment
                  ? _c("appointment-details-dialog-old", {
                      attrs: {
                        "external-activator": true,
                        "show-dialog": _vm.showDetailsDialog,
                        context: _vm.selectedAppointment,
                        appointment: _vm.selectedAppointment,
                      },
                      on: { close: _vm.handleDetailsDialogClose },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }