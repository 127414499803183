<template>
  <div class="container--fluid mt-4 mx-4">
    <template v-if="!showAppointmentFields && !selectedFeatureTriggerId">
      <h3 class="mt-6">Appointment Creation</h3>
      <p>Manage the form required for appointment creation</p>

      <v-row class="my-6">
        <v-col cols="8">
          <v-expansion-panels accordion>
            <v-expansion-panel
              readonly
              @click="showAppointmentFields = true"
              data-testid="custom-fields">
              <v-expansion-panel-header>
                <h4>Appointment fields</h4>
                <template v-slot:actions>
                  <v-btn small depressed data-testid="appointment-fields">
                    Manage this form
                    <v-icon small class="ml-2">mdi-arrow-right</v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <h3 class="mt-6">Gate Management</h3>
      <p>Manage the forms required for arrivals and departures</p>

      <v-row class="my-6">
        <v-col cols="8">
          <v-expansion-panels>
            <v-expansion-panel v-for="[_, feature] of gateManagementFeatures" :key="feature">
              <v-expansion-panel-header>
                <h4>
                  {{ featureData(feature).title || 'Unknown' }}
                  <v-chip
                    v-if="feature !== novaCore.CustomFormsFeatures.CheckOut"
                    class="ml-2"
                    color="disabled"
                    x-small>
                    ADD-ON FEATURE
                  </v-chip>
                </h4>
              </v-expansion-panel-header>
              <v-divider></v-divider>
              <v-expansion-panel-content>
                <custom-forms-feature
                  :warehouse="warehouse"
                  :feature="feature"
                  :triggers="featureTriggers(feature)"
                  @manage="manageFeatureTrigger" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <h3 class="mt-6">Claims Support</h3>
      <p>
        Manage forms required for damage and other claim disputes
        <v-row class="my-6">
          <v-col cols="8">
            <v-expansion-panels>
              <v-expansion-panel v-for="[_, feature] of claimSupportFeatures" :key="feature">
                <v-expansion-panel-header>
                  <h4>
                    {{ featureData(feature).title || 'Unknown' }}
                  </h4>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content>
                  <custom-forms-feature
                    :warehouse="warehouse"
                    :feature="feature"
                    :triggers="featureTriggers(feature)"
                    @manage="manageFeatureTrigger" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </p>
    </template>
    <template v-if="showAppointmentFields">
      <appointment-fields
        :warehouse="warehouse"
        @back="() => (showAppointmentFields = false)"></appointment-fields>
    </template>
    <template v-if="selectedFeatureTrigger?.id">
      <custom-forms-trigger
        :warehouse="warehouse"
        :trigger="selectedFeatureTrigger"
        @data-updated="dataUpdated"
        @back="() => (selectedFeatureTriggerId = null)"></custom-forms-trigger>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    warehouse: {
      type: Object,
      required: true
    }
  },
  computed: {
    features() {
      return this.novaCore.CustomFormsFeatures;
    },
    selectedFeatureTrigger() {
      return this.warehouseTriggers.find(t => t.id === this.selectedFeatureTriggerId);
    },
    claimSupportFeatures() {
      return (
        Object.entries(this.features)
          // eslint-disable-next-line no-unused-vars
          .filter(([_, value]) =>
            Object.values(this.novaCore.CustomFormsClaimFeatures).includes(value)
          )
          .sort()
      );
    },
    gateManagementFeatures() {
      return (
        Object.entries(this.features)
          // eslint-disable-next-line no-unused-vars
          .filter(([_, value]) =>
            Object.values(this.novaCore.CustomFormsGateManagementFeatures).includes(value)
          )
          .sort()
      );
    }
  },
  data() {
    return {
      showAppointmentFields: false,
      selectedFeatureTriggerId: null,
      warehouseTriggers: []
    };
  },
  methods: {
    async dataUpdated() {
      await this.getWarehouseTriggers();
    },
    featureData(feature) {
      return this.novaCore.CustomFormsFeaturesData[feature] || {};
    },
    manageFeatureTrigger(trigger) {
      this.showAppointmentFields = false;
      this.selectedFeatureTriggerId = trigger.id;
    },
    async handleSocketEvent(event) {
      if (!event.warehouseId || event.warehouseId === this.warehouse.id) {
        await this.getWarehouseTriggers();
      }
    },
    async getWarehouseTriggers() {
      if (!this.warehouse.id) {
        return;
      }
      const response = await axios.get('custom-forms/trigger', {
        params: {
          s: { objectId: this.warehouse.id, entityName: 'warehouse' },
          limit: 100
        }
      });

      // Full join structure
      // trigger: { flow: { fromFrom: { formFields: [{ field: customField }] } } }

      this.warehouseTriggers = response?.data?.data || [];
    },
    featureTriggers(feature) {
      return this.warehouseTriggers.filter(trigger => trigger.feature === feature);
    }
  },
  beforeMount() {
    this.getWarehouseTriggers();
  },
  mounted() {
    Object.keys(this.sockets.actions).map(action => {
      this.$eventHub.$on(`${action}-Trigger`, this.handleSocketEvent);
      this.$eventHub.$on(`${action}-FormField`, this.handleSocketEvent);
      this.$eventHub.$on(`${action}-Flow`, this.handleSocketEvent);
    });
  },
  beforeDestroy() {
    Object.keys(this.sockets.actions).map(action => {
      this.$eventHub.$off(`${action}-Trigger`);
      this.$eventHub.$off(`${action}-FormField`);
      this.$eventHub.$off(`${action}-Flow`);
    });
  }
};
</script>
