<template>
  <form-base>
    <template #form>
      <v-form ref="form" @keyup.native.enter="onConfirm">
        <v-row>
          <v-col>
            <text-field
              required
              v-model="fields.name"
              tooltipText="Name of Warehouse location visible to Warehouse and Carriers"
              :rules="$validator.rules.required('Warehouse Name')"
              label="Warehouse Name"></text-field>
          </v-col>
          <v-col>
            <text-field
              v-model="fields.facilityNumber"
              label="Store Number"
              tooltipText="Warehouse store number"></text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <phone-number-field
              v-model="fields.phone"
              margin-top="-7px"
              :validator="$validator"
              :default-country="defaultPhoneCountry"
              tooltipText="Warehouse contact phone number for Carriers"
              label="Phone"></phone-number-field>
          </v-col>
          <v-col>
            <text-field
              type="email"
              v-model="fields.email"
              tooltipText="Warehouse contact email for Carriers. It will be used as 'Reply To' address of the appointment emails."
              :rules="$validator.rules.email"
              label="Warehouse Contact Email"></text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="align-field" cols="12" sm="6" md="4">
            <country-select
              v-model="editingAddress.country"
              limit-options
              tooltipText="Country of the Warehouse location"></country-select>
          </v-col>
          <v-col :class="{ 'align-field': manualAddressMode }">
            <address-autocomplete-field
              required
              :rules="editingAddress.street ? [] : $validator.rules.required('Address')"
              ref="searchAddressField"
              v-if="!manualAddressMode"
              v-model="geocodingData"
              clear-on-country-change
              :disabled="!editingAddress.country"
              :initial-value="editingAddress.street"
              :country="editingAddress.country"
              tooltipText="Address of the Warehouse location visible to carriers"
              @filled="fillFullAddress"
              @manual-override="enterManualAddressMode"></address-autocomplete-field>
            <street-field
              v-else
              ref="manualStreetField"
              @search="enterSearchAddressMode"
              required
              v-model="editingAddress.street"
              :rules="$validator.rules.required('Street Address')" />
          </v-col>
        </v-row>

        <address-display
          v-if="!manualAddressMode && editingAddress.city"
          :zip="editingAddress.zip"
          :city="editingAddress.city"
          :state="editingAddress.state"
          :street="editingAddress.street"
          :timezone="editingAddress.timezone" />

        <v-row v-else-if="manualAddressMode">
          <v-col cols="12" sm="6" md="4" class="pr-0">
            <text-field
              required
              type="text"
              v-model="editingAddress.city"
              :rules="$validator.rules.required('City')"
              label="City"></text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <state-select
              v-if="editingAddress.country === 'USA' || editingAddress.country === 'CAN'"
              required
              :rules="$validator.rules.required('State')"
              label="State"
              :country="editingAddress.country"
              v-model="editingAddress.state"></state-select>
            <text-field
              v-else
              required
              type="text"
              v-model="editingAddress.state"
              :rules="$validator.rules.required('State')"
              label="State"></text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="pl-0">
            <text-field
              required
              type="text"
              v-model="editingAddress.zip"
              :rules="$validator.rules.required('Zip')"
              placeholder="85331"
              label="Zip / Postal Code"></text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <timezone-select
              v-model="editingAddress.timezone"
              :rules="$validator.rules.required('Timezone')"
              label="Timezone"
              required />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <email-list-field
              class="full-width mb-4"
              :read-only="!$rolePermissions.canUpdateWarehouse"
              label="Default Email Subscribers"
              prepend-icon="mdi-email-outline"
              v-model="fields.ccEmails"
              tooltipText="Default email subscribers for appointments at this facility."
              @keyup.native.enter.stop
              small-chips
              small></email-list-field>
          </v-col>
        </v-row>

        <v-textarea rows="2" v-model="fields.notes" @keyup.native.stop>
          <template v-slot:label>
            <div class="with-tooltip">
              Notes
              <help-icon-tooltip iconStyleClass="grey darken-2">
                Notes that are internal to the Warehouse
              </help-icon-tooltip>
            </div>
          </template>
        </v-textarea>

        <v-row>
          <v-col>
            <h2 class="mt-16 mb-2 black--text">Scheduling Portal Availability</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="disable-label-click">
            <carrier-scheduling-toggle
              class="pl-2 pb-2"
              entity="Warehouse"
              @keyup.native.stop
              :disabled="!$rolePermissions.canCreateWarehouse"
              v-model="fields.allowCarrierScheduling"></carrier-scheduling-toggle>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </form-base>
</template>

<script>
import autocompleteAddressMixin from '@/components/mixins/autocompleteAddressMixin';

/**
 * Create Warehouse Form
 * @displayName Create Warehouse Form
 */
export default {
  name: 'CreateWarehouseForm',
  mixins: [autocompleteAddressMixin],
  props: {
    confirmLabel: {
      type: String,
      required: false,
      default: 'Create Warehouse'
    },
    confirmIcon: {
      type: String,
      required: false,
      default: 'plus'
    },
    hideCancel: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      editingAddress: {
        country: 'USA'
      },
      fields: {
        allowCarrierScheduling: false,
        ccEmails: []
      }
    };
  },
  computed: {
    isValid() {
      return (
        this.fields.name &&
        this.editingAddress.country &&
        this.editingAddress.city &&
        this.editingAddress.state &&
        this.editingAddress.zip &&
        this.editingAddress.timezone
      );
    }
  },
  methods: {
    onConfirm() {
      if (this.$refs.form.validate()) {
        this.$emit('confirm', { ...this.fields, ...this.editingAddress });
      }
    }
  }
};
</script>

<style scoped>
.align-field {
  padding-top: 7px !important;
}
</style>
