var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "search-field", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("text-field", {
                        staticClass: "mb-5",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Search orgs",
                          hint: "Search orgs",
                          "single-line": "",
                        },
                        model: {
                          value: _vm.filters.searchStr,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "searchStr", $$v)
                          },
                          expression: "filters.searchStr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-switch", {
                        staticClass: "mb-9",
                        attrs: { inset: "", label: "Show only inactive" },
                        on: {
                          change: function ($event) {
                            return _vm.getData()
                          },
                        },
                        model: {
                          value: _vm.showOnlyInactive,
                          callback: function ($$v) {
                            _vm.showOnlyInactive = $$v
                          },
                          expression: "showOnlyInactive",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            class: { "text--secondary": _vm.showOnlyInactive },
            attrs: {
              headers: _vm.rowHeaders,
              items: _vm.$orgs,
              loading: _vm.$data.$globalLoading,
              "server-items-length": _vm.$orgPagination.total,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "footer-props": _vm.footerProps,
              options: _vm.options,
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:options": function ($event) {
                _vm.options = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.orgType",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.orgType) + " ")]
                },
              },
              {
                key: "item.name",
                fn: function ({ item }) {
                  return [
                    _c("copy-content", { attrs: { content: item.name } }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ]
                },
              },
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [
                    _c("copy-content", { attrs: { content: item.id } }, [
                      _vm._v(_vm._s(item.id)),
                    ]),
                  ]
                },
              },
              {
                key: "item.tags",
                fn: function ({ item }) {
                  return _vm._l(item.tags, function (tag) {
                    return _c(
                      "v-chip",
                      {
                        key: tag,
                        staticClass: "px-2 mb-1 font-weight-bold",
                        attrs: { "x-small": "" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.novaCore.truncateString(tag, 20)) +
                            " "
                        ),
                      ]
                    )
                  })
                },
              },
              {
                key: "item.createdByUser",
                fn: function ({ item }) {
                  return [
                    item.createdByUser
                      ? [_vm._v(" " + _vm._s(item.createdByUser.email) + " ")]
                      : [
                          _c(
                            "v-chip",
                            {
                              staticClass: "px-2 mb-1 font-weight-bold",
                              attrs: { color: "red", "x-small": "" },
                            },
                            [_vm._v("User Deleted")]
                          ),
                        ],
                  ]
                },
              },
              {
                key: "item.createDateTime",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.novaCore.formatExpiresAt(item.createDateTime)
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.expiresAt",
                fn: function ({ item }) {
                  return [
                    _vm.novaCore.isExpiresAtWarningVisible(item.expiresAt)
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "mr-1",
                                            attrs: { color: "red", small: "" },
                                          },
                                          on
                                        ),
                                        [_vm._v("mdi-alert")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.novaCore.getExpirationWarningText(
                                    item.expiresAt
                                  )
                                )
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(_vm.novaCore.formatExpiresAt(item.expiresAt)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c("org-management-menu", {
                      attrs: { org: item },
                      on: { updated: _vm.getData },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }