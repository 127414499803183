/**
 * US States Enum used in state selects
 * @type {Readonly<{DE: string, HI: string, PR: string, TX: string, PW: string, MA: string, MD: string, IA: string,
 * ME: string, ID: string, MH: string, MI: string, UT: string, MN: string, MO: string, IL: string, MP: string,
 * IN: string, MS: string, MT: string, AK: string, AL: string, VA: string, AR: string, AS: string, VI: string,
 * NC: string, ND: string, NE: string, RI: string, AZ: string, NH: string, NJ: string, VT: string, NM: string,
 * FL: string, FM: string, NV: string, WA: string, NY: string, SC: string, SD: string, WI: string, OH: string,
 * GA: string, OK: string, CA: string, WV: string, WY: string, OR: string, KS: string, CO: string, GU: string,
 * KY: string, CT: string, PA: string, LA: string, TN: string, DC: string}>}
 */
const USStatesEnum = Object.freeze({
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
});

export default USStatesEnum;
