var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.latestClosedEvent?.id
        ? [
            _c("strong", [_vm._v("Reason")]),
            _c("p", [_vm._v(_vm._s(_vm.latestClosedEvent.reason))]),
            _vm.latestClosedEvent.observation
              ? [
                  _c("strong", [_vm._v("Additional Notes")]),
                  _c("p", [_vm._v(_vm._s(_vm.latestClosedEvent.observation))]),
                ]
              : _vm._e(),
            _c("strong", [_vm._v("Closed Since")]),
            _c(
              "p",
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-1", attrs: { "x-small": "" } },
                  [_vm._v("mdi-clock-outline")]
                ),
                _vm._v(" " + _vm._s(_vm.spotClosedFor) + " "),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }