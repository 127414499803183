<template>
  <button-base class="primary" v-bind="[$props, $attrs]" @click="handleClick($event)">
    <!-- @slot Button Content -->
    <slot></slot>
  </button-base>
</template>

<script>
import buttonMixin from '@satellite/components/mixins/buttonMixin';
import ButtonBase from '@satellite/components/elements/buttons/ButtonBase';

/**
 * A button styled with Primary Color
 * @displayName Primary Button
 */
export default {
  components: {
    ButtonBase
  },
  mixins: [buttonMixin],
  methods: {
    handleClick($event) {
      /**
       * Emits click event with target's event
       * @event click
       * @property {object} $event - Target's Event object
       */
      this.$emit('click', $event);
    }
  }
};
</script>
