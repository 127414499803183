<template>
  <div v-bind="[$props, $attrs]" class="rich-text-input-container">
    <label v-if="label" class="v-label">
      {{ label }}
      <help-icon-tooltip v-if="tooltipText" iconStyleClass="grey darken-2">
        {{ tooltipText }}
      </help-icon-tooltip>
    </label>

    <ckeditor
      v-if="localEditorConfig"
      :editor="editor"
      :value="value"
      :config="localEditorConfig"
      @input="value => $emit('input', value)"
      @ready="onReady"></ckeditor>
  </div>
</template>

<script>
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { merge } from 'lodash';

export default {
  name: 'RichTextInput',
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    value: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    editorConfig: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      editor: DecoupledEditor,
      isInitialized: false,
      localEditorConfig: null
    };
  },
  methods: {
    onReady(editor) {
      this.$emit('ready');
      if (!this.isInitialized) {
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
          );
        this.isInitialized = true;
      }
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value === null || typeof this.value === 'undefined') {
          this.$emit('input', '');
        }
      },
      immediate: true
    }
  },
  mounted() {
    const defaultConfig = {
      plugins: ['Essentials', 'Bold', 'Italic', 'Underline', 'Link', 'Paragraph'],
      toolbar: {
        items: ['bold', 'italic', 'underline', 'link', 'undo', 'redo']
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://'
      }
    };
    this.localEditorConfig = merge(defaultConfig, this.editorConfig);
  }
};
</script>

<style lang="scss">
.ck-powered-by {
  display: none !important;
}
.ck-content {
  border: solid 1px #777 !important;
  border-radius: 0 0 5px 5px !important;
  border-top: none !important;
}
.ck-toolbar {
  border: solid 1px #777 !important;
  border-radius: 5px 5px 0 0 !important;
}

.ck-content > p:not(:only-child) {
  margin-bottom: 1px !important;
}
</style>
