<template>
  <div>
    <v-chip
      v-for="loadTypeId in loadTypeIdArray"
      :key="loadTypeId"
      class="mr-1 mt-1"
      :color="readOnly ? 'default' : 'primary'"
      :text-color="readOnly ? 'black' : 'white'"
      small
      label>
      {{ loadTypeName(loadTypeId) }}
    </v-chip>
  </div>
</template>

<script>
/**
 * Load Type Chip List
 * @displayName Load Type Chip List
 */
export default {
  props: {
    /**
     * Array of load type IDs
     */
    loadTypeIdArray: {
      type: Array,
      required: false,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    loadTypeName(loadTypeId) {
      if (loadTypeId) {
        const loadType = this.$loadTypes?.find(l => l.id === loadTypeId);
        return `${loadType?.name} - ${loadType?.direction.toUpperCase()}` ?? loadTypeId;
      }
    }
  },
  async beforeMount() {
    // Ensure we have all load types on the store
    if (Object.keys(this.$loadTypes).length === 0) {
      await this.$store.dispatch('LoadTypes/getLoadTypes');
    }
  }
};
</script>
