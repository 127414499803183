<template>
  <v-expansion-panel :class="{ 'is-disabled': isDisabled }">
    <v-expansion-panel-header class="d-flex">
      <template #actions>
        <v-icon class="mr-2 icon" small>mdi-chevron-down</v-icon>
      </template>
      <span class="header-content">
        <slot name="title"></slot>
      </span>

      <v-spacer class="spacer"></v-spacer>

      <div class="header-right">
        <v-icon v-if="isLocked" small class="mr-1">mdi-lock</v-icon>
        <template v-else>
          <slot name="header-right"></slot>
        </template>
        <div class="disclaimer" v-if="$slots.disclaimer"><slot name="disclaimer"></slot></div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot name="content"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    isLocked: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {}
};
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  margin-top: 16px;
  border: 1px solid $color-line-border;
  border-radius: $default-border-radius;
}

.is-disabled {
  .icon {
    display: none;
  }
  .v-expansion-panel-header {
    pointer-events: none;
  }
}

.v-expansion-panel-header {
  height: 60px !important;
}
.icon {
  order: 0;
}
.header-content {
  order: 1;
}
.spacer {
  order: 2;
}
.header-right {
  order: 3;
  display: flex;
  justify-content: flex-end;
}

.disclaimer {
  color: $color-text-tertiary;
  font-size: 12px;
}
::v-deep .v-expansion-panel-header {
  padding: 12px;
}

::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 0;
  height: 48px;
}
</style>
