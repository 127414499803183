<template>
  <div class="menu-wrapper">
    <v-menu :value="modal">
      <template #activator="{ on }">
        <v-btn icon v-on="on" data-testid="carrier-contacts-data-table-menu-activator">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs">mdi-dots-horizontal</v-icon>
            </template>
            <span data-testid="carrier-contacts-data-table-menu-tooltip">More actions</span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-list class="actions-list">
        <v-list-item
          :class="{
            clickable: canManageContact
          }"
          :disabled="!canManageContact"
          @click="manageSubscribersDialog(true)"
          data-testid="carrier-contacts-data-table-menu-subscriber-activator">
          <v-list-item-icon>
            <v-icon>mdi-email-fast-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Manage subscribers</v-list-item-title>
        </v-list-item>
        <v-list-item class="clickable" @click="manageAppointmentDialog(true)">
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Schedule appointment</v-list-item-title>
        </v-list-item>

        <v-divider />
        <v-list-item
          :class="{
            'list-item-danger': canManageContact,
            clickable: canManageContact
          }"
          :disabled="!canManageContact"
          @click="manageRemoveDialog(true)"
          data-testid="carrier-contacts-data-table-menu-remove-activator">
          <v-list-item-icon>
            <v-icon>mdi-playlist-remove</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Remove from list</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <carrier-contact-subscriber-dialog
      ref="subscribersDialog"
      external-activator
      :carrier-contact="carrierContact" />
    <create-appointment-dialog
      external-activator
      @close="manageAppointmentDialog(false)"
      :show-dialog="appointmentDialog"
      :context="{ selectedCarrier: carrierContact.user }" />
    <carrier-contact-remove-dialog
      ref="removeDialog"
      :mixpanel-data="mixpanelData"
      external-activator
      :carrier-contact="carrierContact" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';

import { useAuth, useModal, useNovaCore, useOrg } from '@/composables';
import * as permissions from '@/helpers/permissions';

export default defineComponent({
  name: 'CarrierContactActionsMenu',
  props: {
    carrierContact: { type: Object, required: true }
  },
  setup(props) {
    const removeDialog = ref();
    const appointmentDialog = ref(false);
    const subscribersDialog = ref(false);
    const novaCore = useNovaCore();
    const org = useOrg();
    const { modal, openModal, closeModal } = useModal();
    const { meRef: me, isWarehouseRestricted } = useAuth();

    const mixpanelData = {
      'Entry Point': 'Carrier Contacts Actions Column',
      'Carrier Contact Name': novaCore.namesToFullName(
        props.carrierContact.user.firstName,
        props.carrierContact.user.lastName
      ),
      'Carrier Contact Email': props.carrierContact.user.email,
      'Carrier Company Name': props.carrierContact.company?.name,
      'Carrier Company ID': props.carrierContact.company?.id,
      'Org ID': org.orgRef.value.id,
      'Org Name': org.orgRef.value.name
    };

    const manageSubscribersDialog = isOpen => {
      if (isOpen) {
        subscribersDialog.value.openModal?.();
        closeModal();
      } else {
        subscribersDialog.value.closeModal?.();
      }
    };

    const manageRemoveDialog = isOpen => {
      if (isOpen) {
        removeDialog.value.openModal?.();
        closeModal();
      } else {
        removeDialog.value.closeModal?.();
      }
    };

    const manageAppointmentDialog = isOpen => {
      appointmentDialog.value = isOpen;
      if (isOpen) closeModal();
    };

    const canManageContact = computed(() =>
      permissions.canManageCarrierContact(me.value, props.carrierContact)
    );

    return {
      modal,
      openModal,
      closeModal,
      manageRemoveDialog,
      manageAppointmentDialog,
      manageSubscribersDialog,
      removeDialog,
      appointmentDialog,
      subscribersDialog,
      mixpanelData,
      isWarehouseRestricted,
      canManageContact
    };
  }
});
</script>

<style scoped lang="scss">
div.menu-wrapper {
  display: inline-block;
}

:deep(.v-list.actions-list) {
  .v-list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  .v-list-item.list-item-danger {
    color: $color-text-error;

    .v-icon {
      color: $color-text-error !important;
    }
  }

  .v-list-item__icon {
    margin: 0 !important;
    align-self: center;
  }
}
</style>
