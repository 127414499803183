var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.flat
        ? _c(
            "div",
            [
              _c("custom-forms-data-fields", {
                attrs: {
                  "custom-form-data": _vm.customFormData,
                  "trigger-id": _vm.trigger.id,
                },
              }),
              !_vm.hasCustomFormData
                ? _c("span", [_vm._v("Not Confirmed")])
                : _vm._e(),
            ],
            1
          )
        : _c(
            "v-expansion-panels",
            { attrs: { accordion: "", disabled: !_vm.hasCustomFormData } },
            [
              _c(
                "v-expansion-panel",
                {
                  attrs: {
                    "data-testid": `custom-forms-data-${_vm.trigger.feature}`,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleExpand()
                    },
                  },
                },
                [
                  _c("v-expansion-panel-header", [
                    _c("span", { domProps: { innerHTML: _vm.headerText } }),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("custom-forms-data-fields", {
                        staticClass: "mt-5",
                        attrs: {
                          "prepended-data": _vm.prependedData,
                          "custom-form-data": _vm.customFormData,
                          "trigger-id": _vm.trigger.id,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }