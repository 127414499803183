<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    :key="renderKey"
    header="Manage Form Field"
    loading-message="Saving..."
    :scrollable="false"
    @close="close">
    <template v-slot:body>
      <custom-forms-form-field-form
        :form-field="formField"
        :form-id="formId"
        @save="handleSaveEvent"
        @close="close" />
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
export default {
  mixins: [dialogMixin],
  props: {
    formField: {
      type: Object,
      required: false
    },
    formId: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    async handleSaveEvent(data) {
      delete data.field;

      let response = {};

      if (this.formField) {
        response = await axios.patch(`custom-forms/form-field/${this.formField.id}`, data);
      } else {
        response = await axios.post(`custom-forms/form-field`, data);
      }

      if (response?.data?.data) {
        this.notify('Form field has been updated', 'success');
        this.$emit('save', this.formField);
      }

      this.close();
    }
  }
};
</script>
