<template>
  <div class="login-wrapper">
    <auth-hero></auth-hero>

    <div class="flex-grow-1 d-flex flex-column align-center justify-center">
      <div class="full-width auth-content pt-4">
        <template v-if="!showEmailVerification">
          <span class="sub-title d-flex align-center justify-center font-weight-bold">
            <img
              src="/images/opendock_graphic.svg"
              alt="opendock graphic"
              class="d-inline-block mr-1 opendock-graphic--orange opendock-graphic--x-small" />
            Warehouse
          </span>
          <div class="d-flex flex-column align-center justify-center flex-wrap">
            <span class="text-h4 text-center py-2 pt-4 font-weight-bold secondary--text"
              >Register Now</span
            >
            <span class="pt-4">Just fill in the fields below. Quick and easy!</span>
          </div>
          <registration-form class="mt-8" @registration-submitted="registrationSubmitted" />
        </template>
        <template v-else>
          <verify-email
            :email="email"
            :allow-verification-resend="false"
            @backToLogin="$router.push({ name: 'login', params: { defaultEmail: email } })">
          </verify-email>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      showEmailVerification: false
    };
  },
  mounted() {
    this.mixpanel.track(this.mixpanel.events.VIEW.REGISTER.PAGE);
    document.body.classList.add('registration-page');
  },
  methods: {
    registrationSubmitted(email) {
      this.email = email;
      this.showEmailVerification = true;
    }
  },
  destroyed() {
    document.body.classList.remove('registration-page');
  }
};
</script>
