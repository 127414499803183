<template>
  <div>
    <div v-if="trigger?.flow?.id">
      <v-tabs
        centered
        v-model="formTab"
        grow
        class="non-active-tab-underline"
        v-if="trigger.flow.formTo?.id">
        <v-tab @click="activeForm = 'formFrom'">First form fields</v-tab>
        <v-tab @click="activeForm = 'formTo'">Second form fields</v-tab>
      </v-tabs>
      <v-tabs-items v-model="formTab">
        <v-tab-item v-for="form of ['formFrom', 'formTo']" :key="form" :readonly="readonly">
          <v-row>
            <v-col lg="8" xl="6" sm="12">
              <custom-forms-field-selector
                v-if="!readonly"
                :data-testid="`custom-forms-field-selector-${form}`"
                :existing-fields="trigger.flow[form]?.formFields?.map(f => f.field)"
                @add="field => handleAddField(field, form)"
                @create="showCreateFieldDialog"
                @remove="field => handleRemoveFormField(field, form)"></custom-forms-field-selector>
            </v-col>
          </v-row>
          <v-data-table
            :class="{ disabled: readonly }"
            :headers="headers"
            :items="trigger.flow[form]?.formFields || []"
            item-key="id"
            sort-by="order"
            :id="`${form}-table`"
            hide-default-footer
            disable-pagination>
            <template v-slot:item.order v-if="$rolePermissions.canUpdateWarehouse">
              <v-icon color="black" :class="`handle-${form}`" small>
                mdi-arrow-split-horizontal
              </v-icon>
            </template>

            <template v-slot:item.field.type="{ item }">
              {{ novaCore.customFieldLabels[item.field.type] }}
            </template>

            <template v-slot:item.field.label="{ item }">
              {{ item.overrideLabel || item.field.label }}
            </template>

            <template v-slot:item.required="{ item }">
              {{ item.required ? 'Yes' : 'No' }}
            </template>

            <template v-slot:item.actions="{ item }" v-if="!readonly">
              <div class="row-actions">
                <v-btn
                  icon
                  @click="
                    showFormFieldDialog[form] = true;
                    editingFormField = item;
                  "
                  title="Edit field">
                  <v-icon small color="secondary">mdi-pencil</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="d-inline-block">
                      <v-btn
                        icon
                        @click="
                          handleRemoveFormField(item.field, form, 'Custom Form Actions Column')
                        "
                        :disabled="isConditionField(item.id) || isActionField(item.field)">
                        <v-icon small color="red">mdi-sort-variant-remove</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>
                    {{
                      isConditionField(item.id) || isActionField(item.field)
                        ? 'This field cannot be removed'
                        : 'Remove field from this form'
                    }}
                  </span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>

          <custom-forms-form-field-dialog
            :id="`edit-form-field-${form}`"
            external-activator
            :form-field="editingFormField"
            :form-id="trigger.flow[form]?.id"
            @close="
              editingFormField = null;
              showFormFieldDialog[form] = false;
            "
            @save="
              formField => {
                trackFieldOperation(formField.field.label, 'EDIT_FORM_FIELD', null);
                $emit('data-updated');
              }
            "
            :show-dialog="showFormFieldDialog[form]"></custom-forms-form-field-dialog>

          <custom-forms-field-dialog
            external-activator
            @close="
              showFieldDialog = null;
              searchFieldStr = '';
            "
            @save="handleCreateField"
            :label-suggestion="searchFieldStr"
            :show-dialog="showFieldDialog"></custom-forms-field-dialog>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div v-else class="mt-3">
      <v-alert type="warning" color="info lighten-1" class="grey py-4">
        <p class="mb-0">Feature not available</p>
      </v-alert>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    warehouse: {
      type: Object,
      required: true
    },
    trigger: {
      type: Object,
      required: true
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Sort',
          value: 'order',
          searchable: false,
          sortable: false
        },
        {
          text: 'Field Label',
          value: 'field.label',
          searchable: true,
          sortable: true
        },
        {
          text: 'Field Type',
          value: 'field.type',
          searchable: true,
          sortable: true
        },
        {
          text: 'Field Description',
          value: 'field.description',
          searchable: true,
          sortable: true
        },
        {
          text: 'Is Required',
          value: 'required',
          searchable: true,
          sortable: true
        },
        {
          text: '',
          value: 'actions'
        }
      ];
    }
  },
  data() {
    return {
      loading: false,
      editingFormField: null,
      showFormFieldDialog: { formFrom: false, formTo: false },
      showFieldDialog: false,
      searchFieldStr: '',
      sortBy: ['name'],
      formTab: 0,
      activeForm: 'formFrom',
      searchFields: ['name', 'description']
    };
  },
  methods: {
    showCreateFieldDialog(searchStr) {
      this.searchFieldStr = searchStr;
      this.showFieldDialog = true;
    },
    trackFieldOperation(label, operation, entryPoint = 'Custom Form Selection List') {
      return this.mixpanel?.track(this.mixpanel.events.MODULE.CUSTOM_FORMS[operation], {
        'Custom Form Application': this.trigger.app,
        'Custom Form Type': this.trigger.feature,
        'Field Name': label,
        'Warehouse ID': this.warehouse.id,
        'Warehouse Name': this.warehouse.name,
        'Org ID': this.$org.id,
        'Org Name': this.$org.name,
        'Entry Point': entryPoint
      });
    },
    isConditionField(formFieldId) {
      return this.trigger.flow?.conditionFormFieldId === formFieldId;
    },
    isActionField(field) {
      return this.novaCore.CustomFieldType.Action === field.type;
    },
    async handleAddField(field, formType) {
      const isConfirmed = await this.$confirm(
        'Are you sure you want to add this field to the form?',
        {
          color: 'info'
        }
      );
      if (isConfirmed) {
        const maxOrder = Math.max(...this.trigger.flow[formType].formFields.map(f => +f.order), 0);

        const response = await axios.post(`custom-forms/form-field`, {
          formId: this.trigger.flow[formType].id,
          fieldId: field.id,
          required: false,
          order: maxOrder + 1
        });

        if (response?.data?.data) {
          this.notify(`Field '${field.label}' was added to the form`, 'success');
          this.trackFieldOperation(field.label, 'ADD_FORM_FIELD');
          this.$emit('data-updated');
        }
      }
    },
    async handleRemoveFormField(field, formType, entryPoint) {
      const isConfirmed = await this.$confirm(
        'Are you sure you want to remove this field from the form?',
        {
          color: 'error'
        }
      );
      if (isConfirmed) {
        const formField = this.trigger.flow[formType].formFields.find(f => f.field.id === field.id);

        if (this.isConditionField(formField.id) || this.isActionField(formField.field)) {
          this.notify('The field cannot be removed', 'warning');
          return;
        }

        const response = await axios.delete(`custom-forms/form-field/${formField.id}`, {});

        if (response?.data?.data) {
          this.notify(`Field removed from the form`, 'success');
          this.trackFieldOperation(field.label, 'REMOVE_FORM_FIELD', entryPoint);
          this.$emit('data-updated');
        }
      }
    },
    async handleCreateField(field) {
      this.loading = true;

      try {
        const activeForm = this.trigger.flow[this.activeForm];
        const maxOrder = Math.max(...activeForm.formFields.map(f => +f.order), 0);

        const response = await axios.post(`custom-forms/form-field`, {
          formId: activeForm.id,
          fieldId: field.id,
          order: maxOrder + 1,
          required: false
        });

        if (response?.data?.data) {
          this.notify(`Field '${field.label}' was added to the form`, 'success');
          this.trackFieldOperation(field.label, 'ADD_FORM_FIELD', 'Create Field Modal');
          this.$emit('data-updated');
        }
      } finally {
        this.loading = false;
      }
    },
    async handleSort(oldIndex, newIndex, form) {
      this.loading = true;

      const flow = this.trigger.flow;
      const formFieldIds = flow[form]?.formFields?.map(f => f.id) || [];

      if (newIndex >= formFieldIds.length) {
        let k = newIndex - formFieldIds.length + 1;
        while (k--) {
          formFieldIds.push(undefined);
        }
      }
      formFieldIds.splice(newIndex, 0, formFieldIds.splice(oldIndex, 1)[0]);

      try {
        const response = await axios.patch(`custom-forms/form/sort/${flow[form].id}`, {
          formFieldIds
        });

        if (response?.data?.data) {
          this.notify(`Fields order was updated`, 'success');
        }
      } finally {
        this.loading = false;
      }
    },
    async initSortable() {
      if (this.readonly) {
        return;
      }

      const self = this;
      const sortForm = (form, selector) =>
        new Promise(resolve => {
          const table = document.querySelector(selector);
          if (!table) {
            resolve();
            return;
          }
          Sortable.create(table, {
            handle: `.handle-${form}`,
            animation: 150,
            ghostClass: 'blue-background-class',
            async onEnd({ oldIndex, newIndex }) {
              const result = self.handleSort(oldIndex, newIndex, form);
              resolve();
              return result;
            }
          });
        });

      for (const form of ['formFrom', 'formTo']) {
        const selector = `#${form}-table .v-data-table__wrapper tbody`;
        await this.waitForElement(selector);
        await sortForm(form, selector);
      }
    },
    waitForElement(selector) {
      return new Promise(resolve => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
            observer.disconnect();
            resolve(document.querySelector(selector));
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
      });
    }
  },
  async mounted() {
    await this.initSortable();
  },
  async updated() {
    this.loading = true;
    try {
      await this.initSortable();
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
};
</script>
