var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    {
      staticClass: "d-flex yard-view-page-header-container gap-4 gap-lg-0",
      attrs: { "no-gutters": "" },
    },
    [
      _c("v-col", { attrs: { sm: "12", md: "12", lg: "6" } }, [
        _c("h1", { staticClass: "typography-heading-xlg" }, [
          _vm._v("Live Yard View"),
        ]),
      ]),
      _vm.isGateManagementEnabled
        ? _c(
            "v-col",
            {
              staticClass:
                "d-flex flex-row flex-wrap flex-md-nowrap justify-lg-end gap-3",
              attrs: { sm: "12", md: "12", lg: "6" },
            },
            _vm._l(_vm.cards, function (card) {
              return _c(
                "big-number-card",
                {
                  key: card.title,
                  attrs: {
                    "data-testid": `yard-view-page-header-card-${card.title}`,
                  },
                },
                [
                  _c("v-card-title", [_vm._v(" " + _vm._s(card.title) + " ")]),
                  _c("v-card-text", [_vm._v(" " + _vm._s(card.count) + " ")]),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }