<template>
  <v-dialog v-model="modal" :width="width" @keydown.escape="closeModal" v-bind="[$props, $attrs]">
    <template #activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <v-card class="dialog-box" data-testid="carrier-contact-remove-dialog">
      <v-card-title>
        <span class="headline d-flex">Remove contact from list?</span>
        <v-spacer></v-spacer>
        <v-btn class="pa-3" icon light @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="dialog-body">
        <carrier-contact-card
          :carrier="carrier"
          :company="company"
          :is-favorite="carrierContact.isFavorite" />
        <div class="confirmation-wrapper" v-if="subscriberCount > 0 || carrierContact.isFavorite">
          <span>
            Removing
            <span class="font-weight-bold">{{ carrier.fullName }} - {{ company.name }}</span>
            fom your Org’s list will also
            <span v-if="!carrierContact.isFavorite">
              remove
              <span class="font-weight-bold">{{ carrierContact.emailCCs.length }}</span>
              email {{ novaCore.pluralize(subscriberCount, 'subscriber') }} associated with it:
            </span>
            <span v-else-if="subscriberCount < 1">unfavorite the contact.</span>
            <span v-else>
              unfavorite the contact and remove
              <span class="font-weight-bold">{{ carrierContact.emailCCs.length }}</span>
              email {{ novaCore.pluralize(subscriberCount, 'subscriber') }} associated with it:
            </span>
          </span>
          <div class="email-subscribers" v-if="subscriberCount > 0">
            <v-chip v-for="email in carrierContact.emailCCs" :key="email">
              {{ email }}
            </v-chip>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="closeModal"
          data-testid="carrier-contact-remove-dialog-action-nevermind">
          Nevermind
        </v-btn>
        <v-btn
          text
          color="error"
          @click="removeCarrierContact"
          data-testid="carrier-contact-remove-dialog-action-remove">
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue';
import _ from 'lodash';
import useModal from '@/composables/useModal';
import useCarrierContacts from '@/composables/useCarrierContacts';
import * as novaCore from '@satellite/../nova/core';

export default defineComponent({
  name: 'CarrierContactRemoveDialog',
  props: {
    width: { type: Number, default: 550 },
    carrierContact: { type: Object, required: true },
    externalActivator: { type: Boolean, default: false },
    mixpanelData: { type: Object, required: true }
  },
  setup(props, context) {
    const { modal, closeModal, openModal } = useModal();
    const carrierContacts = useCarrierContacts();
    const removeCarrierContact = async () => {
      try {
        await carrierContacts.remove(
          { id: props.carrierContact.id },
          { mixpanelData: props.mixpanelData }
        );
        closeModal();
        context.emit('removed');
      } catch (error) {
        console.error('Failed to remove carrier contact', error);
      }
    };

    const subscriberCount = computed(() => props.carrierContact.emailCCs?.length || 0);
    const company =
      props.carrierContact?.company && !_.isEmpty(props.carrierContact.company)
        ? props.carrierContact.company
        : props.carrierContact.user.company;
    const carrier = props.carrierContact.user;
    carrier.fullName =
      carrier.fullName || novaCore.namesToFullName(carrier.firstName, carrier.lastName);

    context.expose({ openModal, closeModal });

    return {
      modal,
      closeModal,
      removeCarrierContact,
      openModal,
      company,
      carrier,
      subscriberCount
    };
  }
});
</script>

<style scoped lang="scss">
.dialog-body {
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirmation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 32px 0 20px 0;
}

.email-subscribers {
  margin: 0 0 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .v-chip {
    padding: 3px 12px;
    width: fit-content;
  }
}
</style>
