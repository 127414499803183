var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-list",
        { attrs: { dense: "", flat: "" } },
        [
          _c(
            "v-subheader",
            {
              staticClass:
                "mb-3 pl-2 mx-2 d-flex align-center justify-space-between",
            },
            [
              _c("span", [
                _vm._v(
                  "Add options to your drop-down list (Max " +
                    _vm._s(_vm.maxChoices) +
                    " options)"
                ),
              ]),
              _c("icon-tooltip-button", {
                attrs: {
                  tooltip: _vm.isBulkInput
                    ? "Show Single Option Input"
                    : "Show Bulk Option Input",
                  size: "large",
                  color: "secondary",
                  icon: _vm.isBulkInput ? "mdi-text-short" : "mdi-text-long",
                },
                on: { click: _vm.toggleIsBulkInput },
              }),
            ],
            1
          ),
          _vm.isBulkInput
            ? [
                _c(
                  "v-container",
                  {
                    staticClass:
                      "d-flex flex-column align-center is-relative pt-0",
                  },
                  [
                    _vm.isProcessing
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "overlay-base pa-3 d-flex flex-column justify-center",
                          },
                          [
                            _c(
                              "h3",
                              { staticClass: "z-100 text-center mb-3" },
                              [_vm._v("Processing Items...")]
                            ),
                            _c("v-progress-linear", {
                              staticClass: "z-100 is-relative",
                              attrs: { indeterminate: "" },
                            }),
                            _c("div", {
                              staticClass: "overlay-obscure overlay-base",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-textarea", {
                      staticClass: "full-width text-area mt-0",
                      attrs: {
                        "persistent-placeholder": "",
                        height: "35",
                        placeholder:
                          "Copy/Paste into this box.  Options must be comma or new line separated",
                      },
                      model: {
                        value: _vm.currentBulkValueTemplate,
                        callback: function ($$v) {
                          _vm.currentBulkValueTemplate = $$v
                        },
                        expression: "currentBulkValueTemplate",
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-3",
                        attrs: { disabled: _vm.disableAddChoiceButton },
                        on: { click: _vm.addCurrentBulkValueTemplate },
                      },
                      [_vm._v(" Add ")]
                    ),
                  ],
                  1
                ),
              ]
            : _c(
                "v-subheader",
                [
                  _c("v-text-field", {
                    staticClass: "mx-2",
                    attrs: {
                      type: "text",
                      label: "New option",
                      placeholder: "Enter a text and add it to the list...",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.stopPropagation()
                      },
                      keypress: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.addCurrentValueTemplate.apply(
                          null,
                          arguments
                        )
                      },
                    },
                    model: {
                      value: _vm.currentValueTemplate,
                      callback: function ($$v) {
                        _vm.currentValueTemplate = $$v
                      },
                      expression: "currentValueTemplate",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: { disabled: _vm.disableAddChoiceButton },
                      on: { click: _vm.addCurrentValueTemplate },
                    },
                    [_vm._v(" Add ")]
                  ),
                ],
                1
              ),
          _c("br"),
          _c(
            "v-list-item-group",
            { attrs: { color: "primary" } },
            _vm._l(_vm.dropDownValuesLocal, function (item, i) {
              return _c(
                "v-list-item",
                { key: i },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "mr-3" },
                    [_c("v-icon", [_vm._v("mdi-circle-small")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(item) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", icon: "", title: "Remove Item" },
                          on: {
                            click: function ($event) {
                              return _vm.removeValueTemplate(item)
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "red", "x-small": "" } },
                            [_vm._v("mdi-delete-outline")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm.isEmptyListOfChoices
            ? _c("v-list-item-group", [
                _c("p", { staticClass: "grey--text text-center" }, [
                  _vm._v("Empty list!"),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c("p", { staticClass: "grey--text text-right small mr-3" }, [
        _c("small", [_vm._v("List items are alphabetically sorted")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }