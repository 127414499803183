<template>
  <form-base>
    <template slot="form">
      <v-form ref="form">
        <v-container>
          <p>These tags are visible to internal users only</p>
          <tag-manager small v-model="localTags" @keyup.native.enter.prevent></tag-manager>
        </v-container>
      </v-form>
    </template>
    <template slot="form-actions">
      <action-group
        @cancel="cancel"
        confirm-icon="update"
        confirm-label="Update Org Tags"
        @confirm="submit">
      </action-group>
    </template>
  </form-base>
</template>

<script>
export default {
  props: {
    orgId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localTags: []
    };
  },
  methods: {
    /**
     * Submit form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.localTags) {
        this.localTags = [];
      }

      const org = await this.$store.dispatch('Orgs/updateOrg', {
        tags: this.localTags
      });

      if (org) {
        this.$emit('save', this.localTags);
        this.$emit('close');
      }
    },
    cancel() {
      this.setLocalOrgTags();
      this.$emit('close');
    },
    setLocalOrgTags() {
      this.localTags = this.$org.tags;
    }
  },
  async mounted() {
    await this.$store.dispatch('Orgs/getOrg', this.orgId);
    this.setLocalOrgTags();
  }
};
</script>
