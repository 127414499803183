var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "contact-card",
      attrs: {
        "data-testid": "carrier-contact-card",
        "data-carrier-id": _vm.carrier.id,
      },
    },
    [
      _c("p", { staticClass: "full-name" }, [
        _vm._v(_vm._s(_vm.carrier.fullName)),
      ]),
      _c("p", { staticClass: "company-name" }, [
        _vm._v(_vm._s(_vm.company.name)),
      ]),
      _c("p", { staticClass: "email" }, [_vm._v(_vm._s(_vm.carrier.email))]),
      _vm.isFavorite
        ? _c("v-icon", { staticClass: "contact-icon is-favorite" }, [
            _vm._v("mdi-star"),
          ])
        : _c("v-icon", { staticClass: "contact-icon" }, [
            _vm._v("mdi-account"),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }