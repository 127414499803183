var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Reserves conflict",
          scrollable: false,
          "loading-message": "Processing reserves",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("transfer-reserves-form", {
                  ref: "transferUserForm",
                  attrs: { "deleted-user": _vm.deletedUser },
                  on: { close: _vm.close, confirm: _vm.confirm },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }