var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          loading: _vm.loading,
          header: "Forgot Password",
          "loading-message": "Sending reset email...",
        },
        on: { close: _vm.closeDialog, confirm: _vm.submit },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "secondary-button",
                        _vm._g(
                          _vm._b(
                            {},
                            "secondary-button",
                            slotProps.attrs,
                            false
                          ),
                          slotProps.on
                        ),
                        [_vm._v(" Forgot Password? ")]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  !_vm.loading
                    ? _c("form-base", {
                        attrs: { "hide-required-legend": "" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "form",
                              fn: function () {
                                return [
                                  _c(
                                    "v-form",
                                    { ref: "form" },
                                    [
                                      _c("text-field", {
                                        attrs: {
                                          required: true,
                                          type: "email",
                                          id: "forgot-password-email",
                                          "prepend-icon": "mdi-email",
                                          rules: [
                                            ..._vm.$validator.rules.required(
                                              "Email"
                                            ),
                                            ..._vm.$validator.rules.email,
                                          ],
                                          label: "Email",
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function ($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "form-actions",
                              fn: function () {
                                return [
                                  _c("action-group", {
                                    attrs: {
                                      "stack-actions": _vm.isMobile(),
                                      "inline-actions-mobile": _vm.isMobile(),
                                      "confirm-icon": "email",
                                      "confirm-label": "Send Reset",
                                    },
                                    on: {
                                      cancel: _vm.close,
                                      confirm: _vm.submit,
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          12688406
                        ),
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }