<template>
  <v-autocomplete
    v-bind="[$props, $attrs]"
    class="document-type-select"
    :items="fieldTypeOptions"
    :rules="rules"
    item-value="value"
    @input="value => $emit('input', value)"
    :label="label">
    <template v-slot:item="{ item }">
      <v-row class="my-3">
        <v-col cols="1" class="mr-2">
          <v-icon>mdi-{{ item.icon }}</v-icon>
        </v-col>
        <v-col>
          <v-row>
            <span class="font-size-medium">{{ item.text }}</span>
          </v-row>
          <v-row>
            {{ item.description }}
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-slot:selection="{ item }">
      <v-icon small class="mr-2">mdi-{{ item.icon }}</v-icon>
      {{ item.text }}
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  data() {
    return {
      fieldTypeOptions: []
    };
  },
  props: {
    label: {
      type: String,
      required: false,
      default: 'Field Type'
    },
    rules: {
      type: Array,
      required: false
    }
  },
  methods: {
    setOptions() {
      this.fieldTypeOptions = Object.entries(this.novaCore.customFieldLabels)
        .filter(field => field[0] !== this.novaCore.CustomFieldType.Action)
        .map(field => ({
          value: field[0],
          text: field[1],
          icon: this.novaCore.customFieldIcons[field[0]],
          description: this.novaCore.customFieldDescriptions[field[0]]
        }));
    }
  },
  beforeMount() {
    this.setOptions();
  }
};
</script>
