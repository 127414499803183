<template>
  <v-btn
    text
    small
    :ripple="false"
    :data-testid="`appointment-${actionName}-button`"
    :icon="!actionItem.label"
    class="mx-1"
    v-if="actionItem.show"
    :class="{
      [actionItem.color]: actionItem.color,
      [`${actionItem.textColor}--text`]: actionItem.textColor,
      'sub-item': isSubItem
    }"
    :disabled="actionItem.disabled"
    @click="$emit('click', actionItem.action)">
    <v-icon
      :size="!actionItem.label ? 20 : 16"
      :color="actionItem.iconColor ? actionItem.iconColor : ''"
      class="icon">
      {{ `mdi-${actionItem.icon}` }}
    </v-icon>
    <span class="d-inline-block ml-1" v-if="actionItem.label">{{ actionItem.label }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'AppointmentActionGroupItem',
  props: {
    actionItem: {
      type: Object,
      required: true
    },
    actionName: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    disabledMessage: {
      type: String,
      required: false
    },
    isSubItem: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    getDisabledTooltipMessage() {
      return this.disabledMessage ? this.disabledMessage : this.novaCore.getRoleActionError();
    },
    getTooltipText() {
      return this.disabled ? this.getDisabledTooltipMessage : this.actionItem.tooltip;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-btn:not(.sub-item) .v-btn__content {
  font-weight: bold;
}

::v-deep .v-btn.sub-item .v-btn__content {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
}

.v-btn .icon {
  padding-top: 2px;
}
</style>
