var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pa-2" },
    [
      _c(
        "h3",
        { staticClass: "mb-4" },
        [
          _c(
            "v-icon",
            { staticClass: "mr-2", attrs: { color: "white", small: "" } },
            [_vm._v("mdi-truck")]
          ),
          _vm._v(
            " " +
              _vm._s(
                _vm.assetVisit.company?.name ||
                  _vm.assetVisit.companyHint ||
                  "Unknown company"
              ) +
              " "
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("strong", [_vm._v("Appointment status")]),
            !_vm.appointment?.id
              ? _c("p", { staticClass: "text-capitalize" }, [
                  _vm._v("No Appointment scheduled!"),
                ])
              : _c(
                  "p",
                  [
                    _c(
                      "v-chip",
                      {
                        attrs: {
                          color: _vm.appointment.status.toLowerCase(),
                          dark: "",
                          small: "",
                          pill: "",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.appointment.status.toUpperCase()) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
          ]),
          _c("v-col", [
            _c("strong", [_vm._v("Docked")]),
            _c("p", { staticClass: "text-capitalize" }, [
              _vm._v(_vm._s(_vm.dockedTime)),
            ]),
          ]),
        ],
        1
      ),
      _vm.appointment?.id
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$refNumSettings(_vm.selectedWarehouse).displayName
                    )
                  ),
                ]),
                _c("p", [_vm._v(_vm._s(_vm.appointment.refNumber || "-"))]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }