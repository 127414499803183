import { UserRole } from '@satellite/../nova/core';
import WarehouseGroupsPage from '@/modules/warehouse_groups/WarehouseGroupsPage.vue';

export default [
  {
    path: '/warehouse-groups',
    name: 'warehouse-groups',
    component: WarehouseGroupsPage,
    props: true,
    meta: {
      title: 'Warehouse Groups',
      requiresAuth: true,
      minRole: UserRole.ADMIN,
      maxRole: UserRole.OWNER
    }
  }
];
