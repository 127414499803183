var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: { header: "", "has-dialog-actions": "" },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: { "before-icon": "pencil", large: "" },
                            },
                            "primary-button",
                            slotProps.attrs,
                            false
                          ),
                          slotProps.on
                        ),
                        [_vm._v(" Edit Time ")]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _vm.alertMessage
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "mb-0",
                          attrs: { type: _vm.alertType, shaped: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
                      )
                    : _vm._e(),
                  _c("status-timeline-edit-form-mobile", {
                    attrs: {
                      header: _vm.dialogHeader,
                      "mixpanel-entry-point": _vm.mixpanelEntryPoint,
                      appointment: _vm.appointment,
                      "status-to-edit": _vm.statusToEdit,
                    },
                    on: { close: _vm.close, mounted: _vm.setDialogPosition },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }