<template>
  <v-row>
    <v-col>
      <v-toolbar flat dense class="pl-0" id="duration-toolbar">
        <v-toolbar-title class="d-flex justify-center align-center">
          <span class="subheading">Duration</span>
          <help-icon-tooltip iconStyleClass="grey darken-2">
            The duration of the load type is used when scheduling a new appointment. If this load
            type's duration is changed, no existing appointments will be updated with the new
            duration.
          </help-icon-tooltip>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          v-model="manualDurationEntry"
          color="secondary"
          hide-details
          label="Enter Manually"></v-switch>
      </v-toolbar>

      <v-slider
        v-if="!manualDurationEntry"
        v-model="durationSlider"
        @input="value => $emit('input', value)"
        :tick-labels="loadTypeDurationOptions"
        track-color="secondary"
        color="accent"
        :min="sliderLimits.min"
        :max="sliderLimits.max"
        step="15"
        ticks
        class="ma-0"
        hide-details
        dense
        height="20px"></v-slider>

      <text-field
        v-else
        dense
        height="20px"
        v-model="durationManual"
        @input="value => $emit('input', parseInt(value))"
        hide-details
        outlined
        label="Minutes"
        step="15"
        type="number"></text-field>
      <span
        class="text-h5 font-weight-light"
        v-if="!manualDurationEntry"
        v-text="novaCore.formatMinutesToHuman(value)"></span>
    </v-col>
  </v-row>
</template>

<script>
const SLIDER_LIMITS = {
  min: 15,
  max: 180
};

export default {
  name: 'load-type-duration',
  props: {
    value: {
      type: Number,
      required: false,
      default: 15
    }
  },
  data() {
    return {
      durationManual: 0,
      durationSlider: 0,
      loadTypeDurationOptions: [],
      manualDurationEntry: false
    };
  },
  computed: {
    sliderLimits() {
      return SLIDER_LIMITS;
    },
    isManualEntry() {
      return Number(this.value) % 15 !== 0;
    }
  },
  methods: {
    manageSliderLimits(manualInput) {
      if (manualInput >= this.sliderLimits.max) {
        this.durationSlider = this.sliderLimits.max;
      } else if (manualInput <= this.sliderLimits.min) {
        this.durationSlider = this.sliderLimits.min;
      }
    }
  },
  beforeMount() {
    this.manualDurationEntry = this.isManualEntry;
    this.durationSlider = this.durationManual = this.value;
    this.manageSliderLimits(this.value);
  },
  watch: {
    durationManual(newVal) {
      this.manageSliderLimits(newVal);
    }
  }
};
</script>
