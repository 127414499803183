import AxiosPlugin from '@satellite/plugins/AxiosPlugin';
import {
  canUserUpdateOrg,
  canUserAccessWarehouse,
  UserRole,
  canUserCancelAppointment,
  canUserCreateAppointment,
  canUserUpdateAppointment,
  canUserUpdateAppointmentEmailCCs,
  canUserUpdateFavoriteCarriers,
  canUserReadSubscriptions,
  canUserUpdateCarrier,
  canUserCreateCarrier,
  canUserDeleteLoadTypes,
  canUserUpdateLoadTypes,
  canUserCreateLoadTypes,
  canUserDeleteDocks,
  canUserUpdateDocks,
  canUserCreateDocks,
  canUserUpdateAppointmentTags,
  canUserModifyApmtStatus,
  canUserDeleteAppointment,
  canUserCreateWarehouse,
  canUserUpdateWarehouse,
  canUserDeleteWarehouse,
  canUserUpdateWarehouseGroup,
  canUserDeleteWarehouseGroup,
  canUserChangeChatOpenStatus,
  canUserChangeDriverPhone,
  canUserSendChatMessage
} from '@satellite/../nova/core';

// initial state
const state = {
  me: null,
  appLoaded: false,
  rolePermissions: {},
  carrierViewAllowedUserRoles: [UserRole.BASE, UserRole.OWNER, UserRole.ADMIN]
};

// getters
const getters = {
  me(state) {
    return state.me;
  },
  appLoaded(state) {
    return state.appLoaded;
  },
  accessToken() {
    return sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
  },
  canUserAccessWarehouse: state => (warehouseId, warehouseOrgId) => {
    return canUserAccessWarehouse(state.me, warehouseId, warehouseOrgId);
  },
  isWarehouseReadOnly: (state, getters) => (warehouseId, warehouseOrgId) => {
    return (
      !getters.canUserAccessWarehouse(warehouseId, warehouseOrgId) ||
      !state.rolePermissions.canUpdateWarehouse
    );
  },
  isLoadTypeReadOnly: (state, getters) => (warehouseId, warehouseOrgId) => {
    return (
      !getters.canUserAccessWarehouse(warehouseId, warehouseOrgId) ||
      !state.rolePermissions.canCreateLoadType
    );
  },
  isDockReadOnly: (state, getters) => (warehouseId, warehouseOrgId) => {
    return (
      !getters.canUserAccessWarehouse(warehouseId, warehouseOrgId) ||
      !state.rolePermissions.canCreateDock
    );
  },
  isOrgReadOnly(state) {
    return !state.rolePermissions.canUpdateOrg;
  }
};

// actions
const actions = {
  async login({ dispatch, commit }, creds) {
    let response = await axios
      .post('auth/login', {
        email: creds.email,
        password: creds.password
      })
      .catch(e => {
        return e.response;
      });

    if (response?.status === 200) {
      commit('setAccessToken', response.data.access_token);
      localStorage.setItem('access_token', response.data.access_token);
      dispatch('getMe');
    }

    return response;
  },
  async loginAs({ dispatch, commit }, accessToken, loginOrigin = 'loginAs') {
    commit('setAccessToken', accessToken);
    commit('setLoginOrigin', loginOrigin);
    await dispatch('getMe');
  },
  async loginSaml({ dispatch, commit }, accessToken) {
    commit('setAccessToken', accessToken);
    localStorage.setItem('access_token', accessToken);
    dispatch('getMe');
  },
  async refreshToken({ commit, dispatch }) {
    const response = await axios.get('auth/refresh');

    if (response?.data) {
      await commit('setAccessToken', response.data.access_token);
      dispatch('getMe');
    }

    return response;
  },
  async getMe({ commit, dispatch, state }, getOrg = true) {
    const response = await axios.get('auth/me');
    this.$app.$eventHub.$emit('auth/getMe', response?.data);

    if (response?.data) {
      await commit('setMe', response.data);
      await dispatch('App/getAppEnvironment', {}, { root: true });

      if (getOrg && state.me.orgId) {
        await dispatch('Orgs/getOrg', state.me.orgId, { root: true });
      }

      await commit('setRolePermissions');
    } else {
      localStorage.removeItem('access_token');
    }

    return response;
  },
  logout() {
    this.$app.util.clearLocalStorage();
    this.$app.util.clearSessionStorage(['access_token']);
    this.$app.$eventHub.$emit('auth/logout');
  },
  async verifyEmail({}, verificationToken) {
    return await axios.get(`auth/verify/${verificationToken}`);
  },
  async sendPasswordReset({}, email) {
    return await axios.post(`auth/password-reset-email/${email}/`);
  },
  async resetPassword({}, data) {
    return await axios.post(`auth/reset-password/${data.token}`, {
      email: data.email,
      password: data.password
    });
  },
  async sendVerificationEmail({}, email) {
    return await axios.post(`auth/verification-email`, {
      email
    });
  },
  setInitialAccessToken({ commit, getters }) {
    commit('setAccessToken', getters['accessToken']);
  },
  async verifyAndResetPassword({}, data) {
    return await axios.post(`auth/verify-and-reset-password/${data.token}`, {
      email: data.email,
      password: data.password
    });
  }
};

// mutations
const mutations = {
  setMe(state, me) {
    // sets "me" on state
    state.me = me;

    // pushing user info to dataLayer
    this.$app.util.pushMeToDataLayer(me);
  },
  setAppLoaded(state, loaded = true) {
    state.appLoaded = loaded;
  },
  setAccessToken(state, token) {
    AxiosPlugin.setAccessToken(token);
    this.$app.sockets.connect(token);

    if (!token) {
      sessionStorage.removeItem('access_token');
      localStorage.removeItem('access_token');

      return;
    }

    sessionStorage.setItem('access_token', token);
  },
  setLoginOrigin(_, origin) {
    sessionStorage.removeItem('login_origin');
    sessionStorage.setItem('login_origin', origin);
  },
  setRolePermissions(state) {
    state.rolePermissions = {
      canUpdateOrg: canUserUpdateOrg(state.me, state.me.orgId),
      canCancelAppointment: canUserCancelAppointment(state.me),
      canCreateAppointment: canUserCreateAppointment(state.me),
      canUpdateAppointment: canUserUpdateAppointment(state.me),
      canUpdateAppointmentEmailCCs: canUserUpdateAppointmentEmailCCs(state.me),
      canUpdateAppointmentTags: canUserUpdateAppointmentTags(state.me),
      canUpdateAppointmentStatus: canUserModifyApmtStatus(state.me),
      canDeleteAppointment: canUserDeleteAppointment(state.me),
      canCreateWarehouse: canUserCreateWarehouse(state.me),
      canUpdateWarehouse: canUserUpdateWarehouse(state.me),
      canDeleteWarehouse: canUserDeleteWarehouse(state.me),
      canCreateWarehouseGroup: canUserUpdateWarehouseGroup(state.me),
      canUpdateWarehouseGroup: canUserUpdateWarehouseGroup(state.me),
      canDeleteWarehouseGroup: canUserDeleteWarehouseGroup(state.me),
      canCreateDock: canUserCreateDocks(state.me),
      canUpdateDock: canUserUpdateDocks(state.me),
      canDeleteDock: canUserDeleteDocks(state.me),
      canCreateLoadType: canUserCreateLoadTypes(state.me),
      canUpdateLoadType: canUserUpdateLoadTypes(state.me),
      canDeleteLoadType: canUserDeleteLoadTypes(state.me),
      canCreateCarrier: canUserCreateCarrier(state.me),
      canUpdateCarrier: canUserUpdateCarrier(state.me),
      canReadSubscriptions: canUserReadSubscriptions(state.me),
      canUpdateFavoriteCarriers: canUserUpdateFavoriteCarriers(state.me, state.me.orgId),
      canSendChatMessage: canUserSendChatMessage(state.me),
      canChangeChatOpenStatus: canUserChangeChatOpenStatus(state.me),
      canChangeDriverPhone: canUserChangeDriverPhone(state.me)
    };
  }
};

export default {
  namespaced: true,
  name: 'Auth',
  state,
  getters,
  actions,
  mutations
};
