var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    attrs: { "hide-required-legend": true },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form", staticClass: "pt-lg-6" },
              [
                _c("v-checkbox", {
                  attrs: {
                    label: "Add note or reason for cancellation",
                    "hide-details": "",
                  },
                  model: {
                    value: _vm.hasReason,
                    callback: function ($$v) {
                      _vm.hasReason = $$v
                    },
                    expression: "hasReason",
                  },
                }),
                _c(
                  "v-expand-transition",
                  [
                    _vm.hasReason
                      ? _c("v-textarea", {
                          staticClass: "mt-6",
                          attrs: {
                            autofocus: _vm.hasReason,
                            name: "cancel_reason",
                            label: "Reason for cancellation",
                            "validate-on-blur": false,
                            rules: [
                              ..._vm.$validator.rules.minLength(10),
                              ..._vm.$validator.rules.required(
                                "Reason for Cancellation"
                              ),
                            ],
                          },
                          model: {
                            value: _vm.cancelReason,
                            callback: function ($$v) {
                              _vm.cancelReason = $$v
                            },
                            expression: "cancelReason",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: { "confirm-label": "Yes, Cancel" },
              on: {
                cancel: function ($event) {
                  return _vm.$emit("nevermind")
                },
                confirm: _vm.cancelAppointment,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }