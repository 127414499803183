import storageHelpers from '@satellite/helpers/storage';

export default {
  methods: {
    data() {
      return {
        selectionPersistKey: 'nova_insights'
      };
    },
    getPersistedFilterData() {
      return (
        this.$loadObjectFromLocalStorage(
          storageHelpers.makeUserBoundedKey(this.selectionPersistKey, this.$me.id)
        ) || {}
      );
    },
    persistLastSelectedData(key, value) {
      localStorage.setItem(
        storageHelpers.makeUserBoundedKey(this.selectionPersistKey, this.$me.id),
        JSON.stringify({
          ...this.getPersistedFilterData(),
          [key]: value
        })
      );
    }
  }
};
