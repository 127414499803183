var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isFavorite && !_vm.isOrgFavorite
    ? _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k(
                  $event.keyCode,
                  "escape",
                  undefined,
                  $event.key,
                  undefined
                )
              )
                return null
              return _vm.closeModal.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass:
                              "warehouse-btn text-truncate text-left",
                            attrs: { text: "", left: "" },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c(
                          "span",
                          { staticClass: "warehouse-favorite bold txt-accent" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.favoriteWarehouses
                                    .map(({ name }) => name)
                                    .join(", ")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            328431450
          ),
          model: {
            value: _vm.modal,
            callback: function ($$v) {
              _vm.modal = $$v
            },
            expression: "modal",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialog-box" },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-title" },
                [
                  _c("span", { staticClass: "headline d-flex" }, [
                    _vm._v("Warehouses this contact is favorited for"),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-3",
                      attrs: { icon: "", light: "" },
                      on: { click: _vm.closeModal },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", { staticClass: "dialog-body" }, [
                _c("p", { staticClass: "dialog-txt" }, [
                  _c("span", { staticClass: "bold" }, [
                    _vm._v(
                      _vm._s(_vm.carrier.fullName) +
                        " - " +
                        _vm._s(_vm.company.name)
                    ),
                  ]),
                  _vm._v(" is a favorite carrier contact for: "),
                ]),
                _c(
                  "ul",
                  { staticClass: "warehouse-list" },
                  _vm._l(_vm.favoriteWarehouses, function (warehouse) {
                    return _c("li", { key: warehouse.id }, [
                      _c("span", { staticClass: "bold txt-accent" }, [
                        _vm._v(_vm._s(warehouse.name)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm.isOrgFavorite
    ? _c("div", { staticClass: "org-favorite" }, [_vm._v("All")])
    : _c("div", { staticClass: "not-favorite" }, [_vm._v("None")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }