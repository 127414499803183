<template>
  <div v-if="country" :class="`vti__flag ${iso2CountryCode}`" :title="countryName"></div>
  <div v-else>{{ countryCode }}</div>
</template>

<script>
export default {
  /*
   This class vti__flag uses the flag styles
   from vue-tel-input component.
   */
  props: {
    countryCode: {
      type: String,
      required: true
    }
  },
  computed: {
    country() {
      return this.novaCore.ISO3166Countries[this.countryCode];
    },
    iso2CountryCode() {
      return this.country?.iso2?.toLowerCase();
    },
    countryName() {
      return this.country?.name;
    }
  }
};
</script>
