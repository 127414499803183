var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex align-center" },
      [
        _c(
          "v-icon",
          { staticClass: "pr-1", attrs: { "x-small": "", color: "primary" } },
          [_vm._v("mdi-check")]
        ),
        _c(
          "span",
          { staticClass: "font-size-x-small font-weight-bold black--text" },
          [_vm._v(_vm._s(_vm.title))]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "font-size-x-small text-dense" }, [
      _vm._v(_vm._s(_vm.data)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }