var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-0" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "text-md-right", attrs: { cols: "1" } },
            [
              _c(
                "v-icon",
                { staticClass: "error darken-4 white--text rounded-circle" },
                [_vm._v("mdi-currency-usd")]
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "11" } }, [
            _c("p", { staticClass: "subtitle-1 pt-0" }, [
              _vm._v(" Creating an additional warehouse "),
              _c(
                "span",
                {
                  staticClass:
                    "subtitle-1 error--text text--darken-4 font-weight-bold",
                },
                [_vm._v(" will result in additional charges ")]
              ),
              _vm._v(
                " to your account to the extent it exceeds the original number of warehouses purchased in your Order Form. "
              ),
            ]),
            _c("p", { staticClass: "subtitle-2" }, [
              _vm._v(
                " By completing the process for creating a warehouse, you authorize Loadsmart to bill your account as permitted by the terms of your Order Form and the associated Subscription and Professional Services Agreement. "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "p",
              { staticClass: "mt-4" },
              [
                _c("v-checkbox", {
                  attrs: {
                    ripple: false,
                    color: "primary",
                    "hide-details": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "subtitle-1" }, [
                            _vm._v("I have read and accept the conditions"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.acceptedTerms,
                    callback: function ($$v) {
                      _vm.acceptedTerms = $$v
                    },
                    expression: "acceptedTerms",
                  },
                }),
              ],
              1
            ),
            _c("p", { staticClass: "mt-9 subtitle-2" }, [
              _vm._v(" If you need any help, reach out to our support team "),
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "https://community.loadsmart.com/hc/en-us/community/topics/14812876253715-OpenDock",
                  },
                },
                [_vm._v(" through this link ")]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }