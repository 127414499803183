<template>
  <v-card
    class="mb-4 pa-1 cursor-pointer draggable-card"
    :draggable="true"
    title="Drag to assign to a spot"
    :data-testid="`yard-asset-visit-${assetVisit.id}`"
    :id="assetVisit.id"
    :class="{ dragging: isDragging }"
    @dragstart="onDragStart"
    @dragend="onDragEnd"
    @drop="onDrop"
    @dragover.prevent>
    <v-card-title>
      <div class="d-flex flex-row">
        <v-icon class="mr-4">mdi-drag-vertical</v-icon>
        <svg-truck :theme="truckThemeColor" :scale="1.2" />
      </div>
    </v-card-title>
    <v-card-text>
      <p class="font-size-medium font-weight-bold">
        {{ assetVisit.company?.name || assetVisit.companyHint || 'Unknown Company' }}
      </p>
      <p class="mb-0 mt-0">
        <strong>
          <v-icon small>mdi-map-marker-outline</v-icon>
          Arrived:
        </strong>
        {{ arrivedTimeFormatted }}
      </p>
      <p class="mb-0 mt-0" v-if="appointment?.refNumber">
        <strong>{{ $refNumSettings(selectedWarehouse).displayName }}:</strong>
        {{ appointment.refNumber }}
      </p>
      <v-btn
        small
        outlined
        block
        class="mt-4"
        @click="selectedAssetVisit = assetVisit"
        :data-testid="`yard-asset-visit-view-more-${assetVisit.id}`">
        View More
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import SvgTruck from '@/components/svgs/SvgTruck.vue';
import { isUUID } from 'class-validator';
import { LuxonDateTimeFormats } from '@nova/core';
import { useEventHub } from '@/composables';

export default defineComponent({
  name: 'YardViewAssetVisit',
  components: { SvgTruck },
  props: {
    assetVisit: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();

    const isDragging = ref(false);
    const originalElement = ref(null);
    const eventHub = useEventHub();

    const arrivedTimeFormatted = computed(() => {
      return yardStore.formatDateTime(
        props.assetVisit.createDateTime,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        LuxonDateTimeFormats.Extended24HrTime
      );
    });

    const truckThemeColor = computed(() =>
      yardStore.getTruckThemeColor(props.assetVisit.appointment)
    );

    const appointment = computed(() => props.assetVisit.appointment);

    const onDragStart = event => {
      isDragging.value = true;
      originalElement.value = event.target;

      // Add the dragging class to change appearance
      event.target.classList.add('dragging');

      // Set the data for transfer
      event.dataTransfer.setData('text/plain', event.target.id);

      setTimeout(() => {
        event.target.style.display = 'none';
        event.target.classList.add('dragging');
      }, 0);
    };

    const onDragEnd = event => {
      isDragging.value = false;

      // Revert the element's appearance
      originalElement.value.style.display = 'block';

      // Get the element and remove the dragging class
      const id = event.dataTransfer.getData('text/plain');
      const element = document.getElementById(id);

      if (element) {
        element.classList.remove('dragging');
      }

      // Check if the element was dropped inside a drop-zone
      const dropZone = document
        .elementFromPoint(event.clientX, event.clientY)
        .closest('.drop-zone');

      if (isUUID(dropZone?.id)) {
        const assetVisitElement = event.srcElement;
        const spot = yardStore.spotsRef.value.find(spot => spot.id === dropZone.id);

        if (spot && yardStore.getSpotStatus(spot) === yardStore.spotStatus.OPEN) {
          return yardStore
            .createSpotAssignment({
              assetVisitId: assetVisitElement.id,
              spotId: dropZone.id
            })
            .then(spotAssignment => {
              eventHub.$emit('spot-assignment-created', spotAssignment);
              yardStore.selectedAssetVisitRef.value = null;
              yardStore.selectedSpotRef.value = spot;
            });
        }
      }
      originalElement.value.style.display = 'block';
    };

    /*
     * This happens only when dropping somewhere off the dom
     */
    const onDrop = event => {
      event.preventDefault();

      const elementId = event.dataTransfer.getData('text/plain');
      const element = document.getElementById(elementId);

      if (element) {
        element.classList.remove('dragging');
      }
    };

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      selectedAssetVisit: yardStore.selectedAssetVisitRef,
      appointment,
      onDragStart,
      truckThemeColor,
      arrivedTimeFormatted,
      onDragEnd,
      onDrop,
      isDragging
    };
  }
});
</script>
<style lang="scss" scoped>
.draggable-card {
  transition: 0.3s ease;

  &:hover {
    background-color: $color-neutral-20;
    transform: scale(1.05); /* Slightly enlarge the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow */
  }
}
/* Styles to apply while the card is being dragged */
.dragging {
  opacity: 0.6 !important; /* Make the card semi-transparent */
  border: 2px dashed $color-primary-60 !important; /* Change border to indicate dragging */
  background-color: $color-neutral-10 !important; /* Optional background change */
}
</style>
