<template>
  <div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      loading
      height="6"
      class="mt-6"></v-progress-linear>
    <v-form v-else ref="form">
      <v-container fluid>
        <v-row>
          <v-col>
            <h3>Single Sign-On</h3>
            <p>
              SSO is an authentication and access control technology which allows users to log-in to
              multiple applications using a single set of credentials. Using SSO with Opendock
              provides a better user experience for your employees and helps ensure greater security
              and regulatory compliance.
              <a
                href="https://community.loadsmart.com/hc/en-us/articles/14944624317075-Single-Sign-On-SSO-SAML-2-0"
                target="_blank"
                rel="noopener"
                >Click here</a
              >
              to learn more.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="mt-5">SSO Setup</h3>
            <p>
              <small>
                Copy your IdP Metadata URL from your IdP (App Federation Metadata URL), paste it
                below and then click the Save button.
              </small>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              type="text"
              v-model="config.idpMetadataUrl"
              :required="config.enabled"
              label="IdP Metadata URL">
            </text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch v-model="config.enabled">
              <template v-slot:label>
                <span>
                  SSO Enabled:
                  <strong> {{ config.enabled ? 'yes' : 'no' }}</strong>
                  <small v-if="!config.enabled" class="switch-note">(testing only)</small>
                </span>
              </template>
            </v-switch>
            <v-switch v-model="config.forbidPasswordLogins">
              <template v-slot:label>
                <span>
                  Forbid password logins:
                  <strong> {{ config.forbidPasswordLogins ? 'yes' : 'no' }}</strong>
                  <small v-if="config.forbidPasswordLogins" class="switch-note"
                    >(except for users with Owner role)</small
                  >
                </span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <primary-button class="mt-2" :loading="submitting" @click="submit()"
            >Save SSO Setup</primary-button
          >
        </v-row>
      </v-container>
    </v-form>
    <v-container fluid v-if="!loading" class="mt-10">
      <v-row>
        <v-col>
          <h3>Opendock Service Provider Metadata</h3>
          <p class="mb-0">
            <small>Use the information in this section to configure your IdP:</small>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <span style="font-weight: bold">Metadata URL:</span>
        </v-col>
        <v-col cols="9">
          <a target="_blank" v-bind:href="spMetadataUrl">{{ spMetadataUrl }}</a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <span style="font-weight: bold">Entity ID:</span>
        </v-col>
        <v-col cols="9">
          <span>{{ spEntityId }}</span>
          <v-icon size="large" class="ml-3" color="secondary" @click="copyEntityID"
            >mdi-content-copy</v-icon
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <span style="font-weight: bold">Assertion Consumer (AC) Location:</span>
        </v-col>
        <v-col cols="9">
          <span>{{ spAcLocation }}</span>
          <v-icon size="large" class="ml-3" color="secondary" @click="copyAcLocation"
            >mdi-content-copy</v-icon
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="!loading" class="mt-8">
      <v-row>
        <v-col>
          <h3>Warehouse IDs</h3>
          <p class="mb-0">
            <small
              >If you are using provisioning, and you want to restrict user's warehouseAccessList,
              you can easily copy the desired Warehouse IDs from this list:
            </small>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Warehouse</th>
                  <th class="text-left">ID</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="wh in warehouses" :key="wh.id">
                  <td>{{ wh.name }}</td>
                  <td>
                    <span>
                      {{ wh.id }}
                      <v-icon size="large" class="ml-3" color="secondary" @click="copyWhId(wh.id)"
                        >mdi-content-copy</v-icon
                      >
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { IsString } from 'class-validator';

export default {
  name: 'OrgSsoConfigForm',
  data() {
    return {
      loading: false,
      submitting: false,
      config: {
        idpMetadataUrl: null,
        enabled: false,
        forbidPasswordLogins: false
      },
      spMetadataUrl: null,
      spEntityId: null,
      spAcLocation: null,
      warehouses: []
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.config.enabled && !this.config.idpMetadataUrl) {
        this.notify('The url cannot be blank when SSO is enabled', 'error');
        return;
      }

      if (this.config.idpMetadataUrl === '') {
        this.config.idpMetadataUrl = null;
      }

      const config = {
        idpMetadataUrl: this.config.idpMetadataUrl,
        enabled: this.config.enabled,
        forbidPasswordLogins: this.config.forbidPasswordLogins
      };

      if (config.idpMetadataUrl) {
        config.idpMetadataUrl = config.idpMetadataUrl.trim();
        if (config.idpMetadataUrl.length === 0) {
          config.idpMetadataUrl = null;
        }
      }

      this.submitting = true;
      try {
        let res = await axios.patch('saml/config', config);
        if (res.status === 200 && res.data) {
          this.config = res.data;
          this.notify('SSO Config successfully updated');
        }
      } finally {
        this.submitting = false;
      }
    },
    copyEntityID() {
      navigator.clipboard.writeText(this.spEntityId);
      this.notify('Copied to Clipboard!');
    },
    copyAcLocation() {
      navigator.clipboard.writeText(this.spAcLocation);
      this.notify('Copied to Clipboard!');
    },
    copyWhId(id) {
      navigator.clipboard.writeText(id);
      this.notify('Copied to Clipboard!');
    }
  },
  async mounted() {
    this.loading = true;
    try {
      let res = await axios.get(`saml/config`);
      if (res.status === 200 && res.data) {
        this.config = res.data;
      }

      res = await axios.get(`saml/metadata-url`);
      if (res.status === 200 && res.data) {
        this.spMetadataUrl = res.data;
      }

      if (IsString(this.spMetadataUrl) && this.spMetadataUrl.length > 0) {
        res = await axios.get(this.spMetadataUrl);
        if (res.status === 200 && res.data) {
          const xmlDoc = new DOMParser().parseFromString(res.data, 'text/xml');

          const elED = xmlDoc.getElementsByTagName('EntityDescriptor')[0];
          const entityId = elED.getAttribute('entityID');
          this.spEntityId = entityId;

          const elAcs = xmlDoc.getElementsByTagName('AssertionConsumerService')[0];
          const location = elAcs.getAttribute('Location');
          this.spAcLocation = location;
        }
      }

      res = await axios.get('warehouse');
      if (res.status === 200 && res?.data?.data) {
        this.warehouses = res.data.data;
      }
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.align-field {
  padding-top: 17px !important;
}

.switch-note {
  margin-left: 0.5rem;
}
</style>
