export default {
  MODULE: {
    WAREHOUSE: {
      ...makeModuleEvents('Warehouse'),
      ...{
        OPERATING_HOURS: makeActionEvent('Operating hours updated')
      }
    },
    WAREHOUSE_GROUP: {
      ...makeModuleEvents('Warehouse Group')
    },
    DOCK: {
      ...makeModuleEvents('Dock'),
      ...{
        PARALLEL: {
          UNLINKED: 'Unlinked: Parallel Capacity',
          DELETED: 'Deleted: Parallel Capacity',
          ADDED: 'Added: Parallel Capacity'
        }
      }
    },
    ORG: {
      ...makeModuleEvents('Org'),
      ...{
        SETTINGS: 'Updated: Org Settings'
      }
    },
    APPOINTMENT: {
      ...makeModuleEvents('Appointment'),
      ...{
        STATUS: 'Updated: Appointment Status',
        TAGS: 'Updated: Appointment Tags',
        CANCELLED: 'Cancelled: Appointment',
        DETAILS: 'Updated: Appointment Details',
        RECURRENCE: 'Updated: Appointment Recurrence',
        RESCHEDULED: 'Rescheduled: Appointment',
        ETA_CHANGED: 'Updated: ETA',
        APPOINTMENT_FIELD_CREATED: 'Created: Appointment Field',
        APPOINTMENT_DETAILS: makeViewEvent('Appointment Details')
      }
    },
    USER: {
      ...makeModuleEvents('User'),
      ...{
        REGISTERED: makeActionEvent('Registered'),
        INVITED: makeActionEvent('Invited User'),
        LOGIN: makeActionEvent('Logged In')
      }
    },
    CARRIER: {
      ...makeModuleEvents('Carrier Contact'),
      ...{
        ADDED: 'Added: Carrier Contact',
        REMOVED: 'Removed: Carrier Contact',
        FAVORITES: {
          ADDED: 'Saved: Favorite Carrier Contact',
          REMOVED: 'Unfavorited: Carrier Contact'
        }
      }
    },
    COMPANY: {
      ...makeModuleEvents('Carrier Company')
    },
    LOAD_TYPE: {
      ...makeModuleEvents('Load Type')
    },
    WAREHOUSE_LOAD_TYPE: {
      ...makeModuleEvents('Warehouse Load Type')
    },
    SUBSCRIPTION: {
      ...makeModuleEvents('Billing')
    },
    NOTIFICATION: {
      ACTION_EDIT: makeActionEvent('Edit Notification'),
      UPDATED: 'Updated: Notification',
      PREVIEW: makeActionEvent('Preview Notification')
    },
    REPORTING: {
      NEW_SEARCH_SAVED: 'Reporting: New Search Saved',
      SEARCH_LOADED: 'Reporting: Saved Search Loaded',
      REPORTING_EXPORTED: makeActionEvent('Report Exported'),
      VIEW_PAGE: makeViewEvent('Insights Page'),
      WAREHOUSE_REPORT_EXPORTED: makeActionEvent('Warehouse Report Exported'),
      CARRIER_REPORT_EXPORTED: makeActionEvent('Carrier Report Exported')
    },
    SELF_CHECK_IN: {
      SELF_CHECK_IN_REQUESTED: makeActionEvent('Self Check-in Requested'),
      OPEN_ARRIVALS_LIST: makeActionEvent('Open Arrivals List'),
      REJECT_TRUCK: makeActionEvent('Reject Truck'),
      APPOINTMENT_LINKED: makeActionEvent('Asset and Appointment Linked'),
      APPOINTMENT_UNLINKED: makeActionEvent('Asset and Appointment Unlinked'),
      CTA_CLICKED: 'Clicked: Gate Management CTA'
    },
    CUSTOM_FORMS: {
      ENABLE_FORM: 'Enabled: Custom Form',
      DISABLE_FORM: 'Disabled: Custom Form',
      SUBMIT_FORM: 'Submitted: Custom Form',
      REMOVE_FORM_FIELD: 'Removed: Custom Form Field',
      ADD_FORM_FIELD: 'Added: Custom Form Field',
      CREATE_FIELD: 'Created: Field',
      EDIT_FIELD: 'Edited: Field',
      REMOVE_FIELD: 'Removed: Field',
      EDIT_FORM_FIELD: 'Edited: Custom Form Field'
    }
  },
  ACCOUNT: {
    ACTIVATED: 'Account: Activated',
    RESET_PASSWORD: 'Account: Reset Password',
    CREATED_PASSWORD: 'Created: Password'
  },
  ACTION: {
    DOCK_SCHEDULE_COPIED: makeActionEvent('Dock Schedule Copied'),
    APPOINTMENTS_EXPORTED: makeActionEvent('Appointment Report Exported'),
    OPENED_CLONE_APPOINTMENT: makeActionEvent('Clone Appointment Opened'),
    COPIED_APPOINTMENT_ID: makeActionEvent('Appointment ID Copied'),
    OPENED_CREATE_WAREHOUSE: makeActionEvent('Create Warehouse Opened'),
    PRINT_APPOINTMENTS: makeActionEvent('Print Appointments List'),
    SEARCH_APPOINTMENTS: makeActionEvent('Search Appointments'),
    VIEW_DESKTOP_VERSION: makeActionEvent('View Desktop Version'),
    VIEW_MOBILE_VERSION: makeActionEvent('View Mobile Version')
  },
  BILLING_INFO: {
    REQUESTED: 'Requested: Billing Info'
  },
  VIEW: {
    TV_MODE: makeViewEvent('TV Mode'),
    REGISTER: {
      PAGE: makeViewEvent('Register Page')
    },
    HELP: {
      PAGE: makeViewEvent('Help Page')
    },
    AUDIT_LOG: makeViewEvent('Audit Log')
  },
  SYSTEM: {
    ERROR: {
      WEBSOCKET_CONNECTION: makeErrorEvent('Websocket connection failure')
    }
  }
};

function makeModuleEvents(moduleName) {
  return {
    CREATED: `Created: ${moduleName}`,
    UPDATED: `Updated: ${moduleName}`,
    DELETED: `Deleted: ${moduleName}`
  };
}

function makeViewEvent(event) {
  return `Viewed: ${event}`;
}

function makeActionEvent(event) {
  return `Action: ${event}`;
}

function makeErrorEvent(error) {
  return `Error: ${error}`;
}
