<template>
  <div>
    <p class="mb-8 mt-4" v-html="header"></p>
    <p
      class="font-size-x-small"
      v-if="Boolean(featureData.subtitle)"
      v-html="featureData.subtitle"></p>

    <template v-if="isFeatureInstalled">
      <h5 class="mb-4">Form available for:</h5>

      <v-expansion-panels class="my-4">
        <v-expansion-panel readonly v-for="trigger of triggers" :key="trigger.id">
          <v-expansion-panel-header class="cursor-default">
            <div>
              <span>{{ novaCore.upperFirst(trigger.app) }}</span>
              <v-icon
                class="ml-2"
                :data-testid="`feature-status-${trigger.app}`"
                :title="trigger.isActive ? 'Form active' : 'Form not visible'"
                :color="trigger.isActive ? 'success' : 'grey'"
                x-small>
                mdi-circle
              </v-icon>
            </div>
            <template v-slot:actions>
              <v-btn
                small
                depressed
                @click="$emit('manage', trigger)"
                :data-testid="`feature-manage-${trigger.app}`">
                Manage
                <v-icon small class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <div v-else class="my-4">
      <strong>
        <v-icon color="red" class="mr-2">mdi-alert-circle</v-icon>
        Feature disabled
      </strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    warehouse: {
      type: Object,
      required: true
    },
    feature: {
      type: String,
      required: true
    },
    triggers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    featureData() {
      return this.novaCore.CustomFormsFeaturesData[this.feature] || {};
    },
    header() {
      return `${this.featureData.description} ${this.featureData.helpLink}`;
    },
    isFeatureInstalled() {
      return (
        this.triggers?.length > 0 &&
        this.triggers
          .filter(trigger => trigger.feature === this.feature)
          .every(trigger => trigger.flow)
      );
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.cursor-default {
  cursor: default !important;
}
</style>
