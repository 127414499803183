var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ul", { staticClass: "legend-items mt-4" }, [
    _c(
      "li",
      { staticClass: "legend-item" },
      [
        _c("v-icon", { attrs: { small: "" } }, [
          _vm._v(_vm._s(_vm.getSpotIcon(_vm.SpotType.DOCKING))),
        ]),
        _c("span", { staticClass: "caption" }, [_vm._v("Docking spot")]),
      ],
      1
    ),
    _c(
      "li",
      { staticClass: "legend-item" },
      [
        _c("v-icon", { attrs: { small: "" } }, [
          _vm._v(_vm._s(_vm.getSpotIcon(_vm.SpotType.PARKING))),
        ]),
        _c("span", { staticClass: "caption" }, [_vm._v("Parking spot")]),
      ],
      1
    ),
    _c(
      "li",
      { staticClass: "legend-item" },
      [
        _c(
          "v-icon",
          {
            class: _vm.getLoadTypeDirectionColor(_vm.LoadTypeDirection.Inbound),
            attrs: { small: "" },
          },
          [_vm._v(" mdi-arrow-up-thick ")]
        ),
        _c("span", { staticClass: "caption" }, [_vm._v("Inbound")]),
      ],
      1
    ),
    _c(
      "li",
      { staticClass: "legend-item" },
      [
        _c(
          "v-icon",
          {
            class: _vm.getLoadTypeDirectionColor(
              _vm.LoadTypeDirection.Outbound
            ),
            attrs: { small: "" },
          },
          [_vm._v(" mdi-arrow-down-thick ")]
        ),
        _c("span", { staticClass: "caption" }, [_vm._v("Outbound")]),
      ],
      1
    ),
    _vm._m(0),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", { staticClass: "legend-item" }, [
      _c("span", { staticClass: "appointment-icon" }),
      _c("span", { staticClass: "caption" }, [_vm._v("Appointment")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", { staticClass: "legend-item" }, [
      _c("span", { staticClass: "no-appointment-icon" }),
      _c("span", { staticClass: "caption" }, [_vm._v("No appointment")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }