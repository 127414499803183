var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Save Report Search",
          scrollable: false,
          "has-dialog-actions": "",
          "loading-message": "Saving report search...",
        },
        on: {
          close: function ($event) {
            return _vm.closeDialog()
          },
          confirm: _vm.submit,
        },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    _vm._b(
                      { attrs: { color: "primary" } },
                      "v-btn",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-floppy"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.actionButtonText) + " "),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("form-base", {
                  attrs: { "hide-required-legend": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "form",
                      fn: function () {
                        return [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.submit.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "px-6" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("text-field", {
                                            attrs: {
                                              required: true,
                                              type: "text",
                                              rules:
                                                _vm.$validator.rules.required(
                                                  "Report Search Title"
                                                ),
                                              label: "Report Search Title",
                                            },
                                            model: {
                                              value: _vm.title,
                                              callback: function ($$v) {
                                                _vm.title = $$v
                                              },
                                              expression: "title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "form-actions",
                      fn: function () {
                        return [
                          _c("action-group", {
                            attrs: {
                              "confirm-icon": "plus",
                              "disable-confirm": !_vm.title,
                              "confirm-label": "Save Report Search",
                            },
                            on: {
                              cancel: _vm.closeDialog,
                              confirm: _vm.submit,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }