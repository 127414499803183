var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appointment-sections" },
    [
      _c(
        "header",
        {
          staticClass: "z-100 white",
          attrs: { id: "mobile-appointment-details-header" },
        },
        [
          _c(
            "section",
            {
              staticClass:
                "d-flex align-center justify-space-between pt-3 px-3",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-row flex-nowrap align-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "nav-arrow-btn mr-4 mobile-icon-btn",
                      attrs: { icon: "", "data-testid": "back-button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  ),
                ],
                1
              ),
              _vm._m(0),
            ]
          ),
        ]
      ),
      _c("v-divider"),
      _c(
        "div",
        { staticClass: "mb-12 pb-12" },
        [
          _c(
            "v-expansion-panels",
            {
              staticClass: "details-expansion-panel",
              attrs: { accordion: "" },
            },
            _vm._l(_vm.claimSupportTriggers, function (trigger) {
              return _c(
                "v-expansion-panel",
                { key: trigger.id },
                [
                  _c("v-expansion-panel-header", [
                    _c("h3", { staticClass: "mr-1 pa-2" }, [
                      _vm._v(_vm._s(_vm.featureData(trigger.feature).title)),
                    ]),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("custom-forms-data", {
                        key: trigger.id,
                        attrs: {
                          trigger: trigger,
                          flat: "",
                          "object-id": _vm.appointment.id,
                          timezone: _vm.warehouse.timezone,
                          "military-time-enabled": _vm.$isMilitaryTimeEnabled(
                            _vm.warehouse
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "mobile-claims-form-actions" } },
        [
          _c("v-divider"),
          _c(
            "div",
            [
              _c(
                "v-dialog",
                {
                  attrs: {
                    "content-class": "claims-actions-mobile",
                    value: _vm.showClaimsActions,
                    transition: "dialog-bottom-transition",
                    "external-activator": "",
                  },
                  on: { input: (val) => (_vm.showClaimsActions = val) },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (slotProps) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass:
                                    "full-width m-b-4 p-x-2 claims-actions-trigger",
                                  attrs: { color: "secondary" },
                                },
                                "v-btn",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v(" Update form ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-weight-bold font-size-small mb-4 ml-4",
                        },
                        [
                          _vm._v(
                            " Which Claims Support form would you like to update? "
                          ),
                        ]
                      ),
                      _vm._l(_vm.claimSupportTriggers, function (trigger) {
                        return _c(
                          "v-btn",
                          {
                            key: trigger.feature,
                            staticClass: "full-width mb-2 left-aligned-btn",
                            attrs: {
                              text: "",
                              plain: "",
                              disabled: !trigger.isActive,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showClaimsActions = false
                                _vm.activeTrigger = trigger
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-2", attrs: { small: "" } },
                              [_vm._v("mdi-text-box-edit-outline")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(_vm.featureData(trigger.feature).title) +
                                " "
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("custom-forms-flow-dialog", {
        attrs: {
          "external-activator": true,
          warehouse: _vm.warehouse,
          "show-dialog": _vm.hasActiveTrigger,
          "object-id": _vm.appointment.id,
          trigger: _vm.activeTrigger,
          fullscreen: "",
          "stack-actions": "",
        },
        on: {
          close: _vm.cleanUpCustomFormsData,
          update: _vm.updateCustomFormsData,
          create: (value) =>
            _vm.createBulkCustomFormsData(value, _vm.warehouse.id),
        },
        scopedSlots: _vm._u([
          {
            key: "custom-actions",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column" }, [
      _c("p", { staticClass: "mt-1" }, [
        _vm._v(
          " Keep records of damages, security measures and other evidence to support claim disputes: "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }