<template>
  <div>
    <template v-if="latestClosedEvent?.id">
      <strong>Reason</strong>
      <p>{{ latestClosedEvent.reason }}</p>

      <template v-if="latestClosedEvent.observation">
        <strong>Additional Notes</strong>
        <p>{{ latestClosedEvent.observation }}</p>
      </template>

      <strong>Closed Since</strong>
      <p>
        <v-icon x-small class="mr-1">mdi-clock-outline</v-icon>
        {{ spotClosedFor }}
      </p>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { DateTime } from 'luxon';

export default defineComponent({
  name: 'YardViewSpotDetailClosed',
  setup() {
    const yardStore = useYard();

    const spotClosedFor = computed(() => {
      const timezone = yardStore.selectedWarehouseRef.value.timezone;

      const closedDate = DateTime.fromISO(yardStore.latestClosedEventRef.value.createDateTime, {
        zone: yardStore.selectedWarehouseRef.value.timezone
      });
      const now = DateTime.now().setZone(timezone);

      return closedDate.toRelative({ base: now });
    });

    return {
      latestClosedEvent: yardStore.latestClosedEventRef,
      spot: yardStore.selectedSpotRef,
      spotClosedFor
    };
  }
});
</script>
