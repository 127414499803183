var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("h4", [
                        _vm._v(
                          " This tool will transfer all appointments from one carrier to another, no notification will be triggered for this change. Make sure you clear out with both carriers before doing this change! "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _vm.loading
                      ? _c(
                          "v-col",
                          { staticClass: "mt-4" },
                          [
                            _c(
                              "v-card-text",
                              [
                                _vm._v(" Loading Appointment Data... "),
                                _c("v-progress-linear", {
                                  attrs: {
                                    color: "secondary",
                                    indeterminate: "",
                                    rounded: "",
                                    height: "8",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : [
                          !_vm.appointmentCount
                            ? _c(
                                "v-col",
                                { staticClass: "mt-4" },
                                [
                                  _c(
                                    "v-alert",
                                    { attrs: { type: "warning" } },
                                    [
                                      _vm._v(
                                        " This carrier user has no appointments to be transferred! "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-col",
                                { staticClass: "mt-4" },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Source Carrier"),
                                      ]),
                                      _c("v-card-text", [
                                        _c("strong", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.sourceUser.firstName) +
                                              " " +
                                              _vm._s(_vm.sourceUser.lastName) +
                                              " - " +
                                              _vm._s(_vm.sourceUser.email) +
                                              " "
                                          ),
                                        ]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sourceUser.company.name
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "h3",
                                        { staticClass: "text-center my-4" },
                                        [
                                          _vm._v(
                                            " This carrier user has " +
                                              _vm._s(_vm.appointmentCount) +
                                              " appointment" +
                                              _vm._s(
                                                _vm.appointmentCount > 1
                                                  ? "s"
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                  ],
                  2
                ),
                _c("v-spacer", { staticClass: "my-12" }),
                _vm.appointmentCount
                  ? _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _c(
                            "div",
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-bold font-size-small",
                                },
                                [_vm._v("Destination Carrier Contact:")]
                              ),
                              _c("carrier-select", {
                                attrs: {
                                  "show-carrier-create-button": false,
                                  "hide-icon": "",
                                  required: "",
                                  "hide-details": "",
                                  "entry-point": "Transfer Appointment Modal",
                                },
                                model: {
                                  value: _vm.targetUser,
                                  callback: function ($$v) {
                                    _vm.targetUser = $$v
                                  },
                                  expression: "targetUser",
                                },
                              }),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("v-spacer"),
                _c("v-spacer", { staticClass: "my-12" }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "confirm-icon": "transit-transfer",
                "confirm-label": "Transfer to Carrier User",
                "confirm-color": "red",
                "cancel-label": _vm.cancelLabel,
                "cancel-color": _vm.cancelColor,
                "cancel-icon": _vm.cancelIcon,
                "hide-confirm": !_vm.appointmentCount,
                "disable-confirm": !_vm.targetUser || _vm.loading,
              },
              on: { cancel: _vm.remove, confirm: _vm.transfer },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }