<template>
  <div v-if="customFieldsExist" :class="containerClasses">
    <div
      :class="[{ row: isInline }, ...fieldClasses]"
      v-for="field in customFields"
      :key="field.name">
      <h3 v-if="field.label">
        {{ field.label }}:
        <help-icon-tooltip v-if="field.description">{{ field.description }}</help-icon-tooltip>
      </h3>
      <component
        single-line
        v-bind:is="`${customFieldComponents[field.type]}-old`"
        :field="field"
        :warehouse="warehouse"
        v-model="field.value"></component>
    </div>
  </div>
</template>

<script>
import { IWarehouse } from '@satellite/../nova/core';

/**
 * Custom Fields shown in create appointment form
 * @displayName Custom Fields
 */
export default {
  name: 'CustomFields',
  props: {
    /**
     * Warehouse entity providing the custom fields template
     */
    warehouse: IWarehouse,
    appointment: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    initialCustomFields: {
      type: Array,
      required: false,
      default: null
    },
    isInline: {
      type: Boolean,
      default: true
    },
    containerClasses: {
      type: Array,
      default() {
        return ['my-4'];
      }
    },
    fieldClasses: {
      type: Array,
      default() {
        return ['mb-4'];
      }
    },
    singleLine: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customFieldsExist() {
      return this.customFields?.length;
    },
    customFieldComponents() {
      // Auto generate component names for dynamic importing the Custom Fields components
      const componentsMap = {};
      Object.entries(this.novaCore.CustomFieldType).forEach(
        type => (componentsMap[type[1]] = `custom-field-${type[0].toLowerCase()}`)
      );
      return componentsMap;
    },
    existingCustomFields() {
      return this.initialCustomFields?.length
        ? this.initialCustomFields
        : this.appointment?.customFields;
    }
  },
  data() {
    return {
      customFields: [],
      areCustomFieldsSet: false
    };
  },
  methods: {
    getRequiredValue(field) {
      this.novaCore.getRequiredValue(field, false);
    },
    setCustomFields() {
      this.customFields = this.novaCore.updateCustomFieldsFromWarehouse(
        this.existingCustomFields,
        this.warehouse,
        false
      );
    }
  },
  mounted() {
    this.setCustomFields();
  },
  watch: {
    warehouse() {
      this.setCustomFields();
    },
    customFields() {
      /**
       * Emits input event with new customFields data
       * @event input
       * @property {object} input - customFields data
       */
      this.$emit('input', this.customFields);
    }
  }
};
</script>
