var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("div", { staticClass: "calendar-loader__wrapper overlay-base" }, [
        _c("div", { staticClass: "calendar-loader__overlay overlay-base" }),
        _c(
          "div",
          { staticClass: "calendar-loader__content" },
          [
            _c("v-progress-linear", { attrs: { indeterminate: "" } }),
            _c("div", { staticClass: "text-center mt-3" }, [
              _vm._v(_vm._s(_vm.customMessage ?? "Loading Appointments ...")),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }