var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showDeveloperSettings
        ? _c(
            "div",
            [
              _c("div", { staticClass: "text-h6" }, [
                _vm._v("Developer Tools"),
              ]),
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c("v-switch", {
                    attrs: { label: "Log Subspace Events in Console" },
                    model: {
                      value: _vm.logSubspace,
                      callback: function ($$v) {
                        _vm.logSubspace = $$v
                      },
                      expression: "logSubspace",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("div"),
      !_vm.notificationConfigKey
        ? _c("org-settings-form", {
            attrs: { "read-only": _vm.$isOrgReadOnly },
          })
        : _c("notification-config-form", {
            attrs: {
              settings: _vm.$org.settings,
              "notification-type": _vm.notificationConfigKey,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }