<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs, props }">
      <div v-on="on">
        <v-icon
          @click.stop="handleClick($event)"
          :color="computedColor"
          :class="iconClass"
          :size="size"
          :disabled="disabled"
          v-bind="[attrs, props, $attrs, $props]"
          v-show="!loading"
          >mdi-{{ computedIcon }}</v-icon
        >
        <v-progress-circular
          v-show="loading"
          size="16"
          width="2"
          :class="iconClass"
          indeterminate
          :color="computedColor">
        </v-progress-circular>
      </div>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import buttonMixin from '../../mixins/buttonMixin';

/**
 * Icon button with a tooltip
 * @displayName Icon Button with Tooltip
 */
export default {
  mixins: [buttonMixin],
  props: {
    /**
     * Icon to display
     */
    icon: {
      type: String,
      required: false,
      default: 'information-outline'
    },
    /**
     * Color of the icon
     */
    color: {
      type: String,
      required: false,
      default: 'secondary'
    },
    /**
     * Size of the icon
     */
    size: {
      type: String,
      required: false,
      default: 'small'
    },
    /**
     * Tooltip content to display
     */
    tooltip: {
      type: String,
      required: true
    },
    /**
     * Shortcut for creating red trash icons for delete
     */
    delete: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Shortcut for creating blue pencil icons for edit
     */
    edit: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Classes to apply to the icon "e.g: 'mr-2 mt-1'"
     */
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Disable button and display loading spinner
     */
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedColor() {
      if (this.$options.propsData['color']) {
        return this.color;
      }
      if (this.delete) {
        return 'error';
      }
      if (this.edit) {
        return 'secondary';
      }

      return this.color;
    },
    computedIcon() {
      if (this.$options.propsData['icon']) {
        return this.icon.replace('mdi-', '');
      }
      if (this.delete) {
        return 'delete-outline';
      }
      if (this.edit) {
        return 'pencil';
      }

      return this.icon.replace('mdi-', '');
    }
  }
};
</script>
