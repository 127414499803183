<template>
  <v-tooltip right>
    <template v-slot:activator="{ on }">
      <v-icon
        v-on="on"
        @click="showConnectionStatusDialog = true"
        :color="connectionStatusColor"
        :class="`${
          websocketHeartbeat.shouldPulse ||
          connectionStatus !== sockets.connectionStatuses.CONNECTED
            ? 'circular-pulse'
            : ''
        }`"
        >{{ connectionStatusIcon }}</v-icon
      >
    </template>
    <span>Server Connection Status - Click for more information</span>

    <connection-status-dialog :show-dialog="showConnectionStatusDialog" @close="closeDialog">
    </connection-status-dialog>
  </v-tooltip>
</template>

<script>
import ConnectionStatusDialog from '@/components/elements/ConnectionStatusDialog';
import connectionStatusMixin from '@/components/mixins/connectionStatusMixin';

/**
 * @displayName Connection Status Indicator
 */
export default {
  name: 'connection-status-indicator',
  mixins: [connectionStatusMixin],
  components: { ConnectionStatusDialog },
  data() {
    return {
      websocketHeartbeat: {
        shouldPulse: false
      },
      showConnectionStatusDialog: false
    };
  },
  methods: {
    closeDialog() {
      this.showConnectionStatusDialog = false;
      this.$nextTick(() => {
        document.activeElement.blur();
      });
    }
  },
  created() {
    this.$eventHub.$on('heartbeat', () => {
      // Applies the pulse class for 1.5s allowing the icon to pulse once
      this.websocketHeartbeat.shouldPulse = true;
      setTimeout(() => {
        this.websocketHeartbeat.shouldPulse = false;
      }, 1500);
    });
  }
};
</script>
