<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    max-width="900px"
    width="900px"
    header="Create Warehouse"
    :scrollable="false"
    :loading="saving || waitingOnExternalResource"
    loading-message="Creating Warehouse..."
    has-dialog-actions
    @close="close"
    @confirm="onEnter">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <v-tooltip top :disabled="$rolePermissions.canCreateWarehouse">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <primary-button
              :before-icon="beforeIcon"
              v-on="slotProps.on"
              v-bind="slotProps.attrs"
              :disabled="!$rolePermissions.canCreateWarehouse"
              :loading="waitingOnExternalResource"
              large
              class="mr-2">
              Create Warehouse
            </primary-button>
          </div>
        </template>
        <span>{{ novaCore.getRoleActionError() }}</span>
      </v-tooltip>
    </template>
    <template #body>
      <create-warehouse-stepper
        :hide-terms="isFirstWarehouse"
        @confirm="submit"
        @cancel="close"
        ref="createWarehouseStepper"></create-warehouse-stepper>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for create warehouse form
 * @displayName Create Warehouse Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    beforeIcon: {
      type: String,
      required: false,
      default: 'plus'
    },
    postSubmissionRouteName: {
      type: String,
      required: false,
      default: 'warehouses.details.general'
    },
    waitingOnExternalResource: {
      type: Boolean,
      required: false,
      default: false
    },
    isFirstWarehouse: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      saving: false
    };
  },
  methods: {
    /**
     * Submit form
     * @public
     * @returns {Promise<void>}
     */
    async submit(fields) {
      if (this.saving) {
        return;
      }

      this.saving = true;

      let data = { ...fields };

      let response = await this.$store.dispatch('Warehouses/createWarehouse', data).finally(() => {
        this.saving = false;
      });

      if (response?.data) {
        const entryPoint = this.mixpanel.getEntryPoint(this, [
          {
            entryPoint: 'Warehouses Page',
            component: 'warehouse-list'
          }
        ]);
        this.mixpanel.track(this.mixpanel.events.MODULE.WAREHOUSE.CREATED, {
          'Warehouse ID': response.data.data.id,
          'Warehouse Name': response.data.data.name,
          'Warehouse City': response.data.data.city,
          'Warehouse State': response.data.data.state,
          'Warehouse Zip': response.data.data.zip,
          'Warehouse Phone': response.data.data.phone,
          'Warehouse Changed Fields': this.getAttributesForMixpanel(data),
          'Entry Point': entryPoint,
          'Org Name': this.$org.name,
          'Org ID': this.$org.id
        });

        this.onWarehouseCreated(response.data.data);
      }
    },
    /**
     * Get the attributes with values set as mixpanel value
     * @param warehouse
     */
    getAttributesForMixpanel(warehouse) {
      return Object.entries(warehouse)
        .filter(([key, value]) => {
          return (
            !['city', 'state', 'country', 'street', 'zip', 'timezone'].includes(key) &&
            ((typeof value === 'string' && value.trim() !== '') ||
              (Array.isArray(value) && value.length > 0))
          );
        })
        .map(([attribute]) => this.novaCore.breakWordsAtCaps(attribute).toLowerCase())
        .concat(['address']);
    },
    /**
     * Handle warehouse creation
     * @param warehouse
     */
    onWarehouseCreated(warehouse) {
      this.close();
      this.$router.push({
        name: this.postSubmissionRouteName,
        params: { warehouseId: warehouse.id }
      });
    },
    /**
     * Handle enter key event
     *
     */
    onEnter() {
      this.$refs.createWarehouseStepper.onNext();
    }
  }
};
</script>
