<template>
  <v-autocomplete
    :value="value"
    :items="timezones"
    :rules="rules"
    :disabled="disabled"
    :class="{ required: required }"
    @change="value => $emit('input', value)"
    label="Timezone"
    flat
    placeholder="America/Phoenix"
    dense></v-autocomplete>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'TimezoneSelect',
  props: {
    value: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    timezones() {
      const prefixes = ['America', 'Europe', 'Asia', 'Pacific', 'Australia'];

      return moment.tz.names().filter(tz => prefixes.some(prefix => tz.startsWith(prefix)));
    }
  }
};
</script>
