var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-wrapper" },
    [
      _c("auth-hero"),
      _c(
        "div",
        {
          staticClass:
            "flex-grow-1 d-flex flex-column align-center justify-center",
        },
        [
          _c(
            "div",
            { staticClass: "full-width auth-content pt-4" },
            [
              !_vm.showEmailVerification
                ? [
                    _vm._m(0),
                    _vm._m(1),
                    _c("registration-form", {
                      staticClass: "mt-8",
                      on: {
                        "registration-submitted": _vm.registrationSubmitted,
                      },
                    }),
                  ]
                : [
                    _c("verify-email", {
                      attrs: {
                        email: _vm.email,
                        "allow-verification-resend": false,
                      },
                      on: {
                        backToLogin: function ($event) {
                          return _vm.$router.push({
                            name: "login",
                            params: { defaultEmail: _vm.email },
                          })
                        },
                      },
                    }),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass:
          "sub-title d-flex align-center justify-center font-weight-bold",
      },
      [
        _c("img", {
          staticClass:
            "d-inline-block mr-1 opendock-graphic--orange opendock-graphic--x-small",
          attrs: {
            src: "/images/opendock_graphic.svg",
            alt: "opendock graphic",
          },
        }),
        _vm._v(" Warehouse "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "d-flex flex-column align-center justify-center flex-wrap",
      },
      [
        _c(
          "span",
          {
            staticClass:
              "text-h4 text-center py-2 pt-4 font-weight-bold secondary--text",
          },
          [_vm._v("Register Now")]
        ),
        _c("span", { staticClass: "pt-4" }, [
          _vm._v("Just fill in the fields below. Quick and easy!"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }