var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "time-selector" },
    [
      _c("v-container", { staticClass: "date-header pb-0" }, [
        _c(
          "section",
          { staticClass: "d-flex align-center" },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-1 justify-space-between date-nav align-center",
              },
              [
                _c(
                  "v-btn",
                  { attrs: { text: "" }, on: { click: _vm.previousDates } },
                  [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("date-picker", {
                      attrs: { "icon-mode": true, "open-dates": _vm.openDates },
                      on: { "date-navigation": _vm.setOpenDates },
                      model: {
                        value: _vm.selectedDate,
                        callback: function ($$v) {
                          _vm.selectedDate = $$v
                        },
                        expression: "selectedDate",
                      },
                    }),
                    _c("span", { staticClass: "caption" }, [
                      _vm._v(_vm._s(_vm.dateTimeHeader)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  { attrs: { text: "" }, on: { click: _vm.nextDates } },
                  [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                  1
                ),
              ],
              1
            ),
            _vm.isReschedule && _vm.allowDockSelect
              ? _c("dock-select", {
                  staticClass: "flex-1",
                  attrs: {
                    "hide-icon": "",
                    "custom-persistent-label": "Docks",
                    clearable: false,
                    "multi-select": false,
                    docks: _vm.docks,
                    "data-testid": "reschedule-dialog-dock-select",
                  },
                  model: {
                    value: _vm.selectedDock,
                    callback: function ($$v) {
                      _vm.selectedDock = $$v
                    },
                    expression: "selectedDock",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex date-labels mt-4" },
          _vm._l(_vm.dateRange, function (item) {
            return _c(
              "div",
              {
                key: `slot-${item.date}-header`,
                staticClass: "text-center time-column",
              },
              [
                _c("h3", [
                  _c("span", { staticClass: "d-block" }, [
                    _vm._v(_vm._s(item.day)),
                  ]),
                  _vm._v(" " + _vm._s(item.date) + " "),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "time-grid d-flex mb-4 container pt-0" },
        [
          !_vm.processing
            ? _vm._l(_vm.dateRange, function (item) {
                return _c(
                  "div",
                  {
                    key: `slot-${item.date}-times`,
                    staticClass:
                      "text-center time-column d-flex flex-column align-center",
                    attrs: { "data-testid": `time-column-${item.date}` },
                  },
                  [
                    _vm.availability[item.key] &&
                    _vm.availability[item.key].length
                      ? [
                          _vm._l(
                            _vm.availability[item.key],
                            function (time, i) {
                              return [
                                time.isDSTChange
                                  ? _c("dst-divider", { key: `${i}-divider` })
                                  : _vm._e(),
                                _vm.value &&
                                JSON.stringify(time.start) ===
                                  JSON.stringify(_vm.value.start)
                                  ? _c(
                                      "secondary-button",
                                      {
                                        key: `${i}-time-active`,
                                        staticClass: "time-button",
                                        attrs: {
                                          "data-date-time": _vm.formatTime(
                                            time.start.format(),
                                            _vm.novaCore.LuxonDateTimeFormats
                                              .MonthDayYearSlashedTimeNoSpace
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setTime(time)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatTime(
                                                time.start.format()
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "outline-button",
                                      {
                                        key: `${i}-time`,
                                        staticClass: "time-button",
                                        class: {
                                          "time-button-past": time.isPast,
                                        },
                                        attrs: {
                                          "data-date-time": _vm.formatTime(
                                            time.start.format(),
                                            _vm.novaCore.LuxonDateTimeFormats
                                              .MonthDayYearSlashedTimeNoSpace
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setTime(time)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatTime(
                                                time.start.format()
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ]
                            }
                          ),
                        ]
                      : [_c("div", [_vm._v("No availability")])],
                  ],
                  2
                )
              })
            : [
                _c(
                  "div",
                  { staticClass: "loader full-width pb-8" },
                  [
                    _c("v-progress-linear", {
                      staticClass: "mt-12",
                      attrs: {
                        indeterminate: "",
                        loading: _vm.processing,
                        height: "6",
                      },
                    }),
                    _c("h4", { staticClass: "text-center mt-4" }, [
                      _vm._v("Loading Availability..."),
                    ]),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.appointment && _vm.selectedDock && _vm.allowDockSelect
        ? _c("load-type-re-select-dialog", {
            attrs: {
              "return-load-type": "",
              "original-event": _vm.appointment,
              event: _vm.appointment,
              "event-warehouse": _vm.appointment.dock.warehouse,
              "event-dock": _vm.selectedDock,
              "original-event-dock": _vm.appointment.dock,
              "show-dialog": _vm.shouldShowLoadTypeReselectDialog,
            },
            on: {
              close: function ($event) {
                _vm.shouldShowLoadTypeReselectDialog = false
              },
              "update-selected-load-type": _vm.updateSelectedLoadType,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }