<template>
  <div>
    <dialog-base
      v-bind="$props"
      :key="renderKey"
      header="Edit Carrier Contact"
      :loading="false"
      :scrollable="false"
      @close="close"
      @confirm="submit"
      class="mx-auto"
      has-dialog-actions
      @open="initializeForm"
      max-width="50%"
      width="50%">
      <template v-slot:activator="slotProps" v-if="!externalActivator">
        <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      </template>
      <template slot="body">
        <v-container>
          <v-alert prominent type="warning" icon="mdi-alert-octagon-outline" color="error">
            <v-row align="center">
              <v-col class="grow">
                <strong>This is a system-wide action!</strong>
                <br />
                Modifying this carrier contact will affect ALL Opendock organizations.
              </v-col>
              <v-col class="shrink">
                <v-btn v-if="!isRiskAcknowledged" @click="isRiskAcknowledged = true">
                  I Understand
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <form-base required-legend-class="pl-3">
            <template slot="form">
              <v-form ref="form" :disabled="!isRiskAcknowledged">
                <text-field
                  :required="true"
                  type="text"
                  v-model="localCarrier.firstName"
                  :rules="$validator.rules.required('First Name')"
                  label="First Name"></text-field>
                <text-field
                  :required="true"
                  type="text"
                  v-model="localCarrier.lastName"
                  :rules="$validator.rules.required('Last Name')"
                  label="Last Name"></text-field>
                <text-field
                  type="text"
                  v-model="localCarrier.phone"
                  label="Phone Number"></text-field>
                <company-select
                  v-if="localCarrier"
                  v-model="localCarrier.companyId"
                  ref="companySelect"
                  hide-details
                  :entry-point="entryPoint"
                  placeholder="Search for a company name, scac, usdot or mc..."
                  :rules="$validator.rules.required('Carrier Company')"
                  hide-icon
                  required></company-select>
                <v-btn
                  text
                  x-small
                  :disabled="!isRiskAcknowledged"
                  @click="showCompanyDialog = true"
                  class="secondary--text font-weight-bold cursor-pointer">
                  Create a New Company
                </v-btn>
              </v-form>
            </template>
            <template slot="form-actions">
              <action-group
                :disable-confirm="!isRiskAcknowledged"
                @cancel="close"
                confirm-icon="upload"
                confirm-label="Update Carrier"
                @confirm="submit"></action-group>
            </template>
          </form-base>
        </v-container>
      </template>
    </dialog-base>

    <dialog-base
      header="New Carrier Company"
      :loading="false"
      :external-activator="true"
      :show-dialog="showCompanyDialog"
      @close="showCompanyDialog = false"
      @confirm="handleCompanySave"
      has-dialog-actions
      max-width="560px">
      <template slot="body">
        <create-company-form
          @close="showCompanyDialog = false"
          v-if="showCompanyDialog"
          @save="handleCompanySave"></create-company-form>
      </template>
    </dialog-base>
  </div>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  name: 'edit-carrier-dialog',
  props: {
    carrier: {
      type: Object,
      required: true
    },
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localCarrier: {},
      isRiskAcknowledged: false,
      showCompanyDialog: false
    };
  },
  methods: {
    handleCarrierSave(carrier) {
      this.$emit('save', carrier);
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const selectedCompany = this.$refs.companySelect.getSelectedItem();
      this.$confirm(
        `First Name: ${this.localCarrier.firstName}<br />
        Last Name: ${this.localCarrier.lastName}<br />
        Phone: ${this.localCarrier.phone || 'N/A'}<br />
        Company: ${selectedCompany.name}`,
        {
          title: 'Is this correct?',
          color: 'warning'
        }
      ).then(async confirmed => {
        if (confirmed) {
          const { companyId, firstName, lastName, phone, id } = this.localCarrier;
          const carrierUpdateDto = {
            id,
            companyId,
            firstName,
            lastName,
            phone
          };

          await this.$store.dispatch('Users/updateUser', carrierUpdateDto);
          this.$emit('save');
          this.close();
        }
      });
    },
    handleCompanySave(company) {
      this.localCarrier.companyId = company.id;
      this.$refs.companySelect.handleNewCompany(company);
    },
    initializeForm() {
      this.localCarrier = this.novaCore.deepClone(this.carrier);
      this.isRiskAcknowledged = false;
    }
  }
};
</script>
