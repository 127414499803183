<template>
  <OrgDetailsPage :orgId="orgId"></OrgDetailsPage>
</template>

<script>
import OrgDetailsPage from '@/modules/orgs/OrgDetailsPage';
export default {
  name: 'OrgPage',
  components: { OrgDetailsPage },
  computed: {
    orgId() {
      return this.$me?.orgId ?? this.$route.params.orgId;
    }
  },
  beforeMount() {
    if (!this.$route.params.orgId) {
      this.$router.push(`/org/${this.$me.orgId}/settings`);
    }
  }
};
</script>
