var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.readOnly
        ? [
            _c("v-img", {
              staticClass: "circle",
              attrs: { src: _vm.fileUrl, height: "80", width: "80" },
            }),
          ]
        : [
            _vm.fileTypeText
              ? _c("p", [_c("small", [_vm._v(_vm._s(_vm.fileTypeText))])])
              : _vm._e(),
            _c(
              "v-file-input",
              _vm._g(
                _vm._b(
                  {
                    key: _vm.renderKey,
                    ref: "fileinput",
                    staticClass: "doc-upload-component",
                    attrs: {
                      placeholder: "Upload Document",
                      required: _vm.required,
                      type: "file",
                      "single-line": "",
                      "hide-input": "",
                      "hide-details": "auto",
                      "prepend-icon": "",
                    },
                    on: { change: _vm.onFileChanged },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function () {
                          return [
                            !_vm.fileUrl
                              ? _c(
                                  "outline-button",
                                  {
                                    attrs: {
                                      loading: _vm.isSelectingFile,
                                      "before-icon": "cloud-upload",
                                    },
                                    on: { click: _vm.fileUploadClick },
                                  },
                                  [
                                    _vm._v(" Upload File "),
                                    _vm.required
                                      ? _c(
                                          "span",
                                          { staticClass: "red--text ml-1" },
                                          [_c("strong", [_vm._v("*")])]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "is-relative doc-upload-button",
                                      class: _vm.cropShape,
                                      attrs: {
                                        loading: _vm.isSelectingFile,
                                        height: "100px",
                                      },
                                      on: { click: _vm.fileUploadClick },
                                    },
                                    [
                                      _vm.isImageFile
                                        ? _c("v-img", {
                                            attrs: {
                                              contain: "",
                                              height: "100px",
                                              width: "100px",
                                              src: _vm.fileUrl,
                                            },
                                          })
                                        : _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-large": "",
                                                color: "secondary",
                                              },
                                            },
                                            [_vm._v("mdi-file")]
                                          ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "doc-close-button",
                                          class: {
                                            "is-image": _vm.isImageFile,
                                          },
                                          attrs: { icon: "", "x-small": "" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.deleteDoc.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-small": "" } },
                                            [_vm._v("mdi-close")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-italic text-subtitle-2",
                                    },
                                    [_vm._v(_vm._s(_vm.fileName))]
                                  ),
                                ],
                            _c(
                              "span",
                              { staticClass: "errors v-messages mt-2" },
                              [_vm._v(_vm._s(_vm.fieldError))]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.selectedFile,
                      callback: function ($$v) {
                        _vm.selectedFile = $$v
                      },
                      expression: "selectedFile",
                    },
                  },
                  "v-file-input",
                  [_vm.$props, _vm.$attrs],
                  false
                ),
                _vm.$listeners
              )
            ),
            _vm.tempFileBase64 && _vm.isImageFile && _vm.allowCropping
              ? _c(
                  "v-dialog",
                  {
                    attrs: {
                      value: !!_vm.tempFileBase64,
                      persistent: "",
                      width: "700",
                    },
                    on: {
                      close: function ($event) {
                        _vm.tempFileBase64 = null
                      },
                    },
                  },
                  [
                    _c(
                      "v-card",
                      {
                        staticStyle: { "max-height": "none !important" },
                        attrs: { width: "700" },
                      },
                      [
                        _c("v-card-title", [_vm._v("Adjust Image Preview")]),
                        _c(
                          "v-card-text",
                          [
                            !_vm.isSelectingFile
                              ? [
                                  _vm.cropperLoading
                                    ? _c(
                                        "div",
                                        [
                                          _c("v-progress-linear", {
                                            attrs: { indeterminate: "" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    [
                                      !_vm.cropperLoading
                                        ? _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.modalHeaderText) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("cropper", {
                                        ref: "cropper",
                                        attrs: {
                                          src: _vm.tempFileBase64,
                                          debounce: false,
                                          "background-class":
                                            "cropper-background",
                                          "stencil-component": `${_vm.cropShape}-stencil`,
                                          "foreground-class":
                                            "cropper-foreground",
                                          "stencil-props": {
                                            aspectRatio: _vm.aspectRatio,
                                            handlers: {},
                                            movable: true,
                                            resizeable: true,
                                            stencilSize: {
                                              width: 100,
                                              height: 100,
                                            },
                                          },
                                          "default-boundaries": "fill",
                                          "fill-color": "#FFFFFF",
                                          "image-restriction": "none",
                                          "resize-image": {
                                            wheel: false,
                                            touch: true,
                                            adjustStencil: true,
                                          },
                                          transitions: false,
                                        },
                                        on: {
                                          ready: function ($event) {
                                            _vm.cropperLoading = false
                                          },
                                          change: _vm.change,
                                        },
                                      }),
                                      _c("v-slider", {
                                        staticClass: "mt-1",
                                        attrs: {
                                          disabled: _vm.cropperLoading,
                                          value: _vm.zoom.input,
                                          color: "secondary",
                                          "hide-details": "auto",
                                          "track-color": "secondary",
                                          step: "0.01",
                                          max: 0.99,
                                          min: "-5.99",
                                          "append-icon":
                                            "mdi-magnify-plus-outline",
                                          "prepend-icon":
                                            "mdi-magnify-minus-outline",
                                        },
                                        on: { input: _vm.onZoom },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center",
                                    },
                                    [
                                      _vm.showCropResult &&
                                      _vm.cropResult &&
                                      !_vm.cropperLoading
                                        ? _c("preview", {
                                            staticClass: "upload-preview",
                                            class: _vm.cropShape,
                                            attrs: {
                                              width: 120,
                                              height: 120,
                                              image: _vm.cropResult.image,
                                              coordinates:
                                                _vm.cropResult.coordinates,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c("v-progress-linear", {
                                    staticClass: "mt-12",
                                    attrs: { indeterminate: "", height: "6" },
                                  }),
                                  _c(
                                    "h4",
                                    { staticClass: "text-center mt-4" },
                                    [_vm._v("Uploading Image...")]
                                  ),
                                ],
                          ],
                          2
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "my-2" },
                          [
                            _vm.tempFileSize && !_vm.isSelectingFile
                              ? _c(
                                  "span",
                                  { staticClass: "font-size-x-small" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.tempFileSize.toFixed(2)) +
                                        _vm._s(_vm.conversionUnit) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("v-spacer"),
                            _c(
                              "outline-button",
                              {
                                attrs: { disabled: _vm.isSelectingFile },
                                on: { click: _vm.closeCropperDialog },
                              },
                              [_vm._v(" Nevermind ")]
                            ),
                            _c(
                              "primary-button",
                              {
                                attrs: { disabled: _vm.isSelectingFile },
                                on: { click: _vm.uploadCroppedImage },
                              },
                              [_vm._v(" Upload ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }