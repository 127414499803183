var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    attrs: { "hide-required-legend": "" },
    scopedSlots: _vm._u(
      [
        {
          key: "form",
          fn: function () {
            return [
              _vm.loading
                ? [
                    _c("v-progress-linear", {
                      staticClass: "mt-6",
                      attrs: {
                        indeterminate: "",
                        loading: _vm.loading,
                        height: "6",
                      },
                    }),
                    _c("h4", { staticClass: "text-center mt-4 mb-12" }, [
                      _vm._v("Updating reserve ..."),
                    ]),
                  ]
                : [
                    _c(
                      "v-form",
                      { ref: "form" },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        rules:
                                          _vm.$validator.rules.required(
                                            "Title"
                                          ),
                                        "validate-on-blur": "",
                                        "single-line": "",
                                        placeholder: "Reserve title...",
                                      },
                                      model: {
                                        value: _vm.notes,
                                        callback: function ($$v) {
                                          _vm.notes = $$v
                                        },
                                        expression: "notes",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("date-picker", {
                                      attrs: {
                                        required: "",
                                        height: "26px",
                                        "display-field-icon": false,
                                        "display-field-label": false,
                                        "no-margins": true,
                                        clearable: false,
                                      },
                                      model: {
                                        value: _vm.date,
                                        callback: function ($$v) {
                                          _vm.date = $$v
                                        },
                                        expression: "date",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  [
                                    _c("time-range-selector", {
                                      attrs: {
                                        index: 0,
                                        "date-range-select-items":
                                          _vm.dateRangeSelectItems,
                                        "from-items": _vm.fromItems,
                                        "to-items": _vm.toItems,
                                        "from-rules":
                                          _vm.$validator.rules.required("From"),
                                        "to-rules":
                                          _vm.$validator.rules.required("To"),
                                        "hide-details": false,
                                        "interval-length": _vm.intervalLength,
                                      },
                                      model: {
                                        value: _vm.timeRange,
                                        callback: function ($$v) {
                                          _vm.timeRange = $$v
                                        },
                                        expression: "timeRange",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
            ]
          },
          proxy: true,
        },
        !_vm.loading
          ? {
              key: "form-actions",
              fn: function () {
                return [
                  _c("action-group", {
                    attrs: {
                      "confirm-label": "Save Changes",
                      "cancel-label": "Nevermind",
                    },
                    on: {
                      cancel: function ($event) {
                        return _vm.$emit("close")
                      },
                      confirm: _vm.submit,
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }