<template>
  <div>
    <div class="pa-8 text-center" v-show="loading">
      <v-progress-linear indeterminate color="primary" class="mb-1 mt-4"></v-progress-linear>
      <span>{{ loadingMessage }}</span>
    </div>
    <div class="hoc-loader-content" v-show="!loading" style="padding-bottom: 20px">
      <!-- @slot Content to display when not loading -->
      <slot name="default"></slot>
    </div>
    <div v-if="!loading">
      <slot name="form-actions"></slot>
    </div>
  </div>
</template>

<script>
/**
 * Higher order component loader
 * @displayName HOC Loader
 */
export default {
  name: 'hoc-dialog-loader',
  props: {
    /**
     * Display loader
     */
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    /**
     * Loading message
     */
    loadingMessage: {
      type: String,
      required: false,
      default: 'Loading'
    }
  },
  data() {
    return {
      // Always set dialog to true because it needs to be present on the page to handle events without errors
      showDialog: true
    };
  }
};
</script>
