import { computed } from 'vue';
import { useNovaCore } from '@/composables/index';

export const useRecurringAppointmentProps = {
  // Parent Appointment for the recurring series
  recurringParentAppointment: {
    type: Object,
    required: false,
    default() {
      return {};
    }
  }
};

/**
 * @param appointment
 * @returns {{recurringPattern: ComputedRef<{}>}}
 */
export default function useRecurringAppointment(appointment) {
  const novaCore = useNovaCore();

  // An object with weekDays string array, endDate string, and endTime string
  const recurringPattern = computed(() => {
    let pattern = {};
    if (novaCore.isRecurringAppointment(appointment)) {
      const parentAppointment = appointment.recurringParent;

      // NOTE: If the appointment is recurring, it is expected that the
      // parent WILL have a recurringPattern. However, the parent appt. gets
      // updated AFTER childs are created, so there is a quick point in time
      // where it may not be filled yet.
      if (parentAppointment.recurringPattern) {
        pattern = novaCore.getReadableRecurringPatternParts(
          parentAppointment.recurringPattern,
          parentAppointment.start,
          appointment.dock.warehouse.timezone
        );
      }
    }
    return pattern;
  });

  return {
    recurringPattern
  };
}
