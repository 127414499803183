var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-base",
    [
      _c(
        "template",
        { slot: "form" },
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-container",
                [
                  _c("p", [
                    _vm._v("These tags are visible to internal users only"),
                  ]),
                  _c("tag-manager", {
                    attrs: { small: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.localTags,
                      callback: function ($$v) {
                        _vm.localTags = $$v
                      },
                      expression: "localTags",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "form-actions" },
        [
          _c("action-group", {
            attrs: {
              "confirm-icon": "update",
              "confirm-label": "Update Org Tags",
            },
            on: { cancel: _vm.cancel, confirm: _vm.submit },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }