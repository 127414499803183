<template>
  <div>
    <v-card v-if="!showWarehouseGroupForm">
      <v-card-title>
        <v-row align="center" class="search-field">
          <v-col md="6">
            <text-field
              class="mb-5"
              v-model="filters.searchStr"
              append-icon="mdi-magnify"
              label="Search warehouse groups"
              single-line></text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-tooltip top :disabled="$rolePermissions.canCreateWarehouseGroup">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <primary-button
                  :disabled="!$rolePermissions.canCreateWarehouseGroup"
                  before-icon="plus"
                  large
                  class="mr-2"
                  @click="showCreateDialog">
                  Create Warehouse Group
                </primary-button>
              </div>
            </template>
            <span>Create Warehouse Group</span>
          </v-tooltip>
        </v-row>
      </v-card-title>
      <v-data-table
        class="warehouse-group-list"
        :headers="rowHeaders"
        :items="$warehouseGroups"
        :loading="loading"
        :server-items-length="$warehouseGroupsPagination.total"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="footerProps"
        :options.sync="options">
        <template v-slot:item.name="{ item }">
          {{ item.name }}
          <copy-content :content="item.name"></copy-content>
        </template>

        <template v-slot:item.warehouses="{ item }">
          <template
            v-for="(warehouse, index) in item.warehouses.slice(0, maxWarehousesPreview + 1)">
            <router-link
              v-if="!maxWarehousesPreviewReached(index)"
              :key="index"
              :to="{ name: 'warehouses.details.general', params: { warehouseId: warehouse.id } }">
              {{ warehouse.name + getSeparator(index, item.warehouses.length) }}
            </router-link>
            <span :key="'ellipsis' + index" v-else>...</span>
          </template>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="row-actions">
            <icon-tooltip-button
              :disabled="!$rolePermissions.canUpdateWarehouseGroup"
              :id="item.id"
              tooltip="Edit Warehouse Group"
              size="large"
              icon-class="mr-2"
              color="secondary"
              @click="showEditDialog(item)"
              icon="pencil"></icon-tooltip-button>

            <audit-log-entity-icon-button
              :item="item"
              :timezone="item?.timezone ?? 'UTC'"
              :entity-type="
                novaCore.DatabaseEntities.WarehouseGroup
              "></audit-log-entity-icon-button>

            <icon-tooltip-button
              :disabled="!$rolePermissions.canDeleteWarehouseGroup"
              @click="showDeleteWarehouseGroupConfirmation(item)"
              size="large"
              tooltip="Delete Warehouse Group"
              delete></icon-tooltip-button>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-card v-else>
      <v-card-title>
        <div>
          <span class="text-uppercase text-caption grey--text text--darken-2">
            {{ getTitle() }}
          </span>
          <br />
          <span>Warehouse Group Details</span>
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="closeEditOrCreateDialog">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <warehouse-group-form
          ref="saveWarehouseGroup"
          @close="closeEditOrCreateDialog"
          :warehouseGroup="editingWarehouseGroup"></warehouse-group-form>
      </v-card-text>
    </v-card>

    <confirm
      :should-show="showDeleteConfirm"
      is-manual-mode
      persistent
      v-if="showDeleteConfirm"
      @result="handleDeleteResult"
      icon="mdi-delete-forever"
      delete-confirmation
      title="Delete this Warehouse Group?"
      :width="650"
      :loading="loading"
      :confirmation-input-text="deleteConfirmationInputText"
      :entity-name="deletingWarehouseGroup.name"
      button-true-text="YES, DELETE"
      button-true-color="error"
      color="error">
      <template v-slot:message>
        <v-skeleton-loader v-if="loading" type="list-item-three-line"></v-skeleton-loader>
      </template>
    </confirm>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],
  props: {},
  computed: {
    maxWarehousesPreview() {
      return 5;
    }
  },
  data() {
    return {
      loading: false,
      showWarehouseGroupForm: false,
      showDeleteConfirm: false,
      deleteConfirmationInputText: 'YES, I AM AWARE',
      headers: [
        { text: 'Name', value: 'name', searchable: true },
        { text: 'Warehouses', value: 'warehouses', sortable: false, searchable: true },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
      ],
      deletingWarehouseGroup: null,
      sortBy: ['name'],
      searchFields: ['name', 'warehouses.name']
    };
  },
  mounted() {
    this.$eventHub.$on(
      ['create-WarehouseGroup', 'update-WarehouseGroup', 'delete-WarehouseGroup'],
      this.getData
    );
  },
  beforeDestroy() {
    this.$eventHub.$off(
      ['create-WarehouseGroup', 'update-WarehouseGroup', 'delete-WarehouseGroup'],
      this.getData
    );
  },
  methods: {
    /**
     * Get warehouse group data
     * @public
     */
    async getData() {
      try {
        this.loading = true;
        await this.$store.dispatch('WarehouseGroups/getWarehouseGroups', {
          filters: this.getQuery()
        });
      } finally {
        this.loading = false;
      }
    },
    getTitle() {
      return this.editingWarehouseGroup ? 'edit warehouse group' : 'create warehouse group';
    },
    showEditDialog(warehouseGroup) {
      this.editingWarehouseGroup = warehouseGroup;
      this.showWarehouseGroupForm = true;
    },
    showCreateDialog() {
      this.editingWarehouseGroup = null;
      this.showWarehouseGroupForm = true;
    },
    async closeEditOrCreateDialog() {
      if (
        this.showWarehouseGroupForm &&
        this.$route.meta.isDirty &&
        !(await this.checkDirtyConfirmation())
      ) {
        return;
      }
      this.editingWarehouseGroup = null;
      this.showWarehouseGroupForm = false;
    },
    async showDeleteWarehouseGroupConfirmation(warehouseGroup) {
      this.deletingWarehouseGroup = warehouseGroup;
      this.showDeleteConfirm = true;
    },
    async handleDeleteResult(confirmed) {
      if (confirmed) {
        this.loading = true;
        await this.services.warehouseGroup.deleteWarehouseGroup(this.deletingWarehouseGroup);
        this.loading = false;
      }
      this.deletingWarehouseGroup = null;
      this.showDeleteConfirm = false;
    },
    getSeparator(index, warehousesLength) {
      return index < warehousesLength - 1 ? ', ' : '';
    },
    maxWarehousesPreviewReached(index) {
      return index >= this.maxWarehousesPreview;
    }
  },
  watch: {}
};
</script>

<style>
.v-data-table__selected {
  background-color: #f24848 !important;
}
</style>
