import _ from 'lodash';
import { ref, computed, onMounted } from 'vue';

import { useStore } from '@/composables';
import * as novaCore from '@satellite/../nova/core';

const initialFilters = { searchStr: '', page: 1, limit: 15, sort: 'email,ASC' };
const searchableFields = ['firstName', 'lastName', 'email', 'company.name', 'phone'];
const searchFieldsToSplit = ['firstName', 'lastName'];

export default function useCarriers({ prefetch = false } = {}) {
  const store = useStore();
  const isLoading = ref(false);
  const filters = ref(initialFilters);
  const carriers = computed(() => _.get(store, 'state.Carriers.carriers'));
  const params = computed(() => {
    const filters_ = filters.value;
    const $or = novaCore.searchStringToQuery(filters_.searchStr, {
      searchableFields,
      searchFieldsToSplit
    });
    return { ...filters_, s: { ...filters_.s, $or } };
  });
  const create = carrier => store.dispatch('Carriers/createCarrier', carrier);
  const fetch = async () => {
    isLoading.value = true;
    try {
      await store.dispatch('Carriers/getCarriers', params.value);
    } catch (error) {
      console.error(`Failed to fetch carriers: ${error}`);
    } finally {
      isLoading.value = false;
    }
  };
  const debouncedFetch = _.debounce(fetch, 350);
  const updateSearchInput = value => {
    const searchStr = value ?? '';
    filters.value.searchStr = searchStr.trim();
    debouncedFetch();
  };

  onMounted(() => {
    if (prefetch && !isLoading.value) {
      fetch();
    }
  });

  return {
    carriersRef: carriers,
    isLoadingRef: isLoading,
    filtersRef: filters,
    fetch,
    create,
    updateSearchInput
  };
}
