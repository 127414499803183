var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _vm._v("Forms required for the drivers arrival and departure:"),
      ]),
      _c(
        "div",
        { staticClass: "disclaimer font-size-x-small" },
        [
          [
            _vm._v(" Customize your forms at the warehouse "),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "warehouses.details.forms",
                    params: { warehouseId: _vm.appointment.dock.warehouseId },
                  },
                },
              },
              [_vm._v(" Fields and Forms ")]
            ),
            _vm._v(" section. "),
          ],
        ],
        2
      ),
      _vm.isGateManagementEnabled
        ? _c("custom-forms-data-panels", {
            staticClass: "mt-4",
            attrs: {
              editable: false,
              "object-ids": {
                appointmentId: _vm.appointment.id,
                assetvisitId: _vm.appointment.assetVisit?.id,
              },
              appointment: _vm.appointment,
              triggers: _vm.staticTriggers,
              warehouse: _vm.appointment.warehouse,
              timezone: _vm.appointment.warehouse.timezone,
              "military-time-enabled": _vm.$isMilitaryTimeEnabled(
                _vm.appointment.warehouse
              ),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "additional-disclaimer",
                  fn: function ({ trigger, lastChangedDateTimesInTimezone }) {
                    return [
                      !lastChangedDateTimesInTimezone[trigger?.id] &&
                      !_vm.appointment.assetVisit?.id
                        ? _c(
                            "span",
                            { staticClass: "text--color-text-disabled" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.novaCore.CustomFormsFeaturesData[
                                      trigger?.feature
                                    ]?.title?.split(":")[0]
                                  ) +
                                  " pending "
                              ),
                            ]
                          )
                        : !lastChangedDateTimesInTimezone[trigger?.id] &&
                          _vm.appointment.assetVisit?.id &&
                          trigger?.id !== _vm.assetDetailsTrigger?.id
                        ? _c(
                            "span",
                            { staticClass: "text--color-text-disabled" },
                            [_vm._v(" No appointment details provided ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                _vm.appointment.assetVisit?.id
                  ? {
                      key: `additional-form-data-${_vm.assetDetailsTrigger?.id}`,
                      fn: function () {
                        return [
                          _c("p", { staticClass: "mb-0" }, [
                            _c("strong", { staticClass: "mr-2" }, [
                              _vm._v("Phone:"),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.appointment.assetVisit.phone)),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          })
        : _c(
            "v-expansion-panels",
            { attrs: { flat: "", multiple: "" } },
            [
              _c("expansion-panel", {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v("Unlock Driver's Self Check-In")]
                    },
                    proxy: true,
                  },
                  {
                    key: "header-right",
                    fn: function () {
                      return [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-lock"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "d-flex" }, [
                          _c("div", { staticClass: "blurred" }, [
                            _c("div", { staticClass: "field-value" }, [
                              _c("strong", [_vm._v("Driver's Name")]),
                              _c("div", [_vm._v("Alex Johnson")]),
                            ]),
                            _c("div", { staticClass: "field-value" }, [
                              _c("strong", [_vm._v("Driver's Phone Number")]),
                              _c("div", [_vm._v("+1 (555) 234-5678")]),
                            ]),
                            _c("div", { staticClass: "field-value" }, [
                              _c("strong", [_vm._v("Trucking Company")]),
                              _c("div", [_vm._v("Swiftlane Logistics")]),
                            ]),
                            _c("div", { staticClass: "field-value" }, [
                              _c("strong", [_vm._v("Trailer Number")]),
                              _c("div", [_vm._v("TR-4567")]),
                            ]),
                            _c("div", { staticClass: "field-value" }, [
                              _c("strong", [_vm._v("Fuel Type")]),
                              _c("div", [_vm._v("Diesel")]),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-1 justify-center align-center",
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "ad-card",
                                  attrs: { width: "330", elevation: "0" },
                                },
                                [
                                  _c("v-card-title", { staticClass: "pa-0" }, [
                                    _c(
                                      "p",
                                      { staticClass: "text-break mb-2" },
                                      [
                                        _vm._v(" Upgrade "),
                                        _c("strong", [
                                          _vm._v("Gate Management"),
                                        ]),
                                        _vm._v(" to gain access to: "),
                                      ]
                                    ),
                                  ]),
                                  _c("v-card-text", [
                                    _c(
                                      "p",
                                      { staticClass: "mb-1" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                            },
                                          },
                                          [_vm._v("mdi-check-circle-outline")]
                                        ),
                                        _vm._v(" Fast "),
                                        _c("strong", [
                                          _vm._v("self-check in for drivers"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "mb-1" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                            },
                                          },
                                          [_vm._v("mdi-check-circle-outline")]
                                        ),
                                        _c("strong", [
                                          _vm._v("Real-time alerts"),
                                        ]),
                                        _vm._v(" for all arrivals "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "mb-1" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                            },
                                          },
                                          [_vm._v("mdi-check-circle-outline")]
                                        ),
                                        _vm._v(" In-app "),
                                        _c("strong", [
                                          _vm._v("SMS chat with drivers"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "mb-1" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                            },
                                          },
                                          [_vm._v("mdi-check-circle-outline")]
                                        ),
                                        _vm._v(" Management of "),
                                        _c("strong", [
                                          _vm._v("unplanned arrivals"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "mb-1" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                            },
                                          },
                                          [_vm._v("mdi-check-circle-outline")]
                                        ),
                                        _c("strong", [
                                          _vm._v("Live Yard View"),
                                        ]),
                                        _vm._v(" for assigning assets "),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-card-actions", [
                                    _c(
                                      "div",
                                      { staticClass: "text-center full-width" },
                                      [
                                        _c(
                                          "p",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "small secondary",
                                                attrs: {
                                                  target: "_blank",
                                                  rel: "noopener",
                                                  href: _vm.novaCore
                                                    .GATE_MANAGEMENT_SALES_URL,
                                                },
                                                on: {
                                                  click: _vm.handleGMCTAClick,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "white--text",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: { small: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-open-in-new"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" Learn More "),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("p", [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleGMRequestClick()
                                                },
                                              },
                                            },
                                            [_vm._v("Request a trial")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
      _c("custom-forms-data-panels", {
        ref: "editableFormsPanel",
        attrs: {
          editable: _vm.isCheckoutEditable,
          "object-ids": {
            appointmentId: _vm.appointment.id,
            assetvisitId: _vm.appointment.assetVisit?.id,
          },
          appointment: _vm.appointment,
          triggers: _vm.editableTriggers,
          warehouse: _vm.appointment.warehouse,
          timezone: _vm.appointment.warehouse.timezone,
          "military-time-enabled": _vm.$isMilitaryTimeEnabled(
            _vm.appointment.warehouse
          ),
        },
        scopedSlots: _vm._u([
          {
            key: "additional-disclaimer",
            fn: function ({ trigger, lastChangedDateTimesInTimezone }) {
              return [
                !lastChangedDateTimesInTimezone[trigger?.id]
                  ? _c("span", { staticClass: "text--color-text-disabled" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.novaCore.CustomFormsFeaturesData[
                              trigger?.feature
                            ]?.title?.split(":")[0]
                          ) +
                          " pending "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      !_vm.hasActiveTriggers
        ? _c(
            "v-alert",
            {
              staticClass: "mt-4",
              attrs: { border: "left", "colored-border": "", color: "warning" },
            },
            [_vm._v(" No Gate Management forms have been enabled. ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }