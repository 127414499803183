var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Clone Dock",
          scrollable: true,
          "max-width": "450px",
          width: "450px",
          "has-dialog-actions": "",
          "loading-message": "Cloning Dock...",
        },
        on: { close: _vm.close, confirm: _vm.submit },
        nativeOn: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.submit.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    "before-icon": "content-copy",
                                    large: "",
                                  },
                                },
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            )
                          ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("form-base", {
                    staticClass: "px-2",
                    attrs: { "hide-required-legend": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "form",
                        fn: function () {
                          return [
                            _c(
                              "v-form",
                              { ref: "form" },
                              [
                                _c("span", [
                                  _vm._v(" Cloning "),
                                  _c("strong", [_vm._v(_vm._s(_vm.dockName))]),
                                ]),
                                _c("required-text-field", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    "validate-on-blur": "",
                                    rules:
                                      _vm.$validator.rules.required(
                                        "Dock Name"
                                      ),
                                    type: "text",
                                    "hide-details": "auto",
                                    label: "New Dock Name",
                                  },
                                  nativeOn: {
                                    keydown: [
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.submit.apply(null, arguments)
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                      },
                                    ],
                                  },
                                  model: {
                                    value: _vm.newDockName,
                                    callback: function ($$v) {
                                      _vm.newDockName = $$v
                                    },
                                    expression: "newDockName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "form-actions",
                        fn: function () {
                          return [
                            _c("action-group", {
                              attrs: {
                                "disable-confirm": !_vm.newDockName,
                                "confirm-icon": "content-copy",
                                "confirm-label": "Clone Dock",
                              },
                              on: { cancel: _vm.close, confirm: _vm.submit },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }