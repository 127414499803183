var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        staticClass: "mx-auto",
        attrs: {
          header: "Add new Carrier Contact",
          loading: false,
          scrollable: false,
          "has-dialog-actions": "",
          "max-width": "50%",
          width: "50%",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            disabled: _vm.$rolePermissions.canCreateAppointment,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm.$scopedSlots.activator
                                          ? _vm._t(
                                              "activator",
                                              null,
                                              null,
                                              slotProps
                                            )
                                          : _c(
                                              "primary-button",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$rolePermissions
                                                          .canCreateCarrier,
                                                      "before-icon": "plus",
                                                      large: "",
                                                      "data-testid":
                                                        "carrier-list-create",
                                                    },
                                                  },
                                                  "primary-button",
                                                  slotProps.attrs,
                                                  false
                                                ),
                                                slotProps.on
                                              ),
                                              [
                                                _vm._v(
                                                  " Create Carrier Contact "
                                                ),
                                              ]
                                            ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.novaCore.getRoleActionError())),
                          ]),
                        ]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("create-carrier-form", {
                    ref: "createCarrierForm",
                    attrs: { "entry-point": _vm.entryPoint },
                    on: { close: _vm.close, save: _vm.handleCarrierSave },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }