<template>
  <new-terms-dialog
    :loading="loading"
    :showDialog="showDialog"
    @confirm="confirm"
    v-if="showDialog"></new-terms-dialog>
</template>
<script>
export default {
  props: {
    isConfirmationNeeded: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    showDialog() {
      if (this.isConfirmationNeeded) {
        return true;
      }
      return false;
    }
  },
  methods: {
    async confirm() {
      this.loading = true;
      await axios
        .post('user/accept-tc')
        .then(() => {
          this.$store.dispatch('Auth/getMe');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
