var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "py-2 px-4 d-flex flex-column address-card my-6",
      attrs: { flat: "" },
    },
    [
      _vm.label
        ? _c("span", { staticClass: "font-weight-bold mb-2" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _c("span", [_vm._v(_vm._s(_vm.street))]),
      _c("span", [_vm._v(_vm._s(_vm.city) + ", " + _vm._s(_vm.state))]),
      _c("span", [
        _vm._v(" " + _vm._s(_vm.zip) + " "),
        _vm.country
          ? _c("span", [_vm._v(", " + _vm._s(_vm.country))])
          : _vm._e(),
      ]),
      _vm.timezone
        ? _c("span", [
            _c("strong", [_vm._v("Timezone:")]),
            _vm._v(" " + _vm._s(_vm.timezone) + " "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }