var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 pt-5", attrs: { id: "custom-tags-form" } },
    [
      !_vm.readOnly
        ? [
            _c("p", [
              _vm._v(
                " Create a list of default appointment tags to select from. You can also enable notifications for when a tag is added to an appointment ("
              ),
              _c("span", { staticClass: "caption" }, [
                _vm._v(_vm._s(_vm.maxTagsPerOrg) + " options maximum"),
              ]),
              _vm._v(") "),
            ]),
            _c(
              "v-form",
              {
                ref: "customTagsForm",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
                nativeOn: {
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.stopPropagation()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.addTag.apply(null, arguments)
                    },
                  ],
                },
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "8" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            disabled: _vm.readOnly,
                            "hide-details": "",
                            label: "Type tag label...",
                          },
                          model: {
                            value: _vm.newTag.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.newTag, "name", $$v)
                            },
                            expression: "newTag.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "2" } },
                      [
                        !_vm.readOnly
                          ? [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "v-label v-label--active theme--light",
                                },
                                [_c("small", [_vm._v("Tag Color")])]
                              ),
                              _c("color-picker", {
                                attrs: {
                                  iconStyles: { height: "25px", width: "25px" },
                                  "text-color": _vm.getTextColor(
                                    _vm.newTag.color
                                  ),
                                  "header-text":
                                    _vm.newTag.name || "Custom Tag Color",
                                  swatches: _vm.swatches,
                                },
                                model: {
                                  value: _vm.newTag.color,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newTag, "color", $$v)
                                  },
                                  expression: "newTag.color",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-end",
                        attrs: { cols: "2" },
                      },
                      [
                        _c(
                          "primary-button",
                          {
                            staticClass: "mt-4",
                            attrs: { disabled: !_vm.newTag.name },
                            on: { click: _vm.addTag },
                          },
                          [_vm._v(" Add ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("div", { class: { "mt-8": !_vm.readOnly } }, [
        _vm.filteredTags.length
          ? _c(
              "div",
              { class: { "d-flex flex-row": _vm.readOnly } },
              [
                _vm._l(_vm.filteredTags, function (tag) {
                  return _c(
                    "div",
                    {
                      key: tag.text,
                      staticClass: "d-flex tag-row align-center",
                      class: { "pr-2": !_vm.readOnly },
                    },
                    [
                      _vm.editingTag.name !== tag.name || _vm.readOnly
                        ? _c(
                            "v-chip",
                            {
                              class: {
                                "ma-2": !_vm.readOnly,
                                "mr-1": _vm.readOnly,
                              },
                              attrs: {
                                color: tag.color,
                                "text-color": tag.textColor,
                                small: "",
                                disabled: _vm.areTagActionsDisabled(tag),
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-weight-medium" },
                                [_vm._v(_vm._s(tag.name))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.readOnly
                        ? [
                            _vm.editingTag.name === tag.name
                              ? _c("v-text-field", {
                                  ref: `tagNameField-${tag.name}`,
                                  refInFor: true,
                                  staticClass: "tag-editing-text-field mt-0",
                                  attrs: {
                                    solo: "",
                                    dense: "",
                                    flat: "",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.editingTag.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editingTag, "name", $$v)
                                    },
                                    expression: "editingTag.name",
                                  },
                                })
                              : _vm._e(),
                            _c("v-spacer"),
                            _vm.editingTag.name !== tag.name
                              ? _c("icon-tooltip-button", {
                                  attrs: {
                                    tooltip:
                                      _vm.makeNotificationIconTooltipText(tag),
                                    size: "large",
                                    disabled:
                                      _vm.areTagActionsDisabled(tag) ||
                                      _vm.readOnly ||
                                      _vm.areTaggedNotificationsDisabled(),
                                    "icon-class": "mr-2 custom-tag-toggle",
                                    color: tag.notificationEnabled ? "" : "red",
                                    icon:
                                      tag.notificationEnabled &&
                                      !_vm.areTaggedNotificationsDisabled()
                                        ? "bell-ring-outline"
                                        : "bell-off-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleTagNotification(tag)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.editingTag.name === tag.name
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { "x-small": "", text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.editingTag = {}
                                      },
                                    },
                                  },
                                  [_vm._v(" Nevermind ")]
                                )
                              : _vm._e(),
                            _vm.editingTag.name === tag.name
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { "x-small": "", text: "" },
                                    on: { click: _vm.updateTag },
                                  },
                                  [_vm._v(" Save ")]
                                )
                              : _vm._e(),
                            _vm.editingTag.name === tag.name
                              ? _c("color-picker", {
                                  attrs: {
                                    title: "Tag color",
                                    "text-color": _vm.getTextColor(
                                      _vm.editingTag.color
                                    ),
                                    "header-text":
                                      _vm.editingTag.name || "Custom Tag Color",
                                    "icon-styles": {
                                      marginRight: "10px",
                                      width: "15px",
                                      height: "15px",
                                    },
                                    "icon-height": "15px",
                                    "icon-width": "15px",
                                    swatches: _vm.swatches,
                                  },
                                  model: {
                                    value: _vm.editingTag.color,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editingTag, "color", $$v)
                                    },
                                    expression: "editingTag.color",
                                  },
                                })
                              : _vm._e(),
                            _vm.editingTag.name === tag.name
                              ? _c("icon-tooltip-button", {
                                  attrs: {
                                    tooltip: "Delete Tag",
                                    size: "large",
                                    color: "red",
                                    icon: "mdi-delete-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTag(tag)
                                    },
                                  },
                                })
                              : _c("icon-tooltip-button", {
                                  attrs: {
                                    tooltip: _vm.readOnly
                                      ? _vm.novaCore.getRoleActionError()
                                      : "Edit Tag",
                                    size: "large",
                                    disabled:
                                      _vm.areTagActionsDisabled(tag) ||
                                      _vm.readOnly,
                                    icon: "mdi-pencil",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setEditingTag(tag)
                                    },
                                  },
                                }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                _vm.isShowMoreVisible
                  ? _c(
                      "v-btn",
                      {
                        ref: "showMoreButton",
                        staticClass: "float-right mt-3",
                        attrs: { dense: "", solo: "" },
                        on: { click: _vm.showMoreTags },
                      },
                      [_vm._v(" Show more... ")]
                    )
                  : _vm._e(),
              ],
              2
            )
          : Boolean(_vm.newTag?.name)
          ? _c("div", { staticClass: "py-8 text-center text--disabled" }, [
              _vm._v(" No existing tags with this name "),
            ])
          : _c("div", { staticClass: "py-8 text-center text--disabled" }, [
              _vm._v("No default tags added"),
            ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }