var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        attrs: {
          loading: false,
          "has-dialog-actions": "",
          header: "Edit reserve",
          "loading-message": "Updating reserve...",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.editReserveForm.submit()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("edit-reserve-form", {
                  ref: "editReserveForm",
                  attrs: { reserve: _vm.reserve },
                  on: { close: _vm.close },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }