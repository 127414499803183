<template>
  <form-base>
    <template #form>
      <v-form ref="form">
        <v-autocomplete
          v-if="!localFormField.field"
          label="Select a base field"
          v-model="localFormField.fieldId"
          prepend-icon="mdi-text"
          required
          :items="fields"
          :item-text="f => `${f.label} - ${f.description}`"
          item-value="id">
          <template v-slot:selection="{ item }">
            {{ item.label }}
          </template>
        </v-autocomplete>

        <v-card v-if="localFormField.field" elevation="1">
          <v-card-text class="pa-0">
            <h3 class="pa-3">
              <v-icon class="mr-2">
                mdi-{{ novaCore.customFieldIcons[localFormField.field.type] }}
              </v-icon>
              {{ localFormField.field.label }}
            </h3>

            <p class="px-3 py-0">{{ localFormField.field.description }}</p>

            <section
              class="px-3 py-2"
              v-if="localFormField.field.extraFields?.dropDownValues?.length > 0">
              <p class="mb-1">
                <strong>Choices:</strong>
              </p>
              <p>- {{ localFormField.field.extraFields.dropDownValues.slice(0, 10).join(', ') }}</p>
            </section>
          </v-card-text>
        </v-card>

        <v-card class="my-4" flat>
          <v-card-text class="mt-4 pa-1">
            <h4>
              <strong class="mr-2">Replace the label on this form:</strong>
              <help-icon-tooltip icon-style-class="grey">
                This will not replace the label on forms that also use this field
              </help-icon-tooltip>
            </h4>
            <v-text-field
              placeholder="Enter a label"
              v-model="localFormField.overrideLabel"></v-text-field>
            <v-switch
              inset
              label="Required for this form?"
              v-model="localFormField.required"></v-switch>
          </v-card-text>
        </v-card>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="$emit('close')"
        confirm-icon="upload"
        confirm-label="Save"
        cancel-label="Nevermind"
        :cancel-icon="null"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
export default {
  props: {
    formField: {
      type: Object,
      required: false,
      id: String,
      field: Object,
      form: Object
    },
    formId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localFormField: {},
      fields: []
    };
  },
  methods: {
    async getAllFields() {
      const params = {};
      const fieldId = this.formField?.field?.id;

      if (fieldId) {
        params.s = { id: fieldId };
      }

      const response = await axios.get('/custom-forms/field', { params });
      this.fields = response?.data?.data || [];
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localFormField);
      }
    }
  },
  watch: {
    'localFormField.fieldId': {
      handler(fieldId) {
        if (fieldId) {
          this.localFormField.field = this.fields.find(f => f.id === fieldId);
        }
      }
    }
  },
  async mounted() {
    await this.getAllFields();
    if (this.formField?.id) {
      this.localFormField = { ...this.formField };
    } else {
      this.localFormField = {
        fieldId: null,
        formId: this.formId
      };
    }
  }
};
</script>
