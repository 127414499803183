/**
 * Calendar View Enum used for calendar view options
 * @type {Readonly<{MONTH: string, WEEK: string, DAY: string}>}
 */
const CalendarViewEnum = Object.freeze({
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month'
});

export default CalendarViewEnum;
