<template>
  <v-checkbox
    @change="value => $emit('input', value)"
    class="mt-0 pt-2 pl-1"
    hide-details
    label="All Time"
    type="checkbox">
  </v-checkbox>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
  },
};
</script>

<style scoped>

</style>
