export default {
  computed: {
    connectionStatus() {
      return this.$websocketConnectionStatus.state;
    },
    connectionStatusColor() {
      switch (this.connectionStatus) {
        case this.sockets.connectionStatuses.CONNECTED:
          return 'success';
        case this.sockets.connectionStatuses.RECONNECTING:
          return 'warning';
        case this.sockets.connectionStatuses.DISCONNECTED:
          return 'error';
        default:
          return 'white';
      }
    },
    connectionStatusIcon() {
      return this.connectionStatus === this.sockets.connectionStatuses.CONNECTED
        ? 'mdi-access-point'
        : 'mdi-access-point-off';
    }
  }
};
