<template>
  <dialog-base v-bind="$props" :key="renderKey" header="Edit Warehouse coordinates" @close="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <template v-slot:body>
      <div>
        <h4>{{ warehouse.name }}</h4>
        <address-display
          :label="null"
          :zip="warehouse.zip"
          :city="warehouse.city"
          :state="warehouse.state"
          :street="warehouse.street"
          :country="warehouse.country" />
      </div>
      <br />
      <form-base>
        <template v-slot:form v-if="geolocation">
          <v-form ref="form">
            <v-text-field
              v-model="geolocation.latitude"
              type="number"
              label="Latitude"
              :rules="[v => (v >= -90 && v <= 90) || 'Enter a valid latitude from -90 to 90.']"
              placeholder="Enter a valid latitude from -90 to 90."></v-text-field>
            <v-text-field
              v-model="geolocation.longitude"
              type="number"
              label="Longitude"
              :rules="[v => (v >= -180 && v <= 180) || 'Enter a valid longitude from -180 to 180.']"
              placeholder="Enter a valid longitude from -180 to 180."></v-text-field>
            <div class="my-6"></div>
          </v-form>
        </template>
        <template #form-actions>
          <action-group
            @cancel="close()"
            confirm-icon="update"
            confirm-label="save"
            @confirm="submit"
            :loading="loading">
            <template v-slot:additional-actions>
              <v-btn v-if="warehouse.geolocation" outlined color="red" @click="disable">
                Disable Check-in
              </v-btn>
            </template>
          </action-group>
        </template>
      </form-base>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    warehouse: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      geolocation: {
        latitude: null,
        longitude: null
      }
    };
  },
  methods: {
    async submit() {
      if (this.geolocation && !this.$refs.form.validate()) {
        this.notify('Invalid coordinates', 'error');
        return;
      }
      try {
        const res = await axios.patch(`warehouse/${this.warehouse.id}/gate-management`, {
          geolocation: this.geolocation
        });
        if (res?.data?.data?.id) {
          this.notify('Warehouse coordinates updated successfully', 'success');
          this.$emit('updated');
        }
      } catch (ex) {
        this.notify('Failed to update warehouse coordinates', 'error');
      } finally {
        this.loading = false;
        this.close();
      }
    },
    async disable() {
      this.geolocation = null;
      return this.submit();
    }
  },
  mounted() {
    this.geolocation.latitude = this.warehouse.geolocation?.latitude;
    this.geolocation.longitude = this.warehouse.geolocation?.longitude;
  }
};
</script>
