<template>
  <div>
    <p class="mt-2">
      Enable to select and order the details that are displayed in each appointment tile on your
      grid.
    </p>

    <v-switch v-model="enabled" color="secondary" @click="onEnabledClicked" dense>
      <template v-slot:label>
        <div :class="{ 'text--disabled': !enabled }">
          <div class="pl-1">
            <span>{{
              enabled ? 'Enabled: showing custom grid' : 'Disabled: showing default grid'
            }}</span>
          </div>
        </div>
      </template>
    </v-switch>

    <p :class="{ 'text--disabled': !enabled }">
      Select and order the details you want to display in each appointment tile:
    </p>

    <v-alert border="left" type="info" v-if="enabled && lastItem"
      >You must keep at least one item selected.</v-alert
    >

    <!-- ACTIVE ITEMS: -->
    <div id="datablock-list">
      <v-checkbox
        v-for="key in config"
        v-model="config"
        :key="key"
        :value="key"
        hide-details
        :disabled="!enabled || lastItem">
        <template v-slot:prepend>
          <v-icon class="handle">mdi-arrow-split-horizontal</v-icon>
        </template>
        <template v-slot:label>
          <grid-tile-data-block-checkbox-label :item="dataBlocks[key]" :disabled="!enabled" />
        </template>
      </v-checkbox>
    </div>

    <!-- INACTIVE ITEMS: -->
    <div>
      <v-checkbox
        v-for="(item, key) in dataBlocks"
        v-model="config"
        :key="key"
        :value="key"
        v-show="!config.includes(key)"
        hide-details
        :disabled="!enabled">
        <template v-slot:prepend>
          <v-icon class="handle" disabled>mdi-arrow-split-horizontal</v-icon>
        </template>
        <template v-slot:label>
          <grid-tile-data-block-checkbox-label :item="item" :disabled="!enabled" />
        </template>
      </v-checkbox>
    </div>

    <p class="mt-8 font-size-small">
      <span class="font-weight-bold">Note:</span> Changes will be applied to past and future
      appointments
    </p>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import { isArray } from 'lodash';

export default {
  name: 'GridTileConfigEditor',
  props: {
    inputEnabled: {
      type: Boolean,
      required: true
    },
    inputConfig: {
      type: Array,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      enabled: false,
      config: [],
      dataBlocks: {
        company_name: {
          title: 'Carrier Company Name'
        },
        start_time: {
          title: 'Start Time'
        },
        tags: {
          title: 'Tags'
        },
        ref_number: {
          title: 'Reference Number'
        },
        conf_number: {
          title: 'Confirmation Number'
        },
        dock_name: {
          title: 'Dock Name',
          note: 'only available for week and month views'
        },
        load_type: {
          title: 'Load Type Name'
        }
      }
    };
  },
  mounted() {
    this.enabled = this.inputEnabled;

    let config = this.novaCore.deepClone(this.inputConfig);

    const fieldsTmplt = this.warehouse?.customApptFieldsTemplate;

    if (isArray(fieldsTmplt)) {
      for (const field of fieldsTmplt) {
        // TODO: For now, we don't allow Doc/MultiDoc custom field types.
        if (
          field.type !== this.novaCore.CustomFieldType.Document &&
          field.type !== this.novaCore.CustomFieldType.MultiDocument
        )
          this.dataBlocks['custom:' + field.label] = {
            title: field.label,
            note: 'custom field'
          };
      }

      // Remove custom field items from current grid config if they don't exist
      // in the warehouse's custom fields template:
      config = config.filter(item => {
        if (item.startsWith('custom:')) {
          return item in this.dataBlocks;
        } else {
          return true;
        }
      });
    }

    this.config = config;

    setTimeout(() => this.initSortable());
  },
  computed: {
    lastItem: function () {
      return this.config.length === 1;
    }
  },
  methods: {
    initSortable() {
      const _this = this;
      const container = document.querySelector('#datablock-list');
      if (!container) {
        return;
      }

      Sortable.create(container, {
        handle: '.handle',
        animation: 150,
        filter: '.filtered',
        ghostClass: 'ghost-item',
        onEnd({ oldIndex, newIndex }) {
          const movedItem = _this.config.splice(oldIndex, 1)[0];
          _this.config.splice(newIndex, 0, movedItem);
        }
      });
    },
    onEnabledClicked() {
      if (this.enabled) {
        // If the user just switched on the new system, and nothing is selected in the config,
        // then just go ahead and select everything. This way they start out with "all the things".
        if (this.config.length === 0) {
          for (const key of Object.keys(this.dataBlocks)) {
            this.config.push(key);
          }
        }
      }
    }
  },
  watch: {
    config: function (val) {
      this.$emit('configUpdated', this.novaCore.deepClone(val));
    },
    enabled: function (val) {
      this.$emit('enabledUpdated', this.novaCore.deepClone(val));
    }
  }
};
</script>
