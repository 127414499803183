<template>
  <div class="d-flex align-center justify-center full-height flex-column">
    <div class="icon-wrapper">
      <v-icon x-large :color="iconBgColor">{{ formattedIcon }}</v-icon>
    </div>
    <span class="message-header">{{ messageHeader }}</span>
    <span class="message">{{ message }}</span>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    messageHeader: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    iconBgColor: {
      type: String,
      required: false,
      default: '#9E9E9E'
    }
  },
  setup(props) {
    const formattedIcon = computed(() =>
      !props.icon.includes('mdi-') ? `mdi-${props.icon}` : props.icon
    );

    return {
      formattedIcon
    };
  }
};
</script>

<style scoped lang="scss">
.icon-wrapper {
  background-color: $color-neutral-20;
  height: 96px;
  width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 16px;
}

.message-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.message {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  width: 320px;
  text-align: center;
}
</style>
