<template>
  <div class="row-actions">
    <users-list-dialog :org-id="org.id" :header-text="`${org.name} Users`">
      <template v-slot:activator="{ on, attrs }">
        <icon-tooltip-button
          size="large"
          color="light"
          v-bind="attrs"
          v-on="on"
          tooltip="View users"
          icon="mdi-account-group"></icon-tooltip-button>
      </template>
    </users-list-dialog>

    <v-menu offset-y :ref="`contextMenu-${org.id}`">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-on="on" v-bind="attrs" size="24" class="ml-2">mdi-dots-horizontal</v-icon>
      </template>
      <v-list dense>
        <v-subheader class="ml-2">ORG MANAGEMENT</v-subheader>

        <edit-org-billing-dialog :org="org" @close="closeContextMenu(org.id)">
          <template v-slot:activator="{ attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon class="mr-1">
                <v-icon small>mdi-pencil-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Edit org external billing ID</v-list-item-title>
            </v-list-item>
          </template>
        </edit-org-billing-dialog>

        <edit-org-expires-dialog :org="org" @close="closeContextMenu(org.id)">
          <template v-slot:activator="{ attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon class="mr-1">
                <v-icon small>mdi-calendar</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Edit org expiration date</v-list-item-title>
            </v-list-item>
          </template>
        </edit-org-expires-dialog>

        <settings-list-dialog :settings="org.settings || {}">
          <template v-slot:activator="slotProps">
            <v-list-item :disabled="!org.settings" v-bind="slotProps.attrs" v-on="slotProps.on">
              <v-list-item-icon class="mr-1">
                <v-icon small>mdi-eye</v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                {{ org.settings ? 'View org settings' : 'Org has not configured any settings' }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </settings-list-dialog>

        <edit-org-type-dialog :org="org" @close="closeContextMenu(org.id)">
          <template v-slot:activator="{ attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon class="mr-1">
                <v-icon small>mdi-shape</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Change Org Type</v-list-item-title>
            </v-list-item>
          </template>
        </edit-org-type-dialog>

        <edit-org-tags-dialog :org="org" @close="closeContextMenu(org.id)">
          <template v-slot:activator="{ attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon class="mr-1">
                <v-icon small>mdi-tag</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Manage Tags</v-list-item-title>
            </v-list-item>
          </template>
        </edit-org-tags-dialog>

        <custom-forms-features-dialog :org="org" @close="closeContextMenu(org.id)">
          <template v-slot:activator="{ attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon class="mr-1">
                <v-icon small>mdi-table-plus</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Features Setup</v-list-item-title>
            </v-list-item>
          </template>
        </custom-forms-features-dialog>

        <v-divider class="my-3"></v-divider>

        <v-subheader class="ml-2">
          ORG STATUS (Toggle {{ org.isActive ? 'off to deactivate' : 'on to activate' }})
        </v-subheader>

        <v-list-item @click="toggleOrgStatus(org)">
          <v-list-item-icon class="mr-1">
            <v-icon small :color="org.isActive ? 'orange' : 'gray'">
              {{ org.isActive ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off-outline' }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-title>
            {{ org.isActive ? 'Active' : 'Inactive' }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeContextMenu(orgId) {
      const menuRef = `contextMenu-${orgId}`;
      this.$nextTick(() => (this.$refs[menuRef].isActive = false));
    },
    async toggleOrgStatus(org) {
      if (!org?.id) {
        return this.notify('Invalid Org', 'error');
      }

      const apiEndpoint = org.isActive ? 'deactivateOrg' : 'activateOrg';

      const inactivationText = `<p><strong>Making this Org Inactive will:</strong></p>
                      <p class="mb-1">
                        <small>&#8594;</small> All Org users will be able to log in with read-only access
                      </p>
                      <p class="mb-1">
                        <small>&#8594;</small> No Org users will be able to create, update, or delete anything within the account
                      </p>
                      <p class="mb-1">
                        <small>&#8594;</small> All Org users will see a red banner at the top of the screen informing them the account is inactive and that they must contact the Opendock team to request activation
                      </p>
                      <p class="mb-1">
                        <small>&#8594;</small> No Org data is deleted or removed by setting an account inactive
                      </p>
                      <p class="mb-1">
                        <small>&#8594;</small> An Org can be activated by Internal Users following this same process and changing the ORG STATUS toggle from Inactive to Active
                      </p>
                      <br />
                     `;

      const activateText = `
            <p>
                <strong>${org.name}</strong>
            </p>
            <p>
              Making this Org Active will give back to users all previous Create, Update and Delete permissions.
            </p>`;

      this.$confirm(org.isActive ? inactivationText : activateText, {
        width: 700,
        deleteConfirmation: org.isActive,
        icon: org.isActive ? 'mdi-domain-off' : 'mdi-domain',
        entityName: org.name,
        showDeleteWarning: false,
        title: org.isActive ? 'Make this Org Inactive?' : 'Make this Org Active?',
        buttonTrueColor: org.isActive ? 'red' : 'primary',
        buttonTrueText: org.isActive ? 'YES, MAKE THIS ORG INACTIVE' : 'YES, MAKE THIS ORG ACTIVE',
        confirmationInputText: org.name
      }).then(confirmed => {
        if (confirmed) {
          this.$store.dispatch(`Orgs/${apiEndpoint}`, { id: org.id }).then(res => {
            this.notify(res?.data?.message, 'success');
            this.$emit('updated');
          });
        }
      });
    }
  }
};
</script>
