<template>
  <v-row no-gutters class="d-flex gap-4 gap-lg-0">
    <v-col sm="12" md="12" lg="6" class="d-flex flex-row flex-wrap flex-md-nowrap gap-3">
      <warehouse-select
        data-testid="yard-warehouse-select"
        :multi-select="false"
        :show-create-dock-button="false"
        :should-disable-if-no-docks="false"
        hide-icon
        outlined
        :clearable="false"
        :restricted-warehouse-ids="$me.warehouseAccessList"
        :auto-select-by-id="defaultWarehouseId"
        v-model="selectedWarehouse" />
      <yard-view-area-select
        v-if="isGateManagementEnabled"
        data-testid="yard-area-select"></yard-view-area-select>
    </v-col>
    <v-col
      sm="12"
      md="12"
      lg="6"
      class="d-flex justify-lg-end align-center gap-3"
      v-if="isGateManagementEnabled && !isReadOnly">
      <v-btn data-testid="yard-subheader-add-area" text @click="addSpotArea">
        <v-icon small left>mdi-plus</v-icon>
        Area
      </v-btn>

      <!-- TODO: move this into its own component when we actually implement the different view modes -->
      <div class="menu-container" data-testid="yard-view-mode-menu">
        <v-menu offset-y internal-activator close-on-click>
          <template #activator="{ on, attrs }">
            <v-btn
              outlined
              disabled
              color="darken-2"
              v-bind="attrs"
              v-on="on"
              :ripple="false"
              data-testid="grid-view-mode-menu-activator"
              aria-owns="yard-view-view-mode-options">
              <v-icon small data-testid="view-mode-activator" left>
                {{ getViewModeMeta().icon }}
              </v-icon>
              {{ getViewModeMeta().label }}
              <v-icon small class="ml-2" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list
            class="view-mode-options"
            id="yard-view-view-mode-options"
            data-testid="view-mode-options">
            <v-list-item
              class="is-relative z-100"
              v-for="(item, key) in viewModes"
              :key="key"
              @click="viewMode = item">
              <v-list-item-icon>
                <v-icon small>{{ getViewModeMeta(item).icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="typography-button-md">
                  {{ getViewModeMeta(item).label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import WarehouseSelect from '@/components/elements/selects/WarehouseSelect.vue';
import YardViewAreaSelect from '@/modules/yard/components/Selects/YardViewAreaSelect.vue';
import { viewModes } from '@/modules/yard/store';
import { useRouter } from 'vue-router/composables';

export default defineComponent({
  name: 'YardViewPageSubHeader',
  components: { YardViewAreaSelect, WarehouseSelect },
  props: {},
  setup() {
    const yardStore = useYard();
    const router = useRouter();

    const addSpotArea = () =>
      router.push({
        name: 'yard-add-spot-area',
        params: { warehouseId: yardStore.defaultWarehouseIdRef.value }
      });

    const getViewModeMeta = viewMode => {
      let icon = 'mdi-grid';
      let label = 'view';

      switch (viewMode ?? yardStore.viewModeRef.value) {
        case viewModes.grid:
          icon = 'mdi-grid';
          label = `Grid ${label}`;
          break;
        case viewModes.list:
          icon = 'mdi-format-list-checkbox';
          label = `List ${label}`;
          break;
      }
      return { icon, label };
    };

    return {
      addSpotArea,
      getViewModeMeta,
      selectedWarehouse: yardStore.selectedWarehouseRef,
      defaultWarehouseId: yardStore.defaultWarehouseIdRef,
      isReadOnly: yardStore.isReadOnlyRef,
      viewMode: yardStore.viewModeRef,
      isGateManagementEnabled: yardStore.isGateManagementEnabledForSelectedWarehouse,
      viewModes
    };
  }
});
</script>

<style lang="scss" scoped>
// Brace yourself for some overrides; this is related to the view mode menu
.view-mode-options .v-list-item {
  gap: $spacing-2;
  padding: 0 $spacing-4;
}

.v-application--is-ltr .view-mode-options .v-list-item__icon:first-child {
  min-width: unset;
  margin: auto 0;
}
</style>
