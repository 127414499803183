var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "w-100 d-flex flex-column gap-4 justify-center align-center my-10",
      attrs: { "data-testid": "yard-no-areas-message" },
    },
    [
      _c(
        "span",
        { staticClass: "icon-wrapper" },
        [
          _c(
            "v-icon",
            { staticClass: "icon", attrs: { size: "50", color: "#9E9E9E" } },
            [_vm._v("mdi-view-column-outline")]
          ),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "primary-button",
        {
          on: { click: () => _vm.$router.push({ name: "yard-add-spot-area" }) },
        },
        [_vm._v(" Add Area ")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticStyle: { "max-inline-size": "270px", "text-align": "center" } },
      [
        _c("h3", { staticClass: "typography-body-md-bold align-center" }, [
          _vm._v("No yard areas"),
        ]),
        _c("p", { staticClass: "typography-body-sm" }, [
          _vm._v(
            " Add areas to your warehouse yard view or select one from the menu above. "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }