import { isDefined, isEmpty } from 'class-validator';

export default {
  name: 'customFormsDataMixin',
  props: {
    objectId: {
      type: String,
      required: true
    },
    trigger: {
      type: Object,
      required: true
    },
    timezone: {
      type: String,
      required: true
    },
    militaryTimeEnabled: {
      type: Boolean,
      default: false
    },
    prependedData: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      customFormData: []
    };
  },
  computed: {
    hasCustomFormData() {
      return this.customFormData?.length > 0;
    }
  },
  methods: {
    async getCustomFormData() {
      if (this.objectId && this.trigger.id) {
        const response = await axios.get('custom-forms/form-data', {
          params: {
            s: { objectId: this.objectId, triggerId: this.trigger.id },
            limit: 1000
          }
        });

        this.customFormData = response?.data?.data || [];
        this.prependedData.reverse().forEach(data => {
          this.customFormData.unshift(data);
        });
        this.customFormData.forEach(data => {
          data.label = data.formField?.overrideLabel || data.label;
          data.value = this.formattedValue(data);
        });
      }
    },
    formattedValue(field) {
      if (
        field.value === '' ||
        !isDefined(field.value) ||
        isEmpty(field.value) ||
        (Array.isArray(field.value) && field.value.length === 0)
      ) {
        return '--';
      }
      return this.novaCore.getCustomFieldFormattedValue(field, {
        [this.novaCore.CustomFieldType.Document]: { generateLink: true },
        [this.novaCore.CustomFieldType.MultiDocument]: { generateLink: true },
        [this.novaCore.CustomFieldType.Timestamp]: {
          timezone: this.timezone,
          formatAsMilitary: this.militaryTimeEnabled
        }
      });
    },
    getFormattedDate(date) {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        date,
        this.timezone,
        this.novaCore.LuxonDateTimeFormats.DateSlashedDashTimestamp12HourTimeAMPM,
        this.militaryTimeEnabled,
        this.novaCore.LuxonDateTimeFormats.DateSlashedDashTimestamp24HourTime
      );
    }
  }
};
