var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    attrs: {
      "hide-required-legend": true,
      "is-loading": _vm.isCancellingRef,
      "loading-message": "Cancelling appointment ...",
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form", staticClass: "pt-1 px-2" },
              [
                _c("v-textarea", {
                  staticClass: "mt-4",
                  attrs: {
                    dense: "",
                    outlined: "",
                    autofocus: _vm.hasReasonRef,
                    "no-resize": "",
                    name: "cancel_reason",
                    label: "Enter a reason for cancellation",
                    "validate-on-blur": false,
                    rules: _vm.cancelReasonRules,
                  },
                  model: {
                    value: _vm.cancelReasonRef,
                    callback: function ($$v) {
                      _vm.cancelReasonRef = $$v
                    },
                    expression: "cancelReasonRef",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: { "confirm-label": "Yes, Cancel" },
              on: {
                cancel: function ($event) {
                  return _vm.$emit("nevermind")
                },
                confirm: _vm.cancelAppointment,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }