var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-switch",
        _vm._b(
          {
            staticClass: "disable-label-click",
            attrs: {
              "input-value": _vm.value,
              color: "secondary",
              "hide-details": "auto",
              dense: "",
            },
            on: { change: _vm.emitChange },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _c("div", { class: { "text--disabled": !_vm.value } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-center align-center pl-1",
                        },
                        [
                          _c("span", { staticClass: "d-block mr-2" }, [
                            _vm._v(
                              _vm._s(_vm.entity) +
                                " available on the Scheduling Portal"
                            ),
                          ]),
                          _c(
                            "help-icon-tooltip",
                            { attrs: { iconStyleClass: "grey darken-2" } },
                            [_vm._v(" " + _vm._s(_vm.toggleMessage) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "v-switch",
          [_vm.$attrs, _vm.$props],
          false
        )
      ),
      _vm.shouldDisplayDisclaimer
        ? _c(
            "p",
            { staticClass: "pt-2" },
            [
              _vm._v(
                " This will make the " +
                  _vm._s(_vm.entity) +
                  " available on the Scheduling Portal where carriers, dispatchers, and others can create an account and book appointments. "
              ),
              _vm.entity === _vm.novaCore.DatabaseEntities.Warehouse
                ? [
                    _vm._v(
                      " You can share the warehouse's public link with anyone, or preview the booking experience yourself. "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }