<!--
  -- this component is using the new Vue Composition API, this is a step towards our goal
  -- of migrating helios into Vue3. To learn more about it, check:
  -- https://vuejs.org/api/composition-api-setup
  -- https://vuejs.org/guide/extras/composition-api-faq.html
-->
<template>
  <v-dialog
    v-model="modal"
    width="500"
    @keydown.escape="closeModal"
    v-if="isFavorite && !isOrgFavorite">
    <template #activator="{ on, attrs }">
      <v-btn text left v-on="on" v-bind="attrs" class="warehouse-btn text-truncate text-left">
        <span class="warehouse-favorite bold txt-accent">
          {{ favoriteWarehouses.map(({ name }) => name).join(', ') }}
        </span>
      </v-btn>
    </template>
    <v-card class="dialog-box">
      <v-card-title class="dialog-title">
        <span class="headline d-flex">Warehouses this contact is favorited for</span>
        <v-spacer></v-spacer>
        <v-btn class="pa-3" icon light @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="dialog-body">
        <p class="dialog-txt">
          <span class="bold">{{ carrier.fullName }} - {{ company.name }}</span>
          is a favorite carrier contact for:
        </p>
        <ul class="warehouse-list">
          <li v-for="warehouse in favoriteWarehouses" :key="warehouse.id">
            <span class="bold txt-accent">{{ warehouse.name }}</span>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>
  <div class="org-favorite" v-else-if="isOrgFavorite">All</div>
  <div class="not-favorite" v-else>None</div>
</template>

<script>
import { defineComponent } from 'vue';
import useModal from '@/composables/useModal';

export default defineComponent({
  name: 'CarrierContactWarehouseDialog',
  props: {
    favoriteWarehouses: { type: Array, required: true },
    isOrgFavorite: { type: Boolean, default: false },
    isFavorite: { type: Boolean, default: false },
    carrier: { type: Object, required: true },
    company: { type: Object, required: true }
  },
  setup() {
    const { modal, closeModal } = useModal();
    return { modal, closeModal };
  }
});
</script>

<style scoped lang="scss">
.dialog-title {
  padding-bottom: 32px !important;
}

.dialog-txt {
  padding-bottom: 16px !important;
  margin: 0;
}

.headline {
  font-size: 20px !important;
}

.bold {
  font-weight: 600;
}

.txt-accent {
  color: var(--accent-accent-60, #005a87) !important;
}

.warehouse-list {
  list-style-type: none;
  padding: 0;

  li {
    padding: 8px 0;
  }
}

.warehouse-btn {
  display: inline-block;
  font-size: 14px;
  font-weight: 400 !important;
  text-transform: none;
  padding: 0 4px !important;
  max-width: 350px;

  :deep(.v-btn__content) {
    justify-content: flex-start;
  }

  .warehouse-favorite {
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.not-favorite {
  color: $color-text-disabled;
}
</style>
