<template>
  <v-dialog max-width="950" :scrollable="true">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" size="23"> mdi-eye </v-icon>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{ notificationSubject }}</span>
          <v-spacer></v-spacer>
          <v-btn class="pa-3" icon light @click="dialog.value = false"
            ><v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <email-notification-preview
            :notification-key="notificationKey"></email-notification-preview>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    notificationKey: {
      type: String,
      required: true
    },
    notificationTitle: {
      type: String,
      required: false,
      default: 'Appointment Notification'
    }
  },
  data() {
    return {
      notificationHtml: null,
      notificationSubject: null
    };
  },
  async mounted() {
    await axios.get(`/appointment/email-notification-html/${this.notificationKey}`).then(r => {
      this.notificationHtml = r.data.bodyHtml;
      this.notificationSubject = r.data.subject;
    });
  }
};
</script>
