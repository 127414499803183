var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: { "external-activator": true, header: "Connection Status" },
        on: { close: _vm.close },
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("strong", [_vm._v("Status: ")]),
          _c("span", { class: `${_vm.connectionStatusColor}--text` }, [
            _vm._v(
              _vm._s(
                _vm.novaCore.ucWords(_vm.$websocketConnectionStatus.state || "")
              )
            ),
          ]),
          _c("br"),
          _c("strong", [_vm._v("Last Connected At: ")]),
          _c("span", { class: `${_vm.connectionStatusColor}--text` }, [
            _vm._v(_vm._s(_vm.$websocketConnectionStatus.lastConnected)),
          ]),
          _c("br"),
          _c("v-divider", { staticClass: "my-2" }),
          _c("h3", [_vm._v("What are Websockets?")]),
          _c("div", [
            _vm._v(
              " Websockets are connections between an application website and the application server. They facilitate near real-time data transfer, keeping the website's information up-to-date. "
            ),
          ]),
          _c("h3", { staticClass: "pt-3" }, [
            _vm._v("How does Opendock use Websockets?"),
          ]),
          _c("div", [
            _vm._v(
              " Opendock uses Websockets to keep all of your data up-to-date. When a user in your organization creates or updates information in your org it will automatically change for you. For instance when a new appointment is created it will immediately appear on your grid. This eliminates the need to refresh the webpage in order to have the freshest data. "
            ),
          ]),
          _c("h3", { staticClass: "pb-1 pt-3" }, [_vm._v("Troubleshooting")]),
          _c("div", [
            _c("strong", [_vm._v("Connection fails intermittently")]),
            _c("br"),
            _c("p", [
              _vm._v(
                " Sometimes when a new version of Opendock is deployed, Websockets will temporarily disconnect. The system will try to re-establish the connection, but in rare instances this may result in errors. If a new version of Opendock was deployed you should receive a popup that will ask you to refresh the page. The solution in this case is almost always a page refresh. "
              ),
            ]),
            _c("strong", [_vm._v("Connection always fails")]),
            _c("br"),
            _c("p", [
              _vm._v(
                " Websocket connections are sometimes blocked by default on company networks. Please reach out to your network administrator to ensure that connections to "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.config.subspace_url))]),
              _vm._v(
                " are not blocked. They will need to open both ingress and egress (it will be port 443) and open their proxy. "
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }