var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: {
        top: "",
        color: "color-neutral-90",
        "open-delay": "1000",
        transition: "scale-transition",
        "min-width": "500",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "full-height",
                      attrs: {
                        "data-testid": `yard-spot-assigned-${_vm.spot.code}`,
                      },
                    },
                    "div",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "p",
                    {
                      class: {
                        "mb-9": _vm.getSpotIcon,
                        "mb-7": !_vm.getSpotIcon,
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          class: `spot-type-${_vm.spot.type} ${_vm.getLoadTypeDirectionColor}`,
                          attrs: { small: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.getSpotIcon) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("svg-truck", {
                    attrs: {
                      rotation: 90,
                      scale: 1.1,
                      theme: _vm.truckThemeColor,
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("yard-view-spot-tooltip", {
        attrs: { spot: _vm.spot, appointment: _vm.appointment },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }