<template>
  <form-base :hide-required-legend="true">
    <template slot="form">
      <v-form ref="form" @keyup.native.enter="submit">
        <text-field
          :required="true"
          type="text"
          v-model="fields.name"
          :rules="$validator.rules.required('Company Name')"
          label="Company Name"></text-field>
        <text-field
          type="text"
          v-model="fields.scac"
          label="SCAC code"
          :rules="$validator.rules.optionalLengthBetween(2, 4)"></text-field>
      </v-form>
    </template>
    <template slot="form-actions">
      <v-divider class="mt-8"></v-divider>
      <action-group
        @cancel="$emit('close')"
        class="pa-5"
        confirm-icon="upload"
        confirm-label="Save Company"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * Create Carrier Form
 * @displayName Create Carrier Form
 */
export default {
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    },
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fields: {},
      step: 1,
      selectedCompany: null,
      showCompanyDialog: false,
      newCarrier: {}
    };
  },
  methods: {
    /**
     * Submit form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let response = await this.$store.dispatch('Companies/createCompany', this.fields);

      if (response?.data) {
        this.mixpanel.track(this.mixpanel.events.MODULE.COMPANY.CREATED, {
          'Entry Point': this.entryPoint,
          'Carrier Company Name': this.fields.name,
          'SCAC Code': this.fields.scac,
          'Org Name': this.$org.name,
          'Org ID': this.$org.id
        });

        this.$emit('save', response.data.data);
        this.$emit('close');
      }
    },
    cancel() {
      /**
       * Emits close event
       * @event close
       */
      this.$emit('close');
    }
  }
};
</script>
