<template>
  <v-card v-if="ready" class="elevation-0">
    <v-card-text class="pa-1">
      <bar-chart
        title="Last 14 Days Appointment Counts"
        :colors="colors"
        :horizontal="horizontal"
        :data="chartData"></bar-chart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
</template>

<script>
import insightsMixin from '@/components/mixins/insightsMixin';

/**
 * Appointments By Date Range Bar Chart
 * @displayName Appointments By Date Range Bar Chart
 */
export default {
  mixins: [insightsMixin],
  data() {
    return {
      horizontal: false,
      apiEndpoint: 'date',
      initialLookbackDays: 13
    };
  },
  methods: {
    formatData() {
      let sortedData = this.util.sortObjectByKeys(this.rawData);
      this.makeChartData(sortedData);
      this.ready = true;
    },
    formatXAxis(data) {
      return this.novaCore.getFormattedTime(
        data,
        this.novaCore.DateTimeFormats.ShortDayMonthDayYearSlashed
      );
    }
  }
};
</script>
