<template functional>
  <div class="tile-data-block direction-indicator">
    <v-icon x-small class="direction-arrow" v-if="props.direction === 'OUT'">mdi-arrow-left</v-icon
    >{{ props.direction
    }}<v-icon x-small class="direction-arrow" v-if="props.direction === 'IN'"
      >mdi-arrow-right</v-icon
    >
  </div>
</template>

<script>
export default {
  name: 'DirectionIndicator',
  props: {
    direction: {
      type: String,
      required: true
    }
  }
};
</script>

<!-- TODO: For some reason "scoped" breaks things! -->
<style lang="scss">
.direction-indicator {
  border-radius: 3px;
  background-color: white !important;
  color: black;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}

.direction-arrow {
  margin-top: -2px;
}
</style>
