var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-card",
        { attrs: { "data-testid": "yard-spot-single-form" } },
        [
          _c("v-card-title", [
            _c("span", { staticClass: "font-size-medium" }, [
              _vm._v("Add one single spot to your yard area"),
            ]),
          ]),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Spot code",
                            rules: [_vm.validateCode],
                            outlined: "",
                            dense: "",
                            "data-testid": "yard-spot-form-code-input",
                          },
                          model: {
                            value: _vm.value.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "code", $$v)
                            },
                            expression: "value.code",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            items: Object.keys(_vm.spotType).map((k) =>
                              k.toLowerCase()
                            ),
                            label: "Spot type",
                            rules: [_vm.validateType],
                            "data-testid": "yard-spot-form-type-select",
                            outlined: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.value.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "type", $$v)
                            },
                            expression: "value.type",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }