import AppointmentsTVModePage from '@/modules/appointments/AppointmentsTVModePage';
import { UserRole } from '@satellite/../nova/core';
import AppointmentsSearchPage from '@/modules/appointments/components/AppointmentsSearchPage';
import AppointmentsPage from '@/modules/appointments/AppointmentsPage.vue';
import AppointmentsPageMobile from '@/modules/appointments/mobile/AppointmentsPageMobile.vue';
import { getComponentForDevice } from '@/routes';

export default [
  {
    path: '/appointments',
    name: 'appointments',
    component: getComponentForDevice(AppointmentsPage, AppointmentsPageMobile),
    props: true,
    meta: {
      title: 'Appointments',
      requiresAuth: true,
      customHeader: true,
      pageClass: 'app-appointments',
      maxRole: UserRole.OWNER,
      allowMobile: true
    }
  },
  {
    path: '/search/appointments',
    name: 'appointments.search',
    component: AppointmentsSearchPage,
    props: true,
    meta: {
      title: 'Search Appointments',
      requiresAuth: true,
      pageClass: 'app-appointments-search',
      maxRole: UserRole.OWNER
    }
  },
  {
    path: '/appointments/tv',
    name: 'appointmentsTV',
    component: AppointmentsTVModePage,
    props: true,
    meta: {
      title: 'Appointments TV Mode',
      requiresAuth: true,
      customHeader: true,
      pageClass: 'app-appointments-tv',
      hideSidebar: true,
      fullScreen: true,
      maxRole: UserRole.OWNER
    },
    beforeEnter: async (to, _, next) => {
      // This is needed to keep the url query params
      // after a page reload
      let updatedNext = null;
      if (!to.query.warehouseId) {
        updatedNext = {
          ...to,
          query: {
            dockIds: to.params.dockIdsParam,
            warehouseId: to.params.warehouseIdParam
          }
        };
      }
      if (updatedNext) {
        next(updatedNext);
      } else {
        next();
      }
    }
  }
];
