var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("h4", [
                        _vm._v(
                          " This user has created some reserves and when this user is removed, the reserves will be removed as well. "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("v-spacer", { staticClass: "my-6" }),
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _vm.hasOrgUsersLeft
                        ? _c("h4", [
                            _vm._v(
                              " You can transfer the reserves to another user or just ignore and remove them. If you choose to transfer, past and future reserves will be kept. "
                            ),
                          ])
                        : _vm._e(),
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "If you close this dialog the user will not be deleted."
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("v-spacer", { staticClass: "my-12" }),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _vm.hasOrgUsersLeft
                          ? _c("v-autocomplete", {
                              attrs: {
                                items: _vm.orgUsers,
                                "item-text": "text",
                                "item-value": "value",
                                placeholder: "Select an user",
                                label: "Target User",
                                clearable: "",
                                "open-on-clear": "",
                              },
                              model: {
                                value: _vm.targetUserId,
                                callback: function ($$v) {
                                  _vm.targetUserId = $$v
                                },
                                expression: "targetUserId",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-spacer", { staticClass: "my-12" }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "confirm-icon": "plus",
                "confirm-label": "Transfer to User",
                "cancel-label": "Delete reserves",
                "cancel-color": "red",
                "cancel-icon": "delete-forever",
                "disable-confirm": !_vm.targetUserId,
                hideConfirm: !_vm.hasOrgUsersLeft,
              },
              on: { cancel: _vm.remove, confirm: _vm.transfer },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }