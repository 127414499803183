<template>
  <div>
    <form-base :hide-required-legend="true" data-testid="create-recurring-series-form">
      <template #form>
        <v-form v-if="!hasFailures" class="pt-1 mx-3 inline-fields" ref="form">
          <template v-if="loading">
            <v-progress-linear
              indeterminate
              :loading="loading"
              height="6"
              class="mt-6"></v-progress-linear>
            <h4 class="text-center mt-4 mb-12">Creating Appointments ...</h4>
          </template>
          <template v-else>
            <v-row class="mt-3 mb-6">
              <v-col md="12">
                <strong class="black--text">Repeat every:</strong>
                <weekday-checkbox-group
                  horizontal
                  short-names
                  v-model="checkedDays"></weekday-checkbox-group>
              </v-col>
            </v-row>
            <v-row class="my-3">
              <v-col md="7">
                <strong class="black--text">Ends After:</strong>
                <v-text-field
                  data-testid="weeks-input"
                  dense
                  min="0"
                  :max="maxNumWeeks"
                  hide-details
                  class="mt-2"
                  v-model="numWeeks"
                  :rules="afterRules"
                  type="number"
                  suffix="weeks in a row"></v-text-field>
              </v-col>
            </v-row>
            <p class="font-size-x-small estimated-end-date" v-html="estimatedEndDate"></p>
            <v-row v-if="!novaCore.isReserve(appointment)" class="mt-2">
              <v-col md="6">
                <p>
                  <strong class="black--text">Copy values of</strong>
                  <help-icon-tooltip>
                    Copy the selected fields for all child appointments created in the recurring
                    series
                  </help-icon-tooltip>
                </p>
                <v-checkbox
                  :key="item"
                  v-for="item in recurringExtraFieldsToCopyEnum"
                  :label="formatFieldName(item)"
                  :value="copyFields.includes(item)"
                  @click="() => toggleItem(item)"
                  class="mt-2"
                  hide-details
                  dense></v-checkbox>
              </v-col>
              <v-col md="6">
                <p>
                  <strong class="black--text">Starting appointment status</strong>
                  <help-icon-tooltip>
                    The starting status for all child appointments created in the recurring series
                  </help-icon-tooltip>
                </p>
                <v-radio-group v-model="startingStatus" hide-details v-if="isRequestedStatusSet">
                  <v-radio
                    :value="novaCore.AppointmentStatus.Scheduled"
                    :label="novaCore.AppointmentStatus.Scheduled"></v-radio>
                  <v-radio
                    :value="novaCore.AppointmentStatus.Requested"
                    :label="novaCore.AppointmentStatus.Requested"></v-radio>
                </v-radio-group>
                <p v-else class="text--black">
                  <v-label>Scheduled</v-label>
                </p>
              </v-col>
            </v-row>
          </template>
        </v-form>

        <v-card v-else class="px-7" max-height="100%" elevation="0">
          <v-alert dense border="left" colored-border color="error" class="error lighten-5">
            <strong class="font-size-small">
              Appointments with no availability:
              <span class="error--text text--darken-4">{{ failureCount }}</span>
              / {{ totalAttemptedCount }}
            </strong>
          </v-alert>
          <v-card-text>
            Sorry, we couldn't create the following appointments due to
            <strong class="black--text">no availability on your current schedule.</strong>
            <div class="pt-4">
              <span
                class="font-size-small d-block"
                v-for="(failure, i) in failures"
                :key="`failure-${i}`">
                {{
                  novaCore.transformForUserFriendlyTimezone(
                    novaCore.renderUtcInTimezone(
                      failure.start,
                      appointment.dock.warehouse.timezone
                    ),
                    appointment.dock.warehouse.timezone
                  )
                }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template #form-actions>
        <action-group
          key="recurring-action-create"
          v-if="!loading && !hasFailures"
          confirm-label="Create Recurrence"
          :disable-confirm="!canSubmit"
          @cancel="$emit('close')"
          @confirm="createRecurringSeries"></action-group>
        <action-group
          key="recurring-action-export"
          v-else-if="!loading && hasFailures"
          cancel-label="Got It"
          cancel-icon=""
          hide-confirm
          :is-spacer-visible="false"
          @cancel="$emit('close')">
          <template v-slot:additional-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="exportToExcel">Export List</v-btn>
          </template>
        </action-group>
      </template>
    </form-base>
  </div>
</template>

<script>
import WeekdayCheckboxGroup from '@satellite/components/elements/checkbox/WeekdayCheckboxGroup';
import { utils, writeFile } from 'xlsx';

const endOptionsEnum = Object.freeze({
  ON: 'on',
  AFTER: 'after'
});

export default {
  components: { WeekdayCheckboxGroup },
  props: {
    /**
     * Calendar Event
     */
    appointment: {
      type: Object,
      required: true
    }
  },
  computed: {
    isRequestedStatusSet() {
      return (
        this.appointment.dock.warehouse.settings?.appointmentCreationStatus ===
        this.novaCore.AppointmentStatus.Requested
      );
    },
    orderedCheckedDays() {
      const checkedDays = this.novaCore.deepClone(this.checkedDays);
      const order = momentjs.weekdays().map(day => day.toLowerCase());
      checkedDays.sort(function (a, b) {
        return order.indexOf(a) - order.indexOf(b);
      });
      return checkedDays;
    },
    estimatedEndDate() {
      if (this.checkedDays.length === 0) {
        return '&nbsp;';
      } else {
        const apptStartMoment = momentjs.tz(
          momentjs(this.appointment.start),
          this.appointment.dock.warehouse.timezone
        );
        const latestCheckedDay = momentjs
          .weekdays()
          .indexOf(
            this.novaCore.upperFirst(this.orderedCheckedDays[this.orderedCheckedDays.length - 1])
          );
        const endMoment = apptStartMoment.add(this.numWeeks, 'weeks');
        if (
          endMoment.weekday() >=
          apptStartMoment.clone().add(this.numWeeks).weekday(latestCheckedDay).weekday()
        ) {
          apptStartMoment.add(this.numWeeks).weekday(latestCheckedDay);
        }

        return `Estimated End Date: ${apptStartMoment.format('MMMM D, YYYY')}`;
      }
    },
    recurringExtraFieldsToCopyEnum() {
      return Object.values(this.novaCore.RecurringExtraFieldsToCopyEnum);
    },
    /**
     * Handles disabling of confirmation button
     * @returns {boolean}
     */
    canSubmit() {
      return (
        this.checkedDays.length > 0 &&
        this.numWeeks > 0 &&
        this.numWeeks <= this.novaCore.MAX_RECURRING_APPOINTMENT_WEEKS
      );
    },
    endOptionsEnum() {
      return endOptionsEnum;
    },
    maxNumWeeks() {
      return this.novaCore.MAX_RECURRING_APPOINTMENT_WEEKS;
    },
    afterRules() {
      return this.isAfter
        ? [
            ...this.$validator.rules.required('Number of Weeks'),
            ...this.$validator.rules.numberBetween(1, this.novaCore.MAX_RECURRING_APPOINTMENT_WEEKS)
          ]
        : [];
    },
    formattedCheckedDays() {
      return this.checkedDays.map(day => this.novaCore.upperFirst(day));
    },
    checkedDays: {
      get() {
        return [...new Set(this.checkedDaysProxy)];
      },
      set(val) {
        this.checkedDaysProxy = val;
      }
    },
    hasFailures() {
      return this.failures.length > 0;
    },
    failureCount() {
      return this.failures.length;
    },
    totalAttemptedCount() {
      return this.failureCount + this.successes.length;
    }
  },
  data() {
    return {
      loading: false,
      checkedDaysProxy: [],
      numWeeks: 1,
      successes: [],
      failures: [],
      copyFields: [],
      startingStatus: this.novaCore.AppointmentStatus.Scheduled
    };
  },
  mounted() {
    this.addEventListeners();
  },
  methods: {
    // TODO: Create mixin to handle this functionality?
    addEventListeners() {
      document.querySelectorAll('.disable-label-click').forEach(element => {
        element.querySelector('label').addEventListener('click', this.clickHandler);
      });
    },
    // Using named function here allows the browser to discard exact duplicate event listeners automatically
    clickHandler(e) {
      e.stopPropagation();
    },
    // End of TODO
    async createRecurringSeries() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let numWeeks = this.numWeeks;
        await axios
          .post(`appointment/${this.appointment.id}/recurring`, {
            numWeeks: parseInt(numWeeks),
            weekDays: this.formattedCheckedDays,
            copyFields: this.copyFields,
            startingStatus: this.startingStatus
          })
          .then(({ data: response }) => {
            this.successes = response.data.successes;
            if (response.data.failures.length > 0) {
              this.failures = this.novaCore.sortBy(response.data.failures, 'start');
            } else {
              this.$store.dispatch('Appointments/trackMixpanelEvent', {
                appointment: this.appointment,
                change: 'Recurrence Created'
              });
              this.notify('Recurrence Created Successfully', 'success');
              this.$emit('close');
            }
            if (this.successes.length > 0) {
              this.$store.dispatch('Calendar/refreshEventByAppointmentId', {
                id: this.appointment.id,
                mutation: 'insertEvent'
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    formatFieldName(fieldName) {
      return _.startCase(fieldName);
    },
    toggleItem(item) {
      if (!this.copyFields) {
        this.copyFields = [];
      }
      const existingItemIndex = this.copyFields.findIndex(existingItem => existingItem === item);
      if (existingItemIndex < 0) {
        this.copyFields.push(item);
      } else {
        this.copyFields.splice(existingItemIndex, 1);
      }
    },
    exportToExcel() {
      const data = this.failures.map(failure => {
        return {
          'Date/Time': this.novaCore.renderUtcInTimezone(
            failure.start,
            this.appointment.dock.warehouse.timezone
          ),
          Reason: failure.reason
        };
      });
      const wb = utils.book_new();

      utils.book_append_sheet(wb, utils.json_to_sheet(data), 'Failed Recurring Appointments');

      writeFile(wb, 'Recurring Appointment Failures.xlsx');

      this.buildingExcelData = false;
    }
  },
  watch: {
    failures(failures) {
      if (failures.length) {
        this.$emit('update-title', 'We found some issues');
      } else {
        this.$emit('update-title', 'Create Recurrence');
      }
    }
  }
};
</script>
