var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar-legend" },
    _vm._l(_vm.legendItems, function (item) {
      return _c(
        "v-tooltip",
        {
          key: item.name,
          attrs: {
            top: "",
            disabled:
              !_vm.statusTooltips[_vm.getProperCasedStatusName(item.name)],
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-chip",
                      _vm._g(
                        {
                          class: `py-1 px-2 ${
                            _vm.$useLightGridTheme
                              ? `${item.color} lighten-4 mr-2`
                              : ""
                          } ${item.name}`,
                          attrs: { "x-small": "", color: "transparent" },
                        },
                        on
                      ),
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-1",
                            attrs: { "x-small": "", color: item.color },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.novaCore.isRequested(
                                  _vm.getProperCasedStatusName(item.name)
                                )
                                  ? "mdi-clock-alert"
                                  : "mdi-circle"
                              )
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "text--black" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _vm.statusTooltips[_vm.getProperCasedStatusName(item.name)]
            ? _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.statusTooltips[_vm.getProperCasedStatusName(item.name)]
                      .description
                  ),
                },
              })
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }