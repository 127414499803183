<template>
  <div class="hero">
    <div class="login-page-hero"></div>
    <div class="image-group">
      <img class="opendock-logo" :src="$logoPath" />
    </div>
    <div class="auth-page-tag">Dock scheduling software for Warehouses</div>
  </div>
</template>

<script>
export default {};
</script>
