var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pa-4 details-container",
      class: { "is-editable": _vm.isEditable, "is-dense": _vm.isDense },
      attrs: { "data-testid": _vm.testId },
    },
    [
      _vm.isEditable
        ? [
            _c(
              "div",
              {
                staticClass: "d-flex justify-space-between",
                class: { "align-center": _vm.isDense },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-1" },
                  [
                    _vm.header
                      ? _c("div", { staticClass: "header" }, [
                          _vm._v(_vm._s(_vm.header)),
                        ])
                      : _vm._e(),
                    _vm._t("default"),
                  ],
                  2
                ),
                _vm._t(
                  "edit-button",
                  function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            "data-testid": `${_vm.testId}-edit-btn`,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("click", _vm.field)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      ),
                    ]
                  },
                  { field: _vm.field }
                ),
              ],
              2
            ),
          ]
        : [
            _c("div", { staticClass: "header" }, [_vm._v(_vm._s(_vm.header))]),
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [_vm._t("default")],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }