import carrierContactsService from '@satellite/services/carrier-contacts-service';

const state = {
  isLoading: false,
  carrierContacts: [],
  pagination: { total: 0 },
  params: {
    limit: 10,
    join: ['favoriteWarehouses', 'user', 'org', 'user.company'],
    sort: ['user.email', 'ASC'],
    page: 1,
    searchStr: '',
    favoritesOnly: false
  }
};

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setCarrierContacts(state, carrierContacts) {
    state.carrierContacts = carrierContacts;
  },
  setParams(state, params) {
    state.params = params;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  }
};

function notify(app, meta, message, type) {
  if (meta.skipNotify !== true) app.notify(message, type);
}

const actions = {
  async fetch({ commit, state }) {
    commit('setLoading', true);
    try {
      const { data, ...meta } = await carrierContactsService.list(state.params, {
        includeMetaData: true
      });
      commit('setCarrierContacts', data || []);
      commit('setPagination', { total: meta?.total || 0 });
    } catch (error) {
      this.$app.notify('Failed to get carrier contacts', 'error');
    } finally {
      commit('setLoading', false);
    }
  },
  async create({ commit, dispatch }, { payload, meta = {} }) {
    let ok = false;
    try {
      await carrierContactsService.create(payload);
      notify(this.$app, meta, 'Carrier Contact added to list', 'success');
      ok = true;
      if (this.$app.mixpanel && meta.mixpanelData) {
        this.$app.mixpanel.track(this.$app.mixpanel.events.MODULE.CARRIER.ADDED, meta.mixpanelData);
      }
    } catch (error) {
      this.$app.notify('Failed to add carrier contact to list', 'error');
    } finally {
      commit('setLoading', false);
      if (ok) {
        dispatch('fetch');
      }
    }
  },
  async remove({ commit, dispatch }, { payload, meta = {} }) {
    let ok = false;
    commit('setLoading', true);
    try {
      await carrierContactsService.hardDelete(payload);
      notify(this.$app, meta, 'Carrier Contact removed from list', 'success');
      ok = true;
      if (this.$app.mixpanel && meta.mixpanelData) {
        this.$app.mixpanel.track(
          this.$app.mixpanel.events.MODULE.CARRIER.REMOVED,
          meta.mixpanelData
        );
      }
    } catch (error) {
      this.$app.notify('Failed to remove carrier contact to list', 'error');
    } finally {
      commit('setLoading', false);
      if (ok) {
        dispatch('fetch');
      }
    }
  },
  async update({ commit, dispatch }, { payload, meta = {} }) {
    let ok = false;
    commit('setLoading', true);
    try {
      await carrierContactsService.update(payload);
      notify(this.$app, meta, 'Carrier Contact updated', 'success');
      ok = true;
      if (this.$app.mixpanel && meta.mixpanelData && meta.mixpanelEvent) {
        this.$app.mixpanel.track(meta.mixpanelEvent, meta.mixpanelData);
      }
    } catch (error) {
      this.$app.notify('Failed to update carrier contact', 'error');
    } finally {
      commit('setLoading', false);
      if (ok) {
        dispatch('fetch');
      }
    }
  }
};

export default {
  namespaced: true,
  name: 'CarrierContacts',
  state,
  actions,
  mutations
};
