const WAREHOUSE_DISPLAY_COUNT = 2;

export default {
  data() {
    return {
      warehouseDisplayCount: WAREHOUSE_DISPLAY_COUNT,
      warehouseDialog: {
        shouldShow: false,
        entity: null
      }
    };
  },
  methods: {
    getWarehouseList(warehouses) {
      let string = '';
      if (warehouses) {
        string = [...warehouses]
          .filter(warehouse => warehouse && warehouse.name)
          .splice(0, this.warehouseDisplayCount)
          .map(warehouse => warehouse.name)
          .join(', ');
        if (warehouses.length > this.warehouseDisplayCount) {
          string += ' ...';
        }
      }

      return string;
    },
    closeWarehouseDialog() {
      this.warehouseDialog = {
        shouldShow: false,
        user: null
      };
    }
  }
};
