var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.renderKey },
    [
      _c(
        "v-card",
        { attrs: { disabled: _vm.loading } },
        [
          _c(
            "v-card-title",
            { staticClass: "pb-0" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "align-end" },
                    [
                      _c("v-col", [_c("h4", [_vm._v("Date Range")])]),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-autocomplete", {
                            ref: "savedReportSearchSelect",
                            staticClass: "mb-0 pb-0",
                            attrs: {
                              "hide-details": "",
                              items: _vm.savedReportSearches,
                              filled: "",
                              dense: "",
                              "background-color": "#FFFFFF",
                              clearable: "",
                              "item-text": "title",
                              "item-value": "title",
                              "return-object": "",
                              label: "Load saved search",
                            },
                            on: { input: _vm.loadReportSearch },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ active, item, attrs, on }) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ active }) {
                                                    return [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    "no-gutters":
                                                                      "",
                                                                    align:
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.title
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "v-spacer"
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteReportSearch(
                                                                      item,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "error",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-delete-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          "v-list-item",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.loadedReportSearch,
                              callback: function ($$v) {
                                _vm.loadedReportSearch = $$v
                              },
                              expression: "loadedReportSearch",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pa-4 d-flex align-center elevation-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "pr-10" },
                        [
                          _c("date-range", {
                            ref: "apptReportDateRange",
                            attrs: {
                              label:
                                _vm.filters.dateField ===
                                _vm.novaCore.ReportDateFields.APPOINTMENT_DATE
                                  ? "Appointment Date"
                                  : "Creation Date",
                              dense: false,
                              "use-defined-ranges": "",
                              "initial-defined-range": "wtd",
                            },
                            model: {
                              value: _vm.filters.appointmentDateRange,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filters,
                                  "appointmentDateRange",
                                  $$v
                                )
                              },
                              expression: "filters.appointmentDateRange",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "my-5",
                        attrs: { vertical: "" },
                      }),
                      _c(
                        "div",
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "pl-5",
                              attrs: { row: "" },
                              model: {
                                value: _vm.filters.dateField,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "dateField", $$v)
                                },
                                expression: "filters.dateField",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-weight-bold mr-3" },
                                [_vm._v("Report On:")]
                              ),
                              _c("v-radio", {
                                attrs: {
                                  label:
                                    _vm.novaCore.ReportDateFields
                                      .APPOINTMENT_DATE,
                                  value:
                                    _vm.novaCore.ReportDateFields
                                      .APPOINTMENT_DATE,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label:
                                    _vm.novaCore.ReportDateFields.CREATION_DATE,
                                  value:
                                    _vm.novaCore.ReportDateFields.CREATION_DATE,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c("v-divider", {
                        staticClass: "my-5",
                        attrs: { vertical: "" },
                      }),
                      _c(
                        "div",
                        { staticClass: "pl-10", staticStyle: { flex: "1" } },
                        [
                          _c("exportable-fields-select", {
                            staticClass: "pt-0",
                            attrs: {
                              "minimum-selections": 1,
                              outlined: "",
                              "single-line": false,
                              dense: true,
                              "hide-details": "",
                              label: "Fields to Export",
                              "visible-selection-count": 1,
                              placeholder: "Export Fields",
                            },
                            model: {
                              value: _vm.filters.exportFields,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "exportFields", $$v)
                              },
                              expression: "filters.exportFields",
                            },
                          }),
                          _c(
                            "primary-button",
                            {
                              staticClass: "mr-1 float-end",
                              attrs: {
                                loading: _vm.buildingExcelData,
                                disabled: _vm.total < 1,
                                "before-icon": "microsoft-excel",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleExportReportButtonClick()
                                },
                              },
                            },
                            [_vm._v(" Export Report ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-flex align-center pt-5 pb-1" }, [
                    _c("h4", [_vm._v("Filtering Options")]),
                  ]),
                  _c(
                    "v-container",
                    {
                      staticClass: "elevation-3 mx-1",
                      attrs: {
                        fluid: "",
                        "data-testid": "reporting-page-filter-box",
                      },
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "3" } },
                                [
                                  _c("warehouse-multi-select", {
                                    ref: "reportingWarehouseSelect",
                                    attrs: {
                                      "single-line": false,
                                      "hide-icon": "",
                                      outlined: "",
                                      rules: _vm.$validator.rules.selectLimit(
                                        _vm.novaCore.GlobalLimits
                                          .MAX_REPORTING_FILTER_SELECTIONS
                                          .value,
                                        "Warehouse"
                                      ),
                                      "should-disable-item-if-no-docks": false,
                                      "is-flex": false,
                                      "disable-select-all": "",
                                      dense: true,
                                      placeholder: "Warehouses",
                                      label: _vm.warehouseSelectLabel,
                                      "maximum-selections":
                                        _vm.novaCore.GlobalLimits
                                          .MAX_REPORTING_FILTER_SELECTIONS
                                          .value,
                                      joins: ["docks||id,name"],
                                      "select-fields": [
                                        "id,name,facilityNumber",
                                      ],
                                      "show-store-number": "",
                                    },
                                    model: {
                                      value: _vm.filters.warehouses,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "warehouses", $$v)
                                      },
                                      expression: "filters.warehouses",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "3" } },
                                [
                                  _c("custom-tag-select", {
                                    attrs: {
                                      "hide-details": "",
                                      dense: "",
                                      label: _vm.customTagsSelectLabel,
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.filters.tags,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "tags", $$v)
                                      },
                                      expression: "filters.tags",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "3" } },
                                [
                                  _c("basic-dock-select", {
                                    ref: "dock-select",
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: _vm.dockSelectLabel,
                                      multiple: "",
                                      "should-select-new-docks": false,
                                      "maximum-selections":
                                        _vm.novaCore.GlobalLimits
                                          .MAX_REPORTING_FILTER_SELECTIONS
                                          .value,
                                      "disable-select-all": "",
                                      rules: _vm.$validator.rules.selectLimit(
                                        _vm.novaCore.GlobalLimits
                                          .MAX_REPORTING_FILTER_SELECTIONS
                                          .value,
                                        "Docks"
                                      ),
                                      "hide-inactive-docks": false,
                                      "select-fields": [
                                        "id,name,isActive,loadTypeIds",
                                      ],
                                      "warehouse-ids": _vm.selectedWarehouseIds,
                                      "hide-icon": "",
                                    },
                                    on: {
                                      dockListUpdate: _vm.handleDockListUpdate,
                                    },
                                    model: {
                                      value: _vm.filters.docks,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "docks", $$v)
                                      },
                                      expression: "filters.docks",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "3" } },
                                [
                                  _c("basic-load-type-select", {
                                    attrs: {
                                      label: _vm.loadTypeSelectLabel,
                                      multiple: "",
                                      "disable-select-all": "",
                                      dense: "",
                                      "select-all-on-fetch": "",
                                      "maximum-selections":
                                        _vm.novaCore.GlobalLimits
                                          .MAX_REPORTING_FILTER_SELECTIONS
                                          .value,
                                      "show-direction-in-name": true,
                                      docks: _vm.dockObjects,
                                      rules: _vm.$validator.rules.selectLimit(
                                        _vm.novaCore.GlobalLimits
                                          .MAX_REPORTING_FILTER_SELECTIONS
                                          .value,
                                        "Load Types"
                                      ),
                                      "fetch-load-types-on-mount":
                                        _vm.hasSelectedWarehouses,
                                      "warehouse-ids": _vm.selectedWarehouseIds,
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.filters.loadTypes,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "loadTypes", $$v)
                                      },
                                      expression: "filters.loadTypes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("basic-carrier-select", {
                                    ref: "basicCarrierSelect",
                                    attrs: {
                                      multiple: "",
                                      outlined: "",
                                      clearable: "",
                                      label: _vm.carrierSelectLabel,
                                      "disable-select-all": "",
                                      "should-get-booked-only": "",
                                      "hide-details": "",
                                      "maximum-selections":
                                        _vm.novaCore.GlobalLimits
                                          .MAX_REPORTING_FILTER_SELECTIONS
                                          .value,
                                      rules: _vm.$validator.rules.selectLimit(
                                        _vm.novaCore.GlobalLimits
                                          .MAX_REPORTING_FILTER_SELECTIONS
                                          .value,
                                        "Carrier"
                                      ),
                                      dense: "",
                                    },
                                    on: {
                                      ready: function ($event) {
                                        _vm.areCarriersFetched = true
                                      },
                                    },
                                    model: {
                                      value: _vm.filters.carriers,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "carriers", $$v)
                                      },
                                      expression: "filters.carriers",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      "hide-details": "",
                                      outlined: "",
                                      dense: "",
                                      items: Object.values(
                                        _vm.novaCore
                                          .AppointmentCreatorTypeReportOptions
                                      ),
                                      multiple: "",
                                      label: _vm.createdBySelectLabel,
                                    },
                                    model: {
                                      value:
                                        _vm.filters.appointmentCreatorTypes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "appointmentCreatorTypes",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filters.appointmentCreatorTypes",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "3" } },
                                [
                                  _c("status-select", {
                                    attrs: {
                                      "single-line": false,
                                      outlined: "",
                                      placeholder:
                                        _vm.appointmentStatusSelectLabel,
                                      classes: "pt-0",
                                      "hide-icon": "",
                                      "should-disable-auto-select": "",
                                      dense: true,
                                    },
                                    model: {
                                      value: _vm.filters.statuses,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "statuses", $$v)
                                      },
                                      expression: "filters.statuses",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { xs: "12", md: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      items: Object.values(
                                        _vm.novaCore.AppointmentType
                                      ),
                                      multiple: "",
                                      label: _vm.appointmentTypeSelectLabel,
                                    },
                                    model: {
                                      value: _vm.filters.appointmentTypes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "appointmentTypes",
                                          $$v
                                        )
                                      },
                                      expression: "filters.appointmentTypes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "mt-0 mb-0" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        color: "#195a87",
                                        small: "",
                                      },
                                      on: { click: _vm.clearAllFilters },
                                    },
                                    [_vm._v(" Clear All Filters ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-5 pr-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 ml-4",
                          attrs: {
                            color: "secondary",
                            disabled: !_vm.loadedReportSearch,
                          },
                          on: { click: _vm.handleSearchUpdate },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-update"),
                          ]),
                          _vm._v(" Update Current Search "),
                        ],
                        1
                      ),
                      _c("save-report-search-dialog", {
                        attrs: { "action-button-text": "Save New Search" },
                        on: { "save-report-search": _vm.saveReportSearch },
                      }),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", loading: _vm.loading },
                          on: { click: _vm.fetchData },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-send"),
                          ]),
                          _vm._v(" Run Report "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.loading
            ? [
                _c(
                  "v-card",
                  { staticClass: "mx-8 mt-8", attrs: { elevation: "0" } },
                  [
                    _c(
                      "v-card-text",
                      [
                        _vm._v(" Loading report data... "),
                        _c("v-progress-linear", {
                          attrs: {
                            color: "secondary",
                            indeterminate: "",
                            rounded: "",
                            height: "8",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.isDataFetchedOnce
            ? _c(
                "v-card-text",
                { staticClass: "px-8 mt-8" },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-4 pb-4" },
                    [
                      _c("v-col", [_c("h2", [_vm._v("Report Data")])]),
                      _c("v-spacer"),
                      _c(
                        "span",
                        {
                          staticClass:
                            "align-self-end font-size-large black--text font-weight-bold",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.total
                                  ? `Total Appointments: ${_vm.total}`
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.total > _vm.limit
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            outlined: "",
                            type: "warning",
                            prominent: "",
                            border: "left",
                          },
                        },
                        [
                          _vm._v(
                            " The number of appointments matching these filters ( "
                          ),
                          _c("strong", [_vm._v(_vm._s(_vm.total))]),
                          _vm._v(" ) exceeds the limit "),
                          _c("strong", [_vm._v(_vm._s(_vm.limit))]),
                          _vm._v(" . Only "),
                          _c("strong", [_vm._v(_vm._s(_vm.limit))]),
                          _vm._v(" will be available in this table. "),
                          _c("br"),
                          _c("strong", [
                            _vm._v(
                              "However all " +
                                _vm._s(_vm.total) +
                                " appointments can be exported"
                            ),
                          ]),
                          _vm._v(" . "),
                        ]
                      )
                    : _vm._e(),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.tableHeaders,
                      items: _vm.appointments,
                      loading: !_vm.warehousesAreLoaded(),
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      "footer-props": _vm.footerProps,
                      options: _vm.options,
                    },
                    on: {
                      "update:sortBy": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.status",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                class: `${item.status.toLowerCase()} tile font-size-x-small text-center`,
                              },
                              [
                                _vm.novaCore.isRequested(item.status)
                                  ? _c("v-icon", { staticClass: "mr-1" }, [
                                      _vm._v("mdi-clock-alert"),
                                    ])
                                  : _vm._e(),
                                _c("strong", {
                                  class: {
                                    "black--text": _vm.novaCore.isRequested(
                                      item.status
                                    ),
                                  },
                                  domProps: {
                                    innerHTML: _vm.novaCore.breakWordsAtCaps(
                                      item.status
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.dock.name",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.novaCore.getDockNameWithActiveStatus(
                                    item.dock
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.start",
                        fn: function ({ item }) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getTimeInWarehouseTimezone(
                                      item,
                                      _vm.novaCore.LuxonDateTimeFormats
                                        .MonthDayYearSlashedTimeAMPM,
                                      _vm.novaCore.LuxonDateTimeFormats
                                        .MonthDayYearSlashedTime24
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.creator_type",
                        fn: function ({ item }) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.makeAppointmentCreatorType(
                                    item.createdByUser
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("v-dialog", {
        attrs: { "max-width": "600" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (dialog) {
              return [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "mb-4" },
                      [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Change email destination"),
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "pa-3",
                            attrs: { icon: "", light: "" },
                            on: {
                              click: function ($event) {
                                _vm.shouldShowEmailCCsDialog = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close-circle")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      [
                        _c("small", [_vm._v("* Max 10 email destinations")]),
                        _c("email-list-field", {
                          ref: "emailFieldList",
                          attrs: { "small-chips": "", required: "", small: "" },
                          model: {
                            value: _vm.emailCCs,
                            callback: function ($$v) {
                              _vm.emailCCs = $$v
                            },
                            expression: "emailCCs",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end mx-2 my-2" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { outlined: "" },
                            on: {
                              click: function ($event) {
                                _vm.shouldShowEmailCCsDialog = false
                              },
                            },
                          },
                          [_vm._v("Nevermind")]
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.buildingExcelData,
                              color: "primary",
                            },
                            on: { click: _vm.exportReportAndEmail },
                          },
                          [_vm._v(" Build and Email ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.shouldShowEmailCCsDialog,
          callback: function ($$v) {
            _vm.shouldShowEmailCCsDialog = $$v
          },
          expression: "shouldShowEmailCCsDialog",
        },
      }),
      _c("v-dialog", {
        attrs: { "max-width": "600" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (dialog) {
              return [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Report Delivery Method"),
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "pa-3",
                            attrs: { icon: "", light: "" },
                            on: {
                              click: function ($event) {
                                _vm.shouldShowDeliveryMethodDialog = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close-circle")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-card-text", [
                      _c("p", { staticClass: "body-1" }, [
                        _vm._v(
                          " This is a large report and may take some time to generate, and the request may timeout if you choose the download option. We suggest that you send this report to your email address instead "
                        ),
                        _c("strong", [
                          _vm._v("(" + _vm._s(_vm.$me.email) + ")"),
                        ]),
                        _vm._v(" . "),
                      ]),
                    ]),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end mx-2 my-2" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { outlined: "" },
                            on: {
                              click: function ($event) {
                                _vm.shouldShowDeliveryMethodDialog = false
                              },
                            },
                          },
                          [_vm._v("Nevermind")]
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.buildingExcelData,
                              color: "warning",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.exportReport(false)
                              },
                            },
                          },
                          [_vm._v(" Build and Download Now ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              loading: _vm.buildingExcelData,
                              color: "success",
                            },
                            on: {
                              click: function ($event) {
                                _vm.shouldShowEmailCCsDialog = true
                              },
                            },
                          },
                          [_vm._v(" Build and Email ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.shouldShowDeliveryMethodDialog,
          callback: function ($$v) {
            _vm.shouldShowDeliveryMethodDialog = $$v
          },
          expression: "shouldShowDeliveryMethodDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }