// TODO: Revisit, shouldn't exist with warehouse-level favorites
import MixpanelEvents from '@/enums/MixpanelEvents';

export default {
  computed: {
    doesOrgHaveFavorites() {
      return this.$org?.favoriteCarrierIds?.length > 0;
    }
  },
  data() {
    return {
      favoritesOnly: false,
      shouldFetchData: false
    };
  },
  methods: {
    /**
     * Toggle a carrier as favorite
     * @param carrier
     */
    async toggleFavoriteCarrier(carrier) {
      if (this.$org) {
        const favoritesList = this.$org.favoriteCarrierIds || [];
        const index = favoritesList.findIndex(fav => fav === carrier.id);
        const isFavorite = index === -1;

        if (isFavorite) {
          favoritesList.push(carrier.id);
        } else {
          favoritesList.splice(index, 1);
        }
        this.$org.favoriteCarrierIds = favoritesList;

        if (!this.doesOrgHaveFavorites) {
          this.favoritesOnly = false;
        }

        await axios
          .patch(`/org/${this.$org.id}/favorite-carriers`, { favoriteCarrierIds: favoritesList })
          .then(async () => {
            this.notify(`Carrier ${isFavorite ? 'added to favorites' : 'removed from favorites'}`);

            const mixpanelData = {
              'Entry Point': 'Appointment Details Modal',
              'Carrier Contact Name': this.novaCore.namesToFullName(
                carrier.firstName,
                carrier.lastName
              ),
              'Carrier Contact Email': carrier.email,
              'Carrier Company Name': carrier.company?.name,
              'Carrier Company ID': carrier.company?.id,
              'Org ID': this.$org.id,
              'Org Name': this.$org.name
            };

            this.mixpanel.track(
              MixpanelEvents.MODULE.CARRIER.FAVORITES[`${isFavorite ? 'ADDED' : 'REMOVED'}`],
              mixpanelData
            );
            if (this.shouldFetchData) {
              await this.getData();
            }
          });
      }
    }
  }
};
