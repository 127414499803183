import _ from 'lodash';
import { toValue } from '@vueuse/core';
import { computed } from 'vue';

/**
 * @typedef {import('vue').Ref} VueRef
 */

/**
 * Composable helper to manage selecting/deselecting all items in a list
 * @param {Object} options
 * @param {VueRef<any[]>} options.items - Available items to select
 * @param {VueRef<any[]>} options.selected - Selected items
 * @param {string} [options.itemValue] - The property of the item to use as the value
 * @param {Function} [options.transformSelection] - A function to transform the selection items
 */
export default function useSelectAll({
  items,
  selected,
  itemValue = 'id',
  transformSelection = item => item
}) {
  const itemsValue = computed(() => toValue(items));
  const selectedValue = computed(() => toValue(selected));
  const allSelected = computed(() => {
    const availableItems = itemsValue.value.map(transformSelection);
    const selectedItems = selectedValue.value;

    // Extract values for comparison using itemValue or direct value
    const a = _(availableItems)
      .map(item => (typeof item === 'object' ? item[itemValue] : item))
      .uniq()
      .value();

    const b = _(selectedItems)
      .map(item => (typeof item === 'object' ? item[itemValue] : item))
      .uniq()
      .value();

    return _.xor(a, b).length === 0;
  });

  function toggleSelectAll() {
    selected.value = allSelected.value ? [] : itemsValue.value.map(transformSelection);
  }

  return { allSelected, toggleSelectAll };
}
