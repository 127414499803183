<template>
  <div>
    <yard-view-no-areas v-if="!spotAreas.length" />
    <v-expansion-panels class="d-flex flex-column gap-4" accordion multiple flat v-else>
      <v-expansion-panel
        class="yard-spot-area-container"
        v-for="spotArea of spotAreas"
        :key="spotArea.id"
        :data-testid="`yard-main-area-${spotArea.id}`">
        <v-expansion-panel-header>
          <yard-view-spot-area-header
            :spot-area="spotArea"
            :data-testid="`yard-main-area-header-${spotArea.id}`" />
        </v-expansion-panel-header>

        <v-expansion-panel-content class="yard-spot-area-content">
          <yard-view-spot-area
            :editable="false"
            :spot-area="spotArea"
            class="mt-6"
            :data-testid="`yard-main-area-spots-${spotArea.id}`" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <yard-view-legend v-if="spotAreas.length > 0" />
    <yard-view-message-driver-dialog
      v-if="spotAreas.length > 0"
      :spot-assignment="messageDriverSpotAssignment" />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import YardViewSpotArea from '@/modules/yard/components/SpotArea/YardViewSpotArea.vue';
import YardViewNoAreas from '@/modules/yard/components/Overlays/YardViewNoAreas.vue';
import { useRouter } from 'vue-router/composables';
import YardViewSpotAreaHeader from '@/modules/yard/components/SpotArea/YardViewSpotAreaHeader.vue';
import { useEventHub, useSockets } from '@/composables';
import YardViewMessageDriverDialog from '@/modules/yard/components/Spot/Dialogs/YardViewMessageDriverDialog.vue';

export default defineComponent({
  name: 'YardViewYard',
  components: {
    YardViewMessageDriverDialog,
    YardViewSpotAreaHeader,
    YardViewNoAreas,
    YardViewSpotArea
  },
  props: {},
  setup() {
    const yardStore = useYard();
    const router = useRouter();
    const sockets = useSockets();
    const eventHub = useEventHub();

    const messageDriverSpotAssignment = ref(null);

    const selectedWarehouse = yardStore.selectedWarehouseRef;

    const spotAreas = computed(() =>
      yardStore.spotAreasRef.value.filter(spotArea =>
        yardStore.selectedAreasRef.value.includes(spotArea.id)
      )
    );

    if (selectedWarehouse.value?.id) {
      router.push({ name: 'yard-warehouse', params: { warehouseId: selectedWarehouse.value.id } });
    }

    onBeforeMount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$on(`${operation}-SpotArea`, yardStore.handleSpotAreaSubspaceEvent);
        eventHub.$on(`${operation}-Spot`, yardStore.handleSpotSubspaceEvent);
        eventHub.$on('spot-assignment-created', spotAssignment => {
          messageDriverSpotAssignment.value = spotAssignment;
        });
      });
      yardStore.selectedSpotRef.value = null;
      eventHub.$on('close-message-driver-dialog', () => {
        messageDriverSpotAssignment.value = null;
      });
    });

    onBeforeUnmount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$off(`${operation}-SpotArea`, yardStore.handleSpotAreaSubspaceEvent);
        eventHub.$off(`${operation}-Spot`, yardStore.handleSpotSubspaceEvent);
        eventHub.$off('spot-assignment-created');
        eventHub.$off('close-message-driver-dialog');
      });
    });

    return {
      onBeforeMount,
      onBeforeUnmount,
      selectedWarehouse,
      spotAreas,
      selectedSpot: yardStore.selectedSpotRef,
      messageDriverSpotAssignment
    };
  }
});
</script>

<style lang="scss" scoped>
.yard-spot-area-container {
  border: 1px solid $color-line-border;
  border-radius: $default-border-radius;
}

.yard-spot-area-content {
  border-top: 1px solid $color-line-border;
}
</style>
