<template>
  <dialog-base v-bind="$props" :key="renderKey" header="Change Org Type" @close="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <template v-slot:body>
      <edit-org-type-form
        v-if="org"
        @close="close"
        :org="org"
        @save="updateOrgType"></edit-org-type-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit org type
 * @displayName EditOrgTypeDialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateOrgType(orgType) {
      this.org.orgType = orgType;
    }
  }
};
</script>
