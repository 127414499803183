var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        class: { required: _vm.field.required },
        attrs: {
          height: "20px",
          value: _vm.field.value,
          rules: _vm.validationRules,
          items: _vm.field.dropDownValues,
          dense: "",
          clearable: "",
          "hide-details": "auto",
          placeholder: _vm.field.label,
          "persistent-placeholder": _vm.persistentPlaceholder
            ? Boolean(_vm.field.placeholder)
            : false,
          "open-on-clear": "",
        },
        on: { input: (value) => _vm.$emit("input", value || "") },
        scopedSlots: _vm._u(
          [
            _vm.displayLabel
              ? {
                  key: "label",
                  fn: function () {
                    return [_vm._v("Choose an option")]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      },
      "v-autocomplete",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }