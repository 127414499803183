<template>
  <div>
    <form-base :hide-required-legend="true" class="delete-recurring-series-form">
      <template #form>
        <v-form class="pa-4 inline-fields" ref="form">
          <template v-if="loading">
            <v-progress-linear
              indeterminate
              :loading="loading"
              height="6"
              class="mt-6"></v-progress-linear>
            <h4 class="text-center mt-4 mb-12">{{ loadingMessage }} ...</h4>
          </template>
          <template v-else>
            <div>
              This will
              <strong>delete all future appointments</strong>
              in this series starting from this appointment onward:
            </div>
            <div class="px-3 my-3">
              <v-alert dense color="disabled">
                <span>
                  <v-icon small class="mr-3">mdi-repeat</v-icon>
                  <span class="font-size-x-small recurring-pattern">
                    <strong>
                      {{ recurringPattern.weekDays }} @ {{ recurringPattern.endTime }}
                    </strong>
                    until
                    <strong>{{ recurringPattern.endDate }}</strong>
                  </span>
                </span>
              </v-alert>
              <div>
                <v-icon small class="text--darken-3">mdi-information</v-icon>
                <span class="font-size-x-small">
                  The original appointment will remain on the schedule
                </span>
              </div>
            </div>
          </template>
        </v-form>
      </template>
      <template #form-actions>
        <action-group v-if="!loading" hide-cancel hide-confirm :is-spacer-visible="false">
          <template v-slot:additional-actions>
            <v-spacer></v-spacer>
            <outline-button
              @click="$emit('close')"
              before-icon="close-circle"
              data-testid="delete-recurring-appointment-dialog-nevermind-btn">
              Nevermind
            </outline-button>
            <v-btn
              @click="deleteRecurringSeries"
              text
              class="text--color-neutral-0 bg--color-text-error">
              Yes, Delete
            </v-btn>
          </template>
        </action-group>
      </template>
    </form-base>
  </div>
</template>

<script>
import recurringAppointmentMixin from '@/components/mixins/recurringAppointmentMixin';

export default {
  mixins: [recurringAppointmentMixin],
  props: {
    appointment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loadingMessage: 'Deleting Recurrence'
    };
  },
  methods: {
    async deleteRecurringSeries() {
      this.loading = true;
      const apptId = this.appointment.id;

      await axios
        .delete(`appointment/${apptId}/recurring`)
        .then(response => {
          if (response?.data?.data) {
            if (this.novaCore.isRecurringAppointmentChild(this.appointment)) {
              this.$store.commit('Calendar/setSelectedEvent', null);
              this.$eventHub.$emit('close-appointment-details');
            }

            this.$store.dispatch('Appointments/trackMixpanelEvent', {
              appointment: this.appointment,
              change: 'Recurrence Deleted'
            });

            this.$store.dispatch('Appointments/triggerSocketEvent', {
              response,
              appointment: this.appointment
            });

            this.$emit('close');
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .delete-recurring-series-form {
  .actions {
    border-top: 1px solid $color-line-divider;
  }

  .v-progress-linear {
    margin-top: calc(10vh - 10%) !important;
  }
}
</style>
