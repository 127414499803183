var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    key: _vm.renderKey,
    attrs: { header: _vm.header, "hide-required-legend": true },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "formRef" },
              [
                _c("custom-field-timestamp", {
                  attrs: {
                    warehouse: _vm.appointment.dock.warehouse,
                    "hide-details": "",
                    "hide-timezone": "",
                    "return-object": "",
                    "persistent-placeholder": false,
                    outlined: "",
                    "display-label": false,
                    field: { label: "New ETA", value: _vm.customDateTime },
                    required: true,
                    compact: true,
                  },
                  model: {
                    value: _vm.customDateTimeModel,
                    callback: function ($$v) {
                      _vm.customDateTimeModel = $$v
                    },
                    expression: "customDateTimeModel",
                  },
                }),
                _c("auto-expandable-textarea", {
                  attrs: {
                    "hide-details": "auto",
                    outlined: "",
                    placeholder: "Enter a reason for the ETA update",
                    "validate-on-blur": "",
                  },
                  model: {
                    value: _vm.etaReason,
                    callback: function ($$v) {
                      _vm.etaReason = $$v
                    },
                    expression: "etaReason",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "d-flex mt-4" },
                  [
                    _c("v-spacer"),
                    _c(
                      "outline-button",
                      {
                        staticClass: "mr-2",
                        attrs: { disabled: _vm.isLoading, small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit(
                              "cancel",
                              _vm.EditableDetailsFields.eta
                            )
                          },
                        },
                      },
                      [_vm._v(" Nevermind ")]
                    ),
                    _c(
                      "primary-button",
                      {
                        attrs: { disabled: _vm.isLoading, small: "", text: "" },
                        on: { click: _vm.submit },
                      },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }