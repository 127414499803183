import { useStore } from '@/composables/index';

export default function useMixpanel() {
  const store = useStore();
  const mixpanel = store.$app.mixpanel;

  return {
    ...mixpanel,
    track: (event, data) => mixpanel.track(event, data)
  };
}
