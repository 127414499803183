export default {
  methods: {
    formatInterval(interval) {
      const timeParts = interval.time.split(':');

      // This formats the hours on the grid,
      // We always want to show all the hours as if there is no DST
      const tz = 'UTC';
      let intervalLabel = this.novaCore.formatDateTimeWithMilitarySupport(
        `${interval.date}T${interval.time}`,
        tz,
        this.novaCore.LuxonDateTimeFormats.Hour12,
        this.$isMilitaryTimeEnabled(this.$selectedWarehouse),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );

      if (timeParts[1] !== '00') {
        intervalLabel = timeParts[1];
      }
      return intervalLabel;
    }
  }
};
