var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          loading: _vm.loading,
          "no-padding": "",
          header: "",
          "hide-close-icon": "",
          "loading-message": "",
          "max-width": "100%",
          value: _vm.showDialog,
          color: "black",
          fullscreen: "",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "pa-4" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "disabled-bg",
                        attrs: {
                          icon: "",
                          large: "",
                          color: "black",
                          background: "white",
                        },
                        on: { click: _vm.close },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "my-4 d-flex align-center white--text px-4 font-size-large",
                  },
                  [
                    _c("v-icon", { staticClass: "mr-1 white--text" }, [
                      _vm._v("mdi-map-marker"),
                    ]),
                    _c("strong", [_vm._v("Choose Warehouse")]),
                  ],
                  1
                ),
                _vm._l(_vm.warehouses, function (warehouse) {
                  return _c(
                    "v-btn",
                    {
                      key: warehouse.id,
                      staticClass:
                        "btn-plain text-left px-4 py-6 btn-text-left",
                      class: { active: _vm.isWarehouseSelected(warehouse) },
                      attrs: {
                        plain: "",
                        dark: "",
                        disabled: !warehouse?.docks.length,
                        block: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectWarehouse(warehouse)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(warehouse.name) + " ")]
                  )
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }