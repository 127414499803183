<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <v-btn
          :data-testid="`appointment-${actionName}-button`"
          :icon="!actionItem.label"
          class="mx-1"
          v-if="actionItem.show"
          :class="{
            [actionItem.color]: actionItem.color,
            [`${actionItem.textColor}--text`]: actionItem.textColor
          }"
          :disabled="actionItem.disabled"
          @click="$emit('click', actionItem.action)">
          <v-icon :color="actionItem.iconColor ? actionItem.iconColor : ''">
            {{ `mdi-${actionItem.icon}` }}
          </v-icon>
          <span class="d-inline-block ml-2" v-if="actionItem.label">{{ actionItem.label }}</span>
        </v-btn>
      </div>
    </template>
    <span>{{ getTooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AppointmentActionGroupItem',
  props: {
    actionItem: {
      type: Object,
      required: true
    },
    actionName: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    disabledMessage: {
      type: String,
      required: false
    }
  },
  computed: {
    getDisabledTooltipMessage() {
      return this.disabledMessage ? this.disabledMessage : this.novaCore.getRoleActionError();
    },
    getTooltipText() {
      return this.disabled ? this.getDisabledTooltipMessage : this.actionItem.tooltip;
    }
  }
};
</script>
