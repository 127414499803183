<template>
  <div class="d-flex flex-row align-center mb-4 full-width">
    <h3 v-if="label" :class="disabled ? 'text--disabled' : ''" class="mr-4">{{ label }}:</h3>
    <v-select
      data-testid="status-select"
      class="status-select"
      :dense="dense"
      :height="height"
      :prepend-icon="!hideIcon ? 'mdi-format-list-checks' : ''"
      @input="updateValue"
      :value="value"
      :items="displayStatuses"
      :label="customPersistentLabel ?? placeholder"
      hide-details="auto"
      :flat="flat"
      :solo="solo"
      :outlined="outlined"
      :rules="minimumSelections ? $validator.rules.selectAtLeast(1, 'Status') : []"
      :multiple="multiSelect"
      :class="classes"
      :disabled="disabled"
      :clearable="clearable"
      ref="statusSelect"
      @click:clear="updateValue({})"
      :single-line="!customPersistentLabel && singleLine">
      <template #label>
        <slot name="label"></slot>
        <span v-if="required">
          {{ placeholder }}
          <span class="red--text"><strong>*</strong></span>
        </span>
      </template>

      <template #prepend-item>
        <v-list-item ripple @click="toggleSelectAllItems">
          <v-list-item-action>
            <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider slot="prepend-item" class="mt-2" />
      </template>

      <template #selection="{ item, index }">
        <slot name="selection" :item="item" :index="index">
          <div v-if="index === 0" class="text-truncate">
            {{ novaCore.breakWordsAtCaps(item.text) }}
          </div>
          <div
            v-if="index === 1 || value.length === 1"
            class="text-caption selected-quantity"
            :class="selectionTextData.class">
            {{ selectionTextData.text }}
          </div>
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';

export default {
  name: 'status-select',
  mixins: [selectMixin, selectAllSelectMixin],
  props: {
    /**
     * @model
     */
    value: {
      required: true
    },
    /**
     * Multiple values can be selected
     */
    multiSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Disable the select
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: true
    },
    height: {
      type: String,
      required: false,
      default: '25px'
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select Statuses'
    },
    singleLine: {
      type: Boolean,
      required: false,
      default: true
    },
    classes: {
      type: String,
      required: false,
      default: ''
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    minimumSelections: {
      type: Number,
      required: false,
      default: null
    },
    shouldDisableAutoSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    customPersistentLabel: {
      type: String,
      required: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    extraStatuses: {
      type: Array,
      required: false,
      default: () => []
    },
    statusesSortComparator: {
      type: Function,
      required: false,
      default: () => 0
    }
  },
  data() {
    return {
      selectRefKey: 'statusSelect'
    };
  },
  computed: {
    selectableItems() {
      return [...Object.keys(this.novaCore.AppointmentStatus), ...(this.extraStatuses ?? [])].sort(
        this.statusesSortComparator
      );
    },
    displayStatuses() {
      return this.selectableItems.map(s => ({ text: this.novaCore.breakWordsAtCaps(s), value: s }));
    },
    selectionTextData() {
      const selected = this.value.length;
      const total = this.selectableItems.length;

      return {
        text: `${selected}/${total} Selected`,
        class: selected === total ? 'grey--text' : 'red--text'
      };
    }
  },
  methods: {
    updateValue(statuses) {
      this.$emit('input', statuses);
    },
    setStatuses() {
      if ((this.autoSelect || this.value.length === 0) && !this.shouldDisableAutoSelect) {
        this.updateValue(this.selectableItems);
      }
    }
  },
  mounted() {
    this.setStatuses();
  }
};
</script>

<style scoped>
.selected-quantity {
  width: 150px;
  text-align: right;
}
</style>
