<!--
  -- this component is using the new Vue Composition API, this is a step towards our goal
  -- of migrating helios into Vue3. To learn more about it, check:
  -- https://vuejs.org/api/composition-api-setup
  -- https://vuejs.org/guide/extras/composition-api-faq.html
-->
<template>
  <div class="contact-card" data-testid="carrier-contact-card" :data-carrier-id="carrier.id">
    <p class="full-name">{{ carrier.fullName }}</p>
    <p class="company-name">{{ company.name }}</p>
    <p class="email">{{ carrier.email }}</p>
    <v-icon class="contact-icon is-favorite" v-if="isFavorite">mdi-star</v-icon>
    <v-icon class="contact-icon" v-else>mdi-account</v-icon>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { objectPropFactory } from '@/helpers/props';

export default defineComponent({
  name: 'CarrierContactCard',
  props: {
    ...objectPropFactory('carrier', ['fullName', 'email'], { required: true }),
    ...objectPropFactory('company', ['name'], { required: true }),
    isFavorite: { type: Boolean, default: false }
  }
});
</script>

<style scoped lang="scss">
.contact-card {
  background-color: $color-background-tertiary;
  border-radius: 8px;
  border: 1px solid $color-line-border;
  padding: 12px 24px;
  position: relative;
  font-size: 12px;
  margin: 12px;

  p {
    color: $color-neutral-80;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  p.full-name {
    font-size: 14px;
    font-weight: 600;
  }

  p.company-name {
    font-weight: 600;
  }

  i.contact-icon {
    background-color: $color-background-secondary;
    border-radius: 50px;
    border: 1px solid $color-line-border;
    color: $color-neutral-80;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);

    &.is-favorite {
      color: $color-primary-60;
    }
  }
}
</style>
