<template>
  <v-card class="pa-2" width="300" data-testid="appointment-checkin-advertisement-box">
    <v-card-title>
      <p class="text-break mb-2">
        {{
          isCurrentUserAdminOrOwner
            ? "Enhance your facility's efficiency with"
            : 'Optimize your workflow with'
        }}
        <a
          target="_blank"
          @click="mixpanelClick('Fake-door Hyperlink')"
          :href="isCurrentUserAdminOrOwner ? linkGateManagement : linkGateManagementOthers">
          Gate Management
        </a>
      </p>
    </v-card-title>
    <v-card-text>
      <p class="mb-1">
        <v-icon small color="primary" class="mr-2">mdi-check-circle-outline</v-icon>
        Drivers
        <strong>self-check in</strong>
      </p>
      <p class="mb-1">
        <v-icon small color="primary" class="mr-2">mdi-check-circle-outline</v-icon>
        <strong>Real-time alerts</strong>
        for all arrivals
      </p>
      <p class="mb-1">
        <v-icon small color="primary" class="mr-2">mdi-check-circle-outline</v-icon>
        <strong>Unplanned arrivals</strong>
        visibility
      </p>
      <p class="mb-1">
        <v-icon small color="primary" class="mr-2">mdi-check-circle-outline</v-icon>
        <span v-if="isCurrentUserAdminOrOwner">
          <strong>Insights & Reports</strong>
          into gate activity
        </span>
        <span v-else>
          <strong>Auto-update</strong>
          appointments
        </span>
      </p>
      <p class="mb-1">
        <v-icon small color="primary" class="mr-2">mdi-check-circle-outline</v-icon>
        In-app
        <strong>2-way SMS</strong>
        <small class="disabled ml-2">SOON!</small>
      </p>
    </v-card-text>
    <v-card-actions>
      <div class="text-center full-width">
        <p>
          {{
            isCurrentUserAdminOrOwner
              ? 'Want to learn more?'
              : 'Believe your facility could benefit?'
          }}
        </p>
        <p>
          <v-btn
            v-if="isCurrentUserAdminOrOwner"
            target="_blank"
            class="small secondary"
            @click="mixpanelClick('Fake-door Primary Button')"
            :href="linkContactSales">
            <span class="white--text">
              <v-icon small class="mr-2">mdi-open-in-new</v-icon>
              CONTACT SALES
            </span>
          </v-btn>
          <v-btn class="secondary" v-else @click="requestCheckInFeature">
            <span class="white--text">Yes, I'm interested</span>
          </v-btn>
        </p>
        <p class="pt-2">
          <a v-if="isCurrentUserAdminOrOwner" @click="requestCheckInFeature">Request a trial</a>
          <a
            :href="mailToContent"
            target="_blank"
            @click="mixpanelClick('Fake-door Share link')"
            v-else>
            <v-icon small class="mr-1" color="secondary">mdi-email-fast-outline</v-icon>
            Share with my team
          </a>
        </p>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import mixpanelEvents from '@/enums/MixpanelEvents';

export default {
  props: {
    warehouse: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    mixpanelClick(type) {
      this.mixpanel.track(mixpanelEvents.MODULE.SELF_CHECK_IN.CTA_CLICKED, {
        'CTA Type': type,
        ...this.mixpanelBaseData
      });
      this.notify('Thank you! Our team will contact you soon.', 'success');
    },
    requestCheckInFeature() {
      this.mixpanel.track(mixpanelEvents.MODULE.SELF_CHECK_IN.SELF_CHECK_IN_REQUESTED, {
        'Entry Point': 'Gate Management Panel',
        ...this.mixpanelBaseData
      });
      this.notify('Thank you! Our team will contact you soon.', 'success');
    }
  },
  computed: {
    userRole() {
      return this.$me?.role;
    },
    mixpanelBaseData() {
      return {
        'Org ID': this.$me.orgId,
        'Org Name': this.$me.orgName,
        'Warehouse ID': this.warehouse.id,
        'Warehouse Name': this.warehouse.name
      };
    },
    isCurrentUserAdminOrOwner() {
      return (
        this.userRole === this.novaCore.UserRole.ADMIN ||
        this.userRole === this.novaCore.UserRole.OWNER
      );
    },
    linkGateManagement() {
      return 'https://lp.opendock.com/gate-management?utm_source=salescollateral&utm_medium=hyperlink&utm_campaign=opendock-gate-management-owner-admin-warehouse-opendock-q2-24&utm_content=hyperlink';
    },
    linkGateManagementOthers() {
      return 'https://lp.opendock.com/gate-management?utm_source=salescollateral&utm_medium=hyperlink&utm_campaign=opendock-gate-management-operator-attendant-warehouse-opendock-q2-24&utm_content=hyperlink';
    },
    linkContactSales() {
      return 'https://lp.opendock.com/gate-management?utm_source=opendockapp&utm_medium=cta&utm_campaign=opendock-gate-management-warehouse-opendock-q2-24&utm_content=landing-page';
    },
    mailToContent() {
      const body =
        'Hi!\n' +
        'I just found out about Opendock’s Gate Management tool, which I think could really benefit our facility operations.\n' +
        'It offers features like self check-in for drivers, auto appointment status updates, real-time alerts on planned and unplanned arrivals, and upcoming 2-way SMS communication with drivers.\n' +
        '\n' +
        'For more information or to request a trial, visit the Gate Management website at https://lp.opendock.com/gate-management.';

      const subject = 'Check out Opendock’s Gate Management';

      return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }
  }
};
</script>
