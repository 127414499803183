var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog",
    {
      ref: "contextMenu",
      staticClass: "context-menu d-flex flex-column",
      style: _vm.getPositionStyle,
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
        mousedown: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "mt-2",
          attrs: { icon: "", absolute: "", right: "" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("close")
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      _c("div", { staticClass: "pa-4 black--text" }, [
        _c(
          "span",
          { staticClass: "d-flex flex-row align-center" },
          [
            _c("v-icon", { attrs: { color: "black", "x-small": "" } }, [
              _vm._v("mdi-truck"),
            ]),
            _c("span", { staticClass: "d-inline-block ml-2" }, [
              _vm._v(_vm._s(_vm.appointment.companyName)),
            ]),
          ],
          1
        ),
        _c("span", { staticClass: "d-block" }, [
          _c("strong", [_vm._v(_vm._s(_vm.appointment.refNumLabel))]),
          _vm._v(" : " + _vm._s(_vm.appointment.refNumber) + " "),
        ]),
      ]),
      _c("v-divider"),
      _vm._m(0),
      _c("status-manager-old", {
        staticClass: "mb-2",
        attrs: {
          appointment: _vm.appointment,
          "mixpanel-entry-point": "Quick Status Change",
          "warehouse-triggers": _vm.$selectedWarehouseTriggers,
          dense: "",
          compact: "",
        },
        on: {
          "status-change": function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _c("v-divider"),
      _vm.canCancel || _vm.isCancelled
        ? [
            !_vm.isCancelled && _vm.$rolePermissions.canUpdateAppointment
              ? _c(
                  "v-btn",
                  {
                    staticClass: "text__error font-weight-bold my-2",
                    attrs: {
                      elevation: "0",
                      color: "white",
                      disabled:
                        _vm.appointment.status ===
                        _vm.novaCore.AppointmentStatus.Cancelled,
                    },
                    on: { click: _vm.confirmCancel },
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2 colored" }, [
                      _vm._v(_vm._s(_vm.cancelButtonIcon)),
                    ]),
                    _vm._v(" " + _vm._s(_vm.cancelAppointmentLabel) + " "),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _c("cancel-appointment-dialog-old", {
        ref: "cancelAppointment",
        attrs: {
          "mixpanel-entry-point": "Quick Status Change",
          "external-activator": true,
          "show-dialog": _vm.showConfirmDialog,
          appointment: _vm.appointment,
        },
        on: {
          confirm: function ($event) {
            return _vm.$emit("confirm")
          },
          close: function ($event) {
            _vm.showConfirmDialog = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pt-4 pr-4 pb-1 pl-4 black--text" }, [
      _c("span", { staticClass: "sub-title text-left d-block" }, [
        _vm._v("Change Status To"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }