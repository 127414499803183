var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: `Features Setup for ${_vm.org.name}`,
          "loading-message": "Loading features...",
          width: "800px",
          loading: _vm.loading,
          "max-width": "100%",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _vm._e(),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("custom-forms-features-form", {
                    attrs: {
                      disabled: !_vm.loading,
                      "org-id": _vm.org.id,
                      "org-name": _vm.org.name,
                    },
                    on: {
                      loading: (value) => (_vm.loading = value),
                      close: _vm.close,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }