<template>
  <v-container>
    <v-row class="my-2">
      <v-col cols="9">
        <p class="mt-1">
          Keep records of
          <b>damages, security measures</b>
          and other evidence to support
          <b>claim disputes:</b>
        </p>
      </v-col>
      <v-col cols="3" class="d-flex justify-end">
        <v-menu class="dropdown" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-bind="attrs" v-on="on">
              Update form
              <v-icon small class="ml-2">mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="trigger of claimSupportTriggers" :key="trigger.feature">
              <v-btn text small @click="activeTrigger = trigger" :disabled="!trigger.isActive">
                <v-icon small class="mr-2">mdi-text-box-edit-outline</v-icon>
                {{ featureData(trigger.feature).title }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <v-simple-table dense class="appointment-details-table" v-if="!loading">
          <template v-slot:default>
            <tbody>
              <template v-for="trigger of claimSupportTriggers">
                <tr :key="trigger.id">
                  <td class="font-weight-bold pt-5">
                    {{ featureData(trigger.feature).title }}
                  </td>
                  <td class="pt-5">
                    <custom-forms-data
                      :key="trigger.id"
                      :trigger="trigger"
                      :object-id="appointment.id"
                      :timezone="warehouse.timezone"
                      :military-time-enabled="
                        $isMilitaryTimeEnabled(warehouse)
                      "></custom-forms-data>
                  </td>
                </tr>
                <tr class="divider" :key="`${trigger.id}_divider`">
                  <td colspan="2"></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <custom-forms-flow-dialog-old
      :external-activator="true"
      :warehouse="warehouse"
      :show-dialog="hasActiveTrigger"
      :object-id="appointment.id"
      :trigger="activeTrigger"
      @close="cleanUpCustomFormsData"
      @update="updateCustomFormsData"
      @create="
        value => createBulkCustomFormsData(value, warehouse.id)
      "></custom-forms-flow-dialog-old>
  </v-container>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';

export default {
  extends: AppointmentDetailsBase
};
</script>
