<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for the status timeline editor
 * @displayName Status Timeline Edit Dialog Base
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Alert message to display in dialog
     */
    alertMessage: {
      type: String,
      required: false
    },
    /**
     * Alert type
     * @values success | error
     */
    alertType: {
      type: String,
      required: false,
      default: 'success'
    },
    appointment: {
      type: Object,
      required: true
    },
    statusToEdit: {
      type: String,
      required: true
    },
    mixpanelEntryPoint: {
      type: String,
      required: false
    }
  },
  computed: {
    dialogHeader() {
      switch (this.statusToEdit) {
        case this.novaCore.AppointmentStatus.Arrived:
          return 'When did the carrier arrive?';
        case this.novaCore.AppointmentStatus.Completed:
          return 'When was the appointment completed?';
        case this.novaCore.AppointmentStatus.InProgress:
          return 'When did the loading/unloading start?';
        default:
          return 'Edit';
      }
    }
  }
};
</script>
