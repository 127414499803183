<template>
  <v-card v-if="ready" class="elevation-0">
    <v-card-text class="pa-1">
      <bar-chart
        title="Appointments by Dock by Day of Week (including parallel capacity)"
        :data="chartData"
        :categories="categories"
        :stacked="true"
        :colors="colors"
        :show-data-labels="true">
      </bar-chart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
</template>

<script>
import insightsMixin from '@/components/mixins/insightsMixin';

/**
 * Appointments By Week Day Bar Chart
 * @displayName Appointments By Week Day Bar Chart
 */
export default {
  mixins: [insightsMixin],
  data() {
    return {
      apiEndpoint: 'day-of-week',
      categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      useCurrentWeek: true
    };
  },
  methods: {
    formatXAxis(data) {
      return this.novaCore.ucWords(data);
    },
    formatData() {
      this.chartData = [];

      for (let dockName in this.rawData) {
        const data = Object.values(this.rawData[dockName]);

        if (this.novaCore.isCapacityChildByName(dockName) && this.consolidateCapacityDocks) {
          dockName = this.novaCore.getCapacityParentNameFromCapacityChildName(dockName);
        }

        // Find on the existing chart data the parent dock data
        const capacityDockIndex = this.chartData.findIndex(c => c.name === dockName);

        // If there are already some data for the capacity docks
        // let's aggregate the appointment count
        if (capacityDockIndex !== -1) {
          this.chartData[capacityDockIndex].data = data.map(
            (num, idx) => num + this.chartData[capacityDockIndex].data[idx]
          );
        } else {
          this.chartData.push({
            name: dockName,
            data
          });
        }
      }
      this.ready = true;
    }
  },
  watch: {
    consolidateCapacityDocks() {
      this.formatData();
    }
  }
};
</script>
