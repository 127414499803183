var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-base",
    { attrs: { header: _vm.header } },
    [
      _c(
        "template",
        { slot: "form" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-container",
                [
                  _c("text-field", {
                    attrs: {
                      required: true,
                      type: "text",
                      rules: _vm.$validator.rules.required("Org Name"),
                      label: "Name",
                    },
                    model: {
                      value: _vm.orgName,
                      callback: function ($$v) {
                        _vm.orgName = $$v
                      },
                      expression: "orgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "form-actions" },
        [
          _c("action-group", {
            attrs: { "confirm-icon": "plus-circle", "confirm-label": "Create" },
            on: {
              cancel: function ($event) {
                return _vm.$emit("close")
              },
              confirm: _vm.submit,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }