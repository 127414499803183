<template>
  <v-select
    ref="select"
    height="20px"
    :disabled="disabled"
    :items="items"
    v-bind="[$props, $attrs]"
    @input="value => $emit('input', value || '')"
    dense
    clearable
    open-on-clear>
    <template #label>Select a Warehouse</template>
  </v-select>
</template>

<script>
export default {
  name: 'warehouse-list-select',
  mixins: [],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
