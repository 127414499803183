var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Create new field",
          "loading-message": "Saving...",
          scrollable: false,
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("custom-forms-field-form", {
                  attrs: {
                    field: _vm.field,
                    "label-suggestion": _vm.labelSuggestion,
                  },
                  on: { save: _vm.handleSaveEvent, close: _vm.close },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }