<template>
  <div class="overflow-x-hidden">
    <div
      v-html="notificationHtml"
      style="line-height: initial !important; color: black !important"></div>
  </div>
</template>

<script>
export default {
  props: {
    notificationKey: {
      type: String,
      required: true
    },
    previewData: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      notificationHtml: null,
      notificationSubject: null
    };
  },
  async mounted() {
    if (!this.previewData) {
      await axios.get(`/appointment/email-notification-html/${this.notificationKey}`).then(r => {
        this.notificationHtml = r.data.bodyHtml;
        this.notificationSubject = r.data.subject;
      });
    } else {
      this.notificationHtml = this.previewData.html;
      this.notificationSubject = this.previewData.subject;
    }
  },
  watch: {
    previewData: {
      handler() {
        if (this.previewData) {
          this.notificationHtml = this.previewData.html;
          this.notificationSubject = this.previewData.subject;
        }
      },
      deep: true
    }
  }
};
</script>
