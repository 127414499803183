<template>
  <feature-flag name="enable-warehouse-groups-page">
    <template #enabled>
      <div class="py-3">
        <warehouse-group-list></warehouse-group-list>
      </div>
    </template>
  </feature-flag>
</template>

<script>
/**
 * Warehouse Groups Page
 * @displayName Warehouse Groups Page
 */
export default {
  props: {}
};
</script>
