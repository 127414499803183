<template>
  <div :key="renderKey">
    <v-card :disabled="loading">
      <v-card-title class="pb-0">
        <v-container fluid>
          <v-row class="align-end">
            <v-col>
              <h4>Date Range</h4>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                class="mb-0 pb-0"
                hide-details
                :items="savedReportSearches"
                filled
                dense
                background-color="#FFFFFF"
                clearable
                item-text="title"
                item-value="title"
                return-object
                v-model="loadedReportSearch"
                ref="savedReportSearchSelect"
                label="Load saved search"
                @input="loadReportSearch">
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ item.title }}</span>
                          <v-spacer></v-spacer>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon @click="deleteReportSearch(item, $event)">
                        <v-icon color="error">mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <div class="pa-4 d-flex align-center elevation-2">
            <div class="pr-10">
              <date-range
                :label="
                  filters.dateField === novaCore.ReportDateFields.APPOINTMENT_DATE
                    ? 'Appointment Date'
                    : 'Creation Date'
                "
                ref="apptReportDateRange"
                :dense="false"
                use-defined-ranges
                initial-defined-range="wtd"
                v-model="filters.appointmentDateRange"></date-range>
            </div>
            <v-divider vertical class="my-5"></v-divider>
            <div>
              <v-radio-group v-model="filters.dateField" class="pl-5" row>
                <span class="font-weight-bold mr-3">Report On:</span>
                <v-radio
                  :label="novaCore.ReportDateFields.APPOINTMENT_DATE"
                  :value="novaCore.ReportDateFields.APPOINTMENT_DATE"></v-radio>
                <v-radio
                  :label="novaCore.ReportDateFields.CREATION_DATE"
                  :value="novaCore.ReportDateFields.CREATION_DATE"></v-radio>
              </v-radio-group>
            </div>
            <v-spacer></v-spacer>
            <v-divider vertical class="my-5"></v-divider>
            <div style="flex: 1" class="pl-10">
              <exportable-fields-select
                :minimum-selections="1"
                outlined
                :single-line="false"
                :dense="true"
                hide-details
                label="Fields to Export"
                class="pt-0"
                :visible-selection-count="1"
                placeholder="Export Fields"
                v-model="filters.exportFields"></exportable-fields-select>
              <primary-button
                class="mr-1 float-end"
                :loading="buildingExcelData"
                :disabled="total < 1"
                before-icon="microsoft-excel"
                @click="handleExportReportButtonClick()">
                Export Report
              </primary-button>
            </div>
          </div>

          <div class="d-flex align-center pt-5 pb-1">
            <h4>Filtering Options</h4>
          </div>

          <v-container class="elevation-3 mx-1" fluid data-testid="reporting-page-filter-box">
            <v-form ref="form">
              <v-row>
                <v-col xs="12" md="3">
                  <warehouse-multi-select
                    :single-line="false"
                    hide-icon
                    outlined
                    :rules="
                      $validator.rules.selectLimit(
                        novaCore.GlobalLimits.MAX_REPORTING_FILTER_SELECTIONS.value,
                        'Warehouse'
                      )
                    "
                    :should-disable-item-if-no-docks="false"
                    :is-flex="false"
                    ref="reportingWarehouseSelect"
                    disable-select-all
                    :dense="true"
                    placeholder="Warehouses"
                    :label="warehouseSelectLabel"
                    :maximum-selections="
                      novaCore.GlobalLimits.MAX_REPORTING_FILTER_SELECTIONS.value
                    "
                    v-model="filters.warehouses"
                    :joins="['docks||id,name']"
                    :select-fields="['id,name,facilityNumber']"
                    show-store-number></warehouse-multi-select>
                </v-col>
                <v-col xs="12" md="3">
                  <custom-tag-select
                    v-model="filters.tags"
                    hide-details
                    dense
                    :label="customTagsSelectLabel"
                    outlined></custom-tag-select>
                </v-col>
                <v-col xs="12" md="3">
                  <basic-dock-select
                    ref="dock-select"
                    outlined
                    dense
                    :label="dockSelectLabel"
                    multiple
                    :should-select-new-docks="false"
                    :maximum-selections="
                      novaCore.GlobalLimits.MAX_REPORTING_FILTER_SELECTIONS.value
                    "
                    disable-select-all
                    :rules="
                      $validator.rules.selectLimit(
                        novaCore.GlobalLimits.MAX_REPORTING_FILTER_SELECTIONS.value,
                        'Docks'
                      )
                    "
                    :hide-inactive-docks="false"
                    v-model="filters.docks"
                    :select-fields="['id,name,isActive,loadTypeIds']"
                    :warehouse-ids="selectedWarehouseIds"
                    hide-icon
                    @dockListUpdate="handleDockListUpdate"></basic-dock-select>
                </v-col>
                <v-col xs="12" md="3">
                  <basic-load-type-select
                    v-model="filters.loadTypes"
                    :label="loadTypeSelectLabel"
                    multiple
                    disable-select-all
                    dense
                    select-all-on-fetch
                    :maximum-selections="
                      novaCore.GlobalLimits.MAX_REPORTING_FILTER_SELECTIONS.value
                    "
                    :show-direction-in-name="true"
                    :docks="dockObjects"
                    :rules="
                      $validator.rules.selectLimit(
                        novaCore.GlobalLimits.MAX_REPORTING_FILTER_SELECTIONS.value,
                        'Load Types'
                      )
                    "
                    :fetch-load-types-on-mount="hasSelectedWarehouses"
                    :warehouse-ids="selectedWarehouseIds"
                    outlined></basic-load-type-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <basic-carrier-select
                    multiple
                    outlined
                    clearable
                    ref="basicCarrierSelect"
                    :label="carrierSelectLabel"
                    disable-select-all
                    should-get-booked-only
                    hide-details
                    :maximum-selections="
                      novaCore.GlobalLimits.MAX_REPORTING_FILTER_SELECTIONS.value
                    "
                    :rules="
                      $validator.rules.selectLimit(
                        novaCore.GlobalLimits.MAX_REPORTING_FILTER_SELECTIONS.value,
                        'Carrier'
                      )
                    "
                    dense
                    @ready="areCarriersFetched = true"
                    v-model="filters.carriers"></basic-carrier-select>
                </v-col>
                <v-col xs="12" md="3">
                  <v-select
                    hide-details
                    outlined
                    dense
                    :items="Object.values(novaCore.AppointmentCreatorTypeReportOptions)"
                    multiple
                    v-model="filters.appointmentCreatorTypes"
                    :label="createdBySelectLabel"></v-select>
                </v-col>
                <v-col xs="12" md="3">
                  <status-select
                    v-model="filters.statuses"
                    :single-line="false"
                    outlined
                    :placeholder="appointmentStatusSelectLabel"
                    classes="pt-0"
                    hide-icon
                    should-disable-auto-select
                    :dense="true"></status-select>
                </v-col>
                <v-col xs="12" md="3">
                  <v-select
                    outlined
                    dense
                    :items="Object.values(novaCore.AppointmentType)"
                    multiple
                    v-model="filters.appointmentTypes"
                    :label="appointmentTypeSelectLabel"></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col>
                  <v-btn text color="#195a87" @click="clearAllFilters" small>
                    Clear All Filters
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-row class="mt-5 pr-2">
            <v-btn
              color="secondary"
              @click="handleSearchUpdate"
              class="mr-2 ml-4"
              :disabled="!loadedReportSearch">
              <v-icon left>mdi-update</v-icon>
              Update Current Search
            </v-btn>
            <save-report-search-dialog
              action-button-text="Save New Search"
              @save-report-search="saveReportSearch"></save-report-search-dialog>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="fetchData" :loading="loading">
              <v-icon left>mdi-send</v-icon>
              Run Report
            </v-btn>
          </v-row>
        </v-container>
      </v-card-title>

      <template v-if="loading">
        <v-card class="mx-8 mt-8" elevation="0">
          <v-card-text>
            Loading report data...
            <v-progress-linear
              color="secondary"
              indeterminate
              rounded
              height="8"></v-progress-linear>
          </v-card-text>
        </v-card>
      </template>

      <v-card-text class="px-8 mt-8" v-else-if="isDataFetchedOnce">
        <v-row class="px-4 pb-4">
          <v-col>
            <h2>Report Data</h2>
          </v-col>
          <v-spacer></v-spacer>
          <span class="align-self-end font-size-large black--text font-weight-bold">
            {{ total ? `Total Appointments: ${total}` : '' }}
          </span>
        </v-row>

        <v-alert outlined type="warning" prominent border="left" v-if="total > limit" class="mt-2">
          The number of appointments matching these filters (
          <strong>{{ total }}</strong>
          ) exceeds the limit
          <strong>{{ limit }}</strong>
          . Only
          <strong>{{ limit }}</strong>
          will be available in this table.
          <br />
          <strong>However all {{ total }} appointments can be exported</strong>
          .
        </v-alert>
        <v-data-table
          :headers="tableHeaders"
          :items="appointments"
          :loading="!warehousesAreLoaded()"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :footer-props="footerProps"
          :options.sync="options">
          <template v-slot:item.status="{ item }">
            <div :class="`${item.status.toLowerCase()} tile font-size-x-small text-center`">
              <v-icon class="mr-1" v-if="novaCore.isRequested(item.status)">mdi-clock-alert</v-icon>
              <strong
                :class="{ 'black--text': novaCore.isRequested(item.status) }"
                :inner-html.prop="novaCore.breakWordsAtCaps(item.status)"></strong>
            </div>
          </template>

          <template v-slot:item.dock.name="{ item }">
            {{ novaCore.getDockNameWithActiveStatus(item.dock) }}
          </template>

          <template v-slot:item.start="{ item }">
            <span>
              {{
                getTimeInWarehouseTimezone(
                  item,
                  novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
                  novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
                )
              }}
            </span>
          </template>

          <template v-slot:item.creator_type="{ item }">
            <span>{{ makeAppointmentCreatorType(item.createdByUser) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog max-width="600" v-model="shouldShowEmailCCsDialog">
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="mb-4">
            <span class="headline">Change email destination</span>
            <v-spacer></v-spacer>
            <v-btn class="pa-3" icon light @click="shouldShowEmailCCsDialog = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <small>* Max 10 email destinations</small>
            <email-list-field
              v-model="emailCCs"
              ref="emailFieldList"
              small-chips
              required
              small></email-list-field>
          </v-card-text>
          <v-card-actions class="justify-end mx-2 my-2">
            <v-btn outlined @click="shouldShowEmailCCsDialog = false">Nevermind</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="buildingExcelData" color="primary" @click="exportReportAndEmail">
              Build and Email
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog max-width="600" v-model="shouldShowDeliveryMethodDialog">
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title>
            <span class="headline">Report Delivery Method</span>
            <v-spacer></v-spacer>
            <v-btn class="pa-3" icon light @click="shouldShowDeliveryMethodDialog = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p class="body-1">
              This is a large report and may take some time to generate, and the request may timeout
              if you choose the download option. We suggest that you send this report to your email
              address instead
              <strong>({{ $me.email }})</strong>
              .
            </p>
          </v-card-text>
          <v-card-actions class="justify-end mx-2 my-2">
            <v-btn outlined @click="shouldShowDeliveryMethodDialog = false">Nevermind</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="buildingExcelData" color="warning" @click="exportReport(false)">
              Build and Download Now
            </v-btn>
            <v-btn
              :loading="buildingExcelData"
              color="success"
              @click="shouldShowEmailCCsDialog = true">
              Build and Email
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import { isArray, isObject } from 'class-validator';

export default {
  name: 'AppointmentReportingTable',
  mixins: [dataListMixin],
  data() {
    return {
      appointments: [],
      loading: false,
      isDataFetchedOnce: false,
      joins: [
        'dock||name,loadTypeIds,warehouseId',
        'dock.warehouse',
        'loadType||name,duration_min',
        'user',
        'user.company',
        'org'
      ],
      filters: {
        warehouses: [],
        statuses: [],
        appointmentDateRange: {},
        exportFields: [],
        carriers: [],
        dateField: this.novaCore.ReportDateFields.APPOINTMENT_DATE,
        docks: [],
        loadTypes: [],
        appointmentCreatorTypes: [],
        appointmentTypes: [],
        tags: [],
        allCarriers: false
      },
      limit: 1000,
      total: null,
      sortBy: [],
      areCarriersFetched: false,
      savedReportSearches: [],
      loadedReportSearch: null,
      clearLoadedSearchOnFilterChange: false,
      cachedExportAppointments: [],
      renderKey: 1,
      dockObjects: [],
      shouldShowDeliveryMethodDialog: false,
      shouldShowEmailCCsDialog: false,
      emailCCs: [],
      buildingExcelData: false
    };
  },
  computed: {
    tableHeaders() {
      return [
        { text: 'Conf #', value: 'confirmationNumber', width: 70, searchable: true },
        { text: 'Start Time', value: 'start', searchable: true },
        { text: 'Carrier Contact', value: 'user.email', searchable: true },
        { text: 'Carrier Company', value: 'user.company.name', searchable: true, sortable: true },
        {
          text: 'Location',
          value: 'dock.warehouse.name',
          searchable: true
        },
        { text: 'Dock', value: 'dock.name', searchable: true },
        { text: 'Load Type', value: 'loadType.name', searchable: true },
        { text: 'Direction', value: 'loadType.direction', searchable: true },
        { text: 'Tags', value: 'tags', searchable: true, align: ' d-none' },
        { text: 'Custom Fields', value: 'customFields', searchable: true, align: ' d-none' },
        { text: 'Creator Type', value: 'creator_type', searchable: true },
        {
          text: this.$refNumSettings(this.$org).displayName,
          value: 'refNumber',
          searchable: true
        },
        { text: 'Status', value: 'status', searchable: true },
        { text: 'Appointment ID', value: 'id', searchable: true }
      ];
    },
    defaultExportableFields() {
      return Object.keys(this.novaCore.ExportableFields);
    },
    exportableFields() {
      return this.filters.exportFields;
    },
    selectedWarehouseIds() {
      let warehouseIds = [];
      if (isObject(this.filters.warehouses)) {
        warehouseIds = [this.filters.warehouses?.id];
      } else if (isArray(this.filters.warehouses)) {
        warehouseIds = this.filters.warehouses.map(wh => {
          return typeof wh === 'string' ? wh : wh.id;
        });
      }
      return warehouseIds;
    },
    hasSelectedWarehouses() {
      if (isObject(this.filters.warehouses)) {
        return Object.keys(this.filters.warehouses).length > 0;
      } else {
        return this.filters.warehouses.length > 0;
      }
    },
    warehouseSelectLabel() {
      return this.hasSelectedWarehouses ? 'Warehouses' : 'All Warehouses';
    },
    customTagsSelectLabel() {
      return this.filters.tags.length === 0 ? 'Any Custom Tags' : 'Custom Tags';
    },
    dockSelectLabel() {
      return this.filters.docks.length === 0 ? 'All Docks' : 'Docks';
    },
    loadTypeSelectLabel() {
      return this.filters.loadTypes.length === 0 ? 'All Load Types' : 'Load Types';
    },
    carrierSelectLabel() {
      return this.filters.carriers.length === 0 ? 'All Carrier Contacts' : 'Carrier Contacts';
    },
    createdBySelectLabel() {
      return this.filters.appointmentCreatorTypes.length === 0 ? 'Any Created By' : 'Created By';
    },
    appointmentStatusSelectLabel() {
      return this.filters.statuses.length === 0
        ? 'Any Appointment Statuses'
        : 'Appointment Statuses';
    },
    appointmentTypeSelectLabel() {
      return this.filters.appointmentTypes.length === 0
        ? 'Any Appointment Types'
        : 'Appointment Types';
    }
  },
  watch: {
    filters: {
      handler() {
        // Remove cached export appointments if filters change
        this.cachedExportAppointments = [];
      },
      deep: true
    },
    'filters.allCarriers'(newVal) {
      if (newVal) {
        this.$refs.basicCarrierSelect.selectAllItems();
      }
    },
    'filters.warehouses'(newVal) {
      if (isObject(newVal)) {
        this.filters.warehouses = [newVal.id];
      }
    }
  },
  methods: {
    async fetchData() {
      if (!this.$refs.form.validate()) {
        this.notify('Please fix form errors', 'error');
        return;
      }
      this.loading = true;
      this.isDataFetchedOnce = true;

      axios
        .post('/metrics/appointments', this.getQuery(true, true))
        .then(r => {
          if (r?.data?.data) {
            this.total = r.data.data.total;
            this.appointments = r.data.data.data;
          }
        })
        .finally(() => {
          setTimeout(() => (this.loading = false), 1000);
        });
    },
    getQuery(includeLimit = true, skipCustomFields = false) {
      const filters = this.getFilters(true);

      if (includeLimit) {
        filters.limit = this.limit;
      }

      // to skip customFields when calling for the reports page
      filters.skipCustomFields = skipCustomFields;

      return filters;
    },
    getFilters(forQuery) {
      const rangeData = this.filters.appointmentDateRange;
      const filters = {
        warehouseIds: this.selectedWarehouseIds,
        statuses: this.filters.statuses,
        fromDate: rangeData.range[0],
        toDate: rangeData.range[1],
        dateField: this.filters.dateField,
        loadTypeIds: this.filters.loadTypes.map(lt => {
          return typeof lt === 'string' ? lt : lt.id;
        }),
        dockIds: this.filters.docks,
        appointmentCreatorTypes: this.filters.appointmentCreatorTypes,
        carrierIds: this.filters.carriers.map(carrier => {
          return typeof carrier === 'string' ? carrier : carrier.id;
        }),
        appointmentTypes: this.filters.appointmentTypes,
        tags: this.filters.tags ? this.novaCore.pluck(this.filters.tags, 'name') : [],
        allCarriers: this.filters.allCarriers,
        skipCustomFields: false
      };

      if (!forQuery) {
        filters.exportFields = this.filters.exportFields;
      }

      if (!forQuery && rangeData.definedRange) {
        filters.toDate = null;
        filters.fromDate = null;
        filters.definedRange = rangeData.definedRange;
      }

      return filters;
    },
    async saveReportSearch(data) {
      await axios
        .post(`org/${this.$org.id}/report-search`, {
          ...this.getFilters(false),
          ...{ title: data.title }
        })
        .then(() => {
          this.getSavedReportSearches();
          this.notify('Report search has been saved successfully');
        })
        .finally(() => {
          this.trackSaveReportMixpanelEvent(data.title);
        });
    },
    trackSaveReportMixpanelEvent(title) {
      this.mixpanel.track(this.mixpanel.events.MODULE.REPORTING.NEW_SEARCH_SAVED, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Report Search Name': title,
        'Report Fields': this.getFilters(false).exportFields,
        'Report On': this.getFilters(false).dateField
      });
    },
    async getSavedReportSearches() {
      await axios.get(`org/${this.$org.id}/report-search`).then(res => {
        this.savedReportSearches =
          this.novaCore.sortBy(
            Object.keys(res?.data || {}).map(reportSearchKey => {
              return { ...res.data[reportSearchKey], ...{ key: reportSearchKey } };
            }),
            'title'
          ) || [];
      });
    },
    loadReportSearch(reportSearch) {
      if (!reportSearch) {
        this.renderKey++;
        return;
      }

      this.clearLoadedSearchOnFilterChange = false;
      this.setSelectedWarehousesForReportSearch(reportSearch);
      this.filters.statuses = reportSearch.filters.statuses;
      // For backwards compatibility with previous reportSearch schema
      this.filters.exportFields = reportSearch.filters.exportFields || this.defaultExportableFields;

      this.filters.tags = (reportSearch.filters.tags || [])
        .map(tagName => {
          return this.util.getCustomTagData(this.$org.customTags, tagName);
        })
        .filter(Boolean);

      if (!reportSearch.filters.definedRange) {
        this.$refs.apptReportDateRange.setDates([
          reportSearch.filters.toDate,
          reportSearch.filters.fromDate
        ]);
      } else {
        this.$refs.apptReportDateRange.setCurrentDefinedRange(reportSearch.filters.definedRange);
      }

      if (reportSearch.filters.appointmentCreatorTypes?.length) {
        this.filters.appointmentCreatorTypes = reportSearch.filters.appointmentCreatorTypes;
      }

      if (reportSearch.filters.carrierIds?.length) {
        this.filters.carriers = reportSearch.filters.carrierIds;
      }

      if (reportSearch.filters.dateField) {
        this.filters.dateField = reportSearch.filters.dateField;
      }

      if (reportSearch.filters.loadTypeIds?.length) {
        this.filters.loadTypes = reportSearch.filters.loadTypeIds;
      }

      if (reportSearch.filters.dockIds?.length) {
        this.filters.docks = reportSearch.filters.dockIds;
      }

      if (reportSearch.filters.appointmentTypes?.length) {
        this.filters.appointmentTypes = reportSearch.filters.appointmentTypes;
      }

      if (
        typeof reportSearch.filters.allCarriers !== 'undefined' &&
        reportSearch.filters.allCarriers !== null
      ) {
        this.filters.allCarriers = reportSearch.filters.allCarriers;
      }

      this.trackLoadSearchMixpanelEvent(reportSearch);
      setTimeout(() => {
        this.clearLoadedSearchOnFilterChange = true;
      }, 2500);
    },
    trackLoadSearchMixpanelEvent(reportSearch) {
      this.mixpanel.track(this.mixpanel.events.MODULE.REPORTING.SEARCH_LOADED, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Report Search Name': reportSearch.title,
        'Report Fields': reportSearch.filters.exportFields,
        'Report On': reportSearch.filters.dateField
      });
    },
    setSelectedWarehousesForReportSearch(reportSearch) {
      const warehouseIds = reportSearch.filters.warehouseIds;
      const selectableWarehouses = this.$refs.reportingWarehouseSelect.warehouses;

      const missingWarehouses = reportSearch.filters.warehouseIds.filter(
        whId => !selectableWarehouses.some(swh => swh.id === whId)
      );

      if (missingWarehouses.length > 1 && missingWarehouses.length === warehouseIds.length) {
        this.notify(
          `All ${missingWarehouses.length} warehouses in this report no longer exist.`,
          'error'
        );
      } else if (missingWarehouses.length > 0) {
        const s = missingWarehouses.length === 1;
        this.notify(
          `${missingWarehouses.length} warehouse${s ? '' : 's'} in this report no longer exist${
            s ? 's' : ''
          }.`,
          'error'
        );
      }
      this.filters.warehouses = warehouseIds.filter(whId => !missingWarehouses.includes(whId));
    },
    async deleteReportSearch(report, event) {
      event.stopPropagation();

      this.$confirm(`Are you sure you want to delete the saved report "${report.title}"?`, {
        color: 'warning'
      }).then(async confirmed => {
        if (confirmed) {
          await axios.delete(`org/${this.$org.id}/report-search/${report.key}`).then(() => {
            this.getSavedReportSearches();
            this.notify('Report search has been deleted!');
            this.$refs.savedReportSearchSelect.isMenuActive = true;
            this.$refs.savedReportSearchSelect.isFocused = true;
          });
        }
      });
    },
    getTimeInWarehouseTimezone(appointment, format12, format24) {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        appointment.utcStart ?? appointment.start,
        appointment.dock.warehouse.timezone,
        format12,
        this.$isMilitaryTimeEnabled(this.$org),
        format24
      );
    },
    warehousesAreLoaded() {
      return this.$refs.reportingWarehouseSelect?.warehousesLoaded;
    },
    async handleExportReportButtonClick() {
      if (this.total > this.limit) {
        this.shouldShowDeliveryMethodDialog = true;
      } else {
        await this.exportReport();
      }
    },
    async exportReportAndEmail() {
      if (this.$refs.emailFieldList.validate()) {
        if (this.emailCCs.length > 0 && this.emailCCs.length <= 10) {
          return this.exportReport(true);
        } else {
          this.notify('Too many destination emails. Max 10 emails.', 'warning');
        }
      } else {
        this.notify('Invalid email destination', 'warning');
      }
    },
    async exportReport(shouldEmail) {
      this.buildingExcelData = true;
      const params = {};

      if (shouldEmail) {
        params.emailDelivery = true;
        params.emailCCs = this.emailCCs;
      }

      await axios
        .post(
          `/metrics/appointments/excel`,
          {
            ...this.getQuery(false),
            exportFields: this.filters.exportFields
          },
          { params }
        )
        .then(r => {
          if (shouldEmail) {
            this.notify(
              `Report has been emailed to ${
                this.emailCCs?.length > 0 ? this.emailCCs.join(', ') : this.$me.email
              }.`
            );
          } else {
            if (r.data?.url && typeof r.data.url === 'string' && r.data.url !== 'undefined') {
              this.notify('Report is generating and will be downloaded shortly.');
              window.location = r.data.url;
            } else {
              if (this.total > this.limit && !shouldEmail) {
                this.notify(
                  'Report is too large for immediate download. Please select the "build and email" option when exporting.',
                  'error'
                );
              } else {
                this.notify(
                  'An error occurred generating the report. Please try again later.',
                  'error'
                );
              }
            }
          }
        })
        .finally(() => {
          this.buildingExcelData = false;
          this.shouldShowDeliveryMethodDialog = false;
          this.shouldShowEmailCCsDialog = false;
          this.setEmailCCs();
          this.trackExportMixpanelEvent();
        });

      return this.cachedExportAppointments;
    },
    trackExportMixpanelEvent() {
      this.mixpanel.track(this.mixpanel.events.MODULE.REPORTING.REPORTING_EXPORTED, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Report Fields': this.getFilters(false).exportFields,
        'Report On': this.getFilters(false).dateField
      });
    },
    shouldGetData() {
      return false;
    },
    async handleSearchUpdate() {
      this.$confirm(
        `Are you sure you want to update the <strong>${this.loadedReportSearch.title}</strong> report search with the currently selected filters?`
      ).then(async confirmed => {
        if (confirmed) {
          await axios
            .patch(
              `org/${this.$org.id}/report-search/${this.loadedReportSearch.key}`,
              this.getFilters(false)
            )
            .then(() => {
              this.getSavedReportSearches();
              this.notify('Report search has been saved successfully');
            });
        }
      });
    },
    handleDockListUpdate(docks) {
      this.dockObjects = docks;
    },
    clearAllFilters() {
      this.filters.warehouses = [];
      this.filters.statuses = [];
      this.filters.carriers = [];
      this.filters.docks = [];
      this.filters.loadTypes = [];
      this.filters.appointmentCreatorTypes = [];
      this.filters.appointmentTypes = [];
      this.filters.tags = [];
    },
    setEmailCCs() {
      this.emailCCs = [this.$me.email];
    }
  },
  mounted() {
    this.setEmailCCs();
    this.getSavedReportSearches();
  }
};
</script>
