<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Log in as carrier"
    @close="close"
    @confirm="submit">
    <template v-slot:activator="slotProps">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <template #body v-if="user">
      <v-card class="mb-6">
        <v-card-title>
          <p class="mb-1">Carrier user data</p>
        </v-card-title>
        <v-card-text>
          <p>
            <v-icon small>mdi-truck</v-icon>
            <strong>Company:</strong>
            {{ user.company?.name ?? '--' }}
          </p>
          <p>
            <v-icon small>mdi-account-box</v-icon>
            <strong>Name:</strong>
            {{ user.firstName }}
            {{ user.lastName }}
          </p>
          <p>
            <v-icon small>mdi-email</v-icon>
            <strong>Email:</strong>
            {{ user.email }}
          </p>
        </v-card-text>
      </v-card>

      <org-select @input="value => (orgId = value)"></org-select>
      <warehouse-select :disabled="!orgId" v-model="warehouse" :orgId="orgId"></warehouse-select>

      <v-divider class="my-4"></v-divider>

      <p class="mt-8 text--red text-center">
        <v-icon color="red" small>mdi-alert</v-icon>
        Any changes made on the carrier view will reflect to the actual carrier account!
      </p>

      <action-group
        key="login-as-carrier"
        v-if="novaCore.isInternalUser($me)"
        confirm-label="Log in as Carrier"
        :disable-confirm="!warehouse?.id"
        @cancel="close"
        @confirm="submit"></action-group>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import config from '@/config/config';

/**
 * Button/Dialog for edit user form
 * @displayName Edit User Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      warehouse: null,
      orgId: null
    };
  },
  methods: {
    async submit() {
      const userId = this.user.id;

      const res = await axios.post(`wormhole/login-as/${userId}`);
      const accessToken = res.data?.access_token;

      const routeJSON = JSON.stringify({
        name: 'warehouse.details',
        params: { warehouseId: this.warehouse.id }
      });

      if (accessToken) {
        window.open(`${config.luna_url}?token=${accessToken}&nextRoute=${routeJSON}`, '_blank');
      } else {
        this.notify('Invalid access token', 'error');
      }
    },
    close() {
      this.warehouse = null;
      this.orgId = null;
      this.$emit('close');
    }
  },
  watch: {
    orgId() {
      if (!this.orgId) {
        this.warehouse = null;
      }
    }
  }
};
</script>
