<template>
  <v-list-item class="select-limit-msg">
    <v-list-item-icon class="mr-3"><v-icon>mdi-information</v-icon></v-list-item-icon>
    <v-list-item-title class="mt-1"> Only {{ limit }} selections allowed </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'SelectLimitListItem',
  props: {
    limit: {
      type: Number,
      required: false,
      default: null
    }
  }
};
</script>

<style lang="scss">
.select-limit-msg {
  border-top-color: lightgrey;
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
}
</style>
