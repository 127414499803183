var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "printable-window" }, [
    _c("div", { staticClass: "header-spacing" }),
    _c("article", [
      _c("h1", { staticClass: "title" }, [_vm._v("Driver Self Check-in")]),
      _c(
        "h4",
        { staticClass: "warehouse-name" },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { "x-large": "" } }, [
            _vm._v("mdi-map-marker"),
          ]),
          _vm._v(" " + _vm._s(_vm.warehouseName) + " "),
        ],
        1
      ),
      _c("div", { staticClass: "instructions" }, [
        _c(
          "div",
          { staticClass: "yellow-circle cellphone" },
          [
            _c("v-icon", { attrs: { color: "black", "x-large": "" } }, [
              _vm._v("mdi-cellphone-screenshot"),
            ]),
          ],
          1
        ),
        _vm._m(0),
      ]),
      _c("div", { staticClass: "instructions" }, [
        _c("div", [
          _c("p", { staticClass: "subtitle" }, [_vm._v("How to check in:")]),
          _c("div", { staticClass: "black-text" }, [
            _c(
              "p",
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-3 yellow-circle", attrs: { large: "" } },
                  [_vm._v("mdi-numeric-1")]
                ),
                _vm._v(" Scan the QR code "),
              ],
              1
            ),
            _c("br"),
            _c(
              "p",
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-3 yellow-circle", attrs: { large: "" } },
                  [_vm._v("mdi-numeric-2")]
                ),
                _vm._v(" Fill in required information "),
              ],
              1
            ),
            _c("br"),
            _c(
              "p",
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-3 yellow-circle", attrs: { large: "" } },
                  [_vm._v("mdi-numeric-3")]
                ),
                _vm._v(" You're checked in! "),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "qrcode-box" }, [
      _c("div", { attrs: { id: "qrcode-template-placeholder" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("img", {
        attrs: {
          alt: "logo",
          src: "/images/logo_hi_res_blue.png",
          height: "48",
        },
      }),
      _c("strong", [_vm._v("Dock scheduling for warehouses")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }