var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.appointment.warehouse?.id
    ? _c(
        "dialog-base",
        _vm._b(
          {
            key: _vm.renderKey,
            attrs: {
              header: "Edit Booking Details",
              "content-class": "details-dialog edit-custom-fields-dialog",
              transition: "dialog-bottom-transition",
              "loading-message": "updating appointment",
              "max-width": "100%",
              value: _vm.showDialog,
              width: "1200px",
            },
            on: { close: _vm.close },
            scopedSlots: _vm._u(
              [
                {
                  key: "customHeader",
                  fn: function () {
                    return [
                      _c(
                        "header",
                        { staticClass: "details-header" },
                        [
                          _c("dialog-header", {
                            attrs: {
                              header: "Edit Booking Details",
                              "hide-close-icon": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-button",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { icon: "", text: "" },
                                          on: { click: _vm.close },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-arrow-left"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1447643821
                            ),
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-3",
                              attrs: { icon: "", light: "" },
                              on: { click: _vm.close },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                !_vm.externalActivator
                  ? {
                      key: "activator",
                      fn: function (slotProps) {
                        return undefined
                      },
                    }
                  : null,
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "details-content-box",
                        [
                          _c("custom-fields", {
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": false,
                              outlined: "",
                              "display-label": false,
                              appointment: _vm.appointment,
                              warehouse: _vm.appointment.warehouse,
                              timezone: _vm.appointment.warehouse.timezone,
                            },
                            model: {
                              value: _vm.customFieldsModel,
                              callback: function ($$v) {
                                _vm.customFieldsModel = $$v
                              },
                              expression: "customFieldsModel",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "dialog-actions",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "py-4 d-flex justify-end full-width" },
                        [
                          _c(
                            "outline-button",
                            { staticClass: "mr-4", on: { click: _vm.close } },
                            [_vm._v("Nevermind")]
                          ),
                          _c(
                            "primary-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "save",
                                    _vm.customFieldsModel
                                  )
                                },
                              },
                            },
                            [_vm._v("Save Changes")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "dialog-base",
          _vm.$props,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }