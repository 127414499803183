var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-dialog", {
    attrs: { "max-width": "600" },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on, attrs }) {
          return [
            _c(
              "icon-tooltip-button",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      "icon-class": _vm.iconClass,
                      tooltip: _vm.tooltipText,
                      size: "large",
                      icon: "mdi-chart-pie",
                    },
                  },
                  "icon-tooltip-button",
                  attrs,
                  false
                ),
                on
              )
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function (dialog) {
          return [
            _c(
              "v-card",
              { attrs: { "data-testid": "pie-chart-dialog" } },
              [
                _c(
                  "v-card-title",
                  [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.dialogTitle)),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "pa-3",
                        attrs: { icon: "", light: "" },
                        on: {
                          click: function ($event) {
                            dialog.value = false
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close-circle")])],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "pie-chart",
                      _vm._b({}, "pie-chart", [_vm.$props, _vm.$attrs], false)
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            dialog.value = false
                          },
                        },
                      },
                      [_vm._v("Close ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }