<template>
  <v-select
    class="pt-0"
    hide-details="auto"
    @input="updateValue"
    :search-input.sync="filters.searchStr"
    :value="value"
    :items="carriers"
    :loading="isLoading"
    v-bind="[$props, $attrs]"
    item-text="firstName"
    item-value="id"
    :ref="selectRefKey"
    return-object>
    <template v-slot:prepend-item v-if="carriers.length && multiple && !disableSelectAll">
      <v-list-item ripple @click="toggleSelectAllItems">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider slot="prepend-item" class="mt-2" />
    </template>

    <template v-slot:item="{ item, attrs, on }">
      <v-list-item
        v-on="on"
        v-bind="attrs"
        #default="{ active }"
        :disabled="!attrs.inputValue && isAtSelectionLimit">
        <v-list-item-action>
          <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
              {{ getCarrierName(item) }} - {{ item.company.name }}
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0">{{ getCarrierName(item) }}</span>
      <span v-if="index === 1" class="grey--text text-caption ml-2">
        (+{{ value.length - 1 }} others)
      </span>
    </template>

    <template v-slot:append-item>
      <template>
        <select-limit-list-item
          v-if="isAtSelectionLimit"
          :limit="maximumSelections"></select-limit-list-item>
      </template>
    </template>
  </v-select>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';
import dataListMixin from '@satellite/components/mixins/dataListMixin';

export default {
  mixins: [selectMixin, selectAllSelectMixin, dataListMixin],
  props: {
    /**
     * @model
     */
    value: {
      required: true
    },
    warehouseIds: {
      type: Array,
      required: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldGetBookedOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    filterLocally: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    selectableItems() {
      return this.carriers;
    }
  },
  data() {
    return {
      selectRefKey: 'carrierSelect',
      carriers: [],
      filters: {
        searchStr: null
      },
      isLoading: true
    };
  },
  methods: {
    async getData() {
      if (this.carriers?.length && this.filterLocally) {
        return this.carriers;
      }
      this.isLoading = true;
      const route = this.shouldGetBookedOnly
        ? 'Carriers/getBookedCarriers'
        : 'Carriers/getCarriers';
      const data = await this.$store
        .dispatch(route, this.getQuery(!this.filters.searchStr))
        .finally(() => {
          this.isLoading = false;
          this.$emit('ready');
        });
      this.carriers = this.novaCore.naturalSort(data, false, 'firstName');
    },
    getQuery() {
      let query = this.getQueryBase();

      if (this.filters.searchStr) {
        query.limit = '60000';
      }

      return query;
    },
    updateValue(carriers) {
      this.$emit('input', carriers);
    },
    getCarrierName(carrier) {
      return `${carrier.firstName} ${carrier.lastName}`;
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
