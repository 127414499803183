var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          staticClass: "d-flex align-content-center flex-row justify-center",
          attrs: { sm: "6", lg: "6" },
        },
        [
          _c("label", { staticClass: "mr-2 pt-2" }, [
            _c("strong", [_vm._v("Area name:")]),
          ]),
          _c("v-text-field", {
            attrs: {
              "data-testid": "yard-area-form-name-input",
              "hide-details": "",
              dense: "",
              required: "",
              outlined: "",
            },
            model: {
              value: _vm.spotAreaName,
              callback: function ($$v) {
                _vm.spotAreaName = $$v
              },
              expression: "spotAreaName",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "d-flex justify-end", attrs: { cols: "6" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { outlined: "" },
              on: { click: _vm.back },
            },
            [_vm._v("Nevermind")]
          ),
          _c(
            "primary-button",
            {
              attrs: {
                disabled: !_vm.spotAreaName,
                "data-testid": "yard-area-form-save",
              },
              on: { click: _vm.submit },
            },
            [_vm._v(" Save Area ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }