<template>
  <v-autocomplete
    :value="value"
    @input="value => $emit('input', value)"
    :items="roles"
    v-bind="[$props, $attrs]"
    label="User Role">
    <template v-slot:item="{ item }">
      <div class="py-2">
        <div class="d-block v-list-item__title">
          {{ item.text }}
        </div>
        <div class="d-block font-size-x-small">
          {{ item.description }}
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
/**
 * Org Select
 * @displayName Org Select
 */
export default {
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Current user's role
     */
    parentRole: {
      type: String,
      required: false
    },
    /**
     * Roles to exclude
     */
    excludedRoles: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    roles: []
  }),
  mounted() {
    this.roles = Object.values(this.novaCore.UserRole)
      .filter(
        role =>
          (!this.parentRole || this.novaCore.isUserRoleLessOrEqual(role, this.$me.role)) &&
          !this.excludedRoles.includes(role)
      )
      .map(value => ({
        value,
        text: this.novaCore.userRoleToText(value),
        description: this.novaCore.userRoleDescription(value)
      }));
  }
};
</script>
