var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("svg", { attrs: { height: "150", width: "180" } }, [
    _c("g", [
      _c("circle", { attrs: { cx: "90", cy: "80", r: "60", fill: "#E5F1F7" } }),
      _c(
        "foreignObject",
        {
          staticClass: "node",
          attrs: { x: "60", y: "50", width: "60", height: "60" },
        },
        [
          _c(
            "v-icon",
            { staticClass: "icon", attrs: { color: "secondary lighten-4" } },
            [_vm._v("mdi-warehouse")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }