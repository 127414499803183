<template>
  <div
    class="px-4 py-2 hours-of-operation-item d-flex is-relative align-center"
    :class="{ closed: hoopStatus === closedStatus }">
    <div class="d-flex align-center weekday-controls">
      <span class="font-weight-bold font-size-small weekday-label d-inline-block">{{
        weekdayLabel
      }}</span>
      <v-btn-toggle
        class="day-status-toggle secondary-button-group lighten-2"
        v-model="status"
        v-if="!readOnly"
        rounded
        mandatory
        dense>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn :data-testid="`${weekday}-closed-button`" small v-on="on">
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </template>
          <span>Set to closed</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn :data-testid="`${weekday}-specific-hours-button`" small v-on="on">
              <v-icon>mdi-calendar-arrow-right</v-icon>
            </v-btn>
          </template>
          <span>Set specific hours of operation</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn :data-testid="`${weekday}-24-hours-button`" small v-on="on">
              <v-icon>mdi-hours-24</v-icon>
            </v-btn>
          </template>
          <span>Set hours of operation to 24 hours</span>
        </v-tooltip>
      </v-btn-toggle>
    </div>
    <span
      class="d-inline-block my-4 font-size-x-small font-weight-bold ml-4"
      v-if="hoopStatus === closedStatus"
      >Closed</span
    >
    <span
      class="d-inline-block my-4 font-size-x-small font-weight-bold ml-4"
      v-else-if="hoopStatus === alwaysOpenStatus"
      >Open 24 Hours</span
    >
    <div v-else>
      <div class="d-flex flex-column pt-1 time-range-select-group">
        <transition-group class="time-range-group pl-0 ml-4" name="time-range-group" tag="ul">
          <time-range-selector
            :readonly="readOnly"
            class="my-2"
            v-for="(rangeSpan, index) in value"
            :date-range-select-items="dateRangeSelectItems"
            transition="scroll-y-transition"
            :key="`${weekday}-${index}`"
            :index="index"
            :interval-length="intervalLength"
            :from-items="fromItems[index]"
            :to-items="toItems[index]"
            v-model="value[index]"
            @delete-range="deleteRange">
          </time-range-selector>
        </transition-group>
      </div>
      <v-btn v-if="canAddHours" text class="add-hours-button" @click="addRange">
        <v-icon class="mr-1 font-size-x-small">mdi-plus</v-icon>
        Add Hours
      </v-btn>
      <v-dialog v-model="showWeekdayPicker" width="350" v-if="!readOnly">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top :disabled="showWeekdayPicker" :open-on-focus="false">
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                small
                class="side-button"
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                @click="
                  selectedDays = [];
                  showWeekdayPicker;
                  renderKey++;
                ">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Clone Schedule to Other Days</span>
          </v-tooltip>
        </template>

        <v-card>
          <dialog-header header="Select Days" @close="showWeekdayPicker = false"></dialog-header>

          <v-card-text>
            <weekday-checkbox-group
              :key="renderKey"
              v-model="selectedDays"
              :disabled-day="weekday"></weekday-checkbox-group>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <outline-button @click="showWeekdayPicker = false">Nevermind</outline-button>
            <primary-button @click="copySchedule"> Clone Schedule</primary-button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { SchedulingStatusEnum } from '@/enums';

import renderMixin from '@satellite/components/mixins/renderMixin';

/**
 * Hours of Operation Form Item
 * @displayName Hours of Operation Item
 */
export default {
  mixins: [renderMixin],
  props: {
    /**
     * Array of objects containing items split into specified interval length for use in date range select items
     * [{text: "12:00 am", value: "0:00"}, {text: "12:30 am, value: "0:30"}...]
     */
    dateRangeSelectItems: {
      type: Array,
      required: true
    },
    /**
     * @model
     */
    value: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * Weekday to create schedule for - "sunday"
     */
    weekday: {
      type: String,
      required: true
    },
    /**
     * length of time each interval should be in minutes
     */
    intervalLength: {
      type: Number,
      required: false,
      default: 30
    },
    /**
     * Is item readonly
     */
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    /**
     * Enables the "add hours" button if true
     * @returns {boolean}
     */
    canAddHours() {
      let canAddHours = true;
      if (this.value.length && this.hoopStatus === this.scheduleStatus) {
        let endMoment = this.novaCore.clockToMoment(
          this.value[this.value.length - 1]?.end || '0:00'
        );
        let lastSlotMoment = this.novaCore.clockToMoment('23:30');
        if (endMoment.isSameOrAfter(lastSlotMoment)) {
          canAddHours = false;
        }
      }
      return canAddHours && !this.readOnly;
    },
    /**
     * Formatted day of week - "Sunday"
     * @returns {*}
     */
    weekdayLabel() {
      return this.novaCore.upperFirst(this.weekday);
    },
    /**
     * returns the hours of operation status enum
     * used to determine which button is active - cancel, schedule, 24 hours
     * @returns {string}
     */
    hoopStatus() {
      return this.statusMap[this.status];
    },
    /**
     * Closed enum status
     * @returns {string}
     */
    closedStatus() {
      return SchedulingStatusEnum.CLOSED;
    },
    /**
     * Schedule enum status
     * @returns {string}
     */
    scheduleStatus() {
      return SchedulingStatusEnum.SCHEDULE;
    },
    /**
     * Always open enum status
     * @returns {string}
     */
    alwaysOpenStatus() {
      return SchedulingStatusEnum.ALWAYSOPEN;
    },
    /**
     * Filtered list of items to use in each range's "from" time select box
     * @returns {*[]}
     */
    fromItems() {
      let items = [];
      let availableIntervals = [...this.dateRangeSelectItems];
      this.value.map((range, index) => {
        let previousEnd = this.formatClock(this.getClockFromInterval(index - 1, 'end'));
        let nextStart = this.formatClock(this.getClockFromInterval(index + 1, 'start', 'subtract'));
        items[index] = availableIntervals.filter(item => {
          let itemCompare = this.formatClock(item.value);
          let isTimeAvailable = itemCompare > previousEnd && itemCompare <= 2330;
          if (!index) {
            isTimeAvailable = itemCompare >= previousEnd && itemCompare <= 2330;
          }

          if (nextStart > 0) {
            isTimeAvailable = isTimeAvailable && itemCompare < nextStart;
          }

          return isTimeAvailable;
        });
        availableIntervals = availableIntervals.filter(x => !items.includes(x));
      });

      return items;
    },
    /**
     * Filtered list of items to use in each range's "to" time select box
     * @returns {*[]}
     */
    toItems() {
      let items = [];
      let availableIntervals = [...this.dateRangeSelectItems];
      this.value.map((range, index) => {
        let currentStart = this.formatClock(range.start);
        let nextStart = this.formatClock(this.getClockFromInterval(index + 1));
        items[index] = [...this.dateRangeSelectItems].filter(item => {
          let itemCompare = this.formatClock(item.value);
          let isItemWithinBounds =
            itemCompare > currentStart &&
            (nextStart === 0 || (nextStart > 0 && itemCompare < nextStart));
          return isItemWithinBounds;
        });
        availableIntervals = availableIntervals.filter(x => !items.includes(x));
      });

      return items;
    }
  },
  data() {
    return {
      isOpen: false,
      showWeekdayPicker: false,
      selectedDays: [],
      status: 0,
      statusMap: [
        SchedulingStatusEnum.CLOSED,
        SchedulingStatusEnum.SCHEDULE,
        SchedulingStatusEnum.ALWAYSOPEN
      ]
    };
  },
  methods: {
    /**
     * format the provided clock as an integer - "0:00" = 0, "4:30" = 1630
     * @public
     * @param {string} clock
     * @returns {number}
     */
    formatClock(clock) {
      // TODO: Use this.novaCore.clockAsInt() instead
      return clock ? parseInt(clock.replace(':', '')) : 0;
    },
    /**
     * Add a new range to the weekday schedule
     * @public
     */
    addRange() {
      let start = !this.value.length
        ? '8:00'
        : this.getClockFromInterval(this.value.length - 1, 'end', 'add');
      let startMoment = this.novaCore.clockToMoment(start).add(this.intervalLength, 'minutes');
      let nextDayMidnight = this.novaCore.clockToMoment('0:00').add('1', 'days');
      let endClock = '23:59';
      if (startMoment.isBefore(nextDayMidnight)) {
        endClock = startMoment.clone().format(this.novaCore.DateTimeFormats.Extended24HrTime);
      }
      let end = !this.value.length ? '17:00' : endClock;
      /**
       * Emits input with updated intervals including new item
       * @event input
       * @property {object[]} - [...this.value, { start: '5:00', end: '8:00' }]
       */
      this.$emit('input', [...this.value, { start: start, end: end }]);
    },
    /**
     * Remove range from weekday schedule
     * @public
     * @param index
     */
    deleteRange(index) {
      let values = this.novaCore.deepClone(this.value);
      values.splice(index, 1);
      /**
       * Emits input event with updated values with deleted item
       * @event input - delete range
       * @property {object[]} - [{ start: '0:00', end: '8:00' }]
       */
      this.$emit('input', values);
    },
    /**
     * Returns a specific clock from the weekday schedule based on provided key and index
     * @public
     * @param {number} intervalIndex
     * @param {string} [key=start] - Expects either "start" or "end"
     * @param {string} timeAction - Expects either "add" or "subtract"
     * @returns {string}
     */
    getClockFromInterval(intervalIndex, key = 'start', timeAction) {
      let clock = '0:00';
      let rangeExists =
        this.value.length && intervalIndex >= 0 && intervalIndex <= this.value.length - 1;
      if (rangeExists) {
        clock = this.value[intervalIndex] ? this.value[intervalIndex][key] : clock;
        if (timeAction && clock) {
          let clockMoment = this.novaCore.clockToMoment(clock);
          clockMoment[timeAction](this.intervalLength, 'minutes');
          clock = clockMoment.format(this.novaCore.DateTimeFormats.Extended24HrTime);
        }
      }
      return clock;
    },
    /**
     * Hide weekday picker and emit copy-schedule event with weekdays and schedule to copy
     * @public
     */
    copySchedule() {
      this.showWeekdayPicker = false;

      /**
       * Emits copy-schedule event with object containing weekdays to copy to and schedule to copy
       * @event copy-schedule
       * @property {object} - {days: ["sunday", "monday"...], schedule: [{text: "12:00 am", value: "0:00"}...]}
       */
      this.$emit('copy-schedule', { days: this.selectedDays, schedule: this.value });
    },
    /**
     * Sets the status of the weekday
     * @public
     */
    setStatus(status) {
      this.status = this.statusMap.indexOf(status);
    },
    /**
     * Handle the toggle button group when v-model updates
     * @public
     */
    setScheduleToggles() {
      if (!this.value?.length) {
        this.setStatus(this.closedStatus);
      } else {
        if (this.value.length === 1) {
          if (this.value[0].start === '0:00' && this.value[0].end === '23:59') {
            this.setStatus(this.alwaysOpenStatus);
          } else {
            this.setStatus(this.scheduleStatus);
          }
        } else {
          this.setStatus(this.scheduleStatus);
        }
      }
    }
  },
  watch: {
    value() {
      this.setScheduleToggles();
    },
    hoopStatus() {
      if (this.hoopStatus === this.scheduleStatus) {
        if (!this.value.length) {
          this.addRange();
        }
      } else if (this.hoopStatus === this.alwaysOpenStatus) {
        /**
         * Emits input event with an interval automatically setting the day schedule to 24 hours
         * @event input - always open
         * @property {object[]} - [{ start: '0:00', end: '23:59' }]
         */
        if (!_.isEqual(this.value, [this.novaCore.TwentyFourHourClockSpan])) {
          this.$emit('input', [this.novaCore.TwentyFourHourClockSpan]);
        }
      } else {
        /**
         * Emits input event with an empty array to "reset" the day schedule if closed
         * @event input - closed
         * @property {array} - []
         */
        this.$emit('input', []);
      }
    }
  }
};
</script>
