var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    key: _vm.renderKey,
    attrs: { header: _vm.header, "hide-required-legend": true },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      " Please type in the new ETA " +
                        _vm._s(
                          _vm.partyToAlert === "Warehouse"
                            ? ` and notify the ${_vm.partyToAlert}`
                            : ""
                        ) +
                        ". "
                    ),
                  ]),
                  _c("div", { staticClass: "d-flex my-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pa-3 disabled border-radius font-size-x-small",
                      },
                      [
                        _c("strong", [_vm._v("Appointment time")]),
                        _vm._v(
                          " : " +
                            _vm._s(_vm.formattedAppointmentDatetime) +
                            " (" +
                            _vm._s(_vm.appointment.dock.warehouse.timezone) +
                            ") "
                        ),
                      ]
                    ),
                    _c("img", {
                      staticClass: "align-self-center thin-arrow",
                      attrs: {
                        src: "/images/arrow-right-thin.png",
                        alt: "Right Arrow",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pa-3 disabled border-radius font-size-x-small",
                      },
                      [_vm._v("New ETA")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "my-3 mid-narrow margin-x-auto" },
                    [
                      _c("date-picker", {
                        attrs: {
                          format: _vm.novaCore.DateTimeFormats.ShortMonthYear,
                          clearable: false,
                          "min-date": _vm.minEtaDate,
                          "max-date": _vm.maxEtaDate,
                          "display-field-label": false,
                          required: "",
                        },
                        model: {
                          value: _vm.customDate,
                          callback: function ($$v) {
                            _vm.customDate = $$v
                          },
                          expression: "customDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "margin-x-auto mid-wide" },
                    [
                      _c("time-entry", {
                        attrs: {
                          date: _vm.appointmentDateTime,
                          warehouse: _vm.appointment.dock.warehouse,
                        },
                        model: {
                          value: _vm.customTime,
                          callback: function ($$v) {
                            _vm.customTime = $$v
                          },
                          expression: "customTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("auto-expandable-textarea", {
                  attrs: {
                    label: "Reason",
                    rules: _vm.isCarrierView
                      ? _vm.$validator.rules.required("Reason")
                      : [],
                    placeholder:
                      "Enter a short description to justify this ETA alert",
                    required: _vm.isCarrierView,
                    "validate-on-blur": "",
                  },
                  model: {
                    value: _vm.etaReason,
                    callback: function ($$v) {
                      _vm.etaReason = $$v
                    },
                    expression: "etaReason",
                  },
                }),
                _vm.isCarrierView
                  ? _c("p", [
                      _c("small", [
                        _vm._v(
                          " ETA must be within " +
                            _vm._s(_vm.novaCore.ETA_WINDOW_HOURS) +
                            " hours from the appointment start! "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("v-spacer", { staticClass: "my-6" }),
                _c("v-alert", { attrs: { color: "yellow lighten-4" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "brown darken-0" },
                        },
                        [_vm._v("mdi-alert")]
                      ),
                      _c("p", [
                        _vm._v(
                          " Providing an ETA is an update for facilities but does not reschedule appointments or bypass facility late charges or fines. "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: { "confirm-label": "Save" },
              on: { cancel: _vm.cancel, confirm: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }