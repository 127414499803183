var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { elevation: "0", width: "250" } },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex flex-row" },
        [
          _c(
            "h1",
            {
              staticClass: "font-weight-bold text-uppercase",
              attrs: { "data-testid": "yard-spot-detail-title" },
            },
            [_vm._v(" " + _vm._s(_vm.spot.code) + " ")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                icon: "",
                "data-testid": "yard-spot-detail-close-button",
              },
              on: { click: _vm.closeDetail },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { attrs: { "data-testid": "yard-spot-detail-content" } },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column mb-4" },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("label", [_c("strong", [_vm._v("Spot code")])]),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      rules: [_vm.validateCode],
                      dense: "",
                      "data-testid": "yard-spot-form-code-input",
                    },
                    model: {
                      value: _vm.spot.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.spot, "code", $$v)
                      },
                      expression: "spot.code",
                    },
                  }),
                  _c("label", [_c("strong", [_vm._v("Spot type")])]),
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        rules: [_vm.validateType],
                        outlined: "",
                        dense: "",
                      },
                      model: {
                        value: _vm.spot.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.spot, "type", $$v)
                        },
                        expression: "spot.type",
                      },
                    },
                    _vm._l(
                      Object.keys(_vm.spotType).map((k) => k.toLowerCase()),
                      function (type) {
                        return _c("v-radio", {
                          key: type,
                          attrs: {
                            "data-testid": `yard-spot-form-type-${type}`,
                            value: type,
                            label: _vm.capitalize(type),
                          },
                        })
                      }
                    ),
                    1
                  ),
                  _c("label", [_c("strong", [_vm._v("Current state")])]),
                  _c(
                    "p",
                    { staticClass: "text-capitalize" },
                    [
                      _vm.isSpotAssigned(_vm.spot)
                        ? _c("v-icon", { attrs: { small: "", color: "red" } }, [
                            _vm._v("mdi-alert-circle-outline"),
                          ])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.getSpotStatus(_vm.spot)) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "card-actions",
          attrs: { "data-testid": "yard-spot-detail-actions" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column full-width" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-4",
                  attrs: {
                    block: "",
                    color: "primary",
                    "data-testid": "yard-spot-form-submit-button",
                  },
                  on: { click: _vm.updateSpot },
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                    _vm._v("mdi-check"),
                  ]),
                  _vm._v(" Save changes "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mb-4",
                  attrs: {
                    block: "",
                    outlined: "",
                    color: "error",
                    "data-testid": "yard-spot-form-delete-button",
                    disabled: _vm.isSpotAssigned(_vm.spot),
                  },
                  on: {
                    click: function ($event) {
                      _vm.isDeleteConfirmationVisible = true
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                    _vm._v("mdi-delete-outline"),
                  ]),
                  _vm._v(" Delete Spot "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.isDeleteConfirmationVisible
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.isDeleteConfirmationVisible,
              "delete-confirmation": "",
              "show-delete-warning": "",
              persistent: "",
              title: "Delete this Spot?",
              "entity-name": _vm.spot.code,
              "button-true-text": "YES, DELETE",
              "button-true-color": "error",
              width: 650,
            },
            on: { result: _vm.handleDeleteConfirmationResult },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }