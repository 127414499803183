var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("p", [
        _vm._v("This spot is open and available for truck assignment."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }