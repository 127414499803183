var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("h3", [_vm._v("401 Not Authorized")]),
      _c("p", [_vm._v("You do not have permission to access this page.")]),
      _c("p", [
        _vm._v(" Please log in through "),
        _c("a", { attrs: { href: "https://carrier.opendock.com" } }, [
          _vm._v("Opendock"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }