<template>
  <div class="tv-calendar" :style="{ zoom: `${currentZoom}% !important` }">
    <div class="calendar-header">
      <div class="left-header-div">
        <img alt="logo" src="/images/opendock_graphic_dark.svg" class="logo" />
        <div class="today-date">
          <v-row>
            <v-col>
              <v-btn outlined color="grey darken-2" @click="setToday()"> Today</v-btn>
              <v-btn icon small rounded @click="prev">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon small rouded @click="next">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              {{ formatDateToHeader(selectedDate) }}
              <v-icon class="ml-1 darken-1" small title="Lock on current date">
                {{ lockDateIcon }}
              </v-icon>
              <v-icon class="ml-1 darken-1" small v-if="autoScroll" title="Follow current time">
                mdi-update
              </v-icon>
            </v-col>
          </v-row>
          <div class="warehouse-name">
            {{ title }}
          </div>
        </div>
      </div>
      <div class="right-header-div">
        <div class="today-date">
          {{ currentTime }}
          <span class="mx-3">
            <v-btn icon class="decrease-zoom-button" x-small rounded @click="decreaseZoom()">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn icon class="ml-2 increase-zoom-button" x-small rounded @click="increaseZoom()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-menu absolute offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon x-small rounded class="ml-2" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <v-checkbox x-small label="Follow current time" v-model="autoScroll" />
                    <v-checkbox x-small label="Lock on current date" v-model="lockCurrentDate" />
                    <hr />
                    <v-checkbox
                      x-small
                      label="Show appointment tags"
                      v-model="showAppointmentTags" />
                    <v-checkbox
                      x-small
                      label="Show appointment fields"
                      v-model="showAppointmentFields" />
                    <v-checkbox
                      x-small
                      label="Show parallel capacity"
                      v-model="showParallelCapacity" />
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </div>
        <div class="warehouse-name">
          {{ formatTimezoneString() }}
        </div>
      </div>
    </div>
    <v-calendar
      ref="calendar"
      type="category"
      color="primary"
      interval-minutes="30"
      interval-width="90"
      interval-height="100"
      category-for-invalid="No Dock"
      event-overlap-mode="column"
      :interval-count="(24 * 60) / 30"
      :interval-format="formatInterval"
      :events="appointments"
      :title="title"
      :categories="categories"
      :event-category="getEventCategory"
      :event-color="getEventClasses"
      :short-intervals="true"
      :weekday-format="() => {}"
      v-model="selectedDate"
      category-show-all>
      <template v-slot:day-label-header="{ date }">
        <div></div>
      </template>

      <template v-slot:category="{ category }">
        {{ getDockNameById(category) }}
      </template>

      <template v-slot:day-body="{ category }">
        <div
          class="v-current-time"
          :class="{ first: category === firstCategory }"
          :style="{ top: nowY }"></div>
      </template>

      <template v-slot:event="{ event }">
        <event-tv-mode
          :event="event"
          :showAppointmentFields="showAppointmentFields"
          :timezone="warehouse?.timezone"
          :showAppointmentTags="showAppointmentTags">
        </event-tv-mode>
      </template>
    </v-calendar>
    <v-footer app bottom fixed>
      <span class="text">
        <strong>{{ totalAppointments }} scheduled - {{ cancelledAppointments }} cancelled</strong>
      </span>
    </v-footer>
  </div>
</template>

<script>
import moment from 'moment-timezone';

import gridMixin from '@/modules/calendar/mixins/gridMixin';

export default {
  name: 'AppointmentsTVModePage',
  mixins: [gridMixin],
  data() {
    return {
      categories: [],
      title: '',
      currentZoom: 80,
      autoScroll: true,
      lockCurrentDate: true,
      showAppointmentTags: true,
      showAppointmentFields: true,
      showParallelCapacity: false,
      ready: false,
      currentTime: null
    };
  },
  computed: {
    firstCategory() {
      return this.warehouse?.docks[0].id;
    },
    appointments() {
      return this.$warehouseEventsByDock(this.warehouseId, this.dockIds).filter(
        event => event?.status !== this.novaCore.AppointmentStatus.Cancelled
      );
    },
    totalAppointments() {
      return (
        this.$warehouseEventsByDock(this.warehouseId, this.dockIds)?.filter(event => {
          return (
            !this.novaCore.isReserve(event) &&
            event?.status !== this.novaCore.AppointmentStatus.Cancelled
          );
        }).length || 0
      );
    },
    cancelledAppointments() {
      return (
        this.$warehouseEventsByDock(this.warehouseId, this.dockIds)?.filter(event => {
          return (
            !this.novaCore.isReserve(event) &&
            event?.status === this.novaCore.AppointmentStatus.Cancelled
          );
        }).length || 0
      );
    },
    refreshIntervalSeconds() {
      return 10;
    },
    lockDateIcon() {
      return this.lockCurrentDate ? 'mdi-lock' : 'mdi-lock-open';
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      if (this.cal && this.$selectedWarehouse?.id) {
        const currentWarehouseTime = this.$timeInWarehouseTimezone(momentjs().format());
        const dayTimeObject = this.cal.parseTimestamp(currentWarehouseTime);
        return `${this.cal.timeToY(dayTimeObject)}px`;
      } else {
        return '-10px';
      }
    },
    selectedDate: {
      get() {
        return this.$selectedDate;
      },
      set(date) {
        return this.$store.dispatch('Calendar/setSelectedDate', {
          value: date,
          getEvents: this.ready
        });
      }
    }
  },
  methods: {
    today() {
      if (this.warehouse?.timezone) {
        return momentjs()
          .tz(this.warehouse.timezone)
          .format(this.novaCore.DateTimeFormats.DateDashed);
      }
      return momentjs().format(this.novaCore.DateTimeFormats.DateDashed);
    },
    getEventClasses(event) {
      return event.color;
    },
    getEventCategory(event) {
      const eventDock = this.$docksKeyedById[event.category];
      return this.novaCore.isCapacityChild(eventDock) && !this.showParallelCapacity
        ? eventDock.capacityParentId
        : eventDock.id;
    },
    increaseZoom() {
      if (this.currentZoom < 150) {
        this.currentZoom = +this.currentZoom + 10;
      }
    },
    decreaseZoom() {
      if (this.currentZoom > 50) {
        this.currentZoom = +this.currentZoom - 10;
      }
    },
    getDockNameById(dockId) {
      const dock = this.warehouse?.docks.find(dock => dock.id === dockId);

      if (dock?.capacityParentId) {
        if (String(dock.name).includes(this.novaCore.CAPACITY_CHILD_SLUG)) {
          return `${dock.name.split(this.novaCore.CAPACITY_CHILD_SLUG)[0]} - [Parallel]`;
        }
        return dock.name;
      }

      return dock.name ?? 'Unnamed dock';
    },
    formatDateToHeader() {
      if (this.warehouse) {
        return moment(this.selectedDate).format(this.novaCore.DateTimeFormats.FullDayMonth);
      }
      return 'No warehouse selected';
    },
    formatTimeToWarehouseTimezone() {
      if (this.warehouse?.timezone) {
        return this.novaCore.formatDateTimeWithMilitarySupport(
          new Date().toISOString(),
          this.warehouse.timezone,
          this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
          this.$isMilitaryTimeEnabled(this.$selectedWarehouse),
          this.novaCore.LuxonDateTimeFormats.Extended24HrTime
        );
      }
    },
    formatTimezoneString() {
      if (this.warehouse?.timezone) {
        return `${this.warehouse.timezone} (${moment()
          .tz(this.warehouse.timezone)
          .format(this.novaCore.DateTimeFormats.TimezoneOffset)})`;
      }
    },
    isReserve(event) {
      return this.novaCore.isReserve(event);
    },
    async setToday() {
      await this.$store.dispatch('Calendar/setSelectedDate', {
        value: this.today(),
        getEvents: true
      });
      this.lockCurrentDate = true;
      await this.scrollToTime();
    },
    async prev() {
      await this.cal.prev();
      this.lockCurrentDate = this.selectedDate === this.today();
    },
    async next() {
      await this.cal.next();
      this.lockCurrentDate = this.selectedDate === this.today();
    },
    updateTime() {
      if (this.cal) {
        clearInterval(this.currentTimeInterval);
        this.currentTimeInterval = setInterval(() => {
          if (this.autoScroll) {
            this.scrollToTime();
            this.cal.updateTimes();
          }
          if (this.lockCurrentDate) {
            this.$store.dispatch('Calendar/setSelectedDate', {
              value: this.today(),
              getEvents: this.selectedDate !== this.today()
            });
          }

          this.currentTime = this.formatTimeToWarehouseTimezone();
        }, this.refreshIntervalSeconds * 1000);
      }
    },
    async scrollToTime() {
      if (this.cal) {
        const currentTimeElement = document.getElementsByClassName('v-current-time')[0];
        if (currentTimeElement) {
          currentTimeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
    },
    getSortedCategories() {
      const filteredParents = (this.warehouse.docks || []).filter(
        dock => this.dockIds.includes(dock.id) && this.novaCore.isRootDock(dock)
      );
      const alphabeticalParents = this.novaCore.naturalSort(filteredParents || [], false, 'name');
      const orderedParents = this.novaCore.naturalSort(
        alphabeticalParents || [],
        false,
        'sortOrder'
      );

      const categories = [];
      orderedParents.forEach(dock => {
        if (dock.capacityChildren && dock.capacityParentId === dock.id) {
          categories.push(...this.novaCore.sortCapacityChildren(dock));
        } else {
          categories.push(dock);
        }
      });
      return categories
        .filter(dock => {
          if (!this.showParallelCapacity) {
            return !this.novaCore.isCapacityChild(dock);
          }
          return true;
        })
        .map(dock => dock.id);
    },
    async getAppointments() {
      if (!this.warehouseId || !this.dockIds) {
        this.notify(
          'Invalid warehouse/docks! Please go back to the calendar view and open the TV Mode again.',
          'error'
        );
        return;
      }
      const warehouse = await this.services.warehouse.getWarehouseById(
        this.warehouseId,
        {},
        this.services.warehouse.requestOptions.grid
      );

      if (warehouse) {
        this.warehouse = warehouse;
        await this.$store.dispatch('Calendar/setSelectedWarehouse', warehouse);
        await this.$store.dispatch(
          'Calendar/setSelectedDocks',
          warehouse.docks.filter(dock => this.dockIds.includes(dock.id))
        );
        await this.setToday();
        await this.$store.dispatch('Calendar/setViewType', {
          value: 'day',
          getEvents: true
        });

        this.categories = this.getSortedCategories();

        this.title = `${warehouse.name}`;
        await this.$store.dispatch('Calendar/getEvents');
      }
    }
  },
  beforeDestroy() {
    this.$eventHub.$off('update-Dock');
    ['update', 'create', 'delete'].forEach(operation =>
      this.$eventHub.$off(`${operation}-Appointment`)
    );
  },
  async mounted() {
    // Hide zendesk
    if (window.zE) {
      window.zE('messenger', 'hide');
    }

    this.warehouseId = this.$route.query['warehouseId'];
    this.dockIds = this.$route.query['dockIds'];
    this.ready = true;

    ['update', 'create', 'delete'].forEach(operation => {
      this.$eventHub.$on(`${operation}-Appointment`, async payload => {
        await this.$store.dispatch('Appointments/handleSocketEvent', {
          action: operation,
          data: payload
        });
      });
    });

    if (this.$org) {
      this.mixpanel.track(this.mixpanel.events.VIEW.TV_MODE, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id
      });
    }

    await this.getAppointments();
    this.updateTime();
    await this.scrollToTime();

    this.currentTime = this.formatTimeToWarehouseTimezone();
  },
  watch: {
    lockCurrentDate() {
      this.updateTime();
    },
    autoScroll() {
      this.updateTime();
    },
    showParallelCapacity() {
      this.getAppointments();
    },
    '$org.customTags'() {
      this.$store.dispatch('Calendar/getEvents', {});
    }
  }
};
</script>

<style lang="scss">
.app-appointments-tv {
  .v-calendar-daily__interval-text {
    font-size: 14px !important;
    font-weight: bold;
  }

  .v-current-time {
    height: 2px;
    background-color: #ea433550;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    &.first::before {
      content: '';
      position: absolute;
      background-color: #ea433550;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-top: -5px;
      margin-left: -6.5px;
    }
  }

  .warehouse-name {
    font-family: Barlow, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
  }

  .today-date {
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
  }

  .right-header-div {
    text-align: right;
  }

  .left-header-div {
    text-align: left;
    display: flex;
  }

  .tv-calendar {
    .calendar-header {
      height: 70px;
      padding: 10px 10px 10px 10px;
      background: #ddebf0;
      display: flex;
      justify-content: space-between;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100 !important;
    }

    .calendar-header .v-btn {
      height: 20px !important;
    }

    .v-calendar-daily__head {
      box-shadow: 0 8px 6px -6px #888888;
      z-index: 100 !important;
      margin-top: 68px;
      position: fixed !important;
    }

    .v-calendar-daily__intervals-head {
      background-image: -webkit-linear-gradient(
        top,
        #ddebf0 0px,
        #ddebf0 60px,
        #bcced4 60px
      ) !important;
      border: none !important;
    }

    .v-calendar-daily_head-day-label {
      width: 100% !important;
      padding: 0 !important;
    }

    .v-calendar-category {
      overflow: hidden !important;
      margin-top: 98px !important;
    }

    .v-calendar-category__column-header {
      background: #bcced4 !important;
      text-align: center;
      padding-top: 3px;

      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0;
    }

    .v-calendar-daily_head-weekday {
      display: none !important;
    }

    .v-calendar-daily__day:nth-child(odd) {
      background: rgba(188, 206, 212, 0.2);
    }

    .v-event-timed {
      overflow: hidden;
    }
  }

  .logo {
    height: 42px;
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 30px;
  }
}
</style>
