<template functional>
  <div class="tile-data-block">
    <span style="white-space: nowrap">
      <span style="margin-right: unset !important" class="font-weight-bold"
        >{{ props.fieldLabel }}:</span
      >{{
        $options.methods.getFieldValue(
          props.fields,
          props.fieldLabel,
          props.timezone,
          props.enableMilitaryTime
        )
      }}</span
    >
  </div>
</template>

<script>
import {
  truncateString,
  formatPhoneNumber,
  formatDateTimeWithMilitarySupport,
  LuxonDateTimeFormats
} from '@satellite/../nova/core';
import { isArray, isNull, isUndefined } from 'lodash';
import { DateTime } from 'luxon';

const UnknownStr = '??';

export default {
  name: 'CustomFieldDataBlock',
  props: {
    fieldLabel: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    timezone: {
      type: String,
      required: true
    },
    enableMilitaryTime: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getFieldValue(fields, label, timezone, enableMilitaryTime) {
      for (const field of fields) {
        if (field.label === label) {
          return truncateString(
            String(this.formatCustomFieldValue(field, timezone, enableMilitaryTime)),
            20
          );
        }
      }
      return UnknownStr;
    },
    formatCustomFieldValue(field, timezone, enableMilitaryTime) {
      // NOTE: custom-field.ts:getCustomFieldFormattedValue() does similar work, however
      // the needs of the new custom grid tile system are different, and so I had to
      // create my own custom field value formatter. TODO: In future, we may be able
      // to unify these?

      if (isNull(field) || isUndefined(field) || isNull(field.value) || isUndefined(field.value)) {
        return UnknownStr;
      }

      const val = field.value;

      if (field.type === 'date') {
        const dt = DateTime.fromISO(val);
        return dt.isValid ? dt.toFormat('MM/dd/yyyy') : UnknownStr;
      } else if (field.type === 'bool') {
        return val ? 'Yes' : 'No';
      } else if (field.type === 'doc' || field.type === 'multidoc') {
        // TODO: For now, we just don't support displaying these types of fields.
        // It's complicated dealing with the URLs, and frankly I don't see much
        // value in displaying these on the grid tiles. Can revisit in future however.
        return UnknownStr;
      } else if (field.type === 'phone') {
        return formatPhoneNumber(String(val));
      } else if (field.type === 'dropdownmultiselect') {
        if (isArray(val)) {
          return val.join(',');
        }
      } else if (field.type === 'timestamp') {
        return formatDateTimeWithMilitarySupport(
          String(val),
          timezone,
          LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
          enableMilitaryTime,
          LuxonDateTimeFormats.MonthDayYearSlashedTime24
        );
      }

      return String(val);
    }
  }
};
</script>
