<template>
  <button-base
    class="secondary"
    v-bind="[$props, $attrs]"
    @click="handleClick($event)"
    before-icon="pencil">
    Edit
    <!-- @slot Button Content -->
    <slot></slot>
  </button-base>
</template>

<script>
import buttonMixin from '../../mixins/buttonMixin';

/**
 * An edit button with a pencil icon
 * @displayName Edit Button
 */
export default {
  mixins: [buttonMixin],
  methods: {
    handleClick($event) {
      /**
       * Emits click event with target's event
       * @event click
       * @property {object} $event - Target's Event object
       */
      this.$emit('click', $event);
    }
  }
};
</script>
