export default {
  data() {
    return {
      websocketDisconnectNotificationSentAt: null,
      websocketDisconnectedAt: null,
      websocketNotificationInProgress: false,
      websocketStatusListenerAttached: false,
      websocketNotificationResendDelay: 60000,
      websocketNotificationReconnectDelay: 3500,
      websocketLastKnownStatus: null,
      websocketMixpanelNotificationSent: false
    };
  },
  methods: {
    isWebsocketNotificationResendDelayMet() {
      // If it's never been sent, it can be sent
      if (this.websocketDisconnectNotificationSentAt === null) {
        return true;
      }

      return (
        momentjs().diff(this.websocketDisconnectNotificationSentAt) >
        this.websocketNotificationResendDelay
      );
    },
    attachWebsocketStatusListener() {
      this.$eventHub.$on('websocket-connection-status-change', status => {
        // Reset disconnected status
        if (status === this.sockets.connectionStatuses.CONNECTED) {
          this.websocketDisconnectedAt = null;
        }

        if (status !== this.sockets.connectionStatuses.CONNECTED) {
          this.websocketDisconnectedAt = this.websocketDisconnectedAt || momentjs();

          // Wait 5 seconds to send the notification incase it reconnects quickly
          if (!this.websocketNotificationInProgress) {
            this.websocketNotificationInProgress = true;
            setTimeout(() => {
              if (this.websocketDisconnectedAt) {
                // If resend and reconnect delays have both been met, send notification
                if (this.isWebsocketNotificationResendDelayMet()) {
                  this.websocketDisconnectNotificationSentAt = momentjs();
                  this.notify(
                    'Websocket connection failure. <br />For more information click the connection icon in the navigation bar',
                    'error'
                  );
                }
              }
              this.websocketNotificationInProgress = false;
            }, this.websocketNotificationReconnectDelay);
          }
        }

        const statusData = this.$websocketConnectionStatus;
        statusData.state = status;

        if (
          status === this.sockets.connectionStatuses.CONNECTED ||
          this.websocketLastKnownStatus === this.sockets.connectionStatuses.CONNECTED
        ) {
          statusData.lastConnected = momentjs();
        }

        this.$store.commit('Websockets/setConnectionStatus', statusData);

        if (
          this.$websocketConnectionStatus.lastConnected &&
          status !== this.sockets.connectionStatuses.CONNECTED &&
          momentjs().diff(this.$websocketConnectionStatus.lastConnected) >
            this.websocketNotificationResendDelay &&
          !this.websocketMixpanelNotificationSent
        ) {
          this.mixpanel.track(this.mixpanel.events.SYSTEM.ERROR.WEBSOCKET_CONNECTION);
          this.websocketMixpanelNotificationSent = true;
        }

        this.websocketLastKnownStatus = status;
      });
    }
  },
  mounted() {
    if (!this.websocketStatusListenerAttached) {
      this.websocketStatusListenerAttached = true;
      this.attachWebsocketStatusListener();
    }
  }
};
