export default {
  computed: {
    selectedWarehouseAccessList() {
      return this.selectedWarehouses.map(warehouse => warehouse.id);
    },
    currentUserWarehouseAccessList() {
      return this.novaCore.isUserWarehouseRestricted(this.$me)
        ? this.$me.warehouseAccessList
        : null;
    }
  },
  data() {
    return {
      shouldTargetUserBeRestrictedByLocation: false,
      selectedWarehouses: []
    };
  },
  methods: {
    handleUserRoleUpdate(newRole) {
      if (!this.novaCore.canUserRoleHaveWarehouseAccessList(newRole)) {
        this.shouldTargetUserBeRestrictedByLocation = false;
      } else {
        if (this.novaCore.isUserWarehouseRestricted(this.$me)) {
          this.shouldTargetUserBeRestrictedByLocation = true;
        }
      }
    }
  },
  mounted() {
    this.shouldTargetUserBeRestrictedByLocation = this.novaCore.isUserWarehouseRestricted(this.$me)
      ? true
      : false;
    if (this.user) {
      this.shouldTargetUserBeRestrictedByLocation =
        this.shouldTargetUserBeRestrictedByLocation ||
        this.novaCore.isUserWarehouseRestricted(this.user);
    }
  },
  watch: {
    shouldTargetUserBeRestrictedByLocation(newVal) {
      if (!newVal) {
        this.selectedWarehouses = [];
      }
    },
    'userInvite.role'(newRole) {
      this.handleUserRoleUpdate(newRole);
    },
    'localUser.role'(newRole) {
      this.handleUserRoleUpdate(newRole);
    }
  }
};
