var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          ref: "tabs",
          attrs: {
            grow: "",
            "background-color": "secondary",
            dark: "",
            "slider-color": "primary",
            centered: "",
            id: "insights-tabs",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          !_vm.novaCore.isInternalUser(_vm.$me)
            ? _c("v-tab", { key: "all", attrs: { to: { name: "insights" } } }, [
                _vm._v(" All Insights "),
              ])
            : _vm._e(),
          !_vm.novaCore.isInternalUser(_vm.$me)
            ? _c(
                "v-tab",
                { key: "carrier", attrs: { to: { name: "insights.carrier" } } },
                [_vm._v(" Carrier Insights ")]
              )
            : _vm._e(),
          !_vm.novaCore.isInternalUser(_vm.$me)
            ? _c(
                "v-tab",
                {
                  key: "warehouse",
                  attrs: { to: { name: "insights.warehouse" } },
                },
                [_vm._v(" Warehouse Insights ")]
              )
            : _vm._e(),
          !_vm.novaCore.isInternalUser(_vm.$me)
            ? _c(
                "v-tab",
                {
                  key: "reporting",
                  attrs: { to: { name: "insights.reporting" } },
                },
                [_vm._v(" Reporting ")]
              )
            : _vm._e(),
          _vm.novaCore.isInternalRole(_vm.$me.role)
            ? _c(
                "v-tab",
                {
                  key: "internal",
                  attrs: { to: { name: "insights.internal" } },
                },
                [_vm._v(" Internal Insights ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          !_vm.novaCore.isInternalUser(_vm.$me)
            ? _c(
                "v-tab-item",
                { key: "all", attrs: { value: _vm.route("insights") } },
                [
                  _vm.tab === "/insights"
                    ? _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c("feature-flag", {
                            attrs: { name: "enable-power-bi-all-insights" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "enabled",
                                  fn: function () {
                                    return [
                                      _c("power-bi-insights", {
                                        attrs: {
                                          title: _vm.insightsTitle,
                                          url: _vm.insightsUrl,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "disabled",
                                  fn: function () {
                                    return [_c("chart-grid")]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1393015230
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.novaCore.isInternalUser(_vm.$me)
            ? _c(
                "v-tab-item",
                {
                  key: "carrier",
                  attrs: { value: _vm.route("insights.carrier") },
                },
                [
                  _c("power-bi-insights", {
                    attrs: { title: _vm.carrierTitle, url: _vm.carrierUrl },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.novaCore.isInternalUser(_vm.$me)
            ? _c(
                "v-tab-item",
                {
                  key: "warehouse",
                  attrs: { value: _vm.route("insights.warehouse") },
                },
                [
                  _vm.tab === "/insights/warehouse"
                    ? _c("power-bi-insights", {
                        attrs: {
                          title: _vm.warehouseTitle,
                          url: _vm.warehouseUrl,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.novaCore.isInternalUser(_vm.$me)
            ? _c(
                "v-tab-item",
                {
                  key: "reporting",
                  attrs: { value: _vm.route("insights.reporting") },
                },
                [
                  _vm.tab === "/insights/reporting"
                    ? _c("appointment-reporting-table")
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }