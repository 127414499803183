<template>
  <div class="d-table hours-of-operation-table">
    <div class="font-weight-bold black--text mb-2">({{ timezone }})</div>
    <template v-if="doesScheduleExist">
      <div class="d-table-row" v-for="day in daysOfOperation" :key="day">
        <div class="d-table-cell pr-1 black--text font-weight-bold">
          {{ novaCore.upperFirst(day).slice(0, 3) }}:
        </div>
        <div class="d-table-cell">
          <div v-for="(range, index) in schedule[day]" :key="`${day}-range-${index}`">
            {{ formatScheduleTime(range.start) }}-{{ formatScheduleTime(range.end) }}
          </div>
        </div>
      </div>
      <div class="d-table-row" v-if="closedDates.length > 0">
        <div class="d-table-cell pr-1 black--text font-weight-bold">Closed Dates:</div>
        <div class="d-table-cell">
          <div v-for="(date, index) in closedDates" :key="`closed-interval-${index}`">
            {{ date }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    schedule: {
      type: Object,
      required: true
    },
    timezone: {
      type: String,
      required: true
    }
  },
  computed: {
    closedDates() {
      return (this.schedule?.closedIntervals ?? [])
        .sort(
          (a, b) =>
            momentjs(a.start).format(this.novaCore.DateTimeFormats.Date) -
            momentjs(b.start).format(this.novaCore.DateTimeFormats.Date)
        )
        .filter(interval => momentjs(interval.start).tz(this.timezone).isSameOrAfter())
        .map(interval =>
          this.novaCore.renderUtcInTimezone(
            interval.start,
            this.timezone,
            this.novaCore.DateTimeFormats.ShortDayShortMonthFullYear
          )
        );
    },
    weekdays() {
      return momentjs.weekdays();
    },
    doesScheduleExist() {
      const schedule = { ...this.schedule };
      if (schedule?.closedIntervals) {
        delete schedule.closedIntervals;
        delete schedule.version;
      }
      return schedule && Object.keys(schedule).length > 1;
    },
    daysOfOperation() {
      const sorter = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6
      };

      let weekdays = Object.keys(this.schedule).filter(day => {
        return day !== 'version' && day !== 'closedIntervals';
      });

      let sorted = weekdays.sort(function sortByDay(a, b) {
        let day1 = a.toLowerCase();
        let day2 = b.toLowerCase();
        return sorter[day1] - sorter[day2];
      });

      return sorted;
    }
  },
  methods: {
    formatScheduleTime(time) {
      let date = momentjs().format(this.novaCore.DateTimeFormats.DateSlashed);
      let timeParts = time.split(':');
      let topOfHour = timeParts[1] === '00';
      let format = topOfHour ? 'ha' : 'h:mma';
      return this.novaCore.getFormattedTime(`${date} ${time}`, format);
    }
  }
};
</script>
