var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          loading: _vm.loading,
          "prepend-header-icon": "mdi-alert",
          header: "Load Type Not Supported",
          "loading-message": "Updating Appointment Load Type",
          "max-width": "100%",
          value: _vm.showDialog,
          width: "500",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b({}, "primary-button", slotProps.attrs, false),
                          slotProps.on
                        ),
                        [_vm._v("Update Load Type")]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  !_vm.loading
                    ? [
                        _c("p", [
                          _c("strong", [
                            _vm._v(
                              "This appointment's load type is not currently supported at this dock."
                            ),
                          ]),
                          _vm._v(
                            " Select a new load type or reschedule at a different dock "
                          ),
                        ]),
                        _c("load-type-select", {
                          attrs: {
                            "selected-warehouse": _vm.eventWarehouse,
                            showOnlyAssignedLoadTypes: true,
                            "selected-docks": [_vm.eventDock],
                          },
                          model: {
                            value: _vm.newLoadType,
                            callback: function ($$v) {
                              _vm.newLoadType = $$v
                            },
                            expression: "newLoadType",
                          },
                        }),
                      ]
                    : _vm._e(),
                  _c("div", [
                    _c("span", { staticClass: "d-block mb-4" }, [
                      _vm._v("You are about to Reschedule this appointment:"),
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "flex-grow-1" }, [
                        _c("div", { staticClass: "font-weight-bold mb-1" }, [
                          _vm._v("FROM:"),
                        ]),
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", [_vm._v(_vm._s(_vm.fromDate))]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.fromStart) +
                                " - " +
                                _vm._s(_vm.fromEnd)
                            ),
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.fromDock))]),
                        ]),
                      ]),
                      _c("div", { staticClass: "flex-grow-1" }, [
                        _c("div", { staticClass: "font-weight-bold mb-1" }, [
                          _vm._v("TO:"),
                        ]),
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", [_vm._v(_vm._s(_vm.toDate))]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.toStart) + " - " + _vm._s(_vm.toEnd)
                            ),
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.toDock))]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "dialog-actions",
              fn: function () {
                return [
                  !_vm.loading
                    ? _c("action-group", {
                        attrs: {
                          loading: _vm.loading,
                          "confirm-label": _vm.returnLoadType
                            ? "Okay"
                            : "Reschedule",
                          "disable-confirm": !_vm.newLoadType,
                        },
                        on: {
                          cancel: function ($event) {
                            _vm.close()
                            _vm.$emit("cancel")
                          },
                          confirm: _vm.submitLoadType,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }