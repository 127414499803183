<template>
  <form-base :hide-required-legend="true">
    <template #form>
      <v-form ref="form">
        <v-radio-group v-model="orgType" column class="mt-0">
          <p>
            <strong>Organization:</strong>
            {{ org.name }}
          </p>
          <v-radio :value="novaCore.OrgType.Client" class="mb-4">
            <template v-slot:label>
              <div class="d-flex flex-column justify-center">
                {{ novaCore.OrgType.Client }}
                <p class="legend mb-0">Orgs owned and managed by real paying clients.</p>
              </div>
            </template>
          </v-radio>
          <v-radio :value="novaCore.OrgType.Test" class="mb-4">
            <template v-slot:label>
              <div class="d-flex flex-column justify-center">
                {{ novaCore.OrgType.Test }}
                <p class="legend mb-0">
                  Orgs created by internal users for testing or demo purposes.
                </p>
              </div>
            </template>
          </v-radio>
          <v-radio :value="novaCore.OrgType.Trial">
            <template v-slot:label>
              <div class="d-flex flex-column justify-center">
                {{ novaCore.OrgType.Trial }}
                <p class="legend mb-0">
                  Customers evaluating the product for a limited period of time.
                </p>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="cancel"
        confirm-icon="update"
        confirm-label="save"
        @confirm="submit"
        :disable-confirm="readOnly"
        :loading="loading"></action-group>
    </template>
  </form-base>
</template>

<script>
export default {
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      orgType: null,
      readOnly: false,
      loading: false
    };
  },
  methods: {
    /**
     * Submit form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      try {
        const response = await axios.patch(`org/${this.org.id}`, {
          orgType: this.orgType
        });
        this.notify('Org Type changed successfully', 'success');
        this.$emit('save', response?.data?.data.orgType);
        this.$emit('close');
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit('close');
    }
  },
  async mounted() {
    await this.$store.dispatch('Orgs/getOrg', this.org.id);
    this.orgType = this.org.orgType;
  }
};
</script>

<style scoped>
.legend {
  font-size: 14px;
}
</style>
