import assetVisitService from '@satellite/services/asset-visit-service';

export default function useAssetVisit() {
  const updateAssetVisit = async (id, payload) => {
    return assetVisitService.updateAssetVisit(id, payload);
  };

  return {
    updateAssetVisit
  };
}
