var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        attrs: { value: _vm.value, items: _vm.roles, label: "User Role" },
        on: { input: (value) => _vm.$emit("input", value) },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "py-2" }, [
                  _c("div", { staticClass: "d-block v-list-item__title" }, [
                    _vm._v(" " + _vm._s(item.text) + " "),
                  ]),
                  _c("div", { staticClass: "d-block font-size-x-small" }, [
                    _vm._v(" " + _vm._s(item.description) + " "),
                  ]),
                ]),
              ]
            },
          },
        ]),
      },
      "v-autocomplete",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }