var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "action-group" },
    [
      _c(
        "div",
        { staticClass: "mr-1 d-flex align-center" },
        [
          _vm._l(_vm.appointmentActions, function (actionItem, actionName) {
            return [
              !actionItem.isShowMoreOption
                ? _c("appointment-action-group-item", {
                    key: `${_vm.renderKey}-${actionName}`,
                    attrs: {
                      disabled: actionItem.disabled,
                      "disabled-message": actionItem.disabledMessage,
                      "action-item": actionItem,
                      "action-name": actionName,
                    },
                    on: { click: _vm.handleClick },
                  })
                : _vm._e(),
            ]
          }),
          _vm.isShowMoreMenuVisible
            ? _c(
                "v-menu",
                {
                  staticClass: "action-menu",
                  attrs: {
                    "offset-y": "",
                    "internal-activator": true,
                    "close-on-click": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      small: "",
                                      text: "",
                                      ripple: false,
                                      "data-testid": "appointmentShowMoreMenu",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-dots-vertical"),
                                ]),
                                _vm._v(" More "),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    885875688
                  ),
                },
                [
                  _c(
                    "v-list",
                    [
                      _vm._l(
                        _vm.appointmentActions,
                        function (actionItem, actionName) {
                          return [
                            actionItem.isShowMoreOption
                              ? _c(
                                  "v-list-item",
                                  {
                                    key: `action-${actionName}`,
                                    staticClass: "account-option",
                                  },
                                  [
                                    _c("appointment-action-group-item", {
                                      attrs: {
                                        "is-sub-item": "",
                                        disabled: actionItem.disabled,
                                        "disabled-message":
                                          actionItem.disabledMessage,
                                        "action-item": actionItem,
                                        "action-name": actionName,
                                      },
                                      on: { click: _vm.handleClick },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("share-appointment-dialog", {
        attrs: {
          "external-activator": true,
          "show-dialog": _vm.showShareDialog,
          appointment: _vm.appointment,
        },
        on: {
          close: function ($event) {
            _vm.showShareDialog = false
          },
        },
      }),
      _c("create-recurring-series-dialog", {
        attrs: {
          "external-activator": true,
          "show-dialog": _vm.showCreateRecurringSeriesDialog,
          appointment: _vm.appointment,
        },
        on: {
          close: function ($event) {
            _vm.showCreateRecurringSeriesDialog = false
          },
        },
      }),
      _c("delete-recurring-series-dialog", {
        attrs: {
          "parent-appointment": _vm.recurringParentAppointment,
          "external-activator": true,
          "show-dialog": _vm.showDeleteRecurringSeriesDialog,
          appointment: _vm.appointment,
        },
        on: {
          close: function ($event) {
            _vm.showDeleteRecurringSeriesDialog = false
          },
        },
      }),
      _c("reschedule-appointment-dialog", {
        attrs: {
          "appointment-warehouse": _vm.appointmentWarehouse,
          "external-activator": true,
          "show-dialog": _vm.showRescheduleDialog,
          appointment: _vm.appointment,
        },
        on: {
          close: function ($event) {
            _vm.showRescheduleDialog = false
          },
          "appointment-updated": function ($event) {
            return _vm.$emit("appointment-updated")
          },
        },
      }),
      _c("edit-appointment-dialog", {
        attrs: {
          "external-activator": true,
          "show-dialog": _vm.showEditDialog,
          appointment: _vm.appointment,
        },
        on: {
          "appointment-updated": function ($event) {
            return _vm.$emit("appointment-updated")
          },
          close: function ($event) {
            _vm.showEditDialog = false
          },
        },
      }),
      _c("cancel-appointment-dialog", {
        ref: "cancelAppointmentDialog",
        attrs: {
          "external-activator": true,
          "show-dialog": _vm.showConfirmDialog,
          appointment: _vm.appointment,
        },
        on: {
          confirm: function ($event) {
            return _vm.$emit("confirm")
          },
          close: function ($event) {
            _vm.showConfirmDialog = false
          },
          "appointment-updated": function ($event) {
            _vm.showConfirmDialog = false
            _vm.$emit("appointment-updated")
          },
        },
      }),
      _c("create-appointment-dialog", {
        attrs: {
          "external-activator": true,
          "show-dialog": _vm.showCreateAppointmentDialog,
          context: _vm.appointment,
        },
        on: {
          close: function ($event) {
            _vm.showCreateAppointmentDialog = false
          },
        },
      }),
      _c("edit-reserve-dialog", {
        attrs: {
          "external-activator": "",
          reserve: _vm.appointment,
          "show-dialog": _vm.showEditReserveDialog,
        },
        on: {
          close: function ($event) {
            _vm.showEditReserveDialog = false
          },
        },
      }),
      !_vm.novaCore.isReserve(_vm.appointment)
        ? _c("printable-appointment-details", {
            ref: "printableVisibleAppointmentDetails",
            attrs: {
              settings: _vm.settings,
              appointment: _vm.appointment,
              "custom-form-data": _vm.customFormData,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }