<template>
  <div :class="isFlex ? 'd-flex flex-row align-center mb-4 full-width' : ''">
    <v-select
      :prepend-icon="!hideIcon ? 'mdi-warehouse' : ''"
      :value="value"
      v-bind="[$props, $attrs]"
      @input="updateValue"
      :items="warehouses"
      :item-disabled="noDocks"
      multiple
      :loading="loading"
      item-text="name"
      item-value="id"
      :rules="selectRules"
      return-object
      clearable
      @click:clear="clearSelection"
      :disabled="disabled"
      hide-details="auto"
      :ref="selectRefKey">
      <template #label v-if="required">
        {{ placeholder }}
        <span class="red--text"><strong>*</strong></span>
      </template>

      <template v-slot:item="{ item }">
        {{ getWarehouseName(item) }}
      </template>

      <template v-slot:no-data>
        <v-list-item v-if="!warehouses.length">
          <v-list-item-title>
            <span class="d-block pt-2">
              Looks like your org doesn't have a warehouse set up yet ...
            </span>
            <router-link
              v-if="showCreateWarehouseDialog"
              :to="{
                name: 'warehouses',
                props: true,
                params: { shouldShowCreateWarehouseDialog: true }
              }"
              class="full-width v-btn primary v-size--default mt-2"
              @click.native="handleCreateWarehouseClick">
              Let's Create a warehouse
            </router-link>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-title>
            <span class="d-block pt-2">No warehouses found matching this criteria...</span>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:append-item>
        <template v-if="warehouseDoesNotHaveDocks">
          <v-list-item>
            <v-list-item-title>
              <span class="d-block pt-2">
                Looks like your warehouse doesn't have a dock set up yet ...
              </span>
              <router-link
                v-if="warehouses.length && showCreateDockDialog"
                :to="{
                  name: 'warehouses.details.docks',
                  props: true,
                  params: { warehouseId: warehouses[0].id, shouldShowCreateDockForm: true }
                }"
                class="full-width v-btn primary v-size--default mt-2"
                @click.native="handleCreateDockClick">
                Let's Create a Dock
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-else>
          <select-limit-list-item
            v-if="isAtSelectionLimit"
            :limit="maximumSelections"></select-limit-list-item>
        </template>
      </template>

      <template v-slot:prepend-item v-if="warehouses.length && !disableSelectAll">
        <v-list-item ripple @click="toggleSelectAllItems">
          <v-list-item-action>
            <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider slot="prepend-item" class="mt-2" />
      </template>

      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">{{ item.name }}</span>
        <span v-if="index === 1" class="grey--text text-caption ml-2">
          (+{{ value.length - 1 }} others)
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import { debounce } from 'debounce';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';

export default {
  name: 'WarehouseMultiSelect',
  mixins: [selectMixin, selectAllSelectMixin],
  props: {
    /**
     * @model
     */
    value: {
      required: false,
      default: null
    },
    /**
     * Disable the select
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Show the wh store / facility number in the dropdown
     */
    showStoreNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    classes: {
      type: String,
      required: false,
      default: 'pt-0'
    },
    isFlex: {
      type: Boolean,
      required: false,
      default: true
    },
    joins: {
      type: Array,
      required: true
    },
    selectFields: {
      type: Array,
      required: false
    },
    includeSettings: {
      type: Boolean,
      required: false
    },
    minimumSelections: {
      type: Number,
      required: false,
      default: null
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldDisableItemIfNoDocks: {
      type: Boolean,
      required: false,
      default: true
    },
    shouldEnforceUserWarehouseAccessList: {
      type: Boolean,
      required: false,
      default: true
    },
    // Pass uuids for the component to select
    // from the list, if found
    autoSelectByIds: {
      type: Array,
      required: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    showCreateWarehouseDialog: {
      type: Boolean,
      default: true
    },
    showCreateDockDialog: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    warehouseDoesNotHaveDocks() {
      return this.warehouses.length === 1 && !this.warehouses[0].docks?.length;
    },
    selectableItems() {
      return this.warehouses;
    },
    selectRules() {
      let rules = this.minimumSelections
        ? this.$validator.rules.selectAtLeast(this.minimumSelections, 'Warehouse')
        : [];
      if (this.rules?.length) {
        rules = [...rules, ...this.rules];
      }
      return rules;
    }
  },
  data() {
    return {
      loading: false,
      warehousesLoaded: false, // Immutable, do not change, other comps rely on this property name
      warehouses: [], // Immutable, do not change, other comps rely on this property name
      sortBy: ['name'],
      selectRefKey: 'warehouseSelect'
    };
  },
  methods: {
    /**
     * v-model
     */
    updateValue(val) {
      this.$emit('input', val || {});
    },
    /**
     * returns dock count of warehouse
     * @public
     * @property {object} warehouse
     */
    getWarehouseDockCount(warehouse) {
      return warehouse.docks?.length || 0;
    },
    /**
     * Returns warehouse name with dock count
     */
    getWarehouseName(warehouse) {
      const dockCount = this.getWarehouseDockCount(warehouse);
      const dockNoun = dockCount !== 1 ? 'docks' : 'dock';
      let displayName =
        dockCount > 0
          ? `${warehouse.name} (${dockCount} ${dockNoun})`
          : `${warehouse.name} (No ${dockNoun})`;

      if (this.showStoreNumber && warehouse.facilityNumber) {
        displayName = `${displayName} #${warehouse.facilityNumber}`;
      }

      return displayName;
    },
    /**
     * Returns if warehouse has docks or not
     * @public
     */
    noDocks(warehouse) {
      if (!this.shouldDisableItemIfNoDocks) {
        return false;
      }

      const dockCount = this.getWarehouseDockCount(warehouse);
      return dockCount <= 0;
    },
    /**
     * Get warehouse data
     * @public
     */
    async getData() {
      if (this.loading) {
        // Return current data while loading if attempt is made to get more data
        return this.warehouses;
      }
      this.loading = true;

      const options = {
        fields: this.selectFields ?? [],
        joins: this.joins
      };

      const params = {};

      if (this.shouldEnforceUserWarehouseAccessList && this.$me.warehouseAccessList?.length) {
        params.s = { ...params.s, id: { $in: this.$me.warehouseAccessList } };
      }

      if (!this.includeSettings) {
        params.includeHierarchySettings = false;
      }

      const warehouses = await this.services.warehouse.getWarehouses(params, options);
      let data = [];
      if (warehouses?.length > 0) {
        data = this.novaCore.sortBy(warehouses, 'name');
      }

      this.loading = false;
      this.warehousesLoaded = true;

      return data;
    },
    clearSelection() {
      this.updateValue([]);
    },
    async handleDataRefresh(val) {
      // It is safer to restore the initial behavior when a change happens,
      // because we don't know what changed and the user can start again.
      this.updateValue(val);
      this.warehouses = await this.getData();
    },
    handleCreateWarehouseClick() {
      this.$emit('close');
      if (this.$route.name === 'warehouses') {
        this.$eventHub.$emit('show-create-warehouse-dialog');
      }
    },
    handleCreateDockClick() {
      this.$emit('close');
      if (this.$route.name === 'warehouses.details.docks') {
        this.$eventHub.$emit('show-create-dock-dialog');
      }
    },
    selectDefault() {
      if (this.warehouses?.length === 1) {
        this.updateValue([this.warehouses[0]]);
      } else {
        if (this.autoSelectByIds && !this.value?.length) {
          const autoSelectedWarehouses = this.warehouses.filter(w =>
            this.autoSelectByIds.includes(w.id)
          );
          if (autoSelectedWarehouses.length > 0) {
            this.updateValue(autoSelectedWarehouses);
          }
        }
      }
    },
    subscribeToSubspace() {
      Object.entries(this.sockets.actions).map(entry => {
        let action = entry[0];
        this.$eventHub.$on(`${action}-Warehouse`, async payload => {
          let warehouse = this.value;
          if (action !== 'delete' && this.value?.id === payload?.id) {
            warehouse = await this.$store.dispatch('Warehouses/getWarehouseById', payload.id);
          }
          await this.handleDataRefresh(warehouse);
        });
      });
    }
  },
  async mounted() {
    this.debounceInput = debounce(async () => {
      this.warehouses = await this.getData();
    }, 450);

    this.warehouses = await this.getData();

    this.subscribeToSubspace();

    this.selectDefault();
  },
  beforeDestroy() {
    Object.entries(this.sockets.actions).map(entry => {
      let action = entry[0];
      this.$eventHub.$off(`${action}-Warehouse`);
    });
  },
  watch: {
    warehouses() {
      if (!this.warehouses?.length) {
        this.updateValue([]);
      }
    }
  }
};
</script>
