var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        staticClass: "create-appointment-dialog is-relative",
        attrs: {
          "content-class": "create-appointment-dialog",
          loading: false,
          header: "Create an Appointment",
          "loading-message": "Creating appointment...",
          "max-width": "100%",
          value: _vm.showDialog,
          width: "1100px",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            disabled: _vm.$rolePermissions.canCreateAppointment,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm.$scopedSlots.activator
                                          ? _vm._t("activator", null, null, {
                                              ...slotProps,
                                              isButtonDisabled:
                                                _vm.isButtonDisabled,
                                            })
                                          : _c(
                                              "primary-button",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.isButtonDisabled,
                                                    },
                                                  },
                                                  "primary-button",
                                                  [
                                                    slotProps.attrs,
                                                    _vm.$attrs,
                                                    attrs,
                                                  ],
                                                  false
                                                ),
                                                slotProps.on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.buttonLabel ||
                                                        "Add Appointment"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.novaCore.getRoleActionError())),
                          ]),
                        ]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("create-appointment-form", {
                    attrs: {
                      context: _vm.context,
                      "asset-visit": _vm.assetVisit,
                    },
                    on: {
                      close: _vm.close,
                      scheduled: _vm.emitScheduledAppointment,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }