var render = function render(_c, _vm) {
  return _c("div", { staticClass: "tile-data-block" }, [
    _c("span", { staticStyle: { "white-space": "nowrap" } }, [
      _c(
        "span",
        {
          staticClass: "font-weight-bold",
          staticStyle: { "margin-right": "unset !important" },
        },
        [_vm._v(_vm._s(_vm.props.fieldLabel) + ":")]
      ),
      _vm._v(
        _vm._s(
          _vm.$options.methods.getFieldValue(
            _vm.props.fields,
            _vm.props.fieldLabel,
            _vm.props.timezone,
            _vm.props.enableMilitaryTime
          )
        )
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }