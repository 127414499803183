var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-5 px-3 mt-4 mb-4" },
    [
      _c("h2", { staticClass: "pl-4" }, [_vm._v("All Insights")]),
      _c(
        "v-row",
        { staticClass: "mt-8 px-5" },
        [
          _c(
            "span",
            {
              staticClass:
                "font-weight-bold font-size-base align-self-center pb-4 pr-3 pl-1",
            },
            [_vm._v("Filter:")]
          ),
          _c(
            "v-col",
            {
              attrs: { "data-testid": "chart-grid-warehouse-select-container" },
            },
            [
              _c("warehouse-select", {
                staticClass: "mb-4",
                attrs: {
                  "auto-select-by-id": _vm.getLastSelectedWarehouseId(),
                  "restricted-warehouse-ids": _vm.$me.warehouseAccessList
                    ? _vm.$me.warehouseAccessList
                    : null,
                  "multi-select": false,
                  "hide-icon": "",
                  "should-auto-select-first": "",
                  "custom-persistent-label": "Location",
                  clearable: false,
                },
                on: {
                  input: (w) =>
                    _vm.persistLastSelectedData("warehouseId", w?.id),
                },
                model: {
                  value: _vm.selectedWarehouse,
                  callback: function ($$v) {
                    _vm.selectedWarehouse = $$v
                  },
                  expression: "selectedWarehouse",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex align-center py-0 pb-4" },
            [
              _vm.dateRangesReady
                ? _c("date-range", {
                    attrs: {
                      disabled: _vm.showAllTime,
                      "input-max-width": "20rem",
                      "initial-range": _vm.dateRange,
                    },
                    model: {
                      value: _vm.dateRange,
                      callback: function ($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange",
                    },
                  })
                : _vm._e(),
              _c("all-time-checkbox", {
                staticClass: "ml-2",
                model: {
                  value: _vm.showAllTime,
                  callback: function ($$v) {
                    _vm.showAllTime = $$v
                  },
                  expression: "showAllTime",
                },
              }),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn-toggle",
            {
              attrs: { tile: "", color: "secondary", group: "" },
              model: {
                value: _vm.layout,
                callback: function ($$v) {
                  _vm.layout = $$v
                },
                expression: "layout",
              },
            },
            [
              _c(
                "v-btn",
                { attrs: { value: "stacked" } },
                [
                  _c("span", { staticClass: "hidden-sm-and-down" }, [
                    _vm._v("Stacked"),
                  ]),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-view-sequential"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { value: "grid" } },
                [
                  _c("span", { staticClass: "hidden-sm-and-down" }, [
                    _vm._v("Grid"),
                  ]),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-view-grid"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c(
            "v-col",
            {
              staticClass: "pt-0",
              attrs: { cols: _vm.layout === "grid" ? 6 : 12 },
            },
            [
              _c("appointments-by-week-day-bar-chart", {
                key: _vm.renderKey,
                attrs: {
                  "date-range": _vm.dateRange,
                  "show-all-time": _vm.showAllTime,
                  "consolidate-capacity-docks": _vm.consolidateCapacityDocks,
                  "selected-warehouse": _vm.selectedWarehouse,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-0",
              attrs: { cols: _vm.layout === "grid" ? 6 : 12 },
            },
            [
              _c("appointments-by-load-type-bar-chart", {
                key: _vm.renderKey,
                attrs: {
                  "date-range": _vm.dateRange,
                  "show-all-time": _vm.showAllTime,
                  "selected-warehouse": _vm.selectedWarehouse,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.layout === "grid" ? 6 : 12 } },
            [
              _c("appointment-statuses-by-dock-chart", {
                key: _vm.renderKey,
                attrs: {
                  "date-range": _vm.dateRange,
                  "show-all-time": _vm.showAllTime,
                  "consolidate-capacity-docks": _vm.consolidateCapacityDocks,
                  "selected-warehouse": _vm.selectedWarehouse,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.layout === "grid" ? 6 : 12 } },
            [
              _c("appointments-time-of-day-bar-chart", {
                key: _vm.renderKey,
                attrs: {
                  "date-range": _vm.dateRange,
                  "show-all-time": _vm.showAllTime,
                  "selected-warehouse": _vm.selectedWarehouse,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.layout === "grid" ? 6 : 12 } },
            [
              _c("appointments-in-date-range-bar-chart", {
                key: _vm.renderKey,
                attrs: {
                  "date-range": _vm.dateRange,
                  "show-all-time": _vm.showAllTime,
                  "selected-warehouse": _vm.selectedWarehouse,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.layout === "grid" ? 6 : 12 } },
            [
              _c("appointments-by-carrier-bar-chart", {
                key: _vm.renderKey,
                attrs: {
                  "date-range": _vm.dateRange,
                  "show-all-time": _vm.showAllTime,
                  "selected-warehouse": _vm.selectedWarehouse,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.layout === "grid" ? 6 : 12 } },
            [
              _c("average-duration-by-load-type-bar-chart", {
                key: _vm.renderKey,
                attrs: {
                  "date-range": _vm.dateRange,
                  "show-all-time": _vm.showAllTime,
                  "selected-warehouse": _vm.selectedWarehouse,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.layout === "grid" ? 6 : 12 } },
            [
              !_vm.novaCore.isInternalRole(_vm.$me.role) &&
              _vm.selectedWarehouse
                ? _c("dock-dwell-time-chart", {
                    key: _vm.renderKey,
                    attrs: {
                      "date-range": _vm.dateRange,
                      "show-all-time": _vm.showAllTime,
                      "selected-warehouse": _vm.selectedWarehouse,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.layout === "grid" ? 6 : 8 } },
            [
              !_vm.novaCore.isInternalRole(_vm.$me.role) &&
              _vm.selectedWarehouse
                ? _c("first-avail-appts", {
                    key: _vm.renderKey,
                    attrs: { "selected-warehouse": _vm.selectedWarehouse },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }