<template>
  <div class="custom-forms-data-panels">
    <template v-if="loadingDateTimes">
      <generic-loader class="pt-8"></generic-loader>
    </template>
    <template v-else>
      <v-expansion-panels flat multiple>
        <template v-for="trigger of renderedTriggers">
          <expansion-panel
            v-if="isLocked || trigger.isActive"
            :is-locked="isLocked"
            :key="trigger.id"
            :is-disabled="
              !triggerDataLastChangedDateTimes[trigger.id] &&
              !$scopedSlots[`additional-form-data-${trigger.id}`]
            ">
            <template #title>
              {{ novaCore.CustomFormsFeaturesData[trigger.feature].title }}
            </template>
            <template #header-right>
              <v-icon v-if="isLocked" small class="mr-1">mdi-lock</v-icon>
              <template v-else-if="editable">
                <outline-button
                  v-if="!triggerDataLastChangedDateTimes[trigger.id]"
                  class="form-button"
                  small
                  @click.native.stop="activeTrigger = trigger">
                  Submit Form
                </outline-button>
                <template v-else>
                  <div class="d-flex align-center">
                    <span class="disclaimer">
                      <v-icon
                        v-if="lastChangedDateTimesInTimezone[trigger.id]"
                        x-small
                        class="mr-1">
                        mdi-update
                      </v-icon>
                      {{ lastChangedDateTimesInTimezone[trigger.id] }}
                    </span>
                    <v-btn class="ml-4" icon @click.native.stop="activeTrigger = trigger">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </template>
              </template>
              <span v-else class="disclaimer">
                <v-icon v-if="lastChangedDateTimesInTimezone[trigger.id]" x-small class="mr-1">
                  mdi-update
                </v-icon>
                {{ lastChangedDateTimesInTimezone[trigger.id] }}
                <slot
                  name="additional-disclaimer"
                  v-bind:trigger="trigger"
                  v-bind:lastChangedDateTimesInTimezone="lastChangedDateTimesInTimezone"></slot>
              </span>
            </template>

            <template #content>
              <slot :name="`additional-form-data-${trigger.id}`"></slot>
              <custom-forms-data
                v-if="triggerDataLastChangedDateTimes[trigger.id]"
                flat
                :prepended-data="getStaticAssetDetailsFields(trigger)"
                :key="trigger.id"
                :trigger="trigger"
                :object-id="getObjectId(trigger.dataEntityName)"
                :timezone="timezone"
                :military-time-enabled="militaryTimeEnabled"
                @customformdata-updated="updateTimestamp" />
            </template>
          </expansion-panel>
        </template>
      </v-expansion-panels>

      <edit-custom-form-dialog
        :external-activator="true"
        :warehouse="warehouse"
        :show-dialog="Boolean(activeTrigger)"
        :object-id="getObjectId(activeTrigger?.dataEntityName)"
        :trigger="activeTrigger"
        @close="cleanUpCustomFormsData"
        @update="handleFormUpdate"
        @create="handleFormCreate"></edit-custom-form-dialog>
    </template>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useNovaCore, useCustomForms } from '@/composables';
import { formatDateTimeWithMilitarySupport, LuxonDateTimeFormats } from '@satellite/../nova/core';
import { DateTime } from 'luxon';

export default {
  props: {
    objectIds: {
      type: Object,
      required: true
    },
    appointment: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    triggers: {
      type: Array,
      required: true
    },
    timezone: {
      type: String,
      required: true
    },
    militaryTimeEnabled: {
      type: Boolean,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    },
    isLocked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const novaCore = useNovaCore();

    const {
      activeTrigger,
      hasActiveTrigger,
      createBulkCustomFormsData,
      updateCustomFormsData,
      handleActionFieldTriggered,
      customFormsDataChange,
      cleanUpCustomFormsData,
      checkForTriggerOnStatus,
      getCustomFormsData,
      customFormsData
    } = useCustomForms(props.appointment.warehouse?.timezone, props.militaryTimeEnabled);

    const renderedTriggers = computed(() =>
      props.isLocked ? props.triggers : props.triggers.filter(t => t.isActive)
    );

    const loadingDateTimes = ref(false);

    const triggerDataLastChangedDateTimes = ref({});
    props.triggers.forEach(trigger => {
      triggerDataLastChangedDateTimes.value[trigger.id] = false;
    });

    const lastChangedDateTimesInTimezone = computed(() => {
      const result = {};
      Object.keys(triggerDataLastChangedDateTimes.value).forEach(triggerId => {
        result[triggerId] = getLastChangedDateTimeInTimezone(triggerId);
      });
      return result;
    });

    function getLastChangedDateTimeInTimezone(triggerId) {
      if (triggerDataLastChangedDateTimes.value[triggerId]) {
        return formatDateTimeWithMilitarySupport(
          triggerDataLastChangedDateTimes.value[triggerId],
          props.timezone,
          LuxonDateTimeFormats.DateSlashedTimestamp12HourTimeAMPM,
          props.militaryTimeEnabled,
          LuxonDateTimeFormats.DateSlashedTimestamp24HourTime
        );
      }
      return '';
    }

    function updateTimestamp(triggerId) {
      const dateTimes = triggerDataLastChangedDateTimes.value;
      dateTimes[triggerId] = DateTime.now().toUTC();
      triggerDataLastChangedDateTimes.value = { ...dateTimes };
    }

    function getStaticAssetDetailsFields(trigger) {
      if (
        novaCore.CustomFormsFeaturesData[trigger.feature]?.title
          ?.toLowerCase()
          .includes('additional asset details')
      ) {
        return [
          {
            label: 'Phone',
            value: props.appointment.assetVisit?.phone,
            lastChangedDateTime: props.appointment.assetVisit?.createDateTime
          },
          {
            label: 'Carrier Company',
            value: props.appointment.user?.company?.name,
            lastChangedDateTime: props.appointment.assetVisit?.createDateTime
          }
        ];
      }
    }

    async function handleFormUpdate(value) {
      const activeTriggerId = activeTrigger.value.id;
      try {
        await updateCustomFormsData(value);
        updateTimestamp(activeTriggerId);
      } catch (error) {
        console.error('Error updating custom forms data:', error);
      }
    }

    async function handleFormCreate(value) {
      const activeTriggerId = activeTrigger.value.id;
      try {
        await createBulkCustomFormsData(value, props.warehouse.id);
        updateTimestamp(activeTriggerId);
      } catch (error) {
        console.error('Error creating custom forms data:', error);
      }
    }

    function getObjectId(triggerDataEntityName) {
      return props.objectIds[`${triggerDataEntityName}Id`] ?? '';
    }

    watch(
      () => props.triggers,
      async newVal => {
        const newDataLastChangedDateTimes = {};
        for (const trigger of newVal) {
          try {
            loadingDateTimes.value = true;
            newDataLastChangedDateTimes[trigger.id] = await getCustomFormsData(
              getObjectId(trigger.dataEntityName),
              trigger.id,
              true
            );
          } catch (error) {
            console.error(`Error fetching data for trigger ${trigger.id}:`, error);
          } finally {
            loadingDateTimes.value = false;
          }
        }
        triggerDataLastChangedDateTimes.value = newDataLastChangedDateTimes;
      },
      { immediate: true }
    );

    return {
      triggerDataLastChangedDateTimes,
      novaCore,
      activeTrigger,
      hasActiveTrigger,
      createBulkCustomFormsData,
      updateCustomFormsData,
      handleActionFieldTriggered,
      customFormsDataChange,
      cleanUpCustomFormsData,
      checkForTriggerOnStatus,
      customFormsData,
      loadingDateTimes,
      handleFormCreate,
      handleFormUpdate,
      lastChangedDateTimesInTimezone,
      updateTimestamp,
      getObjectId,
      getStaticAssetDetailsFields,
      renderedTriggers
    };
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  margin-top: 16px;
  border: 1px solid $color-line-border;
  border-radius: $default-border-radius;
}

.form-button {
  pointer-events: all;
}

.is-disabled {
  .icon {
    display: none;
  }
  .v-expansion-panel-header {
    pointer-events: none;
  }
}

.v-expansion-panel-header {
  height: 60px !important;
}
.icon {
  order: 0;
}
.header-content {
  order: 1;
}
.spacer {
  order: 2;
}
.header-right {
  order: 3;
  display: flex;
  justify-content: flex-end;
}
.custom-forms-data-panels .v-expansion-panel--disabled {
  color: black;
}
.disclaimer {
  color: $color-text-tertiary;
  font-size: 12px;
}
::v-deep .v-expansion-panel-header {
  padding: 12px;
}
::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 0;
  height: 48px;
}
</style>
