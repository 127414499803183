<template>
  <span class="d-flex align-center input">
    <v-switch
      dense
      height="20px"
      v-model="value"
      color="primary"
      :label="label"
      hide-details="auto"></v-switch>
    <span v-if="field.required" class="red--text required-bool-label"><strong>*</strong></span>
  </span>
</template>

<script>
import customFieldTypeMixin from '@/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-bool',
  mixins: [customFieldTypeMixin],
  computed: {
    label() {
      return this.value ? 'Yes' : 'No';
    }
  }
};
</script>

<style lang="scss" scoped>
.input {
  height: 32px;
}
::v-deep .v-input--switch label {
  margin-top: -4px;
}
</style>
