var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Create Organization",
          "loading-message": "Creating Org...",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.createOrgForm.submit()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b(
                            { staticClass: "mr-2", attrs: { large: "" } },
                            "primary-button",
                            slotProps.attrs,
                            false
                          ),
                          slotProps.on
                        ),
                        [_vm._v(" Create Organization ")]
                      ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("create-org-form", {
            ref: "createOrgForm",
            attrs: { "redirect-path": _vm.redirectPath },
            on: { close: _vm.close },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }