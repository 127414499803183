/**
 * Mixin used in selects
 * @mixin
 * @displayName Select Mixin
 */
export default {
  props: {
    /**
     * Hide icon
     */
    hideIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Select label
     */
    label: {
      type: String,
      required: false
    },
    /**
     * Is Select Required
     */
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    truncationOptions: {
      type: Object,
      required: false,
      default() {
        return {
          isTruncated: false,
          truncateLength: 15
        };
      }
    },
    maximumSelections: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      defaultTruncateLength: 15
    };
  },
  computed: {
    isAtSelectionLimit() {
      return this.maximumSelections && this.value.length >= this.maximumSelections;
    }
  },
  methods: {}
};
