var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.trigger?.flow?.id
      ? _c(
          "div",
          [
            _vm.trigger.flow.formTo?.id
              ? _c(
                  "v-tabs",
                  {
                    staticClass: "non-active-tab-underline",
                    attrs: { centered: "", grow: "" },
                    model: {
                      value: _vm.formTab,
                      callback: function ($$v) {
                        _vm.formTab = $$v
                      },
                      expression: "formTab",
                    },
                  },
                  [
                    _c(
                      "v-tab",
                      {
                        on: {
                          click: function ($event) {
                            _vm.activeForm = "formFrom"
                          },
                        },
                      },
                      [_vm._v("First form fields")]
                    ),
                    _c(
                      "v-tab",
                      {
                        on: {
                          click: function ($event) {
                            _vm.activeForm = "formTo"
                          },
                        },
                      },
                      [_vm._v("Second form fields")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.formTab,
                  callback: function ($$v) {
                    _vm.formTab = $$v
                  },
                  expression: "formTab",
                },
              },
              _vm._l(["formFrom", "formTo"], function (form) {
                return _c(
                  "v-tab-item",
                  { key: form, attrs: { readonly: _vm.readonly } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { lg: "8", xl: "6", sm: "12" } },
                          [
                            !_vm.readonly
                              ? _c("custom-forms-field-selector", {
                                  attrs: {
                                    "data-testid": `custom-forms-field-selector-${form}`,
                                    "existing-fields": _vm.trigger.flow[
                                      form
                                    ]?.formFields?.map((f) => f.field),
                                  },
                                  on: {
                                    add: (field) =>
                                      _vm.handleAddField(field, form),
                                    create: _vm.showCreateFieldDialog,
                                    remove: (field) =>
                                      _vm.handleRemoveFormField(field, form),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-data-table", {
                      class: { disabled: _vm.readonly },
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.trigger.flow[form]?.formFields || [],
                        "item-key": "id",
                        "sort-by": "order",
                        id: `${form}-table`,
                        "hide-default-footer": "",
                        "disable-pagination": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.$rolePermissions.canUpdateWarehouse
                            ? {
                                key: "item.order",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        class: `handle-${form}`,
                                        attrs: { color: "black", small: "" },
                                      },
                                      [_vm._v(" mdi-arrow-split-horizontal ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                          {
                            key: "item.field.type",
                            fn: function ({ item }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.novaCore.customFieldLabels[
                                        item.field.type
                                      ]
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.field.label",
                            fn: function ({ item }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.overrideLabel || item.field.label
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.required",
                            fn: function ({ item }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(item.required ? "Yes" : "No") +
                                    " "
                                ),
                              ]
                            },
                          },
                          !_vm.readonly
                            ? {
                                key: "item.actions",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "row-actions" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              title: "Edit field",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.showFormFieldDialog[
                                                  form
                                                ] = true
                                                _vm.editingFormField = item
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  color: "secondary",
                                                },
                                              },
                                              [_vm._v("mdi-pencil")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "d-inline-block",
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                disabled:
                                                                  _vm.isConditionField(
                                                                    item.id
                                                                  ) ||
                                                                  _vm.isActionField(
                                                                    item.field
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleRemoveFormField(
                                                                      item.field,
                                                                      form,
                                                                      "Custom Form Actions Column"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "red",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-sort-variant-remove"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.isConditionField(
                                                      item.id
                                                    ) ||
                                                      _vm.isActionField(
                                                        item.field
                                                      )
                                                      ? "This field cannot be removed"
                                                      : "Remove field from this form"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("custom-forms-form-field-dialog", {
                      attrs: {
                        id: `edit-form-field-${form}`,
                        "external-activator": "",
                        "form-field": _vm.editingFormField,
                        "form-id": _vm.trigger.flow[form]?.id,
                        "show-dialog": _vm.showFormFieldDialog[form],
                      },
                      on: {
                        close: function ($event) {
                          _vm.editingFormField = null
                          _vm.showFormFieldDialog[form] = false
                        },
                        save: (formField) => {
                          _vm.trackFieldOperation(
                            formField.field.label,
                            "EDIT_FORM_FIELD",
                            null
                          )
                          _vm.$emit("data-updated")
                        },
                      },
                    }),
                    _c("custom-forms-field-dialog", {
                      attrs: {
                        "external-activator": "",
                        "label-suggestion": _vm.searchFieldStr,
                        "show-dialog": _vm.showFieldDialog,
                      },
                      on: {
                        close: function ($event) {
                          _vm.showFieldDialog = null
                          _vm.searchFieldStr = ""
                        },
                        save: _vm.handleCreateField,
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "mt-3" },
          [
            _c(
              "v-alert",
              {
                staticClass: "grey py-4",
                attrs: { type: "warning", color: "info lighten-1" },
              },
              [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v("Feature not available"),
                ]),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }