<template>
  <v-dialog :value="updateType" width="600" @close="updateType = null" persistent>
    <v-card v-if="updateType">
      <v-card-title>
        <h4 class="text-capitalize" data-testid="yard-spot-assignment-dialog-title">
          <v-icon small class="mr-2">mdi-alert-circle-outline</v-icon>
          <span v-if="updateType === 'unassign'">Confirm unassignment?</span>
          <span v-else-if="updateType === 'depart'">Confirm asset departure?</span>
        </h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p v-if="updateType === 'depart'">
          This asset will be cleared from the Live Yard View, and the spot will be marked as open
          for assignment again.
        </p>
        <p v-else-if="updateType === 'unassign'">
          This asset will be removed from this spot and moved back to the Waiting list.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeDialog" data-testid="yard-spot-assignment-dialog-cancel">
          Nevermind
        </v-btn>
        <v-btn color="primary" @click="submit" data-testid="yard-spot-assignment-dialog-submit">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { useEventHub } from '@/composables';

export default defineComponent({
  name: 'YardViewSpotAssignmentUpdateDialog',

  props: {
    updateType: {
      type: String,
      required: true,
      validator: value => ['unassign', 'depart', ''].includes(value)
    },
    spotAssignment: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();
    const eventHub = useEventHub();

    const submit = () => {
      if (props.updateType === 'depart') {
        yardStore.departSpotAssignment(props.spotAssignment.id);
      }
      if (props.updateType === 'unassign') {
        yardStore.deleteSpotAssignment(props.spotAssignment.id);
      }
      closeDialog();
    };

    const closeDialog = () => {
      eventHub.$emit('close-spot-assignment-update-dialog');
    };

    return {
      submit,
      closeDialog
    };
  }
});
</script>
