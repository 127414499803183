const state = {
  connectionStatus: {
    state: null,
    lastConnected: null
  }
};

const actions = {};

const mutations = {
  setConnectionStatus(state, value) {
    state.connectionStatus.state = value.state;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
