var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: _vm.header,
          loading: false,
          width: "600",
          "has-dialog-actions": false,
          "max-width": "100%",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.setEtaForm.submit()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _vm.alertMessage
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mb-0",
                        attrs: { type: _vm.alertType, shaped: "" },
                      },
                      [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
                    )
                  : _vm._e(),
                _c("set-eta-form-old", {
                  ref: "setEtaForm",
                  attrs: {
                    "party-to-alert": _vm.partyToAlert,
                    appointment: _vm.appointment,
                  },
                  on: {
                    "condition-updated": _vm.updateCondition,
                    close: _vm.close,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }