var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    staticClass: "entry-form-container",
    attrs: { header: _vm.header, "hide-required-legend": true },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              {
                ref: "form",
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "v-container",
                  [
                    _c("text-field", {
                      attrs: {
                        required: true,
                        outlined: "",
                        dense: "",
                        "persistent-placeholder": true,
                        placeholder: "Type your first name ...",
                        rules: _vm.$validator.rules.required("First Name"),
                        label: "First name",
                      },
                      model: {
                        value: _vm.firstName,
                        callback: function ($$v) {
                          _vm.firstName = $$v
                        },
                        expression: "firstName",
                      },
                    }),
                    _c("text-field", {
                      attrs: {
                        required: true,
                        outlined: "",
                        dense: "",
                        "persistent-placeholder": true,
                        placeholder: "Type your last name ...",
                        rules: _vm.$validator.rules.required("Last Name"),
                        label: "Last name",
                      },
                      model: {
                        value: _vm.lastName,
                        callback: function ($$v) {
                          _vm.lastName = $$v
                        },
                        expression: "lastName",
                      },
                    }),
                    _c("text-field", {
                      attrs: {
                        required: true,
                        type: "email",
                        outlined: "",
                        dense: "",
                        "persistent-placeholder": true,
                        placeholder: "Type your email ...",
                        rules: [
                          ..._vm.$validator.rules.required("Email"),
                          ..._vm.$validator.rules.email,
                        ],
                        label: "Email",
                      },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                    _c("text-field", {
                      attrs: {
                        required: true,
                        type: "password",
                        outlined: "",
                        dense: "",
                        "persistent-placeholder": true,
                        placeholder: "Type your password ...",
                        rules: _vm.$validator.rules.password,
                        label: "Create a password",
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                    _c("password-restrictions", {
                      attrs: { password: _vm.password },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pb-8 px-2" },
              [
                _c(
                  "primary-button",
                  {
                    staticClass: "mt-2 registration-button",
                    attrs: { block: "", "x-large": "", loading: _vm.loading },
                    on: { click: _vm.submit },
                  },
                  [_vm._v(" Register ")]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-center pt-4" },
                  [
                    _c("span", { staticClass: "is-uppercase" }, [
                      _vm._v("Already Have an Account?"),
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "registration-btn sign-in-btn ml-2",
                        attrs: { to: { name: "login" }, text: "" },
                      },
                      [_vm._v(" Sign In ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }