<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :external-activator="true"
    :header="actionText"
    :loading="loading"
    :loading-message="loadingMessage"
    @close="close"
    @confirm="submit">
    <template slot="body">
      <v-form ref="form" @keyup.native.enter="submit">
        <v-container class="px-7 pb-7">
          <v-text-field prepend-icon="mdi-email" disabled :value="email"></v-text-field>

          <text-field
            :required="true"
            :type="showPasswords ? 'text' : 'password'"
            prepend-icon="mdi-key"
            :append-icon="showPasswords ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="password"
            :rules="$validator.rules.password"
            @click:append="showPasswords = !showPasswords"
            label="Password">
          </text-field>

          <text-field
            :required="true"
            :type="showPasswords ? 'text' : 'password'"
            prepend-icon="mdi-key"
            :append-icon="showPasswords ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="passwordConfirm"
            :rules="$validator.rules.eq(password, 'Passwords do not match')"
            @click:append="showPasswords = !showPasswords"
            label="Confirm password">
          </text-field>

          <password-restrictions :password="password"> </password-restrictions>
        </v-container>

        <action-group
          @cancel="close"
          confirm-icon="check"
          :confirm-label="actionText"
          @confirm="submit">
        </action-group>
      </v-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
/**
 * Button/Dialog for the password reset form
 * @displayName Password Reset Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Email address of account
     */
    email: {
      type: String,
      required: false
    },
    /**
     * Password reset token
     */
    resetToken: {
      type: String,
      required: false
    },
    /**
     * If true it will set the isEmailVerified=true as well
     */
    verifyAccount: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      password: '',
      passwordConfirm: '',
      showPasswords: false,
      loading: false
    };
  },
  computed: {
    actionText() {
      return this.verifyAccount ? 'Create your password' : 'Reset Password';
    },
    loadingMessage() {
      return this.verifyAccount ? 'Setting password...' : 'Resetting password...';
    }
  },
  methods: {
    /**
     * Removes params since they are what trigger the password reset dialog
     */
    close() {
      this.$router.replace('/login');
    },
    /**
     * Submit the form
     * @public
     */
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const action = this.verifyAccount ? 'verifyAndResetPassword' : 'resetPassword';

      this.$store
        .dispatch(`Auth/${action}`, {
          email: this.email,
          password: this.password,
          token: this.resetToken
        })
        .then(() => {
          const message = this.verifyAccount
            ? 'Account verified and password set!'
            : 'Password has been reset!';
          this.notify(message);
          this.$router.replace('/login?showLogin=true');
        })
        .finally(() => {
          this.loading = false;
        });
      if (this.verifyAccount) {
        this.mixpanel.track(this.mixpanel.events.ACCOUNT.CREATED_PASSWORD, {
          'User Invite Email': this.email
        });
      }
    }
  }
};
</script>
