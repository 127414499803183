export default {
  /**
   * @param key name of the item to be stored
   * @param userId id of the logged user
   * @returns {string} a key bounded to that org
   */
  makeUserBoundedKey(key, userId) {
    return `${key}_${userId}`;
  }
};
