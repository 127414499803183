<template>
  <div>
    <v-form ref="form" id="dock-dialog-form" class="pb-5">
      <v-row>
        <v-col cols="6" xs="12">
          <text-field
            :readonly="!$rolePermissions.canCreateDock"
            :required="true"
            class="mb-0"
            type="text"
            hide-details="auto"
            v-model="internalDock.name"
            prepend-icon="mdi-warehouse"
            :rules="$validator.rules.required('Dock Name')"
            label="Dock Name"></text-field>
        </v-col>
        <v-col cols="6" xs="12">
          <text-field
            :readonly="!$rolePermissions.canCreateDock"
            dense
            height="20px"
            class="mb-0"
            type="text"
            hide-details="auto"
            prepend-icon="mdi-garage"
            v-model="internalDock.doorNumber"
            label="Dock Door Number"></text-field>
        </v-col>
      </v-row>
      <v-row class="mt-7">
        <v-col>
          <load-type-select
            select-all
            :loading="loading"
            :disabled="loading"
            :readonly="!$rolePermissions.canCreateDock"
            v-model="internalDock.loadTypeIds"
            :return-object="false"
            :visible-selection-count="3"
            :clearable="$rolePermissions.canCreateDock"
            :required="true"
            :appendCreateLoadTypeOnList="true"
            :selectedWarehouse="warehouse"
            :rules="$validator.rules.selectAtLeast(1, 'Load Type')"
            :multi-select="true"></load-type-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-if="$rolePermissions.canCreateDock"
            prepend-icon="mdi-message-bulleted"
            rows="2"
            outlined
            label="Instructions"
            v-model="internalDock.instructions"></v-textarea>
          <div v-else class="v-input v-text-field mb-4">
            <div class="v-text-field__slot full-width">
              <label class="v-label font-size-x-small">Instructions</label>
              <div class="text--disabled border-width-1 bordered rounded pa-4 full-width">
                {{ internalDock.instructions }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="mb-2">
          <div class="d-flex align-end">
            <email-list-field
              class="full-width mb-4"
              :read-only="!$rolePermissions.canCreateDock"
              label="Default Email Subscribers"
              prepend-icon="mdi-email-outline"
              v-model="internalDock.ccEmails"
              tooltipText="Default Email Subscribers for appointments at this dock."
              small-chips
              small></email-list-field>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="false">
        <v-col>
          <tag-manager
            :read-only="!$rolePermissions.canCreateDock"
            hide-details
            small-chips
            small
            prepend-icon="mdi-tag"
            v-model="internalDock.tags"
            label="Dock Tags"></tag-manager>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h2 class="mt-12 mb-2 black--text">Scheduling Portal Availability</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <carrier-scheduling-toggle
            entity="Dock"
            :disabled="!$rolePermissions.canCreateDock"
            v-model="internalDock.allowCarrierScheduling"></carrier-scheduling-toggle>
        </v-col>
      </v-row>

      <template v-if="internalDock.allowCarrierScheduling">
        <h2 class="mt-7">Lead Times - Prevent Carriers From:</h2>

        <v-row class="mt-1">
          <v-col class="disable-label-click">
            <v-switch
              :disabled="!$rolePermissions.canCreateDock"
              v-model="enableMinLeadTime"
              color="secondary"
              class="mt-1"
              hide-details="auto"
              dense>
              <template v-slot:label>
                <div class="pl-3" :class="{ 'text--disabled': !enableMinLeadTime }">
                  Scheduling an appointment within
                  <text-field
                    v-model="internalDock.minCarrierLeadTime_hr"
                    ref="leadtimehours"
                    :disabled="!enableMinLeadTime"
                    class="inline-text-field no-spinner text-center"
                    type="number"></text-field>
                  <strong>hours</strong>
                  of its start time.
                </div>
              </template>
            </v-switch>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col class="disable-label-click">
            <v-switch
              :disabled="!$rolePermissions.canCreateDock"
              v-model="enableMinLeadTimeUpdates"
              color="secondary"
              class="mt-1"
              hide-details="auto"
              dense>
              <template v-slot:label>
                <div class="pl-3" :class="{ 'text--disabled': !enableMinLeadTimeUpdates }">
                  Modifying an appointment within
                  <text-field
                    v-model="internalDock.minCarrierLeadTimeForUpdates_hr"
                    ref="leadtimehoursforupdates"
                    :disabled="!enableMinLeadTimeUpdates"
                    class="inline-text-field no-spinner text-center"
                    type="number"></text-field>
                  <strong>hours</strong>
                  of its start time.
                </div>
              </template>
            </v-switch>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col class="disable-label-click">
            <v-switch
              :disabled="!$rolePermissions.canCreateDock"
              v-model="enableMaxLeadTime"
              color="secondary"
              class="mt-1"
              dense
              hide-details="auto">
              <template v-slot:label>
                <div class="pl-3" :class="{ 'text--disabled': !enableMaxLeadTime }">
                  Scheduling more than
                  <text-field
                    v-model="maxLeadTimeInDays"
                    ref="maxleadtime"
                    :disabled="!enableMaxLeadTime"
                    class="inline-text-field no-spinner text-center"
                    type="number"></text-field>
                  <strong>days</strong>
                  in the future.
                </div>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </template>
    </v-form>

    <v-row>
      <v-col>
        <h2 class="mt-5 py-0 black--text">Experimental Feature</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0 mb-4" id="overbooking-container">
        <v-switch
          :disabled="!$rolePermissions.canCreateDock"
          v-model="internalDock.allowOverBooking"
          color="secondary"
          hide-details="auto"
          dense>
          <template
            v-slot:prepend
            v-if="internalDock.capacityNumber > 1 && internalDock.allowOverBooking">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon class="circular-pulse" v-on="on" color="red">
                  mdi-alert-octagon-outline
                </v-icon>
              </template>
              <span>
                Overbooking should not be enabled on a dock that has a Parallel Capacity of 2 or
                more.
              </span>
            </v-tooltip>
          </template>
          <template v-slot:label>
            <div class="ml-3" :class="{ 'text--disabled': !internalDock.allowOverBooking }">
              Allow Overbooking
              <br />
              <strong>Warning:</strong>
              this allows unlimited appointment overlap for the Warehouse and the Carriers. To
              revert this, you must make sure there are no overlapping appointments.
            </div>
          </template>
        </v-switch>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2 class="mt-2 py-0 black--text">Dock Schedule</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-btn-toggle mandatory v-model="view" dense class="secondary-button-group pl-3 mt-4">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div class="d-inline" v-on="on">
              <v-btn
                class="rounded-tr-0 rounded-br-0"
                elevation="0"
                small
                :value="viewTypes.schedule">
                <v-icon small class="mr-2">mdi-calendar</v-icon>
                Weekly Schedule
              </v-btn>
            </div>
          </template>
          <span>View the weekly schedule</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div class="d-inline" v-on="on">
              <v-btn elevation="0" small :value="viewTypes.closedDates">
                <v-icon small class="mr-2">mdi-calendar-remove</v-icon>
                Closed Dates
              </v-btn>
            </div>
          </template>
          <span>View the closed dates</span>
        </v-tooltip>
      </v-btn-toggle>
    </v-row>
    <template v-if="warehouse.id">
      <v-row :class="{ 'visually-remove': view === viewTypes.closedDates }">
        <v-col class="mt-2">
          <template v-if="$rolePermissions.canCreateDock">
            <time-select-grid
              :settings-entity="warehouse"
              :enabled-intervals="warehouse.schedule"
              ref="timeSelect"
              :toggle-to-edit="false"
              :local-storage-key="internalDock.id"
              compact
              :value="internalDock.schedule"
              :docks="warehouse.docks"
              @update-interval="val => (this.interval = val)"
              @set-schedule-default="setScheduleToDefault"
              @set-schedule-closed="clearDockSchedule"
              @copy-warehouse-schedule="copyWarehouseScheduleToDock"
              @copy-dock-schedule="copyDockScheduleToDock"></time-select-grid>
          </template>

          <template v-else>
            <div class="mt-3 mb-4 text-h6 black--text mt-3">Hours of Operation</div>
            <hours-of-operation-list
              v-if="internalDock.schedule && warehouse.timezone"
              :schedule="internalDock.schedule"
              :timezone="warehouse.timezone"></hours-of-operation-list>
          </template>
        </v-col>
      </v-row>

      <v-row :class="{ 'visually-remove': view === viewTypes.schedule }">
        <v-col class="mt-3">
          <days-off
            :schedule="internalDock.schedule"
            :warehouse="warehouse"
            @update-closed-intervals="updateClosedIntervals"></days-off>
        </v-col>
      </v-row>
    </template>

    <action-group
      :loading="loading"
      v-if="$rolePermissions.canCreateDock"
      @cancel="$emit('close')"
      confirm-icon="upload"
      confirm-label="Save Dock"
      cancel-label="Nevermind"
      @confirm="submit"></action-group>
  </div>
</template>

<script>
import dockScheduleMixin from '@satellite/components/mixins/dockScheduleMixin';

/**
 * Create/Edit Dock Form
 * @displayName Dock Form
 */
export default {
  name: 'DockForm',
  mixins: [dockScheduleMixin],
  props: {
    /**
     * Dock object
     */
    dock: {
      type: Object,
      required: false
    },
    /**
     * Position of the dock being created - not used when editing a dock
     */
    sortOrder: {
      type: Number,
      required: false
    },
    /**
     * Warehouse ID
     */
    warehouseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      minLeadTimeInitialized: false,
      maxLeadTimeInitialized: false,
      maxLeadTimeInDays: null,
      internalDock: {},
      warehouse: {},
      enableMinLeadTime: false,
      enableMinLeadTimeUpdates: false,
      enableMaxLeadTime: false,
      formInitialized: false,
      showOverbookWarningDialog: false,
      interval: 30,
      disable30MinOption: false,
      closedIntervals: [],
      loading: false
    };
  },
  computed: {
    ccEmailsStr() {
      return this.internalDock.ccEmails ? this.internalDock.ccEmails.join() : '';
    }
  },
  methods: {
    /**
     * Submit form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      let response = null;

      if (!this.$refs.form.validate() || !this.$refs.timeSelect) {
        return;
      }

      this.$refs.timeSelect.saveChanges();

      await this.removeMissingLoadTypesFromDock();

      this.internalDock.doorNumber = this.internalDock.doorNumber || null;

      // Neutron expects a number, not a string!
      this.internalDock.minCarrierLeadTime_hr =
        this.internalDock.minCarrierLeadTime_hr !== null && this.enableMinLeadTime
          ? Number(this.internalDock.minCarrierLeadTime_hr)
          : null;

      this.internalDock.minCarrierLeadTimeForUpdates_hr =
        this.internalDock.minCarrierLeadTimeForUpdates_hr !== null && this.enableMinLeadTimeUpdates
          ? Number(this.internalDock.minCarrierLeadTimeForUpdates_hr)
          : null;

      this.internalDock.maxCarrierLeadTime_hr =
        this.maxLeadTimeInDays && this.enableMaxLeadTime
          ? Number(this.maxLeadTimeInDays * 24)
          : null;

      const data = {
        ...this.internalDock,
        ...{
          schedule: {
            ...this.$refs.timeSelect.internalSchedule,
            closedIntervals: this.closedIntervals
          },
          warehouseId: this.warehouseId
        }
      };
      const scheduleChanged =
        JSON.stringify(this.dock?.schedule) !== JSON.stringify(this.internalDock?.schedule);
      let shouldUpdateCapacityChildren = false;
      if (scheduleChanged && data.capacityChildren?.length) {
        const capacityCount = data.capacityChildren.filter(
          capacityChild => capacityChild.id !== data.id
        ).length;
        await this.$confirm(
          `This dock has ${capacityCount} parallel capacity docks. Would you like to update their schedules to match this parent dock's schedule?"`,
          {
            width: 650,
            entityName: data.name,
            buttonTrueColor: 'secondary',
            buttonFalseText: 'NO, KEEP CAPACITY SCHEDULES',
            buttonTrueText: 'YES, UPDATE CAPACITY SCHEDULES',
            title: "Update capacity docks' schedules?"
          }
        ).then(async confirmed => {
          if (confirmed) {
            shouldUpdateCapacityChildren = true;
          }
        });
      }

      if (this.internalDock.id) {
        // Neutron demands that capacityParent/Id/Children are empty,
        // so we remove them before an UPDATE
        delete data.capacityParent;
        delete data.capacityParentId;
        delete data.capacityChildren;
        response = await this.$store.dispatch('Docks/updateDock', data);
      } else {
        response = await this.$store.dispatch('Docks/createDock', data);
      }

      if (response?.data) {
        if (shouldUpdateCapacityChildren) {
          for (const capacityChild of this.internalDock.capacityChildren) {
            if (capacityChild.id !== data.id) {
              // The parent dock itself is in its own capacity child array??
              await this.$store.dispatch('Docks/updateDock', {
                id: capacityChild.id,
                schedule: data.schedule
              });
            }
          }
          this.$notify('Capacity dock schedules updated!');
        }

        this.setDirtyStatus(false);

        if (!this.internalDock.id) {
          localStorage.setItem(`scheduleInterval-${response.data.data.id}`, this.interval);
        }

        const entryPoint = this.mixpanel.getEntryPoint(this, [
          {
            entryPoint: 'Docks Page',
            component: 'warehouse-docks-tab'
          }
        ]);
        this.mixpanel.track(
          this.internalDock.id
            ? this.mixpanel.events.MODULE.DOCK.UPDATED
            : this.mixpanel.events.MODULE.DOCK.CREATED,
          {
            'Dock ID': response.data.data.id,
            'Dock Name': response.data.data.name,
            'Warehouse ID': this.warehouse.id,
            'Warehouse Name': this.warehouse.name,
            'Entry Point': entryPoint,
            'Org ID': this.$org.id,
            'Org Name': this.$org.name
          }
        );

        this.$nextTick(() => {
          this.$emit('saved');
        });
      }
    },
    /**
     * Removes any loadTypeIds that are present on the dock, but not in the database.
     * @public
     */
    async removeMissingLoadTypesFromDock() {
      const loadTypes = this.novaCore.pluck(
        await this.$store.dispatch('LoadTypes/getLoadTypes'),
        'id'
      );
      this.internalDock.loadTypeIds = this.internalDock.loadTypeIds.filter(loadTypeId =>
        loadTypes.includes(loadTypeId)
      );
    },
    addEventListeners() {
      document.querySelectorAll('.disable-label-click').forEach(element => {
        element.querySelector('label').addEventListener('click', this.clickHandler);
      });
    },
    // Using named function here allows the browser to discard exact duplicate event listeners automatically
    clickHandler(e) {
      e.stopPropagation();
    },
    updateClosedIntervals(closedIntervals) {
      this.closedIntervals = closedIntervals;
    },
    async loadWarehouse() {
      this.loading = true;
      try {
        this.warehouse = await this.$store.dispatch(
          'Warehouses/getWarehouseById',
          this.warehouseId
        );
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    const dock = this.dock || {
      schedule: {},
      allowCarrierScheduling: true,
      sortOrder: this.sortOrder || null
    };
    this.internalDock = this.novaCore.deepClone(dock);
    await this.loadWarehouse();
    this.enableMinLeadTime = Boolean(this.internalDock.minCarrierLeadTime_hr);
    this.enableMinLeadTimeUpdates =
      this.internalDock.minCarrierLeadTimeForUpdates_hr !== null &&
      typeof this.internalDock.minCarrierLeadTimeForUpdates_hr !== 'undefined';
    this.enableMaxLeadTime = Boolean(this.internalDock.maxCarrierLeadTime_hr);
    if (this.enableMaxLeadTime) {
      this.maxLeadTimeInDays = this.internalDock.maxCarrierLeadTime_hr / 24;
    }

    this.addEventListeners();
    setTimeout(() => {
      this.formInitialized = true;
    }, 1000);
  },
  watch: {
    enableMinLeadTime(value) {
      if (value && this.mounted) {
        this.$nextTick(() => {
          this.$refs.minleadtimehours.$el.querySelector('input').focus();
        });
      }
      this.minLeadTimeInitialized = true;
    },
    internalDock: {
      handler() {
        this.setDirtyStatus(this.formInitialized);
      },
      deep: true
    },
    'internalDock.allowCarrierScheduling'(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.addEventListeners();
        });
      }
    },
    closedIntervals: {
      handler() {
        this.setDirtyStatus(this.formInitialized);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
#overbooking-container {
  .v-input__prepend-outer {
    display: flex;
    align-self: center;
  }
}
</style>
