<template>
  <div class="dst-divider">
    <span>Daylight Savings Changeover</span>
  </div>
</template>
<script>
export default {
  setup() {}
};
</script>

<style lang="scss" scoped>
.dst-divider {
  width: 80%;
  height: 1px;
  background: $color-line-divider;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;

  > span {
    position: absolute;
    font-size: 12px;
    white-space: nowrap;
    padding: 0 5px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 0;
    background-color: white;
  }
}
</style>
