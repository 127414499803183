<template>
  <div>
    <h4>Select the type of email notification you want to send out:</h4>
    <v-list dense id="email-notifications-list" data-testid="email-notification-list">
      <template v-for="(item, key) in novaCore.Notifications">
        <v-list-item dense class="pl-0" :key="key">
          <v-list-item-action class="my-0 mr-2">
            <v-checkbox
              :disabled="!entitySettings.muteAppointmentNotifications"
              :value="key"
              v-model="enabledAppointmentNotifications"
              :ripple="false"
              @click="handleMuteNotificationClick"
              class="mt-0"
              color="primary">
              <template v-slot:label>
                <div class="ml-2">{{ item.title }}</div>
              </template>
            </v-checkbox>
          </v-list-item-action>

          <v-list-item-content dense>
            <v-list-item-title>
              <span class="font-size-xx-small">{{
                item.UIRenderOptions && item.UIRenderOptions.description
                  ? `(${item.UIRenderOptions.description})`
                  : ''
              }}</span>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action class="my-0">
            <v-icon @click="$eventHub.$emit('set-edit-notification-key', item.key)"
              >mdi-pencil</v-icon
            >
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'EmailNotificationsList',
  props: {
    entitySettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      orgAppointmentsAreEnabled: null,
      localSettings: {
        mutedAppointmentNotifications: []
      },
      enabledAppointmentNotifications: []
    };
  },
  methods: {
    handleMuteNotificationClick() {
      this.$emit('settingsUpdated', this.localSettings);
    }
  },
  mounted() {
    this.localSettings = this.novaCore.deepClone(this.entitySettings);
    // NOTE: The UI is designed to show notifications that are ENABLED
    // But in our system we maintain a list of DISABLED/MUTED appointments
    // So here we reverse the logic
    this.enabledAppointmentNotifications = Object.keys(this.novaCore.Notifications).filter(
      key => !this.localSettings.mutedAppointmentNotifications?.includes(key)
    );
  },
  watch: {
    enabledAppointmentNotifications() {
      // Reversing enabled / muted logic as described in the mounted function
      this.localSettings.mutedAppointmentNotifications = Object.keys(
        this.novaCore.Notifications
      ).filter(key => !this.enabledAppointmentNotifications.includes(key));
    }
  }
};
</script>

<style scoped lang="scss">
#email-notifications-list .v-list-item:nth-child(even) {
  background: #e8e8e8;
}
</style>
