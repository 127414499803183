<template>
  <div class="d-flex flex-row align-center mb-4 full-width">
    <h3 v-if="label" class="text--disabled mr-4">{{ label }}:</h3>
    <v-autocomplete
      @input="updateValue"
      prepend-icon="mdi-domain"
      :items="orgs"
      item-text="name"
      dense
      item-value="id"
      placeholder="Select an Org">
    </v-autocomplete>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
/**
 * Org Select
 * @displayName Org Select
 */
export default {
  mixins: [selectMixin],
  data() {
    return {
      orgs: []
    };
  },
  methods: {
    updateValue(org) {
      this.$emit('input', org);
    }
  },
  async mounted() {
    if (this.novaCore.isInternalUser(this.$me)) {
      this.orgs = await this.$store.dispatch('Orgs/getOrgs', { sortBy: ['name'] });
    }
  }
};
</script>
