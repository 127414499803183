/**
 * Calendar View Enum used for calendar view options
 * @type {Readonly<{MONTH: string, WEEK: string, DAY: string}>}
 */
const SchedulingStatusEnum = Object.freeze({
  CLOSED: 'closed',
  SCHEDULE: 'schedule',
  ALWAYSOPEN: 'alwaysOpen'
});

export default SchedulingStatusEnum;
