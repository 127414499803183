<template>
  <form-base :header="header" :is-loading="loading">
    <template #form>
      <v-form ref="form" class="pt-0">
        <v-container>
          <v-row class="pt-0">
            <v-col>
              <text-field
                v-if="editMode"
                :required="true"
                v-model="fields.firstName"
                :rules="$validator.rules.required('First Name')"
                label="First Name"></text-field>
              <div class="v-text-field pt-0" v-else>
                <v-label><strong>First Name</strong></v-label>
                <p class="mb-5">
                  <v-label>
                    {{ $me.firstName }}
                  </v-label>
                </p>
              </div>
            </v-col>
            <v-col>
              <text-field
                v-if="editMode"
                :required="true"
                v-model="fields.lastName"
                :rules="$validator.rules.required('Last Name')"
                label="Last Name"></text-field>
              <div class="v-text-field pt-0" v-else>
                <v-label><strong>Last Name</strong></v-label>
                <p class="mb-5">
                  <v-label>
                    {{ $me.lastName }}
                  </v-label>
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row class="pt-0">
            <v-col>
              <phone-number-field
                v-if="editMode"
                margin-top="-7px"
                v-model="fields.phone"
                :validator="$validator"
                label="Phone"></phone-number-field>
              <div class="v-text-field pt-0" v-else>
                <v-label><strong>Phone</strong></v-label>
                <p class="mb-5">
                  <v-label>
                    {{ $me.phone ? novaCore.formatPhoneNumber($me.phone) : '-' }}
                  </v-label>
                </p>
              </div>
            </v-col>
          </v-row>

          <v-row class="pt-0" v-if="!editMode">
            <v-col>
              <div class="v-text-field pt-0">
                <v-label><strong>Email</strong></v-label>
                <p class="mb-5">
                  <v-label>
                    {{ $me.email }}
                  </v-label>
                </p>
              </div>
            </v-col>
            <v-col>
              <div class="v-text-field pt-0">
                <v-label><strong>My Role</strong></v-label>
                <p class="mb-5">
                  <v-label>
                    {{ novaCore.userRoleToText($me.role) }}
                  </v-label>
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isWarehouseListVisible && !editMode" class="mt-2">
            <v-col>
              <div class="v-text-field pt-0">
                <v-label><strong>My Warehouse list</strong></v-label>
                <p class="mt-3">
                  <v-chip
                    class="mr-2 mb-2"
                    small
                    v-for="warehouse in warehouses"
                    :key="warehouse.id">
                    {{ warehouse.name }}
                  </v-chip>
                </p>
              </div>
            </v-col>
          </v-row>
          <v-spacer class="my-2"></v-spacer>
        </v-container>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="cancel"
        :hide-cancel="editMode"
        @confirm="submit"
        :hide-confirm="!editMode"
        confirm-icon="check"
        confirm-label="Save">
        <template slot="additional-actions">
          <v-btn
            @click="editMode = false"
            :color="editMode ? 'default' : 'secondary'"
            v-if="editMode">
            <v-icon small class="mr-2">mdi-pencil-off</v-icon>
            Cancel edit
          </v-btn>

          <template v-else>
            <v-btn @click="editMode = true" color="secondary">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
              Edit
            </v-btn>
            <change-password-dialog>
              <template v-slot:activator="slotProps">
                <v-btn class="ml-3" v-bind="slotProps.attrs" v-on="slotProps.on">
                  <v-icon small class="mr-2">mdi-key</v-icon>
                  Change password
                </v-btn>
              </template>
            </change-password-dialog>
          </template>
        </template>
      </action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * My Account Form
 * @displayName My Account Form
 */
export default {
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      fields: {
        email: '',
        phone: '',
        firstName: '',
        lastName: ''
      },
      editMode: false,
      loading: false,
      warehouses: []
    };
  },
  computed: {
    isUserWarehouseRestricted() {
      return this.novaCore.isUserWarehouseRestricted(this.$me);
    },
    isWarehouseListVisible() {
      return this.isUserWarehouseRestricted && this.warehouses.length > 0;
    }
  },
  methods: {
    /**
     * Submit the form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const newFields = this.novaCore.deepClone(this.fields);
        newFields.phone = this.parsePhoneNumber(newFields.phone);

        try {
          const res = await axios.patch(`user/${this.$me.id}`, newFields);
          if (res?.data?.data) {
            await this.$store.dispatch('Auth/getMe');
            this.$emit('saved', res.data.data);
          }
        } catch (e) {
          this.notify('Failed to update User data', 'error');
        } finally {
          this.$emit('close');
        }

        this.loading = false;
      }
    },
    parsePhoneNumber(phone) {
      return phone?.replace(/(\()|(\))|( )|(-)/g, '').trim() || null;
    },
    cancel() {
      /**
       * Emits the close event
       * @event close
       */
      this.$emit('close');
    },
    async fetchWarehouseNames() {
      const params = {
        s: {
          id: {
            $in: this.$me.warehouseAccessList
          }
        }
      };
      if (this.isUserWarehouseRestricted) {
        const warehouses = await this.services.warehouse.getWarehouses(params, {
          fields: ['name']
        });

        if (warehouses?.length > 0) {
          this.warehouses = warehouses;
        }
      }
    }
  },
  async mounted() {
    this.fields = {
      firstName: this.$me.firstName,
      lastName: this.$me.lastName,
      phone: this.$me.phone
    };

    await this.fetchWarehouseNames();
  }
};
</script>
