var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("load-type-list", {
    attrs: {
      "read-only": _vm.$isLoadTypeReadOnly(
        _vm.warehouse.id,
        _vm.warehouse.orgId
      ),
      warehouse: _vm.warehouse,
      "should-display-warehouses": false,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }