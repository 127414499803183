var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "py-3",
      class: {
        disabled: !_vm.$rolePermissions.canCreateLoadType,
        "px-5": !_vm.$rolePermissions.canCreateLoadType,
      },
    },
    [
      !_vm.$rolePermissions.canCreateLoadType
        ? _c(
            "div",
            { staticClass: "mb-6" },
            [
              _c("caution-message", [
                _vm._v(" These Load Types are "),
                _c("strong", [_vm._v("shared")]),
                _vm._v(" across all "),
                _c("strong", [_vm._v("Warehouses")]),
                _vm._v(", proceed carefully. "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("load-type-list", {
        attrs: {
          "read-only": _vm.novaCore.isUserWarehouseRestricted(_vm.$me),
          "should-display-warehouses": false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }