var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    { class: { "is-disabled": _vm.isDisabled } },
    [
      _c(
        "v-expansion-panel-header",
        {
          staticClass: "d-flex",
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2 icon", attrs: { small: "" } },
                    [_vm._v("mdi-chevron-down")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("span", { staticClass: "header-content" }, [_vm._t("title")], 2),
          _c("v-spacer", { staticClass: "spacer" }),
          _c(
            "div",
            { staticClass: "header-right" },
            [
              _vm.isLocked
                ? _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-lock"),
                  ])
                : [_vm._t("header-right")],
              _vm.$slots.disclaimer
                ? _c(
                    "div",
                    { staticClass: "disclaimer" },
                    [_vm._t("disclaimer")],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("v-expansion-panel-content", [_vm._t("content")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }