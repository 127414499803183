var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { class: { disabled: _vm.readOnly } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "search-field", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6" } },
                    [
                      _c("text-field", {
                        staticClass: "mb-5",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Search",
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.searchStr,
                          callback: function ($$v) {
                            _vm.searchStr = $$v
                          },
                          expression: "searchStr",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c("field-form-dialog", {
                attrs: {
                  "read-only": _vm.readOnly,
                  warehouse: _vm.warehouse,
                  fieldTypeOptions: _vm.fieldTypeOptions,
                },
                on: { save: _vm.createField },
              }),
              _vm.showCloneButton && _vm.warehouse
                ? _c("clone-custom-fields-dialog", {
                    attrs: {
                      "read-only": _vm.readOnly,
                      "target-warehouse": _vm.warehouse,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                class: { disabled: _vm.readOnly },
                attrs: {
                  "item-key": "name",
                  "disable-sort": "",
                  "disable-pagination": "",
                  "hide-default-footer": "",
                  headers: _vm.rowHeaders,
                  id: "custom-fields-table",
                  items: _vm.customFieldsList,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.$rolePermissions.canUpdateWarehouse
                      ? {
                          key: "item.sort",
                          fn: function ({}) {
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "handle",
                                  attrs: { color: "black" },
                                },
                                [_vm._v("mdi-arrow-split-horizontal")]
                              ),
                            ]
                          },
                        }
                      : null,
                    {
                      key: "header.sort",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "span",
                                { staticClass: "d-inline-block mr-1" },
                                [_vm._v("Sort")]
                              ),
                              _c("help-icon-tooltip", [
                                _vm._v(
                                  " The order of how this data displays across the platform. The top two will display on the appointment tile in the appointments grid. "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "item.type",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.novaCore.customFieldLabels[item.type]
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.required",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.getRequiredValues(item)) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.hiddenFromCarrier",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(item.hiddenFromCarrier ? "Yes" : "No") +
                              " "
                          ),
                        ]
                      },
                    },
                    _vm.$rolePermissions.canUpdateWarehouse
                      ? {
                          key: "item.actions",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "row-actions" },
                                [
                                  _c("field-form-dialog", {
                                    attrs: {
                                      "read-only": _vm.readOnly,
                                      field: item,
                                      warehouse: _vm.warehouse,
                                      fieldTypeOptions: _vm.fieldTypeOptions,
                                    },
                                    on: { save: _vm.updateField },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (slotProps) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        disabled: _vm.readOnly,
                                                        id: item.id,
                                                        small: "",
                                                        color: "secondary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setEditingIndex(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "v-icon",
                                                    slotProps.attrs,
                                                    false
                                                  ),
                                                  slotProps.on
                                                ),
                                                [_vm._v(" mdi-pencil ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("icon-tooltip-button", {
                                    attrs: {
                                      size: "large",
                                      tooltip: _vm.readOnly
                                        ? _vm.novaCore.getRoleActionError()
                                        : "Delete Field",
                                      disabled: _vm.readOnly,
                                      delete: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteField(item)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }