var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "pl-0",
              attrs: { flat: "", dense: "", id: "duration-toolbar" },
            },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "d-flex justify-center align-center" },
                [
                  _c("span", { staticClass: "subheading" }, [
                    _vm._v("Duration"),
                  ]),
                  _c(
                    "help-icon-tooltip",
                    { attrs: { iconStyleClass: "grey darken-2" } },
                    [
                      _vm._v(
                        " The duration of the load type is used when scheduling a new appointment. If this load type's duration is changed, no existing appointments will be updated with the new duration. "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c("v-switch", {
                attrs: {
                  color: "secondary",
                  "hide-details": "",
                  label: "Enter Manually",
                },
                model: {
                  value: _vm.manualDurationEntry,
                  callback: function ($$v) {
                    _vm.manualDurationEntry = $$v
                  },
                  expression: "manualDurationEntry",
                },
              }),
            ],
            1
          ),
          !_vm.manualDurationEntry
            ? _c("v-slider", {
                staticClass: "ma-0",
                attrs: {
                  "tick-labels": _vm.loadTypeDurationOptions,
                  "track-color": "secondary",
                  color: "accent",
                  min: _vm.sliderLimits.min,
                  max: _vm.sliderLimits.max,
                  step: "15",
                  ticks: "",
                  "hide-details": "",
                  dense: "",
                  height: "20px",
                },
                on: { input: (value) => _vm.$emit("input", value) },
                model: {
                  value: _vm.durationSlider,
                  callback: function ($$v) {
                    _vm.durationSlider = $$v
                  },
                  expression: "durationSlider",
                },
              })
            : _c("text-field", {
                attrs: {
                  dense: "",
                  height: "20px",
                  "hide-details": "",
                  outlined: "",
                  label: "Minutes",
                  step: "15",
                  type: "number",
                },
                on: { input: (value) => _vm.$emit("input", parseInt(value)) },
                model: {
                  value: _vm.durationManual,
                  callback: function ($$v) {
                    _vm.durationManual = $$v
                  },
                  expression: "durationManual",
                },
              }),
          !_vm.manualDurationEntry
            ? _c("span", {
                staticClass: "text-h5 font-weight-light",
                domProps: {
                  textContent: _vm._s(
                    _vm.novaCore.formatMinutesToHuman(_vm.value)
                  ),
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }