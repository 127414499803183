var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Carrier sign in",
          "max-width": "100%",
          value: _vm.showDialog,
          width: "650px",
        },
        on: { close: _vm.close },
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("v-container", { staticClass: "px-0" }, [
            _c("div", [_vm._v(_vm._s(_vm.email))]),
            _c("div", { staticClass: "mt-4 black--text" }, [
              _vm._v(
                " It seems like this email is registered as a carrier. To sign in, please proceed to the Opendock Scheduling Portal. "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "dialog-actions" },
        [
          _c("action-group", {
            attrs: { "hide-confirm": true },
            on: { cancel: _vm.close },
            scopedSlots: _vm._u([
              {
                key: "additional-actions-after",
                fn: function () {
                  return [
                    _c(
                      "a",
                      {
                        staticClass:
                          "primary-button v-btn v-size--default px-4 font-weight-bold black--text",
                        attrs: {
                          href: `${_vm.lunaUrl}/login`,
                          target: "_blank",
                          rel: "noopener",
                        },
                      },
                      [
                        _vm._v(" Go To Scheduling Portal "),
                        _c(
                          "v-icon",
                          {
                            staticClass: "black--text ml-2",
                            attrs: { small: "" },
                          },
                          [_vm._v("mdi-arrow-right")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }