<template>
  <div class="action-group">
    <div class="mr-1 d-flex align-center">
      <template v-for="(actionItem, actionName) in appointmentActions">
        <appointment-action-group-item
          v-if="!actionItem.isShowMoreOption"
          :key="`${renderKey}-${actionName}`"
          :disabled="actionItem.disabled"
          :disabled-message="actionItem.disabledMessage"
          :action-item="actionItem"
          :action-name="actionName"
          @click="handleClick"></appointment-action-group-item>
      </template>
      <v-menu
        class="action-menu"
        offset-y
        :internal-activator="true"
        :close-on-click="true"
        v-if="isShowMoreMenuVisible">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            text
            :ripple="false"
            v-bind="attrs"
            v-on="on"
            data-testid="appointmentShowMoreMenu">
            <v-icon small>mdi-dots-vertical</v-icon>
            More
          </v-btn>
        </template>
        <v-list>
          <template v-for="(actionItem, actionName) in appointmentActions">
            <v-list-item
              v-if="actionItem.isShowMoreOption"
              class="account-option"
              :key="`action-${actionName}`">
              <appointment-action-group-item
                is-sub-item
                :disabled="actionItem.disabled"
                :disabled-message="actionItem.disabledMessage"
                :action-item="actionItem"
                :action-name="actionName"
                @click="handleClick"></appointment-action-group-item>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
    <share-appointment-dialog
      :external-activator="true"
      :show-dialog="showShareDialog"
      :appointment="appointment"
      @close="showShareDialog = false"></share-appointment-dialog>
    <create-recurring-series-dialog
      :external-activator="true"
      :show-dialog="showCreateRecurringSeriesDialog"
      :appointment="appointment"
      @close="showCreateRecurringSeriesDialog = false"></create-recurring-series-dialog>
    <delete-recurring-series-dialog
      :parent-appointment="recurringParentAppointment"
      :external-activator="true"
      :show-dialog="showDeleteRecurringSeriesDialog"
      :appointment="appointment"
      @close="showDeleteRecurringSeriesDialog = false"></delete-recurring-series-dialog>
    <reschedule-appointment-dialog
      :appointment-warehouse="appointmentWarehouse"
      :external-activator="true"
      :show-dialog="showRescheduleDialog"
      :appointment="appointment"
      @close="showRescheduleDialog = false"
      @appointment-updated="$emit('appointment-updated')"></reschedule-appointment-dialog>
    <edit-appointment-dialog
      :external-activator="true"
      :show-dialog="showEditDialog"
      :appointment="appointment"
      @appointment-updated="$emit('appointment-updated')"
      @close="showEditDialog = false"></edit-appointment-dialog>
    <cancel-appointment-dialog
      ref="cancelAppointmentDialog"
      @confirm="$emit('confirm')"
      :external-activator="true"
      :show-dialog="showConfirmDialog"
      :appointment="appointment"
      @close="showConfirmDialog = false"
      @appointment-updated="
        showConfirmDialog = false;
        $emit('appointment-updated');
      "></cancel-appointment-dialog>
    <create-appointment-dialog
      :external-activator="true"
      :show-dialog="showCreateAppointmentDialog"
      @close="showCreateAppointmentDialog = false"
      :context="appointment"></create-appointment-dialog>
    <edit-reserve-dialog
      external-activator
      :reserve="appointment"
      @close="showEditReserveDialog = false"
      :show-dialog="showEditReserveDialog"></edit-reserve-dialog>
    <printable-appointment-details
      ref="printableVisibleAppointmentDetails"
      :settings="settings"
      :appointment="appointment"
      :custom-form-data="customFormData"
      v-if="!novaCore.isReserve(appointment)"></printable-appointment-details>
  </div>
</template>

<script>
import AppointmentActionGroupItem from '@/modules/appointments/components/AppointmentActionGroupItem';
import { isObject } from 'class-validator';
import { computed, nextTick, ref, watch } from 'vue';
import {
  useConfirmation,
  useDialogBase,
  useMixpanel,
  useNovaCore,
  useOrg,
  useRenderKey
} from '@/composables';
import { useRecurringAppointmentProps } from '@/composables/useRecurringAppointment';
import customFormsService from '@satellite/services/custom-forms-service';

/**
 * @displayName Appointment Action Group
 */
export default {
  components: { AppointmentActionGroupItem },
  props: {
    ...useRecurringAppointmentProps,
    appointment: {
      type: Object,
      required: true
    },
    /**
     * Appointment actions to show
     * {icon: 'printer', action: 'open-dialog', show: true}
     */
    appointmentActions: {
      type: Object,
      required: true
    },
    appointmentWarehouse: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  emits: ['update:create-appointment-dialog'],
  setup(props, context) {
    const { orgRef } = useOrg();
    const mixpanel = useMixpanel();
    const novaCore = useNovaCore();
    const confirmation = useConfirmation();
    const { renderKey } = useRenderKey();
    const printing = ref(false);
    const showShareDialog = ref(false);
    const showRescheduleDialog = ref(false);
    const showEditDialog = ref(false);
    const showCreateAppointmentDialog = ref(false);
    const showCreateRecurringSeriesDialog = ref(false);
    const showDeleteRecurringSeriesDialog = ref(false);
    const showEditReserveDialog = ref(false);
    const customFormData = ref([]);
    const cancelAppointmentDialog = ref(null);
    const { showConfirmDialog } = useDialogBase();

    const isShowMoreMenuVisible = computed(() => {
      return (
        isObject(props.appointmentActions) &&
        Object.values(props.appointmentActions)?.some(a => a?.isShowMoreOption)
      );
    });

    const settings = computed(() => {
      return props.appointmentWarehouse.settings ?? {};
    });

    const openShareDialog = () => {
      showShareDialog.value = true;
    };

    const openCreateRecurringSeriesDialog = () => {
      showCreateRecurringSeriesDialog.value = true;
    };

    const openDeleteRecurringSeriesDialog = () => {
      showDeleteRecurringSeriesDialog.value = true;
    };

    const openCancelDialog = async () => {
      showConfirmDialog.value = true;
      cancelAppointmentDialog.value.openConfirmation();
    };

    const openCreateAppointmentDialogForClone = () => {
      mixpanel.track(mixpanel.events.ACTION.OPENED_CLONE_APPOINTMENT, {
        'Org Name': orgRef.value.name,
        'Org ID': orgRef.value.id,
        'Appointment ID': props.appointment.id
      });
      showCreateAppointmentDialog.value = true;
    };

    const openRescheduleDialog = () => {
      showRescheduleDialog.value = true;
    };

    const openEditDialog = () => {
      showEditDialog.value = true;
    };

    const openEditReserveDialog = () => {
      showEditReserveDialog.value = true;
    };

    const setCustomFormData = async () => {
      if (!props.appointment) {
        return;
      }

      customFormData.value =
        (await customFormsService.getCustomFormData({
          s: {
            objectId: props.appointment.id,
            entityName: 'appointment'
          },
          limit: 1000
        })) ?? [];
    };

    const printableVisibleAppointmentDetails = ref(null);

    const openPrintTab = async () => {
      await nextTick();
      await setCustomFormData();

      let domClone = printableVisibleAppointmentDetails.value.$el.cloneNode(true);

      let $printContent = document.getElementById('print-content');
      if (!$printContent) {
        $printContent = document.createElement('section');
        $printContent.id = 'print-content';
        document.body.appendChild($printContent);
      }

      $printContent.innerHTML = '';
      $printContent.appendChild(domClone);
      window.print();
    };

    const handleClick = action => {
      const methods = {
        openShareDialog,
        openCreateRecurringSeriesDialog,
        openDeleteRecurringSeriesDialog,
        openCancelDialog,
        openCreateAppointmentDialogForClone,
        openRescheduleDialog,
        openEditDialog,
        openEditReserveDialog,
        openPrintTab,
        muteNotifications
      };

      if (typeof methods[action] === 'function') {
        methods[action]();
      } else {
        console.error(`Action ${action} is not a function`);
      }
    };

    const muteNotifications = async () => {
      const confirmAction = props.appointment.muteNotifications ? 'ENABLE' : 'DISABLE';

      const confirmMessage = `Are you sure you want to ${confirmAction} future notifications for this appointment?`;

      const isConfirmed = await confirmation(confirmMessage, { color: 'warning' });
      if (isConfirmed) {
        const response = await window.axios.patch(`appointment/${props.appointment.id}`, {
          muteNotifications: !props.appointment.muteNotifications
        });

        if (response?.data) {
          props.appointment.muteNotifications = !props.appointment.muteNotifications;
        }
      }
    };

    watch(showCreateAppointmentDialog, newVal => {
      context.emit('update:create-appointment-dialog', newVal);
    });

    return {
      printing,
      showShareDialog,
      showRescheduleDialog,
      showEditDialog,
      showCreateAppointmentDialog,
      showCreateRecurringSeriesDialog,
      showDeleteRecurringSeriesDialog,
      showEditReserveDialog,
      renderKey,
      customFormData,
      isShowMoreMenuVisible,
      settings,
      openShareDialog,
      openCreateRecurringSeriesDialog,
      openDeleteRecurringSeriesDialog,
      openCancelDialog,
      openCreateAppointmentDialogForClone,
      openRescheduleDialog,
      openEditDialog,
      openEditReserveDialog,
      setCustomFormData,
      openPrintTab,
      handleClick,
      muteNotifications,
      showConfirmDialog,
      cancelAppointmentDialog,
      novaCore,
      printableVisibleAppointmentDetails
    };
  }
};
</script>

<style scoped lang="scss">
.action-group {
  line-height: 20px;
  display: flex;
  align-items: center;
}

::v-deep .v-list-item:has(.sub-item):not(:first-child) .sub-item {
  margin-top: 6px;
}
</style>
