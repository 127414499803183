import moment from 'moment';

export default {
  props: {
    /**
     * Warehouse entity
     */
    selectedWarehouse: {
      required: false,
      type: Array | Object
    },
    dateRange: {
      type: Array,
      required: false,
      default: () => []
    },
    showAllTime: {
      type: Boolean,
      required: false,
      default: false
    },
    consolidateCapacityDocks: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      rawData: null,
      ready: false,
      chartData: [],
      queryParams: {},
      currentRequests: [],
      dataKey: null,
      apiEndpoint: null,
      initialLookbackDays: 6,
      useCurrentWeek: false,
      includeVolumeInUri: true,
      waitForRangeToFetch: true,
      colors: [
        '#2F8CF7', // Blue
        '#FF9340', // Orange
        '#4B3A92', // Deep Purple
        '#FADA28', // Yellow
        '#CA80DC', // Light Purple
        '#78DEC9', // Teal
        '#CF3648', // Red
        '#AAD422', // Lime
        '#8720BA', // Purple
        '#00C4F0', // Cyan
        '#5C6BC0', // Indigo
        '#F097B5', // Pink
        '#19784A', // Deep Green
        '#8C2929', // Deep Red
        '#8ACEFF', // Light Blue
        '#F76425', // Deep Orange
        '#2CB843', // Green
        '#584341', // Brown
        '#5D7D8C', // Blue Grey
        '#9E9E9E' // Grey
      ]
    };
  },
  watch: {
    rawData: {
      handler() {
        this.formatData ? this.formatData() : this.makeChartData();
      },
      deep: true
    },
    async selectedWarehouse() {
      await this.getData();
    },
    async dateRange() {
      await this.makeDateRangeVariables();
    },
    async showAllTime() {
      await this.makeDateRangeVariables();
    }
  },
  methods: {
    /**
     * Retrieve metrics data current endpoint
     * @public
     */
    async getData() {
      const url = `/metrics${this.includeVolumeInUri ? '/appointment-volume' : ''}/${
        this.apiEndpoint
      }`;
      const semaphoreKey = encodeURI(`${url}-${JSON.stringify(this.getQueryParams())}`);

      if (this.currentRequests.includes(semaphoreKey)) {
        return;
      }
      this.currentRequests.push(semaphoreKey);

      axios
        .get(url, {
          params: this.getQueryParams()
        })
        .then(response => {
          if (response?.data) {
            this.rawData = this.dataKey ? response.data?.data[this.dataKey] : response.data?.data;
          }
        })
        .finally(
          () => (this.currentRequests = this.currentRequests.filter(r => r !== semaphoreKey))
        );
    },
    /**
     * Format API data for chart consumption
     * @public
     * @param data
     * @returns {object[]}
     */
    makeChartData(data = null) {
      this.chartData = [];
      data = data || this.rawData;

      for (let dataKey in data) {
        this.chartData.push({
          x: this.formatXAxis(dataKey),
          y: data[dataKey]
        });
      }

      if (!this.formatData) {
        this.ready = true;
      }

      return this.chartData;
    },
    /**
     * @param data
     * @returns {*}
     */
    formatXAxis(data) {
      return data;
    },
    /**
     * @param data
     * @returns {*}
     */
    formatYAxis(data) {
      return data;
    },
    /**
     * Get params for API request
     * @public
     * @returns {object}
     */
    getQueryParams() {
      this.queryParams.warehouseId = this.selectedWarehouse?.id || null;

      return this.queryParams;
    },
    /**
     * @public
     * @returns {{fromDate: *, toDate: *}|{fromDate: string, toDate: string}}
     */
    async makeDateRangeVariables() {
      const range = !this.showAllTime
        ? {
            fromDate: this.dateRange[0],
            toDate: this.dateRange[1]
          }
        : {
            fromDate: moment(this.$org.createDateTime).format(
              this.novaCore.DateTimeFormats.DateDashed
            ),
            toDate: moment().add(6, 'months').format(this.novaCore.DateTimeFormats.DateDashed)
          };

      this.queryParams.toDate = range.toDate;
      this.queryParams.fromDate = range.fromDate;
      if (this.selectedWarehouse) {
        await this.getData();
      }
      return range;
    }
  },
  async mounted() {
    if (this.dateRange?.length) {
      await this.makeDateRangeVariables();
    }
  }
};
