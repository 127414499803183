<template>
  <div>
    <v-switch
      class="disable-label-click"
      v-bind="[$attrs, $props]"
      :input-value="value"
      @change="emitChange"
      color="secondary"
      hide-details="auto"
      dense>
      <template v-slot:label>
        <div :class="{ 'text--disabled': !value }">
          <div class="d-flex justify-center align-center pl-1">
            <span class="d-block mr-2">{{ entity }} available on the Scheduling Portal</span>
            <help-icon-tooltip iconStyleClass="grey darken-2">
              {{ toggleMessage }}
            </help-icon-tooltip>
          </div>
        </div>
      </template>
    </v-switch>

    <p class="pt-2" v-if="shouldDisplayDisclaimer">
      This will make the {{ entity }} available on the Scheduling Portal where carriers,
      dispatchers, and others can create an account and book appointments.
      <template v-if="entity === novaCore.DatabaseEntities.Warehouse">
        You can share the warehouse's public link with anyone, or preview the booking experience
        yourself.
      </template>
    </p>
  </div>
</template>

<script>
import config from '@/config/config';

/**
 * Carrier Scheduling Toggle
 * @displayName Carrier Scheduling Toggle
 */
export default {
  name: 'CarrierSchedulingToggle',
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Name of entity flag is toggled on
     */
    entity: {
      type: String,
      required: true,
      validator(value) {
        // The value must match one of these strings
        return ['Warehouse', 'Dock', 'Load Type'].includes(value);
      }
    },
    shouldDisplayDisclaimer: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    carrierSchedulingRequestMail() {
      const emailAddress = 'support@opendock.com';
      const subject = `Nova Request - Import Org in ODC Admin - Org ${this.$org.name}`;
      const body = `Please import/enable this Org in ODC Admin (Opendock Scheduling Portal): ${this.$org.name} - ${this.$org.id}.  Environment: ${config.node_env}`;
      return encodeURI(`mailto:${emailAddress}?subject=${subject}&body=${body}`);
    },
    toggleMessage() {
      return {
        Warehouse: 'Enable carrier scheduling for this warehouse location',
        Dock: 'Enable carrier scheduling for this dock',
        'Load Type': 'Enable carrier scheduling for this load type across all locations'
      }[this.entity];
    }
  },
  methods: {
    /**
     * @model
     * @param value
     */
    emitChange(value) {
      this.$emit('input', value ?? false);
    },
    /**
     * Adds event listeners to prevent label from toggling switch
     */
    addEventListeners() {
      document.querySelectorAll('.disable-label-click').forEach(element => {
        element.querySelector('label').addEventListener('click', clickHandler);
      });

      // Using named function here allows the browser to discard exact duplicate event listeners automatically
      function clickHandler(e) {
        e.stopPropagation();
      }
    }
  },
  mounted() {
    this.addEventListeners();
  }
};
</script>
