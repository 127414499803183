import { getResponse } from '@satellite/services/serviceUtilities';
import { DateTime } from 'luxon';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async updateAssetVisit(assetVisitId, data, options = {}, requestConfig = {}) {
    const response = await axios.patch(`asset-visit/${assetVisitId}`, data, requestConfig);

    return getResponse(response, options);
  },
  async getLatestAssetVisitsByWarehouseId(warehouseId, params = {}, options = {}) {
    const baseParams = {
      s: {
        $and: [
          { warehouseId: warehouseId },
          {
            createDateTime: {
              $gte: DateTime.now().minus({ days: 1 }).toISO()
            }
          }
        ]
      },
      join: [
        'company||id,name,scac',
        'appointment||id,start,end,status,statusTimeline,dockId,userId,refNumber,confirmationNumber',
        'appointment.loadType||id,name,direction',
        'assetVisitEvents||id,createDateTime,eventType',
        'spotAssignments||id,spotId,createDateTime'
      ]
    };

    const response = await axios.get(`asset-visit`, { params: { ...baseParams, ...params } });
    return getResponse(response, options);
  }
};
