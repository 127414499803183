<template>
  <section v-if="assetVisit?.id">
    <v-card elevation="0" flat>
      <v-card-title class="d-flex flex-row border-bottom">
        <h4 data-testid="yard-asset-visit-chat-title">Arrival Details</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDetail" data-testid="yard-asset-visit-chat-close-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text data-testid="yard-asset-visit-chat-content">
        <v-row no-gutters>
          <v-col cols="4">
            <yard-view-asset-visit-detail-data
              :asset-visit="assetVisit"
              class="mt-4"
              :show-assign-spot-select="false" />
          </v-col>

          <v-col cols="8" class="d-flex d-flex-row full-chat-box pt-4">
            <drivers-chat-window
              :allow-phone-edit="false"
              compact-mode
              :warehouse="selectedWarehouse"
              :asset-visit="assetVisit" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </section>
  <div v-else>
    <p>Invalid asset visit</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import DriversChatWindow from '@/modules/appointments/components/drivers_chat/DriversChatWindow.vue';
import YardViewAssetVisitDetailData from '@/modules/yard/components/AssetVisit/Detail/YardViewAssetVisitDetailData.vue';

export default defineComponent({
  name: 'YardViewAssetVisitDetailChat',
  components: { DriversChatWindow, YardViewAssetVisitDetailData },
  props: {
    assetVisit: {
      type: Object,
      required: true
    }
  },
  setup() {
    const yardStore = useYard();

    const closeDetail = () => {
      yardStore.isAssetVisitChatVisibleRef.value = false;
    };

    return {
      closeDetail,
      selectedWarehouse: yardStore.selectedWarehouseRef,
      isAssetVisitChatVisible: yardStore.isAssetVisitChatVisibleRef
    };
  }
});
</script>
<style lang="scss" scoped>
.full-chat-box {
  min-height: 100%;
  border-left: 1px solid $color-line-border;
}
.border-bottom {
  border-bottom: 1px solid $color-line-border;
}
</style>
