<template>
  <div>
    <v-card v-if="!showDockForm && !showChildDockForm" :class="{ disabled: readOnly }">
      <v-card-title class="mb-5">
        <v-row align="center" class="search-field">
          <v-col md="6" class="mr-4">
            <text-field
              v-model="searchDockStr"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details></text-field>
          </v-col>
          <v-switch v-model="showInactiveDocks" inset label="Show inactive docks"></v-switch>
        </v-row>
        <v-spacer></v-spacer>
        <v-tooltip top :disabled="$rolePermissions.canCreateDock && !readOnly">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <primary-button
                :disabled="!$rolePermissions.canCreateDock || readOnly"
                before-icon="plus"
                @click="toggleDockForm()"
                large
                class="mr-2">
                Create Dock
              </primary-button>
            </div>
          </template>
          <span>{{ novaCore.getRoleActionError() }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :class="{ disabled: readOnly }"
          class="grouped-list-items"
          :headers="headers"
          id="dock-table"
          :items="parentDocks"
          :loading="$data.$globalLoading"
          :footer-props="footerProps"
          :sort-by.sync="sortBy"
          :custom-sort="sort"
          :sort-desc.sync="sortDesc"
          :expanded.sync="expanded"
          :options.sync="options">
          <template v-slot:header.sort>
            <div class="d-flex">
              <span class="d-inline-block mr-1">Sort</span>
              <help-icon-tooltip>
                The order of how this data displays across the platform.
              </help-icon-tooltip>
            </div>
          </template>

          <template v-slot:header.capacityNumber>
            <div class="d-flex">
              <span class="d-inline-block mr-1">Parallel Capacity</span>
              <help-icon-tooltip>
                Number of concurrent appointments that can be booked at this Dock at the same time
                interval.
                <br />
                E.g. if this number is 3, you can book up to 3 appointments from 9am to 10am
                (assuming the Dock's schedule is open at that time).
              </help-icon-tooltip>
            </div>
          </template>

          <template v-slot:item.name="{ item }">
            <div class="d-flex align-center">
              <template v-if="item.capacityChildren.length > 0">
                <div class="mr-2">
                  <icon-tooltip-button
                    size="24"
                    @click="toggleRowExpansion(item)"
                    tooltip="Show Capacity Docks"
                    :icon="
                      expanded.includes(item) ? 'chevron-up' : 'chevron-down'
                    "></icon-tooltip-button>
                </div>
              </template>

              <copy-content :disabled="!item.isActive" :content="item.name">
                {{ item.name }}
              </copy-content>
            </div>
          </template>

          <template v-slot:item.sort="{ item }" v-if="$rolePermissions.canUpdateWarehouse">
            <v-icon :disabled="readOnly" v-if="!loading" color="black" class="handle">
              mdi-arrow-split-horizontal
            </v-icon>
          </template>

          <template v-slot:item.doorNumber="{ item }">
            {{ item.doorNumber || '----' }}
          </template>

          <template v-slot:item.loadTypeIds="{ item }">
            <load-type-chip-list
              :read-only="readOnly || !item.isActive"
              :load-type-id-array="item.loadTypeIds"></load-type-chip-list>
          </template>

          <template v-slot:item.allowCarrierScheduling="{ item }">
            {{ !item.isActive ? '-' : !item.allowCarrierScheduling ? 'Yes' : 'No' }}
          </template>

          <template v-slot:item.capacityNumber="{ item }">
            <div class="d-flex align-center">
              <span class="self-stretch">{{ item.capacityNumber }}</span>
              <v-btn
                v-if="!readOnly"
                :disabled="readOnly || !item.isActive"
                text
                small
                class="secondary--text font-weight-bold ml-3"
                @click="createCapacityDock(item)"
                :loading="addingCapacity[item.id]">
                Add
              </v-btn>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="row-actions">
              <icon-tooltip-button
                v-if="!$rolePermissions.canCreateDock && !isCapacityChild(item) && item.isActive"
                :disabled="$rolePermissions.canCreateDock || isCapacityChild(item) || readOnly"
                icon-class="mr-2"
                size="large"
                @click="toggleDockForm(item)"
                :tooltip="readOnly ? novaCore.getRoleActionError('view this dock') : 'View Dock'"
                icon="eye"></icon-tooltip-button>

              <icon-tooltip-button
                v-if="$rolePermissions.canCreateDock && !isCapacityChild(item) && item.isActive"
                :disabled="!$rolePermissions.canCreateDock || isCapacityChild(item) || readOnly"
                :id="item.id"
                size="large"
                icon-class="mr-2"
                color="secondary"
                @click="toggleDockForm(item)"
                :tooltip="readOnly ? novaCore.getRoleActionError('edit this dock') : 'Edit Dock'"
                icon="pencil"></icon-tooltip-button>

              <audit-log-entity-icon-button
                :item="item"
                :timezone="warehouse.timezone"
                :entity-type="novaCore.DatabaseEntities.Dock"></audit-log-entity-icon-button>

              <create-appointment-dialog
                v-if="item.isActive"
                :read-only="!$rolePermissions.canCreateDock || readOnly"
                :context="{ selectedWarehouse: warehouse, selectedDock: item }">
                <template v-slot:activator="slotProps">
                  <icon-tooltip-button
                    :disabled="readOnly"
                    :tooltip="
                      readOnly
                        ? novaCore.getRoleActionError('schedule an appointment at this dock')
                        : 'Schedule an appointment at this dock'
                    "
                    size="large"
                    icon-class="mr-2"
                    v-bind="slotProps.attrs"
                    v-on="slotProps.on"
                    color="primary"
                    icon="calendar"></icon-tooltip-button>
                </template>
              </create-appointment-dialog>
              <clone-dock-dialog
                v-if="item.isActive"
                :dock-id="item.id"
                :dock-name="item.name"
                :clone-sort-order="nextItemSortOrder"
                @keydown.native.enter.stop
                @saved="getData">
                <template #activator="{ on, attrs }">
                  <icon-tooltip-button
                    :disabled="!$rolePermissions.canCreateDock || isCapacityChild(item) || readOnly"
                    tooltip="Clone Dock"
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                    icon="mdi-content-copy"
                    icon-class="mr-2"
                    @saved="handleDockSave"
                    size="large"></icon-tooltip-button>
                </template>
              </clone-dock-dialog>

              <v-menu
                offset-y
                :ref="`contextMenu-${item.id}`"
                v-if="$rolePermissions.canDeleteDock">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" size="24" class="ml-2">
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list dense>
                  <v-list-item
                    class="px-4"
                    @click="showDeleteDockConfirmation(item)"
                    v-if="novaCore.isNormalDock(item)"
                    :disabled="
                      !$rolePermissions.canCreateDock || isCapacityChild(item) || readOnly
                    ">
                    <v-list-item-icon class="mr-4">
                      <v-icon color="red" size="20px">mdi-delete-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="red--text text--lighten-1">
                      Delete dock
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider class="my-2" v-if="novaCore.isNormalDock(item)"></v-divider>

                  <v-subheader class="px-4 d-flex flex-column text-left align-start">
                    <div><strong>DOCK STATUS</strong></div>
                    <div>(Toggle off to deactivate)</div>
                  </v-subheader>

                  <v-list-item class="px-4" @click="toggleDockActiveStatus(item)">
                    <v-list-item-icon class="mr-4">
                      <v-icon :color="item.isActive ? 'orange' : 'gray'" size="20px">
                        {{ item.isActive ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off-outline' }}
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>
                      {{ item.isActive ? 'Active' : 'Inactive' }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }" v-if="headers && headers.length">
            <td :colspan="headers.length" class="child-capacity-dock">
              <v-data-table
                class="nested-table"
                :headers="capacityDockHeaders"
                :items="novaCore.sortCapacityChildren(item).filter(dock => dock.id !== item.id)"
                disable-pagination
                hide-default-header
                hide-default-footer>
                <template v-slot:item.name="{ item }">
                  <div class="d-flex align-center">
                    <span
                      class="d-flex align-center"
                      :class="{
                        'text--dark-grey': getAppointmentCount(item.id) === 0,
                        'black--text': getAppointmentCount(item.id) > 0
                      }">
                      <v-icon small class="mr-2">mdi-file-tree</v-icon>
                      <strong v-if="getAppointmentCount(item.id) > 0" class="mr-1">
                        {{ getAppointmentCount(item.id) }}
                      </strong>
                      <span v-else class="mr-1">No</span>
                      <strong v-if="getAppointmentCount(item.id) > 1" class="mr-1">
                        appointments
                      </strong>
                      <span v-else class="mr-1">appointment</span>
                      using this Parallel Capacity
                    </span>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div
                    class="d-flex align-center justify-end"
                    v-if="$rolePermissions.canUpdateDock && !readOnly">
                    <icon-tooltip-button
                      class="mr-2 d-inline-block"
                      :id="item.id"
                      v-if="item.isActive && $rolePermissions.canCreateDock"
                      :disabled="!$rolePermissions.canDeleteDock || readOnly"
                      :tooltip="readOnly ? novaCore.getRoleActionError() : 'Edit Capacity Dock'"
                      @click="toggleChildDockForm(item)"
                      icon="mdi-pencil"
                      size="large"></icon-tooltip-button>
                    <v-btn
                      text
                      small
                      outlined
                      class="secondary--text d-inline-block"
                      :disabled="
                        !$rolePermissions.canCreateDock || !isCapacityChild(item) || readOnly
                      "
                      v-if="item.isActive && getAppointmentCount(item.id) > 0"
                      @click="showUnlinkCapacityDockDialog(item)">
                      <v-icon small>mdi-link-off</v-icon>
                      <span class="d-inline-block ml-2 text-capitalize">Unlink</span>
                    </v-btn>
                    <icon-tooltip-button
                      v-else-if="item.isActive"
                      color="error"
                      :id="item.id"
                      :disabled="
                        !$rolePermissions.canDeleteDock || !isCapacityChild(item) || readOnly
                      "
                      :tooltip="readOnly ? novaCore.getRoleActionError() : 'Delete Capacity Dock'"
                      @click="deleteCapacityDock(item)"
                      class="mr-2 d-inline-block"
                      icon="mdi-delete-outline"
                      size="large"></icon-tooltip-button>
                  </div>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card v-else>
      <v-card-title>
        <template v-if="showDockForm">
          {{ editingDock?.name ? 'Update Dock' : 'Create Dock' }}
        </template>
        <template v-else>Update Capacity Dock</template>

        <v-spacer></v-spacer>
        <v-icon v-if="showDockForm" @click="toggleDockForm()">mdi-close</v-icon>
        <v-icon v-else @click="toggleChildDockForm()">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <dock-form
          v-if="showDockForm"
          :dock="editingDock"
          :sort-order="nextItemSortOrder"
          @close="toggleDockForm"
          @saved="handleDockSave"
          :warehouse-id="warehouseId"></dock-form>
        <capacity-dock-form
          v-else
          :dock="editingDock"
          :sort-order="nextItemSortOrder"
          @close="toggleChildDockForm"
          @saved="handleChildDockSave"
          :warehouse-id="warehouseId"></capacity-dock-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showUnlinkDialog" width="500px">
      <v-card class="dialog-box">
        <dialog-header
          prepend-header-icon="mdi-alert"
          header="Unlink Parallel Capacity?"
          @close="closeUnlinkDialog"></dialog-header>
        <v-card-text class="px-8 pb-6 pt-2">
          <template v-if="dialogLoading">
            <v-progress-linear indeterminate height="6" class="mt-12"></v-progress-linear>
            <h4 class="text-center mt-4">Unlinking Capacity Dock ...</h4>
          </template>
          <div v-else>
            <p>
              Unlinking will create a new dock and move all existing parallel appointments over to
              it. This ensures that no appointments are lost or deleted.
            </p>
            <div class="d-flex align-center" v-if="dockToUnlink">
              <v-chip :ripple="false" color="dark-grey" label>
                <span class="black--text font-size-x-small">
                  <strong>{{ getAppointmentCount(dockToUnlink.id) }}</strong>
                  appointments
                </span>
              </v-chip>
              <v-icon small class="mx-2">mdi-arrow-right-thin</v-icon>
              <v-chip :ripple="false" color="dark-grey" label>
                <span class="black--text font-size-x-small">New dock</span>
              </v-chip>
            </div>
            <v-form ref="newUnlinkedDockName">
              <v-text-field
                v-model="newUnlinkedDockName"
                placeholder="New dock name"
                label="New dock name"
                required
                :rules="$validator.rules.required('New Dock Name')"></v-text-field>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions v-if="!dialogLoading">
          <action-group @cancel="closeUnlinkDialog" @confirm="updateCapacityDock"></action-group>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm
      :should-show="showDeleteConfirm"
      is-manual-mode
      persistent
      v-if="deletingDock"
      @result="handleDeleteResult"
      icon="mdi-delete-forever"
      delete-confirmation
      :show-delete-warning="!deleteMessageHtml"
      title="Delete this Dock?"
      :width="650"
      :loading="loading"
      :confirmation-input-text="deleteConfirmationInputText"
      :entity-name="deletingDock.name"
      button-true-text="YES, DELETE"
      button-true-color="error"
      color="error">
      <template v-slot:message>
        <v-skeleton-loader v-if="loading" type="list-item-three-line"></v-skeleton-loader>
        <div v-else-if="deleteMessageHtml">
          <h2 class="text-center my-3">Appointments will also be deleted!</h2>
          <v-alert text prominent border="left" color="red" class="my-8">
            <v-icon color="red" class="mr-2">mdi-alert</v-icon>
            <span class="text--primary" v-html="deleteMessageHtml"></span>
          </v-alert>
        </div>
      </template>
    </confirm>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import { isEqual } from 'lodash';

/**
 * Dock List
 * @displayName Dock List
 */
export default {
  mixins: [dataListMixin],
  props: {
    /**
     * Warehouse ID
     */
    warehouseId: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    parentDocks() {
      return this.docks
        .filter(dock => dock.isActive || this.showInactiveDocks)
        .filter(
          dock =>
            (!dock.capacityChildren?.length && !dock.capacityParentId) ||
            (dock.capacityChildren && dock.capacityParentId === dock.id)
        )
        .filter(dock => {
          // Search is made offline since the dock list
          // is loaded at once through the warehouse call,
          // no server side pagination.
          return this.searchDockStr?.length > 0
            ? this.headers
                .filter(h => h.searchable)
                .some(header => {
                  const dockField = dock[header.value]?.toLowerCase();
                  return dockField ? dockField.includes(this.searchDockStr.toLowerCase()) : false;
                })
            : true;
        })
        .map(parent => {
          parent.capacityChildren = this.novaCore.sortCapacityChildren(parent);
          return parent;
        });
    },
    appointmentCountsByDockId() {
      const counts = {};
      this.dockAppointmentCounts.forEach(item => {
        counts[item.dock_id] = item.count;
      });
      return counts;
    },
    nextItemSortOrder() {
      return this.docks.length;
    },
    headers() {
      const headers = [
        {
          text: 'Name',
          value: 'name',
          searchable: true,
          width: '16rem'
        },
        {
          text: 'Door #',
          value: 'doorNumber',
          searchable: true
        },
        {
          text: 'Load Types',
          value: 'loadTypeIds',
          searchable: false
        },
        {
          text: 'Hidden from Carriers?',
          value: 'allowCarrierScheduling',
          sortable: true
        },
        {
          text: 'Parallel Capacity',
          value: 'capacityNumber',
          sortable: false
        }
      ];
      if (!this.readOnly) {
        headers.unshift({
          text: 'Sort',
          sortable: false,
          value: 'sort'
        });
        headers.push({
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end',
          width: '7rem'
        });
      }
      return headers;
    }
  },
  data() {
    return {
      capacityDockHeaders: [
        {
          text: 'Appointments Count',
          value: 'name',
          searchable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end',
          width: '7rem'
        }
      ],
      docks: [],
      deletingDock: null,
      showDeleteConfirm: false,
      deleteMessageHtml: null,
      deleteConfirmationInputText: 'YES, I AM AWARE',
      loading: false,
      sortBy: 'sortOrder',
      searchFields: ['name'],
      warehouse: null,
      showDockForm: false,
      showChildDockForm: false,
      editingDock: null,
      expanded: [],
      dockAppointmentCounts: [],
      showUnlinkDialog: false,
      dockToUnlink: null,
      dialogLoading: false,
      newUnlinkedDockName: '',
      addingCapacity: {},
      searchDockStr: null,
      showInactiveDocks: false
    };
  },
  methods: {
    toggleRowExpansion(item) {
      if (this.expanded.find(i => i.id === item.id)) {
        const index = this.expanded.findIndex(i => i.id === item.id);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    sortDocks() {
      const filteredParents = (this.warehouse.docks || []).filter(dock =>
        this.novaCore.isRootDock(dock)
      );
      const alphabeticalParents = this.novaCore.naturalSort(filteredParents || [], false, 'name');
      const orderedParents = this.novaCore.naturalSort(
        alphabeticalParents || [],
        false,
        'sortOrder'
      );

      const docks = [];
      orderedParents.forEach(dock => {
        docks.push(dock);
        this.novaCore.sortCapacityChildren(dock).forEach(child => {
          if (this.novaCore.isCapacityChild(child)) {
            docks.push(child);
          }
        });
      });
      return docks;
    },

    /**
     * Get dock list data
     * @public
     * @returns {Promise<void>}
     */
    async getData() {
      this.warehouse = await this.$store.dispatch('Warehouses/getWarehouseById', this.warehouseId);

      if (this.warehouse) {
        await this.$store.dispatch('LoadTypes/getLoadTypes', {
          s: { orgId: this.warehouse.orgId }
        });

        if (this.warehouse.docks) {
          this.docks = this.sortDocks(this.warehouse.docks);

          // Inject the capacityNumber, for display-purposes only.
          for (const dock of this.docks) {
            if (dock) {
              dock.capacityNumber = this.getCapacityNumber(dock);
            }
          }
        }
      }
    },
    async showDeleteDockConfirmation(dock) {
      this.deletingDock = dock;
      this.showDeleteConfirm = true;
      this.deleteMessageHtml = await this.makeDeleteConfirmationText(dock);
    },
    async handleDeleteResult(confirmed) {
      if (confirmed) {
        this.loading = true;
        await this.$store.dispatch('Docks/deleteDock', this.deletingDock);

        await this.updateSortOrder(
          this.docks.map((dock, index) => {
            return {
              id: dock.id,
              sortOrder: index
            };
          })
        );
        this.notify('Dock has been deleted');
        await this.getData();

        this.loading = false;
      }
      this.deletingDock = null;
      this.deleteMessageHtml = null;
      this.showDeleteConfirm = false;
    },
    async makeDeleteConfirmationText(dock) {
      const appointmentCount =
        (await this.$store.dispatch('Docks/getAppointmentCountForDocks', [dock.id])) || 0;
      let msg = '';

      if (appointmentCount > 0) {
        msg = `<span>You are about to permanently delete:</span><br /><br />
                <p class='mb-1 ml-8'>
                  <strong>${appointmentCount} appointment${appointmentCount > 1 ? 's' : ''}</strong>
                </p>
               `;

        this.deleteConfirmationInputText = this.util.deleteConfirmationInputText(
          appointmentCount,
          'appointment'
        );
      } else {
        this.deleteConfirmationInputText = this.deletingDock.name;
      }
      return msg;
    },
    /**
     * Deactivate/Reactivate dock
     * @public
     * @param dock
     * @returns {Promise<void>}
     */
    async toggleDockActiveStatus(dock) {
      if (dock.isActive) {
        this.$confirm(this.makeDeactivationConfirmationText(dock), {
          width: 600,
          entityName: dock.name,
          color: 'warning',
          buttonTrueText: 'YES, DEACTIVATE',
          icon: null,
          title: 'Deactivate this Dock?'
        }).then(async confirmed => {
          if (confirmed) {
            await this.$store.dispatch('Docks/deactivateDock', dock);
            this.notify('Dock has been deactivated');
            await this.getData();
          }
        });
      } else {
        this.$confirm(this.makeReactivationConfirmationText(dock), {
          width: 600,
          entityName: dock.name,
          color: 'info',
          buttonTrueText: 'YES, ACTIVATE',
          buttonTrueColor: 'info',
          icon: null,
          title: 'Activate this Dock?'
        }).then(async confirmed => {
          if (confirmed) {
            await this.$store.dispatch('Docks/activateDock', dock);
            this.notify('Dock has been activated');
            await this.getData();
          }
        });
      }
    },
    makeDeactivationConfirmationText(dock) {
      return `
        <div>
          <p><strong>Dock name:</strong> ${dock.name}</p>
          <p><strong>Deactivating this dock will:</strong></p>
          <ul>
            <li>Keep intact all data from past and future appointments scheduled with this dock</li>
            <li>Automatically hide this dock from Carrier Scheduling</li>
            <li>Deactivate all parallel capacity associated with this dock</li>
          </ul>
          <div class='v-alert my-5 v-sheet theme--light v-alert--border v-alert--prominent v-alert--text v-alert--border-left warning--text'>
            <div class='v-alert__wrapper'>
              <div class='v-alert__content text--black'>
                <strong>Note:</strong> All inactive docks can be reactivated at any time.
              </div>
              <div class='v-alert__border v-alert__border--left'></div>
            </div>
          </div>
        </div>`;
    },
    makeReactivationConfirmationText(dock) {
      return `
        <div>
          <p><strong>Dock name:</strong> ${dock.name}</p>
          <p><strong>Activating this dock will:</strong></p>
          <ul>
            <li>Immediately allow new appointments to be scheduled with this dock</li>
            <li>Restore this dock's original setting for Carrier Scheduling</li>
            <li>Activate all parallel capacity associated with this dock</li>
          </ul>
        </div>`;
    },
    async toggleDockForm(dock = null) {
      if (this.showDockForm && this.$route.meta.isDirty && !(await this.checkDirtyConfirmation())) {
        return;
      }

      if (dock) {
        this.editingDock = dock;
      } else {
        this.editingDock = null;
      }
      this.showDockForm = !this.showDockForm;
    },
    async toggleChildDockForm(dock = null) {
      if (dock) {
        this.editingDock = dock;
      } else {
        this.editingDock = null;
      }

      if (
        this.showChildDockForm &&
        this.$route.meta.isDirty &&
        !(await this.checkDirtyConfirmation())
      ) {
        return;
      }
      this.showChildDockForm = !this.showChildDockForm;
    },
    handleDockSave() {
      this.toggleDockForm();
      this.getData();
    },
    handleChildDockSave() {
      this.toggleChildDockForm();
      this.getData();
    },
    async updateSortOrder(docks) {
      if (
        isEqual(
          this.docks?.map(d => ({ id: d.id, sortOrder: d.sortOrder })),
          docks?.map(d => ({ id: d.id, sortOrder: d.sortOrder }))
        )
      ) {
        return;
      }

      try {
        await this.services.dock.saveDockOrder(docks);
      } catch (_) {
        this.notify('Failed to save docks sort order - please try again later', 'error');
      } finally {
        this.loading = false;
        await this.getData();
      }
    },
    sort(items, sortBy, sortDesc) {
      return this.novaCore.naturalSort(items, sortDesc[0], sortBy[0]);
    },
    isCapacityChild(dock) {
      return this.novaCore.isCapacityChild(dock);
    },
    isCapacityParent(dock) {
      return this.novaCore.isCapacityParent(dock);
    },
    getCapacityNumber(dock) {
      return this.novaCore.getCapacityNumber(dock);
    },
    async getAppointmentCountsForDocks() {
      const response = await axios.get('/metrics/counts/appointment-count-for-docks', {
        params: {
          dockIds: this.docks.map(dock => dock.id)
        }
      });
      if (response?.data?.data) {
        this.dockAppointmentCounts = response.data.data;
      } else {
        this.dockAppointmentCounts = [];
      }
    },
    showUnlinkCapacityDockDialog(dock) {
      this.dockToUnlink = { ...dock };
      this.showUnlinkDialog = true;
      this.dialogLoading = false;
    },
    getParentDock(childDock) {
      return this.parentDocks.find(dock => dock.id === childDock.capacityParentId);
    },
    async createCapacityDock(dock) {
      const dockId = dock.id;
      this.$confirm(
        `You are about to add <strong>1 Parallel Capacity</strong> to <strong>${dock.name}</strong>.<br /><br />

        Once an appointment exists on this new Parallel Capacity, you will need to unlink it from <strong>${dock.name}</strong> before it can be deleted.`,
        {
          width: 475,
          entityName: dock.name,
          buttonTrueColor: 'secondary',
          buttonTrueText: 'YES, ADD',
          icon: 'mdi-plus',
          title: 'Add Parallel Capacity to this Dock?'
        }
      ).then(async confirmed => {
        if (confirmed) {
          this.addingCapacity[dockId] = true;
          try {
            const response = await this.services.dock.createCapacityDock(dockId);
            // saving order with new dock nested to its parent
            const parentIndex = this.docks.findIndex(el => el.id === dockId);
            const capacityDockIndex = parentIndex + this.docks[parentIndex].capacityNumber;
            const newDock = response;

            if (newDock) {
              newDock.capacityChildren = [];
              this.docks.splice(capacityDockIndex, 0, newDock);
              await this.updateSortOrder(
                this.docks.map((dock, index) => {
                  return {
                    id: dock.id,
                    sortOrder: index
                  };
                })
              );

              this.mixpanel.track(this.mixpanel.events.MODULE.DOCK.PARALLEL.ADDED, {
                'Org Name': this.$org.name,
                'Org ID': this.$org.id,
                'Warehouse ID': this.warehouse.id,
                'Warehouse Name': this.warehouse.name,
                'Parent Dock ID': this.docks[parentIndex].id,
                'Parent Dock Name': this.docks[parentIndex].name,
                'Child Dock ID': newDock.id
              });
            }
          } finally {
            this.addingCapacity[dockId] = false;
          }

          await this.getData();
        }
      });
    },
    async unlinkCapacityDock(dockToUnlink) {
      const parentDock = this.getParentDock(dockToUnlink);
      this.dialogLoading = true;
      const unlinkedDock = await this.services.dock.unlinkCapacityDock(
        dockToUnlink.id,
        parentDock.id
      );
      return {
        unlinkedDock: unlinkedDock,
        parentDock
      };
    },
    async updateCapacityDock() {
      if (this.$refs.newUnlinkedDockName.validate()) {
        try {
          const { unlinkedDock, parentDock } = await this.unlinkCapacityDock(this.dockToUnlink);
          if (unlinkedDock) {
            await axios.patch(`dock/${unlinkedDock.id}`, {
              id: unlinkedDock.id,
              name: this.newUnlinkedDockName
            });

            this.mixpanel.track(this.mixpanel.events.MODULE.DOCK.PARALLEL.UNLINKED, {
              'Org Name': this.$org.name,
              'Org ID': this.$org.id,
              'Warehouse ID': this.warehouse.id,
              'Warehouse Name': this.warehouse.name,
              'New Dock ID': unlinkedDock.id,
              'New Dock Name': this.newUnlinkedDockName
            });

            await this.getData();
            this.closeUnlinkDialog();
            this.removeFromExpanded(parentDock);
          }
        } finally {
          this.dialogLoading = false;
        }
      }
    },
    async deleteCapacityDock(childDock) {
      try {
        const { unlinkedDock, parentDock } = await this.unlinkCapacityDock(childDock);
        if (unlinkedDock) {
          await this.$store.dispatch('Docks/deleteDock', unlinkedDock);

          this.mixpanel.track(this.mixpanel.events.MODULE.DOCK.PARALLEL.DELETED, {
            'Org Name': this.$org.name,
            'Org ID': this.$org.id,
            'Warehouse ID': this.warehouse.id,
            'Warehouse Name': this.warehouse.name,
            'Parent Dock ID': parentDock.id,
            'Parent Dock Name': parentDock.name
          });

          this.removeFromExpanded(parentDock);
          await this.getData();
        }
      } finally {
        await this.updateSortOrder(
          this.docks.map((dock, index) => {
            return {
              id: dock.id,
              sortOrder: index
            };
          })
        );
        this.dialogLoading = true;
      }
    },
    removeFromExpanded(item) {
      if (item.capacityChildren.length - 1 <= 1) {
        this.toggleRowExpansion(item);
      }
    },
    getAppointmentCount(dockId) {
      return this.appointmentCountsByDockId?.[dockId] ?? 0;
    },
    closeUnlinkDialog() {
      this.dockToUnlink = null;
      this.showUnlinkDialog = false;
      this.newUnlinkedDockName = '';
    },
    /**
     * Init sortable plugin in the docks table
     * @public
     * @returns void
     */
    initSortable() {
      if (!this.readOnly) {
        let _this = this;
        let table = document.querySelector('#dock-table .v-data-table__wrapper tbody');
        if (!table) {
          return;
        }

        Sortable.create(table, {
          handle: '.handle', // Use handle so user can select text
          animation: 150,
          ghostClass: 'blue-background-class',
          onEnd({ oldIndex, newIndex }) {
            _this.loading = true;
            _this.docks = _this.novaCore.rearrangeDocksSortOrder(
              _this.docks,
              _this.parentDocks,
              oldIndex,
              newIndex
            );
            _this
              .updateSortOrder(
                _this.docks.map((dock, index) => {
                  return {
                    id: dock.id,
                    sortOrder: index
                  };
                })
              )
              .finally(() => {
                _this.notify('Docks order saved');
              });
          }
        });
      }
    }
  },
  mounted() {
    if (this.$route.params.shouldShowCreateDockForm) {
      this.toggleDockForm();
    }
    this.$eventHub.$on('update-Warehouse', this.getData);
    this.$eventHub.$on('update-Dock', this.getData);
    this.$eventHub.$on('delete-Dock', this.getData);
    this.$eventHub.$on('create-Dock', this.getData);
  },
  beforeDestroy() {
    this.$eventHub.$off('update-Warehouse', this.getData);
    this.$eventHub.$off('update-Dock', this.getData);
    this.$eventHub.$off('delete-Dock', this.getData);
    this.$eventHub.$off('create-Dock', this.getData);
  },
  watch: {
    async docks(newDocks, oldValue) {
      if (oldValue.length === 0) {
        for (const dock of this.docks) {
          // This is important to populate sortOrder for older docks
          if (dock.sortOrder == undefined && this.$rolePermissions.canCreateDock) {
            await this.updateSortOrder(
              this.docks.map((dock, index) => {
                return {
                  id: dock.id,
                  sortOrder: index
                };
              })
            );
            break;
          }
        }
      }
      if (newDocks.length > 0) {
        await this.getAppointmentCountsForDocks();
      } else {
        this.dockAppointmentCounts = [];
      }

      this.initSortable();
    }
  }
};
</script>
