<template>
  <form-base>
    <template #form>
      <v-form>
        <v-row>
          <v-col>
            <h4>
              This user has created some reserves and when this user is removed, the reserves will
              be removed as well.
            </h4>
          </v-col>
        </v-row>
        <v-spacer class="my-6"></v-spacer>
        <v-row>
          <v-col>
            <h4 v-if="hasOrgUsersLeft">
              You can transfer the reserves to another user or just ignore and remove them. If you
              choose to transfer, past and future reserves will be kept.
            </h4>
            <br />
            <p>If you close this dialog the user will not be deleted.</p>
          </v-col>
        </v-row>
        <v-spacer class="my-12"></v-spacer>
        <v-row>
          <v-col>
            <v-autocomplete
              v-if="hasOrgUsersLeft"
              v-model="targetUserId"
              :items="orgUsers"
              item-text="text"
              item-value="value"
              placeholder="Select an user"
              label="Target User"
              clearable
              open-on-clear></v-autocomplete>
          </v-col>
        </v-row>
        <v-spacer class="my-12"></v-spacer>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="remove"
        confirm-icon="plus"
        confirm-label="Transfer to User"
        cancel-label="Delete reserves"
        cancel-color="red"
        cancel-icon="delete-forever"
        :disable-confirm="!targetUserId"
        :hideConfirm="!hasOrgUsersLeft"
        @confirm="transfer"></action-group>
    </template>
  </form-base>
</template>

<script>
export default {
  data() {
    return {
      targetUserId: null,
      orgUsers: []
    };
  },
  props: {
    deletedUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasOrgUsersLeft() {
      return this.orgUsers.length > 0;
    }
  },
  methods: {
    remove() {
      this.$emit('confirm');
    },
    async fetchUsers() {
      const res = await axios.get('user', { params: { s: { orgId: this.deletedUser.orgId } } });
      return res?.data?.data ? res.data.data : [];
    },
    async loadOrgUsers() {
      const users = await this.fetchUsers();
      this.orgUsers = users
        .filter(
          u =>
            u.id !== this.deletedUser.id &&
            this.novaCore.isUserRoleGreaterOrEqual(u.role, this.novaCore.UserRole.ATTENDANT)
        )
        .map(u => ({
          value: u.id,
          text: `${u.firstName} ${u.lastName} - ${this.novaCore.userRoleToText(u.role)}`
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    async transfer() {
      if (!this.targetUserId || !this.deletedUser?.id) {
        return;
      }

      await this.$store
        .dispatch('Appointments/transferReserves', {
          userId: this.deletedUser.id,
          targetUserId: this.targetUserId
        })
        .then(res => {
          if (res?.data?.affected) {
            this.notify('The existing reserves have been transferred!', 'success');
            this.$emit('confirm');
          } else {
            this.notify('Failed to transfer the reserves to another user', 'error');
            this.$emit('close');
          }
        })
        .catch(e => {
          console.error(e);
          this.$emit('close');
        });
    }
  },
  beforeMount() {
    this.loadOrgUsers();
  }
};
</script>
