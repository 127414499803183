var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-data",
      class: { "is-list": _vm.isList, "is-compact text-dense": _vm.isCompact },
    },
    [
      _vm.isGrid
        ? [
            _c(
              "div",
              { staticClass: "py-4 value-grid" },
              [
                _c("div", { staticClass: "block-value" }, [
                  _c("span", [_vm._v(_vm._s(_vm.phoneTitle) + ":")]),
                  _c("span", [_vm._v(_vm._s(_vm.assetVisit.phone ?? "----"))]),
                ]),
                _vm.showCompanyName
                  ? _c("div", { staticClass: "block-value" }, [
                      _c("span", [_vm._v(_vm._s(_vm.companyTitle) + ":")]),
                      _c("span", [_vm._v(_vm._s(_vm.companyName))]),
                    ])
                  : _vm._e(),
                _vm._l(_vm.assetVisitFormData, function (data) {
                  return _c(
                    "div",
                    {
                      key: data.id,
                      staticClass: "block-value",
                      class: _vm.keyValueClasses,
                    },
                    [
                      _c("span", [_vm._v(_vm._s(data.label) + ":")]),
                      _vm.isDocument(data)
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getCustomFieldFormattedValue(data, {
                                  [_vm.CustomFieldType.Document]: {
                                    generateLink: true,
                                  },
                                })
                              ),
                            },
                          })
                        : _vm.isMultiDocument(data)
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getCustomFieldFormattedValue(data, {
                                  [_vm.CustomFieldType.MultiDocument]: {
                                    generateLink: true,
                                  },
                                })
                              ),
                            },
                          })
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.getCustomFieldFormattedValue(data))
                            ),
                          ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "d-flex key-value-pair",
                class: _vm.keyValueClasses,
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row justify-space-between align-center",
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _vm.boldLabels
                        ? _c("strong", { staticClass: "font-size-x-small" }, [
                            _vm._v("Phone Number"),
                          ])
                        : _c("span", { staticClass: "font-size-x-small" }, [
                            _vm._v("Phone Number:"),
                          ]),
                      !_vm.isEditingPhone
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.assetVisit.phone ?? "----")),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.allowPhoneEdit
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  icon: "",
                                  "x-small": "",
                                  "data-testid": "drivers-chat-edit-phone-btn",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.isEditingPhone = true
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-pencil")])],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm.allowPhoneEdit && _vm.isEditingPhone
                  ? _c(
                      "div",
                      [
                        _c("phone-number-field", {
                          staticClass: "mb-1",
                          attrs: {
                            validator: _vm.$validator,
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            placeholder: "Driver's Phone",
                            label: null,
                          },
                          model: {
                            value: _vm.newAssetVisitPhone,
                            callback: function ($$v) {
                              _vm.newAssetVisitPhone = $$v
                            },
                            expression: "newAssetVisitPhone",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "justify-end d-flex flex-row" },
                          [
                            _c(
                              "outline-button",
                              {
                                staticClass: "ml-2",
                                attrs: { "x-small": "" },
                                on: {
                                  click: function ($event) {
                                    _vm.isEditingPhone = false
                                  },
                                },
                              },
                              [_vm._v(" Nevermind ")]
                            ),
                            _c(
                              "primary-button",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  "x-small": "",
                                  "data-testid":
                                    "drivers-chat-save-driver-phone-btn",
                                },
                                on: { click: _vm.saveNewAssetVisitPhone },
                              },
                              [_vm._v(" Save ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm.showCompanyName
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex key-value-pair",
                    class: _vm.keyValueClasses,
                  },
                  [
                    _vm.boldLabels
                      ? _c("strong", { staticClass: "font-size-x-small" }, [
                          _vm._v("Carrier Company"),
                        ])
                      : _c("span", { staticClass: "font-size-x-small" }, [
                          _vm._v("Carrier Company:"),
                        ]),
                    _c("span", [_vm._v(_vm._s(_vm.companyName))]),
                  ]
                )
              : _vm._e(),
            _vm._l(_vm.assetVisitFormData, function (data) {
              return _c(
                "div",
                {
                  key: data.id,
                  staticClass: "d-flex key-value-pair",
                  class: _vm.keyValueClasses,
                },
                [
                  _vm.boldLabels
                    ? _c("strong", { staticClass: "font-size-x-small" }, [
                        _vm._v(_vm._s(data.label)),
                      ])
                    : _c("span", { staticClass: "font-size-x-small" }, [
                        _vm._v(_vm._s(data.label) + ":"),
                      ]),
                  _vm.isDocument(data)
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getCustomFieldFormattedValue(data, {
                              [_vm.CustomFieldType.Document]: {
                                generateLink: true,
                              },
                            })
                          ),
                        },
                      })
                    : _vm.isMultiDocument(data)
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getCustomFieldFormattedValue(data, {
                              [_vm.CustomFieldType.MultiDocument]: {
                                generateLink: true,
                              },
                            })
                          ),
                        },
                      })
                    : _c("span", [
                        _vm._v(_vm._s(_vm.getCustomFieldFormattedValue(data))),
                      ]),
                ]
              )
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }