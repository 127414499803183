<template>
  <form-base>
    <template #form>
      <v-form ref="form" v-on:submit.prevent @keyup.native.enter="submit">
        <v-container>
          <v-alert border="bottom" colored-border type="warning" elevation="2">
            Updating the External Billing ID will affect this customer billing information. Make
            sure the id corresponds to this customer profile on Midas. If it is blank, this account
            won't be connected to the billing system.

            <p class="mt-4" v-if="Boolean($org.externalBillingId)">
              Current Id: {{ $org.externalBillingId }}
            </p>
          </v-alert>
          <br />
          <text-field
            type="text"
            v-model="localOrgBillingId"
            label="External Billing ID (UUID)"
            :rules="$validator.rules.uuid"></text-field>
        </v-container>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="cancel"
        confirm-icon="update"
        confirm-label="Update External Billing Id"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * Edit Org Form
 * @displayName Edit Org Form
 */
import { isUUID } from 'class-validator';

export default {
  props: {
    orgId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localOrgBillingId: ''
    };
  },
  computed: {
    isValid() {
      return isUUID(this.localOrgBillingId);
    }
  },
  methods: {
    /**
     * Submit form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.localOrgBillingId) {
        this.localOrgBillingId = null;
      }

      let org = await this.$store.dispatch('Orgs/updateOrg', {
        externalBillingId: this.localOrgBillingId
      });

      if (org) {
        this.cancel();
      }
    },
    cancel() {
      this.setLocalOrgExternalBillingId();
      this.$emit('close');
    },
    setLocalOrgExternalBillingId() {
      this.localOrgBillingId = this.$org.externalBillingId;
    }
  },
  async mounted() {
    await this.$store.dispatch('Orgs/getOrg', this.orgId);
    this.setLocalOrgExternalBillingId();
  }
};
</script>
