var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Clone Schedule",
          scrollable: true,
          "max-width": "450px",
          width: "450px",
          "has-dialog-actions": "",
          "loading-message": "Cloning Schedule...",
        },
        on: { close: _vm.close, submit: _vm.emitCopySchedule },
        nativeOn: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.emitCopySchedule.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "button-base",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "white",
                                  attrs: {
                                    text: "",
                                    plain: "",
                                    block: "",
                                    "x-small": "",
                                    "before-icon": "content-copy",
                                  },
                                },
                                "button-base",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v(" Another Dock... ")]
                          ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("form-base", {
                    staticClass: "px-2 mb-2",
                    attrs: { "hide-required-legend": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "form",
                        fn: function () {
                          return [
                            _c(
                              "v-form",
                              { ref: "form" },
                              [
                                _c("h4", { staticClass: "black--text" }, [
                                  _vm._v("Dock schedule to clone from:"),
                                ]),
                                _c("dock-select", {
                                  attrs: {
                                    "multi-select": false,
                                    "prepend-icon": null,
                                    docks: _vm.docks,
                                    placeholder: "Select Dock",
                                  },
                                  model: {
                                    value: _vm.selectedDock,
                                    callback: function ($$v) {
                                      _vm.selectedDock = $$v
                                    },
                                    expression: "selectedDock",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "form-actions",
                        fn: function () {
                          return [
                            _c("action-group", {
                              attrs: {
                                "confirm-icon": "content-copy",
                                "confirm-label": "Clone Schedule",
                              },
                              on: {
                                cancel: _vm.close,
                                confirm: _vm.emitCopySchedule,
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }