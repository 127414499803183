<template>
  <v-btn
    text
    class="asset-item-panel"
    :ripple="false"
    :data-testid="`asset-visit-panel-${visit.id}`"
    @click="handleClick"
    block>
    <div class="flex-1 text-left">
      <div class="asset-item-title font-weight-bold">
        <slot name="title" :visit="visit">
          <span v-if="!visit.checkInAcknowledged && allowAcknowledgement">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click.stop="acknowledgeAssetVisit(visit)"
                  :disabled="acknowledgementInProcess"
                  :data-testid="`checkin-panel-acknowledge-button-${visit.id}`"
                  class="pr-2"
                  size="12px"
                  color="#FF9A1A">
                  mdi-circle
                </v-icon>
              </template>
              Mark as read
            </v-tooltip>
          </span>
          <span
            :data-asset-company-id="visit.company?.id ?? visit.companyHint"
            class="font-weight-bold font--poppins text--color-text-secondary font-size-x-small">
            {{ companyName }}
          </span>
        </slot>
      </div>
      <div class="asset-item-meta d-flex">
        <template v-if="isPlanned">
          <v-chip x-small v-if="isPlanned">Planned</v-chip>
          <v-chip x-small class="ml-1" :class="statusBgColorClass">{{ statusLabel }}</v-chip>
        </template>

        <template v-else>
          <v-chip x-small>Unplanned</v-chip>
          <v-chip
            x-small
            class="ml-1"
            :class="{ 'disabled-chip': isPendingUpdate }"
            v-if="!isPlanned">
            <v-icon v-if="isRejected" x-small>mdi-cancel</v-icon>
            {{ unplannedUpdateTag }}
          </v-chip>
        </template>

        <div
          class="ml-2 latest-event font-size-x-small text--color-text-secondary"
          :data-testid="`asset-visit-latest-event-${visit.id}`">
          <v-icon small class="mr-1 text--color-neutral-60">mdi-map-marker-outline</v-icon>
          <strong class="mr-1">{{ latestEventType }} at:</strong>
          <span>{{ latestEventTime }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="lastDriverMessageTimestamp"
      class="text--color-text-secondary font-size-x-small d-flex align-end flex-column mr-4"
      :class="unreadMessagesCount > 0 ? 'font-weight-bold' : 'font-weight-light'"
      :data-testid="`unread-message-count-${visit.id}`">
      <div>
        <v-icon>mdi-comment-text-outline</v-icon>
        {{ makeLastDriverMessageTimestamp(lastDriverMessageTimestamp) }}
      </div>
      <v-chip
        class="mt-1 unread-message-count-chip"
        x-small
        v-if="unreadMessagesCount"
        color="primary">
        {{ unreadMessagesCount < 100 ? unreadMessagesCount : '99+' }}
      </v-chip>
    </div>
    <div>
      <v-icon class="text--color-neutral-80">mdi-chevron-right</v-icon>
    </div>

    <v-dialog
      v-if="shouldShowDetails"
      v-model="shouldShowDetails"
      content-class="asset-details-dialog"
      transition="slide-left"
      @click:outside="handleCloseDialog">
      <v-card-title class="dialog-title align-center">
        <v-btn icon @click="shouldShowDetails = false">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="pl-3 headline">Arrival Details</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="handleCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text>
        <div class="sidebar-content">
          <aside class="sidebar">
            <div class="d-flex align-start text--color-text-secondary font-weight-bold">
              <v-icon small class="mr-2 mt-1">mdi-truck-outline</v-icon>
              <span class="font--poppins">
                {{ companyName }}
              </span>
            </div>
            <div
              class="mt-2 latest-event font-size-x-small text--color-text-secondary"
              data-testid="asset-visit-details-latest-event">
              <v-icon small class="mr-1 text--color-neutral-60">mdi-map-marker-outline</v-icon>
              <strong class="mr-1">{{ latestEventType }} at:</strong>
              <span>{{ latestEventTime }}</span>
            </div>

            <div class="asset-item-meta d-flex mt-2">
              <v-chip x-small v-if="isPlanned">Planned</v-chip>
              <template v-else>
                <v-chip x-small>Unplanned</v-chip>
                <v-chip
                  x-small
                  class="ml-1"
                  :class="{ 'disabled-chip': isPendingUpdate }"
                  v-if="!isPlanned">
                  <v-icon v-if="isRejected" x-small>mdi-cancel</v-icon>
                  {{ unplannedUpdateTag }}
                </v-chip>
              </template>
            </div>

            <v-menu
              offset-y
              :internal-activator="true"
              :close-on-click="true"
              v-if="isPlanned || (!isPlanned && isAppointmentCreated)">
              <template v-slot:activator="{ on, attrs }">
                <outline-button
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mt-4"
                  data-testid="asset-visit-details-actions-trigger">
                  Appointment
                  <v-icon small class="ml-2">mdi-chevron-down</v-icon>
                </outline-button>
              </template>
              <v-list class="plain-items">
                <v-list-item>
                  <button-base
                    text
                    plain
                    small
                    block
                    :to="{ name: 'appointments', query: { appointmentId: visit.appointmentId } }"
                    :ripple="false"
                    class="d-flex align-center justify-center">
                    <v-icon small class="mr-2">mdi-eye</v-icon>
                    <span class="flex-1">View appointment</span>
                  </button-base>
                </v-list-item>
                <v-list-item
                  v-if="
                    [
                      novaCore.AppointmentStatus.Scheduled,
                      novaCore.AppointmentStatus.Arrived
                    ].includes(visit.appointment.status)
                  ">
                  <button-base
                    text
                    plain
                    small
                    block
                    class="error--text"
                    :data-testid="`asset-visit-panel-unlink-button`"
                    @click="handleUnlinkConfirmDialog(visit.id, visit.appointmentId)">
                    <v-icon small>mdi-link-variant-off</v-icon>
                    Unlink From Appointment
                  </button-base>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu
              offset-y
              :internal-activator="true"
              :close-on-click="true"
              v-if="isPendingUpdate">
              <template v-slot:activator="{ on, attrs }">
                <primary-button
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mt-4"
                  data-testid="asset-visit-details-actions-trigger">
                  Update Arrival
                  <v-icon small class="ml-2">mdi-chevron-down</v-icon>
                </primary-button>
              </template>
              <v-list class="plain-items">
                <v-list-item>
                  <button-base
                    data-testid="asset-visit-details-create-appointment-button"
                    text
                    plain
                    :ripple="false"
                    @click="showCreateAppointmentDialog = true"
                    class="d-flex align-center justify-center">
                    <v-icon small>mdi-calendar-outline</v-icon>
                    <span class="flex-grow-1 justify-start font-size-x-small">
                      Create appointment
                    </span>
                  </button-base>
                </v-list-item>
                <v-list-item>
                  <button-base
                    data-testid="asset-visit-details-link-appointment-button"
                    text
                    plain
                    :ripple="false"
                    @click="$emit('show-appointment-list-dialog')"
                    class="d-flex align-center justify-center">
                    <v-icon small>mdi-link-variant</v-icon>
                    <span class="flex-grow-1 justify-start font-size-x-small">
                      Link to appointment
                    </span>
                  </button-base>
                </v-list-item>
                <v-list-item>
                  <button-base
                    data-testid="asset-visit-details-reject-load-button"
                    text
                    plain
                    :ripple="false"
                    class="d-flex align-center justify-center"
                    @click="showRejectConfirm = true">
                    <v-icon small>mdi-cancel</v-icon>
                    <span class="flex-grow-1 justify-start font-size-x-small">Reject load</span>
                  </button-base>
                </v-list-item>
              </v-list>
            </v-menu>

            <div class="font-weight-bold font--poppins text--color-text-secondary mb-2 mt-8">
              Asset Details
            </div>
            <asset-visit-form-data
              :asset-visit="visit"
              is-list
              :is-inline="false"
              is-divided
              :bold-labels="false"
              :allow-phone-edit="$rolePermissions.canChangeDriverPhone"
              phone-title="phone"
              :company="visit.company || { name: visit.companyHint }"
              company-title="Company" />
          </aside>
          <section class="content">
            <drivers-chat-window
              v-if="warehouse?.id"
              compact-mode
              :allow-phone-edit="false"
              :warehouse="warehouse"
              :asset-visit="visit" />
          </section>
        </div>
      </v-card-text>
    </v-dialog>

    <create-appointment-dialog
      :disable-activator="lockActions"
      :show-dialog="showCreateAppointmentDialog"
      button-label="Create appointment"
      :asset-visit="visit"
      external-activator
      @close="showCreateAppointmentDialog = false"
      :context="{ selectedWarehouse: warehouse }"
      @scheduled="appointment => handleNewAppointment(appointment, visit.id)" />

    <confirm
      :should-show="showRejectConfirm"
      is-manual-mode
      persistent
      v-if="showRejectConfirm"
      @result="handleRejectResult"
      title="Reject load?"
      :width="650"
      button-true-color="primary"
      color="warning">
      <template #message>
        <div>
          <v-alert text prominent border="left" color="warning" class="my-7">
            <v-icon color="#955800" class="mr-2">mdi-alert</v-icon>
            <span class="text--primary">This action cannot be undone</span>
          </v-alert>
        </div>
      </template>

      <template #true-action="{ choose }">
        <v-btn color="primary" @click="choose(true)" :loading="rejectingLoader">Reject load</v-btn>
      </template>
    </confirm>

    <confirm
      :should-show="showUnlinkConfirm"
      is-manual-mode
      persistent
      v-if="showUnlinkConfirm"
      @result="handleUnlinkResult"
      title="Unlink from appointment?"
      :width="650"
      button-true-color="primary"
      color="warning">
      <template #message>
        <div>
          <v-alert text prominent border="left" color="warning" class="my-7">
            <v-icon color="#955800" class="mr-2">mdi-alert</v-icon>
            <span class="text--primary">This action cannot be undone</span>
          </v-alert>
        </div>
      </template>

      <template #true-action="{ choose }">
        <v-btn color="primary" @click="choose(true)" :loading="unlinkingLoader">
          Unlink from appointment
        </v-btn>
      </template>
    </confirm>
  </v-btn>
</template>

<script>
import { formatDateTimeWithMilitarySupport, LuxonDateTimeFormats } from '@satellite/../nova/core';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRouter, useStore, useNotify, useEventHub, useNovaCore } from '@/composables';
import useMixpanel from '@/composables/useMixpanel';
import { DateTime } from 'luxon';
import DriversChatWindow from '@/modules/appointments/components/drivers_chat/DriversChatWindow.vue';

export default {
  components: { DriversChatWindow },
  props: {
    visit: {
      type: Object,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    },
    isPlanned: {
      type: Boolean,
      required: false,
      default: false
    },
    isRejected: {
      type: Boolean,
      required: false,
      default: false
    },
    isAppointmentCreated: {
      type: Boolean,
      required: false,
      default: false
    },
    unreadMessagesCount: {
      type: Number,
      required: false,
      default: 0
    },
    lastDriverMessageTimestamp: {
      type: String,
      required: false,
      default: null
    },
    allowAcknowledgement: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['updated', 'show-appointment-list-dialog', 'close'],
  setup(props, context) {
    const eventHub = useEventHub();
    const store = useStore();
    const router = useRouter();
    const notify = useNotify();
    const mixpanel = useMixpanel();
    const novaCore = useNovaCore();

    let shouldShowDetails = ref(false);

    const acknowledgementInProcess = ref(false);

    const isPendingUpdate = computed(
      () => !props.isRejected && !props.isAppointmentCreated && !props.isPlanned
    );
    const unplannedUpdateTag = computed(() =>
      props.isRejected
        ? 'Rejected'
        : props.isAppointmentCreated
        ? 'Appointment Created'
        : 'Pending update'
    );
    const companyName = props.visit.company?.name || props.visit.companyHint;
    const lockActions = ref(false);
    const rejectingLoader = ref(false);
    const unlinkingLoader = ref(false);
    const unlinkData = ref(null);

    const showUnlinkConfirm = ref(false);
    const showRejectConfirm = ref(false);

    const showCreateAppointmentDialog = ref(false);

    const getBaseMixpanelData = computed(() => ({
      'Warehouse ID': props.warehouse.id,
      'Warehouse Name': props.warehouse.name,
      'Org ID': props.warehouse.org?.id,
      'Org Name': props.warehouse.org?.name
    }));

    async function handleNewAppointment(appointment, visitId) {
      const apptWithUser = (
        await axios.get(`appointment/${appointment?.id}`, {
          params: { select: 'id', join: 'user||companyId' }
        })
      )?.data?.data;
      const assetVisit = await axios.post('/checkin/link-unplanned-checkin-to-appointment', {
        assetVisitId: visitId,
        appointmentId: appointment?.id,
        companyId: apptWithUser?.user?.companyId,
        isPlanned: false
      });

      if (assetVisit?.data) {
        mixpanel.track(mixpanel.events.MODULE.SELF_CHECK_IN.APPOINTMENT_LINKED, {
          ...getBaseMixpanelData.value,
          'Appointment ID': appointment?.id,
          'Asset ID': props.visit.id,
          'Existing Appointment': 'No'
        });
      }

      showCreateAppointmentDialog.value = false;
      await router.replace(`/appointments?appointmentId=${appointment?.id}`);
      context.emit('updated', assetVisit?.data?.data);
    }

    async function handleRejectResult(result) {
      if (result) {
        await rejectLoad();
      }
      showRejectConfirm.value = false;
    }

    async function rejectLoad() {
      lockActions.value = true;
      rejectingLoader.value = true;
      try {
        const response = await axios.post('/asset-visit/event', {
          assetVisitId: props.visit.id,
          eventType: 'Canceled'
        });
        context.emit('updated', response?.data?.data);

        if (response?.data?.data) {
          mixpanel.track(mixpanel.events.MODULE.SELF_CHECK_IN.REJECT_TRUCK, {
            ...getBaseMixpanelData.value,
            'Asset ID': props.visit.id
          });
        }
      } finally {
        lockActions.value = false;
        rejectingLoader.value = false;
      }
    }

    const mostRecentEvent = computed(() => {
      return props.visit.assetVisitEvents.reduce((latest, current) => {
        return new Date(current.createDateTime) > new Date(latest.createDateTime)
          ? current
          : latest;
      }, props.visit.assetVisitEvents[0]);
    });

    const latestEventTime = computed(() => {
      return formatDateTimeWithMilitarySupport(
        mostRecentEvent.value.createDateTime,
        props.warehouse.timezone,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        store.getters['Settings/isMilitaryTimeEnabled'](props.warehouse),
        LuxonDateTimeFormats.Extended24HrTime
      );
    });

    const latestEventType = computed(() => {
      return mostRecentEvent.value.eventType;
    });

    function makeLastDriverMessageTimestamp(timestamp) {
      const dateTime = DateTime.fromISO(timestamp, { zone: props.warehouse.timezone });
      let formats;
      if (dateTime.hasSame(DateTime.now().setZone(props.warehouse.timezone), 'day')) {
        formats = [
          LuxonDateTimeFormats.Extended12HrTimeAMPM,
          LuxonDateTimeFormats.Extended24HrTime
        ];
      } else {
        formats = [
          LuxonDateTimeFormats.MonthDayYearSlashed,
          LuxonDateTimeFormats.MonthDayYearSlashed
        ];
      }

      return formatDateTimeWithMilitarySupport(
        timestamp,
        props.warehouse.timezone,
        formats[0],
        store.getters['Settings/isMilitaryTimeEnabled'](props.warehouse),
        formats[1]
      );
    }

    function handleUnlinkConfirmDialog(visitId, appointmentId) {
      unlinkData.value = { visitId, appointmentId };
      showUnlinkConfirm.value = true;
    }

    async function handleUnlinkResult(result) {
      if (result) {
        await unlinkCheckinFromAppointment(
          unlinkData.value.visitId,
          unlinkData.value.appointmentId
        );
      }
      showUnlinkConfirm.value = false;
      unlinkData.value = null;
    }

    async function unlinkCheckinFromAppointment(visitId, appointmentId) {
      unlinkingLoader.value = true;
      try {
        await axios.post('/checkin/unlink-checkin-from-appointment', {
          assetVisitId: visitId,
          appointmentId
        });
        notify('Asset visit has been unlinked from the appointment');
        mixpanel.track(mixpanel.events.MODULE.SELF_CHECK_IN.APPOINTMENT_UNLINKED, {
          ...getBaseMixpanelData.value,
          'Asset ID': props.visit.id,
          'Appointment ID': appointmentId
        });
      } finally {
        unlinkingLoader.value = false;
      }
    }

    const appointmentUpdateHandler = async updatedAppointment => {
      if (updatedAppointment?.assetVisit?.id === props.visit.id) {
        context.emit('updated', { ...props.visit, appointment: updatedAppointment });
      }
    };

    const isStatusEarly = computed(
      () => getAppointmentArrivalStatus(props.visit) === novaCore.EtaCondition.Early
    );
    const isStatusLate = computed(
      () => getAppointmentArrivalStatus(props.visit) === novaCore.EtaCondition.Late
    );

    const statusBgColorClass = computed(() => ({
      'bg--color-success-20': isStatusEarly.value,
      'bg--color-error-20': isStatusLate.value
    }));

    const statusLabel = computed(() => {
      if (isStatusEarly.value) {
        return 'Early';
      } else if (isStatusLate.value) {
        return 'Late';
      } else {
        return 'On Time';
      }
    });

    function getAppointmentArrivalStatus(visit) {
      const arrival = DateTime.fromISO(visit.createDateTime).set({ second: 0, millisecond: 0 });
      const apptStart = DateTime.fromISO(visit.appointment?.start).set({
        second: 0,
        millisecond: 0
      });
      const diff = apptStart.diff(arrival, 'minutes').toObject();
      return diff.minutes < 0
        ? novaCore.EtaCondition.Late
        : diff.minutes > 0
        ? novaCore.EtaCondition.Early
        : novaCore.EtaCondition.OnTime;
    }

    async function acknowledgeAssetVisit(assetVisit) {
      acknowledgementInProcess.value = true;
      try {
        await axios.patch(
          `asset-visit/${assetVisit.id}/check-in-acknowledgment`,
          {
            checkInAcknowledged: true
          },
          { suppressNotification: true }
        );
        assetVisit.checkInAcknowledged = true;
        updateAssetVisitInArr(assetVisit);
      } finally {
        acknowledgementInProcess.value = false;
      }
    }

    onMounted(() => {
      eventHub.$on('update-Appointment', appointmentUpdateHandler);
    });

    onBeforeUnmount(() => {
      eventHub.$off('update-Appointment', appointmentUpdateHandler);
    });

    function handleClick() {
      shouldShowDetails.value = true;
    }

    const handleCloseDialog = () => {
      shouldShowDetails.value = false;
      context.emit('close');
    };

    return {
      handleNewAppointment,
      lockActions,
      rejectingLoader,
      showRejectConfirm,
      handleRejectResult,
      unlinkingLoader,
      unlinkData,
      handleUnlinkConfirmDialog,
      showUnlinkConfirm,
      handleUnlinkResult,
      isPendingUpdate,
      unplannedUpdateTag,
      shouldShowDetails,
      companyName,
      showCreateAppointmentDialog,
      getAppointmentArrivalStatus,
      statusBgColorClass,
      statusLabel,
      handleClick,
      makeLastDriverMessageTimestamp,
      latestEventTime,
      latestEventType,
      acknowledgeAssetVisit,
      acknowledgementInProcess,
      handleCloseDialog
    };
  }
};
</script>

<style scoped lang="scss">
.asset-item-header {
  flex-direction: column;
}

.asset-item-title {
  font-size: 16px;
  font-family: Poppins, sans-serif;
  color: $color-text-secondary;
  margin-bottom: 4px;
}

.asset-item-panel {
  border: 1px solid $color-line-border;
  border-radius: 0;
  height: auto !important;
  text-transform: none !important;
  padding: 16px !important;
  justify-content: flex-start;
  letter-spacing: normal !important;
  border-radius: 4px;

  &:hover {
    background-color: $color-background-tertiary !important;
  }
}

.asset-item-panel,
.v-expansion-panel-header {
  &:before,
  &:after {
    display: none;
  }
}

.latest-event {
  font-weight: 400;
  color: $color-text-secondary;
}

::v-deep .disabled-chip {
  background-color: $color-background-tertiary !important;
  border: 1px dashed $color-neutral-60;
  color: $color-neutral-60;
}

.asset-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

:deep .v-expansion-panel-content {
  border-top: 1px solid $color-line-border;
  padding-top: 12px;
}

::v-deep .asset-details-dialog {
  display: flex;
  flex-direction: column;
  transform-origin: center center;
  width: 620px;
  max-width: 100%;
  position: fixed;
  right: 0;
  margin: 0;
  height: 100vh;
  max-height: 100%;
  background: $color-neutral-0;

  .dialog-title {
    border-bottom: 1px solid $color-line-divider;
  }

  .v-card {
    height: 100%;
    box-shadow: none;
    max-height: 100%;
    &__text {
      overflow: hidden;
      flex: 1;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
}

.sidebar-content {
  height: 100%;
  display: flex;
  padding: 0 0 0 8px;

  .sidebar {
    width: 276px;
    padding-right: 16px;
    padding-top: 16px;
    overflow: auto;
  }
  .content {
    flex: 1;
    border-left: 1px solid $color-neutral-20;
    padding-top: 16px;
  }
}

::v-deep {
  .plain-items .v-btn__content {
    opacity: 1 !important;
  }
}

.plain-items {
  .v-btn {
    text-transform: none;
    font-weight: 400 !important;
  }
}

.plain-link {
  flex: 1;
  color: $color-text-primary !important;
}

a {
  color: $color-text-primary !important;
  text-decoration: none;
}

.v-list-item {
  min-height: 36px !important;
}
</style>
