var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "custom-field-input-base",
    _vm._b(
      {
        attrs: { outlined: _vm.outlined, field: _vm.field, type: "text" },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      },
      "custom-field-input-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }