<template>
  <div class="calendar-legend">
    <v-tooltip
      v-for="item in legendItems"
      :key="item.name"
      top
      :disabled="!statusTooltips[getProperCasedStatusName(item.name)]">
      <template v-slot:activator="{ on }">
        <v-chip
          v-on="on"
          x-small
          :class="`py-1 px-2 ${$useLightGridTheme ? `${item.color} lighten-4 mr-2` : ''} ${
            item.name
          }`"
          color="transparent">
          <v-icon x-small :color="item.color" class="mr-1">{{
            novaCore.isRequested(getProperCasedStatusName(item.name))
              ? 'mdi-clock-alert'
              : 'mdi-circle'
          }}</v-icon>
          <span class="text--black">{{ item.name }}</span>
        </v-chip>
      </template>
      <span
        v-if="statusTooltips[getProperCasedStatusName(item.name)]"
        v-html="statusTooltips[getProperCasedStatusName(item.name)].description"></span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'CalendarLegend',
  computed: {
    statusItems() {
      return Object.keys(this.novaCore.AppointmentStatus).map(status => {
        const color = this.novaCore.isRequested(status)
          ? 'requested darken-3'
          : status.toLowerCase();
        return { name: this.getStatusName(status), color };
      });
    },
    legendItems() {
      const getStatusName = status => {
        return status.name
          .split(' ')
          .map(part => this.novaCore.upperFirst(part.toLowerCase()))
          .join('');
      };
      return [...this.additionalItems, ...this.statusItems].sort((a, b) => {
        return (
          this.statusOrder.indexOf(getStatusName(a)) - this.statusOrder.indexOf(getStatusName(b))
        );
      });
    }
  },
  data() {
    return {
      additionalItems: [
        {
          name: 'RESERVED',
          color: 'reserve'
        }
      ],
      statusOrder: [
        this.novaCore.AppointmentType.Reserve,
        this.novaCore.AppointmentStatus.Requested,
        this.novaCore.AppointmentStatus.Scheduled,
        this.novaCore.AppointmentStatus.Arrived,
        this.novaCore.AppointmentStatus.InProgress,
        this.novaCore.AppointmentStatus.Completed,
        this.novaCore.AppointmentStatus.NoShow,
        this.novaCore.AppointmentStatus.Cancelled
      ],
      statusTooltips: {
        [this.novaCore.AppointmentStatus.Requested]: {
          description:
            'This is an optional Organization setting that enforces an Appointment starting status of "Requested" if set'
        }
      }
    };
  },
  methods: {
    getStatusName(status) {
      return status ? this.novaCore.breakWordsAtCaps(status).toUpperCase() : 'UNKNOWN';
    },
    getProperCasedStatusName(statusName) {
      return this.novaCore.upperFirst(this.novaCore.breakWordsAtCaps(statusName).toLowerCase());
    }
  }
};
</script>
