<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Manage Org Tags"
    loading-message="Loading org tags..."
    @close="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <template slot="body">
      <edit-org-tags-form @close="close" :org-id="orgId" @save="updateOrgTags"></edit-org-tags-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit org form
 * @displayName EditOrgDialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateOrgTags(tags) {
      this.org.tags = tags || [];
    }
  },
  computed: {
    orgId() {
      return this.org?.id;
    }
  }
};
</script>
