<template>
  <div class="content">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.content {
  background-color: transparent !important;
  width: 700px;
  max-width: 95%;
  margin: 0 auto;
  border-radius: 5px;
}

.icon {
  order: 0;
}
</style>
