<template>
  <div class="text-center">
    <h3>401 Not Authorized</h3>
    <p>You do not have permission to access this page.</p>
    <p>
      Please log in through
      <a href="https://carrier.opendock.com">Opendock</a>
    </p>
  </div>
</template>

<script>
export default {};
</script>
