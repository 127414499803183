var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Transfer carrier appointments",
          scrollable: false,
          "loading-message": "Processing appointments",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            {
              key: "activator",
              fn: function (slotProps) {
                return [
                  _vm.$scopedSlots.activator
                    ? _vm._t("activator", null, null, slotProps)
                    : _vm._e(),
                ]
              },
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("transfer-appointment-form", {
                    ref: "transferAppointmentForm",
                    attrs: {
                      "cancel-label": _vm.cancelLabel,
                      "cancel-color": _vm.cancelColor,
                      "cancel-icon": _vm.cancelIcon,
                      "source-user": _vm.sourceUser,
                    },
                    on: { close: _vm.close, confirm: _vm.confirm },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }