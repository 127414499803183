var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      staticClass:
        "custom-calendar-container custom-week-calendar-container fill-height",
    },
    [
      _c("v-calendar", {
        ref: "calendar",
        staticClass: "calendar",
        class: _vm.calendarClasses,
        attrs: {
          type: "week",
          weekdays: _vm.weekdaysToShow,
          events: _vm.events,
          "interval-count": _vm.intervalsToShow,
          "interval-minutes": _vm.$intervalMinutes,
          "interval-height": _vm.$zoomLevel,
          "first-interval": _vm.intervals.first,
          "interval-format": _vm.formatInterval,
          color: "primary",
          "event-overlap-mode": "column",
          "short-intervals": true,
          "event-color": _vm.getEventClasses,
        },
        on: {
          "contextmenu:event": _vm.setEventContextMenuId,
          "mousedown:event": _vm.startDrag,
          "mousedown:time": _vm.startTime,
          "mousemove:time": _vm.mouseMove,
          "mouseup:time": _vm.endDrag,
          "click:day": _vm.handleDayClick,
          "click:time": _vm.handleCalendarClick,
        },
        nativeOn: {
          mouseup: function ($event) {
            return _vm.cancelDrag.apply(null, arguments)
          },
          mouseleave: function ($event) {
            return _vm.cancelDrag.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "day-label-header",
            fn: function (dateTime) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "d-flex flex-column",
                                      class: {
                                        "primary--text": dateTime.present,
                                      },
                                    },
                                    "div",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-uppercase weekday-label",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.weekdays[dateTime.weekday])
                                      ),
                                    ]
                                  ),
                                  _c("span", [_vm._v(_vm._s(dateTime.day))]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.totalAppointmentsByDayString(dateTime))
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "day-body",
            fn: function ({ date, week, present }) {
              return [
                present
                  ? _c("div", {
                      staticClass: "v-current-time first",
                      style: { top: _vm.nowY },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "interval",
            fn: function ({ date, time, weekday }) {
              return [
                _vm.mounted
                  ? _c(
                      "div",
                      {
                        staticClass: "slot-wrapper",
                        attrs: {
                          "data-weekday": weekday,
                          "data-date": date,
                          "data-time": time.replace(/^0/, ""),
                        },
                      },
                      [
                        _vm.isDateClosed(
                          date,
                          _vm.$selectedWarehouse.schedule
                        ) || _vm.isIntervalDisabled(date, time, _vm.firstDockId)
                          ? _c("div", {
                              staticClass: "disabled-time-interval overlay",
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "event",
            fn: function ({ event }) {
              return [
                _c(
                  "div",
                  {
                    key: `${_vm.renderKey}-${event.id}`,
                    staticClass: "event-content",
                    attrs: {
                      "data-time": event.startTime24.replace(/^0/, ""),
                      "data-weekday": event.weekday,
                      "data-date": event.startDate,
                      "data-dock-id": event.dockId,
                    },
                  },
                  [
                    _c("event-with-tooltip", {
                      attrs: {
                        event: event,
                        "is-light-theme": _vm.$useLightGridTheme,
                        enableMilitaryTime: _vm.$isMilitaryTimeEnabled(
                          _vm.$selectedWarehouse
                        ),
                        "use-new-grid-tiles": _vm.useNewGridTilesSetting,
                        "disable-tooltip": _vm.isTooltipDisabled(event),
                      },
                      on: {
                        click: _vm.handleEventClick,
                        "drag-event": _vm.dragEvent,
                        "cancel-reserve": _vm.cancelReserve,
                      },
                    }),
                  ],
                  1
                ),
                _vm.isStartingStatus(event.status) &&
                _vm.$rolePermissions.canUpdateAppointment
                  ? _c("div", {
                      staticClass: "v-event-drag-bottom",
                      on: {
                        mousedown: function ($event) {
                          $event.stopPropagation()
                          return _vm.extendBottom(event)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.eventContextMenuId === event.id &&
                event.status !== _vm.novaCore.AppointmentStatus.Cancelled &&
                event.type !== _vm.novaCore.AppointmentType.Reserve
                  ? _c("event-context-menu", {
                      attrs: {
                        nativeEvent: _vm.eventContextMenuEvent,
                        appointment: event,
                      },
                      on: {
                        close: function ($event) {
                          _vm.eventContextMenuId = null
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedDate,
          callback: function ($$v) {
            _vm.selectedDate = $$v
          },
          expression: "selectedDate",
        },
      }),
      _vm.eventToUpdate && _vm.originalEvent && _vm.eventWarehouse
        ? _c("load-type-re-select-dialog", {
            attrs: {
              "original-event": _vm.originalEvent,
              event: _vm.eventToUpdate,
              "event-warehouse": _vm.eventWarehouse,
              "event-dock": _vm.eventDock,
              "original-event-dock":
                _vm.$docksKeyedById[_vm.originalEvent.category] ??
                _vm.$docksKeyedById[_vm.originalEvent.dockId],
              "show-dialog": _vm.shouldShowLoadTypeReselectDialog,
            },
            on: { close: _vm.nevermindLoadTypeDialog },
          })
        : _vm._e(),
      _c("calendar-dialogs", {
        attrs: {
          "appointment-context": _vm.appointmentContext,
          "show-create-dialog": _vm.showCreateDialog,
          "show-details-dialog": _vm.showDetailsDialog,
          "show-reserve-dialog": _vm.showReserveDialog,
        },
        on: { close: _vm.handleDialogClose },
      }),
      _c("zoom-buttons"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }