var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("text-field", {
                attrs: {
                  required: true,
                  type: "text",
                  rules: _vm.$validator.rules.required("Field Label"),
                  label: "Field Label",
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.localField.label,
                  callback: function ($$v) {
                    _vm.$set(_vm.localField, "label", $$v)
                  },
                  expression: "localField.label",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("custom-forms-type-selector", {
                attrs: { rules: _vm.$validator.rules.required("Field Type") },
                model: {
                  value: _vm.localField.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.localField, "type", $$v)
                  },
                  expression: "localField.type",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("text-field", {
                staticClass: "mt-10",
                attrs: { type: "text", label: "Description" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.localField.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.localField, "description", $$v)
                  },
                  expression: "localField.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              !_vm.typesWithoutPlaceholder.includes(_vm.localField.type)
                ? _c("v-text-field", {
                    attrs: { type: "text", label: "Example Value" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.stopPropagation()
                      },
                    },
                    model: {
                      value: _vm.localField.placeholder,
                      callback: function ($$v) {
                        _vm.$set(_vm.localField, "placeholder", $$v)
                      },
                      expression: "localField.placeholder",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.localField.type === _vm.novaCore.CustomFieldType.Action
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-select", {
                    attrs: {
                      multiple: "",
                      label: "Features Choices",
                      required: "",
                      items: Object.entries(_vm.novaCore.CustomFormsFeatures),
                      "item-text": (key) =>
                        _vm.novaCore.CustomFormsFeaturesData[key[1]].title,
                      "item-value": (key) => key[1],
                    },
                    model: {
                      value: _vm.localField.extraFields.dropDownValues,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.localField.extraFields,
                          "dropDownValues",
                          $$v
                        )
                      },
                      expression: "localField.extraFields.dropDownValues",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "my-4" },
        [
          _c(
            "v-col",
            [
              _vm.isDocField
                ? _c("v-alert", { attrs: { type: "info", color: "grey" } }, [
                    _vm._v(" Maximum "),
                    _c("strong", [_vm._v("50MB")]),
                    _vm._v(" in size. Only "),
                    _c("strong", [_vm._v("ONE image")]),
                    _vm._v(" , "),
                    _c("strong", [_vm._v("text")]),
                    _vm._v(" , "),
                    _c("strong", [_vm._v("zip")]),
                    _vm._v(" or "),
                    _c("strong", [_vm._v("spreadsheet")]),
                    _vm._v(
                      " allowed. To upload multiple docs, zip first then upload. "
                    ),
                  ])
                : _vm.isMultiDocField
                ? _c("v-alert", { attrs: { type: "info", color: "grey" } }, [
                    _c("p", [
                      _vm._v(" Maximum "),
                      _c("strong", [_vm._v("50MB")]),
                      _vm._v(" in size per file. Maximum 10 files. Only "),
                      _c("strong", [_vm._v("image")]),
                      _vm._v(" , "),
                      _c("strong", [_vm._v("text")]),
                      _vm._v(" , "),
                      _c("strong", [_vm._v("zip")]),
                      _vm._v(" or "),
                      _c("strong", [_vm._v("spreadsheet")]),
                      _vm._v(" allowed. "),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.hasDropDownValues
                ? _c("custom-field-drop-down-choices-form", {
                    model: {
                      value: _vm.localField.extraFields.dropDownValues,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.localField.extraFields,
                          "dropDownValues",
                          $$v
                        )
                      },
                      expression: "localField.extraFields.dropDownValues",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isNumberField || _vm.isTextField
        ? _c(
            "div",
            { staticClass: "d-flex mt-6 flex-row justify-space-between" },
            [
              _c("v-text-field", {
                staticClass: "mr-2",
                attrs: {
                  type: "number",
                  rules: _vm.isTextField
                    ? _vm.$validator.rules.optionalNumberBetween(
                        0,
                        _vm.maxSizeTextField(_vm.localField.type)
                      )
                    : [],
                  label: `Minimum ${_vm.isNumberField ? "value" : "length"}`,
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.localField.extraFields.minLengthOrValue,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.localField.extraFields,
                      "minLengthOrValue",
                      _vm._n($$v)
                    )
                  },
                  expression: "localField.extraFields.minLengthOrValue",
                },
              }),
              _c("v-text-field", {
                staticClass: "ml-2",
                attrs: {
                  type: "number",
                  rules: _vm.isTextField
                    ? _vm.$validator.rules.optionalNumberBetween(
                        0,
                        _vm.maxSizeTextField(_vm.localField.type)
                      )
                    : [],
                  label: `Maximum ${_vm.isNumberField ? "value" : "length"}`,
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.localField.extraFields.maxLengthOrValue,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.localField.extraFields,
                      "maxLengthOrValue",
                      _vm._n($$v)
                    )
                  },
                  expression: "localField.extraFields.maxLengthOrValue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "my-6" }),
      _c("action-group", {
        staticClass: "pr-0",
        attrs: {
          "confirm-icon": "upload",
          "confirm-label": "Save",
          "cancel-label": "Nevermind",
          "cancel-color": "error",
        },
        on: {
          cancel: function ($event) {
            return _vm.$emit("close")
          },
          confirm: _vm.submit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }