var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appointment-sections" },
    [
      _c(
        "header",
        { staticClass: "d-flex justify-space-between align-center mb-6" },
        [
          _c("h3", [_vm._v("Appointment Details")]),
          _c(
            "v-btn",
            {
              staticClass: "mobile-icon-btn",
              attrs: { icon: "", "data-testid": "edit-appointment-button" },
              on: {
                click: function ($event) {
                  _vm.showEditDialog = true
                },
              },
            },
            [_c("v-icon", { attrs: { tile: "" } }, [_vm._v("mdi-pencil")])],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "appointment-details dense" },
            [
              _vm._l(_vm.appointmentTableRows, function (row, index) {
                return [
                  !row.isHidden
                    ? [
                        _c("div", { key: index + "-detail" }, [
                          _c("div", { staticClass: "font-weight-bold" }, [
                            _vm._v(" " + _vm._s(row.label) + " "),
                          ]),
                          _c("div", { class: row.tdClass }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.getRowVal(row.value)),
                              },
                            }),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ]
              }),
              _vm.customFieldsToShow.length > 0
                ? _vm._l(
                    Object.values(_vm.customFieldsToShow),
                    function (customField, index) {
                      return _c("div", { key: `custom-field-${index}` }, [
                        _c("div", { staticClass: "font-weight-bold" }, [
                          _vm._v(" " + _vm._s(customField.label) + " "),
                        ]),
                        _c("div", { class: customField.tdClass }, [
                          _c("div", {
                            staticClass: "text-pre-wrap",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getRowVal(customField.value)
                              ),
                            },
                          }),
                        ]),
                      ])
                    }
                  )
                : _vm._e(),
              _vm.areDetailsExpanded
                ? [
                    _c("div", { staticClass: "font-weight-bold" }, [
                      _vm._v("Appointment Notes"),
                    ]),
                    _c("div", {
                      staticClass: "appointment-notes",
                      domProps: {
                        innerHTML: _vm._s(_vm.getRowVal(_vm.appointment.notes)),
                      },
                    }),
                    _c("div", { staticClass: "font-weight-bold pt-4" }, [
                      _vm._v("Confirmation #"),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "copy-content",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              "should-disable-tooltip": "",
                              content: _vm.appointment.confirmationNumber,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.appointment.confirmationNumber) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "font-weight-bold pt-4" }, [
                      _vm._v("Appointment ID"),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "copy-content",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              "should-disable-tooltip": "",
                              content: _vm.appointment.id,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.appointment.id) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              !_vm.areDetailsExpanded
                ? _c(
                    "outline-button",
                    {
                      class: {
                        "": _vm.customFields.length === 0,
                        "mb-4": _vm.customFields.length > 0,
                      },
                      attrs: { small: "", block: "" },
                      on: {
                        click: function ($event) {
                          _vm.areDetailsExpanded = !_vm.areDetailsExpanded
                        },
                      },
                    },
                    [_vm._v(" Show All ")]
                  )
                : _vm._e(),
              _vm.areDetailsExpanded
                ? _c(
                    "outline-button",
                    {
                      staticClass: "my-4",
                      attrs: { small: "", block: "" },
                      on: {
                        click: function ($event) {
                          _vm.areDetailsExpanded = !_vm.areDetailsExpanded
                        },
                      },
                    },
                    [_vm._v(" Collapse ")]
                  )
                : _vm._e(),
              _c("div", { staticClass: "divider mt-6" }),
              _c(
                "v-expansion-panels",
                {
                  staticClass: "details-expansion-panel",
                  attrs: { accordion: "" },
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h3", { staticClass: "mr-1" }, [
                          _vm._v("Asset Details"),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          !_vm.appointment.assetVisit?.id
                            ? _c("span", [_vm._v("No asset linked")])
                            : [
                                _c("div", { staticClass: "font-weight-bold" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.lastEventType ?? "Not Arrived"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", [_vm._v("Form data will go here!")]),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c(
                "v-expansion-panels",
                {
                  staticClass: "details-expansion-panel",
                  attrs: { accordion: "" },
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h3", { staticClass: "mr-1" }, [
                          _vm._v("Appointment Tags"),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("tag-manager", {
                            attrs: {
                              "hide-instructions": "",
                              outlined: "",
                              "rerender-input-on-select": "",
                              value: "",
                              placeholder: "Add a Tag...",
                              "outlined-chips": true,
                              "should-enable-custom-tags": true,
                              multiple: false,
                              "small-chips": "",
                              small: "",
                              "menu-props": {
                                closeOnClick: true,
                                closeOnContentClick: true,
                                maxHeight: 150,
                              },
                              "read-only":
                                !_vm.$rolePermissions
                                  .canUpdateAppointmentTags || _vm.readOnly,
                              allowTagDelete: !_vm.lockTagEditing,
                              "hide-selected-tags": "",
                            },
                            on: { input: _vm.handleTagInput },
                          }),
                          _c("tag-chips-list", {
                            on: { "remove-tag": _vm.removeTag },
                            model: {
                              value: _vm.tags,
                              callback: function ($$v) {
                                _vm.tags = $$v
                              },
                              expression: "tags",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c(
                "v-expansion-panels",
                {
                  staticClass: "details-expansion-panel",
                  attrs: { accordion: "" },
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h3", { staticClass: "mr-1" }, [_vm._v("Carrier")]),
                      ]),
                      _c("v-expansion-panel-content", [
                        _c("div", { staticClass: "font-weight-bold pb-2" }, [
                          _vm._v(_vm._s(_vm.companyName)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center pb-1" },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-account"),
                            ]),
                            _c("div", { staticClass: "d-block" }, [
                              _vm._v(
                                _vm._s(_vm.getUserName(_vm.appointment.user))
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center pb-1" },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-email"),
                            ]),
                            _c("div", { staticClass: "font-weight-light" }, [
                              _vm._v(_vm._s(_vm.appointment.user.email)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center pb-1" },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-phone"),
                            ]),
                            _c("div", { staticClass: "font-weight-light" }, [
                              _vm._v(
                                _vm._s(_vm.appointment.user.phone || "----")
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c(
                "v-expansion-panels",
                {
                  staticClass: "details-expansion-panel",
                  attrs: { accordion: "" },
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h3", { staticClass: "mr-1" }, [
                          _vm._v("Warehouse"),
                        ]),
                      ]),
                      _c("v-expansion-panel-content", [
                        _c("div", { staticClass: "font-weight-bold pb-2" }, [
                          _vm._v(_vm._s(_vm.appointment.dock.warehouse.name)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center pb-1" },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-account"),
                            ]),
                            _c("div", { staticClass: "d-block" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getWarehouseAddress(
                                    _vm.appointment.dock.warehouse
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center pb-1" },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-email"),
                            ]),
                            _c("div", { staticClass: "font-weight-light" }, [
                              _vm._v(
                                _vm._s(_vm.appointment.dock.warehouse.email)
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center pb-1" },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-phone"),
                            ]),
                            _c("div", {
                              staticClass: "font-weight-light",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getRowVal(
                                    _vm.appointment.dock.warehouse.phone
                                  )
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "divider" }),
              _c(
                "v-expansion-panels",
                {
                  staticClass: "details-expansion-panel",
                  attrs: { accordion: "" },
                },
                _vm._l(_vm.gateManagementTriggers, function (trigger) {
                  return _c(
                    "v-expansion-panel",
                    { key: trigger.id },
                    [
                      _c("v-expansion-panel-header", [
                        _c("h3", { staticClass: "mr-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                trigger.flow?.name ||
                                  _vm.novaCore.CustomFormsFeaturesData[
                                    trigger.feature
                                  ].title
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("custom-forms-data", {
                            key: trigger.id,
                            attrs: {
                              trigger: trigger,
                              flat: "",
                              "object-id": _vm.appointment.id,
                              timezone: _vm.warehouse.timezone,
                              "military-time-enabled":
                                _vm.$isMilitaryTimeEnabled(_vm.warehouse),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            2
          )
        : _vm._e(),
      !_vm.appointment && !_vm.loading
        ? _c(
            "v-alert",
            { staticClass: "white--text", attrs: { color: "error" } },
            [_vm._v(" Could not load appointment data ")]
          )
        : _vm._e(),
      _vm.loading
        ? [
            _c(
              "v-card-text",
              [
                _vm._v(" Loading Appointment Data... "),
                _c("v-progress-linear", {
                  attrs: {
                    color: "secondary",
                    indeterminate: "",
                    rounded: "",
                    height: "8",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("edit-appointment-dialog-mobile", {
        attrs: {
          "content-class": "mobile-dialog",
          fullscreen: "",
          transition: "dialog-bottom-transition",
          "external-activator": true,
          "show-dialog": _vm.showEditDialog,
          appointment: _vm.appointment,
        },
        on: {
          "appointment-updated": function ($event) {
            return _vm.$emit("appointment-updated")
          },
          close: function ($event) {
            _vm.showEditDialog = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }