var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: _vm.dialogHeader,
          scrollable: false,
          "loading-message": "Saving...",
        },
        on: { close: _vm.close, confirm: _vm.handleSaveEvent },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            disabled:
                              _vm.$rolePermissions.canCreateAppointment &&
                              !_vm.readOnly,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm.$scopedSlots.activator
                                          ? _vm._t(
                                              "activator",
                                              null,
                                              null,
                                              slotProps
                                            )
                                          : _c(
                                              "primary-button",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.$rolePermissions
                                                          .canCreateAppointment ||
                                                        _vm.readOnly,
                                                      "before-icon":
                                                        _vm.actionIcon,
                                                      large: "",
                                                    },
                                                  },
                                                  "primary-button",
                                                  slotProps.attrs,
                                                  false
                                                ),
                                                slotProps.on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.dialogHeader) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.novaCore.getRoleActionError())),
                          ]),
                        ]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("custom-field-form", {
                    attrs: {
                      fieldTypeOptions: _vm.fieldTypeOptions,
                      field: _vm.field,
                      warehouse: _vm.warehouse,
                    },
                    on: { save: _vm.handleSaveEvent, close: _vm.close },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }