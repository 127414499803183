var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("create-appointment-dialog", {
        attrs: {
          "external-activator": true,
          "show-dialog": _vm.showCreateDialog,
          context: _vm.appointmentContext,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close", "showCreateDialog")
          },
        },
      }),
      _c("feature-flag", {
        attrs: { name: "enable-new-appointment-details" },
        scopedSlots: _vm._u([
          {
            key: "enabled",
            fn: function () {
              return [
                _vm.$selectedEvent
                  ? _c("appointment-details-dialog", {
                      attrs: {
                        "external-activator": true,
                        "show-dialog": _vm.showDetailsDialog,
                        appointment: _vm.$selectedEvent,
                      },
                      on: {
                        close: function ($event) {
                          return _vm.$emit("close", "showDetailsDialog")
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "disabled",
            fn: function () {
              return [
                _vm.$selectedEvent
                  ? _c("appointment-details-dialog-old", {
                      attrs: {
                        "external-activator": true,
                        "show-dialog": _vm.showDetailsDialog,
                        appointment: _vm.$selectedEvent,
                      },
                      on: {
                        close: function ($event) {
                          return _vm.$emit("close", "showDetailsDialog")
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.$selectedEvent
        ? _c("reserve-details-dialog", {
            attrs: {
              "external-activator": true,
              "show-dialog": _vm.showReserveDialog,
              appointment: _vm.$selectedEvent,
            },
            on: {
              close: function ($event) {
                return _vm.$emit("close", "showReserveDialog")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }