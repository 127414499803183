var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-checkbox", {
    staticClass: "mt-0 pt-2 pl-1",
    attrs: { "hide-details": "", label: "All Time", type: "checkbox" },
    on: { change: (value) => _vm.$emit("input", value) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }