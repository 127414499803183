<template>
  <v-textarea
    :class="{ required: required }"
    v-model="val"
    validate-on-blur
    v-bind="[$props, $attrs]">
  </v-textarea>
</template>

<script>
/**
 * Text area with additional error highlighting and styles
 * @displayName Required Text Area
 */
export default {
  name: 'text-area',
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      val: ''
    };
  },
  mounted() {
    this.val = this.value;
  },
  watch: {
    val() {
      /**
       * @model
       */
      this.$emit('input', this.val);
    }
  }
};
</script>
