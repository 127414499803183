var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h4", [
        _vm._v("Select the type of email notification you want to send out:"),
      ]),
      _c(
        "v-list",
        {
          attrs: {
            dense: "",
            id: "email-notifications-list",
            "data-testid": "email-notification-list",
          },
        },
        [
          _vm._l(_vm.novaCore.Notifications, function (item, key) {
            return [
              _c(
                "v-list-item",
                { key: key, staticClass: "pl-0", attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item-action",
                    { staticClass: "my-0 mr-2" },
                    [
                      _c("v-checkbox", {
                        staticClass: "mt-0",
                        attrs: {
                          disabled:
                            !_vm.entitySettings.muteAppointmentNotifications,
                          value: key,
                          ripple: false,
                          color: "primary",
                        },
                        on: { click: _vm.handleMuteNotificationClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.enabledAppointmentNotifications,
                          callback: function ($$v) {
                            _vm.enabledAppointmentNotifications = $$v
                          },
                          expression: "enabledAppointmentNotifications",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { attrs: { dense: "" } },
                    [
                      _c("v-list-item-title", [
                        _c("span", { staticClass: "font-size-xx-small" }, [
                          _vm._v(
                            _vm._s(
                              item.UIRenderOptions &&
                                item.UIRenderOptions.description
                                ? `(${item.UIRenderOptions.description})`
                                : ""
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    { staticClass: "my-0" },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$eventHub.$emit(
                                "set-edit-notification-key",
                                item.key
                              )
                            },
                          },
                        },
                        [_vm._v("mdi-pencil")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }