var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: { header: "Log in as carrier" },
        on: { close: _vm.close, confirm: _vm.submit },
        scopedSlots: _vm._u(
          [
            {
              key: "activator",
              fn: function (slotProps) {
                return [
                  _vm.$scopedSlots.activator
                    ? _vm._t("activator", null, null, slotProps)
                    : _vm._e(),
                ]
              },
            },
            _vm.user
              ? {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "v-card",
                        { staticClass: "mb-6" },
                        [
                          _c("v-card-title", [
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v("Carrier user data"),
                            ]),
                          ]),
                          _c("v-card-text", [
                            _c(
                              "p",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-truck"),
                                ]),
                                _c("strong", [_vm._v("Company:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.user.company?.name ?? "--") +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-account-box"),
                                ]),
                                _c("strong", [_vm._v("Name:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.user.firstName) +
                                    " " +
                                    _vm._s(_vm.user.lastName) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-email"),
                                ]),
                                _c("strong", [_vm._v("Email:")]),
                                _vm._v(" " + _vm._s(_vm.user.email) + " "),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("org-select", {
                        on: { input: (value) => (_vm.orgId = value) },
                      }),
                      _c("warehouse-select", {
                        attrs: { disabled: !_vm.orgId, orgId: _vm.orgId },
                        model: {
                          value: _vm.warehouse,
                          callback: function ($$v) {
                            _vm.warehouse = $$v
                          },
                          expression: "warehouse",
                        },
                      }),
                      _c("v-divider", { staticClass: "my-4" }),
                      _c(
                        "p",
                        { staticClass: "mt-8 text--red text-center" },
                        [
                          _c("v-icon", { attrs: { color: "red", small: "" } }, [
                            _vm._v("mdi-alert"),
                          ]),
                          _vm._v(
                            " Any changes made on the carrier view will reflect to the actual carrier account! "
                          ),
                        ],
                        1
                      ),
                      _vm.novaCore.isInternalUser(_vm.$me)
                        ? _c("action-group", {
                            key: "login-as-carrier",
                            attrs: {
                              "confirm-label": "Log in as Carrier",
                              "disable-confirm": !_vm.warehouse?.id,
                            },
                            on: { cancel: _vm.close, confirm: _vm.submit },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }