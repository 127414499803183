<template>
  <div class="flex-grow-1">
    <v-progress-linear
      v-if="loading"
      indeterminate
      loading
      height="6"
      class="mt-6"></v-progress-linear>
    <form-base v-else>
      <template #form>
        <v-form ref="form">
          <v-container fluid>
            <h3 class="pb-5">Billing contact info</h3>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <text-field
                  :required="true"
                  type="text"
                  v-model="editingBilling.name"
                  :rules="$validator.rules.required('Name')"
                  label="Name"></text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <text-field
                  :required="true"
                  type="email"
                  v-model="editingBilling.email"
                  :rules="[...$validator.rules.required('Email'), ...$validator.rules.email]"
                  label="Email"></text-field>
              </v-col>
            </v-row>
            <h3 class="pb-5 pt-5">Billing info</h3>
            <v-row>
              <v-col>
                <text-field
                  :required="true"
                  type="text"
                  v-model="editingBilling.billingName"
                  :rules="$validator.rules.required('Company Name')"
                  label="Company Name"></text-field>
              </v-col>
              <v-col>
                <text-field
                  :required="isTaxIdRequired"
                  type="text"
                  v-model="editingBilling.taxId"
                  :rules="isTaxIdRequired ? [...$validator.rules.required('Tax ID')] : []"
                  label="Tax ID"></text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="3"
                :class="{ 'pt-2': !manualAddressMode, 'pt-0': manualAddressMode }">
                <country-select v-model="editingAddress.country"></country-select>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="3"
                :class="{ 'pt-4': !manualAddressMode, 'pt-2': manualAddressMode }">
                <phone-number-field
                  required
                  margin-top="-15px"
                  v-model="editingBilling.phone"
                  :default-country="defaultPhoneCountry"
                  :validator="$validator"
                  label="Phone number"></phone-number-field>
              </v-col>
              <v-col class="pt-0">
                <address-autocomplete-field
                  required
                  label="Search address..."
                  ref="searchAddressField"
                  v-if="!manualAddressMode"
                  clear-on-country-change
                  with-manual-override
                  v-model="geocodingData"
                  :disabled="!editingAddress.country"
                  :rules="$validator.rules.required('Address')"
                  :country="editingAddress.country"
                  @filled="fillFullAddress"
                  @manual-override="enterManualAddressMode"></address-autocomplete-field>
                <street-field
                  v-else
                  ref="manualStreetField"
                  @search="enterSearchAddressMode"
                  required
                  v-model="editingAddress.street"
                  :rules="$validator.rules.required('Street Address')" />
              </v-col>
            </v-row>

            <address-display
              label="Billing Address"
              v-if="!manualAddressMode && editingAddress.city"
              :zip="editingAddress.zip"
              :city="editingAddress.city"
              :state="editingAddress.state"
              :street="editingAddress.street" />

            <v-row class="mt-0" v-else-if="manualAddressMode">
              <v-col cols="12" sm="6" md="4">
                <text-field
                  required
                  type="text"
                  v-model="editingAddress.zip"
                  placeholder="85331"
                  :rules="$validator.rules.required('Zip')"
                  label="Zip / Postal Code"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <text-field
                  required
                  type="text"
                  v-model="editingAddress.city"
                  :rules="$validator.rules.required('City')"
                  label="City"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <state-select
                  v-if="editingAddress.country === 'USA' || editingAddress.country === 'CAN'"
                  required
                  :rules="$validator.rules.required('State')"
                  label="State"
                  :country="editingAddress.country"
                  v-model="editingAddress.state"></state-select>
                <text-field
                  v-else
                  required
                  type="text"
                  v-model="editingAddress.state"
                  :rules="$validator.rules.required('State/Province')"
                  label="State"></text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
    </form-base>
    <action-group
      confirm-label="Save Billing Info"
      :hide-cancel="true"
      @confirm="submit"
      :loading="submitting"></action-group>
  </div>
</template>

<script>
import autocompleteAddressMixin from '@/components/mixins/autocompleteAddressMixin';

/**
 * @displayName Billing Details Form
 */
export default {
  name: 'BillingDetailsForm',
  mixins: [autocompleteAddressMixin],
  data() {
    return {
      loading: false,
      billing: null,
      submitting: false,
      originalBillingData: null,
      editingBilling: {
        name: '',
        billingName: '',
        email: '',
        taxId: '',
        phone: ''
      }
    };
  },
  computed: {
    isTaxIdRequired() {
      return ['USA', 'CAN'].includes(this.editingAddress.country);
    }
  },
  methods: {
    /**
     * Submit Form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const payload = {
        name: this.editingBilling.name,
        billing_name: this.editingBilling.billingName,
        tax_id: this.editingBilling.taxId,
        zip_code: this.editingAddress.zip,
        email: this.editingBilling.email,
        address: this.editingAddress.street,
        city: this.editingAddress.city,
        state: this.editingAddress.state,
        country: this.editingAddress.country,
        phone: this.editingBilling.phone,
        customer_type: ['Warehouse']
      };

      this.submitting = true;
      try {
        const entryPoint = this.$route.query.entryPoint;
        const billing = await this.$store.dispatch('Orgs/updateBilling', {
          payload,
          entryPoint
        });
        this.setBillingData(billing);
      } finally {
        this.submitting = false;
      }
    },
    updateDirtyStatus() {
      this.setDirtyStatus(
        JSON.stringify({ ...this.editingBilling, ...this.editingAddress }) !==
          this.originalBillingData
      );
    },
    setBillingData(billing) {
      this.editingBilling = {
        name: billing.name,
        billingName: billing.billing_name,
        taxId: billing.tax_id,
        email: billing.email,
        phone: billing.phone
      };

      this.editingAddress = {
        zip: billing.zip_code,
        street: billing.address,
        city: billing.city,
        state: billing.state,
        country: billing.country
      };

      this.$nextTick(() => {
        this.originalBillingData = JSON.stringify({
          ...this.editingBilling,
          ...this.editingAddress
        });
        this.updateDirtyStatus();
      });
    }
  },
  watch: {
    editingBilling: {
      deep: true,
      handler() {
        this.updateDirtyStatus();
      }
    },
    editingAddress: {
      deep: true,
      handler() {
        this.updateDirtyStatus();
      }
    }
  },
  async mounted() {
    this.loading = true;
    try {
      this.billing = await this.$store.dispatch('Orgs/getBilling');
      this.setBillingData(this.billing);
    } finally {
      this.loading = false;
    }
  }
};
</script>
