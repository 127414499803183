import { utils, writeFile } from 'xlsx';

/**
 * Mixin that adds functions for exporting appointments to excel
 * @mixin
 * @displayName Export Appointments Mixin
 */
export default {
  data() {
    return {
      buildingExcelData: false,
      // If you are not passing rows to the exportToExcel function, you should have this property on your component
      exportableRows: []
    };
  },
  computed: {
    exportableFields() {
      return Object.keys(this.novaCore.ExportableFields);
    },
    warehouse() {
      return null; // Implement on parent.
    }
  },
  methods: {
    buildExcelData(rows) {
      return new this.novaCore.ExportAppointment(
        rows,
        this.$org?.settings || {},
        this.exportableFields ?? Object.keys(this.novaCore.ExportableFields)
      ).convertAppointmentsToExcelData();
    },

    exportToExcel(rows = null, fileName = 'opendock_appointments.xlsx') {
      const exportRows = rows || this.exportableRows;
      this.buildingExcelData = true;
      const data = this.buildExcelData(exportRows);
      const wb = utils.book_new();

      utils.book_append_sheet(wb, utils.json_to_sheet(data), 'Appointments');

      writeFile(wb, fileName);

      this.mixpanel.track(this.mixpanel.events.ACTION.APPOINTMENTS_EXPORTED, {
        'Org Name': this.$org?.name,
        'Org ID': this.$org?.id,
        'Report Fields': this.exportableFields
      });

      this.buildingExcelData = false;
    }
  }
};
