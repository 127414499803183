var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    attrs: { "hide-required-legend": "" },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-stepper",
              {
                staticClass: "warehouse-stepper mx-6",
                attrs: { flat: "" },
                model: {
                  value: _vm.step,
                  callback: function ($$v) {
                    _vm.step = $$v
                  },
                  expression: "step",
                },
              },
              [
                _c(
                  "v-stepper-header",
                  [
                    !_vm.hideTerms
                      ? _c(
                          "v-stepper-step",
                          { attrs: { complete: _vm.step > 1, step: "1" } },
                          [_vm._v(" Conditions ")]
                        )
                      : _vm._e(),
                    !_vm.hideTerms ? _c("v-divider") : _vm._e(),
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.step > 2, step: "2" } },
                      [_vm._v("Warehouse Information")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.step > 3, step: "3" } },
                      [_vm._v("Instructions & Amenities")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-stepper-items",
                  [
                    !_vm.hideTerms
                      ? _c(
                          "v-stepper-content",
                          { staticClass: "px-2 mt-2", attrs: { step: "1" } },
                          [
                            _c("create-warehouse-terms", {
                              ref: "createWarehouseTerms",
                              on: {
                                confirm: _vm.onTermsNext,
                                input: (v) => (_vm.isTermsAccepted = v),
                                cancel: _vm.onCancel,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-stepper-content",
                      { staticClass: "px-2", attrs: { step: "2" } },
                      [
                        _c("create-warehouse-form", {
                          ref: "createWarehouseForm",
                          attrs: {
                            "confirm-label": "Next",
                            "hide-cancel": _vm.hideTerms,
                            "confirm-icon": null,
                          },
                          on: {
                            confirm: _vm.onWarehouseInfoNext,
                            cancel: _vm.onFormPrevious,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "3" } },
                      [
                        _c("create-warehouse-instructions-form", {
                          ref: "createWarehouseInstructionsForm",
                          on: {
                            confirm: _vm.onFormSubmit,
                            cancel: _vm.onFormPrevious,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "disable-confirm":
                  _vm.step === 1 ? !_vm.isTermsAccepted : !_vm.isStepTwoSet,
                "confirm-label": _vm.step === 3 ? "Create Warehouse" : "Next",
                "confirm-icon": null,
                "cancel-icon": null,
              },
              on: {
                confirm: function ($event) {
                  _vm.step === 1 ? _vm.onTermsNext() : _vm.onNext()
                },
                cancel: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "additional-actions",
                  fn: function () {
                    return [
                      _vm.step !== 1
                        ? _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.step--
                                },
                              },
                            },
                            [_vm._v("Previous")]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }