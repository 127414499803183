var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.emails.length < 1
    ? _c(
        "span",
        {
          staticClass: "no-emails",
          attrs: { "data-testid": "carrier-email-ccs-column-none" },
        },
        [_vm._v(" None ")]
      )
    : _c(
        "div",
        { attrs: { "data-testid": "carrier-email-ccs-column-emails" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                bottom: "",
                "open-on-click": "",
                disabled: _vm.emails.length < 2,
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c("span", _vm._g(_vm._b({}, "span", attrs, false), on), [
                        _vm._v(" " + _vm._s(_vm.emails[0]) + " "),
                        _vm.emails.length > 1
                          ? _c("span", [
                              _vm._v(
                                "(+ " + _vm._s(_vm.emails.length - 1) + " more)"
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _vm.emails.length > 1
                ? _c(
                    "div",
                    {
                      attrs: {
                        "data-testid": "carrier-email-ccs-column-tooltip",
                      },
                    },
                    _vm._l(_vm.emails, function (email) {
                      return _c("p", { key: email, staticClass: "mb-1" }, [
                        _vm._v(_vm._s(email)),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]
          ),
          _vm.emails.length > 0
            ? _c(
                "copy-content",
                {
                  attrs: {
                    content: _vm.emails[0],
                    label: "Click to copy carrier email",
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        "x-small": "",
                        "data-testid": "carrier-email-ccs-column-copy",
                      },
                    },
                    [_vm._v(" mdi-content-copy ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }