var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-overlay",
    { staticClass: "text-center align-center justify-center" },
    [
      _c("v-progress-circular", {
        attrs: { color: "primary", indeterminate: "", size: "42" },
      }),
      _c("p", { staticClass: "text-body-1 mt-4 font-weight-bold" }, [
        _vm._v("Loading Yard data..."),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }