<template>
  <v-sheet class="custom-calendar-container custom-week-calendar-container fill-height">
    <v-calendar
      class="calendar"
      type="week"
      ref="calendar"
      :class="calendarClasses"
      :weekdays="weekdaysToShow"
      v-model="selectedDate"
      :events="events"
      :interval-count="intervalsToShow"
      :interval-minutes="$intervalMinutes"
      :interval-height="$zoomLevel"
      :first-interval="intervals.first"
      :interval-format="formatInterval"
      color="primary"
      event-overlap-mode="column"
      :short-intervals="true"
      :event-color="getEventClasses"
      @contextmenu:event="setEventContextMenuId"
      @mouseup.native="cancelDrag"
      @mousedown:event="startDrag"
      @mousedown:time="startTime"
      @mousemove:time="mouseMove"
      @mouseup:time="endDrag"
      @mouseleave.native="cancelDrag"
      @click:day="handleDayClick"
      @click:time="handleCalendarClick">
      <template v-slot:day-label-header="dateTime">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="{ 'primary--text': dateTime.present }"
              class="d-flex flex-column"
              v-bind="attrs"
              v-on="on">
              <span class="text-uppercase weekday-label">{{ weekdays[dateTime.weekday] }}</span>
              <span>{{ dateTime.day }}</span>
            </div>
          </template>
          <span>{{ totalAppointmentsByDayString(dateTime) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:day-body="{ date, week, present }">
        <div v-if="present" class="v-current-time first" :style="{ top: nowY }"></div>
      </template>

      <template v-slot:interval="{ date, time, weekday }">
        <div
          class="slot-wrapper"
          :data-weekday="weekday"
          :data-date="date"
          :data-time="time.replace(/^0/, '')"
          v-if="mounted">
          <div
            v-if="
              isDateClosed(date, $selectedWarehouse.schedule) ||
              isIntervalDisabled(date, time, firstDockId)
            "
            class="disabled-time-interval overlay"></div>
        </div>
      </template>

      <template v-slot:event="{ event }">
        <div
          :key="`${renderKey}-${event.id}`"
          class="event-content"
          :data-time="event.startTime24.replace(/^0/, '')"
          :data-weekday="event.weekday"
          :data-date="event.startDate"
          :data-dock-id="event.dockId">
          <event-with-tooltip
            :event="event"
            :is-light-theme="$useLightGridTheme"
            :enableMilitaryTime="$isMilitaryTimeEnabled($selectedWarehouse)"
            :use-new-grid-tiles="useNewGridTilesSetting"
            @click="handleEventClick"
            @drag-event="dragEvent"
            @cancel-reserve="cancelReserve"
            :disable-tooltip="isTooltipDisabled(event)"></event-with-tooltip>
        </div>
        <div
          v-if="isStartingStatus(event.status) && $rolePermissions.canUpdateAppointment"
          class="v-event-drag-bottom"
          @mousedown.stop="extendBottom(event)"></div>
        <event-context-menu
          @close="eventContextMenuId = null"
          v-if="
            eventContextMenuId === event.id &&
            event.status !== novaCore.AppointmentStatus.Cancelled &&
            event.type !== novaCore.AppointmentType.Reserve
          "
          :nativeEvent="eventContextMenuEvent"
          :appointment="event"></event-context-menu>
      </template>
    </v-calendar>

    <load-type-re-select-dialog
      @close="nevermindLoadTypeDialog"
      v-if="eventToUpdate && originalEvent && eventWarehouse"
      :original-event="originalEvent"
      :event="eventToUpdate"
      :event-warehouse="eventWarehouse"
      :event-dock="eventDock"
      :original-event-dock="
        $docksKeyedById[originalEvent.category] ?? $docksKeyedById[originalEvent.dockId]
      "
      :show-dialog="shouldShowLoadTypeReselectDialog"></load-type-re-select-dialog>

    <calendar-dialogs
      :appointment-context="appointmentContext"
      :show-create-dialog="showCreateDialog"
      :show-details-dialog="showDetailsDialog"
      :show-reserve-dialog="showReserveDialog"
      @close="handleDialogClose"></calendar-dialogs>
    <zoom-buttons></zoom-buttons>
  </v-sheet>
</template>

<script>
import calendarMixin from '@/modules/calendar/mixins/calendarMixin';

/**
 * Calendar Week View
 * @displayName Calendar Week View
 */
export default {
  mixins: [calendarMixin],
  computed: {
    calendarClasses() {
      let classes = !this.$selectedWarehouse.id || !this.$selectedDocks.length ? 'disabled' : '';
      classes += this.$selectedDocks.length === 1 ? 'clickable' : '';
      if (this.isDrag) {
        classes += ' is-dragging';
      }
      const firstIntervalMinutes = this.firstIntervalClock.split(':')[1];
      classes += ` start-minute__${firstIntervalMinutes} calendar-interval__${this.$intervalMinutes}`;
      return classes;
    },
    firstDockId() {
      return this.$selectedDocks?.[0]?.id;
    }
  },
  data() {
    return {
      mounted: false
    };
  },
  methods: {
    /**
     * Handle day click in calendar (any day(date) header in week view)
     * @param dayTimeObject
     */
    handleDayClick(dayTimeObject) {
      this.viewDay({ date: dayTimeObject.date });
    },
    /**
     * Handle time click in calendar (any timeslot on calendar)
     * @param dayTimeObject
     */
    handleCalendarClick(dayTimeObject, event) {
      if (this.isDrag) {
        return false;
      }
      if (!this.$selectedWarehouse?.id) {
        this.$eventHub.$emit('shake-element', 'warehouse-select');
        return;
      }
      if (!this.dragEvent && !this.isExtending) {
        if (this.$selectedDocks.length === 1) {
          if (event.target.classList.contains('disabled-time-interval')) {
            return false;
          }
          const dock = this.$selectedDocks[0];
          this.showCreateAppointmentDialog(dayTimeObject, dock);
        } else if (this.$selectedDocks.length > 1) {
          this.viewDay({ date: dayTimeObject.date });
        } else {
          this.$eventHub.$emit('shake-element', 'dock-select');
        }
      }
    },
    totalAppointmentsByDay(dateTime) {
      if (dateTime?.date) {
        return (
          this.events?.filter(event => {
            const notReserve = !this.novaCore.isReserve(event);
            const notCanceled = event?.status !== this.novaCore.AppointmentStatus.Cancelled;
            const dayAppointment = event.start.startsWith(dateTime.date);

            return notReserve && notCanceled && dayAppointment;
          }).length || 0
        );
      }

      return 0;
    },
    totalAppointmentsByDayString(dateTime) {
      const total = this.totalAppointmentsByDay(dateTime);
      return `${total} appointment`.concat(total === 1 ? '' : 's');
    },
    isIntervalDisabled(date, time, dockId) {
      if (!(dockId || this.selectedWarehouseId)) {
        return false;
      }
      let isDisabled = false;

      if (this.$selectedDocks?.length > 1) {
        if (this.$selectedWarehouse?.schedule) {
          const start = momentjs.tz(
            `${date} ${time}`,
            `${this.novaCore.DateTimeFormats.DateDashed} ${this.novaCore.DateTimeFormats.Extended24HrTimeLeadingZeroHour}`,
            this.$selectedWarehouse.timezone
          );
          const end = start.clone().add(this.$intervalMinutes, 'minutes');
          const slot = { start: start.toDate(), end: end.toDate() };
          const weekday = start.clone().format('dddd').toLowerCase();
          const scheduleClockSpans = this.$selectedWarehouse.schedule?.[weekday];
          if (scheduleClockSpans?.length) {
            const scheduleIntervals = scheduleClockSpans.map(clockSpan => {
              return {
                start: momentjs
                  .tz(
                    `${date} ${clockSpan.start}`,
                    `${this.novaCore.DateTimeFormats.DateDashed} ${this.novaCore.DateTimeFormats.Extended24HrTime}`,
                    this.$selectedWarehouse.timezone
                  )
                  .toDate(),
                end: momentjs
                  .tz(
                    `${date} ${clockSpan.end}`,
                    `${this.novaCore.DateTimeFormats.DateDashed} ${this.novaCore.DateTimeFormats.Extended24HrTime}`,
                    this.$selectedWarehouse.timezone
                  )
                  .toDate()
              };
            });

            if (!this.novaCore.isAnySubInterval(slot, scheduleIntervals)) {
              isDisabled = true;
            }
          }
        }
      } else {
        const dockHoops = this.$selectedWarehouseHoops?.[dockId];
        if (dockHoops) {
          const start = momentjs.tz(`${date} ${time}`, this.$selectedWarehouse.timezone);
          const end = start.clone().add(this.$intervalMinutes, 'minutes');
          const slot = { start: start.toDate(), end: end.toDate() };
          if (!this.novaCore.isAnySubInterval(slot, dockHoops)) {
            return true;
          }
        }
      }

      return isDisabled;
    }
  },
  async mounted() {
    this.attachZoomControls();
    if (!this.$selectedWarehouse || !this.$selectedDocks) {
      this.util.addCursorTextListener(
        this.$refs.calendar.$el,
        'No Warehouse/Docks selected',
        'calendarCursorText'
      );
    }

    await this.updateTime();
    await this.scrollToTime();

    this.$store.commit('Calendar/setDisabledDateTimesByWarehouse', {});
    this.mounted = true;
    this.$eventHub.$on('update-Dock', this.handleSocketDockUpdate);
    this.$eventHub.$on('update-Warehouse', this.handleSocketWarehouseUpdate);

    this.$eventHub.$on('update-Trigger', this.handleSocketTriggerUpdate);
    this.$eventHub.$on('create-Trigger', this.handleSocketTriggerUpdate);
  },
  beforeDestroy() {
    this.util.removeCursorTextListener(this.$refs.calendar.$el, 'calendarCursorText');
    this.$eventHub.$off('update-Dock', this.handleSocketDockUpdate);
    this.$eventHub.$off('update-Warehouse', this.handleSocketWarehouseUpdate);

    this.$eventHub.$off('update-Trigger');
    this.$eventHub.$off('create-Trigger');
  },
  watch: {
    $selectedDate() {
      if (this.mounted && this.$selectedDocks.length === 1) {
        this.getSelectedWarehouseHoops();
      }
    },
    async $selectedWarehouse(newWarehouse, oldWarehouse) {
      this.shouldShowCapacityDockOpts = {};
      if (newWarehouse?.id !== oldWarehouse?.id) {
        await this.getSelectedWarehouseHoops();
      }
    }
  }
};
</script>

<style>
div.v-calendar-daily__day-interval:hover {
  background-color: rgba(235, 235, 235, 90) !important;
}
</style>
