var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "v-card",
        { attrs: { rounded: "", elevation: "8" } },
        [
          _c(
            "v-card-title",
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v("Next Available Appointment Report"),
              ]),
              _c("help-icon-tooltip", [
                _vm._v(
                  " This report shorts the next available appointment from today for each dock and load type. "
                ),
                _c("br"),
                _c("br"),
                _vm._v(" You can toggle dock or load type by clicking the "),
                _c("b", [_vm._v("group")]),
                _vm._v(" header. "),
                _c("br"),
                _c("br"),
                _vm._v(
                  "This is a new experimental feature and we will be iterating on it for the next release, so please share feedback with the Opendock customer support team. "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-1" },
            [
              _c("v-data-table", {
                staticClass: "elevation-4",
                attrs: {
                  items: _vm.loadTypeMetrics,
                  headers: _vm.headers,
                  "show-group-by": "",
                  "group-by": "dockName",
                  "sort-by": "start",
                  "hide-default-header": "",
                  "hide-default-footer": "",
                  "disable-pagination": "",
                  dense: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ props, on }) {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                { attrs: { colspan: props.headers.length } },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "my-3 text-right text--black",
                                    },
                                    [
                                      _vm._v(
                                        " Grouped by " +
                                          _vm._s(
                                            _vm.getGroupByLabel(
                                              props.options.groupBy
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " Soonest availability: " +
                                          _vm._s(
                                            _vm.getEarlyDate(
                                              _vm.loadTypeMetrics
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "tr",
                              [
                                _vm._l(props.headers, function (header) {
                                  return [
                                    _c(
                                      "th",
                                      { key: header.value },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(header.text)),
                                        ]),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return on.sort(header.value)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  title: `Sort by ${header.text}`,
                                                },
                                              },
                                              [_vm._v("mdi-arrow-down")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return on.group(header.value)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  title: `Group by ${header.text}`,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-format-list-group-plus "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "group.header",
                      fn: function ({
                        group,
                        groupBy,
                        headers,
                        toggle,
                        isOpen,
                        items,
                      }) {
                        return [
                          _c(
                            "th",
                            { attrs: { colspan: headers.length - 1 } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "" }, on: { click: toggle } },
                                [
                                  _vm._v(
                                    _vm._s(isOpen ? "mdi-minus" : "mdi-plus")
                                  ),
                                ]
                              ),
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getGroupByLabel(groupBy)) +
                                    " "
                                ),
                              ]),
                              _vm._v(" : " + _vm._s(group) + " "),
                            ],
                            1
                          ),
                          _c("th", [
                            _vm.getGroupByLabel(groupBy) !== "Start"
                              ? _c("span", [
                                  _vm._v(
                                    " Soonest: " +
                                      _vm._s(_vm.getEarlyDate(items)) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1293268863
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _c("v-skeleton-loader", {
        staticClass: "mx-auto",
        attrs: { type: "card" },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }