<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Transfer carrier appointments"
    :scrollable="false"
    loading-message="Processing appointments"
    @close="close">
    <template v-slot:activator="slotProps">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <template v-slot:body>
      <transfer-appointment-form
        @close="close"
        @confirm="confirm"
        :cancel-label="cancelLabel"
        :cancel-color="cancelColor"
        :cancel-icon="cancelIcon"
        :source-user="sourceUser"
        ref="transferAppointmentForm">
      </transfer-appointment-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    sourceUser: {
      type: Object,
      required: true
    },
    cancelLabel: {
      type: String,
      required: false
    },
    cancelIcon: {
      type: String,
      required: false
    },
    cancelColor: {
      type: String,
      required: false
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.close();
    }
  }
};
</script>
