<template>
  <v-card v-if="ready" class="elevation-0">
    <v-card-text class="pa-1">
      <bar-chart
        title="Appointments by Time of Day"
        :horizontal="true"
        :colors="colors"
        :data="chartData"></bar-chart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
</template>

<script>
import insightsMixin from '@/components/mixins/insightsMixin';

/**
 * Appointments By Time of Day Bar Chart
 * @displayName Appointments By Time of Day Bar Chart
 */
export default {
  mixins: [insightsMixin],
  data() {
    return {
      apiEndpoint: 'time-of-day'
    };
  },
  methods: {
    formatXAxis(data) {
      const tz = null;
      if (data.length === 1) {
        data = `0${data}`;
      }
      return this.novaCore.formatDateTimeWithMilitarySupport(
        data,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
    }
  }
};
</script>
