<template>
  <section>
    <div v-if="flat">
      <custom-forms-data-fields
        :custom-form-data="customFormData"
        :trigger-id="trigger.id"></custom-forms-data-fields>
      <span v-if="!hasCustomFormData">Not Confirmed</span>
    </div>

    <v-expansion-panels accordion :disabled="!hasCustomFormData" v-else>
      <v-expansion-panel
        @click="handleExpand()"
        :data-testid="`custom-forms-data-${trigger.feature}`">
        <v-expansion-panel-header>
          <span :inner-html.prop="headerText"></span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <custom-forms-data-fields
            :prepended-data="prependedData"
            :custom-form-data="customFormData"
            :trigger-id="trigger.id"
            class="mt-5"></custom-forms-data-fields>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
import customFormsDataMixin from '@/modules/custom_forms/mixins/customFormsDataMixin';

export default {
  mixins: [customFormsDataMixin],
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    prependedData: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  computed: {
    dateOfSubmission() {
      if (this.hasCustomFormData) {
        const sortedDates = this.customFormData
          .map(c => c.lastChangedDateTime)
          .sort((a, b) => -a.localeCompare(b));

        return sortedDates[0];
      }

      return new Date().toISOString();
    },
    headerText() {
      return this.hasCustomFormData
        ? `Last updated at ${this.getFormattedDate(this.dateOfSubmission)}`
        : `No updates`;
    },
    elementId() {
      return `custom_forms_element_${this.trigger.id}`;
    }
  },
  methods: {
    handleExpand() {
      setTimeout(() => {
        const element = document.getElementById(this.elementId);
        if (element) {
          element.scrollIntoView();
        }
      }, 500);
    },
    handleSubspaceEvent(payload) {
      if (payload.triggerId === this.trigger.id) {
        this.getCustomFormData();
      }
    }
  },
  async mounted() {
    await this.getCustomFormData();
    this.$eventHub.$on('customformdata-updated', this.getCustomFormData);

    // Subspace events
    this.$eventHub.$on(`update-CustomFormData`, this.handleSubspaceEvent);
    this.$eventHub.$on(`create-CustomFormData`, this.handleSubspaceEvent);
  },
  beforeDestroy() {
    this.$eventHub.$off('customformdata-updated');
    this.$eventHub.$off(`update-CustomFormData`, this.handleSubspaceEvent);
    this.$eventHub.$off(`create-CustomFormData`, this.handleSubspaceEvent);
  },
  watch: {
    dateOfSubmission(newVal) {
      this.$emit('custom-forms-data-updated', newVal);
    }
  }
};
</script>
