var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "mobile-device", attrs: { id: "mobile-header-main" } },
    [
      _vm.$me
        ? _c("v-app-bar-nav-icon", {
            staticClass: "white--text",
            on: {
              click: [
                function ($event) {
                  _vm.showNavDialog = true
                },
                function ($event) {
                  $event.stopPropagation()
                },
              ],
            },
          })
        : _vm._e(),
      _c("nav-dialog-mobile", {
        attrs: {
          fullscreen: "",
          "card-classes": ["mobile-blue-bg"],
          transition: "dialog-bottom-transition",
          "external-activator": "",
          "show-dialog": _vm.showNavDialog,
        },
        on: {
          close: function ($event) {
            _vm.showNavDialog = false
          },
        },
      }),
      _vm.$route.meta.title === "Appointments" &&
      _vm.$route.meta.title === _vm.$mobileAttributes.pageTitle
        ? [
            _c(
              "v-btn",
              {
                staticClass: "btn-plain",
                attrs: {
                  plain: "",
                  "data-testid": "warehouse-select-trigger-header",
                },
                on: {
                  click: function ($event) {
                    _vm.showWarehouseDialog = true
                  },
                },
              },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1 mt-1 white--text",
                    attrs: { small: "" },
                  },
                  [_vm._v("mdi-map-marker")]
                ),
                _c("span", [_vm._v(_vm._s(_vm.selectedWarehouseName))]),
                _c(
                  "v-icon",
                  { staticClass: "mt-1 white--text", attrs: { small: "" } },
                  [_vm._v("mdi-menu-down")]
                ),
              ],
              1
            ),
          ]
        : _c(
            "strong",
            {
              staticClass: "white--text",
              attrs: { "data-testid": "mobile-page-title" },
            },
            [_vm._v(_vm._s(_vm.$mobileAttributes.pageTitle))]
          ),
      _c("mobile-opendock-icon"),
      _vm.$appLoaded
        ? _c("warehouse-select-dialog-mobile", {
            attrs: {
              "card-classes": ["mobile-blue-bg"],
              "external-activator": "",
              "show-dialog": _vm.showWarehouseDialog,
            },
            on: {
              close: function ($event) {
                _vm.showWarehouseDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }