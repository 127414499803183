<template>
  <edit-warehouse-form
    :read-only="$isWarehouseReadOnly(warehouse.id, warehouse.orgId)"
    :warehouse="warehouse"></edit-warehouse-form>
</template>

<script>
export default {
  props: {
    warehouse: {
      type: Object,
      required: true
    }
  }
};
</script>
