var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "mt-6",
            attrs: { indeterminate: "", loading: "", height: "6" },
          })
        : _c("form-base", {
            scopedSlots: _vm._u([
              {
                key: "form",
                fn: function () {
                  return [
                    _c(
                      "v-form",
                      { ref: "form" },
                      [
                        _c(
                          "v-container",
                          { attrs: { fluid: "" } },
                          [
                            _c("h3", { staticClass: "pb-5" }, [
                              _vm._v("Billing contact info"),
                            ]),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12", md: "6" } },
                                  [
                                    _c("text-field", {
                                      attrs: {
                                        required: true,
                                        type: "text",
                                        rules:
                                          _vm.$validator.rules.required("Name"),
                                        label: "Name",
                                      },
                                      model: {
                                        value: _vm.editingBilling.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingBilling,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression: "editingBilling.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12", md: "6" } },
                                  [
                                    _c("text-field", {
                                      attrs: {
                                        required: true,
                                        type: "email",
                                        rules: [
                                          ..._vm.$validator.rules.required(
                                            "Email"
                                          ),
                                          ..._vm.$validator.rules.email,
                                        ],
                                        label: "Email",
                                      },
                                      model: {
                                        value: _vm.editingBilling.email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingBilling,
                                            "email",
                                            $$v
                                          )
                                        },
                                        expression: "editingBilling.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("h3", { staticClass: "pb-5 pt-5" }, [
                              _vm._v("Billing info"),
                            ]),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("text-field", {
                                      attrs: {
                                        required: true,
                                        type: "text",
                                        rules:
                                          _vm.$validator.rules.required(
                                            "Company Name"
                                          ),
                                        label: "Company Name",
                                      },
                                      model: {
                                        value: _vm.editingBilling.billingName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingBilling,
                                            "billingName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "editingBilling.billingName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  [
                                    _c("text-field", {
                                      attrs: {
                                        required: _vm.isTaxIdRequired,
                                        type: "text",
                                        rules: _vm.isTaxIdRequired
                                          ? [
                                              ..._vm.$validator.rules.required(
                                                "Tax ID"
                                              ),
                                            ]
                                          : [],
                                        label: "Tax ID",
                                      },
                                      model: {
                                        value: _vm.editingBilling.taxId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingBilling,
                                            "taxId",
                                            $$v
                                          )
                                        },
                                        expression: "editingBilling.taxId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    class: {
                                      "pt-2": !_vm.manualAddressMode,
                                      "pt-0": _vm.manualAddressMode,
                                    },
                                    attrs: { cols: "12", sm: "4", md: "3" },
                                  },
                                  [
                                    _c("country-select", {
                                      model: {
                                        value: _vm.editingAddress.country,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingAddress,
                                            "country",
                                            $$v
                                          )
                                        },
                                        expression: "editingAddress.country",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    class: {
                                      "pt-4": !_vm.manualAddressMode,
                                      "pt-2": _vm.manualAddressMode,
                                    },
                                    attrs: { cols: "12", sm: "4", md: "3" },
                                  },
                                  [
                                    _c("phone-number-field", {
                                      attrs: {
                                        required: "",
                                        "margin-top": "-15px",
                                        "default-country":
                                          _vm.defaultPhoneCountry,
                                        validator: _vm.$validator,
                                        label: "Phone number",
                                      },
                                      model: {
                                        value: _vm.editingBilling.phone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingBilling,
                                            "phone",
                                            $$v
                                          )
                                        },
                                        expression: "editingBilling.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pt-0" },
                                  [
                                    !_vm.manualAddressMode
                                      ? _c("address-autocomplete-field", {
                                          ref: "searchAddressField",
                                          attrs: {
                                            required: "",
                                            label: "Search address...",
                                            "clear-on-country-change": "",
                                            "with-manual-override": "",
                                            disabled:
                                              !_vm.editingAddress.country,
                                            rules:
                                              _vm.$validator.rules.required(
                                                "Address"
                                              ),
                                            country: _vm.editingAddress.country,
                                          },
                                          on: {
                                            filled: _vm.fillFullAddress,
                                            "manual-override":
                                              _vm.enterManualAddressMode,
                                          },
                                          model: {
                                            value: _vm.geocodingData,
                                            callback: function ($$v) {
                                              _vm.geocodingData = $$v
                                            },
                                            expression: "geocodingData",
                                          },
                                        })
                                      : _c("street-field", {
                                          ref: "manualStreetField",
                                          attrs: {
                                            required: "",
                                            rules:
                                              _vm.$validator.rules.required(
                                                "Street Address"
                                              ),
                                          },
                                          on: {
                                            search: _vm.enterSearchAddressMode,
                                          },
                                          model: {
                                            value: _vm.editingAddress.street,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editingAddress,
                                                "street",
                                                $$v
                                              )
                                            },
                                            expression: "editingAddress.street",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            !_vm.manualAddressMode && _vm.editingAddress.city
                              ? _c("address-display", {
                                  attrs: {
                                    label: "Billing Address",
                                    zip: _vm.editingAddress.zip,
                                    city: _vm.editingAddress.city,
                                    state: _vm.editingAddress.state,
                                    street: _vm.editingAddress.street,
                                  },
                                })
                              : _vm.manualAddressMode
                              ? _c(
                                  "v-row",
                                  { staticClass: "mt-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" },
                                      },
                                      [
                                        _c("text-field", {
                                          attrs: {
                                            required: "",
                                            type: "text",
                                            placeholder: "85331",
                                            rules:
                                              _vm.$validator.rules.required(
                                                "Zip"
                                              ),
                                            label: "Zip / Postal Code",
                                          },
                                          model: {
                                            value: _vm.editingAddress.zip,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editingAddress,
                                                "zip",
                                                $$v
                                              )
                                            },
                                            expression: "editingAddress.zip",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" },
                                      },
                                      [
                                        _c("text-field", {
                                          attrs: {
                                            required: "",
                                            type: "text",
                                            rules:
                                              _vm.$validator.rules.required(
                                                "City"
                                              ),
                                            label: "City",
                                          },
                                          model: {
                                            value: _vm.editingAddress.city,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editingAddress,
                                                "city",
                                                $$v
                                              )
                                            },
                                            expression: "editingAddress.city",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" },
                                      },
                                      [
                                        _vm.editingAddress.country === "USA" ||
                                        _vm.editingAddress.country === "CAN"
                                          ? _c("state-select", {
                                              attrs: {
                                                required: "",
                                                rules:
                                                  _vm.$validator.rules.required(
                                                    "State"
                                                  ),
                                                label: "State",
                                                country:
                                                  _vm.editingAddress.country,
                                              },
                                              model: {
                                                value: _vm.editingAddress.state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editingAddress,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editingAddress.state",
                                              },
                                            })
                                          : _c("text-field", {
                                              attrs: {
                                                required: "",
                                                type: "text",
                                                rules:
                                                  _vm.$validator.rules.required(
                                                    "State/Province"
                                                  ),
                                                label: "State",
                                              },
                                              model: {
                                                value: _vm.editingAddress.state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editingAddress,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editingAddress.state",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
      _c("action-group", {
        attrs: {
          "confirm-label": "Save Billing Info",
          "hide-cancel": true,
          loading: _vm.submitting,
        },
        on: { confirm: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }