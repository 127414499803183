<template>
  <div class="mb-4 full-width">
    <h3 v-if="label" :class="disabled ? 'text--disabled' : ''" class="mr-4">{{ label }}:</h3>
    <v-autocomplete
      v-bind="[$attrs, $props]"
      :required="required"
      :rules="rules"
      class="pt-0"
      hide-details="auto"
      @input="updateValue"
      :search-input.sync="filters.searchStr"
      :value="value"
      :items="carriers"
      item-value="id"
      no-filter
      ref="carrierSelect"
      :loading="isLoading"
      return-object
      :disabled="disabled">
      <template v-slot:item="{ item }">
        <template>
          <div class="full-width">
            <v-list-item color="primary" :input-value="isCarrierFavorite(item)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ getCarrierName(item) }} - {{ item.company.name }} - {{ item.email }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  SCAC: {{ item.company.scac }}
                  <span v-if="getCarrierEmailCCs(item).length > 0">
                    <v-tooltip bottom open-on-click>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" class="ml-3">
                          <v-icon x-small class="mr-1">mdi-account-multiple</v-icon>
                          <strong>+{{ getCarrierEmailCCs(item).length }} email subscribers</strong>
                        </span>
                      </template>
                      <div class="my-2">
                        <p class="mb-1" v-for="email in getCarrierEmailCCs(item)" :key="email">
                          {{ email }}
                        </p>
                      </div>
                    </v-tooltip>
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon v-if="isCarrierFavorite(item)">
                <v-icon color="primary">mdi-star</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider
              v-if="carriers.indexOf(item) < carriers.length - 1"
              :key="carriers.indexOf(item)"></v-divider>
          </div>
        </template>
      </template>

      <template v-slot:selection="{ item }">
        <span>{{ getSelectedCarrierText(item, 15) }}</span>
      </template>

      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            {{
              filters.searchStr && !carriers.length
                ? 'No matching carrier contacts found...'
                : placeholder
            }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <template #label v-if="required">
        {{ placeholder }}
        <span class="red--text"><strong>*</strong></span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import carrierSelectMixin from '@/components/mixins/carrierSelectMixin';

export default {
  name: 'carrier-select-mobile',
  mixins: [selectMixin, dataListMixin, carrierSelectMixin],
  props: {
    value: {
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    warehouse: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      carriers: [],
      filters: {
        searchStr: null
      },
      searchFields: ['firstName', 'lastName', 'email', 'company.name'],
      isLoading: false,
      sortBy: ['firstName'],
      placeholder: 'Start typing to find a carrier contact'
    };
  },
  methods: {
    async updateValue(carrier) {
      await this.$emit('input', carrier);
    }
  },
  async mounted() {
    if (this.value?.id) {
      this.carriers.push(this.value);
    } else {
      await this.getData();
    }
  }
};
</script>
