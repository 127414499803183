<template>
  <div>
    <template v-if="readOnly">
      <v-img class="circle" :src="fileUrl" height="80" width="80"></v-img>
    </template>
    <template v-else>
      <p v-if="fileTypeText">
        <small>{{ fileTypeText }}</small>
      </p>
      <v-file-input
        :key="renderKey"
        ref="fileinput"
        class="doc-upload-component"
        placeholder="Upload Document"
        :required="required"
        type="file"
        single-line
        hide-input
        hide-details="auto"
        prepend-icon=""
        v-model="selectedFile"
        @change="onFileChanged"
        v-on="$listeners"
        v-bind="[$props, $attrs]">
        <template v-slot:append-outer>
          <outline-button
            v-if="!fileUrl"
            :loading="isSelectingFile"
            @click="fileUploadClick"
            before-icon="cloud-upload">
            Upload File
            <span v-if="required" class="red--text ml-1"><strong>*</strong></span>
          </outline-button>
          <template v-else>
            <v-btn
              :loading="isSelectingFile"
              @click="fileUploadClick"
              height="100px"
              :class="cropShape"
              class="is-relative doc-upload-button">
              <v-img v-if="isImageFile" contain height="100px" width="100px" :src="fileUrl"></v-img>
              <v-icon v-else x-large color="secondary">mdi-file</v-icon>
              <v-btn
                icon
                x-small
                class="doc-close-button"
                :class="{ 'is-image': isImageFile }"
                @click.stop="deleteDoc">
                <v-icon x-small>mdi-close</v-icon>
              </v-btn>
            </v-btn>
            <div class="font-italic text-subtitle-2">{{ fileName }}</div>
          </template>
          <span class="errors v-messages mt-2">{{ fieldError }}</span>
        </template>
      </v-file-input>
      <v-dialog
        v-if="tempFileBase64 && isImageFile && allowCropping"
        :value="!!tempFileBase64"
        persistent
        width="700"
        @close="tempFileBase64 = null">
        <v-card width="700" style="max-height: none !important">
          <v-card-title>Adjust Image Preview</v-card-title>
          <v-card-text>
            <template v-if="!isSelectingFile">
              <div v-if="cropperLoading">
                <v-progress-linear indeterminate></v-progress-linear>
              </div>
              <div>
                <p v-if="!cropperLoading">
                  {{ modalHeaderText }}
                </p>
                <cropper
                  ref="cropper"
                  :src="tempFileBase64"
                  :debounce="false"
                  @ready="cropperLoading = false"
                  background-class="cropper-background"
                  :stencil-component="`${cropShape}-stencil`"
                  foreground-class="cropper-foreground"
                  :stencil-props="{
                    aspectRatio,
                    handlers: {},
                    movable: true,
                    resizeable: true,
                    stencilSize: {
                      width: 100,
                      height: 100
                    }
                  }"
                  default-boundaries="fill"
                  fill-color="#FFFFFF"
                  image-restriction="none"
                  :resize-image="{
                    wheel: false,
                    touch: true,
                    adjustStencil: true
                  }"
                  :transitions="false"
                  @change="change"></cropper>
                <v-slider
                  class="mt-1"
                  :disabled="cropperLoading"
                  :value="zoom.input"
                  color="secondary"
                  hide-details="auto"
                  track-color="secondary"
                  step="0.01"
                  :max="0.99"
                  min="-5.99"
                  append-icon="mdi-magnify-plus-outline"
                  prepend-icon="mdi-magnify-minus-outline"
                  @input="onZoom"></v-slider>
              </div>
              <div class="d-flex align-center justify-center">
                <preview
                  class="upload-preview"
                  :class="cropShape"
                  v-if="showCropResult && cropResult && !cropperLoading"
                  :width="120"
                  :height="120"
                  :image="cropResult.image"
                  :coordinates="cropResult.coordinates" />
              </div>
            </template>

            <template v-else>
              <v-progress-linear indeterminate height="6" class="mt-12"></v-progress-linear>
              <h4 class="text-center mt-4">Uploading Image...</h4>
            </template>
          </v-card-text>
          <v-card-actions class="my-2">
            <span class="font-size-x-small" v-if="tempFileSize && !isSelectingFile">
              {{ tempFileSize.toFixed(2) }}{{ conversionUnit }}
            </span>
            <v-spacer></v-spacer>
            <outline-button :disabled="isSelectingFile" @click="closeCropperDialog">
              Nevermind
            </outline-button>
            <primary-button :disabled="isSelectingFile" @click="uploadCroppedImage">
              Upload
            </primary-button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { Cropper, Preview } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import renderMixin from '@satellite/components/mixins/renderMixin';

export default {
  mixins: [renderMixin],
  components: {
    Cropper,
    Preview
  },
  props: {
    /**
     * v-model
     */
    value: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Field is required
     */
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    maxSize: {
      type: Number,
      required: false,
      default: null
    },
    conversionUnit: {
      type: String,
      required: false,
      default: 'KB'
    },
    allowCropping: {
      type: Boolean,
      required: false,
      default: false
    },
    cropShape: {
      type: String,
      required: false,
      default: 'rectangle',
      validator(value) {
        return ['rectangle', 'circle'].includes(value);
      }
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    allowedMimeTypes: {
      type: Array,
      required: false,
      default: null
    },
    aspectRatio: {
      type: Number,
      required: false,
      default: 1
    },
    showCropResult: {
      type: Boolean,
      required: false,
      default: true
    },
    modalHeaderText: {
      type: String,
      required: false,
      default: ' Drag image to frame cropped area.'
    }
  },
  computed: {
    fileName() {
      const selectedFileName = this.selectedFile?.name;
      const existingFileName = this.value ? this.novaCore.getDocumentNameFromUrl(this.value) : '';
      return selectedFileName || existingFileName;
    },
    /**
     * Determines if file is image for thumbnail display purposes
     * @returns {*|boolean}
     */
    isImageFile() {
      return this.selectedFile
        ? this.selectedFile.type.includes('image') || this.novaCore.isImageUrl(this.fileUrl)
        : false;
    },
    /**
     * Get the first error from bucket if exists
     * @returns {boolean}
     */
    fieldError() {
      let error = false;
      if (this.mounted) {
        error = this.$refs.fileinput.errorBucket.length ? this.$refs.fileinput.errorBucket[0] : '';
      }

      return error;
    },
    fileTypeText() {
      let helperText = '';
      const formats = [];

      if (this.allowedMimeTypes?.length > 0) {
        helperText = 'Accepted formats: ';

        if (this.allowedMimeTypes.includes('image/')) {
          formats.push(...['jpeg', 'jpg', 'png', 'webp']);
        }

        if (this.allowedMimeTypes.includes('pdf')) {
          formats.push('pdf');
        }

        if (formats.length === 0) {
          formats.push('other formats');
        }

        helperText += formats.join(', ');
        helperText += '. ';
      }

      if (this.maxSize) {
        helperText += `Maximum file size: ${this.maxSize}${this.conversionUnit}.`;
      }

      return helperText;
    }
  },
  data() {
    return {
      renderKey: 0,
      mounted: false,
      selectedFile: null,
      isSelectingFile: false,
      fileUrl: '',
      fileKey: '',
      tempFileBase64: '',
      tempFileSize: 0,
      fileChangeDebouncer: null,
      loading: false,
      cropperLoading: false,
      cropResult: {
        coordinates: null,
        image: null,
        canvas: null
      },
      zoom: {
        input: 0,
        value: 0,
        manual: false
      }
    };
  },
  methods: {
    isFileValid(file) {
      if (!file) {
        this.notify('File is not valid', 'error');
        return false;
      }

      if (this.allowedMimeTypes?.length > 0) {
        const isValidMime = this.allowedMimeTypes.some(allowedMime =>
          file.type.includes(allowedMime)
        );

        if (!isValidMime) {
          this.notify('The file type is not allowed', 'error');
          return false;
        }
      }

      const sizeInUnit = this.novaCore.convertBytes(file.size, this.conversionUnit);

      if (this.maxSize && sizeInUnit > this.maxSize) {
        this.notify(`File size exceeds limit of ${this.maxSize}${this.conversionUnit}`, 'error');
        return false;
      }

      return true;
    },
    /**
     * Handles file upload button click
     * @public
     */
    fileUploadClick() {
      window.addEventListener(
        'focus',
        () => {
          this.isSelectingFile = false;
        },
        { once: true }
      );

      this.$refs.fileinput.$refs.input.click();
    },
    async uploadFile(file = this.selectedFile) {
      this.cropperLoading = true;
      this.$emit('uploading');
      this.isSelectingFile = true;
      if (file) {
        if (!this.isFileValid(file)) {
          this.isSelectingFile = false;
          return;
        }
        let formData = new FormData();
        formData.append('file', file);
        await axios
          .post('/storage', formData)
          .then(response => {
            if (response && response.data) {
              this.fileUrl = response.data.url;
              this.fileKey = response.data.key;
            }
          })
          .catch(() => {
            this.selectedFile = null;
          })
          .finally(() => {
            this.isSelectingFile = false;
          });
      } else {
        this.fileUrl = '';
        this.fileKey = '';
      }

      /**
       * Emits input event for v-model
       * @event input
       * @property {object} File
       */
      this.$emit('input', this.fileKey);

      this.clearDataProps();
      this.$emit('uploaded');
      this.cropperLoading = false;
    },
    /**
     * Handles file selection change
     * @public
     * @returns {Promise<void>}
     */
    async onFileChanged() {
      if (this.allowCropping && this.isImageFile) {
        this.cropperLoading = true;
        const reader = new FileReader();
        // listen for 'load' events on the FileReader
        reader.addEventListener(
          'load',
          () => {
            // change the preview's src to be the "result" of reading the uploaded file (below)
            this.tempFileBase64 = reader.result;
          },
          false
        );
        reader.readAsDataURL(this.selectedFile);
      } else {
        await this.uploadFile();
      }
    },
    deleteDoc() {
      this.fileUrl = '';
      this.fileKey = '';
      this.selectedFile = null;
      this.$emit('input', this.fileKey);
    },
    change({ coordinates, image, canvas }) {
      this.cropResult = {
        coordinates,
        image,
        canvas
      };
      this.fileChangeDebounce(this.getCroppedFileSize, 100)();
    },
    closeCropperDialog() {
      this.clearDataProps();
      this.setFileUrlUsingVal();
      this.selectedFile = new File([], this.novaCore.getDocumentNameFromUrl(this.fileUrl));
      this.incrementRenderKey();
    },
    clearDataProps() {
      this.isSelectingFile = false;
      this.tempFileBase64 = null;
      this.cropResult = null;
      this.tempFileSize = 0;
      this.zoom.value = 0;
      this.zoom.input = 0;
    },
    uploadCroppedImage() {
      this.cropResult.canvas.toBlob(async blob => {
        const file = new File([blob], this.fileName, { type: this.selectedFile.type });
        await this.uploadFile(file);
      }, this.selectedFile.type);
    },
    getCroppedFileSize() {
      const setFileSize = blob => {
        this.tempFileSize = blob ? this.novaCore.convertBytes(blob.size, this.conversionUnit) : 0;
      };
      this.cropResult.canvas.toBlob(blob => setFileSize(blob), this.selectedFile.type);
    },
    fileChangeDebounce(func, timeout = 300) {
      return (...args) => {
        clearTimeout(this.fileChangeDebouncer);
        this.fileChangeDebouncer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    setFileUrlUsingVal() {
      if (this.value) {
        this.fileUrl = this.value.startsWith('https://')
          ? this.value
          : this.novaCore.fileKeyToDownloadUrl(this.$store.getters['App/s3BaseUrl'], this.value);
      }
    },
    onZoom(newValue) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        // We need the ratio of visible area size (current and target)
        const currentArea = this.getVisibleAreaSize(this.zoom.value, cropper);
        const newArea = this.getVisibleAreaSize(newValue, cropper);

        cropper.runAutoZoom();
        cropper.zoom(currentArea / newArea);

        this.zoom.value = newValue;
      }
    },
    getVisibleAreaSize(absoluteSize, { imageSize, boundaries, sizeRestrictions }) {
      let minSize = Math.max(sizeRestrictions.minHeight, sizeRestrictions.minWidth);

      let maxSize =
        imageSize.width / imageSize.height > boundaries.width / boundaries.height
          ? imageSize.width
          : imageSize.height;

      return maxSize - absoluteSize * (maxSize - minSize);
    }
  },
  mounted() {
    this.setFileUrlUsingVal();

    // Set default value for the v-model
    if (this.fileUrl && !this.selectedFile) {
      this.selectedFile = new File([], this.novaCore.getDocumentNameFromUrl(this.fileUrl));
    }
    this.mounted = true;
  }
};
</script>
