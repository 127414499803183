import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';
import { AppointmentStatus } from '@satellite/../nova/core';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async getAppointmentById(appointmentId, params, options = {}, requestConfig = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(
      `appointment/${appointmentId}?${queryString}`,
      {
        params
      },
      requestConfig
    );
    return getResponse(response, options);
  },
  async getPublicAppointment(appointmentId, options = {}, requestConfig = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(
      `appointment/public/${appointmentId}?${queryString}`,
      requestConfig
    );
    return getResponse(response, options);
  },
  async createAppointment(params, isClone = false, options = {}, requestConfig = {}) {
    const response = await axios.post(
      `/appointment${isClone ? '?bypassCustomFieldsValidation=true' : ''}`,
      params,
      requestConfig
    );

    this.triggerSocketEvent(response, 'create-Appointment');

    return getResponse(response, options);
  },
  async updateAppointment(appointmentId, data, options = {}, requestConfig = {}) {
    const response = await axios.patch(`appointment/${appointmentId}`, data, requestConfig);

    this.triggerSocketEvent(response);

    return getResponse(response, options);
  },
  async cancelAppointment(appointment, reason, options = {}, requestConfig = {}) {
    const notes = reason
      ? `${appointment.notes ?? ''}<br/>Cancellation Reason: ${reason}`
      : appointment.notes ?? '';
    const data = {
      notes,
      status: AppointmentStatus.Cancelled
    };
    const response = await axios.patch(`appointment/${appointment.id}`, data, requestConfig);

    this.triggerSocketEvent(response);

    return getResponse(response, options);
  },

  async setAppointmentEta(appointmentId, data, options = {}, requestConfig = {}) {
    const response = await axios.patch(
      `appointment/${appointmentId}/set-eta`,
      {
        eta: data.etaISO,
        reason: data.etaReason
      },
      requestConfig
    );

    this.triggerSocketEvent(response);

    return getResponse(response, options);
  },
  async getAppointments(params, options = {}, requestConfig = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(
      `appointment?${queryString}`,
      {
        params
      },
      requestConfig
    );

    return getResponse(response, options);
  },
  // By now, this is only valid for helios (not luna)
  // It simulates the socket event for update the appointment at
  // the local session
  triggerSocketEvent(response, eventName = 'update-Appointment') {
    if (this.$app?.$eventHub && response?.status < 400 && response?.data?.data) {
      this.$app.$eventHub.$emit(eventName, response.data.data);
    }
  }
};
