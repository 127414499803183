import { getCurrentInstance } from 'vue';
import { useNovaCore } from '@/composables';
import notificationHelpers from '@satellite/helpers/notifications';

export default function useNotify() {
  const vue = getCurrentInstance();
  const novaCore = useNovaCore();

  const notify = (message, type = 'success', options = {}) => {
    options = {
      ...{
        duration: 5000,
        group: 'bottom-notifications',
        clean: false,
        actionText: null,
        actionFn: null,
        title: null
      },
      ...options
    };

    vue.proxy.$notify({
      group: options.group,
      title: novaCore.sanitizeInput(novaCore.upperFirst(options.title ?? type)),
      text: novaCore.sanitizeInput(message),
      duration: options.duration,
      data: { ...notificationHelpers.getNotificationConfig(type), ...options },
      clean: options.clean
    });
  };

  return notify;
}
