var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.actionItem.show
    ? _c(
        "v-btn",
        {
          staticClass: "mx-1",
          class: {
            [_vm.actionItem.color]: _vm.actionItem.color,
            [`${_vm.actionItem.textColor}--text`]: _vm.actionItem.textColor,
            "sub-item": _vm.isSubItem,
          },
          attrs: {
            text: "",
            small: "",
            ripple: false,
            "data-testid": `appointment-${_vm.actionName}-button`,
            icon: !_vm.actionItem.label,
            disabled: _vm.actionItem.disabled,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click", _vm.actionItem.action)
            },
          },
        },
        [
          _c(
            "v-icon",
            {
              staticClass: "icon",
              attrs: {
                size: !_vm.actionItem.label ? 20 : 16,
                color: _vm.actionItem.iconColor ? _vm.actionItem.iconColor : "",
              },
            },
            [_vm._v(" " + _vm._s(`mdi-${_vm.actionItem.icon}`) + " ")]
          ),
          _vm.actionItem.label
            ? _c("span", { staticClass: "d-inline-block ml-1" }, [
                _vm._v(_vm._s(_vm.actionItem.label)),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }