var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "full-height" },
    [
      _vm.isLoading
        ? _c(
            "v-card",
            { staticClass: "text-center my-4", attrs: { width: "270" } },
            [
              _c(
                "v-card-text",
                [
                  _c("v-progress-circular", {
                    attrs: { color: "primary", indeterminate: "", size: "22" },
                  }),
                  _c("p", { staticClass: "small mt-4 font-weight-bold" }, [
                    _vm._v("Loading Asset Visits data..."),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            { attrs: { elevation: "0" } },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "p",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-clock-outline")]
                      ),
                      _vm._v(" Waiting assignment "),
                    ],
                    1
                  ),
                  _c("v-card-subtitle", { staticClass: "pa-1" }, [
                    _c(
                      "span",
                      { staticClass: "font-size-small font-weight-normal" },
                      [
                        _vm._v(
                          " Drag and drop carriers to assign them a spot. "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              small: "",
                              "data-testid":
                                "yard-unassigned-list-carrier-count",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.waitingAssetVisits.length) +
                                " carrier" +
                                _vm._s(
                                  _vm.waitingAssetVisits.length !== 1 ? "s" : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.waitingAssetVisits.length > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pt-4" },
                    _vm._l(
                      Object.entries(_vm.groupedAssetVisitsByDate),
                      function ([date, dateAssetVisits]) {
                        return _c(
                          "div",
                          { key: date },
                          [
                            _c("p", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(date)),
                            ]),
                            _vm._l(dateAssetVisits, function (assetVisit) {
                              return _c("yard-view-asset-visit", {
                                key: assetVisit.id,
                                attrs: { "asset-visit": assetVisit },
                              })
                            }),
                          ],
                          2
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm.waitingAssetVisits.length === 0
        ? _c("div", { staticClass: "no-waiting-asset-visits" }, [
            _c(
              "div",
              [
                _c("svg-truck", {
                  staticClass: "mb-4",
                  attrs: { theme: "white" },
                }),
                _c("span", { staticClass: "text-center font-size-x-small" }, [
                  _vm._v("No carriers waiting"),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }