<template>
  <div>
    <p>This spot is open and available for truck assignment.</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewSpotDetailOpen',
  setup() {
    const yardStore = useYard();

    return {
      isSpotOpen: yardStore.isSpotOpen,
      spot: yardStore.selectedSpotRef
    };
  }
});
</script>
<style scoped lang="scss"></style>
