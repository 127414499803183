import _ from 'lodash';

/** Generate object prop validator.
 * @example
 * defineComponent({ props: { ...objectPropFactory('prop', ['key', 'another.key']) } })
 * @param {string} name
 * @param {string[]} requiredKeys
 * @param {Object} [opts]
 * @param {boolean} [opts.required]
 */
export function objectPropFactory(name, requiredKeys, opts) {
  return {
    [name]: {
      type: Object,
      required: opts?.required,
      validator: value => requiredKeys.every(key => _.has(value, key))
    }
  };
}
