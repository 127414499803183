<template>
  <dialog-base
    :key="renderKey"
    header="Register with Opendock"
    @close="close"
    loading-message="Registering User...">
    <template v-slot:activator="slotProps">
      <outline-button
        before-icon="account-plus"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2"
        >Register</outline-button
      >
    </template>
    <template slot="body">
      <registration-form @close="close"></registration-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for registration form
 * @displayName Registration Dialog
 */
export default {
  mixins: [dialogMixin]
};
</script>
