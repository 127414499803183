var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "rootEl",
      staticClass: "carrier-search-item",
      attrs: {
        "data-testid": "carrier-list-search-item",
        "data-carrier-id": _vm.carrier.id,
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "d-flex flex-column carrier-company" }, [
        _c("p", { staticClass: "full-name" }, [
          _vm._v(_vm._s(_vm.carrier.fullName)),
        ]),
        _c(
          "p",
          [
            _c(
              "v-icon",
              { staticClass: "truck-icon mr-1", attrs: { small: "" } },
              [_vm._v("mdi-truck")]
            ),
            _c("span", { staticClass: "company-name mr-1" }, [
              _vm._v(_vm._s(_vm.carrier.company.name)),
            ]),
            _c("span", { staticClass: "company-scac" }, [
              _vm._v(_vm._s(_vm.carrier.company.scac)),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "carrier-info" }, [
        _c("p", [_vm._v(_vm._s(_vm.carrier.email))]),
        _vm.subscriberCount && _vm.subscriberCount > 0
          ? _c(
              "p",
              [
                _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                  _vm._v("mdi-email-fast-outline"),
                ]),
                _c("span", [
                  _vm._v(
                    "+ " +
                      _vm._s(_vm.subscriberCount) +
                      " " +
                      _vm._s(
                        _vm.novaCore.pluralize(
                          _vm.subscriberCount,
                          "subscriber"
                        )
                      )
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "p",
        {
          staticClass: "carrier-action",
          class: _vm.state.className,
          attrs: { "data-testid": "carrier-list-search-item-action" },
        },
        [
          _c("v-icon", { attrs: { small: "", left: "" } }, [
            _vm._v(_vm._s(_vm.state.icon)),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.state.label))]),
        ],
        1
      ),
      !!_vm.carrierContactDetail.carrierContactRef.value?.id
        ? _c("carrier-contact-remove-dialog", {
            ref: "removeContactModal",
            attrs: {
              "external-activator": "",
              "mixpanel-data": _vm.mixpanelData,
              carrierContact: _vm.carrierContactDetail.carrierContactRef.value,
            },
            on: { removed: _vm.handleRemoved },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }