var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container--fluid" },
    [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _c(
          "h3",
          { staticClass: "my-6" },
          [
            _c(
              "v-btn",
              {
                attrs: { icon: "", small: "", rounded: "" },
                on: { click: _vm.back },
              },
              [_c("v-icon", [_vm._v("mdi-arrow-left")])],
              1
            ),
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ],
          1
        ),
        _vm.trigger?.flow
          ? _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    _vm.showEnableConfirm = true
                  },
                },
              },
              [
                _c("v-switch", {
                  attrs: {
                    dense: "",
                    inset: "",
                    label: `Form ${_vm.statusText(true)}`,
                  },
                  model: {
                    value: _vm.trigger.isActive,
                    callback: function ($$v) {
                      _vm.$set(_vm.trigger, "isActive", $$v)
                    },
                    expression: "trigger.isActive",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("custom-forms-flow", {
            attrs: {
              readonly: _vm.$isWarehouseReadOnly(
                _vm.warehouse.id,
                _vm.warehouse.orgId
              ),
              warehouse: _vm.warehouse,
              trigger: _vm.trigger,
            },
            on: {
              "data-updated": function ($event) {
                return _vm.$emit("data-updated")
              },
            },
          }),
        ],
        1
      ),
      _c("confirm", {
        attrs: {
          "should-show": _vm.showEnableConfirm,
          "is-manual-mode": "",
          persistent: "",
          icon: null,
          title: `${_vm.novaCore.upperFirst(_vm.statusText(false))} Form`,
          width: 650,
        },
        on: { result: _vm.handleFormActiveChange },
        scopedSlots: _vm._u([
          {
            key: "message",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(" Are you sure you want to "),
                  _c("strong", [_vm._v(_vm._s(_vm.statusText(false)))]),
                  _vm._v(" the " + _vm._s(_vm.title) + " "),
                ]),
                _vm.trigger.isActive
                  ? _c(
                      "p",
                      [
                        _vm.featureData.enableDisclaimer
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.featureData.enableDisclaimer) +
                                  " "
                              ),
                            ]
                          : [
                              _vm._v(
                                " This form will now always be available for the " +
                                  _vm._s(
                                    _vm.novaCore.upperFirst(_vm.trigger.app)
                                  ) +
                                  " after an appointment is completed. "
                              ),
                            ],
                      ],
                      2
                    )
                  : _c(
                      "p",
                      [
                        _vm.featureData.disableDisclaimer
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.featureData.disableDisclaimer) +
                                  " "
                              ),
                            ]
                          : [
                              _vm._v(
                                " This form will no longer be available for the " +
                                  _vm._s(
                                    _vm.novaCore.upperFirst(_vm.trigger.app)
                                  ) +
                                  " after an appointment is completed. "
                              ),
                            ],
                      ],
                      2
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }