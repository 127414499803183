import { CalendarViewEnum } from '@/enums';
import storageHelpers from '@satellite/helpers/storage';
import { isMobile } from '@satellite/plugins/util';

export default {
  data() {
    return {
      mounted: false
    };
  },
  computed: {
    /**
     * Name of the calendar component to render
     * @returns {string}
     */
    calendarComponent() {
      return `calendar-${CalendarViewEnum[this.viewType]}-view`;
    },
    selectedStatuses: {
      get() {
        return this.$selectedStatuses;
      },
      set(value) {
        this.$store.dispatch('Calendar/setSelectedStatuses', value);
      }
    },
    /**
     * Selected docks getter/setter
     */
    selectedDocks: {
      get() {
        return this.$selectedDocks;
      },
      set(value) {
        if (Array.isArray(value)) {
          value = value.filter(dock => {
            if (this.novaCore.isCapacityChild(dock)) {
              return value.findIndex(searchDock => searchDock.id === dock?.capacityParentId) !== -1;
            } else {
              return true;
            }
          });
        }
        value = this.novaCore.naturalSort(value, false, 'name');
        value = this.novaCore.naturalSort(value, false, 'sortOrder');
        this.$store.dispatch('Calendar/setSelectedDocks', value || []);
        if (this.mounted) {
          this.$store.dispatch('Calendar/getEvents');
        }
      }
    },
    /**
     * Selected Warehouse getter/setter
     */
    selectedWarehouse: {
      get() {
        return this.$selectedWarehouse;
      },
      set(value) {
        // NOTE(2024-04-05): when the user sends ctrl+bs the v-autocomplete component sends
        // `null` as the value, in which case we will ignore it.
        if (value === null) {
          return;
        }
        // Prevent various downstream changes in the UI if the user
        // simply selects the same warehouse that's already selected
        if (value.id !== this.$selectedWarehouse.id) {
          this.$store.dispatch('Calendar/setSelectedWarehouse', value);
        }
      }
    },
    selectedLoadtypes: {
      get() {
        return this.$selectedLoadtypes;
      },
      set(value) {
        this.$store.dispatch('Calendar/setSelectedLoadtypes', value || []);
        if (this.mounted && this.$selectedWarehouse?.id) {
          this.$store.dispatch('Calendar/getEvents');
        }
      }
    },
    /**
     * Array of event objects to display in calendar
     * @returns {Object[]}
     */
    eventsByWarehouseId() {
      return this.$store.getters['Calendar/eventsByWarehouseId'](this.selectedWarehouseId);
    },
    /**
     * Events for a warehouse filtered further by selected dock IDs
     * @returns {*}
     */
    warehouseEventsByDock() {
      return this.$warehouseEventsByDock(this.selectedWarehouseId, this.$selectedDockIds).filter(
        event => {
          return this.$selectedStatuses.includes(event.status);
        }
      );
    },
    /**
     * The selected calendar view type
     * @returns {(function(*): *)|*}
     */
    viewType() {
      return this.$viewType;
    },
    warehouseDocks() {
      return this.selectedWarehouse?.id ? this.selectedWarehouse.docks : [];
    },
    shouldShowNoSelectionOverlay() {
      return !this.$selectedWarehouse?.id || !this.$selectedDocks?.length;
    },
    useLightGridTheme: {
      get() {
        return this.$useLightGridTheme;
      },
      set(useLightGridTheme) {
        this.$store.dispatch('Calendar/setUseLightGridTheme', useLightGridTheme);
      }
    }
  },
  methods: {
    /**
     * Re-fetches any previously stored data
     * Currently, we only need Warehouse but may change as the application grows
     * @returns {Promise<void>}
     */
    async refreshStoredData() {
      if (this.selectedWarehouse?.id) {
        let response = await this.services.warehouse.getWarehouseById(
          this.selectedWarehouse.id,
          {},
          this.services.warehouse.requestOptions.grid
        );
        if (response) {
          await this.$store.dispatch('Calendar/setSelectedWarehouse', response);

          const selectedDockIds = this.novaCore.pluck(this.$selectedDocks, 'id');
          let selectedDocks = response.docks.filter(dock => selectedDockIds.includes(dock.id));
          selectedDocks = this.novaCore.naturalSort(selectedDocks, false, 'name');
          selectedDocks = this.novaCore.naturalSort(selectedDocks, false, 'sortOrder');
          await this.$store.dispatch('Calendar/setSelectedDocks', selectedDocks);
        }
        this.displayLoader();
      }
    },
    getStoredCalendarData() {
      return this.$loadObjectFromLocalStorage(
        storageHelpers.makeUserBoundedKey('nova_calendar', this.$me.id)
      );
    },
    /**
     * Sets existing localStorage calendar data
     */
    setStoredCalendarData() {
      const storedCalendarData = this.getStoredCalendarData();
      if (storedCalendarData.selectedWarehouse) {
        this.$store.dispatch('Calendar/setSelectedWarehouse', storedCalendarData.selectedWarehouse);
      }
      if (storedCalendarData.selectedDocks) {
        this.$store.dispatch('Calendar/setSelectedDocks', storedCalendarData?.selectedDocks);
      }
      if (storedCalendarData.selectedLoadtypes) {
        this.$store.dispatch('Calendar/setSelectedLoadtypes', storedCalendarData.selectedLoadtypes);
      }
      if (storedCalendarData.viewType) {
        this.$store.dispatch('Calendar/setViewType', {
          value: storedCalendarData?.viewType,
          getEvents: this.mounted
        });
      }
      if (storedCalendarData.selectedDate) {
        this.$store.dispatch('Calendar/setSelectedDate', {
          value: storedCalendarData?.selectedDate,
          getEvents: this.mounted
        });
      }
      if (storedCalendarData.zoomLevel) {
        this.$store.dispatch('Calendar/setZoomLevel', storedCalendarData?.zoomLevel);
      }
      if (storedCalendarData.selectedStatuses) {
        this.$store.dispatch('Calendar/setSelectedStatuses', storedCalendarData?.selectedStatuses);
      }
      if (storedCalendarData.useLightGridTheme) {
        this.$store.dispatch(
          'Calendar/setUseLightGridTheme',
          storedCalendarData?.useLightGridTheme
        );
      }
      this.$store.dispatch('Calendar/setShouldShowWeekends', {
        value: storedCalendarData?.shouldShowWeekends ?? true
      });

      this.$nextTick(() => {
        if (storedCalendarData.mode) {
          this.$store.dispatch('Calendar/setMode', {
            value: storedCalendarData.mode,
            getEvents: this.mounted
          });
        }
      });
    },
    autoSelectWarehouseDocks() {
      this.selectedDocks = this.selectedWarehouse.docks;
    },
    displayLoader() {
      this.isLoading = Boolean(
        !this.isDockCapacityToggle && this.$selectedWarehouse?.id && this.$selectedDocks?.length > 0
      );
    }
  },
  watch: {
    selectedWarehouse(newValue, oldValue) {
      if (this.mounted && newValue?.id && newValue.id !== oldValue.id) {
        this.autoSelectWarehouseDocks();
      }
    },
    $viewType() {
      this.isDockCapacityToggle = false;
      this.displayLoader();
    },
    $selectedDate() {
      this.isDockCapacityToggle = false;
      this.displayLoader();
    },
    $selectedWarehouse() {
      this.isDockCapacityToggle = false;
      this.displayLoader();
    },
    $selectedDocks() {
      this.displayLoader();
    },
    $selectedLoadtypes() {
      this.displayLoader();
    },
    $isFetchingEvents(isFetching) {
      if (!isFetching) {
        this.isLoading = false;
        this.isDockCapacityToggle = false;
      }
    },
    '$org.customTags'() {
      this.$store.dispatch('Calendar/getEvents', {});
    }
  },
  async mounted() {
    await this.refreshStoredData();
    this.$eventHub.$on('update-Dock', this.refreshStoredData);

    ['update', 'create', 'delete'].forEach(operation => {
      this.$eventHub.$on(`${operation}-Appointment`, async payload => {
        await this.$store.dispatch('Appointments/handleSocketEvent', {
          action: operation,
          data: payload
        });
      });
    });

    this.$eventHub.$on('shake-element', refKey => {
      this.shakeElement(refKey);
    });

    if (!isMobile()) {
      await this.$store.dispatch('Calendar/getEvents');
    }
    this.mounted = true;
  }
};
