<template>
  <dialog-base
    v-if="trigger?.id"
    :key="renderKey"
    v-bind="$props"
    @close="close"
    content-class="details-dialog edit-custom-form-dialog"
    transition="dialog-bottom-transition"
    loading-message="updating appointment"
    max-width="100%"
    :value="showDialog"
    :header="flow.name || 'Flow'"
    width="1200px">
    <template #customHeader>
      <header class="details-header">
        <dialog-header :header="flow.name || 'Flow'" hide-close-icon>
          <template #prepend-button>
            <v-btn icon text class="mr-2" @click="close"><v-icon>mdi-arrow-left</v-icon></v-btn>
          </template>
        </dialog-header>
      </header>
    </template>

    <template v-slot:body>
      <details-content-box>
        <custom-forms-flow-form
          ref="customFormFlowForm"
          hide-details
          :persistent-placeholder="false"
          outlined
          :display-label="false"
          :trigger="trigger"
          :object-id="objectId"
          :warehouse="warehouse"
          :status-change="statusChange"
          :stack-actions="stackActions"
          @close="close"
          @update="data => $emit('update', data)"
          @create="data => $emit('create', data)"></custom-forms-flow-form>
      </details-content-box>
    </template>
  </dialog-base>
</template>

<script>
import { dialogBaseProps, useRenderKey, useDialogBase } from '@/composables';
import { computed, ref } from 'vue';

export default {
  props: {
    ...dialogBaseProps,
    trigger: {
      type: Object,
      default: null
    },
    objectId: {
      type: String,
      required: true
    },
    statusChange: {
      type: String,
      required: false
    },
    /*
     * Stack actions buttons vertically
     */
    stackActions: {
      type: Boolean,
      required: false,
      default: false
    },
    /*
     * Makes the dialog fullscreen, for mobile experience
     */
    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },
    warehouse: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  setup(props, context) {
    const { renderKey } = useRenderKey();
    const customFormFlowForm = ref(null);

    const onClose = () => {
      context.emit('close');
    };

    const onDismiss = () => context.emit('close');

    const { close } = useDialogBase({
      onClose,
      onDismiss
    });

    const flow = computed(() => props.trigger?.flow || {});

    async function submitForm() {
      const form = customFormFlowForm.value;
      if (form) {
        form.submit();
      }
    }

    return {
      renderKey,
      close,
      flow,
      submitForm,
      customFormFlowForm
    };
  }
};
</script>

<style lang="scss">
.details-dialog.edit-custom-form-dialog.v-dialog {
  padding: 0 !important;
  align-self: flex-end;
  margin-bottom: 0;
  margin-top: 0;
  max-height: calc(100% - 48px) !important;
  height: calc(100% - 48px);

  .dialog-box {
    max-height: 100%;
  }

  .v-card {
    &__title {
      padding: 0 !important;
    }

    &__text {
      padding: 24px 0 !important;
      background-color: $color-background-primary !important;

      > div {
        justify-content: center;
        display: flex;
      }
    }
  }

  .details-header {
    padding: 24px;
  }

  .details-container {
    margin-bottom: 0;
    width: 500px !important;
    max-width: 90%;
  }

  .custom-field-wrapper {
    .v-input--switch {
      margin-top: 0;
    }
  }

  .custom-field-wrapper:not(:first-child) {
    padding-top: 20px;
  }

  .v-select.v-text-field:not(.v-text-field--single-line) input {
    margin-top: 0;
  }

  .actions {
    background: $white;
    border-top: 1px solid $color-line-border;
  }
}
</style>
