<template>
  <button-base class="secondary" v-bind="[$props, $attrs]" @click="handleClick($event)">
    <!-- @slot Button Content -->
    <slot></slot>
  </button-base>
</template>

<script>
import buttonMixin from '../../mixins/buttonMixin';

/**
 * A button styled with Secondary Color
 * @displayName Secondary Button
 */
export default {
  mixins: [buttonMixin]
};
</script>
