var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.showWarehouseGroupForm
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    { staticClass: "search-field", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c("text-field", {
                            staticClass: "mb-5",
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Search warehouse groups",
                              "single-line": "",
                            },
                            model: {
                              value: _vm.filters.searchStr,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "searchStr", $$v)
                              },
                              expression: "filters.searchStr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            disabled:
                              _vm.$rolePermissions.canCreateWarehouseGroup,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "primary-button",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              disabled:
                                                !_vm.$rolePermissions
                                                  .canCreateWarehouseGroup,
                                              "before-icon": "plus",
                                              large: "",
                                            },
                                            on: { click: _vm.showCreateDialog },
                                          },
                                          [_vm._v(" Create Warehouse Group ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            596082407
                          ),
                        },
                        [_c("span", [_vm._v("Create Warehouse Group")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "warehouse-group-list",
                attrs: {
                  headers: _vm.rowHeaders,
                  items: _vm.$warehouseGroups,
                  loading: _vm.loading,
                  "server-items-length": _vm.$warehouseGroupsPagination.total,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  "footer-props": _vm.footerProps,
                  options: _vm.options,
                },
                on: {
                  "update:sortBy": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.name",
                      fn: function ({ item }) {
                        return [
                          _vm._v(" " + _vm._s(item.name) + " "),
                          _c("copy-content", { attrs: { content: item.name } }),
                        ]
                      },
                    },
                    {
                      key: "item.warehouses",
                      fn: function ({ item }) {
                        return [
                          _vm._l(
                            item.warehouses.slice(
                              0,
                              _vm.maxWarehousesPreview + 1
                            ),
                            function (warehouse, index) {
                              return [
                                !_vm.maxWarehousesPreviewReached(index)
                                  ? _c(
                                      "router-link",
                                      {
                                        key: index,
                                        attrs: {
                                          to: {
                                            name: "warehouses.details.general",
                                            params: {
                                              warehouseId: warehouse.id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              warehouse.name +
                                                _vm.getSeparator(
                                                  index,
                                                  item.warehouses.length
                                                )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c("span", { key: "ellipsis" + index }, [
                                      _vm._v("..."),
                                    ]),
                              ]
                            }
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "row-actions" },
                            [
                              _c("icon-tooltip-button", {
                                attrs: {
                                  disabled:
                                    !_vm.$rolePermissions
                                      .canUpdateWarehouseGroup,
                                  id: item.id,
                                  tooltip: "Edit Warehouse Group",
                                  size: "large",
                                  "icon-class": "mr-2",
                                  color: "secondary",
                                  icon: "pencil",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showEditDialog(item)
                                  },
                                },
                              }),
                              _c("audit-log-entity-icon-button", {
                                attrs: {
                                  item: item,
                                  timezone: item?.timezone ?? "UTC",
                                  "entity-type":
                                    _vm.novaCore.DatabaseEntities
                                      .WarehouseGroup,
                                },
                              }),
                              _c("icon-tooltip-button", {
                                attrs: {
                                  disabled:
                                    !_vm.$rolePermissions
                                      .canDeleteWarehouseGroup,
                                  size: "large",
                                  tooltip: "Delete Warehouse Group",
                                  delete: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showDeleteWarehouseGroupConfirmation(
                                      item
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3869241493
                ),
              }),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-uppercase text-caption grey--text text--darken-2",
                      },
                      [_vm._v(" " + _vm._s(_vm.getTitle()) + " ")]
                    ),
                    _c("br"),
                    _c("span", [_vm._v("Warehouse Group Details")]),
                  ]),
                  _c("v-spacer"),
                  _c("v-icon", { on: { click: _vm.closeEditOrCreateDialog } }, [
                    _vm._v("mdi-close"),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("warehouse-group-form", {
                    ref: "saveWarehouseGroup",
                    attrs: { warehouseGroup: _vm.editingWarehouseGroup },
                    on: { close: _vm.closeEditOrCreateDialog },
                  }),
                ],
                1
              ),
            ],
            1
          ),
      _vm.showDeleteConfirm
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.showDeleteConfirm,
              "is-manual-mode": "",
              persistent: "",
              icon: "mdi-delete-forever",
              "delete-confirmation": "",
              title: "Delete this Warehouse Group?",
              width: 650,
              loading: _vm.loading,
              "confirmation-input-text": _vm.deleteConfirmationInputText,
              "entity-name": _vm.deletingWarehouseGroup.name,
              "button-true-text": "YES, DELETE",
              "button-true-color": "error",
              color: "error",
            },
            on: { result: _vm.handleDeleteResult },
            scopedSlots: _vm._u(
              [
                {
                  key: "message",
                  fn: function () {
                    return [
                      _vm.loading
                        ? _c("v-skeleton-loader", {
                            attrs: { type: "list-item-three-line" },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3597160914
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }