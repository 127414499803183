var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: _vm.header,
          "loading-message": "Sending verification email...",
        },
        on: { close: _vm.close, confirm: _vm.sendVerificationEmail },
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "v-card-text",
            [
              _c("v-container", [
                _c("p", [
                  _vm._v(
                    " After registering your account we sent a confirmation email to "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.email))]),
                  _vm._v(
                    ". Please find it and click the link inside to verify your email and complete your account creation. "
                  ),
                ]),
                _c("p", [
                  _vm._v(" Didn't get an email? "),
                  _c("br"),
                  _vm._v(" - Please check your spam/junk folder "),
                  _vm.allowVerificationResend ? _c("br") : _vm._e(),
                  _vm.allowVerificationResend
                    ? _c("span", [
                        _vm._v(" - "),
                        _c("a", { on: { click: _vm.sendVerificationEmail } }, [
                          _vm._v("Click here"),
                        ]),
                        _vm._v(" to resend the verification email "),
                      ])
                    : _vm._e(),
                  _c("br"),
                  _vm._v(" - Contact us: "),
                  _c("a", { attrs: { href: "@mailto:support@opendock.com" } }, [
                    _vm._v("support@opendock.com"),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("action-group", {
            attrs: {
              "hide-confirm": true,
              "cancel-icon": "",
              "cancel-label": "Close",
            },
            on: { cancel: _vm.close },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }