<template>
  <button-base v-bind="$attrs" @click="handleClick($event)" outlined>
    <!-- @slot Button Content -->
    <slot></slot>
  </button-base>
</template>

<script>
import buttonMixin from '../../mixins/buttonMixin';
import ButtonBase from './ButtonBase';
/**
 * A button styled with an outline
 * @displayName Outline Button
 */
export default {
  components: { ButtonBase },
  mixins: [buttonMixin],
  methods: {
    handleClick($event) {
      /**
       * Emits click event with target's event
       * @event click
       * @property {object} $event - Target's Event object
       */
      this.$emit('click', $event);
    }
  }
};
</script>
