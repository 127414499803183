<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :loading="loading"
    header="Forgot Password"
    loading-message="Sending reset email..."
    @close="closeDialog"
    @confirm="submit">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <secondary-button v-on="slotProps.on" v-bind="slotProps.attrs">
        Forgot Password?
      </secondary-button>
    </template>
    <template #body>
      <form-base
        v-on:submit.prevent
        @keyup.native.enter="submit"
        v-if="!loading"
        hide-required-legend>
        <template #form>
          <v-form ref="form">
            <text-field
              :required="true"
              type="email"
              id="forgot-password-email"
              prepend-icon="mdi-email"
              v-model="email"
              :rules="[...$validator.rules.required('Email'), ...$validator.rules.email]"
              label="Email"></text-field>
          </v-form>
        </template>
        <template #form-actions>
          <action-group
            @cancel="close"
            :stack-actions="isMobile()"
            :inline-actions-mobile="isMobile()"
            confirm-icon="email"
            confirm-label="Send Reset"
            @confirm="submit"></action-group>
        </template>
      </form-base>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import DialogBase from '@satellite/components/elements/dialogs/DialogBase';
import SecondaryButton from '@satellite/components/elements/buttons/SecondaryButton';
import TextField from '@satellite/components/elements/forms/TextField';
import ActionGroup from '@satellite/components/elements/ActionGroup';
import { isMobile } from '@satellite/plugins/util';

/**
 * Button/Dialog for the forgot password form
 * @displayName Forgot Password Dialog
 */
export default {
  components: {
    DialogBase,
    SecondaryButton,
    ActionGroup,
    TextField
  },
  mixins: [dialogMixin],
  data() {
    return {
      email: '',
      loading: false
    };
  },
  methods: {
    isMobile,
    /**
     * Submit the form
     * @public
     */
    async submit() {
      if (this.loading) {
        return;
      }
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;

      const isUserCarrier = await this.$store.dispatch('Carriers/doesCarrierExist', this.email);

      if (isUserCarrier) {
        this.$emit('show-incorrect-portal', this.email);
        this.closeDialog();
        this.loading = false;
        return;
      }

      this.$store
        .dispatch('Auth/sendPasswordReset', this.email)
        .then(() => {
          this.mixpanel.identify({ email: this.email });
          this.mixpanel.track(this.mixpanel.events.ACCOUNT.RESET_PASSWORD);

          this.notify('Password reset email sent!');
          this.closeDialog();
        })
        .finally(() => (this.loading = false));
    },
    closeDialog() {
      this.email = '';
      this.close();
    }
  }
};
</script>
