export default {
  methods: {
    getClosedIntervals(entity) {
      return entity.schedule?.closedIntervals ?? [];
    },
    /**
     * Copies the warehouse schedule to the current schedule editor
     * @public
     */
    copyWarehouseSchedule(entity, warehouse, trackingFunc = () => null) {
      entity.schedule = this.novaCore.deepClone({
        ...warehouse.schedule,
        closedIntervals: this.getClosedIntervals(entity)
      });

      trackingFunc();
      this.notify('Schedule Cloned');
    },
    /**
     * Copies the provided dock schedule to the current schedule editor
     * @public
     */
    copyDockSchedule(entity, dockScheduleToClone) {
      entity.schedule = this.novaCore.deepClone({
        ...dockScheduleToClone,
        closedIntervals: this.getClosedIntervals(entity)
      });

      this.notify('Schedule Cloned');
    },
    setToDefault(entity) {
      entity.schedule = {
        ...this.novaCore.getFullyOpenSchedule(),
        closedIntervals: this.getClosedIntervals(entity)
      };
      this.notify('Schedule Set to Default');
    },
    clearSchedule(entity) {
      entity.schedule = {
        ...this.novaCore.getClosedScheduleTemplate(),
        closedIntervals: this.getClosedIntervals(entity)
      };
      this.notify('Schedule Closed');
    }
  }
};
