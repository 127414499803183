import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';

const defaultRequestOptions = {
  fields: ['name', 'warehouses'],
  joins: []
};

function transformWarehouseIDs(warehouseGroup) {
  if (warehouseGroup.warehouses) {
    warehouseGroup.warehouseIds = warehouseGroup.warehouses.map(wh => wh.id);
  }
  delete warehouseGroup.warehouses;
}

export default {
  createWarehouseGroup(warehouseGroup) {
    transformWarehouseIDs(warehouseGroup);
    return axios.post('warehouse-group', warehouseGroup);
  },
  updateWarehouseGroup(warehouseGroup) {
    transformWarehouseIDs(warehouseGroup);
    return axios.patch(`warehouse-group/${warehouseGroup.id}`, warehouseGroup);
  },
  async deleteWarehouseGroup(warehouseGroup) {
    return axios.delete(`warehouse-group/${warehouseGroup.id}`);
  },
  async getWarehouseGroupById(warehouseGroupId, params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`warehouse-group/${warehouseGroupId}?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },
  async getWarehouseGroups(params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`warehouse-group?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },
  async getListWarehouseGroups(params, options = {}) {
    return await this.getWarehouseGroups(params, {
      ...this.requestOptions.list,
      includeMetaData: options?.includeMetaData
    });
  },
  requestOptions: {
    default: {
      field: defaultRequestOptions.fields,
      joins: defaultRequestOptions.joins
    },
    list: {
      fields: [...defaultRequestOptions.fields],
      joins: [...defaultRequestOptions.joins, 'org||name']
    },
    grid: {
      fields: [...defaultRequestOptions.fields],
      joins: [...defaultRequestOptions.joins]
    }
  }
};
