<template>
  <div class="hours-of-operation-form">
    <v-row>
      <v-col cols="7" class="mt-4">
        <v-list v-if="closedIntervals.length">
          <v-list-item
            v-for="(interval, index) in closedIntervals"
            class="hours-of-operation-item exception-date closed"
            :key="`closed-interval-${index}`">
            <v-list-item-content>
              <div class="date-picker-container">
                <date-picker
                  required
                  :ref="`datepicker-${index}`"
                  :value="closedIntervals[index].start"
                  @input="value => updateClosedInterval(value, index)"
                  :clearable="false"
                  inner-icon
                  placeholder="Closed Date"
                  :compact-display="true"></date-picker>
              </div>
              <v-btn icon small class="side-button" @click="deleteClosedInterval(index)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn
          :disabled="!$rolePermissions.canCreateDock"
          text
          class="mt-3 add-hours-button"
          small
          @click="addClosedDate">
          <v-icon class="mr-1 font-size-x-small">mdi-plus</v-icon>
          Add closed date
        </v-btn>
      </v-col>
      <v-col cols="5">
        <div class="my-10">
          <holidays-selector
            :country="warehouse.country"
            :added-dates="closedIntervals?.map(i => i.start) || []"
            @input="addClosedDateFromHoliday">
          </holidays-selector>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { isDate } from 'class-validator';

export default {
  props: {
    warehouse: {
      type: Object,
      required: true
    },
    schedule: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      closedIntervals: []
    };
  },
  methods: {
    /**
     * Sets the existing schedule's closed intervals to component data
     * @public
     */
    setClosedIntervals() {
      if (this.schedule?.closedIntervals) {
        const intervals = [];
        Object.keys({ ...this.schedule.closedIntervals }).forEach(key => {
          const interval = this.schedule.closedIntervals[key];
          const start = this.novaCore.renderUtcInTimezone(
            interval.start,
            this.warehouse.timezone,
            null
          );
          const end = this.novaCore.renderUtcInTimezone(
            interval.end,
            this.warehouse.timezone,
            null
          );
          intervals.push({ start, end });
        });

        this.closedIntervals = _.orderBy(intervals, 'start', 'asc');
      }
      this.$emit('update-closed-intervals', this.closedIntervals);
    },
    /**
     * Add new closed "date" with current date default to component data
     * @public
     */
    addClosedDate() {
      this.closedIntervals.push(
        this.novaCore.getFullDayIntervalFromDateInTimezone(
          momentjs.tz(this.warehouse.timezone).format(this.novaCore.DateTimeFormats.DateDashed),
          this.warehouse.timezone
        )
      );
      this.$nextTick(() => {
        this.$refs[`datepicker-${this.closedIntervals.length - 1}`][0].datePicker = true;
      });
    },
    /**
     * Update a specific closed interval in component data by index
     * @public
     * @param date
     * @param index
     */
    updateClosedInterval(date, index) {
      const currentValue = this.closedIntervals[index];
      const newValue = this.novaCore.getFullDayIntervalFromDateInTimezone(
        date,
        this.warehouse.timezone
      );

      this.closedIntervals[index] = newValue;
      // This function does not modifies the array object but an array item,
      // it does not trigger the watch trigger
      if (currentValue?.start !== newValue?.start) {
        this.setDirtyStatus(true);
      }
      this.validateClosedIntervals();
    },
    /**
     * Delete closed interval from component data by index
     * @public
     * @param index
     */
    deleteClosedInterval(index) {
      this.closedIntervals.splice(index, 1);
    },
    /**
     * Validate closed interval has valid start date
     * @public
     */
    validateClosedIntervals() {
      let valid = true;
      this.closedIntervals.map(interval => {
        if (!isDate(new Date(interval?.start))) {
          valid = false;
        }
      });
      this.areClosedIntervalsValid = valid;
    },
    addClosedDateFromHoliday(date) {
      const interval = this.novaCore.getFullDayIntervalFromDateInTimezone(
        date,
        this.warehouse.timezone
      );
      this.closedIntervals.push(interval);
    }
  },
  mounted() {
    this.setClosedIntervals();
  }
};
</script>
