<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    appointment: {
      type: Object,
      required: true
    }
  }
};
</script>
