var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Reschedule Appointment",
          loading: false,
          width: "900",
          "has-dialog-actions": false,
          "max-width": "100%",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.rescheduleAppointmentForm.submit()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "before-icon": "calendar-edit",
                                large: "",
                              },
                            },
                            "primary-button",
                            slotProps.attrs,
                            false
                          ),
                          slotProps.on
                        ),
                        [_vm._v(" Reschedule Appointment ")]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _vm.alertMessage
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "mb-0",
                          attrs: { type: _vm.alertType, shaped: "" },
                        },
                        [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
                      )
                    : _vm._e(),
                  _c("reschedule-appointment-form-old", {
                    ref: "rescheduleAppointmentForm",
                    attrs: { appointment: _vm.appointment },
                    on: { close: _vm.close },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }