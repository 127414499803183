<template>
  <div>
    <strong class="error--text">CAUTION: </strong>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>
