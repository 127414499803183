<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    :key="renderKey"
    :header="dialogHeader"
    :scrollable="false"
    loading-message="Saving..."
    @close="close"
    @confirm="handleSaveEvent">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <v-tooltip top :disabled="$rolePermissions.canCreateAppointment && !readOnly">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <!--
                @slot Custom activator for the dialog
                @binding {object} slotProps Object containing {on, attrs}
              -->
            <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
            <primary-button
              v-else
              :disabled="!$rolePermissions.canCreateAppointment || readOnly"
              :before-icon="actionIcon"
              v-on="slotProps.on"
              v-bind="slotProps.attrs"
              large
              class="mr-2">
              {{ dialogHeader }}
            </primary-button>
          </div>
        </template>
        <span>{{ novaCore.getRoleActionError() }}</span>
      </v-tooltip>
    </template>
    <template v-slot:body>
      <custom-field-form
        :fieldTypeOptions="fieldTypeOptions"
        :field="field"
        :warehouse="warehouse"
        @save="handleSaveEvent"
        @close="close"></custom-field-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Field Form Dialog
 * @displayName Field Form Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Custom field
     */
    field: {
      type: Object,
      required: false
    },
    fieldTypeOptions: {
      value: String,
      text: String
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    warehouse: {
      type: Object,
      required: true
    }
  },
  computed: {
    dialogHeader() {
      return this.field ? 'Edit Field' : 'Create Field';
    },
    actionIcon() {
      return this.field ? 'pencil' : 'plus';
    }
  },
  methods: {
    handleSaveEvent(data) {
      /**
       * Emit save event
       * @event save
       * @property {object} save - field data
       */
      this.$emit('save', data);
      this.close();
    }
  }
};
</script>
