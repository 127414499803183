<template>
  <v-select
    :menu-props="{ auto: shouldDisplayAllItemsImmediately }"
    class="pt-0"
    hide-details="auto"
    @input="updateValue"
    :search-input.sync="filters.searchStr"
    :value="value"
    :items="warehouses"
    :loading="isLoading"
    v-bind="[$props, $attrs]"
    item-text="name"
    item-value="id"
    :required="required"
    persistent-placeholder
    placeholder="Select Warehouses"
    ref="warehouseSelect"
    return-object>
    <template v-slot:prepend-item v-if="warehouses.length && multiple">
      <v-list-item ripple @click="toggleSelectAllItems">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-slot:prepend-item class="mt-2" />
    </template>

    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action>
          <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
              {{ item.name }}
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0">{{ item.name }}</span>
      <span v-if="index === 1" class="grey--text text-caption ml-2">
        (+{{ value.length - 1 }} others)
      </span>
    </template>
  </v-select>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import { isUUID } from 'class-validator';

export default {
  mixins: [selectMixin, selectAllSelectMixin, dataListMixin],
  props: {
    /**
     * @model
     */
    value: {
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    filterLocally: {
      type: Boolean,
      required: false,
      default: true
    },
    restrictedWarehouseIds: {
      type: Array,
      required: false,
      default: null
    },
    orgId: {
      type: String,
      required: false
    }
  },
  computed: {
    selectableItems() {
      return this.warehouses;
    },
    /**
     * Since basic warehouse select fetches 500 warehouses at once, it should be considered static
     * unless we need to accommodate more than 500 warehouses for a single Org
     * This prevents a "paginated" list with indefinite scrolling if less than 100 warehouses
     * otherwise, we load every 20 on scroll to help performance
     * @returns {boolean}
     */
    shouldDisplayAllItemsImmediately() {
      return this.warehouses?.length < 100;
    }
  },
  data() {
    return {
      selectRefKey: 'warehouseSelect',
      warehouses: [],
      filters: {
        searchStr: null
      },
      isLoading: true
    };
  },
  methods: {
    async getData() {
      if (this.warehouses?.length && this.filterLocally) {
        return this.warehouses;
      }
      this.isLoading = true;
      try {
        const warehouses = await this.services.warehouse.getWarehouses(
          this.getQuery(!this.filters.searchStr)
        );
        this.warehouses = this.novaCore.naturalSort(warehouses, false, 'name');
      } catch (e) {
        // Intentional console log so we can easily view the error
        // axios interceptor will already flash the error toast to the user
        console.log(e);
      } finally {
        this.isLoading = false;
        this.$emit('ready');
      }
    },
    getQuery() {
      let query = this.getQueryBase();
      // Restrict to current user's Warehouse Access List
      if (this.restrictedWarehouseIds) {
        query.s = { ...query.s, id: { $in: this.restrictedWarehouseIds } };
      }

      if (isUUID(this.orgId)) {
        query.s = { ...query.s, orgId: { $eq: this.orgId } };
      }

      // TODO: Following the docks lead of 500 - this could possibly be abstracted to a single place where both areas use
      query.limit = '500';

      return query;
    },
    updateValue(warehouses) {
      this.$emit('input', warehouses);
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
