<template>
  <v-dialog max-width="85vw" :scrollable="true">
    <template v-slot:activator="{ on, attrs }">
      <icon-tooltip-button
        :icon-class="iconClass"
        :tooltip="tooltipText"
        size="large"
        v-bind="attrs"
        v-on="on"
        @click="handleDialogActivation"
        icon="mdi-format-list-bulleted">
      </icon-tooltip-button>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{ carrier.name }} - Appointments</span>
          <v-spacer></v-spacer>
          <v-btn class="pa-3" icon light @click="dialog.value = false"
            ><v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center d-flex mt-0">
            <v-alert outlined type="info" dense border="left" class="d-inline-flex">
              Appointments for specific carriers can be exported on the
              <router-link
                :to="{ name: 'insights.reporting' }"
                @click.native="dialog.value = false"
                text
                >reporting page</router-link
              >.
            </v-alert>
          </v-row>
          <appointments-list
            :build-headers-fn="buildTableHeaders"
            :format-appointments-fn="formatAppointments"
            :show-export-button="false"
            :show-print-button="false"
            :items-per-page="5"
            :manage-data-internally="true"
            :query-object="{
              s: {
                'user.company.id': { $eq: carrier.id },
                status: novaCore.AppointmentStatus.Completed
              },
              join: ['user.company']
            }"></appointments-list>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Close </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    carrier: {
      type: Object,
      required: true
    },
    appointmentIds: {
      type: Array,
      required: false,
      default: () => []
    },
    iconColor: {
      type: String,
      required: false,
      default: 'secondary'
    },
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    tooltipText: {
      type: String,
      required: false,
      default: 'View appointments'
    }
  },
  data() {
    return {
      appointments: [],
      ready: false
    };
  },
  methods: {
    buildTableHeaders() {
      return [
        { text: 'Conf #', value: 'confirmationNumber', searchable: true, idx: 0, width: 75 },
        { text: 'Start', value: 'start', searchable: true },
        { text: 'Location', value: 'dock.warehouse.name', searchable: true },
        { text: 'Dock', value: 'dock.name', searchable: true },
        { text: 'Load Type', value: 'loadType.name', searchable: true },
        {
          text: this.$getSettingValue('referenceNumberDisplayName', this.$org?.settings),
          value: 'refNumber',
          searchable: true
        },
        { text: 'Status', value: 'status', searchable: true },
        { text: 'Dwell', value: 'dwellTime', searchable: false, sortable: false },
        { text: 'Cancel Lead', value: 'cancelLeadTime', searchable: false, sortable: false },
        { text: 'Late Time', value: 'lateTime', searchable: false, sortable: false },
        { text: 'Appointment ID', value: 'id', searchable: true },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
      ];
    },
    makeHumanTimeDiff(start, end, timezone) {
      return this.novaCore.formatMinutesToHuman(
        this.util.makeTimeDiff(start, end, timezone, 'minutes')
      );
    },
    formatAppointments(appointments) {
      const apptsCopy = this.novaCore.deepClone(appointments);
      apptsCopy.forEach(a => {
        const arriveT = a.statusTimeline[this.novaCore.AppointmentStatus.Arrived];
        const completeT = a.statusTimeline[this.novaCore.AppointmentStatus.Completed];
        const cancelT = a.statusTimeline[this.novaCore.AppointmentStatus.Cancelled];

        a.dwellTime =
          arriveT && completeT
            ? this.makeHumanTimeDiff(arriveT, completeT, a.dock.warehouse.timezone)
            : '----';

        a.cancelLeadTime = cancelT
          ? this.makeHumanTimeDiff(cancelT, a.start, a.dock.warehouse.timezone)
          : '----';

        a.lateTime = arriveT
          ? this.makeHumanTimeDiff(a.start, arriveT, a.dock.warehouse.timezone)
          : '----';
      });

      this.ready = true;

      return apptsCopy;
    },
    handleDialogActivation() {
      this.ready = false;
    }
  }
};
</script>
