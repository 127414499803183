<template>
  <v-card v-if="ready" class="elevation-0">
    <v-card-text class="pa-1">
      <bar-chart
        title="Appointment Status by Dock (including parallel capacity)"
        :data="chartData"
        :categories="categories"
        :colors="colors"
        :stacked="true"
        :show-data-labels="true">
      </bar-chart>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="px-5 pb-4 pt-5"> </v-card-actions>
  </v-card>
  <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
</template>

<script>
import insightsMixin from '@/components/mixins/insightsMixin';
import { globalThemeColors as statusColors } from '@/plugins/vuetifyPlugin';

/**
 * Appointments by Status Bar Chart
 * @displayName Appointments by Status Bar Chart
 */
export default {
  mixins: [insightsMixin],
  data() {
    return {
      apiEndpoint: 'status-by-dock',
      categories: [],
      colors: [],
      initialLookbackDays: 0
    };
  },
  methods: {
    formatXAxis(data) {
      return this.novaCore.ucWords(data);
    },
    formatData() {
      this.chartData = [];
      this.categories = [];

      // Very important to deep clone the raw Data, so we can consolidate
      // back and forth
      const data = this.novaCore.deepClone(this.rawData);
      const tempData = {};

      // Here we consolidate the child docks from the raw data into the main docks
      for (let dockName in data) {
        const originalDockName = dockName;

        if (this.novaCore.isCapacityChildByName(dockName) && this.consolidateCapacityDocks) {
          dockName = this.novaCore.getCapacityParentNameFromCapacityChildName(dockName);
          // If there are already some data for the capacity docks
          // let's aggregate the appointment count
          if (data[dockName]) {
            Object.keys(data[dockName]).forEach(status => {
              const capacityCount = parseInt(data[originalDockName][status]);
              const count = parseInt(parseInt(data[dockName][status]));
              data[dockName][status] = count + capacityCount;
            });
          }

          // Remove the capacity dock from the cloned data
          delete data[originalDockName];
        }
      }

      for (let dockName in data) {
        for (let status in data[dockName]) {
          if (!tempData[status]) {
            tempData[status] = [];
          }
          tempData[status].push(parseInt(data[dockName][status]));
        }
        this.categories.push(dockName);
      }

      for (let status in tempData) {
        this.chartData.push({
          name: this.novaCore.breakWordsAtCaps(status),
          data: tempData[status]
        });
        this.colors.push(statusColors[status.toLowerCase()].base);
      }

      this.ready = true;
    }
  },
  watch: {
    consolidateCapacityDocks() {
      this.formatData();
    }
  }
};
</script>
