var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              {
                ref: "form",
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.onConfirm.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("text-field", {
                          attrs: {
                            required: "",
                            tooltipText:
                              "Name of Warehouse location visible to Warehouse and Carriers",
                            rules:
                              _vm.$validator.rules.required("Warehouse Name"),
                            label: "Warehouse Name",
                          },
                          model: {
                            value: _vm.fields.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.fields, "name", $$v)
                            },
                            expression: "fields.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("text-field", {
                          attrs: {
                            label: "Store Number",
                            tooltipText: "Warehouse store number",
                          },
                          model: {
                            value: _vm.fields.facilityNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.fields, "facilityNumber", $$v)
                            },
                            expression: "fields.facilityNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("phone-number-field", {
                          attrs: {
                            "margin-top": "-7px",
                            validator: _vm.$validator,
                            "default-country": _vm.defaultPhoneCountry,
                            tooltipText:
                              "Warehouse contact phone number for Carriers",
                            label: "Phone",
                          },
                          model: {
                            value: _vm.fields.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.fields, "phone", $$v)
                            },
                            expression: "fields.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("text-field", {
                          attrs: {
                            type: "email",
                            tooltipText:
                              "Warehouse contact email for Carriers. It will be used as 'Reply To' address of the appointment emails.",
                            rules: _vm.$validator.rules.email,
                            label: "Warehouse Contact Email",
                          },
                          model: {
                            value: _vm.fields.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.fields, "email", $$v)
                            },
                            expression: "fields.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "align-field",
                        attrs: { cols: "12", sm: "6", md: "4" },
                      },
                      [
                        _c("country-select", {
                          attrs: {
                            "limit-options": "",
                            tooltipText: "Country of the Warehouse location",
                          },
                          model: {
                            value: _vm.editingAddress.country,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingAddress, "country", $$v)
                            },
                            expression: "editingAddress.country",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { class: { "align-field": _vm.manualAddressMode } },
                      [
                        !_vm.manualAddressMode
                          ? _c("address-autocomplete-field", {
                              ref: "searchAddressField",
                              attrs: {
                                required: "",
                                rules: _vm.editingAddress.street
                                  ? []
                                  : _vm.$validator.rules.required("Address"),
                                "clear-on-country-change": "",
                                disabled: !_vm.editingAddress.country,
                                "initial-value": _vm.editingAddress.street,
                                country: _vm.editingAddress.country,
                                tooltipText:
                                  "Address of the Warehouse location visible to carriers",
                              },
                              on: {
                                filled: _vm.fillFullAddress,
                                "manual-override": _vm.enterManualAddressMode,
                              },
                              model: {
                                value: _vm.geocodingData,
                                callback: function ($$v) {
                                  _vm.geocodingData = $$v
                                },
                                expression: "geocodingData",
                              },
                            })
                          : _c("street-field", {
                              ref: "manualStreetField",
                              attrs: {
                                required: "",
                                rules:
                                  _vm.$validator.rules.required(
                                    "Street Address"
                                  ),
                              },
                              on: { search: _vm.enterSearchAddressMode },
                              model: {
                                value: _vm.editingAddress.street,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editingAddress, "street", $$v)
                                },
                                expression: "editingAddress.street",
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.manualAddressMode && _vm.editingAddress.city
                  ? _c("address-display", {
                      attrs: {
                        zip: _vm.editingAddress.zip,
                        city: _vm.editingAddress.city,
                        state: _vm.editingAddress.state,
                        street: _vm.editingAddress.street,
                        timezone: _vm.editingAddress.timezone,
                      },
                    })
                  : _vm.manualAddressMode
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pr-0",
                            attrs: { cols: "12", sm: "6", md: "4" },
                          },
                          [
                            _c("text-field", {
                              attrs: {
                                required: "",
                                type: "text",
                                rules: _vm.$validator.rules.required("City"),
                                label: "City",
                              },
                              model: {
                                value: _vm.editingAddress.city,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editingAddress, "city", $$v)
                                },
                                expression: "editingAddress.city",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "3" } },
                          [
                            _vm.editingAddress.country === "USA" ||
                            _vm.editingAddress.country === "CAN"
                              ? _c("state-select", {
                                  attrs: {
                                    required: "",
                                    rules:
                                      _vm.$validator.rules.required("State"),
                                    label: "State",
                                    country: _vm.editingAddress.country,
                                  },
                                  model: {
                                    value: _vm.editingAddress.state,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editingAddress, "state", $$v)
                                    },
                                    expression: "editingAddress.state",
                                  },
                                })
                              : _c("text-field", {
                                  attrs: {
                                    required: "",
                                    type: "text",
                                    rules:
                                      _vm.$validator.rules.required("State"),
                                    label: "State",
                                  },
                                  model: {
                                    value: _vm.editingAddress.state,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editingAddress, "state", $$v)
                                    },
                                    expression: "editingAddress.state",
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pl-0",
                            attrs: { cols: "12", sm: "6", md: "2" },
                          },
                          [
                            _c("text-field", {
                              attrs: {
                                required: "",
                                type: "text",
                                rules: _vm.$validator.rules.required("Zip"),
                                placeholder: "85331",
                                label: "Zip / Postal Code",
                              },
                              model: {
                                value: _vm.editingAddress.zip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editingAddress, "zip", $$v)
                                },
                                expression: "editingAddress.zip",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "3" } },
                          [
                            _c("timezone-select", {
                              attrs: {
                                rules:
                                  _vm.$validator.rules.required("Timezone"),
                                label: "Timezone",
                                required: "",
                              },
                              model: {
                                value: _vm.editingAddress.timezone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editingAddress, "timezone", $$v)
                                },
                                expression: "editingAddress.timezone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("email-list-field", {
                          staticClass: "full-width mb-4",
                          attrs: {
                            "read-only":
                              !_vm.$rolePermissions.canUpdateWarehouse,
                            label: "Default Email Subscribers",
                            "prepend-icon": "mdi-email-outline",
                            tooltipText:
                              "Default email subscribers for appointments at this facility.",
                            "small-chips": "",
                            small: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.fields.ccEmails,
                            callback: function ($$v) {
                              _vm.$set(_vm.fields, "ccEmails", $$v)
                            },
                            expression: "fields.ccEmails",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-textarea", {
                  attrs: { rows: "2" },
                  nativeOn: {
                    keyup: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "with-tooltip" },
                            [
                              _vm._v(" Notes "),
                              _c(
                                "help-icon-tooltip",
                                { attrs: { iconStyleClass: "grey darken-2" } },
                                [
                                  _vm._v(
                                    " Notes that are internal to the Warehouse "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.fields.notes,
                    callback: function ($$v) {
                      _vm.$set(_vm.fields, "notes", $$v)
                    },
                    expression: "fields.notes",
                  },
                }),
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("h2", { staticClass: "mt-16 mb-2 black--text" }, [
                        _vm._v("Scheduling Portal Availability"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "disable-label-click" },
                      [
                        _c("carrier-scheduling-toggle", {
                          staticClass: "pl-2 pb-2",
                          attrs: {
                            entity: "Warehouse",
                            disabled: !_vm.$rolePermissions.canCreateWarehouse,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          model: {
                            value: _vm.fields.allowCarrierScheduling,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.fields,
                                "allowCarrierScheduling",
                                $$v
                              )
                            },
                            expression: "fields.allowCarrierScheduling",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }