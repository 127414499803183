var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-3" },
    [
      _c("div", { attrs: { id: "mobile-dialog-overlay" } }),
      _c(
        "v-stepper",
        {
          staticClass: "status-stepper pb-0",
          attrs: { flat: true, id: "mobile-status-timeline", vertical: "" },
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _vm._l(_vm.visibleStatuses, function (status, index) {
            return [
              _c(
                "div",
                {
                  key: `status-${index}`,
                  staticClass: "is-relative step",
                  class: { "is-visible": status.show },
                },
                [
                  status.name !== _vm.lastVisibleStatus.name && status.show
                    ? _c("div", {
                        key: `step-${index}-divider`,
                        staticClass: "mobile-status-divider",
                      })
                    : _vm._e(),
                  _c(
                    "v-stepper-step",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: status.show,
                          expression: "status.show",
                        },
                      ],
                      class: {
                        "is-requested":
                          _vm.novaCore.isRequested(_vm.appointment) &&
                          status.name === _vm.requestedStatus,
                      },
                      attrs: {
                        step: status.stepNumber,
                        "complete-icon": _vm.getCompletedIcon(status),
                        complete:
                          status.stepNumber <= _vm.currentStep ||
                          _vm.currentStep === 0,
                        color: status.name.toLowerCase(),
                      },
                    },
                    [
                      !_vm.displayTime(status) ||
                      (_vm.displayTime(status) &&
                        !_vm.appointment.statusTimeline[status.name])
                        ? _c(
                            "div",
                            { staticClass: "font-weight-medium status-name" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.novaCore.breakWordsAtCaps(status.name)
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm.displayTime(status) &&
                          status.name ===
                            _vm.novaCore.AppointmentStatus.Cancelled
                        ? [
                            _c(
                              "div",
                              { staticClass: "font-weight-medium status-name" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.novaCore.breakWordsAtCaps(status.name)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-wrap-nowrap font-size-x-small font-weight-light",
                              },
                              [
                                _vm.appointment.statusTimeline[status.name]
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "font-size-x-small" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatDateTime(
                                                  _vm.appointment
                                                    .statusTimeline[
                                                    status.name
                                                  ],
                                                  _vm.novaCore
                                                    .LuxonDateTimeFormats
                                                    .MonthDayYearSlashedTimeAMPM,
                                                  _vm.novaCore
                                                    .LuxonDateTimeFormats
                                                    .MonthDayYearSlashedTime24
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("div", {
                              staticClass: "caption text-uppercase",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.timeDiffInfo(status.name)
                                ),
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm.displayTime(status) &&
                      _vm.appointment.statusTimeline[status.name] &&
                      status.name !== _vm.novaCore.AppointmentStatus.Cancelled
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "column-btn align-left btn-plain white-bg pa-0 color-inherit no-hover mobile-timeline-edit-btn",
                              attrs: { ripple: false, elevation: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.openEditor(status.name)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-medium status-name black--text",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.novaCore.breakWordsAtCaps(
                                          status.name
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-wrap-nowrap font-size-x-small font-weight-light",
                                },
                                [
                                  _vm.appointment.statusTimeline[status.name]
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "font-size-x-small" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatDateTime(
                                                    _vm.appointment
                                                      .statusTimeline[
                                                      status.name
                                                    ],
                                                    _vm.novaCore
                                                      .LuxonDateTimeFormats
                                                      .MonthDayYearSlashedTimeAMPM,
                                                    _vm.novaCore
                                                      .LuxonDateTimeFormats
                                                      .MonthDayYearSlashedTime24
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-3 mobile-icon-btn",
                                          },
                                          [_vm._v("mdi-pencil")]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _c("div", {
                                staticClass: "caption text-uppercase",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.timeDiffInfo(status.name)
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          }),
          _vm.lastVisibleStatus.name !== _vm.appointment.status
            ? [
                !_vm.areAllStatusesVisible
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row align-center v-stepper__step",
                      },
                      [
                        _c("v-icon", { attrs: { color: "secondary" } }, [
                          _vm._v("mdi-chevron-down"),
                        ]),
                        _c(
                          "v-btn",
                          {
                            staticClass: "view-all-btn",
                            attrs: {
                              plain: "",
                              small: "",
                              "data-testid": "view-all-toggle-button",
                            },
                            on: { click: _vm.toggleCollapse },
                          },
                          [_vm._v(" View All ")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row align-center v-stepper__step",
                      },
                      [
                        _c("v-icon", { attrs: { color: "secondary" } }, [
                          _vm._v("mdi-chevron-up"),
                        ]),
                        _c(
                          "v-btn",
                          {
                            staticClass: "view-all-btn",
                            attrs: {
                              plain: "",
                              small: "",
                              "data-testid": "view-all-toggle-button",
                            },
                            on: { click: _vm.toggleCollapse },
                          },
                          [_vm._v(" Collapse ")]
                        ),
                      ],
                      1
                    ),
              ]
            : _vm._e(),
          _c(
            "v-stepper-items",
            {
              ref: "timelineactions",
              attrs: { id: "mobile-status-timeline-actions" },
            },
            [
              !_vm.isCancelled
                ? [
                    _vm._l(_vm.statuses, function (status, index) {
                      return [
                        _c(
                          "v-stepper-content",
                          {
                            key: `status-${index}`,
                            staticClass: "pa-0",
                            attrs: {
                              step: status.stepNumber,
                              transition: "fab-transition",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "card-content",
                                attrs: { elevation: "6", outlined: "" },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _vm.$rolePermissions
                                          .canUpdateAppointmentStatus &&
                                        !_vm.readOnly
                                          ? _c(
                                              "v-row",
                                              { staticClass: "justify-center" },
                                              [
                                                _vm.shouldShowNextHPButton(
                                                  status
                                                )
                                                  ? _c(
                                                      "secondary-button",
                                                      {
                                                        staticClass:
                                                          "status-proceed-button mb-2",
                                                        attrs: {
                                                          block: "",
                                                          loading:
                                                            _vm.btnHappy_loading,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.btnHappy_onClick(
                                                              status
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getButtonLabel(
                                                                _vm.statusMap[
                                                                  _vm.getNextHappyPathStatus(
                                                                    status
                                                                  )
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.checkForTriggerOnStatus(
                                                  status.name
                                                )
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          block: "",
                                                          outlined: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.activeTrigger =
                                                              _vm.checkForTriggerOnStatus(
                                                                status.name
                                                              )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-text-box-edit-outline"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.checkForTriggerOnStatus(
                                                                status.name
                                                              ).flow.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                status.canUndo
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "status-undo-button",
                                                        attrs: {
                                                          block: "",
                                                          loading:
                                                            _vm.btnUndo_loading,
                                                          text: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.btnUndo_onClick()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [_vm._v("mdi-undo")]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              `Undo ${_vm.novaCore.breakWordsAtCaps(
                                                                status.name
                                                              )} Status`
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.getNextNonHappyPathStatus(
                                                  status
                                                ) && !status.isEndState
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "status-undo-button",
                                                        attrs: {
                                                          loading:
                                                            _vm.btnNonHappy_loading,
                                                          text: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.btnNonHappy_onClick(
                                                              status
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.getStatusIcon(
                                                          _vm.statusMap[
                                                            _vm.getNextNonHappyPathStatus(
                                                              status
                                                            )
                                                          ]
                                                        )
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getStatusIcon(
                                                                        _vm
                                                                          .statusMap[
                                                                          _vm.getNextNonHappyPathStatus(
                                                                            status
                                                                          )
                                                                        ]
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getButtonLabel(
                                                                _vm.statusMap[
                                                                  _vm.getNextNonHappyPathStatus(
                                                                    status
                                                                  )
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    }),
                  ]
                : _c(
                    "div",
                    { staticClass: "is-cancelled" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "card-content",
                          attrs: { elevation: "6", outlined: "" },
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                [
                                  _vm.$rolePermissions
                                    .canUpdateAppointmentStatus && !_vm.readOnly
                                    ? _c(
                                        "v-row",
                                        { staticClass: "justify-center" },
                                        [
                                          _vm._v(
                                            " This appointment was cancelled "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            2
          ),
        ],
        2
      ),
      _c("status-timeline-edit-dialog-mobile", {
        attrs: {
          "content-class": "status-timeline-edit-dialog-mobile",
          fullscreen: "",
          transition: "dialog-bottom-transition",
          "external-activator": "",
          "show-dialog": _vm.showEditDialog,
          "status-to-edit": _vm.statusToEdit,
          appointment: _vm.appointment,
        },
        on: {
          "appointment-updated": function ($event) {
            return _vm.$emit("appointment-updated")
          },
          close: function ($event) {
            _vm.showEditDialog = false
          },
        },
      }),
      _c("custom-forms-flow-dialog", {
        attrs: {
          "external-activator": true,
          warehouse: _vm.warehouse,
          "show-dialog": _vm.hasActiveTrigger,
          "object-id": _vm.appointment.id,
          trigger: _vm.activeTrigger,
          "status-change": _vm.pendingStatusChange,
          "stack-actions": "",
          fullscreen: "",
        },
        on: {
          close: _vm.cleanUpCustomFormsData,
          update: _vm.updateCustomFormsData,
          create: _vm.submitStatusWithCustomFormsData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }