import { UserRole } from '@nova/core';

import YardViewPage from '@/modules/yard/YardViewPage.vue';
import YardViewSpotAreaPage from '@/modules/yard/YardViewSpotAreaPage.vue';

export default [
  {
    path: '/yard',
    name: 'yard',
    component: YardViewPage,
    props: true,
    meta: {
      title: 'Live Yard View',
      customHeader: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER
    }
  },
  {
    path: '/yard/:warehouseId',
    name: 'yard-warehouse',
    component: YardViewPage,
    props: true,
    meta: {
      title: 'Live Yard View',
      customHeader: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER
    }
  },
  {
    path: '/yard/:warehouseId/spot-area/add',
    name: 'yard-add-spot-area',
    component: YardViewSpotAreaPage,
    props: true,
    meta: {
      title: 'Live Yard View',
      customHeader: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      isCreating: true
    }
  },
  {
    path: '/yard/:warehouseId/spot-area/:spotAreaId',
    name: 'yard-spot-area-detail',
    component: YardViewSpotAreaPage,
    props: true,
    meta: {
      title: 'Live Yard View',
      customHeader: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER
    }
  }
];
