<template>
  <div class="container--fluid">
    <div class="d-flex justify-space-between">
      <h3 class="my-6">
        <v-btn icon small rounded @click="back">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        {{ title }}
      </h3>
      <span @click="showEnableConfirm = true" v-if="trigger?.flow">
        <v-switch
          dense
          inset
          v-model="trigger.isActive"
          :label="`Form ${statusText(true)}`"></v-switch>
      </span>
    </div>
    <v-container fluid>
      <custom-forms-flow
        :readonly="$isWarehouseReadOnly(warehouse.id, warehouse.orgId)"
        :warehouse="warehouse"
        @data-updated="$emit('data-updated')"
        :trigger="trigger"></custom-forms-flow>
    </v-container>

    <confirm
      :should-show="showEnableConfirm"
      is-manual-mode
      persistent
      :icon="null"
      :title="`${novaCore.upperFirst(statusText(false))} Form`"
      :width="650"
      @result="handleFormActiveChange">
      <template v-slot:message>
        <p>
          Are you sure you want to
          <strong>{{ statusText(false) }}</strong>
          the {{ title }}
        </p>
        <p v-if="trigger.isActive">
          <template v-if="featureData.enableDisclaimer">
            {{ featureData.enableDisclaimer }}
          </template>
          <template v-else>
            This form will now always be available for the
            {{ novaCore.upperFirst(trigger.app) }} after an appointment is completed.
          </template>
        </p>
        <p v-else>
          <template v-if="featureData.disableDisclaimer">
            {{ featureData.disableDisclaimer }}
          </template>
          <template v-else>
            This form will no longer be available for the
            {{ novaCore.upperFirst(trigger.app) }}
            after an appointment is completed.
          </template>
        </p>
      </template>
    </confirm>
  </div>
</template>

<script>
export default {
  props: {
    warehouse: {
      type: Object,
      required: true
    },
    trigger: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showEnableConfirm: false
    };
  },
  computed: {
    title() {
      return `${
        this.novaCore.CustomFormsFeaturesData[this.trigger.feature].title
      } Form for ${this.novaCore.upperFirst(this.trigger.app)}`;
    },
    featureData() {
      return this.novaCore.CustomFormsFeaturesData[this.trigger.feature];
    }
  },
  methods: {
    trackMixpanelFormStatusChange(status) {
      const eventName = status ? 'ENABLE_FORM' : 'DISABLE_FORM';
      return this.mixpanel?.track(this.mixpanel.events.MODULE.CUSTOM_FORMS[eventName], {
        'Custom Form Application': this.trigger.app,
        'Custom Form Type': this.trigger.feature,
        'Warehouse ID': this.warehouse.id,
        'Warehouse Name': this.warehouse.name,
        'Org ID': this.$org.id,
        'Org Name': this.$org.name
      });
    },
    statusText(past = true, inverse = false) {
      const condition = inverse ? !this.trigger.isActive : this.trigger.isActive;
      const text = condition ? 'enable' : 'disable';
      return past ? `${text}d` : text;
    },
    async handleFormActiveChange(value) {
      this.showEnableConfirm = false;

      if (value) {
        const response = await axios.patch(`custom-forms/trigger/${this.trigger.id}`, {
          isActive: this.trigger.isActive
        });
        if (response?.data?.data) {
          this.notify(
            `${this.title} is ${this.trigger.isActive ? 'enabled' : 'disabled'}`,
            'success'
          );
          this.trackMixpanelFormStatusChange(this.trigger.isActive);
        }
      } else {
        this.trigger.isActive = !this.trigger.isActive;
      }
    },
    back() {
      this.$emit('back');
    }
  }
};
</script>
