<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Log In to Opendock"
    loading-message="Logging In..."
    @close="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button
        before-icon="login"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2"
        >Log In</primary-button
      >
    </template>
    <template slot="body">
      <v-alert :type="alertType" class="mb-0" shaped v-if="alertMessage">{{
        alertMessage
      }}</v-alert>
      <login-form @close="close"></login-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for the login form
 * @displayName Login Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Alert message to display in dialog
     */
    alertMessage: {
      type: String,
      required: false
    },
    /**
     * Alert type
     * @values success | error
     */
    alertType: {
      type: String,
      required: false,
      default: 'success'
    }
  }
};
</script>
