var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "font-size-large font-weight-bold" },
        [
          _vm._v(" Edit Appointment Notification "),
          _c("v-spacer"),
          _c("icon-tooltip-button", {
            attrs: {
              tooltip: "Close editing window",
              "x-large": "",
              icon: "mdi-close",
            },
            on: {
              click: function ($event) {
                return _vm.$eventHub.$emit("set-edit-notification-key", null)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-card-subtitle",
        { staticClass: "font-size-medium black--text pt-4" },
        [
          _c("strong", [_vm._v("Subject:")]),
          _vm._v(
            " " +
              _vm._s(
                _vm.novaCore.makeInjectedNotificationSubject(
                  _vm.novaCore.Notifications[_vm.notificationType].emailSubject,
                  "Example Org",
                  "Example Warehouse",
                  1234,
                  "ExampleTag"
                )
              ) +
              " "
          ),
        ]
      ),
      _c("v-card-text", { staticClass: "mt-8" }, [
        _c("div", { staticClass: "d-flex justify-start" }, [
          _c(
            "div",
            [
              _c(
                "v-form",
                {
                  staticClass: "pt-0",
                  attrs: { "data-testid": "notification-config-form" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "pa-5 elevation-3 rounded" },
                    [
                      _c(
                        "label",
                        { staticClass: "v-label font-weight-bold black--text" },
                        [
                          _vm._v(" Display ID Type: "),
                          _c(
                            "help-icon-tooltip",
                            {
                              attrs: {
                                iconStyleClass: "grey darken-2",
                                zIndexClass: "z-0",
                              },
                            },
                            [
                              _vm._v(
                                " The type of identification that will be displayed on the top of the email. "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mt-2",
                          attrs: { row: "", "hide-details": "" },
                          model: {
                            value:
                              _vm.notificationConfig.settings.appointmentIdType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.notificationConfig.settings,
                                "appointmentIdType",
                                $$v
                              )
                            },
                            expression:
                              "notificationConfig.settings.appointmentIdType",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c("v-radio", {
                                class: { "mr-1": _vm.isReferenceNumberHidden },
                                attrs: {
                                  value:
                                    _vm.novaCore.AppointmentIdType
                                      .REFERENCE_NUMBER,
                                  disabled: _vm.isReferenceNumberHidden,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$getSettingValue(
                                                  "referenceNumberDisplayName",
                                                  _vm.settings
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _vm.isReferenceNumberHidden
                                ? _c(
                                    "help-icon-tooltip",
                                    {
                                      attrs: {
                                        iconStyleClass: "grey darken-2",
                                        zIndexClass: "z-0",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.hiddenRefNumberMessage) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("v-radio", {
                            class: { "ml-2": _vm.isReferenceNumberHidden },
                            attrs: {
                              value:
                                _vm.novaCore.AppointmentIdType
                                  .CONFIRMATION_NUMBER,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", [_vm._v("Confirmation Number")]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-5 pa-3 elevation-3 rounded" },
                    _vm._l(
                      _vm.novaCore.NotificationContentBlocks,
                      function (contentBlock) {
                        return _c(
                          "div",
                          {
                            key: contentBlock.blockKey,
                            staticClass: "content-block-container pa-2",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "v-label font-weight-bold black--text",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(contentBlock.meta.title) + ": "
                                ),
                                _c(
                                  "help-icon-tooltip",
                                  {
                                    attrs: { iconStyleClass: "grey darken-2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(contentBlock.meta.description) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-radio-group",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  row: "",
                                  "hide-details": "",
                                  dense: "",
                                },
                                model: {
                                  value:
                                    _vm.notificationConfig.contentBlocks[
                                      _vm.getContentBlockIdxByKey(
                                        contentBlock.blockKey
                                      )
                                    ].styleType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.notificationConfig.contentBlocks[
                                        _vm.getContentBlockIdxByKey(
                                          contentBlock.blockKey
                                        )
                                      ],
                                      "styleType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "\n                  notificationConfig.contentBlocks[getContentBlockIdxByKey(contentBlock.blockKey)]\n                    .styleType\n                ",
                                },
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    value: "hidden",
                                    label: "Hide this block",
                                  },
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    value: "standard",
                                    label: "Neutral",
                                  },
                                }),
                                _c("v-radio", {
                                  attrs: { value: "info", label: "Info" },
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    value: "alert",
                                    label: "Alert/Critical",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("rich-text-input", {
                              staticClass: "mt-7 notification-config-wysiwyg",
                              nativeOn: {
                                keyup: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                              model: {
                                value:
                                  _vm.notificationConfig.contentBlocks[
                                    _vm.getContentBlockIdxByKey(
                                      contentBlock.blockKey
                                    )
                                  ].content,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.notificationConfig.contentBlocks[
                                      _vm.getContentBlockIdxByKey(
                                        contentBlock.blockKey
                                      )
                                    ],
                                    "content",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n                  notificationConfig.contentBlocks[getContentBlockIdxByKey(contentBlock.blockKey)]\n                    .content\n                ",
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-5 pa-3 elevation-3 rounded" },
                    [
                      _c(
                        "label",
                        { staticClass: "v-label font-weight-bold black--text" },
                        [_vm._v("Appointment Details:")]
                      ),
                      _c(
                        "outline-button",
                        {
                          staticClass: "ml-3",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.showAppointmentFieldEditDialog = true
                            },
                          },
                        },
                        [_vm._v(" Select and Reorder ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "primary-button",
                    {
                      staticClass: "mt-5",
                      on: { click: _vm.saveNotificationConfig },
                    },
                    [_vm._v(" Apply & Save Changes ")]
                  ),
                  _c(
                    "text-button",
                    {
                      staticClass: "mt-5 ml-4",
                      attrs: { "before-icon": "eye" },
                      on: { click: _vm.previewNotification },
                    },
                    [_vm._v(" Preview ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ml-10", attrs: { id: "email-preview-container" } },
            [
              _c("h3", { staticClass: "mb-2" }, [_vm._v("Preview")]),
              _c("email-notification-preview", {
                key: _vm.previewRender,
                attrs: {
                  "notification-key": _vm.notificationType,
                  "preview-data": _vm.previewData,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.showAppointmentFieldEditDialog,
            callback: function ($$v) {
              _vm.showAppointmentFieldEditDialog = $$v
            },
            expression: "showAppointmentFieldEditDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("dialog-header", {
                attrs: { header: "Appointment Details" },
                on: {
                  close: function ($event) {
                    _vm.showAppointmentFieldEditDialog = false
                  },
                },
              }),
              _c("v-card-text", [
                _c("h3", [
                  _vm._v(
                    "Select and order the fields to be displayed on this notification"
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { id: "appointment-fields-list" } },
                  _vm._l(
                    _vm.notificationConfig.settings
                      .displayableAppointmentFields,
                    function (field) {
                      return _c("v-checkbox", {
                        key: field,
                        attrs: {
                          label: _vm.novaCore.snakeToTitleCase(field),
                          value: field,
                          "hide-details": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function () {
                                return [
                                  _c("v-icon", { staticClass: "handle" }, [
                                    _vm._v("mdi-arrow-split-horizontal"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value:
                            _vm.notificationConfig.settings
                              .displayableAppointmentFields,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.notificationConfig.settings,
                              "displayableAppointmentFields",
                              $$v
                            )
                          },
                          expression:
                            "notificationConfig.settings.displayableAppointmentFields",
                        },
                      })
                    }
                  ),
                  1
                ),
                _c(
                  "div",
                  _vm._l(
                    Object.values(_vm.novaCore.AppointmentNotificationField),
                    function (field) {
                      return _c("v-checkbox", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.notificationConfig.settings.displayableAppointmentFields.includes(
                                field
                              ),
                            expression:
                              "!notificationConfig.settings.displayableAppointmentFields.includes(field)",
                          },
                        ],
                        key: field,
                        attrs: {
                          value: field,
                          readonly:
                            field ===
                              _vm.novaCore.AppointmentNotificationField
                                .REFERENCE_NUMBER &&
                            _vm.isReferenceNumberHidden,
                          "hide-details": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function () {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.notificationConfig.settings.displayableAppointmentFields.includes(
                                            field
                                          ),
                                      },
                                    },
                                    [_vm._v(" mdi-arrow-split-horizontal ")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", { staticClass: "mr-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.novaCore.snakeToTitleCase(field)
                                      )
                                    ),
                                  ]),
                                  field ===
                                    _vm.novaCore.AppointmentNotificationField
                                      .REFERENCE_NUMBER &&
                                  _vm.isReferenceNumberHidden
                                    ? _c(
                                        "help-icon-tooltip",
                                        {
                                          attrs: {
                                            iconStyleClass: "grey darken-2",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.hiddenRefNumberMessage
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value:
                            _vm.notificationConfig.settings
                              .displayableAppointmentFields,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.notificationConfig.settings,
                              "displayableAppointmentFields",
                              $$v
                            )
                          },
                          expression:
                            "notificationConfig.settings.displayableAppointmentFields",
                        },
                      })
                    }
                  ),
                  1
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "outline-button",
                    { on: { click: _vm.resetAppointmentFields } },
                    [_vm._v("Nevermind")]
                  ),
                  _c(
                    "primary-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showAppointmentFieldEditDialog = false
                        },
                      },
                    },
                    [_vm._v("Confirm")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }