var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        ref: "select",
        attrs: {
          height: "20px",
          disabled: _vm.disabled,
          items: _vm.items,
          dense: "",
          clearable: "",
          "open-on-clear": "",
        },
        on: { input: (value) => _vm.$emit("input", value || "") },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v("Select a Warehouse")]
            },
            proxy: true,
          },
        ]),
      },
      "v-select",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }