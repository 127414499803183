<template>
  <v-clamp autoresize v-bind="[$props, $attrs]">
    <slot></slot>
    <template #after="{ toggle, clamped, expanded }">
      <button
        v-if="clamped === true || expanded === true"
        class="mx-3"
        @click="
          $e => {
            $e.preventDefault();
            toggle();
          }
        ">
        {{ clamped === true ? 'Show more' : 'Show less' }}
      </button>
    </template>
  </v-clamp>
</template>

<script>
import VClamp from 'vue-clamp';

export default {
  components: {
    VClamp
  },
  props: {
    maxLines: {
      type: Number,
      default: 1
    }
  }
};
</script>
