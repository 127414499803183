<template>
  <div>
    <v-btn text v-if="asButton" @click="addSpot" data-testid="yard-area-add-spots-button">
      <v-icon small>mdi-plus</v-icon>
      Add Spots
    </v-btn>
    <v-card
      v-else
      :width="cardWidth"
      :height="cardHeight"
      class="ma-1 cursor-pointer d-flex flex-column align-center justify-center text-center add-card grey--text"
      elevation="0"
      data-testid="yard-area-add-spots-card"
      @click="addSpot">
      <v-card-text>
        <v-icon small>mdi-plus</v-icon>
      </v-card-text>
    </v-card>
    <yard-view-spot-generator-dialog v-model="generatorDialogOpen" />
  </div>
</template>

<script>
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

import { useEventHub } from '@/composables';
import YardViewSpotGeneratorDialog from '@/modules/yard/components/Spot/Generator/YardViewSpotGeneratorDialog.vue';

export default defineComponent({
  name: 'YardViewSpotAdd',
  components: { YardViewSpotGeneratorDialog },

  props: {
    spotArea: {
      type: Object,
      required: true
    },
    asButton: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const yardStore = useYard();
    const eventHub = useEventHub();
    const generatorDialogOpen = ref(false);

    const addSpot = () => (generatorDialogOpen.value = true);

    onMounted(() => {
      eventHub.$on('close-spot-generator-dialog', () => {
        generatorDialogOpen.value = false;
      });
    });

    onBeforeUnmount(() => {
      eventHub.$off('close-spot-generator-dialog');
    });

    return {
      cardWidth: yardStore.spotCardWidth,
      cardHeight: yardStore.spotCardHeight,
      generatorDialogOpen,
      addSpot
    };
  }
});
</script>
<style lang="scss" scoped>
.add-card {
  border: 1px dashed !important;
}
</style>
