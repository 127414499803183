<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Update User Info"
    :scrollable="false"
    :loading="false"
    loading-message="Updating User..."
    @close="close"
    @confirm="submit">
    <template #activator="slotProps" v-if="!externalActivator">
      <!--
        @slot Custom activator for the dialog
        @binding {object} slotProps Object containing {on, attrs}
      -->
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button
        v-else
        before-icon="upload"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2">
        Update User
      </primary-button>
    </template>
    <template #body>
      <edit-user-form
        @close="close"
        @saved="data => $emit('saved', data)"
        :user="user"
        v-if="user.id !== $me.id"
        ref="editUserForm"></edit-user-form>
      <my-account-form
        v-else
        @close="close"
        @saved="data => $emit('saved', data)"
        ref="myAccountForm"></my-account-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit user form
 * @displayName Edit User Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * User object
     */
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    submit() {
      if (this.user.id !== this.$me.id) {
        this.$refs.editUserForm.submit();
      } else {
        this.$refs.myAccountForm.submit();
      }
    }
  }
};
</script>
