<template>
  <div class="mt-8">
    <h2>Warehouse Performance</h2>
    <PowerBIReportEmbed
      v-if="embedConfig"
      :embedConfig="embedConfig"
      :phasedEmbedding="false"
      cssClassName="report-container"></PowerBIReportEmbed>
  </div>
</template>

<script>
import { PowerBIReportEmbed } from 'powerbi-client-vue-js';
import { models } from 'powerbi-client';

export default {
  components: { PowerBIReportEmbed },
  mixins: [],
  data() {
    return {
      loading: false,
      embedConfig: null
    };
  },
  methods: {
    async fetchReportData() {
      this.loading = true;
      axios
        .get('/reporting/warehouse-insights')
        .then(response => {
          this.embedConfig = {
            type: 'report',
            id: response.data.reportId,
            tokenType: models.TokenType.Embed,
            accessToken: response.data.embedToken
          };
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchReportData();
  }
};
</script>
<style scoped lang="scss">
.report-container {
  height: 100vh;
  margin: 8px auto;
  width: 100%;
}
</style>
