var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { ref: "appointmentCard", class: _vm.appointmentClass },
    [
      _vm.isReserve
        ? [
            _c("div", [
              _c("strong", [_vm._v(_vm._s(_vm.eventNotes))]),
              _vm._v(" (" + _vm._s(_vm.eventDuration) + " min) "),
            ]),
          ]
        : [
            _c(
              "div",
              { staticClass: "appointment-half-card" },
              [
                _vm.event.status !== _vm.novaCore.AppointmentStatus.Requested
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-2",
                        class: `${_vm.event.color}`,
                        staticStyle: { margin: "0 !important" },
                        attrs: { label: "", "text-color": _vm.chipTextColor },
                      },
                      [_vm._v(_vm._s(_vm.status) + " ")]
                    )
                  : _c(
                      "v-icon",
                      {
                        staticClass: "requested-icon",
                        attrs: { small: "", color: "requested darken-3" },
                      },
                      [_vm._v(" mdi-clock-alert ")]
                    ),
                _c("strong", [_vm._v(_vm._s(_vm.companyName))]),
              ],
              1
            ),
            _vm.eventEta
              ? _c("section", [
                  _c("span", { staticClass: "font-size-small ml-2" }, [
                    _c("strong", [_vm._v("New ETA: ")]),
                    _vm._v(_vm._s(_vm.eventEta)),
                  ]),
                ])
              : _vm._e(),
            _c("section", { staticClass: "appointment-load-type" }, [
              _c("strong", { staticClass: "text-right mr-1 font-size-base" }, [
                _vm._v(" #" + _vm._s(_vm.refNumber)),
              ]),
              _c("span", { staticClass: "font-size-base" }, [
                _vm._v(_vm._s(_vm.loadTypeName)),
              ]),
            ]),
            _vm.showAppointmentFields && !_vm.hideAppointmentFields
              ? _c(
                  "section",
                  { staticClass: "appointment-fields" },
                  _vm._l(_vm.customFields, function (field) {
                    return _c(
                      "div",
                      {
                        key: field.label,
                        staticClass: "text-sm-caption text--secondary p-0 m-0",
                      },
                      [
                        _c("strong", [_vm._v(_vm._s(field.label))]),
                        _vm._v(": " + _vm._s(field.value) + " "),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("section", { staticClass: "event-footer" }, [
              _vm.showTags
                ? _c(
                    "div",
                    _vm._l(
                      _vm.event.formattedTags.renderableTags,
                      function (tag) {
                        return _c(
                          "v-chip",
                          {
                            key: tag.name,
                            staticClass: "px-2 mb-1 mr-1 font-weight-bold",
                            attrs: {
                              color: tag.color,
                              "text-color": tag.textColor,
                            },
                          },
                          [_vm._v(" " + _vm._s(tag.name) + " ")]
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ]),
            _vm.isInProgress
              ? _c(
                  "div",
                  { staticClass: "progress-bar" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-space-between mx-1" },
                      [
                        _c("small", {
                          domProps: {
                            innerHTML: _vm._s(_vm.inProgressData.timeDwell),
                          },
                        }),
                        _c("small", {
                          domProps: {
                            innerHTML: _vm._s(_vm.inProgressData.timeRemaining),
                          },
                        }),
                      ]
                    ),
                    _c("v-progress-linear", {
                      attrs: {
                        color: "amber",
                        height: "8",
                        value: _vm.inProgressData.progressPercentage,
                        "background-opacity": "0.3",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }