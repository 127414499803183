var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "v-card",
        { staticClass: "elevation-0" },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-1" },
            [
              _c("bar-chart", {
                attrs: {
                  title:
                    "Appointment Status by Dock (including parallel capacity)",
                  data: _vm.chartData,
                  categories: _vm.categories,
                  colors: _vm.colors,
                  stacked: true,
                  "show-data-labels": true,
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-card-actions", { staticClass: "px-5 pb-4 pt-5" }),
        ],
        1
      )
    : _c("v-skeleton-loader", {
        staticClass: "mx-auto",
        attrs: { type: "card" },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }