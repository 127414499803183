<template>
  <form-base>
    <template #form>
      <v-form>
        <v-row>
          <v-col>
            <h4>
              This tool will transfer all appointments from one carrier to another, no notification
              will be triggered for this change. Make sure you clear out with both carriers before
              doing this change!
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="loading" class="mt-4">
            <v-card-text>
              Loading Appointment Data...
              <v-progress-linear
                color="secondary"
                indeterminate
                rounded
                height="8"></v-progress-linear>
            </v-card-text>
          </v-col>
          <template v-else>
            <v-col v-if="!appointmentCount" class="mt-4">
              <v-alert type="warning">
                This carrier user has no appointments to be transferred!
              </v-alert>
            </v-col>
            <v-col v-else class="mt-4">
              <v-card>
                <v-card-title>Source Carrier</v-card-title>
                <v-card-text>
                  <strong>
                    {{ sourceUser.firstName }} {{ sourceUser.lastName }} -
                    {{ sourceUser.email }}
                  </strong>
                  <br />
                  {{ sourceUser.company.name }}
                </v-card-text>

                <h3 class="text-center my-4">
                  This carrier user has {{ appointmentCount }} appointment{{
                    appointmentCount > 1 ? 's' : ''
                  }}
                </h3>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <v-spacer class="my-12"></v-spacer>
        <v-row v-if="appointmentCount">
          <v-col>
            <div>
              <span class="font-weight-bold font-size-small">Destination Carrier Contact:</span>
              <carrier-select
                :show-carrier-create-button="false"
                hide-icon
                required
                hide-details
                entry-point="Transfer Appointment Modal"
                v-model="targetUser"></carrier-select>
              <v-spacer></v-spacer>
            </div>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-spacer class="my-12"></v-spacer>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="remove"
        confirm-icon="transit-transfer"
        confirm-label="Transfer to Carrier User"
        confirm-color="red"
        :cancel-label="cancelLabel"
        :cancel-color="cancelColor"
        :cancel-icon="cancelIcon"
        :hide-confirm="!appointmentCount"
        :disable-confirm="!targetUser || loading"
        @confirm="transfer"></action-group>
    </template>
  </form-base>
</template>

<script>
export default {
  data() {
    return {
      targetUser: null,
      appointmentCount: 0,
      loading: false
    };
  },
  props: {
    sourceUser: {
      type: Object,
      required: true
    },
    cancelLabel: {
      type: String,
      required: false,
      default: 'Nevermind'
    },
    cancelIcon: {
      type: String,
      required: false,
      default: 'close-circle'
    },
    cancelColor: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    remove() {
      this.$emit('confirm');
    },
    async getAppointmentCount() {
      if (this.sourceUser?.id) {
        this.loading = true;
        const response = await axios
          .get('/metrics/counts/appointment-count-for-carrier', {
            params: { carrierId: this.sourceUser.id }
          })
          .finally(() => (this.loading = false));

        if (response.data?.data) {
          return this.novaCore.sumArray(response.data.data, 'appointmentCount');
        }
      }

      return 0;
    },
    async transfer() {
      if (!this.targetUser?.id || !this.sourceUser?.id) {
        return;
      }

      this.loading = true;

      await this.$store
        .dispatch('Appointments/transferAppointments', {
          userId: this.sourceUser.id,
          targetUserId: this.targetUser.id
        })
        .then(res => {
          if (res?.data?.affected) {
            this.notify('The existing appointments have been transferred!', 'success');
            this.$emit('confirm');
          } else {
            this.notify('Failed to transfer the appointments to another user', 'error');
            this.$emit('close');
          }
        })
        .catch(e => {
          console.error(e);
          this.$emit('close');
        })
        .finally(() => (this.loading = false));
    }
  },
  async mounted() {
    this.appointmentCount = await this.getAppointmentCount();
  }
};
</script>
