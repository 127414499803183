var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: "Invite a User to Your Org",
          scrollable: false,
          "loading-message": "Inviting User...",
        },
        on: {
          close: _vm.close,
          confirm: function ($event) {
            return _vm.$refs.inviteUserForm.submit()
          },
        },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "primary-button",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: {
                                "before-icon": _vm.beforeIcon,
                                large: "",
                              },
                            },
                            "primary-button",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("invite-user-form", {
                    ref: "inviteUserForm",
                    attrs: {
                      "post-submission-route-name": _vm.postSubmissionRouteName,
                    },
                    on: {
                      saved: (data) => _vm.$emit("saved", data),
                      close: _vm.close,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }