import TermsAndConditionsPage from './TermsAndConditionsPage';

export default [
  {
    path: '/terms-and-conditions',
    name: 'termsAndConditions',
    component: TermsAndConditionsPage,
    props: true,
    meta: {
      title: 'Terms and Conditions',
      bypassOrgCheck: true,
      requiresAuth: false,
      hideSidebar: false,
      customHeader: true,
      fullScreen: true
    }
  }
];
