var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "w-100 d-flex flex-column gap-4 justify-center align-center my-10",
      attrs: { "data-testid": "yard-no-gm-enabled-message" },
    },
    [
      _c(
        "span",
        { staticClass: "icon-wrapper" },
        [
          _c(
            "v-icon",
            { staticClass: "icon", attrs: { size: "50", color: "#9E9E9E" } },
            [_vm._v("mdi-domain-off")]
          ),
        ],
        1
      ),
      _vm._m(0),
      _vm._m(1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticStyle: { "max-inline-size": "270px", "text-align": "center" } },
      [
        _c("h3", { staticClass: "typography-body-md-bold align-center mb-8" }, [
          _vm._v(" Gate Management is not enabled for this warehouse! "),
        ]),
        _c("p", { staticClass: "typography-body-sm m-0" }, [
          _vm._v(" Upgrade to "),
          _c("strong", [_vm._v("Gate Management")]),
          _vm._v(" to gain access to Live Yard View. "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "typography-body-sm m-0" }, [
      _vm._v(" Interested? "),
      _c(
        "a",
        {
          attrs: {
            href: "https://community.loadsmart.com/hc/en-us/articles/19326940613011-Carrier-Self-Check-In",
            target: "_blank",
          },
        },
        [_vm._v(" Learn how it works ")]
      ),
      _vm._v(" or contact us to "),
      _c(
        "a",
        {
          attrs: {
            href: "https://lp.opendock.com/gate-management",
            target: "_blank",
          },
        },
        [_vm._v("request a trial.")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }