<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    content-class="dock-dialog-content"
    :key="renderKey"
    header="Update Dock"
    :scrollable="true"
    max-width="60vw"
    width="60vw"
    loading-message="Saving Dock..."
    @close="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <!--
        @slot Custom activator for the dialog
        @binding {object} slotProps Object containing {on, attrs}
      -->
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button
        v-else
        before-icon="pencil"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2">
        Update Dock
      </primary-button>
    </template>
    <template slot="body">
      <dock-form
        :dock="dock"
        :warehouse-id="warehouseId"
        @saved="$eventHub.emit('update-Dock')"
        @close="close"></dock-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit create/edit dock form
 * @displayName Edit Dock Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Dock Object
     */
    dock: {
      type: Object,
      required: false
    },
    /**
     * Warehouse ID
     */
    warehouseId: {
      type: String,
      required: true
    }
  }
};
</script>
