<template>
  <v-autocomplete
    height="20px"
    :class="{ required: field.required }"
    :value="field.value"
    :rules="validationRules"
    :items="field.dropDownValues"
    v-bind="[$props, $attrs]"
    @input="value => $emit('input', value || '')"
    dense
    clearable
    open-on-clear
    hide-details="auto"
    :placeholder="field.label"
    :persistent-placeholder="persistentPlaceholder ? Boolean(field.placeholder) : false"
    multiple>
    <template #label>Choose one or more options</template>
  </v-autocomplete>
</template>

<script>
import customFieldTypeMixin from '@/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  mixins: [customFieldTypeMixin]
};
</script>
