<template>
  <v-textarea
    single-line
    variant="outlined"
    hide-details="auto"
    :class="{ required: field.required }"
    :required="field.required"
    v-model="value"
    v-bind="[$props, $attrs, $on]"
    :prepend-icon="fieldIcon"
    :label="field.label"
    :placeholder="field.placeholder ?? field.label"
    :persistent-placeholder="Boolean(field.placeholder)"
    :rules="validationRules"></v-textarea>
</template>

<script>
import customFieldTypeMixinOld from '@/modules/custom_fields/mixins/customFieldTypeMixinOld';

export default {
  mixins: [customFieldTypeMixinOld]
};
</script>
