var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.orgId
    ? _c(
        "div",
        [
          _c(
            "v-tabs",
            {
              attrs: {
                "background-color": "secondary",
                "slider-color": "#ffe67f",
                grow: "",
                dark: "",
                centered: "",
              },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            _vm._l(_vm.tabs, function (tab, index) {
              return _c(
                "v-tab",
                {
                  key: tab.route,
                  ref: tab.title,
                  refInFor: true,
                  class: {
                    [`org-tab-${index}`]: true,
                    "disable-click": tab.disabled,
                  },
                  attrs: {
                    "active-class": "active-tab",
                    to: { name: tab.route, params: { orgId: _vm.orgId } },
                  },
                },
                [
                  _vm._v(" " + _vm._s(tab.title) + " "),
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          top: "",
                          activator: `.org-tab-${index}`,
                          disabled: !tab.disabled,
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(tab.disabledTooltip))])]
                    ),
                  ],
                ],
                2
              )
            }),
            1
          ),
          _c(
            "div",
            { key: _vm.renderKey },
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(_vm.tabs, function (tab) {
                  return _c("div", { key: tab.route }, [
                    _vm.activeTab?.endsWith(tab.name)
                      ? _c(
                          "div",
                          { attrs: { fluid: "" } },
                          [_c(tab.component, { tag: "component" })],
                          1
                        )
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "v-alert",
            {
              staticClass: "mx-2",
              attrs: { color: "yellow lighten-4", elevation: "2" },
            },
            [
              _c(
                "h4",
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "brown darken-0" } },
                    [_vm._v("mdi-alert")]
                  ),
                  _vm._v(" Invalid Organization Data "),
                ],
                1
              ),
              _c("div", { staticClass: "mt-2" }, [
                _c("small", [_vm._v("The request is invalid!")]),
              ]),
            ]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }