<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    :key="renderKey"
    header=""
    @close="close"
    :loading="false"
    test-id="search-dialog-mobile"
    has-dialog-actions>
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button
        before-icon="pencil"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2">
        Search
      </primary-button>
    </template>
    <template v-slot:body>
      <appointment-results-list-mobile @close="close"></appointment-results-list-mobile>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Dialog for create appointment form
 * @displayName Create Appointment Dialog
 */
export default {
  mixins: [dialogMixin]
};
</script>
