<template functional>
  <div
    class="event-container"
    :class="{ newEventContainer: props.useNewGridTiles }"
    :key="`event-${props.event.id}`"
    :data-testid="`event-load-type-${$options.methods.getLoadTypeId(props.event)}`"
    :data-status="props.event.status">
    <v-icon
      v-if="props.event.isRecurringAppointment"
      class="recurring-icon"
      :class="[
        props.event.status,
        props.useNewGridTiles && props.event.isRecurringAppointment ? 'adjustRecurringIcon' : ''
      ]"
      :color="$options.methods.getIconColor(props.event, props)"
      x-small>
      mdi-repeat
    </v-icon>

    <template v-if="!props.isReserve && props.useNewGridTiles">
      <direction-indicator
        :direction="
          $options.methods.getLoadtypeDirection(props.event.loadType)
        "></direction-indicator>

      <template v-for="item in props.event.gridTileConfig">
        <div :key="item" class="tile-data-block font-weight-bold" v-if="item === 'company_name'">
          {{ props.event.companyName }}
        </div>

        <div :key="item" class="tile-data-block" v-if="item === 'start_time'">
          {{ $options.methods.getFormattedStartTime(props.event, props.enableMilitaryTime) }}
        </div>

        <template v-if="item === 'tags'">
          <template v-for="tag in props.event.formattedTags.renderableTags">
            <template>
              <v-chip
                v-if="tag.color"
                :key="item + tag.name"
                x-small
                class="tile-data-block tag-adjust font-weight-bold"
                :color="tag.color"
                :text-color="tag.textColor">
                {{ tag.name }}
              </v-chip>
              <v-chip
                v-else
                :key="item + tag.name"
                x-small
                class="tile-data-block tag-adjust generic-tag"
                :class="{ 'dark-bg': $options.methods.getGenericTagClass(props.event) }"
                outlined>
                {{ tag.name }}
              </v-chip>
            </template>
          </template>
          <v-chip
            v-if="props.event.formattedTags.hiddenTagCount"
            :key="item + 'hiddenCount'"
            x-small
            class="tile-data-block tag-adjust generic-tag"
            :class="{ 'dark-bg': $options.methods.getGenericTagClass(props.event) }"
            outlined>
            +{{ props.event.formattedTags.hiddenTagCount }}
          </v-chip>
        </template>

        <div
          :key="item"
          class="tile-data-block"
          v-if="item === 'ref_number' && props.event.refNumber && props.event.refNumber !== 'N/A'">
          {{ props.event.readableRefNumber }}
        </div>

        <div :key="item" class="tile-data-block" v-if="item === 'conf_number'">
          {{ props.event.confirmationNumber }}
        </div>

        <div
          :key="item"
          class="tile-data-block"
          v-if="item === 'dock_name' && props.viewType !== 'day'">
          {{ props.event.readableDockName }}
        </div>

        <div :key="item" class="tile-data-block" v-if="item === 'load_type'">
          {{ props.event.readableLoadTypeName }}
        </div>
        <custom-field-data-block
          :key="item"
          :timezone="props.event.dock.warehouse.timezone"
          :enableMilitaryTime="props.enableMilitaryTime"
          v-if="item.startsWith('custom:')"
          :field-label="item.split('custom:')[1]"
          :fields="props.event.gridTileFields || []"></custom-field-data-block>
      </template>
    </template>
    <template v-if="!props.isReserve && !props.useNewGridTiles">
      <span class="font-weight-bold"
        ><v-icon
          x-small
          color="requested darken-3"
          class="requested-icon"
          v-if="props.event.isRequested"
          >mdi-clock-alert</v-icon
        >
        {{ props.event.companyName }}</span
      >
      <span>{{
        $options.methods.getFormattedStartTime(props.event, props.enableMilitaryTime)
      }}</span>
      <template v-if="props.event.formattedTags.tagStr">
        <span>•</span>
        <template v-for="(tag, idx) in props.event.formattedTags.renderableTags">
          <template>
            <v-chip
              v-if="tag.color"
              :key="tag.name"
              x-small
              class="px-2 mb-1 font-weight-bold"
              :color="tag.color"
              :text-color="tag.textColor">
              {{ tag.name }}
            </v-chip>
            <v-chip
              v-else
              :key="tag.name"
              x-small
              class="px-2 mb-1 generic-tag"
              :class="{ 'dark-bg': $options.methods.getGenericTagClass(props.event) }"
              outlined>
              {{ tag.name }}
            </v-chip>
          </template>
        </template>
        <span v-if="props.event.formattedTags.hiddenTagCount">
          + {{ props.event.formattedTags.hiddenTagCount }} more
        </span>
      </template>
      <template v-if="props.event.refNumber && props.event.refNumber !== 'N/A'">
        <span class="inline-block">•</span>
        <span>{{ props.event.readableRefNumber }}</span>
      </template>
      <template v-if="props.viewType !== 'day'">
        <span class="inline-block">•</span>
        <span>{{ props.event.readableDockName }}</span>
      </template>
      <span class="inline-block">•</span>
      <span>
        {{ props.event.readableLoadTypeName }} -
        {{ $options.methods.getLoadtypeDirection(props.event.loadType) }}
      </span>
      <template v-if="props.event.customFieldStr">
        <span class="inline-block">•</span>
        <span>{{ props.event.customFieldStr }}</span>
      </template>
    </template>
    <div class="reserve-content" v-if="props.isReserve">
      <span class="font-weight-bold">{{ props.event.notes }}</span>
      <v-btn
        small
        icon
        @click="$options.methods.cancelReserve({ listeners, props }, $event)"
        @mousedown.stop
        class="reserve-delete">
        <v-icon small color="white">mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  AppointmentStatus,
  getLoadtypeDirection,
  formatDateTimeWithMilitarySupport,
  LuxonDateTimeFormats
} from '@satellite/../nova/core';
import etaMixin from '@satellite/components/mixins/etaMixin';

/**
 * Calendar Event
 * @displayName V-Event
 */
export default {
  name: 'VEvent',
  mixins: [etaMixin],
  props: {
    /**
     * Calendar Event
     */
    event: {
      type: Object,
      required: true
    },
    isReserve: {
      type: Boolean,
      required: true
    },
    viewType: {
      type: String,
      required: false
    },
    enableMilitaryTime: {
      type: Boolean,
      default: false
    },
    isLightTheme: {
      type: Boolean,
      required: false,
      default: false
    },
    useNewGridTiles: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    getLoadTypeId(calendarEvent) {
      return calendarEvent.loadType?.id ?? '';
    },
    getFormattedStartTime(event, enableMilitaryTime) {
      const tz = null;
      return formatDateTimeWithMilitarySupport(
        event.start,
        tz,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        enableMilitaryTime,
        LuxonDateTimeFormats.Extended24HrTime
      );
    },
    cancelReserve(data, e) {
      data.listeners['cancel-reserve'](data.props.event, e);
    },
    getGenericTagClass(event) {
      //TODO: abstract this, duplicated login on EventTooltip
      const darkStatusColors = [
        AppointmentStatus.Arrived,
        AppointmentStatus.NoShow,
        AppointmentStatus.Completed,
        AppointmentStatus.Scheduled
      ];
      return darkStatusColors.includes(event.status);
    },
    getLoadtypeDirection(loadtype) {
      return getLoadtypeDirection(loadtype);
    },
    getIconColor(event, props) {
      const lightStatuses = [
        AppointmentStatus.Requested,
        AppointmentStatus.InProgress,
        AppointmentStatus.Cancelled
      ];
      return props.isLightTheme || lightStatuses.includes(event.status) ? 'black' : 'white';
    }
  }
};
</script>

<style lang="scss" scoped>
.reserve-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.reserve-delete {
  z-index: 500;
}

.newEventContainer {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding-top: 2px !important;
}

.tile-data-block {
  margin-right: 10px;
}

.adjustRecurringIcon {
  top: unset !important;
  transform: unset !important;
}

.tag-adjust {
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 2px;
  margin-right: 6px !important;
}
</style>
