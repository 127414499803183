<template>
  <div class="d-flex flex-row flex-wrap align-center gap-2">
    <div class="d-flex flex-column gap-2">
      <h2 class="typography-heading-sm-bold">{{ spotArea.name }}</h2>

      <div class="d-flex flex-row align-center gap-1">
        <v-chip
          small
          title="Click to toggle open spots for this area"
          :outlined="openSpotsButtonOutlined"
          color="secondary"
          @click.prevent.stop
          @click="eventHub.$emit('toggleOpenSpots', spotArea.id)"
          :data-testid="`yard-main-area-header-open-${spotArea.id}`">
          {{ spotsOpen }} open
        </v-chip>
        <v-chip
          small
          title="Click to toggle closed spots for this area"
          :outlined="closedSpotsButtonOutlined"
          color="secondary"
          @click.prevent.stop
          @click="eventHub.$emit('toggleClosedSpots', spotArea.id)"
          :data-testid="`yard-main-area-header-closed-${spotArea.id}`">
          {{ spotsClosed }} closed
        </v-chip>
        <v-chip
          small
          title="Click to toggle occupied spots for this area"
          :outlined="occupiedSpotsButtonOutlined"
          color="secondary"
          @click.prevent.stop
          @click="eventHub.$emit('toggleOccupiedSpots', spotArea.id)"
          :data-testid="`yard-main-area-header-occupied-${spotArea.id}`">
          {{ spotsOccupied }} occupied
        </v-chip>
      </div>
    </div>

    <v-btn small text class="ml-0 ml-sm-auto" @click="editSpotArea">Edit Area</v-btn>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import useYard from '@/modules/yard/composables/useYard';
import { useEventHub } from '@/composables';

export default defineComponent({
  name: 'YardViewSpotAreaHeader',
  props: {
    spotArea: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const yardStore = useYard();
    const eventHub = useEventHub();

    const spots = computed(() =>
      yardStore.spotsRef.value.filter(s => s.spotAreaId === props.spotArea.id)
    );
    const editSpotArea = () =>
      router.push({
        name: 'yard-spot-area-detail',
        params: {
          warehouseId: yardStore.selectedWarehouseRef.value.id,
          spotAreaId: props.spotArea.id
        }
      });

    const openSpotsButtonOutlined = ref(false);
    const closedSpotsButtonOutlined = ref(false);
    const occupiedSpotsButtonOutlined = ref(false);

    const spotsOpen = computed(
      () => spots.value.filter(s => yardStore.getSpotStatus(s) === yardStore.spotStatus.OPEN).length
    );
    const spotsClosed = computed(
      () =>
        spots.value.filter(s => yardStore.getSpotStatus(s) === yardStore.spotStatus.CLOSED).length
    );
    const spotsOccupied = computed(
      () =>
        spots.value.filter(s => yardStore.getSpotStatus(s) === yardStore.spotStatus.ASSIGNED).length
    );

    const toggleOpenSpots = areaId => {
      if (areaId === props.spotArea.id) {
        openSpotsButtonOutlined.value = !openSpotsButtonOutlined.value;
      }
    };

    const toggleClosedSpots = areaId => {
      if (areaId === props.spotArea.id) {
        closedSpotsButtonOutlined.value = !closedSpotsButtonOutlined.value;
      }
    };

    const toggleOccupiedSpots = areaId => {
      if (areaId === props.spotArea.id) {
        occupiedSpotsButtonOutlined.value = !occupiedSpotsButtonOutlined.value;
      }
    };

    onBeforeMount(() => {
      eventHub.$on('toggleOpenSpots', toggleOpenSpots);
      eventHub.$on('toggleClosedSpots', toggleClosedSpots);
      eventHub.$on('toggleOccupiedSpots', toggleOccupiedSpots);
    });

    onBeforeUnmount(() => {
      eventHub.$off('toggleOpenSpots', toggleOpenSpots);
      eventHub.$off('toggleClosedSpots', toggleClosedSpots);
      eventHub.$off('toggleOccupiedSpots', toggleOccupiedSpots);
    });

    return {
      editSpotArea,
      isReadOnly: yardStore.isReadOnlyRef,
      openSpotsButtonOutlined,
      closedSpotsButtonOutlined,
      occupiedSpotsButtonOutlined,
      eventHub,
      spots,
      spotsOpen,
      spotsClosed,
      spotsOccupied
    };
  }
});
</script>
