var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", { staticClass: "mt-4 mb-2 black--text" }, [
              _vm._v("Warehouse Settings"),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("settings-expansion-panels", {
                attrs: {
                  "read-only": _vm.readOnly,
                  "entity-parent": _vm.novaCore.DatabaseEntities.Org,
                  "entity-key": _vm.novaCore.DatabaseEntities.Warehouse,
                  "should-be-expanded": false,
                  "entity-parent-obj": _vm.editingWarehouse,
                  "entity-settings": _vm.warehouse?.settings || {},
                },
                on: {
                  "update-settings": (data) => (_vm.warehouseSettings = data),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-9" },
        [
          _c("v-col", [
            _c("h3", { staticClass: "black--text mb-6" }, [
              _vm._v(" RefNumber (PO Number) Validation Setup "),
              _c(
                "a",
                {
                  staticClass: "link small ml-2",
                  attrs: {
                    href: "https://community.loadsmart.com/hc/en-us/articles/14946368437907-PO-Ref-Number-Validation-Implementation",
                    target: "_blank",
                    rel: "noopener",
                  },
                },
                [_vm._v(" (Learn more here) ")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        { attrs: { multiple: "" } },
        [
          _vm._t("prepended-panel"),
          _c(
            "v-expansion-panel",
            { key: "refNumberValidationSetup" },
            [
              _c("v-expansion-panel-header", { attrs: { ripple: "" } }, [
                _vm._v("Version"),
              ]),
              _c("v-divider"),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.readOnly,
                              readonly:
                                !_vm.$rolePermissions.canUpdateWarehouse ||
                                _vm.readOnly,
                              outlined: "",
                              "persistent-hint": "",
                              "persistent-placeholder": "",
                              label: "Version",
                              items: _vm.refNumberValidationVersionItems,
                            },
                            model: {
                              value:
                                _vm.editingWarehouse.refNumberValidationVersion,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editingWarehouse,
                                  "refNumberValidationVersion",
                                  $$v
                                )
                              },
                              expression:
                                "editingWarehouse.refNumberValidationVersion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", { attrs: { ripple: "" } }, [
                _vm._v("Credentials"),
              ]),
              _c("v-divider"),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.readOnly,
                              readonly:
                                !_vm.$rolePermissions.canUpdateWarehouse ||
                                _vm.readOnly,
                              spellcheck: "false",
                              type: "url",
                              rules: _vm.$validator.rules.url,
                              label: "Validator URL",
                            },
                            model: {
                              value:
                                _vm.editingWarehouse.refNumberValidationUrl,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editingWarehouse,
                                  "refNumberValidationUrl",
                                  $$v
                                )
                              },
                              expression:
                                "editingWarehouse.refNumberValidationUrl",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.editingWarehouse.refNumberValidationVersion ===
                  _vm.novaCore.RefNumberValidationVersion.V2
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: _vm.readOnly,
                                  readonly:
                                    !_vm.$rolePermissions.canUpdateWarehouse ||
                                    _vm.readOnly,
                                  spellcheck: "false",
                                  type: "text",
                                  label:
                                    "Passcode (optional Auth-Bearer token)",
                                },
                                model: {
                                  value:
                                    _vm.editingWarehouse
                                      .refNumberValidationPasscode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editingWarehouse,
                                      "refNumberValidationPasscode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editingWarehouse.refNumberValidationPasscode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h3", { staticClass: "mt-12 mb-4 black--text" }, [
              _vm._v("Scheduling Portal Availability"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        { attrs: { multiple: "" } },
        [
          _vm._t("prepended-panel"),
          _c(
            "v-expansion-panel",
            { key: "schedulePortalAvailability" },
            [
              _c("v-expansion-panel-header", { attrs: { ripple: "" } }, [
                _vm._v("Carrier Visibility"),
              ]),
              _c("v-divider"),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("carrier-scheduling-toggle", {
                            staticClass: "pl-2 pb-2",
                            attrs: {
                              entity: "Warehouse",
                              disabled:
                                !_vm.$rolePermissions.canUpdateWarehouse ||
                                _vm.readOnly,
                            },
                            model: {
                              value:
                                _vm.editingWarehouse.allowCarrierScheduling,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editingWarehouse,
                                  "allowCarrierScheduling",
                                  $$v
                                )
                              },
                              expression:
                                "editingWarehouse.allowCarrierScheduling",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.editingWarehouse.allowCarrierScheduling &&
                  _vm.$carrierViewAllowedUserRoles.includes(_vm.$me.role) &&
                  !_vm.readOnly
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "primary-button",
                                  {
                                    staticClass: "mr-4",
                                    attrs: {
                                      small: "",
                                      "test-id": "copy-warehouse-button",
                                      "before-icon": "link",
                                    },
                                    on: { click: _vm.copyWarehouseLink },
                                  },
                                  [_vm._v(" Copy warehouse link to share ")]
                                ),
                                _c("preview-as-carrier-button", {
                                  attrs: {
                                    warehouse: _vm.warehouse,
                                    disabled:
                                      !_vm.warehouse.allowCarrierScheduling,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", { attrs: { ripple: "" } }, [
                _vm._v("Experimental Feature"),
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("h3", { staticClass: "mt-10 mb-2 black--text" }),
                        _c("div", { staticClass: "mt-5" }, [
                          _c(
                            "p",
                            [
                              _c("caution-message", [
                                _vm._v(
                                  " This changes Carrier availability - make sure you understand the implications: "
                                ),
                              ]),
                              _vm._v(
                                " Prevent surprise appointments by removing the first "
                              ),
                              _c("text-field", {
                                ref: "intervalTrimForCarriers",
                                staticClass:
                                  "inline-text-field no-spinner text-center",
                                attrs: { type: "number" },
                                model: {
                                  value:
                                    _vm.editingWarehouse
                                      .intervalTrimForCarriers,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editingWarehouse,
                                      "intervalTrimForCarriers",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editingWarehouse.intervalTrimForCarriers",
                                },
                              }),
                              _c("strong", [_vm._v("intervals")]),
                              _vm._v(
                                ' from Carrier availability. "Interval" in this context refers to a continuous block of Warehouse open-hours. '
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("action-group", {
        staticClass: "pr-0 pt-8",
        attrs: {
          "confirm-label": "Save Settings",
          "hide-cancel": true,
          "cancel-color": "error",
          "confirm-button-tooltip": _vm.readOnly
            ? _vm.novaCore.getRoleActionError()
            : "",
          "disable-confirm":
            !_vm.$rolePermissions.canUpdateWarehouse || _vm.readOnly,
        },
        on: { confirm: _vm.submit },
      }),
      _c("v-dialog", {
        attrs: {
          value: Boolean(_vm.notificationConfigKey),
          "retain-focus": false,
          width: "auto",
        },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            _vm.notificationConfigKey = null
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "my-2" },
                  [
                    _vm.notificationConfigKey
                      ? _c("notification-config-form", {
                          attrs: {
                            "warehouse-id": _vm.warehouse.id,
                            settings: _vm.warehouse.settings || {},
                            "notification-type": _vm.notificationConfigKey,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }