var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { elevation: "0" } },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex flex-row card-title" },
        [
          _c(
            "h4",
            { attrs: { "data-testid": "yard-spot-multi-selected-title" } },
            [_vm._v("Selected")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                icon: "",
                "data-testid": "yard-spot-multi-selected-close-button",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("clear")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        {
          staticClass: "full-height",
          attrs: { "data-testid": "yard-spot-multi-selected-content" },
        },
        _vm._l(_vm.selectedSpots, function (spot) {
          return _c(
            "div",
            { key: spot.id, staticClass: "d-flex flex-column mb-4" },
            [
              _c("h3", { staticClass: "text-uppercase" }, [
                _vm._v(" " + _vm._s(spot.code) + " "),
              ]),
              _c(
                "span",
                { staticClass: "text-capitalize" },
                [
                  _vm.getSpotStatus(spot) === _vm.SpotStatus.ASSIGNED
                    ? _c("v-icon", { attrs: { "x-small": "", color: "red" } }, [
                        _vm._v(" mdi-clock-outline "),
                      ])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.getSpotStatus(spot)) + " "),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "v-card-actions",
        { staticClass: "pa-4" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                outlined: "",
                block: "",
                color: "error",
                "data-testid": "yard-spot-multi-selected-delete-button",
              },
              on: {
                click: function ($event) {
                  _vm.isDeleteConfirmationVisible = true
                },
              },
            },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                _vm._v("mdi-delete-outline"),
              ]),
              _vm._v(" Delete Spots "),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isDeleteConfirmationVisible
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.isDeleteConfirmationVisible,
              "delete-confirmation": "",
              "show-delete-warning": "",
              persistent: "",
              title: "Delete these Spots?",
              message: `Are you sure to delete ${
                _vm.selectedSpots.length
              } spot${_vm.selectedSpots.length > 1 ? "s" : ""}?`,
              "button-true-text": "YES, DELETE",
              "button-true-color": "error",
              width: 650,
            },
            on: { result: _vm.handleDeleteConfirmationResult },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }