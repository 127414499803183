var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          "max-width": "900px",
          width: "900px",
          header: "Create Warehouse",
          scrollable: false,
          loading: _vm.saving || _vm.waitingOnExternalResource,
          "loading-message": "Creating Warehouse...",
          "has-dialog-actions": "",
        },
        on: { close: _vm.close, confirm: _vm.onEnter },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            disabled: _vm.$rolePermissions.canCreateWarehouse,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "primary-button",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  "before-icon": _vm.beforeIcon,
                                                  disabled:
                                                    !_vm.$rolePermissions
                                                      .canCreateWarehouse,
                                                  loading:
                                                    _vm.waitingOnExternalResource,
                                                  large: "",
                                                },
                                              },
                                              "primary-button",
                                              slotProps.attrs,
                                              false
                                            ),
                                            slotProps.on
                                          ),
                                          [_vm._v(" Create Warehouse ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.novaCore.getRoleActionError())),
                          ]),
                        ]
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("create-warehouse-stepper", {
                    ref: "createWarehouseStepper",
                    attrs: { "hide-terms": _vm.isFirstWarehouse },
                    on: { confirm: _vm.submit, cancel: _vm.close },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }