<template>
  <div>
    <v-tooltip top v-if="disabled">
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs" class="fit">
          <outline-button disabled>Preview Scheduling Portal</outline-button>
        </div>
      </template>
      <span>Save the settings to enable the preview mode</span>
    </v-tooltip>
    <outline-button small v-else @click="jumpToLunaPreview()" :loading="loading">
      Preview Scheduling Portal
    </outline-button>
  </div>
</template>

<script>
import config from '@/config/config';

export default {
  name: 'PreviewAsCarrierButton',
  props: {
    /**
     * Warehouse Object
     */
    warehouse: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async jumpToLunaPreview() {
      this.loading = true;
      try {
        const routeJSON = JSON.stringify({
          name: 'warehouse.details',
          params: { warehouseId: this.warehouse.id }
        });
        const res = await axios.post('/carrier/preview', {});
        window.open(
          `${config.luna_url}?token=${res.data.access_token}&nextRoute=${routeJSON}`,
          '_blank'
        );
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.fit {
  width: fit-content;
}
</style>
