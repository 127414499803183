<!--
  -- this component is using the new Vue Composition API, this is a step towards our goal
  -- of migrating helios into Vue3. To learn more about it, check:
  -- https://vuejs.org/api/composition-api-setup
  -- https://vuejs.org/guide/extras/composition-api-faq.html
-->
<template>
  <div class="mt-8">
    <h2>{{ title }}</h2>
    <PowerBIReportEmbed
      v-if="embedConfig"
      :embedConfig="embedConfig"
      :phasedEmbedding="false"
      cssClassName="report-container" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { PowerBIReportEmbed } from 'powerbi-client-vue-js';
import { models } from 'powerbi-client';

export default defineComponent({
  name: 'PowerBiInsights',
  components: { PowerBIReportEmbed },
  props: {
    title: { type: String, required: true },
    url: { type: String, required: true }
  },
  setup(props) {
    const loading = ref(false);
    const embedConfig = ref(null);

    async function fetchReportData() {
      loading.value = true;
      try {
        const { data } = await axios.get(props.url);
        embedConfig.value = {
          type: 'report',
          id: data.reportId,
          tokenType: models.TokenType.Embed,
          accessToken: data.embedToken
        };
      } catch (error) {
        console.error('Failed to fetch report data', error);
      } finally {
        loading.value = false;
      }
    }

    onMounted(fetchReportData);

    return { loading, embedConfig };
  }
});
</script>

<style scoped lang="scss">
.report-container {
  height: 100vh;
  margin: 8px auto;
  width: 100%;
}
</style>
