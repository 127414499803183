<template>
  <text-field
    dense
    hide-details="auto"
    :required="field.required"
    v-model="value"
    v-bind="[$props, $attrs, $on]"
    :prepend-icon="fieldIcon"
    @keydown="handleKeydown"
    :label="field.label"
    :placeholder="field.placeholder ?? field.label"
    :persistent-placeholder="Boolean(field.placeholder)"
    :rules="validationRules"></text-field>
</template>

<script>
import customFieldTypeMixinOld from '@/modules/custom_fields/mixins/customFieldTypeMixinOld';
export default {
  mixins: [customFieldTypeMixinOld],
  methods: {
    handleKeydown(e) {
      if (
        this.novaCore.CustomFieldType[this.novaCore.upperFirst(this.fieldType)] ===
        this.novaCore.CustomFieldType.Number
      ) {
        if (this.disallowedNumberCharacters.includes(e.key)) {
          e.preventDefault();
        }
      }
    }
  }
};
</script>
