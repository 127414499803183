var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      key: `event-${_vm.props.event.id}`,
      staticClass: "event-container",
      class: { newEventContainer: _vm.props.useNewGridTiles },
      attrs: {
        "data-testid": `event-load-type-${_vm.$options.methods.getLoadTypeId(
          _vm.props.event
        )}`,
        "data-status": _vm.props.event.status,
      },
    },
    [
      _vm.props.event.isRecurringAppointment
        ? _c(
            "v-icon",
            {
              staticClass: "recurring-icon",
              class: [
                _vm.props.event.status,
                _vm.props.useNewGridTiles &&
                _vm.props.event.isRecurringAppointment
                  ? "adjustRecurringIcon"
                  : "",
              ],
              attrs: {
                color: _vm.$options.methods.getIconColor(
                  _vm.props.event,
                  _vm.props
                ),
                "x-small": "",
              },
            },
            [_vm._v(" mdi-repeat ")]
          )
        : _vm._e(),
      !_vm.props.isReserve && _vm.props.useNewGridTiles
        ? [
            _c("direction-indicator", {
              attrs: {
                direction: _vm.$options.methods.getLoadtypeDirection(
                  _vm.props.event.loadType
                ),
              },
            }),
            _vm._l(_vm.props.event.gridTileConfig, function (item) {
              return [
                item === "company_name"
                  ? _c(
                      "div",
                      {
                        key: item,
                        staticClass: "tile-data-block font-weight-bold",
                      },
                      [_vm._v(" " + _vm._s(_vm.props.event.companyName) + " ")]
                    )
                  : _vm._e(),
                item === "start_time"
                  ? _c("div", { key: item, staticClass: "tile-data-block" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$options.methods.getFormattedStartTime(
                              _vm.props.event,
                              _vm.props.enableMilitaryTime
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                item === "tags"
                  ? [
                      _vm._l(
                        _vm.props.event.formattedTags.renderableTags,
                        function (tag) {
                          return [
                            [
                              tag.color
                                ? _c(
                                    "v-chip",
                                    {
                                      key: item + tag.name,
                                      staticClass:
                                        "tile-data-block tag-adjust font-weight-bold",
                                      attrs: {
                                        "x-small": "",
                                        color: tag.color,
                                        "text-color": tag.textColor,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(tag.name) + " ")]
                                  )
                                : _c(
                                    "v-chip",
                                    {
                                      key: item + tag.name,
                                      staticClass:
                                        "tile-data-block tag-adjust generic-tag",
                                      class: {
                                        "dark-bg":
                                          _vm.$options.methods.getGenericTagClass(
                                            _vm.props.event
                                          ),
                                      },
                                      attrs: { "x-small": "", outlined: "" },
                                    },
                                    [_vm._v(" " + _vm._s(tag.name) + " ")]
                                  ),
                            ],
                          ]
                        }
                      ),
                      _vm.props.event.formattedTags.hiddenTagCount
                        ? _c(
                            "v-chip",
                            {
                              key: item + "hiddenCount",
                              staticClass:
                                "tile-data-block tag-adjust generic-tag",
                              class: {
                                "dark-bg":
                                  _vm.$options.methods.getGenericTagClass(
                                    _vm.props.event
                                  ),
                              },
                              attrs: { "x-small": "", outlined: "" },
                            },
                            [
                              _vm._v(
                                " +" +
                                  _vm._s(
                                    _vm.props.event.formattedTags.hiddenTagCount
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                item === "ref_number" &&
                _vm.props.event.refNumber &&
                _vm.props.event.refNumber !== "N/A"
                  ? _c("div", { key: item, staticClass: "tile-data-block" }, [
                      _vm._v(
                        " " + _vm._s(_vm.props.event.readableRefNumber) + " "
                      ),
                    ])
                  : _vm._e(),
                item === "conf_number"
                  ? _c("div", { key: item, staticClass: "tile-data-block" }, [
                      _vm._v(
                        " " + _vm._s(_vm.props.event.confirmationNumber) + " "
                      ),
                    ])
                  : _vm._e(),
                item === "dock_name" && _vm.props.viewType !== "day"
                  ? _c("div", { key: item, staticClass: "tile-data-block" }, [
                      _vm._v(
                        " " + _vm._s(_vm.props.event.readableDockName) + " "
                      ),
                    ])
                  : _vm._e(),
                item === "load_type"
                  ? _c("div", { key: item, staticClass: "tile-data-block" }, [
                      _vm._v(
                        " " + _vm._s(_vm.props.event.readableLoadTypeName) + " "
                      ),
                    ])
                  : _vm._e(),
                item.startsWith("custom:")
                  ? _c("custom-field-data-block", {
                      key: item,
                      attrs: {
                        timezone: _vm.props.event.dock.warehouse.timezone,
                        enableMilitaryTime: _vm.props.enableMilitaryTime,
                        "field-label": item.split("custom:")[1],
                        fields: _vm.props.event.gridTileFields || [],
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ]
        : _vm._e(),
      !_vm.props.isReserve && !_vm.props.useNewGridTiles
        ? [
            _c(
              "span",
              { staticClass: "font-weight-bold" },
              [
                _vm.props.event.isRequested
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "requested-icon",
                        attrs: { "x-small": "", color: "requested darken-3" },
                      },
                      [_vm._v("mdi-clock-alert")]
                    )
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.props.event.companyName)),
              ],
              1
            ),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$options.methods.getFormattedStartTime(
                    _vm.props.event,
                    _vm.props.enableMilitaryTime
                  )
                )
              ),
            ]),
            _vm.props.event.formattedTags.tagStr
              ? [
                  _c("span", [_vm._v("•")]),
                  _vm._l(
                    _vm.props.event.formattedTags.renderableTags,
                    function (tag, idx) {
                      return [
                        [
                          tag.color
                            ? _c(
                                "v-chip",
                                {
                                  key: tag.name,
                                  staticClass: "px-2 mb-1 font-weight-bold",
                                  attrs: {
                                    "x-small": "",
                                    color: tag.color,
                                    "text-color": tag.textColor,
                                  },
                                },
                                [_vm._v(" " + _vm._s(tag.name) + " ")]
                              )
                            : _c(
                                "v-chip",
                                {
                                  key: tag.name,
                                  staticClass: "px-2 mb-1 generic-tag",
                                  class: {
                                    "dark-bg":
                                      _vm.$options.methods.getGenericTagClass(
                                        _vm.props.event
                                      ),
                                  },
                                  attrs: { "x-small": "", outlined: "" },
                                },
                                [_vm._v(" " + _vm._s(tag.name) + " ")]
                              ),
                        ],
                      ]
                    }
                  ),
                  _vm.props.event.formattedTags.hiddenTagCount
                    ? _c("span", [
                        _vm._v(
                          " + " +
                            _vm._s(
                              _vm.props.event.formattedTags.hiddenTagCount
                            ) +
                            " more "
                        ),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.props.event.refNumber && _vm.props.event.refNumber !== "N/A"
              ? [
                  _c("span", { staticClass: "inline-block" }, [_vm._v("•")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.props.event.readableRefNumber)),
                  ]),
                ]
              : _vm._e(),
            _vm.props.viewType !== "day"
              ? [
                  _c("span", { staticClass: "inline-block" }, [_vm._v("•")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.props.event.readableDockName)),
                  ]),
                ]
              : _vm._e(),
            _c("span", { staticClass: "inline-block" }, [_vm._v("•")]),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.props.event.readableLoadTypeName) +
                  " - " +
                  _vm._s(
                    _vm.$options.methods.getLoadtypeDirection(
                      _vm.props.event.loadType
                    )
                  ) +
                  " "
              ),
            ]),
            _vm.props.event.customFieldStr
              ? [
                  _c("span", { staticClass: "inline-block" }, [_vm._v("•")]),
                  _c("span", [_vm._v(_vm._s(_vm.props.event.customFieldStr))]),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.props.isReserve
        ? _c(
            "div",
            { staticClass: "reserve-content" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.props.event.notes)),
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "reserve-delete",
                  attrs: { small: "", icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$options.methods.cancelReserve(
                        { listeners: _vm.listeners, props: _vm.props },
                        $event
                      )
                    },
                    mousedown: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "", color: "white" } }, [
                    _vm._v("mdi-close"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }