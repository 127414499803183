import { objPropExists } from '@satellite/../nova/core';

// initial state
const state = {
  users: [],
  pagination: {},
  filters: {
    limit: 15
  }
};

// getters
const getters = {
  usersByRole: state => role => {
    return state.users.filter(user => {
      return user.role === role;
    });
  }
};

// actions
const actions = {
  async getUsers({ commit, state }, filters = null) {
    const response = await this.$app.userService.getUsers(filters || state.filters);

    if (response.data) {
      commit('setApiResponseData', response.data);
      commit('setFilters', filters || state.filters);
    }

    return state.users;
  },
  async getUsersWithWarehouses({ commit, state }, filters = null) {
    let response = await this.$app.services.user.getUsers(filters || state.filters, {
      includeMetaData: true
    });

    if (response?.data?.data) {
      const warehouseIds = Array.from([
        ...new Set(
          response.data.data
            .map(user => user.warehouseAccessList)
            .filter(i => i)
            .flat()
        )
      ]);

      if (warehouseIds.length > 0) {
        const warehouses = await this.$app.services.warehouse.getWarehouses({
          s: { id: { $in: warehouseIds } },
          limit: 500
        });

        const warehousesKeyedById = {};

        if (warehouses) {
          warehouses.forEach(warehouse => {
            if (!objPropExists(warehousesKeyedById, warehouse.id)) {
              warehousesKeyedById[warehouse.id] = warehouse;
            }
          });
        }

        if (Object.keys(warehousesKeyedById).length > 0) {
          response.data.data.map(user => {
            if (Array.isArray(user.warehouseAccessList)) {
              user.warehouseAccessList = user.warehouseAccessList.map(warehouseId => {
                return warehousesKeyedById[warehouseId];
              });
            }
          });
        }
      }

      commit('setApiResponseData', response.data);
      commit('setFilters', filters || state.filters);
    }

    return state.users;
  },
  async getUser({}, userId) {
    let user = {};
    let response = await axios.get(`user/${userId}`);

    if (response?.data) {
      user = response.data.data;
    }

    return user;
  },
  async handleSocketEvent() {},
  async deleteUser({}, user) {
    await axios.delete(`user/${user.id}`);
  },
  async sendPasswordReset({}, user) {
    let response = await axios.post(`auth/password-reset-email/${user.email}/`);

    if (response.status < 400) {
      this.$app.notify(`Password reset has been sent successfully.`, 'success');
    }

    return response;
  },
  async inviteUser({}, data) {
    return await axios.post(`user/invite`, data);
  },
  async updateUser({}, userData) {
    delete userData.email;
    delete userData.company;

    return await axios.patch(`user/${userData.id}`, userData);
  },
  async changeCurrentUserPassword({ dispatch }, data) {
    return await axios
      .post(`user/change-password`, { ...data })
      .then(() => {
        this.$app.notify('Password has been changed', 'success');
        dispatch('Auth/logout', {}, { root: true });
        setTimeout(() => window.location.replace('/login'), 1000);
      })
      .catch(() => this.$app.notify('Fail to update user password', 'error'));
  }
};

// mutations
const mutations = {
  clearUsers(state) {
    state.users = [];
  },
  setApiResponseData(state, response) {
    state.users = response.data;
    state.pagination = {
      page: response.page,
      pageCount: response.pageCount,
      total: response.total
    };
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setPagination(state, pagination) {
    state.pagination = {
      page: pagination.page,
      pageCount: pagination.pageCount,
      total: pagination.total
    };
  },
  setUserDeleted(state, userId) {
    state.users = state.users.filter(user => user.id !== userId);
  }
};

export default {
  namespaced: true,
  name: 'Users',
  state,
  getters,
  actions,
  mutations
};
