var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-row",
            { staticClass: "mb-4" },
            [
              _c(
                "v-col",
                { attrs: { xl: "8", lg: "8", md: "8" } },
                [
                  _c("text-field", {
                    attrs: {
                      required: true,
                      type: "text",
                      rules: _vm.$validator.rules.required(
                        "Warehouse Group Name"
                      ),
                      label: "Name",
                    },
                    model: {
                      value: _vm.editingWarehouseGroup.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editingWarehouseGroup, "name", $$v)
                      },
                      expression: "editingWarehouseGroup.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-4" },
            [
              _c(
                "v-col",
                { attrs: { xl: "8", lg: "8", md: "6" } },
                [
                  _c("warehouse-list-select", {
                    attrs: {
                      disabled:
                        _vm.editingWarehouseGroup?.warehouses.length >=
                        _vm.maxWarehouses,
                      items: _vm.availableWarehouseNames,
                    },
                    on: { input: _vm.handleWarehouseInput },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-4" },
            [
              _c(
                "v-col",
                { attrs: { xl: "8", lg: "8", md: "8" } },
                [
                  _c("v-data-table", {
                    staticClass: "warehouse-list",
                    attrs: {
                      "hide-default-footer": "",
                      headers: _vm.rowHeaders,
                      items: _vm.editingWarehouseGroup?.warehouses || [],
                      "server-items-length":
                        _vm.editingWarehouseGroup?.warehouses?.length || 0,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      "footer-props": _vm.footerProps,
                      options: _vm.options,
                    },
                    on: {
                      "update:sortBy": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.name",
                        fn: function ({ item }) {
                          return [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            _c("copy-content", {
                              attrs: { content: item.name },
                            }),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "row-actions" },
                              [
                                _c("icon-tooltip-button", {
                                  attrs: {
                                    size: "large",
                                    tooltip: "Remove from Warehouse Group",
                                    delete: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeWarehouseFromGroup(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-4" },
            [
              _c(
                "v-col",
                { attrs: { xl: "8", lg: "8", md: "8" } },
                [
                  _c("v-label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.editingWarehouseGroup?.warehouses.length || 0
                        ) +
                        " out of " +
                        _vm._s(_vm.maxWarehouses) +
                        " maximum warehouses selected "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.loading
            ? [
                _c("v-progress-linear", {
                  staticClass: "mt-6",
                  attrs: {
                    indeterminate: "",
                    loading: _vm.loading,
                    height: "6",
                  },
                }),
                _c("h4", { staticClass: "text-center mt-4 mb-12" }, [
                  _vm._v("Saving Warehouse Group..."),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("v-divider", { staticClass: "my-2" }),
      _c("action-group", {
        staticClass: "mb-2",
        attrs: {
          "confirm-icon": null,
          "cancel-icon": null,
          "disable-confirm": !this.canSubmit,
          "confirm-label": "Save Warehouse Group",
        },
        on: {
          cancel: function ($event) {
            return _vm.$emit("close")
          },
          confirm: _vm.submit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }