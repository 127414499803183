<template>
  <form-base hide-required-legend>
    <template #form>
      <v-card flat>
        <v-card-text>
          <v-stepper v-model="step" flat class="px-8 pt-1 pb-2">
            <v-stepper-header class="elevation-2 rounded">
              <v-stepper-step :complete="step > 1" step="1">Enter Contact Info</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2">Select Carrier Company</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content class="px-0" step="1">
                <form-base :header="header" hide-required-legend>
                  <template #form>
                    <v-form ref="stepOneForm" @keyup.native.enter="submit">
                      <v-row>
                        <v-col>
                          <h3>
                            <v-icon color="grey darken-2" class="pr-3">
                              mdi-card-account-details-outline
                            </v-icon>
                            First enter your Carrier Contact info
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2">
                        <v-col>
                          <text-field
                            :required="true"
                            type="text"
                            v-model="fields.firstName"
                            :rules="$validator.rules.required('First Name')"
                            label="First Name"></text-field>
                        </v-col>
                        <v-col>
                          <text-field
                            :required="true"
                            type="text"
                            v-model="fields.lastName"
                            :rules="$validator.rules.required('Last Name')"
                            label="Last Name"></text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <text-field
                            :required="true"
                            type="text"
                            v-model="fields.email"
                            name="email"
                            :rules="[
                              ...$validator.rules.required('Email'),
                              ...$validator.rules.email
                            ]"
                            :error-messages="$validator.errors['email']"
                            hint="Email must be unique"
                            persistent-hint
                            label="Email"></text-field>
                        </v-col>

                        <v-col>
                          <text-field
                            type="text"
                            v-model="fields.phone"
                            :rules="$validator.rules.phone"
                            label="Phone Number"></text-field>
                        </v-col>
                      </v-row>
                      <v-row class="pb-16">
                        <v-col>
                          <v-checkbox
                            :class="{ 'text--disabled': isWarehouseRestricted }"
                            class="org-favorite-checkbox ml-0 shrink"
                            color="primary"
                            v-model="fields.isOrgFavorite"
                            label="Favorite this contact for every warehouse, including future ones"
                            data-testid="create-carrier-favorite"
                            :disabled="isWarehouseRestricted" />
                        </v-col>
                      </v-row>
                    </v-form>
                  </template>
                </form-base>
              </v-stepper-content>
              <v-stepper-content class="px-0" step="2">
                <form-base :header="header" hide-required-legend>
                  <template #form>
                    <v-form ref="stepTwoForm" @keyup.native.enter="submit">
                      <v-row>
                        <v-col>
                          <h3>
                            <v-icon color="grey darken-2 pb-2">mdi-domain</v-icon>
                            Now select the Carrier Company:
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row class="pt-4">
                        <v-col>
                          <company-select
                            :entry-point="entryPoint"
                            v-model="fields.companyId"
                            ref="companySelect"
                            placeholder="Search for a company name, scac, usdot or mc..."
                            :rules="$validator.rules.required('Carrier Company')"
                            hide-icon
                            required
                            @companyChanged="updateCompany"></company-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </template>
                </form-base>
              </v-stepper-content>
            </v-stepper-items>
            <v-alert color="amber lighten-5" class="mx-1">
              <p class="mb-1">
                <v-icon small color=" amber darken-3" class="mr-2">mdi-information</v-icon>
                <strong>
                  This carrier will receive appointment email notifications if enabled.
                </strong>
              </p>

              <p class="mt-1 mb-0 ml-7">
                To schedule a test appointment, please use an email domain ending in “@example.com”.
              </p>
            </v-alert>
          </v-stepper>
        </v-card-text>
      </v-card>
    </template>

    <v-divider></v-divider>

    <template #form-actions>
      <action-group
        :confirm-label="step === 1 ? 'next' : 'Add Carrier Contact'"
        @confirm="step === 1 ? submitStepOne() : submitStepTwo()"
        @cancel="$emit('close')"
        :cancel-icon="null"
        :disable-confirm="step === 1 ? !isStepOneComplete : !isStepTwoComplete">
        <template #additional-actions>
          <v-btn v-if="step !== 1" @click="step = 1">Previous</v-btn>
        </template>
      </action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * Create Carrier Form
 * @displayName Create Carrier Form
 */
import useAuth from '@/composables/useAuth';

export default {
  setup() {
    const { isWarehouseRestricted } = useAuth();
    return { isWarehouseRestricted: isWarehouseRestricted.value };
  },
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    },
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      company: undefined,
      fields: {},
      step: 1,
      showCompanyDialog: false,
      addToFavoriteCarriers: true
    };
  },
  computed: {
    isStepOneComplete() {
      return this.fields.firstName && this.fields.lastName && this.fields.email;
    },
    isStepTwoComplete() {
      return this.fields.companyId;
    }
  },
  methods: {
    cancel() {
      /**
       * Emits close event
       * @event close
       */
      this.$emit('close');
    },
    updateCompany(company) {
      this.company = company;
    },
    handleCompanySave(company) {
      this.fields.companyId = company.id;
      this.$refs.companySelect.handleNewCompany(company);
    },
    async submitStepOne() {
      this.$validator.clear();
      if (!this.$refs.stepOneForm.validate()) {
        return;
      }

      const emailAvailable = await axios.get(`user/email-available/${this.fields.email}/`);

      if (!emailAvailable?.data) {
        this.$validator.add({
          field: 'email',
          msg: 'This email is already in use'
        });
      } else {
        this.step = 2;
      }
    },
    mixpanelTrack(event, entryPoint = null) {
      this.mixpanel.track(event, {
        'Entry Point': entryPoint || this.entryPoint,
        'Carrier Contact Name': `${this.fields.firstName} ${this.fields.lastName}`,
        'Carrier Contact Email': this.fields.email,
        'Carrier Company ID': this.fields.companyId,
        'Carrier Company Name': this.company.name,
        'Org Name': this.$org.name,
        'Org ID': this.$org.id
      });
    },
    async submitStepTwo() {
      if (!this.$refs.stepTwoForm.validate()) {
        return;
      }

      const payload = {
        ...this.fields,
        orgCarrierSettings: {
          emailCCs: null,
          favoriteWarehouseIds: this.fields.isOrgFavorite ? null : []
        }
      };

      try {
        const response = await this.$store.dispatch('Carriers/createCarrier', payload);
        if (!response?.data) {
          throw new Error('Missing response data');
        }

        this.mixpanelTrack(this.mixpanel.events.MODULE.CARRIER.CREATED);

        if (this.fields.isOrgFavorite) {
          this.mixpanelTrack(this.mixpanel.events.MODULE.CARRIER.FAVORITES.ADDED);
        }

        this.$emit('save', response.data.data);
      } catch (error) {
        console.error('Failed to create carrier', error);
      } finally {
        this.$emit('close');
      }
    },
    async submit() {
      if (this.step === 1) {
        this.submitStepOne();
      } else {
        this.submitStepTwo();
      }
    },
    async confirmNewCompany() {
      this.$refs.createCompanyForm.submit();
    }
  }
};
</script>

<style scoped lang="scss">
.org-favorite-checkbox.text--disabled > :deep(.v-input__control > .v-input__slot > .v-label) {
  color: $color-text-disabled !important;
}

.org-favorite-checkbox {
  margin: 32px 0 0 0 !important;

  :deep(.v-label) {
    font-weight: 400 !important;
  }
}
</style>
