<template>
  <svg
    id="logo-icon"
    width="25"
    height="25"
    viewBox="0 0 46 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7793 3.30172L39.3721 16.5949V43.2203L45.0088 39.8951V13.2931L22.416 0L16.7793 3.30172Z"
      fill="#FFA333" />
    <path
      d="M8.52832 8.17187L31.0212 21.3944V48.1375L36.6502 44.8201V18.2025L14.0266 4.92505L8.52832 8.17187Z"
      fill="#FFA333" />
    <path
      d="M0.161621 13.105L22.7468 26.3982L22.7544 53.0001L28.2912 49.769V23.0494L5.79829 9.77979L0.161621 13.105Z"
      fill="#FFA333" />
    <path d="M2.27588 41.1579L20.2932 51.761V30.5469L2.27588 41.1579Z" fill="#FFA333" />
    <path d="M0 15.9124V39.6126L5.63667 36.2952V19.2298L0 15.9124Z" fill="#FFA333" />
  </svg>
</template>
<script>
export default {};
</script>
