<template>
  <v-select
    @input="updateValue"
    :value="value"
    :items="docks"
    hide-details="auto"
    item-text="name"
    item-value="id"
    v-bind="[$props, $attrs]"
    :return-object="false"
    clearable
    @click:clear="updateValue({})"
    :ref="selectRefKey">
    <template v-slot:prepend-item v-if="docks.length && multiple && !disableSelectAll">
      <v-list-item ripple @click="toggleSelectAllItems">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider slot="prepend-item" class="mt-2" />
    </template>

    <template v-slot:item="{ item, attrs, on }">
      <v-list-item
        v-on="on"
        v-bind="attrs"
        #default="{ active }"
        :disabled="!attrs.inputValue && isAtSelectionLimit">
        <v-list-item-action>
          <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <div class="d-flex flex-row justify-space-between full-width align-center">
            <div>
              {{ item.name }}
            </div>
            <div v-if="item.isActive === false" class="text-caption grey--text ml-2">INACTIVE</div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0">{{ item.name }}</span>
      <span v-if="index === 1" class="grey--text text-caption ml-2">
        (+{{ value.length - 1 }} others)
      </span>
    </template>

    <template v-slot:append-item>
      <template>
        <select-limit-list-item
          v-if="isAtSelectionLimit"
          :limit="maximumSelections"></select-limit-list-item>
      </template>
    </template>
  </v-select>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';
import SelectLimitListItem from '@/components/elements/selects/SelectLimitListItem.vue';

export default {
  components: { SelectLimitListItem },
  mixins: [selectMixin, selectAllSelectMixin],
  props: {
    /**
     * @model
     */
    value: {
      required: true
    },
    warehouseIds: {
      type: Array,
      required: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldSelectNewDocks: {
      type: Boolean,
      required: false,
      default: true
    },
    selectFields: {
      type: Array,
      required: false
    },
    hideInactiveDocks: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    selectableItems() {
      return this.novaCore.pluck(this.docks, 'id');
    }
  },
  data() {
    return {
      selectRefKey: 'dockSelect',
      docks: []
    };
  },
  watch: {
    async warehouseIds() {
      await this.getData();
    }
  },
  methods: {
    updateValue(docks) {
      this.$emit(
        'dockListUpdate',
        this.docks.filter(dock => docks.includes(dock.id))
      );
      this.$emit('input', docks);
    },
    async getData() {
      const oldDockIds = this.novaCore.pluck(this.docks, 'id');
      const params = { s: {} };

      if (!this.hideInactiveDocks) {
        params.s.$or = [{ isActive: true }, { isActive: false }];
      }

      if (await this.warehouseIds?.length) {
        params.s.warehouseId = { $in: this.warehouseIds };
      }

      if (this.selectFields?.length > 0) {
        params.select = this.selectFields;
      }

      const response = await axios.get(`dock`, { params });

      this.docks = this.novaCore.sortBy(response?.data?.data ?? [], 'name');

      // Remove values that are not in the new list of docks
      const data = this.value.filter(dockId => {
        return this.novaCore.pluck(this.docks, 'id').includes(dockId);
      });

      // Select docks that are new to the dataset
      if (this.shouldSelectNewDocks) {
        data.push(
          ...this.novaCore.pluck(
            this.docks.filter(dock => !oldDockIds.includes(dock.id)),
            'id'
          )
        );
      }

      this.updateValue([...new Set(data)]);
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
