export default {
  data() {
    return {
      renderKey: 0
    };
  },
  methods: {
    incrementRenderKey() {
      // Old way
      this.renderKey++;
    },
    rerender() {
      // Prefer this for readability/understanding
      this.renderKey++;
    }
  }
};
