<template>
  <v-form ref="form">
    <v-row>
      <v-col>
        <text-field
          :required="true"
          type="text"
          v-model="localField.label"
          :rules="$validator.rules.required('Field Label')"
          @keydown.enter.native.stop
          label="Field Label"></text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <custom-forms-type-selector
          v-model="localField.type"
          :rules="$validator.rules.required('Field Type')"></custom-forms-type-selector>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <text-field
          type="text"
          class="mt-10"
          v-model="localField.description"
          label="Description"
          @keydown.enter.native.stop></text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-if="!typesWithoutPlaceholder.includes(localField.type)"
          type="text"
          v-model="localField.placeholder"
          label="Example Value"
          @keydown.enter.native.stop></v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="localField.type === novaCore.CustomFieldType.Action">
      <v-col>
        <v-select
          multiple
          v-model="localField.extraFields.dropDownValues"
          label="Features Choices"
          required
          :items="Object.entries(novaCore.CustomFormsFeatures)"
          :item-text="key => novaCore.CustomFormsFeaturesData[key[1]].title"
          :item-value="key => key[1]"></v-select>
      </v-col>
    </v-row>

    <v-row class="my-4">
      <v-col>
        <v-alert type="info" color="grey" v-if="isDocField">
          Maximum
          <strong>50MB</strong>
          in size. Only
          <strong>ONE image</strong>
          ,
          <strong>text</strong>
          ,
          <strong>zip</strong>
          or
          <strong>spreadsheet</strong>
          allowed. To upload multiple docs, zip first then upload.
        </v-alert>
        <v-alert type="info" color="grey" v-else-if="isMultiDocField">
          <p>
            Maximum
            <strong>50MB</strong>
            in size per file. Maximum 10 files. Only
            <strong>image</strong>
            ,
            <strong>text</strong>
            ,
            <strong>zip</strong>
            or
            <strong>spreadsheet</strong>
            allowed.
          </p>
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <custom-field-drop-down-choices-form
          v-if="hasDropDownValues"
          v-model="localField.extraFields.dropDownValues"></custom-field-drop-down-choices-form>
      </v-col>
    </v-row>

    <div class="d-flex mt-6 flex-row justify-space-between" v-if="isNumberField || isTextField">
      <v-text-field
        class="mr-2"
        type="number"
        v-model.number="localField.extraFields.minLengthOrValue"
        :rules="
          isTextField
            ? $validator.rules.optionalNumberBetween(0, maxSizeTextField(localField.type))
            : []
        "
        :label="`Minimum ${isNumberField ? 'value' : 'length'}`"
        @keydown.enter.native.stop></v-text-field>

      <v-text-field
        class="ml-2"
        type="number"
        v-model.number="localField.extraFields.maxLengthOrValue"
        :rules="
          isTextField
            ? $validator.rules.optionalNumberBetween(0, maxSizeTextField(localField.type))
            : []
        "
        :label="`Maximum ${isNumberField ? 'value' : 'length'}`"
        @keydown.enter.native.stop></v-text-field>
    </div>

    <div class="my-6"></div>

    <action-group
      @cancel="$emit('close')"
      class="pr-0"
      confirm-icon="upload"
      confirm-label="Save"
      cancel-label="Nevermind"
      cancel-color="error"
      @confirm="submit"></action-group>
  </v-form>
</template>

<script>
import { FIELDS_WITHOUT_PLACEHOLDER } from '@satellite/../nova/core';

export default {
  props: {
    field: {
      type: Object,
      required: false,
      id: String
    },
    labelSuggestion: {
      type: String,
      required: false
    }
  },
  computed: {
    isDocField() {
      return this.localField.type === this.novaCore.CustomFieldType.Document;
    },
    isMultiDocField() {
      return this.localField.type === this.novaCore.CustomFieldType.MultiDocument;
    },
    isNumberField() {
      return this.localField.type === this.novaCore.CustomFieldType.Number;
    },
    isTextField() {
      return (
        this.localField.type === this.novaCore.CustomFieldType.String ||
        this.localField.type === this.novaCore.CustomFieldType.BigString
      );
    },
    hasDropDownValues() {
      return (
        this.localField.type &&
        (this.localField.type === this.novaCore.CustomFieldType.DropDown ||
          this.localField.type === this.novaCore.CustomFieldType.DropDownMultiSelect ||
          this.localField.type === this.novaCore.CustomFieldType.ComboBox)
      );
    }
  },
  data() {
    return {
      localField: {},
      typesWithoutPlaceholder: Object.freeze(FIELDS_WITHOUT_PLACEHOLDER)
    };
  },
  methods: {
    maxSizeTextField(fieldType) {
      if (fieldType === this.novaCore.CustomFieldType.String) {
        return 255;
      }

      if (fieldType === this.novaCore.CustomFieldType.BigString) {
        return 1000;
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localField);
      }
    }
  },
  async mounted() {
    if (this.field?.id) {
      this.localField = { ...this.field };
    } else {
      this.localField = {
        label: this.labelSuggestion || null,
        description: null,
        type: null,
        placeholder: null
      };
    }

    if (!this.localField.extraFields) {
      this.localField.extraFields = {};
    }
  }
};
</script>
