<template>
  <icon-tooltip-button
    v-if="org && $rolePermissions.canUpdateFavoriteCarriers && !novaCore.isInternalUser($me)"
    :tooltip="isCarrierFavorite(carrier) ? 'Unfavorite this carrier' : 'Favorite this carrier'"
    @click="$emit('toggle-favorite', carrier)"
    size="large"
    :color="isCarrierFavorite(carrier) ? 'success' : 'grey'"
    :icon="isCarrierFavorite(carrier) ? 'star-remove' : 'star-plus-outline'"
    icon-class="mr-2"></icon-tooltip-button>
</template>

<script>
export default {
  props: {
    carrier: {
      type: Object,
      required: true
    },
    org: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * Check if carrier is favorited
     * @param carrier
     * @returns {*|boolean}
     */
    isCarrierFavorite(carrier) {
      return this.org?.favoriteCarrierIds?.includes(carrier.id);
    }
  }
};
</script>
