<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Add new Carrier Contact"
    :loading="false"
    :scrollable="false"
    @close="close"
    class="mx-auto"
    has-dialog-actions
    max-width="50%"
    width="50%">
    <template #activator="slotProps" v-if="!externalActivator">
      <v-tooltip top :disabled="$rolePermissions.canCreateAppointment">
        <template #activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <!--
            @slot Custom activator for the dialog
            @binding {object} slotProps Object containing {on, attrs}
            -->
            <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
            <primary-button
              v-else
              :disabled="!$rolePermissions.canCreateCarrier"
              before-icon="plus"
              v-on="slotProps.on"
              v-bind="slotProps.attrs"
              large
              class="mr-2"
              data-testid="carrier-list-create">
              Create Carrier Contact
            </primary-button>
          </div>
        </template>
        <span>{{ novaCore.getRoleActionError() }}</span>
      </v-tooltip>
    </template>
    <template v-slot:body>
      <create-carrier-form
        @close="close"
        @save="handleCarrierSave"
        :entry-point="entryPoint"
        ref="createCarrierForm"></create-carrier-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for create carrier form
 * @displayName Create Carrier Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    }
  },
  methods: {
    handleCarrierSave(carrier) {
      this.$emit('save', carrier);
    }
  }
};
</script>
