<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :loading="isLoading"
    header="Audit Log"
    width="900px"
    max-width="100%"
    v-model="showDialog"
    @open="
      isVisible = true;
      getAuditLog();
    "
    @close="
      isVisible = false;
      close();
    ">
    <template v-slot:activator="slotProps">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs">Audit Log</primary-button>
    </template>
    <template #body>
      <p>
        Timeline for all updates made to this {{ entity }}:
        <strong>{{ entityName }}</strong>
      </p>
      <v-row align="center" justify="center">
        <v-col md="1">
          <h4>Filter:</h4>
        </v-col>
        <v-col md="3">
          <date-picker
            :maxDate="maxFilterDate"
            outlined
            hide-details
            inner-icon
            v-model="searchDate"
            @input="setLogLines"
            placeholder="Events from date"></date-picker>
        </v-col>
        <v-col md="6" offset-md="2" class="mt-4">
          <text-field
            outlined
            class="mb-5"
            v-model="searchStr"
            append-icon="mdi-magnify"
            label="Search event..."
            :data-testid="`filter-audit-log-${objectId}`"
            single-line
            hide-details></text-field>
        </v-col>
      </v-row>

      <audit-log-entity-form
        v-if="!isLoading"
        :log-lines="logLines"
        :search-str="searchStr"
        :timezone="timezone"></audit-log-entity-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import auditLogMixin from '@/components/mixins/auditLogMixin';
import { DateTime } from 'luxon';
import { isJSON, isObject } from 'class-validator';

export default {
  props: {
    timezone: {
      type: String,
      required: false
    },
    entityName: {
      type: String,
      required: true
    }
  },
  mixins: [dialogMixin, auditLogMixin],
  data() {
    return {
      isVisible: false,
      isLoading: false,
      logLines: [],
      fetchOnMounted: false,
      searchStr: '',
      searchDate: ''
    };
  },
  methods: {
    getItemDate(item) {
      return this.novaCore.getFormattedTime(
        item.timestamp,
        this.novaCore.DateTimeFormats.ShortDate
      );
    },
    getItemTime(item) {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        item.timestamp,
        this.timezone,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPMWithAbbreviatedNamedOffset,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTimeWithAbbreviatedNamedOffset
      );
    },
    getItemAction(item) {
      if (item.action === 'UPDATE') {
        return 'updated';
      }
      if (item.action === 'INSERT') {
        return 'Created';
      }
      if (item.action === 'DELETE') {
        return 'Deleted';
      }

      return item.action?.toLowerCase() || 'No action';
    },
    getItemChangeField(field) {
      return this.novaCore.camelCaseToHumanReadable(field);
    },
    getItemChangeValue(value) {
      const emptyValue = 'Empty value';

      if (value === 't' || value === true) {
        return 'Yes';
      }
      if (value === 'f' || value === false) {
        return 'No';
      }

      if (isJSON(value)) {
        value = JSON.parse(value);
      }

      if (this.novaCore.isTrulyEmpty(value) || !value) {
        return emptyValue;
      }

      if (isObject(value) || Array.isArray(value)) {
        value = JSON.stringify(value, null, 2);
      }

      value = value
        .replace(/[{}[\]]/gu, '')
        .replace(/(:\s)false([,\n])/gu, '$1No$2')
        .replace(/(:\s)true([,\n])/gu, '$1Yes$2')
        .replace(/(:\s)null([,\n])/gu, '$1[Empty]$2')
        .replace(/,\n/gu, '\n')
        .replace(/"(.+)":/gu, (m, c) => `<strong>${this.getItemChangeField(c)}:</strong>`)
        .replace(/"\\|\\"/gu, '')
        .replace(/"/gu, '');

      return value;
    },
    getDefaultLineItem(item, id = item.id) {
      // Ensure timezone
      const timestamp = DateTime.fromISO(item.actionTimestamp).setZone(this.timezone);
      item.timestamp = timestamp.toISO({ includeOffset: false });

      return {
        id,
        action: this.getItemAction(item),
        user: this.getItemUser(item),
        date: this.getItemDate(item),
        sortDate: timestamp.toISODate(),
        unixTimeStamp: timestamp.toUnixInteger(),
        time: this.getItemTime(item)
      };
    },
    filterChangedFields(logEntry) {
      Object.keys(logEntry.changedFields)?.forEach(field => {
        if (this.hiddenFields.includes(field.toUpperCase())) {
          delete logEntry.changedFields[field];
        }
      });
    },
    async trackAuditLogMixpanelEvent() {
      await this.mixpanel.track(this.mixpanel.events.VIEW.AUDIT_LOG, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Audit Log Entity': this.entity
      });
    },
    async getAuditLog() {
      if (!this.isVisible) {
        return;
      }
      this.isLoading = true;

      const auditLogResponse = await axios.get(`audit-log/${this.objectId}`);
      this.auditLog = auditLogResponse?.data || [];

      this.setLogLines();
      await this.trackAuditLogMixpanelEvent();
      this.isLoading = false;
    },
    setLogLines() {
      const lines = [];

      this.auditLog
        .filter(logEntry => {
          if (this.searchDate) {
            const filteredDate = DateTime.fromISO(this.searchDate).setZone(this.timezone);
            const eventDate = DateTime.fromISO(logEntry.actionTimestamp).setZone(this.timezone);
            return eventDate.diff(filteredDate) > 0;
          }
          return true;
        })
        .forEach(logEntry => {
          // Remove unwanted change fields
          this.filterChangedFields(logEntry);
          if (logEntry.action === 'INSERT') {
            lines.push({
              ...this.getDefaultLineItem(logEntry),
              field: this.entity,
              value: this.getItemChangeValue(logEntry.changedFields)
            });
          }

          if (logEntry.action === 'DELETE') {
            lines.push({ ...this.getDefaultLineItem(logEntry) });
          }

          if (logEntry.action === 'UPDATE') {
            Object.entries(logEntry.changedFields)?.forEach(([field, value]) => {
              lines.push({
                ...this.getDefaultLineItem(logEntry, `${logEntry.id}${field}`),
                field: this.getItemChangeField(field),
                value: this.getItemChangeValue(value)
              });
            });
          }
        });

      this.logLines = lines.sort((a, b) => b.unixTimeStamp - a.unixTimeStamp);
    }
  },
  computed: {
    maxFilterDate() {
      return DateTime.now().setZone(this.timezone).toISODate();
    },
    /*
     * Fields for any entity that should NOT
     * show up on the audit log entries
     */
    hiddenFields() {
      return [
        'LASTCHANGEDDATETIME',
        'LASTCHANGEDBY',
        'ISACTIVE',
        'GEOLOCATION',
        'ID',
        'CREATEDBY',
        'CREATEDDATETIME',
        'ORGID',
        'EXPIRESAT',
        'EXTERNALBILLINGID',
        'ISDATAWALLENABLED'
      ];
    }
  }
};
</script>
