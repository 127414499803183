var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-file-input",
    _vm._g(
      _vm._b(
        {
          ref: "fileinput",
          staticClass: "doc-upload-component",
          attrs: {
            placeholder: "Upload Documents",
            required: _vm.required,
            type: "file",
            "single-line": "",
            "hide-input": "",
            multiple: "",
            "hide-details": "auto",
            "prepend-icon": "",
            value: _vm.computedValue,
          },
          on: { change: _vm.onFilesChanged },
          scopedSlots: _vm._u([
            {
              key: "append-outer",
              fn: function () {
                return [
                  !_vm.fileKeys?.length
                    ? _c(
                        "outline-button",
                        {
                          attrs: {
                            loading: _vm.isSelectingFiles,
                            "before-icon": "cloud-upload",
                          },
                          on: { click: _vm.fileUploadClick },
                        },
                        [
                          _vm._v(" Upload Files "),
                          _vm.required
                            ? _c("span", { staticClass: "red--text ml-1" }, [
                                _c("strong", [_vm._v("*")]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "multi-doc-container" },
                        _vm._l(_vm.fileKeys, function (fileKey, i) {
                          return _c(
                            "v-btn",
                            {
                              key: fileKey,
                              staticClass: "multi-doc-upload-button",
                              attrs: { loading: _vm.isSelectingFiles },
                              on: { click: _vm.fileUploadClick },
                            },
                            [
                              _vm.isImageFile(fileKey)
                                ? _c("v-img", {
                                    attrs: {
                                      contain: "",
                                      height: "100",
                                      width: "100",
                                      src: _vm.fileUrls[i],
                                    },
                                  })
                                : _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        size: "100",
                                        color: "secondary",
                                      },
                                    },
                                    [_vm._v("mdi-file")]
                                  ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "doc-close-button",
                                  class: {
                                    "is-image": _vm.isImageFile(fileKey),
                                  },
                                  attrs: { icon: "", "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteDoc(fileKey)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { "x-small": "" } }, [
                                    _vm._v("mdi-close"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "font-italic text-subtitle-2" },
                                [_vm._v(_vm._s(_vm.getFileName(fileKey)))]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                  _c("span", { staticClass: "errors v-messages mt-2" }, [
                    _vm._v(_vm._s(_vm.fieldError)),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "v-file-input",
        [_vm.$props, _vm.$attrs],
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }