var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    key: _vm.renderKey,
    attrs: { header: _vm.header, "hide-required-legend": true },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      { staticClass: "align-center mb-1" },
                      [
                        _c(
                          "h3",
                          { staticClass: "mr-4" },
                          [
                            _vm._v(" Email Subscribers "),
                            _c("help-icon-tooltip", [
                              _vm._v(
                                " List of email addresses that will receive all notifications about the appointment. "
                              ),
                            ]),
                            _vm._v(" : "),
                          ],
                          1
                        ),
                        _c("email-list-field", {
                          ref: "emailFieldList",
                          attrs: { "small-chips": "", small: "" },
                          model: {
                            value: _vm.ccEmails,
                            callback: function ($$v) {
                              _vm.ccEmails = $$v
                            },
                            expression: "ccEmails",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: { "confirm-label": "Save" },
              on: { cancel: _vm.cancel, confirm: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }