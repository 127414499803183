<template>
  <v-combobox
    ref="combobox"
    height="20px"
    :class="{ required: field.required }"
    :value="field.value"
    :rules="validationRules"
    :items="field.dropDownValues"
    v-bind="[$props, $attrs]"
    @input="value => $emit('input', value || '')"
    @keyup="updateSelf"
    dense
    clearable
    open-on-clear>
    <template #label>Choose or Type a value</template>
  </v-combobox>
</template>

<script>
import customFieldTypeMixin from '@/modules/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-combobox',
  mixins: [customFieldTypeMixin],
  methods: {
    /**
     * Combobox does not update itself until the blur event, but we can manually trigger with keyup
     * so it's always current
     */
    updateSelf() {
      this.$refs.combobox.updateSelf();
    }
  }
};
</script>
