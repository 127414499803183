<template>
  <div class="d-flex flex-row align-center mb-4 full-width">
    <v-select
      @input="updateValue"
      :value="value"
      :items="items"
      :rules="minimumSelections ? $validator.rules.selectAtLeast(minimumSelections, itemName) : []"
      class="pt-0 mt-0"
      v-bind="[$props, $attrs]"
      :ref="refKey"
      multiple
      @change="$emit('change')"
      @click:clear="updateValue({})">
      <template #label v-if="required">
        {{ placeholder }}
        <span class="red--text"><strong>*</strong></span>
      </template>

      <template #prepend-item>
        <v-list-item ripple @click="toggleSelectAllItems">
          <v-list-item-action>
            <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider slot="prepend-item" class="mt-2" />
      </template>

      <template #selection="{ item, index }">
        <slot name="selection" :item="item" :index="index" v-if="!!value">
          <v-chip small v-if="index < visibleSelectionCount && asChips">
            {{ itemText ? item[itemText] : item }}
          </v-chip>
          <span v-if="index < visibleSelectionCount && !asChips">
            {{ itemText ? item[itemText] : item }}
          </span>
          <span v-if="index === visibleSelectionCount + 1" class="grey--text text-caption ml-2">
            (+{{ value.length - visibleSelectionCount }} others)
          </span>
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';

export default {
  name: 'genericMultiSelect',
  mixins: [selectMixin, selectAllSelectMixin],
  props: {
    /**
     * @model
     */
    value: {
      required: true
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    autoSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    minimumSelections: {
      type: Number,
      required: false,
      default: null
    },
    visibleSelectionCount: {
      type: Number,
      required: false,
      default: 3
    },
    itemName: {
      type: String,
      required: false,
      default: 'Item'
    },
    refKey: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      required: false
    },
    itemValue: {
      type: String,
      required: false
    },
    asChips: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectRefKey: this.refKey
    };
  },
  computed: {
    selectableItems() {
      return this.items;
    }
  },
  methods: {
    updateValue(selected) {
      this.$emit('input', selected);
    },
    setSelected() {
      if (this.autoSelect || this.value.length === 0) {
        this.updateValue(this.items);
      }
    }
  },
  mounted() {
    this.setSelected();
  }
};
</script>
