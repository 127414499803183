<template>
  <div class="d-flex flex-column justify-end">
    <text-field
      :required="required"
      type="text"
      :value="value"
      @change="value => $emit('input', value)"
      :rules="rules"
      :tooltipText="tooltipText"
      :label="label"></text-field>
    <div class="search-btn d-flex align-end justify-end">
      <a href="#" @click="search">Search for an address</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Street Address'
    },
    tooltipText: {
      type: String,
      required: false,
      default: 'Address of the Warehouse location visible to carriers'
    }
  },
  methods: {
    search(e) {
      e.preventDefault();
      this.$emit('search');
    }
  }
};
</script>

<style scoped>
.search-btn {
  width: 100%;
  z-index: 3;
  margin-top: -12px;
  font-size: 14px;
  text-align: right;
}
</style>
