<template>
  <form-base :header="header" class="entry-form-container" :hide-required-legend="true">
    <template #form>
      <v-form ref="form" @keyup.native.enter="submit">
        <v-container>
          <text-field
            :required="true"
            v-model="firstName"
            outlined
            dense
            :persistent-placeholder="true"
            placeholder="Type your first name ..."
            :rules="$validator.rules.required('First Name')"
            label="First name"></text-field>
          <text-field
            :required="true"
            v-model="lastName"
            outlined
            dense
            :persistent-placeholder="true"
            placeholder="Type your last name ..."
            :rules="$validator.rules.required('Last Name')"
            label="Last name"></text-field>
          <text-field
            :required="true"
            type="email"
            v-model="email"
            outlined
            dense
            :persistent-placeholder="true"
            placeholder="Type your email ..."
            :rules="[...$validator.rules.required('Email'), ...$validator.rules.email]"
            label="Email"></text-field>
          <text-field
            :required="true"
            type="password"
            v-model="password"
            outlined
            dense
            :persistent-placeholder="true"
            placeholder="Type your password ..."
            :rules="$validator.rules.password"
            label="Create a password"></text-field>
          <password-restrictions :password="password"></password-restrictions>
        </v-container>
      </v-form>
      <div class="pb-8 px-2">
        <primary-button
          block
          x-large
          class="mt-2 registration-button"
          @click="submit"
          :loading="loading">
          Register
        </primary-button>

        <div class="d-flex align-center justify-center pt-4">
          <span class="is-uppercase">Already Have an Account?</span>
          <router-link :to="{ name: 'login' }" class="registration-btn sign-in-btn ml-2" text>
            Sign In
          </router-link>
        </div>
      </div>
    </template>
  </form-base>
</template>

<script>
import FormBase from '@satellite/components/elements/forms/FormBase';
import TextField from '@satellite/components/elements/forms/TextField';
import PrimaryButton from '@satellite/components/elements/buttons/PrimaryButton';
import { isObject } from 'class-validator';
/**
 * Registration Form
 * @displayName Registration Form
 */
export default {
  components: {
    FormBase,
    TextField,
    PrimaryButton
  },
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      loading: false
    };
  },
  methods: {
    /**
     * Submit the form
     * @public
     * @returns {Promise<void>}
     */
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        axios
          .post(
            'user/register',
            {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              password: this.password
            },
            { params: this.getUtmQueryParams() }
          )
          .then(response => {
            if (response?.data) {
              this.mixpanel.identify({ email: this.email });
              this.mixpanel.track(this.mixpanel.events.MODULE.USER.REGISTERED);
              this.$emit('registration-submitted', this.email);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getUtmQueryParams() {
      const utmQueryParams = {};

      if (isObject(this.$route.query)) {
        Object.keys(this.$route.query).forEach(param => {
          if (param.startsWith('utm_')) {
            utmQueryParams[param] = this.novaCore.sanitizeInput(this.$route.query[param]);
          }
        });
      }

      return utmQueryParams;
    },
    cancel() {
      /**
       * Emits the close event
       * @event close
       */
      this.$emit('close');
    }
  }
};
</script>
