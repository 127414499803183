var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", {
        staticClass: "mb-8 mt-4",
        domProps: { innerHTML: _vm._s(_vm.header) },
      }),
      Boolean(_vm.featureData.subtitle)
        ? _c("p", {
            staticClass: "font-size-x-small",
            domProps: { innerHTML: _vm._s(_vm.featureData.subtitle) },
          })
        : _vm._e(),
      _vm.isFeatureInstalled
        ? [
            _c("h5", { staticClass: "mb-4" }, [_vm._v("Form available for:")]),
            _c(
              "v-expansion-panels",
              { staticClass: "my-4" },
              _vm._l(_vm.triggers, function (trigger) {
                return _c(
                  "v-expansion-panel",
                  { key: trigger.id, attrs: { readonly: "" } },
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass: "cursor-default",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "actions",
                              fn: function () {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        depressed: "",
                                        "data-testid": `feature-manage-${trigger.app}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("manage", trigger)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" Manage "),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-arrow-right")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.novaCore.upperFirst(trigger.app))
                              ),
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  "data-testid": `feature-status-${trigger.app}`,
                                  title: trigger.isActive
                                    ? "Form active"
                                    : "Form not visible",
                                  color: trigger.isActive ? "success" : "grey",
                                  "x-small": "",
                                },
                              },
                              [_vm._v(" mdi-circle ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ]
        : _c("div", { staticClass: "my-4" }, [
            _c(
              "strong",
              [
                _c("v-icon", { staticClass: "mr-2", attrs: { color: "red" } }, [
                  _vm._v("mdi-alert-circle"),
                ]),
                _vm._v(" Feature disabled "),
              ],
              1
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }