var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          scrollable: "",
          header: "Link to appointment",
          width: "820",
          "max-width": "100%",
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", [
                  _vm._v(
                    " Choose an existing appointment to link this arrival to. The appointment must be in the Requested or Scheduled statuses. "
                  ),
                ]),
                _c("em", [
                  _vm._v(
                    " Search by confirmation number, reference number, company name, Dock name, Load Type name, or status "
                  ),
                ]),
                _c(
                  "v-container",
                  { staticClass: "rounded-box" },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "mb-2 header-row",
                        attrs: { align: "center" },
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("v-text-field", {
                              staticClass: "box-text-field prepend-icon",
                              attrs: {
                                placeholder: "Search appointments",
                                "hide-details": "",
                                "persistent-placeholder": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-magnify"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.searchStr,
                                callback: function ($$v) {
                                  _vm.searchStr = $$v
                                },
                                expression: "searchStr",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "3" } }, [
                          _c(
                            "span",
                            { staticClass: "text--color-text-secondary" },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.totalAppointments)),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.pluralize(
                                      _vm.totalAppointments ?? 0,
                                      "appointment"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("v-data-table", {
                      staticClass: "mt-4",
                      attrs: {
                        items: _vm.appointments,
                        headers: _vm.headers,
                        "fixed-header": "",
                        height: "50vh",
                        "footer-props": {
                          "items-per-page-options": [],
                          "items-per-page-text": "",
                        },
                      },
                      on: { "click:row": _vm.handleRowClick },
                      scopedSlots: _vm._u([
                        {
                          key: "item.start",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                _vm._s(_vm.getAppointmentStart(item.start))
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.status",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "is-uppercase font-weight-bold",
                                  class: {
                                    "text--requested-darken3":
                                      _vm.isRequested(item),
                                    "text--color-neutral-0":
                                      _vm.isScheduled(item),
                                  },
                                  attrs: {
                                    small: "",
                                    color: item.status.toLowerCase(),
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.status) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm.appointmentToLink
                  ? _c(
                      "v-dialog",
                      {
                        attrs: { width: "820", "max-width": "100%" },
                        model: {
                          value: _vm.shouldShowConfirmLinkDialog,
                          callback: function ($$v) {
                            _vm.shouldShowConfirmLinkDialog = $$v
                          },
                          expression: "shouldShowConfirmLinkDialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "dialog-title" },
                              [
                                _c("span", { staticClass: "headline" }, [
                                  _vm._v("Confirm link?"),
                                ]),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: { click: _vm.closeConfirmLinkDialog },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-close")])],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-card-text", [
                              _c("span", [
                                _vm._v(
                                  ' The arrival will be marked as "Planned". The asset and the appointment will be marked as "Arrived". '
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "rounded-box my-6 pa-4" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-size-medium font-weight-bold",
                                    },
                                    [_vm._v("Appointment details")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "py-4 value-grid" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "block-value" },
                                        [
                                          _c("span", [_vm._v("Conf #")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.appointmentToLink
                                                  .confirmationNumber
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "block-value" },
                                        [
                                          _c("span", [_vm._v("Start Date")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.appointmentStartDate)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "block-value" },
                                        [
                                          _c("span", [_vm._v("Start Time")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.appointmentStartTime)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "block-value" },
                                        [
                                          _c("span", [
                                            _vm._v("Carrier Company"),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.appointmentToLink.user
                                                  .company.name
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "block-value" },
                                        [
                                          _c("span", [_vm._v("Dock")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.appointmentToLink.dock.name
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "block-value" },
                                        [
                                          _c("span", [_vm._v("Direction")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.appointmentToLink.loadType
                                                  .direction
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "rounded-box my-6 pa-4" },
                                [
                                  _c("asset-visit-form-data", {
                                    attrs: {
                                      "is-grid": "",
                                      "asset-visit": _vm.assetToLink,
                                      "phone-title": "phone",
                                      company: _vm.assetToLink.company || {
                                        name: _vm.assetToLink.companyHint,
                                      },
                                      "company-title": "Company",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "v-card-actions",
                              { staticClass: "py-4" },
                              [
                                _c(
                                  "outline-button",
                                  {
                                    attrs: { "before-icon": "arrow-left" },
                                    on: { click: _vm.closeConfirmLinkDialog },
                                  },
                                  [_vm._v(" Back to appointments list ")]
                                ),
                                _c("v-spacer"),
                                _c(
                                  "primary-button",
                                  {
                                    on: {
                                      click:
                                        _vm.linkUnplannedCheckinToAppointment,
                                    },
                                  },
                                  [_vm._v("Confirm link")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }