<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Edit External Billing Id"
    loading-message="Updating External Billing Id..."
    @close="close">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
    </template>
    <template slot="body">
      <edit-org-billing-form @close="close" :org-id="orgId"></edit-org-billing-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit org form
 * @displayName EditOrgDialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  computed: {
    orgId() {
      return this.org?.id;
    }
  }
};
</script>
