<template>
  <dialog-base
    :key="renderKey"
    :loading="loading"
    v-bind="$props"
    header="Appointment Details"
    loading-message=""
    @close="closeDialog"
    max-width="100%"
    :value="showDialog"
    content-class="appointment-details-dialog"
    width="980px">
    <template v-slot:header-actions>
      <appointment-action-group-old
        v-if="appointmentWarehouse"
        :appointment-warehouse="appointmentWarehouse"
        :parent-appointment="parentAppointment"
        :appointment="appointment"
        :appointment-actions="appointmentActionsOld"></appointment-action-group-old>
    </template>
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button v-on="slotProps.on" v-bind="slotProps.attrs">View Appointment</primary-button>
    </template>
    <template v-slot:body>
      <appointment-details-old
        :read-only="!canUserAccessWarehouse"
        v-if="appointmentWarehouse"
        :appointment-warehouse="appointmentWarehouse"
        :parent-appointment="parentAppointment"
        @close="close"
        @change-active-tab="handleChangeActiveTab"
        :appointment="appointment"
        :should-edit-status="true"
        :should-display-cancel-button="false"></appointment-details-old>
      <cancel-appointment-dialog-old
        ref="cancelAppointment"
        :external-activator="true"
        :show-dialog="showConfirmDialog"
        :appointment="appointment"
        @close="showConfirmDialog = false"></cancel-appointment-dialog-old>
    </template>
    <template v-slot:dialog-actions v-if="showFooter && (canCancel || isCancelled)">
      <v-btn
        v-if="!isCancelled && $rolePermissions.canUpdateAppointment && canUserAccessWarehouse"
        elevation="0"
        class="text__error font-weight-bold"
        color="white"
        @click="confirmCancel"
        :disabled="appointment.status === novaCore.AppointmentStatus.Cancelled">
        <v-icon class="mr-2">{{ cancelButtonIcon }}</v-icon>
        {{ cancelAppointmentLabel }}
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import AppointmentDetailsDialogBase from '@/modules/appointments/components/details/AppointmentDetailsDialogBase.vue';

/**
 * Dialog for create appointment form
 * @displayName Create Appointment Dialog
 */
export default {
  extends: AppointmentDetailsDialogBase,
  data() {
    return {
      showFooter: true
    };
  },
  methods: {
    handleChangeActiveTab(tab) {
      this.showFooter = +tab === 0;
    },
    resetSelectedEvent() {
      this.$store.commit('Calendar/setSelectedEvent', null);
    },
    closeDialog() {
      this.close();
      this.removeAppointmentIdFromRouteQuery();
      this.resetSelectedEvent();
    }
  }
};
</script>
