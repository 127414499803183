<template>
  <dialog-base
    v-bind="$props"
    header="We value your privacy and security"
    :loading="loading"
    max-width="100%"
    :value="showDialog"
    :hideCloseIcon="true"
    style="z-index: 20001">
    <template v-slot:activator="slotProps"> </template>

    <template v-slot:body>
      <p>
        We made a few changes in order to make Opendock even safer to use. Please review
        <a
          href="https://loadsmart.com/subscription-and-professional-services-agreement/"
          target="_blank"
          >Loadsmart's Terms & Conditions</a
        >
        and
        <a href="https://loadsmart.com/privacy-policy/" target="_blank"
          >Loadsmart's Privacy Policy</a
        >, and mark your consent below to continue using Opendock.
      </p>
      <v-checkbox
        x-small
        label="I have read and agree to be bound by Loadsmart's Terms & Conditions and subject to Loadsmart's Privacy Policy in connection with my use of the Opendock platform."
        v-model="confirmed" />
      <p>
        If you need any help, reach out to our support team
        <a
          href="https://community.loadsmart.com/hc/en-us/community/topics/14812876253715-OpenDock"
          target="_blank"
          >through this link</a
        >.
      </p>
    </template>
    <template v-slot:dialog-actions>
      <v-spacer></v-spacer>
      <v-btn
        elevation="0"
        class="font-weight-bold"
        color="success"
        @click="handleConfirm"
        :disabled="confirmed === false">
        Confirm
      </v-btn>
    </template>
  </dialog-base>
</template>
<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Dialog for new terms and conditions
 * @displayName New Terms Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      confirmed: false
    };
  },
  methods: {
    async handleConfirm() {
      this.$emit('confirm');
    }
  }
};
</script>
