<template>
  <div>
    <hoc-dialog-loader :loading="loading" :loading-message="loadingMessage">
      <template>
        <v-container v-if="header">
          <h2>{{ header }}</h2>
        </v-container>
        <div class="mb-10 pb-8 form-wrapper">
          <!-- @slot The Form -->
          <slot name="form"></slot>
          <small v-if="!hideRequiredLegend" :class="requiredLegendClass">
            * indicates required field
          </small>
        </div>
      </template>
      <div class="actions" v-if="$slots['form-actions']">
        <!-- @slot Form Actions -->
        <slot name="form-actions"></slot>
      </div>
    </hoc-dialog-loader>
  </div>
</template>

<script>
import HocDialogLoader from '@satellite/components/elements/loaders/HocDialogLoader';
/**
 * Base form skeleton used for quick builds of new forms
 * @displayName Form Base
 */
export default {
  components: { HocDialogLoader },
  props: {
    /**
     * Form header
     */
    header: {
      type: String,
      required: false
    },
    /**
     * Hide the "* fields are required" text
     */
    hideRequiredLegend: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Loading message for ajax forms
     */
    loadingMessage: {
      type: String,
      required: false,
      default: 'Loading...'
    },
    requiredLegendClass: {
      type: String,
      required: false,
      default: ''
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    useGlobalLoader: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    loading() {
      const isGlobalLoading = this.useGlobalLoader ? this.$data.$globalLoading : false;
      const isLoading = typeof this.isLoading !== 'undefined' ? this.isLoading : isGlobalLoading;
      return isLoading;
    }
  }
};
</script>
<style lang="scss" scoped>
.actions {
  position: absolute;
  bottom: 0;
  background-color: white;
  z-index: 2;
  left: 0;
  right: 0;
  //border-top: 1px solid #e0e0e0;
  padding: 12px;
}
</style>
