// initial state
const state = {
  appEnvironment: null,
  mobile: {
    pageTitle: ''
  },
  neutronVersion: {},
  neutronVersionNotifyTimeout: null,
  homePageUrl: 'https://www.opendock.com',
  supportUrl: 'https://community.loadsmart.com/hc/en-us/community/topics/14812876253715-OpenDock',
  termsAndConditionsUrl: 'https://loadsmart.com/subscription-and-professional-services-agreement',
  privacyPolicyUrl: 'https://loadsmart.com/privacy-policy'
};

// getters
const getters = {
  s3BaseUrl(state) {
    if (state.appEnvironment?.S3_DOWNLOAD_URL) {
      return state.appEnvironment.S3_DOWNLOAD_URL;
    }

    if (state.appEnvironment?.S3_BUCKET && state.appEnvironment?.S3_BASE) {
      return `https://${state.appEnvironment.S3_BUCKET}.${state.appEnvironment.S3_BASE}`;
    }

    return null;
  }
};

// actions
const actions = {
  async getAppEnvironment({ commit }) {
    await axios.get('app-environment').then(r => {
      commit('setAppEnvironment', r.data);
    });
  },
  async getNeutronVersion({ state, commit }) {
    await axios.get('/version').then(res => {
      if (res?.data) {
        this.neutronVersion = res.data;
        commit('setNeutronVersion', res.data);
      }
    });

    return state.neutronVersion;
  },
  async verifyNeutronVersion({ state, commit }, payload) {
    if (!payload?.version?.major || !state.neutronVersion.major) {
      return;
    }
    const isSameNeutronVersion =
      payload.version.commit === state.neutronVersion.commit &&
      payload.version.major === state.neutronVersion.major &&
      payload.version.minor === state.neutronVersion.minor &&
      payload.version.patch === state.neutronVersion.patch;

    if (payload.version && !isSameNeutronVersion && !state.neutronVersionNotifyTimeout) {
      const notifyGroup = 'top-notifications';
      const reloadFunction = () => {
        commit('setNeutronVersion', payload.version);
        location.reload();
      };
      // 1 minute between new messages, and 1 minute to reload after the message pops up.
      const reloadTimeout = setTimeout(reloadFunction, 240 * 1000);

      state.neutronVersionNotifyTimeout = setTimeout(
        () =>
          this.$app.notify(
            'New app version available. Page will auto-refresh in 2 minutes for the update.',
            'info',
            {
              duration: -1,
              actionText: 'Click here to cancel the auto-refresh',
              actionFn: () => {
                clearTimeout(reloadTimeout);
                clearTimeout(state.neutronVersionNotifyTimeout);
                state.neutronVersionNotifyTimeout = null;
                this.$app.$notify({
                  group: notifyGroup,
                  clean: true
                });
              },
              group: notifyGroup
            }
          ),
        120 * 1000
      );
    }
  }
};

// mutations
const mutations = {
  setAppEnvironment(state, value) {
    state.appEnvironment = value;
  },
  setMobilePageTitle(state, title) {
    state.mobile.pageTitle = title;
  },
  setNeutronVersion(state, value) {
    state.neutronVersion = value;
  }
};

export default {
  namespaced: true,
  name: 'App',
  state,
  getters,
  actions,
  mutations
};
