import { isUUID } from 'class-validator';

const defaultJoins = ['docks', 'docks.capacityChildren', 'org||name,settings'];

// initial state
const state = {
  isLoading: false,
  warehouses: [],
  pagination: {},
  filters: {}
};

// getters
const getters = {};

// actions
const actions = {
  async getWarehouseById({}, params) {
    if (typeof params === 'string' && isUUID(params)) {
      params = { id: params };
    }

    return this.$app.services.warehouse.getWarehouseById(params.id, params, {
      joins: defaultJoins
    });
  },
  async getFullWarehouseById({}, params) {
    if (typeof params === 'string' && isUUID(params)) {
      params = { id: params };
    }

    return this.$app.services.warehouse.getWarehouseById(params.id, params, {
      joins: defaultJoins,
      fields: null
    });
  },
  async getWarehouses({ commit, state }, options) {
    commit('setLoading', true);
    try {
      const warehouseResponse = await this.$app.services.warehouse.getListWarehouses(
        options?.filters || state.filters,
        { includeMetaData: true }
      );
      if (warehouseResponse?.data?.data) {
        commit('setApiResponseData', warehouseResponse.data);
        commit('setFilters', options?.filters || state.filters);
      }
    } finally {
      commit('setLoading', false);
    }

    return { warehouses: state.warehouses };
  },
  async handleSocketEvent() {},
  async createWarehouse({}, warehouse) {
    return axios.post('warehouse', warehouse);
  },
  async updateWarehouse({}, warehouse) {
    const { updatedAttributes } = warehouse;
    const warehouseOrg = { ...warehouse.org };

    // API requires that these do not exist
    delete warehouse.org;
    delete warehouse.orgId;
    delete warehouse.docks; // This one is by proxy, the docks contain org and orgID
    delete warehouse.updatedAttributes;

    const response = await axios.patch(`warehouse/${warehouse.id}`, warehouse);

    if (response?.data?.data) {
      response.data.data.org = warehouseOrg;
      const mixpanelData = {
        'Warehouse ID': warehouse.id,
        'Warehouse Name': warehouse.name,
        'Org ID': response.data.data.org.id,
        'Org Name': response.data.data.org.name,
        'Warehouse Changed Fields': updatedAttributes
      };
      this.$app.mixpanel.track(this.$app.mixpanel.events.MODULE.WAREHOUSE.UPDATED, mixpanelData);
    }

    return response;
  },
  async deleteWarehouse({}, warehouse) {
    const localStorageKey = `nova_calendar_${this.$app.$me.id}`;
    const calendarData = this.$app.$loadObjectFromLocalStorage(localStorageKey);
    if (calendarData?.selectedWarehouse?.id === warehouse.id) {
      this.$app.$updateObjectInLocalStorage(`${localStorageKey}.selectedWarehouse`, {});
    }
    return axios.delete(`warehouse/${warehouse.id}`);
  }
};

// mutations
const mutations = {
  setApiResponseData(state, response) {
    state.warehouses = response.data;
    state.pagination = {
      page: response.page,
      pageCount: response.pageCount,
      total: response.total
    };
  },
  setWarehouses(state, warehouses) {
    state.warehouses = warehouses;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setPagination(state, pagination) {
    state.pagination = {
      page: pagination.page,
      pageCount: pagination.pageCount,
      total: pagination.total
    };
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  }
};

export default {
  namespaced: true,
  name: 'Warehouses',
  state,
  getters,
  actions,
  mutations
};
