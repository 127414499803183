<template>
  <form-base>
    <template #form>
      <template v-if="loading">
        <v-card-text>
          Loading Appointment Data...
          <v-progress-linear color="secondary" indeterminate rounded height="8"></v-progress-linear>
        </v-card-text>
      </template>
      <v-form ref="form" v-else>
        <div
          v-for="localField of formFields"
          :key="localField.formFieldId"
          class="custom-field-wrapper">
          <span class="field-label" v-if="localField.customField.label">
            {{ localField.customField.label }}:
            <help-icon-tooltip v-if="localField.customField.description">
              {{ localField.customField.description }}
            </help-icon-tooltip>
          </span>

          <component
            v-bind="$attrs"
            v-bind:is="customFieldComponents[localField.customField.type]"
            :field="localField.customField"
            :warehouse="warehouse"
            v-model="localField.value"></component>
        </div>
        <div v-if="formFields.length === 0">
          <h3>No fields have been set for this form, please skip it.</h3>
        </div>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="$emit('close')"
        :confirm-label="shouldShowNextForm ? 'Next' : 'Confirm'"
        cancel-label="Nevermind"
        cancel-icon=""
        :stack-actions="stackActions"
        @confirm="shouldShowNextForm ? nextForm() : submit()">
        <template v-slot:additional-actions>
          <v-btn
            v-if="wasAutoTriggered"
            :class="{ 'full-width': stackActions }"
            outlined
            color="secondary"
            @click="skip">
            Skip {{ trigger.flow.name }}
          </v-btn>
        </template>
      </action-group>
    </template>
  </form-base>
</template>

<script>
import { isEqual } from 'lodash';
import { isMobile } from '@satellite/plugins/util';
import { computed, onMounted, ref } from 'vue';
import { useNovaCore, useMixpanel, useStore, useEventHub } from '@/composables';

const FORM_FROM = 'formFrom';
const FORM_TO = 'formTo';

export default {
  props: {
    trigger: {
      type: Object,
      default: null
    },
    objectId: {
      type: String,
      required: true
    },
    warehouse: {
      type: Object,
      required: true,
      default: () => ({})
    },
    statusChange: {
      type: String,
      required: false
    },
    stackActions: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const activeForm = ref(FORM_FROM);
    const localFields = ref([]);
    const submittedData = ref([]);
    const loading = ref(false);
    const novaCore = useNovaCore();
    const eventHub = useEventHub();
    const form = ref(null);
    const mixpanel = useMixpanel();

    const store = useStore();
    const org = store.$app.$org;
    const customFieldComponents = computed(() => {
      const componentsMap = {};
      Object.entries(novaCore.CustomFieldType).forEach(
        type => (componentsMap[type[1]] = `custom-field-${type[0].toLowerCase()}`)
      );
      return componentsMap;
    });

    const wasAutoTriggered = computed(() => {
      if (props.trigger?.category === novaCore.CustomFormFlowCategoryEnum.manualTrigger) {
        return false;
      }
      return !submittedData.value?.length && props.statusChange;
    });

    const flow = computed(() => props.trigger?.flow || {});

    const formFields = computed(() => {
      return localFields.value
        .filter(f => f.form === activeForm.value)
        .sort((a, b) => a.customField.order - b.customField.order);
    });

    const hasFormTo = computed(() => Boolean(flow.value.formTo?.id));

    const hasFieldConditionToShowNextForm = computed(() => {
      const { conditionFormFieldId, conditionOperator, conditionValue } = flow.value;

      if (conditionFormFieldId && conditionOperator) {
        const formField = localFields.value.find(f => f.formFieldId === conditionFormFieldId);
        return novaCore.applyFieldOperation(
          formField?.value,
          conditionValue,
          conditionOperator,
          isEqual
        );
      }

      return false;
    });

    const shouldShowNextForm = computed(() => {
      const canShow = hasFormTo.value && activeForm.value === FORM_FROM;
      return canShow && hasFieldConditionToShowNextForm.value;
    });

    const cleanUpFormToValues = () => {
      if (!hasFieldConditionToShowNextForm.value) {
        flow.value.formTo?.formFields.forEach(formToField => {
          const localFormToField = localFields.value.find(f => f.formFieldId === formToField.id);
          if (localFormToField) {
            localFormToField.value = null;
          }
        });
      }
    };

    const submit = () => {
      if (form.value.validate()) {
        const actionFields = new Set();

        localFields.value.forEach(f => {
          if (f.customField.type === novaCore.CustomFieldType.Action) {
            actionFields.add(f.value);
          }
        });

        const fields = [...localFields.value].filter(
          f => f.customField.type !== novaCore.CustomFieldType.Action
        );

        fields.forEach(f => {
          delete f.customField;
          delete f.form;
        });

        cleanUpFormToValues();

        const eventName = submittedData.value.length > 0 ? 'update' : 'create';
        emit(eventName, fields, props.statusChange);

        trackMixpanelFormSubmit();

        actionFields.forEach(feature => eventHub.$emit('action-field-submitted', feature));
        emit('close');

        return true;
      }
      return false;
    };

    const trackMixpanelFormSubmit = (isSkip = false) => {
      return mixpanel?.track(mixpanel.events.MODULE.CUSTOM_FORMS.SUBMIT_FORM, {
        'Custom Form Application': props.trigger.app,
        'Custom Form Type': props.trigger.feature,
        'Custom Form Trigger': props.trigger.category,
        'Appointment ID': props.objectId,
        'Warehouse ID': props.warehouse.id,
        'Warehouse Name': props.warehouse.name,
        'Org ID': org.id,
        'Org Name': org.name,
        'Entry Point': isMobile() ? 'Mobile Details Page' : 'Details Modal',
        'Was Skipped': isSkip ? 'Yes' : 'No',
        'Was Auto Triggered': wasAutoTriggered.value ? 'Yes' : 'No'
      });
    };

    const skip = () => {
      if (!props.statusChange) {
        emit('close');
        return;
      }

      const skip = true;

      emit('create', { skip }, props.statusChange);
      trackMixpanelFormSubmit(skip);
    };

    const nextForm = () => {
      if (form.value.validate()) {
        activeForm.value = FORM_TO;
      }
    };

    const getCustomField = (formField, existingValue) => {
      const field = formField.field;

      return {
        label: formField.overrideLabel || field.label,
        type: field.type,
        name: formField.id,
        description: field.description,
        order: formField.order,
        required: formField.required,
        dropDownValues: field.extraFields?.dropDownValues,
        placeholder: field.placeholder,
        minLengthOrValue: field.extraFields?.minLengthOrValue,
        maxLengthOrValue: field.extraFields?.maxLengthOrValue,
        value: existingValue
      };
    };

    const setSubmittedData = async () => {
      if (props.statusChange) {
        return;
      }
      loading.value = true;
      try {
        const response = await window.axios.get('custom-forms/form-data', {
          params: {
            s: { objectId: props.objectId, triggerId: props.trigger.id },
            limit: 1000
          }
        });
        submittedData.value = response?.data?.data || [];
      } catch (_) {
        submittedData.value = [];
      } finally {
        loading.value = false;
      }
    };

    const setLocalFields = () => {
      for (const form of [FORM_FROM, FORM_TO]) {
        for (const formField of flow.value[form]?.formFields || []) {
          const defaultValue = formField.field.type === 'bool' ? false : null;
          const existingField = submittedData.value.find(v => v.formField?.id === formField.id);
          const existingValue = existingField?.value;
          const existingId = existingField?.id;

          const value = existingValue || defaultValue;

          localFields.value.push({
            form,
            id: existingId,
            customField: getCustomField(formField, value),
            objectId: props.objectId,
            entityName: novaCore.CustomFormDataEntitiesEnum.appointment,
            formFieldId: formField.id,
            triggerId: props.trigger.id,
            value
          });
        }
      }
    };

    onMounted(async () => {
      await setSubmittedData();
      setLocalFields();
    });

    return {
      activeForm,
      localFields,
      submittedData,
      loading,
      customFieldComponents,
      wasAutoTriggered,
      flow,
      formFields,
      hasFormTo,
      hasFieldConditionToShowNextForm,
      shouldShowNextForm,
      cleanUpFormToValues,
      submit,
      trackMixpanelFormSubmit,
      skip,
      nextForm,
      getCustomField,
      setLocalFields,
      form
    };
  }
};
</script>

<style scoped lang="scss">
.field-label {
  color: $color-text-tertiary;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
::v-deep .custom-field-wrapper .v-input:not([class*='mt-']) {
  margin-top: 0 !important;
}
</style>
