<template>
  <v-row class="py-4">
    <v-col cols="12">
      <v-list dense>
        <v-list-item class="pl-0">
          <v-list-item-icon class="mr-2">
            <v-icon color="success" v-if="warehouse.geolocation">mdi-check-circle</v-icon>
            <v-icon color="error" v-else>mdi-alert-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Feature {{ warehouse.geolocation ? 'enabled' : 'disabled' }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col cols="12" class="mt-4">
      <v-card v-if="warehouse.geolocation" class="qr-code-card w-95">
        <v-card-text>
          <div class="d-flex flex-row">
            <div class="mr-8">
              <canvas id="checkin-qr"></canvas>
            </div>
            <div>
              <p class="text-subtitle-1 font-weight-bold text--primary mb-2">
                QR Code & Check-in template
              </p>
              <p class="text-body-2 text--primary mb-8">
                Print the Check-in template and display it to drivers upon their arrival.
              </p>
              <div class="d-flex flex-row">
                <div class="mr-2">
                  <v-menu internal-activator close-on-click bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <button-base
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        after-icon="chevron-down"
                        append>
                        DOWNLOAD
                      </button-base>
                    </template>
                    <v-list>
                      <v-list-item v-for="(item, index) in downloadChoices" :key="index">
                        <button-base small text block class="text-left" @click="item.action">
                          <span class="flex-grow-1 justify-start">{{ item.title }}</span>
                        </button-base>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <v-btn text @click="jumpToCheckIn()">Check-in URL</v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-col cols="12" class="mt-2" v-else>
        <v-btn color="secondary" @click="requestCheckInFeature">request feature</v-btn>
      </v-col>
    </v-col>
    <gate-management-template
      :warehouse-name="warehouse.name"
      id="printable-warehouse-template"></gate-management-template>
  </v-row>
</template>

<script>
import QRCode from 'qrcode';
import config from '@/config/config';

export default {
  name: 'GateManagementChecklist',
  props: {
    warehouse: {
      required: true,
      type: Object
    }
  },
  computed: {
    checkInUrl() {
      return `${config.comet_url}/?warehouseId=${this.warehouse.id}`;
    },
    downloadChoices() {
      return [
        {
          title: 'Check-in template',
          action: this.downloadCheckInTemplate
        },
        {
          title: 'QR Code image',
          action: this.downloadCheckInQr
        }
      ];
    }
  },
  methods: {
    jumpToCheckIn() {
      window.open(this.checkInUrl, '_blank');
    },
    downloadCheckInQr() {
      QRCode.toDataURL(
        this.checkInUrl,
        {
          errorCorrectionLevel: 'Q',
          width: 1000
        },
        (err, url) => {
          const linkEl = document.createElement('a');
          linkEl.href = url;
          linkEl.download = `${this.warehouse.name}-checkin-qrcode.png`;
          linkEl.click();
        }
      );
    },
    downloadCheckInTemplate() {
      QRCode.toDataURL(
        this.checkInUrl,
        {
          errorCorrectionLevel: 'Q',
          width: 1000
        },
        (err, url) => {
          /**
           * Set generated qrcode inside the printable warehouse template
           */
          {
            const qrcodePlaceholder = document.getElementById('qrcode-template-placeholder');
            qrcodePlaceholder.innerHTML = '';

            const img = document.createElement('img');
            img.src = url;
            img.width = 270;
            img.height = 270;
            qrcodePlaceholder.appendChild(img);
          }

          /**
           * Create a new element for printing only the warehouse template
           */
          {
            const templateDomClone = document
              .getElementById('printable-warehouse-template')
              .cloneNode(true);

            const instructionsPrintable =
              document.getElementById('print-content') ?? document.createElement('section');
            instructionsPrintable.innerHTML = '';
            instructionsPrintable.id = 'print-content';
            instructionsPrintable.appendChild(templateDomClone);
            document.body.appendChild(instructionsPrintable);
          }
          /**
           * Wait 2 seconds before print the new content for the image to load
           */
          {
            this.notify('Warehouse template is being generated for printing...', 'info');
            setTimeout(() => window.print(), 2000);
          }
        }
      );
    },
    requestCheckInFeature() {
      this.mixpanel.track(this.mixpanel.events.MODULE.SELF_CHECK_IN.SELF_CHECK_IN_REQUESTED, {
        'Entry Point': 'Gate Management Settings',
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Warehouse ID': this.warehouse.id,
        'Warehouse Name': this.warehouse.name
      });

      this.notify('Your request has been submitted, your CSA will contact you soon');
    }
  },
  mounted() {
    this.$nextTick(() => {
      const canvas = document.getElementById('checkin-qr');

      QRCode.toCanvas(
        canvas,
        this.checkInUrl,
        {
          errorCorrectionLevel: 'Q',
          width: 180,
          margin: 0
        },
        function () {}
      );
    });
  }
};
</script>

<style scoped>
.qr-code-card {
  background: #f8fbfc;
}
</style>
