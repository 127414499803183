<template>
  <div class="d-flex flex-row align-center full-width">
    <v-autocomplete
      auto-select-first
      dense
      height="25px"
      :class="{ required: required }"
      required
      @input="updateValue"
      :value="value"
      :rules="rules"
      :label="label"
      item-text="label"
      item-value="value"
      v-bind="$attrs"
      :items="options"></v-autocomplete>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';

/**
 * State Select
 * @displayName State Select
 */
export default {
  mixins: [selectMixin],
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false
    },
    /**
     * Select placeholder
     */
    placeholder: {
      type: String,
      required: false,
      default: 'State'
    },
    rules: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Street Address'
    },
    country: {
      type: String,
      default: 'USA',
      validator: value => {
        return ['USA', 'CAN'].includes(value);
      }
    }
  },
  methods: {
    /**
     * V-model
     * @public
     * @param state
     */
    updateValue(state) {
      this.$emit('input', state);
    }
  },
  computed: {
    options() {
      let options = [];
      if (this.country === 'USA') {
        options = this.util.getAmericanStateSelectOptions();
      } else if (this.country === 'CAN') {
        options = this.util.getCanadianProvinceSelectOptions();
      }

      return Object.entries(options).map(entry => {
        return {
          label: entry[0],
          value: entry[1]
        };
      });
    }
  }
};
</script>
