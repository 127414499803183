var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("apexchart", {
    staticClass: "treemap-chart",
    attrs: {
      type: "treemap",
      height: "350",
      options: _vm.chartOptions,
      series: _vm.series,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }