<template>
  <v-card class="elevation-1" v-if="ready">
    <v-card-text class="pa-1">
      <bar-chart
        title="Average Dwell Time in Minutes"
        :horizontal="true"
        :no-data-text="this.errorMsg"
        :data="chartData"></bar-chart>
    </v-card-text>
  </v-card>

  <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
</template>

<script>
import moment from 'moment/moment';

/**
 * Appointments By Time of Day Bar Chart
 * @displayName Appointments By Time of Day Bar Chart
 */
export default {
  data() {
    return {
      dockMetrics: {},
      ready: false,
      chartData: [],
      errorMsg: null,
      currentRequests: []
    };
  },
  computed: {
    warehouseDocks() {
      return this.selectedWarehouse?.id ? this.selectedWarehouse.docks : [];
    }
  },
  props: {
    selectedWarehouse: {
      type: Object,
      required: true,
      default: null
    },
    dateRange: {
      type: Array,
      required: false,
      default: () => []
    },
    showAllTime: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    dockMetrics: {
      handler() {
        this.formatData();
      },
      deep: true
    },
    selectedWarehouse() {
      this.getData();
    },
    dateRange() {
      this.getData();
    },
    showAllTime() {
      this.getData();
    }
  },
  methods: {
    async getData() {
      const range = !this.showAllTime
        ? {
            fromDate: this.dateRange[0],
            toDate: this.dateRange[1]
          }
        : {
            fromDate: moment(this.$org.createDateTime).format(
              this.novaCore.DateTimeFormats.DateDashed
            ),
            toDate: moment().add(6, 'months').format(this.novaCore.DateTimeFormats.DateDashed)
          };

      const params = {
        warehouseId: this.selectedWarehouse?.id,
        toDate: range.toDate,
        fromDate: range.fromDate
      };
      const url = `/metrics/dock/dwell-time`;
      const semaphoreKey = encodeURI(`${url}-${JSON.stringify(params)}`);

      if (this.currentRequests.includes(semaphoreKey)) {
        return;
      }
      this.currentRequests.push(semaphoreKey);
      axios
        .get(url, {
          params
        })
        .then(response => {
          if (response?.data) {
            this.dockMetrics = response.data?.data;
          }
        })
        .finally(
          () => (this.currentRequests = this.currentRequests.filter(r => r !== semaphoreKey))
        );
    },
    formatData() {
      this.errorMsg = null;
      this.chartData = [];
      const dwellData = this.dockMetrics.all;

      for (let dataKey in dwellData) {
        this.chartData.push({
          x: dataKey,
          y: dwellData[dataKey]
        });
      }

      this.ready = true;
    }
  },
  mounted() {
    setInterval(() => {
      this.getData();
    }, 600000);

    this.getData();
  }
};
</script>
