<!--
  -- this component is using the new Vue Composition API, this is a step towards our goal
  -- of migrating helios into Vue3. To learn more about it, check:
  -- https://vuejs.org/api/composition-api-setup
  -- https://vuejs.org/guide/extras/composition-api-faq.html
-->
<template>
  <carrier-list />
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CarriersPage'
});
</script>
