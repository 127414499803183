import { ref } from 'vue';
export default function useRenderKey() {
  const renderKey = ref(0);
  function rerender() {
    renderKey.value++;
  }

  return {
    renderKey,
    rerender
  };
}
