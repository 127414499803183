<template>
  <div class="pa-2">
    <h3 class="mb-4">
      <v-icon color="white" small class="mr-2">mdi-truck</v-icon>
      {{ assetVisit.company?.name || assetVisit.companyHint || 'Unknown company' }}
    </h3>

    <v-row>
      <v-col>
        <strong>Appointment status</strong>
        <p class="text-capitalize" v-if="!appointment?.id">No Appointment scheduled!</p>

        <p v-else>
          <v-chip :color="appointment.status.toLowerCase()" dark small pill>
            {{ appointment.status.toUpperCase() }}
          </v-chip>
        </p>
      </v-col>

      <v-col>
        <strong>Docked</strong>
        <p class="text-capitalize">{{ dockedTime }}</p>
      </v-col>
    </v-row>
    <v-row v-if="appointment?.id">
      <v-col>
        <strong>{{ $refNumSettings(selectedWarehouse).displayName }}</strong>
        <p>{{ appointment.refNumber || '-' }}</p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { LuxonDateTimeFormats } from '@nova/core';

export default defineComponent({
  name: 'YardViewSpotTooltip',
  props: {
    spot: {
      type: Object,
      required: true
    },
    appointment: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const yardStore = useYard();

    const spotAssignment = computed(() => props.spot.spotAssignments[0]);
    const assetVisit = computed(() => spotAssignment.value?.assetVisit);

    const dockedTime = computed(() => {
      return yardStore.formatDateTime(
        spotAssignment.value?.createDateTime,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        LuxonDateTimeFormats.Extended24HrTime
      );
    });

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      spotAssignment,
      assetVisit,
      dockedTime
    };
  }
});
</script>

<style scoped lang="scss"></style>
