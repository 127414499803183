<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="New Carrier Company"
    :scrollable="false"
    @close="close"
    @confirm="$refs.createCompanyForm.submit()"
    class="mx-auto"
    has-dialog-actions
    max-width="560px"
    width="50%">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <v-tooltip top :disabled="$rolePermissions.canCreateCarrier">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <!--
            @slot Custom activator for the dialog
            @binding {object} slotProps Object containing {on, attrs}
            -->
            <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
          </div>
        </template>
      </v-tooltip>
    </template>

    <template v-slot:body>
      <create-company-form
        :entry-point="entryPoint"
        ref="createCompanyForm"
        @close="close"
        @save="handleCompanySave"></create-company-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * @displayName Create Carrier Company Dialog
 */
export default {
  name: 'CreateCarrierCompanyDialog',
  props: {
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    }
  },
  mixins: [dialogMixin],
  methods: {
    handleCompanySave(company) {
      this.$emit('save', company);
    }
  }
};
</script>
