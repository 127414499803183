<template>
  <div class="d-block text-right font-size-xx-small message-footer">
    <span v-if="isSystemMessage(message)">
      <v-icon color="orange" size="large" large>mdi-information-outline</v-icon>
      This Message was sent to the driver automatically.
    </span>
    <span v-else>{{ isOutgoingMessage(message) ? getFullName(message.createdByUser) : '' }}</span>
    <span v-if="isOutgoingMessage(message)" class="dot-divider">•</span>
    <span class="message-timestamp">
      {{ makeChatTimestamp(message.createDateTime, warehouse.timezone, false) }}
    </span>
    <span v-if="shouldShowStatusIcon" class="pl-1 status-icon">
      <v-tooltip left color="black">
        <template v-slot:activator="{ on: onTooltip, attrs }">
          <v-icon v-bind="attrs" v-on="onTooltip" :color="statusIcon.color">
            {{ statusIcon.icon }}
          </v-icon>
        </template>
        <span style="text-transform: capitalize">Status: {{ status }}</span>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
import { useMessageThread, useNovaCore } from '@/composables';
import { computed } from 'vue';

export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    },
    assetVisit: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      required: false
    }
  },

  setup(props) {
    const novaCore = useNovaCore();
    const { isIncomingMessage, isSystemMessage, makeChatTimestamp, isOutgoingMessage } =
      useMessageThread(props.assetVisit);
    const shouldShowStatusIcon =
      (props.status === novaCore.IMessageStatus.SENT ||
        props.status === novaCore.IMessageStatus.DELIVERED) &&
      isOutgoingMessage(props.message);

    const getFullName = user => {
      if (!user) {
        return 'Warehouse User';
      }
      return `${user.firstName} ${user.lastName}`;
    };

    const statusIcon = computed(() => {
      if (props.status === novaCore.IMessageStatus.SENT) {
        return {
          icon: 'mdi-check-circle',
          color: 'inherit'
        };
      }
      if (props.status === novaCore.IMessageStatus.DELIVERED) {
        return {
          icon: 'mdi-check-circle',
          color: 'primary'
        };
      }
      return {};
    });

    return {
      isIncomingMessage,
      isSystemMessage,
      makeChatTimestamp,
      getFullName,
      novaCore,
      statusIcon,
      shouldShowStatusIcon,
      isOutgoingMessage
    };
  }
};
</script>

<style scoped lang="scss">
.message-footer {
  padding-top: 2px;
  line-height: normal;
  .v-icon {
    vertical-align: text-bottom;
  }
}

.dot-divider {
  padding: 0 3px 0 3px;
}
</style>
