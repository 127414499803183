var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "px-4 py-2 hours-of-operation-item d-flex is-relative align-center",
      class: { closed: _vm.hoopStatus === _vm.closedStatus },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center weekday-controls" },
        [
          _c(
            "span",
            {
              staticClass:
                "font-weight-bold font-size-small weekday-label d-inline-block",
            },
            [_vm._v(_vm._s(_vm.weekdayLabel))]
          ),
          !_vm.readOnly
            ? _c(
                "v-btn-toggle",
                {
                  staticClass:
                    "day-status-toggle secondary-button-group lighten-2",
                  attrs: { rounded: "", mandatory: "", dense: "" },
                  model: {
                    value: _vm.status,
                    callback: function ($$v) {
                      _vm.status = $$v
                    },
                    expression: "status",
                  },
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        "data-testid": `${_vm.weekday}-closed-button`,
                                        small: "",
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-cancel")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        376363555
                      ),
                    },
                    [_c("span", [_vm._v("Set to closed")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        "data-testid": `${_vm.weekday}-specific-hours-button`,
                                        small: "",
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-calendar-arrow-right"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3297100482
                      ),
                    },
                    [_c("span", [_vm._v("Set specific hours of operation")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        "data-testid": `${_vm.weekday}-24-hours-button`,
                                        small: "",
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-hours-24")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        316168535
                      ),
                    },
                    [_c("span", [_vm._v("Set hours of operation to 24 hours")])]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.hoopStatus === _vm.closedStatus
        ? _c(
            "span",
            {
              staticClass:
                "d-inline-block my-4 font-size-x-small font-weight-bold ml-4",
            },
            [_vm._v("Closed")]
          )
        : _vm.hoopStatus === _vm.alwaysOpenStatus
        ? _c(
            "span",
            {
              staticClass:
                "d-inline-block my-4 font-size-x-small font-weight-bold ml-4",
            },
            [_vm._v("Open 24 Hours")]
          )
        : _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column pt-1 time-range-select-group",
                },
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "time-range-group pl-0 ml-4",
                      attrs: { name: "time-range-group", tag: "ul" },
                    },
                    _vm._l(_vm.value, function (rangeSpan, index) {
                      return _c("time-range-selector", {
                        key: `${_vm.weekday}-${index}`,
                        staticClass: "my-2",
                        attrs: {
                          readonly: _vm.readOnly,
                          "date-range-select-items": _vm.dateRangeSelectItems,
                          transition: "scroll-y-transition",
                          index: index,
                          "interval-length": _vm.intervalLength,
                          "from-items": _vm.fromItems[index],
                          "to-items": _vm.toItems[index],
                        },
                        on: { "delete-range": _vm.deleteRange },
                        model: {
                          value: _vm.value[index],
                          callback: function ($$v) {
                            _vm.$set(_vm.value, index, $$v)
                          },
                          expression: "value[index]",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.canAddHours
                ? _c(
                    "v-btn",
                    {
                      staticClass: "add-hours-button",
                      attrs: { text: "" },
                      on: { click: _vm.addRange },
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1 font-size-x-small" }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _vm._v(" Add Hours "),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.readOnly
                ? _c(
                    "v-dialog",
                    {
                      attrs: { width: "350" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: menu, attrs }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      top: "",
                                      disabled: _vm.showWeekdayPicker,
                                      "open-on-focus": false,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on: tooltip }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "side-button",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.selectedDays = []
                                                          _vm.showWeekdayPicker
                                                          _vm.renderKey++
                                                        },
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  { ...tooltip, ...menu }
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-content-copy"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Clone Schedule to Other Days"),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2164231879
                      ),
                      model: {
                        value: _vm.showWeekdayPicker,
                        callback: function ($$v) {
                          _vm.showWeekdayPicker = $$v
                        },
                        expression: "showWeekdayPicker",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("dialog-header", {
                            attrs: { header: "Select Days" },
                            on: {
                              close: function ($event) {
                                _vm.showWeekdayPicker = false
                              },
                            },
                          }),
                          _c(
                            "v-card-text",
                            [
                              _c("weekday-checkbox-group", {
                                key: _vm.renderKey,
                                attrs: { "disabled-day": _vm.weekday },
                                model: {
                                  value: _vm.selectedDays,
                                  callback: function ($$v) {
                                    _vm.selectedDays = $$v
                                  },
                                  expression: "selectedDays",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "outline-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showWeekdayPicker = false
                                    },
                                  },
                                },
                                [_vm._v("Nevermind")]
                              ),
                              _c(
                                "primary-button",
                                { on: { click: _vm.copySchedule } },
                                [_vm._v(" Clone Schedule")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }