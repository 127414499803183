var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: {
          header: _vm.headerText,
          "max-width": "85vw",
          width: "85vw",
          loading: false,
          scrollable: true,
        },
        on: { close: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { "before-icon": "users", large: "" },
                                },
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v(" View Users ")]
                          ),
                    ]
                  },
                }
              : null,
            {
              key: "body",
              fn: function () {
                return [
                  _c("user-list", {
                    attrs: {
                      "org-id": _vm.orgId,
                      flat: "",
                      "max-items-per-page": 5,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "dialog-actions",
              fn: function () {
                return [
                  _c("action-group", {
                    attrs: { "hide-cancel": "", "confirm-label": "close" },
                    on: { confirm: _vm.close },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }