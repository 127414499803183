var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dialog-base",
        _vm._b(
          {
            key: _vm.renderKey,
            staticClass: "mx-auto",
            attrs: {
              header: "Edit Carrier Contact",
              loading: false,
              scrollable: false,
              "has-dialog-actions": "",
              "max-width": "50%",
              width: "50%",
            },
            on: {
              close: _vm.close,
              confirm: _vm.submit,
              open: _vm.initializeForm,
            },
            scopedSlots: _vm._u(
              [
                !_vm.externalActivator
                  ? {
                      key: "activator",
                      fn: function (slotProps) {
                        return [
                          _vm.$scopedSlots.activator
                            ? _vm._t("activator", null, null, slotProps)
                            : _vm._e(),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "dialog-base",
          _vm.$props,
          false
        ),
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        prominent: "",
                        type: "warning",
                        icon: "mdi-alert-octagon-outline",
                        color: "error",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _c("strong", [
                              _vm._v("This is a system-wide action!"),
                            ]),
                            _c("br"),
                            _vm._v(
                              " Modifying this carrier contact will affect ALL Opendock organizations. "
                            ),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "shrink" },
                            [
                              !_vm.isRiskAcknowledged
                                ? _c(
                                    "v-btn",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.isRiskAcknowledged = true
                                        },
                                      },
                                    },
                                    [_vm._v(" I Understand ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "form-base",
                    { attrs: { "required-legend-class": "pl-3" } },
                    [
                      _c(
                        "template",
                        { slot: "form" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { disabled: !_vm.isRiskAcknowledged },
                            },
                            [
                              _c("text-field", {
                                attrs: {
                                  required: true,
                                  type: "text",
                                  rules:
                                    _vm.$validator.rules.required("First Name"),
                                  label: "First Name",
                                },
                                model: {
                                  value: _vm.localCarrier.firstName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localCarrier, "firstName", $$v)
                                  },
                                  expression: "localCarrier.firstName",
                                },
                              }),
                              _c("text-field", {
                                attrs: {
                                  required: true,
                                  type: "text",
                                  rules:
                                    _vm.$validator.rules.required("Last Name"),
                                  label: "Last Name",
                                },
                                model: {
                                  value: _vm.localCarrier.lastName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localCarrier, "lastName", $$v)
                                  },
                                  expression: "localCarrier.lastName",
                                },
                              }),
                              _c("text-field", {
                                attrs: { type: "text", label: "Phone Number" },
                                model: {
                                  value: _vm.localCarrier.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localCarrier, "phone", $$v)
                                  },
                                  expression: "localCarrier.phone",
                                },
                              }),
                              _vm.localCarrier
                                ? _c("company-select", {
                                    ref: "companySelect",
                                    attrs: {
                                      "hide-details": "",
                                      "entry-point": _vm.entryPoint,
                                      placeholder:
                                        "Search for a company name, scac, usdot or mc...",
                                      rules:
                                        _vm.$validator.rules.required(
                                          "Carrier Company"
                                        ),
                                      "hide-icon": "",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.localCarrier.companyId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localCarrier,
                                          "companyId",
                                          $$v
                                        )
                                      },
                                      expression: "localCarrier.companyId",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "secondary--text font-weight-bold cursor-pointer",
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    disabled: !_vm.isRiskAcknowledged,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showCompanyDialog = true
                                    },
                                  },
                                },
                                [_vm._v(" Create a New Company ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "form-actions" },
                        [
                          _c("action-group", {
                            attrs: {
                              "disable-confirm": !_vm.isRiskAcknowledged,
                              "confirm-icon": "upload",
                              "confirm-label": "Update Carrier",
                            },
                            on: { cancel: _vm.close, confirm: _vm.submit },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "dialog-base",
        {
          attrs: {
            header: "New Carrier Company",
            loading: false,
            "external-activator": true,
            "show-dialog": _vm.showCompanyDialog,
            "has-dialog-actions": "",
            "max-width": "560px",
          },
          on: {
            close: function ($event) {
              _vm.showCompanyDialog = false
            },
            confirm: _vm.handleCompanySave,
          },
        },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _vm.showCompanyDialog
                ? _c("create-company-form", {
                    on: {
                      close: function ($event) {
                        _vm.showCompanyDialog = false
                      },
                      save: _vm.handleCompanySave,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }