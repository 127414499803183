export default {
  props: {
    // Parent Appointment for the recurring series
    recurringParentAppointment: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  computed: {
    // An object with weekDays string array, endDate string, and endTime string
    recurringPattern() {
      let pattern = {};
      if (this.novaCore.isRecurringAppointment(this.appointment)) {
        const parentAppointment = this.appointment.recurringParent;

        // NOTE: If the appointment is recurring, it is expected that the
        // parent WILL have a recurringPattern. However, the parent appt. gets
        // updated AFTER childs are created, so there is a quick point in time
        // where it may not be filled yet.
        if (parentAppointment.recurringPattern) {
          pattern = this.novaCore.getReadableRecurringPatternParts(
            parentAppointment.recurringPattern,
            parentAppointment.start,
            this.appointment.dock.warehouse.timezone
          );
        }
      }
      return pattern;
    }
  }
};
