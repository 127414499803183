import Vue from 'vue';
import { Settings, AppointmentStatus } from '@satellite/../nova/core';

// initial state
const state = {
  settingsMetadata: null,
  developerSettingsCounter: 0,
  localSettings: {
    showDeveloperSettings: false,
    logSubspace: false
  }
};

// getters
const getters = {
  settingValue:
    state =>
    (settingKey, entitySettings = {}, entityType = null, defaultValue = null) => {
      let result = entitySettings ? entitySettings[settingKey] : defaultValue;

      // If the settings key exists on the entity settings then return the value
      if (typeof result !== 'undefined' && result !== null) {
        return result;
      }

      // If there is no specific type requested, look for the default value on the settings object
      if (!entityType) {
        result = Settings[settingKey].defaultValue;
      } else {
        try {
          result = state.settingsMetadata[entityType][settingKey].defaultValue;
        } catch (e) {
          throw new Error('Setting does not exist');
        }
      }

      // If no result was found in the entity or the metadata then throw
      if (typeof result === 'undefined') {
        throw new Error('Setting does not exist');
      }

      return result;
    },
  refNumSettings: (state, getters) => entityWithConsolidatedSettings => {
    const effectiveSettings = entityWithConsolidatedSettings?.settings ?? null;
    return {
      displayName: getters.settingValue('referenceNumberDisplayName', effectiveSettings),
      helperText: getters.settingValue('referenceNumberHelperText', effectiveSettings),
      isRequired: getters.settingValue('referenceNumberIsRequired', effectiveSettings),
      isVisible: getters.settingValue('referenceNumberIsVisible', effectiveSettings),
      isUnique: getters.settingValue('referenceNumberIsUnique', effectiveSettings)
    };
  },
  isMilitaryTimeEnabled: (state, getters) => entityWithConsolidatedSettings => {
    // The entityWithConsolidatedSettings can be a warehouse or an org. It depends on the needs.
    // Most of the time, the most specific one (WH) is preferable, but there are places like
    // reporting where we go after the Org directly, since multiple warehouses are mixed in.
    const effectiveSettings = entityWithConsolidatedSettings?.settings ?? null;
    return getters.settingValue('enableMilitaryTime', effectiveSettings);
  },
  isRescheduleByCarrierForbidden: (state, getters) => entityWithConsolidatedSettings => {
    const effectiveSettings = entityWithConsolidatedSettings?.settings ?? null;
    return getters.settingValue('rescheduleByCarrierForbidden', effectiveSettings) === true;
  },
  isRequestedStatusEnabled: (state, getters) => entityWithConsolidatedSettings => {
    const effectiveSettings = entityWithConsolidatedSettings?.settings ?? null;
    return (
      getters.settingValue('appointmentCreationStatus', effectiveSettings) ===
      AppointmentStatus.Requested
    );
  }
};

// actions
const actions = {
  async getSettingsMetadata({ commit }) {
    const response = await axios.get(`settings-metadata`);

    if (response.data) {
      commit('setSettingsMetadata', response.data);
    }

    return response.data;
  },
  async getSettingsMetadataByEntityType({}, entityType) {
    return await axios.get(`settings-metadata/${entityType}`);
  },
  async getSettingsMetadataByKey({}, { entityType, settingKey }) {
    return await axios.get(`settings-metadata/${entityType}/${settingKey}`);
  },
  async validateSettings({}, { entityType, settings }) {
    const response = await axios
      .post(`settings-metadata/validate/${entityType}`, settings)
      .catch(() => {
        return false;
      });

    return response?.status === 200;
  }
};

// mutations
const mutations = {
  setSettingsMetadata(state, data) {
    state.settingsMetadata = data;
  },
  setLogSubspace(state, val) {
    state.localSettings.logSubspace = val;
    this.$app.$updateObjectInLocalStorage('local_settings.logSubspace', val);

    if (val) {
      this.$app.addSubspaceLoggingEventListener();
    } else {
      this.$app.removeSubspaceLoggingEventListener();
    }
  },
  incrementDeveloperSettingsCounter(state) {
    state.developerSettingsCounter++;
  },
  setShowDeveloperSettings(state, val) {
    state.localSettings.showDeveloperSettings = val;
    this.$app.$updateObjectInLocalStorage('local_settings.showDeveloperSettings', val);
  },
  setLocalSettings(state, localSettings) {
    Vue.set(state, 'localSettings', { ...state.localSettings, ...localSettings });
  }
};

export default {
  namespaced: true,
  name: 'Settings',
  state,
  getters,
  actions,
  mutations
};
