<template>
  <div>
    <v-card :class="{ disabled: readOnly }">
      <v-card-title>
        <v-row align="center" class="search-field">
          <v-col md="6">
            <text-field
              class="mb-5"
              v-model="searchStr"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details></text-field>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <field-form-dialog
          :read-only="readOnly"
          @save="createField"
          :warehouse="warehouse"
          :fieldTypeOptions="fieldTypeOptions"></field-form-dialog>
        <clone-custom-fields-dialog
          :read-only="readOnly"
          v-if="showCloneButton && warehouse"
          :target-warehouse="warehouse"></clone-custom-fields-dialog>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :class="{ disabled: readOnly }"
          item-key="name"
          disable-sort
          disable-pagination
          hide-default-footer
          :headers="rowHeaders"
          id="custom-fields-table"
          :items="customFieldsList">
          <template v-slot:item.sort="{}" v-if="$rolePermissions.canUpdateWarehouse">
            <v-icon color="black" class="handle">mdi-arrow-split-horizontal</v-icon>
          </template>

          <template v-slot:header.sort>
            <div class="d-flex">
              <span class="d-inline-block mr-1">Sort</span>
              <help-icon-tooltip>
                The order of how this data displays across the platform. The top two will display on
                the appointment tile in the appointments grid.
              </help-icon-tooltip>
            </div>
          </template>

          <template v-slot:item.type="{ item }">
            {{ novaCore.customFieldLabels[item.type] }}
          </template>

          <template v-slot:item.required="{ item }">
            {{ getRequiredValues(item) }}
          </template>

          <template v-slot:item.hiddenFromCarrier="{ item }">
            {{ item.hiddenFromCarrier ? 'Yes' : 'No' }}
          </template>

          <template v-slot:item.actions="{ item }" v-if="$rolePermissions.canUpdateWarehouse">
            <div class="row-actions">
              <field-form-dialog
                :read-only="readOnly"
                :field="item"
                :warehouse="warehouse"
                @save="updateField"
                :fieldTypeOptions="fieldTypeOptions">
                <template v-slot:activator="slotProps">
                  <v-icon
                    :disabled="readOnly"
                    :id="item.id"
                    @click="setEditingIndex(item)"
                    small
                    class="mr-2"
                    color="secondary"
                    v-bind="slotProps.attrs"
                    v-on="slotProps.on">
                    mdi-pencil
                  </v-icon>
                </template>
              </field-form-dialog>

              <icon-tooltip-button
                @click="deleteField(item)"
                size="large"
                :tooltip="readOnly ? novaCore.getRoleActionError() : 'Delete Field'"
                :disabled="readOnly"
                delete></icon-tooltip-button>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Sortable from 'sortablejs';

/**
 * Custom Field List
 * @displayName Custom Field List
 */
export default {
  props: {
    /**
     * Template for the custom fields
     */
    fieldTemplate: {
      type: Array,
      required: true
    },
    showCloneButton: {
      type: Boolean,
      required: false,
      default: false
    },
    warehouse: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      editingIndex: null,
      reRender: 0,
      headers: [
        { text: 'Sort', sortable: false, value: 'sort' },
        { text: 'Field Label', sortable: false, value: 'label', searchable: true },
        { text: 'Field Description', sortable: false, value: 'description', searchable: true },
        { text: 'Field Type', sortable: false, value: 'type', searchable: true },
        { text: 'Required', sortable: false, value: 'required' },
        { text: 'Hidden from Carriers', sortable: false, value: 'hiddenFromCarrier' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
      ],
      localFieldTemplate: [],
      searchStr: null
    };
  },
  computed: {
    rowHeaders() {
      const headersCopy = this.novaCore.deepClone(this.headers);
      if (!this.$rolePermissions.canUpdateWarehouse || this.readOnly) {
        headersCopy.shift();
        headersCopy.pop();
        headersCopy[headersCopy.length - 1].align = 'end';
      }

      return headersCopy;
    },
    fieldTypeOptions() {
      return Object.entries(this.novaCore.customFieldLabels)
        .filter(entry => entry[0] !== 'action')
        .map(entry => {
          const fieldCode = entry[0];
          const fieldLabel = entry[1];
          const fieldDescription = this.novaCore.customFieldDescriptions[fieldCode];

          return {
            value: fieldCode,
            text: `<strong>${fieldLabel}</strong> - ${fieldDescription}`
          };
        });
    },
    customFieldsList() {
      return (
        this.localFieldTemplate?.filter(customField => {
          // Search is made offline since the custom field list
          // is loaded at once through the warehouse call,
          // no server side pagination.
          return this.searchStr?.length > 0
            ? this.headers
                .filter(h => h.searchable)
                .some(header => {
                  const dockField = customField[header.value]?.toLowerCase();
                  return dockField ? dockField.includes(this.searchStr.toLowerCase()) : false;
                })
            : true;
        }) ?? []
      );
    }
  },
  methods: {
    /**
     * Add a new field to list
     * @public
     * @param field
     */
    createField(field) {
      this.localFieldTemplate.push(field);

      this.emitSave('create', field);
    },
    /**
     * Update an existing custom field in the list
     * @public
     * @param field
     */
    updateField(field) {
      this.localFieldTemplate[this.editingIndex] = field;

      this.emitSave('update', field);
    },
    /**
     * Delete a field from list
     * @public
     * @param field
     */
    deleteField(field) {
      this.$confirm(null, {
        deleteConfirmation: true,
        icon: 'mdi-delete-forever',
        title: 'Delete this Appointment Field?',
        entityName: `${field.label} (${this.novaCore.customFieldLabels[field.type]})`,
        width: 475,
        buttonTrueColor: 'red',
        buttonTrueText: 'YES, DELETE',
        confirmationInputText: field.label
      }).then(confirmed => {
        if (confirmed) {
          this.localFieldTemplate.splice(this.localFieldTemplate.indexOf(field), 1);

          this.emitSave('delete', field);
        }
      });
    },
    saveCallback(isSucceed, operation, field) {
      if (!isSucceed) {
        if (operation === 'create') {
          this.localFieldTemplate.splice(this.localFieldTemplate.indexOf(field), 1);
        }
      }
    },
    emitSave(operation, field) {
      /**
       * Emits save event
       * @event save
       * @property {object} save - localFieldTemplate
       */
      this.$emit('save', this.localFieldTemplate, this.saveCallback, operation, field);
    },
    /**
     * Sets the index of the edited field
     * @public
     * @param field
     */
    setEditingIndex(field) {
      this.editingIndex = this.localFieldTemplate.indexOf(field);
    },
    /**
     * Set the local field template
     * @public
     */
    setLocalTemplate() {
      const templateWithUniqueKey = this.fieldTemplate.map(field => ({
        ...field,
        name: field.name ?? this.novaCore.getDefaultCustomFieldName(field)
      }));
      this.localFieldTemplate = this.novaCore.deepClone(templateWithUniqueKey);
    },
    getRequiredValues(customField) {
      const getValue = v => (v ? 'Yes' : 'No');
      const required = {
        Warehouse: getValue(customField.requiredForWarehouse),
        Carriers: getValue(customField.requiredForCarrier)
      };

      return Object.entries(required)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');
    }
  },
  watch: {
    fieldTemplate() {
      this.setLocalTemplate();
    }
  },
  mounted() {
    let _this = this;
    let table = document.querySelector('#custom-fields-table .v-data-table__wrapper tbody');

    this.setLocalTemplate();

    Sortable.create(table, {
      handle: '.handle', // Use handle so user can select text
      animation: 150,
      ghostClass: 'blue-background-class',
      onEnd({ oldIndex, newIndex }) {
        const rowSelected = _this.localFieldTemplate.splice(oldIndex, 1)[0]; // Get the selected row and remove it
        _this.localFieldTemplate.splice(newIndex, 0, rowSelected); // Move it to the new index
        _this.emitSave('update', rowSelected);
      }
    });
  }
};
</script>
