var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", [_vm._v("Appointment updates timeline:")]),
      _c("audit-log-appointment", {
        attrs: {
          "object-id": _vm.appointment.id,
          org: _vm.$org,
          "entity-obj": _vm.appointment,
          entity: "appointment",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }