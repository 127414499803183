var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("form-base", {
        staticClass: "delete-recurring-series-form",
        attrs: { "hide-required-legend": true },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _c(
                  "v-form",
                  { ref: "form", staticClass: "pa-4 inline-fields" },
                  [
                    _vm.loading
                      ? [
                          _c("v-progress-linear", {
                            staticClass: "mt-6",
                            attrs: {
                              indeterminate: "",
                              loading: _vm.loading,
                              height: "6",
                            },
                          }),
                          _c("h4", { staticClass: "text-center mt-4 mb-12" }, [
                            _vm._v(_vm._s(_vm.loadingMessage) + " ..."),
                          ]),
                        ]
                      : [
                          _c("div", [
                            _vm._v(" This will "),
                            _c("strong", [
                              _vm._v("delete all future appointments"),
                            ]),
                            _vm._v(
                              " in this series starting from this appointment onward: "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "px-3 my-3" },
                            [
                              _c(
                                "v-alert",
                                { attrs: { dense: "", color: "disabled" } },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-3",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-repeat")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-size-x-small recurring-pattern",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.recurringPattern.weekDays
                                                ) +
                                                " @ " +
                                                _vm._s(
                                                  _vm.recurringPattern.endTime
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm._v(" until "),
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.recurringPattern.endDate
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text--darken-3",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-information")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "font-size-x-small" },
                                    [
                                      _vm._v(
                                        " The original appointment will remain on the schedule "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "form-actions",
            fn: function () {
              return [
                !_vm.loading
                  ? _c("action-group", {
                      attrs: {
                        "hide-cancel": "",
                        "hide-confirm": "",
                        "is-spacer-visible": false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "additional-actions",
                            fn: function () {
                              return [
                                _c("v-spacer"),
                                _c(
                                  "outline-button",
                                  {
                                    attrs: {
                                      "before-icon": "close-circle",
                                      "data-testid":
                                        "delete-recurring-appointment-dialog-nevermind-btn",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("close")
                                      },
                                    },
                                  },
                                  [_vm._v(" Nevermind ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "text--color-neutral-0 bg--color-text-error",
                                    attrs: { text: "" },
                                    on: { click: _vm.deleteRecurringSeries },
                                  },
                                  [_vm._v(" Yes, Delete ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        53204213
                      ),
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }