var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-radio-group",
    {
      staticClass: "mb-2",
      class: { required: _vm.field.required },
      attrs: { rules: _vm.validationRules },
      on: {
        change: function ($event) {
          return _vm.$emit("input", _vm.field.value)
        },
      },
      model: {
        value: _vm.field.value,
        callback: function ($$v) {
          _vm.$set(_vm.field, "value", $$v)
        },
        expression: "field.value",
      },
    },
    _vm._l(_vm.field.dropDownValues, function (feature) {
      return _c("div", { key: feature, staticClass: "mb-2" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row" },
          [
            _c("v-radio", {
              staticClass: "mt-0",
              attrs: { value: feature, id: feature },
            }),
            _c("div", { staticClass: "mx-2" }, [
              _c("p", { staticClass: "mb-0" }, [
                _c("label", { attrs: { for: feature } }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.featureData(feature).title)),
                  ]),
                ]),
              ]),
              _c("p", [
                _c("small", [
                  _vm._v(_vm._s(_vm.featureData(feature).description)),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }