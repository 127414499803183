<template>
  <v-card class="card ma-2">
    <v-card-title class="font-weight-light text-uppercase text-caption text-center justify-center">
      Need help with billing? Contact us.
    </v-card-title>
    <v-divider />
    <v-card-text class="fill-height">
      <form-base :hide-required-legend="true" v-if="!submitted">
        <template #form>
          <v-form ref="form">
            <v-label>
              <span class="font-weight-bold">What's the subject?</span>
            </v-label>
            <v-select
              v-model="subject"
              :items="subjects"
              label="Subject"
              :rules="$validator.rules.required('Subject')"></v-select>
            <v-label>
              <span class="font-weight-bold">Your message or request</span>
              <v-textarea
                v-model="message"
                label="Message"
                solo
                :rules="$validator.rules.required('Message')"></v-textarea>
            </v-label>
          </v-form>
        </template>
        <template #form-actions>
          <button-base
            class="mb-12"
            color="secondary"
            block
            @click="onSubmit"
            :loading="submitting">
            SEND
          </button-base>
        </template>
      </form-base>
      <div v-else class="d-flex flex-column justify-center align-center fill-height">
        <v-icon color="secondary" size="60" class="mb-4">mdi-check-circle-outline</v-icon>
        <p class="text-center text-subtitle-1">
          Thank you for your message. Our Finance team will contact you via email.
        </p>
        <button-base text width="100%" @click="onBack">BACK</button-base>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
/**
 * @displayName Billing Contact Form
 */
export default {
  name: 'BillingContactForm',
  data() {
    return {
      subject: '',
      message: '',
      subjects: [
        'Invoices',
        'Payment Status',
        'Changing Billing Contact/Information',
        'Subscription Update',
        'Subscription Inquiry'
      ],
      submitting: false,
      submitted: false
    };
  },
  methods: {
    onBack() {
      this.submitted = false;
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.submitting = true;
        try {
          await this.$store.dispatch('Orgs/sendBillingInquiry', {
            subject: this.subject,
            message: this.message
          });
        } finally {
          this.mixpanel.track(this.mixpanel.events.BILLING_INFO.REQUESTED, {
            'Org Name': this.$org.name,
            'Org ID': this.$org.id,
            'Billing Request Subject': this.subject,
            'Billing Request Message': this.message
          });

          this.submitted = true;
          this.submitting = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.card {
  width: 400px;
}
</style>
