var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.spotAreas.length
        ? _c("yard-view-no-areas")
        : _c(
            "v-expansion-panels",
            {
              staticClass: "d-flex flex-column gap-4",
              attrs: { accordion: "", multiple: "", flat: "" },
            },
            _vm._l(_vm.spotAreas, function (spotArea) {
              return _c(
                "v-expansion-panel",
                {
                  key: spotArea.id,
                  staticClass: "yard-spot-area-container",
                  attrs: { "data-testid": `yard-main-area-${spotArea.id}` },
                },
                [
                  _c(
                    "v-expansion-panel-header",
                    [
                      _c("yard-view-spot-area-header", {
                        attrs: {
                          "spot-area": spotArea,
                          "data-testid": `yard-main-area-header-${spotArea.id}`,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel-content",
                    { staticClass: "yard-spot-area-content" },
                    [
                      _c("yard-view-spot-area", {
                        staticClass: "mt-6",
                        attrs: {
                          editable: false,
                          "spot-area": spotArea,
                          "data-testid": `yard-main-area-spots-${spotArea.id}`,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
      _vm.spotAreas.length > 0 ? _c("yard-view-legend") : _vm._e(),
      _vm.spotAreas.length > 0
        ? _c("yard-view-message-driver-dialog", {
            attrs: { "spot-assignment": _vm.messageDriverSpotAssignment },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }