<template>
  <div>
    <div
      id="appointment-results-list-header-mobile"
      class="d-flex justify-space-between px-2 py-4 align-center flex-wrap">
      <v-btn
        icon
        class="nav-arrow-btn mr-4 mobile-icon-btn"
        @click="$emit('close')"
        data-testid="back-button-search">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="flex-grow-1 is-relative">
        <v-text-field
          autofocus
          v-on:keyup.native.enter="search"
          label="Search Appointments"
          class="appended-inner-icon"
          hide-details="auto"
          v-model="searchStr"
          outlined
          dense></v-text-field>
        <v-btn
          icon
          class="appended-inner-icon mobile-icon-btn"
          :ripple="false"
          @click="search"
          data-testid="search-button-mobile">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
      <span class="flex-basis-100 px-2 pt-2" v-if="!hasSearched">
        Search by Carrier, Dock, Tags, Confirmation #, or Reference #
      </span>
      <div v-else class="d-flex align-center justify-space-between flex-basis-100 px-2 pt-2">
        <span>{{ totalResults }} results</span>
        <date-picker
          :no-title="false"
          date-picker-wrapper-class="centered"
          color="#015A86"
          external-trigger
          v-model="dateRangeInput"
          format="MMM D, YYYY"
          height="35"
          no-input-mode
          is-range
          single-line
          hide-details>
          <template v-slot:external-trigger="{ openDatePicker }">
            <v-btn elevation="0" @click="openDatePicker">
              {{ dateRangeLabel }}
              <v-icon class="ml-2">mdi-menu-down</v-icon>
            </v-btn>
          </template>
        </date-picker>
      </div>
    </div>

    <v-container class="pa-4">
      <template v-if="isLoading && isFirstPage">
        <div class="d-flex flex-column align-center justify-center pt-8">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <span>Searching Appointments...</span>
        </div>
      </template>
      <template v-else>
        <template v-if="searchStr">
          <template v-if="hasSearched && events.length === 0">
            <v-row
              no-gutters
              class="mt-8 flex-column align-center"
              data-testid="no-results-message-container">
              <v-icon class="mr-2 primary--text mb-3" x-large>mdi-arrow-up</v-icon>
              <strong class="no-results-message">We couldn't find results for your search</strong>
              <span class="px-4 text-center pt-3">
                You can try choosing another date range at the top toolbar
              </span>
            </v-row>
          </template>
          <template v-else>
            <template v-for="(events, date, index) in eventsByDate">
              <div :key="`event-list-${novaCore.toKebabCase(date)}-${index}`">
                <h3 class="pt-4 mb-3">{{ date }}</h3>
                <v-row no-gutters v-for="event in events" :key="`event-${event.id}`" class="mb-4">
                  <v-col>
                    <article
                      class="mobile-event-tile"
                      :class="[`is-${event.status.toLowerCase()}`]"
                      @click="openDetailsDialog(event.id)">
                      <div class="d-flex align-center justify-space-between">
                        <div class="tile-header">
                          <strong class="mr-4" :data-timestamp="event.start">
                            {{ getEventStart(event) }}
                          </strong>
                          <span class="company-name">{{ event.companyName }}</span>
                        </div>
                        <span
                          :class="[event.status.toLowerCase()]"
                          class="font-size-x-small rounded pa-1 white--text font-weight-bold current-status">
                          {{ event.status }}
                        </span>
                      </div>
                      <div class="d-flex align-center mt-2 font-size-x-small">
                        <div class="third-width">
                          <span class="d-block">Confirmation</span>
                          <span class="d-block appointment-confirmation-number">
                            {{ event.confirmationNumber }}
                          </span>
                        </div>
                        <div class="third-width">
                          <span class="d-block">Reference</span>
                          <span class="d-block" v-html="getRowVal(event.refNumber)"></span>
                        </div>
                        <div class="third-width">
                          <span class="d-block">Dock</span>
                          <span class="d-block">{{ event.dock.name }}</span>
                        </div>
                      </div>
                    </article>
                  </v-col>
                </v-row>
              </div>
            </template>

            <outline-button
              v-if="shouldShowLoadMoreButton"
              data-testid="load-more-button"
              block
              :loading="isLoading"
              @click="loadMoreAppointments">
              Load More Results
            </outline-button>
            <template v-else>
              <div v-if="events.length > 0" class="d-flex align-start">
                <v-icon class="mr-2">mdi-list-status</v-icon>
                <div class="d-flex flex-column">
                  <span>These are all results for your search.</span>
                  <span>You can try choosing another date range at the top toolbar.</span>
                </div>
              </div>
            </template>
          </template>
        </template>
      </template>

      <appointment-details-dialog-mobile
        content-class="mobile-dialog details-dialog-mobile"
        fullscreen
        transition="dialog-bottom-transition"
        external-activator
        @close="showDetailsDialog = false"
        :show-dialog="showDetailsDialog"
        :appointment="$selectedEvent"></appointment-details-dialog-mobile>
    </v-container>
  </div>
</template>

<script>
import { formatDateTimeWithMilitarySupport, LuxonDateTimeFormats } from '@satellite/../nova/core';
import appointmentMixin from '@/components/mixins/appointmentMixin';

const RESULT_LIMIT = 10;
export default {
  mixins: [appointmentMixin],
  data() {
    return {
      showDetailsDialog: false,
      initialPagination: {
        page: 1,
        limit: RESULT_LIMIT
      },
      isLoading: false,
      events: [],
      searchStr: '',
      dateRange: null,
      sort: {
        sortBy: 'appointment.start',
        sortDesc: false
      },
      pagination: {
        size: RESULT_LIMIT,
        from: 0
      },
      totalResults: 0,
      hasSearched: false,
      datePickerVal: [],
      mounted: false
    };
  },
  computed: {
    eventsByDate() {
      const eventsByDate = {};
      if (this.events.length > 0) {
        this.events.forEach(event => {
          const apptStartDate = formatDateTimeWithMilitarySupport(
            event.start,
            this.$selectedWarehouse?.timezone,
            LuxonDateTimeFormats.LongDateShortMonth,
            this.$isMilitaryTimeEnabled(this.$selectedWarehouse),
            LuxonDateTimeFormats.LongDateShortMonth
          );
          if (!this.novaCore.objPropExists(eventsByDate, apptStartDate)) {
            eventsByDate[apptStartDate] = [];
          }
          eventsByDate[apptStartDate].push(event);
        });
      }
      return eventsByDate;
    },
    shouldShowLoadMoreButton() {
      return !this.isLastPage && this.hasSearched;
    },
    isFirstPage() {
      return this.pagination.from === 0;
    },
    isLastPage() {
      return this.events.length === this.totalResults;
    },
    searchOptions() {
      return {
        searchStr: this.searchStr,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate
      };
    },
    dateRangeInput: {
      get: function () {
        return this.dateRange ? [this.dateRange?.startDate, this.dateRange?.endDate] : [];
      },
      set: function (newVal) {
        const newDateRange = { startDate: newVal[0], endDate: newVal[1] };
        // Explicit way of checking that both start and end dates in array are defined
        if (typeof newVal[0] === 'undefined' || typeof newVal[1] === 'undefined') {
          return;
        }
        if (newVal && JSON.stringify(newDateRange) !== JSON.stringify(this.dateRange)) {
          this.dateRange = { startDate: newVal[0], endDate: newVal[1] };
        }
      }
    },
    dateRangeLabel() {
      return this.dateRange
        ? `${formatDateTimeWithMilitarySupport(
            this.dateRange.startDate,
            this.$selectedWarehouse?.timezone,
            LuxonDateTimeFormats.MonthDayYearSlashed,
            this.$isMilitaryTimeEnabled(this.$selectedWarehouse),
            LuxonDateTimeFormats.MonthDayYearSlashed
          )} to ${formatDateTimeWithMilitarySupport(
            this.dateRange.endDate,
            this.$selectedWarehouse?.timezone,
            LuxonDateTimeFormats.MonthDayYearSlashed,
            this.$isMilitaryTimeEnabled(this.$selectedWarehouse),
            LuxonDateTimeFormats.MonthDayYearSlashed
          )}`
        : '';
    }
  },
  methods: {
    getEventStart(event) {
      return formatDateTimeWithMilitarySupport(
        event.utcStart,
        this.$selectedWarehouse?.timezone,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$selectedWarehouse),
        LuxonDateTimeFormats.Extended24HrTime
      );
    },

    async openDetailsDialog(eventId) {
      this.isLoading = true;
      this.services.appointment
        .getAppointmentById(
          eventId,
          {},
          {
            joins: [
              'dock||name,loadTypeIds',
              'loadType||name',
              'user||firstName,lastName,email,phone',
              'user.company||name,scac'
            ]
          }
        )
        .then(appointment => {
          appointment.dock.warehouse = this.$selectedWarehouse;
          this.$store.commit('Calendar/setSelectedEvent', appointment);
          this.showDetailsDialog = true;
        })
        .catch(() => {
          this.notify('Fail to load the appointment data', 'error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async loadMoreAppointments() {
      this.isLoading = true;
      await this.doSearch();
    },
    async doSearch() {
      const response = await axios
        .post('/search/appointments', {
          sort: this.sort,
          ...this.searchOptions,
          pagination: this.pagination,
          dockIds: this.$selectedWarehouse.docks.map(dock => dock.id)
        })
        .finally(() => {
          this.isLoading = false;
          this.mixpanel.track(this.mixpanel.events.ACTION.SEARCH_APPOINTMENTS, {
            'Org Name': this.$org.name,
            'Org ID': this.$org.id,
            'Search Appointments Input': this.searchOptions.searchStr,
            'Search Appointments Filters': this.searchOptions,
            'Entry Point': 'Mobile Search Page'
          });
        });
      if (response?.data?.data?.items) {
        const formattedEvents = response.data.data.items.map(appointment =>
          this.$store.getters['Calendar/appointmentFormattedAsEvent'](appointment, this.$store)
        );
        this.events = [...this.events, ...formattedEvents];
        this.totalResults = response.data.data.total;
        if (!this.isLastPage) {
          this.pagination.from += RESULT_LIMIT;
        }
      } else {
        this.events = [];
      }
      this.hasSearched = true;
    },
    async search() {
      this.hasSearched = false;
      this.pagination.from = 0;
      this.totalResults = 0;
      this.isLoading = true;
      this.events = [];
      await this.doSearch();
    }
  },
  async mounted() {
    this.dateRange = {
      startDate: momentjs.tz(this.$selectedWarehouse.timezone).format('YYYY-MM-DD'),
      endDate: momentjs.tz(this.$selectedWarehouse.timezone).add(1, 'month').format('YYYY-MM-DD')
    };
    this.$nextTick(() => {
      this.mounted = true;
    });
  },
  watch: {
    dateRange() {
      if (this.dateRange?.startDate && this.dateRange?.endDate && this.mounted) {
        this.search();
      }
    }
  }
};
</script>
