<template>
  <dialog-base
    v-if="trigger?.flow"
    v-bind="[$props, $attrs]"
    :key="renderKey"
    :fullscreen="fullscreen"
    :no-padding="fullscreen"
    @close="close"
    :header="flow.name || 'Flow'">
    <template v-slot:body>
      <div :class="{ 'ma-4': fullscreen }">
        <p class="font-size-medium mb-7">{{ flow.description }}</p>
        <custom-forms-flow-form-old
          class="m-4"
          :trigger="trigger"
          :object-id="objectId"
          :warehouse="warehouse"
          :status-change="statusChange"
          :stack-actions="stackActions"
          @close="close"
          @update="data => $emit('update', data)"
          @create="data => $emit('create', data)"></custom-forms-flow-form-old>
      </div>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  mixins: [dialogMixin],
  props: {
    trigger: {
      type: Object,
      default: null
    },
    objectId: {
      type: String,
      required: true
    },
    warehouse: {
      type: Object,
      required: true,
      default: () => ({})
    },
    statusChange: {
      type: String,
      required: false
    },
    /*
     * Stack actions buttons vertically
     */
    stackActions: {
      type: Boolean,
      required: false,
      default: false
    },
    /*
     * Makes the dialog fullscreen, for mobile experience
     */
    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    flow() {
      return this.trigger?.flow || {};
    }
  }
};
</script>
