var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      nativeOn: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.onConfirm.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "px-0" },
        [
          _c("rich-text-input", {
            attrs: {
              tooltipText:
                "To be displayed in the email notifications and the Carrier View Portal",
              label: "Instructions for Carriers",
              "enabled-modules": {
                bold: false,
                italic: false,
                underline: false,
                link: false,
              },
            },
            nativeOn: {
              keyup: function ($event) {
                $event.stopPropagation()
              },
            },
            model: {
              value: _vm.fields.instructions,
              callback: function ($$v) {
                _vm.$set(_vm.fields, "instructions", $$v)
              },
              expression: "fields.instructions",
            },
          }),
          _c("warehouse-amenities-ppe-requirements", {
            attrs: { fields: _vm.fields },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }