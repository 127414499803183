<template>
  <div
    class="py-3"
    :class="{
      disabled: !$rolePermissions.canCreateLoadType,
      'px-5': !$rolePermissions.canCreateLoadType
    }">
    <div class="mb-6" v-if="!$rolePermissions.canCreateLoadType">
      <caution-message>
        These Load Types are <strong>shared</strong> across all <strong>Warehouses</strong>, proceed
        carefully.
      </caution-message>
    </div>
    <load-type-list
      :read-only="novaCore.isUserWarehouseRestricted($me)"
      :should-display-warehouses="false"></load-type-list>
  </div>
</template>

<script>
export default {
  name: 'LoadTypesPage'
};
</script>
