<template>
  <v-autocomplete
    :required="required"
    :prepend-icon="!hideIcon ? 'mdi-domain' : ''"
    dense
    height="20px"
    @input="updateValue"
    @keydown.enter.prevent="() => {}"
    :search-input.sync="filters.searchStr"
    v-bind="[$props, $attrs]"
    :value="value"
    :items="companies"
    test-id="select-company-search-field"
    ref="companySelect"
    item-value="id"
    no-filter
    :hide-no-data="!filters.searchStr"
    persistent-hint
    item-text="name"
    :label="placeholder"
    :loading="isLoading"
    :disabled="disabled"
    @change="emitCompanyChanged">
    <template v-slot:selection="{ item }">[{{ item.scac }}] {{ item.name }}</template>

    <template v-slot:item="{ item }">
      <template>
        <div class="full-width">
          <v-list-item>
            <v-list-item-content class="d-block">
              <v-list-item-title v-html="`${item.name}`"></v-list-item-title>
              <v-list-item-subtitle>SCAC: {{ item.scac }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider
            v-if="companies.indexOf(item) < companies.length - 1"
            :key="companies.indexOf(item)"></v-divider>
        </div>
      </template>
    </template>

    <template v-slot:append-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-title>
          <create-carrier-company-dialog @save="handleNewCompany" :entry-point="entryPoint">
            <template v-slot:activator="slotProps">
              <v-btn color="primary" block v-bind="slotProps.attrs" v-on="slotProps.on">
                Create a new Carrier Company
              </v-btn>
            </template>
          </create-carrier-company-dialog>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template #label v-if="required">
      {{ placeholder }}
      <span class="red--text"><strong>*</strong></span>
    </template>
  </v-autocomplete>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import dataListMixin from '@satellite/components/mixins/dataListMixin';

/**
 * Company Select
 * @displayName Company Select
 */
export default {
  mixins: [selectMixin, dataListMixin],
  props: {
    /**
     * @model
     */
    value: {
      type: String,
      required: false
    },
    /**
     * Is input disabled
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Input placeholder
     */
    placeholder: {
      type: String,
      required: false,
      default: 'Start Typing a company name, scac, usdot, or mc'
    },
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      companies: [],
      filters: {
        searchStr: null
      },
      searchFields: ['name', 'scac', 'mc', 'usdot'],
      isLoading: false
    };
  },
  methods: {
    /**
     * V-model
     * @public
     * @param companyId
     */
    updateValue(companyId) {
      /**
       * Emits input with company Id
       * @event input
       * @property {string} companyId
       */
      this.$emit('input', companyId);
    },
    /**
     * Retrieves data when filters are updated
     * @public
     */
    async getData() {
      this.isLoading = true;

      if (!this.filters.searchStr) {
        return (this.companies = []);
      }

      await axios
        .get('/company', {
          params: this.getQuery()
        })
        .then(r => {
          this.companies = r.data.data;
        });

      this.isLoading = false;
    },
    /**
     * Handles new company creation
     * @public
     * @param {Company} company
     */
    handleNewCompany(company) {
      this.$emit('companyChanged', company);
      this.companies.push(company);
      this.updateValue(company.id);
      this.$refs.companySelect.isMenuActive = false;
    },
    getSelectedItem() {
      return this.$refs.companySelect.selectedItem;
    },
    emitCompanyChanged(value) {
      const newCompany = this.companies.find(company => company.id === value);
      this.$emit('companyChanged', newCompany);
    }
  },
  async mounted() {
    // If there is already a value set we need to fetch the company record and add it to our companies list
    // By default this component does not have any companies until they are searched for
    if (this.value && !this.companies.length) {
      await axios.get(`/company/${this.value}`).then(response => {
        this.companies.push(response.data.data);
      });
    }
  }
};
</script>
