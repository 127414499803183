var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-height" },
    [
      _c(
        "v-tabs",
        {
          staticClass: "mobile-appointment-tabs non-active-tab-underline",
          attrs: {
            "background-color": "var(--v-mobileblue-base)",
            dark: "",
            "slider-color": "#ffe67f",
            grow: "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab", { key: "status" }, [_vm._v("Status & Details")]),
          _c(
            "v-tab",
            {
              key: "claims",
              attrs: { disabled: _vm.claimSupportTriggers.length === 0 },
            },
            [_vm._v("Claims Support")]
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { key: "status" },
            [
              _c("appointment-details-header-mobile", {
                attrs: { appointment: _vm.appointment },
                on: {
                  close: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
              _c("status-timeline-mobile", {
                attrs: {
                  appointment: _vm.appointment,
                  "warehouse-triggers": _vm.warehouseTriggers,
                },
              }),
              _c("v-divider", { staticClass: "my-4" }),
              _c("appointment-details-mobile", {
                staticClass: "px-6",
                attrs: {
                  appointment: _vm.appointment,
                  "appointment-warehouse": _vm.appointmentWarehouse,
                },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "claims" },
            [
              _c("appointment-details-claims-mobile", {
                attrs: {
                  appointment: _vm.appointment,
                  "warehouse-triggers": _vm.warehouseTriggers,
                },
                on: {
                  close: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }