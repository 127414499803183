<template>
  <div>
    <template v-if="loading">
      <span class="text-caption mb-1 d-block">{{ loadingMessage }}</span>
      <v-progress-linear color="secondary" indeterminate rounded height="8"></v-progress-linear>
    </template>
    <slot v-else></slot>
  </div>
</template>

<script>
/**
 * @displayName HOC Linear Loader
 */
export default {
  props: {
    /**
     * Display loader
     */
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    /**
     * Loading message to display while loading
     */
    loadingMessage: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>
