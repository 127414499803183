<template>
  <div class="appointment-sections">
    <header class="d-flex justify-space-between align-center mb-6">
      <h3>Appointment Details</h3>
      <v-btn
        icon
        @click="showEditDialog = true"
        data-testid="edit-appointment-button"
        class="mobile-icon-btn">
        <v-icon tile>mdi-pencil</v-icon>
      </v-btn>
    </header>
    <div class="appointment-details dense" v-if="!loading">
      <template v-for="(row, index) in appointmentTableRows">
        <template v-if="!row.isHidden">
          <div :key="index + '-detail'">
            <div class="font-weight-bold">
              {{ row.label }}
            </div>
            <div :class="row.tdClass">
              <div v-html="getRowVal(row.value)"></div>
            </div>
          </div>
        </template>
      </template>

      <template v-if="customFieldsToShow.length > 0">
        <div
          v-for="(customField, index) in Object.values(customFieldsToShow)"
          :key="`custom-field-${index}`">
          <div class="font-weight-bold">
            {{ customField.label }}
          </div>
          <div :class="customField.tdClass">
            <div v-html="getRowVal(customField.value)" class="text-pre-wrap"></div>
          </div>
        </div>
      </template>

      <template v-if="areDetailsExpanded">
        <div class="font-weight-bold">Appointment Notes</div>
        <div class="appointment-notes" v-html="getRowVal(appointment.notes)"></div>

        <div class="font-weight-bold pt-4">Confirmation #</div>
        <div>
          <copy-content
            should-disable-tooltip
            :content="appointment.confirmationNumber"
            class="mr-2">
            {{ appointment.confirmationNumber }}
          </copy-content>
        </div>

        <div class="font-weight-bold pt-4">Appointment ID</div>

        <div>
          <copy-content should-disable-tooltip :content="appointment.id" class="mr-2">
            {{ appointment.id }}
          </copy-content>
        </div>
      </template>

      <outline-button
        small
        block
        v-if="!areDetailsExpanded"
        :class="{ '': customFields.length === 0, 'mb-4': customFields.length > 0 }"
        @click="areDetailsExpanded = !areDetailsExpanded">
        Show All
      </outline-button>

      <outline-button
        small
        block
        v-if="areDetailsExpanded"
        class="my-4"
        @click="areDetailsExpanded = !areDetailsExpanded">
        Collapse
      </outline-button>

      <div class="divider mt-6"></div>

      <v-expansion-panels accordion class="details-expansion-panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3 class="mr-1">Asset Details</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span v-if="!appointment.assetVisit?.id">No asset linked</span>
            <template v-else>
              <div class="font-weight-bold">
                {{ lastEventType ?? 'Not Arrived' }}
              </div>
              <div>Form data will go here!</div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="divider"></div>

      <v-expansion-panels accordion class="details-expansion-panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3 class="mr-1">Appointment Tags</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <tag-manager
              hide-instructions
              outlined
              rerender-input-on-select
              value=""
              placeholder="Add a Tag..."
              :outlined-chips="true"
              :should-enable-custom-tags="true"
              :multiple="false"
              small-chips
              small
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
                maxHeight: 150
              }"
              :read-only="!$rolePermissions.canUpdateAppointmentTags || readOnly"
              :allowTagDelete="!lockTagEditing"
              hide-selected-tags
              @input="handleTagInput" />

            <tag-chips-list v-model="tags" @remove-tag="removeTag"></tag-chips-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="divider"></div>

      <v-expansion-panels accordion class="details-expansion-panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3 class="mr-1">Carrier</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="font-weight-bold pb-2">{{ companyName }}</div>
            <div class="d-flex align-center pb-1">
              <v-icon class="mr-2">mdi-account</v-icon>
              <div class="d-block">{{ getUserName(appointment.user) }}</div>
            </div>
            <div class="d-flex align-center pb-1">
              <v-icon class="mr-2">mdi-email</v-icon>
              <div class="font-weight-light">{{ appointment.user.email }}</div>
            </div>
            <div class="d-flex align-center pb-1">
              <v-icon class="mr-2">mdi-phone</v-icon>
              <div class="font-weight-light">{{ appointment.user.phone || '----' }}</div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="divider"></div>

      <v-expansion-panels accordion class="details-expansion-panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3 class="mr-1">Warehouse</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="font-weight-bold pb-2">{{ appointment.dock.warehouse.name }}</div>
            <div class="d-flex align-center pb-1">
              <v-icon class="mr-2">mdi-account</v-icon>
              <div class="d-block">{{ getWarehouseAddress(appointment.dock.warehouse) }}</div>
            </div>
            <div class="d-flex align-center pb-1">
              <v-icon class="mr-2">mdi-email</v-icon>
              <div class="font-weight-light">{{ appointment.dock.warehouse.email }}</div>
            </div>
            <div class="d-flex align-center pb-1">
              <v-icon class="mr-2">mdi-phone</v-icon>
              <div
                class="font-weight-light"
                v-html="getRowVal(appointment.dock.warehouse.phone)"></div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="divider"></div>

      <v-expansion-panels accordion class="details-expansion-panel">
        <v-expansion-panel v-for="trigger of gateManagementTriggers" :key="trigger.id">
          <v-expansion-panel-header>
            <h3 class="mr-1">
              {{ trigger.flow?.name || novaCore.CustomFormsFeaturesData[trigger.feature].title }}
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <custom-forms-data
              :key="trigger.id"
              :trigger="trigger"
              flat
              :object-id="appointment.id"
              :timezone="warehouse.timezone"
              :military-time-enabled="$isMilitaryTimeEnabled(warehouse)"></custom-forms-data>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-alert color="error" class="white--text" v-if="!appointment && !loading">
      Could not load appointment data
    </v-alert>
    <template v-if="loading">
      <v-card-text>
        Loading Appointment Data...
        <v-progress-linear color="secondary" indeterminate rounded height="8"></v-progress-linear>
      </v-card-text>
    </template>

    <edit-appointment-dialog-mobile
      content-class="mobile-dialog"
      fullscreen
      transition="dialog-bottom-transition"
      :external-activator="true"
      :show-dialog="showEditDialog"
      :appointment="appointment"
      @appointment-updated="$emit('appointment-updated')"
      @close="showEditDialog = false" />
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';
import appointmentMixin from '@/components/mixins/appointmentMixin';

/**
 * @displayName Appointment Details
 */
export default {
  extends: AppointmentDetailsBase,
  mixins: [appointmentMixin],
  data() {
    return {
      areDetailsExpanded: false,
      showEditDialog: false
    };
  },
  computed: {
    customFieldsToShow() {
      const customFields = Object.values(this.customFields);
      if (!this.areDetailsExpanded) {
        return customFields?.[0] ? [customFields[0]] : [];
      }
      return customFields;
    }
  },
  methods: {
    handleTagInput(val) {
      const tags = this.tags ? this.novaCore.deepClone(this.tags) : [];
      this.tags = Array.from(new Set([...tags, val]));
    },
    removeTag(tag) {
      this.tags = this.novaCore.deepClone(this.tags).filter(item => item !== tag);
    }
  }
};
</script>

<style lang="scss" scoped>
.divider {
  background-color: lightGrey;
  height: 1px;
  width: calc(100% + 48px);
  margin: 0 -24px;
}
.is-mobile {
  .details-expansion-panel {
    min-width: calc(100% + 48px);
    margin: 0 -24px;
    width: calc(100% + 48px) !important;

    .v-expansion-panel {
      &:before {
        display: none !important;
      }
    }

    .v-expansion-panel-header {
      min-height: 65px;
    }
  }
}
</style>
