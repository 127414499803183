<template>
  <div class="d-flex flex-row align-center mb-4 full-width">
    <v-select
      @input="updateValue"
      :value="value"
      :items="exportableFields"
      :rules="minimumSelections ? $validator.rules.selectAtLeast(minimumSelections, 'Field') : []"
      clearable
      class="pt-0 mt-0"
      v-bind="[$props, $attrs]"
      :ref="selectRefKey"
      item-text="label"
      item-value="key"
      :return-object="false"
      multiple
      @click:clear="updateValue({})">
      <template #label v-if="required">
        {{ placeholder }}
        <span class="red--text"><strong>*</strong></span>
      </template>

      <template v-slot:prepend-item>
        <v-list-item ripple @click="toggleSelectAllItems">
          <v-list-item-action>
            <v-icon :color="value.length > 0 ? 'error darken-4' : ''">{{ selectAllIcon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider slot="prepend-item" class="mt-2" />
      </template>
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">{{ item.label }}</span>
        <span v-if="index === 1" class="grey--text text-caption ml-2">
          (+{{ value.length - 1 }} others)
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';

export default {
  name: 'exportableFieldsSelect',
  mixins: [selectMixin, selectAllSelectMixin],
  props: {
    /**
     * @model
     */
    value: {
      required: true
    },
    autoSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    minimumSelections: {
      type: Number,
      required: false,
      default: null
    },
    visibleSelectionCount: {
      type: Number,
      required: false,
      default: 3
    }
  },
  data() {
    return {
      selectRefKey: 'exportableFieldSelect',
      exportableFields: []
    };
  },
  computed: {
    selectableItems() {
      return this.novaCore.pluck(this.exportableFields, 'key');
    }
  },
  methods: {
    updateValue(selected) {
      this.$emit('input', selected);
    },
    setSelected() {
      if (this.autoSelect || this.value.length === 0) {
        this.updateValue(this.selectableItems);
      }
    }
  },
  mounted() {
    Object.keys(this.novaCore.ExportableFields).forEach(efKey => {
      const val =
        efKey === 'REFERENCE_NUMBER'
          ? this.$getSettingValue('referenceNumberDisplayName', this.$org?.settings)
          : this.novaCore.ExportableFields[efKey];
      this.exportableFields.push({
        key: efKey,
        label: this.novaCore.upperFirst(val)
      });
    });

    this.setSelected();
  }
};
</script>
