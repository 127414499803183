var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "min-height": "500px" } },
    [
      _c(
        "v-tabs",
        {
          staticClass: "mb-4 non-active-tab-underline",
          attrs: { grow: "" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab", { key: "status" }, [_vm._v("Status & Details")]),
          _c(
            "v-tab",
            {
              key: "claims_support",
              attrs: { disabled: _vm.claimSupportTriggers.length === 0 },
            },
            [_vm._v(" Claims Support ")]
          ),
          _c("v-tab", { key: "audit_log" }, [_vm._v("Audit Log")]),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { key: "details" },
            [
              _vm.tab === 0
                ? _c(
                    "appointment-details-status-old",
                    _vm._b(
                      {},
                      "appointment-details-status-old",
                      _vm.$props,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "claims_support" },
            [
              _vm.tab === 1
                ? _c(
                    "appointment-details-claims-old",
                    _vm._b(
                      {},
                      "appointment-details-claims-old",
                      _vm.$props,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-tab-item",
            {
              key: "audit_log",
              on: { click: _vm.trackApptDetailsMixpanelEvent },
            },
            [
              _vm.tab === 2
                ? _c(
                    "appointment-details-audit-log-old",
                    _vm._b(
                      {},
                      "appointment-details-audit-log-old",
                      _vm.$props,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("custom-forms-flow-dialog-old", {
        attrs: {
          "external-activator": true,
          warehouse: _vm.warehouse,
          "show-dialog": _vm.hasActiveTrigger,
          "object-id": _vm.appointment.id,
          trigger: _vm.activeTrigger,
        },
        on: {
          close: _vm.cleanUpCustomFormsData,
          update: _vm.updateCustomFormsData,
          create: (value) =>
            _vm.createBulkCustomFormsData(value, _vm.warehouse.id),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }