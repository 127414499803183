var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "margin-x-auto d-inline-block",
      class: { "eta-button-group": _vm.shouldShowEtaValue || _vm.enableEdit },
    },
    [
      _vm.shouldShowEtaValue
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "align-center d-flex font-size-small" },
                [
                  _c(
                    "label",
                    { staticClass: "mr-3" },
                    [
                      _vm.etaTag
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "mr-2",
                              class: _vm.getTagElMeta(_vm.etaTag).classes,
                              attrs: {
                                small: "",
                                color: _vm.getTagElMeta(_vm.etaTag).color,
                              },
                            },
                            [
                              _c("span", { staticClass: "font-size-x-small" }, [
                                _vm._v(_vm._s(_vm.etaTag)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c("strong", [_vm._v("New ETA:")]),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-light d-inline-block ml-2",
                        },
                        [_vm._v(_vm._s(_vm.formattedEta))]
                      ),
                    ],
                    1
                  ),
                  _vm.enableEdit
                    ? _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.editEta } },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil-outline"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("set-eta-dialog", {
                attrs: {
                  "party-to-alert": _vm.partyToAlert,
                  "external-activator": true,
                  etaCondition: _vm.value,
                  "show-dialog": _vm.shouldShowEtaDialog,
                  appointment: _vm.appointment,
                },
                on: {
                  "condition-updated": _vm.updateCondition,
                  close: function ($event) {
                    _vm.shouldShowEtaDialog = false
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.enableEdit
                ? _c(
                    "div",
                    { staticClass: "align-center d-flex" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-3", attrs: { small: "" } },
                        [_vm._v("mdi-alert")]
                      ),
                      _c(
                        "label",
                        { staticClass: "is-uppercase mr-3 font-size-small" },
                        [_vm._v(_vm._s(_vm.label))]
                      ),
                      _c(
                        "text-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            text: "",
                            small: "",
                            "before-icon": "clock-fast",
                            color: "secondary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleEtaButtonClick(
                                _vm.novaCore.EtaCondition.Early
                              )
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "d-block button-label" }, [
                            _vm._v(
                              "Arriving " +
                                _vm._s(_vm.novaCore.EtaCondition.Early)
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "text-button",
                        {
                          attrs: {
                            text: "",
                            small: "",
                            "before-icon": "clock-alert-outline",
                            color: "error",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleEtaButtonClick(
                                _vm.novaCore.EtaCondition.Late
                              )
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "d-block button-label" }, [
                            _vm._v(
                              "Running " +
                                _vm._s(_vm.novaCore.EtaCondition.Late)
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("set-eta-dialog", {
                attrs: {
                  "external-activator": true,
                  etaCondition: _vm.value,
                  "show-dialog": _vm.shouldShowEtaDialog,
                  "party-to-alert": _vm.partyToAlert,
                  appointment: _vm.appointment,
                },
                on: {
                  close: function ($event) {
                    _vm.shouldShowEtaDialog = false
                  },
                },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }