var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { right: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-icon",
                _vm._g(
                  {
                    class: `${
                      _vm.websocketHeartbeat.shouldPulse ||
                      _vm.connectionStatus !==
                        _vm.sockets.connectionStatuses.CONNECTED
                        ? "circular-pulse"
                        : ""
                    }`,
                    attrs: { color: _vm.connectionStatusColor },
                    on: {
                      click: function ($event) {
                        _vm.showConnectionStatusDialog = true
                      },
                    },
                  },
                  on
                ),
                [_vm._v(_vm._s(_vm.connectionStatusIcon))]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("span", [
        _vm._v("Server Connection Status - Click for more information"),
      ]),
      _c("connection-status-dialog", {
        attrs: { "show-dialog": _vm.showConnectionStatusDialog },
        on: { close: _vm.closeDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }