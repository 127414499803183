import { render, staticRenderFns } from "./UserList.vue?vue&type=template&id=552d78ea"
import script from "./UserList.vue?vue&type=script&lang=js"
export * from "./UserList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/apps/helios/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('552d78ea')) {
      api.createRecord('552d78ea', component.options)
    } else {
      api.reload('552d78ea', component.options)
    }
    module.hot.accept("./UserList.vue?vue&type=template&id=552d78ea", function () {
      api.rerender('552d78ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/users/components/UserList.vue"
export default component.exports