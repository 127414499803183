var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("form-base", {
        attrs: { header: _vm.header, "hide-required-legend": "" },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _c(
                  "v-form",
                  { ref: "form", class: { disabled: _vm.readOnly } },
                  [
                    _c(
                      "v-container",
                      { staticClass: "px-8 pt-8", attrs: { fluid: "" } },
                      [
                        _c("h2", { staticClass: "mb-8" }, [
                          _vm._v("Address & Details"),
                        ]),
                        _c("h4", { staticClass: "mb-6" }, [
                          _vm._v("Contact Info"),
                        ]),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6", md: "3" } },
                              [
                                _c("text-field", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    required: true,
                                    type: "text",
                                    rules:
                                      _vm.$validator.rules.required(
                                        "Warehouse Name"
                                      ),
                                    tooltipText:
                                      "Name of Warehouse location visible to Warehouse and Carriers",
                                    label: "Warehouse Name",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "editingWarehouse.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6", md: "3" } },
                              [
                                _c("phone-number-field", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    "margin-top": "-7px",
                                    validator: _vm.$validator,
                                    "default-country": _vm.defaultPhoneCountry,
                                    tooltipText:
                                      "Warehouse contact phone number for Carriers",
                                    label: "Phone",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.phone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "phone",
                                        $$v
                                      )
                                    },
                                    expression: "editingWarehouse.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6", md: "4" } },
                              [
                                _c("text-field", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    type: "email",
                                    tooltipText:
                                      "Warehouse contact email for Carriers. It will be used as 'Reply To' address of the appointment emails.",
                                    rules: _vm.$validator.rules.email,
                                    label: "Warehouse Contact Email",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "email",
                                        $$v
                                      )
                                    },
                                    expression: "editingWarehouse.email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6", md: "2" } },
                              [
                                _c("text-field", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    "prepend-icon": "mdi-pound",
                                    tooltipText: "Warehouse store number",
                                    label: "Store Number",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.facilityNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "facilityNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editingWarehouse.facilityNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mb-4" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "align-field",
                                attrs: { cols: "12", sm: "6", md: "4" },
                              },
                              [
                                _c("country-select", {
                                  attrs: {
                                    disabled: _vm.readOnly,
                                    "limit-options": "",
                                    tooltipText:
                                      "Country of the Warehouse location",
                                    readonly:
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                  },
                                  model: {
                                    value: _vm.editingAddress.country,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingAddress,
                                        "country",
                                        $$v
                                      )
                                    },
                                    expression: "editingAddress.country",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                class: { "align-field": _vm.manualAddressMode },
                              },
                              [
                                !_vm.manualAddressMode
                                  ? _c("address-autocomplete-field", {
                                      ref: "searchAddressField",
                                      attrs: {
                                        required: "",
                                        "clear-on-country-change": "",
                                        readonly:
                                          !_vm.$rolePermissions
                                            .canUpdateWarehouse || _vm.readOnly,
                                        disabled:
                                          !_vm.editingAddress.country ||
                                          _vm.readOnly,
                                        country: _vm.editingAddress.country,
                                        tooltipText:
                                          "Address of the Warehouse location visible to carriers",
                                      },
                                      on: {
                                        filled: _vm.fillFullAddress,
                                        "manual-override":
                                          _vm.enterManualAddressMode,
                                      },
                                      model: {
                                        value: _vm.geocodingData,
                                        callback: function ($$v) {
                                          _vm.geocodingData = $$v
                                        },
                                        expression: "geocodingData",
                                      },
                                    })
                                  : _c("street-field", {
                                      ref: "manualStreetField",
                                      attrs: {
                                        required: "",
                                        rules:
                                          _vm.$validator.rules.required(
                                            "Street Address"
                                          ),
                                      },
                                      on: {
                                        search: _vm.enterSearchAddressMode,
                                      },
                                      model: {
                                        value: _vm.editingAddress.street,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingAddress,
                                            "street",
                                            $$v
                                          )
                                        },
                                        expression: "editingAddress.street",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.manualAddressMode && _vm.editingAddress.city
                          ? _c("address-display", {
                              attrs: {
                                zip: _vm.editingAddress.zip,
                                city: _vm.editingAddress.city,
                                state: _vm.editingAddress.state,
                                street: _vm.editingAddress.street,
                                timezone: _vm.editingAddress.timezone,
                              },
                            })
                          : _vm.manualAddressMode
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6", md: "3" } },
                                  [
                                    _c("text-field", {
                                      attrs: {
                                        required: "",
                                        type: "text",
                                        rules:
                                          _vm.$validator.rules.required("City"),
                                        label: "City",
                                      },
                                      model: {
                                        value: _vm.editingAddress.city,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingAddress,
                                            "city",
                                            $$v
                                          )
                                        },
                                        expression: "editingAddress.city",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6", md: "3" } },
                                  [
                                    _vm.editingAddress.country === "USA" ||
                                    _vm.editingAddress.country === "CAN"
                                      ? _c("state-select", {
                                          attrs: {
                                            required: "",
                                            rules:
                                              _vm.$validator.rules.required(
                                                "State"
                                              ),
                                            label: "State",
                                            country: _vm.editingAddress.country,
                                          },
                                          model: {
                                            value: _vm.editingAddress.state,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editingAddress,
                                                "state",
                                                $$v
                                              )
                                            },
                                            expression: "editingAddress.state",
                                          },
                                        })
                                      : _c("text-field", {
                                          attrs: {
                                            required: "",
                                            type: "text",
                                            rules:
                                              _vm.$validator.rules.required(
                                                "State"
                                              ),
                                            label: "State",
                                          },
                                          model: {
                                            value: _vm.editingAddress.state,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editingAddress,
                                                "state",
                                                $$v
                                              )
                                            },
                                            expression: "editingAddress.state",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6", md: "3" } },
                                  [
                                    _c("text-field", {
                                      attrs: {
                                        required: "",
                                        type: "text",
                                        rules:
                                          _vm.$validator.rules.required("Zip"),
                                        placeholder: "85331",
                                        label: "Zip / Postal Code",
                                      },
                                      model: {
                                        value: _vm.editingAddress.zip,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingAddress,
                                            "zip",
                                            $$v
                                          )
                                        },
                                        expression: "editingAddress.zip",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6", md: "3" } },
                                  [
                                    _c("timezone-select", {
                                      attrs: {
                                        rules:
                                          _vm.$validator.rules.required(
                                            "Timezone"
                                          ),
                                        label: "Timezone",
                                        required: "",
                                      },
                                      model: {
                                        value: _vm.editingAddress.timezone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingAddress,
                                            "timezone",
                                            $$v
                                          )
                                        },
                                        expression: "editingAddress.timezone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "h4",
                          { staticClass: "mb-2 pt-6" },
                          [
                            _vm._v(" Warehouse Logo "),
                            _c(
                              "help-icon-tooltip",
                              {
                                staticClass: "z-100",
                                attrs: { "icon-style-class": "grey darken-2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.warehouseLogoSetting.label) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center mt-2" },
                          [
                            _c("document-upload-button", {
                              staticClass: "flex-grow-0",
                              attrs: {
                                "read-only": _vm.readOnly,
                                "crop-shape":
                                  _vm.warehouseLogoSetting.documentOptions
                                    ?.cropShape,
                                aspectRatio:
                                  _vm.warehouseLogoSetting.documentOptions
                                    ?.aspectRatio,
                                "show-crop-result":
                                  _vm.warehouseLogoSetting.documentOptions
                                    ?.showCropResult,
                                "modal-header-text":
                                  _vm.warehouseLogoSetting.documentOptions
                                    ?.modalHeaderText,
                                "allow-warehouseLogoSetting": "",
                                "max-size":
                                  _vm.warehouseLogoSetting.maxFileSize ?? 1024,
                                "conversion-unit":
                                  _vm.warehouseLogoSetting.fileSizeUnit ??
                                  _vm.novaCore.FileUnits.MB,
                                "allowed-mime-types":
                                  _vm.warehouseLogoSetting.allowedMimeTypes,
                              },
                              model: {
                                value: _vm.warehouseSettings.customLogo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.warehouseSettings,
                                    "customLogo",
                                    $$v
                                  )
                                },
                                expression: "warehouseSettings.customLogo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "v-text-field__details" }, [
                          _c(
                            "div",
                            { staticClass: "v-messages theme--light" },
                            [
                              _c(
                                "div",
                                { staticClass: "v-messages__wrapper" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "v-messages__message" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.warehouseLogoSetting.description
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "h4",
                          { staticClass: "mb-2 pt-6" },
                          [
                            _vm._v(" Warehouse Email Subscribers "),
                            _c(
                              "help-icon-tooltip",
                              {
                                staticClass: "z-100",
                                attrs: { "icon-style-class": "grey darken-2" },
                              },
                              [
                                _vm._v(
                                  " Default email subscribers for appointments at this facility. "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("email-list-field", {
                                  staticClass: "full-width mb-4",
                                  attrs: {
                                    outlined: "",
                                    label: "Email Subscribers",
                                    "read-only":
                                      !_vm.$rolePermissions
                                        .canUpdateWarehouse || _vm.readOnly,
                                    disabled: _vm.readOnly,
                                    "small-chips": "",
                                    small: "",
                                  },
                                  model: {
                                    value: _vm.editingWarehouse.ccEmails,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingWarehouse,
                                        "ccEmails",
                                        $$v
                                      )
                                    },
                                    expression: "editingWarehouse.ccEmails",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "h4",
                          { staticClass: "mb-1 pt-8" },
                          [
                            _vm._v(" Warehouse Internal Notes "),
                            _c(
                              "help-icon-tooltip",
                              {
                                staticClass: "z-100",
                                attrs: { "icon-style-class": "grey darken-2" },
                              },
                              [
                                _vm._v(
                                  " Notes that are internal to the Warehouse "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _vm.$rolePermissions.canUpdateWarehouse &&
                                !_vm.readOnly
                                  ? _c("v-textarea", {
                                      attrs: {
                                        label: "Internal Notes",
                                        rows: "2",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.editingWarehouse.notes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingWarehouse,
                                            "notes",
                                            $$v
                                          )
                                        },
                                        expression: "editingWarehouse.notes",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticClass: "v-input v-text-field" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "v-text-field__slot full-width",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "v-label font-size-x-small",
                                              },
                                              [_vm._v("Notes")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text--disabled border-width-1 bordered rounded pa-4 full-width",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.editingWarehouse.notes
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("h2", { staticClass: "mb-2 mt-6" }, [
                          _vm._v("Instructions to Carriers"),
                        ]),
                        _c(
                          "h4",
                          { staticClass: "mb-2 pt-6" },
                          [
                            _vm._v(" Yard Map "),
                            _c(
                              "help-icon-tooltip",
                              {
                                staticClass: "z-100",
                                attrs: { "icon-style-class": "grey darken-2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.warehouseYardMapSetting.label) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center mt-2" },
                          [
                            _c("document-upload-button", {
                              staticClass: "flex-grow-0",
                              attrs: {
                                "read-only": _vm.readOnly,
                                "crop-shape":
                                  _vm.warehouseYardMapSetting.documentOptions
                                    ?.cropShape,
                                aspectRatio:
                                  _vm.warehouseYardMapSetting.documentOptions
                                    ?.aspectRatio,
                                "show-crop-result":
                                  _vm.warehouseYardMapSetting.documentOptions
                                    ?.showCropResult,
                                "modal-header-text":
                                  _vm.warehouseYardMapSetting.documentOptions
                                    ?.modalHeaderText,
                                "allow-warehouseYardMapSetting": "",
                                "max-size":
                                  _vm.warehouseYardMapSetting.maxFileSize ??
                                  1024,
                                "conversion-unit":
                                  _vm.warehouseYardMapSetting.fileSizeUnit ??
                                  _vm.novaCore.FileUnits.MB,
                                "allowed-mime-types":
                                  _vm.warehouseYardMapSetting.allowedMimeTypes,
                              },
                              model: {
                                value: _vm.warehouseSettings.yardMap,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.warehouseSettings,
                                    "yardMap",
                                    $$v
                                  )
                                },
                                expression: "warehouseSettings.yardMap",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "v-text-field__details" }, [
                          _c(
                            "div",
                            { staticClass: "v-messages theme--light" },
                            [
                              _c(
                                "div",
                                { staticClass: "v-messages__wrapper" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "v-messages__message" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.warehouseYardMapSetting
                                            .description
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "h4",
                          { staticClass: "mb-5 pt-8" },
                          [
                            _vm._v(" Warehouse General Instructions "),
                            _c(
                              "help-icon-tooltip",
                              {
                                staticClass: "z-100",
                                attrs: { "icon-style-class": "grey darken-2" },
                              },
                              [
                                _vm._v(
                                  " To be displayed in the email notifications and the Carrier View Portal "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _vm.$rolePermissions.canUpdateWarehouse &&
                                !_vm.readOnly
                                  ? _c("rich-text-input", {
                                      attrs: { label: "Instructions" },
                                      on: { ready: _vm.handleRichTextReady },
                                      model: {
                                        value:
                                          _vm.editingWarehouse.instructions,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingWarehouse,
                                            "instructions",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "editingWarehouse.instructions",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      { staticClass: "v-input v-text-field" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "v-text-field__slot full-width",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "text--disabled border-width-1 bordered rounded pa-4",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.editingWarehouse
                                                    .instructions
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("warehouse-amenities-ppe-requirements", {
                                  attrs: { fields: _vm.editingWarehouse },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("action-group", {
                          staticClass: "pr-0 pt-8",
                          attrs: {
                            "confirm-label": "Save Warehouse Info",
                            "hide-cancel": true,
                            "cancel-color": "error",
                            "confirm-button-tooltip": _vm.readOnly
                              ? _vm.novaCore.getRoleActionError()
                              : "",
                            "disable-confirm":
                              !_vm.$rolePermissions.canUpdateWarehouse ||
                              _vm.readOnly,
                          },
                          on: { confirm: _vm.submit },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }