var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.selectedSpot?.id
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { attrs: { elevation: "0", flat: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex flex-row card-header" },
                [
                  _c(
                    "h3",
                    {
                      staticClass: "text-uppercase",
                      attrs: { "data-testid": "yard-spot-detail-title" },
                    },
                    [_vm._v(" " + _vm._s(_vm.selectedSpot.code) + " ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        "data-testid": "yard-spot-detail-close-button",
                      },
                      on: { click: _vm.closeDetail },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "card-content",
                  attrs: { "data-testid": "yard-spot-detail-content" },
                },
                [
                  !_vm.isSpotAssigned(_vm.selectedSpot)
                    ? _c("h2", { staticClass: "text-capitalize" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getSpotStatus(_vm.selectedSpot)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _vm.isSpotOpen(_vm.selectedSpot)
                        ? _c("yard-view-spot-detail-open")
                        : _vm._e(),
                      _vm.isSpotClosed(_vm.selectedSpot)
                        ? _c("yard-view-spot-detail-closed")
                        : _vm._e(),
                      _vm.isSpotAssigned(_vm.selectedSpot)
                        ? _c("yard-view-spot-detail-assigned", {
                            attrs: { "spot-assignment": _vm.spotAssignment },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-card-actions",
                {
                  staticClass: "card-actions",
                  attrs: { "data-testid": "yard-spot-detail-actions" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column full-width" },
                    [
                      _vm.isSpotClosed(_vm.selectedSpot)
                        ? _c(
                            "v-btn",
                            {
                              attrs: { block: "", color: "primary" },
                              on: { click: _vm.openSpot },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("mdi-check")]
                              ),
                              _vm._v(" Open This Spot "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSpotOpen(_vm.selectedSpot)
                        ? _c(
                            "v-btn",
                            {
                              attrs: { block: "", outlined: "" },
                              on: { click: _vm.closeSpot },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("mdi-close")]
                              ),
                              _vm._v(" Close this Spot "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSpotAssigned(_vm.selectedSpot)
                        ? [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mb-2 primary",
                                attrs: { block: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.updateSpotAssignmentType = "depart"
                                  },
                                },
                              },
                              [_vm._v(" Truck Departed ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { block: "", outlined: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.updateSpotAssignmentType = "unassign"
                                  },
                                },
                              },
                              [_vm._v("Unassign")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mt-4",
                                attrs: { outlined: "", block: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.isAssetVisitChatVisible = true
                                  },
                                },
                              },
                              [_vm._v(" Details & Chat ")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _c("yard-view-spot-status-dialog", {
            attrs: {
              "status-type": _vm.dialogStatusOpen,
              spot: _vm.selectedSpot,
            },
          }),
          _vm.isSpotAssigned(_vm.selectedSpot)
            ? _c("yard-view-spot-assignment-update-dialog", {
                attrs: {
                  "update-type": _vm.updateSpotAssignmentType,
                  "spot-assignment": _vm.spotAssignment,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }