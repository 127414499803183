var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "label",
        {
          staticClass: "label",
          attrs: {
            for: "carrier-search-autocomplete",
            "data-testid": "carrier-search-autocomplete-label",
          },
        },
        [
          _vm._t("label", function () {
            return [
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-plus")]),
              _vm._v(" Add carrier contacts to your Org’s list "),
            ]
          }),
        ],
        2
      ),
      _c("v-autocomplete", {
        staticClass: "autocomplete",
        attrs: {
          flat: "",
          solo: "",
          dense: "",
          "no-filter": "",
          "hide-details": "",
          "return-object": "",
          id: "carrier-search-autocomplete",
          "data-testid": "carrier-search-autocomplete",
          placeholder: "Search by carrier name, email or company",
          "item-value": "id",
          "item-text": "name",
          value: null,
          loading: _vm.carriers.isLoadingRef.value,
          items: _vm.carriers.carriersRef.value,
        },
        on: { "update:search-input": _vm.carriers.updateSearchInput },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item, attrs, on }) {
              return [
                _c(
                  "carrier-contact-search-item",
                  _vm._g(
                    _vm._b(
                      { attrs: { carrier: item } },
                      "carrier-contact-search-item",
                      attrs,
                      false
                    ),
                    on
                  )
                ),
              ]
            },
          },
          {
            key: "append-item",
            fn: function () {
              return [
                _c("create-carrier-dialog", {
                  attrs: { "entry-point": "Carrier Contacts Select List" },
                  on: { save: _vm.handleSave },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-list-item",
                            _vm._g(
                              _vm._b(
                                { staticClass: "list-item clickable" },
                                "v-list-item",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("span", { staticClass: "mr-1" }, [
                                _vm._v("Didn’t find your carrier contact?"),
                              ]),
                              _c("span", { staticClass: "list-item-action" }, [
                                _vm._v("Create new"),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }