<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="1" class="text-md-right">
        <v-icon class="error darken-4 white--text rounded-circle">mdi-currency-usd</v-icon>
      </v-col>
      <v-col cols="11" class="">
        <p class="subtitle-1 pt-0">
          Creating an additional warehouse
          <span class="subtitle-1 error--text text--darken-4 font-weight-bold">
            will result in additional charges
          </span>
          to your account to the extent it exceeds the original number of warehouses purchased in
          your Order Form.
        </p>

        <p class="subtitle-2">
          By completing the process for creating a warehouse, you authorize Loadsmart to bill your
          account as permitted by the terms of your Order Form and the associated Subscription and
          Professional Services Agreement.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="mt-4">
          <v-checkbox :ripple="false" color="primary" v-model="acceptedTerms" hide-details>
            <template v-slot:label>
              <span class="subtitle-1">I have read and accept the conditions</span>
            </template>
          </v-checkbox>
        </p>

        <p class="mt-9 subtitle-2">
          If you need any help, reach out to our support team
          <a
            target="_blank"
            href="https://community.loadsmart.com/hc/en-us/community/topics/14812876253715-OpenDock">
            through this link
          </a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CreateWarehouseTerms',
  data() {
    return {
      acceptedTerms: false
    };
  },
  watch: {
    acceptedTerms() {
      this.$emit('input', this.acceptedTerms);
    }
  },
  methods: {
    onConfirm() {
      if (this.acceptedTerms) {
        this.$emit('confirm');
      } else {
        this.notify('Please read and accept the conditions', 'error');
      }
    }
  }
};
</script>
