<template>
  <div>You will be redirected to the PDF file...</div>
</template>

<script>
export default {
  created() {
    window.location.href = '/documents/Loadsmart_Master_SaaS_&_Services_Agreement.pdf';
  }
};
</script>
