var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              {
                ref: "form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-alert",
                      {
                        attrs: {
                          border: "bottom",
                          "colored-border": "",
                          type: "warning",
                          elevation: "2",
                        },
                      },
                      [
                        _vm._v(
                          " Updating the External Billing ID will affect this customer billing information. Make sure the id corresponds to this customer profile on Midas. If it is blank, this account won't be connected to the billing system. "
                        ),
                        Boolean(_vm.$org.externalBillingId)
                          ? _c("p", { staticClass: "mt-4" }, [
                              _vm._v(
                                " Current Id: " +
                                  _vm._s(_vm.$org.externalBillingId) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c("br"),
                    _c("text-field", {
                      attrs: {
                        type: "text",
                        label: "External Billing ID (UUID)",
                        rules: _vm.$validator.rules.uuid,
                      },
                      model: {
                        value: _vm.localOrgBillingId,
                        callback: function ($$v) {
                          _vm.localOrgBillingId = $$v
                        },
                        expression: "localOrgBillingId",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "confirm-icon": "update",
                "confirm-label": "Update External Billing Id",
              },
              on: { cancel: _vm.cancel, confirm: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }