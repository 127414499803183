<template>
  <div>
    <v-form ref="form">
      <v-row class="mb-4">
        <v-col xl="8" lg="8" md="8">
          <text-field
            :required="true"
            type="text"
            v-model="editingWarehouseGroup.name"
            :rules="$validator.rules.required('Warehouse Group Name')"
            label="Name"></text-field>
        </v-col>
      </v-row>

      <v-row class="mb-4">
        <v-col xl="8" lg="8" md="6">
          <warehouse-list-select
            :disabled="editingWarehouseGroup?.warehouses.length >= maxWarehouses"
            :items="availableWarehouseNames"
            @input="handleWarehouseInput"></warehouse-list-select>
        </v-col>
      </v-row>

      <v-row class="mb-4">
        <v-col xl="8" lg="8" md="8">
          <v-data-table
            class="warehouse-list"
            hide-default-footer
            :headers="rowHeaders"
            :items="editingWarehouseGroup?.warehouses || []"
            :server-items-length="editingWarehouseGroup?.warehouses?.length || 0"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="footerProps"
            :options.sync="options">
            <template v-slot:item.name="{ item }">
              {{ item.name }}
              <copy-content :content="item.name"></copy-content>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="row-actions">
                <icon-tooltip-button
                  @click="removeWarehouseFromGroup(item)"
                  size="large"
                  tooltip="Remove from Warehouse Group"
                  delete></icon-tooltip-button>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row class="mb-4">
        <v-col xl="8" lg="8" md="8">
          <v-label>
            {{ editingWarehouseGroup?.warehouses.length || 0 }} out of {{ maxWarehouses }} maximum
            warehouses selected
          </v-label>
        </v-col>
      </v-row>

      <template v-if="loading">
        <v-progress-linear
          indeterminate
          :loading="loading"
          height="6"
          class="mt-6"></v-progress-linear>
        <h4 class="text-center mt-4 mb-12">Saving Warehouse Group...</h4>
      </template>
    </v-form>
    <v-divider class="my-2"></v-divider>
    <action-group
      @cancel="$emit('close')"
      :confirm-icon="null"
      class="mb-2"
      :cancel-icon="null"
      :disable-confirm="!this.canSubmit"
      confirm-label="Save Warehouse Group"
      @confirm="submit"></action-group>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],
  props: {
    warehouseGroup: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      editingWarehouseGroup: { name: '', warehouses: [] },
      formInitialized: false,
      warehouses: [],
      availableWarehouseNames: [],
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
      ]
    };
  },
  computed: {
    canSubmit() {
      return (
        this.editingWarehouseGroup?.warehouses?.length > 0 &&
        this.editingWarehouseGroup?.name?.length > 0
      );
    },
    maxWarehouses() {
      return this.novaCore.GlobalLimits.MAX_WAREHOUSE_PER_GROUP.value;
    }
  },
  methods: {
    submit() {
      this.loading = true;
      const save = this.warehouseGroup?.id
        ? this.services.warehouseGroup.updateWarehouseGroup
        : this.services.warehouseGroup.createWarehouseGroup;
      try {
        save(this.editingWarehouseGroup);
      } finally {
        this.loading = false;
      }
      this.setDirtyStatus(false);
      this.$emit('close');
    },
    removeWarehouseFromGroup(warehouse) {
      this.editingWarehouseGroup.warehouses = this.editingWarehouseGroup.warehouses.filter(
        wh => wh.id !== warehouse.id
      );
      this.availableWarehouseNames.push(warehouse.name);
    },
    handleWarehouseInput(input) {
      if (
        !input ||
        this.editingWarehouseGroup?.warehouses.length >=
          this.novaCore.GlobalLimits.MAX_WAREHOUSE_PER_GROUP.value
      ) {
        return;
      }

      const newWarehouseSelected = !this.editingWarehouseGroup.warehouses.some(
        wh => wh.name === input
      );
      if (newWarehouseSelected) {
        const newWarehouse = this.warehouses.find(wh => wh.name === input);
        this.editingWarehouseGroup.warehouses.push(newWarehouse);
        this.availableWarehouseNames = this.availableWarehouseNames.filter(name => name !== input);
      }
    },
    groupContainsWarehouse(warehouse) {
      const warehouseIds = this.warehouseGroup?.warehouses.map(wh => wh.id) || [];
      return warehouseIds.includes(warehouse.id);
    }
  },
  watch: {
    editingWarehouseGroup: {
      handler() {
        this.setDirtyStatus(this.formInitialized);
      },
      deep: true
    }
  },
  async mounted() {
    this.editingWarehouseGroup = this.warehouseGroup
      ? { ...this.warehouseGroup }
      : { name: '', warehouses: [] };
    setTimeout(() => {
      this.formInitialized = true;
    }, 1000);
    this.warehouses = await this.services.warehouse.getWarehouses({}, { fields: ['name', 'id'] });
    const availableWarehouses = this.warehouses.filter(wh => !this.groupContainsWarehouse(wh));
    this.availableWarehouseNames = availableWarehouses.map(wh => wh.name);
  }
};
</script>
