var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row align-center mb-4 full-width" },
    [
      _c(
        "v-select",
        _vm._b(
          {
            ref: "customFieldSelect",
            staticClass: "pt-0 mt-0",
            attrs: {
              value: _vm.value,
              items: _vm.customFields,
              rules: _vm.minimumSelections
                ? _vm.$validator.rules.selectAtLeast(
                    _vm.minimumSelections,
                    "Custom Field"
                  )
                : [],
              clearable: "",
            },
            on: {
              input: _vm.updateValue,
              "click:clear": function ($event) {
                return _vm.updateValue({})
              },
            },
            scopedSlots: _vm._u(
              [
                _vm.required
                  ? {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(" " + _vm._s(_vm.placeholder) + " "),
                          _c("span", { staticClass: "red--text" }, [
                            _c("strong", [_vm._v("*")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "prepend-item",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item",
                        {
                          attrs: { ripple: "" },
                          on: { click: _vm.toggleSelectAllItems },
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color:
                                      _vm.value.length > 0
                                        ? "error darken-4"
                                        : "",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.selectAllIcon))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.selectAllLabel)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "mt-2",
                        attrs: { slot: "prepend-item" },
                        slot: "prepend-item",
                      }),
                    ]
                  },
                  proxy: true,
                },
                _vm.multiple
                  ? {
                      key: "selection",
                      fn: function ({ item, index }) {
                        return [
                          index < _vm.visibleSelectionCount
                            ? _c(
                                "v-chip",
                                {
                                  attrs: {
                                    small: "",
                                    color: "primary",
                                    label: "",
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(item.label))])]
                              )
                            : _vm._e(),
                          index === _vm.visibleSelectionCount
                            ? _c(
                                "span",
                                { staticClass: "grey--text text-caption" },
                                [
                                  _vm._v(
                                    " (+" +
                                      _vm._s(
                                        _vm.value.length -
                                          _vm.visibleSelectionCount
                                      ) +
                                      " other" +
                                      _vm._s(
                                        _vm.value.length ===
                                          _vm.visibleSelectionCount + 1
                                          ? ""
                                          : "s"
                                      ) +
                                      ") "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "v-select",
          [_vm.$props, _vm.$attrs],
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }