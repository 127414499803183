import { IsDefined, isDefined, isObject } from 'class-validator';

export default {
  data() {
    return {
      activeTrigger: null,
      customFormsData: [],
      pendingStatusChange: null
    };
  },
  computed: {
    hasActiveTrigger() {
      return isObject(this.activeTrigger);
    },
    hasCustomFormsData() {
      return this.customFormsData?.skip === true || this.customFormsData?.length > 0;
    }
  },
  methods: {
    async createBulkCustomFormsData(value, warehouseId) {
      if (Array.isArray(value)) {
        const firstSubmit = value.every(formData => !formData.id);

        // Create in bulk, to the tag get applied to the appointment
        if (firstSubmit) {
          await axios.post(`custom-forms/form-data/bulk`, { bulk: value, warehouseId });
          this.notify(`Appointment data has been updated`, 'success');
          this.customFormsDataChange();
        } else {
          await this.updateCustomFormsData(value);
        }
      }
    },
    async updateCustomFormsData(value) {
      for (const formData of value) {
        if (formData.id) {
          await axios.patch(`custom-forms/form-data/${formData.id}`, { value: formData.value });
        } else if (formData.value !== null) {
          await axios.post(`custom-forms/form-data`, { ...formData });
        }
      }

      this.notify(`Appointment data has been updated`, 'success');
      this.customFormsDataChange();
    },
    handleActionFieldTriggered(actionName) {
      if (!actionName) {
        return;
      }
      const featureTrigger = this.warehouseTriggers?.find(
        trigger => trigger.feature === actionName
      );

      if (featureTrigger) {
        if (featureTrigger.isActive && isDefined(featureTrigger.flow)) {
          // Set the active trigger after the modal is closed
          // To avoid the odd transition
          setTimeout(() => {
            this.activeTrigger = featureTrigger;
          }, 600);
        } else {
          this.notify('Feature is not enabled', 'warning');
        }
      } else {
        this.notify('Feature not found', 'warning');
      }
    },
    customFormsDataChange() {
      this.$eventHub.$emit('customformdata-updated');
      this.cleanUpCustomFormsData();
    },
    cleanUpCustomFormsData() {
      this.activeTrigger = null;
      this.pendingStatusChange = null;
      this.customFormsData = [];
    },
    checkForTriggerOnStatus(status) {
      let category = null;

      // Do not set the active trigger if we have pending data to submit
      if (this.hasCustomFormsData || isDefined(this.pendingStatusChange)) {
        return;
      }

      const entityName = this.novaCore.CustomFormFlowEntitiesEnum.warehouse;
      const app = this.novaCore.AppsEnum.Warehouse;

      if (status === this.novaCore.AppointmentStatus.Completed) {
        category = this.novaCore.CustomFormFlowCategoryEnum.appointmentStatusCompleted;
      }

      if (status === this.novaCore.AppointmentStatus.Arrived) {
        category = this.novaCore.CustomFormFlowCategoryEnum.appointmentStatusArrived;
      }

      return this.warehouseTriggers?.find(
        trigger =>
          (!app || trigger.app === app) &&
          (!entityName || trigger.entityName === entityName) &&
          trigger.category === category &&
          trigger.isActive &&
          IsDefined(trigger.flow)
      );
    }
  }
};
