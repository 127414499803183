<template>
  <div v-if="selectedAssetVisit?.id">
    <v-card elevation="0">
      <v-card-title class="d-flex flex-row card-header">
        <h4 data-testid="yard-asset-visit-detail-title">Not Assigned</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDetail" data-testid="yard-asset-visit-detail-close-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text data-testid="yard-asset-visit-detail-content" class="card-content">
        <yard-view-asset-visit-detail-data
          @assign="handleAssignSpotSelection"
          :asset-visit="selectedAssetVisit" />
      </v-card-text>
      <v-card-actions class="card-actions" data-testid="yard-asset-visit-detail-actions">
        <div class="d-flex flex-column full-width">
          <v-btn disabled block class="mb-2 primary">Truck Departed</v-btn>
          <v-btn outlined block class="mt-4" @click="isAssetVisitChatVisible = true">
            Details & Chat
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue';
import { useEventHub, useNotify } from '@/composables';

import useYard from '@/modules/yard/composables/useYard';
import YardViewAssetVisitDetailData from '@/modules/yard/components/AssetVisit/Detail/YardViewAssetVisitDetailData.vue';

export default defineComponent({
  name: 'YardViewAssetVisitDetail',
  components: { YardViewAssetVisitDetailData },
  setup() {
    const yardStore = useYard();
    const eventHub = useEventHub();
    const notify = useNotify();

    const closeDetail = () => {
      yardStore.selectedAssetVisitRef.value = null;
    };

    const handleAssignSpotSelection = async spotId => {
      const spot = yardStore.spotsRef.value.find(spot => spot.id === spotId);

      if (spot?.id) {
        if (!yardStore.isSpotOpen(spot)) {
          return notify('The selected spot is not available', 'error');
        }
        return yardStore
          .createSpotAssignment({
            assetVisitId: yardStore.selectedAssetVisitRef.value.id,
            spotId: spot.id
          })
          .then(spotAssignment => {
            eventHub.$emit('spot-assignment-created', spotAssignment);
            yardStore.selectedAssetVisitRef.value = null;
            yardStore.selectedSpotRef.value = spot;
          });
      }
    };

    const appointment = computed(() => {
      return yardStore.selectedAssetVisitRef?.value?.appointment;
    });

    return {
      closeDetail,
      appointment,
      handleAssignSpotSelection,
      isAssetVisitChatVisible: yardStore.isAssetVisitChatVisibleRef,
      selectedWarehouse: yardStore.selectedWarehouseRef,
      selectedAssetVisit: yardStore.selectedAssetVisitRef
    };
  }
});
</script>
