import UsersPage from './UsersPage';

export default [
  {
    path: '/users',
    name: 'users',
    component: UsersPage,
    props: true,
    meta: {
      title: 'Users',
      requiresAuth: true
    }
  }
];
