var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("warehouse-list", {
    attrs: { "should-show-create-dialog": _vm.shouldShowCreateDialog },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }