import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { isUUID } from 'class-validator';
import { useFeatureFlag } from '@loadsmart/vue-feature-flags';

import { useAuth, useStore } from '@/composables';
import {
  SpotType,
  SpotStatus,
  getSpotStatus,
  getWaitingAssetVisits,
  SpotEventTypeEnum,
  canUserUpdateSpotArea,
  formatDateTimeWithMilitarySupport,
  YARD_SPOT_CODE_MAX_LENGTH,
  AppointmentStatus,
  sortSpots,
  LoadTypeDirection
} from '@nova/core';

export default function useYard() {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const storePath = 'state.Yard';
  const spotCardWidth = 60;
  const spotCardHeight = 170;
  const getSpotIcon = type => {
    return type === SpotType.DOCKING ? 'mdi-truck-delivery-outline' : 'mdi-parking';
  };

  const isGateManagementEnabledForSelectedWarehouse = computed(
    () => selectedWarehouse.value?.id && selectedWarehouse.value.geolocation
  );

  const getAssignedSpotIcon = (type, hasAppointment = false, loadtypeDirection = null) => {
    if (type === SpotType.PARKING) {
      return 'mdi-parking';
    }
    if (type === SpotType.DOCKING && hasAppointment && loadtypeDirection) {
      return loadtypeDirection === LoadTypeDirection.Inbound
        ? 'mdi-arrow-up-thick'
        : 'mdi-arrow-down-thick';
    }
  };

  const getLoadTypeDirectionColor = loadTypeDirection => {
    if (loadTypeDirection === LoadTypeDirection.Inbound) {
      return 'text--color-accent-60';
    }
    if (loadTypeDirection === LoadTypeDirection.Outbound) {
      return 'text--color-primary-100';
    }
  };

  const themeColors = Object.freeze({
    YELLOW: 'yellow',
    GREEN: 'green',
    BLUE: 'blue',
    GREY: 'grey',
    RED: 'red'
  });

  const getTruckThemeColor = appointment => {
    if (!appointment?.status) {
      return themeColors.GREY;
    }

    if (appointment.status === AppointmentStatus.InProgress) {
      return themeColors.YELLOW;
    }

    if (appointment.status === AppointmentStatus.Completed) {
      return themeColors.GREEN;
    }

    if (
      appointment.status === AppointmentStatus.Cancelled ||
      appointment.status === AppointmentStatus.NoShow
    ) {
      return themeColors.RED;
    }

    return themeColors.BLUE;
  };

  const isEditableSpotSelected = spot => {
    if (selectedSpots?.value?.length >= 1) {
      return Boolean(selectedSpots.value.find(s => s.id === spot.id)?.id);
    }

    return selectedSpot.value?.id === spot.id;
  };

  const isMilitaryTimeEnabled = computed(() =>
    store.getters['Settings/isMilitaryTimeEnabled']({
      ...selectedWarehouse.value
    })
  );

  const latestClosedEvent = computed(() => {
    const closeEvents = selectedSpot.value?.spotEvents
      ?.filter(event => event.eventType === SpotEventTypeEnum.CLOSE)
      ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return _.last(closeEvents) || {};
  });

  // Read-only refs
  const enableYardView = computed(() => useFeatureFlag('enable-yard-view-helios'));
  const isLoadingSpots = computed(() => _.get(store, `${storePath}.isLoadingSpots`));
  const isLoadingAssetVisits = computed(() => _.get(store, `${storePath}.isLoadingAssetVisits`));
  const assetVisits = computed(() => _.get(store, `${storePath}.assetVisits`));
  const spotAreas = computed(() => _.get(store, `${storePath}.spotAreas`));
  const spots = computed(() => _.get(store, `${storePath}.spots`));
  const waitingAssetVisits = computed(() => getWaitingAssetVisits(assetVisits.value));

  // Read-write refs
  const selectedWarehouse = computed({
    get() {
      return _.get(store, `${storePath}.selectedWarehouse`);
    },
    set(value) {
      store.commit('Yard/setSelectedWarehouse', value || {});

      if (value?.id) {
        // Make sure all related data is fetched
        fetchAssetVisits();
        fetchSpotAreas();
        fetchSpots();

        // Update warehouseId to the url
        router.push({
          params: { warehouseId: value.id }
        });
      } else {
        // Clean up the url
        router.push({
          name: 'yard'
        });
      }
    }
  });
  const selectedAreas = computed({
    get() {
      return _.get(store, `${storePath}.selectedAreas`);
    },
    set(value) {
      store.commit('Yard/setSelectedAreas', value || []);
    }
  });
  const selectedSpot = computed({
    get() {
      return _.get(store, `${storePath}.selectedSpot`);
    },
    set(value) {
      store.commit('Yard/setSelectedSpot', value || {});
      if (value?.id) {
        store.dispatch('Yard/fetchSpot', value?.id);
        selectedSpots.value = [];
      }
    }
  });
  const selectedAssetVisit = computed({
    get() {
      return _.get(store, `${storePath}.selectedAssetVisit`);
    },
    set(value) {
      store.commit('Yard/setSelectedAssetVisit', value || {});
    }
  });
  const isAssetVisitChatVisible = computed({
    get() {
      return _.get(store, `${storePath}.isAssetVisitChatVisible`);
    },
    set(value) {
      store.commit('Yard/setIsAssetVisitChatVisible', value);
    }
  });
  const selectedSpots = computed({
    get() {
      return _.get(store, `${storePath}.selectedSpots`);
    },
    set(value) {
      store.commit('Yard/setSelectedSpots', value || []);
      if (value?.length > 0) {
        selectedSpot.value = {};
      }
    }
  });
  const viewMode = computed({
    get() {
      return _.get(store, `${storePath}.viewMode`);
    },
    set(value) {
      store.dispatch('Yard/setViewMode', { value });
    }
  });

  // Route refs
  const defaultWarehouseId = computed(() =>
    isUUID(route.params?.warehouseId) ? String(route.params.warehouseId) : null
  );
  const defaultSpotAreaId = computed(() =>
    isUUID(route.params?.spotAreaId) ? String(route.params.spotAreaId) : null
  );

  const auth = useAuth();

  const isReadOnly = computed(() => {
    const user = auth.meRef.value;
    return !canUserUpdateSpotArea(user);
  });

  // Actions
  const fetchAssetVisits = () => store.dispatch('Yard/fetchAssetVisits');
  const fetchSpots = () => store.dispatch('Yard/fetchSpots');
  const fetchSpotAreas = () => store.dispatch('Yard/fetchSpotAreas');
  const fetchSpot = id => store.dispatch('Yard/fetchSpot', id);
  const createSpotArea = data => store.dispatch('Yard/createSpotArea', data);
  const updateSpotArea = (id, data) => store.dispatch('Yard/updateSpotArea', { id, data });
  const deleteSpotArea = id => store.dispatch('Yard/deleteSpotArea', id);
  const updateSpot = (id, data) => store.dispatch('Yard/updateSpot', { id, data });
  const createSpot = data => store.dispatch('Yard/createSpot', data);
  const createManySpot = data => store.dispatch('Yard/createManySpot', data);
  const closeSpot = (id, data) => store.dispatch('Yard/closeSpot', { id, data });
  const openSpot = (id, data) => store.dispatch('Yard/openSpot', { id, data });
  const deleteSpot = id => store.dispatch('Yard/deleteSpot', id);
  const deleteManySpot = ids => store.dispatch('Yard/deleteManySpot', ids);
  const createSpotAssignment = data => store.dispatch('Yard/createSpotAssignment', data);
  const deleteSpotAssignment = id => store.dispatch('Yard/deleteSpotAssignment', id);
  const departSpotAssignment = id => store.dispatch('Yard/departSpotAssignment', id);
  const reassignSpotAssignment = (id, data) =>
    store.dispatch('Yard/reassignSpotAssignment', { id, data });
  const messageDriver = (assetVisitId, content) =>
    store.dispatch('Yard/messageDriver', { assetVisitId, content });
  const setIsLoadingSpots = value => store.commit('Yard/setIsLoadingSpots', value);

  // Subspace
  const showUpdateDataFromSubspace = (event, collection) => {
    return (
      collection?.value?.find(item => item.id === event.id) ||
      event.warehouseId === selectedWarehouse.value.id
    );
  };
  const handlerAssetVisitEventSubspaceEvent = event => {
    const existingAssetVisit = assetVisits?.value?.find(av =>
      av.assetVisitEvents?.find(item => item.id === event.assetVisitId)
    );

    // not ideal, but we don't have the warehouseId in the event
    // too expensive to query it from the db
    const newAssetVisit = event.orgId === selectedWarehouse.value.orgId;

    if (
      selectedSpot.value?.id &&
      event.assetVisitId === selectedSpot.value.spotAssignments[0]?.assetVisit?.id
    ) {
      fetchSpot(selectedSpot.value.id);
    }

    if (existingAssetVisit || newAssetVisit) {
      fetchAssetVisits();
      fetchSpots();
    }
  };
  const handleAssetVisitSubspaceEvent = event => {
    if (showUpdateDataFromSubspace(event, assetVisits)) {
      fetchAssetVisits();
    }
  };
  const handleSpotAreaSubspaceEvent = event => {
    if (showUpdateDataFromSubspace(event, spotAreas)) {
      fetchSpotAreas();
    }
  };
  const handleSpotSubspaceEvent = event => {
    if (showUpdateDataFromSubspace(event, spots)) {
      fetchSpots();
    }
  };
  const handleAppointmentSubspaceEvent = event => {
    if (event.id === selectedSpot.value.spotAssignments[0]?.assetVisit?.appointment?.id) {
      fetchSpot(selectedSpot.value.id).then(spot => {
        const spotIndex = spots.value.findIndex(s => s.id === selectedSpot.value.id);
        if (spotIndex !== -1) {
          spots.value[spotIndex] = { ...spot.value };
        }
      });
    }
  };

  const validateSpotCodeInput = value => {
    if (
      value?.length < 1 ||
      value?.length > YARD_SPOT_CODE_MAX_LENGTH ||
      !/^[a-zA-Z0-9]*$/.test(value)
    ) {
      return `Code must be between 1 and ${YARD_SPOT_CODE_MAX_LENGTH} characters and contains only letters and numbers (a-z,  0-9)`;
    }
    return true;
  };

  const validateSpotTypeInput = value => {
    if (!value) {
      return 'Spot type is required';
    }

    if (!Object.values(SpotType).includes(value)) {
      return `Spot type is not valid, valid choices are ${Object.values(SpotType).join(',')}`;
    }
    return true;
  };

  const formatDateTime = (date, amPmFormat, militaryFormat) =>
    formatDateTimeWithMilitarySupport(
      date,
      selectedWarehouse.value?.timezone,
      amPmFormat,
      isMilitaryTimeEnabled.value,
      militaryFormat
    );

  return {
    // Read-Write Refs
    selectedWarehouseRef: selectedWarehouse,
    selectedAreasRef: selectedAreas,
    assetVisitsRef: assetVisits,
    selectedSpotRef: selectedSpot,
    selectedAssetVisitRef: selectedAssetVisit,
    selectedSpotsRef: selectedSpots,
    viewModeRef: viewMode,
    isAssetVisitChatVisibleRef: isAssetVisitChatVisible,
    // Read-Only Refs
    spotAreasRef: spotAreas,
    spotsRef: spots,
    isLoadingSpotsRef: isLoadingSpots,
    isLoadingAssetVisitsRef: isLoadingAssetVisits,
    enableYardViewRef: enableYardView,
    defaultWarehouseIdRef: defaultWarehouseId,
    defaultSpotAreaIdRef: defaultSpotAreaId,
    waitingAssetVisitsRef: waitingAssetVisits,
    // Methods
    fetchSpots,
    fetchSpotAreas,
    fetchAssetVisits,
    createSpotArea,
    updateSpotArea,
    deleteSpotArea,
    handlerAssetVisitEventSubspaceEvent,
    handleAssetVisitSubspaceEvent,
    handleSpotAreaSubspaceEvent,
    handleSpotSubspaceEvent,
    createSpot,
    createManySpot,
    closeSpot,
    openSpot,
    deleteSpot,
    deleteManySpot,
    fetchSpot,
    updateSpot,
    messageDriver,
    createSpotAssignment,
    deleteSpotAssignment,
    departSpotAssignment,
    reassignSpotAssignment,
    validateSpotCodeInput,
    validateSpotTypeInput,
    handleAppointmentSubspaceEvent,
    formatDateTime,
    getTruckThemeColor,
    setIsLoadingSpots,
    isEditableSpotSelected,
    sortSpots,
    isSpotOpen: spot => getSpotStatus(spot) === SpotStatus.OPEN,
    isSpotClosed: spot => getSpotStatus(spot) === SpotStatus.CLOSED,
    isSpotAssigned: spot => getSpotStatus(spot) === SpotStatus.ASSIGNED,
    // Basic Data
    spotCardHeight,
    spotCardWidth,
    spotType: SpotType,
    spotStatus: SpotStatus,
    getAssignedSpotIcon,
    getSpotIcon,
    getLoadTypeDirectionColor,
    isGateManagementEnabledForSelectedWarehouse,
    latestClosedEventRef: latestClosedEvent,
    isReadOnlyRef: isReadOnly,
    getSpotStatus: getSpotStatus,
    isMilitaryTimeEnabled,
    YARD_SPOT_CODE_MAX_LENGTH
  };
}
