<template>
  <warehouse-list :should-show-create-dialog="shouldShowCreateDialog"></warehouse-list>
</template>

<script>
/**
 * Warehouses Page
 * @displayName Warehouses Page
 */
export default {
  props: {
    shouldShowCreateDialog: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
