<template>
  <div id="date-range-select">
    <v-menu
      v-model="dateMenu"
      ref="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      min-width="auto"
      transition="scale-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :dense="dense"
          :height="height"
          :prepend-icon="showIcon ? 'mdi-calendar' : ''"
          :value="dateRangeFormatted"
          :disabled="disabled"
          :title="rangeDaysSelectedText"
          :label="label"
          :outlined="outlined"
          :style="`max-width:${inputMaxWidth || 'none'}`"
          readonly
          @click:clear="setDates([])"
          :error="error"
          hide-details
          :clearable="clearable"
          v-bind="attrs"
          v-on="on">
          <template v-slot:append>
            <slot name="textfield-append"></slot>
          </template>
        </v-text-field>
        <v-btn-toggle
          v-model="currentDefinedRange"
          dense
          color="secondary darken-5"
          v-if="useDefinedRanges"
          class="mt-1 rounded-0 full-width-button-group">
          <v-tooltip bottom v-for="definedRange in definedRanges" :key="definedRange.value">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :value="definedRange.value" v-bind="attrs" v-on="on">
                {{ definedRange.text }}
              </v-btn>
            </template>
            <span>{{ definedRange.description }}</span>
          </v-tooltip>
        </v-btn-toggle>
      </template>
      <v-date-picker v-model="dates" range min="2010-01-01" :max="maxDate">
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="cancel()">Nevermind</v-btn>
        <v-btn text color="primary" @click="saveDates(dates)">OK</v-btn>
        <v-spacer></v-spacer>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    initialRange: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    inputMaxWidth: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: 'Date range'
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: true
    },
    height: {
      type: String,
      required: false,
      default: '20px'
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    initialDefinedRange: {
      type: String,
      required: false,
      default: null
    },
    useDefinedRanges: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    maxDateRangeDays: {
      type: Number,
      required: false
    },
    error: [Boolean, Object]
  },
  data() {
    return {
      dates: [],
      dateMenu: false,
      currentDefinedRange: this.initialDefinedRange,
      definedRanges: [],
      originalRange: []
    };
  },
  computed: {
    dateRangeFormatted() {
      if (this.dates.length === 2 && this.dates.every(d => !!d)) {
        return [
          momentjs(this.dates[0]).format('MM/DD/Y'),
          momentjs(this.dates[1]).format('MM/DD/Y')
        ].join(' to ');
      }
      return '';
    },
    maxDate() {
      let maxDate = null;
      if (this.maxDateRangeDays > 0 && this.dates[0]) {
        maxDate = momentjs(this.dates[0]).add(this.maxDateRangeDays, 'days').format('YYYY-MM-DD');
      }
      return maxDate;
    },
    rangeDaysSelected() {
      if (this.dates?.length) {
        const start = momentjs(this.dates[0]);
        const end = momentjs(this.dates[1] ?? start);

        return end.diff(start, 'days') + 1;
      }

      return 0;
    },
    rangeDaysSelectedText() {
      if (this.rangeDaysSelected > 1) {
        return `${this.rangeDaysSelected} days selected`;
      } else if (this.rangeDaysSelected >= 0) {
        return '1 day selected';
      }

      return null;
    }
  },
  methods: {
    cancel() {
      this.dates = this.originalRange ?? this.initialRange;
      this.saveDates(this.dates);
    },
    saveDates(dates, isDefinedRange = false) {
      dates = dates.sort(); // If the user clicks the end date first, fix it
      dates[1] = dates.length === 2 ? dates[1] : dates[0]; // If only one date selected, make both dates equal
      this.$refs.menu.save(dates);

      if (!isDefinedRange) {
        this.currentDefinedRange = null;
      }

      if (this.useDefinedRanges) {
        this.$emit('input', {
          range: this.dates,
          definedRange: this.currentDefinedRange
        });
      } else {
        this.$emit('input', this.dates);
      }
    },
    setDatesForDefinedRange() {
      this.definedRanges.forEach(range => {
        if (range.value === this.currentDefinedRange) {
          this.dates = [momentjs().startOf(range.startOf), momentjs()].map(d =>
            d.format('YYYY-MM-DD')
          );
          this.saveDates(this.dates, true);
        }
      });
    },
    // Immutable, do not change, other comps rely on this method
    setCurrentDefinedRange(range) {
      this.currentDefinedRange = range;
    },
    // Immutable, do not change, other comps rely on this method
    setDates(dates) {
      this.dates = dates;
      this.saveDates(dates);
    },
    setDefinedRangeOptions() {
      this.definedRanges = Object.keys(this.novaCore.ReportSearchDefinedRanges).map(range => {
        const rangeVal = this.novaCore.ReportSearchDefinedRanges[range];
        const metadata = this.novaCore.definedRangeMetadata(rangeVal);
        return {
          text: range,
          value: rangeVal,
          description: metadata.description,
          startOf: metadata.startOf
        };
      });
    }
  },
  watch: {
    currentDefinedRange() {
      this.setDatesForDefinedRange();
    },
    dateMenu() {
      // This will keep the original range before opening,
      // When cancelling the selection, we roll back to
      // this range
      this.originalRange = [...this.dates];
    }
  },
  mounted() {
    if (this.useDefinedRanges) {
      this.setDefinedRangeOptions();
    }

    if (this.initialRange) {
      this.dates = [...this.initialRange];
    } else if (this.initialDefinedRange) {
      this.setDatesForDefinedRange();
    } else {
      this.dates = [
        momentjs(this.dates[0]).format('YYYY-MM-DD'),
        momentjs(this.dates[1]).format('YYYY-MM-DD')
      ];
    }
  }
};
</script>

<style lang="scss">
#date-range-select {
  input[role='button'] {
    min-width: 13rem;
  }
}
</style>
