<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :external-activator="true"
    header="Connection Status"
    @close="close">
    <template slot="body">
      <strong>Status: </strong>
      <span :class="`${connectionStatusColor}--text`">{{
        novaCore.ucWords($websocketConnectionStatus.state || '')
      }}</span
      ><br />
      <strong>Last Connected At: </strong>
      <span :class="`${connectionStatusColor}--text`">{{
        $websocketConnectionStatus.lastConnected
      }}</span
      ><br />
      <v-divider class="my-2"></v-divider>

      <h3>What are Websockets?</h3>
      <div>
        Websockets are connections between an application website and the application server. They
        facilitate near real-time data transfer, keeping the website's information up-to-date.
      </div>

      <h3 class="pt-3">How does Opendock use Websockets?</h3>
      <div>
        Opendock uses Websockets to keep all of your data up-to-date. When a user in your
        organization creates or updates information in your org it will automatically change for
        you. For instance when a new appointment is created it will immediately appear on your grid.
        This eliminates the need to refresh the webpage in order to have the freshest data.
      </div>

      <h3 class="pb-1 pt-3">Troubleshooting</h3>
      <div>
        <strong>Connection fails intermittently</strong><br />
        <p>
          Sometimes when a new version of Opendock is deployed, Websockets will temporarily
          disconnect. The system will try to re-establish the connection, but in rare instances this
          may result in errors. If a new version of Opendock was deployed you should receive a popup
          that will ask you to refresh the page. The solution in this case is almost always a page
          refresh.
        </p>
        <strong>Connection always fails</strong><br />
        <p>
          Websocket connections are sometimes blocked by default on company networks. Please reach
          out to your network administrator to ensure that connections to
          <strong>{{ config.subspace_url }}</strong> are not blocked. They will need to open both
          ingress and egress (it will be port 443) and open their proxy.
        </p>
      </div>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import connectionStatusMixin from '@/components/mixins/connectionStatusMixin';

export default {
  name: 'connection-status-dialog',
  mixins: [dialogMixin, connectionStatusMixin],
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
