var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        staticClass: "document-type-select",
        attrs: {
          items: _vm.fieldTypeOptions,
          rules: _vm.rules,
          "item-value": "value",
          label: _vm.label,
        },
        on: { input: (value) => _vm.$emit("input", value) },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "v-row",
                  { staticClass: "my-3" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "mr-2", attrs: { cols: "1" } },
                      [_c("v-icon", [_vm._v("mdi-" + _vm._s(item.icon))])],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-row", [
                          _c("span", { staticClass: "font-size-medium" }, [
                            _vm._v(_vm._s(item.text)),
                          ]),
                        ]),
                        _c("v-row", [
                          _vm._v(" " + _vm._s(item.description) + " "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "selection",
            fn: function ({ item }) {
              return [
                _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                  _vm._v("mdi-" + _vm._s(item.icon)),
                ]),
                _vm._v(" " + _vm._s(item.text) + " "),
              ]
            },
          },
        ]),
      },
      "v-autocomplete",
      [_vm.$props, _vm.$attrs],
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }