var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "my-2" },
        [
          _c("v-col", { attrs: { cols: "9" } }, [
            _c("p", { staticClass: "mt-1" }, [
              _vm._v(" Keep records of "),
              _c("b", [_vm._v("damages, security measures")]),
              _vm._v(" and other evidence to support "),
              _c("b", [_vm._v("claim disputes:")]),
            ]),
          ]),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end", attrs: { cols: "3" } },
            [
              _c(
                "v-menu",
                {
                  staticClass: "dropdown",
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { color: "secondary" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(" Update form "),
                              _c(
                                "v-icon",
                                { staticClass: "ml-2", attrs: { small: "" } },
                                [_vm._v("mdi-chevron-down")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.claimSupportTriggers, function (trigger) {
                      return _c(
                        "v-list-item",
                        { key: trigger.feature },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                small: "",
                                disabled: !trigger.isActive,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.activeTrigger = trigger
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("mdi-text-box-edit-outline")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.featureData(trigger.feature).title
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              !_vm.loading
                ? _c("v-simple-table", {
                    staticClass: "appointment-details-table",
                    attrs: { dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c(
                                "tbody",
                                [
                                  _vm._l(
                                    _vm.claimSupportTriggers,
                                    function (trigger) {
                                      return [
                                        _c("tr", { key: trigger.id }, [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "font-weight-bold pt-5",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.featureData(
                                                      trigger.feature
                                                    ).title
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "pt-5" },
                                            [
                                              _c("custom-forms-data", {
                                                key: trigger.id,
                                                attrs: {
                                                  trigger: trigger,
                                                  "object-id":
                                                    _vm.appointment.id,
                                                  timezone:
                                                    _vm.warehouse.timezone,
                                                  "military-time-enabled":
                                                    _vm.$isMilitaryTimeEnabled(
                                                      _vm.warehouse
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c(
                                          "tr",
                                          {
                                            key: `${trigger.id}_divider`,
                                            staticClass: "divider",
                                          },
                                          [
                                            _c("td", {
                                              attrs: { colspan: "2" },
                                            }),
                                          ]
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1269690789
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("custom-forms-flow-dialog-old", {
        attrs: {
          "external-activator": true,
          warehouse: _vm.warehouse,
          "show-dialog": _vm.hasActiveTrigger,
          "object-id": _vm.appointment.id,
          trigger: _vm.activeTrigger,
        },
        on: {
          close: _vm.cleanUpCustomFormsData,
          update: _vm.updateCustomFormsData,
          create: (value) =>
            _vm.createBulkCustomFormsData(value, _vm.warehouse.id),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }