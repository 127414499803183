<template>
  <v-form ref="form" @keyup.native.enter="onConfirm">
    <v-container class="px-0">
      <rich-text-input
        v-model="fields.instructions"
        @keyup.native.stop
        tooltipText="To be displayed in the email notifications and the Carrier View Portal"
        label="Instructions for Carriers"
        :enabled-modules="{
          bold: false,
          italic: false,
          underline: false,
          link: false
        }"></rich-text-input>
      <warehouse-amenities-ppe-requirements :fields="fields" />
    </v-container>
  </v-form>
</template>

<script>
/**
 * Create Warehouse Form
 * @displayName Create Warehouse Form
 */
export default {
  props: {
    confirmLabel: {
      type: String,
      required: false,
      default: 'Create Warehouse'
    },
    confirmIcon: {
      type: String,
      required: false,
      default: 'plus'
    }
  },
  data() {
    return {
      fields: {
        instructions: '',
        amenities: [],
        ppeRequirements: []
      }
    };
  },
  methods: {
    onConfirm() {
      if (this.$refs.form.validate()) {
        this.$emit('confirm', { ...this.fields });
      }
    }
  }
};
</script>
