<template>
  <span data-testid="carrier-email-ccs-column-none" v-if="emails.length < 1" class="no-emails">
    None
  </span>
  <div data-testid="carrier-email-ccs-column-emails" v-else>
    <v-tooltip bottom open-on-click :disabled="emails.length < 2">
      <template #activator="{ on, attrs }">
        <span v-on="on" v-bind="attrs">
          {{ emails[0] }}
          <span v-if="emails.length > 1">(+ {{ emails.length - 1 }} more)</span>
        </span>
      </template>
      <div data-testid="carrier-email-ccs-column-tooltip" v-if="emails.length > 1">
        <p class="mb-1" v-for="email in emails" :key="email">{{ email }}</p>
      </div>
    </v-tooltip>
    <copy-content v-if="emails.length > 0" :content="emails[0]" label="Click to copy carrier email">
      <v-icon x-small class="ml-2" data-testid="carrier-email-ccs-column-copy">
        mdi-content-copy
      </v-icon>
    </copy-content>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

function getEmailCCs(carrier, carrierContact) {
  const carrierCCs = carrier?.orgCarrierSettings?.at(0)?.emailCCs || [];
  const contactCCs = carrierContact?.emailCCs || [];
  return [...new Set(carrierCCs.concat(contactCCs))];
}

export default defineComponent({
  name: 'CarrierEmailCCsColumn',
  props: {
    carrier: { type: Object, required: false },
    carrierContact: { type: Object, required: false }
  },
  setup(props) {
    const emails = computed(() => getEmailCCs(props.carrier, props.carrierContact));
    return { emails };
  }
});
</script>

<style scoped lang="scss">
.no-emails {
  color: $color-text-disabled;
}
</style>
