var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldShowPasswordRestrictions(_vm.password)
    ? _c(
        "div",
        _vm._l(_vm.passwordValidationErrors, function (error) {
          return _c(
            "span",
            {
              key: error.error,
              staticClass: "d-block",
              class: { text__error: !error.passed },
            },
            [_vm._v(" " + _vm._s(error.message) + " ")]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }