import appointmentMixinBase from '@satellite/components/mixins/appointmentMixinBase';
import { AssetVisitEventOrder } from '@satellite/../nova/core';

/**
 * Mixin used for appointments
 * @mixin
 * @displayName Appointment Mixin
 */
export default {
  computed: {
    ...appointmentMixinBase.computed,
    sortedAssetEvents() {
      return _.sortBy(
        this.appointment.assetVisit?.assetVisitEvents?.map(assetEvent => assetEvent.eventType),
        function (eventType) {
          return AssetVisitEventOrder.indexOf(eventType);
        }
      );
    },
    lastEventType() {
      return this.sortedAssetEvents?.at(-1);
    }
  },
  data() {
    return {
      ...appointmentMixinBase.data()
    };
  },
  methods: {
    ...appointmentMixinBase.methods
  }
};
