<template>
  <div class="d-flex flex-column">
    <v-autocomplete
      @change="fetchFullAddress"
      v-bind="[$props, $attrs]"
      :value="value"
      :required="required"
      :readonly="readonly"
      :disabled="disabled"
      :items="items"
      :loading="isLoading"
      :search-input.sync="searchVal"
      color="primary"
      :hide-no-data="!searchVal || searchVal.length < 3"
      hide-selected
      :label="label"
      placeholder="Search your warehouse address..."
      item-text="address"
      return-object
      no-filter>
      <template v-slot:no-data>
        <v-container class="pl-4 pb-0">
          <p>{{ isLoading ? 'Loading suggestions...' : 'No address found...' }}</p>
        </v-container>
      </template>
      <template #label v-if="required">
        <div :class="{ 'with-tooltip': tooltipText }">
          {{ label }}
          <help-icon-tooltip v-if="tooltipText" iconStyleClass="grey darken-2">
            {{ tooltipText }}
          </help-icon-tooltip>
          <span class="red--text ml-2"><strong>*</strong></span>
        </div>
      </template>
    </v-autocomplete>
    <div class="enter-manually-btn d-flex align-end justify-end">
      <a href="#" @click="manualOverride" v-if="!readonly">Enter address manually</a>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Search your warehouse address'
    },
    country: {
      type: String,
      default: 'USA'
    },
    value: {
      type: Object,
      default: null
    },
    clearOnCountryChange: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      items: [],
      isLoading: false,
      searchVal: ''
    };
  },
  methods: {
    async fetchFullAddress(value) {
      if (value?.placeId) {
        this.isLoading = true;
        try {
          const exactAddress = await axios.get(`/geocoding/${value.placeId}`);
          this.$emit('filled', exactAddress?.data);
        } catch (e) {
          if (e.response?.status === 404) {
            this.$emit('input', null); // Clear field
            this.manualOverride();
          } else {
            throw e;
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    async getItems() {
      if (this.searchVal && this.searchVal.length >= 3) {
        this.isLoading = true;
        try {
          const matches = await axios.get(`/geocoding?locationType=address`, {
            params: {
              address: this.searchVal,
              country: this.country
            }
          });

          this.items = matches?.data?.data;
        } finally {
          this.isLoading = false;
        }
      }
    },
    manualOverride(e) {
      e?.preventDefault();
      this.$emit('manual-override');
    }
  },
  watch: {
    searchVal: debounce(function (newVal) {
      this.getItems(newVal);
    }, 200),
    country(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.items = [];
        if (this.clearOnCountryChange) {
          this.$emit('input', null);
        }
      }
    }
  }
};
</script>

<style scoped>
.enter-manually-btn {
  width: 100%;
  z-index: 3;
  margin-top: -16px;
  font-size: 14px;
}
</style>
