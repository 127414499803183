<template>
  <div class="flex-grow-1">
    <template v-if="readOnly">
      <div class="mt-3 font-size-x-small">Email CCs</div>
      <div class="d-flex flex-wrap mt-3">
        <v-chip
          :color="'dark-gray'"
          v-for="(email, index) in value"
          :key="`email-${email}`"
          class="mb-2"
          :class="{ 'mr-2': index + 1 < value.length }">
          <span class="pr-2">{{ email }}</span>
        </v-chip>
      </div>
    </template>

    <v-combobox
      v-else
      multiple
      v-model="emails"
      @input="validate"
      @blur="validate"
      :label="label"
      :delimiters="[',', ' ']"
      v-bind="[$attrs, $props]"
      :search-input.sync="unsavedValue"
      :error-messages="errorMessage"
      placeholder="Email Subscribers"
      :tooltipText="tooltipText"
      :class="{ 'label-with-tooltip': label, 'stack-selections': stacked }"
      hide-details="auto"
      :hide-no-data="hideNoData"
      :prepend-icon="icon"
      return-object
      chips>
      <template v-slot:selection="{ attrs, item, parent }">
        <v-chip v-bind="$attrs" :color="invalidEmails.includes(item) ? 'error' : chipColor" label>
          <v-edit-dialog @open="updateEmailValue = item" @save="handleEmailSave(item)" large>
            <span class="pr-2">{{ item }}</span>
            <template v-slot:input>
              <div class="font-weight-bold mt-4">Update Email</div>
              <v-text-field
                v-model="updateEmailValue"
                :value="item"
                label="Edit"
                single-line
                autofocus></v-text-field>
            </template>
          </v-edit-dialog>
          <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
        </v-chip>
      </template>
      <template v-slot:label>
        <div v-if="label">
          {{ label }}
          <help-icon-tooltip class="z-100" iconStyleClass="grey darken-2">
            {{ tooltipText }}
          </help-icon-tooltip>
        </div>
      </template>
      <template v-slot:no-data>
        <v-list-item class="input-instructions">
          <v-list-item-content>
            <v-list-item-title>
              <span v-html="instructions"></span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
/**
 * @displayName Email List Field
 */
export default {
  props: {
    /**
     * @model
     */
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Field label
     */
    label: {
      type: String,
      required: false
    },
    /**
     * List item color
     */
    chipColor: {
      type: String,
      required: false,
      default: 'secondary'
    },
    /**
     * Allow deletion of individual list items
     */
    allowChipDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * tooltipText
     */
    tooltipText: {
      type: String,
      required: false,
      default: 'List of email addresses that will receive all notifications about the appointment.'
    },
    /**
     * Field instructions
     */
    instructions: {
      type: String,
      required: false,
      default: 'Press <kbd>enter</kbd> or <kbd>tab</kbd> to add an email'
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Stack up items
     */
    stacked: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Icon to prepend
     */
    icon: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      invalidEmails: [],
      search: null,
      updateEmailValue: null,
      emails: [],
      errorMessage: null,
      unsavedValue: null,
      hideNoData: false
    };
  },
  methods: {
    validate() {
      this.invalidEmails = [];

      // Ensure that "unsaved" text is applied when a form is submitted.
      if (
        this.unsavedValue &&
        this.unsavedValue !== '' &&
        !this.emails.includes(this.unsavedValue)
      ) {
        this.emails.push(this.unsavedValue);
      }

      this.emails?.forEach(email => {
        if (!this.isValidEmail(email)) {
          this.invalidEmails.push(email);
        }
      });

      const invalidLen = this.invalidEmails.length;
      this.errorMessage = invalidLen
        ? `There ${invalidLen === 1 ? 'is' : 'are'} ${invalidLen} invalid email${
            invalidLen === 1 ? '' : 's'
          }.`
        : null;

      if (!invalidLen) {
        this.$emit('input', this.emails);
      }

      return !invalidLen;
    },
    // TODO we have created a task to move this to a generic location
    /**
     * Validate email
     * @public
     * @param {string} email
     */
    isValidEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    },
    /**
     * Save email
     * @public
     * @param item
     */
    handleEmailSave(item) {
      this.emails = this.emails?.map(email => (email === item ? this.updateEmailValue : email));
      this.updateEmailValue = null;
      this.validate();
    },
    setEmails() {
      const emailSet = new Set();
      if (this.value?.length > 0) {
        this.value.forEach(email => {
          emailSet.add(email);
        });
      }

      this.emails = [...emailSet];
    }
  },
  mounted() {
    this.setEmails();
  },
  watch: {
    value() {
      this.setEmails();
      this.hideNoData = true;
    }
  }
};
</script>
