<template>
  <v-sheet height="64" class="pa-0 mb-4 calendar-header-wrapper">
    <v-toolbar class="calendar-header" flat>
      <div class="menu-container">
        <v-menu offset-y :internal-activator="true" :close-on-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltipOn }">
                <div v-on="tooltipOn">
                  <v-btn
                    :disabled="listMode"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-3 calendar-gutter-btn"
                    :ripple="false">
                    <v-icon data-testid="interval-select-button-icon">
                      mdi-timeline-clock-outline
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>
                {{
                  listMode
                    ? 'Grid time interval granularity selection disabled in list view'
                    : 'Select grid time interval granularity'
                }}
              </span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              class="account-option"
              v-for="(item, index) in intervalOptions"
              :key="index">
              <button-base small text block class="text-left" @click="setIntervalMinutes(item)">
                <span class="flex-grow-1 justify-start">{{ item }} minutes</span>
              </button-base>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="menu-container secondary-button-group">
        <v-btn-toggle v-model="viewType" mandatory>
          <v-btn
            class="view-type"
            outlined
            color="darken-2"
            v-for="type in availableViewTypes"
            :value="type"
            :key="`calendar-${type}`">
            {{ type }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <calendar-date-select
        :value="gridMode && calendar ? calendar.value : $selectedDate"
        @input="calendarChange"
        :grid-mode="gridMode"
        :view-type="viewType"
        data-testid="grid-calendar-select"
        :week-details="
          gridMode && calendar && viewType === 'WEEK'
            ? {
                start: calendar.getStartOfWeek(calendar.parsedValue),
                end: calendar.getEndOfWeek(calendar.parsedValue)
              }
            : undefined
        "></calendar-date-select>
      <div class="flex-break"></div>

      <div class="menu-container mr-4">
        <v-menu offset-y :internal-activator="true" :close-on-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="darken-2"
              v-bind="attrs"
              v-on="on"
              :ripple="false"
              data-testid="grid-view-mode-menu-activator">
              <v-icon small data-testid="view-mode-activator">{{ getViewModeMeta().icon }}</v-icon>
              <span>
                {{ getViewModeMeta().label }}
                <v-icon small class="ml-2">mdi-chevron-down</v-icon>
              </span>
            </v-btn>
          </template>
          <v-list class="plain-items" data-testid="view-mode-options">
            <v-list-item
              class="account-option is-relative z-100"
              v-for="(item, key) in viewModes"
              :key="key">
              <button-base
                :data-testid="`${item}-view-button`"
                text
                plain
                :ripple="false"
                class="d-flex align-center justify-center"
                @click="mode = item">
                <v-icon small>{{ getViewModeMeta(item).icon }}</v-icon>
                <span class="flex-grow-1 justify-start font-size-x-small">
                  {{ getViewModeMeta(item).label }}
                </span>
              </button-base>
            </v-list-item>
            <v-list-item class="account-option is-relative z-100">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <button-base
                    data-testid="tv-mode-button"
                    text
                    plain
                    :ripple="false"
                    class="d-flex align-center justify-center"
                    @click="viewTvMode">
                    <v-icon small>mdi-television-play</v-icon>
                    <span class="flex-grow-1 justify-start font-size-x-small">Open TV Mode</span>
                  </button-base>
                </template>
                <span>TV Mode will open a new browser window</span>
              </v-tooltip>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              class="account-option is-relative z-100"
              :class="{
                'active-highlight': $shouldShowWeekends,
                disabled: isWeekdayOptionDisabled
              }">
              <button-base
                data-testid="weekend-toggle-button"
                text
                plain
                :ripple="false"
                class="d-flex align-center justify-center"
                @click="toggleWeekendVisibility">
                <v-icon small>
                  {{ $shouldShowWeekends ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                </v-icon>
                <span class="flex-grow-1 justify-start font-size-x-small">Show weekends</span>
              </button-base>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="calendar-nav d-flex align-center justify-end">
        <div class="d-flex align-center" data-testid="calendar-date-step">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Today</v-btn>
          <div>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
              data-testid="grid-prev-period">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
              data-testid="grid-next-period">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-toolbar>
  </v-sheet>
</template>

<script>
import { CalendarViewEnum } from '@/enums';
import storageHelpers from '@satellite/helpers/storage';
import { viewModes } from '@/modules/calendar/store';

/**
 * Calendar Header
 * @displayName Calendar Header
 */
export default {
  computed: {
    isWeekdayOptionDisabled() {
      return (
        this.$mode === this.viewModes.list || this.$viewType.toLowerCase() === this.viewTypes.DAY
      );
    },
    viewTypes() {
      return CalendarViewEnum;
    },
    viewModes() {
      return viewModes;
    },
    /**
     * The view type based on the CalendarViewEnum options
     */
    viewType: {
      get() {
        return this.$viewType;
      },
      set(type) {
        this.$store.dispatch('Calendar/setViewType', { value: type, getEvents: true });
      }
    },
    mode: {
      get() {
        return this.$mode;
      },
      set(mode) {
        this.$store.dispatch('Calendar/setMode', { value: mode });
      }
    },
    tvModeDisabled() {
      return this.$selectedDockIds?.length === 0;
    },
    /**
     * A mapping of which calendar view enum options are available
     */
    availableViewTypes() {
      return Object.keys(CalendarViewEnum).map(type => {
        return type;
      });
    },
    /**
     * The calendar reference from the vuex calendar store
     */
    calendar() {
      return this.$calendar;
    },
    gridMode() {
      return this.mode === viewModes.grid;
    },
    listMode() {
      return this.mode === viewModes.list;
    },
    accessToken() {
      return sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
    }
  },
  data() {
    return {
      intervalOptions: [15, 20, 30],
      selectedIntervalOptionIndex: 2
    };
  },
  methods: {
    getViewModeMeta(mode) {
      let icon = 'mdi-grid';
      let label = ' View';
      switch (mode ?? this.$mode) {
        case viewModes.grid:
          icon = 'mdi-grid';
          label = `Grid ${label}`;
          break;
        case viewModes.list:
          icon = 'mdi-format-list-checkbox';
          label = `List ${label}`;
          break;
      }
      return { icon, label };
    },
    viewTvMode() {
      if (this.$selectedDockIds) {
        const routeData = this.$router.resolve({
          name: 'wormhole.loginAs',
          params: {
            accessToken: this.accessToken
          },
          query: {
            nextRoute: 'appointmentsTV',
            warehouseIdParam: this.$selectedWarehouse.id,
            dockIdsParam: this.$selectedDockIds
          }
        });
        window.open(routeData.href, '_blank');
      }
    },
    /**
     * Set a new calendar date
     * @param value
     */
    calendarChange(value) {
      this.$store.dispatch('Calendar/setSelectedDate', {
        value,
        getEvents: true
      });
    },
    /**
     * Update calendar state with current date
     * @public
     */
    setToday() {
      const today = this.$selectedWarehouse?.timezone
        ? momentjs().tz(this.$selectedWarehouse.timezone)
        : momentjs();
      const adjustedDate =
        this.viewType.toUpperCase() === 'WEEK' &&
        !this.$shouldShowWeekends &&
        today.isoWeekday() > 5
          ? today.add(1, 'weeks').isoWeekday(1).format(this.novaCore.DateTimeFormats.DateDashed)
          : today.format(this.novaCore.DateTimeFormats.DateDashed);
      this.$store.dispatch('Calendar/setSelectedDate', {
        value: adjustedDate,
        getEvents: true
      });
    },
    /**
     * Navigate to previous date range
     * @public
     */
    prev() {
      if (this.gridMode && this.calendar) {
        this.calendar.prev();
      } else if (this.listMode) {
        const date = momentjs(this.$selectedDate).startOf(this.viewType).subtract(1, this.viewType);
        this.$store.dispatch('Calendar/setSelectedDate', {
          value: date.format(this.novaCore.DateTimeFormats.DateDashed),
          getEvents: true
        });
      }
    },
    /**
     * Navigate to next date range
     * @public
     */
    next() {
      if (this.gridMode && this.calendar) {
        this.calendar.next();
      } else if (this.listMode) {
        const date = momentjs(this.$selectedDate).startOf(this.viewType).add(1, this.viewType);
        this.$store.dispatch('Calendar/setSelectedDate', {
          value: date.format(this.novaCore.DateTimeFormats.DateDashed),
          getEvents: true
        });
      }
    },
    setIntervalMinutes(intervalMinutes) {
      this.$store.dispatch('Calendar/setIntervalMinutes', intervalMinutes);
    },
    toggleWeekendVisibility() {
      if (this.isWeekdayOptionDisabled) {
        return;
      }
      if (this.viewType.toUpperCase() === 'WEEK' && this.$shouldShowWeekends) {
        const selectedDate = momentjs(this.$selectedDate);
        if (selectedDate.isoWeekday() > 5) {
          this.$store.dispatch('Calendar/setSelectedDate', {
            value: selectedDate
              .add(1, 'weeks')
              .isoWeekday(1)
              .format(this.novaCore.DateTimeFormats.DateDashed),
            getEvents: true
          });
        }
      }

      this.$store.dispatch('Calendar/setShouldShowWeekends', {
        value: !this.$shouldShowWeekends,
        getEvents: false
      });
    }
  },
  mounted() {
    const storedCalendarData = this.$loadObjectFromLocalStorage(
      storageHelpers.makeUserBoundedKey('nova_calendar', this.$me.id)
    );
    if (storedCalendarData.intervalMinutes) {
      this.selectedIntervalOptionIndex = this.intervalOptions.findIndex(
        option => option === storedCalendarData.intervalMinutes
      );
      this.setIntervalMinutes(storedCalendarData.intervalMinutes);
    }
  }
};
</script>
<style>
.v-toolbar__content {
  width: 100% !important;
}
</style>
