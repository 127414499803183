var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row-actions" },
    [
      _c("users-list-dialog", {
        attrs: { "org-id": _vm.org.id, "header-text": `${_vm.org.name} Users` },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "icon-tooltip-button",
                  _vm._g(
                    _vm._b(
                      {
                        attrs: {
                          size: "large",
                          color: "light",
                          tooltip: "View users",
                          icon: "mdi-account-group",
                        },
                      },
                      "icon-tooltip-button",
                      attrs,
                      false
                    ),
                    on
                  )
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-menu",
        {
          ref: `contextMenu-${_vm.org.id}`,
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b(
                        { staticClass: "ml-2", attrs: { size: "24" } },
                        "v-icon",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v("mdi-dots-horizontal")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c("v-subheader", { staticClass: "ml-2" }, [
                _vm._v("ORG MANAGEMENT"),
              ]),
              _c("edit-org-billing-dialog", {
                attrs: { org: _vm.org },
                on: {
                  close: function ($event) {
                    return _vm.closeContextMenu(_vm.org.id)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ attrs, on }) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mr-1" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-pencil-outline"),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v("Edit org external billing ID"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("edit-org-expires-dialog", {
                attrs: { org: _vm.org },
                on: {
                  close: function ($event) {
                    return _vm.closeContextMenu(_vm.org.id)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ attrs, on }) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mr-1" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-calendar"),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v("Edit org expiration date"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("settings-list-dialog", {
                attrs: { settings: _vm.org.settings || {} },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (slotProps) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              { attrs: { disabled: !_vm.org.settings } },
                              "v-list-item",
                              slotProps.attrs,
                              false
                            ),
                            slotProps.on
                          ),
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mr-1" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-eye"),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.org.settings
                                      ? "View org settings"
                                      : "Org has not configured any settings"
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("edit-org-type-dialog", {
                attrs: { org: _vm.org },
                on: {
                  close: function ($event) {
                    return _vm.closeContextMenu(_vm.org.id)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ attrs, on }) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mr-1" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-shape"),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v("Change Org Type"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("edit-org-tags-dialog", {
                attrs: { org: _vm.org },
                on: {
                  close: function ($event) {
                    return _vm.closeContextMenu(_vm.org.id)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ attrs, on }) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mr-1" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-tag"),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v("Manage Tags")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("custom-forms-features-dialog", {
                attrs: { org: _vm.org },
                on: {
                  close: function ($event) {
                    return _vm.closeContextMenu(_vm.org.id)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ attrs, on }) {
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mr-1" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-table-plus"),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-title", [_vm._v("Features Setup")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("v-divider", { staticClass: "my-3" }),
              _c("v-subheader", { staticClass: "ml-2" }, [
                _vm._v(
                  " ORG STATUS (Toggle " +
                    _vm._s(
                      _vm.org.isActive ? "off to deactivate" : "on to activate"
                    ) +
                    ") "
                ),
              ]),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toggleOrgStatus(_vm.org)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "mr-1" },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            small: "",
                            color: _vm.org.isActive ? "orange" : "gray",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.org.isActive
                                  ? "mdi-toggle-switch"
                                  : "mdi-toggle-switch-off-outline"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.org.isActive ? "Active" : "Inactive") +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }