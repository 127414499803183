<!--
  -- this component is using the new Vue Composition API, this is a step towards our goal
  -- of migrating helios into Vue3. To learn more about it, check:
  -- https://vuejs.org/api/composition-api-setup
  -- https://vuejs.org/guide/extras/composition-api-faq.html
-->
<template>
  <div
    ref="rootEl"
    class="carrier-search-item"
    @click.prevent.stop="handleClick"
    data-testid="carrier-list-search-item"
    :data-carrier-id="carrier.id">
    <div class="d-flex flex-column carrier-company">
      <p class="full-name">{{ carrier.fullName }}</p>
      <p>
        <v-icon small class="truck-icon mr-1">mdi-truck</v-icon>
        <span class="company-name mr-1">{{ carrier.company.name }}</span>
        <span class="company-scac">{{ carrier.company.scac }}</span>
      </p>
    </div>
    <div class="carrier-info">
      <p>{{ carrier.email }}</p>

      <p v-if="subscriberCount && subscriberCount > 0">
        <v-icon small class="mr-1">mdi-email-fast-outline</v-icon>
        <span>+ {{ subscriberCount }} {{ novaCore.pluralize(subscriberCount, 'subscriber') }}</span>
      </p>
    </div>
    <p
      class="carrier-action"
      :class="state.className"
      data-testid="carrier-list-search-item-action">
      <v-icon small left>{{ state.icon }}</v-icon>
      <span>{{ state.label }}</span>
    </p>
    <carrier-contact-remove-dialog
      ref="removeContactModal"
      external-activator
      :mixpanel-data="mixpanelData"
      :carrierContact="carrierContactDetail.carrierContactRef.value"
      @removed="handleRemoved"
      v-if="!!carrierContactDetail.carrierContactRef.value?.id" />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useElementHover } from '@vueuse/core';

import {
  useAuth,
  useCarrierContactDetail,
  useCarrierContacts,
  useCarriers,
  useNovaCore,
  useNotify,
  useOrg
} from '@/composables';
import { objectPropFactory } from '@/helpers/props';
import * as permissions from '@/helpers/permissions';

/**
 * @param {boolean} isAdded
 * @param {boolean} isHovered
 * @param {boolean} canManageContact
 */
function getCarrierState(isAdded, isHovered, canManageContact) {
  let className = 'carrier-default';
  let label = 'Add';
  let icon = 'mdi-plus';

  if (isAdded) {
    className = 'carrier-added';
    label = 'Added';
    icon = 'mdi-check';

    if (isHovered && canManageContact) {
      className = 'carrier-added-hover';
      label = 'Remove';
      icon = 'mdi-playlist-remove';
    }
  }

  return { className, label, icon };
}

export default defineComponent({
  name: 'CarrierListSearchItem',
  props: {
    ...objectPropFactory('carrier', ['id', 'fullName', 'email', 'company.name', 'company.scac'])
  },
  setup(props) {
    const org = useOrg();
    const rootEl = ref(null);
    const isHovered = useElementHover(rootEl);
    const novaCore = useNovaCore();
    const carriers = useCarriers();
    const carrierContacts = useCarrierContacts();
    const carrierContactDetail = useCarrierContactDetail();
    const removeContactModal = ref();
    const { meRef: me } = useAuth();
    const notify = useNotify();

    const isAdded = computed(() => {
      const orgId = org.orgRef.value.id;
      const settings = props.carrier?.orgCarrierSettings?.find(o => o.orgId === orgId);
      return settings?.isActive ?? false;
    });

    const canManageContact = computed(() => {
      const orgId = org.orgRef.value.id;
      const carrierContact = props.carrier?.orgCarrierSettings?.find(o => o.orgId === orgId);
      return permissions.canManageCarrierContact(me.value, carrierContact);
    });

    const state = computed(() =>
      getCarrierState(isAdded.value, isHovered.value, canManageContact.value)
    );
    const subscriberCount = computed(
      () => props.carrier?.orgCarrierSettings?.[0]?.emailCCs?.length ?? 0
    );

    const mixpanelData = computed(() => ({
      'Entry Point': 'Carrier Contacts Select List',
      'Carrier Contact Name': novaCore.namesToFullName(
        props.carrier.firstName,
        props.carrier.lastName
      ),
      'Carrier Contact Email': props.carrier.email,
      'Carrier Company Name': props.carrier.company?.name,
      'Carrier Company ID': props.carrier.company?.id,
      'Org ID': org.orgRef.value.id,
      'Org Name': org.orgRef.value.name
    }));

    const removeCarrierContact = async () => {
      try {
        await carrierContactDetail.fetch({ s: { userId: props.carrier.id } });
        removeContactModal?.value?.openModal?.();
      } catch {
        notify('Failed to remove contact from list', 'success');
      }
    };

    const createCarrierContact = async () => {
      await carrierContacts.create(
        { userId: props.carrier.id },
        { mixpanelData: mixpanelData.value }
      );
      carriers.fetch();
    };

    const handleClick = async () => {
      if (!isAdded.value) {
        createCarrierContact();
        return;
      }
      if (canManageContact.value) {
        removeCarrierContact();
      }
    };

    const handleRemoved = async () => {
      await carriers.fetch();
      carrierContactDetail.clear();
    };

    return {
      handleClick,
      novaCore,
      rootEl,
      state,
      subscriberCount,
      removeContactModal,
      carrierContactDetail,
      mixpanelData,
      handleRemoved
    };
  }
});
</script>

<style scoped lang="scss">
// TODO(2024-06-19): the styling breaks if we remove these imports,
// we need to investigate why they are not being imported by default.
@import '~vuetify/src/styles/styles.sass';
@import '@satellite/styles/main';
@import '@/styles/partials/variables';

.carrier-search-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 4px;
  margin: 0 !important;
  max-width: 100%;

  p,
  span {
    margin-bottom: 0 !important;
  }

  .full-name {
    color: $color-neutral-90;
  }

  .carrier-company {
    width: 45%;
    color: $color-neutral-80;
  }

  .company-name {
    font-weight: 600;
  }

  .carrier-action {
    display: flex;
    justify-content: flex-end;
    caret-color: $accent !important;
    color: $accent !important;
    font-weight: 600;
    text-transform: capitalize !important;
    width: 10%;
    min-width: 10ch;

    .v-icon {
      color: $accent !important;
    }

    &.carrier-added {
      color: $color-primary-60 !important;
      caret-color: $color-primary-60 !important;

      .v-icon {
        color: $color-primary-60 !important;
      }
    }

    &.carrier-added-hover {
      color: $color-text-error !important;
      caret-color: $color-text-error !important;

      .v-icon {
        color: $color-text-error !important;
      }
    }
  }

  .carrier-info {
    color: $color-neutral-90;
    width: 45%;
  }
}
</style>
