var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mt-1" },
    [
      _c(
        "v-col",
        [
          _vm.role && _vm.disabled
            ? _c(
                "div",
                { staticClass: "font-size-x-small mb-2 mt-1 text--red" },
                [
                  _c("v-icon", { attrs: { "x-small": "" } }, [
                    _vm._v("mdi-alert"),
                  ]),
                  _vm._v(" This user role cannot be warehouse restricted "),
                ],
                1
              )
            : _vm._e(),
          !_vm.disabled
            ? [
                _c("v-switch", {
                  attrs: {
                    "input-value": _vm.value,
                    readonly: _vm.readOnly,
                    disabled: _vm.readOnly,
                    "hide-details": "auto",
                  },
                  on: { change: (val) => _vm.$emit("input", val) },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c("div", _vm._g({}, on), [
                                            _vm._v(
                                              "Restrict this user's permissions to specific warehouses"
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3213949487
                                ),
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tight-tooltip-content" },
                                  [
                                    _c("div", [
                                      _c("strong", [
                                        _vm._v("Restricted user:"),
                                      ]),
                                      _vm._v(
                                        " Permissions will only be allowed in selected warehouses. "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _c("strong", [
                                        _vm._v("Unrestricted user:"),
                                      ]),
                                      _vm._v(
                                        " Permissions will be allowed in all warehouses. This also includes warehouses that may be created in the future. "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2649577368
                  ),
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }