<template>
  <form-base :hide-required-legend="true">
    <template #form>
      <v-form class="pt-lg-6" ref="form">
        <v-checkbox
          v-model="hasReason"
          label="Add note or reason for cancellation"
          hide-details></v-checkbox>
        <v-expand-transition>
          <v-textarea
            :autofocus="hasReason"
            class="mt-6"
            v-if="hasReason"
            name="cancel_reason"
            label="Reason for cancellation"
            :validate-on-blur="false"
            :rules="[
              ...$validator.rules.minLength(10),
              ...$validator.rules.required('Reason for Cancellation')
            ]"
            v-model="cancelReason"></v-textarea>
        </v-expand-transition>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        confirm-label="Yes, Cancel"
        @cancel="$emit('nevermind')"
        @confirm="cancelAppointment"></action-group>
    </template>
  </form-base>
</template>

<script>
export default {
  props: {
    appointment: {
      type: Object,
      required: true
    },
    mixpanelEntryPoint: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      hasReason: false,
      cancelReason: ''
    };
  },
  methods: {
    async cancelAppointment() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('Appointments/cancelAppointment', {
          id: this.appointment.id,
          reason: this.cancelReason
        });
        this.$store.dispatch('Appointments/trackMixpanelEvent', {
          entryPoint: this.mixpanelEntryPoint,
          appointment: this.appointment,
          change: 'Cancelled'
        });
        this.$eventHub.$emit('appointment-updated');
        this.$emit('confirm');
      }
    }
  }
};
</script>
