var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h3", { staticClass: "mt-12 mb-2 black--text" }, [
              _vm._v("Amenities available at the warehouse"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-left flex-wrap",
              attrs: { cols: "12" },
            },
            _vm._l(_vm.amenitiesEnum, function (item) {
              return _c("v-checkbox", {
                key: item,
                staticClass: "mr-4",
                attrs: {
                  label: item,
                  value: _vm.fields.amenities?.includes(item),
                },
                on: { click: () => _vm.toggleItem(item, "amenities") },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h3", { staticClass: "mt-10 mb-2 black--text" }, [
              _vm._v("PPE and other requirements"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-left flex-wrap",
              attrs: { cols: "12" },
            },
            _vm._l(_vm.ppeRequirementsEnum, function (item) {
              return _c("v-checkbox", {
                key: item,
                staticClass: "mr-4",
                attrs: {
                  label: item,
                  value: _vm.fields.ppeRequirements?.includes(item),
                },
                on: { click: () => _vm.toggleItem(item, "ppeRequirements") },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }