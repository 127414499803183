<template>
  <v-row class="mt-1">
    <v-col>
      <div class="font-size-x-small mb-2 mt-1 text--red" v-if="role && disabled">
        <v-icon x-small>mdi-alert</v-icon>
        This user role cannot be warehouse restricted
      </div>
      <template v-if="!disabled">
        <v-switch
          :input-value="value"
          :readonly="readOnly"
          :disabled="readOnly"
          @change="val => $emit('input', val)"
          hide-details="auto">
          <template v-slot:label>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">Restrict this user's permissions to specific warehouses</div>
              </template>
              <div class="tight-tooltip-content">
                <div>
                  <strong>Restricted user:</strong>
                  Permissions will only be allowed in selected warehouses.
                </div>
                <div class="mt-2">
                  <strong>Unrestricted user:</strong>
                  Permissions will be allowed in all warehouses. This also includes warehouses that
                  may be created in the future.
                </div>
              </div>
            </v-tooltip>
          </template>
        </v-switch>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    role: {
      type: String,
      required: true
    }
  },
  computed: {
    disabled() {
      return !this.novaCore.canUserRoleHaveWarehouseAccessList(this.role);
    }
  }
};
</script>
