<template>
  <v-tooltip
    top
    color="color-neutral-90"
    open-delay="1000"
    transition="scale-transition"
    min-width="500">
    <template v-slot:activator="{ on, attrs }">
      <div
        class="full-height"
        :data-testid="`yard-spot-assigned-${spot.code}`"
        v-bind="attrs"
        v-on="on">
        <p :class="{ 'mb-9': getSpotIcon, 'mb-7': !getSpotIcon }">
          <v-icon small :class="`spot-type-${spot.type} ${getLoadTypeDirectionColor}`">
            {{ getSpotIcon }}
          </v-icon>
        </p>

        <svg-truck :rotation="90" :scale="1.1" :theme="truckThemeColor" />
      </div>
    </template>
    <yard-view-spot-tooltip :spot="spot" :appointment="appointment" />
  </v-tooltip>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import SvgTruck from '@/components/svgs/SvgTruck.vue';
import YardViewSpotTooltip from '@/modules/yard/components/Spot/Card/YardViewSpotTooltip.vue';

export default defineComponent({
  name: 'YardViewSpotAssigned',
  components: { YardViewSpotTooltip, SvgTruck },
  props: {
    spot: {
      type: Object,
      required: true
    },
    appointment: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const yardStore = useYard();

    const truckThemeColor = computed(() => yardStore.getTruckThemeColor(props.appointment));

    const getSpotIcon = computed(() =>
      yardStore.getAssignedSpotIcon(
        props.spot.type,
        Boolean(props.appointment?.id),
        props.appointment?.loadType?.direction
      )
    );

    const getLoadTypeDirectionColor = computed(() =>
      yardStore.getLoadTypeDirectionColor(props.appointment?.loadType?.direction)
    );

    return {
      getSpotIcon,
      getLoadTypeDirectionColor,
      getSpotStatus: yardStore.getSpotStatus,
      truckThemeColor
    };
  }
});
</script>
