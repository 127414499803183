<template>
  <dock-list
    :read-only="$isDockReadOnly(warehouse.id, warehouse.orgId)"
    :warehouse-id="warehouse.id"></dock-list>
</template>

<script>
export default {
  props: {
    warehouse: {
      type: Object,
      required: true
    }
  }
};
</script>
