var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "dock-schedule-header d-flex align-center mb-5" },
      [
        _c("strong", { staticClass: "mr-2" }, [_vm._v("Interval:")]),
        _c(
          "v-btn-toggle",
          {
            staticClass: "secondary-button-group interval-buttons",
            attrs: { mandatory: "" },
            model: {
              value: _vm.interval,
              callback: function ($$v) {
                _vm.interval = $$v
              },
              expression: "interval",
            },
          },
          [
            _c(
              "v-tooltip",
              {
                attrs: { top: "", disabled: !_vm.disable30MinOption },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "div",
                          _vm._g({ staticClass: "d-inline" }, on),
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "rounded-tr-0 rounded-br-0",
                                attrs: {
                                  elevation: "0",
                                  small: "",
                                  value: 30,
                                  disabled: _vm.disable30MinOption,
                                },
                              },
                              [_vm._v("30 MIN ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("span", [
                  _vm._v(
                    "Schedule has at least one 15 or 20 min interval. 30 minute view is disabled"
                  ),
                ]),
              ]
            ),
            _c(
              "v-tooltip",
              {
                attrs: { top: "", disabled: !_vm.disable20MinOption },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "div",
                          _vm._g({ staticClass: "d-inline" }, on),
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  tile: "",
                                  elevation: "0",
                                  small: "",
                                  value: 20,
                                  disabled: _vm.disable20MinOption,
                                },
                              },
                              [_vm._v("20 MIN ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("span", [
                  _vm._v(
                    "Schedule has at least one 15 or 30 min interval. 20 minute view is disabled"
                  ),
                ]),
              ]
            ),
            _c(
              "v-tooltip",
              {
                attrs: { top: "", disabled: !_vm.disable15MinOption },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "div",
                          _vm._g({ staticClass: "d-inline" }, on),
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  tile: "",
                                  elevation: "0",
                                  small: "",
                                  value: 15,
                                  disabled: _vm.disable15MinOption,
                                },
                              },
                              [_vm._v("15 MIN ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("span", [
                  _vm._v(
                    "Schedule has at least one 20 min interval. 15 minute view is disabled"
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _c("v-spacer"),
        _c(
          "v-menu",
          {
            attrs: {
              "content-class": "left-aligned-options",
              "offset-y": "",
              "internal-activator": true,
              "close-on-click": true,
            },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "secondary-button",
                      _vm._g(
                        _vm._b(
                          {
                            ref: "scheduleoptionsbtn",
                            attrs: { "after-icon": "chevron-down", append: "" },
                          },
                          "secondary-button",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c("span", { staticClass: "text-truncate" }, [
                          _vm._v(" Set Schedule As "),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _c(
              "v-list",
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "button-base",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "white",
                                    attrs: {
                                      "x-small": "",
                                      text: "",
                                      plain: "",
                                      block: "",
                                      "before-icon": "hours-24",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("set-schedule-default")
                                      },
                                    },
                                  },
                                  "button-base",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" Open 24/7 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("span", [
                      _vm._v("Set schedule back to default (open 24/7)"),
                    ]),
                  ]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "button-base",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "white",
                                    attrs: {
                                      text: "",
                                      plain: "",
                                      block: "",
                                      "x-small": "",
                                      "before-icon": "cancel",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("set-schedule-closed")
                                      },
                                    },
                                  },
                                  "button-base",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" Close 24/7 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [_c("span", [_vm._v("Close Schedule")])]
                ),
                _vm.withCopyFromDock || _vm.withCopyFromWarehouse
                  ? _c("div", { staticClass: "option-label mb-1" }, [
                      _vm._v(" Clone from "),
                    ])
                  : _vm._e(),
                _vm.withCopyFromWarehouse
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "button-base",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "white",
                                          attrs: {
                                            text: "",
                                            plain: "",
                                            block: "",
                                            "x-small": "",
                                            "before-icon": "content-copy",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "copy-warehouse-schedule"
                                              )
                                            },
                                          },
                                        },
                                        "button-base",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(" Warehouse ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          198563286
                        ),
                      },
                      [_c("span", [_vm._v("Clone the Warehouse Schedule")])]
                    )
                  : _vm._e(),
                _vm.withCopyFromDock
                  ? _c("clone-dock-schedule-dialog", {
                      attrs: { docks: _vm.docks },
                      on: {
                        close: function ($event) {
                          return _vm.$refs.scheduleoptionsbtn.$el.click()
                        },
                        "copy-schedule": _vm.emitCopyDockSchedule,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "is-relative" },
      [
        _vm.isInvalidTimeGrid
          ? _c("div", { staticClass: "table-loader__wrapper overlay-base" }, [
              _c("div", { staticClass: "table-loader__overlay overlay-base" }),
              _vm._m(0),
            ])
          : _vm._e(),
        _c("v-simple-table", {
          class: _vm.compact ? "compact" : "",
          attrs: { id: "schedule-table" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c("thead", [
                    _c(
                      "tr",
                      { attrs: { id: "schedule-header" } },
                      [
                        _c(
                          "th",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "2" },
                          },
                          [_vm._v("Time")]
                        ),
                        _vm._l(_vm.weekdayLabels, function (weekday, index) {
                          return _c(
                            "th",
                            {
                              key: weekday,
                              staticClass: "day-header",
                              attrs: { "data-column": index + 2 },
                            },
                            [_vm._v(" " + _vm._s(weekday) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._l(_vm.times, function (hourGroup, key) {
                    return [
                      _c(
                        "tbody",
                        { key: `tbody-${key}`, staticClass: "hour-group" },
                        _vm._l(hourGroup, function (time, index) {
                          return _c(
                            "tr",
                            {
                              key: time.start,
                              staticClass: "time-row",
                              class: [
                                {
                                  "start-of-interval-group":
                                    !index || index % _vm.intervalsInHour === 0,
                                },
                                "interval-row",
                              ],
                            },
                            [
                              !index || index % _vm.intervalsInHour === 0
                                ? _c(
                                    "th",
                                    {
                                      staticClass: "hour-label",
                                      attrs: { rowspan: _vm.intervalsInHour },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-inline-block full-width",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.convert24ToFormat(
                                                time.start,
                                                _vm.novaCore
                                                  .LuxonDateTimeFormats
                                                  .Extended12HrTimeAMPM,
                                                _vm.novaCore
                                                  .LuxonDateTimeFormats
                                                  .Extended24HrTime
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("th", { staticClass: "row-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.convert24to12(time.start)) +
                                    " "
                                ),
                              ]),
                              _vm._l(
                                _vm.weekdays,
                                function (weekday, colIndex) {
                                  return _c(
                                    "td",
                                    {
                                      key: time.start + weekday,
                                      staticClass:
                                        "time-cell is-relative day-cell",
                                      class: {
                                        active:
                                          _vm.isOpen(weekday, time) &&
                                          !_vm.isTimeDisabled(weekday, time),
                                        "time-is-disabled": _vm.isTimeDisabled(
                                          weekday,
                                          time
                                        ),
                                      },
                                      attrs: {
                                        "data-column": colIndex + 2,
                                        "data-start": time.start,
                                        "data-end": time.end,
                                      },
                                      on: {
                                        mousedown: (e) => {
                                          _vm.handleCellClick(
                                            e,
                                            weekday,
                                            time,
                                            _vm.isTimeDisabled(weekday, time)
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "add-interval",
                                          attrs: { "x-small": "" },
                                        },
                                        [_vm._v("mdi-plus")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "subtract-interval",
                                          attrs: { "x-small": "" },
                                        },
                                        [_vm._v("mdi-minus")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "disabled-time-interval overlay align-center justify-center",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { "x-small": "" },
                                            },
                                            [_vm._v("mdi-cancel")]
                                          ),
                                          _vm._v(" Warehouse "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ]
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-loader__content" }, [
      _c("div", { staticClass: "text-center mt-3" }, [
        _vm._v(
          " This schedule has a conflicting combination of intervals - 20 and 15/30. Please clear schedule to edit. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex mt-2 align-center" }, [
      _c("div", { staticClass: "mr-4 d-flex align-center" }, [
        _c("div", { staticClass: "circle bordered primary pa-1 mr-1" }),
        _c("strong", { staticClass: "font-size-x-small" }, [_vm._v("OPEN")]),
      ]),
      _c("div", { staticClass: "d-flex align-center" }, [
        _c("div", { staticClass: "circle pa-1 mr-1 bordered" }),
        _c("strong", { staticClass: "font-size-x-small" }, [_vm._v("CLOSED")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }