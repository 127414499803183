<template>
  <div class="my-6">
    <h5 class="mb-2">+ Add/Remove fields to the form</h5>
    <v-autocomplete
      v-bind="$props"
      :loading="loading"
      :items="fields"
      :search-input.sync="searchStr"
      ref="dropdown"
      outlined
      hide-details
      item-text="label"
      :menu-props="{ auto: true, closeOnClick: false, closeOnContentClick: false }"
      placeholder="Search by field name...">
      <template v-slot:item="{ item }">
        <v-row>
          <v-col align-self="start" cols="6">{{ item.label }}</v-col>
          <v-col>
            <strong>
              <v-icon small class="mr-2">mdi-{{ novaCore.customFieldIcons[item.type] }}</v-icon>
              <small>
                {{ novaCore.customFieldLabels[item.type] }}
              </small>
            </strong>
          </v-col>
          <v-col align-self="end" cols="3">
            <div class="text-right">
              <v-btn
                text
                @click.stop="$emit(getFieldAction(item), item)"
                :color="fieldActions[getFieldAction(item)].color">
                <v-icon class="mr-1" small>
                  {{ fieldActions[getFieldAction(item)].icon }}
                </v-icon>
                <span>
                  {{ fieldActions[getFieldAction(item)].text }}
                </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:selection></template>
      <template v-slot:append-item>
        <v-list-item>
          Missing a field?
          <span class="secondary--text ml-2 cursor-pointer" @click="handleCreateField">
            <strong>Create new</strong>
          </span>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
export default {
  props: {
    existingFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fields: [],
      loading: false,
      searchStr: ''
    };
  },
  computed: {
    fieldActions() {
      return {
        remove: {
          text: 'Remove',
          icon: 'mdi-sort-variant-remove',
          color: 'red'
        },
        add: {
          text: 'Add',
          icon: 'mdi-plus',
          color: 'secondary'
        }
      };
    }
  },
  methods: {
    handleCreateField() {
      this.$refs.dropdown.blur();
      this.$emit('create', this.searchStr);
    },
    getFieldAction(field) {
      const fieldExists = this.existingFields?.map(f => f.id).includes(field.id);
      return fieldExists ? 'remove' : 'add';
    },
    async getFields() {
      this.loading = true;
      try {
        const response = await axios.get('/custom-forms/field', {
          params: {
            sort: 'label,ASC'
          }
        });
        this.fields = (response?.data?.data || []).filter(
          field => field.type !== this.novaCore.CustomFieldType.Action
        );
      } finally {
        this.loading = false;
      }
    }
  },
  async beforeMount() {
    await this.getFields();
  },
  mounted() {
    Object.keys(this.sockets.actions).map(action => {
      this.$eventHub.$on(`${action}-FormField`, this.getFields);
      this.$eventHub.$on(`${action}-Field`, this.getFields);
    });
  },
  beforeDestroy() {
    Object.keys(this.sockets.actions).map(action => {
      this.$eventHub.$off(`${action}-FormField`);
      this.$eventHub.$off(`${action}-Field`);
    });
    this.searchStr = '';
  }
};
</script>
