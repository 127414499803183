<template>
  <div>
    <header class="mobile-header pa-4">
      <img class="mobile-logo" :src="$logoPath" />
      <div class="spacer"></div>
      <create-appointment-dialog data-device="mobile" x-small></create-appointment-dialog>
      <v-app-bar-nav-icon
        v-if="$me"
        @click="drawer = !drawer"
        class="white--text ml-4"
        @click.stop=""></v-app-bar-nav-icon>
    </header>

    <v-navigation-drawer
      data-testid="app-nav-drawer"
      :class="{ collapsed: isCollapsed }"
      v-if="$me"
      v-model="drawer"
      mobile-breakpoint="768"
      :width="isCollapsed ? 60 : 240"
      class="secondary"
      dark
      :mini-variant.sync="mini"
      app>
      <header class="nova-logo">
        <div v-if="isStaging" class="env-flag staging-flag">STAGING</div>
        <div v-if="isRND" class="env-flag rnd-flag">RND</div>
        <div v-if="isQA" class="env-flag rnd-flag">QA</div>
        <div class="pa-3" v-if="!isCollapsed">
          <v-img class="px-2" :src="$logoPath" id="logo-image" contain></v-img>
        </div>
        <div class="text-center pt-4 pb-2" v-else>
          <svg
            width="30"
            height="30"
            viewBox="0 0 46 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.7793 3.30172L39.3721 16.5949V43.2203L45.0088 39.8951V13.2931L22.416 0L16.7793 3.30172Z"
              fill="white" />
            <path
              d="M8.52832 8.17187L31.0212 21.3944V48.1375L36.6502 44.8201V18.2025L14.0266 4.92505L8.52832 8.17187Z"
              fill="white" />
            <path
              d="M0.161621 13.105L22.7468 26.3982L22.7544 53.0001L28.2912 49.769V23.0494L5.79829 9.77979L0.161621 13.105Z"
              fill="white" />
            <path d="M2.27588 41.1579L20.2932 51.761V30.5469L2.27588 41.1579Z" fill="white" />
            <path d="M0 15.9124V39.6126L5.63667 36.2952V19.2298L0 15.9124Z" fill="white" />
            <path
              d="M2.21468 2.69723H3.04519C3.42199 2.69723 3.67576 2.46195 3.67576 2.09335C3.67576 1.7326 3.42199 1.48948 3.04519 1.48948H2.21468V2.69723ZM3.73728 4.61866L2.81449 3.03446H2.21468V4.61866H1.87633V1.15225H3.04519C3.71421 1.15225 4.01411 1.61496 4.01411 2.09335C4.01411 2.54038 3.76034 2.95604 3.19898 3.01878L4.12946 4.61081H3.73728V4.61866ZM0.338354 2.88545C0.338354 1.49732 1.44569 0.367992 2.82218 0.367992C4.20636 0.367992 5.3137 1.50516 5.3137 2.88545C5.3137 4.27358 4.20636 5.40291 2.82218 5.40291C1.44569 5.40291 0.338354 4.26574 0.338354 2.88545ZM0 2.88545C0 4.46181 1.26114 5.74014 2.82218 5.74014C4.38322 5.74014 5.64436 4.46181 5.64436 2.88545C5.64436 1.3091 4.38322 0.0307617 2.82218 0.0307617C1.26114 0.0307617 0 1.3091 0 2.88545Z"
              fill="white" />
          </svg>
        </div>

        <template v-if="isTrial && !isCollapsed && !isInternalUser">
          <div class="trial d-flex justify-center my-2 mt-4 white--text">
            <small>
              <span class="trial-message" v-if="!isExpired">Trial expires in:</span>
              <span class="trial-flag">
                {{ remainingTrialMessage }}
              </span>
            </small>
          </div>
        </template>

        <div class="mt-4 white--text">
          <div class="d-flex flex-row justify-center align-center account-nav">
            <account-navigation :is-compact="isCollapsed"></account-navigation>
          </div>

          <div v-if="!isCollapsed" class="d-flex flex-row justify-center align-center my-2">
            <small>{{ role }}</small>
          </div>

          <v-divider v-if="!isCollapsed" class="mb-3"></v-divider>

          <div v-if="!isCollapsed" class="d-flex justify-center my-2 mb-4">
            <create-appointment-dialog data-device="desktop"></create-appointment-dialog>
          </div>
        </div>
      </header>

      <v-list class="pa-0 mt-3" dense nav>
        <div class="mb-4 text-center">
          <create-appointment-dialog v-if="isCollapsed" data-device="desktop">
            <template v-slot:activator="{ on, attrs }">
              <v-tooltip right>
                <template v-slot:activator="{ on: tooltipOn }">
                  <div v-on="tooltipOn">
                    <primary-button large v-bind="attrs" v-on="on" icon tile class="rounded">
                      <v-icon>mdi-plus</v-icon>
                    </primary-button>
                  </div>
                </template>
                <span v-if="$rolePermissions.canCreateAppointment">Create Appointment</span>
                <span v-else>{{ novaCore.getRoleActionError() }}</span>
              </v-tooltip>
            </template>
          </create-appointment-dialog>
        </div>

        <section v-for="item in navItemsToDisplay" :key="item.link">
          <!-- Top Level Link w/o children -->
          <v-tooltip right :disabled="!isItemDisabled(item) && !isCollapsed">
            <template v-slot:activator="{ on }">
              <v-list-item
                :data-testid="item.testId"
                link
                v-on="on"
                :target="getLinkAttrs(item).target"
                :to="getLinkAttrs(item).to"
                :href="getLinkAttrs(item).href"
                v-if="!item.children"
                :inactive="isItemDisabled(item)"
                :class="{ 'disabled-list-item': isItemDisabled(item) }"
                @click="handleListItemButtonClick(item)">
                <v-list-item-action>
                  <v-icon medium :disabled="isItemDisabled(item)">{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-title class="nav-item-title" :class="{ collapsed: isCollapsed }">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <span v-if="isItemDisabled(item)">{{ makeDisabledTooltipMsg(item) }}</span>
            <span v-else-if="isCollapsed">{{ item.title }}</span>
          </v-tooltip>

          <v-list-group
            :disabled="isItemDisabled(item)"
            :class="{ 'disabled-list-item': isItemDisabled }"
            color="white"
            v-if="item.children"
            :value="item.isOpen"
            no-action
            :key="item.link"
            :prepend-icon="item.icon">
            <!-- Top Level Link with children -->
            <template slot="activator">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>

            <template v-for="childItem in item.children">
              <!-- IF Grandchildren -->
              <v-list-group
                no-action
                v-if="childItem.children"
                :key="childItem.link"
                sub-group
                :value="childItem.isOpen">
                <template slot="activator">
                  <v-tooltip top :disabled="!isItemDisabled(item)" :key="item.link">
                    <template v-slot:activator="{ on }">
                      <v-list-item
                        v-on="on"
                        class="nd-second-level"
                        :target="getLinkAttrs(childItem).target"
                        :to="getLinkAttrs(childItem).to"
                        :href="getLinkAttrs(childItem).href">
                        <v-list-item-content>
                          <v-list-item-title>{{ childItem.title }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>{{ makeDisabledTooltipMsg(item) }}</span>
                  </v-tooltip>
                </template>

                <!-- Grandchild -->
                <v-tooltip top :disabled="!isItemDisabled(item)" :key="grandchild.link">
                  <template v-slot:activator="{ on }">
                    <v-list-item
                      link
                      v-for="grandchild in childItem.children"
                      :key="grandchild.link"
                      :target="getLinkAttrs(grandchild).target"
                      :to="getLinkAttrs(grandchild).to"
                      :href="getLinkAttrs(grandchild).href">
                      <v-list-item-content>
                        <v-list-item-title>{{ grandchild.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{ makeDisabledTooltipMsg(item) }}</span>
                </v-tooltip>
              </v-list-group>

              <v-tooltip top :disabled="!isItemDisabled(item)" :key="childItem.link">
                <template v-slot:activator="{ on }">
                  <v-list-item
                    :key="childItem.title"
                    :target="getLinkAttrs(childItem).target"
                    :to="getLinkAttrs(childItem).to"
                    :href="getLinkAttrs(childItem).href">
                    <v-list-item-title>{{ childItem.title }}</v-list-item-title>
                  </v-list-item>
                </template>
                <span>{{ makeDisabledTooltipMsg(item) }}</span>
              </v-tooltip>
            </template>
          </v-list-group>
        </section>
        <!-- SUPPORT CHAT ITEM -->
        <section>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-list-item link v-on="on" target="" to="" @click="$emit('supportBtnClick')" href="">
                <v-list-item-action>
                  <v-icon medium>mdi-{{ unreadMsgCount > 0 ? 'chat-alert' : 'chat' }}</v-icon>
                </v-list-item-action>
                <v-list-item-title class="nav-item-title" :class="{ collapsed: isCollapsed }">
                  Support Chat
                  <span v-if="unreadMsgCount">({{ unreadMsgCount }})</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <span>Support Chat</span>
          </v-tooltip>
        </section>
        <!-- Beamer ITEM -->
        <section v-if="!novaCore.isInternalUser($me) && !novaCore.isLoggedInAsAnotherUser($me)">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-list-item class="beamerTrigger" link v-on="on" target="" to="" href="">
                <v-list-item-action>
                  <v-icon medium>mdi-bullhorn</v-icon>
                </v-list-item-action>
                <v-list-item-title class="nav-item-title">What's New</v-list-item-title>
              </v-list-item>
            </template>
            <span>What's New</span>
          </v-tooltip>
        </section>
      </v-list>

      <v-footer
        :class="{ collapsed: isCollapsed }"
        class="justify-center text--lighten-1 grey--text text-center overline d-block version"
        absolute
        bottom
        inset>
        <template v-if="isMobileDevice">
          <v-btn
            v-if="!isCollapsed"
            class="mb-4 non-uppercase mobile-blue-bg"
            small
            @click="setRenderMode($root.ENUMS.RenderModeEnum.MOBILE)">
            <v-icon small>mdi-cellphone</v-icon>
            View Mobile Version
          </v-btn>
          <v-btn
            v-else
            icon
            class="mb-4 mobile-blue-bg"
            small
            @click="setRenderMode($root.ENUMS.RenderModeEnum.MOBILE)">
            <v-icon small>mdi-cellphone</v-icon>
          </v-btn>
        </template>

        <div class="d-flex full-width align-center">
          <connection-status-indicator></connection-status-indicator>
          <div class="version-number">
            Nova v{{ $neutronVersion.major }}.{{ $neutronVersion.minor }}.{{
              $neutronVersion.patch
            }}
            -
            {{ $neutronVersion.commit }}
          </div>
        </div>
        <v-tooltip right>
          <template v-slot:activator="{ on: tooltipOn }">
            <div class="d-flex justify-end full-width" v-on="tooltipOn">
              <v-btn data-testid="nav-menu-collapse-toggle-btn" icon small @click="toggleCollapse">
                <v-icon small>{{ collapseIcon }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ collapseIconTooltip }}</span>
        </v-tooltip>
      </v-footer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import NavigationDrawerBase from '@/components/elements/NavigationDrawerBase.vue';
import { isMobileDevice } from '@satellite/plugins/util';

/**
 * @displayName Navigation Drawer
 */
export default {
  extends: NavigationDrawerBase,
  computed: {
    isMobileDevice() {
      return isMobileDevice();
    }
  }
};
</script>

<style scoped lang="scss">
.account-nav {
  width: 100%;
  padding: 0 10px;
}

.v-navigation-drawer {
  .v-list:not(.v-select-list) {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    top: 225px;
    bottom: 100px;
    width: 100%;
  }

  &.collapsed {
    .v-list:not(.v-select-list) {
      top: 181px;
    }
  }
}
</style>
