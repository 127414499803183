var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "form", staticClass: "pb-5", attrs: { id: "dock-dialog-form" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6", xs: "12" } },
                [
                  _c("text-field", {
                    staticClass: "mb-0",
                    attrs: {
                      disabled: "",
                      readonly: !_vm.$rolePermissions.canCreateDock,
                      required: true,
                      type: "text",
                      "hide-details": "auto",
                      "prepend-icon": "mdi-warehouse",
                      rules: _vm.$validator.rules.required("Dock Name"),
                      label: "Dock Name",
                    },
                    model: {
                      value: _vm.internalDock.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.internalDock, "name", $$v)
                      },
                      expression: "internalDock.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-7" },
            [
              _c(
                "v-col",
                [
                  _c("load-type-select", {
                    attrs: {
                      "select-all": "",
                      readonly: "",
                      "selected-warehouse": _vm.warehouse,
                      "return-object": false,
                      "visible-selection-count": 3,
                      clearable: _vm.$rolePermissions.canCreateDock,
                      required: true,
                      rules: _vm.$validator.rules.selectAtLeast(1, "Load Type"),
                      "multi-select": true,
                    },
                    model: {
                      value: _vm.internalDock.loadTypeIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.internalDock, "loadTypeIds", $$v)
                      },
                      expression: "internalDock.loadTypeIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h2", { staticClass: "mt-2 py-0 black--text" }, [
              _vm._v("Dock Schedule"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c("caution-message", { staticClass: "pl-3 mb-4" }, [
            _vm._v(" Make sure you understand the "),
            _c("strong", [_vm._v("implications")]),
            _vm._v(" of having "),
            _c("strong", [_vm._v("different schedules")]),
            _vm._v(" for Capacity Docks before proceeding. "),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-btn-toggle",
            {
              staticClass: "secondary-button-group ml-3 mt-4 interval-buttons",
              attrs: { mandatory: "", dense: "" },
              model: {
                value: _vm.view,
                callback: function ($$v) {
                  _vm.view = $$v
                },
                expression: "view",
              },
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "div",
                            _vm._g({ staticClass: "d-inline" }, on),
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded-tr-0 rounded-br-0",
                                  attrs: {
                                    elevation: "0",
                                    small: "",
                                    value: _vm.viewTypes.schedule,
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-calendar")]
                                  ),
                                  _vm._v(" Weekly Schedule "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("View the weekly schedule")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "div",
                            _vm._g({ staticClass: "d-inline" }, on),
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    elevation: "0",
                                    small: "",
                                    value: _vm.viewTypes.closedDates,
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-calendar-remove")]
                                  ),
                                  _vm._v(" Closed Dates "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("View the closed dates")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.warehouse.id
        ? [
            _c(
              "v-row",
              {
                class: {
                  "visually-remove": _vm.view === _vm.viewTypes.closedDates,
                },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "mt-2" },
                  [
                    _vm.$rolePermissions.canCreateDock
                      ? [
                          _c("time-select-grid", {
                            ref: "timeSelect",
                            attrs: {
                              "settings-entity": _vm.warehouse,
                              "enabled-intervals": _vm.warehouse.schedule,
                              "toggle-to-edit": false,
                              "local-storage-key": _vm.internalDock.id,
                              compact: "",
                              value: _vm.internalDock.schedule,
                              docks: _vm.warehouse.docks,
                            },
                            on: {
                              "update-interval": (val) => (this.interval = val),
                              "set-schedule-default": _vm.setScheduleToDefault,
                              "set-schedule-closed": _vm.clearDockSchedule,
                              "copy-warehouse-schedule":
                                _vm.copyWarehouseScheduleToDock,
                              "copy-dock-schedule": _vm.copyDockScheduleToDock,
                            },
                          }),
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "mt-3 mb-4 text-h6 black--text mt-3",
                            },
                            [_vm._v("Hours of Operation")]
                          ),
                          _vm.internalDock.schedule && _vm.warehouse.timezone
                            ? _c("hours-of-operation-list", {
                                attrs: {
                                  schedule: _vm.internalDock.schedule,
                                  timezone: _vm.warehouse.timezone,
                                },
                              })
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                class: {
                  "visually-remove": _vm.view === _vm.viewTypes.schedule,
                },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "mt-3" },
                  [
                    _c("days-off", {
                      attrs: {
                        schedule: _vm.internalDock.schedule,
                        warehouse: _vm.warehouse,
                      },
                      on: {
                        "update-closed-intervals": _vm.updateClosedIntervals,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.$rolePermissions.canCreateDock
        ? _c("action-group", {
            attrs: {
              "confirm-icon": "upload",
              "confirm-label": "Save Dock",
              "cancel-label": "Nevermind",
            },
            on: {
              cancel: function ($event) {
                return _vm.$emit("close")
              },
              confirm: _vm.submit,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }