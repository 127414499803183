var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", { class: { "grey--text": _vm.disabled } }, [
      _vm._v(_vm._s(_vm.item.title)),
    ]),
    _vm.item.note
      ? _c(
          "span",
          {
            staticClass: "ml-3 font-size-x-small grey--text",
            class: { "text--darken-3": !_vm.disabled },
          },
          [_vm._v("(" + _vm._s(_vm.item.note) + ")")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }