<template>
  <div>
    <v-tabs
      v-model="tab"
      grow
      background-color="secondary"
      v-if="warehouse.id"
      dark
      slider-color="#ffe67f"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
      centered>
      <v-tab
        v-for="tab in tabs"
        :disabled="isTabDisabled(tab)"
        :key="tab.title"
        active-class="active-tab"
        :to="{ name: tab.route, params: { warehouseId: warehouseId } }">
        {{ tab.title }}
        <v-chip v-if="tab.showNewFeatureChip" class="ml-2" color="primary" x-small>NEW</v-chip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              right
              class="circular-pulse"
              v-if="getTabNotification(tab)"
              v-on="on"
              v-bind="attrs">
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span>{{ getTabNotification(tab) }}</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <div :key="renderKey">
      <v-tabs-items v-model="tab" v-if="warehouse.id">
        <v-tab-item v-for="tab in tabs" :key="tab.title" :value="route(tab.route)">
          <v-card flat>
            <component :warehouse="warehouse" :is="tab.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import WarehouseDetailsTab from '@/modules/warehouses/components/detail_tabs/WarehouseDetailsTab';
import WarehouseDocksTab from '@/modules/warehouses/components/detail_tabs/WarehouseDocksTab';
import WarehouseFormsTab from '@/modules/warehouses/components/detail_tabs/WarehouseFormsTab.vue';
import WarehouseOperatingHoursTab from '@/modules/warehouses/components/detail_tabs/WarehouseOperatingHoursTab';
import WarehouseLoadTypesTab from '@/modules/warehouses/components/detail_tabs/WarehouseLoadTypesTab';
import WarehouseSettingsTab from '@/modules/warehouses/components/detail_tabs/WarehouseSettingsTab';

import renderMixin from '@satellite/components/mixins/renderMixin';

/**
 * Warehouse Details Page
 * @displayName Warehouse Details Page
 */
export default {
  mixins: [renderMixin],
  data() {
    return {
      tab: null,
      warehouse: {},
      tabs: [
        {
          route: 'warehouses.details.general',
          title: 'Warehouse Info',
          component: WarehouseDetailsTab,
          name: 'info'
        },
        {
          route: 'warehouses.details.operatingHours',
          title: 'Operating Hours',
          component: WarehouseOperatingHoursTab,
          name: 'ophours'
        },
        {
          route: 'warehouses.details.loadTypes',
          title: 'Load Types',
          component: WarehouseLoadTypesTab,
          name: 'loadTypes'
        },
        {
          route: 'warehouses.details.docks',
          title: 'Docks',
          component: WarehouseDocksTab,
          name: 'docks'
        },
        {
          route: 'warehouses.details.forms',
          title: 'Fields & Forms',
          component: WarehouseFormsTab,
          name: 'fields'
        },
        {
          route: 'warehouses.details.settings',
          title: 'Settings',
          component: WarehouseSettingsTab,
          name: 'settings',
          showNewFeatureChip: true
        }
      ]
    };
  },
  props: {
    warehouseId: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * Get tab notifications
     * @public
     * @param tab
     * @returns {string|boolean}
     */
    getTabNotification(tab) {
      switch (tab.title) {
        case 'Docks':
          if (!this.warehouse.docks || !this.warehouse.docks.length) {
            return 'This warehouse does not have any configured docks.';
          }
          break;
        case 'Operating Hours':
          if (!this.warehouse.schedule || !Object.keys(this.warehouse.schedule).length) {
            return 'This warehouse does not have a schedule configured.';
          }
          break;
        default:
          return false;
      }
    },
    isTabDisabled(tab) {
      const tabsThatNeedsSchedule = ['docks', 'fields'];

      return tabsThatNeedsSchedule.includes(tab.name) && !this.$hasSchedule;
    },
    /**
     * Get Warehouse
     * @public
     */
    getWarehouse() {
      this.$store
        .dispatch('Warehouses/getWarehouseById', {
          id: this.warehouseId,
          includeHierarchySettings: false
        })
        .then(warehouse => {
          if (warehouse) {
            if (warehouse.settings) {
              // Invert because we are displaying as "Notifications ENABLED" instead of "DISABLED"
              warehouse.settings.muteAppointmentNotifications =
                !warehouse.settings.muteAppointmentNotifications;
            }

            this.warehouse = warehouse;
            this.$store.commit('navigation/setPageTitle', `Warehouse: ${warehouse.name}`);
          }
        });
    },
    handleSocketEvent($event) {
      if ($event?.id === this.warehouse.id) {
        this.getWarehouse();
      }
    }
  },
  mounted() {
    this.getWarehouse();
    this.$eventHub.$on(`update-Warehouse`, this.handleSocketEvent);
  },
  beforeDestroy() {
    this.$eventHub.$off(`update-Warehouse`, this.handleSocketEvent);
  },
  watch: {
    tab(oldTab, newTab) {
      if (oldTab !== newTab) {
        this.renderKey++;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.active-tab {
  background-color: #00576c;
}
</style>
