<template>
  <v-card :width="width">
    <h5 class="text--disabled mb-3 pt-3 pl-4">NEXT {{ country }} FEDERAL HOLIDAYS</h5>

    <v-divider></v-divider>

    <div class="p-2">
      <v-list flat class="py-0">
        <v-list-item-group class="p-2">
          <template v-for="(holiday, index) in currentHolidaysPage">
            <section :key="holiday.date">
              <v-list-item class="py-2">
                <v-row class="p-2">
                  <v-col cols="8">
                    <p class="font-size-small mb-0">
                      <strong>{{ holiday.name }}</strong>
                    </p>
                    <p class="font-size-x-small mb-0">
                      {{ formatDate(holiday.date) }}
                    </p>
                  </v-col>
                  <v-col cols="4" class="text-right" :style="{ 'line-height': '35px' }">
                    <v-btn
                      x-small
                      text
                      @click="$emit('input', holiday.date)"
                      v-if="!isDateSelected(holiday.date)">
                      <v-icon small>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                    <span v-else>
                      <small>Added</small>
                    </span>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider></v-divider>
            </section>
          </template>
        </v-list-item-group>
      </v-list>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small text icon @click="currentPage--" :disabled="currentPage <= 0">
        <v-icon small>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn small text icon @click="currentPage++" :disabled="currentPage >= maxPages">
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      currentPage: 0,
      pageSize: 5,
      year: new Date().getFullYear(),
      holidays: []
    };
  },
  props: {
    country: {
      type: String,
      required: true
    },
    addedDates: {
      type: Array,
      required: true,
      default: () => []
    },
    width: {
      type: Number,
      required: false,
      default: 280
    },
    yearsAhead: {
      type: Number,
      required: false,
      default: 3
    }
  },
  methods: {
    formatDate(date) {
      return momentjs(date).format(this.novaCore.DateTimeFormats.ShortDayShortMonthFullYear);
    },
    getHolidays() {
      let currentYear = this.year;

      // Remove past holidays based on the current day
      const dateToNextYearHolidays = this.novaCore
        .getYearHolidaysForCountry(this.country, currentYear)
        .filter(h => h.date && momentjs(new Date(h.date)).isSameOrAfter(new Date()));

      // Get All next years holidays based on the yearsAhead param
      const nextYears = Array.from({ length: this.yearsAhead }, () => {
        currentYear++;
        return this.novaCore.getYearHolidaysForCountry(this.country, currentYear);
      }).flat();

      return [...dateToNextYearHolidays, ...nextYears];
    },
    isDateSelected(date) {
      return this.addedDates.find(d => d?.startsWith(date));
    }
  },
  computed: {
    totalHolidays() {
      return this.holidays.length;
    },
    maxPages() {
      return Math.ceil(this.totalHolidays / this.pageSize) - 1;
    },
    currentHolidaysPage() {
      const start = this.currentPage * this.pageSize;
      const end = start + this.pageSize;

      return this.holidays.slice(start, end);
    }
  },
  mounted() {
    this.holidays = this.getHolidays();
  }
};
</script>
