import settingsMixinBase from '@satellite/components/mixins/settingsMixinBase';

/**
 * Settings Mixin
 * @mixin
 * @displayName Settings Mixin
 */
export default {
  computed: {
    ...settingsMixinBase.computed
  },
  methods: {
    ...settingsMixinBase.methods,
    /**
     * Add Subspace Event Logging Listener
     * Prints event to console
     * @public
     */
    addSubspaceLoggingEventListener() {
      this.$eventHub.$on('subspace-event', data => {
        console.log(
          `%c SUBSPACE EVENT ----- ${data.event.action} ${data.event.entity}`,
          'background: #FFEA61; color: #000',
          data.payload
        );
      });
    },
    /**
     * Remove Subspace Event Logging Listener
     */
    removeSubspaceLoggingEventListener() {
      this.$eventHub.$off('subspace-event');
    }
  }
};
