<template>
  <v-list :id="elementId" v-bind="$attrs">
    <p v-for="{ label, value, id, lastChangedDateTime } of customFormData" :key="id" class="mb-1">
      <strong class="mr-2">{{ label }}:</strong>
      <span :inner-html.prop="value"></span>
      <span class="text--disabled ml-2" v-if="shouldShowDateOnItem">
        ({{ lastChangedDateTime }})
      </span>
    </p>
  </v-list>
</template>

<script>
export default {
  props: {
    customFormData: {
      type: Array,
      required: true
    },
    triggerId: {
      type: String,
      required: true
    }
  },
  computed: {
    hasCustomFormData() {
      return this.customFormData?.length > 0;
    },
    shouldShowDateOnItem() {
      return (
        this.hasCustomFormData &&
        new Set(this.customFormData.map(d => d.formField?.id)).size !== this.customFormData.length
      );
    },
    elementId() {
      return `custom_forms_element_${this.triggerId}`;
    }
  }
};
</script>
