<template>
  <yard-view-asset-visit-detail-data
    @assign="handleReassignSpotSelection"
    :asset-visit="spotAssignment.assetVisit" />
</template>
<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { useEventHub, useSockets } from '@/composables';

import YardViewAssetVisitDetailData from '@/modules/yard/components/AssetVisit/Detail/YardViewAssetVisitDetailData.vue';

export default defineComponent({
  name: 'YardViewSpotDetailAssigned',
  components: { YardViewAssetVisitDetailData },
  props: {
    spotAssignment: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();

    const handleReassignSpotSelection = async spotId => {
      const newSpot = yardStore.spotsRef.value.find(spot => spot.id === spotId);

      if (newSpot?.id) {
        if (!yardStore.isSpotOpen(newSpot)) {
          return notify('The selected spot is not available for reassignment', 'error');
        }
        return yardStore
          .reassignSpotAssignment(props.spotAssignment.id, {
            spotId: newSpot.id
          })
          .then(spotAssignment => {
            eventHub.$emit('spot-assignment-created', spotAssignment);
            yardStore.selectedSpotRef.value = newSpot;
          });
      }
    };

    const assetVisitEvents = computed(() => {
      return props.spotAssignment.assetVisit.assetVisitEvents || [];
    });

    const sockets = useSockets();
    const eventHub = useEventHub();

    onBeforeMount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$on(`${operation}-Appointment`, yardStore.handleAppointmentSubspaceEvent);
        eventHub.$on(`${operation}-AssetVisitEvent`, yardStore.handlerAssetVisitEventSubspaceEvent);
      });
    });

    onBeforeUnmount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$off(`${operation}-Appointment`, yardStore.handleAppointmentSubspaceEvent);
        eventHub.$off(
          `${operation}-AssetVisitEvent`,
          yardStore.handlerAssetVisitEventSubspaceEvent
        );
      });
    });

    return {
      handleReassignSpotSelection,
      assetVisitEvents
    };
  }
});
</script>
