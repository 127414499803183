import { isUUID } from 'class-validator';

// initial state
const state = {
  warehouseGroups: [],
  pagination: {},
  filters: {}
};

// getters
const getters = {};

// actions
const actions = {
  async getWarehouseGroupById({}, params) {
    if (typeof params === 'string' && isUUID(params)) {
      params = { id: params };
    }

    return this.$app.services.warehouseGroup.getWarehouseGroupById(params.id, params);
  },
  async getWarehouseGroups({ commit, state }, options) {
    const warehouseGroupResponse = await this.$app.services.warehouseGroup.getListWarehouseGroups(
      options?.filters || state.filters,
      { includeMetaData: true }
    );

    if (warehouseGroupResponse?.data?.data) {
      commit('setApiResponseData', warehouseGroupResponse.data);
      commit('setFilters', options?.filters || state.filters);
    }

    return { warehouses: state.warehouses };
  }
};

// mutations
const mutations = {
  setApiResponseData(state, response) {
    state.warehouseGroups = response.data;
    state.pagination = {
      page: response.page,
      pageCount: response.pageCount,
      total: response.total
    };
  },
  setFilters(state, filters) {
    state.filters = filters;
  }
};

export default {
  namespaced: true,
  name: 'WarehouseGroups',
  state,
  getters,
  actions,
  mutations
};
