var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.selectedAssetVisit?.id
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { attrs: { elevation: "0" } },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex flex-row card-header" },
                [
                  _c(
                    "h4",
                    {
                      attrs: { "data-testid": "yard-asset-visit-detail-title" },
                    },
                    [_vm._v("Not Assigned")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        "data-testid": "yard-asset-visit-detail-close-button",
                      },
                      on: { click: _vm.closeDetail },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "card-content",
                  attrs: { "data-testid": "yard-asset-visit-detail-content" },
                },
                [
                  _c("yard-view-asset-visit-detail-data", {
                    attrs: { "asset-visit": _vm.selectedAssetVisit },
                    on: { assign: _vm.handleAssignSpotSelection },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass: "card-actions",
                  attrs: { "data-testid": "yard-asset-visit-detail-actions" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column full-width" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-2 primary",
                          attrs: { disabled: "", block: "" },
                        },
                        [_vm._v("Truck Departed")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-4",
                          attrs: { outlined: "", block: "" },
                          on: {
                            click: function ($event) {
                              _vm.isAssetVisitChatVisible = true
                            },
                          },
                        },
                        [_vm._v(" Details & Chat ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }