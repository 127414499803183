<template>
  <div>
    <h3 class="my-6">
      <v-btn icon small rounded @click="back">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Appointment Fields
    </h3>

    <custom-field-list
      :read-only="$isWarehouseReadOnly(warehouse.id, warehouse.orgId)"
      show-clone-button
      :warehouse="warehouse"
      :field-template="warehouse.customApptFieldsTemplate || []"
      @save="handleTemplateChange"></custom-field-list>
  </div>
</template>

<script>
export default {
  props: {
    warehouse: {
      type: Object,
      required: true
    }
  },
  methods: {
    back() {
      this.$emit('back');
    },
    async handleTemplateChange(newTemplate, callback, operation, field) {
      const data = {
        id: this.warehouse.id,
        customApptFieldsTemplate: newTemplate
      };
      await this.$store
        .dispatch('Warehouses/updateWarehouse', data)
        .then(response => {
          if (response?.data?.data) {
            callback(true, operation, field);
            this.$eventHub.$emit('update-Warehouse', response.data.data);
            this.$emit('close');
          }
        })
        .catch(() => {
          callback(false, operation, field);
        })
        .finally(() => {
          if (operation === 'create') {
            this.mixpanel.track(this.mixpanel.events.MODULE.APPOINTMENT.APPOINTMENT_FIELD_CREATED, {
              'Org Name': this.$org.name,
              'Org ID': this.$org.id,
              'Warehouse Name': this.warehouse.name,
              'Warehouse ID': this.warehouse.id,
              'Appointment Field Name': field.label,
              'Entry Point': 'Appointment Fields Page'
            });
          }
        });
    }
  }
};
</script>
