<template>
  <v-container class="pa-0 pt-5" id="custom-tags-form">
    <template v-if="!readOnly">
      <p>
        Create a list of default appointment tags to select from. You can also enable notifications
        for when a tag is added to an appointment (<span class="caption"
          >{{ maxTagsPerOrg }} options maximum</span
        >)
      </p>

      <v-form
        v-on:submit.prevent
        @keyup.native.enter.stop
        @keyup.native.enter="addTag"
        ref="customTagsForm">
        <v-row>
          <v-col cols="8">
            <v-text-field
              :disabled="readOnly"
              v-model="newTag.name"
              hide-details
              label="Type tag label...">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <template v-if="!readOnly">
              <label class="v-label v-label--active theme--light">
                <small>Tag Color</small>
              </label>
              <color-picker
                v-model="newTag.color"
                :iconStyles="{ height: '25px', width: '25px' }"
                :text-color="getTextColor(newTag.color)"
                :header-text="newTag.name || 'Custom Tag Color'"
                :swatches="swatches">
              </color-picker>
            </template>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <primary-button class="mt-4" :disabled="!newTag.name" @click="addTag">
              Add
            </primary-button>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <div :class="{ 'mt-8': !readOnly }">
      <div v-if="filteredTags.length" :class="{ 'd-flex flex-row': readOnly }">
        <div
          v-for="tag in filteredTags"
          :key="tag.text"
          class="d-flex tag-row align-center"
          :class="{ 'pr-2': !readOnly }">
          <v-chip
            v-if="editingTag.name !== tag.name || readOnly"
            :class="{ 'ma-2': !readOnly, 'mr-1': readOnly }"
            :color="tag.color"
            :text-color="tag.textColor"
            small
            :disabled="areTagActionsDisabled(tag)">
            <span class="font-weight-medium">{{ tag.name }}</span>
          </v-chip>
          <template v-if="!readOnly">
            <v-text-field
              v-if="editingTag.name === tag.name"
              v-model="editingTag.name"
              solo
              dense
              flat
              hide-details
              class="tag-editing-text-field mt-0"
              :ref="`tagNameField-${tag.name}`"></v-text-field>

            <v-spacer></v-spacer>

            <icon-tooltip-button
              :tooltip="makeNotificationIconTooltipText(tag)"
              size="large"
              :disabled="areTagActionsDisabled(tag) || readOnly || areTaggedNotificationsDisabled()"
              v-if="editingTag.name !== tag.name"
              icon-class="mr-2 custom-tag-toggle"
              @click="toggleTagNotification(tag)"
              :color="tag.notificationEnabled ? '' : 'red'"
              :icon="
                tag.notificationEnabled && !areTaggedNotificationsDisabled()
                  ? 'bell-ring-outline'
                  : 'bell-off-outline'
              ">
            </icon-tooltip-button>

            <v-btn
              x-small
              text
              class="mr-1"
              v-if="editingTag.name === tag.name"
              @click="editingTag = {}">
              Nevermind
            </v-btn>
            <v-btn x-small text class="mr-1" @click="updateTag" v-if="editingTag.name === tag.name">
              Save
            </v-btn>

            <color-picker
              title="Tag color"
              v-model="editingTag.color"
              v-if="editingTag.name === tag.name"
              :text-color="getTextColor(editingTag.color)"
              :header-text="editingTag.name || 'Custom Tag Color'"
              :icon-styles="{ marginRight: '10px', width: '15px', height: '15px' }"
              icon-height="15px"
              icon-width="15px"
              :swatches="swatches">
            </color-picker>

            <icon-tooltip-button
              v-if="editingTag.name === tag.name"
              tooltip="Delete Tag"
              size="large"
              color="red"
              @click="deleteTag(tag)"
              icon="mdi-delete-outline">
            </icon-tooltip-button>
            <icon-tooltip-button
              v-else
              :tooltip="readOnly ? novaCore.getRoleActionError() : 'Edit Tag'"
              size="large"
              :disabled="areTagActionsDisabled(tag) || readOnly"
              @click="setEditingTag(tag)"
              icon="mdi-pencil">
            </icon-tooltip-button>
          </template>
        </div>
        <v-btn
          ref="showMoreButton"
          class="float-right mt-3"
          dense
          solo
          @click="showMoreTags"
          v-if="isShowMoreVisible">
          Show more...
        </v-btn>
      </div>

      <div v-else-if="Boolean(newTag?.name)" class="py-8 text-center text--disabled">
        No existing tags with this name
      </div>
      <div v-else class="py-8 text-center text--disabled">No default tags added</div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      customTags: [],
      newTag: { color: '#D1C4E9' },
      initialColor: '#D1C4E9',
      editingTag: {},
      pagination: {
        currentPageSize: 10,
        tagsPerPage: 10
      },
      swatches: [
        ['#CFD8DC'],
        ['#C8E6C9'],
        ['#FFECB3'],
        ['#B2EBF2'],
        ['#BBDEFB'],
        ['#D1C4E9'],
        ['#FFCCBC'],
        ['#FFCDD2'],
        ['#D7CCC8']
      ]
    };
  },
  computed: {
    maxTagsPerOrg() {
      return this.novaCore.MAX_CUSTOM_TAGS_PER_ORG;
    },
    isShowMoreVisible() {
      return (
        Boolean(this.filteredTags) &&
        !this.newTag?.name &&
        this.customTags?.length !== this.filteredTags.length
      );
    },
    filteredTags() {
      return (this.customTags || [])
        .filter(t =>
          this.newTag?.name?.length > 0
            ? String(t.name)
                .toLocaleUpperCase()
                .includes(String(this.newTag.name).toLocaleUpperCase())
            : true
        )
        .slice(0, this.pagination.currentPageSize);
    }
  },
  methods: {
    async showMoreTags() {
      this.pagination.currentPageSize += this.pagination.tagsPerPage;
      setTimeout(() => this.$refs.showMoreButton?.$el?.scrollIntoView({ behavior: 'smooth' }), 200);
    },
    addTag() {
      if (!this.$refs.customTagsForm.validate() || !this.newTag.name) {
        return;
      }

      this.saveTags('Tag Created Successfully!', true);
    },
    setEditingTag(tag) {
      this.editingTag = tag;

      this.$nextTick(() => {
        this.$refs[`tagNameField-${tag.name}`][0].focus();
      });
    },
    areTagActionsDisabled(tag) {
      return this.editingTag?.name && this.editingTag.name !== tag.name;
    },
    getTextColor(value) {
      if (!value) {
        return '#FFFFFF';
      }

      const hex = value.replace('#', '');
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? '#1E3036' : '#FFFFFF';
    },
    deleteTag(tag) {
      this.$confirm(
        `Are you sure you want to delete the "${tag.name}" tag?<br />
        Appointments with this tag will keep the tag, but it will no longer have custom colors.
        `,
        { color: 'warning' }
      ).then(confirmed => {
        if (confirmed) {
          this.novaCore.removeArrayItem(this.customTags, tag);
          this.editingTag = {};
          this.saveTags('Tag Deleted Successfully!');
        }
      });
    },
    async updateTag() {
      this.editingTag.textColor = this.getTextColor(this.editingTag.color);
      await this.saveTags('Tag Updated Successfully!');
      this.editingTag = {};
    },
    async saveTags(notification, newTag = false) {
      const data = this.novaCore.deepClone(this.customTags);

      if (newTag) {
        data.unshift({
          name: this.newTag.name,
          color: this.newTag.color,
          textColor: this.getTextColor(this.newTag.color)
        });
      }

      await axios
        .patch(`/org/${this.$org.id}/custom-tags`, {
          customTags: data
        })
        .then(() => {
          this.notify(notification);

          if (newTag) {
            this.newTag = { color: this.initialColor };
          }
        })
        .finally(async () => {
          await this.$store.dispatch('Orgs/getOrg', this.$org.id);
          this.customTags = this.novaCore.deepClone(this.$org.customTags) || [];
        });
    },
    areTaggedNotificationsDisabled() {
      const disabledNotifications = this.$getSettingValue(
        'mutedAppointmentNotifications',
        this.$org?.settings
      );
      const areAllNotificationsDisabledForOrg = this.$getSettingValue(
        'muteAppointmentNotifications',
        this.$org?.settings
      );
      return (
        (disabledNotifications?.length &&
          disabledNotifications.includes(this.novaCore.NotificationType.APPOINTMENT_TAGGED)) ||
        areAllNotificationsDisabledForOrg
      );
    },
    makeNotificationIconTooltipText(tag) {
      if (this.areTaggedNotificationsDisabled()) {
        return 'To use this feature please enable the "Appointment tagged" notification';
      }

      if (!tag.notificationEnabled) {
        return 'Enable notifications';
      } else {
        return 'Disable notifications';
      }
    },
    toggleTagNotification(tag) {
      if (typeof tag.notificationEnabled === 'undefined') {
        tag.notificationEnabled = true;
      } else {
        tag.notificationEnabled = !tag.notificationEnabled;
      }
      const verb = tag.notificationEnabled ? 'enabled' : 'disabled';
      this.saveTags(`Notification for ${tag.name} ${verb}!`);
    }
  },
  mounted() {
    this.customTags = _.orderBy(this.novaCore.deepClone(this.$org.customTags) || [], ['name']);
  }
};
</script>

<style scoped lang="scss">
#custom-tags-form .v-text-field.tag-editing-text-field {
  margin-top: 0 !important;

  .v-input__control input {
    caret-color: inherit !important;
  }
}
div .tag-row:hover {
  background-color: #efefef;
}
</style>
