<template>
  <v-file-input
    ref="fileinput"
    class="doc-upload-component"
    placeholder="Upload Documents"
    :required="required"
    type="file"
    single-line
    hide-input
    multiple
    hide-details="auto"
    prepend-icon=""
    :value="computedValue"
    @change="onFilesChanged"
    v-on="$listeners"
    v-bind="[$props, $attrs]">
    <template v-slot:append-outer>
      <outline-button
        v-if="!fileKeys?.length"
        :loading="isSelectingFiles"
        @click="fileUploadClick"
        before-icon="cloud-upload">
        Upload Files
        <span v-if="required" class="red--text ml-1"><strong>*</strong></span>
      </outline-button>
      <div v-else class="multi-doc-container">
        <v-btn
          :loading="isSelectingFiles"
          @click="fileUploadClick"
          class="multi-doc-upload-button"
          v-for="(fileKey, i) in fileKeys"
          :key="fileKey">
          <v-img
            v-if="isImageFile(fileKey)"
            contain
            height="100"
            width="100"
            :src="fileUrls[i]"></v-img>
          <v-icon v-else size="100" color="secondary">mdi-file</v-icon>
          <v-btn
            icon
            x-small
            class="doc-close-button"
            :class="{ 'is-image': isImageFile(fileKey) }"
            @click.stop="deleteDoc(fileKey)">
            <v-icon x-small>mdi-close</v-icon>
          </v-btn>
          <div class="font-italic text-subtitle-2">{{ getFileName(fileKey) }}</div>
        </v-btn>
      </div>
      <span class="errors v-messages mt-2">{{ fieldError }}</span>
    </template>
  </v-file-input>
</template>

<script>
export default {
  props: {
    /**
     * v-model
     */
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Field is required
     */
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    /**
     * Get the first error from bucket if exists
     * @returns {boolean}
     */
    fieldError() {
      let error = false;
      if (this.mounted) {
        error = this.$refs.fileinput.errorBucket.length ? this.$refs.fileinput.errorBucket[0] : '';
      }

      return error;
    },
    computedValue() {
      // This sucks, but it is a workaround for us having zero control over the vuetify prop definition.
      // The Vuetify component expects a list of Files as a value. We don't have files, we have strings (remote
      // file keys). So what? We need to create dummy files so that the Form Validation knows there is something,
      // as the value can't be the string array. Let's consider moving away from Vuetify v-file-input and create our
      // own component to get rid of this.
      return this.fileKeys?.length ? this.fileKeys.map(() => new File([], null)) : null;
    }
  },
  data() {
    return {
      mounted: false,
      isSelectingFiles: false,
      fileUrls: [],
      fileKeys: []
    };
  },
  methods: {
    getFileName(fileKey) {
      return this.novaCore.getDocumentNameFromUrl(fileKey);
    },
    /**
     * Determines if file is image for thumbnail display purposes
     * @returns {*|boolean}
     */
    isImageFile(fileKey) {
      return this.novaCore.isImageUrl(fileKey);
    },
    /**
     * Handles file upload button click
     * @public
     */
    fileUploadClick() {
      window.addEventListener(
        'focus',
        () => {
          this.isSelectingFiles = false;
        },
        { once: true }
      );

      this.$refs.fileinput.$refs.input.click();
    },
    /**
     * Handles file selection change
     * @public
     * @returns {Promise<void>}
     */
    async onFilesChanged(newFiles) {
      const remainingSlots =
        this.novaCore.MULTI_DOC_MAX_FILES_PER_APPOINTMENT - this.fileKeys.length;

      if (newFiles.length > remainingSlots) {
        this.notify(
          `Maximum of ${this.novaCore.MULTI_DOC_MAX_FILES_PER_APPOINTMENT} files allowed`,
          'error'
        );

        return;
      }

      this.$emit('uploading');
      this.isSelectingFiles = true;
      if (newFiles.length > 0) {
        const promises = newFiles.map(selectedFile => {
          let formData = new FormData();
          formData.append('file', selectedFile);
          return axios.post('/storage', formData);
        });

        try {
          const responses = await Promise.all(promises);
          responses.forEach(response => {
            this.fileUrls.push(response.data.url);
            this.fileKeys.push(response.data.key);
          });
        } finally {
          this.isSelectingFiles = false;
        }
      }

      this.$emit('input', this.fileKeys);
      this.isSelectingFiles = false;
      this.$emit('uploaded');
    },
    deleteDoc(fileKey) {
      this.fileUrls = this.fileUrls.filter(url => !url.endsWith(fileKey));
      this.fileKeys = this.fileKeys.filter(key => key !== fileKey);
      this.$emit('input', this.fileKeys);
    }
  },
  mounted() {
    this.fileUrls = Array.isArray(this.value) ? this.value : [];

    // Set default value for the v-model
    if (this.fileUrls?.length) {
      this.fileKeys = this.fileUrls.map(value => {
        try {
          return new URL(value).pathname.slice(1);
        } catch {
          return value;
        }
      });
    }

    this.mounted = true;

    this.$emit('input', this.fileKeys);
  }
};
</script>
