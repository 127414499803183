<template>
  <div
    class="margin-x-auto d-inline-block"
    :class="{ 'eta-button-group': shouldShowEtaValue || enableEdit }">
    <div v-if="shouldShowEtaValue">
      <div class="align-center d-flex font-size-small">
        <label class="mr-3">
          <v-chip
            v-if="etaTag"
            small
            :color="getTagElMeta(etaTag).color"
            :class="getTagElMeta(etaTag).classes"
            class="mr-2">
            <span class="font-size-x-small">{{ etaTag }}</span>
          </v-chip>
          <strong>New ETA:</strong>
          <span class="font-weight-light d-inline-block ml-2">{{ formattedEta }}</span>
        </label>
        <v-btn icon @click="editEta" v-if="enableEdit">
          <v-icon small>mdi-pencil-outline</v-icon>
        </v-btn>
      </div>

      <set-eta-dialog
        @condition-updated="updateCondition"
        @close="shouldShowEtaDialog = false"
        :party-to-alert="partyToAlert"
        :external-activator="true"
        :etaCondition="value"
        :show-dialog="shouldShowEtaDialog"
        :appointment="appointment"></set-eta-dialog>
    </div>
    <div v-else>
      <div class="align-center d-flex" v-if="enableEdit">
        <v-icon small class="mr-3">mdi-alert</v-icon>
        <label class="is-uppercase mr-3 font-size-small">{{ label }}</label>
        <text-button
          text
          small
          before-icon="clock-fast"
          color="secondary"
          class="mr-2"
          @click="handleEtaButtonClick(novaCore.EtaCondition.Early)">
          <span class="d-block button-label">Arriving {{ novaCore.EtaCondition.Early }}</span>
        </text-button>
        <text-button
          text
          small
          before-icon="clock-alert-outline"
          color="error"
          @click="handleEtaButtonClick(novaCore.EtaCondition.Late)">
          <span class="d-block button-label">Running {{ novaCore.EtaCondition.Late }}</span>
        </text-button>
      </div>

      <set-eta-dialog
        @close="shouldShowEtaDialog = false"
        :external-activator="true"
        :etaCondition="value"
        :show-dialog="shouldShowEtaDialog"
        :party-to-alert="partyToAlert"
        :appointment="appointment"></set-eta-dialog>
    </div>
  </div>
</template>

<script>
import appointmentMixin from '@/components/mixins/appointmentMixin';
import etaMixin from '@satellite/components/mixins/etaMixin';

export default {
  mixins: [appointmentMixin, etaMixin],
  props: {
    appointment: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: `Alert the Warehouse`
    },
    partyToAlert: {
      type: String,
      required: false,
      default: 'Warehouse',
      validator(value) {
        // The value must match one of these strings
        return ['Warehouse', 'Carrier'].includes(value);
      }
    }
  },
  computed: {
    shouldShowEtaValue() {
      return !!this.appointment.eta;
    },
    tagVerb() {
      return this.novaCore.getEtaVerb(this.value);
    },
    etaTag() {
      return this.getEtaTag(this.appointment.tags);
    },
    enableEdit() {
      return (
        !this.readOnly && this.appointment.status !== this.novaCore.AppointmentStatus.Cancelled
      );
    },
    formattedEta() {
      const tz = this.appointment.dock.warehouse.timezone;
      return this.appointment.eta
        ? this.novaCore.formatDateTimeWithMilitarySupport(
            this.appointment.eta,
            tz,
            this.novaCore.LuxonDateTimeFormats.ShortMonthDayTimeAMPM,
            this.$isMilitaryTimeEnabled(this.appointment.dock.warehouse),
            this.novaCore.LuxonDateTimeFormats.ShortMonthDayTime24
          )
        : '';
    }
  }
};
</script>
