var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "d-flex gap-4 gap-lg-0", attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        {
          staticClass: "d-flex flex-row flex-wrap flex-md-nowrap gap-3",
          attrs: { sm: "12", md: "12", lg: "6" },
        },
        [
          _c("warehouse-select", {
            attrs: {
              "data-testid": "yard-warehouse-select",
              "multi-select": false,
              "show-create-dock-button": false,
              "should-disable-if-no-docks": false,
              "hide-icon": "",
              outlined: "",
              clearable: false,
              "restricted-warehouse-ids": _vm.$me.warehouseAccessList,
              "auto-select-by-id": _vm.defaultWarehouseId,
            },
            model: {
              value: _vm.selectedWarehouse,
              callback: function ($$v) {
                _vm.selectedWarehouse = $$v
              },
              expression: "selectedWarehouse",
            },
          }),
          _vm.isGateManagementEnabled
            ? _c("yard-view-area-select", {
                attrs: { "data-testid": "yard-area-select" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isGateManagementEnabled && !_vm.isReadOnly
        ? _c(
            "v-col",
            {
              staticClass: "d-flex justify-lg-end align-center gap-3",
              attrs: { sm: "12", md: "12", lg: "6" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { "data-testid": "yard-subheader-add-area", text: "" },
                  on: { click: _vm.addSpotArea },
                },
                [
                  _c("v-icon", { attrs: { small: "", left: "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                  _vm._v(" Area "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "menu-container",
                  attrs: { "data-testid": "yard-view-mode-menu" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "internal-activator": "",
                        "close-on-click": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          outlined: "",
                                          disabled: "",
                                          color: "darken-2",
                                          ripple: false,
                                          "data-testid":
                                            "grid-view-mode-menu-activator",
                                          "aria-owns":
                                            "yard-view-view-mode-options",
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          small: "",
                                          "data-testid": "view-mode-activator",
                                          left: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getViewModeMeta().icon) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getViewModeMeta().label) +
                                        " "
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-2",
                                        attrs: { small: "", right: "" },
                                      },
                                      [_vm._v("mdi-chevron-down")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4111689918
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "view-mode-options",
                          attrs: {
                            id: "yard-view-view-mode-options",
                            "data-testid": "view-mode-options",
                          },
                        },
                        _vm._l(_vm.viewModes, function (item, key) {
                          return _c(
                            "v-list-item",
                            {
                              key: key,
                              staticClass: "is-relative z-100",
                              on: {
                                click: function ($event) {
                                  _vm.viewMode = item
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.getViewModeMeta(item).icon)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "typography-button-md" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getViewModeMeta(item).label
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }