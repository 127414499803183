var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-block text-right font-size-xx-small message-footer" },
    [
      _vm.isSystemMessage(_vm.message)
        ? _c(
            "span",
            [
              _c(
                "v-icon",
                { attrs: { color: "orange", size: "large", large: "" } },
                [_vm._v("mdi-information-outline")]
              ),
              _vm._v(" This Message was sent to the driver automatically. "),
            ],
            1
          )
        : _c("span", [
            _vm._v(
              _vm._s(
                _vm.isOutgoingMessage(_vm.message)
                  ? _vm.getFullName(_vm.message.createdByUser)
                  : ""
              )
            ),
          ]),
      _vm.isOutgoingMessage(_vm.message)
        ? _c("span", { staticClass: "dot-divider" }, [_vm._v("•")])
        : _vm._e(),
      _c("span", { staticClass: "message-timestamp" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.makeChatTimestamp(
                _vm.message.createDateTime,
                _vm.warehouse.timezone,
                false
              )
            ) +
            " "
        ),
      ]),
      _vm.shouldShowStatusIcon
        ? _c(
            "span",
            { staticClass: "pl-1 status-icon" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { left: "", color: "black" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onTooltip, attrs }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  { attrs: { color: _vm.statusIcon.color } },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                onTooltip
                              ),
                              [_vm._v(" " + _vm._s(_vm.statusIcon.icon) + " ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3278915204
                  ),
                },
                [
                  _c(
                    "span",
                    { staticStyle: { "text-transform": "capitalize" } },
                    [_vm._v("Status: " + _vm._s(_vm.status))]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }