var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-navigation-drawer" },
    [
      _c(
        "v-list",
        {
          staticClass: "pa-0 mt-3 mobile-blue-bg",
          attrs: { dense: "", nav: "" },
        },
        [
          _vm._l(_vm.navItemsToDisplay, function (item) {
            return _c(
              "section",
              { key: item.link },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "", disabled: !_vm.isItemDisabled(item) },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              !item.children
                                ? _c(
                                    "v-list-item",
                                    _vm._g(
                                      {
                                        staticClass: "white--text",
                                        class: {
                                          "disabled-list-item":
                                            _vm.isItemDisabled(item),
                                        },
                                        attrs: {
                                          link: "",
                                          target: _vm.getLinkAttrs(item).target,
                                          to: _vm.getLinkAttrs(item).to,
                                          href: _vm.getLinkAttrs(item).href,
                                          inactive: _vm.isItemDisabled(item),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleListItemButtonClick(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-list-item-action",
                                        { staticClass: "mr-4" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "white--text",
                                              attrs: {
                                                medium: "",
                                                disabled:
                                                  _vm.isItemDisabled(item),
                                              },
                                            },
                                            [_vm._v(_vm._s(item.icon))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "nav-item-title" },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm.isItemDisabled(item)
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.makeDisabledTooltipMsg(item))),
                        ])
                      : _vm._e(),
                  ]
                ),
                item.children
                  ? _c(
                      "v-list-group",
                      {
                        key: item.link,
                        class: { "disabled-list-item": _vm.isItemDisabled },
                        attrs: {
                          disabled: _vm.isItemDisabled(item),
                          color: "white",
                          value: item.isOpen,
                          "no-action": "",
                          "prepend-icon": item.icon,
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "activator" },
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ],
                          1
                        ),
                        _vm._l(item.children, function (childItem) {
                          return [
                            childItem.children
                              ? _c(
                                  "v-list-group",
                                  {
                                    key: childItem.link,
                                    attrs: {
                                      "no-action": "",
                                      "sub-group": "",
                                      value: childItem.isOpen,
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "activator" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            key: item.link,
                                            attrs: {
                                              top: "",
                                              disabled:
                                                !_vm.isItemDisabled(item),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-list-item",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "nd-second-level",
                                                            attrs: {
                                                              target:
                                                                _vm.getLinkAttrs(
                                                                  childItem
                                                                ).target,
                                                              to: _vm.getLinkAttrs(
                                                                childItem
                                                              ).to,
                                                              href: _vm.getLinkAttrs(
                                                                childItem
                                                              ).href,
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      childItem.title
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.makeDisabledTooltipMsg(
                                                    item
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        key: _vm.grandchild.link,
                                        attrs: {
                                          top: "",
                                          disabled: !_vm.isItemDisabled(item),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return _vm._l(
                                                  childItem.children,
                                                  function (grandchild) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: grandchild.link,
                                                        attrs: {
                                                          link: "",
                                                          target:
                                                            _vm.getLinkAttrs(
                                                              grandchild
                                                            ).target,
                                                          to: _vm.getLinkAttrs(
                                                            grandchild
                                                          ).to,
                                                          href: _vm.getLinkAttrs(
                                                            grandchild
                                                          ).href,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    grandchild.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.makeDisabledTooltipMsg(item)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "v-tooltip",
                              {
                                key: childItem.link,
                                attrs: {
                                  top: "",
                                  disabled: !_vm.isItemDisabled(item),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-list-item",
                                            {
                                              key: childItem.title,
                                              attrs: {
                                                target:
                                                  _vm.getLinkAttrs(childItem)
                                                    .target,
                                                to: _vm.getLinkAttrs(childItem)
                                                  .to,
                                                href: _vm.getLinkAttrs(
                                                  childItem
                                                ).href,
                                              },
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(childItem.title)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.makeDisabledTooltipMsg(item))
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "section",
            [
              _c(
                "v-list-item",
                { staticClass: "white--text", on: { click: _vm.logout } },
                [
                  _c(
                    "v-list-item-action",
                    { staticClass: "mr-4" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "white--text", attrs: { medium: "" } },
                        [_vm._v("mdi-logout")]
                      ),
                    ],
                    1
                  ),
                  _c("v-list-item-title", { staticClass: "nav-item-title" }, [
                    _vm._v("Logout"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-footer",
        {
          staticClass:
            "justify-center text--lighten-1 grey--text text-center d-block mobile-blue-bg",
          attrs: { absolute: "", bottom: "", inset: "" },
        },
        [
          _c(
            "secondary-button",
            {
              staticClass: "mb-4 non-uppercase",
              attrs: { small: "" },
              on: {
                click: function ($event) {
                  return _vm.setRenderMode(
                    _vm.$root.ENUMS.RenderModeEnum.DESKTOP
                  )
                },
              },
            },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                _vm._v("mdi-laptop"),
              ]),
              _vm._v(" View desktop version "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d-flex full-width align-center justify-center mb-8",
            },
            [
              _c("connection-status-indicator"),
              _c("div", { staticClass: "version-number ml-2 is-uppercase" }, [
                _vm._v(
                  " Nova v" +
                    _vm._s(_vm.$neutronVersion.major) +
                    "." +
                    _vm._s(_vm.$neutronVersion.minor) +
                    "." +
                    _vm._s(_vm.$neutronVersion.patch) +
                    " - " +
                    _vm._s(_vm.$neutronVersion.commit) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }