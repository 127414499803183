var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-field-timestamp" }, [
    _c(
      "div",
      { staticClass: "component-item" },
      [
        _c("date-picker", {
          attrs: {
            placeholder: "Select date",
            "compact-display": "",
            "display-field-label": false,
            "display-field-icon": false,
            required: _vm.field.required,
          },
          model: {
            value: _vm.customDate,
            callback: function ($$v) {
              _vm.customDate = $$v
            },
            expression: "customDate",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "component-item" },
      [
        _c("time-entry", {
          attrs: {
            date: _vm.dateTime,
            warehouse: _vm.warehouse,
            fontSizeLarge: false,
            required: _vm.field.required,
            compact: _vm.isMobile(),
            inline: "",
          },
          model: {
            value: _vm.customTime,
            callback: function ($$v) {
              _vm.customTime = $$v
            },
            expression: "customTime",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }