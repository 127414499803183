var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "tile-data-block direction-indicator" },
    [
      _vm.props.direction === "OUT"
        ? _c(
            "v-icon",
            { staticClass: "direction-arrow", attrs: { "x-small": "" } },
            [_vm._v("mdi-arrow-left")]
          )
        : _vm._e(),
      _vm._v(_vm._s(_vm.props.direction)),
      _vm.props.direction === "IN"
        ? _c(
            "v-icon",
            { staticClass: "direction-arrow", attrs: { "x-small": "" } },
            [_vm._v("mdi-arrow-right")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }