<template>
  <div>
    <div>
      Keep records of damages, security measures and other evidence to support claim disputes.
    </div>
    <div class="disclaimer font-size-x-small">
      Customize your forms at the warehouse
      <router-link
        :to="{
          name: 'warehouses.details.forms',
          params: { warehouseId: appointment.dock.warehouseId }
        }">
        Fields and Forms
      </router-link>
      section.
    </div>

    <custom-forms-data-panels
      class="mt-4"
      :object-ids="{ appointmentId: appointment.id }"
      :appointment="appointment"
      :triggers="claimSupportTriggers"
      :warehouse="appointment.warehouse"
      :timezone="warehouse.timezone"
      :military-time-enabled="$isMilitaryTimeEnabled(warehouse)" />

    <v-alert class="mt-4" border="left" colored-border color="warning" v-if="!hasActiveTriggers">
      No Claims Support forms have been enabled.
    </v-alert>
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';

export default {
  extends: AppointmentDetailsBase,
  computed: {
    hasActiveTriggers() {
      return this.claimSupportTriggers.filter(trigger => trigger.isActive).length > 0;
    }
  }
};
</script>

<style scoped lang="scss">
.icon {
  order: 0;
}

.header-content {
  order: 1;
}
</style>
