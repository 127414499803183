import InsightsPage from '@/modules/insights/InsightsPage';
import store from '@/store';

export default [
  {
    path: '/insights',
    name: 'insights',
    component: InsightsPage,
    props: true,
    meta: {
      title: 'Insights',
      requiresAuth: true
    },
    // This might be better suited in the main routes file
    // But that file does not have the context it needs to do this
    // This will make sure that any internal users that were redirected from the home page "/"
    // Will be taken to the internalHome page instead of this page (insights).
    beforeEnter: async (to, from, next) => {
      if (store.$app?.novaCore.isInternalUser(store.$app?.$me)) {
        next({ name: 'internalHome' });
        return;
      }

      next();
    }
  },
  {
    path: '/insights/carrier',
    name: 'insights.carrier',
    component: InsightsPage,
    props: true,
    meta: {
      title: 'Carrier Insights',
      requiresAuth: true
    },
    beforeEnter: async (to, from, next) => {
      if (store.$app?.novaCore.isInternalUser(store.$app?.$me)) {
        next({ name: 'insights' });
        return;
      }

      next();
    }
  },
  {
    path: '/insights/warehouse',
    name: 'insights.warehouse',
    component: InsightsPage,
    props: true,
    meta: {
      title: 'Warehouse Insights',
      requiresAuth: true
    },
    beforeEnter: async (to, from, next) => {
      if (store.$app?.novaCore.isInternalUser(store.$app?.$me)) {
        next({ name: 'insights' });
        return;
      }

      next();
    }
  },
  {
    path: '/insights/reporting',
    name: 'insights.reporting',
    component: InsightsPage,
    props: true,
    meta: {
      title: 'Reporting',
      requiresAuth: true
    },
    beforeEnter: async (to, from, next) => {
      if (store.$app?.novaCore.isInternalUser(store.$app?.$me)) {
        next({ name: 'insights' });
        return;
      }

      next();
    }
  }
];
