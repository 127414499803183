var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-container",
        [
          _c("text-field", {
            attrs: {
              required: true,
              type: "text",
              rules: _vm.$validator.rules.required("Field Label"),
              label: "Field Label",
            },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.stopPropagation()
              },
            },
            model: {
              value: _vm.localField.label,
              callback: function ($$v) {
                _vm.$set(_vm.localField, "label", $$v)
              },
              expression: "localField.label",
            },
          }),
          _c("v-text-field", {
            attrs: { type: "text", label: "Description" },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.stopPropagation()
              },
            },
            model: {
              value: _vm.localField.description,
              callback: function ($$v) {
                _vm.$set(_vm.localField, "description", $$v)
              },
              expression: "localField.description",
            },
          }),
          !_vm.typesWithoutPlaceholder.includes(_vm.localField.type)
            ? _c("v-text-field", {
                attrs: { type: "text", label: "Example Value" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.localField.placeholder,
                  callback: function ($$v) {
                    _vm.$set(_vm.localField, "placeholder", $$v)
                  },
                  expression: "localField.placeholder",
                },
              })
            : _vm._e(),
          _c("v-select", {
            staticClass: "document-type-select",
            attrs: {
              items: _vm.fieldTypeOptions,
              rules: _vm.$validator.rules.required("Field Type"),
              label: "Field Type",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item }) {
                  return [_c("span", { domProps: { innerHTML: item.text } })]
                },
              },
              {
                key: "selection",
                fn: function ({ item }) {
                  return [_c("span", { domProps: { innerHTML: item.text } })]
                },
              },
            ]),
            model: {
              value: _vm.localField.type,
              callback: function ($$v) {
                _vm.$set(_vm.localField, "type", $$v)
              },
              expression: "localField.type",
            },
          }),
          _vm.isDocField
            ? _c("span", { staticClass: "fieldNotes" }, [
                _vm._v(" Maximum "),
                _c("strong", [_vm._v("50MB")]),
                _vm._v(" in size. Only "),
                _c("strong", [_vm._v("ONE image")]),
                _vm._v(" , "),
                _c("strong", [_vm._v("text")]),
                _vm._v(" , "),
                _c("strong", [_vm._v("zip")]),
                _vm._v(" or "),
                _c("strong", [_vm._v("spreadsheet")]),
                _vm._v(
                  " allowed. To upload multiple docs, zip first then upload. "
                ),
              ])
            : _vm.isMultiDocField
            ? _c("span", { staticClass: "fieldNotes" }, [
                _vm._v(" Maximum "),
                _c("strong", [_vm._v("50MB")]),
                _vm._v(" in size per file. Maximum 10 files. Only "),
                _c("strong", [_vm._v("image")]),
                _vm._v(" , "),
                _c("strong", [_vm._v("text")]),
                _vm._v(" , "),
                _c("strong", [_vm._v("zip")]),
                _vm._v(" or "),
                _c("strong", [_vm._v("spreadsheet")]),
                _vm._v(" allowed. "),
              ])
            : _vm._e(),
          _vm.hasDropDownValues
            ? _c("custom-field-drop-down-choices-form", {
                model: {
                  value: _vm.localField.dropDownValues,
                  callback: function ($$v) {
                    _vm.$set(_vm.localField, "dropDownValues", $$v)
                  },
                  expression: "localField.dropDownValues",
                },
              })
            : _vm._e(),
          _vm.isNumberField || _vm.isTextField
            ? _c(
                "div",
                { staticClass: "d-flex mt-6 flex-row justify-space-between" },
                [
                  _c("v-text-field", {
                    staticClass: "mr-2",
                    attrs: {
                      type: "number",
                      rules: _vm.isTextField
                        ? _vm.$validator.rules.optionalNumberBetween(
                            0,
                            _vm.maxSizeTextField(_vm.localField.type)
                          )
                        : [],
                      label: `Minimum ${
                        _vm.isNumberField ? "value" : "length"
                      }`,
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.stopPropagation()
                      },
                    },
                    model: {
                      value: _vm.localField.minLengthOrValue,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.localField,
                          "minLengthOrValue",
                          _vm._n($$v)
                        )
                      },
                      expression: "localField.minLengthOrValue",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "ml-2",
                    attrs: {
                      type: "number",
                      rules: _vm.isTextField
                        ? _vm.$validator.rules.optionalNumberBetween(
                            0,
                            _vm.maxSizeTextField(_vm.localField.type)
                          )
                        : [],
                      label: `Maximum ${
                        _vm.isNumberField ? "value" : "length"
                      }`,
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.stopPropagation()
                      },
                    },
                    model: {
                      value: _vm.localField.maxLengthOrValue,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.localField,
                          "maxLengthOrValue",
                          _vm._n($$v)
                        )
                      },
                      expression: "localField.maxLengthOrValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-container",
            { staticClass: "my-6" },
            [
              _c(
                "v-row",
                [
                  _c("v-switch", {
                    staticClass: "mr-3 mt-0",
                    attrs: { color: "primary" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "pr-1" }, [
                              _vm._v("Required for Warehouse"),
                            ]),
                            _c(
                              "help-icon-tooltip",
                              {
                                attrs: {
                                  "icon-style-class": "grey darken-2",
                                  dense: "",
                                },
                              },
                              [
                                _vm._v(
                                  " This field is mandatory for Warehouse users to fill up when scheduling an appointment "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.localField.requiredForWarehouse,
                      callback: function ($$v) {
                        _vm.$set(_vm.localField, "requiredForWarehouse", $$v)
                      },
                      expression: "localField.requiredForWarehouse",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-switch", {
                    staticClass: "mr-2 mt-0",
                    attrs: { color: "primary" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "pr-1" }, [
                              _vm._v("Hide this field on carrier scheduling"),
                            ]),
                            _c(
                              "help-icon-tooltip",
                              {
                                attrs: {
                                  "icon-style-class": "grey darken-2",
                                  dense: "",
                                },
                              },
                              [
                                _vm._v(
                                  " This field will not be visible to carriers when scheduling an appointment "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.localField.hiddenFromCarrier,
                      callback: function ($$v) {
                        _vm.$set(_vm.localField, "hiddenFromCarrier", $$v)
                      },
                      expression: "localField.hiddenFromCarrier",
                    },
                  }),
                ],
                1
              ),
              !_vm.localField.hiddenFromCarrier
                ? _c(
                    "v-row",
                    [
                      _c("v-switch", {
                        staticClass: "mr-2 mt-0",
                        attrs: { color: "primary" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", { staticClass: "pr-1" }, [
                                    _vm._v("Required for Carriers"),
                                  ]),
                                  _c(
                                    "help-icon-tooltip",
                                    {
                                      attrs: {
                                        "icon-style-class": "grey darken-2",
                                        dense: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " This field is mandatory for Carrier users to fill up when scheduling an appointment "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          100853376
                        ),
                        model: {
                          value: _vm.localField.requiredForCarrier,
                          callback: function ($$v) {
                            _vm.$set(_vm.localField, "requiredForCarrier", $$v)
                          },
                          expression: "localField.requiredForCarrier",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("action-group", {
            staticClass: "pr-0",
            attrs: {
              "confirm-icon": "upload",
              "confirm-label": "Save",
              "cancel-label": "Nevermind",
              "cancel-color": "error",
            },
            on: {
              cancel: function ($event) {
                return _vm.$emit("close")
              },
              confirm: _vm.submit,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }