var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("edit-warehouse-form", {
    attrs: {
      "read-only": _vm.$isWarehouseReadOnly(
        _vm.warehouse.id,
        _vm.warehouse.orgId
      ),
      warehouse: _vm.warehouse,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }