import { render, staticRenderFns } from "./AppointmentDetailsClaims.vue?vue&type=template&id=ab78ab34&scoped=true"
import script from "./AppointmentDetailsClaims.vue?vue&type=script&lang=js"
export * from "./AppointmentDetailsClaims.vue?vue&type=script&lang=js"
import style0 from "./AppointmentDetailsClaims.vue?vue&type=style&index=0&id=ab78ab34&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab78ab34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/apps/helios/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab78ab34')) {
      api.createRecord('ab78ab34', component.options)
    } else {
      api.reload('ab78ab34', component.options)
    }
    module.hot.accept("./AppointmentDetailsClaims.vue?vue&type=template&id=ab78ab34&scoped=true", function () {
      api.rerender('ab78ab34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/appointments/components/details/AppointmentDetailsClaims.vue"
export default component.exports