var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "h3",
        { staticClass: "my-6" },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", small: "", rounded: "" },
              on: { click: _vm.back },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
            1
          ),
          _vm._v(" Appointment Fields "),
        ],
        1
      ),
      _c("custom-field-list", {
        attrs: {
          "read-only": _vm.$isWarehouseReadOnly(
            _vm.warehouse.id,
            _vm.warehouse.orgId
          ),
          "show-clone-button": "",
          warehouse: _vm.warehouse,
          "field-template": _vm.warehouse.customApptFieldsTemplate || [],
        },
        on: { save: _vm.handleTemplateChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }