<template>
  <v-card elevation="0" width="250">
    <v-card-title class="d-flex flex-row">
      <h1 class="font-weight-bold text-uppercase" data-testid="yard-spot-detail-title">
        {{ spot.code }}
      </h1>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDetail" data-testid="yard-spot-detail-close-button">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text data-testid="yard-spot-detail-content">
      <div class="d-flex flex-column mb-4">
        <v-form ref="form">
          <label><strong>Spot code</strong></label>
          <v-text-field
            v-model="spot.code"
            outlined
            :rules="[validateCode]"
            dense
            data-testid="yard-spot-form-code-input"></v-text-field>

          <label><strong>Spot type</strong></label>
          <v-radio-group v-model="spot.type" :rules="[validateType]" outlined dense>
            <v-radio
              v-for="type of Object.keys(spotType).map(k => k.toLowerCase())"
              :key="type"
              :data-testid="`yard-spot-form-type-${type}`"
              :value="type"
              :label="capitalize(type)"></v-radio>
          </v-radio-group>

          <label><strong>Current state</strong></label>
          <p class="text-capitalize">
            <v-icon v-if="isSpotAssigned(spot)" small color="red">mdi-alert-circle-outline</v-icon>
            {{ getSpotStatus(spot) }}
          </p>
        </v-form>
      </div>
    </v-card-text>
    <v-card-actions class="card-actions" data-testid="yard-spot-detail-actions">
      <div class="d-flex flex-column full-width">
        <v-btn
          block
          color="primary"
          @click="updateSpot"
          data-testid="yard-spot-form-submit-button"
          class="mb-4">
          <v-icon small class="mr-2">mdi-check</v-icon>
          Save changes
        </v-btn>
        <v-btn
          block
          outlined
          color="error"
          @click="isDeleteConfirmationVisible = true"
          class="mb-4"
          data-testid="yard-spot-form-delete-button"
          :disabled="isSpotAssigned(spot)">
          <v-icon small class="mr-2">mdi-delete-outline</v-icon>
          Delete Spot
        </v-btn>
      </div>
    </v-card-actions>
    <confirm
      :should-show="isDeleteConfirmationVisible"
      delete-confirmation
      show-delete-warning
      persistent
      v-if="isDeleteConfirmationVisible"
      @result="handleDeleteConfirmationResult"
      title="Delete this Spot?"
      :entity-name="spot.code"
      button-true-text="YES, DELETE"
      button-true-color="error"
      :width="650"></confirm>
  </v-card>
</template>

<script>
import { defineComponent, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { capitalize } from 'lodash';

export default defineComponent({
  name: 'YardViewSpotForm',
  methods: { capitalize },
  props: {
    spot: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();
    const form = ref(null);
    const isDeleteConfirmationVisible = ref(false);

    const handleDeleteConfirmationResult = result => {
      if (result) {
        deleteSpot();
      }
      isDeleteConfirmationVisible.value = false;
    };

    const deleteSpot = () => {
      yardStore.deleteSpot(props.spot.id);
    };

    const closeDetail = () => {
      yardStore.selectedSpotRef.value = null;
    };

    const updateSpot = () => {
      if (!form.value?.validate()) {
        return;
      }
      yardStore.updateSpot(props.spot.id, {
        code: props.spot.code,
        type: props.spot.type
      });
    };

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      deleteSpot,
      closeDetail,
      isSpotAssigned: yardStore.isSpotAssigned,
      isSpotClosed: yardStore.isSpotClosed,
      isSpotOpen: yardStore.isSpotOpen,
      isDeleteConfirmationVisible,
      updateSpot,
      handleDeleteConfirmationResult,
      form,
      validateType: yardStore.validateSpotTypeInput,
      validateCode: yardStore.validateSpotCodeInput,
      selectedSpot: yardStore.selectedSpotRef,
      spotStatus: yardStore.spotStatus,
      spotType: yardStore.spotType,
      getSpotStatus: yardStore.getSpotStatus
    };
  }
});
</script>
<style lang="scss" scoped>
.scrollable-content {
  max-height: 300px;
  overflow-y: auto;
}

.card-actions {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $white;
  z-index: 1000;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
