import { render, staticRenderFns } from "./AccountNavigation.vue?vue&type=template&id=542ae960"
import script from "./AccountNavigation.vue?vue&type=script&lang=js"
export * from "./AccountNavigation.vue?vue&type=script&lang=js"
import style0 from "./AccountNavigation.vue?vue&type=style&index=0&id=542ae960&lang=scss"


/* normalize component */
import normalizer from "!../../../../../apps/helios/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/apps/helios/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('542ae960')) {
      api.createRecord('542ae960', component.options)
    } else {
      api.reload('542ae960', component.options)
    }
    module.hot.accept("./AccountNavigation.vue?vue&type=template&id=542ae960", function () {
      api.rerender('542ae960', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "libs/satellite/src/components/elements/AccountNavigation.vue"
export default component.exports