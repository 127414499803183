<template>
  <dialog-base
    :loading="false"
    has-dialog-actions
    v-bind="$props"
    header="Edit reserve"
    loading-message="Updating reserve..."
    @close="close"
    @confirm="$refs.editReserveForm.submit()">
    <template v-slot:body>
      <edit-reserve-form
        @close="close"
        :reserve="reserve"
        ref="editReserveForm"></edit-reserve-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Dialog for edit reserve form
 * @displayName Edit Reserve Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    reserve: {
      type: Object,
      required: true
    }
  }
};
</script>
