var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: {
        "closed-spot": _vm.isSpotClosed(_vm.spot),
        "open-spot": _vm.isSpotOpen(_vm.spot),
      },
      attrs: { "data-testid": `yard-spot-unassigned-${_vm.spot.code}` },
    },
    [
      _c(
        "p",
        { staticClass: "mb-0" },
        [
          _c(
            "v-icon",
            {
              class: `spot-type-${_vm.spot.type}`,
              attrs: {
                small: "",
                color: `${
                  _vm.isSpotOpen(_vm.spot) ? "success" : "neutral"
                } darken-3`,
              },
            },
            [_vm._v(" " + _vm._s(_vm.getSpotIcon(_vm.spot.type)) + " ")]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass:
            "font-weight-medium text-uppercase spot-status font-size-x-small",
        },
        [_vm._v(" " + _vm._s(_vm.getSpotStatus(_vm.spot)) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }