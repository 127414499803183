var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "550" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)
          )
            return null
          return _vm.closeModal.apply(null, arguments)
        },
        input: _vm.handleDialogInput,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        icon: "",
                        "data-testid":
                          "carrier-contact-manage-dialog-activator",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _vm.carrierContact.isFavorite
                                  ? _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { staticClass: "is-favorite" },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-star ")]
                                    )
                                  : _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b({}, "v-icon", attrs, false),
                                        on
                                      ),
                                      [_vm._v("mdi-star-plus-outline")]
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: {
                            "data-testid": "carrier-contact-manage-tooltip",
                          },
                        },
                        [
                          _vm.carrierContact.isFavorite
                            ? _c("span", [_vm._v("Manage favorite")])
                            : _c("span", [_vm._v("Favorite this contact")]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "dialog-box" },
        [
          _c(
            "v-card-title",
            [
              _c("span", { staticClass: "headline d-flex" }, [
                _vm._v("Manage Favorite Contact"),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "pa-3",
                  attrs: { icon: "", light: "" },
                  on: { click: _vm.closeModal },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "dialog-body" },
            [
              _c("carrier-contact-card", {
                attrs: {
                  carrier: _vm.carrierContact.user,
                  company: _vm.carrierContact.company,
                  "is-favorite": _vm.carrierContact.isFavorite,
                },
              }),
              _c(
                "div",
                { staticClass: "favorite-warehouses" },
                [
                  _c(
                    "label",
                    {
                      class: { "text--disabled": _vm.isOrgFavorite },
                      attrs: { for: "carrier-contact-warehouses" },
                    },
                    [
                      _vm._v(
                        " This carrier contact is a favorite for the following warehouses: "
                      ),
                    ]
                  ),
                  _c("v-autocomplete", {
                    staticClass: "warehouse-autocomplete",
                    attrs: {
                      flat: "",
                      solo: "",
                      dense: "",
                      "no-filter": "",
                      "hide-details": "",
                      "return-object": "",
                      id: "carrier-contact-warehouses",
                      "data-testid": "carrier-contact-warehouses",
                      placeholder: "Select warehouses",
                      "item-value": "id",
                      "item-text": "name",
                      multiple: "",
                      disabled: _vm.isOrgFavorite,
                      items: _vm.warehouses.warehousesRef.value,
                      loading: _vm.warehouses.isLoadingRef.value,
                    },
                    on: {
                      "update:search-input": _vm.warehouses.updateSearchInput,
                    },
                    scopedSlots: _vm._u(
                      [
                        (_vm.warehouses.warehousesRef.value?.length || 0) > 0
                          ? {
                              key: "prepend-item",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { ripple: "" },
                                      on: {
                                        click: _vm.toggleSelectAllWarehouses,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color:
                                                  _vm.allWarehousesSelected > 0
                                                    ? "error darken-4"
                                                    : "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.allWarehousesSelected
                                                      ? "mdi-close-box"
                                                      : "mdi-plus-box"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.allWarehousesSelected
                                                    ? "Deselect All"
                                                    : "Select All"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", {
                                    staticClass: "mt-2",
                                    attrs: { slot: "prepend-item" },
                                    slot: "prepend-item",
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.favoriteWarehouses,
                      callback: function ($$v) {
                        _vm.favoriteWarehouses = $$v
                      },
                      expression: "favoriteWarehouses",
                    },
                  }),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        disabled: !_vm.isWarehouseRestricted,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "div",
                                _vm._g(_vm._b({}, "div", attrs, false), on),
                                [
                                  _c("v-checkbox", {
                                    staticClass: "org-favorite-checkbox",
                                    class: {
                                      "text--disabled":
                                        _vm.isWarehouseRestricted,
                                    },
                                    attrs: {
                                      dense: "",
                                      "hide-details": "",
                                      disabled: _vm.isWarehouseRestricted,
                                      label:
                                        "Favorite this contact for every warehouse, including future ones",
                                    },
                                    model: {
                                      value: _vm.isOrgFavorite,
                                      callback: function ($$v) {
                                        _vm.isOrgFavorite = $$v
                                      },
                                      expression: "isOrgFavorite",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Your role does not allow you to perform this action"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "dialog-actions justify-space-between" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { text: "", disabled: _vm.isWarehouseRestricted },
                  on: { click: _vm.unfavorite },
                },
                [
                  _c("v-icon", { staticClass: "mr-1" }, [
                    _vm._v("mdi-star-minus-outline"),
                  ]),
                  _vm._v(" Unfavorite from all "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.canSave },
                  on: { click: _vm.save },
                },
                [_vm._v("Save Favorite")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }