var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-2",
      attrs: {
        width: "300",
        "data-testid": "appointment-checkin-advertisement-box",
      },
    },
    [
      _c("v-card-title", [
        _c("p", { staticClass: "text-break mb-2" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.isCurrentUserAdminOrOwner
                  ? "Enhance your facility's efficiency with"
                  : "Optimize your workflow with"
              ) +
              " "
          ),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: _vm.isCurrentUserAdminOrOwner
                  ? _vm.linkGateManagement
                  : _vm.linkGateManagementOthers,
              },
              on: {
                click: function ($event) {
                  return _vm.mixpanelClick("Fake-door Hyperlink")
                },
              },
            },
            [_vm._v(" Gate Management ")]
          ),
        ]),
      ]),
      _c("v-card-text", [
        _c(
          "p",
          { staticClass: "mb-1" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-2", attrs: { small: "", color: "primary" } },
              [_vm._v("mdi-check-circle-outline")]
            ),
            _vm._v(" Drivers "),
            _c("strong", [_vm._v("self-check in")]),
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "mb-1" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-2", attrs: { small: "", color: "primary" } },
              [_vm._v("mdi-check-circle-outline")]
            ),
            _c("strong", [_vm._v("Real-time alerts")]),
            _vm._v(" for all arrivals "),
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "mb-1" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-2", attrs: { small: "", color: "primary" } },
              [_vm._v("mdi-check-circle-outline")]
            ),
            _c("strong", [_vm._v("Unplanned arrivals")]),
            _vm._v(" visibility "),
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "mb-1" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-2", attrs: { small: "", color: "primary" } },
              [_vm._v("mdi-check-circle-outline")]
            ),
            _vm.isCurrentUserAdminOrOwner
              ? _c("span", [
                  _c("strong", [_vm._v("Insights & Reports")]),
                  _vm._v(" into gate activity "),
                ])
              : _c("span", [
                  _c("strong", [_vm._v("Auto-update")]),
                  _vm._v(" appointments "),
                ]),
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "mb-1" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-2", attrs: { small: "", color: "primary" } },
              [_vm._v("mdi-check-circle-outline")]
            ),
            _vm._v(" In-app "),
            _c("strong", [_vm._v("2-way SMS")]),
            _c("small", { staticClass: "disabled ml-2" }, [_vm._v("SOON!")]),
          ],
          1
        ),
      ]),
      _c("v-card-actions", [
        _c("div", { staticClass: "text-center full-width" }, [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isCurrentUserAdminOrOwner
                    ? "Want to learn more?"
                    : "Believe your facility could benefit?"
                ) +
                " "
            ),
          ]),
          _c(
            "p",
            [
              _vm.isCurrentUserAdminOrOwner
                ? _c(
                    "v-btn",
                    {
                      staticClass: "small secondary",
                      attrs: { target: "_blank", href: _vm.linkContactSales },
                      on: {
                        click: function ($event) {
                          return _vm.mixpanelClick("Fake-door Primary Button")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "white--text" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [_vm._v("mdi-open-in-new")]
                          ),
                          _vm._v(" CONTACT SALES "),
                        ],
                        1
                      ),
                    ]
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "secondary",
                      on: { click: _vm.requestCheckInFeature },
                    },
                    [
                      _c("span", { staticClass: "white--text" }, [
                        _vm._v("Yes, I'm interested"),
                      ]),
                    ]
                  ),
            ],
            1
          ),
          _c("p", { staticClass: "pt-2" }, [
            _vm.isCurrentUserAdminOrOwner
              ? _c("a", { on: { click: _vm.requestCheckInFeature } }, [
                  _vm._v("Request a trial"),
                ])
              : _c(
                  "a",
                  {
                    attrs: { href: _vm.mailToContent, target: "_blank" },
                    on: {
                      click: function ($event) {
                        return _vm.mixpanelClick("Fake-door Share link")
                      },
                    },
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", color: "secondary" },
                      },
                      [_vm._v("mdi-email-fast-outline")]
                    ),
                    _vm._v(" Share with my team "),
                  ],
                  1
                ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }