var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.spots.length > 0
      ? _c(
          "div",
          { staticClass: "d-flex flex-wrap" },
          [
            _vm.editable
              ? [
                  _vm._l(_vm.spots, function (spot) {
                    return _c("yard-view-spot-editable", {
                      key: spot.id,
                      staticClass: "mr-1 mb-6",
                      attrs: { spot: spot },
                    })
                  }),
                  _vm.spotArea?.id && !_vm.isReadOnly
                    ? _c("yard-view-spot-add", {
                        attrs: { "spot-area": _vm.spotArea },
                      })
                    : _vm._e(),
                ]
              : _vm._l(_vm.spots, function (spot) {
                  return _c("yard-view-spot", {
                    key: spot.id,
                    staticClass: "mr-1 mb-6",
                    attrs: { spot: spot },
                  })
                }),
          ],
          2
        )
      : _vm._e(),
    !_vm.editable && !_vm.spots.length
      ? _c("p", { staticClass: "small text--disabled mt-4" }, [
          _vm._v(" There are no spots available "),
        ])
      : _vm._e(),
    _vm.editable && !_vm.spots.length
      ? _c(
          "div",
          {
            staticClass:
              "d-flex flex-row justify-center align-content-center full-width my-12",
          },
          [
            _vm.editable && _vm.spotArea?.id && !_vm.isReadOnly
              ? _c("yard-view-spot-add", {
                  attrs: { "as-button": "", "spot-area": _vm.spotArea },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }