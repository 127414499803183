var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("audit-log-entity-dialog", {
    attrs: {
      "object-id": _vm.item.id,
      "entity-obj": _vm.item,
      "entity-name": _vm.entityName ?? _vm.item.name,
      timezone: _vm.timezone,
      entity: _vm.entityType,
    },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on, attrs }) {
          return [
            _c(
              "icon-tooltip-button",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      tooltip: "Audit log",
                      size: "large",
                      "icon-class": "mr-2",
                      icon: "timeline-text-outline",
                    },
                  },
                  "icon-tooltip-button",
                  attrs,
                  false
                ),
                on
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }