var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "w-100 d-flex flex-column gap-4 justify-center align-center my-10",
      attrs: { "data-testid": "yard-no-areas-message" },
    },
    [
      _c(
        "span",
        { staticClass: "icon-wrapper" },
        [
          _c(
            "v-icon",
            { staticClass: "icon", attrs: { size: "50", color: "#9E9E9E" } },
            [_vm._v("mdi-warehouse")]
          ),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "p",
        { staticClass: "typography-body-sm m-0" },
        [
          _vm._v(" Go to the "),
          _c(
            "router-link",
            {
              staticClass: "typography-body-sm-bold",
              attrs: { to: "warehouses" },
            },
            [_vm._v("Warehouses")]
          ),
          _vm._v(" page. "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticStyle: { "max-inline-size": "270px", "text-align": "center" } },
      [
        _c("h3", { staticClass: "typography-body-md-bold align-center" }, [
          _vm._v("Don’t have a warehouse yet?"),
        ]),
        _c("p", { staticClass: "typography-body-sm m-0" }, [
          _vm._v(
            " To create a yard view, you first need to add a warehouse to your Organization. "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }