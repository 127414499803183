<template>
  <v-select
    class="yard-view-area-select"
    outlined
    v-model="selectedAreas"
    :disabled="!selectedWarehouse"
    :items="spotAreas"
    item-text="name"
    item-value="id"
    placeholder="Select areas"
    :return-object="false"
    multiple
    dense
    :clearable="false"
    hide-details>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          <span class="typography-body-sm">No areas available</span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template #selection="{ index }">
      <span v-if="index === 0">{{ selectedAreas.length }} of {{ spotAreas.length }} selected</span>
    </template>

    <!-- Prepend item (Select all button) -->
    <template #prepend-item v-if="spotAreas.length > 0">
      <v-list-item ripple @click="toggleSelectAll">
        <v-list-item-action>
          <v-icon>
            {{ selectAllIcon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="typography-body-md">All areas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <!-- Item display in dropdown -->
    <template #item="{ item, attrs, on }">
      <v-list-item ripple v-bind="attrs" v-on="on">
        <v-list-item-action>
          <v-icon>
            {{ getItemIcon(item) }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="d-flex flex-row align-center typography-body-md">
            {{ item.name }}

            <v-btn
              text
              small
              class="text-none typography-button-sm ml-auto"
              @click="selectOnly($event, item)">
              Only
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useSelectAll } from '@/composables';
import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewAreaSelect',
  props: {},
  setup() {
    const yardStore = useYard();

    const selectOnly = ($event, item) => {
      $event.stopPropagation();
      yardStore.selectedAreasRef.value = [item.id];
    };

    const { allSelected: isAllSelected, toggleSelectAll } = useSelectAll({
      items: yardStore.spotAreasRef,
      selected: yardStore.selectedAreasRef,
      transformSelection: item => item.id
    });

    const selectAllIcon = computed(() => {
      if (yardStore.selectedAreasRef.value.length === yardStore.spotAreasRef.value.length) {
        return 'mdi-close-box';
      }
      return yardStore.selectedAreasRef.value.length > 0
        ? 'mdi-minus-box'
        : 'mdi-checkbox-blank-outline';
    });

    function getItemIcon(item) {
      return yardStore.selectedAreasRef.value.includes(item.id)
        ? 'mdi-checkbox-marked'
        : 'mdi-checkbox-blank-outline';
    }

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      selectedAreas: yardStore.selectedAreasRef,
      spotAreas: yardStore.spotAreasRef,
      isAllSelected,
      selectAllIcon,
      getItemIcon,
      selectOnly,
      toggleSelectAll
    };
  }
});
</script>

<style lang="scss" scoped>
.yard-view-area-select {
  min-inline-size: 220px;
}
</style>
