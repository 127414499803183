// We import directly from core rather than using this.novaCore since this mixin is used in functional components
import { ETA_PREFIX_STRING, EtaCondition, getEtaVerb } from '@satellite/../nova/core';

export default {
  data() {
    return {
      etaCondition: '',
      shouldShowEtaDialog: false
    };
  },
  methods: {
    handleEtaButtonClick(etaCondition) {
      this.shouldShowEtaDialog = true;
      this.$emit('input', etaCondition);
    },
    editEta() {
      this.shouldShowEtaDialog = true;
    },
    updateCondition(newCondition) {
      this.$emit('condition-updated', newCondition);
    },
    getTagElMeta(tag) {
      const meta = {
        color: '',
        classes: ''
      };
      if (tag.includes(`${EtaCondition.Early}`)) {
        meta.color = 'blue';
        meta.classes = 'lighten-5 black--text';
      } else if (tag.includes(`${EtaCondition.Late}`)) {
        meta.color = 'error';
        meta.classes = 'white--text';
      }
      return meta;
    },
    getEtaTag(tags) {
      return tags.filter(tag => tag.includes(this.getEtaPrefixString()))[0] ?? '';
    },
    getEtaPrefixString() {
      return ETA_PREFIX_STRING;
    },
    getEtaVerb(tag) {
      return getEtaVerb(tag);
    }
  }
};
