import _ from 'lodash';
import { computed } from 'vue';
import { useStore } from '@/composables';

export default function useSettings() {
  const store = useStore();
  const settingsStateRef = computed(() => _.get(store, 'state.Settings'));
  const settingsGettersRef = computed(() =>
    _.mapKeys(
      _.pickBy(_.get(store, 'getters'), (value, key) => key.startsWith('Settings/')),
      (value, key) => key.replace(/^Settings\//, '')
    )
  );
  const settingsActionsRef = computed(() =>
    _.mapKeys(
      _.pickBy(_.get(store, '_actions'), (value, key) => key.startsWith('Settings/')),
      (value, key) => key.replace(/^Settings\//, '')
    )
  );
  const settingsMutationsRef = computed(() =>
    _.mapKeys(
      _.pickBy(_.get(store, '_mutations'), (value, key) => key.startsWith('Settings/')),
      (value, key) => key.replace(/^Settings\//, '')
    )
  );
  return { settingsStateRef, settingsGettersRef, settingsActionsRef, settingsMutationsRef };
}
