var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    { staticClass: "select-limit-msg" },
    [
      _c(
        "v-list-item-icon",
        { staticClass: "mr-3" },
        [_c("v-icon", [_vm._v("mdi-information")])],
        1
      ),
      _c("v-list-item-title", { staticClass: "mt-1" }, [
        _vm._v(" Only " + _vm._s(_vm.limit) + " selections allowed "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }