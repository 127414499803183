<template>
  <v-card flat class="py-2 px-4 d-flex flex-column address-card my-6">
    <span v-if="label" class="font-weight-bold mb-2">{{ label }}</span>
    <span>{{ street }}</span>
    <span>{{ city }}, {{ state }}</span>
    <span>
      {{ zip }}
      <span v-if="country">, {{ country }}</span>
    </span>
    <span v-if="timezone">
      <strong>Timezone:</strong>
      {{ timezone }}
    </span>
  </v-card>
</template>

<script lang="js">
export default {
  name: 'AddressDisplay',
  props: {
    label: {
      type: String,
      default: 'Warehouse address'
    },
    street: {
      type: String,
      required: true
    },
    state: {
      type: String,
      required: true
    },
    city: {
      type: String,
      required: true
    },
    zip: {
      type: String,
      required: true
    },
    country: {
      type: String,
      required: false,
    },
    timezone: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>

<style scoped>
.address-card {
  background-color: #f1f2f2;
  border: 1px solid gray;
}
</style>
