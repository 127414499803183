<template>
  <dialog-base
    :key="renderKey"
    v-bind="$props"
    @close="close"
    no-padding
    :use-global-loader="false"
    content-class="details-dialog reschedule-appointment-dialog"
    transition="dialog-bottom-transition"
    loading-message="updating appointment"
    max-width="100%"
    :value="showDialog"
    header="Reschedule appointment"
    width="1200px">
    <template #customHeader>
      <header class="full-width">
        <dialog-header header="Reschedule appointment" @close="close">
          <template #prepend-button>
            <v-btn icon text class="mr-2" @click="close"><v-icon>mdi-arrow-left</v-icon></v-btn>
          </template>
          <template #close-icon>
            <v-icon>mdi-close</v-icon>
          </template>
        </dialog-header>
      </header>
    </template>
    <template v-slot:body>
      <v-alert :type="alertType" class="mb-0" shaped v-if="alertMessage">
        {{ alertMessage }}
      </v-alert>
      <reschedule-appointment-form
        @close="close"
        ref="rescheduleAppointmentForm"
        :appointment="appointment"></reschedule-appointment-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import RescheduleAppointmentForm from '@/modules/appointments/components/reschedule/RescheduleAppointmentForm.vue';

/**
 * Button/Dialog for the reschedule form
 * @displayName Reschedule Appointment Dialog
 */
export default {
  mixins: [dialogMixin],
  components: { RescheduleAppointmentForm },
  props: {
    /**
     * Alert message to display in dialog
     */
    alertMessage: {
      type: String,
      required: false
    },
    /**
     * Alert type
     * @values success | error
     */
    alertType: {
      type: String,
      required: false,
      default: 'success'
    },
    appointment: {
      type: Object,
      required: true
    },
    appointmentWarehouse: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  }
};
</script>

<style scoped lang="scss">
.reschedule-appointment-dialog {
  .v-card__title {
    border-bottom: 1px solid $color-line-divider;
  }
}

::v-deep .actions {
  border-top: 1px solid $color-line-divider;
}
</style>
