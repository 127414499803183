var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "header",
        { staticClass: "mobile-header pa-4" },
        [
          _c("img", {
            staticClass: "mobile-logo",
            attrs: { src: _vm.$logoPath },
          }),
          _c("div", { staticClass: "spacer" }),
          _c("create-appointment-dialog", {
            attrs: { "data-device": "mobile", "x-small": "" },
          }),
          _vm.$me
            ? _c("v-app-bar-nav-icon", {
                staticClass: "white--text ml-4",
                on: {
                  click: [
                    function ($event) {
                      _vm.drawer = !_vm.drawer
                    },
                    function ($event) {
                      $event.stopPropagation()
                    },
                  ],
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.$me
        ? _c(
            "v-navigation-drawer",
            {
              staticClass: "secondary",
              class: { collapsed: _vm.isCollapsed },
              attrs: {
                "data-testid": "app-nav-drawer",
                "mobile-breakpoint": "768",
                width: _vm.isCollapsed ? 60 : 240,
                dark: "",
                "mini-variant": _vm.mini,
                app: "",
              },
              on: {
                "update:miniVariant": function ($event) {
                  _vm.mini = $event
                },
                "update:mini-variant": function ($event) {
                  _vm.mini = $event
                },
              },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "header",
                { staticClass: "nova-logo" },
                [
                  _vm.isStaging
                    ? _c("div", { staticClass: "env-flag staging-flag" }, [
                        _vm._v("STAGING"),
                      ])
                    : _vm._e(),
                  _vm.isRND
                    ? _c("div", { staticClass: "env-flag rnd-flag" }, [
                        _vm._v("RND"),
                      ])
                    : _vm._e(),
                  _vm.isQA
                    ? _c("div", { staticClass: "env-flag rnd-flag" }, [
                        _vm._v("QA"),
                      ])
                    : _vm._e(),
                  !_vm.isCollapsed
                    ? _c(
                        "div",
                        { staticClass: "pa-3" },
                        [
                          _c("v-img", {
                            staticClass: "px-2",
                            attrs: {
                              src: _vm.$logoPath,
                              id: "logo-image",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "text-center pt-4 pb-2" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "30",
                              height: "30",
                              viewBox: "0 0 46 53",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M16.7793 3.30172L39.3721 16.5949V43.2203L45.0088 39.8951V13.2931L22.416 0L16.7793 3.30172Z",
                                fill: "white",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M8.52832 8.17187L31.0212 21.3944V48.1375L36.6502 44.8201V18.2025L14.0266 4.92505L8.52832 8.17187Z",
                                fill: "white",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M0.161621 13.105L22.7468 26.3982L22.7544 53.0001L28.2912 49.769V23.0494L5.79829 9.77979L0.161621 13.105Z",
                                fill: "white",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M2.27588 41.1579L20.2932 51.761V30.5469L2.27588 41.1579Z",
                                fill: "white",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M0 15.9124V39.6126L5.63667 36.2952V19.2298L0 15.9124Z",
                                fill: "white",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M2.21468 2.69723H3.04519C3.42199 2.69723 3.67576 2.46195 3.67576 2.09335C3.67576 1.7326 3.42199 1.48948 3.04519 1.48948H2.21468V2.69723ZM3.73728 4.61866L2.81449 3.03446H2.21468V4.61866H1.87633V1.15225H3.04519C3.71421 1.15225 4.01411 1.61496 4.01411 2.09335C4.01411 2.54038 3.76034 2.95604 3.19898 3.01878L4.12946 4.61081H3.73728V4.61866ZM0.338354 2.88545C0.338354 1.49732 1.44569 0.367992 2.82218 0.367992C4.20636 0.367992 5.3137 1.50516 5.3137 2.88545C5.3137 4.27358 4.20636 5.40291 2.82218 5.40291C1.44569 5.40291 0.338354 4.26574 0.338354 2.88545ZM0 2.88545C0 4.46181 1.26114 5.74014 2.82218 5.74014C4.38322 5.74014 5.64436 4.46181 5.64436 2.88545C5.64436 1.3091 4.38322 0.0307617 2.82218 0.0307617C1.26114 0.0307617 0 1.3091 0 2.88545Z",
                                fill: "white",
                              },
                            }),
                          ]
                        ),
                      ]),
                  _vm.isTrial && !_vm.isCollapsed && !_vm.isInternalUser
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "trial d-flex justify-center my-2 mt-4 white--text",
                          },
                          [
                            _c("small", [
                              !_vm.isExpired
                                ? _c("span", { staticClass: "trial-message" }, [
                                    _vm._v("Trial expires in:"),
                                  ])
                                : _vm._e(),
                              _c("span", { staticClass: "trial-flag" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.remainingTrialMessage) + " "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mt-4 white--text" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-center align-center account-nav",
                        },
                        [
                          _c("account-navigation", {
                            attrs: { "is-compact": _vm.isCollapsed },
                          }),
                        ],
                        1
                      ),
                      !_vm.isCollapsed
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-center align-center my-2",
                            },
                            [_c("small", [_vm._v(_vm._s(_vm.role))])]
                          )
                        : _vm._e(),
                      !_vm.isCollapsed
                        ? _c("v-divider", { staticClass: "mb-3" })
                        : _vm._e(),
                      !_vm.isCollapsed
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-center my-2 mb-4" },
                            [
                              _c("create-appointment-dialog", {
                                attrs: { "data-device": "desktop" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "v-list",
                { staticClass: "pa-0 mt-3", attrs: { dense: "", nav: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "mb-4 text-center" },
                    [
                      _vm.isCollapsed
                        ? _c("create-appointment-dialog", {
                            attrs: { "data-device": "desktop" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({
                                                  on: tooltipOn,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g({}, tooltipOn),
                                                      [
                                                        _c(
                                                          "primary-button",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "rounded",
                                                                attrs: {
                                                                  large: "",
                                                                  icon: "",
                                                                  tile: "",
                                                                },
                                                              },
                                                              "primary-button",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-plus"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm.$rolePermissions
                                            .canCreateAppointment
                                            ? _c("span", [
                                                _vm._v("Create Appointment"),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.novaCore.getRoleActionError()
                                                  )
                                                ),
                                              ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              39139620
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._l(_vm.navItemsToDisplay, function (item) {
                    return _c(
                      "section",
                      { key: item.link },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              right: "",
                              disabled:
                                !_vm.isItemDisabled(item) && !_vm.isCollapsed,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      !item.children
                                        ? _c(
                                            "v-list-item",
                                            _vm._g(
                                              {
                                                class: {
                                                  "disabled-list-item":
                                                    _vm.isItemDisabled(item),
                                                },
                                                attrs: {
                                                  "data-testid": item.testId,
                                                  link: "",
                                                  target:
                                                    _vm.getLinkAttrs(item)
                                                      .target,
                                                  to: _vm.getLinkAttrs(item).to,
                                                  href: _vm.getLinkAttrs(item)
                                                    .href,
                                                  inactive:
                                                    _vm.isItemDisabled(item),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleListItemButtonClick(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        medium: "",
                                                        disabled:
                                                          _vm.isItemDisabled(
                                                            item
                                                          ),
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.icon))]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass: "nav-item-title",
                                                  class: {
                                                    collapsed: _vm.isCollapsed,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.title) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm.isItemDisabled(item)
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.makeDisabledTooltipMsg(item))
                                  ),
                                ])
                              : _vm.isCollapsed
                              ? _c("span", [_vm._v(_vm._s(item.title))])
                              : _vm._e(),
                          ]
                        ),
                        item.children
                          ? _c(
                              "v-list-group",
                              {
                                key: item.link,
                                class: {
                                  "disabled-list-item": _vm.isItemDisabled,
                                },
                                attrs: {
                                  disabled: _vm.isItemDisabled(item),
                                  color: "white",
                                  value: item.isOpen,
                                  "no-action": "",
                                  "prepend-icon": item.icon,
                                },
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "activator" },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._l(item.children, function (childItem) {
                                  return [
                                    childItem.children
                                      ? _c(
                                          "v-list-group",
                                          {
                                            key: childItem.link,
                                            attrs: {
                                              "no-action": "",
                                              "sub-group": "",
                                              value: childItem.isOpen,
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "activator" },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    key: item.link,
                                                    attrs: {
                                                      top: "",
                                                      disabled:
                                                        !_vm.isItemDisabled(
                                                          item
                                                        ),
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "nd-second-level",
                                                                    attrs: {
                                                                      target:
                                                                        _vm.getLinkAttrs(
                                                                          childItem
                                                                        )
                                                                          .target,
                                                                      to: _vm.getLinkAttrs(
                                                                        childItem
                                                                      ).to,
                                                                      href: _vm.getLinkAttrs(
                                                                        childItem
                                                                      ).href,
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              childItem.title
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.makeDisabledTooltipMsg(
                                                            item
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                key: _vm.grandchild.link,
                                                attrs: {
                                                  top: "",
                                                  disabled:
                                                    !_vm.isItemDisabled(item),
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return _vm._l(
                                                          childItem.children,
                                                          function (
                                                            grandchild
                                                          ) {
                                                            return _c(
                                                              "v-list-item",
                                                              {
                                                                key: grandchild.link,
                                                                attrs: {
                                                                  link: "",
                                                                  target:
                                                                    _vm.getLinkAttrs(
                                                                      grandchild
                                                                    ).target,
                                                                  to: _vm.getLinkAttrs(
                                                                    grandchild
                                                                  ).to,
                                                                  href: _vm.getLinkAttrs(
                                                                    grandchild
                                                                  ).href,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            grandchild.title
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.makeDisabledTooltipMsg(
                                                        item
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-tooltip",
                                      {
                                        key: childItem.link,
                                        attrs: {
                                          top: "",
                                          disabled: !_vm.isItemDisabled(item),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      key: childItem.title,
                                                      attrs: {
                                                        target:
                                                          _vm.getLinkAttrs(
                                                            childItem
                                                          ).target,
                                                        to: _vm.getLinkAttrs(
                                                          childItem
                                                        ).to,
                                                        href: _vm.getLinkAttrs(
                                                          childItem
                                                        ).href,
                                                      },
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            childItem.title
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.makeDisabledTooltipMsg(item)
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _c(
                    "section",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        {
                                          attrs: {
                                            link: "",
                                            target: "",
                                            to: "",
                                            href: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "supportBtnClick"
                                              )
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { medium: "" } },
                                              [
                                                _vm._v(
                                                  "mdi-" +
                                                    _vm._s(
                                                      _vm.unreadMsgCount > 0
                                                        ? "chat-alert"
                                                        : "chat"
                                                    )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass: "nav-item-title",
                                            class: {
                                              collapsed: _vm.isCollapsed,
                                            },
                                          },
                                          [
                                            _vm._v(" Support Chat "),
                                            _vm.unreadMsgCount
                                              ? _c("span", [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.unreadMsgCount
                                                      ) +
                                                      ")"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2489817378
                          ),
                        },
                        [_c("span", [_vm._v("Support Chat")])]
                      ),
                    ],
                    1
                  ),
                  !_vm.novaCore.isInternalUser(_vm.$me) &&
                  !_vm.novaCore.isLoggedInAsAnotherUser(_vm.$me)
                    ? _c(
                        "section",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            {
                                              staticClass: "beamerTrigger",
                                              attrs: {
                                                link: "",
                                                target: "",
                                                to: "",
                                                href: "",
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { medium: "" } },
                                                  [_vm._v("mdi-bullhorn")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "nav-item-title" },
                                              [_vm._v("What's New")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                982051965
                              ),
                            },
                            [_c("span", [_vm._v("What's New")])]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "v-footer",
                {
                  staticClass:
                    "justify-center text--lighten-1 grey--text text-center overline d-block version",
                  class: { collapsed: _vm.isCollapsed },
                  attrs: { absolute: "", bottom: "", inset: "" },
                },
                [
                  _vm.isMobileDevice
                    ? [
                        !_vm.isCollapsed
                          ? _c(
                              "v-btn",
                              {
                                staticClass:
                                  "mb-4 non-uppercase mobile-blue-bg",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setRenderMode(
                                      _vm.$root.ENUMS.RenderModeEnum.MOBILE
                                    )
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-cellphone"),
                                ]),
                                _vm._v(" View Mobile Version "),
                              ],
                              1
                            )
                          : _c(
                              "v-btn",
                              {
                                staticClass: "mb-4 mobile-blue-bg",
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setRenderMode(
                                      _vm.$root.ENUMS.RenderModeEnum.MOBILE
                                    )
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-cellphone"),
                                ]),
                              ],
                              1
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex full-width align-center" },
                    [
                      _c("connection-status-indicator"),
                      _c("div", { staticClass: "version-number" }, [
                        _vm._v(
                          " Nova v" +
                            _vm._s(_vm.$neutronVersion.major) +
                            "." +
                            _vm._s(_vm.$neutronVersion.minor) +
                            "." +
                            _vm._s(_vm.$neutronVersion.patch) +
                            " - " +
                            _vm._s(_vm.$neutronVersion.commit) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: tooltipOn }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "d-flex justify-end full-width",
                                    },
                                    tooltipOn
                                  ),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "nav-menu-collapse-toggle-btn",
                                          icon: "",
                                          small: "",
                                        },
                                        on: { click: _vm.toggleCollapse },
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(_vm._s(_vm.collapseIcon)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        311304604
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.collapseIconTooltip))])]
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }