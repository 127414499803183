<template>
  <div class="generic-text-icon" v-if="text">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped lang="scss">
.generic-text-icon {
  height: auto;
  padding: 2px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  background-color: #666;
}
</style>
