<template>
  <div class="flex-grow-1 flex-column d-flex justify-center align-center">
    <p class="text-h6">The page you are looking for does not exist</p>
    <p>
      <router-link to="/appointments">Go back to warehouse portal</router-link>
    </p>
  </div>
</template>

<script>
export default {
  mounted() {
    // Add meta tag so bots ignore any page where this component is rendered when component is mounted
    let meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';

    document.getElementsByTagName('head')[0].appendChild(meta);
  },
  destroyed() {
    // Remove meta tag when component is destroyed
    document.querySelector("[name='robots']").remove();
  }
};
</script>
