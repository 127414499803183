var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    attrs: { "hide-required-legend": true },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "mt-0",
                    attrs: { column: "" },
                    model: {
                      value: _vm.orgType,
                      callback: function ($$v) {
                        _vm.orgType = $$v
                      },
                      expression: "orgType",
                    },
                  },
                  [
                    _c("p", [
                      _c("strong", [_vm._v("Organization:")]),
                      _vm._v(" " + _vm._s(_vm.org.name) + " "),
                    ]),
                    _c("v-radio", {
                      staticClass: "mb-4",
                      attrs: { value: _vm.novaCore.OrgType.Client },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.novaCore.OrgType.Client) +
                                      " "
                                  ),
                                  _c("p", { staticClass: "legend mb-0" }, [
                                    _vm._v(
                                      "Orgs owned and managed by real paying clients."
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      staticClass: "mb-4",
                      attrs: { value: _vm.novaCore.OrgType.Test },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.novaCore.OrgType.Test) +
                                      " "
                                  ),
                                  _c("p", { staticClass: "legend mb-0" }, [
                                    _vm._v(
                                      " Orgs created by internal users for testing or demo purposes. "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("v-radio", {
                      attrs: { value: _vm.novaCore.OrgType.Trial },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.novaCore.OrgType.Trial) +
                                      " "
                                  ),
                                  _c("p", { staticClass: "legend mb-0" }, [
                                    _vm._v(
                                      " Customers evaluating the product for a limited period of time. "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            _c("action-group", {
              attrs: {
                "confirm-icon": "update",
                "confirm-label": "save",
                "disable-confirm": _vm.readOnly,
                loading: _vm.loading,
              },
              on: { cancel: _vm.cancel, confirm: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }