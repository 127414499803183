var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-base", {
    attrs: { "hide-required-legend": true },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function () {
          return [
            _c(
              "v-form",
              {
                ref: "form",
                staticClass: "pt-6 mx-3 edit-appointment-form inline-fields",
              },
              [
                _vm.loading
                  ? [
                      _c("v-progress-linear", {
                        staticClass: "mt-6",
                        attrs: {
                          indeterminate: "",
                          loading: _vm.loading,
                          height: "6",
                        },
                      }),
                      _c("h4", { staticClass: "text-center mt-4 mb-12" }, [
                        _vm._v("Updating appointment ..."),
                      ]),
                    ]
                  : [
                      [
                        _c(
                          "div",
                          { staticClass: "align-center d-flex" },
                          [
                            _c("load-type-select", {
                              attrs: {
                                "return-object": false,
                                label: "Load Type",
                                hideIcon: "",
                                tooltipText:
                                  "Changing the Load Type of this appointment will not change this appointment’s duration.",
                                "visible-selection-count": 5,
                                required: true,
                                rules:
                                  _vm.$validator.rules.required("Load Type"),
                                "multi-select": false,
                                showOnlyAssignedLoadTypes: true,
                                "selected-docks": _vm.warehouse.docks,
                                "selected-warehouse": _vm.warehouse,
                              },
                              model: {
                                value: _vm.appointment.loadTypeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appointment, "loadTypeId", $$v)
                                },
                                expression: "appointment.loadTypeId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      _vm.refNumberSettings.isVisible
                        ? _c(
                            "div",
                            { staticClass: "align-center d-flex" },
                            [
                              _c(
                                "h3",
                                { staticClass: "mr-4" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.refNumberSettings.displayName
                                      ) +
                                      " "
                                  ),
                                  _vm.refNumberSettings.helperText
                                    ? _c("help-icon-tooltip", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.refNumberSettings.helperText
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" : "),
                                ],
                                1
                              ),
                              _vm.refNumberSettings.isRequired
                                ? _c("auto-expandable-textarea", {
                                    attrs: {
                                      label: _vm.refNumberPlaceholder,
                                      placeholder: _vm.refNumberPlaceholder,
                                      "validate-on-blur": "",
                                      rules: _vm.$validator.rules.required(
                                        _vm.refNumberSettings.displayName
                                      ),
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.refNumber,
                                      callback: function ($$v) {
                                        _vm.refNumber = $$v
                                      },
                                      expression: "refNumber",
                                    },
                                  })
                                : _c("auto-expandable-textarea", {
                                    attrs: {
                                      label: _vm.refNumberPlaceholder,
                                      placeholder: _vm.refNumberPlaceholder,
                                      "validate-on-blur": "",
                                    },
                                    model: {
                                      value: _vm.refNumber,
                                      callback: function ($$v) {
                                        _vm.refNumber = $$v
                                      },
                                      expression: "refNumber",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "px-7" },
                        [
                          _c("custom-fields-old", {
                            attrs: {
                              appointment: _vm.appointment,
                              warehouse: _vm.appointment.dock.warehouse,
                              timezone: _vm.appointment.dock.warehouse.timezone,
                            },
                            on: {
                              "toggle-confirm-button": _vm.toggleConfirmButton,
                            },
                            model: {
                              value: _vm.customFields,
                              callback: function ($$v) {
                                _vm.customFields = $$v
                              },
                              expression: "customFields",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c("h3", { staticClass: "mr-4 mt-4" }, [
                            _vm._v("Appointment Notes:"),
                          ]),
                          _c("rich-text-input", {
                            staticClass: "flex-fill",
                            attrs: {
                              "editor-config": {
                                plugins: ["Essentials", "Paragraph"],
                                toolbar: { items: [] },
                              },
                            },
                            model: {
                              value: _vm.notes,
                              callback: function ($$v) {
                                _vm.notes = $$v
                              },
                              expression: "notes",
                            },
                          }),
                        ],
                        1
                      ),
                      [
                        _c("carrier-select", {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: _vm.refillCarrierField,
                              expression: "refillCarrierField",
                            },
                          ],
                          attrs: {
                            required: "",
                            "entry-point": "Edit Appointment Modal",
                            warehouse: _vm.warehouse,
                            label: "Carrier Contact",
                            rules:
                              _vm.$validator.rules.required("Carrier Contact"),
                            "hide-icon": "",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.clearCarrierField.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedCarrier,
                            callback: function ($$v) {
                              _vm.selectedCarrier = $$v
                            },
                            expression: "selectedCarrier",
                          },
                        }),
                      ],
                    ],
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "form-actions",
        fn: function () {
          return [
            !_vm.loading
              ? _c("action-group", {
                  attrs: { "confirm-label": "Save Changes" },
                  on: {
                    cancel: function ($event) {
                      return _vm.$emit("close")
                    },
                    confirm: _vm.updateAppointment,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }