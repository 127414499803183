var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "printable-appointments" } },
        [
          _vm._l(_vm.listableAppointments, function (appointment) {
            return [
              _c("printable-appointment-details", {
                key: appointment.id,
                attrs: { settings: _vm.settings, appointment: appointment },
              }),
            ]
          }),
        ],
        2
      ),
      _c(
        "v-card",
        { staticClass: "appointment-list" },
        [
          _c(
            "v-card-title",
            [
              _vm.customHeader
                ? _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _vm._t("table-header", null, {
                        filters: _vm.filters,
                        rows: _vm.exportableRows,
                        columns: {
                          available: _vm.selectColumns,
                          selected: _vm.selectedColumns,
                          autoSelect: _vm.selectedColumnsAutoselect,
                          update: (event) => (_vm.selectedColumns = event),
                        },
                        exporter: {
                          exportExcel: _vm.exportToExcel,
                          excelFileName: _vm.excelFileName,
                          buildingExcelData: _vm.buildingExcelData,
                          printTable: _vm.printTable,
                        },
                      }),
                    ],
                    2
                  )
                : _c(
                    "v-row",
                    { staticClass: "mb-2 pl-3", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("text-field", {
                            staticClass: "mb-5",
                            attrs: {
                              "append-icon": "mdi-filter",
                              label: "Filter",
                              hint: "Search for appointments",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.filters.searchStr,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "searchStr", $$v)
                              },
                              expression: "filters.searchStr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("generic-multi-select", {
                            staticClass: "mb-5",
                            attrs: {
                              items: _vm.selectColumns,
                              "item-text": "text",
                              "item-value": "value",
                              "append-icon": "mdi-list",
                              refKey: "columnSelect",
                              "auto-select": _vm.selectedColumnsAutoselect,
                              "minimum-selections": 1,
                              "visible-selection-count": 3,
                              label: "Table Columns",
                              "return-object": "",
                              "as-chips": "",
                              hint: "Select columns to show in the table",
                              multiple: "",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.selectedColumns,
                              callback: function ($$v) {
                                _vm.selectedColumns = $$v
                              },
                              expression: "selectedColumns",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _vm.showExportButton
                        ? _c(
                            "primary-button",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                "data-testid": "export-to-excel",
                                loading: _vm.buildingExcelData,
                                "before-icon": "microsoft-excel",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.exportToExcel(
                                    _vm.exportableRows,
                                    _vm.excelFileName
                                  )
                                },
                              },
                            },
                            [_vm._v(" Export to Excel ")]
                          )
                        : _vm._e(),
                      _vm.showPrintButton
                        ? _c(
                            "primary-button",
                            {
                              attrs: { "before-icon": "printer" },
                              on: { click: _vm.printTable },
                            },
                            [_vm._v(" Print Appointments ")]
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "span",
                        { staticClass: "font-size-large text--black" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.displayTotal
                                  ? `Total Appointments: ${_vm.displayTotal}`
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c("appointments-loader", { attrs: { "is-loading": _vm.isLoading } }),
          _c(
            "div",
            { staticClass: "print-table" },
            [
              _c("v-data-table", {
                ref: "appointmentListTable",
                staticClass: "clickable-rows",
                attrs: {
                  "data-testid": "appointments-list-data-table",
                  headers: _vm.columnsToShow,
                  items: _vm.listableAppointments,
                  loading: _vm.$data.$globalLoading,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  "custom-sort": _vm.customSort,
                  search: _vm.regexSafeSearch,
                  "custom-filter": _vm.searchRows,
                  id: "appointments-table",
                  "item-key": "id",
                  "server-items-length": _vm.totalItems,
                  expanded: _vm.expanded,
                  "footer-props": _vm.footerProps,
                  "disable-sort": _vm.printMode,
                  "disable-pagination": _vm.printMode,
                  options: _vm.options,
                },
                on: {
                  "update:sortBy": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "current-items": _vm.setExportRows,
                  "update:expanded": function ($event) {
                    _vm.expanded = $event
                  },
                  pagination: _vm.handlePagination,
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.columnsToShow, function (header) {
                      return {
                        key: `item.${header.value}`,
                        fn: function ({ value }) {
                          return [
                            header.value === "user.email"
                              ? [
                                  _c(
                                    "copy-content",
                                    {
                                      key: header.value,
                                      attrs: {
                                        content: value,
                                        label: "Click to copy carrier email",
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._f("highlight")(
                                            value,
                                            _vm.regexSafeSearch
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              : [
                                  _c("span", {
                                    key: header.value,
                                    domProps: {
                                      innerHTML: _vm._f("highlight")(
                                        value,
                                        _vm.regexSafeSearch
                                      ),
                                    },
                                  }),
                                ],
                          ]
                        },
                      }
                    }),
                    {
                      key: "item.status",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            {
                              class: `${item.status.toLowerCase()} tile font-size-x-small text-center`,
                            },
                            [
                              _vm.novaCore.isRequested(item.status)
                                ? _c("v-icon", { staticClass: "mr-1" }, [
                                    _vm._v("mdi-clock-alert"),
                                  ])
                                : _vm._e(),
                              _c("strong", {
                                class: {
                                  "black--text": _vm.novaCore.isRequested(
                                    item.status
                                  ),
                                },
                                domProps: {
                                  innerHTML: _vm._f("highlight")(
                                    _vm.novaCore.breakWordsAtCaps(item.status),
                                    _vm.regexSafeSearch
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "row-actions" },
                            [
                              _c("icon-tooltip-button", {
                                attrs: {
                                  "icon-class": "mr-2",
                                  size: "large",
                                  tooltip: "View Appointment",
                                  icon: "eye",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEventClick(item)
                                  },
                                },
                              }),
                              _c("icon-tooltip-button", {
                                attrs: {
                                  size: "large",
                                  tooltip: "Show More",
                                  icon: _vm.expanded.includes(item)
                                    ? "chevron-up"
                                    : "chevron-down",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleRowExpansion(item)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    _vm.headers && _vm.headers.length
                      ? {
                          key: "expanded-item",
                          fn: function ({ headers, item }) {
                            return [
                              _c(
                                "td",
                                {
                                  staticClass: "py-6",
                                  attrs: { colspan: headers.length },
                                },
                                [
                                  _c("h3", [_vm._v("Tags")]),
                                  _vm._l(item.tags, function (tag) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          key: tag,
                                          staticClass: "px-2 mr-1",
                                          attrs: {
                                            label: "",
                                            "text-color":
                                              _vm.util.makeTagObject(
                                                _vm.$org.customTags,
                                                tag
                                              ).textColor,
                                            color: _vm.util.makeTagObject(
                                              _vm.$org.customTags,
                                              tag
                                            ).color,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._f("highlight")(
                                                _vm.util.makeTagObject(
                                                  _vm.$org.customTags,
                                                  tag
                                                ).name,
                                                _vm.regexSafeSearch,
                                                item.id,
                                                "tag"
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  }),
                                  !item.tags || !item.tags.length
                                    ? _c("span", [
                                        _vm._v(
                                          " This appointment does not have tags "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("v-divider", { staticClass: "my-5" }),
                                  _c("h3", [_vm._v("Custom Fields")]),
                                  _vm._l(
                                    _vm.filteredCustomFields(item),
                                    function (customField) {
                                      return _c(
                                        "div",
                                        { key: customField.name },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(customField.label)),
                                          ]),
                                          _vm._v(" : "),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._f("highlight")(
                                                _vm.getCustomFieldValue(
                                                  customField
                                                ),
                                                _vm.regexSafeSearch,
                                                item.id,
                                                "customField"
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  _c("v-divider", { staticClass: "my-5" }),
                                  _vm._l(
                                    _vm.$selectedWarehouseTriggers,
                                    function (trigger) {
                                      return _c(
                                        "div",
                                        {
                                          key: trigger.id,
                                          staticClass: "mb-2",
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    trigger.flow?.name ||
                                                      _vm.novaCore
                                                        .CustomFormsFeaturesData[
                                                        trigger.feature
                                                      ].title
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          [
                                            _c("custom-forms-data", {
                                              key: trigger.id,
                                              attrs: {
                                                trigger: trigger,
                                                "object-id": item.id,
                                                timezone:
                                                  _vm.warehouse.timezone,
                                                flat: "",
                                                "military-time-enabled":
                                                  _vm.$isMilitaryTimeEnabled(
                                                    _vm.warehouse
                                                  ),
                                              },
                                            }),
                                          ],
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          },
                        }
                      : null,
                    {
                      key: "item.startDate",
                      fn: function ({ item }) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getTimeInWarehouseTimezone(
                                    item,
                                    _vm.novaCore.LuxonDateTimeFormats
                                      .LongDateShortMonth,
                                    _vm.novaCore.LuxonDateTimeFormats
                                      .LongDateShortMonth,
                                    _vm.$isMilitaryTimeEnabled(_vm.warehouse)
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.startTime",
                      fn: function ({ item }) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getTimeInWarehouseTimezone(
                                    item,
                                    `${_vm.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM} - ${_vm.novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                                    `${_vm.novaCore.LuxonDateTimeFormats.Extended24HrTime} - ${_vm.novaCore.LuxonDateTimeFormats.AbbreviatedNamedOffset}`,
                                    _vm.$isMilitaryTimeEnabled(_vm.warehouse)
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.start",
                      fn: function ({ item }) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getTimeInWarehouseTimezone(
                                    item,
                                    _vm.novaCore.LuxonDateTimeFormats
                                      .MonthDayYearSlashedTimeAMPM,
                                    _vm.novaCore.LuxonDateTimeFormats
                                      .MonthDayYearSlashedTime24,
                                    _vm.$isMilitaryTimeEnabled(_vm.warehouse)
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.tags",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.toggleRowExpansion(item)
                                },
                              },
                            },
                            [
                              _vm._l(item.tags, function (tag, idx) {
                                return [
                                  idx === 0
                                    ? _c(
                                        "v-chip",
                                        {
                                          key: tag,
                                          staticClass: "px-2 mr-1",
                                          attrs: {
                                            label: "",
                                            small: "",
                                            color: _vm.util.makeTagObject(
                                              _vm.$org.customTags,
                                              tag
                                            ).color,
                                            "text-color":
                                              _vm.util.makeTagObject(
                                                _vm.$org.customTags,
                                                tag
                                              ).textColor,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._f("highlight")(
                                                _vm.util.makeTagObject(
                                                  _vm.$org.customTags,
                                                  tag
                                                ).name,
                                                _vm.regexSafeSearch,
                                                item.id,
                                                "tag"
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : item.tags.length > 1 &&
                                      idx + 1 === item.tags.length
                                    ? _c("span", { key: tag }, [
                                        _vm._v(
                                          " + " +
                                            _vm._s(item.tags.length - 1) +
                                            " more "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                    _vm.shouldShowNoSelectionOverlayMargin
                      ? {
                          key: "no-data",
                          fn: function () {
                            return [
                              _c("div", { staticStyle: { height: "350px" } }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("feature-flag", {
        attrs: { name: "enable-new-appointment-details" },
        scopedSlots: _vm._u([
          {
            key: "enabled",
            fn: function () {
              return [
                _vm.selectedEvent
                  ? _c("appointment-details-dialog", {
                      attrs: {
                        "external-activator": true,
                        "show-dialog": _vm.showDetailsDialog,
                        appointment: _vm.selectedEvent,
                      },
                      on: { close: _vm.handleDetailsDialogClose },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "disabled",
            fn: function () {
              return [
                _vm.selectedEvent
                  ? _c("appointment-details-dialog-old", {
                      attrs: {
                        "external-activator": true,
                        "show-dialog": _vm.showDetailsDialog,
                        appointment: _vm.selectedEvent,
                      },
                      on: { close: _vm.handleDetailsDialogClose },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }