<template>
  <v-card class="big-number-card" outlined>
    <slot></slot>
  </v-card>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BigNumberCard',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: '-'
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/partials/typography';

.big-number-card {
  border-radius: 8px !important;
  border-color: $color-line-divider !important;

  min-inline-size: 192px;
  min-block-size: 64px;

  padding: $spacing-3;
}

:deep .v-card__title {
  @include typography('caption-bold');

  color: $color-text-placeholder;
  padding: 0;
  margin-block-end: $spacing-0-5;
}

:deep .v-card__text {
  @include typography('heading-md-bold');

  color: $color-text-tertiary;
  padding: 0;
}
</style>
