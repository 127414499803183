<template>
  <component :is="`${buttonType}-button`" small class="height-auto" v-on="$listeners">
    <span class="text-left">
      <span class="d-block" v-for="item in items" :key="item.name">
        <v-icon class="mr-2" small>{{ `mdi-${item.icon}` }}</v-icon>
        {{ item.value }}
      </span>
    </span>
  </component>
</template>

<script>
export default {
  props: {
    /**
     * Type of button to display
     */
    buttonType: {
      type: String,
      required: false,
      default: 'outline'
    },
    /**
     * Button items
     */
    items: {
      type: Array,
      required: true
    }
  }
};
</script>
