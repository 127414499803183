import { render, staticRenderFns } from "./WarehouseDocksTab.vue?vue&type=template&id=4b75848a"
import script from "./WarehouseDocksTab.vue?vue&type=script&lang=js"
export * from "./WarehouseDocksTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/apps/helios/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b75848a')) {
      api.createRecord('4b75848a', component.options)
    } else {
      api.reload('4b75848a', component.options)
    }
    module.hot.accept("./WarehouseDocksTab.vue?vue&type=template&id=4b75848a", function () {
      api.rerender('4b75848a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/warehouses/components/detail_tabs/WarehouseDocksTab.vue"
export default component.exports