var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog-base",
    _vm._b(
      {
        key: _vm.renderKey,
        attrs: { header: "Org Settings", scrollable: false },
        on: { close: _vm.close, confirm: _vm.close },
        scopedSlots: _vm._u(
          [
            !_vm.externalActivator
              ? {
                  key: "activator",
                  fn: function (slotProps) {
                    return [
                      _vm.$scopedSlots.activator
                        ? _vm._t("activator", null, null, slotProps)
                        : _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    "before-icon": "settings",
                                    large: "",
                                  },
                                },
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v("View Org Settings")]
                          ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      },
      "dialog-base",
      _vm.$props,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        _vm._l(Object.keys(_vm.settings), function (setting) {
          return _c("div", { key: setting }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.novaCore.EntitySettings.Org[setting].label)),
            ]),
            _vm._v(": " + _vm._s(_vm.settings[setting]) + " "),
          ])
        }),
        0
      ),
      _c(
        "template",
        { slot: "dialog-actions" },
        [
          _c("action-group", {
            attrs: { "hide-cancel": "", "confirm-label": "close" },
            on: { confirm: _vm.close },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }