<template>
  <form-base hide-required-legend>
    <template #form>
      <v-stepper v-model="step" class="warehouse-stepper mx-6" flat>
        <v-stepper-header>
          <v-stepper-step v-if="!hideTerms" :complete="step > 1" step="1">
            Conditions
          </v-stepper-step>
          <v-divider v-if="!hideTerms"></v-divider>
          <v-stepper-step :complete="step > 2" step="2">Warehouse Information</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3">Instructions & Amenities</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" v-if="!hideTerms" class="px-2 mt-2">
            <create-warehouse-terms
              @confirm="onTermsNext"
              @input="v => (isTermsAccepted = v)"
              @cancel="onCancel"
              ref="createWarehouseTerms"></create-warehouse-terms>
          </v-stepper-content>

          <v-stepper-content step="2" class="px-2">
            <create-warehouse-form
              confirm-label="Next"
              :hide-cancel="hideTerms"
              :confirm-icon="null"
              @confirm="onWarehouseInfoNext"
              @cancel="onFormPrevious"
              ref="createWarehouseForm"></create-warehouse-form>
          </v-stepper-content>

          <v-stepper-content step="3">
            <create-warehouse-instructions-form
              @confirm="onFormSubmit"
              @cancel="onFormPrevious"
              ref="createWarehouseInstructionsForm"></create-warehouse-instructions-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
    <template #form-actions>
      <action-group
        :disable-confirm="step === 1 ? !isTermsAccepted : !isStepTwoSet"
        :confirm-label="step === 3 ? 'Create Warehouse' : 'Next'"
        :confirm-icon="null"
        :cancel-icon="null"
        @confirm="step === 1 ? onTermsNext() : onNext()"
        @cancel="$emit('cancel')">
        <template #additional-actions>
          <v-btn v-if="step !== 1" @click="step--">Previous</v-btn>
        </template>
      </action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * Create Warehouse Stepper
 * @displayName Create Warehouse Stepper
 */
export default {
  name: 'CreateWarehouseStepper',
  props: {
    hideTerms: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      step: 1,
      fields: {},
      isTermsAccepted: false
    };
  },
  computed: {
    isStepTwoSet() {
      return this.$refs.createWarehouseForm.isValid;
    }
  },
  methods: {
    onTermsNext() {
      this.step++;
    },
    onWarehouseInfoNext(fields) {
      this.fields = { ...this.fields, ...fields };
      this.step++;
    },
    onFormPrevious() {
      this.step--;
    },
    onFormSubmit(fields) {
      this.fields = { ...this.fields, ...fields };
      this.$emit('confirm', this.fields);
    },
    onNext() {
      if (this.step === 1) {
        this.$refs.createWarehouseTerms.onConfirm();
      } else if (this.step === 2) {
        this.$refs.createWarehouseForm.onConfirm();
      } else {
        this.$refs.createWarehouseInstructionsForm.onConfirm();
      }
    },
    onCancel() {
      this.$emit('cancel');
    }
  },
  mounted() {
    this.mixpanel.track(this.mixpanel.events.ACTION.OPENED_CREATE_WAREHOUSE, {
      'Org Name': this.$org.name,
      'Org ID': this.$org.id
    });

    if (this.hideTerms) {
      this.onTermsNext();
    }
  }
};
</script>

<style lang="scss">
.warehouse-stepper {
  //Hide the number inside the stepper
  span.v-stepper__step__step {
    font-size: 0 !important;
  }
}
</style>
