var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("form-base", {
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _vm.warehouses?.length === 0
                  ? _c(
                      "v-card",
                      { staticClass: "my-6", attrs: { elevation: "0" } },
                      [
                        _c(
                          "v-card-title",
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-2", attrs: { small: "" } },
                              [_vm._v("mdi-alert")]
                            ),
                            _vm._v(" No warehouses found for this org "),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-form",
                      {
                        ref: "form",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            required: "",
                            items: _vm.featuresOptions,
                            label:
                              "Select a feature to enable/disable for this org warehouses",
                            "item-text": "text",
                            "item-value": "value",
                          },
                          model: {
                            value: _vm.selectedFeature,
                            callback: function ($$v) {
                              _vm.selectedFeature = $$v
                            },
                            expression: "selectedFeature",
                          },
                        }),
                        _vm.isCheckInFeature
                          ? _c(
                              "v-alert",
                              {
                                staticClass: "darken-2",
                                attrs: { type: "warning" },
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    " In order to fully enable the check-in feature, please install the feature and set the coordinates. "
                                  ),
                                ]),
                                _c("strong", [
                                  _vm._v(
                                    "The coordinates can be set individually for each warehouse."
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectedFeature && _vm.warehouses.length > 0
                          ? _c(
                              "v-card",
                              {
                                staticClass: "my-4",
                                style: {
                                  "max-height": _vm.isCheckInFeature
                                    ? "300px"
                                    : "400px",
                                },
                                attrs: { elevation: "1" },
                              },
                              [
                                _c("v-card-title", [
                                  _c("small", [
                                    _vm._v(
                                      _vm._s(_vm.selectedFeatureDescription)
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "v-list",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function () {
                                            return [
                                              _c("v-list-item-title", [
                                                _c("strong", [
                                                  _vm._v(
                                                    "Warehouses feature status"
                                                  ),
                                                ]),
                                              ]),
                                              _c("v-list-item-action", [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.warehouseTriggers[
                                                        _vm.selectedFeature
                                                      ]?.length
                                                    ) +
                                                    ") "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3254888960
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list-item",
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "my-0 py-0" },
                                          [
                                            _c("v-col", [
                                              _c(
                                                "strong",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-warehouse")]
                                                  ),
                                                  _vm._v(" Warehouse "),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "text-center",
                                                attrs: { "align-self": "end" },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-form-select")]
                                                ),
                                                _c("strong", [
                                                  _vm._v("Status"),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm.isCheckInFeature
                                              ? _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      "align-self": "end",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: { small: "" },
                                                      },
                                                      [_vm._v("mdi-earth")]
                                                    ),
                                                    _c("strong", [
                                                      _vm._v(
                                                        "Coordinates set?"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.warehouseTriggers[
                                        _vm.selectedFeature
                                      ],
                                      function (warehouse) {
                                        return _c(
                                          "v-list-item",
                                          { key: warehouse.id },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            warehouse.name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                        attrs: {
                                                          "align-self": "end",
                                                          title:
                                                            _vm.isCheckInFeature &&
                                                            !_vm.isGeolocationSet(
                                                              warehouse.id
                                                            )
                                                              ? "Disabled, coordinates must be set"
                                                              : "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              disabled:
                                                                _vm.isCheckInFeature &&
                                                                !_vm.isGeolocationSet(
                                                                  warehouse.id
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.selectedWarehouseIdToggle =
                                                                  warehouse.id
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  large: "",
                                                                  title:
                                                                    _vm.isFeatureInstalled(
                                                                      warehouse.id,
                                                                      _vm.selectedFeature
                                                                    )
                                                                      ? "Installed"
                                                                      : "Not Installed",
                                                                  color:
                                                                    _vm.isFeatureInstalled(
                                                                      warehouse.id,
                                                                      _vm.selectedFeature
                                                                    )
                                                                      ? "primary"
                                                                      : "grey",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.isFeatureInstalled(
                                                                        warehouse.id,
                                                                        _vm.selectedFeature
                                                                      )
                                                                        ? "mdi-toggle-switch"
                                                                        : "mdi-toggle-switch-off"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.isCheckInFeature
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                            attrs: {
                                                              "align-self":
                                                                "end",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.toggleGeolocation(
                                                                        warehouse,
                                                                        _vm.selectedFeature
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      large: "",
                                                                      title:
                                                                        _vm.isGeolocationSet(
                                                                          warehouse.id
                                                                        )
                                                                          ? "Yes"
                                                                          : "No",
                                                                      color:
                                                                        _vm.isGeolocationSet(
                                                                          warehouse.id
                                                                        )
                                                                          ? "primary"
                                                                          : "grey",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.isGeolocationSet(
                                                                            warehouse.id
                                                                          )
                                                                            ? "mdi-map-marker"
                                                                            : "mdi-map-marker-off"
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "form-actions",
            fn: function () {
              return [
                _c("action-group", {
                  attrs: {
                    "confirm-icon": "update",
                    "confirm-label": "Install for all warehouses",
                    "disable-confirm": !_vm.selectedFeature,
                  },
                  on: { cancel: _vm.cancel, confirm: _vm.submit },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-dialog",
        { attrs: { value: _vm.selectedWarehouseIdToggle, width: "500" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", dense: "", flat: "" } },
            [
              _c("v-icon", { attrs: { left: "", color: "white" } }, [
                _vm._v("mdi-help-circle-outline"),
              ]),
              _c("v-toolbar-title", {
                staticClass: "white--text",
                domProps: { textContent: _vm._s("Confirm?") },
              }),
            ],
            1
          ),
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("p", { staticClass: "my-4 font-size-medium" }, [
                  _vm._v(
                    " Are you sure to " +
                      _vm._s(
                        _vm.isFeatureInstalled(
                          _vm.selectedWarehouseIdToggle,
                          _vm.selectedFeature
                        )
                          ? "uninstall"
                          : "install"
                      ) +
                      " the "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.selectedFeatureTitle))]),
                  _vm._v(" feature for this warehouse? "),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "grey" },
                      on: {
                        click: function ($event) {
                          _vm.selectedWarehouseIdToggle = null
                        },
                      },
                    },
                    [_vm._v("Nevermind")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: { click: _vm.toggleFeature },
                    },
                    [_vm._v("Confirm")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.editingWarehouse
        ? _c("edit-org-warehouse-geolocation-dialog", {
            attrs: {
              "external-activator": "",
              warehouse: _vm.editingWarehouse,
              "show-dialog": Boolean(_vm.editingWarehouse?.id),
            },
            on: {
              close: function ($event) {
                _vm.editingWarehouse = null
              },
              updated: _vm.setWarehouseTriggers,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }