var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-dialog", {
    attrs: { "max-width": "950", scrollable: true },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on, attrs }) {
          return [
            _c(
              "v-icon",
              _vm._g(
                _vm._b({ attrs: { size: "23" } }, "v-icon", attrs, false),
                on
              ),
              [_vm._v(" mdi-eye ")]
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function (dialog) {
          return [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.notificationSubject)),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "pa-3",
                        attrs: { icon: "", light: "" },
                        on: {
                          click: function ($event) {
                            dialog.value = false
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close-circle")])],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c("email-notification-preview", {
                      attrs: { "notification-key": _vm.notificationKey },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }