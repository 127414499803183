<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row align="center" class="search-field">
          <v-col md="6">
            <text-field
              class="mb-5"
              v-model="filters.searchStr"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details></text-field>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <create-warehouse-dialog
          :is-first-warehouse="$warehouses.length === 0"
          class="action-button"
          :waiting-on-external-resource="loadingBillingData"></create-warehouse-dialog>
      </v-card-title>
      <v-data-table
        class="warehouse-list"
        :headers="rowHeaders"
        :items="$warehouses"
        :loading="loading"
        :server-items-length="$warehousePagination.total"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="footerProps"
        :options.sync="options">
        <template v-slot:item.org.name="{ item }">
          <copy-content :content="item.org.name">{{ item.org.name }}</copy-content>
        </template>

        <template v-slot:item.name="{ item }">
          <router-link
            :to="{ name: 'warehouses.details.general', params: { warehouseId: item.id } }">
            {{ item.name }}
          </router-link>
          <copy-content :content="item.name"></copy-content>
        </template>

        <template v-slot:item.street="{ item }">
          <copy-content
            :content="novaCore.formatFullAddress(item)"
            message="Copied full address to clipboard"
            label="Click to copy warehouse address">
            {{ item.street }}
          </copy-content>
        </template>

        <template v-slot:item.city="{ item }">
          <copy-content
            :content="novaCore.formatFullAddress(item)"
            message="Copied full address to clipboard"
            label="Click to copy warehouse address">
            {{ item.city }}
          </copy-content>
        </template>

        <template v-slot:item.state="{ item }">
          <copy-content
            :content="novaCore.formatFullAddress(item)"
            message="Copied full address to clipboard"
            label="Click to copy warehouse address">
            {{ item.state }}
          </copy-content>
        </template>

        <template v-slot:item.country="{ item }">
          <country-flag :country-code="item.country"></country-flag>
        </template>

        <template v-slot:item.zip="{ item }">
          <copy-content
            :content="novaCore.formatFullAddress(item)"
            message="Copied full address to clipboard"
            label="Click to copy warehouse address">
            {{ item.zip }}
          </copy-content>
        </template>

        <template v-slot:item.actions="{ item }" v-if="areActionsDisplayed">
          <div class="row-actions">
            <icon-tooltip-button
              v-if="novaCore.canUserAccessWarehouse($me, item.id, item.orgId)"
              :id="item.id"
              tooltip="Edit warehouse"
              size="large"
              icon-class="mr-2"
              color="secondary"
              @click="
                $router.push({
                  name: 'warehouses.details.general',
                  params: { warehouseId: item.id }
                })
              "
              icon="pencil"></icon-tooltip-button>

            <create-appointment-dialog
              v-if="
                $rolePermissions.canCreateAppointment &&
                novaCore.canUserAccessWarehouse($me, item.id, item.orgId)
              "
              :context="{ selectedWarehouse: item }">
              <template v-slot:activator="slotProps">
                <icon-tooltip-button
                  :disabled="!novaCore.canUserAccessWarehouse($me, item.id, item.orgId)"
                  :tooltip="
                    !novaCore.canUserAccessWarehouse($me, item.id, item.orgId)
                      ? novaCore.getRoleActionError()
                      : 'Schedule an appointment at this warehouse'
                  "
                  size="large"
                  icon-class="mr-2"
                  v-bind="slotProps.attrs"
                  v-on="slotProps.on"
                  color="primary"
                  icon="calendar"></icon-tooltip-button>
              </template>
            </create-appointment-dialog>

            <audit-log-entity-icon-button
              :item="item"
              v-if="novaCore.canUserAccessWarehouse($me, item.id, item.orgId)"
              :timezone="item.timezone"
              :entity-type="novaCore.DatabaseEntities.Warehouse"></audit-log-entity-icon-button>

            <icon-tooltip-button
              v-if="$rolePermissions.canDeleteWarehouse"
              @click="showDeleteWarehouseConfirmation(item)"
              size="large"
              tooltip="Delete Warehouse"
              delete></icon-tooltip-button>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <confirm
      :should-show="showDeleteConfirm"
      is-manual-mode
      persistent
      v-if="showDeleteConfirm"
      @result="handleDeleteResult"
      icon="mdi-delete-forever"
      delete-confirmation
      :show-delete-warning="!deleteMessageHtml"
      title="Delete this Warehouse?"
      :width="650"
      :loading="loading"
      :confirmation-input-text="deleteConfirmationInputText"
      :entity-name="deletingWarehouse.name"
      button-true-text="YES, DELETE"
      button-true-color="error"
      color="error">
      <template v-slot:message>
        <v-skeleton-loader v-if="loading" type="list-item-three-line"></v-skeleton-loader>
        <div v-else-if="deleteMessageHtml">
          <h2 class="text-center my-3">Appointments and other data will also be deleted!</h2>
          <v-alert text prominent border="left" color="red" class="my-7">
            <v-icon color="red" class="mr-2">mdi-alert</v-icon>
            <span class="text--primary" v-html="deleteMessageHtml"></span>
          </v-alert>
        </div>
      </template>
    </confirm>

    <create-warehouse-dialog
      :is-first-warehouse="$warehouses.length === 0"
      :external-activator="true"
      :show-dialog="showCreateWarehouseDialog"
      @close="showCreateWarehouseDialog = false"></create-warehouse-dialog>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],
  props: {
    shouldShowCreateWarehouseDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    areActionsDisplayed() {
      return (
        this.$rolePermissions.canCreateAppointment ||
        this.$rolePermissions.canUpdateWarehouse ||
        this.$rolePermissions.canDeleteWarehouse
      );
    }
  },
  data() {
    return {
      loading: false,
      showCreateWarehouseDialog: false,
      loadingBillingData: false,
      showDeleteConfirm: false,
      deleteMessageHtml: null,
      deleteConfirmationInputText: 'YES, I AM AWARE',
      headers: [
        { text: 'Org', value: 'org.name' },
        { text: 'Name', value: 'name', searchable: true },
        { text: 'Street', value: 'street', searchable: true },
        { text: 'City', value: 'city', searchable: true },
        { text: 'State', value: 'state', searchable: true },
        { text: 'Country', value: 'country', searchable: true },
        { text: 'Zip', value: 'zip', searchable: true },
        { text: 'Store #', value: 'facilityNumber', searchable: true },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
      ],
      deletingWarehouse: null,
      sortBy: ['name']
    };
  },
  methods: {
    /**
     * Get warehouse data
     * @public
     */
    async getData() {
      try {
        this.loading = true;
        await this.$store.dispatch('Warehouses/getWarehouses', { filters: this.getQuery() });
      } finally {
        this.loading = false;
      }
    },
    async showDeleteWarehouseConfirmation(warehouse) {
      this.deletingWarehouse = warehouse;
      this.showDeleteConfirm = true;
      this.deleteMessageHtml = await this.makeDeleteConfirmationText(warehouse);
    },
    async handleDeleteResult(confirmed) {
      if (confirmed) {
        this.loading = true;
        try {
          await this.$store.dispatch('Warehouses/deleteWarehouse', this.deletingWarehouse);
          this.getData();
        } finally {
          this.loading = false;
        }
      }
      this.deletingWarehouse = null;
      this.deleteMessageHtml = null;
      this.showDeleteConfirm = false;
    },
    async makeDeleteConfirmationText(warehouse) {
      let appointmentCount = 0;
      let msg = '';

      const docks = await this.services.dock.getDocks(
        {
          s: { warehouseId: warehouse.id }
        },
        {
          fields: ['id']
        }
      );
      const dockCount = docks.length || 0;

      const loadTypeCount = await this.$store.dispatch('LoadTypes/getLoadTypesCountPerWarehouse', {
        warehouseId: warehouse.id
      });

      if (dockCount && loadTypeCount) {
        appointmentCount = await this.$store.dispatch(
          'Docks/getAppointmentCountForDocks',
          this.novaCore.pluck(docks, 'id')
        );
      }

      const totalCount = dockCount + loadTypeCount + appointmentCount;

      if (totalCount > 0) {
        msg = `<span>You are about to permanently delete:</span><br /><br />`;
        this.deleteConfirmationInputText = this.util.deleteConfirmationInputText(
          totalCount,
          'record'
        );
      } else {
        this.deleteConfirmationInputText = this.deletingWarehouse.name;
      }

      if (dockCount > 0) {
        msg += `<p class="mb-1 ml-8"><strong>
                  ${dockCount} dock${dockCount > 1 ? 's' : ''}
                </strong></p>`;
      }
      if (loadTypeCount > 0) {
        msg += `<p class="mb-1 ml-8"><strong>
                    ${loadTypeCount} load type${loadTypeCount > 1 ? 's' : ''}
                </strong></p>`;
      }
      if (appointmentCount > 0) {
        msg += `<p class="mb-1 ml-8"><strong>
                    ${appointmentCount} appointment${appointmentCount > 1 ? 's' : ''}
                </strong></p>`;

        this.deleteConfirmationInputText = this.util.deleteConfirmationInputText(
          appointmentCount,
          'appointment'
        );
      }

      return msg;
    },
    delayedDialog(showDialog) {
      if (this.shouldShowCreateWarehouseDialog || showDialog) {
        setTimeout(() => {
          this.showCreateWarehouseDialog = true;
        }, 300);
      }
    }
  },
  mounted() {
    this.$eventHub.$on('show-create-warehouse-dialog', () => {
      this.delayedDialog(true);
    });
    this.$eventHub.$on(['create-Warehouse', 'update-Warehouse', 'delete-Warehouse'], this.getData);

    this.delayedDialog();
  },
  beforeDestroy() {
    this.$eventHub.$off('show-create-warehouse-dialog');
    this.$eventHub.$off(['create-Warehouse', 'update-Warehouse', 'delete-Warehouse'], this.getData);
  },
  watch: {
    shouldShowCreateWarehouseDialog() {
      this.delayedDialog();
    }
  }
};
</script>

<style>
.v-data-table__selected {
  background-color: #f24848 !important;
}
</style>
