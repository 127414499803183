var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "spot-title",
      attrs: {
        width: _vm.cardWidth,
        height: _vm.cardHeight,
        elevation: "1",
        "data-testid": `yard-spot-${_vm.spot.code}`,
        id: _vm.spot.id,
      },
      on: { click: _vm.handleSpotClick },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "pa-1",
          class: {
            "bg--color-neutral-20": !_vm.selectedSpot || !_vm.isSpotSelected,
            "bg--color-primary-60": _vm.isSpotSelected,
          },
          attrs: { "data-testid": `yard-spot-title-${_vm.spot.code}` },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "font-size-small text-center full-width text-uppercase spot-card-code",
            },
            [_c("strong", [_vm._v(_vm._s(_vm.spot.code))])]
          ),
        ]
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-card",
            {
              staticClass:
                "d-flex flex-column align-center justify-center text-center bg--color-background-transparent",
              attrs: {
                elevation: "0",
                height: _vm.cardContentHeight,
                "data-testid": `yard-spot-unassigned-${_vm.spot.code}`,
              },
            },
            [
              _c(
                "p",
                { staticClass: "mb-1" },
                [
                  _c(
                    "v-icon",
                    {
                      class: `spot-type-${_vm.spot.type}`,
                      attrs: { small: "", color: "dark-grey" },
                    },
                    [_vm._v(" " + _vm._s(_vm.getSpotIcon(_vm.spot.type)) + " ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }