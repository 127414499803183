var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(
          " Enable to select and order the details that are displayed in each appointment tile on your grid. "
        ),
      ]),
      _c("v-switch", {
        attrs: { color: "secondary", dense: "" },
        on: { click: _vm.onEnabledClicked },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("div", { class: { "text--disabled": !_vm.enabled } }, [
                  _c("div", { staticClass: "pl-1" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.enabled
                            ? "Enabled: showing custom grid"
                            : "Disabled: showing default grid"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.enabled,
          callback: function ($$v) {
            _vm.enabled = $$v
          },
          expression: "enabled",
        },
      }),
      _c("p", { class: { "text--disabled": !_vm.enabled } }, [
        _vm._v(
          " Select and order the details you want to display in each appointment tile: "
        ),
      ]),
      _vm.enabled && _vm.lastItem
        ? _c("v-alert", { attrs: { border: "left", type: "info" } }, [
            _vm._v("You must keep at least one item selected."),
          ])
        : _vm._e(),
      _c(
        "div",
        { attrs: { id: "datablock-list" } },
        _vm._l(_vm.config, function (key) {
          return _c("v-checkbox", {
            key: key,
            attrs: {
              value: key,
              "hide-details": "",
              disabled: !_vm.enabled || _vm.lastItem,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend",
                  fn: function () {
                    return [
                      _c("v-icon", { staticClass: "handle" }, [
                        _vm._v("mdi-arrow-split-horizontal"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("grid-tile-data-block-checkbox-label", {
                        attrs: {
                          item: _vm.dataBlocks[key],
                          disabled: !_vm.enabled,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.config,
              callback: function ($$v) {
                _vm.config = $$v
              },
              expression: "config",
            },
          })
        }),
        1
      ),
      _c(
        "div",
        _vm._l(_vm.dataBlocks, function (item, key) {
          return _c("v-checkbox", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.config.includes(key),
                expression: "!config.includes(key)",
              },
            ],
            key: key,
            attrs: { value: key, "hide-details": "", disabled: !_vm.enabled },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend",
                  fn: function () {
                    return [
                      _c(
                        "v-icon",
                        { staticClass: "handle", attrs: { disabled: "" } },
                        [_vm._v("mdi-arrow-split-horizontal")]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("grid-tile-data-block-checkbox-label", {
                        attrs: { item: item, disabled: !_vm.enabled },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.config,
              callback: function ($$v) {
                _vm.config = $$v
              },
              expression: "config",
            },
          })
        }),
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mt-8 font-size-small" }, [
      _c("span", { staticClass: "font-weight-bold" }, [_vm._v("Note:")]),
      _vm._v(" Changes will be applied to past and future appointments "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }