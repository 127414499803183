var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.appointment?.id
    ? _c(
        "dialog-base",
        _vm._b(
          {
            key: _vm.renderKey,
            attrs: {
              persistent: false,
              loading: _vm.loading,
              header: "Appointment Details",
              "loading-message": "",
              "content-class": _vm.contentClasses,
              transition: "dialog-bottom-transition",
              "max-width": "100%",
              value: _vm.showDialog,
              width: "1200px",
            },
            on: { close: _vm.closeDialog, "click:outside": _vm.closeDialog },
            scopedSlots: _vm._u(
              [
                {
                  key: "customHeader",
                  fn: function () {
                    return [
                      _c("header", { staticClass: "details-header" }, [
                        _c("div", { staticClass: "time-details" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("span", {
                                staticClass: "text-lg-h6 black--text",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getAppointmentDateTimeHeader(
                                      _vm.appointment
                                    )
                                  ),
                                },
                              }),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _vm.appointment.isInDSTChange
                                              ? _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    { staticClass: "ml-2" },
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-sun-clock ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    391765754
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Includes a Daylight Savings time shift"
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.reschedulesCount > 0
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "reschedules-count",
                                        attrs: {
                                          "data-testid":
                                            "appointment-details-dialog-reschedules-count",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.reschedulesString) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center timezone" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("mdi-earth")]
                              ),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.appointment.dock.warehouse.timezone.replace(
                                        "_",
                                        " "
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm.recurringPattern.weekDays
                            ? _c("div", { staticClass: "recurring-pattern" }, [
                                _vm._v(" Recurs every "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.recurringPattern.weekDays) +
                                      " @ " +
                                      _vm._s(_vm.recurringPattern.endTime)
                                  ),
                                ]),
                                _vm._v(" until "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.recurringPattern.endDate)),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _vm.appointmentWarehouse
                              ? _c("appointment-action-group", {
                                  attrs: {
                                    "appointment-warehouse":
                                      _vm.appointmentWarehouse,
                                    "parent-appointment": _vm.parentAppointment,
                                    appointment: _vm.appointment,
                                    "appointment-actions":
                                      _vm.appointmentActions,
                                  },
                                  on: {
                                    "update:create-appointment-dialog":
                                      _vm.handleDialogVisibility,
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                staticClass: "pa-3",
                                attrs: { icon: "", light: "" },
                                on: { click: _vm.closeDialog },
                              },
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                !_vm.externalActivator
                  ? {
                      key: "activator",
                      fn: function (slotProps) {
                        return [
                          _c(
                            "primary-button",
                            _vm._g(
                              _vm._b(
                                {},
                                "primary-button",
                                slotProps.attrs,
                                false
                              ),
                              slotProps.on
                            ),
                            [_vm._v("View Appointment")]
                          ),
                        ]
                      },
                    }
                  : null,
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm.appointmentWarehouse
                        ? _c("appointment-details", {
                            staticClass: "full-height",
                            attrs: {
                              "read-only": !_vm.canUserAccessWarehouse,
                              "appointment-warehouse": _vm.appointmentWarehouse,
                              "parent-appointment":
                                _vm.recurringParentAppointment,
                              appointment: _vm.appointment,
                              "should-edit-status": true,
                              "should-display-cancel-button": false,
                            },
                            on: { close: _vm.close },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "dialog-base",
          _vm.$props,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }