<template>
  <v-card :class="appointmentClass" ref="appointmentCard">
    <template v-if="isReserve">
      <div>
        <strong>{{ eventNotes }}</strong> ({{ eventDuration }} min)
      </div>
    </template>
    <template v-else>
      <div class="appointment-half-card">
        <v-chip
          v-if="event.status !== novaCore.AppointmentStatus.Requested"
          class="ma-2"
          label
          :text-color="chipTextColor"
          :class="`${event.color}`"
          style="margin: 0 !important"
          >{{ status }}
        </v-chip>
        <v-icon v-else small color="requested darken-3" class="requested-icon">
          mdi-clock-alert
        </v-icon>
        <strong>{{ companyName }}</strong>
      </div>
      <section v-if="eventEta">
        <span class="font-size-small ml-2"><strong>New ETA: </strong>{{ eventEta }}</span>
      </section>
      <section class="appointment-load-type">
        <strong class="text-right mr-1 font-size-base"> #{{ refNumber }}</strong>
        <span class="font-size-base">{{ loadTypeName }}</span>
      </section>
      <section v-if="showAppointmentFields && !hideAppointmentFields" class="appointment-fields">
        <div
          v-for="field in customFields"
          :key="field.label"
          class="text-sm-caption text--secondary p-0 m-0">
          <strong>{{ field.label }}</strong
          >: {{ field.value }}
        </div>
      </section>
      <section class="event-footer">
        <div v-if="showTags">
          <v-chip
            v-for="tag in event.formattedTags.renderableTags"
            :key="tag.name"
            :color="tag.color"
            :text-color="tag.textColor"
            class="px-2 mb-1 mr-1 font-weight-bold">
            {{ tag.name }}
          </v-chip>
        </div>
      </section>
      <div class="progress-bar" v-if="isInProgress">
        <div class="d-flex justify-space-between mx-1">
          <small v-html="inProgressData.timeDwell"></small>
          <small v-html="inProgressData.timeRemaining"></small>
        </div>
        <v-progress-linear
          color="amber"
          height="8"
          :value="inProgressData.progressPercentage"
          background-opacity="0.3"></v-progress-linear>
      </div>
    </template>
  </v-card>
</template>

<script>
import etaMixin from '@satellite/components/mixins/etaMixin';
import { DateTime } from 'luxon';

export default {
  mixins: [etaMixin],
  data: function () {
    return {
      inProgressData: {},
      updateInProgressTimer: null,
      hideAppointmentFields: false
    };
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    showAppointmentTags: {
      type: Boolean,
      required: false,
      default: true
    },
    showAppointmentFields: {
      type: Boolean,
      required: false,
      default: true
    },
    timezone: {
      type: String,
      required: false
    }
  },
  methods: {
    getInProgressData() {
      const timeDwell = DateTime.now()
        .setZone(this.timezone)
        .diff(
          DateTime.fromISO(this.event.statusTimeline.Arrived).setZone(this.timezone),
          'minutes'
        ).minutes;

      const timeRemaining = Math.ceil(this.eventDuration - timeDwell);

      const percentage = (timeDwell / this.eventDuration) * 100;

      return {
        timeRemaining:
          timeRemaining < 0
            ? `<span class='warning--text'>${timeRemaining * -1}min overtime</span>`
            : `${timeRemaining}min remaining`,
        timeDwell: `${Math.floor(timeDwell)}min dwell`,
        progressPercentage: timeRemaining < 0 ? 100 : Math.floor(percentage)
      };
    },
    hasVerticalOverflow() {
      const root = this.$refs.appointmentCard?.$el;
      const scrollHeight = root?.scrollHeight ? root.scrollHeight : null;
      const offsetHeight = root?.offsetHeight ? root.offsetHeight : null;
      return scrollHeight && offsetHeight && scrollHeight > offsetHeight;
    }
  },
  computed: {
    chipTextColor() {
      const lightStatuses = [this.novaCore.AppointmentStatus.InProgress];
      return lightStatuses.includes(this.event.status) ? 'black' : 'white';
    },
    isShortAppointment() {
      return this.event?.loadType?.duration_min <= 15;
    },
    isInProgress() {
      return this.novaCore.isInProgress(this.event);
    },
    appointmentClass() {
      const baseClass = this.isShortAppointment ? 'appointment-short' : 'appointment';
      const classes = [baseClass];
      return classes.join(' ');
    },
    companyName() {
      return this.event?.user?.company?.name;
    },
    eventNotes() {
      return this.event?.notes ?? '';
    },
    eventEta() {
      if (this.event.eta) {
        return this.novaCore.formatDateTimeWithMilitarySupport(
          this.event.eta,
          this.timezone ?? null,
          this.novaCore.LuxonDateTimeFormats.ShortMonthDayTimeAMPM,
          this.$isMilitaryTimeEnabled(this.$org),
          this.novaCore.LuxonDateTimeFormats.ShortMonthDayTime24
        );
      }

      return '';
    },
    showTags() {
      return (
        this.showAppointmentTags &&
        !this.isShortAppointment &&
        Boolean(this.event.formattedTags.tagStr)
      );
    },
    refNumber() {
      return this.event?.refNumber ?? 'N/A';
    },
    status() {
      return this.event?.status?.toUpperCase() || '';
    },
    isReserve() {
      return this.novaCore.isReserve(this.event);
    },
    loadTypeName() {
      return `${this.event?.loadType?.name} (${this.eventDuration} min)`;
    },
    eventDuration() {
      return momentjs(this.event?.end).diff(momentjs(this.event?.start), 'minute');
    },
    customFields() {
      if (Array.isArray(this.event?.customFields)) {
        return this.event.customFields
          .filter(field => {
            if (Array.isArray(field.value)) {
              return field.value.length > 0;
            }
            if (
              field.type === this.novaCore.CustomFieldType.Document ||
              field.type === this.novaCore.CustomFieldType.MultiDocument
            ) {
              return false;
            }
            return Boolean(field.value);
          })
          .map(field => ({
            label: this.novaCore.truncateString(field.label, 20),
            value: this.novaCore.truncateString(
              this.novaCore.getCustomFieldFormattedValue(field, {
                [this.novaCore.CustomFieldType.Timestamp]: {
                  timezone: this.timezone,
                  formatAsMilitary: this.$isMilitaryTimeEnabled(this.$org)
                }
              }),
              25
            )
          }));
      }
      return [];
    }
  },
  mounted() {
    if (this.isInProgress) {
      this.inProgressData = this.getInProgressData();
    }

    this.updateInProgressTimer = setInterval(() => {
      if (this.isInProgress) {
        this.inProgressData = this.getInProgressData();
      }
    }, this.novaCore.millisecondsIn.minute);
    this.hideAppointmentFields = this.hasVerticalOverflow();
  },
  destroyed() {
    clearInterval(this.updateInProgressTimer);
  },
  watch: {
    event(newEvent, oldEvent) {
      if (newEvent?.start !== oldEvent?.start || newEvent?.end !== oldEvent?.end) {
        this.hideAppointmentFields = this.hasVerticalOverflow();
      }
      if (this.isInProgress) {
        this.$nextTick(() => (this.inProgressData = this.getInProgressData()));
      }
    }
  }
};
</script>

<style lang="scss">
.appointment,
.appointment-short {
  border: 1px solid #0e4059 !important;
  display: flex;
  flex-direction: column;
  height: 100% !important;
  margin-bottom: 2px !important;
  margin-left: 9px !important;
  position: static;
}

.appointment {
  padding: 3px 3px 3px 3px;
  font-size: 18px;
}

.appointment-short {
  padding: 2px 2px 2px 2px;
  font-size: 11px;
  line-height: 11px;
}

.appointment-half-card {
  align-items: center;
  column-gap: 2px;
  display: flex;
  max-height: 50%;
  text-overflow: ellipsis;
  width: 100%;

  .v-chip {
    padding: 2px 2px 2px 2px !important;
    margin: 1px 3px 3px 1px !important;
  }

  .v-chip__content {
    font-weight: bold;
  }

  section {
    line-height: 18px !important;
  }
}

.event-footer {
  width: 100%;
  margin-top: auto;
}

.progress-bar {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.appointment-fields {
  column-gap: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.appointment-load-type {
  display: flex;
  white-space: nowrap;

  span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
