<template>
  <v-overlay class="text-center align-center justify-center">
    <v-progress-circular color="primary" indeterminate size="42"></v-progress-circular>
    <p class="text-body-1 mt-4 font-weight-bold">Loading Yard data...</p>
  </v-overlay>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'YardViewLoading'
});
</script>
