// initial state
const state = {
  companies: []
};

// getters
const getters = {
  keyedById(state) {
    let keyedById = {};
    state.companies.map(company => {
      keyedById[company.id] = company;
    });

    return keyedById;
  }
};

// actions
const actions = {
  async getCompanies({ commit }) {
    let response = await axios.get('company');

    if (response && response.data) {
      commit('setCompanies', response.data.data);
    }
  },
  async createCompany({}, data) {
    if (!data.scac) {
      delete data.scac;
    }

    return await axios.post('company', data);
  }
};

// mutations
const mutations = {
  setCompanies(state, companies) {
    state.companies = companies;
  }
};

export default {
  namespaced: true,
  name: 'Companies',
  state,
  getters,
  actions,
  mutations
};
