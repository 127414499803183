import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import { satelliteGlobalThemeColors, satelliteOpts } from '@satellite/plugins/vuetify';

Vue.use(Vuetify);

export const globalThemeColors = {
  ...satelliteGlobalThemeColors,
  ...{
    // Helios specific theme colors go here
  }
};

const opts = {
  ...satelliteOpts,
  ...{
    // Helios Specific options go here
    themes: {
      light: globalThemeColors,
      dark: globalThemeColors
    }
  }
};

export default new Vuetify(opts);
