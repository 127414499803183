var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.loadTypeIdArray, function (loadTypeId) {
      return _c(
        "v-chip",
        {
          key: loadTypeId,
          staticClass: "mr-1 mt-1",
          attrs: {
            color: _vm.readOnly ? "default" : "primary",
            "text-color": _vm.readOnly ? "black" : "white",
            small: "",
            label: "",
          },
        },
        [_vm._v(" " + _vm._s(_vm.loadTypeName(loadTypeId)) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }