var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showDialog
    ? _c("new-terms-dialog", {
        attrs: { loading: _vm.loading, showDialog: _vm.showDialog },
        on: { confirm: _vm.confirm },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }