import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';
import companyService from '@satellite/services/company-service';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async getUserById(userId, params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`user/${userId}?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },

  async getUsers(params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`user?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },

  // TODO: Add request config to all services
  async updateUser(userId, data, options = {}, requestConfig = {}) {
    const response = await axios.patch(`user/${userId}`, data, requestConfig);
    return getResponse(response, options);
  },

  async setCompanyOnUser(user) {
    const company = await companyService.getCompanyById(user.companyId, {}, { fields: ['name'] });
    user.company = company;
    return user;
  }
};
