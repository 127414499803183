var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-center justify-center full-height flex-column",
    },
    [
      _c(
        "div",
        { staticClass: "icon-wrapper" },
        [
          _c("v-icon", { attrs: { "x-large": "", color: _vm.iconBgColor } }, [
            _vm._v(_vm._s(_vm.formattedIcon)),
          ]),
        ],
        1
      ),
      _c("span", { staticClass: "message-header" }, [
        _vm._v(_vm._s(_vm.messageHeader)),
      ]),
      _c("span", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }