<template>
  <v-overlay absolute :value="isVisible" :color="color" :opacity="opacity">
    <div class="overlay-contents" data-testid="appts-missing-selection-overlay">
      <svg-warehouse />
      <h3 class="align-center">No {{ missingSelectionTitle }} selected</h3>
      <p>
        Please select a warehouse along with one or more {{ missingSelectionTitle }} to continue
      </p>
    </div>
  </v-overlay>
</template>

<script lang="js">
import { isEmpty } from 'lodash';

export default {
  name: 'ApptsMissingSelectionOverlay',
  props: {
    /*
     * Flag to control whether the overlay is visible over content
     */
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    /*
     * Overlay background opacity decimal, from 0 to 1
     */
    opacity: {
      type: Number,
      default: 0.7,
      required: false
    },
    /*
     * Overlay background color
     */
    color: {
      type: String,
      default: 'white',
      required: false
    },
    warehouse: {
      type: String,
      default: '',
      required: false,
    },
    docks: {
      type: Array,
      default: () => [],
      required: false,
    },
    loadTypes: {
      type: Array,
      default: () => [],
      required: false,
    },
    statuses: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  computed: {
    missingSelections() {
      const missing = [];
      if (!this.$props.warehouse) {
        missing.push('warehouse');
      }
      if (isEmpty(this.$props.docks)) {
        missing.push('docks');
      }
      if (isEmpty(this.$props.loadTypes)) {
        missing.push('load types');
      }
      if (isEmpty(this.$props.statuses)) {
        missing.push('statuses');
      }
      return missing;
    },
    missingSelectionTitle() {
      if (this.missingSelections.length <= 1) {
        return this.missingSelections.join(', ');
      }
      const head = this.missingSelections.slice(0, -1);
      const tail = this.missingSelections.at(-1);
      return `${head.join(', ')} and ${tail}`;
    },
  }
};
</script>
